import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import DateInput from "../../../create-star-quote/utils/DateInput";
import CurrencyInput from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../common/Currency";
import { connect, useDispatch } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { formateDate } from "../../../../common/FormatDate";

function LossHistory(props) {
  const [showLossHistory, setShowLossHistory] = useState(false);
  const [lossHistoryFields] = useState({
    d_LossDate: "",
    n_LossAmount: "",
    s_LossDescription: "",
    s_LossType: "",
  });
  const [lossHistoryTable, setLossHistoryTable] = useState(
    props?.quoteData?.lossHistoryData || []
  );
  const [lossHistoryData, setLossHistoryData] = useState(lossHistoryFields);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid",
    padding: "0.62rem",
    borderRadius: "12px",
    borderColor: "#9AA5B5",
    width: "100%",
  };

  const handleDelete = (index) => {
    const updatedLossHistory = lossHistoryTable.filter((_, i) => i !== index);
    dispatch(
      getEditEmptyQuoteData({
        ...props.quoteData,
        lossHistoryData: updatedLossHistory,
      })
    );
    setLossHistoryTable(updatedLossHistory);
  };

  const handleAddLossHistory = () => {
    const updatedLossHistory = [...lossHistoryTable, lossHistoryData];
    dispatch(
      getEditEmptyQuoteData({
        ...props.quoteData,
        lossHistoryData: updatedLossHistory,
      })
    );
    setLossHistoryTable(updatedLossHistory);
    setLossHistoryData(lossHistoryFields);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setLossHistoryData((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = () => {
    if (showLossHistory) {
      handleAddLossHistory();
    } else {
      setShowLossHistory(true);
    }
  };

  return (
    <Box>
      {lossHistoryTable?.length > 0 ? (
        <>
          <TableContainer
            w={"100%"}
            border={"1px solid"}
            borderColor={"primary.50"}
            borderRadius={"xl"}
            mt={4}
          >
            <Table
              variant="simple"
              border={"1px solid"}
              borderColor={"gray.200"}
              borderRadius={"xl"}
              overflow={"hidden"}
            >
              <Thead bg={"primary.50"}>
                <Tr>
                  <Th border={"1px solid"} borderColor={"primary.50"}>
                    #
                  </Th>
                  <Th border={"1px solid"} borderColor={"primary.50"}>
                    Date of Loss
                  </Th>
                  <Th border={"1px solid"} borderColor={"primary.50"}>
                    Loss Type
                  </Th>
                  <Th border={"1px solid"} borderColor={"primary.50"}>
                    Loss Description
                  </Th>
                  <Th
                    border={"1px solid"}
                    borderColor={"primary.50"}
                    textAlign={"right"}
                  >
                    Amount
                  </Th>
                  <Th
                    textAlign={"right"}
                    border={"1px solid"}
                    borderColor={"primary.50"}
                  >
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.isArray(lossHistoryTable) &&
                  lossHistoryTable.map((row, index) => (
                    <Tr key={index}>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        {index + 1}
                      </Td>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        {formateDate(row.d_LossDate)}
                      </Td>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        {row.s_LossType}
                      </Td>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        {row.s_LossDescription}
                      </Td>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        {<Currency amount={row.n_LossAmount} />}
                      </Td>
                      <Td border={"1px solid"} borderColor={"primary.50"}>
                        <Flex alignItems={"center"} justifyContent={"end"}>
                          <IconButton
                            isDisabled={props?.isBind}
                            aria-label="Delete"
                            icon={<FaTrash />}
                            size="sm"
                            onClick={() => handleDelete(index)}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Box p={4} textAlign={"center"}>
            <Text fontSize={"lg"} fontWeight={500}>
              {" "}
              No loss history or claim details found or added{" "}
            </Text>
          </Box>
        </>
      )}

      <Flex p={4} pb={0} alignItems="center" justifyContent="flex-end">
        <Button
          isDisabled={props?.isBind}
          style={{
            borderRadius: "12px",
          }}
          variant="primary"
          id="primary-outlined-btn"
          onClick={handleClick}
        >
          {t("Add")}
        </Button>
      </Flex>

      {showLossHistory && (
        <>
          <Box p={4}>
            <Grid
              templateColumns={{
                base: "1fr",
                md: "repeat(3, 1fr)",
              }}
              gap={{ base: "0.75rem", md: "1.5rem" }}
            >
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Date Of Loss</FormLabel>
                  <DateInput
                    maxDate={new Date()}
                    value={lossHistoryData.d_LossDate}
                    placement={"start-end"}
                    onChange={(e) => {
                      setLossHistoryData((prev) => ({
                        ...prev,
                        d_LossDate: e,
                      }));
                    }}
                    styleProps={{
                      border: "2px solid",
                      borderColor: "#9AA5B5",
                      borderRadius: "12px",
                      size: "lg",
                      readOnly: false,
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Loss Type</FormLabel>
                  <Select
                    size="lg"
                    name="s_LossType"
                    value={lossHistoryData.s_LossType}
                    onChange={handleFieldChange}
                    border="2px solid #9AA5B5"
                    borderRadius={"12px"}
                  >
                    <option value=""></option>
                    {props.getBaseData.STARCAUSEOFLOSS &&
                      props.getBaseData.STARCAUSEOFLOSS.map((dd) => (
                        <option key={dd.s_AppCodeName} value={dd.s_AppCodeName}>
                          {dd.s_AppCodeNameForDisplay}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel>Amount</FormLabel>
                  <CurrencyInput
                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                    placeholder="$0.00"
                    size="lg"
                    style={CurrencyStyles}
                    prefix="$"
                    name="n_LossAmount"
                    value={lossHistoryData.n_LossAmount}
                    onChange={(e) => {
                      setLossHistoryData((prev) => ({
                        ...prev,
                        n_LossAmount: Number(
                          e.target.value.replace(/[^\d.]/g, "")
                        ),
                      }));
                    }}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <FormControl pt={4}>
              <FormLabel>Loss Description:</FormLabel>
              <Input
                size="lg"
                type="text"
                name="s_LossDescription"
                placeholder="Description of Loss Occurred (Optional)"
                value={lossHistoryData.s_LossDescription}
                onChange={handleFieldChange}
                border="2px solid #9AA5B5"
                borderRadius={"12px"}
              />
            </FormControl>
          </Box>
        </>
      )}
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}

export default connect(mapStateToProps)(LossHistory);
