import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import { useTranslation } from "react-i18next";
import GoogleMap from "../instantQuote/flood-detail/google-map/GoogleMap";
import { AiFillCheckCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import Slab from "../../../../assets/foundation-type-images/Slab.jpg";
import Crowlspace from "../../../../assets/foundation-type-images/Crawlspace.jpg";
import Basement from "../../../../assets/foundation-type-images/Basement.jpg";
import elevatedWithoutEnclosure from "../../../../assets/foundation-type-images/Elevated_Without_Enclosure.jpg";
import elevatedWithEnclosure from "../../../../assets/foundation-type-images/Elevated_With_Enclosure.jpg";
import twoToFour from "../../../../assets/foundation-type-images/Two_To_Four_Family_Building.png";
import walkout from "../../../../assets/foundation-type-images/Walkout.jpg";
import "./mode-home-type.scss";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall, updateWhichSection, updateLastSection } from "../../../../services/floodService";


const ModePropertyDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLocated, setIsLocated] = useState(false);
  const [isCondoAssoc, setIsCondoAssoc] = useState(false);
  const [descLabel, setDescLabel] = useState('Building Description');

  const { floodSystemDD } = useSelector(state => state.flood);
  const [validated, setValidated] = useState(false);
  const [yearBuilt, setYearBuiltd] = useState('');
  const [usage, setUsage] = useState('');
  const [foundation, setFoundation] = useState('');
  const [bldgConstructionType, setBldgConstructionType] = useState('');
  const [noOfFloors, setNoOfFloors] = useState('');
  const [areaInSqFt, setAreaInSqFt] = useState('');
  const [totalSqFootage, setTotalSqFootage] = useState('');
  const [viewType, setViewType] = useState('');
  const [DOC, setDOC] = useState(null);
  const [label, setLabel] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);
  const [model, setModal] = useState(null);
  const [sflabel, setSflabel] = useState('Square footage');
  const [isResidentialUnit, setIsResidentialUnit] = useState('YES');
  const [condoAssociation, setCondoAssociation] = useState('');
  const [floorOfUnit, setFloorOfUnit] = useState('');
  const [description, setDescription] = useState('');
  const [noOfUnit, setNoOfUnit] = useState(0);
  const [descDD, setDescDD] = useState([]);
  const [noOfFloorLabel, setNoOfFloorLabel] = useState('');
  const [occupancyType, setOccupancyType] = useState('');
  const [occupancyDD, setOccupancyDD] = useState([]);
  const [rcvVal, setRcvVal] = useState(0);
  const [persqft, setPerSqft] = useState(0);

  const [propertyInfo, setPropertyInfo] = useState({});

  useEffect(() => {
    if (props.name) {
      props.handleChange({}, props.name)
    }
  }, [props.name])


  useEffect(() => {
    setQuoteData();
  }, [props.emptyQuoteData,props.initApiObject])


  const setQuoteData = () => {
    if (props.emptyQuoteData && props.initApiObject && Object.keys(props.emptyQuoteData).length > 0) {
      setPropertyInfo(props.emptyQuoteData.propertyInfo);
      var noOflabel = 'Number of Floors';
      setYearBuiltd(props.emptyQuoteData.propertyInfo.s_YearBuilt || '')
      setUsage(props.emptyQuoteData.propertyInfo.s_Usage || '')
      setFoundation(props.emptyQuoteData.propertyInfo.s_Foundation || '')
      setBldgConstructionType(props.emptyQuoteData.propertyInfo.s_BldgConstructionType || '')
      setNoOfFloors(props.emptyQuoteData.propertyInfo.n_NoOfFloors || '')
      setAreaInSqFt(props.emptyQuoteData.propertyInfo.s_AreaInSqFt || '')
      setTotalSqFootage(props.emptyQuoteData.propertyInfo.s_TotalSqFootage || '')
      setViewType(props.emptyQuoteData.viewType);
      setOccupancyType(props.emptyQuoteData.propertyInfo.s_Occupancy || '');
      setRcvVal(props.emptyQuoteData.propertyInfo.n_ReplacementCost || '');
      setPerSqft(props.emptyQuoteData.persqft || 0);

      // MOBILEHOME
      if (props.emptyQuoteData.viewType === 'MOBILEHOME') {
        if (props.checkDateisValid(props.emptyQuoteData.propertyInfo.d_DateOfConstruction)) {
          setDOC(new Date(props.emptyQuoteData.propertyInfo.d_DateOfConstruction));
        }
        var temp = props.emptyQuoteData.propertyInfo.s_DtOfConstructionSource === 'LOCATEDIN' ? true : false;
        setIsLocated(temp);
        setDescription(props.emptyQuoteData.propertyInfo.s_BuildingUse);
      }

      if (['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType)) {
        noOflabel = 'Number of Floors';
        var view = props.emptyQuoteData.viewType;
        var label = 'Unit Square footage';
        var bldgDescription = 'Unit Description';
        if (view === 'UNIT') {
          setFloorOfUnit(props.emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor);
        }
        if (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(view)) {
          label = 'Building Square footage';
          bldgDescription = 'Building Description'
        }
        setDescLabel(bldgDescription);
        setSflabel(label);
        setCondoAssociation(props.emptyQuoteData.propertyInfo.s_CondoOwnership);
        setDescription(props.emptyQuoteData.propertyInfo.s_BuildingUse);
        setNoOfUnit(props.emptyQuoteData.propertyInfo.s_NoOfUnits);
      }

      setNoOfFloorLabel(noOflabel);
      setIsStateSet(true);
    }
  }

  // Trigger to update primary residency values based on Usages
  useEffect(() => {
    if (isStateSet === true) {
      if (Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.initApiObject)) {
        const reqJson = props.initApiObject;
        const emptyQuote = props.emptyQuoteData;

        // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
        var temp = 'YES', isTenantorOwner = 'OWNER';
        if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(usage)) {
          temp = 'NO'
        }
        if ((['UNIT'].includes(viewType) && condoAssociation === 'YES') || (['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(viewType))) {
          temp = 'NO';
        }

        reqJson.primaryResidence = temp;
        reqJson.type = props.emptyQuoteData.viewType;

        emptyQuote.propertyInfo.s_InsuredResides = temp

        temp = 'NO';
        if (['RENT', 'TENANT'].includes(usage)) {
          temp = 'YES';
          isTenantorOwner = 'TENANT';
        }
        emptyQuote.propertyInfo.s_isInsuredTenant = isTenantorOwner;
        emptyQuote.propertyInfo.s_RentalProperty = temp;


        if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'YES') {
          reqJson.occupancyType = 'RESIDENTIALCONDOBUILD20';
          reqJson.buildingDescription = 'ENTIRERESCONDOBLDG20';
          emptyQuote.propertyInfo.s_Occupancy = 'RESIDENTIALCONDOBUILD20';
          emptyQuote.propertyInfo.s_BuildingUse = 'ENTIRERESCONDOBLDG20';
        }
        if (viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO') {
          reqJson.occupancyType = 'OTHERRESIDENTIAL20';
          emptyQuote.propertyInfo.s_Occupancy = 'OTHERRESIDENTIAL20';
        }

        // To update
        dispatch(updateInitQuoteApi(reqJson));
        dispatch(getEditEmptyQuoteData(emptyQuote));
      }
    }
  }, [usage, props.emptyQuoteData, condoAssociation]);

  useEffect(() => {
    if (Object.keys(floodSystemDD).length > 0) {
      var dropdown = floodSystemDD.FLOODBUILDINGUSE20;
      if (props.emptyQuoteData.viewType === 'MOBILEHOME') {
        setDescDD(dropdown.filter(x => ['MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20', 'OTHER20'].includes(x.s_AppCodeName)));
      }
      if (props.emptyQuoteData.viewType === 'HOME') {
        setOccupancyDD(floodSystemDD.FLOODOCCUPANCY20.filter(x => ['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(x.s_AppCodeName)));
      }
      if (props.emptyQuoteData.viewType === 'UNIT') {
        if (isResidentialUnit === 'YES' && condoAssociation === 'NO') {
          setDescDD(dropdown.filter(x => ['COOPERATIVEUNIT20', 'APARTMENTUNIT20'].includes(x.s_AppCodeName)));
        }
        if (isResidentialUnit === 'YES' && condoAssociation === 'YES') {
          setDescDD(dropdown.filter(x => ['RESIDENTIALCONDOUNITRB20', 'RESIDENTIALCONDOUNITNRB20'].includes(x.s_AppCodeName)));
        }

        if (isResidentialUnit === 'NO') {
          setDescDD(dropdown.filter(x => ['RECREATIONBLDG20', 'COMMERCIAL20', 'HOUSEOFWORSHIP20', 'GOVOWNED20'].includes(x.s_AppCodeName)));
        }
      }
      if (viewType === 'RESIDENTIALBLDG') {
        if (condoAssociation === 'NO' && noOfUnit > 0) {
          var array = [];
          var unit = parseInt(noOfUnit);
          if (unit >= 1 && unit <= 4) {
            array.push('DETACHEDGUESTHOUSE20');
          }
          if (unit >= 2) {
            array.push('ENTIREAPARTMENTBLDG20', 'ENTIRECOOPERATIVEBLDG20');
            if (unit <= 4) {
              array.push('MAINHOUSEBLDG20', 'DETACHEDGUESTHOUSE20');
            }
          }
          setDescDD(dropdown.filter(x => array.includes(x.s_AppCodeName)));
        }
      }

    }

    // No Of Unit always 1 for occupancy - single family
    if (props.emptyQuoteData.viewType === 'HOME' && occupancyType !== 'TWOFOURFAMILY20') {
      if (Object.keys(props.emptyQuoteData).length > 0) {
        const emptyQuote = { ...props.emptyQuoteData };
        emptyQuote.propertyInfo.s_NoOfUnits = 1;
        dispatch(getEditEmptyQuoteData(emptyQuote));
        setNoOfUnit(1);
      }
    }

    if (occupancyType) {
      const reqJson = props.initApiObject;
      reqJson.occupancyType = occupancyType;
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [occupancyType]);

  const callPivotApi = () => {
    if (props.emptyQuoteData && props.initApiObject && Object.keys(props.emptyQuoteData).length > 0) {

      // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
      var temp = 'YES';
      if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(props.emptyQuoteData.propertyInfo.s_Usage)) {
        temp = 'NO'
      }

      if (props.emptyQuoteData.viewType !== "") {
        const reqJson = { ...props.initApiObject };
        reqJson.primaryResidence = temp;

        reqJson.buildingDescription = props.s_BuildingUse;
        reqJson.numberOfUnits = props.s_NoOfUnits;
        reqJson.floorOfUnit = props.s_UnitLocatedOnFloor;

        reqJson.addressLine1 = props.emptyQuoteData.personAddr.s_AddressLine1
        reqJson.addressLine2 = props.emptyQuoteData.personAddr.s_AddressLine1
        reqJson.addressType = 'PROPERTY_ADDRESS'
        reqJson.city = props.emptyQuoteData.personAddr.s_CityName
        reqJson.countyFips = props.emptyQuoteData.personAddr.s_countyFips
        reqJson.deleteAddress = false
        reqJson.propertyBeginningStreetNumber = props.emptyQuoteData.personAddr.s_HouseNo
        reqJson.nameOrDescriptiveType = 'N'
        reqJson.state = props.emptyQuoteData.personAddr.s_StateName
        reqJson.stateFips = props.emptyQuoteData.personAddr.s_stateFips
        reqJson.zipCode = props.emptyQuoteData.personAddr.s_PostalCode
        reqJson.zipPlus4 = props.emptyQuoteData.personAddr.s_PostalCodeSuffix
        reqJson.constructionType = props.emptyQuoteData.propertyInfo.s_BldgConstructionType
        reqJson.effectiveDate = props.emptyQuoteData.effectiveDate
        reqJson.foundationType = props.emptyQuoteData.propertyInfo.s_Foundation
        reqJson.numberOfFloors = props.emptyQuoteData.propertyInfo.n_NoOfFloors
        reqJson.originalConstructionDate = props.emptyQuoteData.propertyInfo.d_DateOfConstruction
        reqJson.totalSquareFootage = props.emptyQuoteData.propertyInfo.s_TotalSqFootage
        reqJson.enclosureSize = props.emptyQuoteData.propertyInfo.s_AreaInSqFt
        reqJson.communityNumber = props.emptyQuoteData.propertyInfo.s_CommunityNumber
        reqJson.floodZone = props.emptyQuoteData.propertyInfo.s_FloodZone
        reqJson.postFirmConstruction = ''
        reqJson.preFirmSubsidyEligible = ''
        reqJson.priorNaicNumber = null;
        reqJson.priorPolicyNumber = null;
        reqJson.sqft = props.emptyQuoteData.propertyInfo.s_TotalSqFootage
        reqJson.type = props.emptyQuoteData.viewType;
        reqJson.uniqueKey = localStorage.getItem('uniqueKey');
        reqJson.whichSection = props.whichSection;
        // To update

        dispatch(updateInitQuoteApi(reqJson));
        
        if (reqJson.addressLine1 !== null && Object.keys(props.emptyQuoteData).length > 0) {
          const code = 'QUOTE_NEWBUSINESS';
          const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
          // Call Api
          if (reqJson.addressLine1 !== null && Object.keys(props.emptyQuoteData).length > 0) {
            dispatch(pivotApiCall(code, request));
          }
        }

        const emptyQuote = { ...props.emptyQuoteData };
        emptyQuote.propertyInfo.s_InsuredResides = temp;
        emptyQuote.personAddr.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.personEmailContact.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.personInfo.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.personPhoneContact.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.policyInfo.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.propertyCoverageInfo.n_UpdatedUser = localStorage.getItem('userID') || '';
        emptyQuote.propertyInfo.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.transactionInfo.n_CreatedUser = localStorage.getItem('userID') || '';
        emptyQuote.uniqueKey = localStorage.getItem('uniqueKey');
        dispatch(getEditEmptyQuoteData(emptyQuote));
      }
    }
  }
  const callPivotOnBlur = (e) => {
      var quoteDataVar = props.emptyQuoteData;
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;

      // Update Date Of Construction If Year Built Changed
      if (name === 's_YearBuilt') {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        //setIsFirmUpdated(false);
      }

      if (name === 's_TotalSqFootage') {
        var rcv = (totalSqFootage * persqft);
        quoteDataVar[parentKey]['n_ReplacementCost'] = rcv;
      }
    dispatch(updateWhichSection('DEFAULT'));
    dispatch(getEditEmptyQuoteData(quoteDataVar));
    callPivotApi();
  }

  const handleStateChange = (e, state) => {
    let temp = 'NO';
    const { value, attributes, checked } = e.target;

    if (checked && ['s_IsResidentialUnit', 's_CondoOwnership'].includes(state)) {
      temp = attributes.checkedvalue.value;
    }

    switch (state) {
      case 's_IsResidentialUnit':
        setIsResidentialUnit(temp);
        break;
      case 's_CondoOwnership':
        setCondoAssociation(temp);
        break;
      case 's_UnitLocatedOnFloor':
        setFloorOfUnit(value);
        break;
      case 's_BuildingUse':
        setDescription(value);
        break;
      case 's_NoOfUnits':
        setNoOfUnit(value);
        break;
      default:
        break;
    }

    if (['s_CondoOwnership'].includes(state)) {
      updateUnitDescription();
      props.handleSwitchChange(e);
    } else {
      if (!['s_IsResidentialUnit'].includes(state)) {
        props.handleChange(e);
      }
    }
  }

  useEffect(() => {
    var temp = isLocated === true ? 'LOCATEDIN' : 'LOCATEDOUTSIDE';
    if (Object.keys(props.emptyQuoteData).length > 0) {
      const emptyQuote = { ...props.emptyQuoteData };
      emptyQuote.propertyInfo.s_DtOfConstructionSource = temp;
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
    var label = 'Date of Permanent Placement';
    if (isLocated === true) {
      label = 'Date of Park/Subdivision was established';
    }
    setLabel(label);
  }, [isLocated]);

  const updateUnitDescription = () => {
    const emptyQuote = { ...props.emptyQuoteData };
    emptyQuote.propertyInfo.s_BuildingUse = '';
    setDescription('');
    dispatch(getEditEmptyQuoteData(emptyQuote));
  }

  useEffect(() => {
    if (totalSqFootage && persqft) {
      var rcv = (totalSqFootage * persqft);
      setRcvVal(rcv);
      const emptyQuote = { ...props.emptyQuoteData };
      emptyQuote.propertyInfo.n_ReplacementCost = rcv;
      dispatch(getEditEmptyQuoteData(emptyQuote));
    }
  }, [totalSqFootage]);

  return (
    <>
      <Form className="confirm-address-form">
        <Row className="justify-content-center">
          <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form" style={{ width: "100%" }}>
            <Row className="justify-content-start">
              <Col xs="12">
                <Form validated={validated} className="confirm-address-form">
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group
                        className="confirm-address-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="confirm-address-label">
                          {t(sflabel)}<AiOutlineInfoCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="square-footage" />
                        </Form.Label>
                        <ReactTooltip id="square-footage" place="top" effect="solid">
                          {t('Provide the total finished living area')}. <br /> {t('An attached garage converted to a living space')} <br /> {t('or an attic converted to living space')} <br /> {t('should be included in the square footage calculation')}.
                        </ReactTooltip>
                        <Form.Control
                          className="confirm-address-input"
                          type="text"
                          placeholder=""
                          name='s_TotalSqFootage'
                          title={t(sflabel)}
                          parentobject="propertyInfo"
                          value={totalSqFootage}

                          defaultValue={''}
                          onChange={(e) => {
                            //props.handleChange(e);
                            setTotalSqFootage(e.currentTarget.value);
                          }}
                          onBlur={(e) => callPivotOnBlur(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('Please Add Square footage error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      ['HOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            controlId="formBasicPassword"
                            className="confirm-address-field"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Year built')}
                            </Form.Label>
                            <Form.Control
                              className="confirm-address-input"
                              type="text"
                              placeholder=""
                              name='s_YearBuilt'
                              title={t('Year built')}
                              parentobject="propertyInfo"
                              value={yearBuilt}
                              defaultValue={''}
                              onChange={(e) => {
                                setYearBuiltd(e.currentTarget.value);
                              }}
                              onBlur={(e) => callPivotOnBlur(e)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Add Year Build Error')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      props.emptyQuoteData.viewType === 'HOME' && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group className="confirm-address-field">
                            <Form.Label className="confirm-address-label">
                              {t('Occupancy type')}<AiOutlineQuestionCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="occupation-type" />
                            </Form.Label>
                            <ReactTooltip id="occupation-type" place="left" effect="solid">
                              <Row>
                                <Col>{t('Single Family')}</Col>
                                <Col> <img src={Slab} alt="image" width="180px" height="90px" /> </Col>
                                <Col>{t('Two to Four Family Building')}</Col>
                                <Col> <img src={twoToFour} alt="image" width="180px" height="90px" /></Col>
                              </Row>
                            </ReactTooltip>
                            <Form.Select
                              className="confirm-address-input"
                              name='s_Occupancy'
                              title={t('Occupancy Type')}
                              parentobject="propertyInfo"
                              value={occupancyType}
                              onChange={(e) => {
                                setOccupancyType(e.currentTarget.value);
                              }}
                              onBlur={(e) => callPivotOnBlur(e)}
                              required
                            >
                              <option value={''}>{t('Select')}</option>
                              {
                                Object.keys(occupancyDD).length > 0 && occupancyDD.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Occupancy Type')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                      <Form.Group className="confirm-address-field">
                        <Form.Label className="confirm-address-label">
                          {t('Construction type')}<AiOutlineInfoCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="cons-type" />
                        </Form.Label>
                        <ReactTooltip id="cons-type" place="top" effect="solid">
                          {t('Construction Type based on the construction')} <br /> {t('used for the wall on the building’s first floor')}.
                        </ReactTooltip>
                        <Form.Select
                          className="confirm-address-input"
                          name='s_BldgConstructionType'
                          title={t('Construction type')}
                          parentobject="propertyInfo"
                          value={bldgConstructionType}
                          onChange={(e) => {
                            setBldgConstructionType(e.currentTarget.value);
                          }}
                          onBlur={(e) => callPivotOnBlur(e)}
                          required
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODCONSTRUCTIONTYPE20.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t('Please Select Construction Type Error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                    <Form.Group
                        className="confirm-address-field"
                      >
                        <Form.Label className="confirm-address-label">
                          {t(noOfFloorLabel)}<AiOutlineInfoCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="noOfStory" />
                        </Form.Label>
                        <ReactTooltip id="noOfStory" place="top" effect="solid">
                          {t('Number of floors above the ground, excluding')} <br /> {t('enclosures or basements (finished or unfinished)')}, <br /> {t('crawlspaces (on grade or subgrade)')} <br /> {t('and attics (if not used for living space)')}.
                        </ReactTooltip>
                        <Form.Control
                          className="confirm-address-input"
                          type="number"
                          placeholder=""
                          name="n_NoOfFloors"
                          title={t('Number of Floors')}
                          parentobject="propertyInfo"
                          value={propertyInfo ? propertyInfo.n_NoOfFloors : ''}
                          onChange={(e) => {
                            props.handleChange(e);
                            setRcvVal(e.currentTarget.value);
                          }}
                          onBlur={(e) => callPivotOnBlur(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {t("Please Add " + noOfFloorLabel + " Error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      !['RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Usage')}
                            </Form.Label>
                            <Form.Select
                              className="confirm-address-input"
                              name="s_Usage"
                              title={t('Usage')}
                              parentobject="propertyInfo"
                              value={usage}
                              onChange={(e) => {
                                setUsage(e.currentTarget.value);
                              }}
                              onBlur={(e) => callPivotOnBlur(e)}
                              required
                            >
                             <option value={''}>{t('Select')}</option>
                              {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLDUSAGETYPE.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Usage Error')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      ['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType) && (
                        <>
                          {
                            props.emptyQuoteData.viewType === 'UNIT' && (
                              <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                <Form.Group
                                  className="confirm-address-field"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label className="confirm-address-label">
                                    {t('Residential Unit')}
                                  </Form.Label>
                                  <div className="flood-construction-row">
                                    <div className="flood-construction-row-actions d-flex">
                                      <span className="me-2">{t('No')}</span>
                                      <div className="custom-main-swtich">
                                        <Form.Check
                                          type="switch"
                                          id="custom-switch"
                                          className="flood-construction-row-switch"
                                          parentobject="propertyInfo"
                                          name="s_IsResidentialUnit"
                                          checked={isResidentialUnit === 'YES' ? true : false}
                                          onChange={(e) => handleStateChange(e, 's_IsResidentialUnit')}
                                          checkedvalue='YES'
                                          uncheckedvalue='NO'
                                        />
                                      </div>
                                      <span className="me-2">{t('Yes')}</span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            )
                          }
                          {
                            ((props.emptyQuoteData.viewType === 'UNIT' && isResidentialUnit === 'YES') || props.emptyQuoteData.viewType === 'RESIDENTIALBLDG') && (
                              <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                <Form.Group
                                  className="confirm-address-field"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Label className="confirm-address-label">
                                    {t('Condominium Association')}
                                  </Form.Label>
                                  <div className="flood-construction-row">
                                    <div className="flood-construction-row-actions d-flex">
                                      <span className="me-2">{t('No')}</span>
                                      <div className="custom-main-swtich">
                                        <Form.Check
                                          type="switch"
                                          id="custom-switch"
                                          className="flood-construction-row-switch"
                                          parentobject="propertyInfo"
                                          name="s_CondoOwnership"
                                          checked={propertyInfo.s_CondoOwnership === "YES" ? true : false}
                                          onChange={(e) => handleStateChange(e, 's_CondoOwnership')}
                                          checkedvalue='YES'
                                          uncheckedvalue='NO'
                                        />
                                      </div>
                                      <span classname="me-2">{t('Yes')}</span>
                                    </div>
                                  </div>
                                </Form.Group>
                              </Col>
                            )
                          }
                          {
                            <>
                              {
                                (props.emptyQuoteData.viewType === 'UNIT' && propertyInfo && propertyInfo.n_NoOfFloors > 1) && (
                                  <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                    <Form.Group
                                      className="confirm-address-field"
                                      controlId="formBasicPassword"
                                    >
                                      <Form.Label className="confirm-address-label">
                                        {t('Floor of Unit')}
                                      </Form.Label>
                                      <Form.Control
                                        className="myform-input"
                                        type="number"
                                        placeholder="0000"
                                        id="flood-constructon-input"
                                        name="s_UnitLocatedOnFloor"
                                        title={t('Floor of Unit')}
                                        parentobject="propertyInfo"
                                        value={propertyInfo ? propertyInfo.s_UnitLocatedOnFloor : ''}
                                        min={0}
                                        onChange={(e) => handleStateChange(e, 's_UnitLocatedOnFloor')}
                                        required
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {t('Please Enter Floor of Unit')}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                )
                              }
                            </>
                          }
                        </>
                      )
                    }
                    {
                      (['UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType) || (props.emptyQuoteData.viewType === 'HOME' && occupancyType === 'TWOFOURFAMILY20')) && (
                        <Col xs="12" sm="12" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Number of Units')}:
                            </Form.Label>
                            <Form.Control
                              className="myform-input"
                              type="number"
                              placeholder="0"
                              name="s_NoOfUnits"
                              title={t('in feet')}
                              parentobject="propertyInfo"
                              value={propertyInfo ? propertyInfo.s_NoOfUnits : ''}
                              min={0}
                              onChange={(e) => handleStateChange(e, 's_NoOfUnits')}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Enter Number of Units')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      ((['MOBILEHOME', 'UNIT', 'NONRESIDENTIALBLDG'].includes(props.emptyQuoteData.viewType)) || (props.emptyQuoteData.viewType === 'RESIDENTIALBLDG' && condoAssociation === 'NO' && noOfUnit > 0)) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4" className='my-2'>
                          <Form.Group className="confirm-address-field">
                            <Form.Label className="confirm-address-label">
                              {t(descLabel)}
                            </Form.Label>
                            <Form.Select
                              className="confirm-address-input"
                              name='s_BuildingUse'
                              title={t(descLabel)}
                              parentobject="propertyInfo"
                              value={propertyInfo ? propertyInfo.s_BuildingUse : ''}
                              onChange={(e) => handleStateChange(e, 's_BuildingUse')}
                              required
                            >
                              <option value={''}>{t('Select')}</option>
                              {
                                descDD.length > 0 && descDD.map((v, i) => {
                                  return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                              }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {t('Please Select Buildind Description')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    <Col xs="12" sm="6" md="6" lg="4" xl="4" className='my-2'>
                      <Form.Group className="confirm-address-field">
                        <Form.Label className="confirm-address-label">
                          {t('Foundation type')}<AiOutlineQuestionCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="foundation-type" />
                        </Form.Label>
                        <ReactTooltip id="foundation-type" place="top" effect="solid">
                          <Row>
                            <Col>{t('Slab')}</Col>
                            <Col> <img src={Slab} alt="image" width="180px" height="90px" /> </Col>
                            <Col>{t('Crawlspace')}</Col>
                            <Col> <img src={Crowlspace} alt="image" width="180px" height="90px" /></Col>
                          </Row>
                          <br />
                          <Row>
                            <Col>{t('Basement')}</Col>
                            <Col> <img src={Basement} alt="image" width="180px" height="90px" /> </Col>
                            <Col>{t('Elevated without enclosure on piers, posts & piles')}</Col>
                            <Col> <img src={elevatedWithoutEnclosure} alt="image" width="180px" height="90px" /></Col>

                          </Row>
                          <br />
                          <Row>
                            <Col>{t('Elevated with enclosure on piers, posts & piles')}</Col>
                            <Col> <img src={elevatedWithEnclosure} alt="image" width="180px" height="90px" /> </Col>
                            <Col>{t('Elevated with enclosure not on piers, posts & piles')}</Col>
                            <Col> <img src={walkout} alt="image" width="180px" height="90px" /></Col>
                          </Row>
                        </ReactTooltip>
                        <Form.Select
                          className="confirm-address-input"
                          name="s_Foundation"
                          title={t('Foundation type')}
                          parentobject="propertyInfo"
                          value={foundation}
                          onChange={(e) => {
                            setFoundation(e.currentTarget.value);
                          }}
                          onBlur={(e) => callPivotOnBlur(e)}
                          required
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODFOUNDATION20.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t('Please Select Foundation Type Error')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="4" xl="4" className='my-2'>
                      <Form.Group
                        className="confirm-address-field"
                      >
                        <Form.Label className="confirm-address-label">
                          {t('Estimated Replacement Cost')}
                        </Form.Label>
                        <Form.Control
                          className="confirm-address-input"
                          type="number"
                          placeholder="0"
                          name="n_ReplacementCost"
                          title={t('Estimated Replacement Cost')}
                          parentobject="propertyInfo"
                          value={propertyInfo ? propertyInfo.n_ReplacementCost : ''}
                          onChange={(e) => {
                            props.handleChange(e);
                            setRcvVal(e.currentTarget.value);
                          }}
                          onBlur={(e) => callPivotOnBlur(e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('Please Enter Estimated Replacement Cost')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {
                      ['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundation) && (
                        <Col xs="12" sm="6" md="6" lg="4" xl="4">
                          <Form.Group
                            className="confirm-address-field"
                          >
                            <Form.Label className="confirm-address-label">
                              {t('Size of Enclosure')}
                            </Form.Label>
                            <Form.Control
                              className="confirm-address-input"
                              type="text"
                              placeholder=""
                              name="s_AreaInSqFt"
                              title={t('Size of Enclosure')}
                              parentobject="propertyInfo"
                              value={propertyInfo ? propertyInfo.s_AreaInSqFt : ''}
                              defaultValue={''}
                              onChange={(e) => {
                                setAreaInSqFt(e.currentTarget.value);
                              }}
                              onBlur={(e) => callPivotOnBlur(e)}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              {t('Please Enter Size of Enclosure')}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )
                    }
                    {
                      props.emptyQuoteData.viewType === 'MOBILEHOME' && (
                        <>
                          <Col xs="12" sm="6" md="6" lg="4" xl="4">
                            <Form.Group
                              className="confirm-address-field"
                              controlId="formBasicPassword"
                            >
                              <Form.Label className="confirm-address-label">
                                {t('Located in Park/Subdivision')}
                              </Form.Label>
                              <div className="flood-construction-row">
                                <div className="flood-construction-row-actions d-flex">
                                  <span className="me-2">{t('No')}</span>
                                  <div className="custom-main-swtich">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      className="flood-construction-row-switch"
                                      parentobject="propertyInfo"
                                      name="s_DtOfConstructionSource"
                                      checked={isLocated}
                                      onChange={() => setIsLocated(!isLocated)}
                                    />
                                  </div>
                                  <span className="me-2">{t('Yes')}</span>
                                </div>
                              </div>
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="6" md="6" lg="4" xl="4">
                            <Form.Group
                              controlId="formBasicPassword"
                              className="confirm-address-field"
                            >
                              <Form.Label className="confirm-address-label">
                                {t('Date of Permanent Placement')}
                              </Form.Label>
                              <DatePicker
                                className="custom-info-date-picker"
                                placeholderText="MM/DD/YYYY"
                                selected={DOC}
                                // minDate={DOC}
                                onChange={(date) => {
                                  props.handleDateChange(date, 'd_DateOfConstruction', 'propertyInfo');
                                  setDOC(date);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </>
                      )
                    }
                    <Col xs="12">
                      <div className="form-map">
                        <GoogleMap />
                      </div>
                    </Col>

                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row >
      </Form>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
    initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},
    ZDDetails: state.flood.ZDDetails ? state.flood.ZDDetails : {},
    whichSection: state.flood.whichSection,
  };
}
export default connect(mapStateToProps)(ModePropertyDetails);

  // export default ModePropertyDetails;