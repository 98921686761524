import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Select,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { toast } from "react-toastify";
import { RiDeleteBin2Line } from "react-icons/ri";
import { CgAddR } from "react-icons/cg";
import DateInput from "../../utils/DateInput";
import states from "../../../common/States";
import { useAutoZipSelection } from "../../utils/useAutoZipDetection";
import { useEffect, useState } from "react";
import LocationSelectionModal from "../../../common/LocationSelectionModal";

export default function Insure() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const { confirmation, setConfirmation, validationError, setValidationError } =
    useStarDataContext();
  const [zipData, setZipData] = useState({});
  const [zipUpdated, setZipUpdated] = useState(false);
  const [currentIndexInsured, setCurrentIndexInsured] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleAddRow = () => {
    const additionalInsuredFields = {
      sameAsMailingAddress: true,
      firstName: "",
      lastName: "",
      mailingAddress: {
        street_address: "",
        city: "",
        zipcode: "",
        state: "",
      },
      phoneNumber: "",
      email: "",
    };

    const isAnyFieldEmpty =
      confirmation?.additionalInsured?.some((additionalInsured, index) => {
        if (index !== 0) {
          return (
            additionalInsured.firstName === "" ||
            additionalInsured.lastName === ""
          );
        }
      }) || false;

    const isFirstIndexFieldsEmpty =
      (confirmation?.additionalInsured || [])[0]?.firstName === "" ||
      (confirmation?.additionalInsured || [])[0].lastName === "" ||
      (confirmation?.additionalInsured || [])[0].dateOfBirth === "" ||
      (confirmation?.additionalInsured || [])[0].dateOfBirth === undefined ||
      (confirmation?.additionalInsured || [])[0].dateOfBirth === null ||
      ((confirmation?.additionalInsured || [])[0]?.sameAsMailingAddress ===
        false &&
        ((confirmation?.additionalInsured || [])[0].mailingAddress
          .street_address === "" ||
          (confirmation?.additionalInsured || [])[0].mailingAddress.city ===
            "" ||
          (confirmation?.additionalInsured || [])[0].mailingAddress.zipcode ===
            "" ||
          (confirmation?.additionalInsured || [])[0].mailingAddress.state ===
            ""));

    if (isFirstIndexFieldsEmpty) {
      toast.warn("Secondary Insured fields Cannot be Empty", {
        autoClose: 5000,
      });
      setValidationError((prev) => ({
        ...prev,
        additionalInsured: true,
      }));
    } else if (isAnyFieldEmpty) {
      toast.warn("Please fill Required Fields");
      setValidationError((prev) => ({
        ...prev,
        additionalInsured: true,
      }));
    } else {
      const newAdditionalInsured = [...(confirmation?.additionalInsured || [])];
      newAdditionalInsured.push(additionalInsuredFields);
      setConfirmation((prev) => ({
        ...prev,
        additionalInsured: newAdditionalInsured,
      }));
      setValidationError((prev) => ({
        ...prev,
        additionalInsured: false,
      }));
      setZipData({});
    }
  };

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    confirmation?.additionalInsured?.[currentIndexInsured]?.mailingAddress
      ?.zipcode,
    !confirmation?.additionalInsured?.[currentIndexInsured]
      ?.sameAsMailingAddress && zipUpdated
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (
      zipData &&
      confirmation.additionalInsured.length > currentIndexInsured &&
      zipData.s_CityName &&
      zipData.s_StateCode
    ) {
      setConfirmation((prevConfirmation) => ({
        ...prevConfirmation,
        additionalInsured: prevConfirmation.additionalInsured.map(
          (insured, index) =>
            index === currentIndexInsured
              ? {
                  ...insured,
                  mailingAddress: {
                    ...insured.mailingAddress,
                    city: zipData.s_CityName,
                    state: zipData.s_StateCode,
                    cityId: zipData.city_id ?? 0,
                    stateId: zipData.state_id ?? 0,
                    countryId: zipData.n_CountryId_FK ?? 1,
                    countyId: zipData.county_id ?? 0,
                    county: zipData.s_CountyName ?? "",
                  },
                }
              : insured
        ),
      }));
      setZipData({});
      setZipUpdated(false);
    }
  }, [zipData, currentIndexInsured]);

  const handleRemoveRow = (index) => {
    if (confirmation?.additionalInsured?.length > 0) {
      const newAdditionalInsured = [
        ...confirmation.additionalInsured.slice(0, index),
        ...confirmation.additionalInsured.slice(index + 1),
      ];
      setConfirmation((prev) => ({
        ...prev,
        additionalInsured: newAdditionalInsured,
      }));
    }

    if (confirmation?.additionalInsured?.length === 1 && index === 0) {
      setConfirmation((prev) => ({
        ...prev,
        additionalInsured: [],
        additionalInsuredFlag: false,
      }));
    }
  };

  const handleSwitchChange = () => {
    const additionalInsuredFields = {
      sameAsMailingAddress: true,
      firstName: "",
      lastName: "",
      mailingAddress: {
        street_address: "",
        city: "",
        zipcode: "",
        state: "",
      },
      phoneNumber: "",
      email: "",
    };
    if (confirmation?.additionalInsuredFlag) {
      setConfirmation((prev) => ({
        ...prev,
        additionalInsuredFlag: false,
        additionalInsured: [],
      }));
    } else {
      setConfirmation((prev) => ({
        ...prev,
        additionalInsuredFlag: true,
        additionalInsured: [additionalInsuredFields],
      }));
    }
    setValidationError((prev) => ({
      ...prev,
      additionalInsured: false,
    }));
  };

  const handleChange = (e, index) => {
    const { name, value, checked } = e.target;
    const newAdditionalInsured = [...confirmation?.additionalInsured];

    if (name === "sameAsMailingAddress") {
      newAdditionalInsured[index].sameAsMailingAddress = checked;
    } else if (name.includes("mailingAddress")) {
      const property = name.split(".")[1];
      newAdditionalInsured[index].mailingAddress = {
        ...newAdditionalInsured[index].mailingAddress,
        [property]: value,
      };
    } else {
      newAdditionalInsured[index][name] = value;
    }

    setConfirmation((prev) => ({
      ...prev,
      additionalInsured: newAdditionalInsured,
    }));
  };

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Flex
        background="var(--chakra-colors-primary-50)"
        borderRadius={"xl"}
        p={"0.1rem"}
        mb="20px"
        mt="20px"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text
          fontSize={"xl"}
          mt={3}
          pl="1.5rem"
          fontWeight="var(--chakra-fontWeights-medium)"
        >
          {t("additional_insured")}
        </Text>

        <Box mr={2}>
          <Switch
            isChecked={confirmation?.additionalInsuredFlag}
            onChange={() => handleSwitchChange()}
            size={"lg"}
          />
        </Box>
      </Flex>
      {confirmation?.additionalInsuredFlag && (
        <Box>
          {confirmation?.additionalInsured?.map((row, index) => (
            <Box pl={5} pr={5}>
              <Card
                border={"1px solid"}
                borderColor={"neutral.100"}
                boxShadow={0}
                borderRadius={"xl"}
                mb={4}
              >
                <CardHeader
                  borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
                  p={0}
                  borderTopRadius={"inherit"}
                >
                  <Flex
                    p={4}
                    borderTopRadius={"inherit"}
                    bg={"var(--chakra-colors-primary-50)"}
                    justifyContent={"space-between"}
                  >
                    <Flex ml={2} gap={3} alignItems={"center"}>
                      <Text mb={0} fontSize={"lg"} fontWeight={400}>
                        {t("additional_insured")}
                      </Text>
                      {index === 0 ? (
                        <Badge
                          colorScheme="green"
                          variant="solid"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          Secondary Insured
                        </Badge>
                      ) : (
                        <Badge
                          colorScheme="blue"
                          variant="outline"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          Additional Insured(s)
                        </Badge>
                      )}
                      {isLoading && index === currentIndexInsured && (
                        <Spinner size={"lg"} color="primary.500" />
                      )}
                    </Flex>
                    <Box mr={2}>
                      <RiDeleteBin2Line
                        onClick={() => handleRemoveRow(index)}
                        size={"25px"}
                      />
                    </Box>
                  </Flex>
                </CardHeader>
                <CardBody p={8}>
                  <Grid
                    w="100%"
                    templateColumns={`repeat(${index === 0 ? 5 : 4}, 1fr)`}
                    gap={6}
                    pt={2}
                    pb={2}
                  >
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("First Name")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="firstName"
                          type="text"
                          size="lg"
                          focusBorderColor="primary.500"
                          value={row?.firstName}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {validationError?.additionalInsured &&
                          confirmation?.additionalInsuredFlag &&
                          !row?.firstName && (
                            <Text pr={1} color={"red"}>
                              {t("First Name is required")}.
                            </Text>
                          )}
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("Last Name")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="lastName"
                          type="text"
                          size="lg"
                          focusBorderColor="primary.500"
                          value={row?.lastName}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {validationError?.additionalInsured &&
                          confirmation?.additionalInsuredFlag &&
                          !row?.lastName && (
                            <Text pr={1} color={"red"}>
                              {t("Last Name is required")}.
                            </Text>
                          )}
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("phone_number")}:
                        </FormLabel>
                        <Box
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          py={"0.21rem"}
                          borderRadius={"xl"}
                          _hover={{
                            borderColor: "neutral.300",
                          }}
                          _activeLink={{
                            borderColor: "secondary.500",
                          }}
                        >
                          <MaskedFormControl
                            style={{ borderColor: "transparent" }}
                            name="phoneNumber"
                            type="text"
                            size="md"
                            focusBorderColor="secondary.500"
                            mask="(111) 111-1111"
                            guide={false}
                            defaultValue={"+1 "}
                            value={
                              row?.phoneNumber &&
                              row?.phoneNumber.replace(/^\+\d+\s?/, "")
                            }
                            onChange={(e) => handleChange(e, index)}
                          />
                        </Box>
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("email")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="email"
                          type="text"
                          size="lg"
                          focusBorderColor="primary.500"
                          value={row?.email}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    {index === 0 && (
                      <GridItem w="100%">
                        <FormControl isRequired>
                          <FormLabel pl={1} fontWeight={400}>
                            {t("Date of Birth")}:
                          </FormLabel>
                          <DateInput
                            value={row?.dateOfBirth || ""}
                            onChange={(e) => {
                              setConfirmation(() => ({
                                ...confirmation,
                                additionalInsured: [
                                  {
                                    ...confirmation?.additionalInsured[0],
                                    dateOfBirth: e,
                                  },
                                ],
                              }));
                            }}
                            styleProps={{
                              border: "2px solid",
                              borderColor: "neutral.500",
                              borderRadius: "xl",
                              size: "lg",
                              readOnly: false,
                            }}
                            maxDate={new Date()}
                          />
                          {validationError?.additionalInsured &&
                            confirmation?.additionalInsuredFlag &&
                            index === 0 &&
                            !confirmation?.additionalInsured?.[0]
                              ?.dateOfBirth && (
                              <Text pr={1} color={"red"}>
                                {t("Date of Birth is required")}.
                              </Text>
                            )}
                        </FormControl>
                      </GridItem>
                    )}
                  </Grid>
                  <Box
                    borderTop={"1px solid"}
                    borderColor={"gray.200"}
                    my={"0px"}
                  />
                  <Flex
                    justifyContent={"flex-start"}
                    py={2}
                    alignItems={"center"}
                    alignContent={"center"}
                    gap={3}
                  >
                    <Text pt={"1rem"}>Same as Property Address?</Text>
                    <Switch
                      colorScheme="blue"
                      mt={"-15px"}
                      ml={"14px"}
                      name="sameAsMailingAddress"
                      isChecked={row?.sameAsMailingAddress}
                      onChange={(e) => handleChange(e, index)}
                      size={"lg"}
                    />
                  </Flex>
                  <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={6}>
                    {!row?.sameAsMailingAddress && (
                      <>
                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("Street Address")}:
                            </FormLabel>
                            <Input
                              border={
                                "2px solid var(--chakra-colors-neutral-500)"
                              }
                              borderRadius={"xl"}
                              name="mailingAddress.street_address"
                              type="text"
                              size="lg"
                              focusBorderColor="primary.500"
                              value={row?.mailingAddress?.street_address}
                              onChange={(e) => handleChange(e, index)}
                            />
                            {validationError?.additionalInsured &&
                              confirmation?.additionalInsuredFlag &&
                              index === 0 &&
                              !confirmation?.additionalInsured?.[0]
                                ?.mailingAddress?.street_address && (
                                <Text pr={1} color={"red"}>
                                  {t("Street Address is required")}.
                                </Text>
                              )}
                          </FormControl>
                        </GridItem>

                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("City")}:
                            </FormLabel>
                            <Input
                              bg={"gray.100"}
                              cursor={"not-allowed"}
                              isDisabled
                              _disabled={{ opacity: 1 }}
                              border={
                                "2px solid var(--chakra-colors-neutral-500)"
                              }
                              borderRadius={"xl"}
                              name="mailingAddress.city"
                              type="text"
                              size="lg"
                              focusBorderColor="primary.500"
                              value={row?.mailingAddress?.city}
                              onChange={(e) => handleChange(e, index)}
                            />
                            {validationError?.additionalInsured &&
                              confirmation?.additionalInsuredFlag &&
                              index === 0 &&
                              !confirmation?.additionalInsured?.[0]
                                ?.mailingAddress?.city && (
                                <Text pr={1} color={"red"}>
                                  {t("City is required")}.
                                </Text>
                              )}
                          </FormControl>
                        </GridItem>

                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("State / State Code")}:
                            </FormLabel>
                            <Select
                              bg={"gray.100"}
                              cursor={"not-allowed"}
                              _disabled={{ opacity: 1 }}
                              isDisabled
                              border={
                                "2px solid var(--chakra-colors-neutral-500)"
                              }
                              borderRadius={"xl"}
                              name="mailingAddress.state"
                              size="lg"
                              focusBorderColor="primary.500"
                              value={row?.mailingAddress?.state}
                              onChange={(e) => {
                                handleChange(e, index);
                              }}
                            >
                              <option value=""></option>
                              {states.map((state) => (
                                <option key={state.name} value={state.abbr}>
                                  {" "}
                                  {state.name} ({state.abbr}){" "}
                                </option>
                              ))}
                            </Select>
                            {validationError?.additionalInsured &&
                              confirmation?.additionalInsuredFlag &&
                              index === 0 &&
                              !confirmation?.additionalInsured?.[0]
                                ?.mailingAddress?.state && (
                                <Text pr={1} color={"red"}>
                                  {t("State is required")}.
                                </Text>
                              )}
                          </FormControl>
                        </GridItem>
                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("Zip Code")}:
                            </FormLabel>
                            <Input
                              border={
                                "2px solid var(--chakra-colors-neutral-500)"
                              }
                              borderRadius={"xl"}
                              name="mailingAddress.zipcode"
                              type="number"
                              size="lg"
                              onKeyDown={(e) =>
                                ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault()
                              }
                              focusBorderColor="primary.500"
                              value={row?.mailingAddress?.zipcode}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                if (
                                  name !== "mailingAddress.zipcode" ||
                                  (name === "mailingAddress.zipcode" &&
                                    value.length <= 5)
                                ) {
                                  handleChange(e, index);
                                  setCurrentIndexInsured(index);
                                  setZipUpdated(true);
                                }
                              }}
                            />
                            {validationError?.additionalInsured &&
                              confirmation?.additionalInsuredFlag &&
                              index === 0 &&
                              !confirmation?.additionalInsured?.[0]
                                ?.mailingAddress?.zipcode && (
                                <Text pr={1} color={"red"}>
                                  {t("Zip Code is required")}.
                                </Text>
                              )}
                          </FormControl>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </CardBody>
              </Card>
            </Box>
          ))}
          <Flex pl={5} pr={5} justifyContent={"flex-end"}>
            <Button
              leftIcon={<CgAddR />}
              color={"white"}
              bg={"var(--chakra-colors-primary-500)"}
              borderColor={"var(--chakra-colors-primary-400)"}
              border={"1px solid"}
              borderRadius="12px"
              fontWeight="var(--chakra-fontWeights-medium)"
              onClick={() => handleAddRow()}
            >
              {t("Add Another Insured")}
            </Button>
          </Flex>
        </Box>
      )}
    </>
  );
}
