import React, { useState, createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const LangContext = createContext();

function LangContextProvider(props) {
  const { i18n } = useTranslation();
  const [selectedLng, setSelectedLng] = useState("English");

  useEffect(() => {
    const getLang = localStorage.getItem("lang");
    if (getLang && (getLang === "en" || getLang === "spa")) {
      const value = getLang === "en" ? "English" : "Spanish";
      i18n.changeLanguage(getLang);
      setSelectedLng(value);
    } else {
      localStorage.setItem("lang", "en");
      setSelectedLng("English");
    }
  }, []);

  const handleDropdownChange = (language, value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    setSelectedLng(language);
  };

  return (
    <LangContext.Provider
      value={{
        selectedLng,
        setSelectedLng,
        handleDropdownChange,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
}

export default LangContextProvider;