import React from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const smokingOptions = [
  { name: "Allowed", icon: "/starImages/miniIcons/allowed.svg" },
  { name: "Not Allowed", icon: "/starImages/miniIcons/none.svg" },
];

const SmokingPolicyCard = ({ name, icon: Icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={Icon || ""} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const SmokingSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails } = useQuote();
  const { t } = useTranslation();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "smokingPolicy", name);
  };

  return (
    <>
      <Flex flexDir={"column"} w={"100%"}>
        <Flex gap={2} my={0}>
          <Text fontWeight="500">{t("Smoking Policy")}:</Text>{" "}
          {buildingDetails[index]?.smokingPolicy && (
            <Text fontWeight="500" color={"neutral.300"}>
              ( {buildingDetails[index]?.smokingPolicy} )
            </Text>
          )}
        </Flex>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
          {smokingOptions.map(({ name, icon }) => (
            <GridItem key={name}>
              <SmokingPolicyCard
                name={name}
                icon={icon}
                isSelected={buildingDetails[index]?.smokingPolicy === name}
                onClick={() => handleSelection(name)}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </>
  );
};
