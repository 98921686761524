import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ModalBody,
  ModalFooter,
  Box,
} from "@chakra-ui/react";

const ErrorModal = ({ isOpen, onClose, alertType, errorData }) => {
  const alertIcons = {
    warning: { icon: "exclamation", title: "Warning" },
    error: { icon: "error", title: "Error" },
    info: { icon: "info", title: "Information" },
  };
  const { title } = alertIcons[alertType] || {};

  const renderErrorContent = (errorData) => {
    if (!errorData) return null;

    const flattenedErrors = [];

    if (errorData.errors) {
      Object.values(errorData.errors).forEach((error) =>
        flattenedErrors.push(error)
      );
    }

    if (errorData.error) {
      if (Array.isArray(errorData.error)) {
        flattenedErrors.push(...errorData.error);
      } else if (typeof errorData.error === "object") {
        // Handle the case where errorData.error is an object
        Object.entries(errorData.error).forEach(([key, value]) => {
          flattenedErrors.push({ errorField: key, message: value });
        });
      } else {
        flattenedErrors.push(errorData.error);
      }
    }

    if (errorData.detailed_error) {
      if (Array.isArray(errorData.detailed_error)) {
        flattenedErrors.push(...errorData.detailed_error);
      } else {
        flattenedErrors.push(errorData.detailed_error);
      }
    }

    return flattenedErrors.map((error, index) => (
      <Box key={index} mb={4}>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>{error.errorField || "Error"}:</AlertTitle>
            <AlertDescription>
              {error.message || error.toString()}
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    ));
  };
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>{renderErrorContent(errorData)}</Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorModal;
