import Http from '../Http';
import * as action from '../store/actions';

export function fetchProductDetails() {
    return dispatch => (
      new Promise((resolve, reject) => {
        Http.get("/api/getProductDetails")
          .then((res) => {
            return resolve(res.data);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response.data);
            }
          });
      })
    );
  }
  
  export function redirectTo(pvtProduct) {
    return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(`/api/redirectToPrivateLink/${pvtProduct}`)
          .then((res) => {
            return resolve(res.data);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response.data);
            }
          });
      })
    );
  }