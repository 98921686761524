import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const PaymentTab = () => {
  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        policy_no: "HO62015005355",
        insured_name: "RICHARD FOX AND LINA FOX",
        payment_amt: 400.0,
        amount_due: 400.0,
        due_date: "03-31-2021",
      });
    }
    return items;
  };

  const products = productsGenerator(100);

  const columns = [
    {
      dataField: "policy_no",
      text: "Policy No",
      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/"}>
          {value}
        </Link>
      ),
    },
    {
      dataField: "insured_name",
      text: "Policyholder/Member Name",
    },
    {
      dataField: "payment_amt",
      text: "Payment AMT",
    },
    {
      dataField: "amount_due",
      text: "Amount Due",
    },
    {
      dataField: "due_date",
      text: "Due Date",
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Payment due</span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="policy_no"
            data={products}
            columns={columns}
            hover
            bordered={false}
            pagination={paginationFactory(options)}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentTab;
