import React, { useState, useEffect } from "react";
import { Link } from 'react-scroll'
import { useNavigate } from "react-router-dom";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";


import { useParams } from 'react-router-dom';
import {isTenancyEnabledForAmazonChat} from "../../../../common/Helper";
const licenseKey = process.env.REACT_APP_LIVECHAT_LICENSE_KEY;
const TasksAndRecentWorksSection = () => {
    const { div } = useParams();
    const selectedDiv = div;
    const navigate = useNavigate();
    const [chatButton, setChatButton] = useState(false);
    const enableChatBot = localStorage.getItem('enable_chat_bot');

    // const hideDivObject =  {
    //     findapolicy:false,
    //     createaquote:false,
    //     endorseapolicy:false,
    //     cancelapolicy:false,
    //     tasks:false,
    // }
    // if(selectedDiv){
    //     Object.keys(hideDivObject).forEach(key => {
    //         hideDivObject[key] = true;
    //     });
    //     hideDivObject[selectedDiv] = false;
    // }

    //const companyPhone = getCompanyDetails("phone");
    const handleClick = (cardNumber, div = '') => {
        if (div == '') {
            navigate(`/findPolicySupport/${cardNumber}`);
        } else {
            navigate(`/findPolicySupport/${cardNumber}/${div}`);
        }
    }

    return (
        <div className="container mt-3">
            <p>Help Topics</p>
            <hr />
            <div>
                <div className="row" >
                    <div className="col-md-7 border-right" style={{ borderRight: "1px solid #dee2e6" }}>
                        <a onClick={() => handleClick(1)} ><button className="containbutton shadow mr-2" style={{ width: "20%", height: "15%" }} ><i className="fa fa-paper-plane mb-2 buttonicon" /><br />Create Log</button></a>
                        <a onClick={() => handleClick(2)} ><button className="containbutton shadow mr-2" style={{ width: "20%", height: "15%" }} ><i className="fa fa-sticky-note mb-2 buttonicon" /><br />Search Log</button></a>
                        <a onClick={() => handleClick(3)} ><button className="containbutton shadow mr-2" style={{ width: "20%", height: "15%" }} ><i className="fa fa-search mb-2 buttonicon" /><br />View Log</button></a>

                    </div>
                    <div className="col-md-5">
                        <div className="rightdv p-5">
                            <h5 className="font-weight-bold">Quick Links</h5>
                            <br></br>
                            <p>Fema.gov - <a href="https://www.fema.gov/">https://www.fema.gov/</a></p><hr />
                            <p>Fema.gov Floods & Maps - <a href="https://www.fema.gov/flood-insurance">https://www.fema.gov/flood-insurance</a></p><hr />
                            <p>FloodSmart - <a href="https://www.floodsmart.gov/">https://www.floodsmart.gov/</a> </p><hr />
                            <p>Flood Damage Cost Calculator - <a href="https://www.floodsmart.gov/cost-flooding">https://www.floodsmart.gov/cost-flooding</a> </p><hr />
                            <p>Flood Insurance Education & Resources - <a href="https://edu.myflood.com/">https://edu.myflood.com/</a> </p><hr />
                        </div>
                        <div className="rightdv p-5">
                        {enableChatBot === "Y" &&   <>
                            <p className="buttonicon"><i className="fa fa-commenting-o" />&nbsp;&nbsp;Live chat with our support Assistant</p>
                            <p>Click on the link to connect with our support assistant. 8am EST - 7pm EST service is available for our valueable customers.</p>
                            <button className="launch-widget-btn btnCustom btnCustom-info pr-4 pl-4" onClick={() => setChatButton(!chatButton)}> Chat Now</button>
                            {chatButton && !isTenancyEnabledForAmazonChat &&
                                <LiveChatWidget
                                    license={licenseKey}
                                    visibility="maximized"
                                />
                            }
                            <hr /></>}
                            <p><b>Need Support?</b></p>
                            <p className="buttonicon"><i className="fa fa-phone buttonicon" />&nbsp;&nbsp;<span style={{cursor:"pointer"}}>(800)-219-3102</span></p>
                            <p>Connect directly with our support team. 8:00AM to 8:00PM ET service is available for our valueable customers.</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default TasksAndRecentWorksSection;