import { Form } from "react-bootstrap";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { useEffect } from "react";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { ResidentialDataDD } from "../../data/ResidentialData";
import { CommercialDataDD } from "../../data/CommercialData";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import useUpdateConstructionDetails from "./UpdateConstructionDetails";
import Question from "./Question";
import { checkedValues } from "./Question";
import { FaExclamationTriangle } from "react-icons/fa";
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Select,
  Switch,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import "../style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendar } from "react-icons/ci";
import moment from "moment";

//defining this for future type errors. (for instance if it's commercial.)
const ResidentialOnlyVacancyTypes = [
  "Primary",
  "Secondary",
  "Seasonal",
  "Tenanted",
  "Vacant",
  "CourseOfConstruction",
  "VacantRenovation",
];

export const ConstructionDetails = () => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const minDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (isChecked) {
      handleShow();
    }
  }, [isChecked]);

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    {
      checkedValues.includes(true) ? setIsChecked(true) : setIsChecked(false);
    }
  };
  const [date, setDate] = useState(undefined);
  const { constructionDetails, setConstructionDetails, loading } =
    useMyContext(CreateQuoteContext);

  function dateIsValid(date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  function calculateDates(requestDate) {
    if (!dateIsValid(requestDate)) {
      throw new Error("Invalid date provided");
    }
    const today = new Date(requestDate);
    const effectiveDate = new Date(today);
    effectiveDate.setDate(today.getDate() + 7);
    const expirationDate = new Date(effectiveDate);
    expirationDate.setFullYear(effectiveDate.getFullYear() + 1);

    return {
      requestDate: today.toISOString().slice(0, 10), // TODAY'S DATE
      effectiveDate: effectiveDate.toISOString().slice(0, 10), // 7 DAYS AFTER TODAY
      expirationDate: expirationDate.toISOString().slice(0, 10), // 1 YEAR FROM EFFECTIVE DATE
    };
  }

  function calculateYear(effectiveDate) {
    if (!dateIsValid(effectiveDate)) {
      throw new Error("Invalid date provided");
    }
    const newEffectiveDate = new Date(effectiveDate);
    const expirationDate = new Date(newEffectiveDate);
    expirationDate.setFullYear(newEffectiveDate.getFullYear() + 1);

    return {
      effectiveDate: newEffectiveDate.toISOString().slice(0, 10),
      expirationDate: expirationDate.toISOString().slice(0, 10), // 1 YEAR FROM EFFECTIVE DATE
    };
  }

  const isValidOccupancyType = (occupancyType) => {
    return ResidentialOnlyVacancyTypes.includes(occupancyType);
  };

  useEffect(() => {
    const requestDate = constructionDetails?.requestDate;
    if (
      requestDate === undefined ||
      requestDate === null ||
      !dateIsValid(requestDate)
    ) {
      return;
    }
    try {
      const dates = calculateDates(requestDate);
      setConstructionDetails({
        ...constructionDetails,
        requestDate: dates.requestDate,
        effectiveDate: dates.effectiveDate,
        expirationDate: dates.expirationDate,
      });
    } catch (error) {
      console.error("Error calculating dates:", error.message);
    }
  }, [constructionDetails?.requestDate]);

  useEffect(() => {
    const effectiveDate = constructionDetails?.effectiveDate;
    if (
      effectiveDate === undefined ||
      effectiveDate === null ||
      !dateIsValid(effectiveDate)
    ) {
      return;
    }
    try {
      const dates = calculateYear(effectiveDate);
      setConstructionDetails({
        ...constructionDetails,
        effectiveDate: dates.effectiveDate,
        expirationDate: dates.expirationDate,
      });
    } catch (error) {
      console.error("Error calculating dates:", error.message);
    }
  }, [constructionDetails?.effectiveDate]);

  useEffect(() => {
    setConstructionDetails({
      ...constructionDetails,
      requestDate: new Date().toISOString().slice(0, 10),
    });
  }, [date]);

  //this is just to update date state as component mounts and render.
  useEffect(() => {
    setDate(null);
    return () => {};
  }, []);

  const handleDateChange = (selectedDate, type) => {
    if (selectedDate) {
      const momentDate = moment(selectedDate);
      const formattedDate = momentDate.format("YYYY-MM-DD");
      if (type === "RequestDate") {
        setConstructionDetails((prevState) => ({
          ...prevState,
          requestDate: formattedDate,
        }));
      } else if (type === "EffectiveDate") {
        setConstructionDetails((prevState) => ({
          ...prevState,
          effectiveDate: formattedDate,
        }));
      }
    }
  };

  useUpdateConstructionDetails();
  const yearBuilt = constructionDetails?.yearBuilt;
  const isInvalidYear =
    yearBuilt === null || yearBuilt === "" || yearBuilt < 0 || yearBuilt === 0;
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const isInvalidLength = yearBuilt.toString().length !== 4;

  const errorMessage = isInvalidYear
    ? t("Year Built is required")
    : isInvalidLength
    ? t("Year Built should be 4 digits")
    : yearBuilt > nextYear
    ? t(`Year Built should be less than ${nextYear}`)
    : "";
  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <Form>
        <Box>
          <Grid templateColumns={"repeat(3, 1fr)"} gap={6} px={10} py={6}>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Occupancy Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.occupancyType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      occupancyType: e.target.value,
                    })
                  }
                  type="text"
                  name="occupancy"
                >
                  <option value=""></option>
                  {constructionDetails?.productType === "Commercial" &&
                    CommercialDataDD?.OccupancyType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {" "}
                        {item.label}{" "}
                      </option>
                    ))}
                  {constructionDetails?.productType === "Residential" &&
                    ResidentialDataDD?.OccupancyType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {(constructionDetails?.occupancyType == null ||
                constructionDetails?.occupancyType == "") && (
                <Text color="red">{t("Occupancy Type is required")}</Text>
              )}
              {constructionDetails?.productType === "Commercial" &&
                isValidOccupancyType(constructionDetails?.occupancyType) && (
                  <Text color="red">{t("Occupancy Type is required")}</Text>
                )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Construction Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.constructionType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      constructionType: e.target.value,
                    })
                  }
                  type="text"
                  name="construction"
                >
                  <option value=""></option>
                  {constructionDetails?.productType === "Residential" &&
                    ResidentialDataDD?.ConstructionType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  {constructionDetails?.productType === "Commercial" &&
                    CommercialDataDD?.ConstructionType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {(constructionDetails?.constructionType == null ||
                constructionDetails?.constructionType == "") && (
                <Text color="red">{t("Construction Type is required")}</Text>
              )}
            </GridItem>

            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Year Built")}:
                </FormLabel>
                <Input
                  className="inputField"
                  value={constructionDetails?.yearBuilt}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      yearBuilt: Number(e.target.value),
                    })
                  }
                  // min={0}
                  placeholder="0000"
                  type="number"
                  name="yearBuilt"
                />
              </FormControl>
              {errorMessage && <Text color="red">{errorMessage}</Text>}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Square Footage")}:
                </FormLabel>
                <Input
                  className="inputField"
                  value={constructionDetails?.squareFootage}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      squareFootage: Number(e.target.value),
                    })
                  }
                  min={0}
                  placeholder="in square feet"
                  type="number"
                  name="squareFootage"
                />
              </FormControl>
              {(constructionDetails?.squareFootage == null ||
                constructionDetails?.squareFootage == "") && (
                <Text color="red">{t("Square Footage Type is required")}</Text>
              )}
              {constructionDetails?.squareFootage >= 20000 && (
                <Text color="red">{t("May give Excessive amount error.")}</Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Number Of Stories")}:
                </FormLabel>
                <Input
                  className="inputField"
                  value={constructionDetails?.noOfStories}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      noOfStories: Number(e.target.value),
                    })
                  }
                  min={1}
                  type="number"
                  name="noOfStories"
                />
              </FormControl>
              {(constructionDetails?.noOfStories == null ||
                constructionDetails?.noOfStories == "") && (
                <Text color="red">
                  At least one story / level / floor is required.
                </Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel className="dashboard-group-label">
                  {t("Elevation Height")}:
                </FormLabel>
                <Input
                  className="inputField"
                  value={constructionDetails?.elevationHeight}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      elevationHeight: e.target.value,
                    })
                  }
                  min={0}
                  placeholder="in square feet"
                  type="number"
                  name="elevation"
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Building Water Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.buildingWaterType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      buildingWaterType: e.target.value,
                    })
                  }
                  type="text"
                  name="water"
                >
                  <option value=""></option>
                  {ResidentialDataDD?.BuildingOverWaterType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
              {(constructionDetails?.buildingWaterType == null ||
                constructionDetails?.buildingWaterType == "") && (
                <Text color="red">Building Water Type is required</Text>
              )}
              {(constructionDetails?.buildingWaterType === "Entirely" ||
                constructionDetails?.buildingWaterType === "Partially") && (
                <GridItem>
                  <FaExclamationTriangle
                    style={{ marginRight: "5px" }}
                    size={50}
                    color="orange"
                  />
                  <Text
                    style={{ color: "orange", fontSize: "16px" }}
                    className="d-flex flex-row"
                  >
                    Your building is located over water? which may affect the
                    validity of the quotation. Creating a quote for a building
                    over water might result in a failure.
                  </Text>
                </GridItem>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Basement Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.basementType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      basementType: e.target.value,
                    })
                  }
                  type="text"
                  name="basement"
                >
                  {constructionDetails?.productType === "Residential" &&
                    ResidentialDataDD?.BasementType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  {constructionDetails?.productType === "Commercial" &&
                    CommercialDataDD?.BasementType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {(constructionDetails?.basementType == null ||
                constructionDetails?.basementType == "") && (
                <Text color="red">{t("Basement Type Type is required")}</Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Foundation Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.foundationType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      foundationType: e.target.value,
                    })
                  }
                  type="text"
                  name="foundationType"
                >
                  <option value=""></option>
                  {constructionDetails?.productType === "Residential" &&
                    ResidentialDataDD?.FoundationType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  {constructionDetails?.productType === "Commercial" &&
                    CommercialDataDD?.FoundationType?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                </Select>
              </FormControl>
              {(constructionDetails?.foundationType == null ||
                constructionDetails?.foundationType == "") && (
                <Text color="red">{t("Foundation Type is required")}</Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Additional Foundation Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.additionalFondationType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      additionalFondationType: e.target.value,
                    })
                  }
                  type="text"
                  name="additionalFoundationType"
                >
                  {constructionDetails?.productType === "Residential" &&
                    ResidentialDataDD?.AdditionalFoundationType?.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                  {constructionDetails?.productType === "Commercial" &&
                    CommercialDataDD?.AdditionalFoundationType?.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Attached Garage Type")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.attachedGarageType}
                  onChange={(e) =>
                    setConstructionDetails({
                      ...constructionDetails,
                      attachedGarageType: e.target.value,
                    })
                  }
                  type="text"
                  name="garage"
                >
                  {ResidentialDataDD?.AttachedGarageType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {(constructionDetails?.attachedGarageType == null ||
                constructionDetails?.attachedGarageType == "") && (
                <Text color="red">{t("Attached Garage Type is required")}</Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Request Date")}:
                </FormLabel>
                <Box style={{ maxWidth: "100%", margin: "auto" }}>
                  <DatePicker
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    minDate={new Date(minDate)}
                    showIcon
                    placeholderText="MM/DD/YYYY"
                    toggleCalendarOnIconClick
                    icon={
                      <Box mt={"0.27rem"} ml={{ lg: "95%", md: "80%" }}>
                        <CiCalendar size={25} />
                      </Box>
                    }
                    className="dateInput"
                    wrapperClassName="dateWidth"
                    selected={
                      constructionDetails.requestDate
                        ? moment(constructionDetails.requestDate).toDate()
                        : null
                    }
                    onChange={(e) => handleDateChange(e, "RequestDate")}
                    type="date"
                  />
                </Box>
              </FormControl>
              {(constructionDetails?.requestDate === null ||
                constructionDetails?.requestDate === "" ||
                constructionDetails?.requestDate < minDate) && (
                <Text color="red">
                  {constructionDetails?.requestDate !== ""
                    ? t("Please select appropriate request date")
                    : t("Request Date is required")}
                </Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Effective Date")}:
                </FormLabel>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  showIcon
                  placeholderText="MM/DD/YYYY"
                  toggleCalendarOnIconClick
                  icon={
                    <Box mt={"0.27rem"} ml={{ lg: "95%", md: "80%" }}>
                      <CiCalendar size={25} />
                    </Box>
                  }
                  className="dateInput"
                  wrapperClassName="dateWidth"
                  // minDate={new Date(minDate) || new Date()}
                  selected={
                    constructionDetails.effectiveDate
                      ? moment(constructionDetails.effectiveDate).toDate()
                      : null
                  }
                  type="date"
                  onChange={(e) => handleDateChange(e, "EffectiveDate")}
                />
              </FormControl>
              {(constructionDetails?.effectiveDate === null ||
                constructionDetails?.effectiveDate === "" ||
                constructionDetails?.effectiveDate < minDate) && (
                <Text color="red">
                  {constructionDetails?.effectiveDate !== ""
                    ? t("Efftective Date should be greater than Request Date")
                    : t("Effective Date is required")}
                </Text>
              )}
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel className="dashboard-group-label">
                  {t("Expiration Date")}:
                </FormLabel>
                <Flex w={"100%"}>
                  <DatePicker
                    showIcon
                    placeholderText="MM/DD/YYYY"
                    toggleCalendarOnIconClick
                    icon={
                      <Box mt={"0.27rem"} ml={{ lg: "95%", md: "80%" }}>
                        <CiCalendar size={25} />
                      </Box>
                    }
                    disabled={true}
                    className="dateInput"
                    wrapperClassName="dateWidth-disabled"
                    selected={
                      constructionDetails.expirationDate
                        ? moment(constructionDetails.expirationDate).toDate()
                        : null
                    }
                    type="date"
                  />
                </Flex>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel className="dashboard-group-label">
                  {t("Flood Zone")}:
                </FormLabel>
                <Select
                  className="selectField"
                  value={constructionDetails?.floodZone}
                  onChange={(e) => {
                    setConstructionDetails({
                      ...constructionDetails,
                      floodZone: e.target.value,
                    });
                  }}
                  type="text"
                  name="floodZone"
                >
                  <option value=""></option>
                  {CommercialDataDD?.FloodZoneGroups?.map((item, index) => (
                    <option key={index} value={item.s_FloodZone}>
                      {item.s_FloodZone}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
        </Box>
        <Box>
          <Flex mt={3} ml={8}>
            <FormLabel mt={0.5}>
              {t(
                "This policy cannot incept within seven days of Underwriters receiving instruction to bind. Do you need the policy to incept sooner than that?"
              )}
              :
            </FormLabel>
            <Switch
              size="lg"
              checked={isChecked !== undefined ? isChecked : showModal}
              onChange={(e) => {
                setIsChecked(e ? true : false);
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              height={24}
              width={48}
              onColor="#00CBF7"
            />
          </Flex>
        </Box>
      </Form>
      <Modal size="lg" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Questions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <Question />
        </Modal.Body>
      </Modal>
    </LoadingOverlay>
  );
};
