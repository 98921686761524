import React, { useState } from "react";
import { Grid, GridItem, Button, Flex, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Options from "./Options";
import RunDate from "./RunDate";
import BasicManagement from "./BasicManagement";
import IvansAccountInfo from "./IvansAccountInfo";
import Management from "./Management";
import Commission from "./Commission";
import { FaSave } from "react-icons/fa";

const AL3Type = ({ disabled, onSubmit, floodData, onDownloadSuspended, downloadType, commData }) => {
  const [data, setData] = useState(floodData);
  const [commissionData, setCommissionData] = useState(commData);
  const { t } = useTranslation();

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    setData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === "download_suspended") {
      onDownloadSuspended(value === 'Y', downloadType);
    }
  };

  const handleCommissionChange = (event) => {
    const { value } = event.target;
    setCommissionData(value);
  };

  const handleClick = () => {
    onSubmit(data, commissionData);
  };
  return (
    <Box>
      <Grid mt={4} templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem>
          <IvansAccountInfo
            disabled={disabled}
            onChange={handleInputChange}
            data={data}
          />
        </GridItem>
        <GridItem>
          <Management
            disabled={disabled}
            onChange={handleInputChange}
            data={data}
          />
        </GridItem>
        <GridItem>
          <BasicManagement
            disabled={disabled}
            onChange={handleInputChange}
            data={data}
          />
        </GridItem>
        <GridItem>
          <RunDate
            disabled={disabled}
            onChange={handleInputChange}
            data={data}
            setData={setData}
          />
        </GridItem>
        <GridItem>
          <Options
            disabled={disabled}
            onChange={handleInputChange}
            data={data}
          />
        </GridItem>
        <GridItem>
          <Commission
            disabled={disabled}
            onCommissionChange={handleCommissionChange}
            data={commissionData}
          />
        </GridItem>
      </Grid>
      <Flex my={4} justifyContent={"center"}>
        <Button
          leftIcon={<FaSave />}
          size={"md"}
          onClick={handleClick}
          isDisabled={disabled}
        >
          {t("Submit")}
        </Button>
      </Flex>
    </Box>
  );
};

export default AL3Type;
