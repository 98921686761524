import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
//import logo from "../../assets/main-login-logo.svg";
//import logo from "../../assets/unifimga_logo.png";

import "./login.scss";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { authCheck, login,getSiteData,forgotPass,addEamil,getLogo,getUserDebardStatus, logout, setUserDebardStatus} from "../../services/authService";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { EncryptOrDecrypt,checkPermission,setSiteDataInLocalStorage } from "../../services/commonService";
import Moment from "moment";
import Http from "../../Http";
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from "react-icons/ai";
import Swal from 'sweetalert2';
import { setButtonColor, setTitleColor } from "../../setButtonColor";
import SecureImage from "../common/SecureImage";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const token = localStorage.getItem('access_token') || '';
	const [logo, setLogo] = useState('');
    const tenant = window.location.host.split('.')[0];
    const [userPasswordReset, setUserPasswordReset] = useState('');
	const [view, setView] = useState('login');
	const [newEmail, setNewEmail] = useState('');
	const [isPasswordExpired, setIsPasswordExpired] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDebardModal, setShowDebardModal] = useState(false);
    let debardStatus = [];
	useEffect(() => {
		callGetSiteData();
        //eslint-disable-next-line
	  }, []);
	  
	  const callGetSiteData = async () => {
		// setLoading(true);
		await dispatch(getSiteData()).then((res) => { 
			if (res.data.success === true) {
                setSiteDataInLocalStorage(res.data.data);
				setLogo(localStorage.getItem('company_logo'));
                setColors(res.data.data.mainData);
				setLoading(false);
			} else {
				localStorage.setItem('company_logo','https://www.pngkey.com/png/full/246-2467574_your-company-slogen-here-your-company-logo-here.png');
				setLogo(localStorage.getItem('company_logo'));
				setLoading(false);
			}
            dispatch(getLogo(localStorage.getItem('company_logo')));
		});
	  };

      function setColors(mainData){
        setButtonColor({ color:  mainData[0]['primary_color'] });
        setTitleColor({ color: mainData[0]['secondary_color'] });
      }
	
    useEffect(() => {
        if (token.length > 0) {
            window.location = '/';
        }
        //eslint-disable-next-line
    }, [])
	 
	// const toggelView = async (e) => {
    //     e.preventDefault();

    // }
    const handleDebard = async(e) =>{
        setLoading(true)
        const userId = EncryptOrDecrypt(localStorage.getItem('user_id'), 'D');
        await dispatch(setUserDebardStatus({'Admin_ID':userId,'is_approved':false,'is_debarred':true}))
        await dispatch(logout());
        localStorage.clear();
        setShowModal(false);
        setLoading(false)
        setShowDebardModal(true);
        //navigate('/login');
    }
    const handleApproved = async(e) =>{
        setLoading(true)
        const userId = EncryptOrDecrypt(localStorage.getItem('user_id'), 'D');
        await dispatch(setUserDebardStatus({'Admin_ID':userId,'is_approved':true,'is_debarred':false})).then((res)=>{
            if(res.is_login === true && res.status === true){
                handleSubmit(e)   
            }
        })
        setShowModal(false);
        setLoading(false)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const request = {
            'username': username,
            'password': password,
        };
        
        dispatch(getUserDebardStatus(username)).then(async (res) =>{
            debardStatus = res
          let  EncryptUserData = EncryptOrDecrypt(res.data.Admin_ID, 'E');
          localStorage.setItem('user_id', EncryptUserData);
            if(res.status === true){
                if(debardStatus.data.show_modal === true){
                    setShowModal(true);
                }
                else{                    
                    if(res.data.is_login === true && debardStatus.data.show_modal === false){
                        dispatch(login(request)).then(async (response) => {
                            var EncryptUserData = ''; 
                            var accessToken ='';
                            if (response.success === true) {
                                if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                                    accessToken = response.data.access_token;
                                    localStorage.setItem('access_token', response.data.access_token);
                                    Http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                                    var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                                    localStorage.setItem('access_token_expires_at', tokenExpireAt);
                                    EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                                    localStorage.setItem('user', EncryptUserData);
                                    localStorage.setItem('is_first_login', response.data.is_first_login);
                                    const productCodeList=JSON.stringify(response?.data?.extraDetails?.productCodeList)
                                    localStorage.setItem('productCodeList',productCodeList)
                                    await dispatch(authCheck());
                                    if(response.data?.extraDetails?.companyDetails?.logo_url) {
                                        localStorage.setItem('company_logo', response.data?.extraDetails?.companyDetails?.logo_url);
                                    }
                                    const showDashboard = () => {
                                        if(response.data.extraDetails.accessRights.modules.includes('AGENTPORTAL')){
                                            dispatch(checkPermission('AGENTPORTAL')).then((ckresult)=>{
                                                navigate("/dashboard");
                                            });
                                        }else{
                                            navigate("/dashboard");
                                        }
                                    }
                                    if(response.data?.passwordExpirationNotification?.status === 'Y') {
                                    await Swal.fire({
                                        title: response?.data?.passwordExpirationNotification?.msg,
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085D6',
                                        cancelButtonColor: '#d33',
                                        confirmButtonText: 'Reset your password',
                                        cancelButtonText:'Cancel'
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                            navigate("/profile",{state:{id:"1"}});

                                        }else{
                                            showDashboard();
                                        }
                                    })
                                    }
                                    else{
                                        showDashboard();
                                    }
                                    
                                
                                } else if(response.success === true && response.data.username !== '' &&  response.data.code === 200){
                                    if(response.data.email === ''){
                                        setUserPasswordReset(response.data.username);
                                        setView('emailUpdateScreen');
                                        setLoading(false);
                                    } else {
                                        setUserPasswordReset(response.data.username);
                                        setView('resetPasswordScreen');
                                        setLoading(false);
                                        setIsPasswordExpired(response.data.passwordExpirationStatus);
                                    } 
                
                                } else {
                                    setUsername('');
                                    setPassword('');
                                    toast.error(response.data.errors);
                                }
                            }
                            setLoading(false);
                        }).catch(() => {
                            setLoading(false);
                            toast.error(t('Please Try again'));
                        });
                    }
            }
            }
            else if(res.status === false){
                setLoading(false);
                toast.error(res.message);
            }
        })
        

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim(); 
        return Moment(new Date(date)).format();
    }

    const odysseypage = () => {
        navigate("/odysseyLogin");
    }

    const enrolAgentPage = () => {
        navigate("/enrol-agent");
    }

    const resetPassword = (e) => {
        e.preventDefault();
        const credentials = {
          username: userPasswordReset,
          sourceView: "AGENTPORTAL"
        };

        dispatch(forgotPass(credentials))
        .then(async (response) => {
          if (response.status === true) {
            toast.success(response.message);
            Swal.fire('Success', response.message, 'success');
            setView('login');
          } else {
            setUsername("");
            toast.error(response.data.errors);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error(t("Please Try again"));
        });
    }
    
    const addEmailOnCognito = (e) => {
        e.preventDefault();
        const credentials = {
            username: userPasswordReset,
            email: newEmail
        };
        dispatch(addEamil(credentials))
          .then((res) => {
            if(res.resetStatus === true){
                setUserPasswordReset(res.username);
                setView('resetPasswordScreen');
            } else {
                toast.error(res.msg);
            }
          }).catch((err) => {
            console.log(err);
            // const response = {
            //   error: true,
            //   message: err.error,
            // };
          })
    }

    const displayEnrollButtonForTenant = (tenant) => {
        const excludedTenants = ['amfam', 'csaa', 'farmers', 'foremost', 'alliedtrust','autoclub','ngm'];
        return !excludedTenants.includes(tenant);
    }

    return (
        <>
            <LoadingOverlay>
            <Loader loading={loading} />
            <div className="login-page-section">
                {
                    view === 'login' && (
                        <Form onSubmit={handleSubmit} >
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="8" lg="6" xl="5">
                            <div className="login-page-card">
                                {/*<img alt="" src={`https://images.weserv.nl/?url=${logo}`}/>*/}
                                <SecureImage src={`${logo}`} />
                                <span className="login-card-title">
                                    {t("Login to Agent Portal")}
                                </span>
                                <span className="login-card-subtitle">{t("Welcome Back")}!</span>
                                <div className="login-card-line-breaker"> </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Username")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        placeholder=""
                                        value={username}
                                        onChange={(e) => setUsername(e.currentTarget.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Password")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="password"
                                        placeholder=""
                                        value={password}
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                        required
                                    />
                                    <Form.Text id="login-page-form-subtext" >
                                        <a href={'/forgotPassword'}>{t('Forgot Password')}</a>
                                    </Form.Text>
                                </Form.Group>
                                <Button
                                    id="common-btn"
                                    variant="primary"
                                    type="submit"
                                    style={{ padding: "12px", display: "block" }}
                                    disabled={loading}
                                >
                                    {t("Login")}
                                </Button>{" "}
                                <Button
                                    id="common-btn-1"
                                    variant="secondary"
                                    type="button"
                                    style={{ padding: "12px", marginTop:"10px" }}
                                    onClick={odysseypage}
                                >
                                    {t("Set a new password")}
                                </Button>{" "}
                                {
                                    displayEnrollButtonForTenant(tenant) ?
                                    <Button
                                        id="common-btn"
                                        variant="primary"
                                        type="button"
                                        style={{ padding: "12px", marginTop:"10px", display: "block"  }}
                                        onClick={enrolAgentPage}
                                    >
                                        {t("Enroll My Agency")}
                                    </Button> : null
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                    )
                }

                {
                    view === 'resetPasswordScreen' && (
                        <Form onSubmit={resetPassword} >
                        <Row className="justify-content-center">
                            <Col xs="12" sm="12" md="8" lg="6" xl="5">
                                <div className="login-page-card">
                                    {/*<img alt="" src={`https://images.weserv.nl/?url=${logo}`}/>*/}
                                    <SecureImage src={`${logo}`} />
                                    <span className="login-card-title">
                                        {t("Reset Password")}
                                    </span>
                                    {
                                        isPasswordExpired ? <p>
                                            Your password has expired. Please reset your password.
                                        </p> : 
                                    <span className="login-card-subtitle">Hi, your current password does not comply with our new password requirements. Please reset your password.</span>
                                    }
                                    <div className="login-card-line-breaker"> </div>
                                   
                                    <Button
                                        id="common-btn"
                                        variant="primary"
                                        type="submit"
                                        style={{ padding: "12px" }}
                                        disabled={loading}
                                    >
                                        {t("Reset Password")}
                                    </Button>{" "}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    )
                }

                {
                    view === 'emailUpdateScreen' && (
                        <Form onSubmit={addEmailOnCognito} >
                        <Row className="justify-content-center">
                            <Col xs="12" sm="12" md="8" lg="6" xl="5">
                                <div className="login-page-card">
                                    {/*<img alt="" src={`https://images.weserv.nl/?url=${logo}`}/>*/}
                                    <SecureImage src={`${logo}`} />
                                    <span className="login-card-title">
                                        {t("Add Email")}
                                    </span>
                                    
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="dashboard-group-label">
                                            {t("Email")}
                                        </Form.Label>
                                        <Form.Control
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            value={newEmail}
                                            onChange={(e) => setNewEmail(e.currentTarget.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <Button
                                        id="common-btn"
                                        variant="primary"
                                        type="submit"
                                        style={{ padding: "12px" }}
                                        disabled={loading}
                                    >
                                        {t("Save")}
                                    </Button>{" "}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    )
                }
            </div>
            </LoadingOverlay>
            <Modal size="lg" show={showModal} className="add-mortage-modal" onHide={(e)=>{setShowModal(true)}}>
		        <Modal.Header id="add-mortage-modal-header" >
			        <Modal.Title> Suspension or Debarment Attestation </Modal.Title>
		        </Modal.Header>
		        <Modal.Body className="add-mortage-modal-body" style={{ alignContent:"center", fontSize: "20px" }} >
                    <p>  Agents are required to notify the insurer should they be suspended or debarred from doing business with the federal government</p>
                    
                    <p>    Click <a href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/suspension-debarment-faq" target='_blank' rel="noreferrer">here</a> for information about Suspension or Debarment</p>
                    
                    <Row>
                        <Col>
                            <Button variant="light" size="lg" onClick={(e)=>handleDebard()}>
                                <Row>
                                    <Col sm={2}>
                                        <AiOutlineExclamationCircle size={26} style={{ marginTop:"50px" , cursor: "pointer", color:"red"}}/>
                                    </Col>
                                    <Col >
                                        I am currently debarred or suspended from doing business with the Federal Government
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                        <Col>
                            <Button variant="light" size="lg" onClick={(e)=>handleApproved(e)}>
                            <Row>
                                    <Col sm={2} style={{align: "center"}}>
                                        <AiOutlineCheckCircle size={26} style={{ marginTop:"50px" , cursor: "pointer", color:"green"}}/>
                                    </Col>
                                    <Col >
                                        I am in good standing.I am not suspended or debarred from doing business with the Federal Government
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
		    </Modal>
            {/* access denied modal */}
            <Modal size="lg" show={showDebardModal} className="add-mortage-modal" onHide={(e)=>{
                setShowDebardModal(false)
                navigate('/login');

            }}>
		        <Modal.Header id="add-mortage-modal-header" closeButton>
			        <Modal.Title> Access Denied </Modal.Title>
		        </Modal.Header>
		        <Modal.Body className="add-mortage-modal-body" style={{ alignContent:"center", fontSize: "20px" }} >
                    <p>  Agents who are suspended or debarred from doing business with the federal government cannot participate in the National Flood Insurance Program.</p>
                    
                    <p>    Click <a href="https://www.gsa.gov/policy-regulations/policy/acquisition-policy/office-of-acquisition-policy/gsa-acq-policy-integrity-workforce/suspension-debarment-and-agency-protests/suspension-debarment-faq" target='_blank' rel="noreferrer">here</a> for information about Suspension or Debarment</p>
                    
                    
                </Modal.Body>
		    </Modal>

        </>
    );
};

export default Login;