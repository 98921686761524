import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { recentPolicyClaimList } from "../../../services/dashboardService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

const NewClaims = () => {
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [RecentPolicyClaimListData, setRecentPolicyClaimListData] = useState({});
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    pageLength: 10,
  });
  useEffect(() => {
   
    getRecentPolicyClaimListData();
    
  }, [filter.page, filter.pageLength]);
  const getRecentPolicyClaimListData = async () => {
    setLoading(true);
    await dispatch(recentPolicyClaimList(filter)).then(
      (res) => {
        setRecentPolicyClaimListData(res.data.data);
        setTotal(res.data.total)
      }
    );
    setLoading(false);
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  }

  const columns = [
    {
      dataField: "Claim_No",
      text: "CLAIM INFO",
      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/"}>
          {value}
        </Link>
      ),
    },
    {
      dataField: "Policy_No",
      text: "POLICY NO",
    },
    {
      dataField: "Date_Of_Loss",
      text: "Date Of Loss",
    },
  ];

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    showTotal: true,
    totalSize: total,
    pageStartIndex: 1,
    // alwaysShowAllBtns:true,
    paginationTotalRenderer: renderShowsTotal,
  };


  return (
    <Fragment>
      <h4 className="dashboard-cards-table-title">Dashboard</h4>
      <div className="dashboard-tab-header-fill">
        <span>New Claims</span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <LoadingOverlay>
           <Loader loading={loading} />
          <BootstrapTable
            wrapperClasses="dashboard-table-main"
            bootstrap4
            keyField="PolicyNo"
            data={RecentPolicyClaimListData.length > 0 ? RecentPolicyClaimListData : []}
            columns={columns}
            hover
            remote
            bordered={false}
            pagination={paginationFactory(options)}
            onTableChange={onTableChange}
          />
          </LoadingOverlay>
        </div>
      </div>
    </Fragment>
  );
};

export default NewClaims;
