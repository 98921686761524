import { Box, Card, Collapse } from "@chakra-ui/react";
import { useQuote } from "../../CommCreateQuote";
import GoogleMaps from "../../../create-star-quote/utils/GoogleMaps";
import { CardSelection } from "./CardSelection";
import { BuissnessEstablisments } from "./BuissnessEstablisments";
import HotelQuestionaireCard from "./HotelQuestionaireCard";
import ConvenienceStoreQuestionaireCard from "./GasStationQuestionaire";

export const Policy = () => {
  const { location, selectedCard } = useQuote();

  return (
    <>
      <CardSelection />
      <Collapse animateOpacity in={selectedCard === "Hotel"} unmountOnExit>
        <Box mt="4px" ml={"3px"}>
          <HotelQuestionaireCard />
        </Box>
      </Collapse>
      <Collapse
        animateOpacity
        in={selectedCard === "ConvenienceStore"}
        unmountOnExit
      >
        <Box mt="4px" ml={"3px"}>
          <ConvenienceStoreQuestionaireCard />
        </Box>
      </Collapse>
      <Card
        borderRadius={"2xl"}
        boxShadow={"0 0 6px rgba(0, 0, 0, 0.2)"}
        bg={"white"}
        px={4}
        py={4}
      >
        <Box borderRadius={"xl"} overflow={"hidden"}>
          <GoogleMaps
            lat={Number(location.latitude) || 0}
            lng={Number(location.longitude) || 0}
          />
        </Box>
      </Card>
      {/* <EntityTypeSelection /> */}
      <Box borderTop={"1px solid"} borderColor={"gray.200"} mt={6} mx={2}>
        <BuissnessEstablisments />
        <Box
          borderTop={"1px solid"}
          borderColor={"gray.200"}
          mt={8}
          mx={1}
        ></Box>
      </Box>
    </>
  );
};
