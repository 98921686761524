import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";

function FaqTab() {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>FAQs</span>
      </div>
      <div className="custom-tab-body">
        <div className="faq-tab-accordion">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  What is a Payment Gateway?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  Do I need to pay to Avatar even when there is no transaction
                  going on in my business?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  What payment platforms does Avatar support?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  What platforms does Avatar payment support?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  Is there any setup fee or annual fee that I need to pay
                  regularly?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <div className="faq-tab-accordion-header">
                  <span></span>
                  Is there any setup fee or annual fee that I need to pay
                  regularly?
                </div>
              </Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rhoncus
                fames odio in lectus at ac hendrerit etiam. Volutpat vitae
                venenatis suspendisse tortor consequat. Diam dui dolor aliquet
                metus magna dictum ornare sed arcu. Consectetur dui mi mauris et
                ut fermentum suscipit massa. Feugiat etiam blandit nibh sit
                ultrices. Non sed lorem non eu velit. At eu risus fermentum
                enim, et, duis condimentum purus. A venenatis vitae magna
                aliquet. Fames in sit elit mauris, eget suscipit at ut. Sit
                consectetur at fringilla urna non lacinia volutpat. Eleifend est
                nec semper eu hendrerit arcu.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </Fragment>
  );
}

export default FaqTab;
