import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Flex, Card, CardBody, Text } from "@chakra-ui/react";
import { toast } from "react-toastify";

import {
  propertyDataAutocompleteStore,
  getAgentAuthorizeState,
} from "../../../services/floodService";
import { EncryptOrDecrypt } from "../../../services/commonService";
import { useAutoZipSelection } from "../create-star-quote/utils/useAutoZipDetection";
import ChakraLoader from "../create-star-quote/utils/ChakraLoader";
import LocationSelectionModal from "./LocationSelectionModal";
import SecureImage from "../../common/SecureImage";
import ManualEntryForm from "./ManualEntryForm";
import AutocompleteForm from "./AutoCompleteForm";
import { getArticle, getCompanyName } from "./utility";

const initialPropertyData = {
  street_number: "",
  street_name: "",
  city: "",
  CountyName: "",
  PostalCode: "",
  country: "US",
  latitude: null,
  longitude: null,
  value: "",
  autocomplete: "",
  state: "",
  s_PostalCodeSuffix: "",
  apt_Number: "",
  stateCode: "",
};

export default function QuoteStartLandingPage() {
  const { productID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { coreSystem, common } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [manualEntry, setManualEntry] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [zipData, setZipData] = useState({});
  const [selectedPropertyData, setSelectedPropertyData] =
    useState(initialPropertyData);

  const userData = useMemo(
    () => EncryptOrDecrypt(localStorage.getItem("user"), "D"),
    []
  );
  const AccessRights = userData.accessRights?.levelName || "";

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    selectedPropertyData.PostalCode,
    manualEntry
  );

  useEffect(() => {
    if (showModal) {
      setOpenModal(showModal);
    }
  }, [showModal]);

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
  }, [selectedLocation]);

  const onAutocompleteSubmit = async () => {
    try {
      setLoading(true);
      await handlePropertyDataFetch();
      await agentAuthorizeState();
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(t("An error occurred. Please try again."), {
        position: "top-center",
        toastId: "errorOccurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const agentAuthorizeState = async () => {
    const userAccess = AccessRights;
    const params = {
      userPersonInfoFK: userData.n_PersonInfoId_FK,
      product: coreSystem?.applicationData?.n_ProductId_FK || "1",
      state: selectedPropertyData?.state,
    };

    if (userAccess !== "EMPLOYEE") {
      setLoading(true);
      const res = await dispatch(getAgentAuthorizeState(params));
      setLoading(false);

      if (res.userAuthorizedState === false) {
        toast.error(t("State Not Authorized To Agent!"), {
          position: "top-center",
          toastId: "stateNotAuthorized",
        });
      } else if (res.userAuthorizedState === true) {
        navigate(`/quote/${productID}/create`);
      }
    } else {
      navigate(`/quote/${productID}/create`);
    }
  };

  const handlePropertyDataFetch = async () => {
    await dispatch(propertyDataAutocompleteStore(selectedPropertyData));
  };

  const toggleManualEntry = () => {
    setManualEntry(!manualEntry);
    setSelectedPropertyData(initialPropertyData);
  };

  const renderLogo = () => {
    const logoUrl = typeof common?.getLogo === "string" ? common.getLogo : "";
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        maxW="250px"
        maxH="200px"
        px={2}
      >
        <SecureImage alt="logo" src={isLoading ? "" : logoUrl} />
      </Flex>
    );
  };

  return (
    <Box>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <ChakraLoader isLoading={loading || isLoading}>
        <Flex
          h={{ md: "80vh", lg: "70vh" }}
          w="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Card
            borderRadius="2xl"
            width="80%"
            h={manualEntry ? "70%" : "60%"}
            style={{ boxShadow: "0 0 30px var(--chakra-colors-primary-5 0)" }}
            border="1px solid"
            borderColor="primary.50"
          >
            <CardBody p={4}>
              <Flex
                gap={2}
                h="100%"
                border="1px solid"
                borderRadius="xl"
                borderColor="primary.50"
                flexDir="column"
                justifyContent="center"
                alignItems="center"
              >
                <Box pb={4}>{renderLogo()}</Box>

                <Flex
                  flexDir="row"
                  pb={2}
                  gap={2}
                  fontSize="3xl"
                  textAlign="center"
                  alignItems="center"
                >
                  {t("Create")} {t(getArticle(getCompanyName()))}
                  <Text as="span" color="primary.500">
                    {getCompanyName() || "StarLight"}
                  </Text>
                  {t("Insurance Quote")}
                </Flex>

                {manualEntry ? (
                  <ManualEntryForm
                    selectedPropertyData={selectedPropertyData}
                    setSelectedPropertyData={setSelectedPropertyData}
                    zipData={zipData}
                    onManualSearch={onAutocompleteSubmit}
                    toggleManualEntry={toggleManualEntry}
                  />
                ) : (
                  <AutocompleteForm
                    onAutocompleteSubmit={onAutocompleteSubmit}
                    setSelectedPropertyData={setSelectedPropertyData}
                    toggleManualEntry={toggleManualEntry}
                  />
                )}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
      </ChakraLoader>
    </Box>
  );
}
