import React, { useState } from "react";
import "./AccountInfo.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form ,Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MaskedFormControl from 'react-bootstrap-maskedinput';
import "react-overlay-loader/styles.css";
import { saveQuoteData , getEditEmptyInstantQuoteData } from "../../../../services/instaQuote";
import { connect,useDispatch } from 'react-redux';
import {useLocation} from "react-router-dom";

import { LoadingOverlay, Loader } from "react-overlay-loader";

const AccountInfo = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [loadingStatus, setLoadingStatus] = useState(false);

    const handleOnclick123 = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    const handleOnclick = () => {
        setLoadingStatus(true);
        let actions = dispatch(saveQuoteData(props.quoteData)).then((res) => {
            const add = res.data.transactionPK;
            const allData = props.quoteData;
            dispatch(getEditEmptyInstantQuoteData(allData));
            setLoadingStatus(false);
            window.location.href = "/quote/editApplication/13/" + add;
        })
    }

    return(
        <LoadingOverlay >
        <Loader loading={loadingStatus}/>
        {
        <section className="account-info-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="account-info-form-section">
                    <Row className="justify-content-center">
                        <Col xs="12" className="account-info-form-main">
                            <div className="account-info-heading">
                                <h4>{t('Account Information')}</h4>
                                <p>{t('Please confirm all the provided information is correct:')}</p>
                            </div>
                            <Row className="justify-content-center">
                                <Col xs="12">
                                    <div className="account-pi-title">
                                        <h6>{t('Personal Information')}</h6>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                    <Row className="account-pi-main">
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <Form.Group className="account-form-group"  controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('First Name')}
                                                </Form.Label>
                                                <Form.Control
                                                    className="myform-input"
                                                    type="text"
                                                    placeholder={t("First name")}
                                                    name="s_FirstName"
                                                    value={props.quoteData.personInfo.s_FirstName}
                                                    onChange = {props.handleChange}
                                                    title="personInfo"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <Form.Group className="account-form-group"  controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('Last Name')}
                                                </Form.Label>
                                                <Form.Control
                                                    className="myform-input"
                                                    type="text"
                                                    placeholder={t("First name")}
                                                    name="s_LastOrganizationName"
                                                    title="personInfo"
                                                    value={props.quoteData.personInfo.s_LastOrganizationName}
                                                    onChange = {props.handleChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="12">
                                            <Form.Group className="account-form-group" controlId="formBasicEmail">
                                                <Form.Label className="myform-label"></Form.Label>
                                                <Form.Control
                                                    className="myform-input"
                                                    type="text"
                                                    value={props.quoteData.personInfo.s_FullLegalName}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <Form.Group className="account-form-group" controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('Phone Number')}
                                                </Form.Label>
                                                <MaskedFormControl 
                                                    type='text'
                                                    name='s_CommValue'
                                                    mask="(111)111-1111"
                                                    placeholder=""
                                                    title="personPhoneContact"
                                                    className="myform-input"
                                                    id='contact-phone-number' 
                                                    value={props.quoteData.personPhoneContact.s_CommValue}
                                                />                                                    
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <Form.Group className="account-form-group" controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('Email')}
                                                </Form.Label>
                                                <Form.Control 
                                                    className="myform-input"
                                                    type="email"
                                                    placeholder={t("Enter email address")}
                                                    value={props.quoteData.personEmailContact.s_CommValue}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="12">
                                            <Form.Group className="account-form-group" controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('Property Address')}
                                                </Form.Label>
                                                <Form.Control 
                                                    className="myform-input"
                                                    type="email"
                                                    placeholder={t("Enter property address")}
                                                    value={props.quoteData.personAddr.s_AddressLine1+','+props.quoteData.personAddr.s_CityName+','+props.quoteData.personAddr.s_CountyName+','+props.quoteData.personAddr.s_StateName+','+props.quoteData.personAddr.s_PostalCode}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="12">
                                            <Form.Group className="account-form-group" controlId="formBasicEmail">
                                                <Form.Label className="myform-label">
                                                    {t('Mailing Address')}
                                                </Form.Label>
                                                <Form.Control 
                                                    className="myform-input"
                                                    type="email"
                                                    placeholder={t("Enter Mail address")}
                                                    value={props.quoteData.mapData.isSameMailingAddress == 'NO' ? props.quoteData.mailingAddr.s_AddressLine1+','+props.quoteData.mailingAddr.s_CityName+','+props.quoteData.mailingAddr.s_CountyName+','+props.quoteData.mailingAddr.s_StateName+','+props.quoteData.mailingAddr.s_PostalCode : props.quoteData.personAddr.s_AddressLine1+','+props.quoteData.personAddr.s_CityName+','+props.quoteData.personAddr.s_CountyName+','+props.quoteData.personAddr.s_StateName+','+props.quoteData.personAddr.s_PostalCode}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs="12">
                                            <div className="account-pi-title">
                                                <h6>{t('Property Interests (Mortgagees)')}</h6>
                                            </div>
                                        </Col>
                                        <Row>
                                            <Col xs="12">
                                                <Row className="account-pi-main">
                                                    {props.inputFields.map((field, index) => (
                                                    (field.type === "First Mortgagee" ||
                                                    field.type === "Second Mortgagee" ||
                                                    field.type === "Third Mortgagee") && (
                                                        <Col xs="12" sm="12" md="6" lg="6" xl="12">
                                                            <Form.Group
                                                                className="account-form-group"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder={t('Mortgages')}
                                                                    value={field.name + ' - ' + field.mailAddress1 + ',' +field.city + ',' + field.state + ',' + field.zipCode}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    )))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs="12">
                                            <div className="account-pi-title">
                                                <h6>{t('Property Interests (Additional Interests)')}</h6>
                                            </div>
                                        </Col>
                                        <Row>
                                            <Col xs="12">
                                                <Row className="account-pi-main">
                                                    {props.inputFields.map((field, index) => (
                                                    (field.type === "Additional Insured" ||
                                                    field.type === "Additional Interest") && (
                                                        <Col xs="12" sm="12" md="6" lg="6" xl="12">
                                                            <Form.Group 
                                                                className="account-form-group"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder={t('Additional Interests')}
                                                                    value={field.name + ' - ' + field.mailAddress1 + ',' +field.city + ',' + field.state + ',' + field.zipCode}
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    )))}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs="12">
                                            <div className="account-pi-title">
                                                <h6>{t('Property Coverages')}</h6>
                                            </div>
                                        </Col>
                                        <Row>
                                            <Col xs="12">
                                                <Row className="account-pi-main">
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} A
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage A"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_coverageA)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} B
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage B"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_coverageB)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} C
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage C"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_coverageC)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} D
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage D"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_coverageD)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} E
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage E"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_coverageE)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            className="account-form-group"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label className="myform-label">
                                                                {t('Coverage')} F
                                                            </Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder="Coverage F"
                                                                value={'$'+(Math.round(props.quoteData.propertyCoverageInfo.n_CoverageF)).toLocaleString()}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="flood-bts-div">
                        <Button
                            id="primary-grey-btn-flood"
                            variant="outline-dark"
                            onClick={() => handleOnclick123('10')}
                        >
                            {t('Previous')}
                        </Button>
                        <Button
                            id="primary-colored-btn"
                            variant="primary"
                            onClick={() => handleOnclick('12')}
                        >
                            {t('Next')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </section>
        }
        </LoadingOverlay>
    )
}

function mapStateToProps(state) {
    return {
        quoteData : state.common.emptyInstantQuoteData,
        inputFields : state.common.emptyInstantQuoteData.mortgageeData.mortgageeData,
    };
}

export default connect(mapStateToProps)(AccountInfo)