import React,{useEffect,useState} from "react";
import { Row, Col, Card,Alert} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { currencyFormat } from "../../../../../services/commonService";
import CheckIcon from "../../../../../assets/check.svg";
import "../RenewPayment/renew-payment.scss";
import { useDispatch } from 'react-redux';
import { getFemaUpdates} from "../../../../../services/dashboardService";

const CvgOptions = (props) => {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const { selectedCvg, setSelectedCvg, cvgOptions } = props;
	const [femaUpdateMsg, setFemaUpdateMsg] = useState({ __html: "" });
    const [isHiatus, setIsHiatus] = useState(false);
	
	useEffect(() => {
		checkFemaUpdates();
	  }, [localStorage.getItem('lang') || 'en']);
	  
	  const checkFemaUpdates = async () => {
		await dispatch(getFemaUpdates()).then((res) => {
			setIsHiatus(res.data.data.isHiatus);
			if(res.data.data.isHiatus == true){
				setFemaUpdateMsg({ __html: res.data.data.hiatusSignAndPayMsg });
			}
		})
	  }

    return (
        <section className="pay-now-section">
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pay-now-form-section">
                <h2 className="p-2 ms-3 mt-2">{t('Renew Payment')}</h2>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="alert_head">{t('Please review and enter the following information to continue')}</div>
                    </Col>
                </Row>
                <div className="scroll">
                    <Row className="m-3">
						{(isHiatus == true) &&
				          <>
				            <div>
				              <Alert variant="warning">
				                <div dangerouslySetInnerHTML={femaUpdateMsg}></div>
				              </Alert>
				            </div>
				          </>
				        }
                        {
                            cvgOptions && cvgOptions.map((option, index) => {
                                return (
                                    <Col xs="12" sm="12" md="12" lg="6" xl="6" key={index} className="mt-3">
                                        <Card
                                            className={selectedCvg === option.renewalCvgType ? 'coverage_css' : ''}
                                            onClick={() => setSelectedCvg(option.renewalCvgType)}
                                        >
                                            <Card.Header>
                                                <div className="">
                                                    <h6>{
                                                        (selectedCvg === option.renewalCvgType) && (
                                                            <img
                                                                src={CheckIcon}
                                                                alt='Selected'
                                                            />
                                                        )
                                                    } {t(option.name || "")}</h6>
                                                    <div className="coverage_head">
                                                        <h5>{t(option.title || "")}</h5>
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body>
                                                <div>
                                                    {t(option.description || "")}
                                                    <br />
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col md='9'><h5>{t('Premium')}:</h5></Col>
                                                            <Col md='3' className="d-flex justify-content-end"><h5>{currencyFormat(option.premium || 0)}</h5></Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md='9'>{t('Building')}:</Col>
                                                            <Col md='3' className="d-flex justify-content-end">{currencyFormat(option.buildingCoverage || 0)}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md='9'>{t('Contents')}:</Col>
                                                            <Col md='3' className="d-flex justify-content-end">{currencyFormat(option.contentCoverage || 0)}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md='4'>{t('Building/Contents Deductibles')}:</Col>
                                                            <Col md='8' className="d-flex justify-content-end">{option.buildingDeductible || 0}/{option.contentDeductible || 0}</Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </div>
            </Col>
        </section>
    );
};

export default CvgOptions;
