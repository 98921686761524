import React from 'react'
import Mortgagees from '../AdditionalInformation/Mortgagees'
import AdditionalInsured from '../AdditionalInformation/AdditionalInsured'

const StepAdditionalInformation = () => {
  return (
    <div className="p-3">
      <div
        style={{
          border: "1px solid #64D6CD",
          padding: "2rem 16px 32px 16px",
          borderRadius: "16px",
        }}
      >
        {/* <h2
          style={{
            marginBottom: "16px",
            textAlign: "center",
            paddingBottom: "2.5rem",
          }}
        >
          Additional Information
        </h2> */}
        <Mortgagees />
        <AdditionalInsured />
      </div>
    </div>
  )
}

export default StepAdditionalInformation
