import React from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import {
  buildingInterpetionsFields,
  coveredFerilsFields,
  restorationFactorFields,
} from "../../commercial-quote/data/commercialDD";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const COVERAGESPERCENTAGE = [100, 90, 80];

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

const SelectField = ({ options, ...props }) => (
  <ChakraSelect w="30%" {...props}>
    <option value=""></option>
    {options.map((field, i) => (
      <option key={i} value={field}>
        {field}
      </option>
    ))}
  </ChakraSelect>
);

export const BlanketBasic = () => {
  const { t } = useTranslation();
  const { blanketDisplayCoverages, setBlanketDisplayCoverages, selectedCard } =
    useQuote();

  const updateCoverageDetails = (name, value) => {
    setBlanketDisplayCoverages({
      ...blanketDisplayCoverages,
      basicCoverages: {
        ...blanketDisplayCoverages.basicCoverages,
        [name]: value,
      },
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 650 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        <FormField label={t("Building Replacement Cost")}>
          <CustomCurrencyInput
            value={blanketDisplayCoverages?.basicCoverages?.buildingRCV}
            onValueChange={(value) => {
              updateCoverageDetails("buildingRCV", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Building Coverage Selection (ITV)")}>
          <ChakraSelect
            w={"30%"}
            value={
              blanketDisplayCoverages?.basicCoverages?.buildingCVPercent || ""
            }
            onChange={(e) => {
              updateCoverageDetails("buildingCVPercent", e.target.value);
            }}
          >
            <option value=""></option>
            {COVERAGESPERCENTAGE?.map((v) => (
              <option key={v} value={v}>
                {v}%
              </option>
            ))}
          </ChakraSelect>
        </FormField>

        <FormField label={t("Business Personal Property Coverage")}>
          <CustomCurrencyInput
            value={
              blanketDisplayCoverages?.basicCoverages
                ?.buildingPersonalPropertyCVG
            }
            onValueChange={(value) => {
              updateCoverageDetails("buildingPersonalPropertyCVG", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Business Interruption Extra Expense")}>
          <CustomCurrencyInput
            value={
              blanketDisplayCoverages?.basicCoverages?.buildingExtraExpenses
            }
            onValueChange={(value) => {
              updateCoverageDetails("buildingExtraExpenses", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Business Interruption Waiting Period")}>
          <SelectField
            value={
              blanketDisplayCoverages?.basicCoverages?.buissnessWaitingPeriod ||
              ""
            }
            onChange={(e) => {
              updateCoverageDetails("buissnessWaitingPeriod", e.target.value);
            }}
            options={buildingInterpetionsFields}
          />
        </FormField>

        <FormField label={t("Restoration Factor")}>
          <SelectField
            value={
              blanketDisplayCoverages?.basicCoverages?.restorationFactor || ""
            }
            onChange={(e) => {
              updateCoverageDetails("restorationFactor", e.target.value);
            }}
            options={restorationFactorFields}
          />
        </FormField>
        <FormField label={t("Covered Perils")}>
          <SelectField
            value={blanketDisplayCoverages?.basicCoverages?.coveredPerils || ""}
            onChange={(e) => {
              updateCoverageDetails("coveredPerils", e.target.value);
            }}
            options={coveredFerilsFields}
          />
        </FormField>
      </VStack>
    </Box>
  );
};
