import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
} from "@chakra-ui/react";

const CustomFormControl = ({
  label,
  children,
  icon,
  hasQuestionMark = false,
}) => (
  <FormControl>
    <FormLabel>
      <Flex gap={2} alignItems={"center"}>
        {label}
        {hasQuestionMark ? "?" : ":"}
        {icon ? icon : ""}
      </Flex>
    </FormLabel>
    {children}
  </FormControl>
);

const LabeledSwitch = ({
  leftLabel,
  rightLabel,
  isChecked,
  onChange,
  ...props
}) => {
  return (
    <Flex ml={"2px"} gap={2.5} alignItems={"center"}>
      <FormLabel fontWeight={400} pt={"6px"} mr={0}>
        {leftLabel}
      </FormLabel>
      <Switch
        colorScheme="blue"
        size="md"
        isChecked={isChecked}
        onChange={onChange}
        {...props}
      />
      <FormLabel fontWeight={400} pt={"6px"}>
        {rightLabel}
      </FormLabel>
    </Flex>
  );
};

const CustomInput = ({
  type = "text",
  value,
  onChange,
  isDisabled = false,
  ...props
}) => (
  <Input
    className="inputSelectBoxes"
    type={type}
    value={value}
    onChange={onChange}
    isDisabled={isDisabled}
    cursor={isDisabled ? "not-allowed" : "auto"}
    _disabled={{ bg: "neutral.50" }}
    _hover={{ bg: "gray.50" }}
    {...props}
  />
);

const CustomSelect = ({ value, onChange, options, isDisabled, ...props }) => (
  <Select
    className="inputSelectBoxes"
    value={value}
    onChange={onChange}
    cursor={isDisabled ? "not-allowed" : "auto"}
    _disabled={{ bg: "neutral.50" }}
    _hover={{ bg: "gray.50" }}
    {...props}
  >
    <option></option>
    {options.map((option, index) => (
      <option key={index} value={option}>
        {option}
      </option>
    ))}
  </Select>
);

const ChakraSelect = ({
  value,
  onChange,
  children,
  isDisabled = false,
  ...props
}) => (
  <Select
    className="inputSelectBoxes"
    value={value}
    onChange={onChange}
    isDisabled={isDisabled}
    cursor={isDisabled ? "not-allowed" : "auto"}
    _disabled={{ bg: "neutral.50" }}
    _hover={{ bg: "gray.50" }}
    {...props}
  >
    {children}
  </Select>
);

export {
  CustomFormControl,
  LabeledSwitch,
  CustomInput,
  CustomSelect,
  ChakraSelect,
};
