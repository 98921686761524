import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import Switch from "react-switch";
import { Box, Flex, Spacer, Stack, Switch } from "@chakra-ui/react";

export const checkedValues = [false,false,false]
const Question = () => {
  const { t } = useTranslation();
  const [isLenderChecked, setIsLenderChecked] = useState(false);
  const handleLenderChange = () => {
    setIsLenderChecked(!isLenderChecked);
    checkedValues[0]=!isLenderChecked
  };

  const [isSixtyDaysChecked, setSixtyDaysChecked] = useState(false);
  const handleSixtyDaysChange = () => {
    setSixtyDaysChecked(!isSixtyDaysChecked);
    checkedValues[1]=!isSixtyDaysChecked
  };

  const [isCarrierChecked, setCarrierChecked] = useState(false);
  const handleCarrierChange = () => {
    setCarrierChecked(!isCarrierChecked);
    checkedValues[2]=!isCarrierChecked
  };

  useEffect(() => {
    checkedValues[0]=isLenderChecked;
    checkedValues[1]=isSixtyDaysChecked;
    checkedValues[2]=isCarrierChecked;
  },[isLenderChecked,isSixtyDaysChecked,isCarrierChecked])
  return (
    <>
      <Box>
        <Flex>
          <Box>
            <Form.Label>
              {t("Is this policy supporting the closing of a loan with the lender?")}
            </Form.Label>
          </Box>
          <Spacer />
          <Box>
            <Stack direction="horizontal">
              <Switch
                size={"lg"}
                checked={isLenderChecked}
                // defaultChecked={!isLenderChecked}
                onChange={handleLenderChange}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#00CBF7"
                height={24}
                width={48}
              />
            </Stack>
          </Box>
        </Flex>
        <Flex>
        <Box>
          <Form.Label>
            {t("Has the insured building being located in an area which has been reclassified as a Special Flood Hazard Area within the previous 60 days?")}
          </Form.Label>
        </Box>
        <Spacer />
        <Box >
          <Stack direction="horizontal">
            <Switch
              size={"lg"}
              checked={isSixtyDaysChecked}
              // defaultChecked={!isSixtyDaysChecked}
              onChange={handleSixtyDaysChange}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#00CBF7"
              height={24}
              width={48}
            />
          </Stack>
        </Box>
        </Flex>
        <Flex>
        <Box >
          <Form.Label>
            {t("Is the Policy Replacing a Flood policy With Another Carrier?")}
          </Form.Label>
        </Box>
        <Spacer />
        <Box>
          <Stack direction="horizontal">
            <Switch
              size={"lg"}
              checked={isCarrierChecked}
              // defaultChecked={!isCarrierChecked}
              onChange={handleCarrierChange}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#00CBF7"
              height={24}
              width={48}
            />
          </Stack>
        </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Question;
