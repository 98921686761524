import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Moment from 'moment';
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useNavigate } from "react-router-dom";
import { getActivityListData,getChangeMultipleMessageUR,getChangeMessageStatus } from "../../../services/activityService.js";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../../services/commonService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./inbox.scss";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { getAgentTasks } from  "../../../services/dashboardService";
import AgentTasks from "../agentTasks/AgentTasks.js";

const Inbox = (props) => {
  var d = new Date();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const FilterCriteriaValue = props.sourceCode != undefined ? props.sourceCode : '';
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState([]);
  const [total, setTotal] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [ScheduledFrom, setScheduledFrom] = useState(d.setMonth(d.getMonth() - 6));
  const [ScheduledTo, setScheduledTo] = useState(new Date());
  const user = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [BaseParentid, setBaseParentid] = useState("");
  const [messageCode, setMessageCode] = useState("");
  const [isFilterHidden, setIsFilterHidden] = useState(false);
  const [isStateSet, setIsStateSet] = useState(false);
  
  
  const [filter, setFilter] = useState({
      FilterCriteria : "ALL",
      FilterCriteriaValue :FilterCriteriaValue,
      FilterByFlag : "",
      FilterByFlagStatus : "",
      Display: "",
      ScheduledFrom: ScheduledFrom,
      ScheduledTo: ScheduledTo,
      ActivityUserID: user.Admin_ID,
      ActivityPersonPK: user.n_PersonInfoId_FK,
      sourceCode : "",
      sourceType : "",
      page: 1,
      pageLength: 10,
  });

  useEffect(() => {
    // if from header button
    var temp = false, flag = "";
    if (props.from && props.from === 'POLICY_HEADER_BUTTON') {
      temp = true;
      flag = "SYSTEM";
    }
    const tempArray = filter; // creating copy of an array
    tempArray["FilterByFlag"] = flag;
    setFilter(tempArray); // set copied array
    setIsFilterHidden(temp);
    searchActivityList();
    setIsStateSet(true);
  }, []);

  useEffect(() => {
    // prevent multiple calling
    if (isStateSet === true) {
      searchActivityList();
    }
  }, [filter.page,filter.pageLength])

  const searchActivityList = async () => {
    const tempArray = filter;
    tempArray['ScheduledFrom'] = Moment(ScheduledFrom).format("YYYY-MM-DD");
    tempArray['ScheduledTo'] = Moment(ScheduledTo).format("YYYY-MM-DD");
    setFilter(tempArray);
    setLoading(true);
    await dispatch(getActivityListData(filter)).then((res) => { 
       setTableData(res.data.data);
       setTableTotal(res.data.total);
       setLoading(false);
    });
  };

  const renderFormatter1 = (value, row) => {
    return(
      <div style={{fontSize: "13px" }}>{value}</div>
    )
  }

  const renderFormatter = (value, row) => {
    var text = value;
    const myArray = text.replace("(", " (");
    if (FilterCriteriaValue === '') {
      return (
        <Link
          className="management-reports-link"
          to={`/active-log/${row.MessageInfo_PK}`}
        >
          {myArray}
        </Link>
      )
    } else {
      return (
        <a
          className="management-reports-link"
          style={{ cursor: 'pointer' }}
          onClick={() => props.setValuesCondition('DETAIL', row.MessageInfo_PK)}
        >
          {myArray}
        </a>
      )
    }
  }

  const columns = [
    {
      dataField: "MessageFrom",
      text: "From",
      formatter: (value, row) => renderFormatter(value, row),
    },
    {
      dataField: "MessageSubject",
      text: "Subject",
      formatter: (value, row) => renderFormatter1(value, row),
    },
    {
      dataField: "MessageSourceType",
      text: "Type",
      formatter: (value, row) => renderFormatter1(value, row),
    },
    /*{
      dataField: "PolicyHolderName",
      text: "Name",
    },*/
    {
      dataField: "MessageSourceCode",
      text: "Source",
      formatter: (value, row) => renderFormatter1(value, row),
    },
    {
      dataField: "MessageCreatedDate",
      text: "Date",
      sort: true,
      formatter: (value, row) => renderFormatter1(value, row),
    },
    {
      dataField: "Scheduled_Date",
      text: "Date Scheduled",
      sort: true,
      formatter: (value, row) => renderFormatter1(value, row),
    },
    {
      dataField: "MessageStatusTask",
      text: "Status",
      sort: true,
      formatter: (value, row) => renderStatusDropdown(value, row),
      /*formatter: (value, row) => (
        <Form.Select
          className="dashboard-group-input"
          id="table-select-options"
          name="FilterByFlag"
          value={row.MessageStatusTask}
          onChange={(e) => statusChangeRow(e, row)}
        >
          <option value={"OPEN"}>Open</option>
          <option value={"PENDING"}>Pending</option>
          <option value={"CLOSED"}>Closed</option>
        </Form.Select>
      ),*/
    },
  ];

  const taskColumns = [
    {
      dataField: "MessageFrom",
      text: "Policy No",
    },
    {
      dataField: "MessageSubject",
      text: "Transaction Type",
    },
    {
      dataField: "MessageSourceType",
      text: "Log Note",
    },
    {
      dataField: "MessageSourceCode",
      text: "Date",
    },
  ];

  const renderStatusDropdown = (cell, row, enumObject, rowIndex) => {
    return (
      <Form.Select
          className="dashboard-group-input"
          id="table-select-options"
          name="FilterByFlag"
          value={row.MessageStatusTask}
          onChange={(e) => statusChangeRow(e, row)}
        >
          <option value={"OPEN"}>Open</option>
          <option value={"PENDING"}>Pending</option>
          <option value={"CLOSED"}>Closed</option>
        </Form.Select>
    );
  };

  const statusChangeRow = (e, row) => {
    setIsStatusModalOpen(!isStatusModalOpen);
    setBaseParentid(row.MessageInfo_PK);
    setMessageCode(e.target.value);
  };

  const updateStatus = async () => {
    //console.log('pritesh in update status function')
    const data = {
      BaseParentid,
      messageCode,
    };
    let actions = await getChangeMessageStatus(data);
    setIsStatusModalOpen(!isStatusModalOpen);
    searchActivityList();
    setSelectedActivity([]);
  };

  const rowStyle = (row, rowIndex) => {
    let style = {};
    if(row){
      if(row.InboxUnreadCount > 0){
        style = {
          fontWeight : '600'
        }
      }else{
        style = {
          fontWeight : '400'
        }
      }
    }
    return style;
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    const tempArray = filter; // creating copy of an array
    tempArray[name] = value;
    setFilter(tempArray); // set copied array
  };

  const onHandleChangeDate = (value, field) => {
    if(field == "ScheduledFrom") {
     setScheduledFrom(value);
    }
    else {
      setScheduledTo(value);
    }
    const tempArray = filter; // creating copy of an array
    tempArray[field] = Moment(value).format('YYYY-MM-DD');
    setFilter(tempArray); // set copied array
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  }

  const handlePageChange = (page, sizePerPage) => {
    setFilter({...filter,page:page,pageLength:sizePerPage});
  };

  const sizePerPageListChange = (page, sizePerPage) => {
    setFilter({ ...filter, pageLength: sizePerPage });
  };

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    showTotal: true,
    onPageChange: handlePageChange,
    totalSize: tableTotal,
    onSizePerPageChange: sizePerPageListChange,
    paginationTotalRenderer: renderShowsTotal,
  };

  const selectRowProp = {
    mode: 'checkbox',
    selected:selectedActivity,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  }
  function onRowSelect(row, isSelected, e){
    if (selectedActivity.indexOf(parseInt(row.MessageInfo_PK)) > -1) {
      selectedActivity.splice(
        selectedActivity.indexOf(parseInt(row.MessageInfo_PK)),
        1
      );
      setIsSelectedAll(true);
    } else {
      selectedActivity.push(parseInt(row.MessageInfo_PK)); // push into the array
    }
    const tempArray = [...selectedActivity]; // creating copy of an array
    setSelectedActivity(tempArray); // setting copied array
  }
  function onSelectAll(isSelected, rows) {
    var tempArray = [];
    // if All select
    if (!isSelectedAll) {
      tableData.forEach((data) => {
        selectedActivity.push(parseInt(data.MessageInfo_PK));
      });

      tempArray = [...selectedActivity];
    }
    setSelectedActivity(tempArray);
    setIsSelectedAll(!isSelectedAll);
  }

  const actionChange = async (e) => {
    if(selectedActivity.length == 0){
      toast.error('Please Select Message From List !');
      return false;
    }
    var flag = e.target.value;
    
    const params = {
      messageFlag: flag,
      selectedActivity,
    };
    let actions = await getChangeMultipleMessageUR(params);
    if (actions != "") {
      searchActivityList();
      setSelectedActivity([]);
    }
  };

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.pageLength,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   paginationShowsTotal: renderShowsTotal,
  //   onPageChange: handlePageChange,
  //   totalSize:tableTotal
  //   //sizePerPageDropDown: renderSizePerPageDropDown
  // };
  return (
    <LoadingOverlay>
    <Loader loading={loading} />
    <div className="inbox-page-section">
      <h4 className="activity-log-title">Communication</h4>
        <div className="card-header-main-title">
            { !isFilterHidden &&
              <AgentTasks setLoading={setLoading}/> 
            }
        </div>
        {/* <div className="card-header-main-title">
          <FaInbox size={21} color={"#fff"} />
          <Button id="common-btn" style={{ marginLeft: "89%", marginBottom:"4px" }} onClick={() => {
            props.sourceCode != undefined ?
              props.viewPart('NEW')
              :
              navigation('/active-log/new-activity')
          }}>Add New</Button>
        </div> */}
      <Row hidden={isFilterHidden}>
        <Col xs="12">
          <div className="card-header-main">
            <div className="card-header-main-title">
              <FaInbox size={21} color={"#fff"} />
              <span className="card-header-main-text">Inbox</span>
            </div>
            <div className="card-header-btns-div">
              <div className="card-header-action" style={{display: "flex"}}>
                <FaEye size={21} color={"#fff"} />
                <span className="card-header-action-title">View Log</span>
              </div>
              {/* <Button
                id="common-outline-btn"
                className="card-header-btn0"
                variant="outline-primary"
                   onClick={()=> 
                    {
                       FilterCriteriaValue != '' ?
                       props.viewPart('NEW')
                    :
                       navigation('/active-log/new-activity')
                    }}
              >
                <FaPlus size={15} /> Add New
              </Button>{" "} */}
            </div>
          </div>
          <div className="card-body-main">
            <Row className="align-items-baseline">
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Filter By Gen
                  </Form.Label>
                  <Form.Select
                     name="FilterByFlag"
                     className="dashboard-group-input"
                     id="select-ym-option"
                     onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>All</option>
                    <option value={"USER"}>User genrated</option>
                    <option value={"SYSTEM"}>System Genrated</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Filter By Status
                  </Form.Label>
                  <Form.Select
                    name="FilterByFlagStatus"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>All</option>
                    <option value={"OPEN"}>OPEN</option>
                    <option value={"PENDING"}>PENDING</option>
                    <option value={"CLOSED"}>CLOSED</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Display
                  </Form.Label>
                  <Form.Select
                    name="Display"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    onChange={(e) => onHandleChange(e)}
                  >
                    <option value={""}>ALL</option>
                    <option value={"U"}>Unread</option>
                    <option value={"R"}>Read </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    Action
                  </Form.Label>
                  <Form.Select
                    name="Action"
                    className="dashboard-group-input"
                    id="select-ym-option"
                    onChange={(e) => actionChange(e)}
                  >
                    <option value={"None"}>None</option>
                    <option value={"U"}>Mark as Unread</option>
                    <option value={"R"}>Mark as Read </option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">
                    From
                  </Form.Label>
                  <DatePicker
                    name="ScheduledFrom"
                    id="dashboard-datepicker"
                    className="dashboard-datepicker-main"
                    selected={ScheduledFrom}
                    onChange={(date) => onHandleChangeDate(date, 'ScheduledFrom')}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="4" lg="3" xl="2">
                <Form.Group
                  className="activity-group-forms"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="dashboard-group-label">To</Form.Label>
                  <DatePicker
                    name="ScheduledTo"
                    id="dashboard-datepicker"
                    className="dashboard-datepicker-main"
                    selected={ScheduledTo}
                    onChange={(date) => onHandleChangeDate(date, 'ScheduledTo')}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" className="activity-filter-btn-div">
                <Button
                  id="common-btn"
                  className="activity-filter-btn"
                  onClick={() => searchActivityList()}
                  variant="primary"
                >
                  <FaFilter size={18} />
                  Filter
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
        <div className="card-header-main-title">
          <Button id="common-btn" style={{ marginLeft: "89%", marginBottom: "4px", marginTop:"1%" }} onClick={() => {
            props.sourceCode != undefined ?
              props.viewPart('NEW')
              :
              navigation('/active-log/new-activity')
          }}>Add New</Button>
        </div>
      <Row>
        <Col xs="12">
          <div
            className="inbox-table"
            id="inbox-table-outer-div"
            style={{ paddingBottom: "20px", overflowX: "scroll" }}
          >
             <BootstrapTable
              wrapperClasses="dashboard-table-main"
              bootstrap4
              remote
              keyField="BaseMessageLink_IdFk"
              data={tableData}
              columns={columns}
              rowStyle = {rowStyle} 
              selectRow={selectRowProp}
              hover
              bordered={false}
              pagination={paginationFactory(options)}
              tabIndexCell
              onTableChange={onTableChange}
            />
            {/**Confirmation Modal - Start*/}
            <Modal
            className="custom-dashboard-modal"
            size="sm"
            show={isStatusModalOpen}
            >
            <Modal.Header className="custom-dashboard-modal-header">
              <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-dashboard-modal-body">
              <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className="text-center">
                  Are you sure you want to change status?
                </Col>
              </Row>
              <Row>  

                <Col xs="12" style={{ padding: 0 }} className="text-center">
                <Button
                    id="common-btn-sm"
                    variant="primary"
                    type="button"
                    size="sm"
                    onClick={() => updateStatus()}
                  >
                <i className="fa fa-check"></i>&nbsp;&nbsp;Yes
                </Button>
                &nbsp;&nbsp;
                  <Button
                    /*id="common-btn"*/
                    variant="danger"
                    type="button"
                    size="sm"
                    onClick={() => setIsStatusModalOpen(!isStatusModalOpen)}
                  >
                    <i className="fa fa-times"></i>&nbsp;&nbsp;No
                  </Button>    
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          {/**Confirmation Modal - End*/}
          </div>
        </Col>
      </Row>
    </div>
    </LoadingOverlay>
      
  );
};

function mapStateToProps(state) {
  return {
    getActivityList: state.common.getActivityList ? state.common.getActivityList : {},
  };
}
export default connect(mapStateToProps)(Inbox);
