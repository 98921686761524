import React from "react";
import { useNavigate , useParams} from "react-router-dom";
import AgencyManagement from "./AgencyManagement";
import ClaimsSystem from "./ClaimsSystem";
import GettingStarted from "./GettingStarted";
import PolicySystem from "./PolicySystem";
import TasksAndRecentWorks from "./TasksAndRecentWorks";
import PolicySystemSection from "./section/PolicySystemSection";

const FindPolicySupport = () => {
    let params = useParams();
    const navigate = useNavigate();
    const backFunction = () => {
        navigate('/help');
    }

    let selectedComponent;
    if(params.id == 1){
        selectedComponent = <GettingStarted />
    } else if(params.id == 2){
        selectedComponent =  <PolicySystemSection /> 
        // <PolicySystem />
    } else if(params.id == 3){
        selectedComponent = <ClaimsSystem />
    } else if(params.id == 4){
        selectedComponent = <AgencyManagement />
    } else if(params.id == 5){
        selectedComponent = <TasksAndRecentWorks />
    }
    return(
        <div className="container-fluid bg-white ">
            <p style={{cursor:"pointer"}}><spam className="fa fa-arrow-left colorfont pt-4" onClick={() => backFunction()}>&nbsp;&nbsp;Back</spam></p>
            {selectedComponent}
            
        </div>

    )
}

export default FindPolicySupport;