import React from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import './page.scss';

const Page404 = () => {
    return (
        <div className="page-section">
            <Form>
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="8" lg="6" xl="5">
                        <div className="page-card text-center">
                            <h1>404</h1>
                            <h4>Oops! You're lost.</h4>
                            The page you are looking for was not found
                        </div>
                    </Col>
                </Row>
                <br />
            </Form>
        </div>
    )
}

export default Page404