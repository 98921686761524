import React, { useState } from "react";
import { Table, Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
    verifyPolicy, setPolicyPropsData, getBaseData
  } from "../../../services/coreSystemService";
import { getTransactionData } from "../../../services/dashboardService";
import { toast } from "react-toastify";
import { LoadingOverlay, Loader } from "react-overlay-loader";

const HeaderSearchQuick = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const policyNoColumnFormatter = (cell, row) => {
        if (row) {
          return (
            <a href="#" onClick={() =>  verify(row)}>
              {cell}
            </a>
            
          );
        } else {
          return row.policy_no;
        }
      };

      // const formatInsuredName = (cell,row) =>{
      //   return row.first_name+' '+row.last_name
      // }

  const verify = async (row) => {
    var params = {}
    setLoading(true);
    let policyStatus = false;
    if (row.policy_no.includes("TMP") || (row.policy_status == "QUOTE" || row.policy_status == "APPLICATION")) {
      await dispatch(getTransactionData(row.policy_no)).then((res) => {
        if(isEmpty(res)){
          toast.error("Policy Not Found");
          setLoading(false);
          return false;
      }else{
          if (res.n_ProductId_FK === 10) {
              navigate(`/flood/quote/${res.n_potransaction_PK}`);
              setLoading(false);
              window.location.reload();
          }
      }
  })
    } else {
      await dispatch(verifyPolicy(row.policy_no)).then((res) => {
        policyStatus = res.status
        if(!policyStatus){
          toast.error(res.message);
          setLoading(false);
          return false;
        }else{
          if (res.policyIdPK && res.status) {
            params = {
              n_POPolicyMasterFK: res.policyIdPK,
              n_POTermMasterFK: res.termMasterPK,
              n_POTransactionFK: res.poTransPK,
            };
          }
          else {
            navigate(`/core/policy`);
          }
        }
      });
      if(policyStatus){
        await dispatch(setPolicyPropsData(params));
        await dispatch(
          getBaseData(params.n_POTransactionFK)
        );
        navigate(`/core/policy/${row.policy_no}`);
        setLoading(false);
        window.location.reload();
      }
    }
  };

    const formatAddress = (cell,row) =>{
      return row.address_line1+', '+row.city+', '+row.county+', '+row.state+', '+row.zip_code
    }
    const columns = [
        // {
        //     dataField: "name",
        //     text: t("Search Match"),
        // },
        {
          dataField: "policy_no",
          text: t("Policy/Quote Number"),
          formatter: (value, row) => policyNoColumnFormatter(value, row),
          sort: true,
          style:{
            textAlign:"left"
          }
      },
        {
          dataField: "policy_status",
          text: t("Status"),
          sort: true,
          style: {
            textAlign: "left"
          }
        },
        {
          dataField: "address_line1",
          text: t("Property Address"),
          formatter: (value, row) => formatAddress(value, row),
          sort: true,
          style: {
            textAlign: "left"
          }
        },
        {
          dataField: "full_name",
          text: t("Insured Name"),
          // formatter: (value,row) => formatInsuredName(value,row),
          sort: true,
          style: {
            textAlign: "left"
          }
        },
        {
          dataField: "effective_date",
          text: t("Effective Date"),
          sort: true,
          style: {
            textAlign: "left"
          }
        }
    ]

    return(
        <>
        <LoadingOverlay>
        <Loader loading={loading} />
        {!isEmpty(props.data) ? 
          <div className="add-mortage-table-div" style={{width:"661px", display:"block", position:"absolute", zIndex:"1" }}>
                <Card style={{ maxHeight: "200px" }}>
                    <Table className="add-mortage-table" borderless responsive>
                        <Col xs="12">
                            <BootstrapTable
                              wrapperClasses="dashboard-table-main"
                              bootstrap4
                              keyField="s_POAppNo"
                              data={props.data}
                              columns={columns}
                              hover
                            />
                        </Col>
                    </Table>
                </Card>
            </div>
            : 
          <>
            {
              isEmpty(props.data) && !props.loading ?
                <div className="add-mortage-table-div" style={{ width: "661px", display: "block", position: "absolute", zIndex: "1" }}>
                  <Card style={{ maxHeight: "200px" }}>
                    <h6 className="ml-2">No result found</h6>
                  </Card>
                </div>
                : !isEmpty(props.data) && !props.loading ?
                  <div className="add-mortage-table-div" style={{ width: "661px", display: "block", position: "absolute", zIndex: "1" }}>
                    <Card style={{ maxHeight: "200px" }}>
                      <Table className="add-mortage-table" borderless responsive>
                        <Col xs="12">
                          <BootstrapTable
                            wrapperClasses="dashboard-table-main"
                            bootstrap4
                            keyField="s_POAppNo"
                            data={props.data}
                            columns={columns}
                            hover
                          />
                        </Col>
                      </Table>
                    </Card>
                  </div>
                  : null
            }
          </>
          }
          </LoadingOverlay>
            
        </>
    )
}

export default HeaderSearchQuick;