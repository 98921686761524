import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col,Row} from "react-bootstrap";
import "./tracking.css";
const ClaimAddInfo  = (props) => {

    const [claimDamageData, setClaimDamageData] = useState({});

    useEffect(() => {
        if(Object.keys(props.claimDamageData).length > 0){
            setClaimDamageData(props.claimDamageData);
        }
    }, [props.claimDamageData]);

    const getNumberFormat = (number = 0) => {
        if(number !== undefined && number != null && number != ''){
           return "$"+parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return "$0.00";
    }

  return (
    <>
        <Row  className='mt-2'>
            <Col md="12">
                <fieldset class="border p-2 border-success rounded">
                    <Row className="m-1">
                        <Col md="3">
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >Building</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >Appurtenant</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >Contents</span>
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Property Value (RCV) </span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                                    <span >{getNumberFormat(claimDamageData?.building?.propertyValueRCV)}</span>
                                }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                               <span >{getNumberFormat(claimDamageData?.appurtenant?.propertyValueRCV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                               <span >{getNumberFormat(claimDamageData?.contents?.propertyValueRCV)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Property Value (ACV)</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                               <span >{getNumberFormat(claimDamageData?.building?.propertyValueACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.propertyValueACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.propertyValueACV)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Gross Loss (RCV)</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.grossLossRCV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.grossLossRCV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.grossLossRCV)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Covered Damage (ACV)</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.coverageDamageACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.coverageDamageACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.coverageDamageACV)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Removal/Protection</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.removalProtection)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.removalProtection)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.removalProtection)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Less Salvage</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.lessSalvage)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.lessSalvage)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.lessSalvage)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Total Loss (ACV)</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.totalLossACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.appurtenant?.totalLossACV)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.contents?.totalLossACV)}</span>
                            }
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Rec Depreciation</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.recDepreciation)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.appurtenant?.recDepreciation)}</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.contents?.recDepreciation)}</span>
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Co-Insurance Penalty</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            { 
                              <span >{getNumberFormat(claimDamageData?.building?.coInsurancePenalty)}</span>
                            }
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.appurtenant?.coInsurancePenalty)}</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.contents?.coInsurancePenalty)}</span>
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span >Deductible</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.building?.deductible)}</span>
                        </Col>
                        <Col md="3" className="text-right border-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.appurtenant?.deductible)}</span>
                        </Col>
                        <Col md="3" className="text-right coverageClaimAddInfo">
                            <span >{getNumberFormat(claimDamageData?.contents?.deductible)}</span>
                        </Col>
                    </Row>
                    <Row className="m-1">
                        <Col md="3">
                           <span ><b>Total</b></span>
                        </Col>
                        <Col md="3" className="text-right border-top coverageClaimAddInfo">
                            <span ><b>{getNumberFormat(claimDamageData?.building?.total)}</b><br/>Total Building Claim</span>
                        </Col>
                        <Col md="3" className="text-right border-top coverageClaimAddInfo">
                            <span >&nbsp;</span>
                        </Col>
                        <Col md="3" className="text-right border-top coverageClaimAddInfo">
                            <span ><b>{getNumberFormat(claimDamageData?.contents?.total)}</b><br/>Total Contents Claim</span>
                        </Col>
                    </Row>
                </fieldset>
            </Col>
        </Row>
    </>
  )

};

const mapStateToProps = (state) => {
    return {
        claimDamageData : state.coreSystem.claimDamageData
    };
};

export default connect(mapStateToProps)(ClaimAddInfo);