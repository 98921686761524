import Http from '../Http';
import * as action from '../store/actions';

const claimCoreUrl = process.env.REACT_APP_CLAIM_CORE_URL;

export function listClaim(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/listClaim",data)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function listPersonClaims(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/listAllClaims",data)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getClaimForMasters(policy_no) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get(claimCoreUrl+"/api/v1/claim_form_masters?policy_no="+policy_no)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getPolicyClaimDetails(policy_no, transactionId) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get(claimCoreUrl+"/api/v1/policy_details_add_claim?policy_no="+ policy_no +"&claimId=0")
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function saveReportAClaim(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/saveReportAClaim",data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function checkClaimExist(policy_no) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get("/api/checkClaimExist?policy_no="+policy_no )
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getReportAClaim(policy_no) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get("/api/getReportAClaim?policy_no="+policy_no )
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}


export function getCoreClaimData(claimNumber) {
  return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(claimCoreUrl + "/api/v2/getEditClaimData/"+claimNumber )
          .then((res) => {
            return resolve(res.data);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response);
            }
          });
      })
  );
}

export function setCoreClaimData(params) {
  return (dispatch) => dispatch(action.setCoreClaimData(params));
}

export function getCoreClaimPaymentData(claimId) {
  return dispatch => (
      new Promise((resolve, reject) => {
        Http.get(claimCoreUrl + "/api/v2/getPaymentList/"+claimId )
          .then((res) => {
            return resolve(res.data);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response);
            }
          });
      })
  );
}

export function setCoreClaimPaymentData(params) {
  return (dispatch) => dispatch(action.setCoreClaimPaymentData(params));
}

export function setClaimDamageData(params) {
  return (dispatch) => dispatch(action.setClaimDamageData(params));
}

export function getNumberFormat (value, row) {
  let number=0;
  number= value;
  if(number !== undefined && number != null && number != ''){
     return "$"+parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  return "$0.00";
}