import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { Row, Col, Form } from "react-bootstrap";
import "./common.scss";
import * as action from '../../store/actions';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import React, { Fragment, useContext, useEffect, useState } from "react";


const ModeToggle = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [check, setCheck] = useState(false)

  const switchMode = (e) => {
    dispatch(action.getPageMode(e.target.checked))
  }
  return (
    <div className="main-header-main-div" style={{ marginLeft: "60%" }}>
      {
         (props.step == 'address' || props.step == 'home-type' || props.step < 11) && process.env.REACT_APP_NODE_ENV !== "PROD"?
            <Fragment>
              <span >{t('Interview Mode') } </span>
              &nbsp;
              <div className="custom-main-swtich">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  onChange={(e) => { switchMode(e) }}
                  checked={props.pageMode === true ? props.pageMode : check}
                />
              </div>
              <span >{t('Form Mode')}</span>

            </Fragment>
            : ''

      }
        
      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pageMode: state.flood.pageMode ?? false
  }
}

export default connect(mapStateToProps)(ModeToggle);
