import Http from '../Http';
import * as action from '../store/actions';

/**
 * To get Pay.Gov Auth Token
 * @returns 
 */
 export function getPayGovToken(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/getPayGovToken',params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}


export function getPayGovCollectionDetails(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/flood/getPayGovCollectionDetails',params)
        .then((res) => {
          return resolve(res.data);
        }).catch((err) => {
          if (err.response) {
            return reject(err.response);
          }
        });
    })
  );
}