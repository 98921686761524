import Http from "../../../../Http";

export function getAutoLocationData({ requestBody }) {
  return new Promise((resolve, reject) => {
    const url = `/api/quote/getPropertyDataAddr/10`;
    Http.post(url, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}

export function getBaseData(){
  return new Promise((resolve, reject) => {
    const url = `/api/quote/getBaseData/10`;
    Http.get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}
