import { useState, useMemo, useEffect} from "react";
import { Loader,LoadingOverlay } from "react-overlay-loader";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react"
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { commisionsStatement, commisionsAccDetails, printBatchAgentStatement, commisionsStmtDetails } from "../../../services/producerService";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { formateDate } from "../../common/FormatDate";
import { useTranslation } from "react-i18next";
import "../agentuser.css"


const CommisionModal = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const paginationPageSize = 10;
    const paginationPageSizeSelector = [10, 25, 50];
    const [accountview, setAccountview] = useState([]);
    const [accountDetails, setAccountDetails] = useState([]);
    const [statementDetails, setStatementDetails] = useState([]);
    const [statement, setStatement] = useState([]);
    const [due, setDue] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        commisionData()
    }, [])

    const commisionData = () => {
        setLoading(true);
        dispatch(commisionsStatement(props?.agencyId))
            .then((res) => {
                if (res.status === 200) {
                    setAccountview(res.data.accMaster);
                    setStatement(res.data.stmtArr);
                    setDue(res.data.stmtTempArr);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const generatePdf = (n_PAAgentStatementMaster_PK) => {
        setLoading(true);
        dispatch(printBatchAgentStatement(n_PAAgentStatementMaster_PK))
            .then((res) => {                
                if (res.body.status ==="Y") {
                    toast.success("Downloaded Successfully..!");
                    window.open(res.body.url);                    
                }
            })
            .catch((error) => {
                toast.error("Failed to downlaod..!")
                console.log(error);

            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getAccViewDetails = (n_PATransactionMaster_PK) => {
        setLoading(true);
        dispatch(commisionsAccDetails(props?.agencyId, n_PATransactionMaster_PK)).then((res) => {
            if (res.status === 200) {
                setAccountDetails(res.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const getStmtDetails = (n_PAAgentStatementMaster_PK) => {
        setLoading(true);
        dispatch(commisionsStmtDetails(props?.agencyId, n_PAAgentStatementMaster_PK)).then((res) => {
            if (res.status === 200) {
                setStatementDetails(res.data)
            }
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const alignItems=(params)=>{
        return(
            <>
             <Text textAlign={"center"}>{params.value}</Text>
            </>
        )
    }


    const [columnDefs] = useState([
        {
            headerName: t('ACC DT'), field: 'd_AccountingDate',
            cellRenderer: (params) => {
                return(
                    <Box alignItems={"center"}>
                        {formateDate(params.value)}
                    </Box>
                )
            }

        },
        {
            headerName: t('TRAN TYPE'), field: 'OriginalTranType'

        },
        {
            headerName: t('TRAN REF'), field: 's_TransRefNo',
            cellRenderer: (params) => {
                return (
                    <Box textAlign={"center"}>
                        <Link
                            href="#"
                            style={{ padding: '0px' }}
                            _hover={{ color: 'blue' }}
                            onClick={(e) => {
                                e.preventDefault()
                                getAccViewDetails(params.data.n_PATransactionMaster_PK)
                            }
                            }
                            onMouseEnter={(e) => e.currentTarget.style.color = "blue"}
                            onMouseLeave={(e) => e.currentTarget.style.color = ""}
                        >
                            {params.data.s_TransRefNo}
                        </Link>
                    </Box>
                )
            }
        },
        {
            headerName: t('DEBIT'), field: 'n_Amount',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }
        },
        {
            headerName: t('CREDIT'), field: 'n_Amount',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }

        },
        {
            headerName: t('BALANCE'), field: 'runningbalance',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }

        },
        {
            headerName: t('SYSTEM DT'), field: 'd_CreatedDate'
        }
    ]);

    const [columnDefs1] = useState([
        {
            headerName: t('TRANS SUB TYPE'), field: 's_SubtypeScreenName',flex:1

        },
        {
            headerName: t('DEBIT'), field: 'n_Amount',flex:1,
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }

        },
        {
            headerName: t('CREDIT'), field: 'n_Amount',flex:1,
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }

        },
        {
            headerName: t('CONTRA ACCOUNT'), field: 's_AccountID',flex:1

        },
    ]);

    const [columnDefs2] = useState([
        {
            headerName: t('STATEMENT DATE'), field: 'd_StatementDate', flex:1,
            cellRenderer: (params) => {
                return(
                    <Box textAlign="center">
                        {formateDate(params.value)}
                    </Box>
                )
            }
        },
        {
            headerName: t('STATEMENT NO'), field: 's_StatementNo', flex:1,
            cellRenderer: (params) => {

                return (
                    <Box textAlign="Center">
                        <Link
                            href='#'
                            style={{ padding: '0px' }}
                            onClick={(e) => {
                                e.preventDefault();
                                getStmtDetails(params.data.n_PAAgentStatementMaster_PK);
                            }}
                            onMouseEnter={(e) => e.currentTarget.style.color = "blue"}
                            onMouseLeave={(e) => e.currentTarget.style.color = ""}
                        >
                            {params.data.s_StatementNo}
                        </Link>
                    </Box>
                )
            }
        },
        {
            headerName: t('TOTAL AMOUNT'), field: 'n_StatementCommAmt',flex:1,
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }
        },
        {
            headerName: t('PDF'), field: 'n_PAAgentStatementMaster_PK', flex:1,
            cellRenderer: (params) => {

                return (
                    <Box  textAlign={"center"}>
                        <Link
                            href='#'
                            style={{color: 'blue' }}
                            onClick={() =>
                                generatePdf(params.data.n_PAAgentStatementMaster_PK)
                            }>
                            STATEMENT
                        </Link>
                    </Box>
                )
            }
        },
    ]);

    const [columnDefs3] = useState([
        {
            headerName: t('Agency Flood Code'), field: 'AgencyCode'
        },
        {
            headerName: t('Agency Name'), field: 'AgencyName', wrapText: true, autoHeight: true,
        },
        {
            headerName: t('POLICY NO'), field: 'PolicyNo'
        },
        {
            headerName: t('TRANSACTION'), field: 's_StatementDetailCode'
        },
        {
            headerName: t('COMM %'), field: 'n_CommissionRate'
        },
        {
            headerName: t('COMM AMT'), field: 'n_PostedCommAmount',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }
        },
        {
            headerName: t('PREM/WRITTEN'), field: 'n_CommBaseAmt',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }
        },
    ]);

    const [columnDefs4] = useState([
        {
            headerName: t('ACCT NO'), field: 'Policy_No'
        },
        {
            headerName: t('POLICYHOLDER/MEMBER NAME'), field: 's_FullLegalName', wrapText: true, autoHeight: true,
        },
        {
            headerName: t('TERM ST'), field: 'd_TermStartDate',
            cellRenderer: (params) => {
                return(
                    <Box textAlign={"center"}>
                        {formateDate(params.value)}
                    </Box>
                )
            }
        },
        {
            headerName: t('TERM END'), field: 'd_TermEndDate', 
            cellRenderer: (params) => {
                return(
                    <Box textAlign={"center"}>
                        {formateDate(params.value)}
                    </Box>
                )
            }
        },
        {
            headerName: t('TRANSACTION'), field: 's_PRTranTypeCode'
        },
        {
            headerName: t('AGENCY'), field: 'AgencyName',  wrapText: true, autoHeight: true,
        },
        {
            headerName: t('COM.%'), field: 'n_CommissionRateOriginal'
        },
        {
            headerName: t('COM.AMT'), field: 'n_TransactionCommissionDue',
            cellRenderer:(params)=>{
                return <Text textAlign={"center"}>{`$${params.value}`}</Text>
            }
        },
    ]);

    const defaultColDef = useMemo(
        () => ({
            resizable: true,
            sortable:true,
            cellEditor: 'agSelectCellEditor',
            cellRenderer:alignItems,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            minWidth: 120,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        }),
        []
    );


    return (
        <>
            <LoadingOverlay>
                <Loader loading={loading} />
                <Box>
                    <Tabs>
                        <TabList>
                            <Tab>{t('Commission Account View')}</Tab>
                            <Tab>{t('Commission statement')}</Tab>
                            <Tab>{t('Commission Due')}</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Loader loading={loading} />
                                <Box
                                    boxShadow={"lg"}
                                    bgColor={"white"}
                                    borderRadius={"2xl"}
                                    borderColor={"white"}
                                    m={2}
                                >

                                    <Box
                                        className="ag-theme-alpine"
                                        height={"450px"}
                                        id="myGrid"
                                        w={"100%"}
                                    >
                                        <AdvancedGridTable
                                            columnDefs={columnDefs}
                                            defaultColDef={defaultColDef}
                                            rowData={accountview}
                                            pagination={true}
                                            paginationPageSize={paginationPageSize}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            enableCellTextSelection={true}

                                        />
                                    </Box>
                                </Box>
                                <Box
                                    boxShadow={"lg"}
                                    bgColor={"white"}
                                    borderRadius={"2xl"}
                                    borderColor={"white"}
                                    mt={6}
                                >
                                    <Box
                                        className="ag-theme-alpine"
                                        height={"450px"}
                                        id="myGrid"
                                        w={"100%"}
                                    >
                                        <AdvancedGridTable
                                            columnDefs={columnDefs1}
                                            defaultColDef={defaultColDef}
                                            rowData={accountDetails}
                                            pagination={true}
                                            paginationPageSize={paginationPageSize}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            enableCellTextSelection={true}

                                        />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box
                                    boxShadow={"lg"}
                                    bgColor={"white"}
                                    borderRadius={"2xl"}
                                    borderColor={"white"}
                                    m={2}
                                >

                                    <Box
                                        className="ag-theme-alpine"
                                        height={"450px"}
                                        id="myGrid"
                                        w={"100%"}
                                    >
                                        <AdvancedGridTable
                                            columnDefs={columnDefs2}
                                            defaultColDef={defaultColDef}
                                            rowData={statement}
                                            pagination={true}
                                            paginationPageSize={paginationPageSize}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            enableCellTextSelection={true}


                                        />
                                    </Box>
                                </Box>
                                <Box
                                    boxShadow={"lg"}
                                    bgColor={"white"}
                                    borderRadius={"2xl"}
                                    borderColor={"white"}
                                    mt={6}
                                >

                                    <Box
                                        className="ag-theme-alpine"
                                        height={"450px"}
                                        id="myGrid"
                                        w={"100%"}
                                    >
                                        <AdvancedGridTable
                                            columnDefs={columnDefs3}
                                            defaultColDef={defaultColDef}
                                            rowData={statementDetails}
                                            pagination={true}
                                            paginationPageSize={paginationPageSize}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            enableCellTextSelection={true}

                                        />
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel>
                                <Box
                                    boxShadow={"lg"}
                                    bgColor={"white"}
                                    borderRadius={"2xl"}
                                    borderColor={"white"}
                                    m={2}
                                >
                                    <Box
                                        className="ag-theme-alpine"
                                        height={"450px"}
                                        id="myGrid"
                                        w={"100%"}
                                    >
                                        <AdvancedGridTable
                                            columnDefs={columnDefs4}
                                            defaultColDef={defaultColDef}
                                            rowData={due}
                                            pagination={true}
                                            paginationPageSize={paginationPageSize}
                                            paginationPageSizeSelector={paginationPageSizeSelector}
                                            enableCellTextSelection={true}

                                        />
                                    </Box>
                                </Box>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </LoadingOverlay>

        </>
    )

}
export default CommisionModal;