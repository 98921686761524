import React, { useContext } from "react";
import { Box, Flex, FormControl, Select, Text } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { starCommercialContext } from "../CommercialContext";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import { coveredFerilsFields } from "../data/commercialDD";

const OptionalCoverage = ({ quoteData }) => {
  const { t } = useTranslation();
  const { setCommercialData, commercialData } = useContext(
    starCommercialContext
  );

  const allFields = [
    ...(quoteData?.allCvgOpt || []),
    {
      s_ScreenName: t("Covered Perils"),
      s_CoverageCode: "coveredPerils",
      s_LimitCodeType: "DROPDOWN",
      dropDown: coveredFerilsFields.map((value) => ({ value })),
    },
  ];

  const handleInputChange = (value, fieldName, type = "text") => {
    setCommercialData((prev) => {
      // Handle direct fields vs policySummaryDetails fields
      const isDirectField = [
        "coveredPerils",
        "aopDeductible",
        "hurricaneDeductible",
      ].includes(fieldName);

      if (isDirectField) {
        return {
          ...prev,
          [fieldName]: type === "number" ? value : value,
        };
      }

      return {
        ...prev,
        policySummaryDetails: {
          ...prev.policySummaryDetails,
          [fieldName]: type === "number" ? value : value,
        },
      };
    });
  };

  const getFieldValue = (fieldName) => {
    const isDirectField = [
      "coveredPerils",
      "aopDeductible",
      "hurricaneDeductible",
    ].includes(fieldName);
    return isDirectField
      ? commercialData[fieldName]
      : commercialData?.policySummaryDetails?.[fieldName];
  };

  const renderOptions = (field) => {
    if (field.s_CoverageCode === "coveredPerils") {
      return coveredFerilsFields.map((value, idx) => (
        <option key={idx} value={value}>
          {value}
        </option>
      ));
    }

    return field.dropDown?.map((option, idx) => (
      <option
        key={idx}
        value={option.s_LimitCode || option.s_DiscountCode || option.value}
      >
        {option.s_LimitScreenName || option.s_ScreenName || option.value}
      </option>
    ));
  };

  return (
    <Box display="flex">
      <Box p={4} width={{ xl: "65%", md: "75%", base: "100%" }}>
        {allFields.map((field, index) => (
          <Flex key={index} gap={100} mt={1} alignItems="center">
            <Box w="200px" flex={1}>
              <Text display="flex" flexDirection="row">
                {field.s_ScreenName} &nbsp;
                <Box w="10px" flex={1}>
                  <FcInfo style={{ marginTop: "5px" }} />
                </Box>
              </Text>
            </Box>
            <Box w="250px">
              <FormControl>
                {field.s_LimitCodeType === "NUMBER" ? (
                  <CustomCurrencyInput
                    name={field.s_CoverageCode}
                    value={getFieldValue(field.s_CoverageCode)}
                    onValueChange={(value) =>
                      handleInputChange(value, field.s_CoverageCode, "number")
                    }
                  />
                ) : (
                  <Select
                    className="inputSelectBoxes"
                    name={field.s_CoverageCode}
                    value={getFieldValue(field.s_CoverageCode)}
                    onChange={(e) =>
                      handleInputChange(e.target.value, field.s_CoverageCode)
                    }
                  >
                    <option value=""></option>
                    {renderOptions(field)}
                    {field.s_LimitCodeType === "DROPDOWN" &&
                      ![
                        "coveredPerils",
                        "aopDeductible",
                        "hurricaneDeductible",
                      ].includes(field.s_CoverageCode) && (
                        <option value="NO">No</option>
                      )}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Flex>
        ))}
      </Box>
      <style jsx>{`
        .inputSelectBoxes {
          border: 2px solid var(--chakra-colors-neutral-200);
          border-radius: 12px;
          padding-right: 10px;
          padding-left: 15px;
          width: 100%;
          height: 45px;
        }
      `}</style>
    </Box>
  );
};

export default OptionalCoverage;
