import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { CommercialDataDD } from "../../data/CommercialData";
import { ResidentialDataDD } from "../../data/ResidentialData";
import getRenewedTenantedReqBody from "../../requestBodies/renewTenantedReq";
import getRenewedOwnedReqBody from "../../requestBodies/renewOwnedReq";
import getRenewedResidentialReqBody from "../../requestBodies/renewResidentialReq";
import { renewPolicyHiscox } from "../../api/renewPolicyHiscox";
import PremiumModal from "./RenewPremiumModal";
import PriorLossesTable from "../PriorLosses";
import { useDispatch, useSelector } from "react-redux";
import LimitsTable from "../LimitsTable";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  getCvg,
  saveEndorsmentData,
  saveNewTransaction,
  verifyPolicy,
} from "../../../../../services/coreSystemService";
import getEndorsementApplicationBody from "../../requestBodies/endorsementApplicationBody";

let minDate = "2000-01-01"; //minimum date for calender ui.

export default function RenewPolicyModal({
  show,
  onHide,
  quoteDetailData,
  quoteId,
  productType,
  commercialProductType,
  handleClose,
  productCode,
  productId,
}) {
  const [renewedData, setRenewedData] = useState({
    effectiveDate: minDate,
  });
  const [requestBody, setRequestBody] = useState({});
  const [premiumResponseData, setPremiumResponseData] = useState({});
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coverageData, setCoverageData] = useState({});
  const [readyToSave, setReadyToSave] = useState(false);
  const [dataForRenewal, setDataForRenewal] = useState(null);
  const [dynamicId, setDynamicId] = useState({
    PolicyPK: 0,
    n_TermMaster_PK: 0,
    TransPK: 0,
  });
  const { policyNumber } = useParams();
  const reduxStore = useSelector((state) => state);

  useEffect(() => {
    if (renewedData?.quoteDetailData?.data?.quoteId) {
      const { quoteDetailData, effectiveDate } = renewedData;

      const getRequestBody = (type) => {
        switch (type) {
          case "Tenanted":
            return getRenewedTenantedReqBody({
              quoteDetailData,
              effectiveDate,
            });
          case "Owned":
            return getRenewedOwnedReqBody({ quoteDetailData, effectiveDate });
          default:
            return getRenewedResidentialReqBody({
              quoteDetailData,
              effectiveDate,
            });
        }
      };

      setRequestBody(getRequestBody(commercialProductType));
    }
  }, [renewedData]);

  useEffect(() => {
    if (quoteDetailData) {
      setRenewedData({
        ...renewedData,
        quoteDetailData,
        effectiveDate: moment(
          reduxStore?.coreSystem?.applicationData?.d_TermEndDate || minDate
        ).format("YYYY-MM-DD"),
      });
    }
  }, [quoteDetailData]);

  const handleSubmit = async () => {
    setLoading(true);
    const productID = quoteId || 0;
    try {
      const res = await renewPolicyHiscox({ requestBody, productID });
      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) =>
          toast.error(error, { autoClose: 5000, toastId: "renewPolicyError" })
        );
      } else if (res?.status === true && res?.data) {
        setPremiumResponseData(res.data);
        setShowPremiumModal(true);
        // const payload = {
        //   poTransPK: store?.coreSystem?.n_POTransactionFK,
        //   transactionEffectiveDate: requestBody.effectiveDate,
        // };
        // await saveUpdates({ payload, transType: "renew" });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const handleClosePremiumModal = () => {
    setShowPremiumModal(false);
    handleClose();
  };

  const getCurrentDate = () => {
    const today = new Date();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    const year = today.getFullYear();

    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const handleTransaction = async () => {
    try {
      // Save Transaction
      const param = {
        s_PRTranTypeCode: "RENEW",
        s_PRTranSubTypeCode: "RATEINFO",
        s_PRTranReasonCode: "NONE",
        s_RequestedBy: "INSURED",
        n_StandardTerm: "12",
        s_StandardTermUnit: "MONTH",
        s_TermTypeCode: "NONCOMMON",
        d_TransEffectiveFrom: requestBody?.effectiveDate,
        d_TransEffectiveTo: moment(requestBody?.effectiveDate)
          .add(1, "year")
          .format("YYYY-MM-DD"),
        d_AccountingDate: getCurrentDate(),
        s_Note: "",
        Policy_No: policyNumber,
        PolicyPK: reduxStore?.coreSystem?.n_POPolicyMasterFK || 0,
        n_TermMaster_PK: reduxStore?.coreSystem?.n_POTermMasterFK || 0,
        TransPK: reduxStore?.coreSystem?.n_POTransactionFK || 0,
        isCorrection: false,
        reasonCode: [],
        isPoAssignmant: false,
        source: "AGENT_PORTAL",
        s_PolicyWaitingPeriod: "STANDARD30",
        effectiveDate: requestBody?.effectiveDate,
        currentWaitingPeriod: "STANDARD30",
        noPremiumChangeEndorse: false,
      };
      const res = await dispatch(saveNewTransaction(param));
      if (res?.Status === 1) {
        // 1  == error
        toast.error(res?.msg, {
          autoClose: 5000,
          toastId: "transactionSaved1",
        });
      } else if (res?.Status === 0) {
        //0 == success
        toast.success(res?.msg, {
          autoClose: 5000,
          toastId: "transactionSaved",
        });
        verify();
      } else {
        toast.error("Failed to save transaction");
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    } finally {
      setLoading(false);
    }
  };
  const verify = async () => {
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        getCoverageInfo({
          PolicyPK: res.policyIdPK,
          TransPK: res.poTransPK,
        });
        setDynamicId({
          ...dynamicId,
          PolicyPK: res.policyIdPK,
          TransPK: res.poTransPK,
        });
      }
    });
  };
  const getCoverageInfo = async ({ PolicyPK, TransPK }) => {
    try {
      const res = await dispatch(getCvg(PolicyPK, TransPK));
      if (res?.data) {
        setCoverageData(res?.data?.basicCvgData);
        setReadyToSave(true);
      }
    } catch (error) {
      toast.error("Something Went Wrong, while fetching coverage data");
    }
  };

  useEffect(() => {
    let PolicyPK = dynamicId?.PolicyPK;
    let TransPK = dynamicId?.TransPK;
    if (readyToSave === true) {
      saveEndorsementToDB({
        PolicyPK,
        TransPK,
      });
    }
  }, [readyToSave]);

  const saveEndorsementToDB = async ({ PolicyPK, TransPK }) => {
    const endorsementApplicationBody = getEndorsementApplicationBody({
      requestBody: requestBody,
    });

    function findCoverageInfo(data, coverageCode, infoType) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].s_CoverageCode === coverageCode) {
          switch (infoType) {
            case "POCoverageTran_PK":
              return data[i].n_POCoverageTran_PK;
            case "InsuredValue":
              return data[i].n_InsuredValue;
            case "PrDiscountCode":
              return data[i].s_PrDiscountCode;
            default:
              return null;
          }
        }
      }
      return null;
    }

    const buildCoverageObject = (
      coverageCode,
      limit,
      premium,
      annualizedPremium,
      TransPK
    ) => ({
      poTransPK: TransPK,
      n_POCoverageTran_PK: findCoverageInfo(
        coverageData,
        coverageCode,
        "POCoverageTran_PK"
      ),
      value: limit,
      premium: Number(premium.toFixed(2) || 0),
      annualizedPremium,
    });

    const buildDeductibleObject = (
      coverageCode,
      deductible,
      PolicyPK,
      TransPK
    ) => {
      const {
        coreSystem: {
          applicationData: { n_ProductId_FK },
        },
      } = reduxStore;
      return {
        poTransPK: TransPK,
        policyNoPK: PolicyPK,
        product: n_ProductId_FK,
        whichDeductible: coverageCode,
        value: deductible === 0 ? "" : `FLDDED${deductible}`,
      };
    };

    try {
      const {
        coreSystem: {
          applicationData: { n_NetPremium },
        },
      } = reduxStore;

      const { selectedCardData, limits, options, totalPremium } =
        dataForRenewal || {};

      const {
        buildingPremium,
        contentsPremium,
        lossOfUsePremium,
        otherStructuresPremium,
        improvementsAndBettermentsPremium,
        businessIncomePremium,
        deductible,
      } = selectedCardData || {};

      const buildingCoverage = buildCoverageObject(
        "FLDBLDCVGAMT",
        limits?.buildingLimit ?? limits?.improvementsAndBettermentsLimit,
        buildingPremium ?? improvementsAndBettermentsPremium,
        buildingPremium ?? improvementsAndBettermentsPremium,
        TransPK
      );
      const contentCoverage = buildCoverageObject(
        "FLDCONTCVGAMT",
        options?.includeContents ? limits?.contentsLimit : 0,
        options?.includeContents ? contentsPremium : 0,
        options?.includeContents ? contentsPremium : 0,
        TransPK
      );

      const buildingDeductible = buildDeductibleObject(
        "FLDBLDCVGAMT",
        deductible,
        PolicyPK,
        TransPK
      );

      const contentDeductible = buildDeductibleObject(
        "FLDCONTCVGAMT",
        deductible,
        PolicyPK,
        TransPK
      );
      const includeLossOfUseCoverage =
        productType === "Residential"
          ? buildCoverageObject(
              "HOMCVGD",
              options?.includeLossOfUse ? limits?.lossOfUseLimit : 0,
              options?.includeLossOfUse ? lossOfUsePremium : 0,
              options?.includeLossOfUse ? lossOfUsePremium : 0,
              TransPK
            )
          : null;

      const otherStructureCoverage =
        productType === "Residential"
          ? buildCoverageObject(
              "HOMCVGB",
              limits?.otherStructureLimit,
              otherStructuresPremium,
              otherStructuresPremium,
              TransPK
            )
          : null;

      const buissnessIncomeCoverage =
        productType === "Commercial"
          ? buildCoverageObject(
              "BUSIINCOMEEXP",
              options?.businessIncomePremium
                ? limits?.businessIncomeAndExtraExpenseAnnualValue
                : 0,
              options?.businessIncomePremium ? businessIncomePremium : 0,
              options?.businessIncomePremium ? businessIncomePremium : 0,
              TransPK
            )
          : null;

      const requestBody = {
        poTransPK: TransPK,
        transType: "RENEW",
        isPrimaryRes: "NO",
        nPolicyPK: PolicyPK,
        metadata: {
          current: {
            Coverage: {
              buildingCoverage: findCoverageInfo(
                coverageData,
                "FLDBLDCVGAMT",
                "InsuredValue"
              ),
              contentCoverage: findCoverageInfo(
                coverageData,
                "FLDCONTCVGAMT",
                "InsuredValue"
              ),
              buildingDeductible: findCoverageInfo(
                coverageData,
                "FLDBLDCVGAMT",
                "PrDiscountCode"
              ),

              contentDeductible: findCoverageInfo(
                coverageData,
                "FLDCONTCVGAMT",
                "PrDiscountCode"
              ),
            },
            InsuredInformation: [],
            Lender: [],
            AdditionalInformation: [],
            EndorsmentEffectiveDate: [],
            RequestUnderwriterReview: [],
            Email: [],
            Phone: [],
          },
          new: {
            Coverage:
              productType === "Residential"
                ? {
                    buildingCoverage,
                    buildingDeductible,
                    contentCoverage,
                    contentDeductible,
                    includeLossOfUseCoverage,
                    otherStructureCoverage,
                  }
                : {
                    //this is for Commercial (Hiscox)
                    buildingCoverage,
                    buildingDeductible,
                    contentCoverage,
                    contentDeductible,
                    buissnessIncomeCoverage,
                  },
            InsuredInformation: [],
            Lender: [],
            AdditionalInformation: {
              ...endorsementApplicationBody,
              policyType: quoteDetailData?.data?.premium?.selectedPremium?.type,
            },
            EndorsmentEffectiveDate: [],
            RequestUnderwriterReview: [],
            Email: [],
            Phone: [],
          },
          preEndorsePK: 0,
          isPrimaryRes: "NO",
          isPaymentDone: false,
          isDocRequired: false,
          newPremium: Number(
            ((n_NetPremium || 0) + (totalPremium || 0) || 0).toFixed(2)
          ),
          premiumChange: totalPremium || 0,
          currentPremium: Number(n_NetPremium) || 0,
        },
        policyNumber: quoteId,
        Action: "Update",
      };

      const res = await dispatch(saveEndorsmentData(requestBody));

      if (res?.data?.status === "N" && res?.data?.msg) {
        toast.error(res?.data?.msg, {
          autoClose: 5000,
          toastId: "error",
        });
      } else if (res?.data?.status === "Y" && res?.data?.msg) {
        toast.success(res?.data?.msg, {
          autoClose: 5000,
          toastId: "success",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1600);
      } else if (res?.success === true) {
        toast.success("Endorsement Created Successfully!", {
          autoClose: 1500,
          toastId: "success1",
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1600);
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
      console.error(error);
    } finally {
      setReadyToSave(false);
    }
  };

  const hiscoxBuildingType = [
    "",
    "BrickVeneer",
    "EIFS",
    "Frame",
    "Log",
    "Masonry",
    "Stucco",
    "Asbestos",
  ];

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={onHide}
        size="xl"
        className={`${showPremiumModal ? "d-none" : ""}`}
      >
        <Modal.Header closeButton id="common-modal-header" className="px-4">
          <Modal.Title className="text-white d-flex align-items-center justify-content-between gap-3">
            {t("Renew Policy")}
            <span className="pt-1">
              {loading && (
                <Spinner animation="border" variant="light" size="lg" />
              )}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            style={{
              padding: "0.5rem",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "1.5rem",
            }}
          >
            <Form.Group>
              <Form.Label>Policy No</Form.Label>
              <Form.Control readOnly type="text" value={quoteId} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Effective Date</Form.Label>
              <Form.Control
                disabled={true} // a year added priorly should always be disabled
                type="date"
                min={moment(
                  reduxStore?.coreSystem?.applicationData?.d_TermEndDate ||
                    minDate
                ).format("YYYY-MM-DD")}
                name="effectiveDate"
                value={moment(requestBody?.effectiveDate).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    effectiveDate: e.target.value,
                  })
                }
              />
              {requestBody.effectiveDate === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Occupancy Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.occupancyType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    occupancyType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.OccupancyType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.OccupancyType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
              </Form.Select>
              {requestBody?.occupancyType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Construction Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.constructionType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    constructionType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.ConstructionType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.ConstructionType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
              </Form.Select>
              {requestBody?.constructionType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>
            {Object.keys(requestBody).includes(
              "improvementsAndBettermentsReplacementCostValues"
            ) && (
              <Form.Group>
                <Form.Label>Improvements And Betterments (RCV)</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    requestBody?.improvementsAndBettermentsReplacementCostValues
                  }
                />
              </Form.Group>
            )}

            <Form.Group>
              <Form.Label>Contents (RCV)</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={requestBody?.contentsReplacementCostValues}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    contentsReplacementCostValues: Number(e.target.value),
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {requestBody?.commercialProductType === "Tenanted"
                  ? "Business Income & Extra Expense"
                  : "Building (RCV)"}
              </Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={
                  requestBody?.commercialProductType === "Tenanted"
                    ? requestBody?.businessIncomeAndExtraExpenseAnnualValue
                    : requestBody?.buildingReplacementCostValues
                }
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    [requestBody?.commercialProductType === "Tenanted"
                      ? "businessIncomeAndExtraExpenseAnnualValue"
                      : "buildingReplacementCostValues"]: Number(
                      e.target.value
                    ),
                  })
                }
              />
            </Form.Group>
            {requestBody?.commercialProductType === "Owned" && (
              <Form.Group>
                <Form.Label>Business Income & Extra Expense</Form.Label>
                <Form.Control
                  type="number"
                  name="businessIncomeAndExtraExpenseAnnualValue"
                  onChange={(e) => {
                    setRequestBody({
                      ...requestBody,
                      businessIncomeAndExtraExpenseAnnualValue: Number(
                        e.target.value
                      ),
                    });
                  }}
                  value={
                    requestBody?.businessIncomeAndExtraExpenseAnnualValue || 0
                  }
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>Foundation Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.foundationType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    foundationType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.FoundationType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.FoundationType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
              </Form.Select>
              {requestBody?.foundationType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Additional Foundation Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.additionalFoundationType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    additionalFoundationType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.AdditionalFoundationType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {" "}
                        {item.label}{" "}
                      </option>
                    )
                  )}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.AdditionalFoundationType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {" "}
                        {item.label}{" "}
                      </option>
                    )
                  )}
              </Form.Select>
              {requestBody?.additionalFoundationType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Contents Cost Value Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.contentsCostValueType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    contentsCostValueType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.ContentsCostValueType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {" "}
                        {item.label}{" "}
                      </option>
                    )
                  )}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.ContentsCostValueType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {" "}
                        {item.label}{" "}
                      </option>
                    )
                  )}
              </Form.Select>
              {requestBody?.contentsCostValueType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Basement Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.basementType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    basementType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.BasementType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.BasementType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
              </Form.Select>
              {requestBody?.basementType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Attached Garage Type</Form.Label>
              <Form.Select
                type="text"
                name="occupancy"
                value={requestBody?.attachedGarageType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    attachedGarageType: e.target.value,
                  })
                }
              >
                <option value=""></option>
                {requestBody?.productType === "Residential" &&
                  ResidentialDataDD?.AttachedGarageType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}

                {requestBody?.productType === "Commercial" &&
                  CommercialDataDD?.AttachedGarageType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
              </Form.Select>
              {requestBody?.attachedGarageType === "" && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Year Built</Form.Label>
              <Form.Control
                type="text"
                value={requestBody?.yearBuilt}
                onChange={(e) => {
                  setRequestBody({
                    ...requestBody,
                    yearBuilt: Number(e.target.value),
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Square Footage</Form.Label>
              <Form.Control
                type="text"
                value={requestBody?.squareFootage}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    squareFootage: Number(e.target.value),
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Number of Stories</Form.Label>
              <Form.Control
                type="text"
                value={requestBody?.numberOfStories}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    numberOfStories: Number(e.target.value),
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Elevation Height</Form.Label>
              <Form.Control
                type="text"
                value={requestBody?.elevationHeight}
                onChange={(e) => {
                  setRequestBody({
                    ...requestBody,
                    elevationHeight: Number(e.target.value),
                  });
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Building Over Water?</Form.Label>
              <Form.Select
                type="text"
                value={requestBody?.buildingOverWaterType}
                onChange={(e) =>
                  setRequestBody({
                    ...requestBody,
                    buildingOverWaterType: e.target.value,
                  })
                }
              >
                {/* //data will be same for Commercial & Residential */}
                <option value=""></option>
                {ResidentialDataDD?.BuildingOverWaterType?.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  )
                )}
              </Form.Select>
              {hiscoxBuildingType?.includes(
                requestBody?.buildingOverWaterType
              ) && (
                <Form.Text className="text-danger">
                  This field is required
                </Form.Text>
              )}
            </Form.Group>

            {Object.keys(requestBody).includes("stockReplacementCostValue") && (
              <Form.Group>
                <Form.Label>Stock Replacement Cost Value</Form.Label>
                <Form.Control
                  type="text"
                  value={requestBody.stockReplacementCostValue}
                  onChange={(e) =>
                    setRequestBody({
                      ...requestBody,
                      stockReplacementCostValue: Number(e.target.value),
                    })
                  }
                />
              </Form.Group>
            )}
          </Form>
          <div
            style={{
              marginTop: "2rem",
              padding: "0.5rem",
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
              gap: "2rem",
            }}
          >
            <LimitsTable
              commercialProductType={commercialProductType}
              requestBody={requestBody}
              setRequestBody={setRequestBody}
            />
            <PriorLossesTable
              requestBody={requestBody}
              setRequestBody={setRequestBody}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex gap-2">
          <Button
            variant="primary"
            onClick={onHide}
            id="common-btn"
            style={{ padding: "9px  6px" }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            id="common-btn"
            style={{ padding: "9px  6px" }}
          >
            {loading ? (
              <>
                <Spinner animation="border" size="sm" /> Renewing
              </>
            ) : (
              "Renew"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <PremiumModal
        handleClosePremiumModal={handleClosePremiumModal}
        handleTransaction={handleTransaction}
        setDataForRenewal={setDataForRenewal}
        show={showPremiumModal}
        onHide={handleClosePremiumModal}
        premiumResponseData={premiumResponseData}
        productType={productType}
        commercialProductType={commercialProductType}
        quoteDetailData={quoteDetailData}
        productCode={productCode}
        productId={productId}
        rootLoading={loading}
      />
    </>
  );
}
