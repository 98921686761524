import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Grid,
  GridItem,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { VscDiffAdded } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { postDeletePolicyMortagee } from "../../../api/postDeletePolicyMortagee";
import useDebounce from "../../../utility/useDebounce";
import { getAddrByZip } from "../../../api/getAddrByZip";
import { ZipSelectionModal } from "./ZipLocationSelectModal";

const AdditionalInsuredModal = ({
  isOpen,
  setIsOpen,
  bindDetails,
  setBindDetails,
  setSaveAdditionalInsured,
  quoteDetailsData,
}) => {
  const { t } = useTranslation();
  const [mortgagees, setMortgagees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zipData, setZipData] = useState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isOpenInternal, setIsOpenInternal] = useState(false);

  const [newMortgagee, setNewMortgagee] = useState({
    person: {
      dob: "",
      firstName: "",
      lastName: "",
      entityType: "INDIVIDUAL",
    },
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateCode: "",
      zip: "",
    },
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    setNewMortgagee({
      person: {
        dob: "",
        firstName: "",
        lastName: "",
        entityType: "INDIVIDUAL",
      },
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        stateCode: "",
        zip: "",
      },
    });
  };

  const handleAddMortgagee = () => {
    if (mortgagees.length < 5) {
      setMortgagees([...mortgagees, newMortgagee]);
      setNewMortgagee({
        person: {
          dob: "",
          firstName: "",
          lastName: "",
          entityType: "INDIVIDUAL",
        },
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          stateCode: "",
          zip: "",
        },
      });
    } else {
      toast.warn(t("Maximum of 5 Additonal Insured is allowed"), {
        autoClose: 3500,
      });
    }
  };

  useEffect(() => {
    if (
      quoteDetailsData?.get_quote_response?.data &&
      quoteDetailsData?.get_quote_response?.data?.additionalInsured &&
      Array.isArray(
        quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data
      )
    ) {
      setMortgagees(
        quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data
      );
    }
  }, [quoteDetailsData]);

  const deleteMortagee = async (mortgagee) => {
    try {
      setLoading(true);
      if (
        mortgagee?.n_PolicyAddInterest_PK === null ||
        mortgagee?.n_PolicyAddInterest_PK === undefined
      ) {
        setMortgagees(
          mortgagees.filter((m) => m.uniqueId !== mortgagee.uniqueId)
        );
      } else {
        const reqBody = {
          transPK: quoteDetailsData?.get_quote_response?.data?.poTransactionPk,
          n_PolicyAddInterest_PK: mortgagee?.n_PolicyAddInterest_PK,
        };
        const response = await postDeletePolicyMortagee(reqBody);
        if (response.success && response.data.Status === "Y") {
          toast.success(t("Deleted!", { autoClose: 1500 }));
          setMortgagees(
            mortgagees.filter((m) => m.uniqueId !== mortgagee.uniqueId)
          );
        }
      }
    } catch (error) {
      console.error("Error deleting mortgagee:", error);
      toast.error(t("Error deleting mortgagee. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedNewMortgagee;
    const newUniqueId = mortgagees.length + 1;
    updatedNewMortgagee = {
      ...newMortgagee,
      uniqueId: newUniqueId,
      mortgageeType: null,
      personType: "ADDITIONALINSURED",

      loanNo: name === "loanNo" ? value : newMortgagee?.loanNo || "",
      person: {
        ...newMortgagee.person,
        dob: name === "dob" ? value : newMortgagee?.person?.dob || "",
        entityType:
          name === "entityType"
            ? value
            : newMortgagee?.person?.entityType || "",
        firstName:
          name === "firstName" ? value : newMortgagee?.person?.firstName || "",
        lastName:
          name === "lastName" ? value : newMortgagee?.person?.lastName || "",
      },
      address: {
        ...newMortgagee?.address,
        // addressLine1:
        //   name === "mailingAddress1"
        //     ? value
        //     : newMortgagee.address?.addressLine1 || null,
        // addressLine2:
        //   name === "mailingAddress2"
        //     ? value
        //     : newMortgagee?.address?.addressLine2 || null,
        addressLine1:
          name === "addressLine1"
            ? value
            : newMortgagee.address?.addressLine1 || "",
        addressLine2:
          name === "addressLine2"
            ? value
            : newMortgagee?.address?.addressLine2 || null,
        zip: name === "zip" ? value : newMortgagee?.address?.zip || "",
      },
    };
    setNewMortgagee(updatedNewMortgagee);
  };

  const debouncedZipCode = useDebounce(newMortgagee.address?.zip, 1000);
  useEffect(() => {
    const fetchAddressData = async () => {
      if (debouncedZipCode && debouncedZipCode.length > 4) {
        try {
          setLoading(true);
          const data = await getAddrByZip({ zipCode: debouncedZipCode });
          if (
            data?.data?.status === "Y" &&
            Array.isArray(data?.data?.zipData)
          ) {
            if (data?.data?.zipData?.length > 1) {
              setIsOpenInternal(true);
              setZipData(data?.data?.zipData);
            } else if (data?.data?.zipData?.length === 1) {
              setSelectedLocation(data?.data?.zipData[0]);
            }
          } else if (data?.data?.status === "N" && data?.data?.errorMsg) {
            toast.warn(data?.data?.errorMsg, {
              autoClose: 2500,
            });
          }
        } catch (err) {
          console.error("Error fetching address data:", err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAddressData();
  }, [debouncedZipCode]);

  const handleDeleteMortgagee = (index) => {
    deleteMortagee(mortgagees[index]);
  };

  const handleSaveMortgagee = () => {
    const mortgageeData = mortgagees?.map((mortgagee) => ({
      n_PolicyAddInterest_PK: null,
      personType: mortgagee?.personType || "ADDITIONALINSURED",
      mortgageeType: "",
      loanNo: mortgagee?.loanNo || null,
      person: {
        dob: mortgagee?.person?.dob || null,
        entityType: "INDIVIDUAL",
        firstName: mortgagee?.person?.firstName,
        middleName: null,
        lastName: mortgagee?.person?.lastName,
        isMailingDiff: null,
        isTempMailing: null,
        isInsuredTenant: null,
      },
      address: {
        ...mortgagee?.address,
        addressLine1: mortgagee?.address?.addressLine1 || null,
        addressLine2: mortgagee?.address?.addressLine2 || null,
        county: mortgagee?.address?.county || null,
        city: mortgagee?.address?.city || null,
        stateCode: mortgagee?.address?.state || null,
        country: mortgagee?.address?.country || null,
        latitude: null,
        longitude: null,
        zip: mortgagee?.address?.zip || null,
      },
    }));
    if (mortgageeData.length > 0) {
      setBindDetails({
        ...bindDetails,
        additionalInsuredData: mortgageeData,
        doesPolicyHaveAdditionalInsured: "YES",
      });
      setSaveAdditionalInsured(true);
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setNewMortgagee({
        ...newMortgagee,
        address: {
          ...newMortgagee.address,
          city: selectedLocation?.s_CityName || "",
          // zip: selectedLocation?.n_Zipcodes_PK,
          cityId: selectedLocation?.city_id || "",
          country:
            typeof selectedLocation?.n_CountryId_FK === "number"
              ? selectedLocation?.n_CountryId_FK
              : 1 || "",
          county: selectedLocation?.s_CountyName || "",
          countyId: selectedLocation?.county_id || "",
          stateCode: selectedLocation?.s_StateName || "",
          stateId: selectedLocation?.n_StateId_PK || "",
        },
      });
    }
  }, [selectedLocation]);

  const isDisabled =
    (newMortgagee?.person?.entityType === "ORGANIZATION" &&
      newMortgagee?.person?.lastName === "") ||
    (newMortgagee?.person?.entityType === "INDIVIDUAL" &&
      (newMortgagee?.person?.firstName === "" ||
        newMortgagee?.person?.lastName === ""));

  return (
    <>
      <ZipSelectionModal
        isOpen={isOpenInternal}
        setOpenModal={setIsOpenInternal}
        setZipData={setSelectedLocation}
        selectedLocation={zipData}
      />
      <Modal isCentered size={"4xl"} isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom={"1px solid lightgray"}
            display={"flex"}
            alignItems={"center"}
            gap={3}
          >
            Add Additional Insured(s) {loading && <Spinner />}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            <Grid gap={6} templateColumns="repeat(2, 1fr)">
              <GridItem colSpan={2}>
                <FormControl w={"31%"}>
                  <FormLabel>{t("Insured Type")}</FormLabel>
                  <Select
                    name="entityType"
                    value={newMortgagee?.person?.entityType}
                    onChange={handleInputChange}
                  >
                    <option value="INDIVIDUAL">{t("Individual")}</option>
                    <option value="ORGANIZATION">{t("Organization")}</option>
                  </Select>
                </FormControl>
              </GridItem>
              {newMortgagee?.person?.entityType === "INDIVIDUAL" && (
                <GridItem colSpan={2}>
                  <Flex flexDir={"row"} gap={4}>
                    <FormControl id="mortgageeName" isRequired>
                      <FormLabel>{t("First Name")}</FormLabel>
                      <Input
                        type="text"
                        name="firstName"
                        value={newMortgagee.person?.firstName}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl id="mortgageeName" isRequired>
                      <FormLabel>{t("Last Name")}</FormLabel>
                      <Input
                        type="text"
                        name="lastName"
                        value={newMortgagee.person?.lastName}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl id="loanNo">
                      <FormLabel>
                        {t("Birth Date")} ({t("Optional")})
                      </FormLabel>
                      <Input
                        name="dob"
                        type="date"
                        value={newMortgagee.person?.dob}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Flex>
                </GridItem>
              )}

              {newMortgagee?.person?.entityType === "ORGANIZATION" && (
                <GridItem colSpan={2}>
                  <FormControl id="mortgageeName" isRequired>
                    <FormLabel>{t("Organization Name")}</FormLabel>
                    <Input
                      type="text"
                      name="lastName"
                      value={newMortgagee.person?.lastName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </GridItem>
              )}

              <GridItem>
                <FormControl>
                  <FormLabel ml={1}>{t("Address Line 1")}</FormLabel>
                  <Input
                    placeholder="Street Address"
                    type="text"
                    name="addressLine1"
                    value={newMortgagee?.address?.addressLine1}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl>
                  <FormLabel ml={1}>{t("Address Line 2")} </FormLabel>
                  <Input
                    type="text"
                    name="addressLine2"
                    placeholder="(optional)"
                    value={newMortgagee?.address?.addressLine2}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={2}>
                <Flex flexDir={"row"} gap={4}>
                  <FormControl>
                    <FormLabel>{t("Zip Code")}</FormLabel>
                    <Input
                      type="number"
                      name="zip"
                      placeholder="Zip Code"
                      value={newMortgagee?.address?.zip}
                      onChange={(e) => {
                        if (e.target.value.length <= 5) {
                          handleInputChange(e);
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t("State")}</FormLabel>
                    <Input
                      type="text"
                      name="stateCode"
                      placeholder="State"
                      // isDisabled
                      // _disabled={{ bg: "gray.200", color: "black" }}
                      value={newMortgagee?.address?.stateCode}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t("City")}</FormLabel>
                    <Input
                      type="text"
                      name="city"
                      placeholder="City"
                      // isDisabled
                      // _disabled={{ bg: "gray.200", color: "black" }}
                      value={newMortgagee?.address?.city}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </Flex>
              </GridItem>

              <GridItem colSpan={2}>
                <Flex flexDir={"row"} gap={4}>
                  <FormControl>
                    <FormLabel></FormLabel>
                    <Flex mt={8} justifyContent={"flex-end"}>
                      <Button
                        _hover={{ bgColor: "primary.400" }}
                        onClick={handleAddMortgagee}
                        isDisabled={isDisabled}
                      >
                        <Box mr={1}>
                          <VscDiffAdded />
                        </Box>
                        Add Additional Insured
                      </Button>
                    </Flex>
                  </FormControl>
                </Flex>
              </GridItem>
            </Grid>

            {mortgagees.length !== 0 && (
              <Box mt={8} overflowX="auto">
                <Table
                  variant="simple"
                  border={"1px solid"}
                  borderColor="gray.100"
                >
                  <Thead>
                    <Tr>
                      <Th>{t("#")}</Th>
                      <Th>{t("Insured Type")}</Th>
                      <Th>{t("Name")}</Th>
                      <Th>{t("Date of Birth")}</Th>
                      <Th>{t("Address")}</Th>

                      <Th>{t("Action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Array.isArray(mortgagees) &&
                      mortgagees.map((mortgagee, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{mortgagee?.person?.entityType || ""}</Td>
                          <Td>
                            {mortgagee?.person?.firstName +
                              " " +
                              mortgagee?.person?.lastName || ""}
                          </Td>
                          <Td>{mortgagee?.person?.dob || ""}</Td>
                          <Td>
                            {`
                            ${mortgagee?.address?.addressLine1 || ""},  ${
                              mortgagee?.address?.city || ""
                            }, ${mortgagee?.address?.stateCode || ""}
                           `}
                          </Td>
                          <Td>
                            <Button
                              size="sm"
                              _hover={{
                                bgColor: "primary.400",
                              }}
                              onClick={() => handleDeleteMortgagee(index)}
                            >
                              <RiDeleteBin2Line size={20} />
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>

          <ModalFooter borderTop={"1px solid"} borderColor={"primary.500"}>
            {mortgagees.length !== 0 && (
              <Button
                _hover={{ bgColor: "primary.400" }}
                w={"4rem"}
                size={"md"}
                mr={3}
                onClick={handleSaveMortgagee}
              >
                {t("Save")}
              </Button>
            )}
            <Button
              color={"primary.500"}
              border={"1px solid"}
              size={"md"}
              borderColor={"neutral.300"}
              variant="ghost"
              onClick={handleCloseModal}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <style>
        {`
        td {
          border: 1px solid;
          border-color: primary.200;
        }
      `}
      </style>
    </>
  );
};

export default AdditionalInsuredModal;