import React from "react";
import GoogleMaps from "../../utility/google-map/GoogleMap";

const GoogleMap = () => {
  return (
    <div>
      <GoogleMaps />
    </div>
  );
};

export default GoogleMap;
