import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { Currency } from "../../../../common/Currency";
import { useTranslation } from "react-i18next";
import SaveHome from "../../../../../assets/star/icons/save-home.svg";
import { useQuote } from "../../CommCreateQuote";
import { formateDate } from "../../../../common/FormatDate";

export const PaymentSection = () => {
  const { t } = useTranslation();
  const { masterQuoteData, location } = useQuote();
  return (
    <>
      <Box>
        <Box
          border="1px solid var(--chakra-colors-neutral-200)"
          p="3"
          borderRadius="10px"
        >
          <Flex>
            <HStack>
              <Image
                src={SaveHome}
                width={70}
                height={70}
                alt=""
                style={{
                  marginTop: "10px",
                }}
              />
              <Flex
                flexDir={"column"}
                alignItems={"flex-start"}
                justifyContent={"center"}
                mt={2.5}
              >
                <Text
                  textAlign={"left"}
                  fontSize={"24px"}
                  mb={"10px"}
                  fontWeight="var(--chakra-fontWeights-medium)"
                >
                  {masterQuoteData?.applicantDetails?.lastName}
                </Text>
                <Text
                  fontSize={"16px"}
                  color="var(--chakra-colors-neutral-400)"
                  lineHeight="0px"
                >
                  {location?.fullAddress || ""}
                </Text>
              </Flex>
            </HStack>
            <Spacer />
            <VStack mt={3} gap={0}>
              <Text
                fontSize={"24px"}
                mb={"10px"}
                fontWeight="var(--chakra-fontWeights-medium)"
              >
                {t("coverage_effective")}
              </Text>
              <Text
                fontSize={"16px"}
                color="var(--chakra-colors-neutral-400)"
                lineHeight="0px"
              >
                {formateDate(masterQuoteData?.policy?.effectiveDate)} -{" "}
                {formateDate(masterQuoteData?.policy?.effectiveDate)}
              </Text>
            </VStack>
          </Flex>
          <Box my={8} borderTop={"1px solid"} borderColor={"gray.200"} />
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
            }}
            gap={4}
            p="25px 10px 25px 10px"
          >
            <GridItem>
              <TableContainer
                mt={"17px"}
                rounded="xl"
                borderRadius={"xl"}
                // h={"96.5%"}
              >
                <Table variant="none" h={"100%"}>
                  <Thead bg={"primary.50"}>
                    <Tr className="trClass">
                      <Text
                        w={"50%"}
                        py={4}
                        fontSize={"xl"}
                        pl={6}
                        as={"th"}
                        textDecor={"none"}
                      >
                        {t("Basic Coverages")}
                      </Text>
                      <Text
                        py={4}
                        fontSize={"xl"}
                        pr={6}
                        as={"th"}
                        textAlign="right"
                      >
                        {t("Limits")}
                      </Text>
                    </Tr>
                  </Thead>

                  <Tbody>
                    <Tr className="trClass">
                      <Td className="trClass">{t("Cost To Rebuild")}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        {<Currency amount={0} />}
                      </Td>
                    </Tr>
                    <Tr className="trClass">
                      <Td className="trClass">{t("other_structures")}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        {<Currency amount={0} />}
                      </Td>
                    </Tr>
                    <Tr className="trClass">
                      <Td className="trClass">{t("contents")}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        {<Currency amount={0} />}
                      </Td>
                    </Tr>
                    <Tr className="trClass">
                      <Td className="trClass">{t("-")}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        {<Currency amount={0} />}
                      </Td>
                    </Tr>
                    <Tr className="trClass">
                      <Td className="trClass">{t("-")}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        {<Currency amount={0} />}
                      </Td>
                    </Tr>
                    {/* <Tr my={0}>
                  <Td className="trClass" border={"none"}></Td>
                  <Td className="trClass" border={"none"}></Td>
                </Tr>
                <Tr my={0}>
                  <Td className="trClass" border={"none"}></Td>
                  <Td className="trClass" border={"none"}></Td>
                </Tr> */}
                    {/* <Tr className="trClass">
                    <Td className="trClass"></Td>
                    <Td className="trClass"></Td>
                  </Tr> */}
                  </Tbody>
                  <Tbody
                    background="var(--chakra-colors-primary-500)"
                    overflow="hidden"
                  >
                    <Tr justifyContent={"space-between"} my={3}>
                      <Td
                        py={4}
                        pl={6}
                        color="var(--chakra-colors-white)"
                        fontSize="xl"
                        fontWeight="600"
                      >
                        {t("total_premium")}
                      </Td>
                      <Td
                        py={4}
                        pr={6}
                        textAlign={"end"}
                        color={"white"}
                        fontSize={"xl"}
                        letterSpacing={"1px"}
                        fontWeight={600}
                      >
                        <Text
                          my={"0.15rem"}
                          fontSize={"xl"}
                          textAlign={"center"}
                        >
                          <Currency amount={0} />
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <TableContainer mt={"17px"} rounded="xl" borderRadius={"xl"}>
                <Table variant="none" h={"100%"}>
                  <Thead bg={"primary.50"}>
                    <Tr className="trClass">
                      <Text
                        w={"50%"}
                        py={4}
                        fontSize={"xl"}
                        pl={6}
                        as={"th"}
                        textDecor={"none"}
                      >
                        {t("Fees")}
                      </Text>
                      <Text
                        py={4}
                        fontSize={"xl"}
                        pr={6}
                        as={"th"}
                        textAlign="right"
                      >
                        {t("Amount")}
                      </Text>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {/* {Object.entries(feesData?.fees || {}).map(
                      ([feeName, feeAmount]) => (
                        <Tr key={feeName} className="trClass">
                          <Td className="trClass">{feeName}</Td>
                          <Td
                            className="trClass"
                            textAlign="right"
                            fontWeight={500}
                          >
                            <Currency amount={feeAmount} />
                          </Td>
                        </Tr>
                      )
                    )} */}
                    <Tr className="trClass">
                      <Td className="trClass">-</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        <Currency amount={0} />
                      </Td>
                    </Tr>
                    <Tr className="trClass">
                      <Td className="trClass">-</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        <Currency amount={0} />
                      </Td>
                    </Tr>
                  </Tbody>
                  <Tbody
                    background="var(--chakra-colors-primary-500)"
                    overflow="hidden"
                  >
                    <Tr justifyContent={"space-between"} my={3}>
                      <Td
                        py={4}
                        pl={6}
                        color="var(--chakra-colors-white)"
                        fontSize="xl"
                        fontWeight="600"
                      >
                        {t("Total Fees")}
                      </Td>
                      <Td
                        py={4}
                        pr={6}
                        textAlign={"end"}
                        color={"white"}
                        fontSize={"xl"}
                        letterSpacing={"1px"}
                        fontWeight={600}
                      >
                        <Text
                          my={"0.15rem"}
                          fontSize={"xl"}
                          textAlign={"center"}
                        >
                          <Currency amount={0} />
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </GridItem>
            <GridItem>
              <Box
                background="var(--chakra-colors-primary-50)"
                p="190px 40px 190px 40px"
                borderRadius="2xl"
                mt="15px"
                height={"98%"}
              ></Box>
            </GridItem>
          </Grid>
          <style>
            {`
          .trClass {
           border: 1px solid var(--chakra-colors-primary-50);
          }
        `}
          </style>
        </Box>
      </Box>
    </>
  );
};
