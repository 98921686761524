import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { formateDate } from "../../common/FormatDate";

export default function DebarmentInformation({ debarrmentData }) {
  const { t } = useTranslation();
  return (
    <>
      <Box>
        <Text mb={0} fontWeight={500} fontSize="xl">
          {t("Debarment Information")}
        </Text>
        <Box
          mt={2}
          p={4}
          borderRadius={"2xl"}
          boxShadow={"0 0 4px rgba(0, 0, 0, 0.5)"}
        >
          <Grid
            alignItems={"end"}
            templateColumns={"repeat(3, 1fr)"}
            rowGap={4}
            columnGap={6}
          >
            <FormControl>
              <FormLabel>{t("Debarment Approval Date")}:</FormLabel>
              <Input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={
                  debarrmentData?.debarment_approval_date != null
                    ? formateDate(debarrmentData?.debarment_approval_date)
                    : ""
                }
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Debarment Expiration Date")}:</FormLabel>
              <Input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={
                  debarrmentData?.debarment_expiration_date != null
                    ? formateDate(debarrmentData?.debarment_expiration_date)
                    : ""
                }
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Debarred/Suspended Date")}:</FormLabel>
              <Input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={
                  debarrmentData?.debarred_date != null
                    ? formateDate(debarrmentData?.debarred_date)
                    : ""
                }
                isDisabled={true}
              />
            </FormControl>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
