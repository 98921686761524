import Http from '../Http';

export function showCommissions(accountIdFk) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get("/api/showCommissions/"+accountIdFk)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function showCommStmtDetails(n_PAAgentStatementMaster_PK) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get("/api/showCommStmtDetails/"+n_PAAgentStatementMaster_PK)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function printBatchAgentStatement(pAAgentStatementMasterPK) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.get("/api/printBatchAgentStatement/"+pAAgentStatementMasterPK)
            .then((res) => {
              return resolve(res.data);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}
