import React, { Fragment, useState, useEffect } from "react";
import ModeHomeType from "../form-mode/ModeHomeType";
import { connect, useDispatch, useSelector } from 'react-redux';
//import StepWizard from "react-step-wizard";
import { Button, Modal } from "react-bootstrap";
import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../services/floodService";
import { checkPivotOutage } from "../../../../services/commonService";

const ModeRouter = (props) => {
  const [premium, setPremium] = useState(0);
  const isPivotOutage = checkPivotOutage();
  const [errorModal, setErrorModal] = useState(null);
  const [isFirmUpdated, setIsFirmUpdated] = useState(false);

  const dispatch = useDispatch();

  const {
    addressAnalysisGeoData,
    quoteResponseDetails,
    ZDDetailsFromAddress,
    createQuoteResponse
  } = useSelector(state => state.flood);


  useEffect(() => {

    if (Object.keys(quoteResponseDetails).length > 0) {
      if (quoteResponseDetails.data && Object.keys(quoteResponseDetails.data).length > 0) {
        if (isPivotOutage == 'true') {
          setPremium(quoteResponseDetails.data.premium || 0);
        } else {
          var code = quoteResponseDetails.data.code || 0;
          if ([200, 201].includes(code) && Object.keys(quoteResponseDetails.data.rreQuote).length > 0) {
            setPremium(quoteResponseDetails.data.rreQuote.premium.totalAnnualPayment || 0);
          } else {
            showError();
            setPremium(0);
          }
        }

      }
    }
  }, [quoteResponseDetails, createQuoteResponse]);


  useEffect(() => {
    if (ZDDetailsFromAddress && props.emptyQuoteData && Object.keys(ZDDetailsFromAddress).length > 0  && Object.keys(props.emptyQuoteData).length > 0) {
      setzDDetails(ZDDetailsFromAddress);
    }
  }, [ZDDetailsFromAddress, props.emptyQuoteData])

  const setzDDetails = async (zDData) => {
    // setting zone determination 
    const emptyQuote = { ...props.emptyQuoteData };
    const NFIPCommunityFIRMDate =zDData.NFIPCommunityFIRMDate;
    let InitFirmDate = '';
    if (NFIPCommunityFIRMDate !== '' && NFIPCommunityFIRMDate !== null && NFIPCommunityFIRMDate !== '0000-00-00') {
      InitFirmDate = NFIPCommunityFIRMDate;
      }
      
    emptyQuote.propertyInfo.s_CountyName = (zDData.CountyName || '');
    emptyQuote.propertyInfo.s_CommunityNumber = (zDData.CommunityNumber || '');
    emptyQuote.propertyInfo.d_InitialFirmDate = InitFirmDate
    const NFIPCommunityParticipationStatusType = zDData.NFIPCommunityParticipationStatusType || '';
    emptyQuote.propertyInfo.s_FloodProgramType = (NFIPCommunityParticipationStatusType.includes('Regular') ? 'FLOODREGULAR' : NFIPCommunityParticipationStatusType.includes('Emergency') ? 'FLOODEMERGENCY' : '') || null;

    const ProtectedAreaIndicator = zDData.cbrs || '';
    emptyQuote.propertyInfo.s_CBRSorOPA = ProtectedAreaIndicator === 'Y' ? 'YES' : ProtectedAreaIndicator === 'N' ? 'NO' : 'NO';
    let CurrentFirmDate = '';
      const NFIPMapPanelDate = (zDData.NFIPCommunityFIRMDate || '');
    if (NFIPMapPanelDate !== '' && NFIPMapPanelDate !== null && NFIPMapPanelDate !== '0000-00-00') {
      CurrentFirmDate = NFIPMapPanelDate;
    }
    emptyQuote.propertyInfo.d_CurrentFirmDate = CurrentFirmDate
    emptyQuote.propertyInfo.s_PanelNumber = (zDData.PanelNumber || '');
    emptyQuote.propertyInfo.s_MapSuffix = (zDData.MapSuffix || '');
    var floodZone = (zDData.Floodzone || '');
    emptyQuote.propertyInfo.s_FloodZone = (['X500', 'X100'].includes(floodZone) ? 'X' : floodZone || '');
    if (zDData.BaseElevation) {
      var elevation = Math.round(zDData.BaseElevation);
    } else {
      var elevation = ''
    }
    emptyQuote.propertyInfo.s_BaseElevation = (elevation || null);
    emptyQuote.personAddr.s_countyFips = zDData.CountyFips.slice(-3);
    emptyQuote.personAddr.s_stateFips = zDData.StateFips;
    emptyQuote.personAddr.s_Latitude = zDData.Latitude ?? '';
    emptyQuote.personAddr.s_Longitude = zDData.Longitude ?? '';

    // Zone determination end
    await dispatch(getEditEmptyQuoteData(emptyQuote));
  }

  // For All Input/Select Handler
  const handleChange = (e, fieldValue = '') => {
    var quoteDataVar = props.emptyQuoteData;
    if (fieldValue) {
      fieldValue = fieldValue.trim().toUpperCase();
      var firstName = '', lastName = '', fullName = '', char = '', result = [], addInter = [];

      if (fieldValue.indexOf("AND") !== -1) {
        char = "AND";
        fullName = fieldValue.slice(0, fieldValue.indexOf("AND"))
      } else if (fieldValue.indexOf("&") !== -1) {
        char = "&";
        fullName = fieldValue.slice(0, fieldValue.indexOf("&"))
      } else {
        fullName = fieldValue
      }

      // First Name and Last Name
      const [first, last] = getFirstLastName(fullName);
      firstName = first;
      lastName = last;

      // get all additional insured
      result = findAllSubString(fieldValue, char);
      if (result.length > 0) {
        for (var i = 1; i < result.length; i++) {
          if (result[i].length > 0) {
            const [first, last] = getFirstLastName(result[i], true);
            // additional interest JSON
            var addInJson = {
              s_HouseNo: quoteDataVar['personAddr']['s_HouseNo'],
              s_FirstName: first,
              s_LastOrganizationName: last.length === 0 ? lastName : last,
              s_AddressLine1: quoteDataVar['personAddr']['s_AddressLine1'],
              s_StreetName: quoteDataVar['personAddr']['s_StreetName'],
              s_CityName: quoteDataVar['personAddr']['s_CityName'],
              s_StateName: quoteDataVar['personAddr']['s_StateName'],
              s_CountyName: quoteDataVar['personAddr']['s_CountyName'],
              s_PostalCode: quoteDataVar['personAddr']['s_PostalCode'],
              s_CountryName: quoteDataVar['personAddr']['s_CountryName'],
            }
            addInter.push(addInJson);
          }
        }
      }

      if (addInter.length > 0) {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'Yes';
      } else {
        quoteDataVar['propertyInfo']['s_IsAddInsureds'] = 'No';
      }
      quoteDataVar['additionalInsuredData'] = addInter;
      quoteDataVar['personInfo']['s_FirstName'] = firstName;
      quoteDataVar['personInfo']['s_LastOrganizationName'] = lastName;
    } else {
      const { name, value, attributes } = e.target;
      const parentKey = attributes.parentobject.value;
      quoteDataVar[parentKey][name] = value;

      // Update Date Of Construction If Year Built Changed
      if (name === 's_YearBuilt') {
        quoteDataVar[parentKey]['d_DateOfConstruction'] = value ? new Date(value + '-01-01') : '';
        setIsFirmUpdated(false);
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  const getFirstLastName = (fieldValue, isAddInsureds = false) => {
    var firstName = '';
    var lastName = '';
    var length = 0;
    fieldValue = fieldValue.trim();
    if (fieldValue.indexOf(",") === -1) {
      length = fieldValue.split(" ").length
      firstName = fieldValue.split(" ")[0] || '';
      lastName = fieldValue.substr(fieldValue.indexOf(" ") + 1) || '';

      if (length === 1) {
        lastName = firstName;
        firstName = ''
      }
    } else {
      firstName = fieldValue.substr(fieldValue.indexOf(",") + 1) || '';
      lastName = fieldValue.split(",")[0] || '';
    }
    if (isAddInsureds === true && firstName.length === 0) {
      firstName = lastName;
      lastName = ''
    }
    return [firstName.trim(), lastName.trim()];
  }

  const findAllSubString = (fieldValue, char) => {
    if (char.length === 0) return [];
    var idArray = [], stringsArray = [];
    var id = 0, idx = fieldValue.indexOf(char);
    // Find If String present in given string of Not
    while (idx !== -1) {
      idArray.push(idx);
      idx = fieldValue.indexOf(char, idx + 1);
    }

    if (idArray.length > 0) {
      // Get Array of String After Split it
      for (var i = 0; i <= idArray.length; i++) {
        if (id !== 0) {
          stringsArray.push(fieldValue.slice(id, idArray[i]).trim())
        } else {
          stringsArray.push(fieldValue.slice(0, idArray[i]).trim())
        }
        char === 'AND' ? id = idArray[i] + 3 : id = idArray[i] + 1;
      }
    }
    return stringsArray;
  }

  // For All App Switch Handler
  const handleSwitchChange = (e) => {
    const { name, attributes, checked } = e.target;
    var parentObjectName = attributes.parentobject.value;
    var quoteDataVar = props.emptyQuoteData;
    if (checked) {
      quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
    } else {
      quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;

      // Updated Dependent Question Value
      if (name === 's_IsPriorNFIPLapsed' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['s_IsCommunitySuspension'] = '';
      }
      if (name === 's_IsNewPurchase' && ['', 'NO'].includes(attributes.unCheckedValue.value)) {
        quoteDataVar[parentObjectName]['isSellerDec'] = '';
      }
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  const showError = () => {
    const resp = quoteResponseDetails && Object.keys(quoteResponseDetails.data).length > 0 ? quoteResponseDetails.data : {};

    resp && Object.keys(resp).length > 0 && resp.code && ![200, 201].includes(resp.code) && (
      setErrorModal(<Modal show={true}>
        <Modal.Header>
          <Modal.Title>Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            resp && Object.keys(resp).length > 0 && ![200, 201].includes(resp.code) && (
              <React.Fragment>
                <span style={{ color: 'red' }}>
                  {resp.message}
                </span>
                {
                  resp.fieldErrors && resp.fieldErrors.length > 0 && (
                    <ul>
                      {
                        resp.fieldErrors.map((val, key) => {
                          return <li key={key}>{val.field} - {val.message}</li>
                        })
                      }
                    </ul>
                  )
                }
                {
                  resp.ruleFailures && resp.ruleFailures.length > 0 && (
                    <ul>
                      {
                        resp.ruleFailures.map((val, key) => {
                          if (val.rule && val.rule.severity !== 'WARNING' && val.exception && val.exception !== '') {
                            return <li key={key}>{val.exception}</li>
                          }
                        })
                      }
                    </ul>
                  )
                }
              </React.Fragment>
            )
          }
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="outline-dark" onClick={() => setErrorModal(null)}>Close</Button>
        </Modal.Footer>
      </Modal>)
    )
  }

  // check date if valid or not
  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  }


  // For All Date Handler
  const handleDateChange = (date, name, parent) => {
    var quoteDataVar = props.emptyQuoteData;
    if (parent) {
      quoteDataVar[parent][name] = date;
    } else {
      quoteDataVar[name] = date;
    }
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  // For All Radio Button Handler
  const handleRadioChange = (e) => {
    const { name, value, id, attributes } = e.target;
    var quoteDataVar = props.emptyQuoteData;
    quoteDataVar[id][name] = value;
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  return (
    <Fragment>
      <ModeHomeType handleChange={handleChange} premium={premium} checkDateisValid={checkDateisValid} handleDateChange={handleDateChange} handleSwitchChange={handleSwitchChange} handleRadioChange={handleRadioChange} />
    </Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
  };
}

export default connect(mapStateToProps)(ModeRouter);