import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { forgotPass } from "../../services/authService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      toast.error(t('Please enter username!'));
      return false;
    }

    const request = {
      username: username,
      sourceView: "AGENTPORTAL"
    };
    setLoading(true);
    dispatch(forgotPass(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          Swal.fire('Success', response.message, 'success')
          navigate("/login");
        } else {
          setUsername("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t("Please Try again"));
      });
  };

  return (
    <>
      <div className="login-page-section">
        <Form>
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="8" lg="6" xl="5">
              <div className="login-page-card">
                {/* <img src={logo} /> */}
                <span className="login-card-title">{t("Forgot Password")}</span>
                <span className="login-card-subtitle">
                  {t("Reset Your Password")}
                </span>
                <div className="login-card-line-breaker"> </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("Username")}
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    placeholder=""
                    value={username}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    id="common-btn"
                    variant="primary"
                    type="submit"
                    style={{ padding: "12px" }}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {t("Send")}
                  </Button>{" "}
                  <Form.Text id="login-page-form-subtext" >
                    <a href={'/login'}>{t("Login Here")}</a>
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
    </>
  );
};

export default ForgotPassword;
