import * as ActionTypes from '../action-types';
import { logoutSession } from "../../services/authService";

const initialState = {
  isAuthenticated: false,
  getSiteData:''
};

const checkAuth = (state) => {
  const stateObj = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem('access_token'),
  });
  return stateObj;
};

const logout = (state) => {
  const stateObj = Object.assign({}, state, {
    isAuthenticated: false,
  });
  logoutSession();
  localStorage.clear();
  return stateObj;
};

const getSiteData = (state,payload) => {
	const stateObj = Object.assign({}, state, {
		getSiteData: payload,
	});
	return stateObj;
  };

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.AUTH_CHECK:
      return checkAuth(state);
    case ActionTypes.AUTH_LOGOUT:
      return logout(state);
    case ActionTypes.SITE_DATA:
	  return getSiteData(state);
    default:
      return state;
  }
};

export default Auth;
