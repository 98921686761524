import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import RootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
export const history = createBrowserHistory();

const persistConfig = {
  key:'persist-store',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === 'production' ? compose(applyMiddleware(ReduxThunk, routerMiddleware(history))) : composeWithDevTools(applyMiddleware(ReduxThunk, routerMiddleware(history))),
);

export const persistor = persistStore(store);
export default store;
