import Http from "../../../../../Http";

export async function postCommercialQuote(quoteNo, requestBody) {
  const isUpdate = Boolean(quoteNo);
  const endpoint = isUpdate ? `update/${quoteNo}` : "create";
  const url = `/api/v2/quote/commercial/${endpoint}`;

  try {
    const { data } = await Http.post(url, requestBody);
    return data;
  } catch (error) {
    console.error("Error posting commercial quote:", error);
    throw error;
  }
}
