import Http from "../../../../Http";

export async function getBindStatus({ trans_pk }) {
  try {
    const url =
      process.env.REACT_APP_POLICY_CORE_URL + `/api/v1/issueTrans/${trans_pk}`;
    const response = await Http.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
  }
}
