import React, { useEffect } from "react";
import {
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import states from "./States";
import { getTenant } from "../../../services/commonService";
import { starTenancySynonyms } from "./tenantSynonyms";

const ManualEntryForm = ({
  selectedPropertyData,
  setSelectedPropertyData,
  zipData,
  onManualSearch,
  toggleManualEntry,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (zipData && zipData.s_CityName && zipData.s_StateCode) {
      const { s_CityName, s_StateCode, s_CountyName } = zipData;
      const state =
        states.find((state) => state.abbr === s_StateCode)?.name || "";

      setSelectedPropertyData((prevData) => ({
        ...prevData,
        city: s_CityName || "",
        stateCode: s_StateCode || "",
        state,
        CountyName: s_CountyName || "",
      }));
    }
  }, [zipData, setSelectedPropertyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedPropertyData((prevData) => ({
      ...prevData,
      [name]: value,
      autocomplete:
        `${prevData.street_number} ${prevData.street_name} ${prevData.city} ${prevData.CountyName} ${prevData.stateCode} ${prevData.PostalCode}`.trim(),
    }));
  };

  return (
    <>
      <Grid
        my={8}
        p={6}
        templateColumns="repeat(4, 1fr)"
        gap={6}
        alignItems="center"
        mb={8}
      >
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel fontWeight={500} color="black">
              {t("Full Address")}
            </FormLabel>
            <Input
              type="text"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              value={selectedPropertyData?.autocomplete || ""}
              disabled
              name="autocomplete"
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel fontWeight={500} color="black">
              {t("Street Number")}
            </FormLabel>
            <Input
              type="number"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              name="street_number"
              value={selectedPropertyData.street_number}
              onChange={handleChange}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel fontWeight={500} color="black">
              {t("Street Name")}
            </FormLabel>
            <Input
              type="text"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              name="street_name"
              value={selectedPropertyData.street_name}
              onChange={handleChange}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel fontWeight={500} color="black">
              {t("Zip Code")}
            </FormLabel>
            <Input
              type="number"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              value={selectedPropertyData?.PostalCode || ""}
              name="PostalCode"
              onChange={handleChange}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel fontWeight={500} color="black">
              {t("City")}
            </FormLabel>
            <Input
              type="text"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              value={selectedPropertyData?.city || ""}
              name="city"
              onChange={handleChange}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel fontWeight={500} color="black">
              {t("County Name")}
            </FormLabel>
            <Input
              type="text"
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              value={selectedPropertyData?.CountyName || ""}
              name="CountyName"
              onChange={handleChange}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isRequired>
            <FormLabel>{t("State Code")}</FormLabel>
            <Select
              size="md"
              focusBorderColor="secondary.500"
              borderColor="gray.300"
              name="stateCode"
              value={selectedPropertyData?.stateCode || ""}
              onChange={(e) => {
                const stateName = states.find(
                  (state) => state.abbr === e.target.value
                )?.name;
                setSelectedPropertyData((prevData) => ({
                  ...prevData,
                  stateCode: e.target.value,
                  state: stateName,
                }));
                handleChange(e);
              }}
            >
              <option value=""></option>
              {starTenancySynonyms?.includes(getTenant()) ? (
                <option value="MS">Mississippi</option>
              ) : (
                states.map((state) => (
                  <option key={state?.abbr} value={state?.abbr}>
                    {state?.name} ({state?.abbr})
                  </option>
                ))
              )}
            </Select>
          </FormControl>
        </GridItem>
      </Grid>
      <Flex gap={2}>
        <Button
          variant="solid"
          border="1px solid"
          fontWeight={500}
          fontSize="0.9rem"
          borderRadius="md"
          onClick={toggleManualEntry}
        >
          {t("Google Auto Entry")}
        </Button>
        <Button
          onClick={onManualSearch}
          variant="solid"
          border="1px solid"
          fontWeight={500}
          fontSize="0.9rem"
          borderRadius="md"
        >
          {t("Search Manually")}
        </Button>
      </Flex>
    </>
  );
};

export default ManualEntryForm;
