import React from "react";
import { policySearch } from "../../s3Pdf.js";
import Quote from "../../../help/Getting_Started_Quote.pdf";
import createClaim from "../../../help/Getting_Started_Create_Claim.pdf";
import claimSearch from "../../../help/Getting_Started_Claim_Search.pdf";
import { Box, Center, Image, Text, VStack, Wrap, WrapItem, Link } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const GettingStartedSection = () => {
    const { t } = useTranslation();
    //const companyPhone = getCompanyDetails("phone");

    return (
        <div >
            <Wrap width={"52rem"}>
                <Link href={policySearch} target="_blank" p={1} >
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/sendIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("find_a_policy")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={Quote} target="_blank" p={1} >
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("create_a_quote")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={createClaim} target="_blank" p={1}>
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/triangleResourceIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("create_claim")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={claimSearch} target="_blank" p={1}>
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("view_claim")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
            </Wrap>
        </div>
    )
}

export default GettingStartedSection;