export default {
  black: "#000000",
  white: "#FFFFFF",
  primary: {
    50: "#f1e7ff",
    100: "#d9c3fe",
    200: "#bf9aff",
    300: "#a36eff",
    400: "#8b48ff",
    /*500: "#7017ff",*/
    500: "#64d6cd",
    600: "#6211f8",
    /*700: "#4e00f0",*/
    700: "#93E2DC",
    800: "#3500ec",
    900: "#0000e7",
  },
  secondary: {
    50: "#e1f5fe",
    100: "#b3e5fd",
    200: "#81d4fb",
    300: "#4ec2f9",
    400: "#27b5f8",
    500: "#00a8f6",
    600: "#029ae7",
    700: "#0387d3",
    800: "#0376bf",
    900: "#04569d",
  },
  neutral: {
    50: "#F5F5F7",
    100: "#EAEAEC",
    200: "#DCDCDE",
    300: "#C8C8CA",
    400: "#A2A2A4",
    500: "#808082",
    600: "#5A5A5B",
    700: "#474749",
    800: "#2A2A2C",
    900: "#0B0B1A",
  },
  danger: "#E53E3E",
  warning: "#FFCB3D",
  success: "#27AE60",
  info: "#72C8CC",
  agGridHeaderBg: "#CFF2EF",
  linkColor: "#0B7189",
  agGridHeaderFontColor: "#474747"
};
