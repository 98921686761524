import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,InputGroup} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { resetPass, verifyToken } from "../../services/authService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PasswordChecklist from "react-password-checklist";
import PasswordEyeIconButton from "../profile/PasswordEyecon";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showPassword, setshowPassword] =useState(false);
  const[showRetypePassword,setshowRetypePassword] =useState(false);

  useEffect(() => {
    checkToken();
  }, []);

  function checkToken() {
    dispatch(verifyToken(token))
      .catch((err) => {
        const { status } = err.response;
        if (status === 419 || status === 404) {
          navigate("/" + status);
        }
      });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      toast.error(t('Password & Confirm Password are require.'));
      return false;
    }
    if (password !== confirmPassword) {
      toast.error(t('Password and Confirm Password must be same.'));
      return false;
    }

    const isContainFlood = /flood/i.test(password); 
    if(isContainFlood){
        return toast.error('The password must not include the word flood.');
    }
    const isContainPassword = /password/i.test(password);
    if(isContainPassword){
        return toast.error('The password must not include the word Password.');
    }

    const isValidPassword = /^(?!.*password)(?!.*flood)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]).{8,}$/.test(password);
    if(!isValidPassword){
      toast.error(t('Password Validation Failed.'));
      return false;
    }
    const request = {
      password: password,
      confirmPass: confirmPassword,
      token: token,
    };
    setLoading(true);
    dispatch(resetPass(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          navigate("/200/reset-password");
        } else if (response.status === false) {
          toast.error(response.error);
        } else {
          setPassword("");
          setConfirmPassword("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
      const { status, data } = error.response;
      if (status === 421) {
        const { error } = data;
        toast.error(error);
      } else {
        toast.error(t("Please Try again"));
      }
      setLoading(false);
      });
  };

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handleRetypePassword = () => {
    setshowRetypePassword(!showRetypePassword);
  };

  return (
    <>
      <div className="login-page-section">
        <Form>
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="8" lg="6" xl="5">
              <div className="login-page-card">
                {/* <img src={logo} /> */}
                <span className="login-card-title">{t("Reset Password")}</span>
                <span className="login-card-subtitle">
                  {t("Set New Password To Your Account")}
                </span>
                <div className="login-card-line-breaker"> </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("Password")}
                  </Form.Label>
                  <InputGroup id="input_group">
                    <Form.Control
                      className="password-input"
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      required
                      maxLength={25}
                    />
                    <PasswordEyeIconButton
                      handleClick={handlePassword}
                      showPassword={showPassword}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("Retype Password")}
                  </Form.Label>
                  <InputGroup id="input_group">
                    <Form.Control
                      className="password-input"
                      type={showRetypePassword ? "text" : "password"}
                      placeholder=""
                      value={confirmPassword}
                      onChange={(e) =>
                        setConfirmPassword(e.currentTarget.value)
                      }
                      required
                      maxLength={25}
                    />
                    <PasswordEyeIconButton
                      handleClick={handleRetypePassword}
                      showPassword={showRetypePassword}
                    />
                  </InputGroup>
                </Form.Group>
                {password != '' && 
                  <PasswordChecklist
                    rules={["minLength", "capital","lowercase", "number", "specialChar", "match"]}
                    minLength={8}
                    value={password}
                    valueAgain={confirmPassword}
                    messages={{
                      minLength: "Must be 8-25 characters in length and cannot contain any spaces.",
                      capital: "Must contain at least one uppercase alphabetic character: A-Z",
                      lowercase:"Must contain at least one lowercase alphabetic character: a-z",
                      number: "Must contain at least one number",
                      specialChar: "Must contain at least one non-alphabetic 'special' character: i.e.: -#&$*!~@%+",
                      match: "Password match",
                    }}
                  />
                }
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    id="common-btn"
                    variant="primary"
                    type="submit"
                    style={{ padding: "12px" }}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {t("Reset")}
                  </Button>{" "}
                </Form.Group>
              </div>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
    </>
  );
};

export default ResetPassword;
