import bodyFont from "./css/body";
import colors from "./foundations/color";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const overrides = {
  colors,
  styles: {
    global: {
      ...bodyFont,
    },
  },
  config,
};

export default overrides;
