import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { tenant } from "../../../../common/Helper";
import { HomeOwnersTerretory } from "../../../create-star-quote/database/HomeOwnersTerretory";
import ReactTooltip from "react-tooltip";
import { Box } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import { starTenancySynonyms } from "../../../common/tenantSynonyms";

const Deductible = (props) => {
  const { t } = useTranslation();
  function checkNorthIssued(countyName) {
    if (typeof countyName !== "string") {
      return false;
    }
    let formattedCountyName = countyName.trim().replace(/ County$/, "");
    const countyObj = HomeOwnersTerretory.County.find((county) => {
      const key = Object.keys(county)[0];
      return key === formattedCountyName;
    });
    if (countyObj && countyObj?.northIssed) {
      return true;
    } else {
      return false;
    }
  }
  const renderTootTip = (id) => {
    return (
      <>
        <ReactTooltip id={id} className={id && "custom-tooltip"}>
          <div>
            {id === "HurricaneDeductible" && (
              <>
                {checkNorthIssued(props.quoteData.personAddr.s_CountyName) ? (
                  <Box fontSize={"15px"}>
                    {`Your policy covers losses to your property from a hurricane,
                    but hurricane-related claims have their own deductible. This
                    is the amount you have to pay before we can pay for a
                    covered loss. You only have to pay the deductible once in a
                    policy year, even if there are several hurricanes. With us, 
                    your hurricane deductible is 5% of your home rebuilding cost,
                    but it's also a Smart Deductible, so you get a growing
                    amount of cash back every year you don't have a claim`}
                  </Box>
                ) : (
                  <Box fontSize={"15px"}>
                    {`Your policy covers losses to your property from a hurricane,
                    but hurricane-related claims have their own deductible. This
                    is the amount you have to pay before we can pay for a
                    covered loss. You only have to pay the deductible once in a
                    policy year, even if there are several hurricanes. With us,
                    your hurricane deductible is 2% of your home rebuilding
                    cost, but it's also a Smart Deductible, so you get a growing
                    amount of cash back every year you don't have a claim`}
                  </Box>
                )}
              </>
            )}
            {id === "NonHurricaneDeductible"  && (
              <Box fontSize={"15px"}>
                This deductible applies to losses that are not related to
                hurricanes. It’s the amount you pay before we pay for a covered
                loss. It applies to all of your coverages unless otherwise
                specified. (Liability coverages don’t have a deductible.) Unlike
                the hurricane deductible, you pay this one on each claim you
                file. The options we have provided are standard. Tip: Consider
                how much you could afford to pay in an emergency. The higher the
                deductible, the lower your premium.
              </Box>
            )}
          </div>
        </ReactTooltip>
        <style>
          {`
      .custom-tooltip {
        width: 500px;
        height: auto;
      }
    `}
        </style>
      </>
    );
  };
  return (
    <Row className="align-items-end p-1 pt-3">
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Wind / Hail Hurricane Deductible")}:{" "}
            <span className="label-asterisk">*</span>
          </Form.Label>
          <a
                  style={{
                    marginBottom: "10px",
                    marginLeft: "-10px",
                    cursor: "pointer",
                    display: "inline-flex",
                    marginTop: "5px",
                    marginLeft: "5px",
                    verticalAlign: "middle",
                  }}
                  data-tip
                  data-for={"NonHurricaneDeductible"}
                >
                  {" "}
                  <Box ml={1}>
                    <FcInfo size={18} />
                  </Box>
                  {renderTootTip("NonHurricaneDeductible")}
                </a>
          <Form.Select
            className="starQuotePageInput"
            id="select-ym-option"
            name="s_HurricaneDedutible"
            parentobject="propertyCoverageInfo"
            title="propertyCoverageInfo"
            value={
              props.quoteData.propertyCoverageInfo.s_HurricaneDedutible
                ? starTenancySynonyms.includes(tenant)
                  ? props.quoteData.propertyCoverageInfo.s_HurricaneDedutible
                  : props.quoteData.propertyCoverageInfo.s_HurricaneDedutible
                : ""
            }
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>

            {checkNorthIssued(props.quoteData.personAddr.s_CountyName) ? (
              <option value="HURRA05P">5% Ded</option>
            ) : (
              props.getBaseData.ddDeductHurricane.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              ))
            )}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Non Hurricane Deductible (AOP)")}:{" "}
            <span className="label-asterisk">*</span>
          </Form.Label>
          <a
                  style={{
                    marginBottom: "10px",
                    marginLeft: "-10px",
                    cursor: "pointer",
                    display: "inline-flex",
                    marginTop: "5px",
                    marginLeft: "5px",
                    verticalAlign: "middle",
                  }}
                  data-tip
                  data-for={"HurricaneDeductible"}
                >
                  {" "}
                  <Box ml={1}>
                    <FcInfo size={18} />
                  </Box>
                  {renderTootTip("HurricaneDeductible")}
                </a>
          <Form.Select
            className="starQuotePageInput"
            id="select-ym-option"
            name="s_AOPDedutible"
            parentobject="propertyCoverageInfo"
            title="propertyCoverageInfo"
            value={
              props.quoteData.propertyCoverageInfo.s_AOPDedutible
                ? starTenancySynonyms.includes(tenant)
                  ? props.quoteData.propertyCoverageInfo.s_AOPDedutible
                  : props.quoteData.propertyCoverageInfo.s_AOPDedutible
                : ""
            }
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>
              {props.getBaseData.ddDeductNonHurricane.map((x, index) => (
              <option key={index} value={x.value}>
                {x.label}
              </option>
            ))}
            {/* {checkNorthIssued(props.quoteData.personAddr.s_CountyName) ? (
              <option value="DED05P">5% Ded</option>
            ) : (
              props.getBaseData.ddDeductNonHurricane.map((x, index) => (
                <option key={index} value={x.value}>
                  {x.label}
                </option>
              ))
            )} */}
          </Form.Select>
        </Form.Group>
      </Col>
    </Row>
  );
};
function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(Deductible);
