import { CreateQuoteContext, useMyContext } from "../CreateQuote.js";
import Rectangle3 from "../../../../assets/Rectangle3.svg";
import Rectangle1 from "../../../../assets/Rectangle1.svg";
 import { useNavigate, useParams } from "react-router-dom";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { Card, Grid, Image, Text } from "@chakra-ui/react";

export const ProductType = () => {
  const {
    setProduct,
    setTenant,
    loading,
    setConstructionDetails,
    constructionDetails,
    viewMode,
    status,
    setStatus,
    setQuoteNumber,
  } = useMyContext(CreateQuoteContext);
  const { productID } = useParams();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const navgateToStepper = () => {
    navigation(`/quote/${productID}/create/${viewMode}`);
  };

  useEffect(() => {
    setStatus("create");
    setQuoteNumber(null);
  }, []);

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Grid
          ml={{ base: 0, lg: 6, md: 3 }}
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          p={10}
          gap={0}
        >
          <>
            <Card
              maxH={"20rem"}
              maxW={"20rem"}
              border={
                constructionDetails?.productType === "Residential"
                  ? "1px solid var(--chakra-colors-primary-500)"
                  : "none"
              }
              _hover={{
                cursor: "pointer",
                boxShadow: "xl",
                border: "1px solid var(--chakra-colors-secondary-500)",
              }}
              borderRadius="2xl"
              boxShadow="lg"
              p={5}
              bg="white"
              m={5}
              onClick={() => {
                setProduct("Residential");
                setConstructionDetails({
                  ...constructionDetails,
                  productType: "Residential",
                  commercialProductType: "",
                });
                navgateToStepper();
              }}
            >
              <Image src={"/assets/images/home.svg"} />
              <Text fontWeight={600} textAlign={"center"}>
                {t("Residential")}
              </Text>
            </Card>

            {/* <Card
            maxH={'20rem'}
             maxW={'20rem'}
             border={"Owned "}
              _hover={{
                cursor: "pointer",
                boxShadow: "xl",
                border: "1px solid var(--chakra-colors-secondary-500)",
              }}
              borderRadius={"2xl"}
              boxShadow={"lg"}
              p={5}
              bg={"white"}
              m={5}
              onClick={() => {
                setProduct("Commercial");
                setConstructionDetails({
                  ...constructionDetails,
                  productType: "Commercial",
                });
                setProductSelection(true);
              }}
            >
              <Image src={Rectangle2} />
              <Text fontWeight={600} textAlign={"center"}>
                {t("Commercial")}
              </Text>
            </Card> */}
          </>
          <>
            <Card
              maxH={"20rem"}
              maxW={"20rem"}
              border={
                constructionDetails?.commercialProductType === "Owned"
                  ? "1px solid var(--chakra-colors-primary-500)"
                  : "none"
              }
              _hover={{
                cursor: "pointer",
                boxShadow: "xl",
                border: "1px solid var(--chakra-colors-secondary-500)",
              }}
              borderRadius="2xl"
              boxShadow="lg"
              p={5}
              bg="white"
              m={5}
              onClick={() => {
                navgateToStepper();
                setProduct("Commercial");
                setTenant("Owned");
                setConstructionDetails({
                  ...constructionDetails,
                  commercialProductType: "Owned",
                  productType: "Commercial",
                });
              }}
            >
              <Image src={Rectangle3} />
              <Text fontWeight={600} textAlign={"center"}>
                {t("Commercially Owned")}
              </Text>
            </Card>

            <Card
              maxH={"20rem"}
              maxW={"20rem"}
              border={
                constructionDetails?.commercialProductType === "Tenanted"
                  ? "1px solid var(--chakra-colors-primary-500)"
                  : "none"
              }
              _hover={{
                cursor: "pointer",
                boxShadow: "xl",
                border: "1px solid var(--chakra-colors-secondary-500)",
              }}
              borderRadius="2xl"
              boxShadow="lg"
              p={5}
              bg="white"
              m={5}
              onClick={() => {
                navgateToStepper();
                setProduct("Commercial");
                setTenant("Tenanted");
                setConstructionDetails({
                  ...constructionDetails,
                  commercialProductType: "Tenanted",
                  productType: "Commercial",
                });
              }}
            >
              <Image src={Rectangle1} />
              <Text fontWeight={600} textAlign={"center"}>
                {t("Commercially Tenanted")}
              </Text>
            </Card>
          </>
        </Grid>{" "}
      </LoadingOverlay>
    </>
  );
};

export default ProductType;
