export function setButtonColor({color}) {
    document.documentElement.style.setProperty('--buttonColor', color);
    document.documentElement.style.setProperty('--starting-estimate-header-color', color);
    document.documentElement.style.setProperty('--switch-color', color);
    document.documentElement.style.setProperty('--border-color', color);
    document.documentElement.style.setProperty('--modal-heading-color', color);
    document.documentElement.style.setProperty('--discount-input-label-color', color);
    document.documentElement.style.setProperty('--flood-pay-now-color', color);
    document.documentElement.style.setProperty('--home-updates-border', color);
    document.documentElement.style.setProperty('--border-main-color', color);
    document.documentElement.style.setProperty('--hoverColor', color);
    document.documentElement.style.setProperty('--createQuoteHeaderButton', color);
}

export function setTitleColor({color}) {
    document.documentElement.style.setProperty('--starting-estimate-title-color', color);
}
