import React from "react";
import "./Payment.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const tenant = window.location.host.split('.')[0];
const title = tenant.charAt(0).toUpperCase() + tenant.slice(1);

const Payment = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    return(
        <section className="pay-now-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pay-now-form-section">
                    <div className="pay-now-headings">
                        <h6>{t('Pay Now')}</h6>
                        <div className="yearly-plan">
                            <span className="pn-amount">$2580</span>
                            <span className="pn-slash">/</span>
                            <span className="pn-year">{t('Year')}</span>
                        </div>
                    </div>
                    <div className="pay-now-form-main">
                        <p className="payment-method-title">
                            {t('Please Add a payment method')}
                        </p>
                        <div className="credit-card-section">
                            <Button
                                className="credit-card-main"
                                type="button"
                                variant="light"
                                style={{ marginRight: "25px" }}
                            >
                                <div className="credit-card-icon1"></div>
                                <span className="credit-card-h">{t('Credit Card')}</span>
                            </Button>
                            <Button
                                className="credit-card-main"
                                type="button"
                                variant="light"
                                style={{ marginRight: "25px" }}
                            >
                                <div className="credit-card-icon2"></div>
                                <span className="credit-card-h">{t('eCheck')}</span>
                            </Button>
                        </div>
                        <div className="payment-method-details">
                            <p className="pmd-heading">
                                {t('Make your check payable to')}{" "}
                                <span className="avatar-insurance">{title} </span> 
                                {t('and include your policy number')}
                            </p>
                            <br />
                            <div className="pmd-address-div">
                                <span className="pmd-address">{t('Our Address')}:</span>
                                <div className="pmd-address-details">
                                    <p>1101 E. Cumberland Ave,</p>
                                    <p>Tampa, FL 33602</p>
                                </div>
                            </div>
                            <div className="pmd-mortage-div">
                                <span className="pmd-mortage-address">
                                    {t('For Mortgagees & Late Payments, please use our Express Overnight Address')}:
                                </span>
                                <div className="pmd-mortage-details">
                                    <p>Cash Management Solutios/Image Remit</p>
                                    {/* <p>Attn: Avatar #30537</p> */}
                                    <p>1101 E. Cumberland Ave,</p>
                                    <p>Tampa, FL 33602</p>
                                </div>
                            </div>
                        </div>
                        <div className="flood-bts-div">
                            <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                onClick={() => handleOnclick('13')}
                            >
                                {t('Previous')}
                            </Button>
                            <Button
                                id="primary-colored-btn"
                                variant="primary"
                                onClick={() => handleOnclick('address')}
                            >
                                {t('Next')}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default Payment;

