import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Select,
  Spinner,
  Switch,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { toast } from "react-toastify";
import { RiDeleteBin2Line } from "react-icons/ri";
import { CgAddR } from "react-icons/cg";
import states from "../../../common/States";
import { useEffect, useState } from "react";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import DateInput from "../../../create-star-quote/utils/DateInput";
import { formateDate } from "../../../../common/FormatDate";
import { postDeletePolicyMortagee } from "../api/DeleteMortg";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";

export default function Insure({
  confirmation,
  setConfirmation,
  handleInsuredChange,
  updateAdditionalInsuredData,
  quoteData,
}) {
  const { t } = useTranslation();
  const [currentIndexInsured, setCurrentIndexInsured] = useState(0);
  const [zipUpdated, setZipUpdated] = useState(false);
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    confirmation?.additionalInsured?.[currentIndexInsured]?.address
      ?.s_PostalCode,
    zipUpdated
  );
  const additionalInsuredFields = {
    n_PolicyAddInterest_PK: null,
    n_POTransaction_FK: null,
    n_PersonInfoId_PK: null,
    s_PersonType: "ADDITIONALINSURED",
    s_MortgageeType: null,
    s_LoanNo: null,
    s_AddionalIntType: null,
    s_RelationType: null,
    s_HouseholdType: null,
    s_isDeleted: null,
    n_CorePersonPK: null,
    n_CorePersonAddressPK: null,
    n_CreatedUser: null,
    d_CreatedDate: null,
    n_UpdatedUser: null,
    d_UpdatedDate: null,
    person: {
      n_PersonInfoId_PK: null,
      s_PersonUniqueId: null,
      s_EntityType: "PERSON",
      s_IsMailingDiff: null,
      s_FirstName: null,
      s_MiddleName: null,
      s_LastOrganizationName: null,
      s_FullLegalName: "",
      s_PayeeName: null,
      d_BirthDate: null,
      d_DeathDate: null,
      s_MaritalStatusCode: null,
      s_Occupation: null,
      s_1099Flag: null,
      s_LegacyId: null,
      s_IsTempMailing: null,
      metadata: null,
      n_CreatedUser: null,
      d_CreatedDate: null,
      n_UpdatedUser: null,
      d_UpdatedDate: null,
      n_EditVersion: null,
    },
    address: {
      n_PersonAddressesId_PK: 0,
      n_PersonInfoId_PK: null,
      s_AddressTypeCode: "MAILING",
      s_IsDefaultAddress: null,
      s_AddressNickName: null,
      s_HouseNo: null,
      s_HouseDirection1: null,
      s_StreetName: null,
      s_HouseType: null,
      s_HouseDirection2: null,
      s_AddressLine1: "",
      s_AddressLine2: null,
      s_AddressLine3: null,
      s_AddressLine4: null,
      n_CountyId_FK: null,
      n_CityId_FK: null,
      n_StateId_FK: null,
      n_CountryId_FK: null,
      s_PostalCode: null,
      s_PostalCodeSuffix: null,
      s_Latitude: null,
      s_Longitude: null,
      s_CountryName: null,
      s_StateName: null,
      s_CountyName: null,
      s_CityName: null,
      s_UnitNo: null,
      s_ParcelId: null,
      s_countyFips: null,
      s_stateFips: null,
      n_CreatedUser: null,
      d_CreatedDate: null,
      n_UpdatedUser: null,
      d_UpdatedDate: null,
      n_EditVersion: null,
      php_session_id: null,
    },
    communication: [
      {
        n_CommID_PK: null,
        n_PersonInfoId_PK: null,
        s_CommType: "HOME",
        s_CommValue: "",
        s_CommIsDefault: null,
        s_DND: null,
        s_IsExpired: null,
        n_CreatedUser: null,
        d_CreatedDate: null,
        n_UpdatedUser: null,
        d_UpdatedDate: null,
      },
      {
        n_CommID_PK: null,
        n_PersonInfoId_PK: null,
        s_CommType: "EMAIL",
        s_CommValue: "",
        s_CommIsDefault: null,
        s_DND: null,
        s_IsExpired: null,
        n_CreatedUser: null,
        d_CreatedDate: null,
        n_UpdatedUser: null,
        d_UpdatedDate: null,
      },
    ],
  };

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (
      zipData &&
      confirmation?.additionalInsured?.length > currentIndexInsured &&
      zipData.s_CityName &&
      zipData.s_StateCode
    ) {
      const newAdditionalInsured = confirmation.additionalInsured.map(
        (insured, index) =>
          index === currentIndexInsured
            ? {
                ...insured,
                address: {
                  ...insured.address,
                  s_CityName: zipData?.s_CityName,
                  s_StateName: zipData?.s_StateCode,
                  n_CityId_FK: zipData.city_id ?? 0,
                  n_StateId_FK: zipData.state_id ?? 0,
                  n_CountryId_FK: zipData.n_CountryId_FK ?? 1,
                  n_CountyId_FK: zipData.county_id ?? 0,
                  s_CountyName: zipData.s_CountyName ?? "",
                },
              }
            : insured
      );
      setConfirmation((prevConfirmation) => ({
        ...prevConfirmation,
        additionalInsured: newAdditionalInsured,
      }));
      updateAdditionalInsuredData(
        newAdditionalInsured,
        confirmation.additionalInterests
      );
      setZipData({});
      setZipUpdated(false);
    }
  }, [zipData, currentIndexInsured]);

  const isEmptyOrUndefinedOrNull = (value) =>
    value === null || value === undefined || value === "";

  const handleAddRow = () => {
    const isAnyFieldEmpty =
      confirmation?.additionalInsured?.some((insured, index) => {
        if (index !== 0) {
          return (
            isEmptyOrUndefinedOrNull(insured?.person?.s_FirstName) ||
            isEmptyOrUndefinedOrNull(insured?.person?.s_LastOrganizationName) ||
            isEmptyOrUndefinedOrNull(insured?.address?.s_AddressLine1) ||
            isEmptyOrUndefinedOrNull(insured?.address?.s_CityName) ||
            isEmptyOrUndefinedOrNull(insured?.address?.s_PostalCode) ||
            !insured?.address?.s_PostalCode?.toString()?.length === 5 ||
            isEmptyOrUndefinedOrNull(insured?.address?.s_StateName)
          );
        }
      }) || false;

    // const isFirstIndexFieldsEmpty =
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.person?.s_FirstName
    //   ) ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.person
    //       ?.s_LastOrganizationName
    //   ) ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.person?.d_BirthDate
    //   ) ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.address?.s_AddressLine1
    //   ) ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.address?.s_CityName
    //   ) ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.address?.s_PostalCode
    //   ) ||
    //   !(confirmation?.additionalInsured ||
    //     [])[0]?.address?.s_PostalCode?.toString()?.length === 5 ||
    //   isEmptyOrUndefinedOrNull(
    //     (confirmation?.additionalInsured || [])[0]?.address?.s_StateName
    //   );

    // if (isFirstIndexFieldsEmpty) {
    //   toast.warn("Secondary Insured fields Cannot be Empty", {
    //     autoClose: 5000,
    //     toastId: "secondaryInsured",
    //   });
    // } else
    if (isAnyFieldEmpty) {
      toast.warn("Please fill Required Fields", {
        toastId: "allFieldsRequired",
      });
    } else {
      const newAdditionalInsured = [...(confirmation?.additionalInsured || [])];
      newAdditionalInsured.push(additionalInsuredFields);
      setConfirmation((prev) => ({
        ...prev,
        additionalInsured: newAdditionalInsured,
      }));
      setZipData({});
      updateAdditionalInsuredData(
        newAdditionalInsured,
        confirmation.additionalInterests
      );
    }
  };

  const deleteMortgagee = async (insured, index) => {
    if (index === 0) {
      toast.warn("Cannot Delete Secondary Insured");
      return;
    }
    try {
      if (
        insured?.n_PolicyAddInterest_PK === null ||
        insured?.n_PolicyAddInterest_PK === undefined
      ) {
        toast.success(
          t("Additional Insured Deleted Successfully", { autoClose: 1500 })
        );
      } else {
        const reqBody = {
          transPK: quoteData?.transactionInfo?.n_potransaction_PK,
          n_PolicyAddInterest_PK: insured?.n_PolicyAddInterest_PK,
        };
        const response = await postDeletePolicyMortagee(reqBody);
        if (response.success && response.data.Status === "Y") {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          toast.success(
            t("Additional Insured Deleted Successfully", { autoClose: 1500 })
          );
        }
      }
    } catch (error) {
      console.error("Error deleting insured:", error);
      toast.error(t("Error deleting insured. Please try again."));
    } finally {
    }
  };

  const handleRemoveRow = (index) => {
    if (confirmation.additionalInsured?.length > 0 && index !== 0) {
      const newAdditionalInsured = [
        ...confirmation.additionalInsured.slice(0, index),
        ...confirmation.additionalInsured.slice(index + 1),
      ];
      setConfirmation((prev) => ({
        ...prev,
        additionalInsured: newAdditionalInsured,
      }));
      updateAdditionalInsuredData(
        newAdditionalInsured,
        confirmation.additionalInterests
      );
    } else {
      toast.warn("Cannot Delete Secondary Insured.", {
        toastId: "deleteSecondaryInsured",
      });
    }
    // if (confirmation.additionalInsured?.length === 1 && index === 0) {
    //   setConfirmation((prev) => ({
    //     ...prev,
    //     additionalInsured: [],
    //     additionalInsuredFlag: false,
    //   }));
    //   updateAdditionalInsuredData([], confirmation.additionalInterests);
    // }
  };

  const handleSwitchChange = () => {
    let newAdditionalInsured = [];
    if (!confirmation?.additionalInsuredFlag) {
      newAdditionalInsured = [additionalInsuredFields];
    }
    setConfirmation((prev) => ({
      ...prev,
      additionalInsuredFlag: !prev.additionalInsuredFlag,
      additionalInsured: newAdditionalInsured,
    }));
    updateAdditionalInsuredData(
      newAdditionalInsured,
      confirmation.additionalInterests
    );
  };

  const formatDate = (dateString) => {
    if (!dateString || typeof dateString !== "string") return null;
    const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
    if (regexYYYYMMDD.test(dateString)) return dateString;

    const regexMMDDYYYY = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const match = dateString.match(regexMMDDYYYY);
    if (match) return `${match[3]}-${match[1]}-${match[2]}`;

    const regexYYYYDDMM = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match2 = dateString.match(regexYYYYDDMM);
    if (match2) return dateString;

    return null;
  };

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Flex
        background="var(--chakra-colors-primary-50)"
        borderRadius={"xl"}
        p={"0.1rem"}
        mb="20px"
        mt="20px"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex mt={3} align={"center"}>
          <Text
            fontSize={"xl"}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-medium)"
          >
            {t("additional_insured")}
          </Text>
          <Box ml={2} mb={4}>
            <FcInfo data-tip data-for={"secondaryInsured"} />
          </Box>
          <ReactTooltip
            id={"secondaryInsured"}
            delayHide={500}
            effect="solid"
            className={"custom-tooltip"}
            place="right"
          >
            <Box width={"300px"} fontSize={"15px"}>
              After creating a quote, you are unable to add or modify secondary
              insured. Kindly generate a fresh quote if you want to add a
              secondary insured.
            </Box>
          </ReactTooltip>
        </Flex>

        <Box mr={2}>
          <Switch
            isDisabled
            isChecked={confirmation?.additionalInsuredFlag}
            onChange={() => handleSwitchChange()}
            size={"lg"}
          />
        </Box>
      </Flex>
      {confirmation?.additionalInsuredFlag && (
        <Box>
          {confirmation.additionalInsured?.map((row, index) => (
            <Box pl={5} pr={5}>
              <Card
                border={"1px solid"}
                borderColor={"neutral.100"}
                boxShadow={0}
                borderRadius={"xl"}
                mb={4}
              >
                <CardHeader
                  borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
                  p={0}
                  borderTopRadius={"inherit"}
                >
                  <Flex
                    p={4}
                    borderTopRadius={"inherit"}
                    bg={"var(--chakra-colors-primary-50)"}
                    justifyContent={"space-between"}
                  >
                    <Flex ml={2} gap={3} alignItems={"center"}>
                      <Text mb={0} fontSize={"lg"} fontWeight={400}>
                        {t("additional_insured")}
                      </Text>
                      {index === 0 ? (
                        <Badge
                          colorScheme="green"
                          variant="solid"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          Secondary Insured
                        </Badge>
                      ) : (
                        <Badge
                          colorScheme="blue"
                          variant="outline"
                          fontSize="sm"
                          fontWeight="600"
                        >
                          Additional Insured(s)
                        </Badge>
                      )}
                      {isLoading && index === currentIndexInsured && (
                        <Spinner size={"lg"} color="primary.500" />
                      )}
                    </Flex>
                    {index !== 0 && (
                      <Box mr={2}>
                        <RiDeleteBin2Line
                          onClick={() => {
                            handleRemoveRow(index);
                            deleteMortgagee(row, index);
                          }}
                          size={"25px"}
                        />
                      </Box>
                    )}
                  </Flex>
                </CardHeader>
                <CardBody p={8}>
                  <Grid
                    w="100%"
                    templateColumns={`repeat(${index === 0 ? 5 : 4}, 1fr)`}
                    gap={6}
                    pt={0}
                    pb={2}
                  >
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("First Name")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_FirstName"
                          title="person"
                          type="text"
                          size="lg"
                          _disabled={{ opacity: 1 }}
                          isDisabled={index === 0}
                          bg={index === 0 ? "gray.100" : ""}
                          focusBorderColor="primary.500"
                          value={row?.person?.s_FirstName}
                          onChange={(e) => handleInsuredChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("Last Name")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_LastOrganizationName"
                          title="person"
                          type="text"
                          size="lg"
                          _disabled={{ opacity: 1 }}
                          isDisabled={index === 0}
                          bg={index === 0 ? "gray.100" : ""}
                          focusBorderColor="primary.500"
                          value={row?.person?.s_LastOrganizationName}
                          onChange={(e) => handleInsuredChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    {index === 0 && (
                      <>
                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("phone_number")}:
                            </FormLabel>
                            <Box
                              border={"2px solid"}
                              borderColor={"neutral.500"}
                              py={"0.21rem"}
                              borderRadius={"xl"}
                              _hover={{
                                borderColor: "neutral.300",
                              }}
                              _activeLink={{
                                borderColor: "secondary.500",
                              }}
                            >
                              <MaskedFormControl
                                style={{ borderColor: "transparent" }}
                                name="s_CommValue"
                                title="communication"
                                type="text"
                                size="md"
                                focusBorderColor="secondary.500"
                                mask="(111) 111-1111"
                                guide={false}
                                defaultValue={"+1 "}
                                disabled={index === 0}
                                bg={index === 0 ? "gray.100" : ""}
                                _disabled={{ opacity: 1 }}
                                value={
                                  row?.communication[0]?.s_CommValue &&
                                  row?.communication[0]?.s_CommValue?.replace(
                                    /^\+\d+\s?/,
                                    ""
                                  )
                                }
                                onChange={(e) => handleInsuredChange(e, index)}
                              />
                            </Box>
                          </FormControl>
                        </GridItem>

                        <GridItem w="100%">
                          <FormControl>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("email")}:
                            </FormLabel>
                            <Input
                              border={
                                "2px solid var(--chakra-colors-neutral-500)"
                              }
                              borderRadius={"xl"}
                              name="s_CommValue"
                              title="communication"
                              type="email"
                              size="lg"
                              isDisabled={index === 0}
                              _disabled={{ opacity: 1 }}
                              bg={index === 0 ? "gray.100" : ""}
                              focusBorderColor="primary.500"
                              value={row?.communication[1]?.s_CommValue}
                              onChange={(e) => handleInsuredChange(e, index)}
                            />
                          </FormControl>
                        </GridItem>

                        <GridItem w="100%">
                          <FormControl isRequired>
                            <FormLabel pl={1} fontWeight={400}>
                              {t("Date of Birth")}:
                            </FormLabel>
                            <DateInput
                              title="person"
                              name="d_BirthDate"
                              isDisabled={index === 0}
                              value={
                                formateDate(row?.person?.d_BirthDate) || ""
                              }
                              onChange={(e) => {
                                setConfirmation((prev) => ({
                                  ...prev,
                                  additionalInsured: [
                                    {
                                      ...prev.additionalInsured[0],
                                      person: {
                                        ...prev.additionalInsured[0].person,
                                        d_BirthDate: formatDate(e),
                                      },
                                    },
                                  ],
                                }));
                              }}
                              styleProps={{
                                border: "2px solid",
                                borderColor: "neutral.500",
                                borderRadius: "xl",
                                size: "lg",
                                bg: index === 0 ? "gray.100" : "",
                                readOnly: false,
                              }}
                              maxDate={new Date()}
                            />
                          </FormControl>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                  <Box
                    borderTop={"1px solid"}
                    borderColor={"gray.300"}
                    my={"10px"}
                  />
                  {/* <Flex
                    justifyContent={"space-between"}
                    py={2}
                    alignItems={"center"}
                    alignContent={"center"}
                    gap={3}
                  >
                    <Text pt={"1rem"}>Same as Property Address?</Text>
                    <Switch
                      colorScheme="blue"
                      mt={"-15px"}
                      name="s_IsMailingDiff"
                      title="person"
                      isDisabled={index === 0}
                      _disabled={{ opacity: 1 }}
                      isChecked={row?.person?.s_IsMailingDiff === "NO"}
                      onChange={(e) => handleInsuredChange(e, index)}
                      size={"lg"}
                    />
                  </Flex> */}
                  <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={6}>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("Street Address")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_AddressLine1"
                          title="address"
                          type="text"
                          size="lg"
                          bg={index === 0 ? "gray.100" : ""}
                          _disabled={{ opacity: 1 }}
                          isDisabled={index === 0}
                          focusBorderColor="primary.500"
                          value={row?.address?.s_AddressLine1}
                          onChange={(e) => handleInsuredChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>

                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("City")}:
                        </FormLabel>
                        <Input
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          isDisabled
                          _disabled={{ opacity: 1 }}
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_CityName"
                          title="address"
                          type="text"
                          size="lg"
                          focusBorderColor="primary.500"
                          value={row?.address?.s_CityName}
                          onChange={(e) => handleInsuredChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>

                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("State / State Code")}:
                        </FormLabel>
                        <Select
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          _disabled={{ opacity: 1 }}
                          isDisabled
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_StateName"
                          title="address"
                          size="lg"
                          focusBorderColor="primary.500"
                          value={row?.address?.s_StateName}
                          icon={""}
                          onChange={(e) => {
                            handleInsuredChange(e, index);
                          }}
                        >
                          <option value=""></option>
                          {states.map((state) => (
                            <option key={state.name} value={state.abbr}>
                              {" "}
                              {state.name} ({state.abbr}){" "}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {t("Zip Code")}:
                        </FormLabel>
                        <Input
                          border={"2px solid var(--chakra-colors-neutral-500)"}
                          borderRadius={"xl"}
                          name="s_PostalCode"
                          title="address"
                          type="number"
                          size="lg"
                          autoComplete="off"
                          isDisabled={index === 0}
                          _disabled={{ opacity: 1 }}
                          bg={index === 0 ? "gray.100" : ""}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                          focusBorderColor="primary.500"
                          value={row?.address?.s_PostalCode}
                          onChange={(e) => {
                            if (e.target.value.length <= 5) {
                              handleInsuredChange(e, index);
                              setZipUpdated(true);
                              setCurrentIndexInsured(index);
                            }
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </Box>
          ))}
          {/* <Flex pl={5} pr={5} justifyContent={"flex-end"}>
            <Button
              leftIcon={<CgAddR />}
              color={"white"}
              bg={"var(--chakra-colors-primary-500)"}
              borderColor={"var(--chakra-colors-primary-400)"}
              border={"1px solid"}
              borderRadius="12px"
              fontWeight="var(--chakra-fontWeights-medium)"
              onClick={() => handleAddRow()}
            >
              {t("Add Another Insured")}
            </Button>
          </Flex> */}
        </Box>
      )}
    </>
  );
}
