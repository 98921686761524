import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Alert } from "react-bootstrap";
import { reinstatePolicyHiscox } from "./api/ReinstatePolicyHiscox";
import { toast } from "react-toastify";
import { saveUpdates } from "./api/saveUpdates";
import { useSelector } from "react-redux";

import "./ReinstatePolicy.css";
import { FaUndo } from "react-icons/fa";

export default function ReinstatePolicy({ quoteId }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reinstateData, setReinstateData] = useState(null);
  const store = useSelector((state) => state);
  useEffect(() => {
    if (!showModal) {
      setReinstateData(null);
    }
  }, [showModal]);

  const handleReinstate = async () => {
    setLoading(true);
    const productID = quoteId || 0;
    try {
      const res = await reinstatePolicyHiscox({ productID });
      if (res?.status === false && Array.isArray(res?.errors)) {
        res?.errors.forEach((error) => {
          toast.error(error);
        });
      } else if (res?.status === true && res?.data) {
        const payload = {
          poTransPK: store?.coreSystem?.n_POTransactionFK,
          transactionEffectiveDate: res.data.reinstatementDate,
        };
        await saveUpdates({ payload, transType: "reinstate" });
        setReinstateData(res.data);
        setShowModal(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("An error occurred while reinstating the policy.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Button
        id="common-btn"
        variant="primary"
        size="sm"
        style={{ padding: "4px  6px" }}
        onClick={handleReinstate}
        disabled={loading}
      >
        <FaUndo size={18} /> &nbsp;{" "}
        {loading ? "Loading..." : "Reinstate  Policy"}
      </Button>
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header id="common-btn">
          <Modal.Title>Policy Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reinstateData ? (
            <>
              <Alert variant="success">
                <Alert.Heading>Policy Reinstated Successfully</Alert.Heading>
              </Alert>
              <Table bordered hover className="modal-table">
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hiscox ID</td>
                    <td>{reinstateData.hiscoxId || "-"}</td>
                  </tr>
                  <tr>
                    <td>Reinstatement Date</td>
                    <td>
                      {reinstateData.reinstatementDate
                        ? new Date(
                            reinstateData.reinstatementDate
                          ).toLocaleDateString()
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Charged Premium</td>
                    <td>
                      {reinstateData?.chargedPremium
                        ? reinstateData.chargedPremium.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <div className="loading-message">Loading...</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              id="common-btn"
              style={{ padding: "6px 8px" }}
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                window.location.reload();
              }}
            >
              Close
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
