import React from "react";
import policyCancellations from "../../../help/Policy_System_Cancellations.pdf";
import { policySearch, policyNonMoneyEndorsement, endorsePolicy } from "../../s3Pdf.js";
import policyQuote from "../../../help/Policy_System_Quote.pdf";
import policyTransfer from "../../../help/Policy_System_Transfer.pdf";
import { Box, Center, Image, Text, VStack, Wrap, WrapItem, Link } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const PolicySystemSection = () => {
    const { t } = useTranslation();

    return (
        <Wrap width={"52rem"}>
            <Link href={policySearch} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/sendIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("find_your_policy")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
            <Link href={policyQuote} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("create_quote")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
            <Link href={policyTransfer} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/triangleResourceIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("transfer_quote")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
            <Link href={endorsePolicy} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("endorse_a_policy")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
            <Link href={policyCancellations} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("cancel_a_policy")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
            <Link href={policyNonMoneyEndorsement} target="_blank">
                <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                    <VStack m={"auto"}>
                        <Box>
                            <Center>
                                <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                            </Center>
                        </Box>
                        <Box>
                            <Center>
                                <Text fontSize={"sm"} textAlign={"center"}>
                                    {t("non_money_endorsement")}
                                </Text>
                            </Center>
                        </Box>
                    </VStack>
                </WrapItem>
            </Link>
        </Wrap>
    )
}

export default PolicySystemSection;