import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EncryptOrDecrypt } from "../../../../services/commonService";

const CheckModal = (props) => {
    const { t } = useTranslation();

    const { showCheckModal, setShowCheckModal } = props;

    const user = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    const company_name = user.companyDetails.company_name ?? "";
    const company_address = user.companyDetails.company_address ?? {};
    const payment_overnight_address = user.companyDetails.payment_overnight_address ?? {};

    return (
        <Modal show={showCheckModal} size="lg" backdrop='static'>
            <Modal.Body>
                <section className="pay-now-section">
                    <Row className="justify-content-center">
                        <Col md="12" className="pay-now-form-section">
                            <div className="pay-now-form-main" id="flood-pay-now-form-main">
                                <div
                                    className="payment-method-details"
                                    id="flood-payment-method-details"
                                >
                                    <p className="pmd-heading">
                                        {t('Make your check payable to')}{" "}
                                        <span className="avatar-insurance">{company_name}</span> {t('and include your policy number')}
                                    </p>
                                    <div className="pmd-address-div">
                                        <span className="pmd-address">{t('Our Address')}:</span>
                                        <div className="pmd-address-details">
                                            <p>{company_address.address || ""}</p>
                                            <p>{company_address.city || ""}, {company_address.state || ""} {company_address.zip || ""}</p>
                                        </div>
                                    </div>

                                    <div className="pmd-mortage-div">
                                        <span className="pmd-mortage-address">
                                            {t('For Mortgagees & Late Payments, please use our Express Overnight Address')}:
                                        </span>
                                        <div className="pmd-mortage-details">
                                            <p>Flood Processing Center</p>
                                            <p>{payment_overnight_address.address || ""} </p>
                                            <p>{payment_overnight_address.city || ""}, {payment_overnight_address.state || ""} {payment_overnight_address.zip || ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    size="sm"
                    id="common-close-btn-sm"
                    variant="danger"
                    onClick={() => setShowCheckModal(false)}
                >
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CheckModal;
