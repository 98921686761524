import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Premium } from "./Premium/Premium";

export default function PremiumModal({
  show,
  handleClose,
  premiumResponseData,
  productType,
  setShowBindModal,
  setRefreshQuoteApplication,
  handleClosePremiumBox,
  quoteDetailsData,
  printQuoteState,
  setPrintQuoteState,
  requestBindState,
  setRequestBindState,
  userDatas,
  userLevel,
  userSubLevel,
}) {
  return (
    <Modal
      centered
      scrollable
      size="xl"
      show={show}
      onHide={() => {
        handleClose();
        setRefreshQuoteApplication(true);
      }}
      dialogClassName="modal-lg custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: "600" }}>Modify Premium</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Premium
          premiumResponseData={premiumResponseData}
          productType={productType}
          setRefreshQuoteApplication={setRefreshQuoteApplication}
          handleClosePremiumBox={handleClosePremiumBox}
          quoteDetailsData={quoteDetailsData}
          printQuoteState={printQuoteState}
          setPrintQuoteState={setPrintQuoteState}
          requestBindState={requestBindState}
          setRequestBindState={setRequestBindState}
        />
      </Modal.Body>
      <Modal.Footer className="gap-2">
        <Button
          size="sm"
          variant="secondary"
          id="primary-colored-btn"
          onClick={() => {
            handleClose();
            setRefreshQuoteApplication(true);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
