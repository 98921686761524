import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import { TbBrandOpenSource } from "react-icons/tb";
import { Card, CardBody, CardHeader, Flex, Text } from "@chakra-ui/react";
import { EncryptOrDecrypt } from "../../../../../services/commonService";
import { MdOutlinePayments } from "react-icons/md";
import { FaRegMap } from "react-icons/fa";

const BillingInfo = (props) => {
  const { t } = useTranslation();
  const user = EncryptOrDecrypt(localStorage.getItem("user"), "D");

  const {
    company_name = "",
    company_address = {},
    payment_mailling_address = {},
  } = user.companyDetails || {};
  const { address, city, state, zip, county, country } = company_address || {};

  function addPOBox(address) {
    if (typeof address !== "string") {
      return "";
    }
    if (!address) return "";
    const startsWithNumber = /^\d/; //checks for digits
    // Add "PO BOX" ODS-5138
    if (startsWithNumber.test(address)) {
      address = `PO BOX ${address}`;
    }
    // Remove "USA" ODS-5138
    address = address.replace(/,?\s*USA\b/gi, "");
    return address.trim();
  }

  return (
    <Fragment>
      <Row className="align-items-end">
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Bill To")}: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select
              className="starQuotePageInput"
              title="policyInfo"
              name="s_BillTo"
              onChange={props?.propsData.handleChange}
              value={props?.quoteData.policyInfo.s_BillTo}
              disabled={props?.isBind}
            >
              <option
                value={""}
                className="myform-input custom-select-autocomplete"
              ></option>
              {props?.getBaseData.BILLTO &&
                props?.getBaseData.BILLTO.map((v, i) => {
                  return (
                    <option
                      className="myform-input custom-select-autocomplete"
                      key={i}
                      value={v.s_AppCodeName}
                    >
                      {v.s_AppCodeNameForDisplay}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Pay Plan")}: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Select
              className="starQuotePageInput"
              id="select-ym-option"
              title="policyInfo"
              name="s_PayPlan_Code"
              onChange={props?.propsData.handleChange}
              value={props?.quoteData.policyInfo.s_PayPlan_Code || ""}
              disabled={props?.isBind}
            >
              <option
                value={""}
                className="myform-input custom-select-autocomplete"
              ></option>
              {props?.getBaseData.PayPlanDD &&
                props?.getBaseData.PayPlanDD.map((v, i) => {
                  return (
                    <option
                      className="myform-input custom-select-autocomplete"
                      key={i}
                      value={v.value}
                    >
                      {v.label}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Amount Included With Application")}:{" "}
              <span className="label-asterisk">*</span>
            </Form.Label>
            {/* <Form.Control
              className="starQuotePageInput"
              type="text"
              disabled
              value={
                (props?.quoteData.premiumData &&
                  props?.quoteData.premiumData.FinalPremium) ||
                ""
              }
            /> */}
            <CurrencyInput
              intlConfig={{ locale: "en-US", currency: "USD" }}
              className="starQuotePageInput"
              placeholder=""
              value={
                props?.feesData?.finalPremium
                  ? props?.feesData?.finalPremium
                  : (props?.quoteData.premiumData &&
                      props?.quoteData.premiumData.FinalPremium) ||
                    ""
              }
              disabled
              style={{
                width: "100%",
                textAlign: "right",
                backgroundColor: "#e9ecef",
              }}
              prefix="$"
            />
          </Form.Group>
        </Col>
      </Row>

      <Card
        mt={3}
        borderRadius={"xl"}
        backgroundColor="#fff3cd"
        borderColor="#ffeeba"
      >
        {props?.quoteData.policyInfo.s_BillTo === "MORTGAGE" ? (
          <CardHeader
            fontWeight={500}
            fontSize="16px"
            mb={"-5px"}
            color="#856404"
          >
            <Flex gap={2} alignItems={"center"}>
              <MdOutlinePayments />
              Your mortgage is being billed for this policy. Their payment
              should be mailed to the following address:
            </Flex>
          </CardHeader>
        ) : (
          <CardHeader
            fontWeight={500}
            fontSize="16px"
            mb={"-5px"}
            color="#856404"
          >
            <Flex gap={2} alignItems={"center"}>
              <MdOutlinePayments />
              Please mail payments to the following address:
            </Flex>
          </CardHeader>
        )}
        <Flex gap={2} alignItems={"center"} ml={5}>
          <TbBrandOpenSource color="#856404" />
          <Text mb={"-2.5px"} fontWeight={400} fontSize="16px" color="#856404">
            {t("Company Name")} : {company_name}
          </Text>
        </Flex>
        <CardBody>
          <Flex gap={2} alignItems={"center"}>
            <FaRegMap color="#856404" />
            <Text color="#856404" mb={0}>
              {t("Address")} : {addPOBox(payment_mailling_address) || ""}
            </Text>
          </Flex>
        </CardBody>
        <CardBody mt={"-20px"}>
          <Flex gap={2} alignItems={"center"} color="#856404" fontWeight={500}>
            <MdOutlinePayments />
            Need to overnight your payment?
          </Flex>

          <Flex gap={2} alignItems={"center"} mt={3}>
            <FaRegMap color="#856404" />
            <Text color="#856404" mb={0}>
              {t("Overnight")} {t("Address")} :{" "}
              {`${address ? address + ", " : ""}${city ? city + ", " : ""}${
                state ? state + ", " : ""
              }${zip ? zip + ", " : ""}${county ? county + ", " : ""}`
                .trim()
                .replace(/,\s*$/, "")}
            </Text>
          </Flex>
        </CardBody>
      </Card>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(BillingInfo);
