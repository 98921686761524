import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { Row, Col, Form, Button, Modal, Alert } from "react-bootstrap";
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import "react-datepicker/dist/react-datepicker.css";
import QuoteTab from "./QuoteTab";
import ApplicationTab from "./ApplicationTab";
import AsyncSelect from "react-select/async";
import {
  getEditEmptyQuoteData,
  agencyList,
  agentList,
  getAgencyDetails,
} from "../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useDispatch, connect } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../../components/CustomTablePagination";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../management-reports/management-reports.scss";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";
import DateInput from "../create-star-quote/utils/DateInput";
import { formateDate } from "../../common/FormatDate";
import Moment from "moment";
import QuoteButtons from "./components/QuoteTabComponents/QuoteButtons";
import { debounce } from "lodash";
import DateInputs from "./components/utils/DateInputApplication";
const currentYear = new Date().getFullYear();

const SearchQuoteTab = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [quoteData] = useState(props.quoteData);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agencyDD, setAgencyDD] = useState([]);
  const [agentDD, setAgentDD] = useState([]);
  const [isChange, setIsChange] = useState(false);

  const [agencyEmail, setAgencyEmail] = useState();
  const [agencyAddr, setAgencyAddr] = useState();
  const [agencyPhone, setAgencyPhone] = useState();

  const debouncedDispatch = useCallback(
    debounce((data) => {
      dispatch(getEditEmptyQuoteData(data));
    }, 0),
    [dispatch]
  );

  useEffect(() => {
    if (
      quoteData?.transactionInfo &&
      !quoteData.transactionInfo.n_potransaction_PK
    ) {
      const date = moment().add(1, "day");
      const tempdate = {
        ...quoteData,
        transactionInfo: {
          ...quoteData.transactionInfo,
          d_TransEffectiveFrom: date.format("YYYY-MM-DD"),
          d_TransEffectiveTo: date.add(1, "year").format("YYYY-MM-DD"),
        },
      };
      debouncedDispatch(tempdate);
    }
  }, [quoteData]);

  useEffect(() => {
    const { selectedAgency, selectedAgent, agencyData } = quoteData || {};

    setAgencyDD(Array.isArray(selectedAgency) ? selectedAgency : []);
    setSelectedAgency(Array.isArray(selectedAgency) ? selectedAgency : []);
    setAgentDD(Array.isArray(selectedAgent) ? selectedAgent : []);
    setSelectedAgent(Array.isArray(selectedAgent) ? selectedAgent : []);

    if (agencyData) {
      setAgencyEmail(agencyData.getAgencyEmail || "");
      setAgencyAddr(agencyData.getAgencyaddr || "");
      setAgencyPhone(agencyData.getAgencyPhone || "");
    } else {
      setAgencyEmail("");
      setAgencyAddr("");
      setAgencyPhone("");
    }
  }, [quoteData]);

  const handleChange = (e) => {
    const { name, value, title } = e.target;
    let quoteDataVar = props.quoteData;
    switch (`${name}-${title}`) {
      case "s_FirstName-personInfo":
        quoteDataVar.personInfo.s_FirstName = `${value}`;
        break;
      case "s_MiddleName-personInfo":
        quoteDataVar.personInfo.s_MiddleName = `${value}`;
        break;
      case "s_LastOrganizationName-personInfo":
        quoteDataVar.personInfo.s_LastOrganizationName = `${value}`;
        break;
      case "noOfResident-propertyInfo":
        quoteDataVar.propertyInfo.s_RoofAge = currentYear - value;
        quoteDataVar.riskRenovationTypeData =
          quoteDataVar.riskRenovationTypeData || {};
        quoteDataVar.riskRenovationTypeData.roofInfo = {
          s_RenovationYear: value,
          s_RenovationTypeCode: "FULL",
        };
        break;
      case "creditScore-personInfo":
        quoteDataVar.personInfo.metadata =
          quoteDataVar.personInfo.metadata || {};
        quoteDataVar.personInfo.metadata.creditScore = value;
        break;
      case "claddingType-propertyInfo":
      case "isBuildingVacant-propertyInfo":
      case "isUnderConstruction-propertyInfo":
        quoteDataVar.propertyInfo.metadata =
          quoteDataVar.propertyInfo.metadata || {};
        quoteDataVar.propertyInfo.metadata[name] = value;
        break;
      case "s_HouseNo-personPriorAddr":
      case "s_HouseNo-personMailingAddr":
      case "s_StreetName-personPriorAddr":
      case "s_StreetName-personMailingAddr":
        quoteDataVar[title][name] = value;
        quoteDataVar[title].s_AddressLine1 =
          name === "s_StreetName"
            ? quoteDataVar[title]?.s_HouseNo + " " + value
            : value + " " + quoteDataVar[title]?.s_StreetName;
        break;
      default:
        quoteDataVar[title] = quoteDataVar[title] || {};
        quoteDataVar[title][name] = value;
        break;
    }

    debouncedDispatch(quoteDataVar);
  };

  const handleRadioChange = (e) => {
    const { name, value, id } = e.target;
    const updatedQuoteData = {
      ...props.quoteData,
      [id]: { ...props.quoteData[id], [name]: value },
    };
    debouncedDispatch(updatedQuoteData);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, attributes } = e.target;
    const parentObjectName = attributes.parentobject.value;
    const fieldValue = checked
      ? attributes.checkedvalue.value
      : attributes.uncheckedvalue.value;

    const updatedQuoteData = {
      ...props.quoteData,
      [parentObjectName]: {
        ...props.quoteData[parentObjectName],
        [name]: fieldValue,
      },
    };
    debouncedDispatch(updatedQuoteData);
  };

  const [agencyModal, setAgencyModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const { SearchBar } = Search;

  const productsGenerator = useMemo(
    () => (quantity) => {
      return Array.from({ length: quantity }, (_, i) => ({
        agency_code: 16000 + i,
        name: "USi Insurance Service LCC",
        city: "POLICY- NEW BUSINESS",
        zip: "TMP220304712662",
        county: `HILLSBOROUG${i}`,
      }));
    },
    []
  );

  const products = productsGenerator(100);
  const columns = [
    {
      dataField: "agency_code",
      text: "Agency Code",

      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/"}>
          {value}
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "zip",
      text: "Zip",
    },
    {
      dataField: "county",
      text: "County",
      sort: true,
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  const handleChangeAsyncSelect = async (value, state) => {
    try {
      let result;
      switch (state) {
        case "Agency":
          setSelectedAgency(value);
          await loadAgentOptions(value.value);
          result = await dispatch(getAgencyDetails(value.value));
          setAgencyEmail(result.getAgencyEmail);
          setAgencyAddr(result.getAgencyaddr);
          setAgencyPhone(result.getAgencyPhone);
          break;
        case "Agent":
          setSelectedAgent(value);
          break;
        default:
          break;
      }

      setIsChange(true);
    } catch (error) {
      console.error("Error handling async select change:", error);
      // Handle error appropriately
    }
  };

  const loadAgencyOptions = (inputValue, callback) => {
    if (inputValue.length > 3) {
      setLoader(true);
      var request = {
        searchParams: inputValue,
      };
      dispatch(agencyList(request))
        .then((res) => {
          setAgencyDD(res);
          callback(filterData(res, inputValue, "Agency"));
          setLoader(false);
        })
        .catch(() => toast.error(t("Something Went Wrong!")));
    } else {
      callback(filterData(agencyDD, inputValue, "Agency"));
    }
  };

  const filterData = (result, inputValue = "", from) => {
    var dd = [];
    if (from === "Agency") {
      dd = result.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    if (from === "Agent") {
      dd = result.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
    return dd;
  };

  const filterAgentOptions = (value, callback) => {
    callback(filterData(agentDD, value, "Agent"));
  };

  const loadAgentOptions = (value) => {
    setLoader(true);
    dispatch(agentList(value, 13))
      .then((res) => {
        setAgentDD(res);
        var agent = [];
        if (res.length > 0) {
          agent = res[0];
          setSelectedAgent(agent);
          setIsChange(true);
        }
        setLoader(false);
      })
      .catch(() => toast.error(t("Something Went Wrong!")));
  };

  const memoizedQuoteDataVar = useMemo(() => {
    return {
      ...props.quoteData,
      selectedAgency: [selectedAgency],
      selectedAgent: [selectedAgent],
      policyInfo: {
        ...props.quoteData.policyInfo,
        n_AgencyPeson_FK: selectedAgency ? selectedAgency.value : 0,
        n_SubAgentPersoninfo_FK: selectedAgent ? selectedAgent.value : 0,
      },
    };
  }, [selectedAgency, selectedAgent, props.quoteData]);

  useEffect(() => {
    if (isChange === true) {
      dispatch(getEditEmptyQuoteData(memoizedQuoteDataVar));
      setIsChange(false);
    }
  }, [isChange, memoizedQuoteDataVar, dispatch]);

  return (
    <Fragment>
      <Form validated={props.ValidationError}>
        <LoadingOverlay>
          <Loader loading={loader} />
          <Box
            border="2px solid"
            borderColor={"#E6E9ED"}
            borderRadius="xl"
            mb={6}
          >
            <Box
              background="var(--chakra-colors-primary-50)"
              p={"0.1rem"}
              borderTopRadius={"xl"}
            >
              <Text
                my={2}
                pl="1.5rem"
                fontWeight="var(--chakra-fontWeights-semibold)"
                color="black"
                fontSize="1.5rem"
              >
                {t("Policy Details")}
              </Text>
            </Box>

            {
              <div className="custom-tab-body" style={{ border: "none" }}>
                {quoteData && quoteData?.transactionInfo && (
                  <Row
                    className="align-items-baseline"
                    style={{
                      display: "grid",
                      gridTemplateColumns: props?.isBind
                        ? "repeat(auto-fit, minmax(290px, 1fr))"
                        : "repeat(auto-fit, minmax(340px, 1fr))",
                      gap: "0.5rem",
                      columnGap: "0.25rem",
                      paddingTop: "1rem",
                      borderRadius: "25px",
                    }}
                  >
                    <Col>
                      <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Quote Number")}:
                        </Form.Label>
                        <Form.Control
                          className="starQuotePageInput"
                          type="text"
                          placeholder=""
                          value={
                            quoteData?.transactionInfo?.s_PolicyQuoteNo
                              ? quoteData?.transactionInfo?.s_PolicyQuoteNo
                              : ""
                          }
                          name="s_PolicyQuoteNo"
                          title="transactionInfo"
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    {props?.isBind ? (
                      <Col>
                        <Form.Group
                          className="search-quote-form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="dashboard-group-label">
                            {t("Policy Number")}:
                          </Form.Label>
                          <Form.Control
                            className="starQuotePageInput"
                            type="text"
                            placeholder=""
                            value={
                              quoteData?.policyInfo?.Policy_No
                                ? quoteData?.policyInfo?.Policy_No
                                : ""
                            }
                            name="Policy_No"
                            title="policyInfo"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    ) : null}
                    <Col>
                      <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Effective Date")}:
                        </Form.Label>
                        <DateInputs
                          styleProps={{
                            border: "2px solid",
                            borderColor: "#9AA5B5",
                            borderRadius: "12px",
                            size: "lg",
                            readOnly: false,
                            backgroundColor: props?.isBind ? "#e9ecef" : "",
                          }}
                          value={
                            quoteData?.effectiveDate
                              ? formateDate(quoteData?.effectiveDate)
                              : ""
                          }
                          minDate={moment().format("YYYY-MM-DD") || ""}
                          isDisabled={props?.isBind}
                          name="d_TransEffectiveFrom"
                          title="transactionInfo"
                          // onChange={props.handleChange}
                          onChange={(value) => {
                            let quoteDataVar = quoteData;
                            quoteDataVar.effectiveDate =
                              moment(value).format("YYYY-MM-DD");
                            quoteDataVar.transactionInfo.d_TransEffectiveFrom =
                              Moment(value).format("YYYY-MM-DD");
                            quoteDataVar.transactionInfo.d_TransEffectiveTo =
                              Moment(value).add(1, "year").format("YYYY-MM-DD");
                            dispatch(getEditEmptyQuoteData(quoteDataVar));
                          }}
                        />

                        {/* <Form.Control
                          className="starQuotePageInput"
                          type="date"
                          value={
                            quoteData?.transactionInfo?.d_TransEffectiveFrom
                              ? quoteData?.transactionInfo?.d_TransEffectiveFrom
                              : ""
                          }
                          disabled={props?.isBind}
                          name="d_TransEffectiveFrom"
                          title="transactionInfo"
                          onChange={props.handleChange}
                        /> */}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Effective To")}:
                        </Form.Label>
                        <DateInput
                          styleProps={{
                            border: "2px solid",
                            borderColor: "#9AA5B5",
                            borderRadius: "12px",
                            size: "lg",
                            disabled: true,
                            backgroundColor: "#e9ecef",
                          }}
                          isDisabled={true}
                          value={
                            quoteData?.transactionInfo?.d_TransEffectiveTo
                              ? formateDate(
                                  quoteData?.transactionInfo?.d_TransEffectiveTo
                                )
                              : ""
                          }
                          name="d_TransEffectiveTo"
                          title="transactionInfo"
                        />

                        {/* <Form.Control
                          className="starQuotePageInput"
                          type="date"
                          value={
                            quoteData?.transactionInfo?.d_TransEffectiveTo
                              ? quoteData?.transactionInfo?.d_TransEffectiveTo
                              : ""
                          }
                          name="d_TransEffectiveTo"
                          title="transactionInfo"
                          readOnly
                        /> */}
                      </Form.Group>
                    </Col>
                    {props?.isBind ? (
                      <Col>
                        <Form.Group
                          className="search-quote-form-group"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="dashboard-group-label">
                            {t("Binder Date")}:
                          </Form.Label>
                          <DateInput
                            styleProps={{
                              border: "2px solid",
                              borderColor: "#9AA5B5",
                              borderRadius: "12px",
                              size: "lg",
                              disabled: true,
                              backgroundColor: "#e9ecef",
                            }}
                            isDisabled={true}
                            value={
                              props?.quoteData?.policyInfo?.d_BinderDate
                                ? formateDate(
                                    props?.quoteData?.policyInfo?.d_BinderDate
                                  )
                                : ""
                            }
                            name="d_TransEffectiveTo"
                            title="transactionInfo"
                          />

                          {/* <Form.Control
                          className="starQuotePageInput"
                          type="date"
                          placeholder=""
                          value={
                            props?.isBind
                              ? props?.isBind
                              : ""
                          }
                          readOnly
                        /> */}
                        </Form.Group>
                      </Col>
                    ) : null}
                    <Col>
                      <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Estimated Premium")}:
                        </Form.Label>
                        {/* <Form.Control
                          className="starQuotePageInput"
                          type="text"
                          placeholder=""
                          value={
                            quoteData?.premiumData &&
                            quoteData?.premiumData.FinalPremium
                              ? quoteData?.premiumData.FinalPremium
                              : ""
                          }
                          name="FinalPremium"
                          title="transactionInfo"
                          onChange={handleChange}
                          readOnly
                        /> */}
                        <CurrencyInput
                          intlConfig={{ locale: "en-US", currency: "USD" }}
                          className="starQuotePageInput"
                          placeholder=""
                          value={
                            props?.feesData?.finalPremium
                              ? props?.feesData?.finalPremium
                              : quoteData?.premiumData?.FinalPremium || 0
                          }
                          name="FinalPremium"
                          title="transactionInfo"
                          disabled
                          style={{
                            width: "100%",
                            textAlign: "right",
                            backgroundColor: "#e9ecef",
                          }}
                          prefix="$"
                        />
                        {typeof quoteData?.propertyCoverageInfo?.metadata
                          ?.originalPrem === "number" &&
                          typeof quoteData?.premiumData?.n_NetPremium ===
                            "number" &&
                          quoteData?.propertyCoverageInfo?.metadata
                            ?.originalPrem !==
                            quoteData?.premiumData?.n_NetPremium && (
                            <Text as={"code"} fontSize={"sm"} color={"red.400"}>
                              {t("Revised Estimated Premium")}
                            </Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <div className="flex-start-class">
                        <Form.Group
                          className="search-quote-form-group"
                          id="search-quote-uw"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="dashboard-group-label">
                            {t("Application Status")}:
                          </Form.Label>
                          <Form.Select
                            className="starQuotePageInput"
                            id="select-ym-option"
                            disabled
                          >
                            <option>Open</option>
                            <option>Closed</option>
                          </Form.Select>
                        </Form.Group>
                        {props.quoteData?.transactionInfo?.n_potransaction_PK !=
                        null ? (
                          <Flex mt={4}>
                            <FaEdit
                              size={20}
                              color={"#64D6CD"}
                              style={{ cursor: "pointer" }}
                              onClick={() => setStatusModal(true)}
                            />
                          </Flex>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                )}

                <Box
                  mx={4}
                  my={4}
                  border={"1px solid"}
                  borderColor={"gray.100"}
                />

                <Row
                  className="align-items-baseline"
                  style={{
                    display: "grid",
                    gridTemplateColumns: props?.isBind
                      ? "repeat(4, minmax(290px, 1fr))"
                      : "repeat(3, minmax(340px, 1fr))",
                  }}
                >
                  <Col>
                    <div className="justify-start-class">
                      <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        style={{ width: "100%" }}
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Agency Name")}:
                        </Form.Label>
                        <AsyncSelect
                          cacheOptions
                          name="agency_id"
                          // id="select-ym-option"
                          loadOptions={loadAgencyOptions}
                          defaultOptions={agencyDD}
                          value={selectedAgency}
                          isDisabled={props?.isBind}
                          onChange={(selectedValue) =>
                            handleChangeAsyncSelect(selectedValue, "Agency")
                          }
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: "2px solid #9aa5b5",
                              paddingLeft: "20px",
                              borderRadius: "12px",
                              height: "48px",
                              backgroundColor: props?.isBind ? "#e9ecef" : "",
                            }),
                          }}
                          // className="dashboard-group-input"
                          required
                        />
                      </Form.Group>
                    </div>
                  </Col>

                  <Col>
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        {t("Producer Name")}:
                      </Form.Label>
                      <AsyncSelect
                        cacheOptions
                        name="agent_id"
                        id="select-ym-option"
                        loadOptions={filterAgentOptions}
                        value={selectedAgent}
                        isDisabled={props?.isBind}
                        onChange={(selectedValue) =>
                          handleChangeAsyncSelect(selectedValue, "Agent")
                        }
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: "2px solid #9aa5b5",
                            paddingLeft: "20px",
                            borderRadius: "12px",
                            height: "48px",
                            backgroundColor: props?.isBind ? "#e9ecef" : "",
                          }),
                        }}
                        // className="dashboard-group-input"
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        {t("Phone Number")}:
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="(111) 111-1111"
                        value={agencyPhone ? agencyPhone : ""}
                        //name="FinalPremium"
                        //title="transactionInfo"
                        //onChange = {handleChange}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ marginTop: "10px" }}
                    xs="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <span className="search-quote-title">
                      {t("Agency Address")} :
                      <span className="search-quote-value">
                        {agencyAddr ? agencyAddr : ""}
                      </span>
                    </span>
                  </Col>
                  <Col
                    style={{ marginTop: "10px" }}
                    xs="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                  >
                    <span className="search-quote-title">
                      {t("Agent Email Address")} :{" "}
                      <span className="search-quote-value">
                        {agencyEmail ? agencyEmail : ""}
                      </span>
                    </span>
                  </Col>
                </Row>
              </div>
            }
          </Box>
          <div>
            <QuoteButtons
              isBind={props?.isBind}
              loading={loader}
              setLoading={setLoader}
              quoteData={props.quoteData}
              rateSubmit={props.rateSubmit}
              baseData={props.baseData}
              feesData={props.feesData}
            />
          </div>
          <Row>
            {props?.isBind === true ? (
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style={{ padding: 0, marginTop: "20px" }}
              >
                <Alert variant="warning">
                  Quote bound, Underwriter will review & contact you shortly.
                </Alert>
              </Col>
            ) : (
              ""
            )}
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style={{ padding: 0, marginTop: "10px" }}
            >
              <Tabs>
                <TabList>
                  <Tab
                    borderTopRadius={"lg"}
                    _selected={{
                      bgColor: "var(--chakra-colors-primary-500)",
                      color: "white",
                    }}
                  >
                    {t("Quote")}
                  </Tab>
                  <Tab
                    borderTopRadius={"lg"}
                    _selected={{
                      bgColor: "var(--chakra-colors-primary-500)",
                      color: "white",
                    }}
                  >
                    {t("Applicant")}
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0} pb={2}>
                    <QuoteTab
                      {...props}
                      setDefaultData={props.setDefaultData}
                      handleChange={handleChange}
                      handleRadioChange={handleRadioChange}
                      handleCheckboxChange={handleCheckboxChange}
                      getZipCodeDetails={props.getZipCodeDetails}
                      isBind={props?.isBind || props?.isInUWReview}
                      rateSubmit={props.rateSubmit}
                      transId={quoteData?.transactionInfo?.n_potransaction_PK}
                      callGeneratePdf={props.callGeneratePdf}
                      rateLoader={props.rateLoader}
                    />
                  </TabPanel>
                  <TabPanel p={0} pb={2}>
                    <ApplicationTab
                      feesData={props?.feesData}
                      handleChange={handleChange}
                      isBind={props?.isBind || props?.isInUWReview}
                      rateSubmit={props.rateSubmit}
                      transId={quoteData?.transactionInfo?.n_potransaction_PK}
                      callGeneratePdf={props.callGeneratePdf}
                      rateLoader={props.rateLoader}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Col>
          </Row>
        </LoadingOverlay>

        {/* search agency modal */}
        <Modal
          className="custom-dashboard-modal"
          size="xl"
          show={agencyModal}
          onHide={() => setAgencyModal(false)}
        >
          <Modal.Header closeButton className="custom-dashboard-modal-header">
            <Modal.Title>Agency Search</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="custom-dashboard-modal-body"
            style={{ padding: 0 }}
          >
            <Row>
              <Col xs="12" id="search-quote-tab-all-filters">
                <Row className="align-items-baseline">
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        Agency Code
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="number"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        Zip
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="number"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        Principal Name
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        Agency Name
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        County
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        Manager Name
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        FEIN Number
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="number"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        State
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        DBA Name
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="4" xl="4">
                    <Form.Group
                      className="search-quote-form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        City
                      </Form.Label>
                      <Form.Control
                        className="starQuotePageInput"
                        type="text"
                        placeholder="Type here"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" style={{ padding: 0 }}>
                <div id="search-agency-modal-table">
                  <ToolkitProvider
                    keyField="agency_code"
                    data={products}
                    columns={columns}
                    bootstrap4
                    search
                  >
                    {(props) => (
                      <div className="search-agency-table-searchbar">
                        <SearchBar {...props.searchProps} />
                        <div className="management-reports-table">
                          <BootstrapTable
                            wrapperClasses="management-functional-table table-responsive"
                            hover
                            bordered={false}
                            pagination={paginationFactory(options)}
                            selectRow={{ mode: "checkbox" }}
                            tabIndexCell
                            noDataIndication={
                              <div className="center-div-class">No data</div>
                            }
                            {...props.baseProps}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        {/* status modal */}
        <Modal
          className="custom-dashboard-modal"
          size="md"
          show={statusModal}
          onHide={() => setStatusModal(false)}
          centered
        >
          <Modal.Header closeButton className="custom-dashboard-modal-header">
            <Modal.Title>Application Status</Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-dashboard-modal-body">
            <Row>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="statusReason">
                  <Form.Label className="dashboard-group-label">
                    Select Reason
                  </Form.Label>
                  <Form.Select className="starQuotePageInput">
                    <option value="">Select a reason</option>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="statusNote">
                  <Form.Label className="dashboard-group-label">
                    Note
                  </Form.Label>
                  <Form.Control
                    className="starQuotePageInput"
                    as="textarea"
                    rows={3}
                    placeholder="Type here"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} className="d-flex justify-content-end gap-2">
                <Button
                  onClick={() => setStatusModal(false)}
                  id="common-btn"
                  variant="primary"
                  type="submit"
                >
                  Update Status
                </Button>
                <Button
                  id="common-cancel-btn"
                  variant="secondary"
                  onClick={() => setStatusModal(false)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Form>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(SearchQuoteTab);
