import React, { useMemo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  TableContainer,
  Text,
} from "@chakra-ui/react";

export const ZipSelectionModal = ({
  isOpen,
  selectedLocation,
  setOpenModal,
  setZipData,
}) => {
  const locationRows = useMemo(() => {
    if (!Array.isArray(selectedLocation)) {
      return [];
    }
    return selectedLocation.map((location, index) => (
      <Tr
        key={index}
        _hover={{ bg: "gray.100" }}
        cursor={"pointer"}
        onClick={() => {
          setZipData(location);
          setOpenModal(false);
        }}
      >
        <Td border={"1px solid"} borderColor={"primary.50"}>
          {index + 1}
        </Td>
        <Td border={"1px solid"} borderColor={"primary.50"}>
          {location.s_CityName}
        </Td>
        <Td border={"1px solid"} borderColor={"primary.50"}>
          {location.s_CountyName}
        </Td>
      </Tr>
    ));
  }, [selectedLocation]);

  return (
    <>
      <Modal size={"2xl"} isOpen={isOpen} onClose={() => setOpenModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Please Select or Skip For Manual Entry</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer
              rounded={"lg"}
              border={"1px solid "}
              borderColor={"gray.200"}
            >
              <Table variant="simple">
                <Thead bg={"primary.50"}>
                  <Tr>
                    <Th border={"1px solid"} borderColor={"primary.50"}>
                      #
                    </Th>
                    <Th border={"1px solid"} borderColor={"primary.50"}>
                      City
                    </Th>
                    <Th border={"1px solid"} borderColor={"primary.50"}>
                      County
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>{locationRows}</Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"sm"}
              borderRadius={"xl"}
              fontWeight={400}
              mr={3}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Skip
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
