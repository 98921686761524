import { getTenant } from "../../../../services/commonService";
import { excludedEmailTenant } from "../instantQuote/common/variables";

const ValidationPrivateProducts = (request, checkDateisValid, firstTime = "N") => {
  var toastArray = [],
    indexArray = [],
    status = true;

  const pushToArray = (index, error = null) => {
    toastArray.push(error);
    indexArray.indexOf(index.toString()) === -1 && indexArray.push(index.toString());
    status = false;
  };

  const returnError = (key) => {
    switch (key) {
      case "s_EntityType":
        return ["Applicant Type is missing", 0];
      case "s_AddressLine1":
        return [
          "Please enter the House No, Street Name, Direction of the Location separately. This is required to get the correct valuation of the property.",
          0,
        ];
      case "s_PostalCode":
        return ["Zip code is missing", 0];
      case "s_PostalCodeSuffix":
        return ["Suffix address is invalid", 0];
      case "s_CommType":
        return ["Phone Type is missing", 0];
      case "s_CondoOwnership":
        return ["Is the policyholder a condominium association?", 0];
      case "s_PolicyWaitingPeriod":
        return ["Waiting Period is invalid.", 2];
      case "s_FloodProgramType":
        return ["Program Type is missing.", 3];
      case "d_InitialFirmDate":
        return ["Initial Firm Date is missing or invalid.", 3];
      case "s_CountyName":
        return ["County Name is missing.", 3];
      case "s_FloodZone":
        return ["Flood Zone is missing.", 3];
      case "s_ManufactureMobileHome":
        return ["Please select Is the Structure a mobile home?", 4];
      case "d_DateOfConstruction":
        return ["Construction Date is missing.", 4];
      case "s_BuildingCourseConstruction":
        return [
          "Please select Is the building currently under construction?",
          4,
        ];
      case "s_Occupancy":
        return ["Occupancy type is invalid.", 5];
      case "s_BuildingUse":
        return ["Please select the value that best describes the building.", 5];
      case "s_Foundation":
        return ["Foundation type is invalid.", 6];
      case "s_BuildingOverWater":
        return ["Building Over Water indicator is missing or invalid.", 6];
      case "s_TotalSqFootage":
        return ["Total sq footage of building is missing.", 6];
      case "n_NoOfFloors":
        return ["Total No Of Floor is missing.", 6];
      case "s_BldgConstructionType":
        return ["Building Construction Type is missing.", 6];
      case "s_NoOfElevator":
        return ["No Of Elevators is missing", 6];
        default:
          return ["", 0];
    }
  };

  for (const [key, val] of Object.entries(request)) {
    // main Object
    if (
      (
        [
          "propertyInfo",
          "policyInfo",
          "personInfo",
          "personAddr",
          "personPhoneContact",
          "propertyCoverageInfo",
        ].includes(key) ||
        (!excludedEmailTenant.includes(getTenant()) && key === "personEmailContact")
      ) &&
      Object.keys(val).length > 0
    ) {
      const innerKeys = [
        "s_EntityType",
        "s_AddressLine1",
        "s_PostalCode",
        "s_PostalCodeSuffix",
        "s_CommValue",
        "s_CommType",
        "s_CondoOwnership",
        "s_PolicyWaitingPeriod",
        "s_FloodProgramType",
        "s_FloodZone",
        "s_CountyName",
        "d_InitialFirmDate",
        "s_ManufactureMobileHome",
        "d_DateOfConstruction",
        "s_BuildingCourseConstruction",
        "s_Occupancy",
        "s_BuildingUse",
        "s_Foundation",
        "s_BuildingOverWater",
        "s_TotalSqFootage",
        "n_NoOfFloors",
        "s_BldgConstructionType",
        "n_ContentCvg",
        "s_ContentDeductible",
        "s_NoOfElevator",
        "s_IsNewPurchase"
      ];
      // inner Object
      for (const [innerKey, innerVal] of Object.entries(val)) {
        // check value except date
        if (innerKeys.includes(innerKey)) {
          var errorData = [];
          // Check Date
          if ([
            "d_InitialFirmDate",
            "d_DateOfConstruction"
          ].includes(innerKey) &&
            !checkDateisValid(innerVal)) {
            errorData = returnError(innerKey);
          }
          if (![
            "d_InitialFirmDate",
            "d_DateOfConstruction"
          ].includes(innerKey) && [null, ""].includes(innerVal)) {
            if (firstTime == "Y") {
              let ignoreFields = ["s_PostalCodeSuffix", "s_FloodProgramType", "s_CountyName"];
              if (!ignoreFields.includes(innerKey)) {
                errorData = returnError(innerKey);
              }
            } else {
              errorData = returnError(innerKey);
            }
          }
          if (errorData && errorData.length > 0) {
            pushToArray(errorData[1], errorData[0]);
          }
        }
        if (innerKey === "s_EntityType") {
          if (innerVal === "INDIVIDUAL") {
            if (
              [null, ""].includes(
                request["personInfo"]["s_LastOrganizationName"].trim()
              ) ||
              [null, ""].includes(request["personInfo"]["s_FirstName"].trim())
            ) {
              if (
                [null, ""].includes(
                  request["personInfo"]["s_LastOrganizationName"].trim()
                )
              ) {
                pushToArray(0, "Last name should not be empty");
              }
              if ([null, ""].includes(request["personInfo"]["s_FirstName"].trim())) {
                pushToArray(0, "First name should not be empty");
              }
            }
          }
          if (["BUSINESS", "ASSOCIATION"].includes(innerVal)) {
            if (
              [null, ""].includes(
                request["personInfo"]["s_LastOrganizationName"].trim()
              )
            ) {
              pushToArray(0, "Organization name should not be empty");
            }
          }
        }

        if (innerKey === "s_IsMailingDiff" && innerVal === "YES") {
          if (
            [null, ""].includes(request["personAddrMail"]["s_AddressLine1"])
          ) {
            pushToArray(
              0,
              "Please enter the House No,Street Name,Direction of the Location separately. This is required to get the correct valuation of the property."
            );
          }

          if ([null, ""].includes(request["personAddrMail"]["s_PostalCode"])) {
            pushToArray(0, "Please Enter Zip code");
          }
        }

        if (innerKey === "s_IsTempMailing" && innerVal === "YES") {
          if (
            [null, ""].includes(request["tempMailingAddr"]["s_AddressLine1"])
          ) {
            pushToArray(
              0,
              "Please enter the House No,Street Name,Direction of the Location separately. This is required to get the correct valuation of the property."
            );
          }

          if ([null, ""].includes(request["tempMailingAddr"]["s_PostalCode"])) {
            pushToArray(0, "Please Enter Zip code");
          }
        }

        //policy wating period
        if (innerKey === "s_PolicyWaitingPeriod") {
          if (innerVal === "LOANNOWAIT") {
            if (!checkDateisValid(request.propertyInfo.d_FloodLoanClosingDt)) {
              pushToArray(2, "Please enter Loan Closing Date ");
            }
          }
          if (innerVal === "ROLLOVER_RENEWAL") {
            if (!checkDateisValid(request.propertyInfo.s_PriorPolicyExpDt)) {
              pushToArray(2, "Please enter Prior Policy Exp Date");
            }
            if ([null, ""].includes(request.propertyInfo.s_PriorCompanyNAIC)) {
              pushToArray(2, "Please enter Prior NAIC Number");
            }
            if ([null, ""].includes(request.propertyInfo.s_PriorPolicyNo)) {
              pushToArray(2, "Please enter Prior Policy Number");
            }
          }
        }

        //construction Location of Mobile home
        if (innerKey === "s_ManufactureMobileHome" && innerVal === "YES") {
          if (
            [null, ""].includes(request.propertyInfo.s_DtOfConstructionSource)
          ) {
            pushToArray(4, "Please enter Date of Constuction Mobile Home");
          }
        }

        if (innerKey === "n_ReplacementCost" && innerVal <= 0) {
          pushToArray(9, "Replacement Cost is missing or invalid");
        }
        if (request.propertyInfo.s_Occupancy === 'RESIDENTIALCONDOBUILD20') {
          if (innerKey === "n_BuildingCvg" && innerVal <= 0) {
            pushToArray(9, "Building Coverage is missing or invalid");
          }
          if (innerKey === "s_BuildingDeductible" && [0, null, ''].includes(innerVal)) {
            pushToArray(9, "Building Deductible is missing or invalid");
          }
          // if (innerKey === "n_ReplacementCostRatio" && innerVal <= 0) {
          //   pushToArray(9, "Replacement Cost Ratio is missing or invalid");
          // }
        }

        if (innerKey === "s_isValidElevationCert" && innerVal === 'YES') {
          if (!checkDateisValid(request.propertyInfo.d_CertificateDate)) {
            pushToArray(12, "Certificate Signature date is missing or invalid");
          }
        }

        if (innerKey === 's_IsPropNewlyMapped' && innerVal === 'YES') {
          var priorObj = {
            'Prior Community Number is missing or invalid': request.propertyInfo.s_PriorCommunityNumber,
            'Prior Panel Number is missing or invalid': request.propertyInfo.s_PriorPanelNumber,
            'Prior Map Suffix is missing or invalid': request.propertyInfo.s_PriorMapSuffix,
            'Prior Flood Zone is missing or invalid': request.propertyInfo.s_PriorFloodZone,
            'Prior Map Date is missing or invalid': request.propertyInfo.d_PriorMapDate
          }
          for (const [key, value] of Object.entries(priorObj)) {
            if (["Prior Community Number is missing or invalid",
              "Prior Panel Number is missing or invalid",
              "Prior Map Suffix is missing or invalid",
              "Prior Flood Zone is missing or invalid"].includes(key) && [null, ""].includes(value)) {
              pushToArray(8, key);
            }
            if (["Prior Map Date is missing or invalid"].includes(key) && !checkDateisValid(value)) {
              pushToArray(8, key);
            }
          }
        }

        // ODS-860
        /*if (innerKey === "s_IsNewPurchase" && innerVal !== 'YES') {
          if (request["propertyInfo"]["isSellerDec"] !== 'YES') {
            pushToArray(8, "Statutory discounts applied to the prior policy cannot be maintained without the sellers dec page");
          }
        }*/
      }
    }

    //occupancy

    // effective date
    if (key === "effectiveDate") {
      if (!checkDateisValid(val)) {
        pushToArray(2, "Please enter Effective Date");
      }
    }
  }
  //coverage check based on Occupancy
  var occupancy = request.propertyInfo.s_Occupancy;
  var isBldgCvgExceed = false;
  var isContentCvgExceed = false;
  var maxLimit = "";
  var NOU = "",
    RC = "";

  // Building coverage maxes out at 250k and contents at 100k for residential
  if (
    [
      "SINGLEFAMILY20",
      "TWOFOURFAMILY20",
      "RESIDENTIALUNIT20",
      "RESIDENTIALMOBILEHOME20",
    ].includes(occupancy)
  ) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 250000) {
      isBldgCvgExceed = true;
      maxLimit = 250000;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }
  // Building coverage maxes out at 250k and contents at 100k for residential
  if (["OTHERRESIDENTIAL20"].includes(occupancy)) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 500000) {
      isBldgCvgExceed = true;
      maxLimit = 500000;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }
  // For a non-residential, it is 500k and 500k.
  if (
    [
      "NONRESIDENTIALUNIT20",
      "NONRESIDENTIALBUILD20",
      "NONRESIDENTIALMOBILEHOME20",
    ].includes(occupancy)
  ) {
    if (request.propertyCoverageInfo.n_BuildingCvg > 500000) {
      isBldgCvgExceed = true;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 500000) {
      isContentCvgExceed = true;
    }
    maxLimit = 500000;
  }
  // RCB max for building can be the RCE value or 250k multiplied by the number of units. wich ever is less. Contents max is 100k
  if (["RESIDENTIALCONDOBUILD20"].includes(occupancy)) {
    if (
      (RC < 250000 * NOU && request.propertyCoverageInfo.n_BuildingCvg > RC) ||
      (250000 * NOU < RC &&
        request.propertyCoverageInfo.n_BuildingCvg > 250000 * NOU)
    ) {
      isBldgCvgExceed = true;
    }
    if (request.propertyCoverageInfo.n_ContentCvg > 100000) {
      isContentCvgExceed = true;
      maxLimit = 100000;
    }
  }

  if (isBldgCvgExceed == true) {
    pushToArray(
      5,
      "Coverage Max Limit Exceed!, building coverage should be upto " + maxLimit
    );
  }
  if (isContentCvgExceed == true) {
    pushToArray(
      5,
      "Coverage Max Limit Exceed!, content coverage should be upto " + maxLimit
    );
  }
  if ([null, ""].includes(request["personEmailContact"]["s_CommValue"]) && !excludedEmailTenant.includes(getTenant())) {
    pushToArray(0,"Email is missing.");
  }
  if ([null, ""].includes(request["personPhoneContact"]["s_CommValue"])) {
    pushToArray(0,"Phone Number is missing.");
  }
  
  return {
    toastArray,
    indexArray,
    status,
  };
};

export default ValidationPrivateProducts;
