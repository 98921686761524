import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs ,Spinner} from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { connect, useDispatch } from "react-redux";
import {transactionTypeData,saveNewTransaction,getReasonCode,getTransSubTypeDDData, getPropertyInfo} from "../../../services/coreSystemService";
import {checkDateisValid, dateFormat} from "../../../services/commonService";

import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextMask, InputAdapter } from 'react-text-mask-hoc';
import Moment from 'moment';

import {
  verifyPolicy, setPolicyPropsData, getBaseData
} from "../../../services/coreSystemService";
import { resetCoreStore } from "../../../store/actions";

const NewTransaction = (props) => {
  const { loading, setLoading } = props;
  const animatedComponents = makeAnimated();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { policyNumber ,type = ''} = useParams();
  // const [loading, setLoading] = useState(false);
  const [showNewTransactionModal, setShowNewTransactionModal] = useState(false);
  const [transactionTypeDD, settransactionTypeDD] = useState([]);
  const [ReaconCodeTypeDD, setReaconCodeTypeDD] = useState([]);
  const [TransactionEffDate, setTransactionEffDate] = useState('');
  const [TransEffectiveTo, setTransEffectiveTo] = useState('');
  const [TransactionDate, setTransactionDate] = useState(new Date(Moment(new Date())));
  const [TransctionSelected, setTransctionSelected] = useState('ENDORSE');
  const [TransctionNote, setTransctionNote] = useState('');
  const [reasonCode, setReasonCode] = useState([]);
  const [isCorrection, setIsCorrection] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [TransSubTypeDD, setTransSubTypeDD] = useState([]);
  const [subTransctionSelected, setSubTransctionSelected] = useState('');
  const [tranSubTypeCode, setTranSubTypeCode] = useState('');
  const [isEndorsement, setIsEndorsement] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState('');
  const [waitingPeriodDD, setWaitingPeriodDD] = useState([]);
  const [isEffectiveDateSetForEndorse, setIsEffectiveDateSetForEndorse] = useState(false);
  
  useEffect(() => {
    if (Object.keys(props.applicationData).length != 0) {
      transactionModalFunction();
    }
  }, [type,props.applicationData]);

  // Set waiting period if Endorse
  useEffect(() => {
    if (Object.keys(props.propertyInfo).length > 0) {
      setWaitingPeriod(props.propertyInfo.RA.s_PolicyWaitingPeriod);
    }
  }, [props.propertyInfo]);

  // set endorse effective date first time
  useEffect(() => {
    if (!["", null, undefined].includes(waitingPeriod) && isEffectiveDateSetForEndorse === false) {
      setIsEffectiveDateSetForEndorse(true);
      setTransactionEffDate(new Date(getEffectiveDateFromWP(waitingPeriod, new Date())));
    }
  }, [waitingPeriod]);

  // If Selected Transction is endorsement
  useEffect(() => {
    var temp = false;
    if (TransctionSelected === "ENDORSE") {
      temp = true;
    }
    // get policy waiting period
    if (waitingPeriod.length === 0) {
      getWaitingPeriod();
    }
    setIsEndorsement(temp);
  }, [TransctionSelected])

  useEffect(() => {
    const callSubTypeFunction = async () => {
      if(props.transactionType == 'CANCEL'){
        const param = {
          TransType: 'CANCEL',
          product : 10
        }
        await dispatch(getTransSubTypeDDData(param)).then((res) => { 
          setTransSubTypeDD(res.data.TransactionDropDown);
        });
      }
      setIsEndorsement(true);
      setTransctionSelected(props.transactionType);
    }
    callSubTypeFunction();
  }, [props.transactionType]);

  // get policy waiting period
  const getWaitingPeriod = async () => {
    await dispatch(
      getPropertyInfo(props.n_POPolicyMasterFK, props.n_POTransactionFK)
    );
  }

  // get effective date based on waiting period
  const getEffectiveDateFromWP = (waitingPeriod, effectiveDate) => {
    var date = Moment(effectiveDate); // effective date

    if (['STANDARD30', 'LENDER30'].includes(waitingPeriod)) {
      date = Moment(effectiveDate).add(30, 'days');
    }

    return date;
  }

  const transactionModalFunction = async () => {
    setLoading(true);
    await dispatch(transactionTypeData()).then((res) => { 
      if(res.success == true){
        settransactionTypeDD(res.data.TransactionDropDown);
        setWaitingPeriodDD(res.data.FLOODWAITINGPERIOD);
        setReaconCodeTypeDD(res.data.TRANREASONCODE);
        setLoading(false);
        setShowNewTransactionModal(true);
        setTransEffectiveTo(new Date(Moment(new Date(props.applicationData.d_TransEffectiveTo.replace(/-/g, '\/')))));
        setTransactionEffDate(new Date(Moment(props.applicationData.d_TransEffectiveFrom.replace(/-/g, '\/'))));
        if (props.transactionType == "ENDORSE") {
          if(props.propertyInfo && Object.keys(props.propertyInfo).length > 0) {
            setTransactionEffDate(new Date(getEffectiveDateFromWP(props.propertyInfo.RA.s_PolicyWaitingPeriod, new Date())));
          }
        }
      }
    });
    if(TransctionSelected == 'ENDORSE'){
      let ddParam = {list:'FLENDORSEMENTTRANSUBTYPE'}
          transactionReason(ddParam);
    }
  };

  const transactionReason = async (ddParam) => {
    await dispatch(getReasonCode(ddParam)).then((res) => { 
      setReaconCodeTypeDD(res.data.FLENDORSEMENTTRANSUBTYPE);
    });
  }

  const inputChangedHandler = async (event, key) => {
    
    const param = {
      TransType: event.target.value,
      product : 10
    }
    if(key == 's_PRTranTypeCode'){
      if(event.target.value == 'ENDORSE'){
        let ddParam = {list:'FLENDORSEMENTTRANSUBTYPE'}
        transactionReason(ddParam);
        setSubTransctionSelected('');
      }

      setTransctionSelected(event.target.value);
      
      if(event.target.value == 'CANCEL'){
        await dispatch(getTransSubTypeDDData(param)).then((res) => { 
          setTransSubTypeDD(res.data.TransactionDropDown);
        });
      }
    }
    if(key == 'transactionNote'){
      setTransctionNote(event.target.value);
    }
    if(key == 's_PRTranSubTypeCode'){
      setSubTransctionSelected(event.target.value);
    }
    
  }

  const reasonChangedHandler = (reasons, key) => {
    
    let value = [];
    if(reasons != null){
      for (let i = 0, l = reasons.length; i < l; i++) {
        value.push(reasons[i].s_AppCodeName);
      }
    }
    setReasonCode(value);
  }

  const setDate = (date, key) => {
    /**
     * Do not allow year digit greater then 4
     * while manually entering date with masking
     * Issue with date-picker
     */
    const yearInString = Moment(date).year().toString();
    if (!isNaN(yearInString) && yearInString.length <= 4) {
      switch (key) {
        case 'd_TransEffectiveFrom':
          setTransactionEffDate(new Date(Moment(date)));
          break;
        case 'TransactionEffDate':
          setTransEffectiveTo(new Date(Moment(date)));
          break;
        case 'TransactionDate':
          setTransactionDate(new Date(Moment(date)));
          break;
        default:
          toast.error(key + ' key not define');
          break;
      }
    }
  }
  

const handleSubmit = async () => {
  var effectiveDate = TransactionEffDate;
  var s_PRTranSubTypeCodeVar = subTransctionSelected;
  if(TransctionSelected == 'ENDORSE'){
    s_PRTranSubTypeCodeVar = 'RATEINFO'
    // effectiveDate = getEffectiveDateFromWP(waitingPeriod, effectiveDate);
  }
  const params = {
    s_PRTranTypeCode : TransctionSelected,
    s_PRTranSubTypeCode : s_PRTranSubTypeCodeVar,
    s_PRTranReasonCode : 'NONE',
    s_RequestedBy : 'INSURED',
    n_StandardTerm: "12",
    s_StandardTermUnit: "MONTH",
    s_TermTypeCode: "NONCOMMON",
    d_TransEffectiveFrom:  dateFormat(effectiveDate, 'YYYY-MM-DD'), 
    d_TransEffectiveTo: dateFormat(TransEffectiveTo, 'YYYY-MM-DD'),
    d_AccountingDate: dateFormat(TransactionDate, 'YYYY-MM-DD'),
    s_Note: TransctionNote,
    Policy_No:props.applicationData.Policy_No,
    PolicyPK:props.n_POPolicyMasterFK,
    n_TermMaster_PK:props.n_POTermMasterFK,
    TransPK:props.n_POTransactionFK,
    isCorrection: isCorrection,
    reasonCode : reasonCode,
    isPoAssignmant:false,
    source: 'AGENT_PORTAL'
  }
  if (TransctionSelected === 'ENDORSE') {
    params['s_PolicyWaitingPeriod'] = waitingPeriod;
    params['effectiveDate'] = getEffectiveDateFromWP(waitingPeriod, effectiveDate);
    params['currentWaitingPeriod'] = props.propertyInfo.RA.s_PolicyWaitingPeriod;
  }

  const validat = validationFunction(params);

  if(validat){
    setDisableButton(true);
      await dispatch(saveNewTransaction(params)).then((res) => { 
        if(res.Status == 0){
          setShowNewTransactionModal(false);
          toast.success('Transaction Saved Successfully');
          verify();
        }else{
          // display error message if exist
          toast.error(res.msg || 'Transaction Failed');
        };
      });
      setDisableButton(false);
  }
}

const verify = async () => {
  setLoading(true);
  var params = {};
  await dispatch(verifyPolicy(policyNumber)).then((res) => {
    if (res.status) {
      params = {
        n_POPolicyMasterFK: res.policyIdPK,
        n_POTermMasterFK: res.termMasterPK,
        n_POTransactionFK: res.poTransPK,
      };
    } else {
      setLoading(false);
      toast.error(res.message);
      navigation(`/core/policy`);
    }
  });
  await dispatch(setPolicyPropsData(params));
  await dispatch(
    getBaseData(params.n_POTransactionFK)
  );
  setLoading(false);
  navigation(`/core/policy/${policyNumber}`);
  dispatch(resetCoreStore());
  window.location.reload();
};

const validationFunction = (params) => {
    let param = params;
    let error = "N";
    let msg = "";
    var s_PRTranTypeCode = props.transactionDD[0].s_PRTranTypeCode;
    if (s_PRTranTypeCode == 'CANCEL') {
      error = "Y";
      msg = "Please Contact Underwriter";
    }

    if (param.s_Note == null || param.s_Note == "") {
        error = "Y";
        msg = "Please Enter Transaction Note";
    }
    if (param.s_PRTranTypeCode == null || param.s_PRTranTypeCode == "") {
        error = "Y";
        msg = "Please Select Transaction Type";
    }
    if (param.s_PRTranTypeCode === "ENDORSE" && param.s_PolicyWaitingPeriod.length === 0) {
        error = "Y";
        msg = "Please Select Waiting Period Error";
    }
    if (!checkDateisValid(param.d_TransEffectiveFrom) || [null, undefined, '', '0000-00-00'].includes(param.d_TransEffectiveFrom)) {
      error = "Y";
      msg = "Enter Effective Date";
    }
    if(error == "Y"){
        toast.error(msg);
        return false;
    } else {
        return true;
    }
     
}

  // close modal
  const handleClose = (value) => {
    if (disableButton == false) {
      setShowNewTransactionModal(value);
      props.setShowNewTransactionModal(value);
    }
  }

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="policy-details-section">
        <Modal
          className="custom-dashboard-modal"
          size="md"
          show={showNewTransactionModal}
        >
          <Modal.Header closeButton onClick={()=>handleClose(false)} className="custom-dashboard-modal-header">
            <Modal.Title> {TransctionSelected === "CANCEL" ? t('Canecl Transaction') : t('Endorsement Transaction') } </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-dashboard-modal-body">
            <div className="inbox-page-section">
                 
              {!isEndorsement && (
                <Col xs="12">
                  <Form.Group
                    controlId="formBasicEmail"
                  >
                  <Row>

                    <Col xs="5">
                      <Form.Label
                        className="group-label"
                      >
                        {t('Transaction Type')}
                      </Form.Label>
                    </Col>
                    <Col xs="7">
                      <Form.Select
                        name="transactionType"
                        className="group-input"
                        id="select-ym-option"
                        disabled={TransctionSelected === "CANCEL" }
                        value={TransctionSelected}
                        onChange={(event) => inputChangedHandler(event, 's_PRTranTypeCode')}
                        >
                           {Object.keys(transactionTypeDD).length > 0 &&
                      transactionTypeDD.map((v, i) => {
                        return (
                          <option key={i} value={v.s_AppCodeName}>
                            {v.s_AppCodeNameForDisplay}
                          </option>
                        );
                      })}
                      </Form.Select>
                    </Col>
                  </Row>
                  <br></br>
                </Form.Group>      
              {TransctionSelected == 'ENDORSE' ?
              <div>
                  <Form.Group>
                      <Row>
                        <Col md="3" sm="12" style={{marginTop:'10px'}}>
                          <p>{t('Mark as Correction')}? </p>
                        </Col>
                        <Col md="3" sm="12" style={{marginTop:'10px'}}>
                          <Form.Check
                              type="checkbox"
                               name="isCorrection" 
                                className="table-checkbox"
                                id="isCorrection"
                                checked={isCorrection}
                                onChange={(e) => setIsCorrection(e.target.checked)}
                              />

                                        
                        </Col>
                      </Row>
                  </Form.Group>
                    <Form.Group>
                      <Row>
                        <Col  xs="4">
                          <Form.Label
                            className="group-label"
                          >
                            {t('Transaction Reason Code')}
                          </Form.Label>
                        </Col>
                        <Col xs="6">
                          <Select
                            //onChange={(event) => this.reasonChangedHandler(event, 's_PRTranReasonCode')}
                            options={ReaconCodeTypeDD}
                            components={animatedComponents}
                            placeholder="Select Reason"
                            isMulti
                            getOptionLabel={(option) => option.s_AppCodeNameForDisplay}
                            getOptionValue={(option) => option.s_AppCodeName}
                            onChange={(event) => reasonChangedHandler(event, 's_PRTranReasonCode')}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    </div>
                    : null }

                    {
                      TransctionSelected == 'CANCEL' ? 
                      <div>
                        
                        <Col xs="12">
                  <Form.Group
                    controlId="formBasicEmail"
                  >
                  <Row>

                    <Col xs="5">
                      <Form.Label
                        className="group-label"
                      >
                        {t('Transaction Sub-Type:')}
                      </Form.Label>
                    </Col>
                    <Col xs="7">
                      <Form.Select
                        name="transactionType"
                        className="group-input"
                        id="select-ym-option"
                        value={subTransctionSelected}
                        onChange={(event) => inputChangedHandler(event, 's_PRTranSubTypeCode')}
                        >
                        <option value=''>Select Transaction Sub Type</option>
                      {TransSubTypeDD.map((v, i) => {
                        return (
                          <option key={i} value={v.s_AppCodeName}>
                            {v.s_AppCodeNameForDisplay}
                          </option>
                        );
                      })}
                      </Form.Select>
                    </Col>
                    </Row>
                    </Form.Group>
                    </Col>
                      </div>
                      : 
                      null
                    }
                    <br></br>
                    <Form.Group className="confirm-address-field" >
                      <Row>
                        <Col xs="5">
                          <Form.Label className="confirm-address-label">
                              {t("Effective Date")}
                          </Form.Label>
                        </Col>
                        <Col xs="7">
                        <DatePicker
                        customInput={
                          <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter}
                          />
                        }
                        className="form-control form-control-md" selected={TransactionEffDate} name="d_TransEffectiveFrom" onChange={(date) => setDate(date, 'd_TransEffectiveFrom')}  />
                        </Col>
                      </Row>
                    </Form.Group> 
                    <br></br>
                    <Form.Group className="confirm-address-field" >
                      <Row>
                        <Col xs="5">
                          <Form.Label className="confirm-address-label">
                            {t("Transaction Eff. To")}
                          </Form.Label>
                        </Col>
                        <Col xs="7">
                        <DatePicker
                        customInput={
                          <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter}
                          />
                        }
                        className="form-control form-control-md" selected={TransEffectiveTo} name="TransEffectiveTo"  readOnly={true} onChange={(date) => setDate(date, 'TransEffectiveTo')}  />
                        </Col>
                      </Row>
                    </Form.Group> 
                    <br></br>
                    <Form.Group className="confirm-address-field" >
                      <Row>
                        <Col xs="5">
                          <Form.Label className="confirm-address-label">
                              {t("Transaction Date")}
                          </Form.Label>
                        </Col>
                        <Col xs="7">
                        <DatePicker
                        customInput={
                          <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter}
                          />
                        }
                        className="form-control form-control-md" selected={TransactionDate} name="TransactionDate"  readOnly={true} onChange={(date) => setDate(date, 'TransactionDate')}/>
                        </Col>
                      </Row>
                    </Form.Group> 
                    <br></br>
                  </Col>
              )}
              {
                isEndorsement && (
                <>
                  <Form.Group controlId="formBasicEmail">
                    <Row>
                      <Col xs="4" >
                        <Form.Label
                          className="group-label"
                        >
                          {t('Waiting Period')}
                        </Form.Label>
                      </Col>
                      <Col xs="7">
                        <Form.Select
                          className="table-show-entries-select"
                          name="s_PolicyWaitingPeriod"
                          title={t('Waiting Period')}
                          value={waitingPeriod}
                          onChange={(e) => {
                            setWaitingPeriod(e.currentTarget.value);
                            // set effective date based on waiting period
                            if (TransctionSelected === "ENDORSE") {
                              setTransactionEffDate(new Date(getEffectiveDateFromWP(e.currentTarget.value, new Date())));
                            }
                          }}
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            waitingPeriodDD.length > 0 && waitingPeriodDD.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                      </Col>
                    </Row>
                      <Row className="mt-3">
                        <Col xs="4">
                          <Form.Label className="confirm-address-label">{t("Effective Date")}</Form.Label>
                        </Col>
                        <Col xs="7">
                          <DatePicker
                            minDate={new Date(props.applicationData.d_TransEffectiveFrom)}
                            customInput={
                              <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter}
                              />
                            }
                            className="form-control form-control-md"
                            selected={TransactionEffDate}
                            name="d_TransEffectiveFrom"
                            onChange={(date) => setDate(date, 'd_TransEffectiveFrom')}
                          />
                        </Col>
                      </Row>
                    </Form.Group> 
                    <br></br>
                  </>
                )
              }
              <Form.Group className="confirm-address-field" >
                      <Row>
                      
                        <Col xs={isEndorsement ? "4" : "5"}>
                          <Form.Label className="confirm-address-label">
                              {t("Transaction Note")}
                          </Form.Label>
                        </Col>
                        <Col xs="7">
                        <textarea id="transactionNote" name="transactionNote" rows="4" cols="18" onChange={(event) => inputChangedHandler(event, 'transactionNote')}></textarea>
                        {/* <Form.Control
                              type="textarea"
                              name="note"
                              title="note"
                              rows="5"
                              /> */}
                        </Col>
                      </Row>
                    </Form.Group> 
              
             
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button  id="common-btn" size="sm" variant="primary" style={{ padding: "8px 8px" }}>Save</Button> */}
            <Col md={{ size: 6, offset: 3 }} sm="12" className="mt-2 text-center" style={{display: "ruby"}}>
              <Button id="common-btn" size="sm" disabled={disableButton} variant="primary" style={{ padding: "8px 8px" }} onClick={() => handleSubmit()}>{disableButton ? (<><Spinner
				          as="span"
				          variant="light"
				          size="sm"
				          role="status"
				          aria-hidden="true"
				          animation="border" /> &nbsp;</>) : null}Save & Close</Button>&nbsp;&nbsp;
              <Button id="common-btn" size="sm" variant="primary" style={{ padding: "8px 8px" }} className="ml-4" onClick={() => {setShowNewTransactionModal(false); props.setShowNewTransactionModal(false); }} disabled={disableButton}>Exit</Button>
            </Col>
          </Modal.Footer>
          </Modal>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionDD: state.coreSystem.transactionDD,
    termSelection: state.coreSystem.termSelection,
    n_POPolicyMasterFK: state.coreSystem.n_POPolicyMasterFK,
    n_POTermMasterFK: state.coreSystem.n_POTermMasterFK,
    n_POTransactionFK: state.coreSystem.n_POTransactionFK,
    applicationData: state.coreSystem.applicationData,
    newTransactionBaseData: state.coreSystem.newTransactionBaseData,
    propertyInfo: state.coreSystem.propertyInfo,
  };
};
export default connect(mapStateToProps)(NewTransaction);
