import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useStarDataContext } from "../../StarDataContext";
import { useTranslation } from "react-i18next";
import { useAutoZipSelection } from "../../utils/useAutoZipDetection";
import { useEffect } from "react";
import states from "../../../common/States";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import { IoInformationCircleOutline } from "react-icons/io5";
import { FcInfo } from "react-icons/fc";

export default function NewPurchaseLocation() {
  const { underWriting, setUnderWriting, validationError } =
    useStarDataContext();
  const { t } = useTranslation();
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    underWriting?.propertyDetails?.newPurchaseAddr?.zipcode,
    underWriting?.propertyDetails?.isThisNewPurchase
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (
      zipData &&
      Object.keys(zipData).length > 0 &&
      underWriting?.propertyDetails?.isThisNewPurchase
    ) {
      setUnderWriting({
        ...underWriting,
        propertyDetails: {
          ...underWriting?.propertyDetails,
          newPurchaseAddr: {
            ...underWriting?.propertyDetails?.newPurchaseAddr,
            city: zipData?.s_CityName || "",
            state: zipData?.s_StateCode || "",
            cityId: zipData.city_id ?? 0,
            stateId: zipData.state_id ?? 0,
            countryId: zipData.n_CountryId_FK ?? 1,
            countyId: zipData.county_id ?? 0,
            county: zipData.s_CountyName ?? "",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipData]);

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />{" "}
      <Box
        display={"flex"}
        gap={2}
        borderTop={"1px solid"}
        borderColor={"gray.200"}
        py={2}
      >
        <Text fontWeight={500} pl={1} pt={3} pb={0}>
          Please provide your prior physical residence address
        </Text>
        <Tooltip
          textAlign={"center"}
          hasArrow
          p={3}
          label={`A 'prior physical residence address' refers to the location where a person lived before their current residence.`}
          placement="top"
        >
          <Box mt={"15px"}>
            <FcInfo size={20} />
          </Box>
        </Tooltip>
      </Box>
      <Grid w="100%" templateColumns="repeat(4, 1fr)" gap={4} py={2}>
        <>
          <GridItem w="100%">
            <FormControl isRequired>
              <FormLabel pl={1} fontWeight={500}>
                Street Address:
              </FormLabel>
              <Input
                className="inputBorder"
                border={"2px solid var(--chakra-colors-neutral-500)"}
                borderRadius={"xl"}
                name="street_address"
                type="text"
                size="lg"
                focusBorderColor="secondary.500"
                value={
                  underWriting?.propertyDetails?.newPurchaseAddr
                    ?.street_address || ""
                }
                onChange={(e) => {
                  setUnderWriting({
                    ...underWriting,
                    propertyDetails: {
                      ...underWriting?.propertyDetails,
                      newPurchaseAddr: {
                        ...underWriting?.propertyDetails?.newPurchaseAddr,
                        street_address: e.target.value,
                      },
                    },
                  });
                }}
              />
              {validationError?.underWritingPage &&
                underWriting?.propertyDetails?.isThisNewPurchase &&
                !underWriting?.propertyDetails?.newPurchaseAddr
                  ?.street_address && (
                  <Text pr={1} color={"red"}>
                    {t("Street Address is required")}.
                  </Text>
                )}
            </FormControl>
          </GridItem>

          <GridItem w="100%">
            <FormControl isRequired>
              <FormLabel pl={1} fontWeight={500}>
                {t("City")}:
              </FormLabel>
              <Input
                bg={"gray.100"}
                cursor={"not-allowed"}
                autoComplete="off"
                isDisabled
                _disabled={{ opacity: 1 }}
                className="inputBorder"
                border={"2px solid var(--chakra-colors-neutral-500)"}
                borderRadius={"xl"}
                name="city"
                type="text"
                size="lg"
                focusBorderColor="secondary.500"
                value={
                  underWriting?.propertyDetails?.newPurchaseAddr?.city || ""
                }
                onChange={(e) => {
                  setUnderWriting({
                    ...underWriting,
                    propertyDetails: {
                      ...underWriting?.propertyDetails,
                      newPurchaseAddr: {
                        ...underWriting?.propertyDetails?.newPurchaseAddr,
                        city: e.target.value,
                      },
                    },
                  });
                }}
              />
              {validationError?.underWritingPage &&
                underWriting?.propertyDetails?.isThisNewPurchase &&
                !underWriting?.propertyDetails?.newPurchaseAddr?.city && (
                  <Text pr={1} color={"red"}>
                    {t("City is required")}.
                  </Text>
                )}
            </FormControl>
          </GridItem>

          <GridItem w="100%">
            <FormControl isRequired>
              <FormLabel pl={1} fontWeight={500}>
                {t("State")}:
              </FormLabel>
              <Select
                bg={"gray.100"}
                cursor={"not-allowed"}
                isDisabled
                _disabled={{ opacity: 1 }}
                autoComplete="off"
                className="inputBorder"
                border={"2px solid var(--chakra-colors-neutral-500)"}
                borderRadius={"xl"}
                name="state"
                type="text"
                size="lg"
                focusBorderColor="secondary.500"
                value={
                  underWriting?.propertyDetails?.newPurchaseAddr?.state || ""
                }
                onChange={(e) =>
                  setUnderWriting({
                    ...underWriting,
                    propertyDetails: {
                      ...underWriting?.propertyDetails,
                      newPurchaseAddr: {
                        ...underWriting?.propertyDetails?.newPurchaseAddr,
                        state: e.target.value,
                      },
                    },
                  })
                }
              >
                <option value=""></option>
                {states.map((state) => (
                  <option key={state?.name} value={state?.abbr}>
                    {state?.name} ({state?.abbr})
                  </option>
                ))}
              </Select>
              {validationError?.underWritingPage &&
                underWriting?.propertyDetails?.isThisNewPurchase &&
                !underWriting?.propertyDetails?.newPurchaseAddr?.state && (
                  <Text pr={1} color={"red"}>
                    {t("State is required")}.
                  </Text>
                )}
            </FormControl>
          </GridItem>
          <GridItem w="100%">
            <FormControl isRequired>
              <FormLabel pl={1} fontWeight={500}>
                {t("Zip Code")}:{" "}
                {isLoading && (
                  <Spinner size={"sm"} color="primary.500" ml={2} />
                )}
              </FormLabel>
              <Input
                className="inputBorder"
                border={"2px solid var(--chakra-colors-neutral-500)"}
                borderRadius={"xl"}
                name="zipcode"
                type="number"
                size="lg"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                onWheel={(e) => {
                  e.target.blur();
                }}
                focusBorderColor="secondary.500"
                value={
                  underWriting?.propertyDetails?.newPurchaseAddr?.zipcode || ""
                }
                onChange={(e) => {
                  if (e.target.value.length <= 5) {
                    setUnderWriting({
                      ...underWriting,
                      propertyDetails: {
                        ...underWriting?.propertyDetails,
                        newPurchaseAddressSameAsMailing: false,
                        newPurchaseAddr: {
                          ...underWriting?.propertyDetails?.newPurchaseAddr,
                          zipcode: e.target.value,
                        },
                      },
                    });
                  }
                }}
              />
              {validationError?.underWritingPage &&
                underWriting?.propertyDetails?.isThisNewPurchase &&
                !underWriting?.propertyDetails?.newPurchaseAddr?.zipcode && (
                  <Text pr={1} color={"red"}>
                    {t("Zip Code is required")}.
                  </Text>
                )}
            </FormControl>
          </GridItem>
        </>
      </Grid>
    </>
  );
}
