import Http from "../../../../Http";

export async function getAutoZipData(zipCode) {
  const url = `/api/quote/getZipCodeDetails/10/${zipCode}/APPLICANT`;

  try {
    const response = await Http.get(url);

    // Check if the request was successful
    if (!response.data || !response.status === 200) {
      throw new Error(
        `Failed to fetch data for zip code ${zipCode}. Response: ${JSON.stringify(
          response.data
        )}`
      );
    }

    return response.data;
  } catch (error) {
    console.error(
      `Error fetching auto zip code data for zip code ${zipCode}:`,
      error
    );
    throw error; // Rethrow the error after logging it
  }
}
