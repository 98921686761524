import React, { useState } from 'react'
import {Badge, Button, Col, Collapse, Row} from "react-bootstrap";
import PdfIcon from '../../../../../../assets/pdf-icon.png'
import {useTranslation} from "react-i18next";

const NfipProductItem = ({data, updatePlanQuoteData}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const {
      basicPrem, basicRCV, recommendedPrem, recommendCV, customPrem, customRCV, basicBuildingCvg, recommendedBuildingCvg,
      customBldgCvg, basicContentsCvg, recommendedContentsCvg, customContentCvg, basicBuildingDed, recommendedBuildingDed,
      customBldgDed, basicContentsDed, recommendedContentsDed, customContentDed, logo, pdf, isExpanded
  } = data;
  const {t} = useTranslation();
  const [open, setOpen] = useState(typeof isExpanded === 'boolean' ? isExpanded : isExpanded === 'true');
  return (
    <Row className="pt-3">
      <Col md={3}>
        <img src={logo} height="60px"/>
      </Col>
      <Col md={5}>
        <ul>
          {
            data.benefits.map((benefitText) => (<li>{benefitText}</li>))
          }
        </ul>
      </Col>
      <Col md={4}>
        <div className="d-flex justify-content-between">
          <div className="pt-3">
            <Button variant="warning" size="sm">Custom Coverage</Button>
          </div>
          {
            <div className="ps-5 pe-5">
              <p className="mt-3 mb-0 fw-bold">
                {formatter.format(customPrem)}
              </p>
              <p className="fw-bold">
                Premium
              </p>
            </div>
          }
          <div className="pt-3">
            <Button
              variant="outline-none"
              style={{backgroundColor: '#64d6cd'}}
              className="text-white"
              size="sm"
              onClick={() => updatePlanQuoteData('Custom')}
              autoFocus={true}
              id="button-select"
            >
              Select
            </Button>
          </div>
          <div className="pt-2">
            <Button variant="outline-light" onClick={()=>window.open(pdf, '_blank')}>
              <img src={PdfIcon} width="25px"/>
            </Button>
            <Button onClick={() => setOpen(!open)} size="sm" className={"ms-2"} variant="outline-info"
                    aria-expanded={open}>
              <i className={`fa fas fa-arrow-${open ? 'down' : 'right'}`}/>
            </Button>
          </div>
        </div>
      </Col>
      <Col md={12}>
        <Collapse in={open}>
          <Row>
            <Col md={3}/>
            <Col md={9} className="mt-3 mb-3">
              <Badge bg="warning">
                <h6 className="mb-0 pt-2 pb-2 ps-4 pe-4 text-black">Basic Coverage</h6>
              </Badge>
            </Col>
            <Col md={3}/>
            <Col md={7}>
              <table width="100%" className="coverage-table">
                <tr>
                  <td/>
                  <td>Coverages</td>
                  <td>Deductibles</td>
                </tr>
                <tr>
                  <td>Building Coverage</td>
                  <td>
                    {formatter.format(basicBuildingCvg)}
                  </td>
                  <td>
                    {formatter.format(basicBuildingDed)}
                  </td>
                </tr>
                <tr>
                  <td>Contents Coverage</td>
                  <td>
                    {formatter.format(basicContentsCvg)}
                  </td>
                  <td>
                    {formatter.format(basicContentsDed)}
                  </td>
                </tr>
              </table>
            </Col>
            <Col md={2}>
              <div className="d-flex justify-content-between">
                {
                  <div className="ps-5 pe-5">
                    <p className="mt-3 mb-0 fw-bold">
                      {formatter.format(basicPrem)}
                    </p>
                    <p className="fw-bold">
                      Premium
                    </p>
                  </div>
                }
                <div className="pt-3">
                  <Button
                    variant="outline-none"
                    style={{backgroundColor: '#64d6cd'}}
                    className="text-white"
                    size="sm"
                    onClick={() => updatePlanQuoteData('Basic')}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={3}/>
            <Col md={9} className="mt-3 mb-3">
              <Badge bg="success">
                <h6 className="mb-0 pt-2 pb-2 ps-4 pe-4">Premium Coverage</h6>
              </Badge>
            </Col>
            <Col md={3} className="mb-3"/>
            <Col md={7} className="mb-3">
              <table width="100%" className="coverage-table">
                <tr>
                  <td/>
                  <td>Coverages</td>
                  <td>Deductibles</td>
                </tr>
                <tr>
                  <td>Building Coverage</td>
                  <td>
                    {formatter.format(recommendedBuildingCvg)}
                  </td>
                  <td>
                    {formatter.format(recommendedBuildingDed)}
                  </td>
                </tr>
                <tr>
                  <td>Contents Coverage</td>
                  <td>
                    {formatter.format(recommendedContentsCvg)}
                  </td>
                  <td>
                    {formatter.format(recommendedContentsDed)}
                  </td>
                </tr>
              </table>
            </Col>
            <Col md={2} className="mb-3">
              <div className="d-flex justify-content-between">
                {
                  <div className="ps-5 pe-5">
                    <p className="mt-3 mb-0 fw-bold">
                      {formatter.format(recommendedPrem)}
                    </p>
                    <p className="fw-bold">
                      Premium
                    </p>
                  </div>
                }
                <div className="pt-3">
                  <Button
                    variant="outline-none"
                    style={{backgroundColor: '#64d6cd'}}
                    className="text-white"
                    size="sm"
                    onClick={() => updatePlanQuoteData('Recommended')}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Col>
    </Row>
  )
}
export default NfipProductItem
