export const coveragesSchema = {
  basicCoverages: {
    buildingRCV: 0,
    buildingCVPercent: 80,
    buildingPersonalPropertyCVG: 0,
    buildingExtraExpenses: 0,
    buissnessWaitingPeriod: "48 Hours",
    restorationFactor: "12 Months",
    coveredPerils: "",
    theftExclusions: "NO",
    windExclusions: "NO",
  },
  additionalCoverages: {
    waterSublimit: "",
    allPerilsDed: "",
    excludSinkhole: "NO",
    cosmeticRoofDamage: "NO",
    acvRoofSurface: "NO",
    canopy: 0,
    pumps: 0,
    sign: 0,
  },
  deductibles: {
    allPerilsDed: "",
    hurricaneDed: "",
    theftDed: "",
    windHailDed: "",
  },
};
