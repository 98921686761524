import React, { useContext, useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Button, Row, Col, Table } from "react-bootstrap";
import { Box, Flex } from "@chakra-ui/react";
import userFill from "../../../assets/user-fill.png";
import phoneFill from "../../../assets/Phone_fill.png";
import { GrNotification } from "react-icons/gr";
import profileImg from "../../../assets/profile.png";
import usFlag from "../../../assets/usa_flag.png";
import { LangContext } from "../../../contexts/LangContext";
import { useTranslation } from "react-i18next";
import { logout } from "../../../services/authService";
import { useDispatch } from 'react-redux';
import "./header.scss";
import { toast } from 'react-toastify';
import { BsChat} from "react-icons/bs";

import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { withRouter } from "react-router";
import { EncryptOrDecrypt, getCompanyDetails } from "../../../services/commonService";
import { getAgentTasks } from "../../../services/dashboardService";
import AWS from 'aws-sdk';
import {
  FaComments,
  FaTimes
} from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
//import logo from "../../../assets/unifimga_logo.png";
import logoicon from "../../../assets/logoicon.png";
import RefreshToken from "../../../pages/login/RefreshToken";
import {
  verifyPolicy, setPolicyPropsData, getBaseData, resetCoreStore
} from "../../../services/coreSystemService";
import "./header.scss";
import HeaderSearchQuick from "./HeaderSearchQuick";
import AdvanceSearchQuick from "./AdvanceSearchQuick"
import { quickSearch } from "../../../services/dashboardService"
import AdvancedSearchPage from "./AdvancedSearchPage"
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { isEmpty } from 'lodash';
import { resetFloodStore } from "../../../services/floodService";
import NavbarItems from "./NavbarItems";
import { tenant } from "../../../pages/common/Helper";
import LiveChatCustom from "../../../pages/dashboard/help/LiveChatCustom";
import { LiveChatWidget } from "@livechat/widget-react";
import { isTenancyEnabledForAmazonChat } from "../../../pages/common/Helper";
import { FiPhoneCall } from "react-icons/fi";
import SecureImage from "../../../pages/common/SecureImage";
import { starTenancySynonyms } from "../../../pages/quote/common/tenantSynonyms";

const licenseKey = process.env.REACT_APP_LIVECHAT_LICENSE_KEY;

const AdvancedHeader = () => {
  const navigate = useNavigate();
  const { selectedLng, handleDropdownChange } = useContext(LangContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = localStorage.getItem('access_token') || ''
  const enableChatBot = localStorage.getItem('enable_chat_bot');
  const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const companyPhone = getCompanyDetails("phone");

  const [question, setQuestion] = useState("");
  const [sessionAttributes, setSessionAttributes] = useState({});
  const [lexUserId, setlexUserId] = useState('chatbot-demo' + Date.now());
  const [showModel, setShowModel] = useState(false);
  const [logo] = useState(localStorage.getItem('company_logo'));
  const [agentTasks, setAgentTasks] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatButton, setChatButton] = useState(false);

  AWS.config.region = process.env.REACT_APP_REGION; // Region
  AWS.config.credentials = {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };

  AWS.config.CognitoIdentityCredentials = {
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }

  var lexruntime = new AWS.LexRuntime();
  const devKey = process.env.REACT_APP_NODE_ENV;
  const agentSubModulesAccess = localStorage.getItem("agentSubModules") ? JSON.parse(
    localStorage.getItem("agentSubModules")
  ) : [];
  //var lexUserId = 'chatbot-demo' + Date.now();
  //let sessionAttributes = {};

  useEffect(() => {
    if (token.length === 0) {
      navigate('/login');
    }
  }, [])

  useEffect(() => {
    // 60000 = 1 Min
    const interval = setInterval(() => {
      checkTimeDifference();
    }, 60000);
    getAgentAllTasks();
    return () => clearInterval(interval);
  }, []);

  const getAgentAllTasks = async () => {
    await dispatch(getAgentTasks()).then((res) => {
      setAgentTasks(res.data.data);
    })
  }

  const checkTimeDifference = () => {
    var now = new Date();
    var tokenExpiresAt = EncryptOrDecrypt(localStorage.getItem('access_token_expires_at'), 'D');
    tokenExpiresAt = new Date(tokenExpiresAt);
    var milliSec = (tokenExpiresAt - now); // milliseconds between now & tokenExpiresAt
    var minutes = Math.round((milliSec / 1000) / 60);

    // 300000 = 5 Min
    if (minutes <= 5) {
      setShowModel(true);
    }
  }

  const handleSelect = async (eventKey) => {
    if (eventKey === 'logout') {
      await dispatch(logout());
      localStorage.clear();
      navigate('/login');
    }
  }

  const showchatDiv = () => {

    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'visible';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'hidden';
    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = "Hi, it's great to see you!";
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = "What information are you looking for?";
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const hidechatDiv = () => {
    var element1 = document.getElementById('chatbot');
    element1.style.visibility = 'hidden';
    var element2 = document.getElementById('chatbotIcon');
    element2.style.visibility = 'visible';
    var conversationDiv = document.getElementById('conversation');
    conversationDiv.innerHTML = '';
  }


  const showRequest = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var requestPara = document.createElement("P");
    requestPara.className = 'userRequest';
    requestPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(requestPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showError = (daText) => {

    var conversationDiv = document.getElementById('conversation');
    var errorPara = document.createElement("P");
    errorPara.className = 'lexError';
    errorPara.appendChild(document.createTextNode(daText));
    conversationDiv.appendChild(errorPara);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const showResponse = (lexResponse) => {

    var conversationDiv = document.getElementById('conversation');
    var responseDiv = document.createElement("div");
    responseDiv.className = 'lexResponse';
    var responseIcon = document.createElement("img");
    responseIcon.src = logoicon;
    responseIcon.className = 'lexResponseIcon';
    responseIcon.alt = 'chatbot';
    var responsePara = document.createElement("div");
    responsePara.className = 'lexResponseText';
    responsePara.innerHTML = lexResponse.message;
    var responseIconDiv = document.createElement("div");
    responseIconDiv.className = 'lexResponseIconDiv';
    responseIconDiv.appendChild(responseIcon);
    responseDiv.appendChild(responsePara);
    responseDiv.appendChild(responseIconDiv);
    conversationDiv.appendChild(responseDiv);
    conversationDiv.scrollTop = conversationDiv.scrollHeight;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // if there is text to be sent...
    var wisdomText = document.getElementById('wisdom');
    if (wisdomText && wisdomText.value && wisdomText.value.trim().length > 0) {

      // disable input to show we're sending it
      var wisdom = wisdomText.value.trim();
      wisdomText.value = '...';
      wisdomText.locked = true;
      // send it to the Lex runtime
      var params = {
        botAlias: '$LATEST',
        botName: 'AgentPortal',
        inputText: question,
        userId: lexUserId,
        sessionAttributes: sessionAttributes
      };
      showRequest(question);
      lexruntime.postText(params, function (err, data) {
        if (err) {
          console.log(err, err.stack);
          showError('Error:  ' + err.message + ' (see console for details)')
        }
        if (data) {
          // capture the sessionAttributes for the next cycle
          setSessionAttributes(data.sessionAttributes);
          //sessionAttributes = data.sessionAttributes;
          // show response and/or error/dialog status
          showResponse(data);
        }
        // re-enable input
        wisdomText.value = '';
        wisdomText.locked = false;
      });
    }
    // we always cancel form submission
    return false;
  }

  const floodpage = async () => {
    // reset store
    await dispatch(resetFloodStore());
    await dispatch(resetCoreStore());
    if(starTenancySynonyms.includes(tenant)) {
      navigate('/quote?activeTab=createQuote');
      return;
    }
    navigate('/flood/instantquote/step/address');
  }

  const handleRedirectToPolicy = (policyNo, status) => {
    verify(policyNo, status)
  }

  const verify = async (policyNumber, status) => {
    var params = {}, agentPortalTransPk = 0;
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
        agentPortalTransPk = res.agentPortalTransPk
      } else {
        navigate(`/core/policy`);
      }
    });
    await dispatch(setPolicyPropsData(params));
    await dispatch(
      getBaseData(params.n_POTransactionFK)
    );
    // redirect based on status
    if (['APPLICATION', 'QUOTE'].includes(status) && agentPortalTransPk > 0) {
      navigate(`/flood/quote/${agentPortalTransPk}`);
    } else {
      navigate(`/core/policy/${policyNumber}`);
    }
    window.location.reload();
  };
  const notificationOnClick = () => {
    if (agentTasks.length == 0) {
      navigate(`/active-log`);
    }
  }

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      const userInput = e.currentTarget.value;
      setUserInput(userInput);
      setAdvanceSearch(true);
      navigate('/quick-search');
    }
  }

  const inputFieldClear = () => {
    setUserInput("");
  }

  const quickSearchData = (e) => {
    e.preventDefault()
    const userInput = e.currentTarget.value;
    setUserInput(userInput);
    if (userInput.length > 2) {
      getQuickSearchData(userInput)
    }
    setAdvanceSearch(false);
    setUserInput(e.target.value);
  }

  const getQuickSearchData = async (input) => {
    setSearchData([]);
    setLoading(true);
    await quickSearch(input).then((res) => {
      if (res.data.totalCount !== 0) {
        res.data.data.length > 0 && setSearchData(res.data.data);
      } else {
        setSearchData([]);
      }
    }).catch((err) => {
      toast.error(t("Please enter valid data"));
    })
    setLoading(false);
  }

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        {/*<div id="chatbotIcon" onClick={showchatDiv}> 
      <FaComments size={30} style={{color:'white' }}/> 
    </div>
    <div id="chatbot">
      <div id="chatbox">
        <div style={{textAlign : 'right'}}>
            <span style={{color:'#ccc'}} onClick={hidechatDiv}> <FaTimes /></span>
        </div>
        <div style={{textAlign : 'center'}}>
            <span> <img src={logo} style={{ width:"170px"}}  /></span>
        </div>
      </div>
      <div id="conversation"></div> 
      <div style={{marginTop: '-10px'}}>
          <form onSubmit={handleSubmit}>
              <input type="text" id="wisdom" size="80" placeholder="Reply Here..." onChange={(e) => setQuestion(e.target.value)}/>
          </form>
      </div> 
    </div>*/}

        <Navbar expand="lg" className="dashboard-navbar" >

          {/* <div className="navbar-info-main">
          <img src={userFill} />
          <span>{t('Account Executive')}: Patrick Brennan</span>
        </div> */}
          {/* <div className="vertical-line-bar"></div> */}
          {/* {
            (process.env.REACT_APP_NODE_ENV === 'PROD') && (
              <div className="navbar-info-main">
                <img src={phoneFill} />
                <span>{t('Phone No')}: {companyPhone}</span>
              </div>
            )
          } */}
          <Col style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Row >
              <div style={{ textAlign: 'center' }} >
                <SecureImage src={`${logo}`} style={{ maxWidth: "200px", maxHeight: "52px", marginRight: "25px", marginTop: "9px" }} />
                {/* <img src={`https://images.weserv.nl/?url=${logo}`} style={{ maxWidth: "200px", maxHeight: "52px", marginRight: "25px", marginTop: "9px" }} /> */}
              </div>
            </Row>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Row>
                <div className="form-group has-search" >
                  <div className="input-group">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input type="text" className="form-control" placeholder={t("Names, Address, Policy Number")} onChange={(e) => quickSearchData(e)} value={userInput} onKeyDown={onKeyDown} />
                    <button type="button" className="btn bg-transparent" style={{ marginLeft: "-40px", zIndex: "100" }} value={userInput} onClick={inputFieldClear}> <i className="fa fa-times"></i></button>
                  </div>
                  <div>
                    <Row >
                      <Col>
                        {userInput.length > 2 ?
                          <>
                            {advanceSearch ? "" : <HeaderSearchQuick data={searchData} loading={loading} />}
                          </>
                          : ''}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Nav style={{ alignItems: "center" }} onSelect={handleSelect}>
                {EncryptOrDecrypt(localStorage.getItem('is_active'), 'D') == 'Y' &&
                  <Button className="Forth-step align-items-center pl-0 pr-3" id="createQuoteHeaderButton" onClick={floodpage} style={{ display: "flex", fontSize: "13.5px" }}>
                    <AiOutlinePlus size={15} style={{ color: 'white' }} />&nbsp;
                    {t('Create Quote')}
                  </Button>
                }
                &nbsp;
                <Flex>
                    <Box mr={"15px"}>
                      {enableChatBot === "Y" && <Button className="launch-widget-btn Forth-step" id="createQuoteHeaderButton" style={{width: "130px", display: "flex", fontSize: "13.5px"}} onClick={() => setChatButton(!chatButton)}><BsChat size={20} className="sidebar-link-icon" style={{marginRight: "4px"}} />&nbsp;{t('Chat Now')}  </Button>}
                      {chatButton && !isTenancyEnabledForAmazonChat &&
                        <>
                          <LiveChatCustom />
                          <LiveChatWidget
                            license={licenseKey}
                            visibility="maximized"
                          />
                        </>
                      }
                    </Box>
                    {tenant != 'farmers' && <Box
                    display="flex"
                    alignItems="center"
                    >
                    <FiPhoneCall size={20} className="sidebar-link-icon" style={{ marginRight: "4px" }} />{companyPhone}</Box>}
                </Flex>
                &nbsp;&nbsp;
                <NavDropdown
                  className="lng-selector-drop"
                  title={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      {
                        selectedLng == 'English' ?
                          (
                            <img
                              style={{ width: "20px", height: "20px", marginRight: "10px" }}
                              src={usFlag}
                            />
                          ) : null
                      }
                      <span>{selectedLng}</span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => handleDropdownChange("English", "en")}>English</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleDropdownChange("Spanish", "spa")}>Spanish</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  className="noti-selector-drop"
                  title={
                    <div className="noti-div Third-step">
                      <div className={agentTasks.length > 0 ? "notification-alert" : ""} ></div>
                      <GrNotification onClick={() => notificationOnClick()} size={22} color={"pink"} />
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  {
                    agentTasks.map((item, i) => (
                      <NavDropdown.Item>
                        <span className="noti-text" onClick={() => handleRedirectToPolicy(item.Policy_No, item.status)}>{item.task_title}</span>
                      </NavDropdown.Item>
                    ))
                  }
                </NavDropdown>

                <NavDropdown
                  className="avatar-selector-drop first-step"
                  title={
                    <div className="noti-div">
                     {(userDatas.profileImage && ![null, '', undefined].includes(userDatas.profileImage)) ?
                        <img id="img-profile" src={userDatas.profileImage} style={{ borderRadius: '50%', height: '45px' }} alt="profile" />
                        :
                        <img src={profileImg} />
                      }
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item className="avatar-name">
                    {userDatas.s_ScreenName}
                  </NavDropdown.Item>

                  <NavDropdown.Item className="avatar-edit" onClick={() => navigate(`/profile`)} >
                    Edit Profile
                  </NavDropdown.Item>
                  {(!starTenancySynonyms.includes(tenant))? 
                    <>
                      <NavDropdown.Item className="avatar-edit" onClick={() => navigate(`/celicense`)} >
                        CE-License
                      </NavDropdown.Item>
                    </> 
                    : ""
                  }
                  <NavDropdown.Item className="avatar-edit" onClick={() => navigate(`/al3`)} >
                    AL3
                  </NavDropdown.Item>

                  {agentSubModulesAccess.includes('ACH_INFO_AGENT') &&
                    <NavDropdown.Item className="avatar-edit" onClick={() => navigate(`/ACH-Information`)} >
                      ACH Information
                    </NavDropdown.Item>
                  }
                  <NavDropdown.Divider />
                  <NavDropdown.Item className="avatar-logout" eventKey="logout">
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Navbar>
        <Row><NavbarItems /></Row>
        {
          showModel &&
          (
            <RefreshToken
              showModel={showModel}
              setShowModel={setShowModel}
            />
          )
        }
        <div>
          <Row >
            <Col>
              {userInput.length > 2 ?
                <>
                  {advanceSearch && window.location.pathname == "/quick-search" ? <AdvanceSearchQuick data={searchData} text={userInput} /> : ""}
                </>
                : ''}
            </Col>
          </Row>
        </div>

      </LoadingOverlay>
    </>
  );
};

export default AdvancedHeader;
