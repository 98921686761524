import React, { useCallback, useMemo, useRef, useState } from "react";
// import { Col, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { searchAgency } from "../../../services/producerService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { LoadingOverlay } from "react-overlay-loader";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
} from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { BiReset } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import { pickBy } from "lodash";

const AgencySearch = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const navigate = useNavigate();
  // const [searchData, setSearchData] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [clientId, setClientId] = useState("");
  // const [wyoAgencyCode, setwyoAgencyCode] = useState("");
  // const [status, setStatus] = useState("");
  // const [wyoUpn, setWyoUpn] = useState("");
  // const [zip, setZip] = useState("");
  // const [principalName, setPrincipalName] = useState("");
  // const [lastOrg, setLastOrg] = useState("");
  // const [county, setCounty] = useState("");
  // const [managerName, setManagerName] = useState("");
  // const [feinNumber, setFeinNumber] = useState("");
  // const [state, setState] = useState("");
  // const [dbaName, setDbaName] = useState("");
  // const [city, setCity] = useState("");
  // const [license, setLicense] = useState("");
  // const [pageTemp, setPageTemp] = useState(1);
  // const [sizePerPageTemp, setSizePerPageTemp] = useState(10);
  // const [totalSize, setTotalSize] = useState(0);

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 25, 50];
  const filterOptions = {
    clientId: "",
    wyoAgencyCode: "",
    status: "",
    wyoUpn: "",
    zip: "",
    // principalName: "",//previously sent as payload but not used
    lastOrg: "",
    // county: "",//previously sent as payload but not used
    // managerName: "",//previously sent as payload but not used
    feinNumber: "",
    state: "",
    // dbaName: dbaName, //was already commented
    city: "",
    // license: "",//previously sent as payload but not used
  };
  const [filterParams, setFilterParams] = useState(filterOptions);
  const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);

  // const handlePageChange = (page, sizePerPage) => {
  //   setPageTemp(page);
  //   setSizePerPageTemp(sizePerPage);
  // };

  // const sizePerPageListChange = (page, sizePerPage) => {
  //   setPageTemp(page);
  //   setSizePerPageTemp(sizePerPage);
  // };

  // const search = (e) => {
  //   e.preventDefault();
  //   handleSearch(e);
  // }

  // const renderShowsTotal = (from, to, size) => (
  //   <span style={{ paddingLeft: 10 }}>
  //     Showing {from} to {to} of {totalSize} Results
  //   </span>
  // );

  // const onTableChange = (type, { page, sizePerPage }) => {
  //   setPageTemp(page);
  //   setSizePerPageTemp(sizePerPage);
  // };
  // useEffect(() => {
  //   if (pageTemp !== 1 || sizePerPageTemp !== 10) {
  //     handleSearch();
  //   }
  // }, [pageTemp, sizePerPageTemp]);

  // const handleSearch = (e) => {
  //   if (!validateSearchForm()) {
  //     toast.error("Please Enter Atleast One field for Search");
  //     return false;
  //   }
  //   const params = {
  //     clientId: clientId,
  //     wyoAgencyCode: wyoAgencyCode,
  //     status: status,
  //     wyoUpn: wyoUpn,
  //     zip: zip,
  //     principalName: principalName,
  //     lastOrg: lastOrg,
  //     county: county,
  //     managerName: managerName,
  //     feinNumber: feinNumber,
  //     state: state,
  //     // dbaName: dbaName,
  //     city: city,
  //     license: license,
  //     pageTemp: pageTemp,
  //     sizePerPageTemp: sizePerPageTemp,
  //   };

  //   getSearchReasult({ filterData: params });
  // };
  // const handleClear = (e) => {
  //   setClientId("");
  //   setwyoAgencyCode("");
  //   setStatus("");
  //   setWyoUpn("");
  //   setZip("");
  //   setPrincipalName("");
  //   setLastOrg("");
  //   setCounty("");
  //   setManagerName("");
  //   setFeinNumber("");
  //   setState("");
  //   // setDbaName("");
  //   setCity("");
  //   setLicense("");
  // };
  // const validateSearchForm = () => {
  //   if (
  //     clientId == "" &&
  //     wyoAgencyCode == "" &&
  //     feinNumber == "" &&
  //     // dbaName == "" &&
  //     status == "" &&
  //     wyoUpn == "" &&
  //     lastOrg == "" &&
  //     zip == "" &&
  //     county == "" &&
  //     state == "" &&
  //     city == ""
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  // const getSearchReasult = async (params) => {
  //   setIsLoading(true);
  //   await dispatch(searchAgency(params)).then((res) => {
  //     if (res.status === 200) {
  //       setSearchData(res.data);
  //       setIsLoading(false);
  //       setTotalSize(res.data?.returnCount);
  //     } else {
  //       toast.error("No data Found!");
  //     }
  //   });
  // };

  // const createAgencyLink = (cell, row) => {
  //   return (
  //     <a
  //       href=""
  //       onClick={(e) => navigate(`/producer/${row.n_PersonInfoId_PK}`)}
  //     >
  //       {row.s_PersonUniqueId}
  //     </a>
  //   );
  // };
  // const columns = [
  //   {
  //     dataField: "s_PersonUniqueId",
  //     text: t("AGENCY FLOOD CODE"),
  //     formatter: (value, row) => createAgencyLink(value, row),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_FullLegalName",
  //     text: t("NAME"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "wyoAgencyCode",
  //     text: t("WYO AGENCY CODE"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_CityCode",
  //     text: t("CITY"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_StateCode",
  //     text: t("STATE"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_PersonStatusCode",
  //     text: t("STATUS"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  // ];

  // const options = {
  //   page: pageTemp,
  //   sizePerPage: sizePerPageTemp,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   showTotal: true,
  //   onPageChange: handlePageChange,
  //   totalSize: totalSize,
  //   onSizePerPageChange: sizePerPageListChange,
  //   paginationTotalRenderer: renderShowsTotal,
  // };

  const createAgencyCodeLink = (params) => {
    const rowData = params.data;
    return (
      <a
        style={{
          color: "var(--chakra-colors-linkColor)",
          textDecoration: "underline",
        }}
        href=""
        onClick={(e) => navigate(`/producer/${rowData.n_PersonInfoId_PK}`)}
      >
        {rowData.s_PersonUniqueId}
      </a>
    );
  };

  const WordBreakRenderer = (params) => {
    return <div style={{ wordBreak: "break-word" }}>{params.value}</div>;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    const result = pickBy(filterParams, function (value) {
      return value !== "";
    });
    if (Object.keys(result).length === 0) {
      toast.error("Please provide at least one parameter for search.", {
        toastId: "AgencySearch",
      });
      return;
    }
    const dataSource = createServerSideDataSource();
    gridRef.current.api.updateGridOptions({ serverSideDatasource: dataSource });
  };

  const handleReset = () => {
    setFilterParams(filterOptions);
    setGridKey(`grid-key-${Math.random()}`);
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const loadData = async (params) => {
    try {
      const res = await dispatch(searchAgency(params)).then((res) => {
        return res;
      });
      return res.data;
    } catch (e) {
      const errorMessages = Object.values(e?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
      toast.warning("No data found");
      return {
        rows: 0,
        total: 0,
      };
    }
  };

  const createServerSideDataSource = () => {
    return {
      getRows: async (params) => {
        const pageLength = params.request.endRow - params.request.startRow;
        const page = params.request.endRow / pageLength;

        let filterData = {
          ...filterParams,
          pageTemp: page,
          sizePerPageTemp: pageLength,
        };

        //because this api takes data in this format
        let apiParams = {
          filterData,
        };

        const res = await loadData(apiParams);

        setTimeout(function () {
          if (res?.returnCount) {
            // supply rows for requested block to grid
            params.success({
              rowData: res.returnObject,
              rowCount: res.returnCount,
            });
            params.api.hideOverlay();
          } else {
            params.api.showNoRowsOverlay();
            params.success({
              rowData: [],
              rowCount: 0,
            });
          }
        }, 500);
      },
    };
  };

  const onGridReady = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      filter: false,
      floatingFilter: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      headerName: t("AGENCY FLOOD CODE"),
      field: "s_UserCode",
      minWidth: 195,
      cellRenderer: (params) => createAgencyCodeLink(params),
    },
    {
      headerName: t("NAME"),
      field: "s_FullLegalName",
      wrapText: true,
      autoHeight: true,
      minWidth: 350,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("WYO AGENCY CODE"),
      field: "wyoAgencyCode",
      minWidth: 190,
    },
    {
      headerName: t("CITY"),
      field: "s_CityCode",
      wrapText: true,
      autoHeight: true,
      minWidth: 150,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("STATE"),
      field: "s_StateCode",
      minWidth: 85,
    },
    {
      headerName: t("STATUS"),
      field: "s_PersonStatusCode",
      minWidth: 100,
    },
  ]);

  return (
    <>
      <LoadingOverlay>
        <Box p={4}>
          <Grid
            templateColumns="repeat(5, 1fr)"
            gap={6}
            alignItems={"flex-end"}
          >
            <FormControl>
              <FormLabel>{t("Agency Flood Code")}</FormLabel>
              <Input
                name="clientId"
                id="clientId"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.clientId}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Agency Name")}</FormLabel>
              <Input
                name="lastOrg"
                id="lastOrg"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.lastOrg}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("FEIN/SS")}#</FormLabel>
              <Input
                name="feinNumber"
                id="feinNumber"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.feinNumber}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO Agency Code")}</FormLabel>
              <Input
                name="wyoAgencyCode"
                id="wyoAgencyCode"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.wyoAgencyCode}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO Agency UPN")}</FormLabel>
              <Input
                name="wyoUpn"
                id="wyoUpn"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.wyoUpn}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("City")}</FormLabel>
              <Input
                name="city"
                id="city"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.city}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Zip")}</FormLabel>
              <Input
                name="zip"
                id="zip"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.zip}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("State")}</FormLabel>
              <Input
                name="state"
                id="state"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.state}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Status")}</FormLabel>
              <Select
                name="status"
                id="status"
                className="dashboard-group-input"
                placeholder=""
                value={filterParams.status}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              >
                <option value=""></option>
                <option value="Active">{t("Active")}</option>
                <option value="Closed">{t("Closed")}</option>
              </Select>
            </FormControl>
            <Flex>
              <Button leftIcon={<FaSearch />} onClick={handleSearch}>
                {t("Search")}
              </Button>
              <Button ml={2} leftIcon={<BiReset />} onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Flex>
          </Grid>
          <Box mt={6} borderRadius={"2xl"} boxShadow={"lg"}>
            <Box
              className="ag-theme-alpine"
              height={"400px"}
              id="myGrid"
              w={"100%"}
            >
              <AdvancedGridTable
                gridRef={gridRef}
                key={gridKey}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowBuffer={0}
                rowModelType={"serverSide"}
                cacheBlockSize={50}
                maxBlocksInCache={10}
                onGridReady={(params) => onGridReady(params)}
                enableCellTextSelection={true}
              />
            </Box>
          </Box>
        </Box>
        {/* <Row className="align-items-baseline">
          <form onSubmit={(e) => search(e)}>
            <Col xs="12" className="quote-page-tab1">
              <Row className="align-items-center">
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Agency Flood Code")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={clientId}
                      onChange={(e) => setClientId(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Agency Name")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={lastOrg}
                      onChange={(e) => setLastOrg(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("FEIN/SS")}#
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={feinNumber}
                      onChange={(e) => setFeinNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col> */}
        {/* <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("DBA Name")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={dbaName}
                      onChange={(e) => setDbaName(e.target.value)}
                    />
                  </Form.Group> */}
        {/* <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("WYO Agency Code")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={wyoAgencyCode}
                      onChange={(e) => setwyoAgencyCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("WYO Agency UPN")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={wyoUpn}
                      onChange={(e) => setWyoUpn(e.target.value)}
                    />
                  </Form.Group>
                
                </Col>
                <Col>
                  <Form.Group
                      className="dashboard-group-forms"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="dashboard-group-label">
                        {t("City")}
                      </Form.Label>
                      <Form.Control
                        name="quoteNo"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">{t("Zip")}</Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("State")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Status")}
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input"
                      as="select"
                      placeholder=""
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">-Select-</option>
                      <option value="Active">Active</option>
                      <option value="Closed">Closed</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                 
                </Col>
              </Row>
              <Row id="dashboard-table-section">
                <Col
                  xs="12"
                  style={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1.2rem",
                  }}
                > */}
        {/* <Button id="common-btn" type='submit' onClick={(e) => handleSearch(e)}>Search</Button> */}
        {/* <Button id="common-btn" type='submit'>{t("Search")}</Button>
                  &nbsp;&nbsp;
                  <Button
                    id="common-btn"
                    type="button"
                    onClick={(e) => {
                      handleClear(e);
                    }}
                  >
                    {t("Clear")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </form>
          <Col style={{ marginTop: "1.2rem" }}>
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              bootstrap4
              keyField="n_PersonInfoId_PK"
              data={searchData?.returnObject ? searchData?.returnObject : []}
              columns={columns}
              hover
              remote
              bordered={false}
              pagination={paginationFactory(options)}
              onTableChange={onTableChange}
            />
          </Col>
        </Row> */}
      </LoadingOverlay>
    </>
  );
};

export default AgencySearch;
