import React from "react";
import { Box, Flex, Text, Grid, Checkbox, Alert } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LiabilityExposuresComponent = ({
  question,
  liabilityExposures,
  handleLiabilityExposureChange,
  showError,
}) => {
  const { t } = useTranslation("underwriting");

  const exposures = [
    { key: "trampolines", label: t("trampolines") },
    { key: "aboveGroundPools", label: t("aboveGroundPools") },
    { key: "unprotectedSpas", label: t("unprotectedSpas") },
    { key: "divingBoards", label: t("divingBoards") },
    { key: "slides", label: t("slides") },
    { key: "ziplines", label: t("ziplines") },
    { key: "playgrounds", label: t("playgrounds") },
    { key: "treehouses", label: t("treehouses") },
    { key: "ramps", label: t("ramps") },
    { key: "halfPipes", label: t("halfPipes") },
    { key: "unsecuredPools", label: t("unsecuredPools") },
  ];

  return (
    <Box p={5} borderRadius="md">
      <Flex direction="column" gap={4}>
        <Text>{t(question)}</Text>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {exposures.map(({ key, label }) => (
            <Checkbox
              key={key}
              isChecked={liabilityExposures[key]}
              onChange={() => handleLiabilityExposureChange(key)}
              colorScheme="blue"
            >
              {label}
            </Checkbox>
          ))}
        </Grid>
      </Flex>
      {showError && (
        <Box px={0} mt={3} w={"90%"}>
          <Alert
            status="error"
            borderRadius={"xl"}
            color={"red"}
            p={1.5}
            bg={"transparent"}
            fontSize={"md"}
          >
            {t("hasTrampolineAndPoolSecurity")}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default LiabilityExposuresComponent;
