import Http from "../../../../Http";

export async function createStarQuote({ requestBody, quoteId }) {
  const baseUrl = `/api/v2/quote/star`;
  const requestUrl = quoteId
    ? `${baseUrl}/update/${quoteId}`
    : `${baseUrl}/create`;

  try {
    const { data } = await Http.post(requestUrl, requestBody);
    return data;
  } catch (error) {
    console.error("Error fetching base data:", error);
    throw error;
  }
}
