import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { starCommercialContext } from "../../CommercialContext";
import { useContext } from "react";

export const InfoBox = () => {
  const { t } = useTranslation();
  const { isBind } = useContext(starCommercialContext);
  return (
    <>
      {isBind && (
        <Box p={2} mt={4}>
          <Alert status="warning" borderRadius={"2xl"} py={4}>
            <AlertIcon />
            <AlertTitle>{t("Quote is bound")}!</AlertTitle>
            <AlertDescription>
              {"Underwriter will review & contact you shortly"}.
            </AlertDescription>
          </Alert>
        </Box>
      )}
    </>
  );
};
