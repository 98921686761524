import React from "react";
import "./ProfileStyle.css";
import { BiHide ,BiShow } from "react-icons/bi";
import {
    Button,
    InputGroup
  } from "react-bootstrap";
const PasswordEyeIconButton=({handleClick, showPassword})=>{
    return (
      <InputGroup.Text id="inputgroup_text">
        <Button id="toggle_button" autoFocus onClick={handleClick}>
          {showPassword? (
            <BiShow
              style={{
                color: "black",
                fontSize: 22,
              }}
            />
          ) : (
            <BiHide
              style={{
                color: "black",
                fontSize: 22,
              }}
            />
          )}
        </Button>
      </InputGroup.Text>
    );
}
export default PasswordEyeIconButton;