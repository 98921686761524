import React from "react";
// import { Col, Row } from "react-bootstrap";
import { InputAdapter, TextMask } from "react-text-mask-hoc";
import { useTranslation } from "react-i18next";
import "../agentuser.css";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
} from "@chakra-ui/react";
import { tenant } from "../../common/Helper";

const AgencyMain = (props) => {
  const { t } = useTranslation("translations", "producer");
  return (
    <>
      <Box p={6}>
        <Grid alignItems={"flex-end"} templateColumns="repeat(5, 1fr)" gap={4}>
          <FormControl>
            <FormLabel>{t("Agency Flood Code")}</FormLabel>
            <Input
              type="text"
              id="s_PersonUniqueId"
              name="s_PersonUniqueId"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.s_PersonUniqueId}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Agency Name")}</FormLabel>
            <Input
              type="text"
              id="s_LastOrganizationName"
              name="s_LastOrganizationName"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.s_LastOrganizationName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("U/W Assign")}</FormLabel>
            <Select
              id="role_n_UWAssigned"
              name="role_n_UWAssigned"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.role_n_UWAssigned}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              {(props?.AgencyData?.underwriter_arr
                ? props?.AgencyData?.underwriter_arr
                : []
              ).map((underwriter, index) => (
                <option
                  key={index}
                  value={underwriter.Admin_ID}
                  disabled={underwriter.s_isActiveYN == "N"}
                >
                  {underwriter.s_FullLegalName}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("EFT Payee Name")}</FormLabel>
            <Input
              type="text"
              id="s_PayeeName"
              name="s_PayeeName"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.s_PayeeName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Agency Status")}</FormLabel>
            <Select
              id="s_PersonStatusCode"
              name="s_PersonStatusCode"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.s_PersonStatusCode}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              <option value="Active">{t("Active", {ns: "producer"})}</option>
              <option value="Closed">{t("Closed", {ns: "producer"})}</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Corp Status")}</FormLabel>
            <Select
              id="s_CorpStatus"
              name="s_CorpStatus"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.tbPersonAddrInfo?.s_CorpStatus}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              <option value="YES">{t("Yes")}</option>
              <option value="NO">{t("No")}</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("DBA Name")}</FormLabel>
            <Input
              type="text"
              id="s_DBAName"
              name="s_DBAName"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.s_DBAName}
              isDisabled={true}
            />
          </FormControl>
          {props.AgencyData?.main?.s_PersonStatusCode == "Closed" && (
            <FormControl>
              <FormLabel>{t("Closed Reason")}</FormLabel>
              <Input
                type="text"
                id="s_PaActivityLogNotes"
                name="s_PaActivityLogNotes"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main?.s_PaActivityLogNotes}
                isDisabled={true}
              />
            </FormControl>
          )}
          <FormControl>
            <FormLabel>{t("FEIN Number/Soc Sec ID")}</FormLabel>
            <TextMask
              mask={[
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              Component={InputAdapter}
              className="form-control col-form-input text-mask-input"
              type="text"
              id="s_SSNNo"
              name="s_SSNNo"
              value={props.AgencyData?.main?.s_SSNNo}
              placeholder=""
              disabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Service Rep")}</FormLabel>
            <Select
              id="role_n_ServiceRep"
              name="role_n_ServiceRep"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.role_n_ServiceRep}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              {(props?.AgencyData?.servreppol_arr
                ? props?.AgencyData?.servreppol_arr
                : []
              ).map((servicerep, index) => {
                return (
                  <option key={index} value={servicerep.Admin_ID}>
                    {servicerep.s_FullLegalName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("WYO Agency Code")}</FormLabel>
            <Input
              type="text"
              id="s_CompanyCode"
              name="s_CompanyCode"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.tbPersonAddrInfo?.s_CompanyCode}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Tax Type")}</FormLabel>
            <Select
              id="tax_type"
              name="tax_type"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.tbPersonAddrInfo?.tax_type}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              <option value="FEIN">FEIN</option>
              <option value="SS">SS</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Branded Company")}</FormLabel>
            <Select
              id="branded_person_pk"
              name="branded_person_pk"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.main?.branded_person_pk}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              {props.BrandedCompanies &&
                props.BrandedCompanies.map((value, index) => {
                  return (
                    <option key={index} value={value.n_Personinfo_FK}>
                      {value.s_CompanyName}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>{t("Settlement Code")}</FormLabel>
            <Select
              id="settlement_code"
              name="settlement_code"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.tbPersonAddrInfo?.settlement_code}
              isDisabled={true}
              icon={""}
            >
              <option value=""></option>
              {(props?.AgencyData?.main?.SettlementData
                ? props?.AgencyData?.main?.SettlementData
                : []
              ).map((value, index) => (
                <option key={index} value={value.settlement_code}>
                  {value.settlement_code}
                </option>
              ))}
            </Select>
          </FormControl>
          {tenant === "farmers" ? (
            <>
              <FormControl>
                <FormLabel>{t("SIAA")}</FormLabel>
                <Input
                  type="text"
                  id="siaa"
                  name="siaa"
                  className="form-control producer-group-input col-form-input input-producer"
                  value={props.AgencyData?.tbPersonAddrInfo?.siaa}
                  isDisabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t("WYO Agency UPN")}</FormLabel>
                <Input
                  type="text"
                  id="Sub_Agent_ID"
                  name="Sub_Agent_ID"
                  className="form-control producer-group-input col-form-input input-producer"
                  value={props?.AgencyData?.main?.Sub_Agent_ID}
                  isDisabled={true}
                />
              </FormControl>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Box>
      {/* <div className="dashboard-table-div" id="dashboard-table-section">
      <Row>
        <Col xs="12">
          <Row>
            <Col xs={1} sm={2}>
              <b>{t("Agency Flood Code")}:</b>
            </Col>
            <Col xs={3} sm={2}>
              <input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main ? props.AgencyData?.main?.s_PersonUniqueId : '' }
                disabled={true}
              />
            </Col>
            <Col xs={1} sm={2}>
              <b>{t("Agency Name")}:</b>
            </Col>
            <Col xs={3} sm={2}>
              <input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main ? props.AgencyData?.main?.s_LastOrganizationName : '' }
                disabled={true}
              />
            </Col>
            <Col xs={1} sm={2}>
              <b>{t("U/W Assign")}:</b>
            </Col>
            <Col xs={3} sm={2}>
              <select
                type="select"
                name="corp_status"
                id="corp_status"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main?.role_n_UWAssigned}
                disabled={true}
              >
                <option value="">Select U/W</option>
                {(props?.AgencyData?.underwriter_arr ? props?.AgencyData?.underwriter_arr : []) .map((underwriter, index) => (
                    <option key={index} value={underwriter.Admin_ID} disabled={underwriter.s_isActiveYN=='N'}>
                      {underwriter.s_FullLegalName}
                    </option>
                  ))}
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("EFT Payee Name")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main ? props.AgencyData?.main?.s_PayeeName : '' }
                disabled={true}
              />
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Agency Status")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <select
                type="select"
                name="agency_status"
                id="agency_status"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.main ? props.AgencyData?.main?.s_PersonStatusCode : '' }
              >
                <option value="">-Select-</option>
                <option value="Active">Active</option>
                <option value="Closed">Closed</option>
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Corp Status")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <select
                type="select"
                name="corp_status"
                id="corp_status"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.tbPersonAddrInfo ? props.AgencyData?.tbPersonAddrInfo?.s_CorpStatus : '' }
              >
                <option value="">-Select-</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("DBA Name")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.main ? props.AgencyData?.main?.s_DBAName : '' }
              />
            </Col>
            {props.AgencyData?.main?.s_PersonStatusCode == "Closed" &&
            <>
                <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
                <b>{t("Closed Reason")}:</b>
               </Col>
                <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
                    <input
                      type="text"
                      id="s_PaActivityLogNotes"
                      name="s_PaActivityLogNotes"
                      disabled={true}
                      value={props.AgencyData?.main ? props.AgencyData?.main?.s_PaActivityLogNotes : '' }
                      className="form-control producer-group-input col-form-input input-producer"
                    />
              </Col>
            </>
          }
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("FEIN Number/Soc Sec ID")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
            <TextMask
                  mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  Component={InputAdapter}
                  className="form-control producer-group-input col-form-input input-producer"
                  type="text"
                  id="s_SSNNo"
                  name="s_SSNNo"
                  value={props.AgencyData?.main ? props.AgencyData?.main?.s_SSNNo : '' }
                  placeholder=""
                  disabled={true}
                  
                />
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Service Rep")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <select
                type="select"
                name="corp_status"
                id="corp_status"
                className="form-control producer-group-input col-form-input input-producer"
                value={props.AgencyData?.main ? props.AgencyData?.main?.role_n_ServiceRep : ''}
                disabled={true}
              >
               <option value="">Select Service Rep</option>
                  {(props?.AgencyData?.servreppol_arr ? props?.AgencyData?.servreppol_arr : []).map((servicerep, index) => {
                  return ( <option key={index} value={servicerep.Admin_ID}>
                      {servicerep.s_FullLegalName}
                    </option>)
                  })}
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("WYO Agency Code")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <input
                type="text"
                name="version"
                id="version"
                className="form-control producer-group-input col-form-input input-producer"
                disabled
                value={props.AgencyData?.tbPersonAddrInfo ? props.AgencyData?.tbPersonAddrInfo?.s_CompanyCode : ''}
              />
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Tax Type")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
                <select
                type="select"
                name="tax_type"
                id="tax_type"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.tbPersonAddrInfo ? props.AgencyData?.tbPersonAddrInfo?.tax_type : ''}
              >
                <option value="">Select Tax Type</option>
                  <option value="FEIN">FEIN</option> 
                  <option value="SS">SS</option>
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Branded Company")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <select
                type="select"
                name="corp_status"
                id="corp_status"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.main ? props.AgencyData?.main?.branded_person_pk : ''}
              >
                
                <option value="">Select Branded Company</option>
                  {props.BrandedCompanies && props.BrandedCompanies.map((value, index) => {
                    return(<option key={index} value={value.n_Personinfo_FK}>
                      {value.s_CompanyName}
                    </option>)
                })}
              </select>
            </Col>
            <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
              <b>{t("Settlement Code")}:</b>
            </Col>
            <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
              <select
                type="select"
                name="settlement_code"
                id="settlement_code"
                className="form-control producer-group-input col-form-input input-producer"
                disabled={true}
                value={props.AgencyData?.tbPersonAddrInfo ? props.AgencyData?.tbPersonAddrInfo?.settlement_code : ''}
              >
                <option value="">Select Settlement Code </option>
                  {((props?.AgencyData?.main?.SettlementData ? props?.AgencyData?.main?.SettlementData : [])).map((value, index) => (
                    <option key={index} value={value.settlement_code}>
                      {value.settlement_code}
                    </option>
                  ))}
              </select>
            </Col>
            {
              window.location.host.split('.')[0] == 'farmers' ?
              <>
                <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
                  <b>{t("SIAA")}:</b>
                </Col>
                <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={props.AgencyData?.tbPersonAddrInfo ? props.AgencyData?.tbPersonAddrInfo?.siaa : ''}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={2} style={{ marginTop: "7px" }}>
                  <b>{t("WYO Agency UPN")}:</b>
                </Col>
                <Col xs={3} sm={2} style={{ marginTop: "5px" }}>
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={props?.AgencyData?.main?.Sub_Agent_ID ? props?.AgencyData?.main?.Sub_Agent_ID : ''}
                    disabled={true}
                  />
                </Col>
              </>
              : null
            }
          </Row>
        </Col>
      </Row>
    </div> */}
    </>
  );
};
export default AgencyMain;
