import React, { useEffect, useState } from "react";
import { Button, Row, Col, Accordion, Spinner, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Applicant from "./Applicant";
import Coverage from "./Coverage";
import AdditionalInformation from "./AdditionalInformation";
import InsuredInformation from "./InsuredInformation";
import Lender from "./Lender";
// import EndorsmentEffectiveDate from "./EndorsmentEffectiveDate";
import RequestUnderwriterReview from "./RequestUnderwriterReview"
import { getEndorsementData, saveEndorsmentData, rateEndorsement, TransactionCancel } from "../../../services/coreSystemService";
import { getBaseData, updateEndorsementQuotePaymentStatus } from "../../../services/floodService";
import { toast } from 'react-toastify';
import EndorsementDetails from "./EndorsementDetails";
import SummaryofChanges from "./SummaryofChanges";
import { FaExclamationTriangle } from "react-icons/fa";
// import PivotLog from "../../quote/flood/quote/Modal/PivotLog";
import Swal from 'sweetalert2';
import Error from "../../quote/flood/quote/Modal/Error";
import { resetCoreStore } from "../../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayGovCollectionDetails, getPayGovToken } from "../../../services/paymentService";
import { getHostUrl } from "../../common/HostUrl";
import { checkPivotOutage } from "../../../services/commonService";
import { getFemaUpdates} from "../../../services/dashboardService";

const Index = (props) => {
    const { t } = useTranslation();
	const isPivotOutage = checkPivotOutage();
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { showEndorse, policyMasterFK, poTransPK, productPK, policyNumber } = props;
    const { floodSystemDD } = useSelector(state => state.flood);

    const search = useLocation().search;  
    const payGovToken = new URLSearchParams(search).get('token');
    const isCancelled = new URLSearchParams(search).get('cancelled');

    const [index, setIndex] = useState(['0']);
    const [loading, setLoading] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [btLabel, setBtLabel] = useState('Expand All');
    const [occupancyType, setOccupancyType] = useState('');
    const [NOU, setNOU] = useState(0);
    const [replacementCost, setReplacementCost] = useState(0);
    const [isPreFirmSubsidyEligible, setIsPreFirmSubsidyEligible] = useState(false);
    const [buildingUse, setBuildingUse] = useState('');
    const [cvgInfo, setCvgInfo] = useState({});
    const [propertyInfo, setPropertyInfo] = useState({});
    const [applicationData, setApplicationData] = useState({});
    const [additionalInterestInfo, setAdditionalInterestInfo] = useState({});
    const [mortgageeInfo, setMortgageeInfo] = useState([]);
    const [addInsuredInfo, setAddInsuredInfo] = useState([]);
    const [saveStatus, setSaveStatus] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const [btnText, setBtnText] = useState("Review");
    const [currentMailingAddr, setCurrentMailingAddr] = useState({});
    const [isPrimaryRes, setIsPrimaryRes] = useState("NO");
    const [newPremium, setNewPremium] = useState(0);
    const [premiumChange, setPremiumChange] = useState(0);
    const [currentPremium, setCurrentPremium] = useState(0);
    const [isDocRequired, setIsDocRequired] = useState(false);
    const [transType, setTransType] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorArray, setErrorArray] = useState([]);
    // const [pivotLog, setPivotLog] = useState(false);
    const [showPaymentBtn, setShowPaymentBtn] = useState(false);
    const [getDataLoader, setGetDataLoader] = useState(false);
    const [isRateSuccessfully, setIsRateSuccessfully] = useState("");
    const [alertText, setAlertText] = useState("Don't Refresh this page");
	const [femaUpdateMsg, setFemaUpdateMsg] = useState({ __html: "" });
    const [isHiatus, setIsHiatus] = useState(false);
    var isAPICalling = true, preEndorsePK = 0;

    const [endorsementJSON, setendorsementJSON] = useState({
        current: { Coverage: {}, InsuredInformation: {}, Lender: {}, AdditionalInformation: {}, EndorsmentEffectiveDate: {}, RequestUnderwriterReview: {}, Email: {}, Phone: {}, ApplicantName:{} },
        new: { Coverage: {}, InsuredInformation: {}, Lender: {}, AdditionalInformation: {}, EndorsmentEffectiveDate: {}, RequestUnderwriterReview: {}, Email: {}, Phone: {}, ApplicantName:{} },
        preEndorsePK: 0,
        isPaymentDone: false
    });
	
	useEffect(() => {
		checkFemaUpdates();
	  }, [localStorage.getItem('lang') || 'en']);
	  
	  const checkFemaUpdates = async () => {
		await dispatch(getFemaUpdates()).then((res) => {
			setIsHiatus(res.data.data.isHiatus);
			if(res.data.data.isHiatus == true){
				setFemaUpdateMsg({ __html: res.data.data.hiatusSignAndPayMsg });
			}
		})
	  }
    
    //Get Base Data
    useEffect(() => {
        if (showEndorse === true) {
            // get dropdown
            async function getDropDown() {
                setLoading(true);
                await dispatch(getBaseData(productPK || 10)).then((res) => {
                    if (res) {
                        getEndorsement();
                    } else {
                        toast.error(t('Something Went Wrong!'));
                    }
                });
                setLoading(false);
            }

            getDropDown();
        }
    }, [showEndorse]);

    // set required state
    useEffect(() => {
        if (propertyInfo && Object.keys(propertyInfo).length > 0) {
            if (propertyInfo.RA) {
                setOccupancyType(propertyInfo.RA.s_Occupancy || '');
                setNOU(propertyInfo.RA.s_NoOfUnits || 0);
                setReplacementCost(propertyInfo.RA.n_ReplacementCost || 0);
                var isPrefirmEligible = propertyInfo.RA.s_IsPreFirmSubsidyEligible === "YES" ? true : false;
                setIsPreFirmSubsidyEligible(isPrefirmEligible);
                setBuildingUse(propertyInfo.RA.s_BuildingUse);
            }
        }
    }, [propertyInfo]);

    // Expand
    useEffect(() => {
        var label = "Expand All";
        var index = ['0'];
        if (isExpand) {
            label = 'Close All';
            index = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];
        }
        setIndex(index);
        setBtLabel(label);
    }, [isExpand]);

    // after payment
    useEffect(() => {
        if (isAPICalling === false && ![null, "", undefined].includes(payGovToken) && isCancelled === 'false') {
            isAPICalling = true;
            getEndorsement();
        }
        // remove token from url 
        if (isCancelled === 'true') {
            navigation(window.location.pathname);
        }
    }, [payGovToken]);

    // get Endorsement data
    const getEndorsement = async () => {
        setLoading(true);
        const params = {
            "poTransPK": poTransPK,
            "policyIdPK": policyMasterFK
        }
        setGetDataLoader(true);
        await dispatch(getEndorsementData(params)).then(async (res) => {
            if (res.success === true) {
                const { cvgInfo } = res.data;
                const { propertyInfo } = res.data;
                const { policyInfo } = res.data;
                const { additionalInterestInfo } = res.data;
                const { mortgageeInfo } = res.data;

                setCvgInfo(cvgInfo);
                setPropertyInfo(propertyInfo);
                setApplicationData(policyInfo);
                setMortgageeInfo(mortgageeInfo);

                var addIntInfo = additionalInterestInfo;
                /**
                 * convert object to array if resp is object
                 * @link https://implevision.atlassian.net/browse/ODS-1920
                 */
                if (addIntInfo && Object.keys(addIntInfo).length > 0) {
                    if (addIntInfo.additionalInsured && typeof addIntInfo.additionalInsured === 'object') {
                        addIntInfo['additionalInsured'] = Object.values(additionalInterestInfo.additionalInsured);
                    }
                }
                setAdditionalInterestInfo(addIntInfo);
                setAddInsuredInfo(addIntInfo['additionalInsured']);
                setCurrentMailingAddr(policyInfo.mailingAddress);
                setIsPrimaryRes(propertyInfo.RA.s_InsuredResides);

                if (policyInfo.transInfo && policyInfo.transInfo[0]) {
                    setTransType(policyInfo.transInfo[0].s_PRTranTypeCode);
                }
                setCurrentPremium(res.data.finalPremium.n_FinalPremium);
                setLoading(false);

                // if exist on agent-portal
                if (res.data.hasOwnProperty("agentPreEndorseData")) {
                    var agentPreEndorseData = res.data.agentPreEndorseData;
                    if (agentPreEndorseData.hasOwnProperty("metadata")) {
                        var metadata = JSON.parse(agentPreEndorseData.metadata);
                        setIsRateSuccessfully(metadata['isRated'] || "");
                        delete metadata['Action'];
                        delete metadata['isRated'];
                        setendorsementJSON(metadata);
                        preEndorsePK = metadata['preEndorsePK'];

                        if (metadata['preEndorsePK'] > 0) {
                            setIsExpand(true);
                            setShowReview(true);
                            setIsDocRequired(metadata['isDocRequired']);
                            setNewPremium(metadata['newPremium'] || 0);
                            setPremiumChange(metadata['premiumChange'] || 0);
                            setCurrentPremium(metadata['currentPremium'] || 0);
                            var tempText = "Submit";
                            if (metadata['premiumChange'] > 0) {
                                if (metadata['isPaymentDone'] === false) {
                                    tempText = "Pay & Submit";
                                }
                                setShowPaymentBtn(!metadata['isPaymentDone']);
                            }
                            setTextForAlert(metadata);
                            setBtnText(tempText);
                        }
                    }
                }

                if (![null, "", undefined].includes(payGovToken) && isCancelled === 'false') {
                    await payGovCollectionDetails();
                }
            } else {
                toast.error(t('Something Went Wrong!'));
            }
        }).catch(() => {
            setLoading(false);
            toast.error(t('Something Went Wrong!'));
        });
        setGetDataLoader(false);
    }

    // open accordion
    const toggleAccordion = (index) => {
        if (!showReview) {
            setIndex(index);
        }
    }

    // handle show pivot api log
    // const handleShowPivotLog = () => {
    //     setPivotLog(<PivotLog showPivotLog={setPivotLog} poTransPK={poTransPK} />)
    // }

    /**
    * To update replacement cost ratio
    */
     const updateReplacementCostRatio = (buildingCoverage) => {
        var buildingCvg = buildingCoverage.value || 0, replacementCost = endorsementJSON.new.AdditionalInformation.replacementCost.value || 0;
        
        var ratio = 0;
        if (replacementCost) {
            ratio = ((buildingCvg * 100) / replacementCost); // to get ratio
        }
        const tempArray = {...endorsementJSON};
        tempArray["new"]['AdditionalInformation']['replacementCostRatio'] = Math.round(ratio);
        setendorsementJSON(tempArray);
    }


    // handleChange for all input
    const handleChange = (field, currentValue, newValue, tab) => {
        const tempArray = {...endorsementJSON};
        tempArray["current"][tab] ??= []; // Initialize if it's undefined
        tempArray["new"][tab] ??= [];
        if (Object.keys(tempArray["current"][tab]).length === 0) {
            tempArray["current"][tab] = {};
        }
        if (Object.keys(tempArray["new"][tab]).length === 0) {
            tempArray["new"][tab] = {};
        }
        tempArray["current"][tab][field] = currentValue;
        tempArray["new"][tab][field] = newValue;

        if(field == 'email'){
            tempArray["new"][tab][field] = {"policyPK": policyMasterFK,
            "type": "EMAIL", //'EMAIL'
            "oldEmail": currentValue,
            "s_EmailAddress": newValue,
            "n_EmailInfoId_PK" : applicationData.personContact.email.n_EmailInfoId_PK};
        }

        if(field == 'phone'){
            tempArray["new"][tab][field] = {"policyPK": policyMasterFK,
            "type": "PHONE", 
            "oldPhoneNumber": currentValue,
            "s_PhoneNumber": newValue,
            "n_PhoneInfoId_PK": applicationData.personContact.phone.n_PhoneInfoId_PK};
        }

        if(field == 'buildingCoverage' || field == 'replacementCost'){
            const buildingCvg = getSelectedCoverageData();

            tempArray["new"][tab][field] = {"poTransPK": poTransPK,"n_POCoverageTran_PK": buildingCvg.n_POCoverageTran_PK ,"value" : newValue};
            

            if(tempArray.new.Coverage.buildingCoverage ){
                if(tempArray.new.AdditionalInformation.replacementCost){
                    updateReplacementCostRatio(tempArray.new.Coverage.buildingCoverage);
                }
            } else {
                const buildingCvgValue = {"poTransPK": poTransPK,"n_POCoverageTran_PK": buildingCvg.n_POCoverageTran_PK,"value" : buildingCvg.n_InsuredValue};
                updateReplacementCostRatio(buildingCvgValue);
            }
        }
        if(field == 'contentCoverage'){
            const buildingCvg = getSelectedCoverageData('FLDCONTCVGAMT');
            tempArray["new"][tab][field] = {"poTransPK": poTransPK,"n_POCoverageTran_PK": buildingCvg.n_POCoverageTran_PK,"value" : newValue};
        }
        if(field == 'contentDeductible' || field == 'buildingDeductible'){
            var code = 'FLDCONTCVGAMT';
            if(field == 'buildingDeductible'){
                code = 'FLDBLDCVGAMT'
            }
            tempArray["new"][tab][field] = {"poTransPK":poTransPK ,"policyNoPK": policyMasterFK,"product":'10' ,"whichDeductible":code,"value":newValue};
        }
        if(field == 'Lender'){
            tempArray["current"][tab][field] = currentValue;
            tempArray["new"][tab]['riskMasterPK'] = applicationData.poriskmaster.n_PORiskMaster_PK;
            tempArray["new"][tab]['poTransPK'] = poTransPK;
        }
        if(field == 'billTo'){
            tempArray["new"][tab][field] = {n_OwnerId_FK: applicationData.n_OwnerId_FK, billTo : newValue} 
        }
        if(field == 'review'){
            tempArray["new"][tab][field] = {"policyNoPK": policyMasterFK,"policyNo": applicationData.Policy_No, review : newValue} 
        }
        if(tempArray.new.InsuredInformation.AdditionalInsured){
            tempArray["current"][tab][field] = addInsuredInfo;            
            tempArray["new"][tab]['additionalInfoData'] = {
                poAppMasterPK : applicationData.transInfo[0].n_POAppsMaster_PK,
                policyNo : applicationData.Policy_No,
                poRiskMasterPK: applicationData.poriskmaster.n_PORiskMaster_PK,
                potransactionPK: poTransPK,
                policyID: policyMasterFK
            }
        }

        // IF Edits to mailing address= True AND Primary residence = True Then Auto-fill “underwriter box” with “Policy listed as primary residence, please upload proof of primary residence to avoid potential premium increase.”
        if (field === "MailingAddress") {
            if (
                (currentMailingAddr.s_AddressLine1.trim() !== newValue.s_AddressLine1.trim()) &&
                (isPrimaryRes === "YES")) {
                var temp = "", current = "";

                if (Object.keys(endorsementJSON.new.RequestUnderwriterReview).length > 0) {
                    current = endorsementJSON.new.RequestUnderwriterReview.review.review
                }
                // check if already exist then no need to add again
                if (!current.includes("Policy listed as primary residence")) {
                    temp = current + " Policy listed as primary residence, please upload proof of primary residence to avoid potential premium increase."
                } else {
                    temp = current;
                }
                handleChange('review', current, temp, "RequestUnderwriterReview");
                setIsDocRequired(true);
            } else {
                setIsDocRequired(false);
            }         
        }
        if(field == 'firstName'){
            tempArray["new"][tab][field] = {
            "oldFirstNAme": currentValue,
            "firstName": newValue,
            // "personInfoPK" : applicationData.insuredData.personInfoPK
            };
        }
        if(field == 'middleName'){
            tempArray["new"][tab][field] = {
            "oldMiddleName": currentValue,
            "middleName": newValue,
            // "personInfoPK" : applicationData.insuredData.personInfoPK
            };
        }
        if(field == 'lastName'){
            tempArray["new"][tab][field] = {
            "oldLastName": currentValue,
            "lastName": newValue,
            // "personInfoPK" : applicationData.insuredData.personInfoPK
            };
        }
        if(field == 'fullLegalName'){
            tempArray["new"][tab][field] = {
            "oldFullLegalName": currentValue,
            "fullLegalName": newValue,
            // "personInfoPK" : applicationData.insuredData.personInfoPK
            };
        }
        if (['firstName', 'middleName', 'lastName', 'fullLegalName'].includes(field)) {
            tempArray["new"][tab]['personInfoPK'] = applicationData.insuredData.personInfoPK;
        }

        setendorsementJSON(tempArray);


    }

    const updateParentState = async (name, value) => {
        if(name == 'fullLegalName'){
            const tempApplicantArray = {...applicationData};
            tempApplicantArray['insuredData']['s_FullLegalName'] = value;
            setApplicationData(tempApplicantArray);
        }
    }
    const handleSubmit = async (afterPayment = false) => {
        setLoading(true);
        setSaveStatus(true);
        // Rate befor Issue
        if (isRateSuccessfully == "N" && endorsementJSON.preEndorsePK > 0) {
            setLoading(false);
            setSaveStatus(false);
            // show modal
            Swal.fire({
                title: 'Error',
                html: "Please take a moment to rate your application by clicking on the <b>Rate</b> button.",
                icon: 'warning',
                confirmButtonColor: '#64d6cd',
                confirmButtonText: 'Rate',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let params = {
                        "transType": transType,
                        "poTransPK": poTransPK,
                        "preEndorsePK": endorsementJSON.preEndorsePK,
                    }

                    setLoading(true);
                    setSaveStatus(true);
                    await dispatch(rateEndorsement(params)).then((res) => {
                        if (res.success) {
                            if (res.data && res.data.status == 'Y') {
                                toast.success(res.data.msg);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 2000);
                            } else {
                                toast.error(res.data.msg);
                            }
                        }
                    });
                    setLoading(false);
                    setSaveStatus(false);
                }
            })
            return false;
        }

        if (showPaymentBtn === true && afterPayment === false) {
            // pay.gov 
            var hostUrl = getHostUrl();

            var url = hostUrl + `/core/policy/` + policyNumber;
            const paymentSuccessUrl = url + "?cancelled=false";
            const paymentCancelUrl = url + "?cancelled=true";

            const params = {
                'transaction_amount': premiumChange,
                'url_success': paymentSuccessUrl,
                'url_cancel': paymentCancelUrl,
                'payment_type': 'PLASTIC_CARD',
                'policyNo': policyNumber,
                'source': "AgentSide_UW_Endorsequote"
            };

            await dispatch(getPayGovToken(params)).then((res) => {
                if (res.data.token != '') {
                    window.location = res.data.payment_page_url;
                }
            }).catch((error) => {
                // show error
                const { data } = error || {};

                if (data.errors && data.errors.hasOwnProperty('text')) {
                    toast.error(data.errors.text);
                } else {
                    toast.error(t('Something Went Wrong!'));
                }
            });
        } else {
            // save/issue endorsement 
            var param = {};
            param['poTransPK'] = poTransPK;
            param['transType'] = transType;
            param['isPrimaryRes'] = isPrimaryRes;
            param['nPolicyPK'] = policyMasterFK;
            param['metadata'] = endorsementJSON;
            param['metadata']['preEndorsePK'] = preEndorsePK;
            param['metadata']['isDocRequired'] = isDocRequired;
            param['metadata']['newPremium'] = newPremium;
            param['metadata']['premiumChange'] = premiumChange;
            param['metadata']['currentPremium'] = currentPremium;
            param['policyNumber'] = policyNumber;

            var temp = 'Update';
            if (showReview === true || afterPayment === true) {
                temp = 'ISSUE';
            }
            param['Action'] = temp;

            // ODS-696 if there RCV change do not issue
            if (param['Action'] === "ISSUE") {
                let isRCVChange = checkChange(param, "RCV");
                // ODS- 3382 check insured name is changed - donot issue 
                let isInsuredNameChanged = checkChange(param, "INSUREDNAME");
                let isAdditionalInsuredDeleted = checkChange(param, "ADDITIONALINSUREDDELETED");

                if (isRCVChange == 'Y' || isInsuredNameChanged == 'Y' || isAdditionalInsuredDeleted == 'Y') {
                    setLoading(false);
                    setSaveStatus(false);
                    // show modal
                    Swal.fire({
                        title: 'Info',
                        html: "The endorsement is currently under review.",
                        icon: 'info',
                        confirmButtonColor: '#64d6cd',
                        confirmButtonText: 'OK',
                        allowOutsideClick: false
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            return false;
                        }
                    });
                    return false;
                }
            }

            await dispatch(saveEndorsmentData(param)).then((res) => {
                if (!showReview && afterPayment === false) {
                    if (res.success === false) {
                        toast.error(res.data);
                    } else {
                        if (res.data && res.data.status === 'Y') {
                            setIsExpand(true);
                            toast.success(res.data.msg);
                            setNewPremium(res.data.premium);
                            setPremiumChange(res.data.premiumChange);
                            setShowReview(true);
                            var tempText = "Submit";
                            if (res.data.premiumChange > 0) {
                                tempText = "Pay & Submit";
                            }
                            setBtnText(tempText);
                            setTextForAlert(endorsementJSON);

                            var temp = false;
                            if (res.data.premiumChange > 0) {
                                temp = true;
                            }
                            setShowPaymentBtn(temp);
                        } else {
                            toast.error(res.data.msg);
                        }
                    }
                } else {
                    if (res.success === false) {
                        if (res.hasOwnProperty("error")) {
                            // show error modal
                            setShowErrorModal(true);
                            setErrorArray(res.error);
                        } else {
                            toast.error(res.data);
                        }
                    } else {
                        if (res.data && res.data.Status === 'Y') {
                            Swal.fire({
                                title: 'Success',
                                text: res.data.pivotResposneMsg,
                                icon: 'success',
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'OK',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch(resetCoreStore());
                                    window.location.reload();
                                }
                            })
                        } else {
                            toast.error(res.data.pivotResposneMsg);
                            if (res.data.ErrorArray && res.data.ErrorArray.length > 0) {
                                // show error modal
                                setShowErrorModal(true);
                                setErrorArray(res.data.ErrorArray);
                            }
                        }
                    }
                    if (afterPayment === true) {
                        navigation("/core/policy/" + policyNumber);
                    }
                }
            }).catch(() => {
                toast.error(t('Something Went Wrong!'));
            });
        }

        setSaveStatus(false);
        setLoading(false);
    }

    const handleTransactionCancel = async () => {
        setLoading(true);
        const params = {
            "poTransPK": poTransPK,
            "policyMasterFK": policyMasterFK
        }
        await dispatch(TransactionCancel(params)).then((res) => {
            if (res.success === true) {
                toast.success('Transaction Cancelled.');
                dispatch(resetCoreStore());
                window.location.reload();
            } else {
                toast.error(res.data);
            }
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        });
        setLoading(false);
    }

    

    const getSelectedCoverageData = (code = 'FLDBLDCVGAMT')=> {
        var basicCvgData = cvgInfo;
            var buildingCvg = basicCvgData.filter((x) => x.s_CoverageCode === code);
            if (buildingCvg.length > 0) {
                return buildingCvg[0];
            }
            return [];
    }

    // after payment
    const payGovCollectionDetails = async () => {
        const params = {
            'token': payGovToken,
            'poTransPK': poTransPK,
        };
        setLoading(true);
        dispatch(getPayGovCollectionDetails(params)).then((res) => {
            var temp = false;
            if (res.success === true) {
                if (res.data.status === "Y") {
                    if (res.data.transaction_status === "Success") {
                        temp = false;
                        setBtnText("Submit");
                    } else {
                        toast.error(t('Something Went Wrong!'));
                        temp = true;
                    }
                    updatePaymentStatus(!temp);
                }
            }

            setShowPaymentBtn(temp);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t('Something Went Wrong!'));
        });
    }

    // after payment - update payment status
    const updatePaymentStatus = async (paymentStatus) => {
        const params = {
            'preEndorsePK': preEndorsePK,
            'paymentStatus': paymentStatus,
        };
        setLoading(true);
        dispatch(updateEndorsementQuotePaymentStatus(params)).then((res) => {
            setLoading(false);
            if (res.status === 'Y') {
                handleSubmit(true);
            }
        }).catch(() => {
            setLoading(false);
            toast.error(t('Something Went Wrong!'));
        });
    }

    // check if there is change
    const checkChange = (param, field) => {
        let result = 'N';
        if (param['metadata']) {
            let metadata = param['metadata'];
            if (field == 'RCV') {
                if (metadata['new']['AdditionalInformation']) {
                    let additionalInformation = metadata['new']['AdditionalInformation'];
                    if (additionalInformation['replacementCost']) {
                        result = 'Y';
                    }
                }
            }
            if (field == 'INSUREDNAME') {
                if (metadata['new']['ApplicantName'] && metadata['new']['ApplicantName']['insuredNameChangeType']) {
                    let insuredNameChangeType = metadata['new']['ApplicantName']['insuredNameChangeType'];

                    if (insuredNameChangeType == 'FULLNAMECHANGE') {
                        result = 'Y';
                    }
                }
            }
            if (field == 'ADDITIONALINSUREDDELETED') {
                if (metadata['new']['InsuredInformation'] && metadata['new']['InsuredInformation']['isAdditionalInsuredDeleted']) {
                    let isAdditionalInsuredDeleted = metadata['new']['InsuredInformation']['isAdditionalInsuredDeleted'];

                    if (isAdditionalInsuredDeleted == 'YES') {
                        result = 'Y';
                    }
                }
            }
        }

        return result;
    }

    /**
     * Set alert text
     */
    const setTextForAlert = (param) => {
        if (checkChange({ 'metadata': param }, "RCV") == 'Y' || checkChange({ 'metadata': param }, "INSUREDNAME") == 'Y' || checkChange({ 'metadata': param }, "ADDITIONALINSUREDDELETED") == 'Y') {
            setAlertText("The endorsement is currently under review.");
        }
    }

    /**
     * Conditions for submit button 
     * @returns bool
     */
    const checkSubmitButtonDisabled = () => {
        let result = false;
        if (saveStatus) {
            result = true;
        }
        if (btnText == 'Review' && isPivotOutage == 'true') {
            result = true;
        }
        // disable submit button ODS-696
        if (btnText == 'Submit' && (checkChange({ 'metadata': endorsementJSON }, "RCV") == 'Y' || 
            checkChange({ 'metadata': endorsementJSON }, "INSUREDNAME") == 'Y' ||
            checkChange({ 'metadata': endorsementJSON }, "ADDITIONALINSUREDDELETED") == 'Y'
        )) {
            result = true;
        }

        return result;
    }

    /*
        All Views Array
        Add new JSON if you want to add new Accordion
    */
    const allViews = [
        {
            "eventKey": "0",
            "viewLable": "Applicant",
            "hidden": false,
            "view":
                <Applicant
                    applicationData={applicationData}
                    loading={loading}
                    setLoading={setLoading}
                    additionalInterestInfo={additionalInterestInfo}
                    handleChange={handleChange}
                    updateParentState={updateParentState}
                    showReview={showReview}
                    applicantNameEndorseJSON={endorsementJSON['new']['ApplicantName']}
                />
        },
        {
            "eventKey": "1",
            "viewLable": "Coverage",
            "hidden": showReview,
            "view":
                <Coverage
                    floodSystemDD={floodSystemDD}
                    cvgDetails={cvgInfo}
                    occupancyType={occupancyType}
                    NOU={NOU}
                    RC={replacementCost}
                    preFirmSubsidyEligible={isPreFirmSubsidyEligible}
                    buildingType={buildingUse}
                    handleChange={handleChange}
                />
        },
        /*{
            "eventKey": "2",
            "viewLable": "Insured Information",
            "hidden": showReview,
            "view":
                <InsuredInformation
                    loading={loading}
                    setLoading={setLoading}
                    applicationData={applicationData}
                    additionalInterestInfo={additionalInterestInfo}
                    handleChange={handleChange}
                />
        },*/
        {
            "eventKey": "3",
            "viewLable": "Lender",
            "hidden": showReview,
            "view":
                <Lender
                    floodSystemDD={floodSystemDD}
                    poTransPk={poTransPK}
                    handleChangeLender={handleChange}
                    mortgageeInfo={mortgageeInfo}
                    propertyInfo={propertyInfo}
                />
        },
        {
            "eventKey": "4",
            "viewLable": "Additional Information",
            "hidden": showReview,
            "view":
                <AdditionalInformation
                    floodSystemDD={floodSystemDD}
                    RC={replacementCost}
                    applicationData={applicationData}
                    propertyInfo={propertyInfo}
                    IsPrimaryResidence={setIsPrimaryRes}
                    handleChange={handleChange}
                />
        },
        // {
        //     "eventKey": "5",
        //     "viewLable": "Endorsement Effective date",
        //     "hidden": true,
        //     "view":
        //         <EndorsmentEffectiveDate
        //             floodSystemDD={floodSystemDD}
        //             applicationData={applicationData}
        //             propertyInfo={propertyInfo}
        //             handleChange={handleChange}
        //         />
        // },
        {
            "eventKey": "6",
            "viewLable": "Request Underwriter Review",
            "hidden": showReview,
            "view":
                <RequestUnderwriterReview
                    endorsementJSON={endorsementJSON}
                    handleChange={handleChange}
                />
        },
        {
            "eventKey": "7",
            "viewLable": "Endorsement Details",
            "hidden": !showReview,
            "view":
                <EndorsementDetails
                    applicationData={applicationData}
                    floodSystemDD={floodSystemDD}
                    propertyInfo={propertyInfo}
                    newPremium={newPremium}
                    currentPremium={currentPremium}
                    premiumChange={premiumChange}
                    poTransPK={poTransPK}
                    setLoading={setLoading}
                    showPaymentBtn={showPaymentBtn}
                    isRateSuccessfully={isRateSuccessfully}
                />
        },
        {
            "eventKey": "8",
            "viewLable": "Summary of Changes",
            "hidden": !showReview,
            "view":
                <SummaryofChanges
                    showReview={showReview}
                    endorsementJSON={endorsementJSON}
                    floodSystemDD={floodSystemDD}
                    isDocRequired={isDocRequired}
                    poTransPK={poTransPK}
                    applicationData={applicationData}
                    policyPK={policyMasterFK}
                />
        },
    ]

    return (
        <LoadingOverlay>
            <Loader loading={loading || getDataLoader} />
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style={{ padding: 0 }}
                >
                    <Alert show={showReview} variant={'warning'} style={{ marginTop: '5px' }}>
                        <FaExclamationTriangle />&nbsp;&nbsp;{alertText}
                    </Alert>
					{(isHiatus == true) &&
				          <>
				            <div>
				              <Alert variant="warning">
				                <div dangerouslySetInnerHTML={femaUpdateMsg}></div>
				              </Alert>
				            </div>
				          </>
				        }
                    <div className="search-quote-tab-div mt-3">
                        <div className="search-quote-btns-div">
                            {/* <Button
                                variant="primary"
                                id="close-all-btn"
                                onClick={() => handleShowPivotLog()}
                            >
                                {t('Pivot Log')}
                            </Button>{" "} */}
                            {" "}
                            <Button
                                variant="primary"
                                id="close-all-btn"
                                onClick={() => {
                                    if (!showReview) {
                                        setIsExpand(!isExpand)
                                    }
                                }}
                                disabled={showReview}
                            >
                                {t(btLabel)}
                            </Button>{" "}
                        </div>

                        <Accordion activeKey={index} className="custom-quote-accordion">
                            {
                                allViews.map((view, i) => {
                                    return (
                                        <Accordion.Item eventKey={view.eventKey} onClick={() => toggleAccordion(view.eventKey)} key={i} hidden={view.hidden}>
                                            <Accordion.Header className="search-quote-card-header">
                                                <span>{t(view.viewLable)}</span>
                                            </Accordion.Header>
                                            <Accordion.Body className="search-quote-card-body">
                                                {view.view}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                        <br></br>
                        <Row>
                            <center>
                                <Button
                                    id="primary-grey-btn-flood"
                                    variant="outline-dark"
                                    className="mr-3 mb-5"
                                    onClick = {() => handleTransactionCancel()}
                                >
                                    {t('Cancel Transaction')}
                                </Button>{" "}

                                <Button
                                    id="primary-colored-btn"
                                    variant="primary"
                                    className="ml-3 mb-5"
                                    disabled={checkSubmitButtonDisabled()}
                                    onClick = {() => handleSubmit()}
                                >
                                    {saveStatus ? (<Spinner
                                    as="span"
                                    variant="light"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    animation="border" />) : null}&nbsp;
                                    {t(btnText)}
                                </Button>
                            </center>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* {pivotLog} */}
            {
                showErrorModal && (
                    <Error
                        show={showErrorModal}
                        error={errorArray}
                        toggle={setShowErrorModal}
                    />
                )
            }
        </LoadingOverlay>
    )
}

export default Index;