import React from "react";
import { Flex } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import "./ChakraSteps.css";
/**
 * A component for rendering breadcrumbs.
 * Created common component that can be used anywhere without any dependencies.
 * @param {Array<string>} steps - An array of step labels [string, string].
 * @param {number} activeStep - The index of the currently active step. 0, 1, 2
 * @param {string} [crumbWidth="13.5%"] - DEFAULT The width of each breadcrumb.
 * @param {string} [margin="2px"] - DEFAULT The margin between breadcrumbs.
 */
export const BreadCrumbs = ({
  steps,
  activeStep,
  crumbWidth = "13.5%",
  margin = "2px",
  fontSize = "16px",
}) => {
  return (
    <Flex w="100%" flexDirection={"column"} fontSize={"sm"}>
      <Steps responsive={true} activeStep={activeStep} className="chakra-steps">
        {steps.map((label, index) => (
          <Step key={index} label={label}></Step>
        ))}
      </Steps>
      <style>
        {`.chakra-steps .cui-steps__horizontal-step {  
            margin: ${margin};
            width: ${crumbWidth};
          }
          .cui-steps__horizontal-step-container .css-0 span {
            color: var(--chakra-colors-black);
            font-size: ${fontSize};
          }
        `}
      </style>
    </Flex>
  );
};
