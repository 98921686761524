import CurrencyInput from "react-currency-input-field";

export const CustomCurrencyInput = ({ currencyStyles, ...restProps }) => {
  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid var(--chakra-colors-neutral-200)",
    borderRadius: "12px",
    padding: "0 10px",
    width: "100%",
    height: "45px",
    ...currencyStyles,
  };

  return (
    <CurrencyInput
      intlConfig={{ locale: "en-US", currency: "USD" }}
      decimalScale={0} //ODS-5349  Any selection will be to the nearest whole dollar
      size={"lg"}
      min={0}
      style={CurrencyStyles}
      prefix="$"
      {...restProps}
    />
  );
};
