import Http from "../../../../Http";

export const getStarinsQuoteData = async (cancelRequest, product) => {
  let isCancelled = false;
  const controller = new AbortController();

  if (cancelRequest && cancelRequest.cancelled) {
    isCancelled = true;
  }

  try {
    const url = `${process.env.REACT_APP_POLICY_CORE_URL}/api/v1/${product}/getQuoteBaseData`;
    const response = await Http.get(url, {
      signal: controller.signal,
    });

    if (!isCancelled) {
      return response.data;
    }
  } catch (err) {
    if (err.name === "AbortError") {
      console.log("Request cancelled");
    } else {
      console.error("Error fetching base data:", err);
    }
  }
};
