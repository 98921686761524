import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import CommonModal from "./CommonModal"; 
import AdditionalInsured from "./AdditionalInsured";
import MailingAddress from "./MailingAddress";
import ConfirmInsuredNameModal from "./ConfirmInsuredNameModal";
import { toast } from "react-toastify";

const Applicant = (props) => {
    const { applicationData , additionalInterestInfo, handleChange, loading, setLoading,updateParentState ,showReview, applicantNameEndorseJSON} = props;
    const { t } = useTranslation();

    const [fullAddress, setFullAddress] = useState('');
    const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
    const [openConfirmUserNameModal, setOpenConfirmUserNameModal] = useState(false);
    const [fullLegalName, setFullLegalName] = useState('');
    const [insuredNameChangeType, setInsuredNameChangeType] = useState('');


    useEffect(() => {
        if (applicationData && applicationData.mailingAddress) {
            let fullAddress = "";
            ['s_AddressLine1', 's_AddressLine2', 's_CityName', 's_StateCode', 's_PostalCode'].map((key) => {
                if (![null, '', undefined].includes(applicationData.mailingAddress[key])) {
                    if (fullAddress.length == 0) {
                        return fullAddress += applicationData.mailingAddress[key];
                    } else {
                        return fullAddress += ', ' + applicationData.mailingAddress[key];
                    }
                }
            })
            setFullAddress(fullAddress);
        }
        if (applicationData && applicationData.insuredData) {
            setFullLegalName(applicationData.insuredData.s_FullLegalName);
        }
    }, [applicationData])

    useEffect(() => {
        if (applicantNameEndorseJSON && applicantNameEndorseJSON['insuredNameChangeType']) {
            setInsuredNameChangeType(applicantNameEndorseJSON['insuredNameChangeType']);
        }
    }, [applicantNameEndorseJSON])

    const resetModal = () => {
        setOpenUserInfoModal(!openUserInfoModal);
    }

    const handleInsureNameChangeType = (value) => {
        if (value) {
            setInsuredNameChangeType(value);
            handleChange("insuredNameChangeType", insuredNameChangeType, value, "ApplicantName");

            setOpenConfirmUserNameModal(false);
            // open insured modal
            resetModal();
        } else {
            toast.error(t('Please select one option'));
        }
    }

    return (
        <div style={{
            "padding": "25px",
        }}>
            {
                applicationData && applicationData.mailingAddress && (
                    <Row className="align-items-end">
                        <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-2">
                            <b>{t('Name')} : </b>
                            {fullLegalName || ""}
                            {/* {
                                !showReview && (<i className='fa fa-pencil-square-o mt-2 ml-2' onClick={() => {setOpenConfirmUserNameModal(true) }} style={{fontSize:"20px", color:'#0D9D59', cursor:"pointer"}}></i>)
                            } */}
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-2">
                            <b>{t('Policy Number')} : </b>
                            {applicationData.Policy_No || ""}
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b>{t('Address')} : </b>
                            {fullAddress}
                        </Col>
                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b>{t('Term')} : </b>
                            {Moment(applicationData.transInfo[0].d_TermStartDate).format("MM-DD-YYYY") + " / " + Moment(applicationData.transInfo[0].d_TermEndDate).format("MM-DD-YYYY")}
                        </Col>
                    </Row >
                )
            }
            <hr></hr>
            <div>
                <MailingAddress
                    applicationData={applicationData}
                    handleChange={handleChange}
                    showReview={showReview}
                />
            </div>
            <hr></hr>
            <div>
                <AdditionalInsured
                    loading={loading}
                    setLoading={setLoading}
                    applicationData={applicationData}
                    additionalInterestInfo={additionalInterestInfo}
                    handleChange={handleChange}
                    showReview={showReview}
                />
            </div>
            <div>
                {
                    openUserInfoModal && 
                    <CommonModal isOpen={openUserInfoModal} handleChange={handleChange} applicantData= {applicationData.insuredData} resetModal={resetModal} updateParentState={props.updateParentState} 
                    applicantNameEndorseJSON={applicantNameEndorseJSON}/>
                }
            </div>
            {
                openConfirmUserNameModal && (
                    <ConfirmInsuredNameModal
                        show={openConfirmUserNameModal}
                        setShow={setOpenConfirmUserNameModal}
                        selected={insuredNameChangeType}
                        setSelected={setInsuredNameChangeType}
                        handleNext={handleInsureNameChangeType}
                        loading={loading}
                    />
                )
            }
        </div>

    )
}

export default Applicant;
