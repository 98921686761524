import {
  Box,
  Flex,
  Switch,
  Text,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useState } from "react";
import { MdOutlineAddBox } from "react-icons/md";
import { LossHistoryTable } from "./LossHistoryTable";
import moment from "moment";
import { FcInfo } from "react-icons/fc";
import { useQuote } from "../../CommCreateQuote";
import DateInput from "../../../create-star-quote/utils/DateInput";
import TitleHeader from "../TitleHeader";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import {
  ChakraSelect,
  CustomInput,
} from "../../commercial-quote/Components/CustomComponents";
import { causesOfLoss } from "../../commercial-quote/data/commercialDD";
const todayMaxDate = moment().toDate() || "";

export default function LossHistory() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");

  const { lossHistoryData, setLossHistoryData, underWriting, setUnderWriting } =
    useQuote();

  const [lossHistoryFields, setLossHistoryFields] = useState({
    causeOfLoss: "",
    dateOfLoss: "",
    totalPayout: "",
    lossDescription: "",
  });

  const handleSwitchChange = (event) => {
    setUnderWriting((prev) => ({
      ...prev,
      lossHistoryQuestion: event.target.checked,
    }));
    setLossHistoryData([]);
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setLossHistoryFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddLossHistory = () => {
    setLossHistoryData((prev) => [...prev, { ...lossHistoryFields }]);
    setLossHistoryFields({
      causeOfLoss: "",
      dateOfLoss: "",
      totalPayout: "",
      lossDescription: "",
    });
  };

  return (
    <>
      <TitleHeader title={t("Risk Details")}>
        <Grid templateColumns={"repeat(1, 1fr)"} gap={5}>
          <Flex
            borderBottom={"1px solid"}
            borderColor={"gray.100"}
            pb={1}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <FormLabel>{t("Shuttle Service")}</FormLabel>
            <Switch
              isChecked={underWriting?.shuttleServices}
              onChange={(e) => {
                setUnderWriting((prev) => ({
                  ...prev,
                  shuttleServices: e.target.checked,
                }));
              }}
              size={"lg"}
            />
          </Flex>
          <Flex
            borderBottom={"1px solid"}
            borderColor={"gray.100"}
            pb={1}
            flexDir={"column"}
            // justifyContent={"space-between"}
            // alignItems="center"
          >
            <Flex
              w={"100%"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <FormLabel>{t("Franchise")}?</FormLabel>
              <Switch
                isChecked={underWriting?.franchise}
                onChange={(e) => {
                  setUnderWriting((prev) => ({
                    ...prev,
                    franchise: e.target.checked,
                  }));
                }}
                size={"lg"}
              />
            </Flex>
            {underWriting?.franchise && (
              <Flex
                py={2}
                w={"100%"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <FormLabel>{t("Description")}:</FormLabel>
                <Textarea
                  value={underWriting?.franchiseDescription}
                  onChange={(e) => {
                    setUnderWriting((prev) => ({
                      ...prev,
                      franchiseDescription: e.target.value,
                    }));
                  }}
                  w={"100%"}
                  borderRadius={"xl"}
                  maxH={"100px"}
                ></Textarea>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent={"space-between"} alignItems="center">
            <FormLabel>{t("Only Owner Occupied")}?</FormLabel>
            <Switch
              isChecked={underWriting?.onlyOwnerOccupied}
              onChange={(e) => {
                setUnderWriting((prev) => ({
                  ...prev,
                  onlyOwnerOccupied: e.target.checked,
                }));
              }}
              size={"lg"}
            />
          </Flex>
          {underWriting?.onlyOwnerOccupied === false && (
            <Flex
              py={2}
              w={"100%"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <FormLabel>{t("Description")}:</FormLabel>
              <Textarea
                value={underWriting?.onlyOwnerOccupiedDescription}
                onChange={(e) => {
                  setUnderWriting((prev) => ({
                    ...prev,
                    onlyOwnerOccupiedDescription: e.target.value,
                  }));
                }}
                borderRadius={"xl"}
                maxH={"100px"}
              ></Textarea>
            </Flex>
          )}
        </Grid>
        <Box
          borderBottom={"1px solid"}
          borderColor={"gray.100"}
          borderBottomRadius={"md"}
          mb={6}
          mt={2}
        />
        <Flex alignItems="center" justifyContent="space-between" mt={2}>
          <Flex>
            <Text fontWeight={500} mb={0}>
              {t(
                "Has the applicant experienced any significant losses in the past(5) years"
              )}
              ?
            </Text>
            <Tooltip
              hasArrow
              color="white"
              textAlign="center"
              p={5}
              label={t(
                "exclude_losses_with_amount_paid_less_than_catastrophic_losses"
              )}
              bg="black"
            >
              <Box as="span" pl={2} pt={"2.8px"}>
                <FcInfo size={20} alt="" />
              </Box>
            </Tooltip>
          </Flex>
          <Switch
            isChecked={underWriting.lossHistoryQuestion}
            onChange={(event) => handleSwitchChange(event)}
            size="lg"
          />
        </Flex>

        <Flex
          display={underWriting.lossHistoryQuestion ? "flex" : "none"}
          mt={4}
        >
          <Flex flexDir={"column"} py={1} w={"100%"}>
            <Grid w={"100%"} templateColumns="repeat(3, 1fr)" gap={3}>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>{t("Date of Loss")}:</FormLabel>
                  <DateInput
                    styleProps={{
                      className: "inputSelectBoxes",
                    }}
                    maxDate={todayMaxDate}
                    value={lossHistoryFields.dateOfLoss}
                    onChange={(e) => {
                      setLossHistoryFields((prev) => ({
                        ...prev,
                        dateOfLoss: e,
                      }));
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>{t("Loss Type")}:</FormLabel>
                  <ChakraSelect
                    name="causeOfLoss"
                    value={lossHistoryFields.causeOfLoss}
                    onChange={handleFieldChange}
                  >
                    <option value=""></option>
                    {causesOfLoss.map((dd) => (
                      <option key={dd.s_AppCodeName} value={dd.s_AppCodeName}>
                        {dd.s_AppCodeNameForDisplay}
                      </option>
                    ))}
                  </ChakraSelect>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>{t("Amount")}:</FormLabel>
                  <CustomCurrencyInput
                    currencyStyles={{
                      border: "2px solid var(--chakra-colors-gray-400)",
                    }}
                    placeholder={"$0.00"}
                    value={lossHistoryFields.totalPayout}
                    onChange={(e) => {
                      setLossHistoryFields((prev) => ({
                        ...prev,
                        totalPayout: Number(
                          e.target.value.replace(/[^\d.]/g, "")
                        ),
                      }));
                    }}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <FormControl pt={4}>
              <FormLabel fontWeight={500}>{t("Loss Description")}:</FormLabel>
              <CustomInput
                name="lossDescription"
                placeholder="Description of Loss Occured (Optional)"
                value={lossHistoryFields.lossDescription}
                onChange={handleFieldChange}
              />
            </FormControl>
            <Flex justifyContent={"flex-end"}>
              <Button
                fontWeight={500}
                borderRadius={"lg"}
                mt={4}
                isDisabled={
                  !lossHistoryFields.dateOfLoss ||
                  !lossHistoryFields.causeOfLoss ||
                  !lossHistoryFields.totalPayout
                }
                onClick={handleAddLossHistory}
              >
                <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
                  <MdOutlineAddBox />
                  <Text pt={4}>{t("Add")}</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <Flex>
          {lossHistoryData?.length > 0 && (
            <LossHistoryTable
              lossHistoryData={lossHistoryData}
              setLossHistoryData={setLossHistoryData}
            />
          )}
        </Flex>
      </TitleHeader>
    </>
  );
}
