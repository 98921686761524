export const CommercialDataDD = {
  ConstructionType: [
    {
      label: "Brick Veneer",
      value: "BrickVeneer",
    },
    {
      label: "EIFS",
      value: "EIFS",
    },
    {
      label: "Frame",
      value: "Frame",
    },
    {
      label: "Log",
      value: "Log",
    },
    {
      label: "Masonry",
      value: "Masonry",
    },
    {
      label: "Stucco",
      value: "Stucco",
    },
    {
      label: "Asbestos",
      value: "Asbestos",
    },
    {
      label: "Light Non Combustible",
      value: "LightNonCombustible",
    },
  ],
  FoundationType: [
    {
      label: "Piers, Posts, Pilings",
      value: "PiersPostsPilings",
    },
    {
      label: "Reinforced Shear Walls",
      value: "ReinforcedShearWalls",
    },
    {
      label: "Solid Foundation Walls",
      value: "SolidFoundationWalls",
    },
    {
      label: "Foundation Wall",
      value: "FoundationWall",
    },
    {
      label: "Slab On Fill",
      value: "SlabOnFill",
    },
    {
      label: "Slab On Grade",
      value: "SlabOnGrade",
    },
  ],
  AdditionalFoundationType: [
    {
      label: "None",
      value: "None",
    },
    {
      label: "Finished Enclosure Full",
      value: "FinishedEnclosureFull",
    },
    {
      label: "Finished Enclosure Partial",
      value: "FinishedEnclosurePartial",
    },
    {
      label: "Unfinished Enclosure Full",
      value: "UnfinishedEnclosureFull",
    },
    {
      label: "Unfinished Enclosure Partial",
      value: "UnfinishedEnclosurePartial",
    },
    {
      label: "Finished Crawlspace",
      value: "FinishedCrawlspace",
    },
    {
      label: "Unfinished Crawlspace",
      value: "UnfinishedCrawlspace",
    },
  ],
  BasementType: [
    {
      label: "Finished",
      value: "Finished",
    },
    {
      label: "Unfinished",
      value: "Unfinished",
    },
    {
      label: "None",
      value: "None",
    },
  ],

  OccupancyType: [
    {
      label: "Airport",
      value: "Airport",
    },
    {
      label: "Amusement Park or Fairground",
      value: "AmusementParkOrFairground",
    },
    { label: "Auditorium", value: "Auditorium" },
    { label: "Bar", value: "Bar" },
    {
      label: "Beauty Salon",
      value: "BeautySalon",
    },
    {
      label: "Boat Yard",
      value: "BoatYard",
    },
    {
      label: "Bowling Alley",
      value: "BowlingAlley",
    },
    {
      label: "Brewery",
      value: "Brewery",
    },
    {
      label: "Cafe",
      value: "Cafe",
    },
    {
      label: "Camp",
      value: "Camp",
    },
    {
      label: "Casino",
      value: "Casino",
    },
    {
      label: "Cement Plant",
      value: "CementPlant",
    },
    {
      label: "Chemical Plant",
      value: "ChemicalPlant",
    },
    {
      label: "Convention Center",
      value: "ConventionCenter",
    },
    {
      label: "Day Care",
      value: "DayCare",
    },
    {
      label: "Distillery",
      value: "Distillery",
    },
    {
      label: "Farming",
      value: "Farming",
    },
    {
      label: "Fine Arts Dealers (excluding Artworks)",
      value: "FineArtsDealersExArtworks",
    },
    {
      label: "Food Processing",
      value: "FoodProcessing",
    },
    {
      label: "Funeral Home",
      value: "FuneralHome",
    },
    {
      label: "Gasoline Station",
      value: "GasolineStation",
    },
    {
      label: "Grocery Store",
      value: "GroceryStore",
    },
    {
      label: "Gym",
      value: "Gym",
    },
    {
      label: "Habitational Condominium Building",
      value: "HabitationalCondominiumBuilding",
    },
    {
      label: "Habitational Individual Units",
      value: "HabitationalIndividualUnits",
    },
    {
      label: "Habitational Multi-Family",
      value: "HabitationalMultiFamily",
    },
    {
      label: "Hospital",
      value: "Hospital",
    },
    {
      label: "Hotel",
      value: "Hotel",
    },
    {
      label: "Indoor Sports Courts",
      value: "IndoorSportsCourts",
    },
    {
      label: "Kennels",
      value: "Kennels",
    },
    {
      label: "Laboratory",
      value: "Laboratory",
    },
    {
      label: "Laundry",
      value: "Laundry",
    },
    {
      label: "Library",
      value: "Library",
    },
    {
      label: "Manufacturing",
      value: "Manufacturing",
    },
    {
      label: "Marina",
      value: "Marina",
    },
    {
      label: "Medical Clinic",
      value: "MedicalClinic",
    },
    {
      label: "Mercantile Buildings",
      value: "MercantileBuildings",
    },
    {
      label: "Mobile Home Park",
      value: "MobileHomePark",
    },
    {
      label: "Museum",
      value: "Museum",
    },
    {
      label: "Night Club",
      value: "NightClub",
    },
    {
      label: "Nursing Home",
      value: "NursingHome",
    },
    {
      label: "Office",
      value: "Office",
    },
    {
      label: "Parking Garage",
      value: "ParkingGarage",
    },
    {
      label: "Pharmacy",
      value: "Pharmacy",
    },
    {
      label: "Racetrack",
      value: "Racetrack",
    },
    {
      label: "Radio or TV Stations (excluding Tower)",
      value: "RadioOrTVStationsExclTower",
    },
    {
      label: "Railroad Properties",
      value: "RailroadProperties",
    },
    {
      label: "Recycling",
      value: "Recycling",
    },
    {
      label: "Religious Building",
      value: "ReligiousBuilding",
    },
    {
      label: "Resort",
      value: "Resort",
    },
    {
      label: "Restaurant",
      value: "Restaurant",
    },
    {
      label: "Retail Clothing",
      value: "RetailClothing",
    },
    {
      label: "Retail Food",
      value: "RetailFood",
    },
    {
      label: "Retail Other",
      value: "RetailOther",
    },
    {
      label: "School",
      value: "School",
    },
    {
      label: "Sewage Treatment Plant",
      value: "SewageTreatmentPlant",
    },
    {
      label: "Social Club",
      value: "SocialClub",
    },
    {
      label: "Spa",
      value: "Spa",
    },
    {
      label: "Stadium (Enclosed)",
      value: "StadiumEnclosed",
    },
    {
      label: "Storage Units",
      value: "StorageUnits",
    },
    {
      label: "Tavern",
      value: "Tavern",
    },
    {
      label: "Theatre",
      value: "Theatre",
    },
    {
      label: "Vehicle Showroom",
      value: "VehicleShowroom",
    },
    {
      label: "Warehouse (excluding Perishables)",
      value: "WarehouseExPerishables",
    },
    {
      label: "Warehouse (Perishables)",
      value: "WarehousePerishables",
    },
    {
      label: "Winery",
      value: "Winery",
    },
  ],

  AttachedGarageType: [
    {
      value: "None",
      label: "None",
    },
    {
      value: "Finished",
      label: "Finished",
    },
    {
      value: "Unfinished",
      label: "Unfinished",
    },
  ],
  ContentsCostValueType: [
    {
      label: "Replacement Cost Value",
      value: "ReplacementCostValue",
    },
    {
      label: "Actual Cash Value",
      value: "ActualCashValue",
    },
  ],
  FloodZoneGroups: [
    {
      s_FloodZone: "A",
    },
    {
      s_FloodZone: "AE",
    },
    {
      s_FloodZone: "A1",
    },
    {
      s_FloodZone: "A2",
    },
    {
      s_FloodZone: "A3",
    },
    {
      s_FloodZone: "A4",
    },
    {
      s_FloodZone: "A5",
    },
    {
      s_FloodZone: "A6",
    },
    {
      s_FloodZone: "A7",
    },
    {
      s_FloodZone: "A8",
    },
    {
      s_FloodZone: "A9",
    },
    {
      s_FloodZone: "A10",
    },
    {
      s_FloodZone: "A11",
    },
    {
      s_FloodZone: "A12",
    },
    {
      s_FloodZone: "A13",
    },
    {
      s_FloodZone: "A14",
    },
    {
      s_FloodZone: "A15",
    },
    {
      s_FloodZone: "A16",
    },
    {
      s_FloodZone: "A17",
    },
    {
      s_FloodZone: "A18",
    },
    {
      s_FloodZone: "A19",
    },
    {
      s_FloodZone: "A20",
    },
    {
      s_FloodZone: "A21",
    },
    {
      s_FloodZone: "A22",
    },
    {
      s_FloodZone: "A23",
    },
    {
      s_FloodZone: "A24",
    },
    {
      s_FloodZone: "A25",
    },
    {
      s_FloodZone: "A26",
    },
    {
      s_FloodZone: "A27",
    },
    {
      s_FloodZone: "A28",
    },
    {
      s_FloodZone: "A29",
    },
    {
      s_FloodZone: "A30",
    },
    {
      s_FloodZone: "AO",
    },
    {
      s_FloodZone: "AH",
    },
    {
      s_FloodZone: "D",
    },
    {
      s_FloodZone: "V",
    },
    {
      s_FloodZone: "VE",
    },
    {
      s_FloodZone: "V1",
    },
    {
      s_FloodZone: "V2",
    },
    {
      s_FloodZone: "V3",
    },
    {
      s_FloodZone: "V4",
    },
    {
      s_FloodZone: "V5",
    },
    {
      s_FloodZone: "V6",
    },
    {
      s_FloodZone: "V7",
    },
    {
      s_FloodZone: "V8",
    },
    {
      s_FloodZone: "V9",
    },
    {
      s_FloodZone: "V10",
    },
    {
      s_FloodZone: "V11",
    },
    {
      s_FloodZone: "V12",
    },
    {
      s_FloodZone: "V13",
    },
    {
      s_FloodZone: "V14",
    },
    {
      s_FloodZone: "V15",
    },
    {
      s_FloodZone: "V16",
    },
    {
      s_FloodZone: "V17",
    },
    {
      s_FloodZone: "V18",
    },
    {
      s_FloodZone: "V19",
    },
    {
      s_FloodZone: "V20",
    },
    {
      s_FloodZone: "V21",
    },
    {
      s_FloodZone: "V22",
    },
    {
      s_FloodZone: "V23",
    },
    {
      s_FloodZone: "V24",
    },
    {
      s_FloodZone: "V25",
    },
    {
      s_FloodZone: "V26",
    },
    {
      s_FloodZone: "V27",
    },
    {
      s_FloodZone: "V28",
    },
    {
      s_FloodZone: "V29",
    },
    {
      s_FloodZone: "V30",
    },
    {
      s_FloodZone: "A99",
    },
    {
      s_FloodZone: "B",
    },
    {
      s_FloodZone: "C",
    },
    {
      s_FloodZone: "X",
    },
    {
      s_FloodZone: "AR",
    },
    {
      s_FloodZone: "AR DUAL ZONES",
    },
  ],
  PropertyType: [
    {
      s_AppCodeName: "individual",
      s_AppCodeNameForDisplay: "Individual"
    },
    {
      s_AppCodeName: "organisation",
      s_AppCodeNameForDisplay: "Organisation"
    }
  ],
};
