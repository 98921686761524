import React from "react";
import { Button, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./flood-error.scss";

const FloodCBRS = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const handleOnclick = (step) => {
    navigation('/flood/instantquote/step/' + step)
  }

  return (
    <Container >
      <div className="flood-div">
        <Col md="8" className="flood-div-border">
          <div className="flood-error-heading">
            <h6>{t("Coastal Barrier Resources System (CBRS)")}!</h6>
          </div>
          <p>
            {t(
              "This Property appears to be in a CBRS/OPA and is not eligible for coverage. If you feel this is an error, please continue with the quote. This will be verified in the underwriting process and if found to be in a CBRS/OPA, a full premium refund will be mailed to the supplied mailing address"
            )}{"."}
          </p>
          <div className="flood-bts-div">
            <Button
              id="primary-grey-btn-flood"
              variant="outline-dark"
              onClick={() => handleOnclick('address')}
            >
              {t('Previous')}
            </Button>{" "}
            <Button
              id="primary-colored-btn"
              variant="primary"
              onClick={() => handleOnclick('home-type')}
            >
              {t('Next')}
            </Button>{" "}
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default FloodCBRS;