import React, { useCallback, useEffect, useState } from "react";
import { Row, Button, Col, Form } from "react-bootstrap";
import { FaFileUpload, FaPlus, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getDocTypes, UploadEndorsementDocument } from "../../../services/coreSystemService";
import Moment from 'moment';
import "react-overlay-loader/styles.css";

const UploadRequiredDoc = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { policyNumber, policyPK, poTransPK, documenttrackTransPK, setShow, documentName } = props;
    const module = "Policy";
    const subModuleName = "ATTACHMENTS_ADD_POLICY";

    const [documentTypesDD, setDocumentTypesDD] = useState([]);
    const [fileName, setFileName] = useState("");
    const [docType, setDocType] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isFileSelected, setIsFileSelected] = useState(true);

    useEffect(() => {
        getDocTypeDD();
    }, [])

    // get drop down
    const getDocTypeDD = async () => {
        setLoading(true);
        await dispatch(getDocTypes(module, subModuleName)).then((response) => {
            setDocumentTypesDD(response);
            if (![undefined, null, ""].includes(documentName)) {
                if (documentName === "Proof Of Residency") {
                    var selectedDoc = response.filter(x => x.s_DocTypeNameForDisplay === "Proof of residency")[0];
                    setDocType(selectedDoc.n_DocType_PK);
                }
            }
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        })
        setLoading(false);
    }

    // set file name
    useEffect(() => {
        if (selectedFiles.name) {
            var name = selectedFiles.name;
            setFileName(name.substr(0, selectedFiles.name.lastIndexOf('.')) || "file name");
        }
        setIsFileSelected(true)
    }, [selectedFiles])

    // on drop file
    const onDrop = useCallback(event => {
        setSelectedFiles(event[0]);
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false });

    // display file name 
    var files = acceptedFiles.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => removeFile(i)} />
        </li>
    ));

    // to delete file
    const removeFile = (file) => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setSelectedFiles(newFiles);
    };

    // validation
    const validate = () => {
        var isValid = true;
        if ([undefined].includes(selectedFiles.name)) {
            toast.error(t('Please Select File'))
            isValid = false;
            setIsFileSelected(false);
        }
        if ([null, '', undefined].includes(docType)) {
            isValid = false;
        }
        if ([null, '', undefined].includes(fileName)) {
            isValid = false;
        }
        setValidated(!isValid);
        return isValid;
    };

    // submit form
    const handleSubmit = async () => {
        const isValid = validate();
        if (isValid) {
            const formData = new FormData();
            formData.append("file", selectedFiles, selectedFiles.name);
            formData.append('fileName', fileName);
            formData.append("moduleSelected", 1);
            formData.append('moduleName', module);
            formData.append('subModule', subModuleName);
            formData.append('authorization', 'Internal');
            formData.append('docType', "");
            formData.append('doctypeSelected', docType);
            formData.append("description", fileName);
            formData.append('sourcePk', policyPK);
            formData.append('activityLogPk', 0);
            formData.append('subModulePk', 251);
            formData.append("uploadDate", Moment().format('YYYY-MM-DD'));
            formData.append("sourceId", policyNumber);
            formData.append("caseType", "");
            formData.append("receivedDate", Moment().format('YYYY-MM-DD'));
            formData.append("status", "");
            formData.append("caseNumber", "");
            formData.append("assignTo", "");
            formData.append("demandAmt", "");
            formData.append("subStatus", "");
            formData.append("refNumber", "");
            formData.append("OppCounsel", "");
            formData.append("policyId", "");
            formData.append("claimlossPaymentPk", "");
            formData.append("viewSource", "");
            formData.append("documenttrackTransPK", documenttrackTransPK);
            formData.append("poTransactionPK", poTransPK || '');
            
            setLoading(true);

            await dispatch(UploadEndorsementDocument(formData)).then((res) => {
                if (res.success === true) {
                    toast.success(t(res.data.message));
                    resetState();
                    setShow(false);
                } else {
                    toast.error(res.data);
                }
            }).catch(() => {
                toast.error(t('Something Went Wrong!'));
            });

            setLoading(false);
        }
    };

    const resetState = () => {
        setSelectedFiles([]);
        setDocType('');
        setFileName('');
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
    };

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <Row>
                <Col xs="12" sm="12" md="12" lg="5" xl="5" style={{ marginTop: "25px" }}>
                    <Form validated={validated} >
                        <Form.Label className="confirm-address-label">
                            {t('Module')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            value={module}
                            disabled
                        />

                        <Form.Label className="confirm-address-label mt-2">
                            {t('Policy NO')}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder="Policy NO"
                            value={policyNumber || ''}
                            disabled
                        />

                        <Form.Group>
                            <Form.Label className="confirm-address-label mt-2">
                                {t('Document Type')}
                            </Form.Label>
                            <Form.Select
                                className="dashboard-group-input-flood"
                                name="docuemntType"
                                id="docuemntType"
                                onChange={(e) => setDocType(e.currentTarget.value)}
                                value={docType}
                                required
                                disabled={loading}
                            >
                                <option value={''}>{t('Select Document Type')}</option>
                                {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                    <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {t("Please Select Document Type")}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="confirm-address-label mt-2">
                                {t('File Name')}
                            </Form.Label>
                            <Form.Control
                                className="dashboard-group-input-flood"
                                placeholder={t('File Name')}
                                type="text"
                                onChange={(e) => setFileName(e.target.value)}
                                value={fileName}
                                required
                                disabled={loading}
                            />
                            <Form.Control.Feedback type="invalid">
                                {t("Please Enter File Name")}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>

                <Col xs="12" sm="12" md="12" lg="7" xl="7" style={{ marginTop: "25px" }}>
                    <span className="dashboard-group-label">{t('Attachment')}</span>
                    <div
                        id="drop-document-type-div"
                        {...getRootProps({ className: "dropzone" })}
                    >
                        <input {...getInputProps()} />
                        <FaFileUpload size={34} color={"#64D6CD"} />
                        <p>{t('Drag and Drop File Here')}</p>
                        <Button id="common-btn" variant="primary">
                            <FaPlus size={15} />
                            {t('Add File')}
                        </Button>{" "}
                    </div>

                    <aside className="reply-document-name">
                        <ul>{files}</ul>
                    </aside>
                    {!isFileSelected && (
                        <aside className="reply-document-name" style={{ color: 'red' }}>
                            <ul>{t('Please Select File')}</ul>
                        </aside>
                    )}
                </Col>

                <div className="card-header-main-flood">
                    <Button
                        id="primary-grey-btn-flood"
                        variant="outline-dark"
                        onClick={() => setShow(false)}
                        style={{
                            marginRight: "1rem",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                        }}
                        disabled={loading}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        id="common-outline-btn-flood"
                        variant="outline-primary"
                        onClick={() => handleSubmit()}
                        disabled={loading}
                    >
                        {t('Submit')}
                    </Button>
                </div>
            </Row>
        </LoadingOverlay>
    )
}

export default UploadRequiredDoc;