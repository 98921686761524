import React from "react";
import { ConstructionDetails as ConstructionDetailsComponent } from "../ConstructionDetails/ConstructionDetails";
import { Box} from "@chakra-ui/react";

const StepConstructionDetails = () => {
  return (
    <Box pt={3}>
      <Box pb={"1.5rem"} p={2}>
        <Box p={2}>
          <ConstructionDetailsComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default StepConstructionDetails;