import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";
import { postHiscoxUpdateQuote } from "../../../api/postUpdateHiscoxQuote";
import { getPrintQuoteDoc } from "../../../api/getPrintQuoteDoc";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./QuoteAction.css";
import { FaPrint, FaFile, FaCheck } from "react-icons/fa";
import { BindModal } from "./BindQuote/BindModal";
import PremiumModal from "./updatePremium/PremiumModal";
import { useTranslation } from "react-i18next";
import ActivityLogs from "./ActivityLogs";
import { isAllowedToBindQuoteInHiscoxFloodPlus } from "../../../../../../services/moduleVisibilityService";
import { EncryptOrDecrypt } from "../../../../../../services/commonService";
import { FaLink } from "react-icons/fa";
import ListDocument from "../../../../flood/quote/Modal/ListDocument";
import { requestBindQuote } from "../../../api/requestBindQuote";
import { postReleaseBindQuote } from "../../../api/postReleaseBindQuote";
import { postSaveHiscoxPremiumCard } from "../../../api/postSaveHiscoxPremium";
import { MdOutlineNewReleases } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { Button, Badge, HStack, Box, Flex } from "@chakra-ui/react";
import { FiEdit3 } from "react-icons/fi";

export default function QuoteActions({
  bindDetails,
  setLoading,
  quoteNumber,
  quoteDetailsData,
  edit,
  setEdit,
  updateQuote,
  setUpdateQuote,
  loading,
  setShowUpdatePremiumModal,
  showUpdatePremiumModal,
  setRefreshQuoteApplication,
  saveMortgagee,
  setSaveMortgagee,
  setSaveMailingAddress,
  saveMailingAddress,
  saveApplicantInfo,
  setSaveApplicantInfo,
  saveAdditionalInsured,
  setSaveAdditionalInsured,
  displayQuote,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [premiumResponseData, setPremiumResponseData] = useState({});
  const [showBindModal, setShowBindModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [isBind, setIsBind] = useState(false);
  const [showRequiredDocModal, setShowRequiredDocModal] = useState(false);
  const [requireDocumentCount, setRequireDocumentCount] = useState(0);

  const { productType } = bindDetails;
  const poTransPk = quoteDetailsData?.data?.poTransactionPk;
  const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const userLevel = userDatas.accessRights.levelName;
  const userSubLevel = userDatas.accessRights.subLevelName;
  const [printQuoteState, setPrintQuoteState] = useState(false);
  const [requestBindState, setRequestBindState] = useState(false);
  const [showConfirmRequestBindModal, setShowConfirmRequestBindModal] =
    useState(false);
  const [showConfirmReleaseBindModal, setShowConfirmReleaseBindModal] =
    useState(false);

  const showBindQuoteModalBox = (resetPrintAndRequestBindState = false) => {
    if (resetPrintAndRequestBindState) {
      setPrintQuoteState(false);
      setRequestBindState(false);
    }
    if (quoteDetailsData.data.premium.hasOwnProperty("selectedPremium")) {
      //Show BIND modal if user has already selected the premium
      setShowBindModal(true);
    } else {
      //Show select permoim modal if user has not selected the premium
      let mappedPremiumData = {
        data: {
          hiscoxId: quoteDetailsData.data.quoteId,
        },
      };

      if (quoteDetailsData.data.productType == "Residential") {
        mappedPremiumData = {
          data: {
            ...mappedPremiumData.data,
            residential: quoteDetailsData.data.premium,
          },
        };
      } else if (quoteDetailsData.data.commercialProductType == "Owned") {
        mappedPremiumData = {
          data: {
            ...mappedPremiumData.data,
            commercialOwned: quoteDetailsData.data.premium,
          },
        };
      } else if (quoteDetailsData.data.commercialProductType == "Tenanted") {
        mappedPremiumData = {
          data: {
            ...mappedPremiumData.data,
            commercialTenanted: quoteDetailsData.data.premium,
          },
        };
      }
      setPremiumResponseData(mappedPremiumData);
      setTimeout(() => setShowPremiumModal(true), 500); // setShowUpdatePremiumModal(true);
    }
  };

  const handleClosePremiumBox = () => {
    setShowPremiumModal(false);
    setShowUpdatePremiumModal(false);
  };
  const handleQuoteUpdate = async () => {
    try {
      setLoading(true);
      const updateResult = await postHiscoxUpdateQuote({
        requestBody: bindDetails,
        quoteNo: quoteNumber || 0,
      });
      setPremiumResponseData(updateResult);
      if (updateResult?.status === true) {
        toast.success("Quote Updated", {
          autoClose: 1500,
        });
        setEdit(false);
        setShowUpdatePremiumModal(true);
        setShowPremiumModal(true);
      } else if (
        updateResult?.status === false &&
        Array.isArray(updateResult?.errors)
      ) {
        updateResult.errors.forEach((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        });
      }
    } catch (error) {
      console.error("Error during quote update:", error);
      toast.error(
        "An error occurred during quote update. Please try again later.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        }
      );
    } finally {
      setLoading(false);
      setUpdateQuote(false);
    }
  };

  useEffect(() => {
    if (updateQuote === true) {
      handleQuoteUpdate();
    }
  }, [updateQuote]);

  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  };
  useEffect(() => {
    if (Object.keys(quoteDetailsData).length > 0) {
      var temp = false;
      if (checkDateisValid(quoteDetailsData.data.quoteBindDate)) {
        temp = true;
      }
      setIsBind(temp);

      if (quoteDetailsData?.data?.requireDocumentCount) {
        setRequireDocumentCount(
          quoteDetailsData?.data?.requireDocumentCount ?? 0
        );
      }
    }
  }, [quoteDetailsData]);

  const handleRequestBindClick = () => {
    setShowConfirmRequestBindModal(true);
  };
  const handleRequestBindSubmit = () => {
    requestBind();
    setShowConfirmRequestBindModal(false);
  };

  const handleReleaseBindClick = () => {
    setShowConfirmReleaseBindModal(true);
  };

  const handleRequestReleaseBindSubmit = () => {
    releaseBind();
    setShowConfirmReleaseBindModal(false);
  };
  const printQuote = async () => {
    try {
      setLoading(true);
      const updateResult = await getPrintQuoteDoc(poTransPk);
      if (updateResult?.status === true) {
        window.open(updateResult.url, "_blank");
        toast.success("Print quote generated!", {
          autoClose: 1500,
        });
      } else if (
        !quoteDetailsData.data.premium.hasOwnProperty("selectedPremium") ||
        updateResult?.error
      ) {
        setPrintQuoteState(true);
        showBindQuoteModalBox();
      } else {
        updateResult?.errors?.forEach((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        });
      }
    } catch (error) {
      console.error("Error during print quote:", error);
      toast.error(
        "An error occurred during print quote. Please try again later.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const savePremiumCard = async () => {
    const requestBody = {
      calculatedFees:
        quoteDetailsData?.data?.premium?.selectedPremium?.calculatedFees,
      includeLossOfUse:
        productType === "Commercial"
          ? null
          : quoteDetailsData?.data?.premium?.selectedPremium?.includeLossOfUse,
      includeContents:
        quoteDetailsData?.data?.premium?.selectedPremium?.includeContents,
      includeBusinessIncomeAndExtraExpense:
        productType === "Residential"
          ? null
          : quoteDetailsData?.data?.premium?.selectedPremium
              ?.includeBusinessIncomeAndExtraExpense,
      deductible: quoteDetailsData?.data?.premium?.selectedPremium?.deductible,
      chargedPremium:
        quoteDetailsData?.data?.premium?.selectedPremium?.chargedPremium,
      type: quoteDetailsData?.data?.premium?.selectedPremium?.type,
      building_coverage:
        quoteDetailsData?.data?.commercialProductType !== "Tenanted"
          ? quoteDetailsData?.data?.premium?.selectedPremium?.buildingLimit
          : null,
      content_coverage:
        quoteDetailsData?.data?.premium?.selectedPremium?.contentsLimit ?? null,
      improvement_betterment_coverage:
        quoteDetailsData?.data?.commercialProductType === "Tenanted"
          ? quoteDetailsData?.data?.premium?.selectedPremium
              ?.improvement_betterment_coverage
          : null,
    };
    const cleanedRequestBody = Object.fromEntries(
      Object.entries(requestBody).filter(([_, value]) => value !== null)
    );

    const quoteNo = quoteDetailsData?.data?.quoteId;
    try {
      setLoading(true);
      const data = await postSaveHiscoxPremiumCard({
        requestBody: cleanedRequestBody,
        quoteNo,
      });
      if (data?.status === true) {
        toast.success(
          data?.message === "Coverage Update successfully"
            ? "Updated!"
            : data?.message,
          {
            autoClose: 1500,
          }
        );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1500);
        await displayQuote();
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      saveMortgagee === true ||
      saveApplicantInfo === true ||
      saveMailingAddress === true ||
      saveAdditionalInsured === true
    ) {
      const updateQuoteToSaveMortgage = async () => {
        try {
          setLoading(true);
          const updateResult = await postHiscoxUpdateQuote({
            requestBody: bindDetails,
            quoteNo: quoteNumber || 0,
          });
          if (updateResult?.status === true) {
            if (quoteDetailsData?.data?.premium?.selectedPremium) {
              savePremiumCard();
            } else {
              toast.success("Updated!", {
                autoClose: 1500,
              });
              await displayQuote();
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            }
          } else if (
            updateResult?.status === false &&
            Array.isArray(updateResult?.errors)
          ) {
            updateResult.errors.forEach((error) => {
              toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000,
              });
            });
          }
        } catch (error) {
          console.error("Error during quote update:", error);
          toast.error(
            "An error occurred during quote update. Please try again later.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            }
          );
        } finally {
          setLoading(false);
          setSaveMortgagee(false);
          setSaveMailingAddress(false);
          setSaveApplicantInfo(false);
          setSaveAdditionalInsured(false);
        }
      };
      updateQuoteToSaveMortgage();
    }
  }, [
    saveMortgagee,
    saveApplicantInfo,
    saveMailingAddress,
    saveAdditionalInsured,
  ]);

  const countRequiredDocFunction = () =>
    setRequireDocumentCount(requireDocumentCount - 1);

  const requestBind = async () => {
    try {
      setLoading(true);
      setRequestBindState(true);
      if (quoteDetailsData.data.premium.hasOwnProperty("selectedPremium")) {
        const res = await requestBindQuote(quoteDetailsData?.data?.quoteId);
        toast.success("Quote Bind Requested", {
          position: "top-center",
          toastId: "quoteBind Request",
        });
        window.location.reload();
      } else {
        showBindQuoteModalBox();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const releaseBind = async () => {
    setLoading(true);
    const quoteId = bindDetails.quoteId;
    try {
      const data = await postReleaseBindQuote(quoteId);
      if (data.status === true) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
        toast.success("Request to Bind Released", {
          autoClose: 1500,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {!isBind && (
        <HStack justifyContent={"flex-end"} style={{ marginBottom: "1.1rem" }}>
          {!quoteDetailsData?.data?.quoteBindRequestDate && (
            <Button
              color={"white"}
              bg={"primary"}
              id="primary-colored-btn"
              variant="primary"
              onClick={() => setShowRequiredDocModal(!showRequiredDocModal)}
            >
              <Box display="flex" alignItems="center" gap={2}>
                {requireDocumentCount > 0 ? (
                  <Badge
                    borderRadius={"full"}
                    backgroundColor={"red.500"}
                    color={"white"}
                    className="Blink"
                  >
                    {requireDocumentCount}
                  </Badge>
                ) : (
                  <FaFile />
                )}
                {t("Required Document")}
              </Box>
            </Button>
          )}
          <Button
            color={"white"}
            bg={"primary"}
            style={{ padding: "0.65rem 1.5rem" }}
            id="primary-colored-btn"
            variant="primary"
            onClick={() => {
              printQuote();
            }}
          >
            <FaPrint style={{ marginRight: "5px" }} /> {`${t("Print Quote")}`}
          </Button>
          {!quoteDetailsData?.data?.quoteBindRequestDate &&
            !isAllowedToBindQuoteInHiscoxFloodPlus(userLevel, userSubLevel) && (
              <Button
                color={"white"}
                bg={"primary"}
                id="primary-colored-btn"
                variant="primary"
                style={{ display: "flex", marginRight: "20px" }}
                // onClick={requestBind}
                onClick={handleRequestBindClick}
              >
                <FaLink size={20} />
                {`${t("Request Bind")}`}
              </Button>
            )}
          <ActivityLogs poTransPk={poTransPk} />
          {!edit &&
            (!quoteDetailsData?.data?.quoteBindRequestDate ||
              isAllowedToBindQuoteInHiscoxFloodPlus(
                userLevel,
                userSubLevel
              )) && (
              <>
                <Button
                  color={"white"}
                  bg={"primary"}
                  id="primary-colored-btn"
                  variant="primary"
                  onClick={(e) => setEdit(true)}
                >
                  <Box display={"flex"} alignItems={"center"} gap={2}>
                    <FiEdit3 size={20} />
                    &nbsp;{t("Edit Application")}
                  </Box>
                </Button>
              </>
            )}
          {!edit &&
            isAllowedToBindQuoteInHiscoxFloodPlus(userLevel, userSubLevel) && (
              <>
                <Button
                  color={"white"}
                  bg={"primary"}
                  id="primary-colored-btn"
                  variant="primary"
                  onClick={() => {
                    showBindQuoteModalBox(true);
                  }}
                  style={{ display: "flex" }}
                >
                  <Flex gap={1} alignItems={"center"}>
                    <FaCheck size={20} />
                    {quoteDetailsData?.data?.quoteBindRequestDate
                      ? `${t("Accept Bind Request")}`
                      : `${t("Bind Application")}`}
                  </Flex>
                </Button>
              </>
            )}
          {quoteDetailsData?.data?.quoteBindRequestDate &&
            isAllowedToBindQuoteInHiscoxFloodPlus(userLevel, userSubLevel) && (
              <>
                <Button
                  color={"white"}
                  bg={"primary"}
                  id="primary-colored-btn"
                  variant="primary"
                  onClick={handleReleaseBindClick}
                  style={{ display: "flex", marginLeft: "25px" }}
                >
                  {quoteDetailsData?.data?.quoteBindRequestDate && (
                    <>
                      <MdOutlineNewReleases size={20} />
                      {t("Release Bind Request")}
                    </>
                  )}
                </Button>
              </>
            )}
        </HStack>
      )}

      <div
        className="d-flex justify-content-end "
        style={{ marginBottom: "1.1rem" }}
      >
        {isBind && <ActivityLogs poTransPk={poTransPk} />}
        {isBind && (
          <>
            <Button
              color={"white"}
              bg={"primary"}
              id="primary-colored-btn"
              variant="primary"
              onClick={() => {
                navigate(`/core/policy/${quoteDetailsData?.data?.quoteId}`);
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <CgFileDocument size={20} />
                &nbsp;{t("Continue to Policy")}
              </Box>
            </Button>
          </>
        )}
        {/* {!edit && (
          <Button
            className="btn btn-primary me-4 p-2 "
            id="primary-colored-btn"
            variant="primary"
            onClick={(e) => setEdit(true)}
          >
            <div className="d-flex align-items-center gap-2">
              {t("Edit Application")} <CiEdit size={20} />
            </div>
          </Button>
        )}
        {!edit && (
          <Button
            className="btn btn-primary me-4 p-2"
            id="primary-colored-btn"
            variant="primary"
            onClick={() => {
              showBindQuoteModalBox();
            }}
          >
            {`${t("Bind")} ${t("Quote")}`}
          </Button>
        )} */}
      </div>
      {/* Bind Modal  */}
      <BindModal
        quoteDetailsData={quoteDetailsData}
        show={showBindModal}
        setLoading={setLoading}
        loading={loading}
        handleClose={() => {
          setShowBindModal(false);
        }}
      />

      {/* Update Premium Modal */}
      <PremiumModal
        show={showPremiumModal || showUpdatePremiumModal}
        handleClose={() => {
          handleClosePremiumBox();
          setShowUpdatePremiumModal(false);
        }}
        quoteDetailsData={quoteDetailsData}
        setShowBindModal={setShowBindModal}
        premiumResponseData={premiumResponseData}
        productType={productType}
        setRefreshQuoteApplication={setRefreshQuoteApplication}
        handleClosePremiumBox={handleClosePremiumBox}
        printQuoteState={printQuoteState}
        setPrintQuoteState={setPrintQuoteState}
        requestBindState={requestBindState}
        setRequestBindState={setRequestBindState}
        userDatas={userDatas}
        userLevel={userLevel}
        userSubLevel={userSubLevel}
      />
      {/* Required Documents Modal */}
      {showRequiredDocModal && (
        <ListDocument
          show={showRequiredDocModal}
          toggle={setShowRequiredDocModal}
          poTransPK={poTransPk}
          updateReqDocCount={countRequiredDocFunction}
        />
      )}
      <Modal
        size="lg"
        show={showConfirmRequestBindModal}
        onHide={() => setShowConfirmRequestBindModal(false)}
      >
        <Modal.Header closeButton id="common-modal-header" className="px-4">
          <Modal.Title className="d-flex align-items-center gap-2 text-white">
            {t("Confirm Bind Quote")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to request to bind the quote?")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-btn"
            variant="secondary"
            style={{ padding: "5px  15px" }}
            onClick={() => setShowConfirmRequestBindModal(false)}
          >
            {t("No")}
          </Button>
          <Button
            id="common-btn"
            style={{ padding: "5px  15px" }}
            onClick={handleRequestBindSubmit}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showConfirmReleaseBindModal}
        onHide={() => setShowConfirmReleaseBindModal(false)}
      >
        <Modal.Header closeButton id="common-modal-header" className="px-4">
          <Modal.Title className="d-flex align-items-center gap-2 text-white">
            Confirm Release Bind Quote
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to release bind quote request?
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-btn"
            variant="secondary"
            style={{ padding: "5px  15px" }}
            onClick={() => setShowConfirmReleaseBindModal(false)}
          >
            {t("No")}
          </Button>
          <Button
            id="common-btn"
            style={{ padding: "5px  15px" }}
            onClick={handleRequestReleaseBindSubmit}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
