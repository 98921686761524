import React, { useState, useEffect } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis,Tooltip } from "recharts";
import { Row, Col, Form } from "react-bootstrap";
import "./statistics.scss";
import {getStatistics,getYearsForStatistics} from '../../services/dashboardService';
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

const Statistics = () => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  useEffect(()=>{
    getYearsForStatisticsData();
    getStatisticsData(new Date().getFullYear());
  },[])
 
  
  const getStatisticsData = async (year) => {
    setLoading(true);
    await dispatch(getStatistics(year)).then(
      (res) => {
        setStats(res.data.data.graph)     
      }
    );
    setLoading(false);
  };

  const getYearsForStatisticsData = async () =>{
    setLoading(true);
      await dispatch(getYearsForStatistics()).then(
      (res) =>{
        setYears(res.data.data);
      }
      );
      setLoading(false);
  }
  // const renderCustomBarLabel = ({payload, x, y, width, height, value})=>{
  //   return <text x={x + width } y={y} fill="#666" textAnchor="middle" >{`Total Premium: ${value}`}</text>
  // }

  function CustomTooltip({ payload, label, active,value }) {
    if (active && payload != null) {
      return (
        <div className="custom-tooltip">
          <p className="label">Total Policies : {numberWithCommas(payload[0].payload.policy_count)}  Total Premium : $ {numberWithCommas(payload[0].payload.total_premium)}</p>
        </div>
      );
    }
  
    return null;
  }

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }

 const handleChange = (e) =>{
  setSelectedYear(e.target.value)
  getStatisticsData(e.target.value);
 }

  return (
    <div className="stat-page-section">
      <Row>
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          className="stat-horizontal-bar"
        >
          <div className="stat-horizontal-header">
            <span className="stat-horizontal-title">
              Top Ten State Wide Quote
            </span>
            <div className="stat-horizontal-header-subdiv">
              <span className="stat-horizontal-subtitle">
                <div className="stat-square"></div>
                Top 10 States
              </span>

              <div className="stat-select-div">
                <p className="dashboard-group-label" id="stat-select-label">
                  Select Year
                </p>
                <div className="stat-page-selections">{
                  
                }
                  <Form.Select className="dashboard-group-select"  onChange={handleChange} value={selectedYear}>
                 
                                                                {years.length > 0 && years.map((v, i) => {
                                                                    return (
                                                                        <option key={i} value={v}>
                                                                            {v}
                                                                        </option>
                                                                    );
                                                                })}
                  </Form.Select>
                </div>
              </div>
            </div>
          </div>
          <LoadingOverlay>
           <Loader loading={loading} />
          <ResponsiveContainer width="100%" height={428}>
            <BarChart
              margin={{ right: 30, left: 60 }}
              width={500}
              height={100}
              data={stats}
              layout="vertical"
            
            >
              <XAxis type="number" />
              <YAxis type="category" label dataKey="StateName" />
              <Tooltip content={<CustomTooltip />}/>
              <Bar  dataKey="policy_count" fill="#23C6C8" />
            </BarChart>
          </ResponsiveContainer>
          </LoadingOverlay>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics;
