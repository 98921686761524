import React, { useState, useEffect, useCallback } from "react";
import "./license.scss";
import { FaInbox, FaEye, FaPlus, FaFileUpload, FaTimes } from "react-icons/fa";
import { Row, Col, Button, Form, Modal, Table, Card, CardBody, CardHeader, CardText, ModalBody, ModalHeader,Spinner} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import { getCeLicenseData, updateCelicenseInformation, getViewDocument } from "../../services/ceDocumentService";
import Moment from 'moment';
import {EncryptOrDecrypt } from "../../services/commonService";
import { toast } from 'react-toastify';
import { stateList } from "../../services/enrollService";
import { useDispatch } from "react-redux";

const EditCELicense = (props) => {
	
	const dispatch = useDispatch();
    const [issueDate, setIssueDate] = React.useState(new Date());
    const [expireDate, setExpireDate] = React.useState('9999-01-01');
    const [courseCompletionDate, setCourseCompletionDate] = React.useState();
    const [courseExpirationDate, setCourseExpirationDate] = React.useState('');
    const [fileSelected, setFiles] = React.useState([]);
    const [fileFromDb, setFileFromDb] = React.useState([]);
    const [tableData, setTableData] = useState([]);
    const [listData, setListData] = useState([]);
    const [docname, setDocName] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [courseHours, setCourseHours] = useState('');
    const [courseNumber, setCourseNumber] = useState('');
    const [courseName, setCourseName] = useState('');
    const [trainingProviderNumber, setTrainingProviderNumber] = useState('');
    const [trainingProviderName, setTrainingProviderName] = useState('');
    const [individualLicenseNumber, setIndividualLicenseNumber] = useState('');
    const [residentialState, setResidentialState] = useState(null);
    const [modalType, setModalType] = useState();
    const [rowId, setRowId] = useState(0);
    const [rowStatus, setRowStatus] = useState('');
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [reasonModal, setReasonModal] = useState(false);
    const [viewReason, setViewReason] = useState('');
    const [modalMsg, setModalMsg] = useState();
    const [id, setId] = useState();

    const [moduleName, setModuleName] = useState('Producer');
    const [subModule, setSubModule] = useState('CE_LICENSE');
    const [subModulePk, setSubModulePk] = useState(170);
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    const [buttonLoading, setButtonLoading] = useState(false);
	const [states,setStates] = useState([])
	const [attest,setAttest] = useState(false);
	const [isShow, setIsShow] = useState(true);
	
	useEffect(() => {
		setIsShow(true);
        (async () => {
            const stateData = await dispatch(stateList())
            setStates(stateData.data)
           
        })();
    }, [props])
	
	const onDrop = useCallback(event => {
        setFiles(event[0])
    }, [])

    const { acceptedFiles, isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop,multiple: false });

    var files = acceptedFiles.map((file, i) => (
		<li key={file.path}>
			{file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => remove(i)} />
		</li>
	));
	
    const remove = file => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        acceptedFiles.splice(file, 1);
        setFiles(newFiles);
    };
	
	const removeFileFromDb = async () => {
		setFileFromDb('');
	}
	
	useEffect(() => {
        (async () => {
            let rowId = props?.rowData?.id ?? 0;
			if(rowId){
				let getFileData =  await getCeLicenseData(rowId);
		        if(getFileData.success == true){
					let metadata = getFileData?.data?.metadata;
					setResidentialState(userDatas?.residentState || null);
					setIndividualLicenseNumber(metadata?.LicenseNo || '');
					setTrainingProviderName(metadata?.TrainingProvider || '');
					setTrainingProviderNumber(metadata?.TrainingProviderNo || '');
					setCourseName(metadata?.CourseName || '');
					setCourseNumber(metadata?.CourseNumber || '');
					setCourseHours(metadata?.CourseHours || '');
					setDocName(getFileData?.data?.Doc_Name || '');
					setCourseCompletionDate(new Date(Moment(metadata?.CourseCompletionDate)) || '');
					setCourseExpirationDate(new Date(Moment(metadata?.CourseExpirationDate)) || '');
					setIssueDate(metadata?.issue_date);
					setExpireDate(metadata?.expiry_date);
					setFileFromDb(getFileData?.data?.Doc_OriginalName);
		        }
			}
        })();
    }, [props])
	

    const handleSave = async (e) => {
        e.preventDefault();
        const validateStatus = await validate();
            if(!validateStatus){
                return false;
            }
            setButtonLoading(true);
            const formData = new FormData();
            let uploadDate = Moment(issueDate).format("YYYY-MM-DD");
            var receivedDate = Moment(new Date()).format("YYYY-MM-DD");
			if(fileSelected && fileSelected.length != 0){
				formData.append("file", fileSelected, fileSelected.name);
				formData.append("fileName", docname);
			}
            formData.append("moduleSelected", 4);
            
			formData.append("Username", userDatas.Username);
			formData.append("id", props?.rowData?.id);
			formData.append("ProducerName", userDatas.s_ScreenName);
			formData.append("LicenseNo", individualLicenseNumber);
			formData.append("TrainingProvider", trainingProviderName);
			formData.append("TrainingProviderNo", trainingProviderNumber);
			formData.append("CourseName", courseName);
			formData.append("CourseNumber", courseNumber);
			formData.append("CourseHours", courseHours);
			formData.append("CourseCompletionDate",  Moment(courseCompletionDate).format("YYYY-MM-DD"));
			formData.append("CourseExpirationDate", (courseExpirationDate != '') ? Moment(courseExpirationDate).format("YYYY-MM-DD") : '9999-01-01');
			formData.append("ResidencyState", userDatas.residentState ?? 1);
			
            formData.append("doctypeSelected", '');
            formData.append("description", docname);
            formData.append("uploadDate", uploadDate);
            formData.append("sourceId", '');
            formData.append("moduleName", moduleName);
            
            formData.append("authorization", 'Internal');
            formData.append("caseType", '');
            formData.append("sourcePk", userDatas.persInfoAgencyFK);
            formData.append("docType", '');
            formData.append("status", '');
            formData.append("policyId", '');
            formData.append("subModule", subModule);
            formData.append("subModulePk", subModulePk);
            formData.append("issue_date", Moment(courseCompletionDate).format("YYYY-MM-DD"));
            formData.append("expiry_date", (expireDate != '9999-01-01') ? Moment(expireDate).format("YYYY-MM-DD") : '9999-01-01');
            let SaveFileData =  await updateCelicenseInformation(formData);
            if(SaveFileData?.status == 200){
                
                toast.success(SaveFileData.data.data.message);
				
				setTimeout(() => {
					setIsShow(false);
					setButtonLoading(false);
					window.location.reload();
				}, 1500);
				
            }else{
				toast.error('This Details Cannot be submitted please Check again');
				setButtonLoading(false);
			}
    }

    const validate = async () => {
        var status = 'N';
        var msg = ''
		if (fileSelected.length == 0 && fileFromDb == '') {
			status = 'Y';
            msg = 'Please Add File'
        }
		if (attest == false) {
			status = 'Y';
			msg = 'Please attest the CE License'
		}
		if (typeof courseCompletionDate == 'undefined' || courseCompletionDate == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Completion Date'
        }
		if (courseHours == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Hours'
        }
		if (courseNumber == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Number'
        }
		if (courseName == '') {
            status = 'Y';
            msg = 'Please Enter Flood Course Name'
        }
		if (trainingProviderNumber == '') {
            status = 'Y';
            msg = 'Please Enter Training Provider Number'
        }
		if (trainingProviderName == '') {
            status = 'Y';
            msg = 'Please Enter Training Provider Name'
        }
		if (individualLicenseNumber == '') {
            status = 'Y';
            msg = 'Please Enter Individual License Number'
        }
		if (residentialState == '' || residentialState == null) {
			status = 'Y';
			msg = 'Please Enter Residential State'
		}
        if(status == 'Y'){
            toast.error(msg);
            return false;
        } else{
            return true;
        }
    }
	
	const calculateExpireDate = (date) => {
		
		{states.map((state) => {
			if(state.s_StateCode == 'DE'){
				if(residentialState == state.n_StateId_PK){
					setCourseExpirationDate(new Date(Moment(date).add(2, 'years')));
					setExpireDate(new Date(Moment(date).add(2, 'years')));
				}	
			}else if(state.s_StateCode == 'NC'){
				if(residentialState == state.n_StateId_PK){
					setCourseExpirationDate(new Date(Moment(date).add(4, 'years')));
					setExpireDate(new Date(Moment(date).add(2, 'years')));
				}
			}
		})}
		
		setCourseCompletionDate(date);
	}

    return(
        <Modal
	        className="custom-dashboard-modal"
	        size="xl"
	        show={isShow}
	      >
	        <Modal.Header closeButton  onClick={() => {setIsShow(false); }} className="custom-dashboard-modal-header">
	          <Modal.Title>Edit CE Information</Modal.Title>
	        </Modal.Header>
	        <Modal.Body className="custom-dashboard-modal-body">
			<Row>
                <Col xs="6" style={{ margin: "0px",padding: "0px" }}>
                    
                    <Row className="">
                        <Col xs="12" style={{ marginTop: "0px" }}>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Company:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        value={userDatas?.companyDetails?.company_name || ''}
										disabled
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Individual Producer Name:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input-small"
                                        type="text"
										value={userDatas?.s_ScreenName || ''}
										disabled
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Residential State:
                                    </Form.Label>
								</Col>
								<Col xs="7">
								  	<Form.Select
							        	className="table-show-entries-select"
							        	onChange={(e) => setResidentialState(e.target.value)}
										value={residentialState}
										disabled
							      	>
										<option >Please Select State</option>
								        {states.map((state) => {
											
								          return  <option value={state.n_StateId_PK}>
										   {state.s_StateDisplayName} ({state.s_StateName}) 
										  </option>
								        })}
							      	</Form.Select>
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Individual License Number:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        onChange={(e) => setIndividualLicenseNumber(e.target.value)}
										value={individualLicenseNumber}
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Training Provider Name:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        onChange={(e) => setTrainingProviderName(e.target.value)}
										value={trainingProviderName}
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Training Provider Number:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        onChange={(e) => setTrainingProviderNumber(e.target.value)}
										value={trainingProviderNumber}
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Flood Course Name:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        onChange={(e) => setCourseName(e.target.value)}
										value={courseName}
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Flood Course Number:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
										required
                                        onChange={(e) => setCourseNumber(e.target.value)}
										value={courseNumber}
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Flood Course Hours:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        onChange={(e) => setCourseHours(e.target.value)}
										value={courseHours}
                                    />
								</Col>
							</Row>
							
							{acceptedFiles && acceptedFiles.length > 0 &&
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Document Name:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        placeholder=""
                                        onChange={(e) => setDocName(e.target.value)}
										value={docname}
                                    />
								</Col>
							</Row>
							}
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									Flood Course Completion Date:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<DatePicker
                                        id="dashboard-datepicker"
                                        className="datepicker-full-width"
                                        name="issue date"
                                        onChange={(date) => calculateExpireDate(date)}
                                        selected={courseCompletionDate}
										onKeyDown={(e) => {
											e.preventDefault();
										 }}
										 
                                    />
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								<Col xs="5" style={{'textAlign': 'right','paddingTop':'5px'}}>
									<Form.Label className="small">
									State Flood CE Expiration Date:
                                    </Form.Label>
								</Col>
								<Col xs="7">
									<DatePicker
                                        id="dashboard-datepicker"
                                        className="datepicker-full-width"
                                        name="issue date"
                                        //onChange={(date) => setCourseExpirationDate(date)}
                                        selected={courseExpirationDate}
										//disabled
										readOnly={true}
                                    />
								</Col>
							</Row>
							<Row>
								<Col xs="12">
										&nbsp;
								</Col>
							</Row>
							<Row style={{'padding':'1px'}}>
								
								<Col xs="2" style={{'display': 'flex' ,'alignItems': 'center','paddingLeft':'50px'}}>
									<Form.Check
			                            type="checkbox"
			                            label=""
			                            className="table-checkbox"
			                            id="custom-checker"
										onChange={(e) => setAttest(e.currentTarget.checked)}
										//style={{'padding-left': '7 em'}}
			                          />
								</Col>
								<Col xs="10">
									<Form.Label className="small">
										<small>I attest that I have complied with the minimum flood training requirements set by FEMA and/or the states in which I’m licensed to write flood, through an education provider approved by the state(s) in which I’m licensed to write this insurance.  I also attest that I can provide the required information and that I am the individual producer writing this flood policy.</small>
                                    </Form.Label>
								</Col>
							</Row>
                        </Col>
                        
                        
                    </Row>
                </Col>
				<Col xs="6" style={{ margin: "0px",padding: "0px" }}>
					<Row>
						<Col xs="12" >
							<span><b>Upload Training Certificate</b></span>
							
							{fileFromDb && fileFromDb.length > 0 ? 
								(<div id="drop-document-type-div">
		                            <aside className="reply-document-name">
			                            <ul>{fileFromDb} <FaTimes size={15} onClick={() => removeFileFromDb()} /></ul>
			                        </aside>
		                        </div>) : <>
								<div id="drop-document-type-div"
		                            {...getRootProps({ className: "dropzone" })}
		                        >
		                            <input type="file"
		                                autoComplete="off"
		                                className="inp-file"
		                                // onChange={uploadFile}
		                                {...getInputProps()}
		                            />
		                            <FaFileUpload size={34} color={"#64D6CD"} />
		                            <p>Drag and Drop File Here</p>
		                            <Button id="common-btn" variant="primary">
		                                <FaPlus size={15} />
		                                Add File
		                            </Button>{" "}
		                        </div>
								<aside className="reply-document-name">
                                    <ul>{files}</ul>
                                </aside>
								</>
							}
	                        
	                        
						</Col>
						
					</Row>
					<Row>
						<Col xs="12">
							<p>Please upload one of the following flood training documents:</p>
							<ul>
								<li>Flood Training Certificate of Completion OR</li>
								<li>Agent CE report issued by either a State Dept. of Insurance OR an accredited Flood Insurance Training provider (WebCe, RegEd, etc.)</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<Col xs="12" style={{ marginTop: "75px" }}>
                            <Table>
                                <tr>
                                    <th style={{ textAlign: "center" }}><Button id="common-btn" disabled={buttonLoading} variant="primary" onClick={(e) => handleSave(e)}>
                                    {buttonLoading ? (<Spinner
		                                as="span"
		                                variant="light"
		                                size="sm"
		                                role="status"
		                                aria-hidden="true"
		                                animation="border" />) : null}&nbsp;
		                                Submit
                                    </Button>{" "}</th>
                                </tr>
                            </Table>
                        </Col>
					</Row>
                </Col>
            </Row>
        </Modal.Body>
      </Modal>
    )
}

export default EditCELicense;