import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Table,
    Alert,
    
} from "react-bootstrap";
import { currencyFormat } from '../../services/commonService';
import { useTranslation } from "react-i18next";
import Moment from 'moment';

function ClaimSummary(props) {

    const [claim, setClaim] = useState({});
    const [insured, setInsured] = useState({});
    const [examiner , setExaminer] = useState('');
    const [adjuster, setAdjuster] = useState({});
    const [adjustingFirm, setAdjustingFirm] = useState({});
    const [tempCommunication, setTempCommunication] = useState({});
    const [wyo , setWyo] = useState('');
    const [claimProcess, setClaimProcess] = useState({});
    const [coverage, setCoverage] = useState({});
    const [paymentReserveBuilding, setPaymentReserveBuilding] = useState({});
    const [paymentReserveContent, setPaymentReserveContent] = useState({});
    const [additionaInfo, setAdditionalInfo] = useState({});
    const [reasonDropdowns , setReasonDropdowns] = useState({});
    const [tenancyNaicPrefix, setTenancyNaicPrefix] = useState("");
    const { t } = useTranslation();
    useEffect(() => {
        if(Object.keys(props.claimDetailObject).length > 0){
            setClaim(props.claimDetailObject.claimData);
            setInsured(props.claimDetailObject.policyData.insuredPerson_info);            
            setExaminer(props.claimDetailObject.examiner);
            setAdjuster(props.claimDetailObject.adjuster);
            setAdjustingFirm(props.claimDetailObject.adjustingFirm);
            setTempCommunication(props.claimDetailObject.communicationInfo);
            setWyo(props.claimDetailObject.wyo);
            setClaimProcess(props.claimDetailObject.claimProcess);
            setCoverage(props.claimDetailObject.policyData.coverageData);
            setPaymentReserveBuilding(props.claimDetailObject.paymentData.building?props.claimDetailObject.paymentData.building:{});
            setPaymentReserveContent(props.claimDetailObject.paymentData.content?props.claimDetailObject.paymentData.content:{});
            setAdditionalInfo(props.claimDetailObject.additionalInfo);
            setReasonDropdowns(props.claimDetailObject.reasonDropdowns);
            setTenancyNaicPrefix(props.claimDetailObject.tenancy.toUpperCase().charAt(0)+props.claimDetailObject.naic);
        }
    }, [props.claimDetailObject]);

    const newDateFormat = (date) => {
        return Moment(date).format('MM/DD/YYYY'); // Converting Date into MM/DD/YYYY
    }

    const addressFormat = (addressObject) => {
        if(addressObject !== undefined){
            let postalSuffix = addressObject.postal_code_suffix ? ' - ' + addressObject.postal_code_suffix : '';
            return ((addressObject.addressline1 ? addressObject.addressline1 : '') + (addressObject.cityName ? ", " + addressObject.cityName : "") + (addressObject.countyName ? ", " + addressObject.countyName : " ") + (addressObject.stateName ? ", " + addressObject.stateName : " ") + " " + (addressObject.postal_code ? addressObject.postal_code : '' ) + postalSuffix )
        } 
    }

    const locationAddressFormat= (addressObject) => {
        if(addressObject !== undefined){
            let postalSuffix = addressObject.s_PostalCodeSuffix ? ' - ' + addressObject.s_PostalCodeSuffix : '';
            return ((addressObject.s_StreetName ? addressObject.s_StreetName : '') + (addressObject.city_name ? ", " + addressObject.city_name : "") + (addressObject.county_name ? ", " + addressObject.county_name : " ") + (addressObject.state_name ? ", " + addressObject.state_name : " ") + " " + (addressObject.s_PostalCode ? addressObject.s_PostalCode : '' ) + postalSuffix )
        } 
    }

    const phoneFormat = (phonenumber) => {
        if(phonenumber !== undefined && phonenumber !== null && phonenumber !=''){
            return phonenumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
    }

    const showingDateField = (field, defalutvalue='', format="MM/DD/YYYY") => {
        if(field !== undefined && field != null && field != ''){
           return  Moment(field).format(format)
        }
        return defalutvalue;
    }

    const showingField = (field, defalutvalue='') => {
        if(field !== undefined && field !== null && field !=''){
            return field;
        }
        return defalutvalue;
    }

    const getNumberFormat = (number = 0) => {
        if(number !== undefined && number != null && number != ''){
           return "$"+parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return "$0.00";
     }

    const statusRemark   = showingField(additionaInfo?.claimLossCorrectionA, {});
    const buildingRemark = showingField(reasonDropdowns?.closeWithoutPaymentBuilding, []);
    const contentRemark  = showingField(reasonDropdowns?.closeWithoutPaymentContent, []);

    return (
        <div>
            <div className="billing-tab-row1-header mt-3">
            <span>Claim # {(Object.keys(claim).length > 0 && tenancyNaicPrefix+claim.Claim_No) }</span>
            </div>
            <div className="billing-tab-row1-body">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Policy #/Holder Name')}</th>
                                    <td>
                                        {
                                            (Object.keys(claim).length > 0 && claim.Risk_Id) + '/' + (Object.keys(insured).length > 0 && insured.s_FullLegalName)
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Adjusting Firm')}</th>
                                    <td>{
                                        Object.keys(adjustingFirm).length > 0 && adjustingFirm.s_FullLegalName
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('WYO')}</th>
                                    <td>{wyo}</td>
                                </tr>
                                <tr>
                                    <th>{t('Location Address')}</th>
                                    <td>{
                                        Object.keys(insured).length > 0 && locationAddressFormat(insured.addresses.Location)
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Mailing Address')}</th>
                                    <td>{                                      
                                        Object.keys(insured).length > 0 && locationAddressFormat(insured.addresses.Mailing)
                                    }</td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Date Of Loss')}</th>
                                    <td>{Object.keys(claim).length > 0 && newDateFormat(claim.Date_Of_Loss)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Adjuster/FCN')}</th>
                                    <td>{Object.keys(adjuster).length > 0 && adjuster.s_FullLegalName + '/' +  adjuster.fcn}</td>
                                </tr>
                                <tr>
                                    <th>{t('Examiner')}</th>
                                    <td>{examiner}</td>
                                </tr>
                                <tr>
                                    <th>{t('Temporary Address')}</th>
                                    <td>{
                                        Object.keys(tempCommunication).length > 0 && addressFormat(tempCommunication)
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Temporary Email')}</th>
                                    <td>
                                        { Object.keys(tempCommunication).length > 0 && tempCommunication.primary_email != "" ? 
                                        tempCommunication.primary_email : tempCommunication.secondary_email }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Temporary Phone')}</th>
                                    <td>{ Object.keys(tempCommunication).length > 0 && tempCommunication.primary_phone != "" ? 
                                    phoneFormat(tempCommunication.primary_phone) : phoneFormat(tempCommunication.secondary_phone)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="">
                    <Col md="12">
                        <fieldset>
                            <Alert className='mt-1' style={{background:"#64d6cd", padding:"4px"}}>
                                <span style={{fontWeight:"600", fontSize:"14px", lineHeight:"18px", color:"#ffffff"}}>
                                    Claim Process
                                </span>
                            </Alert>
                            <div className="container">
                                {Object.keys(claim).length > 0 && Object.keys(claimProcess).length > 0 ? 
                                <Row>
                                    <Col md="12" className="hh-grayBox">
                                        <Row className="justify-content-between">
                                            <div className="order-tracking completed" style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Opened<br/><span>{showingDateField(claim.Inserted_Date, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('Adjuster_Assigned') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Assigned<br/><span>{showingDateField(claimProcess.Adjuster_Assigned, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('Prelim_Report') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Pre Lim<br/><span>{showingDateField(claimProcess.Prelim_Report, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('Advance_Payment') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Adv Pay<br/><span>{showingDateField(claimProcess.Advance_Payment, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('Final_Report') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Final<br/><span>{showingDateField(claimProcess.Final_Report, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('POL_Report') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Pol<br/><span>{showingDateField(claimProcess.POL_Report, '', 'MMM DD')}</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('test') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Approved<br/><span>&nbsp;</span></p>
                                            </div>
                                            <div className={"order-tracking " + (claimProcess.hasOwnProperty('Close') ? 'completed' : 'uncompleted')} style={{width:"12%"}}>
                                                <span className="is-complete"></span>
                                                <p>Closed<br/><span>{showingDateField(claimProcess.Close, '', 'MMM DD')}</span></p>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row> : null }
                            </div>
                        </fieldset>
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col md="12">
                        <fieldset >
                            <Alert className='mt-1' style={{background:"#64d6cd", padding:"4px"}}>
                                <span style={{fontWeight:"600", fontSize:"14px", lineHeight:"18px", color:"#ffffff"}}>
                                Coverage
                                </span>
                            </Alert>
                            <Row className="m-1">
                                <Col md="1">
                                </Col>
                                <Col md="3" className="border-bottom px-1">
                                    <span >Limit/Deductible</span>
                                </Col>
                                <Col md="3" className="border-bottom px-1">
                                    <span >Reserve</span>
                                </Col>
                                <Col md="3" className="border-bottom px-1">
                                    <span >Payments</span>
                                </Col>
                                <Col md="2" className="border-bottom px-1">
                                    <span >Status</span>
                                </Col>
                            </Row>
                                { Object.keys(coverage).length > 0 && Object.keys(coverage.buildingCvg).length > 0 && coverage.buildingCvg.n_InsuredValue > 0 ?
                                    <Row className="m-1 mt-2">
                                        <Col md="1">
                                        <span >{showingField(coverage.buildingCvg.s_ScreenName)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                            <span >{getNumberFormat(coverage.buildingCvg.n_InsuredValue)}/{getNumberFormat(coverage.buildingCvg.s_PrDiscountCode ? 
                                                (coverage.buildingCvg.s_PrDiscountCode).slice(6) : 0)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                            <span >{getNumberFormat(Object.keys(paymentReserveBuilding).length > 0 ? paymentReserveBuilding.total_reserve : 0)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                            <span >{getNumberFormat(Object.keys(paymentReserveBuilding).length > 0 ? paymentReserveBuilding.total_payment : 0)}</span>
                                        </Col>
                                        <Col md="2" className="border-bottom px-1">
                                            <span >
                                                { (showingField(statusRemark.buildingCoverageStatus, '') != '') ? 
                                                (statusRemark.buildingCoverageStatus == "CWOP") ?
                                                 showingField(buildingRemark.find(item => item.s_AppCodeName === statusRemark.nonPaymentReasonBuilding).s_AppCodeNameForDisplay, '') :
                                                  statusRemark.buildingCoverageStatus : 'OPEN' }
                                            </span>
                                        </Col>
                                    </Row>
                                    : null 
                                }
                                { Object.keys(coverage).length > 0 && Object.keys(coverage.contentCvg).length > 0 && coverage.contentCvg.n_InsuredValue > 0 ?
                                    <Row className="m-1 mt-2">
                                        <Col md="1">
                                        <span >{showingField(coverage.contentCvg.s_ScreenName)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                        <span >{getNumberFormat(coverage.contentCvg.n_InsuredValue)}/{getNumberFormat(coverage.contentCvg.s_PrDiscountCode ? 
                                            (coverage.contentCvg.s_PrDiscountCode).slice(6) : 0)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                        <span >{getNumberFormat(Object.keys(paymentReserveContent).length > 0 ? paymentReserveContent.total_reserve : 0)}</span>
                                        </Col>
                                        <Col md="3" className="border-bottom px-1">
                                            <span >{getNumberFormat(Object.keys(paymentReserveContent).length > 0 ? paymentReserveContent.total_payment : 0)}</span>
                                        </Col>                                
                                        <Col md="2" className="border-bottom px-1">
                                            <span >
                                            { (showingField(statusRemark.contentCoverageStatus, '') != '') ? (statusRemark.contentCoverageStatus == "CWOP") ? 
                                            showingField(contentRemark.find(item => item.s_AppCodeName === statusRemark.nonPaymentReasonContents).s_AppCodeNameForDisplay, '') : 
                                            statusRemark.contentCoverageStatus : 'OPEN' }
                                            </span>
                                        </Col>
                                    </Row>
                                    : null 
                                }
                        </fieldset>
                    </Col>
                </Row>                           

            </div>
        </div>
        
    )
}

const mapStateToProps = (state) => {
    return {
        claimDetailObject : state.coreSystem.coreClaimData
    };
};
export default connect(mapStateToProps)(ClaimSummary);
