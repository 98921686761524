import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import PropertyOtherDetails from "./components/PropertyOtherDetails";
import QuoteActions from "./components/QuoteActions";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { getHiscoxQuoteDetail } from "../../api/getHiscoxQuoteDetail";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./QuoteApplication.css";
import { Premium } from "./components/Premium/Premium";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import ApplicantInfoUpdateModal from "./components/ApplicantInfoUpdateModal";
import { AdditionalDetails } from "./components/AdditionalDetails";
import {
  Card,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Button,
  Box,
} from "@chakra-ui/react";
import AgentAssignment from "./components/AgentAssignment";

export const QuoteApplication = () => {
  const { t } = useTranslation();
  const [quoteDetailsData, setQuoteDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  const { quoteNumber } = useParams();
  const [bindDetails, setBindDetails] = useState({
    productType: "Residential", //default passed 1 argue passed so some condition may no break if they found no value
    commercialProductType: "Owned",
    mailingAddress: quoteDetailsData?.data?.mailingAddress,
    mailingAddressAddressLine2:
      quoteDetailsData?.data?.mailingAddressAddressLine2,
    mailingAddressZipCode: quoteDetailsData?.data?.mailingAddressZipCode,
    mailingAddressCounty: quoteDetailsData?.data?.mailingAddressCounty,
    mailingAddressState: quoteDetailsData?.data?.mailingAddressState,
    mailingAddressCity: quoteDetailsData?.data?.mailingAddressCity,
    isMailingAddressSame: true,
  });
  const [messages, setMessages] = useState([]); //to store api errors, warnings any notices
  const [fetchOnce, setFetchOnce] = useState(false);
  const [edit, setEdit] = useState(false);
  const [updateQuote, setUpdateQuote] = useState(false);
  const [productId, setProductId] = useState(0);
  const [saveMailingAddress, setSaveMailingAddress] = useState(false);
  const [personDetail, setPersonDetail] = useState({
    firstName: "N/A",
    lastName: "N/A",
  });
  const [saveMortgagee, setSaveMortgagee] = useState(false);
  const [saveAdditionalInsured, setSaveAdditionalInsured] = useState(false);
  const [showUpdatePremiumModal, setShowUpdatePremiumModal] = useState(false);
  const [refreshQuoteApplication, setRefreshQuoteApplication] = useState(false);
  const [showApplicantModal, setShowApplicantModal] = useState(false);
  const [saveApplicantInfo, setSaveApplicantInfo] = useState(false);

  const { selectedPremium } = quoteDetailsData?.data?.premium ?? {};
  const displayQuote = async () => {
    setLoading(true);
    try {
      const quoteData = await getHiscoxQuoteDetail({
        quoteNo: quoteNumber || 0,
      });
      setProductId(quoteData?.data?.productId);
      if (
        !quoteData ||
        (quoteData.status === false && Array.isArray(quoteData.errors))
      ) {
        if (!quoteData) {
          throw new Error("Failed to fetch quote.");
        } else {
          const errorMessage = Array.isArray(quoteData.errors)
            ? quoteData.errors.join("\n")
            : "Unknown error occurred.";
          throw new Error(errorMessage);
        }
      }
      if (quoteData.data && Object.keys(quoteData.data).length > 0) {
        setQuoteDetailsData(quoteData);
        // toast.success("Quote retrieved");
        setFetchOnce(true);
      } else {
        throw new Error("No quote data found.");
      }
    } catch (err) {
      if (err.response) {
        toast.error(
          `Fetch failed (Error ${err.response.status}): ${err.response.statusText}`
        );
      } else if (err.request) {
        toast.error(
          "Could not reach the server. Please check your connection."
        );
      } else {
        toast.error(
          err.message ||
            "An unexpected error occurred while fetching the quote."
        );
      }
    } finally {
      setLoading(false);
      setRefreshQuoteApplication(false);
    }
  };

  useEffect(() => {
    if ((quoteNumber && !fetchOnce) || refreshQuoteApplication) {
      displayQuote();
    }
  }, [quoteNumber, fetchOnce, refreshQuoteApplication]);

  //this is just to display address
  //NOTE COUNTY & COUNTRY ARE TWO DIFFERENT FIELDS
  const {
    streetName = "Street Name",
    county = "County",
    stateCode = "State Code",
    // country = "Country",
    zip = "Zip Code",
    city,
    mailingAddress,
    mailingAddressAddressLine2,
    mailingAddressZipCode,
    mailingAddressCounty,
    mailingAddressState,
    mailingAddressCity,
  } = quoteDetailsData?.data ?? {};
  const capitalizeCity =
    city?.charAt(0).toUpperCase() + city?.slice(1).toLowerCase() || "Capital";
  const fullAddress =
    `${streetName}, ${county}, ${capitalizeCity}, ${stateCode}, ${zip}` || "";
  const fullMailingAddress =
    mailingAddress &&
    mailingAddressCounty &&
    mailingAddressState &&
    mailingAddressCity &&
    mailingAddressZipCode
      ? `${mailingAddress} ${mailingAddressAddressLine2}, ${mailingAddressCounty}, ${mailingAddressCity}, ${mailingAddressState}, ${mailingAddressZipCode}`
      : "SAME AS ADDRESS";

  useEffect(() => {
    if (quoteDetailsData?.data) {
      setPersonDetail({
        firstName: quoteDetailsData?.data?.firstName || "",
        lastName: quoteDetailsData?.data?.lastName || "",
      });
    }
    const checkErrorsAndWarnings = () => {
      if (
        quoteDetailsData &&
        quoteDetailsData?.get_quote_response &&
        quoteDetailsData?.get_quote_response?.data
      ) {
        const { errors, warnings } =
          quoteDetailsData?.get_quote_response?.data.property?.metadata?.premium
            .primary[0] ?? {};
        const combinedMessages = [...(errors ?? []), ...(warnings ?? [])] ?? [];
        setMessages(combinedMessages ?? []);
      }
    };

    checkErrorsAndWarnings();
  }, [quoteDetailsData]);

  const editApplicantInfo = () => {
    setShowApplicantModal(true);
  };

  const checkDateisValid = (date) => {
    return !isNaN(Date.parse(date));
  };
  return (
    <LoadingOverlay>
      <Loader loading={loading} text="Loading..." />
      <div>
        <QuoteActions
          bindDetails={bindDetails}
          setLoading={setLoading}
          quoteNumber={quoteNumber}
          quoteDetailsData={quoteDetailsData}
          edit={edit}
          setEdit={setEdit}
          updateQuote={updateQuote}
          setUpdateQuote={setUpdateQuote}
          saveMortgagee={saveMortgagee}
          setSaveMortgagee={setSaveMortgagee}
          saveAdditionalInsured={saveAdditionalInsured}
          setSaveAdditionalInsured={setSaveAdditionalInsured}
          messages={messages}
          loading={loading}
          setShowUpdatePremiumModal={setShowUpdatePremiumModal}
          showUpdatePremiumModal={showUpdatePremiumModal}
          setRefreshQuoteApplication={setRefreshQuoteApplication}
          setSaveMailingAddress={setSaveMailingAddress}
          saveMailingAddress={saveMailingAddress}
          saveApplicantInfo={saveApplicantInfo}
          setSaveApplicantInfo={setSaveApplicantInfo}
          displayQuote={displayQuote}
        />

        <Grid
          templateColumns={"30% 66%"}
          gap={4}
          columnGap={10}
          bgColor={"white"}
          p={6}
          pr={5}
          pl={8}
          mb={4}
          pb={8}
          boxShadow={"lg"}
          borderRadius={"2xl"}
        >
          <GridItem>
            <FormControl>
              <FormLabel  >{t("Quote No")}: </FormLabel>
              <Input
                className="inputField"
                type="text"
                bgColor={"neutral.50"}
                _disabled={{ color: "black", opacity: 0.8 }}
                size={"lg"}
                value={bindDetails?.quoteId}
                border={"1px solid gray"}
                disabled
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel >{t("Address")}: </FormLabel>
              <Input
                className="inputField"
                type="text"
                bgColor={"neutral.50"}
                _disabled={{ color: "black", opacity: 0.8 }}
                size={"lg"}
                value={fullAddress}
                border={"1px solid gray"}
                disabled
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel >{t("Applicant")}: &nbsp;</FormLabel>
              <HStack>
                <Input
                  className="inputField"
                  bgColor={"neutral.50"}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  size={"lg"}
                  type="text"
                  value={`${personDetail?.firstName} ${
                    personDetail?.lastName ? `${personDetail?.lastName}` : ""
                  }`}
                  disabled
                  border={"1px solid gray"}
                />
                {!checkDateisValid(quoteDetailsData?.data?.quoteBindDate) && (
                  <div
                    style={{
                      display: "inline",
                      marginLeft: "6px",
                      marginTop: "2px",
                    }}
                  >
                    <FaEdit size={"20px"} onClick={editApplicantInfo} />
                  </div>
                )}
              </HStack>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel >{t("Mailing Address")}: </FormLabel>
              <Input
                className="inputField"
                bgColor={"neutral.50"}
                _disabled={{ color: "black", opacity: 0.8 }}
                size={"lg"}
                type="text"
                border={"1px solid gray"}
                value={
                  quoteDetailsData?.data?.isMailingAddressSame === true
                    ? "Same as Address"
                    : fullMailingAddress
                }
                disabled
              />
            </FormControl>
          </GridItem>
        </Grid>

        <Stack className="custom-radius pb-1">
          {selectedPremium && (
            <>
              <Premium selectedPremium={selectedPremium} />
            </>
          )}
        </Stack>

        <Card p={2} boxShadow={"lg"} borderRadius={"2xl"} mb={4}>
          <AgentAssignment
            quoteDetailsData={quoteDetailsData}
            edit={edit}
            setBindDetails={setBindDetails}
          />
        </Card>

        <Card p={2} boxShadow={"lg"} borderRadius={"2xl"}>
          <PropertyOtherDetails
            edit={edit}
            quoteDetailsData={quoteDetailsData}
            bindDetails={bindDetails}
            setBindDetails={setBindDetails}
            setShowUpdatePremiumModal={setShowUpdatePremiumModal}
            setSaveMortgagee={setSaveMortgagee}
            productId={productId}
            setSaveAdditionalInsured={setSaveAdditionalInsured}
            setLoading={setLoading}
            loading={loading}
            setSaveMailingAddress={setSaveMailingAddress}
            updateQuote={updateQuote}
          />
        </Card>
        <div className="custom-radius pt-1" />

        <AdditionalDetails
          quoteDetailsData={quoteDetailsData}
          displayQuote={displayQuote}
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={"2rem"}
          paddingTop={"2rem"}
          paddingBottom={"2rem"}
          textAlign={"center"}
          mt={"20px"}
        >
          {edit && (
            <>
              <Button
                bg={"primary"}
                color={"white"}
                style={{ padding: "0.7rem" }}
                id="primary-colored-btn"
                onClick={() => {
                  setEdit(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                bg={"primary"}
                color={"white"}
                style={{ padding: "0.7rem" }}
                id="primary-colored-btn"
                onClick={() => {
                  setUpdateQuote(true);
                }}
              >
                {t("Save & Update")}
              </Button>
            </>
          )}
        </Box>
        <ApplicantInfoUpdateModal
          personDetails={quoteDetailsData?.get_quote_response?.data?.person}
          showApplicantModal={showApplicantModal}
          setShowApplicantModal={setShowApplicantModal}
          setSaveApplicantInfo={setSaveApplicantInfo}
          setBindDetails={setBindDetails}
        />
      </div>
    </LoadingOverlay>
  );
};

export default QuoteApplication;
