import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GoogleMap from "../Insta-Property/google-map/GoogleMap";
import table from "../../../../assets/address/confirm-address-table.svg";
import tree from "../../../../assets/address/confirm-address-tree.svg";
import "./InstaProperty.scss";
import { connect } from "react-redux";

const InstaProperty = (props) => {
    // const [validated, setValidated] = useState(false);
    const { t } = useTranslation();
    const navigation = useNavigate();
    const [validated, setValidated] = useState(false); 
    const handleOnclick = (step) => {
        //validation
        var validateStatus = false;
        if(props.n_AreaSqtft == "" || props.n_YearBuilt == "" || props.n_NoOfStories  == "" || props.s_ConstructionTypeSelected  == "" || props.s_UsageSelected == ''){
            validateStatus = true;
        }
        setValidated(validateStatus);
        if(validateStatus == false){
            navigation('/instaQuote/InstaSteps/' + step)
        }
    }

    useEffect(() => {
       
      }, [validated]);
    const onRoofChange = (evt) => {
        const data = {
            target: {
                name: "s_RoofType",
                value: evt,
                title: "propertyInfo",
            },
        };
        return data;
    };

    const getSpecificClass = (x) => {
        const value = x;
        if (value === "Architectural") {
            return "roof-dropdown-img1";
        } else if (value === "Other") {
            return "roof-dropdown-img2";
        } else if (value === "Metal Roof") {
            return "roof-dropdown-img3";
        } else if (value === "Tile") {
            return "roof-dropdown-img4";
        } else {
            return "roof-dropdown-img5";
        }
    };

    const getRoofValue = (value) => {
        if (value === "ARCHITECTURAL") {
            return "Architectural";
        } else if (value === "OTHER") {
            return "Other";
        } else if (value === "ROOFMETAL") {
            return "Metal Roof";
        } else if (value === "TILE") {
            return "Tile";
        } else {
            return "3-Tab";
        }
    };

    return(
        <section className="property-details-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
                    <h1 style={{ paddingLeft: "42px" }}>
                        {t('Please Check Property Details')}
                    </h1>
                    <Row className="justify-content-start">
                        <Col xs="12">
                            <Form  validated={validated} className="confirm-address-form">
                                <Row>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                        <Form.Group className="confirm-address-field" controlId="formBasicEmail">
                                            <Form.Label className="confirm-address-label">
                                                {t('Square footage')}
                                            </Form.Label>
                                            <Form.Control
                                                className="confirm-address-input"
                                                type="text"
                                                placeholder=""
                                                name='n_AreaSqtft'
                                                title="propertyInfo"
                                                value={props.n_AreaSqtft}
                                                onChange={props.handleChange}
                                                // parentobject="propertyInfo"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                        <Form.Group controlId="formBasicPassword" className="confirm-address-field">
                                            <Form.Label className="confirm-address-label">
                                                {t('Year built')}
                                            </Form.Label>
                                            <Form.Control
                                                className="confirm-address-input"
                                                type="text"
                                                placeholder=""
                                                name='n_YearBuilt'
                                                title="propertyInfo"
                                                value={props.n_YearBuilt}
                                                onChange={props.handleChange}
                                                // parentobject="propertyInfo"
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                        <Form.Group className="confirm-address-field" controlId="formBasicPassword">
                                            <Form.Label className="confirm-address-label">
                                                {t('Number of stories')}
                                            </Form.Label>
                                            <Form.Control
                                                className="confirm-address-input"
                                                type="number"
                                                placeholder=""
                                                name='n_NoOfStories'
                                                title="propertyInfo"
                                                parentobject="propertyInfo"
                                                value={props.n_NoOfStories}
                                                onChange={props.handleChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                        <Form.Group controlId="formBasicPassword" className="confirm-address-field">
                                            <Form.Label className="confirm-address-label">
                                                {t('Construction type')}
                                            </Form.Label>
                                            <Form.Select
                                                className="confirm-address-input"
                                                // type="text"
                                                placeholder=""
                                                name='s_ConstructionType'
                                                title="propertyInfo"
                                                value={props.s_ConstructionTypeSelected}
                                                onChange={props.handleChange}
                                                required
                                            >
                                                <option value="">{t('Select')}</option>
                                                {props.s_ConstructionType.map((x) => (
                                                    <option value={x.s_AppCodeName}>
                                                        {x.s_AppCodeNameForDisplay}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4">
                                        <Form.Group controlId="formBasicPassword" className="confirm-address-field">
                                            <Form.Label className="confirm-address-label">
                                                {t('Usage')}
                                            </Form.Label>
                                            <Form.Select
                                                className="confirm-address-input"
                                                placeholder=""
                                                name='s_UsageTypeCode'
                                                title="propertyInfo"
                                                value={props.s_UsageSelected}
                                                onChange={props.handleChange}
                                                required
                                            >
                                                <option value=''>{t('Select')}</option>
                                                {props.s_Usage.map((x) => (
                                                    <option value={x.s_AppCodeName}>
                                                        {x.s_AppCodeNameForDisplay}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="6" md="6" lg="4" xl="4" id="root-type-input">
                                        <Form.Group className="confirm-address-field">
                                            <Form.Label className="confirm-address-label">
                                                {t("Roof type")}
                                            </Form.Label>
                                            <Dropdown
                                                onSelect={(evt) => 
                                                    props.handleChange(onRoofChange(evt))
                                                }
                                            >
                                                <Dropdown.Toggle
                                                    variant="primary"
                                                    className="root-type-dropdown"
                                                    id="dropdown-basic"
                                                >
                                                    <div
                                                        id="root-drop-title"
                                                        className={getSpecificClass(
                                                            getRoofValue(props.s_RoofTypeSelected)
                                                        )}
                                                    ></div>
                                                    <span style={{ marginLeft: "3rem" }}>
                                                        {getRoofValue(props.s_RoofTypeSelected)}
                                                    </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {props.s_RoofType.map((x) => (
                                                        <Dropdown.Item
                                                            href="#/action-1"
                                                            id="root-type-dropdown-item"
                                                            eventKey={x.s_AppCodeName}
                                                        >
                                                            <div
                                                                className={getSpecificClass(
                                                                    x.s_AppCodeNameForDisplay
                                                                )}
                                                            ></div>
                                                            <span>{x.s_AppCodeNameForDisplay}</span>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12">
                                        <div className="form-map">
                                        <GoogleMap />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <div className="flood-bts-div">
                            <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                onClick={() => handleOnclick('address')}
                            >
                                {t('Previous')}
                            </Button>
                            <Button
                                id="primary-colored-btn"
                                variant="primary"
                                onClick={() => handleOnclick('2')}
                            >
                                {t('Next')}
                            </Button>
                        </div>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},

        n_AreaSqtft: state.common.emptyInstantQuoteData.propertyInfo.n_AreaSqtft,
        n_YearBuilt : state.common.emptyInstantQuoteData.propertyInfo.n_YearBuilt,
        n_NoOfStories: state.common.emptyInstantQuoteData.propertyInfo.n_NoOfStories,
        
        s_ConstructionType: state.common.getSystemDDInstant.CONSTRTYPE,
        s_Usage: state.common.getSystemDDInstant.HOUSAGETYPE,
        s_RoofType: state.common.getSystemDDInstant.ROOFTYPE,

        s_ConstructionTypeSelected: state.common.emptyInstantQuoteData.propertyInfo.s_ConstructionType,
        s_UsageSelected: state.common.emptyInstantQuoteData.propertyInfo.s_UsageTypeCode,
        s_RoofTypeSelected: state.common.emptyInstantQuoteData.propertyInfo.s_RoofType,
    };
}

export default connect(mapStateToProps)(InstaProperty)