import {
  Grid,
  GridItem,
  Card,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../style.css";

export const Premium = ({ selectedPremium }) => {
  const { t } = useTranslation();
  const [premiumData, setPremiumData] = useState(selectedPremium);

  useEffect(() => {
    if (selectedPremium) {
      setPremiumData(selectedPremium);
    } else {
      console.error("Selected premium is undefined or null.");
      setPremiumData({});
    }
  }, [selectedPremium]);

  const formatKey = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const boolToYesNo = (value) => {
    return value ? "Yes" : "No";
  };
  const labels = {
    type: t("Type of Deductible"),
    deductible: t("Deductible"),
    includeLossOfUse: t("Loss of Use Included"),
    includeContents: t("Contents Included"),
    includeBusinessIncomeAndExtraExpense: t("Business Income & Extra Expense?"),
    chargedPremium: t("Premium"),
    buildingLimit: t("Building Limit"),
    contentsLimit: t("Contents Limit"),
    improvement_betterment_coverage: t("Improvements & Betterments Limit"),
  };

  const orderedKeys = [
    "type",
    "deductible",
    "chargedPremium",
    ...(premiumData?.improvement_betterment_coverage ? [] : ["buildingLimit"]),
    "contentsLimit",
    "improvement_betterment_coverage",
    "includeLossOfUse",
    "includeContents",
    "includeBusinessIncomeAndExtraExpense",
  ];

  return (
    <Card boxShadow={"lg"} borderRadius={"2xl"} p={2}>
      <Grid templateColumns={"repeat(4, 1fr)"} gap={6} p={3} rowGap={3}>
        {orderedKeys.map((key) => {
          let value = premiumData?.[key];
          if (
            (key === "contentsLimit" || key === "buildingLimit") &&
            value !== 0
          ) {
            value = value?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            });
          } else if (
            (key === "contentsLimit" && value === 0) ||
            (key === "buildingLimit" && value === 0)
          ) {
            value = "$0";
          } else if (
            value === false &&
            (key === "includeLossOfUse" ||
              key === "includeContents" ||
              key === "includeBusinessIncomeAndExtraExpense")
          ) {
            value = boolToYesNo(value);
          } else if (!value) {
            return null;
          }

          return (
            <GridItem key={key} style={{ padding: "10px" }}>
              <FormControl>
                <FormLabel> {formatKey(labels[key])}: </FormLabel>
                <Input
                  className="inputField"
                  type="text"
                  bgColor={"neutral.50"}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  size={"lg"}
                  value={
                    key === "deductible" ||
                    key === "chargedPremium" ||
                    key === "improvement_betterment_coverage"
                      ? value.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : typeof value === "boolean"
                      ? boolToYesNo(value)
                      : value
                  }
                  border={"1px solid gray"}
                  disabled
                />
              </FormControl>
            </GridItem>
          );
        })}
      </Grid>
    </Card>
  );
};
