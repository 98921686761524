import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CardPos from "../../../../../assets/star/icons/card-pose.svg";
import Card from "../../../../../assets/star/icons/card.svg";
import CardReceive from "../../../../../assets/star/icons/card-receive.svg";
import SaveHome from "../../../../../assets/star/icons/save-home.svg";
import { formateDate } from "../../../../common/FormatDate";
import { Currency } from "../../../../common/Currency";
import moment from "moment";

export const PaymentSection = ({
  location,
  starDataObject,
  underWriting,
  policy,
  feesData,
}) => {
  const { t } = useTranslation();
  const [expirationDate, setExpirationDate] = useState("");
  const [coveragesFromFp, setCoveragesFromFp] = useState({});

  useEffect(() => {
    function isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    }
    try {
      if (policy?.effectiveDate) {
        const date = moment(policy.effectiveDate, "MM/DD/YYYY");
        const newDate = date.add(1, "year"); // Add 1 year

        setExpirationDate(newDate.format("MM/DD/YYYY"));
      } else {
        console.error("Policy effective date is missing or invalid.");
      }
    } catch (error) {
      console.error("An error occurred while updating expiration date:", error);
    }
  }, [policy?.effectiveDate]);

  useEffect(() => {
    if (starDataObject?.data?.actual_quote_details?.policy_details?.limits) {
      setCoveragesFromFp(
        starDataObject?.data?.actual_quote_details?.policy_details?.limits
      );
    }
  }, [starDataObject]);

  return (
    <Box
      border="1px solid var(--chakra-colors-neutral-200)"
      p="3"
      borderRadius="10px"
    >
      <Flex>
        <HStack>
          <Image
            src={SaveHome}
            width={70}
            height={70}
            alt=""
            style={{
              marginTop: "10px",
            }}
          />
          <Flex
            flexDir={"column"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            mt={2.5}
          >
            <Text
              textAlign={"left"}
              fontSize={"24px"}
              mb={"10px"}
              fontWeight="var(--chakra-fontWeights-medium)"
            >
              {`${underWriting?.propertyDetails?.firstName || ""} ${
                underWriting?.propertyDetails?.lastName || ""
              }`}
            </Text>
            <Text
              fontSize={"16px"}
              color="var(--chakra-colors-neutral-400)"
              lineHeight="0px"
            >
              {location?.autocomplete || ""}
            </Text>
          </Flex>
        </HStack>
        <Spacer />
        <VStack mt={3} gap={0}>
          <Text
            fontSize={"24px"}
            mb={"10px"}
            fontWeight="var(--chakra-fontWeights-medium)"
          >
            {t("coverage_effective")}
          </Text>
          <Text
            fontSize={"16px"}
            color="var(--chakra-colors-neutral-400)"
            lineHeight="0px"
          >
            {formateDate(policy?.effectiveDate)} - {formateDate(expirationDate)}
          </Text>
        </VStack>
      </Flex>
      <Box my={8} borderTop={"1px solid"} borderColor={"gray.200"} />
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
        p="25px 10px 25px 10px"
      >
        <GridItem>
          <TableContainer
            mt={"17px"}
            rounded="xl"
            borderRadius={"xl"}
            // h={"96.5%"}
          >
            <Table variant="none" h={"100%"}>
              <Thead bg={"primary.50"}>
                <Tr className="trClass">
                  <Text
                    w={"50%"}
                    py={4}
                    fontSize={"xl"}
                    pl={6}
                    as={"th"}
                    textDecor={"none"}
                  >
                    {t("Basic Coverages")}
                  </Text>
                  <Text
                    py={4}
                    fontSize={"xl"}
                    pr={6}
                    as={"th"}
                    textAlign="right"
                  >
                    {t("Limits")}
                  </Text>
                </Tr>
              </Thead>

              <Tbody>
                <Tr className="trClass">
                  <Td className="trClass">{t("cost_to_rebuild_home")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_a} />}
                  </Td>
                </Tr>
                <Tr className="trClass">
                  <Td className="trClass">{t("other_structures")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_b} />}
                  </Td>
                </Tr>
                <Tr className="trClass">
                  <Td className="trClass">{t("contents")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_c} />}
                  </Td>
                </Tr>
                <Tr className="trClass">
                  <Td className="trClass">{t("Loss of Use")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_d} />}
                  </Td>
                </Tr>
                <Tr className="trClass">
                  <Td className="trClass">{t("personal_liability")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_e} />}
                  </Td>
                </Tr>
                <Tr className="trClass">
                  <Td className="trClass">{t("medical_payments_to_others")}</Td>
                  <Td className="trClass" textAlign="right" fontWeight={500}>
                    {<Currency amount={coveragesFromFp?.coverage_f} />}
                  </Td>
                </Tr>
                {/* <Tr my={0}>
                  <Td className="trClass" border={"none"}></Td>
                  <Td className="trClass" border={"none"}></Td>
                </Tr>
                <Tr my={0}>
                  <Td className="trClass" border={"none"}></Td>
                  <Td className="trClass" border={"none"}></Td>
                </Tr> */}
                {/* <Tr className="trClass">
                    <Td className="trClass"></Td>
                    <Td className="trClass"></Td>
                  </Tr> */}
              </Tbody>
              <Tbody
                background="var(--chakra-colors-primary-500)"
                overflow="hidden"
              >
                <Tr justifyContent={"space-between"} my={3}>
                  <Td
                    py={4}
                    pl={6}
                    color="var(--chakra-colors-white)"
                    fontSize="xl"
                    fontWeight="600"
                  >
                    {t("total_premium")}
                  </Td>
                  <Td
                    py={4}
                    pr={6}
                    textAlign={"end"}
                    color={"white"}
                    fontSize={"xl"}
                    letterSpacing={"1px"}
                    fontWeight={600}
                  >
                    <Text my={"0.15rem"} fontSize={"xl"} textAlign={"center"}>
                      <Currency
                        amount={
                          starDataObject?.data?.quote_premiums?.original_model
                            ?.total_premium ||
                          starDataObject?.data?.quote_premiums?.original_model
                            ?.final_premium ||
                          0
                        }
                      />
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>

          <TableContainer mt={"17px"} rounded="xl" borderRadius={"xl"}>
            <Table variant="none" h={"100%"}>
              <Thead bg={"primary.50"}>
                <Tr className="trClass">
                  <Text
                    w={"50%"}
                    py={4}
                    fontSize={"xl"}
                    pl={6}
                    as={"th"}
                    textDecor={"none"}
                  >
                    {t("Fees")}
                  </Text>
                  <Text
                    py={4}
                    fontSize={"xl"}
                    pr={6}
                    as={"th"}
                    textAlign="right"
                  >
                    {t("Amount")}
                  </Text>
                </Tr>
              </Thead>

              <Tbody>
                {Object.entries(feesData?.fees || {}).map(
                  ([feeName, feeAmount]) => (
                    <Tr key={feeName} className="trClass">
                      <Td className="trClass">{feeName}</Td>
                      <Td
                        className="trClass"
                        textAlign="right"
                        fontWeight={500}
                      >
                        <Currency amount={feeAmount} />
                      </Td>
                    </Tr>
                  )
                )}
              </Tbody>
              <Tbody
                background="var(--chakra-colors-primary-500)"
                overflow="hidden"
              >
                <Tr justifyContent={"space-between"} my={3}>
                  <Td
                    py={4}
                    pl={6}
                    color="var(--chakra-colors-white)"
                    fontSize="xl"
                    fontWeight="600"
                  >
                    {t("Total Fees")}
                  </Td>
                  <Td
                    py={4}
                    pr={6}
                    textAlign={"end"}
                    color={"white"}
                    fontSize={"xl"}
                    letterSpacing={"1px"}
                    fontWeight={600}
                  >
                    <Text my={"0.15rem"} fontSize={"xl"} textAlign={"center"}>
                      <Currency amount={feesData && feesData?.totalFee} />
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
        <GridItem>
          <Box
            background="var(--chakra-colors-primary-50)"
            p="190px 40px 190px 40px"
            borderRadius="2xl"
            mt="15px"
            height={"98%"}
          >
            {/* <Center>
              <VStack>
                <Text fontSize="24px" mb="10px">
                  {t("please_add_a_payment_method")}
                </Text>
                <HStack>
                  <Button
                    borderRadius="12px"
                    fontWeight="var(--chakra-fontWeights-medium)"
                    size="lg"
                    color="var(--chakra-colors-black)"
                    background="var(--chakra-colors-warning)"
                    _hover={{
                      color: "var(--chakra-colors-black)",
                      background: "var(--chakra-colors-warning)",
                    }}
                  >
                    <Image
                      style={{ marginRight: "6px" }}
                      src={Card}
                      width={18}
                      height={18}
                      alt=""
                    />
                    {t("mortgagee")}
                  </Button>
                  <Button
                    borderRadius="12px"
                    fontWeight="var(--chakra-fontWeights-medium)"
                    color="var(--chakra-colors-black)"
                    background="var(--chakra-colors-white)"
                    border="1px solid var(--chakra-colors-neutral-100)"
                    _hover={{
                      color: "var(--chakra-colors-black)",
                      background: "var(--chakra-colors-white)",
                    }}
                    size="lg"
                  >
                    <Image
                      style={{ marginRight: "6px" }}
                      src={CardPos}
                      width={18}
                      height={18}
                      alt=""
                    />
                    {t("e_check")}
                  </Button>
                  <Button
                    borderRadius="12px"
                    fontWeight="var(--chakra-fontWeights-medium)"
                    size="lg"
                  >
                    <Image
                      style={{ marginRight: "6px" }}
                      src={CardReceive}
                      width={18}
                      height={18}
                      alt=""
                    />
                    {t("check")}
                  </Button>
                </HStack>
              </VStack>
            </Center> */}
          </Box>
        </GridItem>
      </Grid>
      <style>
        {`
          .trClass {
           border: 1px solid var(--chakra-colors-primary-50);
          }
        `}
      </style>
    </Box>
  );
};
