import React, { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { CreateQuoteContext, useMyContext } from "../CreateQuote";
import { useTranslation } from "react-i18next";
import MaskedFormControl from "react-bootstrap-maskedinput";
import {
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  GridItem,
  Text,
} from "@chakra-ui/react";
import "./style.css";
export default function ApplicantInformation() {
  const { t } = useTranslation();
  const { applicantDetails, setApplicantDetails, isNextClicked } =
    useMyContext(CreateQuoteContext);
  const fullName = `${applicantDetails?.firstName} ${applicantDetails?.lastName}`;
  useEffect(() => {
    setApplicantDetails({
      ...applicantDetails,
      organisationName: fullName,
    });
  }, []);
  return (
    <Form>
      <Grid templateColumns={"repeat(2, 1fr)"} gap={6} p={6}>
        <FormControl>
          <FormLabel className="dashboard-group-label">
            {t("Select a Property Type")}:
          </FormLabel>
          <div>
            <Flex gap="1rem">
              <Form.Check
                type="radio"
                id="individual"
                name="propertyType"
                value="individual"
                checked={applicantDetails?.propertyType === "individual"}
                onChange={(e) =>
                  setApplicantDetails({
                    ...applicantDetails,
                    propertyType: e.target.value,
                  })
                }
                label="Individual"
                required
              />
              <Form.Check
                type="radio"
                id="organisation"
                name="propertyType"
                value="organisation"
                checked={applicantDetails?.propertyType === "organisation"}
                onChange={(e) =>
                  setApplicantDetails({
                    ...applicantDetails,
                    propertyType: e.target.value,
                  })
                }
                label="Organisation"
                required
              />
            </Flex>
          </div>
        </FormControl>
        <GridItem></GridItem>
        {applicantDetails?.propertyType == "individual" ? (
          <>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("First Name")}:</FormLabel>
                <Input
                  className="inputField"
                  name="firstName"
                  value={applicantDetails?.firstName}
                  onChange={(e) =>
                    setApplicantDetails({
                      ...applicantDetails,
                      firstName: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="First Name"
                  required
                />
                {isNextClicked &&
                  (applicantDetails?.firstName == null ||
                    applicantDetails?.firstName == "") && (
                    <Text color={"red"}>{t("First Name is required")}</Text>
                  )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("Last Name")}:</FormLabel>
                <Input
                  className="inputField"
                  name="lastName"
                  value={applicantDetails?.lastName}
                  onChange={(e) =>
                    setApplicantDetails({
                      ...applicantDetails,
                      lastName: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Last Name"
                  required
                />
                {isNextClicked &&
                  (applicantDetails?.lastName == null ||
                    applicantDetails?.lastName == "") && (
                    <Text color={"red"}>{t("Last Name is required")}</Text>
                  )}
              </FormControl>
            </GridItem>
          </>
        ) : (
          <>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("Organization Name")}:</FormLabel>
                <Input
                  className="inputField"
                  value={applicantDetails?.organisationName}
                  onChange={(e) =>
                    setApplicantDetails({
                      ...applicantDetails,
                      organisationName: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Enter Organization Name"
                  required
                />
                {isNextClicked &&
                  !/[a-zA-Z]/.test(applicantDetails?.organisationName) && (
                    <Text
                      className="label-asterisk"
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      {t("Organization Name is required")}
                    </Text>
                  )}
              </FormControl>
            </GridItem>
          </>
        )}

        <GridItem>
          <FormControl>
            <FormLabel>{t("Phone Number")}:</FormLabel>
            <MaskedFormControl
              className="inputField"
              style={{ border: "1px solid black", height: "34px" }}
              name="contactNo"
              mask="(111)111-1111"
              value={applicantDetails?.contactNo}
              onChange={(e) =>
                setApplicantDetails({
                  ...applicantDetails,
                  contactNo: e.target.value,
                })
              }
              type="text"
              placeholder="Enter Phone Number"
            />
            {/* {isNextClicked &&
              (applicantDetails?.contactNo == null ||
                applicantDetails?.contactNo == "") && (
                <Text
                  className="label-asterisk"
                  style={{ color: "red", fontSize: "12px" }}
                >
                  Phone Number is required
                </Text>
              )} */}
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>{t("Email")}:</FormLabel>
            <Input
              className="inputField"
              name="email"
              value={applicantDetails?.email}
              onChange={(e) =>
                setApplicantDetails({
                  ...applicantDetails,
                  email: e.target.value,
                })
              }
              type="email"
              placeholder="email@email.com"
              // required
            />
            {/* {isNextClicked && (
              <>
                {applicantDetails?.email == null ||
                  applicantDetails?.email == "" ? (
                  <Text
                    className="label-asterisk"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    Email is Required
                  </Text>
                ) : !/^\S+@\S+\.\S+$/.test(applicantDetails?.email) ? (
                  <Text
                    className="label-asterisk"
                    style={{ color: "red", fontSize: "12px" }}
                  >
                    Invalid Email Format
                  </Text>
                ) : null}
              </>
            )} */}
          </FormControl>
        </GridItem>
      </Grid>
    </Form>
  );
}
