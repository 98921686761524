import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import AdvancedGridTable from "../../../../../components/AdvancedGridTable";
import { LogsApi } from "../api/LogsApi";
import { BiCheckCircle } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { formateDate } from "../../../../common/FormatDate";

const CopyIcon = ({ copied }) =>
  copied ? <BiCheckCircle size={25} /> : <FiCopy size={25} />;

export default function ApiLogs({ transactionPolicyNo }) {
  const [requestData, setRequestData] = useState([]);
  const [requestJson, setRequestJson] = useState({});
  const [responseJson, setResponseJson] = useState({});
  const [data, setData] = useState(null);
  const gridRef = useRef(null);
  const openRequestJson = (response) => setRequestJson(response);
  const openResponseJson = (response) => setResponseJson(response);

  const [copied, setCopied] = useState(false);

  const {
    isOpen: isRequest,
    onOpen: onRequest,
    onClose: onCloseRequest,
  } = useDisclosure();
  const {
    isOpen: isView,
    onOpen: onView,
    onClose: onCloseView,
  } = useDisclosure();

  const defaultColDef = {
    resizable: true,
    sortable: true,
    floatingFilter: true,
  };

  const columnDefs = [
    {
      headerName: "Response",
      field: "response",
      filter: true,
      width: 400,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (rowData) => (
        <ResponseCell
          rowData={rowData}
          openRequestJson={openRequestJson}
          openResponseJson={openResponseJson}
          onRequest={onRequest}
          onView={onView}
        />
      ),
    },
    {
      headerName: "Status",
      field: "status",
      filter: true,
      sortable: true,
      valueFormatter: (params) =>
        params.value === "SUCCESS" ? "Success" : "Error",
      flex: 1,
    },
    {
      headerName: "Date",
      field: "d_CreatedDate",
      filter: true,
      sortable: true,
      valueFormatter: (params) => formateDate(params.value),
    },
    {
      headerName: "Source",
      field: "source",
      filter: true,
      sortable: true,
      flex: 1,
    },
  ];

  function JsonDisplay({ jsonData }) {
    const handleCopy = async () => {
      const data1 =
        typeof jsonData === "string" ? JSON.parse(jsonData) : jsonData;

      try {
        await navigator.clipboard.writeText(JSON.stringify(data1, null, 2));
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    };

    const data = typeof jsonData === "string" ? JSON.parse(jsonData) : jsonData;

    return (
      <div
        style={{
          backgroundColor: "#f0f0f5",
          padding: "20px",
          fontFamily: "monospace",
          fontSize: "20px",
          lineHeight: "1.5",
          color: "#333",
        }}
      >
        <pre
          style={{
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            overflowX: "auto",
          }}
        >
          <Flex justifyContent="flex-end" alignItems="center">
            <Tooltip
              label={copied ? "Copied!" : "Copy to Clipboard"}
              aria-label="Copy to Clipboard"
            >
              <Button variant="ghost" colorScheme="blue" onClick={handleCopy}>
                <CopyIcon copied={copied} />
              </Button>
            </Tooltip>
          </Flex>
          {JSON.stringify(data, null, 2)}
        </pre>
      </div>
    );
  }

  const ResponseCell = ({
    rowData,
    openRequestJson,
    openResponseJson,
    onRequest,
    onView,
  }) => (
    <Flex mb={2} w={"100%"} flexDir={"column"}>
      <Flex mb="0px" w={"100%"} flexDir={"row"} alignItems={"center"}>
        {rowData.data.status === "SUCCESS" ? (
          <Box color="green.500">{rowData.data.display_name}</Box>
        ) : rowData.data.status === "ERROR" ? (
          <Box color="red.500">Error in {rowData.data.display_name}</Box>
        ) : null}
      </Flex>

      <Flex mt="0px" w={"100%"} flexDir={"row"} alignItems={"center"} gap={2}>
        <Button
          variant={"outline"}
          onClick={() => {
            openRequestJson(rowData.data.request);
            onRequest();
          }}
          size={"sm"}
          mr={2}
        >
          Request JSON
        </Button>
        <Button
          variant={"outline"}
          onClick={() => {
            openResponseJson(
              rowData.data.response || rowData.data.errorResponse
            );
            onView();
          }}
          size={"sm"}
        >
          Response JSON
        </Button>
      </Flex>
    </Flex>
  );

  useEffect(() => {
    const fetchData = async () => {
      const page = 1;
      const pageLength = 20;
      const poTransPK = transactionPolicyNo;
      try {
        const pivotData = await LogsApi(page, pageLength, poTransPK);
        setData(pivotData.data);
        setRequestData(pivotData.data);
      } catch (error) {
        console.log("Error fetching data", error);
      }
    };

    fetchData();
  }, [transactionPolicyNo]);
  return (
    <>
      <Box w={"100%"} className="ag-theme-alpine">
        <AdvancedGridTable
          gridRef={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={5}
          domLayout="autoHeight"
        />
      </Box>
      <Modal isOpen={isRequest} onClose={onCloseRequest} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request JSON</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              height={"65vh"}
              borderRadius={"lg"}
              overflow={"auto"}
              border={"1px solid"}
              borderColor={"orange"}
              p={5}
            >
              <JsonDisplay jsonData={requestJson} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={"ghost"}
              border={"1px solid gray"}
              fontWeight={400}
              borderRadius={"xl"}
              onClick={onCloseRequest}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isView} onClose={onCloseView} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View JSON</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              height={"65vh"}
              borderRadius={"lg"}
              overflow={"auto"}
              border={"1px solid"}
              borderColor={"orange"}
              p={5}
            >
              <JsonDisplay jsonData={responseJson} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              border={"1px solid gray"}
              variant={"ghost"}
              fontWeight={400}
              borderRadius={"xl"}
              onClick={onCloseView}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
