import Http from '../Http';

const documentCenterUrl = process.env.REACT_APP_DOCUMENT_CENTER_URL;

export function getList(policyNo) {
    const url = documentCenterUrl + "/api/listDocument?policy_no=" + policyNo;

    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get(url)
                .then((res) => {
                    return resolve(res.data);
                }).catch(function (error) {
                    return reject(error);
                });
        })
    )
}
