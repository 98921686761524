import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const ConfirmInsuredNameModal = (props) => {
    const { show, setShow, loading, handleNext, selected, setSelected } = props;
    const { t } = useTranslation();

    const handleChange = (e) => {
        let checked = e.target.checked;
        let value = e.target.value;

        if (checked == false) {
            value = '';
        }

        setSelected(value);
    }

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <Modal
                size="md"
                show={show}
                className="custom-dashboard-modal"
                onHide={() => setShow(!show)}
                backdrop='static'
            >
                <Modal.Header
                    closeButton
                    className="custom-dashboard-modal-header"
                >
                    <Modal.Title>{t('This change is due to')}:</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-dashboard-modal-body">
                    <Form>
                        <Form.Check
                            type={'checkbox'}
                            value={`CORRECTION`}
                            label={t('Correction of existing insured name')}
                            checked={selected == 'CORRECTION' ? true : false}
                            onChange={handleChange}
                        />

                        <Form.Check
                            type={'checkbox'}
                            value={`FULLNAMECHANGE`}
                            label={t('Change Insured Name on Policy')}
                            checked={selected == 'FULLNAMECHANGE' ? true : false}
                            onChange={handleChange}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type='button'
                        size='sm'
                        color='info'
                        onClick={() => handleNext(selected)}>
                        {t('Next')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </LoadingOverlay>
    )
}

export default ConfirmInsuredNameModal;