import Http from "../../../../Http";

export async function bindStarQuote(productName, quoteNo, requestBody) {
  const url = `/api/v2/quote/${productName}/bind/${quoteNo}`;
  try {
    const { data } = await Http.post(url, requestBody);
    return data;
  } catch (error) {
    console.error("Error binding star quote:", error);
    throw error;
  }
}
