import React, { useState, useEffect } from "react";
import {useParams,useNavigate } from 'react-router-dom';
import {redirectTo} from '../../services/privateFloodServicing';
import { useDispatch } from 'react-redux';
import { LoadingOverlay, Loader } from "react-overlay-loader";

const Forwarder = (props) => {
	const [agentSubModulesAccess, setAgentSubModulesAccess] = useState(localStorage.getItem("agentSubModules") ? JSON.parse(localStorage.getItem("agentSubModules")) : []);
  const dispatch = useDispatch();
  const { privateProductType} = useParams();
  const [loaderText, setLoaderText] = useState("Loading...");
  const navigate = useNavigate ();

  useEffect(() => {
	if(agentSubModulesAccess && !agentSubModulesAccess.includes('PRIVATE_PRODUCT_SERVICING')){
        navigate('/');
    }else{
		setLoaderText('Redirecting...')
	    dispatch(redirectTo(privateProductType)).then(
	      (res) => {
			if(res.status == true){
				window.location.href = res.url;
			}else{
				navigate('/');
			}
	      }
	    );
	}
	
  },[privateProductType]);

  return <><LoadingOverlay>
	<div style={{
    display: 'flex',
	marginTop:'20%',
    alignItems: 'center',
    justifyContent: 'center'}}>
		<Loader loading={true} text={loaderText}/>
	</div>
  </LoadingOverlay></>;
};

export default Forwarder;
