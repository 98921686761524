import { Box, FormControl, FormLabel, Grid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  agencyList,
  agentList,
  getBaseData,
} from "../../../../../../services/floodService";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { sortMutliDimensionArray } from "../../../../../common/ArrayFunctions";

export default function AgentAssignment({
  quoteDetailsData,
  edit,
  setBindDetails,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [agencyDD, setAgencyDD] = useState([]);
  const [agentDD, setAgentDD] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      const productId = quoteDetailsData?.data?.productId;
      if (productId) {
        const response = await dispatch(getBaseData(productId)).then((res) => {
          return res;
        });
        const sortedAgencyArray = sortMutliDimensionArray(
          "label",
          response?.agentAgency
        );
        const sortedAgentArray = sortMutliDimensionArray(
          "label",
          response?.agentDropdown
        );
        setAgencyDD(sortedAgencyArray);
        setAgentDD(sortedAgentArray);
      }
    };

    if (Object.keys(quoteDetailsData).length > 0) {
      fetchdata();
      setSelectedAgency(quoteDetailsData?.data?.selectedAgency);
      setSelectedAgent(quoteDetailsData?.data?.selectedAgent);
    }
  }, [quoteDetailsData]);

  const handleChange = (value, state) => {
    switch (state) {
      case "Agency":
        setSelectedAgency(value);
        loadAgentOptions(value.value);
        setBindDetails((prev) => ({
          ...prev,
          selectedAgency: value,
        }));
        break;
      case "Agent":
        setSelectedAgent(value);
        setBindDetails((prev) => ({
          ...prev,
          selectedAgent: value,
        }));
        break;
      default:
        break;
    }
  };

  const loadAgencyOptions = async (value) => {
    if (value.length > 3) {
      var request = {
        searchParams: value,
      };
      const response = await dispatch(agencyList(request))
        .then((res) => {
          return res;
        })
        .catch(() => toast.error(t("Something Went Wrong!")));
      if (response.length === 0) return [];
      const sortedAgencyArray = sortMutliDimensionArray("label", response);
      return sortedAgencyArray;
    }
  };

  const loadAgentOptions = async (value) => {
    const productId = quoteDetailsData?.data?.productId;
    if (productId) {
      const response = await dispatch(agentList(value, productId))
        .then((res) => {
          return res;
        })
        .catch(() => toast.error(t("Something Went Wrong!")));
      const sortedAgentArray = sortMutliDimensionArray("label", response);
      setAgentDD(sortedAgentArray);
    }
  };

  const filterAgentOptions = async (value) => {
    const results = agentDD.filter((i) =>
      i.label.toLowerCase().includes(value.toLowerCase())
    );
    return results;
  };
  const Styles = {
    control: (provided) => ({
      ...provided,
      textAlign: "left",
      border: "2px solid var(--chakra-colors-gray-300)",
      borderRadius: "12px",
      width: "100%",
      height: "53px",
      backgroundColor: edit ? "white" : "var(--chakra-colors-neutral-50)",
      "& .react-select__dropdown-indicator": {
        color: "black", // Change the color of the dropdown indicator
      },
    }),
  };

  return (
    <>
      <Grid templateColumns={"repeat(3, 1fr)"} gap={6} p={6}>
        <FormControl>
          <FormLabel>{t("Agency Name")}:</FormLabel>
          <AsyncSelect
            styles={Styles}
            cacheOptions
            loadOptions={loadAgencyOptions}
            defaultOptions={agencyDD}
            value={selectedAgency}
            onChange={(value) => handleChange(value, "Agency")}
            required
            isDisabled={!edit}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t("Producer Name")}:</FormLabel>
          <AsyncSelect
            styles={Styles}
            cacheOptions
            loadOptions={filterAgentOptions}
            defaultOptions={agentDD}
            value={selectedAgent}
            onChange={(value) => handleChange(value, "Agent")}
            required
            isDisabled={!edit}
          />
        </FormControl>
      </Grid>
    </>
  );
}
