import { Box, Text } from "@chakra-ui/react";

const TitleHeader = ({ title, children }) => {
  return (
    <Box border={"1px solid"} borderColor={"gray.200"} borderRadius={"xl"}>
      <Box border="1px solid #E6E9ED" borderRadius="12px">
        <Box
          background="var(--chakra-colors-primary-50)"
          p={"0.1rem"}
          borderRadius={"xl"}
        >
          <Text
            mt={3}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-semibold)"
            color="black"
            fontSize="1.5rem"
          >
            {title}
          </Text>
        </Box>
      </Box>
      <Box p={5}>{children}</Box>
    </Box>
  );
};

export default TitleHeader;
