import { React, useState, useMemo, useRef, useCallback } from "react";
// import { Col, Row, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { searchAgencyUsers } from "../../../services/producerService";
// import { LoadingOverlay, Loader } from "react-overlay-loader";
import { toast } from "react-toastify";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  setAgencyUserModal,
  setAgencyUserPk,
} from "../../../store/actions/index";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
} from "@chakra-ui/react";
import { BiReset } from "react-icons/bi";
import { FaSearch } from "react-icons/fa";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { pickBy } from "lodash";

const AgentSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  // const [TableData, setTableData] = useState([]);
  // const [tableTotal, setTableTotal] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [filter, setFilter] = useState({
  //   clientId: "",
  //   fname: "",
  //   lastOrg: "",
  //   wyoAgentCode: "",
  //   wyoUpn: "",
  //   prevWyoAgentCode: "",
  //   state: "",
  //   city: "",
  //   pageTemp: 1,
  //   sizePerPageTemp: 10,
  // });
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 25, 50];
  const filterOptions = {
    clientId: "",
    fname: "",
    lastOrg: "",
    wyoAgentCode: "",
    wyoUpn: "",
    prevWyoAgentCode: "",
    state: "",
    city: "",
    status: "",
  };
  const [filterParams, setFilterParams] = useState(filterOptions);
  const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);

  // const handleClear = () => {
  //   setFilter({
  //     clientId: "",
  //     fname: "",
  //     lastOrg: "",
  //     wyoAgentCode: "",
  //     wyoUpn: "",
  //     prevWyoAgentCode: "",
  //     state: "",
  //     city: "",
  //     pageTemp: 1,
  //     sizePerPageTemp: 10,
  //   });
  // };

  // const onHandleChange = (e) => {
  //   var name = e.target.name;
  //   const tempArray = filter; // creating copy of an array
  //   tempArray[name] = e.target.value;
  //   setFilter({ ...filter, name: e.target.value }); // set copied array
  // };
  // const onTableChange = (type, { page, sizePerPage }) => {
  //   setFilter({ ...filter, pageTemp: page, sizePerPageTemp: sizePerPage });
  // };

  // const handlePageChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, pageTemp: page, sizePerPageTemp: sizePerPage });
  // };

  // const sizePerPageListChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, sizePerPageTemp: sizePerPage });
  // };

  // const renderShowsTotal = (from, to, size) => (
  //   <span style={{ paddingLeft: 10 }}>
  //     Showing {from} to {to} of {size} Results
  //   </span>
  // );
  // useEffect(() => {
  //   if (filter.pageTemp !== 1 || filter.sizePerPageTemp !== 10) {
  //     handleSearchAgent();
  //   }
  // }, [filter.pageTemp, filter.sizePerPageTemp]);

  // const validate = () => {
  //   if (
  //     filter.clientId == "" &&
  //     filter.fname == "" &&
  //     filter.lastOrg == "" &&
  //     filter.wyoAgentCode == "" &&
  //     filter.wyoUpn == "" &&
  //     filter.prevWyoAgentCode == "" &&
  //     filter.state == "" &&
  //     filter.city == ""
  //   ) {
  //     toast.error("Please Enter Atleast One field for Search");
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  // const getSearchedAgencyUsers = async () => {
  //   let paramsUrl = "?";
  //   for (const [key, value] of Object.entries(filter)) {
  //     paramsUrl += key + "=" + value + "&";
  //   }
  //   paramsUrl = paramsUrl.replace(/&([^&]*)$/, "$1");
  //   setLoading(true);
  //   await dispatch(searchAgencyUsers(paramsUrl)).then((res) => {
  //     setLoading(false);
  //     setTableData(res.data.returnObject);
  //     setTableTotal(res.data.returnCount);
  //   });
  //   setLoading(false);
  // };

  // const handleSearchAgent = (e) => {
  //   if (validate()) {
  //     getSearchedAgencyUsers();
  //   }
  // };
  // const createAgencyLink = (cell, row) => {
  //   return (
  //     <a href="" onClick={(e) => handleUserClick(e, row)}>
  //       {row.s_UserCode}
  //     </a>
  //   );
  // };

  // const handleUserClick = (e, row) => {
  //   dispatch(setAgencyUserModal(true));
  //   dispatch(setAgencyUserPk(row.n_PersonInfoId_PK));
  //   navigate(`/producer/${row.n_PersoninfoAgency_FK}`);
  // };

  // const search = (e) => {
  //   e.preventDefault();
  //   handleSearchAgent(e);
  // }
  // const columns = [
  //   {
  //     dataField: "s_UserCode",
  //     text: t("AGENT CODE"),
  //     formatter: (value, row) => createAgencyLink(value, row),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_FullLegalName",
  //     text: t("AGENT NAME"),
  //     style: {
  //       textAlign: "left",

  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },

  //   {
  //     dataField: "s_CityCode",
  //     text: t("CITY"),
  //     // formatter: (value,row) => formatInsuredName(value,row),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "s_StateCode",
  //     text: t("STATE"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  //   {
  //     dataField: "agency",
  //     text: t("AGENCY"),
  //     style: {
  //       textAlign: "left",
  //     },
  //     headerStyle: {
  //       textAlign: "left",
  //       backgroundColor: "var(--buttonColor)",
  //       color: "white"
  //     },
  //   },
  // ];

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.pageLength,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   showTotal: true,
  //   onPageChange: handlePageChange,
  //   totalSize: tableTotal,
  //   onSizePerPageChange: sizePerPageListChange,
  //   paginationTotalRenderer: renderShowsTotal,
  // };

  const handleAgencyCodeClick = (rowData) => {
    try {
      dispatch(setAgencyUserModal(true));
      dispatch(setAgencyUserPk(rowData.n_PersonInfoId_PK));
      navigate(`/producer/${rowData.n_PersoninfoAgency_FK}`);
    } catch (error) {
      console.error(error);
    }
  };

  const createAgencyCodeLink = (params) => {
    const rowData = params.data;
    return (
      <a
        style={{
          textDecoration: "underline",
          color: "var(--chakra-colors-linkColor)",
        }}
        href=""
        onClick={(e) => handleAgencyCodeClick(rowData)}
      >
        {rowData.s_UserCode}
      </a>
    );
  };

  const WordBreakRenderer = (params) => {
    return <div style={{ wordBreak: "break-word" }}>{params.value}</div>;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    const result = pickBy(filterParams, function (value) {
      return value !== "";
    });
    if (Object.keys(result).length === 0) {
      toast.error("Please provide at least one parameter for search.", {
        toastId: "agentSearch",
      });
      return;
    }
    const dataSource = createServerSideDataSource();
    gridRef.current.api.updateGridOptions({ serverSideDatasource: dataSource });
  };

  const handleReset = () => {
    setFilterParams(filterOptions);
    setGridKey(`grid-key-${Math.random()}`);
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const loadData = async (params) => {
    let paramsUrl = "?";
    for (const [key, value] of Object.entries(params)) {
      paramsUrl += key + "=" + value + "&";
    }
    paramsUrl = paramsUrl.replace(/&([^&]*)$/, "$1");
    try {
      const res = await dispatch(searchAgencyUsers(paramsUrl)).then((res) => {
        return res;
      });
      return res.data;
    } catch (e) {
      const errorMessages = Object.values(e?.errors).flat();
      errorMessages.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
      toast.warning("No data found");
      return {
        rows: 0,
        total: 0,
      };
    }
  };

  const createServerSideDataSource = () => {
    return {
      getRows: async (params) => {
        const pageLength = params.request.endRow - params.request.startRow;
        const page = params.request.endRow / pageLength;

        let apiParams = {
          ...filterParams,
          pageTemp: page,
          sizePerPageTemp: pageLength,
        };

        if (params.request?.sortModel?.length > 0) {
          apiParams = {
            ...apiParams,
            sortOrder: params.request?.sortModel[0]?.sort,
            sortBy: params.request?.sortModel[0]?.colId,
          }
        }

        const res = await loadData(apiParams);

        setTimeout(function () {
          if (res?.returnCount) {
            // supply rows for requested block to grid
            params.success({
              rowData: res.returnObject,
              rowCount: res.returnCount,
            });
            params.api.hideOverlay();
          } else {
            params.api.showNoRowsOverlay();
            params.success({
              rowData: [],
              rowCount: 0,
            });
          }
        }, 500);
      },
    };
  };

  const onGridReady = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: false,
      floatingFilter: false,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      headerName: t("AGENT CODE"),
      field: "s_UserCode",
      minWidth: 200,
      cellRenderer: (params) => createAgencyCodeLink(params),
    },
    {
      headerName: t("AGENT NAME"),
      field: "s_FullLegalName",
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("CITY"),
      field: "s_CityCode",
      minWidth: 120,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: t("STATE"),
      field: "s_StateCode",
      minWidth: 80,
    },
    {
      headerName: t("STATUS"),
      field: "s_PersonStatusCode",
      minWidth: 100,
    },
    {
      headerName: t("AGENCY"),
      field: "agency",
      wrapText: true,
      autoHeight: true,
      minWidth: 350,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
  ]);

  return (
    <>
      <Box>
        <Box p={4}>
          <Grid
            templateColumns="repeat(5, 1fr)"
            gap={6}
            alignItems={"flex-end"}
          >
            <FormControl>
              <FormLabel>{t("Agent Flood Code")}</FormLabel>
              <Input
                name="clientId"
                id="clientId"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.clientId}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Agent First Name")}</FormLabel>
              <Input
                name="fname"
                id="fname"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.fname}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Agent Last Name")}</FormLabel>
              <Input
                name="lastOrg"
                id="lastOrg"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.lastOrg}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO Agent Code")}</FormLabel>
              <Input
                name="wyoAgentCode"
                id="wyoAgentCode"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.wyoAgentCode}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO UPN")}</FormLabel>
              <Input
                name="wyoUpn"
                id="wyoUpn"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.wyoUpn}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Previous WYO Agent Code")}</FormLabel>
              <Input
                name="prevWyoAgentCode"
                id="prevWyoAgentCode"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.prevWyoAgentCode}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("State")}</FormLabel>
              <Input
                name="state"
                id="state"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.state}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("City")}</FormLabel>
              <Input
                name="city"
                id="city"
                className="dashboard-group-input"
                type="text"
                placeholder=""
                autoComplete="off"
                value={filterParams.city}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Status")}</FormLabel>
              <Select
                name="status"
                id="status"
                className="dashboard-group-input"
                placeholder=""
                value={filterParams.status}
                onChange={handleChange}
                onKeyDown={handleEnterSearch}
              >
                <option value=""></option>
                <option value="Active">{t("Active")}</option>
                <option value="Closed">{t("Closed")}</option>
              </Select>
            </FormControl>
            <Flex>
              <Button leftIcon={<FaSearch />} onClick={handleSearch}>
                {t("Search")}
              </Button>
              <Button ml={2} leftIcon={<BiReset />} onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Flex>
          </Grid>
          <Box
            mt={6}
            bgColor={"white"}
            borderRadius={"2xl"}
            borderColor={"white"}
            boxShadow={"lg"}
          >
            <Box
              className="ag-theme-alpine"
              height={"400px"}
              id="myGrid"
              w={"100%"}
            >
              <AdvancedGridTable
                gridRef={gridRef}
                key={gridKey}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                rowBuffer={0}
                rowModelType={"serverSide"}
                cacheBlockSize={50}
                maxBlocksInCache={10}
                onGridReady={(params) => onGridReady(params)}
                enableCellTextSelection={true}
              />
            </Box>
          </Box>
        </Box>
        {/* <Row className="align-items-baseline">
          <form onSubmit={(e) => search(e)}>
            <Col xs="12" className="quote-page-tab1">
              <Row className="align-items-center">
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Agent Flood Code")}
                    </Form.Label>
                    <Form.Control
                      name="clientId"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.clientId}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Agent First Name")}
                    </Form.Label>
                    <Form.Control
                      name="fname"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.fname}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Agent Last Name")}
                    </Form.Label>
                    <Form.Control
                      name="lastOrg"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.lastOrg}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("WYO Agent Code")}
                    </Form.Label>
                    <Form.Control
                      name="wyoAgentCode"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.wyoAgentCode}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("WYO UPN")}
                    </Form.Label>
                    <Form.Control
                      name="wyoUpn"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.wyoUpn}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Previous WYO Agent Code")}
                    </Form.Label>
                    <Form.Control
                      name="prevWyoAgentCode"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.prevWyoAgentCode}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("State")}
                    </Form.Label>
                    <Form.Control
                      name="state"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.state}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("City")}
                    </Form.Label>
                    <Form.Control
                      name="city"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      value={filter.city}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row id="dashboard-table-section">
                <Col
                  xs="12"
                  style={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1.2rem",
                  }}
                > */}
        {/* <Button id="common-btn" onClick={(e) => handleSearchAgent(e)}>Search</Button> */}
        {/* <Button id="common-btn" type='submit'>{t("Search")}</Button>
                  &nbsp;&nbsp;
                  <Button
                    id="common-btn"
                    type="button"
                    onClick={(e) => {
                      handleClear(e);
                    }}
                  >
                    {t("Clear")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </form>
          <Col style={{ marginTop: "1.2rem" }}>
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              bootstrap4
              keyField="n_PersonInfoId_PK"
              data={TableData}
              columns={columns}
              hover
              remote
              bordered={false}
              pagination={paginationFactory(options)}
              onTableChange={onTableChange}
              tabIndexCell
            />
          </Col>
        </Row> */}
      </Box>
    </>
  );
};

export default AgentSearch;
