import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getGeoCode } from "../../utility/googleAddressHelper";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import { getAutoLocationData } from "../../api/getBaseData";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getAddrByZip } from "../../api/getAddrByZip";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import "../style.css";
import states from "../../../common/States";
import { FaRegSave } from "react-icons/fa";

export const Location = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { productID } = useParams();
  const [selectedPropertyData, setSelectedPropertyData] = useState({});
  const {
    isOpen: isMailingModalOpen,
    onOpen: onMailingModalOpen,
    onClose: closeMailingModalOpen,
  } = useDisclosure();
  const [onSaveClick, setOnSaveClick] = useState(false);
  const [mailingAddressLocally, setMailingAddressLocally] = useState({
    s_StateName: "",
    s_CountyName: "",
    s_CityName: "",
    s_ZipCode: "",
    mailingAddressData: "",
    addressLine: "",
  });
  const [zipCodeSearch, setZipCodeSearch] = useState(false);
  const [value, setValue] = useState({ label: "", value: "" });
  const {
    locationQuoteObject,
    setLocationQuoteObject,
    inputValue,
    setInputValue,
    loading,
    setLoading,
    setAutoGenMapData,
    setStepLock,
    constructionDetails,
    manualEntry,
    setApplicantDetails,
    applicantDetails,
    autoGenMapData,
    setMailingAddress,
    isMailingAddressSame,
    setIsMailingAddressSame,
    mailingAddress,
  } = useMyContext(CreateQuoteContext);

  const HandleZipData = async (e) => {
    const zipCode = e.target.value;
    setMailingAddressLocally({ ...mailingAddressLocally, s_ZipCode: zipCode });
    setZipCodeSearch(true);
    if (zipCode.length === 5)
      await getAddrByZip({ zipCode }).then((res) => {
        setMailingAddressLocally({
          ...mailingAddressLocally,
          s_ZipCode: zipCode,
          s_StateName: res?.data?.zipData[0]?.s_StateDisplayName || "",
          s_CountyName: res?.data?.zipData[0]?.s_CountyName || "",
          s_CityName: res?.data?.zipData[0]?.s_CityName || "",
        });
        setZipCodeSearch(false);
      });
    setZipCodeSearch(false);
  };

  useEffect(() => {
    if (
      locationQuoteObject == null ||
      locationQuoteObject == undefined ||
      inputValue == null
    )
      return;
    setValue({
      value: locationQuoteObject.fullAddress,
      label: locationQuoteObject.fullAddress,
    });
  }, [locationQuoteObject]);

  useEffect(() => {
    if (constructionDetails?.productType === "") {
      toast.error("Please Select a Product Type", { autoClose: 2000 });
      setTimeout(() => {
        navigation(`/quote/${productID}/create`);
      }, 2000);
    }
  }, [constructionDetails?.productType]);

  const geoCallar = async () => {
    if (inputValue != null) {
      const propData = await getGeoCode(
        inputValue?.value?.description,
        selectedPropertyData
      );
      setSelectedPropertyData({
        ...propData,
        fullAddress: inputValue.value.description,
      });
      setLocationQuoteObject({
        ...propData,
        addressLine3: "",
        fullAddress: inputValue.value.description,
      });
    }
  };

  const showMailingAddress = () => {
    if (
      mailingAddress?.mailingAddressData &&
      mailingAddress?.s_CityName &&
      mailingAddress?.s_CountyName &&
      mailingAddress?.s_StateName &&
      mailingAddress?.s_ZipCode
    ) {
      return (
        <>
          {mailingAddress?.mailingAddressData}
          {mailingAddress?.addressLine
            ? `, ${mailingAddress?.addressLine}`
            : ""}
          , {mailingAddress?.s_CountyName}, {mailingAddress?.s_CityName},{" "}
          {mailingAddress?.s_StateName}, {mailingAddress?.s_ZipCode}
        </>
      );
    }
    return <></>;
  };

  const autoFloodMapGen = async () => {
    if (
      inputValue == null ||
      locationQuoteObject == null ||
      inputValue == undefined ||
      locationQuoteObject == undefined
    ) {
      return;
    }
    try {
      setLoading(true);
      setStepLock(false);
      const data = await getAutoLocationData({
        requestBody: {
          street_number: locationQuoteObject.streetNumber,
          street_name: locationQuoteObject.streetName,
          city: locationQuoteObject.city,
          CountyName: locationQuoteObject.countyName,
          PostalCode: locationQuoteObject.postalCode,
          country: locationQuoteObject.country,
          latitude: locationQuoteObject.latitude,
          longitude: locationQuoteObject.longitude,
          value: "",
          autocomplete: locationQuoteObject.autoComplete,
          state: locationQuoteObject.stateCode,
          s_PostalCodeSuffix: locationQuoteObject.s_PostalCodeSuffix,
        },
      });
      if (data?.data != null) {
        setAutoGenMapData(data?.data);
      }
    } catch (error) {
      console.error("Error fetching auto location data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      inputValue != null ||
      locationQuoteObject != null ||
      inputValue != undefined
    ) {
      geoCallar();
    }
  }, [inputValue]);
  useEffect(() => {
    if (
      locationQuoteObject.streetName == null ||
      locationQuoteObject.streetName == undefined
    ) {
      return;
    }
    autoFloodMapGen();
  }, [locationQuoteObject.streetName]);

  useEffect(() => {
    let firstName = "";
    let secondName = "";
    let lastName = "";
    let backupName = "";
    let backupLastName = "";

    try {
      const deedsData = autoGenMapData?.EastedData?.data?.deeds;
      const ownerData = autoGenMapData?.EastedData?.data?.owner;

      if (Array.isArray(deedsData) && deedsData.length > 0) {
        const deed = deedsData[0];
        firstName = deed?.buyer_first_name || "";
        secondName = deed?.buyer2_first_name || "";
        lastName = deed?.buyer_last_name || "";
      }

      if (ownerData && ownerData?.name) {
        [backupName, backupLastName] = ownerData?.name?.split(" ");
      }
    } catch (error) {
      console.error("Error retrieving applicant details:", error);
    }

    const fullFirstName = `${firstName}${secondName ? `, ${secondName}` : ""}`;
    const finalFirstName = fullFirstName || backupName || "";
    const finalLastName = lastName || backupLastName || "";

    setApplicantDetails({
      ...applicantDetails,
      firstName: finalFirstName,
      lastName: finalLastName,
    });
  }, [autoGenMapData]);

  const updateMailing = () => {
    if (!isMailingAddressSame) {
      setMailingAddress({
        s_StateName: "",
        s_CountyName: "",
        s_CityName: "",
        s_ZipCode: "",
        mailingAddressData: "",
        addressLine: "",
      });
      setMailingAddressLocally({
        s_StateName: "",
        s_CountyName: "",
        s_CityName: "",
        s_ZipCode: "",
        mailingAddressData: "",
        addressLine: "",
      });
      setOnSaveClick(false);
    }
    setIsMailingAddressSame(!isMailingAddressSame);
  };

  const validateString = (stringValue) => {
    if (stringValue !== "" && stringValue.length < 40) {
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    setOnSaveClick(true);
    if (
      validateString(mailingAddressLocally?.s_StateName) &&
      validateString(mailingAddressLocally?.s_CountyName) &&
      validateString(mailingAddressLocally?.s_CityName) &&
      validateString(mailingAddressLocally?.mailingAddressData) &&
      mailingAddressLocally?.s_ZipCode !== "" &&
      mailingAddressLocally?.s_ZipCode?.length === 5
    ) {
      setMailingAddress({ ...mailingAddressLocally });
      closeMailingModalOpen();
    } else {
      toast.error("Please fill correct data", {
        toastId: "fillCorrectData",
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        border: "none",
      },
    }),
    container: (provided) => ({
      ...provided,
      border: "2px solid",
      borderColor: "var(--chakra-colors-gray-300)",
      borderRadius: "12px",
      paddingRight: "25px",
      paddingLeft: "0px",
      paddingTop: "5px",
      height: "52px",
    }),
    input: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--chakra-colors-gray-800)",
    }),
    placeholder: (provided) => ({
      ...provided,
      GridItemor: "black",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
  };

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Form>
          <Grid templateColumns={"repeat(4, 1fr)"} gap={6} p={2}>
            <GridItem pt={"0.3rem"}>
              <FormControl
                isRequired={manualEntry ? false : true}
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel className="dashboard-group-label">
                  {t("Google Auto Address")}:
                </FormLabel>

                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                  name="personAddr.s_StreetName"
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us"],
                    },
                  }}
                  selectProps={{
                    onChange: (e) => {
                      setInputValue(e);
                    },

                    placeholder: value?.label,
                    styles: customStyles,
                    className: "googleInput",
                  }}
                />
                {/* {(inputValue == null || inputValue == "") && (
                  <Text
                    className="label-asterisk"
                    style={{ GridItemor: "green", fontSize: "12px" }}
                  >
                    {t("Use it for Auto Address Generation")}
                  </Text>
                )} */}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("Address Line 1")}:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  placeholder=""
                  isDisabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      addressLine: e.target.value,
                    });
                  }}
                  value={locationQuoteObject?.addressLine || ""}
                />
                {(locationQuoteObject?.addressLine == null ||
                  locationQuoteObject?.addressLine == "") && (
                  <Text
                    className="label-asterisk"
                    style={{ GridItemor: "red", fontSize: "12px" }}
                  >
                    {t("Address Line 1 is required")}
                  </Text>
                )}
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Address Line 2")}:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  _placeholder={{ color: "gray.300" }}
                  isDisabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      addressLine3: e.target.value,
                    });
                  }}
                  value={locationQuoteObject?.addressLine3}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("Country")}:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  placeholder=""
                  disabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      country: e.target.value,
                    });
                  }}
                  required
                  value={locationQuoteObject?.country}
                />
                {(locationQuoteObject?.country == null ||
                  locationQuoteObject?.country == "") && (
                  <Text
                    className="label-asterisk"
                    style={{ GridItemor: "red", fontSize: "12px" }}
                  >
                    Country is required
                  </Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>{t("State Code")}:</FormLabel>
                {/* <Input
                  className="inputField"
                  bgColor={manualEntry ?  "white.50" : "gray.200"}
                  type="text"
                  placeholder=""
                  disabled={!manualEntry}
                  _disabled={{color:'black', opacity:0.8}}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      stateCode: e.target.value,
                    });
                  }}
                  required
                  value={locationQuoteObject?.stateCode}
                /> */}
                <Select
                  mt={1}
                  size="lg"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  className="selectField"
                  name="stateCode"
                  isDisabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  defaultValue={locationQuoteObject?.stateCode || ""}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      stateCode: e.target.value,
                    });
                  }}
                >
                  <option value=""></option>
                  {states.map((state) => (
                    <option key={state?.abbr} value={state?.abbr}>
                      {state?.name} ({state?.abbr})
                    </option>
                  ))}
                </Select>
                {(locationQuoteObject?.stateCode == null ||
                  locationQuoteObject?.stateCode == "") && (
                  <Text
                    className="label-asterisk"
                    style={{ GridItemor: "red", fontSize: "12px" }}
                  >
                    {t("State Code is required")}
                  </Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl isRequired>
                <FormLabel>Zip:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  placeholder=""
                  disabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      postalCode: e.target.value,
                    });
                  }}
                  required
                  value={locationQuoteObject?.postalCode}
                />
                {(locationQuoteObject?.postalCode == null ||
                  locationQuoteObject?.postalCode == "") && (
                  <Text
                    className="label-asterisk"
                    style={{ GridItemor: "red", fontSize: "12px" }}
                  >
                    {t("Zip is required")}
                  </Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("County")}:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  placeholder=""
                  disabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,

                      countyName: e.target.value,
                    });
                  }}
                  required
                  value={locationQuoteObject?.countyName}
                />
                {/* {(locationQuoteObject?.countyName == null ||
                  locationQuoteObject?.countyName == "") && (
                    <Text
                      className="label-asterisk"
                      style={{ GridItemor: "red", fontSize: "12px" }}
                    >
                      {t("County is required")}
                    </Text>
                  )} */}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("City")}:</FormLabel>
                <Input
                  className="inputField"
                  bgColor={manualEntry ? "white.50" : "gray.100"}
                  type="text"
                  placeholder=""
                  disabled={!manualEntry}
                  _disabled={{ color: "black", opacity: 0.8 }}
                  onChange={(e) => {
                    setLocationQuoteObject({
                      ...locationQuoteObject,
                      city: e.target.value,
                    });
                  }}
                  value={locationQuoteObject?.city}
                />
                {/* {(locationQuoteObject?.city == null ||
                  locationQuoteObject?.city == "") && (
                    <Text
                      className="label-asterisk"
                      style={{ GridItemor: "red", fontSize: "12px" }}
                    >
                      {t("City is required")}
                    </Text>
                  )} */}
              </FormControl>
            </GridItem>
            {manualEntry && (
              <>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Lattitude")}:</FormLabel>
                    <Input
                      className="inputField"
                      bgColor={manualEntry ? "white.50" : "gray.100"}
                      type="text"
                      placeholder=""
                      disabled={!manualEntry}
                      _disabled={{ color: "black", opacity: 0.8 }}
                      onChange={(e) => {
                        setLocationQuoteObject({
                          ...locationQuoteObject,
                          latitude: e.target.value,
                        });
                      }}
                      value={locationQuoteObject?.latitude}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Longitude")}:</FormLabel>
                    <Input
                      className="inputField"
                      bgColor={manualEntry ? "white.50" : "gray.100"}
                      type="text"
                      placeholder=""
                      disabled={!manualEntry}
                      _disabled={{ color: "black", opacity: 0.8 }}
                      onChange={(e) => {
                        setLocationQuoteObject({
                          ...locationQuoteObject,
                          longitude: e.target.value,
                        });
                      }}
                      value={locationQuoteObject?.longitude}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
          </Grid>
          <Flex py={2}>
            <HStack pt={4} px={2}>
              <Text fontSize={"lg"}>
                {t("Mailing Address Same as the Property Address?")}
              </Text>
              <HStack ml={2} mb={"15px"}>
                <Switch
                  size="lg"
                  isChecked={isMailingAddressSame}
                  onChange={updateMailing}
                />
              </HStack>
            </HStack>
            {!isMailingAddressSame && (
              <Box pt={"1.25rem"} pl={5}>
                {mailingAddress?.mailingAddressData && (
                  <>
                    <Text fontWeight={500} fontSize={"lg"}>
                      Mailing Address:{" "}
                      <Text fontWeight={400} as={"span"}>
                        {" "}
                        {!isMailingAddressSame && showMailingAddress()}
                      </Text>
                    </Text>
                  </>
                )}
              </Box>
            )}
          </Flex>
          <Box p={3}>
            {!isMailingAddressSame && (
              <Box>
                <Button
                  id="primary-GridItemored-btn"
                  onClick={onMailingModalOpen}
                >
                  Update Mailing Address
                </Button>
                {!mailingAddress?.mailingAddressData && (
                  <Text mt={2} color="red" fontSize={"sm"}>
                    Please update the mailing address
                  </Text>
                )}
              </Box>
            )}
          </Box>
        </Form>
        <Modal
          isCentered
          size={"3xl"}
          isOpen={isMailingModalOpen}
          onClose={closeMailingModalOpen}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderRadius={"lg"} bgColor={"agGridHeaderBg"}>
              {t("Set a Mailing Address")}
            </ModalHeader>

            <ModalCloseButton
              onClick={() => {
                setOnSaveClick(false);
                setMailingAddressLocally(mailingAddress);
                //setIsMailingAddressSame(!isMailingAddressSame);
              }}
            />
            <ModalBody mt={1}>
              <Grid templateColumns={"repeat(3, 1fr)"} gap={6} p={3}>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Mailing Address:</FormLabel>

                    <Input
                      className="inputField"
                      type="text"
                      value={mailingAddressLocally?.mailingAddressData}
                      onChange={(e) =>
                        setMailingAddressLocally({
                          ...mailingAddressLocally,
                          mailingAddressData: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  {onSaveClick &&
                    mailingAddressLocally?.mailingAddressData === "" && (
                      <Text color="red" fontSize={"sm"}>
                        Mailing Address is required
                      </Text>
                    )}
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Address Line 2:</FormLabel>
                    <Input
                      className="inputField"
                      type="text"
                      value={mailingAddressLocally?.addressLine}
                      onChange={(e) =>
                        setMailingAddressLocally({
                          ...mailingAddressLocally,
                          addressLine: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>Zip: {zipCodeSearch && <Spinner size="sm" />}</FormLabel>
                    <Input
                      className="inputField"
                      type="number"
                      defaultValue={mailingAddressLocally?.s_ZipCode}
                      onChange={(e) => HandleZipData(e)}
                    />
                  </FormControl>
                  {onSaveClick && mailingAddressLocally?.s_ZipCode === "" && (
                    <Text color="red" fontSize={"sm"}>
                      Zip code is required
                    </Text>
                  )}
                  {onSaveClick &&
                    mailingAddressLocally?.s_ZipCode !== "" &&
                    mailingAddressLocally?.s_ZipCode?.length != 5 && (
                      <Text color="red" fontSize={"sm"}>
                        Zip code should be exactly five digits.
                      </Text>
                    )}
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>County:</FormLabel>
                    <Input
                      className="inputField"
                      type="text"
                      value={mailingAddressLocally?.s_CountyName}
                      onChange={(e) =>
                        setMailingAddressLocally({
                          ...mailingAddressLocally,
                          s_CountyName: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  {onSaveClick &&
                    mailingAddressLocally?.s_CountyName === "" && (
                      <Text color="red" fontSize={"sm"}>
                        County is required
                      </Text>
                    )}
                </GridItem>
                <GridItem>
                  <FormControl isRequired>
                    <FormLabel>State:</FormLabel>
                    <Input
                      className="inputField"
                      type="text"
                      value={mailingAddressLocally?.s_StateName}
                      onChange={(e) =>
                        setMailingAddressLocally({
                          ...mailingAddressLocally,
                          s_StateName: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  {onSaveClick && mailingAddressLocally?.s_StateName === "" && (
                    <Text color="red" fontSize={"sm"}>
                      State is required
                    </Text>
                  )}
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>City:</FormLabel>
                    <Input
                      className="inputField"
                      type="text"
                      value={mailingAddressLocally?.s_CityName}
                      onChange={(e) =>
                        setMailingAddressLocally({
                          ...mailingAddressLocally,
                          s_CityName: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  {onSaveClick && mailingAddressLocally?.s_CityName === "" && (
                    <Text color="red" fontSize={"sm"}>
                      City is required
                    </Text>
                  )}
                </GridItem>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button
                isDisabled={zipCodeSearch}
                id="primary-GridItemored-btn"
                onClick={handleSubmit}
                leftIcon={<FaRegSave />}
              >
                {t("Save")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </LoadingOverlay>
    </>
  );
};
