import { Button, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { FaConnectdevelop, FaSave } from "react-icons/fa";
import { TbLayoutNavbarExpand } from "react-icons/tb";
import { starCommercialContext } from "../CommercialContext";
import { useTranslation } from "react-i18next";

export const QuoteButtons = () => {
  const { t } = useTranslation();

  const {
    accordiansArray,
    setAccordianArray,
    handleSubmitQuote,
    defaultAccordion,
    bindComQuote,
    isBind,
  } = useContext(starCommercialContext);
  const showAll = () => {
    setAccordianArray(!accordiansArray);
    if (accordiansArray.length === 4) {
      setAccordianArray([]);
    } else {
      setAccordianArray(defaultAccordion);
    }
  };
  return (
    <>
      <Flex my={1} gap={3} justifyContent={"flex-end"}>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<TbLayoutNavbarExpand />}
          onClick={showAll}
        >
          {accordiansArray && accordiansArray.length > 0
            ? t("Collapse All")
            : t("Expand All")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<FaConnectdevelop />}
          onClick={bindComQuote}
          isDisabled={isBind}
        >
          {t("Bind Quote")}
        </Button>
        <Button
          fontWeight={400}
          borderRadius={"xl"}
          leftIcon={<FaSave />}
          isDisabled={isBind}
          onClick={() => {
            handleSubmitQuote();
          }}
        >
          {t("Save & Update")}
        </Button>
      </Flex>
    </>
  );
};
