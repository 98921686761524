import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  Flex,
  Grid,
  GridItem,
  Input,
  ListItem,
  Select,
  Spacer,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useStarDataContext } from "../../StarDataContext";
import MultiSelect from "../../utils/ChakraMultiSelect";
import LiabilityExposuresComponent from "./LiabilityExposures";
import DateInput from "../../utils/DateInput";

export default function UnderWritingQuestion() {
  const {
    baseDD,
    underWriting,
    setUnderWriting,
    selectedRisk,
    setSelectedRisk,
    lossHistoryData,
    errorMessages,
    setErrorMessages,
    liabilityExposures,
    setLiabilityExposures,
    discounts,
    setDiscounts,
    validationError,
    coverages,
  } = useStarDataContext();
  const { t } = useTranslation("underwriting");
  const [underWriterQues, setUnderWriterQues] = useState([]);
  const [isBaseDataLoaded, setIsBaseDataLoaded] = useState(false);
  const [solarPanelLocation, setSolarPanelLocation] = useState([]);
  const [riskAssociated, setRiskAssociated] = useState([]);

  const handleLiabilityExposureChange = (exposure) => {
    setLiabilityExposures((prev) => ({
      ...prev,
      [exposure]: !prev[exposure],
    }));
  };

  useEffect(() => {
    if (baseDD) {
      const sortedQuestions = (
        (Array.isArray(baseDD.GetQuestionForgeneralTab) &&
          baseDD.GetQuestionForgeneralTab.length > 0 &&
          baseDD.GetQuestionForgeneralTab) ||
        (Array.isArray(baseDD.GetQuestion) &&
          baseDD.GetQuestion.length > 0 &&
          baseDD.GetQuestion) ||
        []
      )
        .filter((ques) => ques.n_DisplaySequenceNo)
        .sort(
          (a, b) =>
            parseFloat(a.n_DisplaySequenceNo) -
            parseFloat(b.n_DisplaySequenceNo)
        );
      setUnderWriterQues(sortedQuestions);
      setSolarPanelLocation(baseDD.STARSOLARPANELLOCATION || []);
      setRiskAssociated(baseDD.STAROTHERRISKASSOCIATED || []);
      setIsBaseDataLoaded(true);
    }
  }, [baseDD]);

  useEffect(() => {
    const hasAnimalClaim =
      Array.isArray(lossHistoryData) && lossHistoryData.length > 0;
    const hasAnimals = underWriting.underWritingQuestions.hasAnimals;

    setErrorMessages((prev) => {
      const newErrorMessages = { ...prev };

      if (hasAnimals && hasAnimalClaim) {
        newErrorMessages.hasAnimals = t("hasAnimals");
      } else {
        delete newErrorMessages.hasAnimals;
      }

      return newErrorMessages;
    });
  }, [lossHistoryData, underWriting]);

  const handleSwitchChange = (questionKey) => {
    const newValue = !underWriting.underWritingQuestions[questionKey];
    setUnderWriting((prevState) => ({
      ...prevState,
      underWritingQuestions: {
        ...prevState.underWritingQuestions,
        [questionKey]: newValue,
      },
    }));

    const newErrorMessages = { ...errorMessages };
    if (newValue) {
      newErrorMessages[questionKey] = t(questionKey);
    } else {
      delete newErrorMessages[questionKey];
    }

    setErrorMessages(newErrorMessages);
  };

  useEffect(() => {
    setUnderWriting((prevState) => ({
      ...prevState,
      underWritingQuestions: {
        ...prevState.underWritingQuestions,
        hasTrampolineAndPoolSecurity: Object?.values(liabilityExposures).some(
          (val) => val === true
        ),
      },
    }));
  }, [liabilityExposures]);

  const renderQuestion = (ques) => {
    const { n_PRPolicyAddStatQuestions_Pk, s_PRQuestionDesc, poolManagerKey } =
      ques;
    const commonProps = {
      px: 4,
      alignItems: "center",
      pt: 5,
      pb: 2,
      style: { marginBottom: "-10px" },
    };

    const renderErrorMessage = (key) =>
      errorMessages[key] && (
        <Box px={3} w={"90%"}>
          <Alert
            status="error"
            borderRadius={"xl"}
            color={"red"}
            p={1.5}
            bg={"transparent"}
            fontSize={"md"}
          >
            {errorMessages[key]}
          </Alert>
        </Box>
      );

    switch (n_PRPolicyAddStatQuestions_Pk) {
      case 13:
        return (
          underWriting?.underWritingQuestions?.hasSolarPanels && (
            <Flex
              px={4}
              alignItems="center"
              pt={3}
              style={{ marginBottom: "-10px" }}
            >
              <UnorderedList pl={3}>
                <ListItem color={"neutral.500"}>{s_PRQuestionDesc}</ListItem>
              </UnorderedList>
              <Spacer />
              <Select
                w={"12%"}
                mb={2}
                value={underWriting?.underWritingQuestions?.solarPanelLocation}
                onChange={(e) =>
                  setUnderWriting((prevState) => ({
                    ...prevState,
                    underWritingQuestions: {
                      ...prevState.underWritingQuestions,
                      solarPanelLocation: e.target.value,
                    },
                  }))
                }
              >
                <option value=""></option>
                {solarPanelLocation.map((item, i) => (
                  <option key={i} value={item.s_AppCodeName}>
                    {item.s_AppCodeNameForDisplay}
                  </option>
                ))}
              </Select>
              {underWriting &&
                underWriting?.underWritingQuestions?.solarPanelLocation ===
                  "" && (
                  <Text pt={2} color="red">
                    Solar Panel Location is required
                  </Text>
                )}
            </Flex>
          )
        );
      case 5:
        return null;
      case 10:
      case 12:
      case 14:
      case 16:
        return null;
      case 15:
        return (
          <Box>
            <Flex
              {...commonProps}
              justifyContent="space-between"
              textAlign="left"
            >
              <Text pr={5}>{s_PRQuestionDesc}</Text>
              <Spacer />
              <Switch
                size="lg"
                pb={4}
                isChecked={underWriting.underWritingQuestions.animalsExtended}
                onChange={(e) => {
                  setUnderWriting((prevState) => ({
                    ...prevState,
                    underWritingQuestions: {
                      ...prevState.underWritingQuestions,
                      animalsExtended: e.target.checked,
                    },
                  }));
                }}
              />
            </Flex>
            {underWriting.underWritingQuestions.animalsExtended && (
              <Box my={4} borderTop={"1px solid"} borderColor={"gray.200"}>
                <Flex
                  {...commonProps}
                  justifyContent="space-between"
                  textAlign="left"
                >
                  <Text pr={5}>{t("animalsExtended")}</Text>

                  <Spacer />
                  <Switch
                    size="lg"
                    pb={4}
                    isChecked={
                      underWriting.underWritingQuestions[poolManagerKey]
                    }
                    onChange={() => handleSwitchChange(poolManagerKey)}
                  />
                </Flex>

                {renderErrorMessage(poolManagerKey)}
              </Box>
            )}
          </Box>
        );

      case 17:
        return (
          <Box>
            <LiabilityExposuresComponent
              key={n_PRPolicyAddStatQuestions_Pk}
              question={s_PRQuestionDesc}
              liabilityExposures={liabilityExposures}
              handleLiabilityExposureChange={handleLiabilityExposureChange}
              showError={
                underWriting?.underWritingQuestions
                  ?.hasTrampolineAndPoolSecurity
              }
            />
          </Box>
        );

      default:
        return (
          <Box>
            <Flex
              {...commonProps}
              justifyContent="space-between"
              textAlign="left"
            >
              <Text pr={5}>{s_PRQuestionDesc}</Text>
              <Spacer />
              <Switch
                size="lg"
                pb={4}
                isChecked={underWriting.underWritingQuestions[poolManagerKey]}
                onChange={() => handleSwitchChange(poolManagerKey)}
              />
            </Flex>
            {renderErrorMessage(poolManagerKey)}
          </Box>
        );
    }
  };

  return (
    <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6} mt={7}>
      <Box
        mb={1}
        background="var(--chakra-colors-primary-50)"
        borderRadius={"xl"}
        p={"0.5rem"}
      >
        <Text
          mt={3}
          pl="1.5rem"
          fontWeight="var(--chakra-fontWeights-semibold)"
          color="black"
          fontSize="1.5rem"
        >
          {t("Underwriting Questions")}
        </Text>
      </Box>
      {underWriterQues.length && isBaseDataLoaded ? (
        underWriterQues.map((ques) => (
          <Box
            pl={2}
            key={ques.n_PRPolicyAddStatQuestions_Pk}
            fontSize={"17.5px"}
          >
            {renderQuestion(ques)}
            {(!underWriting?.underWritingQuestions?.hasSolarPanels &&
              ques.n_PRPolicyAddStatQuestions_Pk === 13) ||
            (!underWriting?.underWritingQuestions?.hasSwimmingPool &&
              ques.n_PRPolicyAddStatQuestions_Pk === 5) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 14 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 16 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 5 ||
            (!underWriting?.underWritingQuestions?.additionalRisks &&
              ques.n_PRPolicyAddStatQuestions_Pk === 11) ||
            ques.n_PRPolicyAddStatQuestions_Pk === 10 ||
            ques.n_PRPolicyAddStatQuestions_Pk === 12 ? null : (
              <Box my={4} borderTop={"1px solid"} borderColor={"gray.200"} />
            )}
          </Box>
        ))
      ) : (
        <Text>No underwriting questions found.</Text>
      )}
      {!underWriting?.underWritingQuestions?.additionalRisks && <Box my={3} />}
      {underWriting?.underWritingQuestions?.additionalRisks &&
        isBaseDataLoaded && (
          <Flex
            px={4}
            alignItems="center"
            justifyContent={"space-between"}
            py={4}
          >
            <UnorderedList pl={3}>
              <ListItem color={"neutral.500"}>
                Please Select Additional Risks (Multiple Allowed)
              </ListItem>
            </UnorderedList>
            <MultiSelect
              options={riskAssociated.map((item) => ({
                label: item.s_AppCodeNameForDisplay,
                value: item.s_AppCodeName,
              }))}
              setSelected={setSelectedRisk}
              selected={selectedRisk}
              placeholder="Select Additional Risks"
            />
          </Flex>
        )}
      <Box borderTop={"1px solid"} borderColor={"gray.200"} my={2} />
      <Flex pt={1} mb={"-5px"}>
        <Accordion
          borderColor={"transparent"}
          width={"100%"}
          index={discounts?.hasHomeInsuranceInForce ? 0 : 1}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box borderRadius={"xl"} border={"none"}>
                <h2>
                  <AccordionButton
                    _hover={{ bg: "none" }}
                    pr={0}
                    borderBottomRadius={"xl"}
                    borderRadius={"xl"}
                    pt={"2"}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          hasHomeInsuranceInForce:
                            !discounts?.hasHomeInsuranceInForce,
                        });
                      }}
                    >
                      <Text pt={4} pl={2} fontSize={"17.5px"}>
                        {t(
                          "Does the Applicant currently have home insurance in force?"
                        )}
                      </Text>
                    </Box>
                    <Switch
                      size={"lg"}
                      mr={"1rem"}
                      isChecked={discounts?.hasHomeInsuranceInForce}
                      onChange={(e) =>
                        setDiscounts({
                          ...discounts,
                          hasHomeInsuranceInForce: e.target.checked,
                        })
                      }
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Grid w={"100%"} templateColumns="repeat(1 , 1fr)" gap={6}>
                    <GridItem>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <UnorderedList>
                          <ListItem>
                            <Text fontSize={"lg"}>{t("Prior Carrier")}</Text>
                          </ListItem>
                          <Box
                            my={0}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </UnorderedList>
                        <Box w={"30%"}>
                          <Input
                            type="text"
                            value={
                              discounts?.priorPolicy?.propertyInfo
                                ?.s_PriorCompanyNAIC || ""
                            }
                            onChange={(e) => {
                              setDiscounts({
                                ...discounts,
                                priorPolicy: {
                                  ...discounts.priorPolicy,
                                  propertyInfo: {
                                    ...discounts.priorPolicy.propertyInfo,
                                    s_PriorCompanyNAIC: e.target.value,
                                  },
                                },
                              });
                            }}
                            size={"lg"}
                            placeholder="Prior Carrier -"
                            className="inputBorder"
                          />

                          {validationError?.discounts &&
                            !discounts?.priorPolicy?.propertyInfo
                              ?.s_PriorCompanyNAIC && (
                              <Text
                                textAlign={"right"}
                                color={"red"}
                                fontSize={"14px"}
                              >
                                {t("Please enter Prior Carrier")}
                              </Text>
                            )}
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <UnorderedList>
                          <ListItem>
                            <Text fontSize={"lg"}>
                              {t("Prior Policy Number")}
                            </Text>
                          </ListItem>
                          <Box
                            my={0}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </UnorderedList>
                        <Box w={"30%"}>
                          <Input
                            type="text"
                            value={
                              discounts?.priorPolicy?.propertyInfo
                                ?.s_PriorPolicyNo || ""
                            }
                            onChange={(e) => {
                              setDiscounts({
                                ...discounts,
                                priorPolicy: {
                                  ...discounts.priorPolicy,
                                  propertyInfo: {
                                    ...discounts.priorPolicy.propertyInfo,
                                    s_PriorPolicyNo: e.target.value,
                                  },
                                },
                              });
                            }}
                            size={"lg"}
                            placeholder="Prior Policy Number -"
                            className="inputBorder"
                          />
                          {validationError?.discounts &&
                            !discounts?.priorPolicy?.propertyInfo
                              ?.s_PriorPolicyNo && (
                              <Text
                                textAlign={"right"}
                                color={"red"}
                                fontSize={"14px"}
                              >
                                {t("Policy number is required")}
                              </Text>
                            )}
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <UnorderedList>
                          <ListItem>
                            <Text fontSize={"lg"}>
                              {t("Policy Expiration Date")}
                            </Text>
                          </ListItem>
                          <Box
                            my={0}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </UnorderedList>
                        <Box w={"15%"}>
                          <DateInput
                            value={
                              discounts?.priorPolicy?.propertyInfo
                                ?.s_PriorPolicyExpDt || ""
                            }
                            onChange={(e) => {
                              setDiscounts({
                                ...discounts,
                                priorPolicy: {
                                  ...discounts.priorPolicy,
                                  propertyInfo: {
                                    ...discounts.priorPolicy.propertyInfo,
                                    s_PriorPolicyExpDt: e,
                                  },
                                },
                              });
                            }}
                            styleProps={{
                              size: "lg",
                              readOnly: false,
                              width: "100%",
                              className: "inputBorder",
                            }}
                          />
                          {validationError?.discounts &&
                            !discounts?.priorPolicy?.propertyInfo
                              ?.s_PriorPolicyExpDt && (
                              <Text
                                textAlign={"right"}
                                color={"red"}
                                fontSize={"14px"}
                              >
                                {t("Policy Expiration Date is required")}
                              </Text>
                            )}
                        </Box>
                      </Flex>
                    </GridItem>
                  </Grid>
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
      <Box borderTop={"1px solid"} borderColor={"gray.200"} my={2} />
      <Flex pt={1}>
        <Accordion borderColor={"transparent"} width={"100%"}>
          <AccordionItem>
            {({ isExpanded }) => (
              <Box borderRadius={"xl"}>
                <h2>
                  <AccordionButton pr={0} _hover={{ bg: "none" }} pt={"2"}>
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          isApplicantLocatedNorhI10:
                            !discounts?.isApplicantLocatedNorhI10,
                        });
                      }}
                    >
                      <Text fontSize={"18px"} pt={3} pl={2}>
                        {t(
                          "Is the Applicant's premises located north of I-10 ?"
                        )}
                      </Text>
                    </Box>
                    <Switch
                      size="lg"
                      mr={"1rem"}
                      isChecked={discounts?.isApplicantLocatedNorhI10}
                      onChange={(e) =>
                        setDiscounts({
                          ...discounts,
                          isApplicantLocatedNorhI10: e.target.checked,
                        })
                      }
                      disabled={coverages.deductibles.isCountyRisky === true}
                    />
                  </AccordionButton>
                </h2>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
    </Box>
  );
}
