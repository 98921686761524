import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Form,  Table, Modal} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {  FaPlus, FaFileUpload, FaTimes } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../services/commonService";
import { createAchConfig, listAchConfig, getAchHelp,updateSignStatus } from "../../services/producerService";
import { AiOutlineEye, AiOutlineCheckCircle } from "react-icons/ai";
import Moment from 'moment';
import { toast } from 'react-toastify';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const ACHInformation = (props) => {
    const { t } = useTranslation();
    const [docType, setDocType] = useState("");
    const [fileSelected, setFiles] = React.useState([]);
    const [EffectiveDate, setEffectiveDate] = React.useState(new Date());
    const [achType, setAchType] = useState("");
    const [accountType, setAccounType] = useState("");
    const [achData, setAchData] = useState([]);
    const [loading, setLoader] = useState(false);
    const [accountNumber, setAccounNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [instituteName, setInstituteName] = useState("");
    const [instituteAddress, setInstituteAddress] = useState("");
    const [pageFilter, setPageFilter] = useState({ page: 1, pageLength: 10 });
    const [achOpen, setAchOpen] = useState(false);
    const [prodName, setProdName] = useState("");
    const [isDocumentConcentOpen, setIsDocumentConcentOpen] = useState(false);
    const [currentRow,setCurrentRow] = useState([]);
    let userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    useEffect(() => { 
        getAchConfig()
     }, []);
   
     const onDrop = useCallback(event => {
        setFiles([event[0]])
    }, [])

    
    let { acceptedFiles, isDragActive, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false,
    //      accept: {
    //     'image/png': ['.png'],
    //     'text/html': ['.html', '.htm'],
    //   } 
    });
    var files = fileSelected.map((file, i) => (
        <li key={file.path}>
            {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => remove(i)} />
        </li>
    ));
    
    const handleDocumentConcent = async (row) => {
        setLoader(true)
        let res = await getAchHelp();
        setLoader(false)
        if(res){
            setProdName(res.data.producerData.s_FullLegalName);
        }
        setIsDocumentConcentOpen(true);
        setAchOpen(true);
        setCurrentRow(row);

    }
    const remove = file => {
        // const newFiles = [...files];
        // newFiles.splice(file, 1);
        // acceptedFiles.splice(file, 1);
        // console.log(newFiles);
        setFiles([]);
    };

    const statusFormatter = (cell,row) =>{
        let status = ''
        if(row.status === 'P')
        {
            status = 'Pending'
        }
        if(row.status === 'A')
        {
            status = 'Approved'
        }
        if(row.status === 'R')
        {
            status = 'Rejected'
        }
        if(row.status === 'I')
        {
            status = 'Inactive'
        }
        return status
    }

    const actionFormatterApprove = (value, row) => {
        let metadata = JSON.parse(row.metadata)
            return (
                <>
                    
                   { metadata[0].is_signed == 'P' ? <AiOutlineCheckCircle size={26} style={{  cursor: "pointer" }} onClick={(e) => {
                        handleDocumentConcent(row)
                    }} />
                    :  metadata[0].is_signed == 'R' ? <p style={{color:"red"}} >Rejected</p> : metadata[0].is_signed == 'A' ? <p style={{color:"Green"}} >Approved</p> :null
                    }
                   
                    
                </>
            )
    }
    const actionFormatterView = (value, row) => {
      
            return (
                <>
                    <AiOutlineEye size={26} style={{ cursor: "pointer" }} onClick={() => window.open(row.docUrl)}> {value} </AiOutlineEye> &nbsp;    
                </>
            )
    }

    const columns = [
        {
            dataField: "ach_type",
            text: "ACH Type",
        },
        {
            dataField: "account_type",
            text: "Account Type",
        },
        {
            dataField: "account_number",
            text: "Account Number",
        },
        {
            dataField: "routing_number",
            text: "Routing Number",
        },
        {
            dataField: "financial_institution_name",
            text: "Financial Institution Name",
        },
        {
            dataField: "doc_type",
            text: "Document Type",
        },
        {
            dataField: "effective_date",
            text: "Effective Date",
        },
        {
            dataField: "status",
            text: "Status",
            formatter: statusFormatter
        },
        {
            dataField: "view",
            text: "View",
            formatter: (value, row,index) => actionFormatterView(value, row,index),
            style: {
                width: "5%"
            },
        },
        {
            dataField: "verify",
            text: "Verify Document",
            formatter: (value, row,index) => actionFormatterApprove(value, row,index),
            style: {
                width: "8%"
            },
        },
    ];

    const handlePageChange = (page, sizePerPage) => {
        setPageFilter({ ...pageFilter, page: page, pageLength: sizePerPage });
    };

    const renderShowsTotal = (start, to, total) => {
        return showDataTableTotalText(start, to, total);
    };

    const options = {
        page: pageFilter.page,
        sizePerPage: pageFilter.pageLength,
        sortIndicator: false,
        pageStartIndex: 1,
        paginationShowsTotal: renderShowsTotal,
        onPageChange: handlePageChange,
        totalSize: props.detailsTotal
    };

    const closeAchModal = () => {
        setAchOpen(false);
    }

    const handleSubmit = async () =>{
        
        if(validateData()){
            let res = await getAchHelp();
            if(res){
                setProdName(res.data.producerData.s_FullLegalName);
            }
        }
        // else
        // {
        //     toast.error("All fields are Required !")
        // }
    }

    const achHelp = async () => {
        closeAchModal()
        setLoader(true);
        const formData = new FormData();
        formData.append("file", fileSelected[0], fileSelected[0].name);
        formData.append("ach_type", achType);
        formData.append("account_type", accountType);
        formData.append("account_number", accountNumber);
        formData.append("routing_number", routingNumber);
        formData.append("financial_institution_name", instituteName);
        formData.append("doc_type", docType);
        formData.append("effective_date", Moment(EffectiveDate).format("YYYY-MM-DD"));
        formData.append("status", 'P');
        formData.append("person_id", userDatas.persInfoAgencyFK);
        formData.append("account_id", userDatas.n_AgencyAddlInfoId_PK);
        formData.append("financial_institution_address", instituteAddress);
        
        insertAchConfig(formData);
        setLoader(false);
    }

    const insertAchConfig = async(params) =>{
        setLoader(true)
        await createAchConfig(params).then((res)=>{
        if(res.data.success){
            setFiles([])
            resetForm();
            setAchOpen(false);
            setLoader(false);
            toast.success(res.data.message);
            getAchConfig() ;
       }
       else{
            setLoader(false);
            toast.error("Something went wrong !");
       }
       }).catch((err)=>{
        if(err.errors){
            let keys = Object.keys(err.errors);
            setLoader(false);
            keys.map(key=>(
                toast.error(key + ' is invalid ')
            ))
        }
       })
       
       
    }
    const getAchConfig = async() =>{
        setLoader(true)
       let res = await listAchConfig(userDatas.persInfoAgencyFK);
       
       if(res.data.success){
        setLoader(false)
          setAchData(res.data.data)
       }
       else{
            setLoader(false)
            toast.error("Something went wrong !")
       }
    }
     const validateData = () =>{
        let isRoutingNoValid = validateRoutingNo(routingNumber);
         if(achType && docType  && accountType  && accountNumber && accountNumber != '' && routingNumber &&accountNumber.length <= 17 && routingNumber.length === 9 && isRoutingNoValid && instituteName  && instituteAddress !== '' &&     EffectiveDate !== '' && fileSelected.length > 0  ){
            setAchOpen(true);
             return true;
        }else{
            if(accountNumber.length > 17){
                toast.error("Account Number length must be less than equal to 17 digits!")
            }
            if(routingNumber.length !== 9){
                toast.error("Routing Number must be of 9 digits !")
            }
            if(!isRoutingNoValid){
                toast.error("Invalid Routing Number !")
            }
            else{
                toast.error("All fields are Required !")  
            }
            
        }
        return false;
     }

     const resetForm =() =>{
        setDocType('');
        setFiles([]);
        setEffectiveDate('');
        setAchType('');
        setAccounType('');
        setAchData('');
        setAccounNumber('');
        setRoutingNumber('');
        setInstituteName('');
        setInstituteAddress('');
     }

    const accountTypeChange = () => {
        if (accountType === "CHECKING"){
            setDocType("VOIDED_CHECK")
        }

        if (accountType === "SAVINGS"){
            setDocType("DEPOSIT_SLIP")
        }

        if (accountType === "") {
            setDocType("")
        }
    }

    const handleAchSignStatus = async (status) => {
        setLoader(true)
        let res = await updateSignStatus(currentRow.id,status)
        if(res.data.success){
            setLoader(false);
            closeAchModal();
            setIsDocumentConcentOpen(false)
            toast.success(res.data.message);
            getAchConfig() ;
        }
        else{
            closeAchModal();
            setLoader(false);
            setIsDocumentConcentOpen(false)
            toast.error("Status not updated !")
        }

    }

    const validateRoutingNo = (routingNo) => {
        let CHECK_DIGIT_WEIGHTS = [3, 7, 1, 3, 7, 1, 3, 7];
        let transNo = routingNo.substring(0, 8); 
        let checkDigit = parseInt(routingNo.substring(8)); 
        let transitNumber = parseInt(transNo);
        let transitSum = 0;
        let reverseWeights = CHECK_DIGIT_WEIGHTS.reverse();
        for (let i = 0; i<reverseWeights.length ; i++){
            let digit = transitNumber % 10;
            transitSum += digit * reverseWeights[i];
            transitNumber = parseInt(transitNumber / 10);
        }
        let calculatedCheckDigit = (10 - (transitSum % 10)) % 10;
        return checkDigit === calculatedCheckDigit;
    }

    return (
        <>
        <LoadingOverlay>
            <Loader loading={loading}/>
            <div className="inbox-page-section">
                <h4 className="activity-log-title">ACH Information</h4>
                <Row>
                    <Col xs="12">
                        <div className="card-header-main">
                            <div className="card-header-main-title">
                                Upload Files
                            </div>
                        </div>
                        <div className="card-body-main">
                            <Row className="align-items-baseline">
                                <Col>
                                    <Form.Label className="confirm-address-label">
                                        {t('ACH Type')}
                                    </Form.Label>
                                    <Form.Group>
                                        <Form.Select
                                            className="dashboard-group-input-flood"
                                            name="ach_type"
                                            id="ach_type"
                                            onChange={(e) => setAchType(e.currentTarget.value)}
                                            value={achType}
                                        >
                                            <option value={''}>{t('Select ACH Type')}</option>
                                            <option value={'PREMIUM'}>{t('Premium')}</option>
                                            <option value={'COMMISSION'}>{t('Commission')}</option>
                                            {/* {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                                <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                            ))} */}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className="confirm-address-label">
                                        {t('Account Type')}
                                    </Form.Label>
                                    <Form.Group>
                                        <Form.Select
                                            className="dashboard-group-input-flood"
                                            name="doc_type"
                                            id="doc_type"
                                            onChange={(e) => setAccounType(e.currentTarget.value)}
                                            value={accountType}
                                            onBlur={accountTypeChange}
                                        >
                                            <option value={''}>{t('Select Account Type')}</option>
                                            <option value={'CHECKING'}>{t('Checking')}</option>
                                            <option value={'SAVINGS'}>{t('Savings')}</option>
                                            {/* {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                                <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                            ))} */}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label className="confirm-address-label">
                                        {t('Account Number')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        placeholder={t('Account Number')}
                                        type="number"
                                        onChange={(e) => setAccounNumber(e.target.value.substring(0, 17))}
                                        value={accountNumber}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label className="confirm-address-label">
                                        {t('Routing Number')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        placeholder={t('Routing Number')}
                                        type="number"
                                        onChange={(e) => setRoutingNumber(e.target.value.substring(0, 9))}
                                        value={routingNumber}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row className="align-items-baseline">
                                <Col xs="4">
                                    <Form.Label className="confirm-address-label">
                                        {t('Financial Institution Name')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        placeholder={t('Financial Institution Name')}
                                        type="text"
                                        onChange={(e) => setInstituteName(e.target.value)}
                                        value={instituteName}
                                    />
                                </Col>
                                <Col xs="8">
                                    <Form.Label className="confirm-address-label">
                                        {t('Financial Institution Address')}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input-flood"
                                        placeholder={t('Financial Institution Address')}
                                        type="text"
                                        onChange={(e) => setInstituteAddress(e.target.value)}
                                        value={instituteAddress}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row className="align-items-baseline">
                                <Col xs="5" style={{ marginTop: "25px" }}>
                                    <Form.Group>
                                        <Form.Label className="confirm-address-label mt-2">
                                            {t('Document Type')}
                                        </Form.Label>
                                        <Form.Select
                                            className="dashboard-group-input-flood"
                                            name="docuemntType"
                                            id="docuemntType"
                                            onChange={(e) => setDocType(e.currentTarget.value)}
                                            value={docType}
                                        >
                                            <option value={''}>{t('Select Document Type')}</option>
                                            <option value={'VOIDED_CHECK'}>{t('Voided Check')}</option>
                                            <option value={'DEPOSIT_SLIP'}>{t('Deposit Slip')}</option>
                                            {/* {documentTypesDD.length > 0 && documentTypesDD.map((docType, i) => (
                                                <option key={i} value={docType.n_DocType_PK}>{docType.s_DocTypeNameForDisplay}</option>
                                            ))} */}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group style={{marginTop:"15px"}}>
                                        <Form.Label className="confirm-address-label">
                                            {t('Effective Date')}
                                        </Form.Label>
                                        <br />
                                        <DatePicker
                                            id="dashboard-datepicker"
                                            className="datepicker-full-width"
                                            name="effective date"
                                            onChange={(date) => setEffectiveDate(date)}
                                            selected={EffectiveDate}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="7" style={{ marginTop: "25px" }}>
                                    <span><b>Attachment Detail</b></span>
                                    <div id="drop-document-type-div"
                                        {...getRootProps({ className: "dropzone" })}
                                    >
                                        <input type="file"
                                            multiple={false}
                                            autocomplete="off"
                                            className="inp-file"
                                            // onChange={uploadFile}
                                            {...getInputProps()}
                                        />
                                        <FaFileUpload size={34} color={"#64D6CD"} />
                                        <p>attach .jpeg,.jpg,.png and .pdf file only </p>
                                       {fileSelected.length == 0 ? <Button id="common-btn" variant="primary">
                                            <FaPlus size={15} />
                                            Add File
                                        </Button> : null}
                                    </div>
                                    <aside className="reply-document-name">
                                        <ul>{files}</ul>
                                    </aside>
                                </Col>
                                <Col xs="12" style={{ marginTop: "25px" }}>
                                    <Table>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>
                                                <Button id="common-btn" variant="primary"  onClick={handleSubmit}>
                                                Submit
                                            </Button>{" "}</th>
                                        </tr>
                                    </Table>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row id="dashboard-table-section">
                    <Col xs="12" style={{ marginTop: "50px" }}>
                        <div className="table-main-title-div">
                            <span className="table-main-title-name">{t('List ACH Information')}</span>
                        </div>
                    </Col>
                    <Col xs="12">
                        <BootstrapTable
                            wrapperClasses="management-functional-table"
                            bootstrap4
                            keyField="AchType"
                            data={achData}
                            columns={columns}
                            hover
                            pagination={paginationFactory(options)}
                        />
                    </Col>
                </Row>
            </div>
        </LoadingOverlay>

        <Modal show={achOpen} onHide={closeAchModal} size="lg" style={{ marginLeft:"2%" }} >
          <LoadingOverlay>
          <Loader loading={loading}/>
            <Modal.Body>
                <h4 style={{textAlign:"center"}}>AUTHORIZATION AGREMENT <br />FOR  DIRECT DEPOSIT (ACH CREDITS)</h4>
                <hr />
                    <p>By pressing the submit button you are digitally signing the Authorization Agreement for Direct Deposit (ACH Credits) between The Flood Insurance Processing Center (the "Company") and {prodName}</p><br />
                <p>I (we) hereby authorize the Company to initiate credit entries and to initiate, if necessary, debit entries and adjustments to my (our) account previously entered.</p><br />
                <p>This authority is to remain in full force and effect until the Company has received written notification from me of its termination in such time and manner as to afford the Company and Financial Institution a reasonable opportunity to act on it.</p>
            </Modal.Body>
       <Modal.Footer style={{display:"flex", justifyContent:"center"}}>
       { !isDocumentConcentOpen  ? 
             <>
             <Button variant="danger" onClick={closeAchModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={achHelp}>
                    Submit
                </Button> 
                </>   
                :
                <>
                
                <Button variant="success" onClick={(e) => handleAchSignStatus('A')}>
                    Approve
                </Button>
                <Button variant="danger" onClick={(e) => handleAchSignStatus('R')}>
                    Reject
                </Button>
                <Button variant="info" onClick={closeAchModal}>
                    Close
                </Button>
                </>
}
            </Modal.Footer>  
                
            </LoadingOverlay>
            
        </Modal>
        </>
    )
}

export default ACHInformation;