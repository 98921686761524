import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Input, Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from "react-overlay-loader";

const ChangeInsuredName = (props) => {
    const { applicantNameEndorseJSON = {} } = props;
    const [fname, setfname] = useState('');
    const [mname, setMname] = useState('');
    const [lname, setLname] = useState('');
    const [tname, setTname] = useState('');
    const [personInfoPK, setPersonInfoPK] = useState('');

    useEffect(() => {
        setfname(props.applicantData.s_FirstName);
        setMname(props.applicantData.s_MiddleName);
        setLname(props.applicantData.s_LastOrganizationName);
        setTname(props.applicantData.s_FullLegalName);
        
        if (applicantNameEndorseJSON) {
            if (applicantNameEndorseJSON['fullLegalName']) {
                setTname(applicantNameEndorseJSON['fullLegalName']['fullLegalName']);
            }
            if (applicantNameEndorseJSON['firstName']) {
                setfname(applicantNameEndorseJSON['firstName']['firstName']);
            }
            if (applicantNameEndorseJSON['lastName']) {
                setLname(applicantNameEndorseJSON['lastName']['lastName']);
            }
            if (applicantNameEndorseJSON['middleName']) {
                setMname(applicantNameEndorseJSON['middleName']['middleName']);
            }
        }
        setPersonInfoPK(props.applicantData.personInfoPK);
    }, [props.applicantData, applicantNameEndorseJSON])

    useEffect(() => {
        const fullName = [fname, mname, lname].filter(Boolean).join(' ').trim();
        if (fullName && props.applicantData && props.applicantData.s_FullLegalName) {
            setTname(fullName.toUpperCase());
            props.handleChange("fullLegalName", props.applicantData.s_FullLegalName, fullName.toUpperCase(), "ApplicantName");

        }
    }, [fname, mname, lname]);

    const handleStateChange = (value, state) => {
        switch (state) {
            case 'firstName':
                setfname(value);
                props.handleChange("firstName", props.applicantData.s_FirstName , value, "ApplicantName");
            break;
            case 'middleName':
                setMname(value);
                props.handleChange("middleName", props.applicantData.s_MiddleName, value, "ApplicantName");
            break;
            case 'lastName':
                setLname(value);
                props.handleChange("lastName", props.applicantData.s_LastOrganizationName, value, "ApplicantName");
            break;
            case 'titleName':
                setTname(value);
                props.handleChange("fullLegalName", props.applicantData.s_FullLegalName, value, "ApplicantName");
            break;
        }
    }
    const update = (e) => {
        props.updateParentState("fullLegalName", tname);
        props.resetModal()
    }
    
    return (
        <LoadingOverlay>
            <Loader loading={props.isLoading} />
            <Row className='mb-1'>
                <Col md='4'><label style={{ fontWeight: 'normal' }}>First Name</label></Col>
                <Col md='8'><input type='text' className="form-control dashboard-group-input" bsSize='sm' name='firstName' value={fname} onChange={(e) => handleStateChange(e.currentTarget.value.toUpperCase(),'firstName')} /></Col>
            </Row>
            <Row className='mb-1'>
                <Col md='4'><label style={{ fontWeight: 'normal' }}>Middle Name</label></Col>
                <Col md='8'><input type='text' className="form-control dashboard-group-input" bsSize='sm' name='middleName' value={mname} onChange={(e) => handleStateChange(e.currentTarget.value.toUpperCase(),'middleName')} /></Col>
            </Row>
            <Row className='mb-1'>
                <Col md='4'><label style={{ fontWeight: 'normal' }}>Last Name</label></Col>
                <Col md='8'><input type='text' className="form-control dashboard-group-input" bsSize='sm' name='lastName' value={lname} onChange={(e) => handleStateChange(e.currentTarget.value.toUpperCase(),'lastName')} /></Col>
            </Row>
            <Row className='mb-1'>
                <Col md='4'><label style={{ fontWeight: 'normal' }}>Name As On Title.</label></Col>
                <Col md='8'><input type='text' className="form-control dashboard-group-input" bsSize='sm' name='TitleName' readOnly value={tname} onChange={(e) => handleStateChange(e.currentTarget.value.toUpperCase(),'titleName')} /></Col>
            </Row>
            <Row>
                <Col md='12' className='text-center'>
                    <Button type='button' size='sm' color='info' onClick={() =>  update()}><i className='fa fa-save'></i>&nbsp;Change</Button>&nbsp;&nbsp;
                </Col>
            </Row>
        </LoadingOverlay>
    )
}

ChangeInsuredName.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(ChangeInsuredName);