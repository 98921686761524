import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, connect } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import "./coverage.css";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertIcon,
  Box,
  Card,
  Flex,
  Grid,
  GridItem,
  HStack,
  Select,
  Spacer,
  Text,
} from "@chakra-ui/react";
import CurrencyInput from "react-currency-input-field";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";

const Coverage = (props) => {
  const [quoteData, setQuoteData] = useState(props?.quoteData);
  const [showError, setShowError] = useState(false);
  const [minValue] = useState(
    props?.quoteData?.propertyCoverageInfo?.metadata?.fp_proposed_coverage
      ?.coverage_a || 0
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let otherStructureLimits = [];
  for (let i = 0; i <= 15; i++) {
    otherStructureLimits.push(i);
  }

  const [contentsLimit, setContentsLimit] = useState([]);
  const [basicCoverages, setBasicCoverages] = useState([]);
  const [coverageItems, setCoverageItems] = useState([]);
  const [additionalCoverageItems, setAdditionalCoverageItems] = useState([]);

  useEffect(() => {
    setQuoteData({
      ...quoteData,
      propertyCoverageInfo: {
        ...quoteData?.propertyCoverageInfo,
        n_coverageA: basicCoverages?.costToRebuildHome,
        n_coverageB: basicCoverages?.otherStructures,
        n_coverageC: basicCoverages?.contents,
        n_coverageE: basicCoverages?.personalLiability,
        n_CoverageF: basicCoverages?.medicalPayments,
      },
    });
  }, [
    basicCoverages.costToRebuildHome,
    basicCoverages.personalLiability,
    basicCoverages?.medicalPayments,
  ]);

  useEffect(() => {
    setBasicCoverages(() => ({
      ...quoteData,
      costToRebuildHome: parseFloat(
        quoteData.propertyCoverageInfo?.n_coverageA || 0
      ), //coverage a
      otherStructures: parseFloat(
        quoteData.propertyCoverageInfo?.n_coverageB || 0
      ), //coverage b
      otherStructuresPercent: 10, //parseInt(
      //   (parseInt(props?.quoteData?.propertyCoverageInfo?.n_coverageB) * 100) /
      //     parseInt(props?.quoteData?.propertyCoverageInfo?.n_coverageA) || 0
      // ),
      contents: parseFloat(quoteData.propertyCoverageInfo?.n_coverageC || 0), //coverage c
      contentsPercent:
        parseInt(
          (parseInt(props?.quoteData?.propertyCoverageInfo?.n_coverageC) *
            100) /
            parseInt(props?.quoteData?.propertyCoverageInfo?.n_coverageA) || 0
        ) || 50,
      lossOfUse: parseFloat(quoteData.propertyCoverageInfo?.n_coverageD) || 0,
      personalLiability: parseFloat(
        quoteData.propertyCoverageInfo?.n_coverageE || 0
      ), //coverage e
      medicalPaymentsToOthers: parseFloat(
        quoteData.propertyCoverageInfo?.n_CoverageF || 0
      ), //coverage f
    }));
  }, [props?.quoteData?.propertyCoverageInfo]);

  useEffect(() => {
    setCoverageItems(props?.getBaseData?.all_basic_Coverage);
  }, [props?.getBaseData?.all_basic_Coverage]);

  useEffect(() => {
    setAdditionalCoverageItems(props?.getBaseData?.all_endorsement_Coverage);
    setContentsLimit(props?.getBaseData?.CPersonalPropPer ?? []);
  }, [
    props?.getBaseData?.all_endorsement_Coverage,
    props?.getBaseData?.CPersonalPropPer,
  ]);

  const handleChange = (name, value) => {
    const parsedValue = parseFloat(value || 0);

    // Update basic coverages
    if (name in basicCoverages) {
      basicCoverages[name] = parsedValue;
    }

    // Calculate dependent values
    updateDependentCoverages(name);

    // Update quote data if available
    if (props.quoteData?.propertyCoverageInfo) {
      updateQuoteData(name, parsedValue);
    }

    dispatch(getEditEmptyQuoteData(props.quoteData));
  };

  const updateDependentCoverages = (changedField) => {
    const { costToRebuildHome, otherStructuresPercent, contentsPercent } =
      basicCoverages;

    if (
      changedField === "costToRebuildHome" ||
      changedField === "otherStructuresPercent"
    ) {
      basicCoverages.otherStructures =
        (costToRebuildHome * otherStructuresPercent) / 100;
    }

    if (
      changedField === "costToRebuildHome" ||
      changedField === "contentsPercent"
    ) {
      basicCoverages.contents = (costToRebuildHome * contentsPercent) / 100;
      basicCoverages.lossOfUse = (costToRebuildHome * 30) / 100;
    }
  };

  const updateQuoteData = (name, parsedValue) => {
    const { propertyCoverageInfo } = props.quoteData;

    switch (name) {
      case "costToRebuildHome":
        propertyCoverageInfo.n_coverageA = parsedValue;
        propertyCoverageInfo.n_coverageB = basicCoverages.otherStructures;
        propertyCoverageInfo.n_coverageC = basicCoverages.contents;
        propertyCoverageInfo.n_coverageD = basicCoverages.lossOfUse;
        break;
      case "otherStructuresPercent":
        propertyCoverageInfo.n_coverageB = basicCoverages.otherStructures;
        break;
      case "contentsPercent":
      case "lossOfUse":
        propertyCoverageInfo.n_coverageC = basicCoverages.contents;
        propertyCoverageInfo.n_coverageD = basicCoverages.lossOfUse;
        break;
      case "personalLiability":
        propertyCoverageInfo.n_coverageE = parsedValue;
        break;
      case "medicalPaymentsToOthers":
        propertyCoverageInfo.n_CoverageF = Math.trunc(parsedValue);
        break;
      default:
        propertyCoverageInfo[name] = parsedValue;
    }
  };

  const currencyFormat = (num) => {
    if (!num) {
      num = 0;
    }
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const renderTootTip = (id) => {
    return (
      <>
        <ReactTooltip
          id={id}
          delayHide={500}
          effect="solid"
          className={id && "custom-tooltip"}
          place="right"
        >
          {id === "Cost to Rebuild Home" && (
            <Box fontSize={"15px"}>
              This covers the cost to repair your home after a loss. The cost
              depends on factors like materials, style, and age. If you're
              switching insurers, you might use the replacement cost on your
              current insurance as guidance. Keep in mind this is not the same
              as the market value or the purchase price of your home. Tip: This
              coverage is generally referred to as Dwelling or (Coverage A).
            </Box>
          )}
          {id === "Other Structures" && (
            <Box fontSize={"15px"}>
              This covers other structures on your property that aren't your
              house, like a shed, fence, gazebo, or detached garage. If you're
              switching insurers, you might use your previous coverage as
              guidance. Tip: This coverage is generally referred to as (Coverage
              B).
            </Box>
          )}
          {id === "Contents" && (
            <Box fontSize={"15px"}>
              This covers your belongings, like your furniture, clothing, and
              electronics. Estimate how much it would cost to replace all your
              stuff in case of a fire or robbery or at the very least, the
              things you can't live without. Tip: This coverage is generally
              referred to as Personal Property or (Coverage C).
            </Box>
          )}
          {id === "Personal Liability" && (
            <Box fontSize={"15px"}>
              This covers you in the event you're involved in an accident where
              someone is injured or their property is damaged, regardless of
              location. This coverage can help pay for any resulting legal fees,
              medical fees, or damage for which you are held responsible. Tip:
              This coverage is affordable, even at the maximum amounts. This is
              coverage is generally referred to as (Coverage E).
            </Box>
          )}
          {id === "Medical Payments To Others" && (
            <Box fontSize={"15px"}>
              If a guest is injured on your property, you may have to cover
              their medical costs. This coverage will help you proactively
              assist your injured guest. Tip: This coverage is affordable at the
              maximum amounts. This coverage is generally referred to as
              (Coverage F).
            </Box>
          )}
          {id === "Loss of Use" && (
            <Box fontSize={"15px"}>
              This coverage will help you pay for temporary housing and living
              expenses if you can't live in your home while it's being repaired
              or replaced. This coverage is generally referred to as (Coverage
              D).
            </Box>
          )}
        </ReactTooltip>
        <style>
          {`
          .custom-tooltip {
            width: 500px;
            height: auto;
          }
        `}
        </style>
      </>
    );
  };

  return (
    <>
      <Box>
        {showError && (
          <Alert my={1} borderRadius={"lg"} status="warning">
            <AlertIcon />
            <Text fontWeight={"500"} fontSize={"md"} as="span">
              "Cost to Rebuild Home" cannot be less than $
              {currencyFormat(minValue)} for this property
            </Text>
          </Alert>
        )}
        <Grid templateColumns={"repeat(2, 1fr)"} gap={5}>
          <GridItem>
            <Box textAlign="left" className="coverageTitle">
              <Text fontSize={"xl"} mt={3} pl={1}>
                {t("basic_coverages")}
              </Text>
            </Box>
            <Card p={3}>
              {coverageItems
                ?.filter(
                  (item) =>
                    // item.s_ScreenName !== "Loss of Use" &&
                    item.s_ScreenName !== "Accidental Death"
                )
                ?.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Flex>
                        <HStack>
                          <Text
                            pl="10px"
                            fontWeight="var(--chakra-fontWeights-medium)"
                          >
                            {item?.s_ScreenName}{" "}
                          </Text>
                          <Text ml={"-2px"} color={"red"}>
                            *
                          </Text>
                          <a
                            style={{
                              marginBottom: "18px",
                              marginLeft: "-10px",
                              cursor: "pointer",
                            }}
                            data-tip
                            data-for={item?.s_ScreenName}
                          >
                            {" "}
                            <Box ml={1}>
                              <FcInfo size={18} />
                            </Box>
                            {renderTootTip(item?.s_ScreenName)}
                          </a>
                        </HStack>
                        <Spacer />
                        {item?.s_LimitCodeType === "NOEDIT" && (
                          <>
                            <Flex
                              gap={4}
                              alignItems={"center"}
                              justifyContent="flex-end"
                            >
                              <Select
                                className="starQuotePageInput"
                                width="90px"
                                name={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? "otherStructuresPercent"
                                    : item?.s_CoverageCode === "HOMCVGC"
                                    ? "contentsPercent"
                                    : item?.s_CoverageCode === "HOMCVGD"
                                    ? "lossOfUse"
                                    : ""
                                }
                                isDisabled={
                                  item?.s_CoverageCode === "HOMCVGB" ||
                                  item?.s_CoverageCode === "HOMCVGD" ||
                                  props?.isBind
                                }
                                style={
                                  item?.s_CoverageCode === "HOMCVGB" ||
                                  item?.s_CoverageCode === "HOMCVGD" ||
                                  props?.isBind
                                    ? {
                                        backgroundColor: "#e9ecef",
                                      }
                                    : {}
                                }
                                value={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? parseInt(
                                        basicCoverages?.otherStructuresPercent
                                      )
                                    : item?.s_CoverageCode === "HOMCVGC"
                                    ? parseInt(basicCoverages?.contentsPercent)
                                    : item?.s_CoverageCode === "HOMCVGD"
                                    ? 30
                                    : 0
                                }
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  handleChange(name, value);
                                }}
                                _disabled={{ opacity: 1 }}
                              >
                                {item?.s_CoverageCode === "HOMCVGB" &&
                                  otherStructureLimits?.map((limit, index) => (
                                    <option key={index} value={limit}>
                                      {limit}%
                                    </option>
                                  ))}
                                {item?.s_CoverageCode === "HOMCVGC" &&
                                  contentsLimit?.map((limit) => (
                                    <option
                                      key={limit.value}
                                      value={limit.value}
                                    >
                                      {limit.label}
                                    </option>
                                  ))}
                                {item?.s_CoverageCode === "HOMCVGD" && (
                                  <option value="30">30%</option>
                                )}
                              </Select>
                              <CurrencyInput
                                intlConfig={{
                                  locale: "en-US",
                                  currency: "USD",
                                }}
                                className="starQuotePageInput"
                                readOnly
                                style={{
                                  width: "40%",
                                  textAlign: "right",
                                  backgroundColor: "#e9ecef",
                                }}
                                prefix="$"
                                disabled={props?.isBind}
                                value={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? parseFloat(
                                        basicCoverages?.otherStructures || 0
                                      ).toFixed(2)
                                    : item?.s_CoverageCode === "HOMCVGC"
                                    ? parseFloat(
                                        basicCoverages?.contents || 0
                                      ).toFixed(2)
                                    : item?.s_CoverageCode === "HOMCVGD"
                                    ? parseFloat(
                                        basicCoverages?.lossOfUse || 0
                                      ).toFixed(2)
                                    : 0
                                }
                                name={
                                  item?.s_CoverageCode === "HOMCVGB"
                                    ? "otherStructures"
                                    : item?.s_CoverageCode === "HOMCVGB"
                                    ? "contents"
                                    : ""
                                }
                              />
                            </Flex>
                          </>
                        )}
                        {item.s_LimitCodeType === "NUMBER" ? (
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            className="starQuotePageInput"
                            style={{
                              width: "24%",
                              textAlign: "right",
                              backgroundColor: props?.isBind ? "#e9ecef" : "",
                            }}
                            prefix="$"
                            value={
                              item?.s_CoverageCode === "HOMCVGA"
                                ? parseFloat(
                                    basicCoverages?.costToRebuildHome || 0
                                  ).toFixed(2)
                                : 0
                            }
                            name={
                              item?.s_CoverageCode === "HOMCVGA"
                                ? "costToRebuildHome"
                                : ""
                            }
                            disabled={props?.isBind}
                            onValueChange={(float, name, values) => {
                              if (float !== null && float !== undefined) {
                                handleChange(name, values.value);
                                setShowError(false);
                              }
                            }}
                            onBlur={(e) => {
                              let value =
                                Number(
                                  e.target.value.replace(/[^0-9\.]+/g, "")
                                ) || 0;
                              if (value < minValue) {
                                handleChange("costToRebuildHome", minValue);
                                setShowError(true);
                              }
                            }}
                          />
                        ) : item.s_LimitCodeType === "DROPDOWN" ? (
                          <>
                            <Select
                              className="starQuotePageInput"
                              width="25%"
                              value={
                                item.s_CoverageCode === "HOMCVGE"
                                  ? parseFloat(
                                      basicCoverages?.personalLiability
                                    )
                                  : item.s_CoverageCode === "HOMCVGF"
                                  ? parseFloat(
                                      basicCoverages?.medicalPaymentsToOthers
                                    )
                                  : 0
                              }
                              name={
                                item?.s_CoverageCode === "HOMCVGE"
                                  ? "personalLiability"
                                  : item?.s_CoverageCode === "HOMCVGF"
                                  ? "medicalPaymentsToOthers"
                                  : ""
                              }
                              onChange={(e) => {
                                const { name, value } = e.target;
                                handleChange(name, value);
                              }}
                              isDisabled={props?.isBind}
                              textAlign="right"
                              _disabled={{ opacity: 1 }}
                              style={{
                                backgroundColor: props?.isBind ? "#e9ecef" : "",
                              }}
                            >
                              <option value=""></option>
                              {item?.dropDown?.map((ddOptions, index) => (
                                <option
                                  key={index}
                                  value={parseFloat(ddOptions?.n_LimitIncident)}
                                >
                                  {" "}
                                  {ddOptions?.s_LimitScreenName}
                                </option>
                              ))}
                            </Select>
                          </>
                        ) : (
                          ""
                        )}
                      </Flex>

                      {props?.getBaseData?.all_basic_Coverage.length - 1 !==
                        props?.getBaseData?.all_basic_Coverage.indexOf(
                          item
                        ) && (
                        <Box
                          my={4}
                          borderTop={"1px solid"}
                          borderColor={"gray.200"}
                        />
                      )}
                    </Box>
                  );
                })}
            </Card>
          </GridItem>
          <GridItem>
            <Box textAlign="left" className="coverageTitle">
              <Text fontSize={"xl"} mt={3} pl={1}>
                {t("additional_coverages")}
              </Text>
            </Box>
            <Card h={"88%"} p={3}>
              <Box>
                {additionalCoverageItems
                  ?.filter(
                    (item) =>
                      item.s_ScreenName !== "Wind Coverage Exclusion" &&
                      item.s_ScreenName !== "Pers Prop Dec Limit" &&
                      item.s_ScreenName !== "Water Damage Exclusion" &&
                      item.s_ScreenName !== "B-Incr Other Structures" &&
                      item.s_ScreenName !== "C-Increased Pers Prop" &&
                      item.s_ScreenName !== "Ordinance or Law" &&
                      item.s_ScreenName !== "Ordinance or Law" &&
                      item.s_ScreenName !== "Mold/Fungi Cvg Endt" &&
                      item.s_ScreenName !== "Sewer or Drain Damage" &&
                      item.s_ScreenName !== "Fire Dept Charge" &&
                      item.s_ScreenName !== "Golf Cart Coverage" &&
                      item.s_ScreenName !== "Screened Enclosures" &&
                      item.s_ScreenName !== "Identity Theft or Fraud" &&
                      item.s_ScreenName !== "In-home Business" &&
                      item.s_ScreenName !== "In-home Business - Prop" &&
                      item.s_ScreenName !== "Watercraft Liability"
                  )
                  ?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Flex
                          mt="10px"
                          alignItems={"center"}
                          justifyContent={"start"}
                        >
                          <HStack>
                            <Text
                              pl="10px"
                              fontWeight="var(--chakra-fontWeights-medium)"
                            >
                              {item.s_ScreenName}
                            </Text>
                          </HStack>
                          <Spacer />
                          <Flex justifyContent={"flex-end"}>
                            {item.s_LimitCodeType === "NOEDIT" ? (
                              <CurrencyInput
                                intlConfig={{
                                  locale: "en-US",
                                  currency: "USD",
                                }}
                                className="starQuotePageInput"
                                style={{
                                  padding: "9px 16px",
                                  textAlign: "right",
                                  width: "160px",
                                }}
                                prefix="$"
                              />
                            ) : (
                              ""
                            )}
                            {item.s_LimitCodeType === "SUPPFORM" ? (
                              <CurrencyInput
                                intlConfig={{
                                  locale: "en-US",
                                  currency: "USD",
                                }}
                                className="starQuotePageInput"
                                style={{
                                  padding: "9px 16px",
                                  textAlign: "right",
                                  width: "160px",
                                }}
                                prefix="$"
                              />
                            ) : (
                              ""
                            )}
                            {item.s_LimitCodeType === "DROPDOWN" ? (
                              <Select
                                className="starQuotePageInput"
                                minW={"158px"}
                                style={{
                                  height: "54px",
                                  width: "100%",
                                }}
                                textAlign="right"
                              >
                                {item?.dropDown?.map((ddOptions) => (
                                  <option value={ddOptions?.n_LimitDisplaySeq}>
                                    {ddOptions?.s_LimitScreenName}
                                  </option>
                                ))}
                              </Select>
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Flex>
                        {additionalCoverageItems?.length - 1 !==
                          additionalCoverageItems?.indexOf(item) && (
                          <Box
                            my={4}
                            borderTop={"1px solid"}
                            borderColor={"gray.200"}
                          />
                        )}
                      </Box>
                    );
                  })}
              </Box>
            </Card>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};
function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(Coverage);
