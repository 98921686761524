import React from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import {
  buildingInterpetionsFields,
  coveredFerilsFields,
  restorationFactorFields,
} from "../../commercial-quote/data/commercialDD";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const COVERAGESPERCENTAGE = [100, 90, 80];

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

const SelectField = ({ options, ...props }) => (
  <ChakraSelect w="30%" {...props}>
    <option value=""></option>
    {options.map((field, i) => (
      <option key={i} value={field}>
        {field}
      </option>
    ))}
  </ChakraSelect>
);

export const BasicCoverages = ({ index }) => {
  const { t } = useTranslation();
  const { coverages, setCoverages, selectedCard } = useQuote();

  const updateCoverageDetails = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        basicCoverages: {
          ...updatedCoverages[index].basicCoverages,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 680 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        <FormField label={t("Building Replacement Cost")}>
          <CustomCurrencyInput
            value={coverages[index]?.basicCoverages?.buildingRCV}
            onValueChange={(value) => {
              updateCoverageDetails(index, "buildingRCV", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Building Coverage Selection (ITV)")}>
          <ChakraSelect
            w={"30%"}
            value={coverages[index]?.basicCoverages?.buildingCVPercent || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "buildingCVPercent", e.target.value);
            }}
          >
            <option value=""></option>
            {COVERAGESPERCENTAGE?.map((v) => (
              <option key={v} value={v}>
                {v}%
              </option>
            ))}
          </ChakraSelect>
        </FormField>

        <FormField label={t("Business Personal Property Coverage")}>
          <CustomCurrencyInput
            value={
              coverages[index]?.basicCoverages?.buildingPersonalPropertyCVG
            }
            onValueChange={(value) => {
              updateCoverageDetails(
                index,
                "buildingPersonalPropertyCVG",
                value
              );
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Business Interruption Extra Expense")}>
          <CustomCurrencyInput
            value={coverages[index]?.basicCoverages?.buildingExtraExpenses}
            onValueChange={(value) => {
              updateCoverageDetails(index, "buildingExtraExpenses", value);
            }}
            currencyStyles={{
              width: "30%",
              borderColor: "var(--chakra-colors-gray-400)",
            }}
          />
        </FormField>

        <FormField label={t("Business Interruption Waiting Period")}>
          <SelectField
            value={
              coverages[index]?.basicCoverages?.buissnessWaitingPeriod || ""
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "buissnessWaitingPeriod",
                e.target.value
              );
            }}
            options={buildingInterpetionsFields}
          />
        </FormField>

        <FormField label={t("Restoration Factor")}>
          <SelectField
            value={coverages[index]?.basicCoverages?.restorationFactor || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "restorationFactor", e.target.value);
            }}
            options={restorationFactorFields}
          />
        </FormField>
        <FormField label={t("Covered Perils")}>
          <SelectField
            value={coverages[index]?.basicCoverages?.coveredPerils || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "coveredPerils", e.target.value);
            }}
            options={coveredFerilsFields}
          />
        </FormField>
      </VStack>
    </Box>
  );
};
