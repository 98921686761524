import Http from "../../../../Http";

export function getAddrByZip({ zipCode }) {
  return new Promise((resolve, reject) => {
    const url = `/api/quote/getZipCodeDetails/10/${zipCode}/APPLICANT`;
    Http.get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}
