import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./mode-starting-estimate.scss";
import { useParams, useNavigate } from "react-router-dom";
const ModeSteps = (props) => {
    const navigation = useNavigate();

    const navigateStep = (step) => {
        navigation('/flood/instantquote/step/' + step);
      }
    //   emptyQuoteData.personEmailContact.s_CommValue
    //   console.log(emptyQuoteData);
    const handleNextButton = () => {
        var isValid = true;
        if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(props.emptyQuoteData.personEmailContact.s_CommValue))) {
            toast.error(t('Enter email address'));
            isValid = false;
        }
        if(props.emptyQuoteData.personPhoneContact.s_CommValue == '' || props.emptyQuoteData.personPhoneContact.s_CommValue == null){
            toast.error(t('Enter Phone Number'));
            isValid = false;
        }

        if (isValid === true) {
            navigateStep(12)
        }

    }
    const { t } = useTranslation();


    return (
        <div className="flood-bts-div">
            <Button
                id="primary-grey-btn-flood"
                variant="outline-dark"
                disabled={true}
            >
                {t('Previous')}
            </Button>{" "}
            <Button
                onClick={() => handleNextButton()}
                id="primary-colored-btn"
                variant="primary"
            >
                {t('Next')}
            </Button>{" "}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
    };
}

export default connect(mapStateToProps)(ModeSteps);