import Http from "../../../../Http";

export async function saveUpdates({ payload, transType }) {
  try {
    const url = `api/flood/${transType}/create`;
    const response = await Http.post(url, payload);
    return response.data;
  } catch (err) {
    console.error(
      "Error in renew Hiscox policy:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
