import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";

const ExistingPolicyTab = () => {
  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        submit_date: "2021-10-21 14:29:59",
        policy_type: "HO6 (Homeowners)",
        policy_no: `HO6202100082${i}`,
        status: "CLOSED",
        remark: "Premium too high",
        agent: "Jean Cladue Jonasnait",
        agency: "FIRST CLASS INSURANCE & FINANCIAL SVCS ,LLC",
      });
    }
    return items;
  };

  const products = productsGenerator(100);

  const columns = [
    {
      dataField: "submit_date",
      text: "Submit Date",
      sort: true,
    },
    {
      dataField: "policy_type",
      text: "Policy Type",
      sort: true,
    },
    {
      dataField: "policy_no",
      text: "Policy No",
      sort: true,
      formatter: (value, row) => (
        <Link className="management-reports-link" to={"/"}>
          {value}
        </Link>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "remark",
      text: "Remark",
      sort: true,
    },
    {
      dataField: "agent",
      text: "Agent",
      sort: true,
    },
    {
      dataField: "agency",
      text: "Agency",
      sort: true,
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  return (
    <div className="existing-policy-section">
      <div className="existing-policy-filters">
        <h2>Search Policy</h2>
        <Row className="align-items-end">
          <Col xs="12" sm="12" md="4" lg="3" xl="3">
            <Form.Group
              className="existing-policy-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                Policy No.
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3" xl="3">
            <Form.Group
              className="existing-policy-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                Last Name
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3" xl="3">
            <Form.Group
              className="existing-policy-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                First Name
              </Form.Label>
              <Form.Control
                className="dashboard-group-input"
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col xs="12" sm="12" md="4" lg="3" xl="3">
            <Form.Group
              className="existing-policy-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                Select Product
              </Form.Label>
              <Form.Select
                className="dashboard-group-input"
                id="select-ym-option"
              >
                <option></option>
                <option></option>
                <option></option>
                <option></option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="existing-search-policy-btn-col">
            <Button id="common-btn" variant="primary">
              <FaSearch size={15} style={{ marginRight: "10px" }} />
              Search Policy
            </Button>{" "}
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs="12" style={{ padding: 0 }}>
          <div className="binding-tab-table" id="existing-tab-table-outer-div">
            <div className="binding-tab-header">Search Results</div>

            <BootstrapTable
              wrapperClasses="management-functional-table"
              bootstrap4
              keyField="message_id"
              data={products}
              columns={columns}
              hover
              bordered={false}
              pagination={paginationFactory(options)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExistingPolicyTab;
