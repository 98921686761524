import { Box } from "@chakra-ui/react";
import { PaymentSection } from "./PaymentSection";
import { useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { useQuote } from "../../CommCreateQuote";
import { postCommercialQuote } from "../../commercial-quote/Api/postCommercialQuote";
import { useTranslation } from "react-i18next";
import { CreateCommPool } from "../../CreateCommercialPool";

export const Payment = () => {
  const { t } = useTranslation();
  const {
    masterQuoteData,
    setMasterQuoteData,
    setIsLoading,
    setApiResponse,
    coverages,
    location,
    selections,
    selectedCard,
    underWriting,
    currentZipData,
    lossHistoryData,
    agent,
    hotelResponses,
    convenienceStoreResponses,
    blanketDisplayCoverages,
    discounts,
    buildingDetails,
    hasMortagee,
    saveMortgagee,
  } = useQuote();

  const postQuote = useCallback(async () => {
    const quoteNumber = masterQuoteData?.quoteNumber;
    const requestBody = CreateCommPool({
      masterQuoteData,
      coverages,
      location,
      selections,
      selectedCard,
      underWriting,
      currentZipData,
      lossHistoryData,
      agent,
      hotelResponses,
      convenienceStoreResponses,
      blanketDisplayCoverages,
      discounts,
      buildingDetails,
      hasMortagee,
      saveMortgagee,
    });
    const toastId = toast.loading(
      quoteNumber ? t("Updating Quote...") : t("Creating Quote..."),
      {
        position: toast.POSITION.TOP_CENTER,
        toastId: "createQuoteLoading",
        autoClose: 3000,
      }
    );
    try {
      setIsLoading(true);
      const response = await postCommercialQuote(quoteNumber, requestBody);
      if (response?.status === true) {
        toast.update(toastId, {
          render: quoteNumber
            ? t("Quote Updated Successfully")
            : t("Quote Created Successfully"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          toastId: "createQuoteSuccess",
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.update(toastId, {
          render:
            response?.message ||
            t(`Failed to ${quoteNumber ? "update" : "create"} quote`),
          type: "error",
          isLoading: false,
          autoClose: 2000,
          toastId: "createQuoteError1",
        });
      }
      if (response?.data?.policyNo && response?.status && !quoteNumber) {
        setApiResponse(response?.data);
        setMasterQuoteData({
          ...masterQuoteData,
          quoteNumber: response?.data?.policyNo,
          premiumToDisplay: 1, // 1$ for devloper @TheEinshine :)
        });
      } else {
        setApiResponse({});
      }
    } catch (error) {
      toast.update(toastId, {
        render: t("Failed to create quote"),
        type: "error",
        isLoading: false,
        autoClose: 2000,
        toastId: "createQuoteError",
      });
    } finally {
      setIsLoading(false);
    }
  }, [masterQuoteData]);

  useEffect(() => {
    if (masterQuoteData?.applicantDetails?.lastName) {
      postQuote();
    } else {
      toast.error(t("Insured / Buissiness Name is Required"), {
        toastId: "nameError",
        autoClose: 3000,
      });
    }
  }, []);

  return (
    <Box>
      <PaymentSection />
    </Box>
  );
};
