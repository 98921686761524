import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import Moment from 'moment';

const Construction = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, handleDateChange, checkDateisValid, handleStateChange, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [MMH, setMMH] = useState('');
    const [DOCS, setDOCS] = useState(null);
    const [docLabel, setDocLabel] = useState('Construction Date');
    const [DOC, setDOC] = useState(null);
    const [SI, setSI] = useState('');
    const [constructionSource, setConstructionSource] = useState('');
    const [CSDD, setCSDD] = useState([]);
    const [inNRHP, setInNRHP] = useState('');
    const [dSI, setDSI] = useState(null);
    const [DOCMaxLimit, setDOCMaxLimit] = useState(null);
    const [isDataSet, setIsDataSet] = useState(false);

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setMMH(emptyQuoteData.propertyInfo.s_ManufactureMobileHome || null);
            setDOCS(emptyQuoteData.propertyInfo.s_DtOfConstructionSource || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_DateOfConstruction)) {
                setDOC(new Date(Moment(emptyQuoteData.propertyInfo.d_DateOfConstruction)) || null);
            }
            setSI(emptyQuoteData.propertyInfo.s_SubstantialImprovement || null);
            setConstructionSource(emptyQuoteData.propertyInfo.s_BuildingCourseConstruction || null);
            setInNRHP(emptyQuoteData.propertyInfo.s_IsBldgNRHP || null);
            if (checkDateisValid(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate)) {
                setDSI(new Date(Moment(emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate)) || null);
            }
            setIsDataSet(true);
        }
    }, [emptyQuoteData])

    // trigger on update MMH
    useEffect(() => {
        if (isDataSet) {
            if (MMH !== "YES") {
                updateReducerState('MMH');
            }
            handleStateChange('CONSTRUCTION', MMH === 'YES' ? true : false, 'MOBILE');

            var dropDown = [], value = DOCS;
            if (MMH === 'YES') {
                dropDown = Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODDTCONSTRSOURCE.filter(x => ['LOCATEDIN', 'LOCATEDOUTSIDE'].includes(x.s_AppCodeName));
            } else {
                value = '';
            }
            setCSDD(dropDown);
            updateValue(value)
        }
    }, [MMH, floodSystemDD, isDataSet])

    // update state in accordion
    useEffect(() => {
        handleStateChange('CONSTRUCTION', DOC, 'DOC');
        handleStateChange('CONSTRUCTION', SI, 's_SubstantialImprovement');
        handleStateChange('CONSTRUCTION', dSI, 'd_SubstantialImprovmentDate');
        if (SI === 'NO') {
            updateReducerState('SI');
        }
    }, [DOC, SI, dSI]);

    // To set Label as per the construction source
    const updateValue = (value) => {
        // get filted obj
        var obj = Object.keys(floodSystemDD).length > 0 ? floodSystemDD.FLOODDTCONSTRSOURCE.filter(e => e.s_AppCodeName === value) : [];
        var label = 'Construction Date'; // default
        if (Object.keys(obj).length > 0 && !['LOCATEDIN', 'LOCATEDOUTSIDE'].includes(obj[0].s_AppCodeName)) {
            label = obj[0].s_AppCodeNameForDisplay;  // as per the selected value - label
        }
        if (Object.keys(obj).length > 0 && ['LOCATEDIN'].includes(obj[0].s_AppCodeName)) {
            label = 'Date the Park/subdivision was established';   // as per the selected value - label
        }
        if (Object.keys(obj).length > 0 && ['LOCATEDOUTSIDE'].includes(obj[0].s_AppCodeName)) {
            label = 'Date of Permanent Placement';   // as per the selected value - label
        }
        setDocLabel(label); // setting label
        setDOCS(value); // setting source value
    }

    // update value in reducer 
    const updateReducerState = (from) => {
        var emptyQuote = { ...emptyQuoteData };
        if (from === "MMH" && MMH !== "YES") {
            emptyQuote['propertyInfo']['s_DtOfConstructionSource'] = 'ORIGINALCONSTRDT';
            setDOCS('ORIGINALCONSTRDT');
        }
        if (from === 'SI') {
            emptyQuote['propertyInfo']['s_IsBldgNRHP'] = null;
            emptyQuote['propertyInfo']['d_SubstantialImprovmentDate'] = null;
            setInNRHP(null);
            setDSI(null);
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    return (
        <Form validated={validated}>
            <Row className="align-items-end">
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Is the Structure a Mobile home')}?
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_ManufactureMobileHome"
                            title={t('Is the Structure a Mobile home')}
                            parentobject="propertyInfo"
                            value={MMH}
                            onChange={(e) => {
                                setMMH(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    MMH === "YES" && (
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t('Location of mobile home')}?
                                </Form.Label>
                                <Form.Select
                                    className="dashboard-group-input-flood"
                                    id="select-ym-option"
                                    name="s_DtOfConstructionSource"
                                    title={t('Location of mobile home')}
                                    parentobject="propertyInfo"
                                    value={DOCS}
                                    onChange={(e) => {
                                        setDOCS(e.currentTarget.value);
                                        handleChange(e);
                                        updateValue(e.currentTarget.value);
                                    }}
                                    disabled={isBind}
                                >
                                    <option value={''}>{t('Select')}</option>
                                    {
                                        CSDD && CSDD.map((v, i) => {
                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    MMH && (
                        <Col xs="12" sm="12" md="6" lg="2" xl="2">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t(docLabel)}
                                </Form.Label>
                                <DatePicker
                                    id="dashboard-datepicker"
                                    className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                    placeholderText="MM/DD/YYYY"
                                    selected={DOC}
                                    maxDate={DOCMaxLimit}
                                    onChange={(date) => {
                                        setDOC(date);
                                        handleDateChange(date, 'd_DateOfConstruction', 'propertyInfo');
                                    }}
                                    required
                                    autoComplete="off"
                                    disabled={isBind}
                                />
                            </Form.Group>
                        </Col>
                    )
                }
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Has the building been substantially improved")}?<AiOutlineInfoCircle size={25} style={{ paddingLeft: '5%' }} data-tip data-for="substantially-improved"/>
                        </Form.Label>
                        <ReactTooltip id="substantially-improved" place="top" effect="solid">
                            Reconstruction, rehabilitation, addition, or other improvement,<br /> the cost of which equals or exceeds 50% <br /> of the market value prior to the <br /> "start of construction" of improvement <br /> and not designated as an
                            historic building.
                        </ReactTooltip>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_SubstantialImprovement"
                            title={t('Has the building been substantially improved')}
                            parentobject="propertyInfo"
                            value={SI}
                            onChange={(e) => {
                                setSI(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    SI === 'YES' && (
                        <>
                            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t("Is building listed in the National Register of Historic Places")}?
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_IsBldgNRHP"
                                        title={t('Is building listed in the National Register of Historic Places')}
                                        parentobject="propertyInfo"
                                        value={inNRHP}
                                        onChange={(e) => {
                                            setInNRHP(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                inNRHP === 'NO' && (
                                    <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                        <Form.Group
                                            className="search-quote-form-group"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                                {t('Date of substantial improvement')}
                                            </Form.Label>
                                            <DatePicker
                                                id="dashboard-datepicker"
                                                className={(isBind ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                                placeholderText="MM/DD/YYYY"
                                                selected={dSI}
                                                onChange={(date) => {
                                                    setDSI(date);
                                                    handleDateChange(date, 'd_SubstantialImprovmentDate', 'propertyInfo');
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                )
                            }
                        </>
                    )
                }
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Is the building currently under construction')}?
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_BuildingCourseConstruction"
                            title={t('Is the building currently under construction')}
                            parentobject="propertyInfo"
                            value={constructionSource}
                            onChange={(e) => {
                                setConstructionSource(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default Construction