const getResidentialEndorseBody = ({ quoteDetailData, effectiveDate }) => {
  return {
    effectiveDate: effectiveDate,
    productType: quoteDetailData?.data?.productType,
    commercialProductType: quoteDetailData?.data?.commercialProductType,
    addressLine1: quoteDetailData?.data?.streetName,
    addressLine2: quoteDetailData?.data?.streetName,
    addressLine3: " ",
    county: quoteDetailData?.data?.county,
    stateCode: quoteDetailData?.data?.stateCode,
    zip: quoteDetailData?.data?.zip,
    latitude: quoteDetailData?.data?.latitude || 0,
    longitude: quoteDetailData?.data?.longitude || 0,
    occupancyType: quoteDetailData?.data?.occupancyType,
    constructionType: quoteDetailData?.data?.constructionType,
    buildingReplacementCostValues: Number(
      quoteDetailData?.data?.buildingReplacementCostValues
    ),
    contentsReplacementCostValues: Number(
      quoteDetailData?.data?.contentsReplacementCostValues
    ),
    residentialLimits: (quoteDetailData?.data?.residentialLimits || []).map(
      (limit) => {
        return {
          building: Number(limit?.building) || 0,
          contents: Number(limit?.contents) || 0,
        };
      }
    ),
    contentsCostValueType: quoteDetailData?.data?.contentsCostValueType,
    foundationType: quoteDetailData?.data?.foundationType,
    additionalFoundationType: quoteDetailData?.data?.additionalFoundationType,
    basementType: quoteDetailData?.data?.basementType,
    attachedGarageType: quoteDetailData?.data?.attachedGarageType,
    yearBuilt: Number(quoteDetailData?.data?.yearBuilt),
    squareFootage: Number(quoteDetailData?.data?.squareFootage),
    numberOfStories: Number(quoteDetailData?.data?.numberOfStories),
    elevationHeight: Number(quoteDetailData?.data?.elevationHeight || 0),
    buildingOverWaterType: quoteDetailData?.data?.buildingOverWaterType,
    priorLosses: quoteDetailData?.data?.priorLosses,
    firstName: quoteDetailData?.data?.firstName,
    lastName: quoteDetailData?.data?.lastName,
    phoneNumber: quoteDetailData?.data?.phoneNumber,
    email: quoteDetailData?.data?.email,
  };
};
export default getResidentialEndorseBody;