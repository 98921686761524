import React, { useState } from "react";
import { Row, Col, Tabs, Tab, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PivotLog from "../../quote/flood/quote/Modal/PivotLog";
import ActivityLog from "./ActivityLogModal";
import PolicyNote from "./PolicyNote";

const LogModalView = (props) => {
    const { t } = useTranslation();
    const { show, setShow } = props;
    const [key, setKey] = useState('NOTES');

    return (
        <Modal
            size="xl"
            show={show}
            className="custom-dashboard-modal"
            onHide={() => setShow(!show)}
            backdrop="static"
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
            >
                <Modal.Title>{t('Activity Log')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12" className="custom-tabs">
                        <Tabs
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="NOTES" title={t('Notes')}>
                                <PolicyNote
                                    {...props}
                                />
                            </Tab>
                            <Tab eventKey="ACTIVITY_LOG" title={t('Activity Log')}>
                                <ActivityLog
                                    {...props}
                                />
                            </Tab>
                            <Tab eventKey="PIVOT_LOG" title={t('Pivot Api Logs')}>
                                <PivotLog
                                    poTransPK={props.poTransPK || 0}
                                    from="POLICY_VIEW"
                                    tranTypeCode={props.tranTypeCode || ""}
                                />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default LogModalView;