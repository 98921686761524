import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Tab, Form, Button } from "react-bootstrap";
import { FaBars, FaSearch } from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {Link } from 'react-router-dom';
import { getMortgageeListData, getPolicyChecked } from "../../services/quickLinksService";
import { showDataTableTotalText } from "../../services/commonService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
const Mortgagee = (props) => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [TableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState([]);
  const [filter, setFilter] = useState({
    policyNo: "",
    firstName: "",
    lastName: "",
    page:1,
    pageLength:10
  });
  
  useEffect(() => {
    searchMortgageeList();
  }, []);
  
  useEffect(() => {
    searchMortgageeList();
  }, [filter.page]);

  const searchMortgageeList = async () => {
      setLoading(true);
      await dispatch(getMortgageeListData(filter)).then((res) => { 
      setTableData(res.data.data);
      setTableTotal(res.data.total);
      setLoading(false);
    });
  };

  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        policy_no: "HO32014012226",
        insured_name: "ROGE DAMEUS",
        product_type: "HO6 (Homeowners)",
        renew_date: "MM/DD/YYYY",
      });
    }
    return items;
  };

  const getPolicyCheckedByPolicy = async (policyNumber) => {
        setLoading(true);
        await dispatch(getPolicyChecked(policyNumber)).then((res) => { 
        console.log(res.data);
        if(res.data.status === "Y"){
          navigation('information/'+policyNumber);
        }else{
          setLoading(false);
          toast.error(res.data.msgString);
        }
    });
  };

  const columnFormatter = (cell, row, rowIndex, formatExtraData) => {
  if (row && row.s_POAppNo) {
    return <a style={{ color:"#0d6efd", cursor: "pointer", fontSize : "16px", textDecoration : "underline" }} onClick={() => getPolicyCheckedByPolicy(row.s_POAppNo)}>{cell}</a>
	} else {
	  return row.s_POAppNo;
	}
  };
  
  const columns = [
    {
      dataField: "d_DateSubmitted",
      text: "Submit Date",
    },
    {
      dataField: "s_ProductName",
      text: "Product Type",
    },
    {
      dataField: "s_POAppNo",
      text: "Policy No",
	    formatter: columnFormatter
    },
    {
      dataField: "InsuredName",
      text: "Policyholder/Member Name",
    },
    {
      dataField: "s_AppCodeNameForDisplay",
      text: "Remark",
    },
    {
      dataField: "AgentName",
      text: "Agent",
    },
    {
      dataField: "AgencyName",
      text: "Agency",
    },
    {
      dataField: "s_AppStatusTypeCode",
      text: "SS Policy No.",
    },

  ];
  const onHandleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    const tempArray = filter; // creating copy of an array
    tempArray[name] = e.target.value
    setFilter(tempArray); // set copied array
  }

  const handlePageChange = (page, sizePerPage) => {
    setFilter({...filter,page:page,pageLength:sizePerPage});
  };

  const sizePerPageListChange = (sizePerPage) => {
    setFilter({...filter,pageLength:sizePerPage});
  };

  const renderShowsTotal = (start, to, total) => {
    return showDataTableTotalText(start, to, total);
  };

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    paginationShowsTotal: renderShowsTotal,
    onPageChange: handlePageChange,
    totalSize:tableTotal
    //sizePerPageDropDown: renderSizePerPageDropDown
  };

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="search-policy-section">
        <Row className="align-items-center">
          <Col xs="12">
            <div className="search-policy-filters">
              <h2>{t('Mortgagee Change')}</h2>
              <Row className="align-items-end">
                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Policy NO')}.
                    </Form.Label>
                    <Form.Control
                      name="policyNo"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('Last Name')}
                    </Form.Label>
                    <Form.Control
                      name="lastName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                  <Form.Group
                    className="policy-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t('First Name')}
                    </Form.Label>
                    <Form.Control
                      name="firstName"
                      className="dashboard-group-input"
                      type="text"
                      placeholder=""
                      onChange={(e) => onHandleChange(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="search-policy-btn-col">
                  <Button
                    id="common-btn"
                    variant="primary"
                    onClick={() => searchMortgageeList()}
                  >
                    <FaSearch size={15} style={{ marginRight: "10px" }} />
                    {t('Search')}
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row id="dashboard-table-section">
          <Col xs="12" style={{ marginTop: "50px" }}>
            <div className="table-main-title-div">
              <span className="table-main-title-name">Search Result</span>
              <div className="table-main-title-icons">
                <div className="table-square"></div>
                <FaBars size={15} color={"#fff"} />
              </div>
            </div>
          </Col>
          <Col xs="12">
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              bootstrap4
              remote
              keyField="n_POAppsMaster_PK"
              data={TableData}
              columns={columns}
              hover
              pagination={paginationFactory(options)}
            />
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  );
};

function mapStateToProps(state) {
    return {
        MortgageeChangeList: state.common.MortgageeChangeList ? state.common.MortgageeChangeList : {},
    };
  }
export default connect(mapStateToProps)(Mortgagee);