import Http from '../Http';
import * as action from '../store/actions';

export function getResourceData() {
	
	const url = "/api/resources/getResourceData";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function saveResourceData(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
          Http.post("/api/resources/saveResourceData",data)
            .then((res) => {
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}