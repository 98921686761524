import Http from '../Http';
import * as action from '../store/actions';

export function getMortgageeListData(params) {
    return dispatch => (
        new Promise((resolve, reject) => {
        const url = `/api/mortgagee/list?firstName=+${params.firstName}&lastName=+${params.lastName}&policyNo=+${params.policyNo}&page=+${params.page}&pageLength=+${params.pageLength}`;
        Http.get(url)
            .then((res) => {
              dispatch(action.getMortgageeListData(res.data));
              return resolve(res);
            }).catch((err) => {
              if (err.response) {
                return resolve(err.response);
              }
            });
        })
    );
}

export function getPolicyChecked(policyNumber) {
	const url = "/api/mortgagee/getpolicychecked/"+policyNumber;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}
export function getAndCheckMortgageeInformation(policyNumber) {
	const url = "/api/mortgagee/mortgageeinformation/"+policyNumber;
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getMortgageeListDataForSet(params) {
  return dispatch => (
      new Promise((resolve, reject) => {
      const url = `/api/mortgagee/searchlist?MortgageeLastName=+${params.MortgageeLastName}&MortgageeClientId=+${params.MortgageeClientId}&MortgageeCity=+${params.MortgageeCity}&MortgageeFirstName=+${params.MortgageeFirstName}&MortgageeState=+${params.MortgageeState}&page=+${params.page}&pageLength=+${params.pageLength}`;
      Http.get(url)
          .then((res) => {
            //dispatch(action.getMortgageeListDataModel(res.data));
            return resolve(res);
          }).catch((err) => {
            if (err.response) {
              return resolve(err.response);
            }
          });
      })
  );
}

export function getMortgageeSaveData(params) {
	const url = "/api/mortgagee/getmortgageesave";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.post(url, params)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}