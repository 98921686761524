import { useTranslation } from "react-i18next";
import PolicyNumberCell from "./quoteNumberCellRender";
import { Box, Card, Text } from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { useCallback, useMemo, useRef } from "react";
import { fetchPendingTransactions } from "./api/getPendingTransactions";
import {formateDate} from "../../../pages/common/FormatDate";

export const NewBusiness = ({ transType }) => {
  const { t } = useTranslation();
  const gridRef = useRef(null);

  const linkArray = {
    FLOOD: "/flood/quote/",
    HiscoxFloodPlus: "/quote/hiscox/application/",
  };

  const prepareRedirectUrl = (row) => {
    let redirectUrl = linkArray[row.s_ProductCode];

    switch (row.s_ProductCode) {
      case "FLOOD":
        redirectUrl = redirectUrl + row.poTransPK;
        break;

      case "HiscoxFloodPlus":
        redirectUrl = redirectUrl + row.quote_no;
        break;

      default:
        break;
    }

    return redirectUrl;
  };

  const WordBreakRenderer = (params) => {
    return (
      <div style={{ wordBreak: "break-word" }}>{params.data.doc_name}</div>
    )
  }

  const onPolicyNumberClick = (row) => {
    const redirectUrl = prepareRedirectUrl(row);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const columnDefs = [
    {
      headerName: t("Quote Number"),
      field: "quote_no",
      cellRenderer: (params) => (
        <PolicyNumberCell
          value={params.value}
          onPolicyNumberClick={() => onPolicyNumberClick(params.data)}
        />
      ),
    },
    {
      headerName: t("Document Name"),
      field: "doc_name",
      wrapText:true,
      autoHeight: true, 
      cellRenderer: (params) => WordBreakRenderer(params)
    },
    {
      headerName: t("Attached Date"),
      field: "attached_date",
      cellRenderer: (params) => formateDate(params.data.attached_date)
    },
    {
      headerName: t("Document Attached?"),
      field: "s_IsAttached",
      cellRenderer: (params) => {
        return params.data.s_IsAttached === "N" ? "No" : "Yes"
      }
    },
    {
      headerName: t("Product"),
      field: "s_ProductName",
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: false,
      sizeColumnsToFit: true,
      flex: 1, 
      minWidth: 170,
    }),
    []
  );
  // const fetchData = async (
  //   params = {
  //     sizePerPage: 10,
  //     pageNo: 1,
  //   }
  // ) => {
  //   try {
  //     let filters = {
  //       ...{
  //         pageNo: params.pageNo,
  //         sizePerPage: params.sizePerPage,
  //       },
  //     };
  //     const res = await fetchPendingTransactions(filters, transType);
  //     return res;
  //   } catch (e) {
  //     return {
  //       rows: 0,
  //       total: 0,
  //     };
  //   }
  // };

  const createServerSideDataSource = () => {
    return {
      getRows: async (params) => {
        const sizePerPage = params.request.endRow - params.request.startRow;
        const pageNo = params.request.endRow / sizePerPage;
        let filters = {
          pageNo,
          sizePerPage,
        };
        try {
          const res = await fetchPendingTransactions(filters, transType);
          if (res?.total) {
            params.success({
              rowData: res.data,
              rowCount: res.total,
            });
          } else {
            params.api.showNoRowsOverlay();
            params.success({
              rowData: [],
              rowCount: 0,
            });
            // params.fail();
          }
        } catch (e) {
          params.api.showNoRowsOverlay();
          params.success({
            rowData: [],
            rowCount: 0,
          });
          // params.fail();
        }
      },
    };
  };

  const onGridReady = useCallback((params) => {
    const dataSource = createServerSideDataSource();
    // Use updateGridOptions to register the datasource with the grid
    params.api.updateGridOptions({ serverSideDatasource: dataSource });
    params.api.sizeColumnsToFit();
  }, []);

  return (
    <Card
      w={"100%"}
      boxShadow={"lg"}
      mb={4}
      bg={"white"}
      p={5}
      borderRadius={"2xl"}
    >
      <Box
        w={"100%"}
        className="ag-theme-alpine"
        height={"550px"}
        border={"1px solid"}
        borderColor={"gray.200"}
        borderRadius={"2xl"}
        overflow={"hidden"}
      >
        <AdvancedGridTable
          gridRef={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowModelType={"serverSide"}
          cacheBlockSize={24}
          maxBlocksInCache={20}
          onGridReady={(params) => onGridReady(params)}
          enableCellTextSelection={true}
          isExternalFilterPresent={() => {
            return true;
          }}
          doesExternalFilterPass={() => {
            return true;
          }}
        />
      </Box>
    </Card>
  );
};
