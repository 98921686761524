import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, ListGroup, NavLink, Badge, Form } from "react-bootstrap";
import { FaInbox, FaEye, FaPlus, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import Moment from 'moment';
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { getMessageData } from "../../../services/activityService.js";
import "./message-view.scss";
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';

const MessageView = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  let params = useParams();
  useEffect(() => {
		if(typeof params.id != 'undefined' && params.id > 0) {
      getMessageForView(params.id);
		}
    else if(props.messageId != undefined && props.messageId > 0) {
      getMessageForView(props.messageId);
    }
    else {
      /* If id is undefined */
		}  
	}, []);
  const getMessageForView = async (id) => {
    setLoading(true);
		await dispatch(getMessageData(id)).then((res) => { 
      if(res.data.length > 0) {
        setTableData(res.data);
        setLoading(false);
      }
		});
	};
  const downloadAttachment = (docUrl) => {
    window.open(docUrl);
  }
  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="inbox-page-section">
        <h4 className="activity-log-title">Communication</h4>
        <Row>
          <Col xs="12">
            <div className="card-header-main">
              <div className="card-header-main-title">
                <FaInbox size={21} color={"#fff"} />
                <span className="card-header-main-text">Message View</span>
              </div>
              <div className="card-header-btns-div">
                <div className="card-header-action" style={{display: "flex"}}>
                  <FaEye size={21} color={"#fff"} />
                  <span className="card-header-action-title" 
                  onClick={()=> 
                    {
                       props.sourceCode != undefined ?
                       props.viewPart('VIEW')
                    :
                       navigation('/active-log/')
                    }}
                  >View Log</span>
                </div>
                <Button
                   style={{display: "flex"}}
                  id="common-outline-btn"
                  className="card-header-btn0"
                  variant="outline-primary"
                  onClick={()=> 
                    {
                       props.sourceCode != undefined ?
                       props.viewPart('NEW')
                    :
                       navigation('/active-log/new-activity')
                    }}
                >
                  <FaPlus size={15} /> Add New
                </Button>{" "}
              </div>
            </div>
          </Col>

          <Col xs="12" className="message-view-row">
            {!isEmpty(tableData) && tableData.length > 0 ? 
             tableData.map((data, i) => {
               return (
                <div>
                  <div className="card-colored-header">
                    <span>{ data.messagedetails.MessageSubject }</span>
                    <span className="float-right">{ data.messagedetails.MessageCreatedDate }</span>
                  </div>
                  <div className="card-colored-body">
                    <Row>
                      <Col xs="12" sm="12" md="12" lg="12" xl="7">
                        <Table borderless className="columns-table-main">
                          <tr>
                            <th>From</th>
                            <td>{data.messagemappingdetails[0].MessageUserName}</td>
                          </tr>
                          <tr>
                            <th>To</th>
                            <td>{data.messagemappingdetails[1].MessageUserName}</td>
                          </tr>
                          <tr>
                            <th>Created Date</th>
                            <td>{data.messagedetails.MessageCreatedDate}</td>
                          </tr>
                          <tr>
                            <th>Scheduled Date</th>
                            <td>{Moment(data.messagedetails.Scheduled_Date).format("MM-YYYY-DD")}</td>
                          </tr>
                          <tr>
                            <th>Subject</th>
                            <td>{data.messagedetails.MessageSubject}</td>
                          </tr>
                          <tr>
                            <th>Activity Type</th>
                            <td>{data.messagedetails.MessageSourceType}</td>
                          </tr>

                          <tr>
                            <th>Policy No</th>
                            <td>{data.messagedetails.MessageSourceCode}</td>
                          </tr>
                          <tr>
                            <th>Activity Status</th>
                            <td>{data.messagedetails.MessageStatusTask}</td>
                          </tr>
                          <tr>
                             <th>Attachment:</th> 
                            <td>
                            <ListGroup style={{ marginLeft : "-10px"}}>
                            {data.getdocdetails.map((link) => (
                              <ListGroup.Item>
                                <NavLink href='javascript:void(0);' style={{ padding: "0px", color: "#1454a8" }} onClick={() => downloadAttachment(link.DocURL)}>
                                  {link.Doc_OriginalName}
                                </NavLink>
                              </ListGroup.Item>
                            ))}
                              {/* <ListGroup.Item>No style</ListGroup.Item>
                              <ListGroup.Item>No style</ListGroup.Item>
                              <ListGroup.Item>No style</ListGroup.Item> */}
                            </ListGroup>
                            </td>
                          </tr>
                        </Table>
                      </Col>
                      <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="5"
                        className="message-view-btn-col"
                      >
                        <Button id="common-btn" variant="primary" onClick={() => 
                          {
                            props.sourceCode != undefined ?
                            props.setValuesCondition('NEW', data.messagedetails.MessageInfo_PK)
                          :
                          navigation(`/active-log/${data.messagedetails.MessageInfo_PK}/reply`)}
                          }
                          >
                          Reply
                        </Button>{" "}
                      </Col>
                    </Row>
                  </div>
                </div>
               );
            })
            : null
            } 
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
  );
};

function mapStateToProps(state) {
  return {
    getActivityList: state.common.getActivityList ? state.common.getActivityList : {},
    
  };
}
export default connect(mapStateToProps)(MessageView);
