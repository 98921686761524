import React from "react";
import {
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  MenuDivider,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";

const MultiSelect = ({ options, placeholder, selected, setSelected }) => {
  const handleSelect = (value) => {
    setSelected((prev) => {
      if (value === "None" || value === "Unknown") {
        return [value];
      } else {
        const filteredPrev = prev.filter(
          (item) => item !== "None" && item !== "Unknown"
        );
        return filteredPrev.includes(value)
          ? filteredPrev.filter((item) => item !== value)
          : [...filteredPrev, value];
      }
    });
  };

  const isSelected = (value) => selected.includes(value);

  const stopScrollPropagation = (event) => {
    event.stopPropagation();
  };

  const scrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "gray.300",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "gray.400",
    },
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        w="20%"
        as={Button}
        rightIcon={<i className="fa fa-angle-down" />}
        maxH="550px"
        overflowY="auto"
        borderRadius={"xl"}
      >
        {selected.length > 0 ? `Selected (${selected.length})` : placeholder}
      </MenuButton>
      <MenuList
        zIndex={999}
        h="400px"
        overflowY="auto"
        onWheel={stopScrollPropagation}
        sx={scrollbarStyles}
      >
        <Box h="500px" minW={"30rem"}>
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <MenuDivider />
              <MenuItem
                zIndex={999}
                key={index}
                justifyContent="space-between"
                px={4}
                _hover={{
                  bg: isSelected(option.value) ? "gray.200" : "gray.100",
                }}
                _focus={{
                  bg: isSelected(option.value) ? "gray.200" : "gray.100",
                }}
                _active={{
                  bg: isSelected(option.value) ? "gray.200" : "gray.100",
                }}
                onClick={() => handleSelect(option.value)}
              >
                <Flex
                  w="100%"
                  align="center"
                  alignItems="center"
                  onClick={() => handleSelect(option.value)}
                >
                  <Checkbox
                    size={"lg"}
                    p={3.5}
                    isChecked={isSelected(option.value)}
                    onChange={() => handleSelect(option.value)}
                  />
                  <Text
                    fontSize={"lg"}
                    pt={3.5}
                    ml={2}
                    cursor={"pointer"}
                    opacity={isSelected(option.value) ? 1 : 0.8}
                    onClick={() => handleSelect(option.value)}
                  >
                    {option.label}
                  </Text>
                </Flex>
              </MenuItem>
            </React.Fragment>
          ))}
        </Box>
      </MenuList>
    </Menu>
  );
};

export default MultiSelect;
