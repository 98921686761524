import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import "./discount.scss";
import "react-overlay-loader/styles.css";

const PreFirmDiscount = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading } = useSelector(state => state.flood);
  const [priorNFIPLapsed, setPriorNFIPLapsed] = useState('');
  const [communitySuspension, setCommunitySuspension] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);

  // set Initial State from reducer and get NAIC
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setPriorNFIPLapsed(emptyQuoteData.propertyInfo.s_IsPriorNFIPLapsed);
      setCommunitySuspension(emptyQuoteData.propertyInfo.s_IsCommunitySuspension);
      setIsStateSet(true);
    }
  }, []);

  useEffect(() => {
    if (isStateSet === true) {
      if (emptyQuoteData && initApiObject && emptyQuoteData.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM') {
        var quoteDataVar = emptyQuoteData;
        quoteDataVar['propertyInfo']['s_IsPriorNFIPPolicy'] = 'YES';
        dispatch(getEditEmptyQuoteData(quoteDataVar));
      }
    }
  }, [isStateSet]);

  // handle switch Change
  const handleChange = (e, state) => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    switch (state) {
      case 'priorNFIPLapsed':
        setPriorNFIPLapsed(temp);
        break;
      case 'communitySuspension':
        setCommunitySuspension(temp);
        break;
      default:
        break;
    }
    props.handleSwitchChange(e);
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-discount-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-discount-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="11">
                <div className="flood-discount-header">
                  <div className="flood-discount-title">
                    <h4 className="flood-discount-main-title">{t('Discounts')}</h4>
                    <p className="flood-discount-main-desc">
                      {t('Watch your premium drop as you add your discounts')}
                    </p>
                  </div>
                  <div className="flood-discount-pricing">
                    <h5 className="flood-discount-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-discount-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row>
                  <Col xs="12">
                    <div className="discount-percentage-icon"></div>
                  </Col>
                  <Col xs="12">
                    <div
                      className="flood-discount-row"
                      id="prefirm_discount-one-row"
                    >
                      <div className="flood-discount-question-div">
                        <p>
                          {t("Did the prior policy lapse while receiving a pre-firm discount?")}
                        </p>
                        <div className="flood-discount-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-discount-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_IsPriorNFIPLapsed"
                              checked={priorNFIPLapsed === 'YES' ? true : false}
                              onChange={(e) => { handleChange(e, 'priorNFIPLapsed') }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {
                    priorNFIPLapsed === 'YES' && (
                      <Col xs="12">
                        <div
                          className="flood-discount-row"
                          id="prefirm_discount-two-row"
                        >
                          <div className="flood-discount-question-div">
                            <p>
                              {t("Was the lapse the result of a community suspension?")}
                            </p>
                            <div className="flood-discount-row-actions">
                              <span>{t('No')}</span>
                              <div className="custom-main-swtich">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  className="flood-discount-row-switch"
                                  label=""
                                  parentobject="propertyInfo"
                                  name="s_IsCommunitySuspension"
                                  checked={communitySuspension === 'YES' ? true : false}
                                  onChange={(e) => { handleChange(e, 'communitySuspension') }}
                                  checkedvalue='YES'
                                  uncheckedvalue='NO'
                                />
                              </div>
                              <span>{t('Yes')}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  }
                </Row>
                <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={11} saveQuoteData={props.saveQuoteData} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay >
  );
};

export default PreFirmDiscount;
