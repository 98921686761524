import React from "react";
// import { Col, Row, Form } from "react-bootstrap";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";

const AgencyLocationAddress = ({ AgencyData }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Box p={6}>
        <Box mb={2}>
          <FormControl display={"flex"} alignContent={"center"}>
            <Switch
              height={20}
              width={48}
              onChange={handleChange}
              checked={checked}
              disabled={true}
            />
            <FormLabel ml={"6px"} display={"inline"}>
              {t("Copy as mailing Address")}
            </FormLabel>
          </FormControl>
        </Box>
        <Grid alignItems={"flex-end"} templateColumns="repeat(6, 1fr)" gap={4}>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>{t("Location Address")}</FormLabel>
              <Input
                type="text"
                id="loc_s_AddressLine1"
                name="loc_s_AddressLine1"
                className="form-control producer-group-input col-form-input input-producer"
                value={AgencyData?.loc_s_AddressLine1}
                isDisabled={true}
              />
            </FormControl>
          </GridItem>
          <FormControl>
            <FormLabel>{t("Zip")}</FormLabel>
            <Input
              type="text"
              id="loc_s_PostalCode"
              name="loc_s_PostalCode"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.loc_s_PostalCode}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("City")}</FormLabel>
            <Input
              type="text"
              id="loc_s_CityName"
              name="loc_s_CityName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.loc_s_CityName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("State")}</FormLabel>
            <Input
              type="text"
              id="loc_s_StateName"
              name="loc_s_StateName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.loc_s_StateName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("County")}</FormLabel>
            <Input
              type="text"
              id="loc_s_CountyName"
              name="loc_s_CountyName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.loc_s_CountyName}
              isDisabled={true}
            />
          </FormControl>
        </Grid>
      </Box>
      {/* <div
        className="animated fadeIn d-flex"
        style={{ marginLeft: "20px", marginTop: "5px" }}
      >
        <Switch
          height={20}
          width={48}
          onChange={handleChange}
          checked={checked}
          disabled={true}
        />
        <span>{t("Copy as mailing Address")}</span>
      </div>
      <div
        className="dashboard-table-div"
        id="dashboard-table-section"
        style={{ marginTop: "5px" }}
      >
        <Row>
          <Col xs="12">
            <Row>
              <Col xs={2} sm={5}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label p-0">
                    <b>{t("Location Address")}</b>
                  </label>
                  <Col sm="8" className="p-0">
                    <input
                      type="text"
                      name="Location Address"
                      className="form-control producer-group-input col-form-input input-producer"
                      value={
                        props?.AgencyData?.loc_s_AddressLine1
                          ? props?.AgencyData?.loc_s_AddressLine1
                          : null
                      }
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} sm={2}>
                <Row className="form-group">
                  <label className="col-sm-3 col-form-label p-0">
                    <b>{t("Zip")}</b>
                  </label>
                  <Col sm="8" className="p-0">
                    <input
                      type="text"
                      name="Zip"
                      className="form-control producer-group-input col-form-input input-producer"
                      value={
                        props?.AgencyData?.loc_s_PostalCode
                          ? props?.AgencyData?.loc_s_PostalCode
                          : null
                      }
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} sm={2}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label p-0">
                    <b>{t("City")}</b>
                  </label>
                  <Col sm="8" className="p-0">
                    <input
                      type="text"
                      name="City"
                      className="form-control producer-group-input col-form-input input-producer"
                      value={
                        props?.AgencyData?.loc_s_CityName
                          ? props?.AgencyData?.loc_s_CityName
                          : null
                      }
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} sm={2}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label p-0">
                    <b>{t("State")}</b>
                  </label>
                  <Col sm="8" className="p-0">
                    <input
                      type="text"
                      name="State"
                      className="form-control producer-group-input col-form-input input-producer"
                      value={
                        props?.AgencyData?.loc_s_StateName
                          ? props?.AgencyData?.loc_s_StateName
                          : null
                      }
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={2} sm={3} style={{ marginTop: "15px" }}>
                <Row className="form-group">
                  <label className="col-sm-4 col-form-label p-0">
                    <b>{t("County")}</b>
                  </label>
                  <Col sm="8" className="p-0">
                    <input
                      type="text"
                      name="County"
                      className="form-control producer-group-input col-form-input input-producer"
                      value={
                        props?.AgencyData?.loc_s_CountyName
                          ? props?.AgencyData?.loc_s_CountyName
                          : null
                      }
                      disabled={true}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div> */}
    </>
  );
};
export default AgencyLocationAddress;
