import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { MdDelete, MdEdit, MdAdd } from "react-icons/md";
import AdvancedGridTable from "../../../../../../components/AdvancedGridTable";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import states from "../../../../common/States";
import { useAutoZipSelection } from "../../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../../common/LocationSelectionModal";
import { useTranslation } from "react-i18next";
import { starCommercialContext } from "../../CommercialContext";
import { useContext } from "react";

export const Mortgagees = ({ mortgageeData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [formData, setFormData] = useState({
    personType: "",
    loanNo: "",
    bankName: "",
    addressLine1: "",
    addressLine2: "",
    county: "",
    city: "",
    cityId: "",
    stateId: "",
    countryId: "",
    stateCode: "",
    zipCode: "",
  });

  const { commercialData, setCommercialData, handleSubmitQuote, isBind } =
    useContext(starCommercialContext);
  const columnDefs = [
    {
      headerName: "ID",
      field: "n_PolicyAddInterest_PK",
      flex: 0,
      width: 80,
    },
    { headerName: "Person Type", field: "personType" },
    { headerName: "Mortgagee Type", field: "mortgageeType" },
    { headerName: "Loan Number", field: "loanNo" },
    { headerName: "Business Name", field: "person.lastName" },
    {
      headerName: "Address",
      valueGetter: (params) =>
        `${params.data.address.addressLine1}, ${params.data.address.city}, ${params.data.address.stateCode} ${params.data.address.zip}`,
    },
    {
      headerName: "Actions",
      flex: 0,
      width: 110,
      cellRenderer: (params) => (
        <Flex alignItems={"center"} gap={2} mt={1}>
          <Button
            isDisabled={isBind}
            size={"xs"}
            variant={"outline"}
            p={0.5}
            border={"1px solid"}
            borderColor={"gray.500"}
            borderRadius={"xl"}
            cursor={"pointer"}
            onClick={() => handleEdit(params.data)}
          >
            <MdEdit size={20} />
          </Button>
          <Button
            isDisabled={isBind}
            size={"xs"}
            variant={"outline"}
            p={0.5}
            border={"1px solid"}
            borderColor={"gray.500"}
            borderRadius={"xl"}
            cursor={"pointer"}
            onClick={() => {
              //  handleDelete(params.data)
              setCommercialData((prevData) => ({
                ...prevData,
                mortgagees: { data: [] },
              }));
              setSubmitTriggered(true);
            }}
          >
            <MdDelete size={20} />
          </Button>
        </Flex>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    flex: 1,
    wrapText: true,
    autoHeight: true,
  };

  const handleEdit = (data) => {
    onOpen();
    setFormData({
      personType: data?.personType,
      loanNo: data?.loanNo,
      bankName: data?.person?.lastName,
      addressLine1: data?.address?.addressLine1,
      addressLine2: data?.address?.addressLine2,
      county: data?.address?.county,
      city: data?.address?.city,
      cityId: data?.address?.cityId,
      stateId: data?.address?.stateId,
      countryId: data?.address?.countryId,
      stateCode: data?.address?.stateCode,
      zipCode: data?.address?.zip,
      countyId: data?.address?.countyId,
    });
  };

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    formData.zipCode,
    true
  );

  const [zipData, setZipData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedLocation) {
      setZipData(selectedLocation);
      if (showModal) {
        setOpenModal(true);
      }
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData) {
      setFormData((prevData) => ({
        ...prevData,
        stateCode: zipData.s_StateCode,
        city: zipData.s_CityName,
        cityId: zipData.city_id || 0,
        stateId: zipData.state_id || 0,
        countryId: zipData.n_CountryId_FK || 1,
        countyId: zipData.county_id || 0,
        county: zipData.s_CountyName || "",
      }));
    }
  }, [zipData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.loanNo) newErrors.loanNo = "Loan Number is required.";
    if (!formData.bankName) newErrors.bankName = "Bank Name is required.";
    if (!formData.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required.";
    if (!formData.zipCode) newErrors.zipCode = "ZIP Code is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const newMortgagee = {
        n_PolicyAddInterest_PK: null,
        personType:
          (mortgageeData?.data?.length === 0 && "PRIMARY") ||
          (mortgageeData?.data?.length === 1 && "SECONDARY") ||
          (mortgageeData?.data?.length === 2 && "THIRD"),
        mortgageeType: "First mortgagee",
        loanNo: formData.loanNo,
        person: {
          entityType: "ORGANISATION",
          firstName: "",
          middleName: null,
          lastName: formData.bankName,
          isMailingDiff: null,
          isTempMailing: null,
        },
        address: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          county: formData.county,
          city: formData.city,
          cityId: formData.cityId,
          stateId: formData.stateId,
          countryId: formData.countryId,
          stateCode: formData.stateCode,
          countyId: formData.countyId,
          latitude: null,
          longitude: null,
          zip: formData.zipCode,
        },
      };

      setCommercialData((prevData) => ({
        ...prevData,
        mortgageeData: [newMortgagee],
        //  [...mortgageeData?.data, newMortgagee],
      }));
      setSubmitTriggered(true);
      onClose();
    }
  };

  useEffect(() => {
    if (submitTriggered) {
      handleSubmitQuote();
      setSubmitTriggered(false);
    }
  }, [commercialData]);

  const formFields = [
    { name: "loanNo", label: "Loan Number" },
    { name: "bankName", label: "Bank Name" },
    { name: "addressLine1", label: "Address Line 1" },
    { name: "addressLine2", label: "Address Line 2" },
    { name: "zipCode", label: "ZIP Code", type: "number" },
    { name: "county", label: "County" },
    { name: "city", label: "City" },
    {
      name: "stateCode",
      label: "State Code",
      type: "select",
      options: [...states],
    },
  ];

  return (
    <Box my={4}>
      <Flex justifyContent="space-between" mb={4}>
        <Button
          isDisabled={isBind}
          leftIcon={<MdAdd />}
          onClick={() => {
            setFormData({
              personType: "",
              loanNo: "",
              bankName: "",
              addressLine1: "",
              addressLine2: "",
              county: "",
              stateCode: "",
              zipCode: "",
            });
            onOpen();
          }}
          borderRadius={"2xl"}
          variant={"outline"}
        >
          {t("Add Mortgagee")}
        </Button>
      </Flex>
      <Box className="ag-theme-alpine">
        <AdvancedGridTable
          rowData={mortgageeData?.data || []}
          columnDefs={columnDefs}
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
        />
      </Box>
      <>
        <LocationSelectionModal
          isOpen={openModal}
          selectedLocation={selectedLocation}
          setOpenModal={setOpenModal}
          setZipData={setZipData}
        />
        <Modal isCentered isOpen={isOpen} onClose={onClose} size={"6xl"}>
          <ModalOverlay />
          <ModalContent borderRadius={"2xl"}>
            <ModalHeader bg={"agGridHeaderBg"} borderTopRadius={"2xl"}>
              <Flex gap={2}>
                <Text my={1} py={1}>
                  {" "}
                  {t("Add Mortgagee")}
                </Text>
                <Box mt={2.5}>{isLoading && <Spinner />}</Box>
                {/* <Text color={"gray"} my={1} py={1}>
                  (
                  {(mortgageeData?.data?.length === 0 && "Primary") ||
                    (mortgageeData?.data?.length === 1 && "Secondary") ||
                    (mortgageeData?.data?.length === 2 && "Third")}
                  )
                </Text> */}
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid
                gridTemplateColumns={"repeat(auto-fit, minmax(250px, 1fr))"}
                gap={2}
                p={3}
              >
                {formFields.map((field) => (
                  <FormControl
                    key={field.name}
                    mb={4}
                    isInvalid={!!errors[field.name]}
                  >
                    <FormLabel>{field.label}:</FormLabel>
                    {field.type === "select" ? (
                      <Select
                        name={field.name}
                        onChange={handleChange}
                        value={formData[field.name]}
                        placeholder=""
                      >
                        <option value=""></option>
                        {field.options.map((option, i) => (
                          <option key={i} value={option.abbr}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        name={field.name}
                        onChange={handleChange}
                        placeholder=""
                        value={formData[field.name]}
                        type={field.type || "text"}
                        onKeyDown={(e) =>
                          field.name === "zipCode" &&
                          ["e", "E", "+", "-"].includes(e.key) &&
                          e.preventDefault()
                        }
                      />
                    )}
                    {errors[field.name] && (
                      <FormErrorMessage>{errors[field.name]}</FormErrorMessage>
                    )}
                  </FormControl>
                ))}
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button
                borderRadius={"xl"}
                leftIcon={<FaSave />}
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={isLoading}
                loadingText="Submitting"
                ml={3}
              >
                {t("Submit")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Box>
  );
};
