import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Flex,
  Icon,
  Switch,
  Tooltip,
} from "@chakra-ui/react";
import { FaGasPump, FaShoppingBag, FaUtensils } from "react-icons/fa";
import { useQuote } from "../../CommCreateQuote";
import { FcInfo } from "react-icons/fc";

const QUESTIONS = [
  {
    key: "is_restaurant",
    label: "Is this a Restaurant?",
    type: "switch",
    icon: FaUtensils,
  },
  {
    key: "is_gas_station",
    label: "Is this a Gas Station?",
    type: "switch",
    icon: FaGasPump,
  },
  {
    key: "is_convenience_store",
    label: "Is this a Convenience Store?",
    type: "switch",
    icon: FaShoppingBag,
  },
];

const QuestionItem = ({ question, value, onChange }) => {
  const { key, label, type, icon } = question;

  return (
    <Flex
      w="100%"
      p={4}
      borderBottom={key === "is_convenience_store" ? "none" : "1px"}
      borderColor="gray.200"
      alignItems="center"
    >
      <FormControl>
        <Flex justifyContent="space-between" alignItems="center" pt={3}>
          <Flex alignItems="center" gap={3} p={1}>
            <Box
              border={"1px solid"}
              borderColor={"gray.200"}
              p={2}
              borderRadius={"2xl"}
            >
              <Icon as={icon} boxSize={8} color="blue.600" mb={1} />
            </Box>
            <FormLabel fontSize="lg" fontWeight="medium" mb={0}>
              {label}
            </FormLabel>
            {key === "is_restaurant" && (
              <Tooltip hasArrow label=" " fontSize="md">
                <Box ml={"-15px"} mt={0.5}>
                  <FcInfo size={18} />
                </Box>
              </Tooltip>
            )}
          </Flex>
          {type === "switch" && (
            <Switch
              size="lg"
              isChecked={value}
              onChange={(e) => onChange(key, e.target.checked)}
            />
          )}
        </Flex>
      </FormControl>
    </Flex>
  );
};

export const ConvenienceStoreQuestionaireCard = () => {
  const { convenienceStoreResponses, setConvenienceStoreResponses } =
    useQuote();

  const handleChange = (key, value) => {
    setConvenienceStoreResponses(() => ({
      ...convenienceStoreResponses,
      [key]: value,
    }));
  };

  return (
    <Box
      bg="white"
      boxShadow="0 0 6px rgba(0, 0, 0, 0.2)"
      borderRadius="2xl"
      p={6}
      mb={6}
      mr={1}
    >
      <Flex direction="column" gap={4}>
        {QUESTIONS.map((question) => (
          <QuestionItem
            key={question.key}
            question={question}
            value={convenienceStoreResponses[question.key]}
            onChange={handleChange}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default ConvenienceStoreQuestionaireCard;
