import React from "react";
import { Table } from "react-bootstrap";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { MdDelete, MdOutlineAddBox } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CustomCurrencyInput } from "../../../../common/CustomCurrencyInput";
import { Button, Input } from "@chakra-ui/react";
import { toast } from "react-toastify";
export const PriorLossesTable = () => {
  const { t } = useTranslation();
  const { financialDetails, setFinancialDetails } =
    useMyContext(CreateQuoteContext);

  const handleAddRow = () => {
    const newRow = { Year: "", Value: "" };
    setFinancialDetails({
      ...financialDetails,
      priorLosses: [...financialDetails.priorLosses, newRow],
    });
  };

  const handleRemoveRow = (indexToRemove) => {
    const updatedPriorLosses = financialDetails.priorLosses.filter(
      (_, index) => index !== indexToRemove
    );
    setFinancialDetails({
      ...financialDetails,
      priorLosses: updatedPriorLosses,
    });
  };

  const handleChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = financialDetails.priorLosses.map(
      (row, index) => {
        if (index === rowIndex) {
          return { ...row, [field]: parsedValue };
        }
        return row;
      }
    );

    setFinancialDetails({
      ...financialDetails,
      priorLosses: updatedPriorLosses,
    });
  };

  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid var(--chakra-colors-gray-300)",
    padding: "0.62rem",
    borderRadius: "12px",
    width: "100%",
    height: "53.5px",
  };

  return (
    <>
      <Table bordered responsive striped hover>
        <thead>
          <tr>
            <th>{t("Year")}</th>
            <th>{t("Value")}</th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {financialDetails &&
            financialDetails?.priorLosses?.map((row, index) => (
              <tr key={index}>
                <td>
                  <Input //do not use CurrencyInput here
                    size="lg"
                    min={0}
                    style={CurrencyStyles}
                    value={row.Year}
                    onChange={(e) => {
                      const currentYear = new Date().getFullYear();
                      if (e.target.value <= currentYear) {
                        handleChange("Year", Number(e.target.value), index);
                      } else {
                        toast.warn(
                          t("cannot be greater than year bult or current year"),
                          {
                            toastId: "priorLossesYear",
                          }
                        );
                      }
                    }}
                  />
                  {row.Year <= 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("can't be zero")}
                    </span>
                  )}
                </td>
                <td>
                  <CustomCurrencyInput
                    value={row.Value}
                    onChange={(e) =>
                      handleChange(
                        "Value",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {row.Value <= 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("can't be zero")}
                    </span>
                  )}
                </td>
                <td className="text-center align-middle">
                  <Button
                    colorScheme={"red"}
                    borderRadius={"xl"}
                    size={"sm"}
                    onClick={() => handleRemoveRow(index)}
                  >
                    <MdDelete size={19} />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Button
        borderRadius={"xl"}
        size={"sm"}
        leftIcon={<MdOutlineAddBox />}
        onClick={handleAddRow}
        style={{ backgroundColor: "#64D6CD", border: "none" }}
      >
        {t("Add Row")}
      </Button>
    </>
  );
};
