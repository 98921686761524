import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Text
} from "@chakra-ui/react";
import React, { useState } from "react";
import { LiveChatWidget } from "@livechat/widget-react";
import { isTenancyEnabledForAmazonChat } from "../../common/Helper";
import LiveChatCustom from "./LiveChatCustom";
import { useTranslation } from "react-i18next";
import { getCompanyDetails } from "../../../services/commonService";

export default function Support2() {
  const companyPhone = getCompanyDetails("phone");
  const [chatButton, setChatButton] = useState(false);
  const { t } = useTranslation();
  const licenseKey = process.env.REACT_APP_LIVECHAT_LICENSE_KEY;
  const enableChatBot = localStorage.getItem('enable_chat_bot');
  
  return (
    <Box p={"3px"} boxShadow={"lg"} pos={"relative"} borderRadius={"2xl"}>
      <Box m={2}>
        <Text fontSize="20px" fontWeight={600} pl={3}>
          <HStack spacing="10px">
            <Box>
              <img src={"/assets/icons/likeTagIcon.svg"} alt="tag" width={25} height={25} />
            </Box>
            <Box>{t("Help & Support")}</Box>
          </HStack>
        </Text>
      </Box>
      <Box my={"1rem"} borderTop={"2px solid"} borderColor={"gray.200"} />
      {enableChatBot === "Y" && (
        <>
          <Box px={3}>
            <Text fontWeight={800} fontSize="16px" mx="16px">
              {t("Live chat with our support assistant")}
            </Text>
            <Text
              fontWeight={600}
              fontSize="15px"
              mx="16px"
              color="neutral.600"
              lineHeight="20px"
            >
              {t("Online chat is available from 8am - 7pm Eastern (ET)")}.
            </Text>
          </Box>
          <Button
            mt={2}
            mx={6}
            leftIcon={
              <img src={"/assets/icons/messagesTwoIcon.svg"} alt="quicklink" width={20} height={20} />
            }
            onClick={() => setChatButton(!chatButton)}
            _hover={{ background: "var(--createQuoteHeaderButton)" }}
            className="launch-widget-btn"
          >
            {t("Chat Now")}
          </Button>
          {chatButton && !isTenancyEnabledForAmazonChat && (
            <>
              <LiveChatCustom />
              <LiveChatWidget license={licenseKey} visibility="maximized" />
            </>
          )}
          <Box
            mt={"1.6rem"}
            mb={"1rem"}
            borderTop={"2px solid"}
            borderColor={"gray.200"}
          />
        </>
      )}
      <Box px={3}>
        <Text fontWeight={800} fontSize="16px" mx="16px">
          {t("need_Spport")}?
        </Text>
        <Flex alignItems={"center"} gap={1} my="-4px">
          <Box pb={4}>
            <Image src={"/assets/icons/phoneLogo.svg"} alt="phone" boxSize={55} />
          </Box>

          <Text fontWeight={800} fontSize="xl">
            {companyPhone}
          </Text>
        </Flex>
        <Text
          fontWeight={600}
          fontSize="15px"
          mx="16px"
          color="neutral.600"
          lineHeight="20px"
        >
          {t("Feel free to reach out to us anytime from 8am to 7pm Eastern (ET), and we'll gladly lend a hand")}.
        </Text>
      </Box>
    </Box>
  );
}
