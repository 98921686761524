import {
  React,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
// import { Accordion, Button, Card, } from "react-bootstrap";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  loadCeLicenseDocumentDataForProducerView,
  getViewDocument,
} from "../../../services/producerService";
// import { dateFormat } from '../../../services/commonService';
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { formateDate } from "../../common/FormatDate";
import { FaRegEye } from "react-icons/fa";
import { toast } from "react-toastify";

const CeLicenseModal = (props) => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 25, 50];
  const statusArray = {
    APPROVED: { label: "Completed", color: "green" },
    REJECTED: { label: "Rejected", color: "red" },
    PENDING: { label: "Approval Pending", color: "orange" },
  };

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = async () => {
    setLoading(true);
    try {
      const response = await loadCeLicenseDocumentDataForProducerView({
        pageTemp: 1,
        sizePerPageTemp: 50,
        agencyPersonId: props?.agencyId || 0,
      });
      setListData(response?.returnObject);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // const viewDocument = async (row) => {
  //   setLoading(true)
  //   if(row?.docInfo?.length > 0){
  // 	row.docInfo.map(async (doc) => {
  // 		let data = await getViewDocument(doc.DocInfo_PK, doc.systemSource, 'Producer');
  // 		let docUrl = data.data;
  // 		if (docUrl != '') {
  // 			window.open(docUrl);
  // 		}
  // 	});

  // }else{
  // 	toast.error('Document Not Available');
  // }
  // setLoading(false)
  // }
  // const formatDate = (date) =>{
  //   return  dateFormat(date,'MM-DD-YYYY')
  // }

  // const actionButton = (cell, row, enumObject, rowIndex) => {
  //   return (
  //       <div>
  //           <Button id="common-btn" name="actionNameData"  style={{ padding: "6px 10px" }} onClick={(e) => viewDocument(row)} ><i className='fa fa-eye' /></Button>
  //       </div>
  //   )
  // }

  // const columns = [
  //   {
  //       dataField: "courseName",
  //       text: t("Course Name"),
  //   },
  //   {
  //       dataField: "uploadedDate",
  //       text: t("Upload Date"),
  //       formatter: (value,row) =>formatDate(row.uploadedDate)
  //   },
  //   {
  //       dataField: "expiryDate",
  //       text: t("Expiry Date"),
  //       formatter: (value,row) =>formatDate(row.expiryDate)
  //   },
  //   {
  //       dataField: "s_ScreenName",
  //       text: t("User"),
  //   },
  //   {
  //       dataField: "docStatus",
  //       text: t("Status"),
  //       formatter: (value, row) => statusField(value, row),
  //   },

  //   {
  //       text: t("Actions"),
  //       formatter: (value, row) => actionButton(value, row),
  //   },
  // ];

  // const statusField = (cell, row) => {
  //   if (row.docStatus == "APPROVED") {
  //       return (
  //           <div>
  //               <h1 style={{ fontSize: "1.2rem", color: "green" }} >Completed</h1>
  //           </div>
  //       )
  //   }
  //   if (row.docStatus == "REJECTED") {
  //       return (
  //           <div>
  //               <h1 style={{ fontSize: "1.2rem", color: "red", cursor: "pointer" }}>
  //                   <div>
  //                       <a href='#' style={{ color: "red" }}>Rejected</a>
  //                   </div>
  //               </h1>
  //           </div>
  //       )
  //   }
  //   if (row.docStatus == "PENDING") {
  //       return (
  //           <div>
  //               <h1 style={{ fontSize: "1.2rem", color: "yellow" }}>Approval Pending</h1>
  //           </div>
  //       )
  //   }
  // }

  const openDocument = async (rowData) => {
    setLoading(true);
    if (rowData?.docInfo?.length > 0) {
      rowData.docInfo.map(async (doc) => {
        let res = await getViewDocument(
          doc.DocInfo_PK,
          doc.systemSource,
          "Producer"
        );
        let docUrl = res.data;
        if (docUrl != "") {
          window.open(docUrl);
        }
      });
    } else {
      toast.error("Document Not Available");
    }
    setLoading(false);
  };

  const ActionCellRenderer = (params) => {
    const rowData = params.data;
    return (
      <Flex>
        <Button
          mt={"4px"}
          size={"sm"}
          name="actionNameData"
          onClick={(e) => openDocument(rowData)}
        >
          <FaRegEye />
        </Button>
      </Flex>
    );
  };

  const StatusCellRenderer = (params) => {
    return (
      <Text
        mb={0}
        wordBreak={"break-word"}
        fontSize="1rem"
        fontWeight={500}
        color={statusArray[params.data?.docStatus]?.color}
      >
        {statusArray[params.data?.docStatus]?.label || ""}
      </Text>
    );
  };

  const WordBreakRenderer = (params) => {
    return <div style={{ wordBreak: "break-word" }}>{params.value}</div>;
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      headerName: t("Course Name"),
      field: "courseName",
      filter: "agTextColumnFilter",
      wrapText: true,
      autoHeight: true,
      minWidth: 160,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("Upload Date"),
      field: "uploadedDate",
      filter: "agDateColumnFilter",
      minWidth: 130,
      cellRenderer: (params) => {
        return (
          <div style={{ lineHeight: "31px" }}>{formateDate(params.value)}</div>
        );
      },
    },
    {
      headerName: t("Expiry Date"),
      field: "expiryDate",
      filter: "agDateColumnFilter",
      minWidth: 130,
      cellRenderer: (params) => {
        return (
          <div style={{ lineHeight: "31px" }}>{formateDate(params.value)}</div>
        );
      },
    },
    {
      headerName: t("User"),
      field: "s_ScreenName",
      filter: "agTextColumnFilter",
      wrapText: true,
      autoHeight: true,
      minWidth: 190,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("Status"),
      field: "docStatus",
      filter: "agSetColumnFilter",
      wrapText: true,
      autoHeight: true,
      minWidth: 120,
      cellRenderer: (params) => StatusCellRenderer(params),
    },
    {
      headerName: t("Actions"),
      field: "",
      filter: false,
      sortable: false,
      minWidth: 110,
      cellRenderer: (params) => ActionCellRenderer(params),
    },
  ]);

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box
          boxShadow={"lg"}
          bgColor={"white"}
          borderRadius={"2xl"}
          borderColor={"white"}
          m={2}
        >
          <Box
            className="ag-theme-alpine"
            height={"450px"}
            id="myGrid"
            w={"100%"}
          >
            <AdvancedGridTable
              gridRef={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              rowData={listData}
              onGridReady={(params) => onGridReady(params)}
              enableCellTextSelection={true}
            />
          </Box>
        </Box>
      </LoadingOverlay>
      {/* <LoadingOverlay>
     <Loader loading={loading}/>
      <Accordion defaultActiveKey={["1"]} alwaysOpen> */}
      {/* <Accordion.Item eventKey="0">
          <Accordion.Header>Upload Files</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col className="col-5">
                <div
                  className="dashboard-table-div"
                  id="dashboard-table-section"
                >
                  <Row>
                    <Col xs="12">
                      <Row>
                        <Col xs={1} sm={6}>
                          <b>Document Name</b>
                        </Col>
                        <Col xs={3} sm={6}>
                          <input
                            type="text"
                            name="version"
                            id="version"
                            className="form-control dashboard-group-input"
                          />
                        </Col>
                        <Col xs={1} sm={6} style={{ marginTop: "1.3rem" }}>
                          <b>Issue Date</b>
                        </Col>
                        <Col xs={3} sm={6} style={{ marginTop: "1.3rem" }}>
                          <input
                            type="date"
                            name="version"
                            id="version"
                            className="form-control dashboard-group-input"
                          />
                        </Col>
                        <Col xs={1} sm={6} style={{ marginTop: "1.3rem" }}>
                          <b>Expiry Date</b>
                        </Col>
                        <Col xs={3} sm={6} style={{ marginTop: "1.3rem" }}>
                          <input
                            type="date"
                            name="version"
                            id="version"
                            className="form-control dashboard-group-input"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className="col-7">Upload Files</Col>
              <Col
                className="col-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  style={{
                    background: "#64D6CD",
                    border: "1px solid #64D6CD",
                  }}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item> */}
      {/* <Accordion.Item eventKey="1" >
          <Card.Header id="card-header-style">{t("List License")}</Card.Header>
          <Accordion.Body>
          <BootstrapTable
            wrapperClasses="management-functional-table"
            bootstrap4
            keyField="BaseMessageLink_IdFk"
            data={listData}
            columns={columns}
            // rowStyle={rowStyle}
            // selectRow={selectRowProp}
            hover
            bordered={false}
            pagination={paginationFactory()}
            tabIndexCell
          />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </LoadingOverlay> */}
    </>
  );
};

export default CeLicenseModal;
