/* global amazon_connect */
import React, { useEffect } from "react";
import { EncryptOrDecrypt } from "../../../services/commonService";
import { tenant } from "../../../pages/common/Helper";

const loadAmazonConnectScript = () => {
  const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");

  (function (w, d, x, id) {
    const s = d.createElement("script");
    s.src =
      "https://dg9yx063wiiht.cloudfront.net/amazon-connect-chat-interface-client.js";
    s.async = 1;
    s.id = id;
    d.getElementsByTagName("head")[0].appendChild(s);
    w[x] =
      w[x] ||
      function () {
        (w[x].ac = w[x].ac || []).push(arguments);
      };
  })(
    window,
    document,
    "amazon_connect",
    "1b4b000f-a2b4-45a2-8d9f-cccc2711b047"
  );

  amazon_connect("styles", {
    iconType: "CHAT",
    openChat: { color: "#ffffff", backgroundColor: "var(--buttonColor)" },
    closeChat: { color: "#ffffff", backgroundColor: "var(--buttonColor)" },
  });
  amazon_connect(
    "snippetId",
    "QVFJREFIaWtnNjZPdjVkZlUwdFhDTXVXcGwxYjNONktFVmUvMXdZcUxEazZpU0lmVXdGbnBJbDkwemlnZFB5S2tPMjY1OXBOQUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNS3hESEdPZ0V0MDJ1OFgzT0FnRVFnQ3VHcEI2aFZnWDlYSW45SjRwNTdRYk9wdXpHbURHY0ExVUtjemVHb2RuaWhqbkNjUGs1SzkzazNtc0c6OnAwVTNoT0VCUE4rQnhzcW9sMGxYdWVnb3RrWjFLcmsxbVhWQjRuMTZVSDVYNzA5NktVV01OWUVtZFVvaGxxdGE1MTZkU3JsYWV3VlhpK3RDeThlVkF3QXlYRnpJeUZjblpVTU4zNElJK0loMWh3anlSaGs1RVNiNFVWbFVobEh2ZTZEa3MvRkpGcU1RcExrNTY4N3RaUk9vSG0ydmp1TT0="
  );
  amazon_connect("supportedMessagingContentTypes", [
    "text/plain",
    "text/markdown",
    'application/vnd.amazonaws.connect.message.interactive',
    'application/vnd.amazonaws.connect.message.interactive.response'
  ]);
  amazon_connect("authenticate", function (callback) {
    const token = localStorage.getItem('access_token') || '';

    window.fetch(
      `${process.env.REACT_APP_API_URL}/api/v2/amazon/connect`,
      {
        method: 'GET',
        headers: {
          'X-Tenant': tenant,
          'Authorization': `Bearer ${token}`
        }
      }
    ).then((res) => {
      res.json().then((data) => {
        if (data.status) {
          callback(data.token);
        }
      });
    });
  });
  amazon_connect("customerDisplayName", function (callback) {
    const displayName = `${userDatas.s_ScreenName}`;
    callback(displayName);
  });
  amazon_connect('customLaunchBehavior', {
    skipIconButtonAndAutoLaunch: true,
    alwaysHideWidgetButton: true,
    programmaticLaunch: (function (launchCallback) {
      var launchWidgetBtns = document.getElementsByClassName('launch-widget-btn');
      if (launchWidgetBtns.length) {
        for(let i=0; i<launchWidgetBtns.length; i++ ) {
          launchWidgetBtns[i].addEventListener('click', launchCallback);
          window.onunload = function () {
            launchWidgetBtns[i].removeEventListener('click', launchCallback);
            return;
          }
        }
      }
    }),
  });
  amazon_connect('customizationObject', {
    header: {
        dropdown: true,
    },
    footer: {
        disabled: false,
    }
  });
};

const AmazonConnectChatWidget = () => {

  useEffect(() => {
    loadAmazonConnectScript()
  });

  return <></>;
};

export default AmazonConnectChatWidget;
