import {
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import { Currency } from "../../../../common/Currency";
import { useTranslation } from "react-i18next";

export const LossHistoryTable = ({ lossHistoryData, setLossHistoryData }) => {
  const { t } = useTranslation();
  const handleDelete = (index) => {
    setLossHistoryData(lossHistoryData.filter((_, i) => i !== index));
  };
  return (
    <>
      <Flex w={"100%"} p={3}>
        <TableContainer
          w={"100%"}
          border={"1px solid"}
          borderColor={"primary.50"}
          borderRadius={"xl"}
        >
          <Table
            variant="simple"
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"xl"}
            overflow={"hidden"}
          >
            <Thead bg={"primary.50"}>
              <Tr>
                <Th border={"1px solid"} borderColor={"primary.50"}>
                  #
                </Th>
                <Th border={"1px solid"} borderColor={"primary.50"}>
                  {t("Date of Loss")}
                </Th>
                <Th border={"1px solid"} borderColor={"primary.50"}>
                  {t("Loss Type")}
                </Th>
                <Th
                  border={"1px solid"}
                  borderColor={"primary.50"}
                  textAlign={"right"}
                >
                  {t("Amount")}
                </Th>
                <Th
                  textAlign={"right"}
                  border={"1px solid"}
                  borderColor={"primary.50"}
                >
                  {t("Loss Description")}
                </Th>
                <Th
                  textAlign={"right"}
                  border={"1px solid"}
                  borderColor={"primary.50"}
                >
                  {t("Actions")}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(lossHistoryData) &&
                lossHistoryData.map((row, index) => (
                  <Tr key={index}>
                    <Td border={"1px solid"} borderColor={"primary.50"}>
                      {index + 1}
                    </Td>
                    <Td border={"1px solid"} borderColor={"primary.50"}>
                      {row.dateOfLoss}
                    </Td>
                    <Td border={"1px solid"} borderColor={"primary.50"}>
                      {row.causeOfLoss}
                    </Td>
                    <Td border={"1px solid"} borderColor={"primary.50"}>
                      {<Currency amount={row.totalPayout} />}
                    </Td>
                    <Td
                      textAlign={"right"}
                      border={"1px solid"}
                      borderColor={"primary.50"}
                    >
                      {row.lossDescription}
                    </Td>
                    <Td border={"1px solid"} borderColor={"primary.50"}>
                      <Flex alignItems={"center"} justifyContent={"end"}>
                        <IconButton
                          aria-label="Delete"
                          icon={<FaTrash />}
                          size="sm"
                          onClick={() => handleDelete(index)}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  );
};
