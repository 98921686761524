import Http from "../../../../../Http";

const API_URL = `${process.env.REACT_APP_POLICY_CORE_URL}/api/v1/CP/getQuoteBaseData`;

export async function fetchStarComBaseData() {
  try {
    const response = await Http.get(API_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
    return false;
  }
}
