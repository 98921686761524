import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Tab, Form, Button, Modal } from "react-bootstrap";
import { FaBars, FaSearch } from "react-icons/fa";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {Link } from 'react-router-dom';
import { getAndCheckMortgageeInformation, getMortgageeListDataForSet, getMortgageeSaveData } from "../../services/quickLinksService";
import { showDataTableTotalText } from "../../services/commonService";
import css from "./mortgagee.scss";
import { useNavigate } from "react-router-dom";
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { CalendarContainer } from "react-datepicker";
import Swal from 'sweetalert2';
const MortgageeInformation = (props) => {
  let params = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDatas, setTableDatas] = useState([]);
  const [tableTotal, setTableTotal] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [currentMortgagee, setCurrentMortgagee] = useState('');
  const [filter, setFilter] = useState({
     MortgageeLastName:'',
     MortgageeClientId:'',
     MortgageeCity:'',
     MortgageeFirstName:'',
     MortgageeState:'',
     page:1,
     pageLength:10
 });
 const [data, setData] = useState({
    firstmortgageeName:'',
    secondmortgageeName:'',
    thirdmortgageeName:'',
    firstmortgageeAddress:'',
    secondmortgageeAddress:'',
    thirdmortgageeAddress:'',
    firstmortgageeLoanNo:'',
    secondmortgageeLoanNo:'',
    thirdmortgageeLoanNo:'',
    firstmortgageeCorePersonPK:'',
    firstmortgageeCoreAddrPK:'',
    secondmortgageeCorePersonPK:'',
    secondmortgageeCoreAddrPK:'',
    thirdmortgageeCorePersonPK:'',
    thirdmortgageeCoreAddrPK:'',
    corePolicyPK:'',
    corePolicyNO:''
});
    useEffect(() => {
        if(isModelOpen == true){
          searchMortgageeList();
        }
    }, [isModelOpen]);
    
    useEffect(() => {
        if(isModelOpen == true){
        searchMortgageeList();
        }
    }, [filter.page]);

    useEffect(() => {
        if(!isEmpty(tableData)){
            const mortgegeeData = data;
            mortgegeeData['corePolicyNO'] = tableData.policyData.Policy_No;
            mortgegeeData['corePolicyPK'] = tableData.policyData.n_PolicyNoId_PK;
            if(typeof tableData.MortgageeData1.n_POMortgageePerson_Fk !== 'undefined' ){
                let address = tableData.MortgageeData1.s_AddressLine1 + ',' + tableData.MortgageeData1.s_CityName + ',' + tableData.MortgageeData1.s_StateCode + ',' + tableData.MortgageeData1.s_PostalCode;
                mortgegeeData['firstmortgageeCorePersonPK'] = tableData.MortgageeData1.n_POMortgageePerson_Fk;
                mortgegeeData['firstmortgageeCoreAddrPK'] = tableData.MortgageeData1.n_POAddress_Fk;
                mortgegeeData['firstmortgageeName'] = tableData.MortgageeData1.s_FullLegalName;
                mortgegeeData['firstmortgageeAddress'] = address;
                mortgegeeData['firstmortgageeLoanNo'] = tableData.MortgageeData1.s_LoanNumber;
            }
            if(typeof tableData.MortgageeData2.n_POMortgageePerson_Fk !== 'undefined' ){
                let address = tableData.MortgageeData2.s_AddressLine1 + ',' + tableData.MortgageeData2.s_CityName + ',' + tableData.MortgageeData2.s_StateCode + ',' + tableData.MortgageeData2.s_PostalCode;
                mortgegeeData['secondmortgageeCorePersonPK'] = tableData.MortgageeData2.n_POMortgageePerson_Fk;
                mortgegeeData['secondmortgageeCoreAddrPK'] = tableData.MortgageeData2.n_POAddress_Fk;
                mortgegeeData['secondmortgageeName'] = tableData.MortgageeData2.s_FullLegalName;
                mortgegeeData['secondmortgageeAddress'] = address;
                mortgegeeData['secondmortgageeLoanNo'] = tableData.MortgageeData2.s_LoanNumber;
            }
            setData(mortgegeeData);
        }
    }, [tableData]);

    const searchMortgageeList = async () => {
        setLoading(true);
        await dispatch(getMortgageeListDataForSet(filter)).then((res) => { 
        setTableDatas(res.data.data);
        setTableTotal(res.data.total);
        setLoading(false);
        });
    };
  useEffect(() => {
		if(typeof params.policyNumber != 'undefined') {
            getAndCheckMortgagee(params.policyNumber);
		}else {
		}  
	}, []);

   const getAndCheckMortgagee = async (policyNumber) => {
        setLoading(true);
        await dispatch(getAndCheckMortgageeInformation(policyNumber)).then((res) => { 
            if(res.data.status === "Y"){
                setTableData(res.data);
              }else{
                toast.error(res.data.msgString);
              }
             setLoading(false);
        });
    };
    const modelOpen = (type) => {
        setCurrentMortgagee(type);
        setIsModelOpen(true);
    }  

    const onHandleLoanChange = (e) => {
        const tempArrayMortgagee = {...data};
        var name = e.target.name;
        var value = e.target.value;
        tempArrayMortgagee[name] = value;
        setData(tempArrayMortgagee);
    }
    const onHandleChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;
        const tempArray = filter; // creating copy of an array
        tempArray[name] = e.target.value
        setFilter(tempArray); // set copied array
      }
    
      const handlePageChange = (page, sizePerPage) => {
        setFilter({...filter,page:page,pageLength:sizePerPage});
      };
    
      const sizePerPageListChange = (sizePerPage) => {
        setFilter({...filter,pageLength:sizePerPage});
      };
    
      const renderShowsTotal = (start, to, total) => {
        return showDataTableTotalText(start, to, total);
      };
    
    const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    pageStartIndex: 1,
    paginationShowsTotal: renderShowsTotal,
    onPageChange: handlePageChange,
    totalSize:tableTotal
    //sizePerPageDropDown: renderSizePerPageDropDown
    };

    const setMortgageeData = (row) => {
        const mortgegeeData = data;
        mortgegeeData[currentMortgagee+'Name'] = row.s_FullLegalName;
        mortgegeeData[currentMortgagee+'Address'] = row.s_AddressLine1+' '+row.s_CityName+' '+row.s_StateCode+' '+row.s_PostalCode;
        mortgegeeData[currentMortgagee+'CorePersonPK'] = row.n_PersonInfoId_PK;
        mortgegeeData[currentMortgagee+'CoreAddrPK'] = row.n_PersonAddressesId_PK;
        setData(mortgegeeData);
        setIsModelOpen(false);
        setCurrentMortgagee('');
    }

    const columnFormatter = (cell, row, rowIndex, formatExtraData) => {
        return <a style={{ color:"#0d6efd", cursor: "pointer", fontSize : "16px" }} onClick={() => setMortgageeData(row)}>{row.s_PersonUniqueId}</a>
      };

    const columnFormatterAddress = (cell, row, rowIndex, formatExtraData) => {
        return row.s_AddressLine1+' '+row.s_CityName+' '+row.s_StateCode+' '+row.s_PostalCode;
      };

    const columns = [
        {
          text: "Client ID",
          formatter: columnFormatter
        },
        {
          dataField: "s_EntityType",
          text: "Type",
        },
        {
          dataField: "s_FullLegalName",
          text: "Legal Full Name",
        },
        {
          text: "Address",
          formatter: columnFormatterAddress
        }
    
      ];

const saveAndConfirmMortgagee = () => {
    let error = "N";
    let msg = "";
    if ((data.thirdmortgageeCorePersonPK != "" && data.thirdmortgageeCoreAddrPK != "") && data.thirdmortgageeLoanNo == '') {
        error = "Y";
        msg = "Please Enter Third Mortgagee Loan No.";
    }
    if ((data.secondmortgageeCorePersonPK != "" && data.secondmortgageeCoreAddrPK != "") && data.secondmortgageeLoanNo == '') {
        error = "Y";
        msg = "Please Enter Second Mortgagee Loan No.";
    }
    if ((data.firstmortgageeCorePersonPK != "" && data.firstmortgageeCoreAddrPK != "") && data.firstmortgageeLoanNo == '') {
        error = "Y";
        msg = "Please Enter First Mortgagee Loan No.";
    }
    if ((data.firstmortgageeCorePersonPK == "" && data.firstmortgageeCoreAddrPK == "") && data.secondmortgageeCoreAddrPK != '' ) {
        error = "Y";
        msg = "Please Add First Mortgagee Details Before Third Mortgagee Details";
    }
    if ((data.secondmortgageeCoreAddrPK == "" && data.secondmortgageeCorePersonPK == "") && data.thirdmortgageeCorePersonPK != '') {
        error = "Y";
        msg = "Please Add Second Mortgagee Details Before Third Mortgagee Details";
    }
    if ((data.firstmortgageeCorePersonPK == "" && data.firstmortgageeCoreAddrPK == "") && data.thirdmortgageeCorePersonPK != '') {
        error = "Y";
        msg = "Please Add First Mortgagee Details Before First Mortgagee Details";
    }

    if (error == "Y") {
      toast.error(t(msg));
    }
    else {
          Swal.fire({
            title: 'Confirmation',
            text: "Please confirm your changes!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then((result) => {
            if (result.isConfirmed) {
              setLoading(true);
              dispatch(getMortgageeSaveData(data)).then((res) => {
              setLoading(false);
              console.log(res.data.Status);
              if(res.data.Status === "Y"){
                toast.success(t(res.data.MsgString));
              }
              else {
                toast.error(t(res.data.MsgString));
               }
              })
            }
        })
       }
}
  return (
    <LoadingOverlay>
      <Loader loading={loading} />
        <div className="quote-page-section">
        {!isEmpty(tableData) && tableData.length != 0 ?
          <Row>
            <Col xs="12" className="custom-tabs" style={{ marginBottom:'10px'}}>
              <div className="custom-tab-header-fill">
                <span>Policy Details</span>
              </div>
              <div className="custom-tab-body">
                <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Policy #/Holder Name</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                 {tableData.policyData.Policy_No}/{tableData.policyData.n_TermSequence}/{tableData.policyData.s_FullLegalName}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Payment Plan</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                             {tableData.billPay.s_PayPlanScreenName}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Term Start Date</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            {Moment(tableData.policyData.d_TermStartDate).format("MM-DD-YYYY")}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Term End Date</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            {Moment(tableData.policyData.d_TermEndDate).format("MM-DD-YYYY")}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Transaction Eff. Dt.</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {Moment(tableData.policyData.d_TransEffectiveFrom).format("MM-DD-YYYY")}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Transaction Exp. Dt.</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {Moment(tableData.policyData.d_TransEffectiveTo).format("MM-DD-YYYY")}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Transaction Type</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {tableData.policyData.s_TranTypeScreenName} - {tableData.policyData.s_TranSubtypeScreenName}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Bill To</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            {tableData.billPay.s_BillToType}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Premium</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            ${tableData.policyData.n_NetPremium}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Premium Change</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              ${tableData.policyData.n_PremiumChange}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Total Premium</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                               ${ tableData.policyData.n_NetPremium + tableData.policyData.n_Policyfees} 
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Product Name</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {tableData.policyData.s_ProductName}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Total Claim</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {tableData.totalClaim}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Inception Date</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              {Moment(tableData.policyData.d_InceptionDate).format("MM-DD-YYYY")}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Serv Rep</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            {/* {tableData.policyData.d_InceptionDate} */}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>U/writer</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                            {/* {tableData.policyData.d_InceptionDate} */}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    
		        </Row>
              </div>
            </Col>
            <Col xs="12" className="custom-tabs" style={{ marginBottom:'10px'}}>
              <div className="custom-tab-header-fill">
                <span>First Mortgagee</span>
              </div>
              <div className="custom-tab-body">
                <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Name:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder=""
                            name="firstmortgageeName"
                            value={data.firstmortgageeName}
                            disabled
                        />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                <Button id="common-btn" variant="success" style={{padding:'10px'}} onClick={() => modelOpen('firstmortgagee')}
                                ><i className="fa fa-Search"></i>&nbsp;Search/Update
                                </Button>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Address:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="firstmortgageeAddress"
                                value={data.firstmortgageeAddress}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Loan No:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="firstmortgageeLoanNo"
                                value={data.firstmortgageeLoanNo}
                                onChange={(e) => onHandleLoanChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
		        </Row>
              </div>
            </Col>
            <Col xs="12" className="custom-tabs" style={{ marginBottom:'10px'}}>
              <div className="custom-tab-header-fill">
                <span>Second Mortgagee</span>
              </div>
              <div className="custom-tab-body">
                <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Name:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder=""
                            name="secondmortgageeName"
                            value={data.secondmortgageeName}
                            disabled
                        />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                <Button id="common-btn" variant="success" style={{padding:'10px'}} onClick={() => modelOpen('secondmortgagee')}
                                ><i className="fa fa-Search"></i>&nbsp;Search/Update
                                </Button>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Address:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="secondmortgageeAddress"
                                value={data.secondmortgageeAddress}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Loan No:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="secondmortgageeLoanNo"
                                value={data.secondmortgageeLoanNo}
                                onChange={(e) => onHandleLoanChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
		        </Row>
              </div>
            </Col>
            <Col xs="12" className="custom-tabs" style={{ marginBottom:'10px'}}>
              <div className="custom-tab-header-fill">
                <span>Third Mortgagee</span>
              </div>
              <div className="custom-tab-body">
                <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Name:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                        <Form.Control
                            className="dashboard-group-input"
                            type="text"
                            placeholder=""
                            name="thirdmortgageeName"
                            value={data.thirdmortgageeName}
                            disabled
                        />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                                <Button id="common-btn" variant="success" style={{padding:'10px'}} onClick={() => modelOpen('thirdmortgagee')}
                                ><i className="fa fa-Search"></i>&nbsp;Search/Update
                                </Button>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Mortgagee Address:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="thirdmortgageeAddress"
                                value={data.thirdmortgageeAddress}
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="2" xl="2">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              <b>Loan No:</b>
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Control
                                className="dashboard-group-input"
                                type="text"
                                placeholder=""
                                name="thirdmortgageeLoanNo"
                                value={data.thirdmortgageeLoanNo}
                                onChange={(e) => onHandleLoanChange(e)}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                            <Form.Label className="dashboard-group-label">
                              
                            </Form.Label>
                        </Form.Group>
                    </Col>
		        </Row>
              </div>
            </Col>
            <Col xs="12" className="custom-tabs" style={{ marginBottom:'10px'}}>
              <div className="custom-tab-body">
                <Row className="align-items-baseline">
                   <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                        >
                        { ( data.firstmortgageeCorePersonPK || data.secondmortgageeCorePersonPK || data.thirdmortgageeCorePersonPK)  ?
                        <Button id="common-btn" variant="success" style={{padding:'10px', float:'right'}} onClick={() => saveAndConfirmMortgagee()}
                                ><i className="fa fa-Search"></i>&nbsp;Confirm Changes
                        </Button>
                        : null }
                        <Button id="common-btn"  variant="danger" style={{padding:'10px', float:'right',marginRight:'10px', background: '#d4182a'}} onClick={() => saveAndConfirmMortgagee()}
                                ><i className="fa fa-Search"></i>&nbsp;Cancel Changes
                        </Button>
                        </Form.Group>
                    </Col>
		        </Row>
              </div>
            </Col>
          </Row>
          : null 
        }
            <Modal
                className="custom-dashboard-modal"
                size="lg"
                show={isModelOpen}
                onHide={() => {setIsModelOpen(false)}}
            >
                <Modal.Header closeButton onClick={() => { setIsModelOpen(false) }} className="custom-dashboard-modal-header">
                  <Modal.Title>Search Mortgagees</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-dashboard-modal-body">
                    <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <LoadingOverlay>
                                <Loader loading={loading} />
                                <Row className="align-items-baseline">
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Form.Group
                                            className="dashboard-group-forms"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                            Last/Org:
                                            </Form.Label>
                                            <Form.Control
                                            name="MortgageeLastName"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => onHandleChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Form.Group
                                            className="dashboard-group-forms"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                            Client ID
                                            </Form.Label>
                                            <Form.Control
                                            name="MortgageeClientId"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => onHandleChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Form.Group
                                            className="dashboard-group-forms"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                            City
                                            </Form.Label>
                                            <Form.Control
                                            name="MortgageeCity"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => onHandleChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="align-items-baseline" style={{ paddingBottom: "0rem", paddingTop: "1rem" }}>
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Form.Group
                                            className="dashboard-group-forms"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                            First Name
                                            </Form.Label>
                                            <Form.Control
                                            name="MortgageeFirstName"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => onHandleChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Form.Group
                                            className="dashboard-group-forms"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                            State
                                            </Form.Label>
                                            <Form.Control
                                            name="MortgageeState"
                                            className="dashboard-group-input"
                                            type="text"
                                            placeholder=""
                                            onChange={(e) => onHandleChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ paddingBottom: "0rem", paddingTop: "0rem" }}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <Form.Group
                                        className="search-quote-form-group"
                                        controlId="formBasicEmail"
                                        style={{padding:'10px', float:'right'}}
                                        >
                                            <Form.Label className="dashboard-group-label">
                                                <Button id="common-btn" variant="success" style={{padding:'10px', float:'right'}} onClick={() => searchMortgageeList()}
                                                ><i className="fa fa-Search"></i>&nbsp;Search
                                                </Button>
                                            </Form.Label>
                                        </Form.Group>
                                </Col>
                                </Row>
                                <Row style={{ paddingBottom: "0rem"}}>
                                    <BootstrapTable
                                    wrapperClasses="dashboard-table-main"
                                    bootstrap4
                                    remote
                                    keyField="n_PersonInfoId_PK"
                                    data={tableDatas}
                                    columns={columns}
                                    hover
                                    pagination={paginationFactory(options)}
                                    />
                                </Row>
                            </LoadingOverlay>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    </LoadingOverlay>
  );
};

export default MortgageeInformation;