import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RenewPolicyModal from "./components/RenewComponents/RenewModal";
import { FaSyncAlt } from "react-icons/fa";

export default function RenewPolicy({
  quoteDetailData,
  quoteId,
  productType,
  commercialProductType,
  productCode,
  productId,
}) {
  const [showRenewModal, setShowRenewModal] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShowRenewModal(false);
  return (
    <div>
      <Button
        id="common-btn"
        variant="primary"
        size="sm"
        className="px-2 py-1"
        onClick={() => setShowRenewModal(true)}
      >
        <FaSyncAlt size={18} /> &nbsp; {t("Renew Policy")}
      </Button>
      <RenewPolicyModal
        show={showRenewModal}
        onHide={handleClose}
        quoteDetailData={quoteDetailData}
        quoteId={quoteId}
        productType={productType}
        commercialProductType={commercialProductType}
        handleClose={handleClose}
        productCode={productCode}
        productId={productId}
      />
    </div>
  );
}
