import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { InputAdapter, TextMask } from "react-text-mask-hoc";
import { dateFormat } from "../../../services/commonService";
import {PhoneNumberMask, tenant} from "../../common/Helper";

export default function AgencyUserForm({
  userData,
  metadata,
  stateData,
  userLevels,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        {/* <Text mb={2} fontWeight={500} fontSize="xl">
          {t("Agency User Form")}
        </Text> */}
        <Box
          p={6}
          borderRadius={"2xl"}
          boxShadow={"0 0 4px rgba(0, 0, 0, 0.5)"}
        >
          <Grid
            alignItems={"end"}
            templateColumns={"repeat(2, 1fr)"}
            rowGap={4}
            columnGap={6}
          >
            <FormControl>
              <FormLabel>{t("First Name")}:</FormLabel>
              <Input
                name="First_Name"
                id="First_Name"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.First_Name}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Last Name")}:</FormLabel>
              <Input
                name="Last_Name"
                id="Last_Name"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.Last_Name}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("E-Mail")}:</FormLabel>
              <Input
                name="Email"
                id="Email"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.Email}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Phone")}:</FormLabel>
              <TextMask
                mask={PhoneNumberMask}
                Component={InputAdapter}
                className="form-control producer-group-input dashboard-group-input"
                type="text"
                id="s_PhoneNumber"
                name="s_PhoneNumber"
                value={userData?.s_PhoneNumber}
                placeholder=""
                disabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Agent Flood Code")}:</FormLabel>
              <Input
                name="s_UserCode"
                id="s_UserCode"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.s_UserCode}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO Agent Code")}:</FormLabel>
              <Input
                name="s_CompanyCode"
                id="s_CompanyCode"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={metadata?.s_CompanyCode}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("WYO UPN")}:</FormLabel>
              <Input
                name="Sub_Agent_ID"
                id="Sub_Agent_ID"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.Sub_Agent_ID}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Previous WYO Agent Code")}:</FormLabel>
              <Input
                name="pre_agency_code"
                id="pre_agency_code"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={metadata?.pre_agency_code}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("User ID")}:</FormLabel>
              <Input
                name="Username"
                id="Username"
                type="text"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.Username}
                isDisabled={true}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("Status")}:</FormLabel>
              <Select
                name="s_UserStatus"
                id="s_UserStatus"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.s_UserStatus}
                isDisabled={true}
                icon={""}
              >
                <option value=""></option>
                <option value="111">{t("Active")}</option>
                <option value="112">{t("Closed")}</option>
              </Select>
            </FormControl>
            {tenant === "farmers" ? (
              <>
                <FormControl>
                  <FormLabel>{t("New Business Suspense Date")}:</FormLabel>
                  <Input
                    name="newbusiness_suspend_date"
                    id="newbusiness_suspend_date"
                    type="text"
                    placeholder=""
                    className="form-control producer-group-input dashboard-group-input input-producer"
                    value={
                      metadata?.newbusiness_suspend_date != null ||
                      metadata?.newbusiness_suspend_date != ""
                        ? dateFormat(
                            metadata.newbusiness_suspend_date,
                            "MM/DD/YYYY"
                          )
                        : ""
                    }
                    isDisabled={true}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Sales Channel")}:</FormLabel>
                  <Input
                    name="sales_channel"
                    id="sales_channel"
                    type="text"
                    placeholder=""
                    className="form-control producer-group-input dashboard-group-input input-producer"
                    value={metadata?.sales_channel}
                    isDisabled={true}
                  />
                </FormControl>
              </>
            ) : null}
            <FormControl>
              <FormLabel>{t("User Level")}:</FormLabel>
              <Select
                name="Level"
                id="Level"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={userData?.Level}
                isDisabled={true}
                icon={""}
              >
                <option value=""></option>
                {userLevels.map((level, index) => {
                  return (
                    <option key={index} value={level.UserLevel_ID}>
                      {level.UserLevel_Name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{t("Resident State")}:</FormLabel>
              <Select
                name="resident_state_id"
                id="resident_state_id"
                placeholder=""
                className="form-control producer-group-input dashboard-group-input input-producer"
                value={metadata?.resident_state_id}
                isDisabled={true}
                icon={""}
              >
                <option value=""></option>
                {stateData.map((state, index) => {
                  return (
                    <option key={index} value={state.n_stateId_PK}>
                      {state.s_StateName}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
