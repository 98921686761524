import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Select,
  Switch,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useStarDataContext } from "../../StarDataContext";
import { RiDeleteBin2Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { useAutoZipSelection } from "../../utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";

const Interest = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const [zipData, setZipData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { confirmation, setConfirmation, validationError, setValidationError } =
    useStarDataContext();

  const addInterestField = () => ({
    type: "Property_Manager",
    firstName: "",
    lastName: "",
    address: "",
    zipCode: null,
    stateCode: null,
    city: "",
  });

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    confirmation?.additionalInterests[0]?.zipCode,
    confirmation?.additionalInterestsFlag
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData && zipData.s_CityName) {
      setConfirmation(() => ({
        ...confirmation,
        additionalInterests: [
          {
            ...confirmation.additionalInterests[0],
            stateCode: zipData?.s_StateCode,
            city: zipData?.s_CityName,
            cityId: zipData.city_id ?? 0,
            stateId: zipData.state_id ?? 0,
            countryId: zipData.n_CountryId_FK ?? 1,
            countyId: zipData.county_id ?? 0,
            county: zipData.s_CountyName ?? "",
          },
          ...confirmation.additionalInterests.slice(1),
        ],
      }));
    }
  }, [zipData]);

  const handleAddRow = () => {
    if (confirmation?.additionalInterests.length >= 2) {
      toast.warn("Currently Limite to 2 Additional Interests.");
      setValidationError((prev) => ({ ...prev, additionalInterests: true }));
    } else {
      setConfirmation((prev) => ({
        ...prev,
        additionalInterests: [
          ...confirmation.additionalInterests,
          addInterestField(),
        ],
      }));
      setValidationError((prev) => ({ ...prev, additionalInterests: false }));
    }
  };

  const handleRemoveRow = (index) => {
    if (confirmation?.additionalInterests.length > 1) {
      setConfirmation((prev) => ({
        ...prev,
        additionalInterests: prev.additionalInterests.filter(
          (_, i) => i !== index
        ),
      }));
    } else {
      setConfirmation((prev) => ({
        ...prev,
        additionalInterestsFlag: false,
        additionalInterests: [],
      }));
    }
  };

  const handleSwitchChange = () => {
    setConfirmation((prev) => ({
      ...prev,
      additionalInterestsFlag: !prev.additionalInterestsFlag,
      additionalInterests: prev.additionalInterestsFlag
        ? []
        : [addInterestField()],
    }));
    setValidationError((prev) => ({ ...prev, additionalInterests: false }));
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setConfirmation((prev) => ({
      ...prev,
      additionalInterests: prev.additionalInterests.map((row, i) =>
        i === index ? { ...row, [name]: value } : row
      ),
    }));
  };

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Flex
        background="var(--chakra-colors-primary-50)"
        borderRadius="xl"
        p="0.1rem"
        mb="20px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="xl"
          mt={3}
          pl="1.5rem"
          fontWeight="var(--chakra-fontWeights-medium)"
        >
          {t("additional_interests")}
        </Text>
        <Box mr={2}>
          <Switch
            // isDisabled
            isChecked={confirmation?.additionalInterestsFlag}
            onChange={handleSwitchChange}
            size="lg"
          />
        </Box>
      </Flex>
      {confirmation?.additionalInterestsFlag && (
        <Box>
          {confirmation?.additionalInterests.map((row, index) => (
            <Box key={index} pl={5} pr={5}>
              <Card
                border="1px solid"
                borderColor="neutral.100"
                boxShadow={0}
                borderRadius="xl"
                mb={4}
              >
                <CardHeader
                  borderBottom="1px solid var(--chakra-colors-neutral-100)"
                  p={0}
                  borderTopRadius="inherit"
                >
                  <Flex
                    gap={2}
                    alignItems={"center"}
                    p={4}
                    borderTopRadius="inherit"
                    bg="var(--chakra-colors-primary-50)"
                    justifyContent="space-between"
                  >
                    <Flex ml={2} alignItems={"center"} gap={3}>
                      <Text mb={0} fontSize="lg" fontWeight={400}>
                        {t("additional_interests")}{" "}
                        {isLoading && (
                          <Spinner size={"md"} color="primary.500" />
                        )}
                      </Text>
                    </Flex>
                    <Box mr={2}>
                      <RiDeleteBin2Line
                        onClick={() => handleRemoveRow(index)}
                        size="25px"
                      />
                    </Box>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Grid w="100%" templateColumns="repeat(3, 1fr)" gap={6} p={2}>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("Type")}:{" "}
                        </FormLabel>
                        <Select
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          size={"lg"}
                          name="type"
                          value={row?.type}
                          onChange={(e) => handleChange(e, index)}
                        >
                          <option value="Property_Manager">
                            Property Manager
                          </option>
                          <option value="Others">Others</option>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("First Name")}:{" "}
                        </FormLabel>
                        <Input
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          name="firstName"
                          type="text"
                          size="lg"
                          focusBorderColor="secondary.500"
                          value={row?.firstName}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {validationError?.additionalInterests &&
                          confirmation?.additionalInterestsFlag &&
                          !row?.firstName && (
                            <Text color={"red"}>{t("First Name is required")}.</Text>
                          )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("Last Name")}:{" "}
                        </FormLabel>
                        <Input
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          name="lastName"
                          type="text"
                          size={"lg"}
                          focusBorderColor="secondary.500"
                          value={row?.lastName}
                          onChange={(e) => handleChange(e, index)}
                        />
                        {validationError?.additionalInterests &&
                          confirmation?.additionalInterestsFlag &&
                          !row?.lastName && (
                            <Text color={"red"}>{t("Last Name is required")}.</Text>
                          )}
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Grid p={2} templateColumns="repeat(4, 1fr)" gap={6}>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          Address
                        </FormLabel>
                        <Input
                          name="address"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.address}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          City
                        </FormLabel>
                        <Input
                          type="text"
                          name="city"
                          isDisabled
                          _disabled={{ opacity: 1 }}
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.city || ""}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel fontWeight={"400"}>
                          State / State Code
                        </FormLabel>
                        <Input
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          isDisabled
                          _disabled={{ opacity: 1 }}
                          name="stateCode"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.stateCode}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          Zip Code
                        </FormLabel>
                        <Input
                          name="zipCode"
                          type="number"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.zipCode}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => {
                            const { name, value } = e.target;
                            if (
                              name !== "zipCode" ||
                              (name === "zipCode" && value.length <= 5)
                            ) {
                              handleChange(e, index);
                            }
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Interest;
