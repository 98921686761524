export default {
    black: "#000000",
    white: "#FFFFFF",
    primary: {
      50: "#EDF2FF",
      100: "#D3DEFF",
      200: "#B6C7F6",
      300: "#A4B8F3",
      400: "#4870E5",
      500: "var(--createQuoteHeaderButton)",
      600: "#4870E5",
      700: "#4D8DCF",
      800: "#7C9DFF",
      900: "#A7BEFF",
    },
    secondary: {
      50: "#F2EFFD",
      100: "#DFD6FB",
      200: "#C7BBF0",
      300: "#B9A9EC",
      400: "#BFADF7",
      500: "#7E5BEF",
      600: "#7152D7",
      700: "#8B6BF1",
      800: "#9E84F3",
      900: "#BFADF7",
    },
    neutral: {
      50: "#E9EBEF",
      100: "#DDE0E5",
      200: "#D2D6DC",
      300: "#C6CBD2",
      400: "#8B95A3",
      500: "#9AA5B5",
      600: "#97A0AC",
      700: "#A3ABB6",
      800: "#AEB5BF",
      900: "#BAC0C9",
    },
    success: {
      50: "#E7FAF0",
      100: "#CFF3E0",
      200: "#B7ECCF",
      300: "#A0E4BF",
      400: "#88DDAE",
      500: "#11B95C",
      600: "#29C06C",
      700: "#41C77D",
      800: "#58CF8D",
      900: "#70D69E",
    },
    danger: "#E53E3E",
    statusPink: "#FFE5E7",
    warning: "#FFCB3D",
    info: "#72C8CC",
    agGridHeaderBg: "#D6EBFF",
    linkColor: "#005CBA",
    agGridHeaderFontColor: "#474747"
  };