import { useState, useEffect } from "react";
import { getAutoZipData } from "../apis/getAutoZipData";
import useDebounce from "../../create-quote/utility/useDebounce";
import { toast } from "react-toastify";

export const useAutoZipSelection = (initialZipCode, makeACall) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedZip = useDebounce(initialZipCode, 500);
  const [currentZip] = useState(initialZipCode);

  useEffect(() => {
    const fetchData = async () => {
      if (
        !debouncedZip ||
        debouncedZip.length < 5 ||
        !makeACall ||
        currentZip === debouncedZip
      )
        return;
      setIsLoading(true);
      try {
        const result = await getAutoZipData(debouncedZip);
        if (
          result &&
          Array.isArray(result?.data?.zipData) &&
          result?.data?.zipData.length === 1
        ) {
          setShowModal(false);
          setSelectedLocation(result?.data?.zipData[0]);
        } else if (result?.data?.zipData.length > 1) {
          setSelectedLocation(result?.data?.zipData || []);
          setShowModal(true);
        } else if (result?.data?.errorMsg) {
          toast.warn(result?.data?.errorMsg);
        }
      } catch (error) {
        console.error("Error fetching auto zip data:", error);
      } finally {
        setIsLoading(false);
        setShowModal(false);
      }
    };

    fetchData();
  }, [debouncedZip]);

  return { selectedLocation, showModal, isLoading };
};

//usage example
// import useAutoZipSelection from "./hooks/useAutoZipSelection"; // Adjust the path as necessary
//   const initialZipCode = "90210"; // Example initial zip code
//   const { selectedLocation, showModal, isLoading } = useAutoZipSelection(initialZipCode);
//   useEffect(() => {
//     // Handle showing/hiding the modal based on showModal state
//     // Also handle the selection of a preferred location
//   }, [showModal, selectedLocation]);
