import React from "react";
import {
  Box,
  Text,
  FormLabel,
  Grid,
  Card,
  Divider,
  FormControl,
  Input,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const IvansAccountInfo = ({ disabled, onChange, data }) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    onChange(event);
  };
  return (
    <Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
      <Box p={6} mb={4}>
        <Text fontSize={"xl"} fontWeight={600}>
          {t("Ivans Information")}
        </Text>
        <Divider orientation="horizontal" />
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <FormInputs
            data={data}
            handleChange={handleChange}
            disabled={disabled}
          />
        </Grid>
      </Box>
    </Card>
  );
};

const formConfig = [
  {
    label: "Ivans Account",
    name: "ivans_account",
    id: "ivans_account",
  },
  {
    label: "Ivans User ID",
    name: "ivans_user_id",
    id: "ivans_user_id",
  },
];
const FormInput = ({ label, name, id, value, onChange, disabled }) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>
    <Input
      className="dashboard-group-input"
      type="text"
      name={name}
      id={id}
      borderColor="neutral.400"
      bgColor={disabled ? "gray.200" : ""}
      _disabled={{ opacity: 1 }}
      onChange={onChange}
      value={value}
      isDisabled={disabled}
    />
  </FormControl>
);
const FormInputs = ({ data, handleChange, disabled }) => {
  const { t } = useTranslation();
  return (
    <>
      {formConfig.map((config, index) => (
        <FormInput
          key={index}
          label={t(config.label)}
          name={config.name}
          id={config.id}
          value={data && data[config.name]}
          onChange={handleChange}
          disabled={disabled}
        />
      ))}
    </>
  );
};

export default IvansAccountInfo;
