import React, {useState} from 'react'
import {Button, Col, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap";
import PdfIcon from '../../../../../../assets/pdf-icon.png'
import ExcessProductModal from "./ExcessProductModal";
import {selectExcessProducts} from "../../../../../../store/selectors/private-flood";
import {useDispatch, useSelector} from "react-redux";
import {
  updatePrivateProductReport,
} from "../../../../../../services/floodService";
const PrivateProductItem = ({data, isLastItem,getExcessFloodQuote}) => {
  const [open, setOpen] = useState(false);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const dispatch = useDispatch();
  const excessProductsResponse = useSelector(state => selectExcessProducts(state))
  const {
    emptyQuoteData, initApiObject, apiLoading, floodSystemDD, basicCvg, recommendedCvg, basicCvgResponse,
    recommendedCvgResponse, customCvg, customCvgResp, deductible, ZDDetails, ZDDetailsFromAddress
  } = useSelector(state => state.flood);
  const goToDetails = () => {
    window.open(`/flood/instantquote/result/${data.productId}`, '_blank');
  }
  const goToNeptuneQuoteLink = () => {
    updateSelectedQuoteInReport(data);
    window.open(data.quoteLink, '_blank');
  }
  const goToAonEdgeQuoteLink = () => {
    updateSelectedQuoteInReport(data);
    window.open(data.quoteLink, '_blank');
  }
  const goToCatQuoteLink = () => {
    updateSelectedQuoteInReport(data);
    window.open(data.quoteLink, '_blank');
  }

  const updateSelectedQuoteInReport = (data) => {
    const reportId = data.reportId ?? 0;
    const premium = data.premium?.best?.totalPremium;
    reportId !== 0 && dispatch(updatePrivateProductReport(reportId,premium))
  }
  const tooltip = data.error ? (
    <Tooltip id="tooltip">
      {data.error}
    </Tooltip>
  ) : null;

  const tooltipExcess = excessProductsResponse != undefined && excessProductsResponse.excessAonEdgeproduct != undefined && excessProductsResponse.excessAonEdgeproduct.error ? (
    <Tooltip id="tooltip">
      {excessProductsResponse.excessAonEdgeproduct.error}
    </Tooltip>
  ) : null;

  const [openModalForExcessProducts, setOpenModalForExcessProducts] = useState(false);
  const closeModal = () => {
    setOpenModalForExcessProducts(false);
  };

  const handleGetPremiumClick = (coverage) => {
    getExcessFloodQuote(coverage);
  };
  return (
    <Row className="pt-3">
      <Col md={3}>
        <img src={data.logo} height="60px"/>
      </Col>
      <Col md={5}>
        <ul>
          {
            data.benefits.map((benefitText) => (<li>{benefitText}</li>))
          }
        </ul>
      </Col>
      <Col md={4}>
        <div className="d-flex justify-content-between">
          <div className="ps-5 pe-5" style={{minWidth: '200px', textAlign: 'center'}}>
            {
              data.loading ?
                (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) :
                (
                  data.error ?
                    (
                    <p className="mt-3 mb-0 fw-bold">
                      Risk not Eligible
                    </p>
                    )
                    :
                    <>
                    {data.premium?.best?.totalPremium !== 0 ? (
                      <>
                      <p className="mt-3 mb-0 fw-bold">
                        {data.premium?.best?.totalPremium ? formatter.format(data.premium.best.totalPremium) : '-'}
                      </p>
                      {
                        data.premium?.best?.totalPremium && (
                          <p className="fw-bold">
                            Premium
                          </p>
                        )
                      }
                      </>
                    ) : (
                      <p className="fw-bold">Risk not Eligible</p>
                    )}
                    </>
                )
            }
          </div>
          <div className="pt-3">
            {
              data.productId === 'neptune' &&
                (
                  <Button
                    variant="outline-none"
                    style={{backgroundColor: (data.loading || data.error || !data.premium?.best?.totalPremium) ? '#f1f1f1': '#64d6cd'}}
                    className={(data.loading || data.error || !data.premium?.best?.totalPremium) ? 'text-black': 'text-white'}
                    size="sm"
                    disabled={data.loading || data.error || !data.premium?.best?.totalPremium}
                    onClick={() => goToNeptuneQuoteLink()}
                  >
                    Select
                  </Button>
                )
            }
            {
              data.productId === 'ezflood' &&
              (
                <Button
                  variant="outline-none"
                  style={{backgroundColor: (data.loading || data.error || !data.premium?.best?.totalPremium) ? '#f1f1f1': '#64d6cd'}}
                  className={(data.loading || data.error || !data.premium?.best?.totalPremium) ? 'text-black': 'text-white'}
                  size="sm"
                  disabled={data.loading || data.error || !data.premium?.best?.totalPremium}
                  onClick={() => goToAonEdgeQuoteLink()}
                >
                  Select
                </Button>
              )
            }
            {
              data.productId === 'cat' &&
              (
                <Button
                  variant="outline-none"
                  style={{backgroundColor: (data.loading || data.error || !data.premium?.best?.totalPremium) ? '#f1f1f1': '#64d6cd'}}
                  className={(data.loading || data.error || !data.premium?.best?.totalPremium) ? 'text-black': 'text-white'}
                  size="sm"
                  disabled={data.loading || data.error || !data.premium?.best?.totalPremium}
                  onClick={() => goToCatQuoteLink()}
                >
                  Select
                </Button>
              )
            }
            {
              data.productId === 'macneill' && (
                <Button
                  variant="outline-none"
                  style={{backgroundColor: (data.loading || data.error || !data.premium?.best?.totalPremium) ? '#f1f1f1': '#64d6cd'}}
                  className={(data.loading || data.error || !data.premium?.best?.totalPremium) ? 'text-black': 'text-white'}
                  size="sm"
                  disabled={data.loading || data.error || !data.premium?.best?.totalPremium}
                  onClick={() => goToDetails()}
                >
                  Select
                </Button>
              )
            }
          </div>
          <div className="pt-2">
            <Button variant="outline-light" onClick={() => window.open(data.pdf, '_blank')}>
              <img src={PdfIcon} width="25px"/>
            </Button>
            {
              data.id === 'nfip' && (
                <Button onClick={() => setOpen(!open)} size="sm" className={"ms-2"} variant="outline-info"
                        aria-expanded={open}>
                  <i className={`fa fas fa-arrow-${open ? 'down' : 'right'}`}/>
                </Button>
              )
            }
          </div>
        </div>
        {/* {
          basicCvg.totalCoverageBuilding == 250000 && 
          <div className="d-flex justify-content-between">
          {data.productId == 'ezflood' && 
              (
              <div className="d-flex align-items-center">
                <br></br>
                <div className="pt-3" style={{ minWidth: '150px'}}>
                <Button onClick={() => setOpenModalForExcessProducts(!openModalForExcessProducts)} id="primary-colored-btn" size="sm">Excess Available</Button>
                </div>
                {
                  excessProductsResponse != undefined && excessProductsResponse.excessAonEdgeproduct != undefined  ?
                  <div className="ps-3 pe-3" style={{ minWidth: '200px', textAlign: 'center' }}>
                    {excessProductsResponse.excessAonEdgeproduct.loading ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      excessProductsResponse.excessAonEdgeproduct.error ?
                      (
                        <OverlayTrigger placement="top" overlay={tooltipExcess}>
                          <i className="fa fa-exclamation-triangle mt-4"/>
                        </OverlayTrigger>
                      )
                      :
                        <>
                          <p className="mt-3 mb-0 fw-bold">
                            {excessProductsResponse.excessAonEdgeproduct.premium?.best?.totalPremium ? formatter.format(excessProductsResponse.excessAonEdgeproduct.premium.best.totalPremium) : '-'}
                          </p>
                          {excessProductsResponse.excessAonEdgeproduct.premium?.best?.totalPremium && (
                            <p className="fw-bold">Premium</p>
                          )}
                        </>
                      
                    )}
                  </div> : null

                }
                <div className="pt-3">
                {
                  excessProductsResponse != undefined && excessProductsResponse.excessAonEdgeproduct != undefined  ?
                  <Button
                    variant="outline-none"
                    style={{backgroundColor: (excessProductsResponse.excessAonEdgeproduct.loading || excessProductsResponse.excessAonEdgeproduct.error || !excessProductsResponse.excessAonEdgeproduct.premium?.best?.totalPremium) ? '#f1f1f1': '#64d6cd'}}
                    className={(excessProductsResponse.excessAonEdgeproduct.loading || excessProductsResponse.excessAonEdgeproduct.error || !excessProductsResponse.excessAonEdgeproduct.premium?.best?.totalPremium) ? 'text-black': 'text-white'}
                    size="sm"
                    disabled={excessProductsResponse.excessAonEdgeproduct.loading || excessProductsResponse.excessAonEdgeproduct.error || !excessProductsResponse.excessAonEdgeproduct.premium?.best?.totalPremium}
                    onClick={() => window.open(excessProductsResponse.excessAonEdgeproduct.quoteLink, '_blank')}
                  >
                    Select
                  </Button> : null
                }
                
                </div>
                <div className="pt-2">
                <Button variant="outline-light" onClick={() => window.open(data.pdf, '_blank')}>
                </Button>
                </div>
              </div>
              )
              }
          </div>
        } */}
      </Col>
      {
        !isLastItem && (
          <Col md={12}>
            <div className="border border-light-dark border-1"/>
          </Col>
        )
      }
      {
        openModalForExcessProducts &&
        <ExcessProductModal
          data={data}
          isModalOpen={openModalForExcessProducts}
          closeModal={closeModal}
          onGetPremiumClick={handleGetPremiumClick}
        />
      }
    </Row>
  )
}
export default PrivateProductItem
