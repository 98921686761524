import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { standardizeKeyName } from "./StandarizeNames";
import { Currency } from "../../../common/Currency";

const FeeTable = ({ fees }) => {
  const formattedFees = useMemo(() => {
    if (!fees) return [];

    return Object.entries(fees).map(([key, value], index) => {
      if (key === "fees" && value) {
        return Object.entries(value).map(
          ([nestedKey, nestedValue], nestedIndex) => (
            <tr
              key={`${key}-${nestedIndex}`}
              className={key === "totalFee" ? "fw-bold" : ""}
            >
              <td>{nestedKey}</td>
              <td className="text-end text-nowrap align-middle">
                <Currency amount={nestedValue} negetiveAmountStyle="none" />
              </td>
            </tr>
          )
        );
      } else if (key !== "premium" && key !== "finalPremium") {
        return (
          <tr key={index} className={key === "totalFee" ? "fw-bold" : ""}>
            <td>{key !== "fees" && standardizeKeyName(key)}</td>
            <td className="text-end text-nowrap align-middle">
              {key !== "fees" ? <Currency amount={value} negetiveAmountStyle={'none'} /> : <span></span>}
            </td>
          </tr>
        );
      }
      return null;
    });
  }, [fees]);

  return (
    <Table size="sm" className="table">
      <tbody>{formattedFees}</tbody>
    </Table>
  );
};

export default FeeTable;
