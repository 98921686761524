import React from "react";
import {
  Box,
  Text,
  FormLabel,
  Card,
  Input,
  FormControl,
  Divider,
  Grid,
  GridItem
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MaskedFormControl from "react-bootstrap-maskedinput";

const BasicManagement = ({
  disabled, onChange, data
}) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    onChange(event);
  };

  return (<><Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
    <Box p={6} mb={4}>
      <Text fontWeight={600} fontSize={"xl"}>
        {t("Basic Management")}
      </Text>
      <Divider orientation='horizontal' />
      <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
        <GridItem colSpan={2}>
        <FormControl>
          <FormLabel>{t("Name")}</FormLabel>
          <Input
            className="dashboard-group-input"
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            value={data && data.name}
            isDisabled={disabled}
            bgColor={disabled ? "gray.200" : ""}
            _disabled={{ opacity: 1 }}
          />
        </FormControl>
        </GridItem>
        <GridItem>
        <FormControl>
          <FormLabel>{t("Email")}</FormLabel>
          <Input
            className="dashboard-group-input"
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            value={data && data.email}
            isDisabled={disabled}
            bgColor={disabled ? "gray.200" : ""}
            _disabled={{ opacity: 1 }}
          />
        </FormControl>
        </GridItem>
        <GridItem>
        <FormControl>
          <FormLabel>{t("Phone")}</FormLabel>
          <MaskedFormControl
            className="dashboard-group-input"
            type="text"
            id="phone"
            name="phone"
            onChange={handleChange}
            value={data && data.phone}
            bg={disabled ? "gray.200" : ""}
            disabled={disabled}
            mask="(111) 111-1111"
          />
        </FormControl>
        </GridItem>
      </Grid>
    </Box>
  </Card></>
  );
};

export default BasicManagement;
