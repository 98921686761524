import CurrencyInput from "react-currency-input-field";

export const CustomCurrencyInput = ({ ...restProps }) => {
  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid var(--chakra-colors-gray-300)",
    padding: "0.62rem",
    borderRadius: "12px",
    width: "100%",
    height: "53.5px",
  };

  return (
    <CurrencyInput
      intlConfig={{ locale: "en-US", currency: "USD" }}
      size={"lg"}
      min={0}
      style={CurrencyStyles}
      prefix="$"
      {...restProps}
    />
  );
};
