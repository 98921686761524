import "bootstrap-daterangepicker/daterangepicker.css";
import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { connect, useDispatch } from "react-redux";
import "./claim-details.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {getCoreClaimData,setCoreClaimData,getCoreClaimPaymentData,setCoreClaimPaymentData,setClaimDamageData} from "../../services/claimService";
import { toast } from "react-toastify";
import ClaimSummary from "./ClaimSummary";
import ClaimPayment from "./ClaimPayment";
import "./tracking.css";
import { isEmpty } from 'lodash';
import ClaimAddInfo from "./ClaimAddInfo"

const ClaimDetails = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('summary');
  const { claimNumber} = useParams();
  const [loading, setLoading] = useState(false);
  const [claim, setClaim] = useState({});

  useEffect(() => {
    if(Object.keys(props.claimDetailObject).length > 0 &&  Object.keys(props.claimDetailObject.claimData).length > 0){
      setClaim(props.claimDetailObject.claimData);
    }
  }, [props.claimDetailObject]);

  useEffect(() => {
    const getCallCoreClaimDataApi = async () => {
      setLoading(true);
      await dispatch(getCoreClaimData(claimNumber)).then((res) => {
        if (res.status == 'success') {
          dispatch(setCoreClaimData(res.data));
          manageDamageData(res.data);
        } else {
          toast.error(res.msg);
        }
        setLoading(false);
      });
    };

    if (claimNumber) {
      getCallCoreClaimDataApi();
    }	

  }, [claimNumber]);

  const manageDamageData = async (claimData) => {
    const additionalInfo = (claimData?.additionalInfo?.newScreenData !== undefined) ? claimData?.additionalInfo?.newScreenData : {}; 
    const claimDamages = (claimData?.additionalInfo?.claimLossCorrectionA?.claimedDamages !== undefined) ? claimData?.additionalInfo?.claimLossCorrectionA?.claimedDamages : {};
    const buildingValues = (!isEmpty(claimDamages)) ? claimDamages.find(v => v.damageType == "BUILDING") : {};
    const contentValues  = (!isEmpty(claimDamages)) ? claimDamages.find(v => v.damageType == "CONTENTS") : {};
    const coverageData = (claimData?.policyData?.coverageData !== undefined) ? claimData?.policyData?.coverageData : {};
    const isContentCoverage = formatAmountStringToFloatNumber(coverageData?.contentCvg.n_InsuredValue) > 0;
    const isBuildingCoverage = formatAmountStringToFloatNumber(coverageData?.buildingCvg.n_InsuredValue) > 0;
    const buildingTotal = ((formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.coverageDamageACV, buildingValues?.damageAmount) + formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.removalProtection)) - formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.lessSalvage) - formatAmountStringToFloatNumber((isBuildingCoverage && validateField(coverageData.buildingCvg?.s_PrDiscountCode)) != '' ? (coverageData.buildingCvg?.s_PrDiscountCode).slice(6): 0) + formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.recDepreciation) - formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.coInsurancePenalty) + formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.coverageDamageACV));

    const finalBuildingTotal = ( (0 > buildingTotal)) ? 0 : ( buildingTotal > formatAmountStringToFloatNumber(coverageData?.buildingCvg.n_InsuredValue)) ? formatAmountStringToFloatNumber(coverageData?.buildingCvg.n_InsuredValue) : buildingTotal; 


    const contentTotal = ((formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.coverageDamageACV, contentValues?.damageAmount) + formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.removalProtection)) - formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.lessSalvage) - formatAmountStringToFloatNumber((isContentCoverage && validateField(coverageData.contentCvg?.s_PrDiscountCode)) != '' ? (coverageData.contentCvg?.s_PrDiscountCode).slice(6): 0 ));
    const finalContentTotal = ( 0 > contentTotal) ? 0 : (contentTotal > formatAmountStringToFloatNumber(coverageData?.contentCvg.n_InsuredValue)) ? formatAmountStringToFloatNumber(coverageData?.contentCvg.n_InsuredValue) : contentTotal; 
    const damageData = {
      building   : {
        propertyValueRCV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.propertyValueRCV, buildingValues?.replacementCost),
        propertyValueACV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.propertyValueACV, buildingValues?.propertyValue),
        grossLossRCV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.grossLossRCV, buildingValues?.damageReplacementCost),
        coverageDamageACV  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.coverageDamageACV, buildingValues?.damageAmount),
        removalProtection  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.removalProtection),
        lessSalvage        : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.lessSalvage),
        totalLossACV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.totalLossACV),
        recDepreciation    : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.recDepreciation),
        coInsurancePenalty : formatAmountStringToFloatNumber(additionalInfo?.damageData?.building?.coInsurancePenalty),
        deductible         : formatAmountStringToFloatNumber((isBuildingCoverage && validateField(coverageData.buildingCvg?.s_PrDiscountCode)) != '' ? (coverageData.buildingCvg?.s_PrDiscountCode).slice(6): 0 ),
        total              : isBuildingCoverage ? formatAmountStringToFloatNumber(finalBuildingTotal) : 0,
      },
      appurtenant : {
        propertyValueRCV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.propertyValueRCV),
        propertyValueACV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.propertyValueACV),
        grossLossRCV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.grossLossRCV),
        coverageDamageACV  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.coverageDamageACV),
        removalProtection  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.removalProtection),
        lessSalvage        : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.lessSalvage),
        totalLossACV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.totalLossACV),
        recDepreciation    : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.recDepreciation),
        coInsurancePenalty : formatAmountStringToFloatNumber(additionalInfo?.damageData?.appurtenant?.coInsurancePenalty),
      },
      contents : {
        propertyValueRCV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.propertyValueRCV, contentValues?.replacementCost),
        propertyValueACV   : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.propertyValueACV, contentValues?.propertyValue),
        grossLossRCV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.grossLossRCV, contentValues?.damageReplacementCost),
        coverageDamageACV  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.coverageDamageACV, contentValues?.damageAmount),
        removalProtection  : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.removalProtection),
        lessSalvage        : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.lessSalvage),
        totalLossACV       : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.totalLossACV),
        recDepreciation    : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.recDepreciation),
        coInsurancePenalty : formatAmountStringToFloatNumber(additionalInfo?.damageData?.contents?.coInsurancePenalty),
        deductible         : formatAmountStringToFloatNumber((isContentCoverage && validateField(coverageData.contentCvg?.s_PrDiscountCode)) != '' ? (coverageData.contentCvg?.s_PrDiscountCode).slice(6): 0 ),
        total              : isContentCoverage ? formatAmountStringToFloatNumber(finalContentTotal) : 0,
      }
    };
    dispatch(setClaimDamageData(damageData));
  }

  const formatAmountStringToFloatNumber = (newAmount, oldAmount='', defalutvalue=0) => {
    if(newAmount !== undefined && newAmount !== null && newAmount !=''){
        return parseFloat(newAmount)
    }else if(oldAmount !== undefined && oldAmount !== null && oldAmount !=''){
      return parseFloat(oldAmount)
    }
    return parseFloat(defalutvalue)
  }

  const validateField = (field, defalutvalue='') => {
    if(field !== undefined && field !== null && field !=''){
        return field;
    }
    return defalutvalue;
  }

  const handleSelect = async (key) => {
    setActiveTab(key);
    switch (key) {
      case 'payment':
        setLoading(true);
        if(claim.ClaimId_PK){
          await dispatch(getCoreClaimPaymentData(claim.ClaimId_PK)).then((res) => {
            if (res.Status == 'Y') {
              dispatch(setCoreClaimPaymentData(res.data));
              setLoading(false);
            } else {
              toast.error(res.Msg);
            }
          });
        }
        setLoading(false);
        break;
      default:
        break;
    }
  };

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="policy-details-section">  
        <Row>
          {
            (
              <Col xs="12" id="policy-details-tabs-section" className="custom-tabs">
                <Tabs
                  activeKey={activeTab}
                  id="uncontrolled-tab-example"
                  onSelect={handleSelect}
                >
                  <Tab eventKey="summary" title={t("Summary")}>
                    <Col xs="12" className="billing-tab-row1">
                      <ClaimSummary />
                    </Col>
                  </Tab>
                  <Tab eventKey="payment" title={t("Payments")}>
                    <ClaimPayment />
                  </Tab>
                  <Tab eventKey="additional-info" title={t("Additional Info")}>
                    <ClaimAddInfo />
                  </Tab>
                </Tabs>
              </Col>
            )
          }
        </Row>
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
      claimDetailObject : state.coreSystem.coreClaimData
  };
};

export default connect(mapStateToProps)(ClaimDetails);
