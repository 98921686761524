import React, { useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import PivotLog from "../../../../flood/quote/Modal/PivotLog";
import { FaHistory } from "react-icons/fa";

export default function ActivityLogs(props) {
  const [pivotLog, showPivotLog] = useState(null);
  return (
    <Flex mx={1}>
      {pivotLog}
      <Button
        color={"white"}
        bg={"primary"}
        style={{ padding: "0.65rem 0.75rem" }}
        id="primary-colored-btn"
        variant="primary"
        onClick={() =>
          showPivotLog(
            <PivotLog
              showPivotLog={showPivotLog}
              poTransPK={props.poTransPk}
              from="QUOTE_VIEW"
              productType="HISCOX"
            />
          )
        }
      >
        <FaHistory style={{ marginRight: "5px" }} />
        Logs
      </Button>
    </Flex>
  );
}
