
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
// import { Row, Col, Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import SearchQuoteTab from "./search-quote/SearchQuoteTab";
// import Icon1 from "../../assets/Icon1.png";
// import Icon2 from "../../assets/Icon2.png";
// import Icon3 from "../../assets/Icon3.png";
// import Icon4 from "../../assets/Icon4.png";
// import Flood from "../../assets/flood_house.png";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./quote.scss";
// import { Link } from "react-router-dom";
// import { resetFloodStore } from "../../services/floodService";
import {
  getQuoteListData,
  getProductListData,
  getAppStatusDD,
  getAppRemarkDD,
  checkAccessibilityOfProduct,
} from "../../services/quoteService";
import { useDispatch, connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { showDataTableTotalText } from "../../services/commonService";
import { EncryptOrDecrypt } from "../../services/commonService";
import { LoadingOverlay } from "react-overlay-loader";
import { toast } from "react-toastify";
import { Box, Flex, Link, FormControl, FormLabel, Grid, Input, Select, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Image, Text, Alert, AlertIcon } from "@chakra-ui/react";
import AdvancedGridTable from "../../components/AdvancedGridTable";
import { BiReset } from "react-icons/bi";
import { formateDate } from "../common/FormatDate";
import "./quotePage.css"; //for new quote page ui
import Tour from "reactour";
import QuoteNumberCell from "./QuoteNumberCell";
import { tenant } from "../common/Helper";
import { disallowedFlood } from "./common/tenantSynonyms";

const Quote = (props) => {
  const { t } = useTranslation("translations","quoteSearch");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const tourRef = useRef(null);
  const [searchParams] = useSearchParams();
  //eslint-disable-next-line
  const [createQuote, setCreateQuote] = useState(false);
  // const [TableData, setTableData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [filter, setFilter] = useState({
  //   quoteNo: "",
  //   policyNo: "",
  //   product: "",
  //   appStatus: "",
  //   firstName: "",
  //   lastName: "",
  //   agencyCode: "",
  //   agencyName: "",
  //   page: 1,
  //   pageLength: 10,
  //   remark: "",
  // });
  const [ProductList, setProductList] = useState([]);
  const [AppStatus, setAppStatus] = useState([]);
  // const [tableTotal, setTableTotal] = useState([]);
  const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const productCodeList = JSON.parse(localStorage.getItem('productCodeList')) || [];
  const isAgentHasHiscoxAccess = productCodeList.some(item => Object.values(item).includes("HiscoxFloodPlus"));
  const [AppRemark, setAppRemark] = useState([]);
  const [userProduct, setUserProduct] = useState([]);
  const [productId, setProductId] = useState("");
  const [productCode, setProductCode] = useState("");
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const filterOptions = {
    quoteNo: "",
    firstName: "",
    lastName: "",
    appStatus: "",
    remark: "",
    policyNo: "",
    product: "",
    agencyName: "",
    agencyCode: "",
  }
  const [filterParams, setFilterParams] = useState(filterOptions);
  const remarkArray = [
    { value: "PENDING", label: "Pending" },
    { value: "NA", label: "N/A" },
    { value: "EXPIRED", label: "Expired" }
  ];
  const [isOpenGuide, setIsOpenGuide] = useState(false);
  // const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    // searchQuoteList();
    getProductList();
    if (userDatas && userDatas.productList) {
      let productList = userDatas.productList;
      productList.unshift(10);
      // if exist
      if (productList.includes(13)) productList.unshift(10, 13);

      const uniqueProductList = productList.filter(
        (item, index, ar) => ar.indexOf(item) === index
      );
      setUserProduct(uniqueProductList);
    }
    //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   searchQuoteList();
  // }, [filter.page, filter.pageLength]);

  useEffect(()=>{
    const activeTab = searchParams.get('activeTab');
    if(activeTab==="createQuote") {
      setCurrentTabIndex(1);
    }
  },[ searchParams ]);

  useEffect(() => {
    setProductList(props.getProductList.data);
    setAppStatus(props.getAppStatusDDList.data);
    setAppRemark(props.getAppRemarkDDList.data);
  }, [props]);

  // const searchQuoteList = async () => {
  //   setLoading(true);
  //   await dispatch(getQuoteListData(filter)).then((res) => {
  //     setTableData(res.data.data);
  //     setTableTotal(res.data.total);
  //     if (res.data.total == 0) {
  //       toast.warning("No data found");
  //     }
  //     if (res.data.status === false) {
  //       const errorMessages = Object.values(res.data.errors).flat();
  //       errorMessages.forEach(errorMessage => {
  //         toast.error(errorMessage);
  //       });
  //       toast.warning("No data found");
  //     }
  //   });
  //   setLoading(false);
  // };

  const getProductList = async () => {
    await dispatch(getProductListData());
    await dispatch(getAppStatusDD());
    await dispatch(getAppRemarkDD());
  };

  const checkId = async () => {
    try {
      dispatch(checkAccessibilityOfProduct("hiscox")).then((res) => {
        const accessibility = res.data;
        if (accessibility.status === true) {
          setProductId(accessibility.data.product_id);
          setProductCode(accessibility.data.product_code);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkId();
    //eslint-disable-next-line
  }, [productId]);
  // const productsGenerator = (quantity) => {
  //   const items = [];
  //   for (let i = 0; i < quantity; i++) {
  //     items.push({
  //       policy_no: "HO32014012226",
  //       insured_name: "ROGE DAMEUS",
  //       product_type: "HO6 (Homeowners)",
  //       renew_date: "MM/DD/YYYY",
  //     });
  //   }
  //   return items;
  // };

  // const products = productsGenerator(5);

  // const columnFormatter = (cell, row, rowIndex, formatExtraData) => {
  //   if (row && row.Policy_No && row.n_ProductId_PK === 10) {
  //     return (
  //       <Link
  //         to={`/flood/quote/${row.n_potransaction_PK}`}
  //         onClick={() => dispatch(resetFloodStore())}
  //       >
  //         {cell}
  //       </Link>
  //     );
  //   } else if (row && row.Policy_No && row.s_ProductCode === 'HiscoxFloodPlus') {
  //     return (
  //       <Link
  //         to={`hiscox/application/${row.s_PolicyQuoteNo}`}
  //       >
  //         {cell}
  //       </Link>
  //     );
  //   } else if (row && row.Policy_No) {

  //     return (
  //       <Link
  //         to={`editApplication/${row.n_ProductId_PK}/${row.n_potransaction_PK}`}
  //       >
  //         {cell}
  //       </Link>
  //     );
  //   } else {
  //     return row.Policy_No;
  //   }
  // };

  // const columns = [
  //   {
  //     dataField: "d_CreatedDate",
  //     text: t("Submit Date"),
  //   },
  //   {
  //     dataField: "d_TransEffectiveFrom",
  //     text: t("Effective date"),
  //   },
  //   {
  //     dataField: "s_PolicyQuoteNo",
  //     text: t("Quote No"),
  //     formatter: columnFormatter,
  //     style: {
  //       textDecoration: "underline",
  //       color: "blue"
  //     }
  //   },
  //   {
  //     dataField: "s_FullLegalName",
  //     text: t("Policyholder/Member Name"),
  //   },
  //   {
  //     dataField: "s_TransactionCycleCode",
  //     text: t("Status"),
  //   },
  //   {
  //     dataField: "s_Remark",
  //     text: t("Remark"),
  //   },
  //   {
  //     dataField: "Policy_No",
  //     text: t("Policy No"),
  //   },
  //   {
  //     dataField: "agencyName",
  //     text: t("Agency Name"),
  //   },
  //   {
  //     dataField: "agentName",
  //     text: t("Agent Name"),
  //   },
  // ];
  // const onHandleChange = (e) => {
  //   var name = e.target.name;
  //   const tempArray = filter; // creating copy of an array
  //   tempArray[name] = e.target.value;
  //   setFilter(tempArray); // set copied array
  // };

  // const onTableChange = (type, { page, sizePerPage }) => {
  //   setFilter({ ...filter, page: page, pageLength: sizePerPage });
  // };

  // const handlePageChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, page: page, pageLength: sizePerPage });
  // };

  // const sizePerPageListChange = (page, sizePerPage) => {
  //   setFilter({ ...filter, pageLength: sizePerPage });
  // };

  // const renderShowsTotal = (from, to, size) => (
  //   <span style={{ paddingLeft: 10 }}>
  //     Showing {from} to {to} of {size} Results
  //   </span>
  // );

  // const options = {
  //   page: filter.page,
  //   sizePerPage: filter.pageLength,
  //   sortIndicator: false,
  //   pageStartIndex: 1,
  //   showTotal: true,
  //   onPageChange: handlePageChange,
  //   totalSize: tableTotal,
  //   onSizePerPageChange: sizePerPageListChange,
  //   paginationTotalRenderer: renderShowsTotal,
  // };
  
  // const handleTabChange = (index) => {
  //   setActiveTabIndex(index);
  //   if (index === 1) { //if it is create quote tab
  //     setTimeout(() => {
  //       let card = document.getElementById("floodTile");
  //       if (card != null) {
  //         card.focus();
  //       }
  //     }, 100);
  //   }
  // };

  const onQuoteNumberClick = ({ data: rowData }) => {
    if (!rowData || !rowData.Policy_No) return;
  
    const { n_ProductId_PK, n_potransaction_PK, s_PolicyQuoteNo, s_ProductCode } = rowData;

    if (n_ProductId_PK === 10) {
      navigation(`/flood/quote/${n_potransaction_PK}`);
    } else if (n_ProductId_PK === 11) {
      navigation(`/quote/starcom/${s_PolicyQuoteNo}`);
    } else if (s_ProductCode === "HiscoxFloodPlus") {
      navigation(`hiscox/application/${s_PolicyQuoteNo}`);
    } else { // default is editApplication route.
      navigation(`editApplication/${n_ProductId_PK}/${n_potransaction_PK}`);
    }
  };
  

  const WordBreakRenderer = (params) => {
    return (
      <div style={{ wordBreak: "break-word" }}>
        {params.value}
      </div>
    )
  };

  const PolicyNumberRenderer = (params) => {
    if(params?.data?.s_TransactionCycleCode === "QUOTE") {
      return "";
    } else {
      return params.value;
    }
  };

  const RemarkCellRenderer = (params) => {
    const rowData = params.data;
    let remark = "";
    remarkArray.forEach((list, index) => {
      if (rowData.s_Remark === list.value) {
        remark = list.label;
      }
    });
    return remark;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterParams(prevParams => {
      const updatedParams = { ...prevParams };
      updatedParams[name] = value;
      return updatedParams;
    });
  };

  const handleSearch = (filterParams) => {
    const datasource = createServerSideDatasource(filterParams);
    gridRef?.current?.api?.setServerSideDatasource(datasource);
  };

  const handleReset = () => {
    gridRef?.current?.api?.resetColumnState();
    setFilterParams(filterOptions);
    gridRef?.current?.api?.setFilterModel(null);
    const datasource = createServerSideDatasource();
    gridRef?.current?.api?.setServerSideDatasource(datasource);
    gridRef.current.api.sizeColumnsToFit();
  };

  const loadData = async (params = { pageLength: 50, page: 1 }) => {
    try {
      const res = await dispatch(getQuoteListData(params)).then((res)=>{
        return res;
      });
      return res?.data;
    } catch (e) {
      const errorMessages = Object.values(e?.errors).flat();
      errorMessages.forEach(errorMessage => {
        toast.error(errorMessage);
      });
      toast.warning("No data found");
      return {
        rows: 0,
        total: 0,
      };
    }
  };

  const createServerSideDatasource = (filterParams) => {
    return {
      getRows: async (params) => {
        const pageLength = params.request.endRow - params.request.startRow;
        const page = params.request.endRow / pageLength;
        let apiParams = {
          pageLength,
          page,
          sortOrder : "Desc",
          sortBy :"d_CreatedDate ",
        };

        //for external parameter filtering
        if (filterParams?.quoteNo) {
          apiParams = {
            ...apiParams,
            quoteNo: filterParams.quoteNo,
          }
        }
        if (filterParams?.lastName) {
          apiParams = {
            ...apiParams,
            lastName: filterParams.lastName,
          }
        }
        if (filterParams?.firstName) {
          apiParams = {
            ...apiParams,
            firstName: filterParams.firstName,
          }
        }
        if (filterParams?.appStatus) {
          apiParams = {
            ...apiParams,
            appStatus: filterParams.appStatus,
          }
        }
        if (filterParams?.remark) {
          apiParams = {
            ...apiParams,
            remark: filterParams.remark,
          }
        }
        if (filterParams?.policyNo) {
          apiParams = {
            ...apiParams,
            policyNo: filterParams.policyNo,
          }
        }
        if (filterParams?.product) {
          apiParams = {
            ...apiParams,
            product: filterParams.product,
          }
        }
        if (filterParams?.agencyName) {
          apiParams = {
            ...apiParams,
            agencyName: filterParams.agencyName,
          }
        }
        if (filterParams?.agencyCode) {
          apiParams = {
            ...apiParams,
            agencyCode: filterParams.agencyCode,
          }
        }

        //for internal parameter sorting
        if (params.request?.sortModel?.length > 0) {
          apiParams = {
            ...apiParams,
            sortOrder: params.request?.sortModel[0]?.sort,
            sortBy: params.request?.sortModel[0]?.colId, //only taking one parameter for sorting
          }
        }

        //for internal parameter filtering
        if (params.request?.filterModel?.s_PolicyQuoteNo?.filter) {
          apiParams = {
            ...apiParams,
            quoteNo: params.request.filterModel.s_PolicyQuoteNo.filter,
          };
        }
        if (params.request?.filterModel?.s_FullLegalName?.filter) {
          apiParams = {
            ...apiParams,
            firstName: params.request.filterModel.s_FullLegalName.filter,
          };
        }
        if (params.request?.filterModel?.s_TransactionCycleCode?.values) {
          apiParams = {
            ...apiParams,
            appStatus: params.request.filterModel.s_TransactionCycleCode.values,
          };
        }
        if (params.request?.filterModel?.Policy_No?.filter) {
          apiParams = {
            ...apiParams,
            policyNo: params.request.filterModel.Policy_No.filter,
          };
        }
        const policies = await loadData(apiParams);

        setTimeout(function () {
          if (policies?.total) {
            // supply rows for requested block to grid
            params.success({
              rowData: policies.data,
              rowCount: policies.total,
            });
            params.api.hideOverlay();
          } else {
            params.api.showNoRowsOverlay();
            params.success({
              rowData: [],
              rowCount: 0,
            });
            // params.fail();
          }
        }, 500);
      },
    };
  };

  const onGridReady = useCallback((params) => {
    const datasource = createServerSideDatasource();
    // register the datasource with the grid
    params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
    const columnState = {
      state: [
        {
          colId: "d_CreatedDate", //By default sorted according to submit date
          sort: "desc",
        },
      ],
    };
    params.columnApi.applyColumnState(columnState);
    //eslint-disable-next-line
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressHeaderMenuButton: true,
      sizeColumnsToFit: true,
    }),
    []
  );

  // const autoSizeStrategy = useMemo(() => {
  //   return {
  //     type: "fitGridWidth",
  //     // defaultMinWidth: 100,
  //   };
  // }, []);

  // const sideBar = useMemo(() => {
  //   return {
  //     toolPanels: [
  //       {
  //         id: "filters",
  //         labelDefault: "Filters",
  //         labelKey: "filters",
  //         iconKey: "filter",
  //         toolPanel: "agFiltersToolPanel",
  //         /*minWidth: 180,
  //         maxWidth: 400,
  //         width: 250,*/
  //       },
  //     ],
  //     position: "left",
  //     //defaultToolPanel: "filters",
  //     hiddenByDefault: false
  //   };
  // }, []);

  const [columnDefs] = useState([
    {
      headerName: t("Submit Date"),
      field: "d_CreatedDate",
      filter: false,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 110,
      cellRenderer: (params) =>
        WordBreakRenderer({
          value: formateDate(params.value, "MM/DD/Y HH:mm:ss"),
        }),
    },
    {
      headerName: t("Quote Number", {ns: "quoteSearch"}),
      field: "s_PolicyQuoteNo",
      filter: "agTextColumnFilter",
      minWidth: 165,
      cellStyle: {
        color: "var(--chakra-colors-linkColor)",
        textDecoration: "underline",
      },
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
      cellRenderer: (params) => (
        <QuoteNumberCell
          value={params.value}
          onQuoteNumberClick={()=>onQuoteNumberClick(params)}
        />
      ),
    },
    {
      headerName: t("Effective Date"),
      field: "d_TransEffectiveFrom",
      filter: false,
      sortable: false,
      minWidth: 110,
      valueFormatter: (params) => formateDate(params.value),
    },
    {
      headerName: t("Policyholder / Member Name", {ns: "quoteSearch"}),
      field: "s_FullLegalName",
      filter: "agTextColumnFilter",
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
      minWidth: 190,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("Status"),
      field: "s_TransactionCycleCode",
      filter: "agSetColumnFilter",
      sortable: false,
      minWidth: 120,
      filterParams: {
        values: ["BOUND", "QUOTE"],
        valueFormatter: (params) =>
          params.value === "BOUND" ? "APPLICATION" : params.value,
      },
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
    },
    {
      headerName: t("Policy Number"),
      field: "Policy_No",
      filter: "agTextColumnFilter",
      sortable: true,
      minWidth: 140,
      floatingFilterComponentParams: {},
      suppressFloatingFilterButton: true,
      cellRenderer: (params) => PolicyNumberRenderer(params),
    },
    {
      headerName: t("Remark"),
      field: "s_Remark",
      filter: false,
      sortable: false,
      minWidth: 90,
      cellRenderer: RemarkCellRenderer,
    },
    {
      headerName: t("Agency Name"),
      field: "agencyName",
      filter: false,
      sortable: false,
      minWidth: 220,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
    {
      headerName: t("Agent Name", {ns: "quoteSearch"}),
      field: "agentName",
      filter: false,
      sortable: false,
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => WordBreakRenderer(params),
    },
  ]);

  const tourGuide = () => {
    setIsOpenGuide(true);
  }

  const closeTourguide = () => {
    setIsOpenGuide(false);
  }

  const steps = [
    {
      selector: '.tour-guide-enter-search',
      content: <div>{t("Click enter key after entering information to search", {ns: "quoteSearch"})}.</div>
    },
    {
      selector: '.tour-reset',
      content: <div>{t("Click to reset your search", {ns: "quoteSearch"})}.</div>
    },
    //temp steps 
    {
      selector: ".ag-header-row-column-filter",
      content: <div>{t("Enter specific information to filter data", {ns: "quoteSearch"})}.</div>,
    },
    {
      selector: ".ag-header-cell-resize",
      content: <div>{t("From here, columns can be resized", {ns: "quoteSearch"})}.</div>,
    },
    {
      selector: ".ag-column-first",
      content: <div>{t("To sort the data, click on the column", {ns: "quoteSearch"})}.</div>,
    },
    {
      selector: ".ag-column-first",
      content: <div>{t("Drag a column header outside the table to remove the column", {ns: "quoteSearch"})}.</div>,
    },
    {
      selector: ".ag-row-level-0 .ag-column-first",
      content: <div>{t("To see more options, right-click on a column", {ns: "quoteSearch"})}.</div>,
    },
    // ...defaultSteps
  ];
  
  const handleEnterQuoteSearch =(e)=> {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch(filterParams);
      // searchQuoteList();
    }
  };

  // const changeTab = (e) =>{
  //   if(e=="create"){
  //    setTimeout(() => {
  //      let card = document.getElementById("floodTile");
  //      if(card!=null){
  //         card.focus();
  //       }
  //    }, 100);
  //   }
  // };

  // const handleFloodEnter= ()=>{
  //   navigation(
  //     "/flood/instantquote/step/address"
  //   )
  // };

  return (
    <>
    <Tour
      accentColor={"var(--chakra-colors-primary-500)"}
      ref={tourRef}
      startAt={tourRef?.current?.state?.current===steps?.length-1 ? 0 : tourRef?.current?.state?.current}
      steps={steps}
      isOpen={isOpenGuide}
      onRequestClose={closeTourguide}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      lastStepNextButton={<Button>Close</Button>}
    />
    <LoadingOverlay>
      {/* <Loader loading={loading} /> */}
      <Box>
        <Flex mb={4} align={'center'}>
          <Text mb={0} fontWeight={600} fontSize="2xl" lineHeight="32px">
            {t("Quote Search", {ns: "quoteSearch"})}
          </Text>
          {/* {activeTabIndex === 0 && (<Button
            leftIcon={<FaWalking size={20} className="sidebar-link-icon"/>}
            className="zero-step"
            id="createQuoteHeaderButton"
            onClick={tourGuide}
            
          >
            {t("What's New")}
          </Button>)} */}
        </Flex>
        <Tabs index={currentTabIndex} onChange={(index)=>setCurrentTabIndex(index)}>
          <TabList>
            <Tab mb={0} _selected={{ bgColor: "var(--chakra-colors-primary-500)", color: "white" }}>{t("Search Quote")}</Tab>
            {EncryptOrDecrypt(localStorage.getItem("is_active"), "D") ===
              "Y" && (<Tab mb={0} _selected={{ bgColor: "var(--chakra-colors-primary-500)", color: "white" }}>{t("Create Quote")}</Tab>)}
          </TabList>
          <TabPanels>
            <TabPanel pl={0} pr={0}>
              <Box mb={4}>
                  <Box pt={0} mb={4}>
                    <Alert status='info'>
                      <AlertIcon />
                      {t("Check out quick tips and tools to simplify your experience", { ns: "quoteSearch" })}.&nbsp;
                      <Link href="#" onClick={tourGuide} style={{ textDecoration: "underline", color: "blue" }}>
                        {t("Guide Me", {ns: "quoteSearch"})}!
                      </Link>
                    </Alert>
                  </Box>
                <Box mb={4} border={'1px solid white'} borderRadius={"2xl"} boxShadow={'lg'} p={5} bg={'white'}>
                  <Grid alignItems={'end'} templateColumns='repeat(5, 1fr)' gap={6}>
                    <FormControl>
                      <FormLabel>{t("Quote Number", {ns: "quoteSearch"})}</FormLabel>
                      <Input
                        name="quoteNo"
                        className="dashboard-group-input tour-guide-enter-search"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.quoteNo}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                        autoFocus={true}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Last Name")}</FormLabel>
                      <Input
                        name="lastName"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.lastName}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("First Name")}</FormLabel>
                      <Input
                        name="firstName"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.firstName}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Status")}</FormLabel>
                      <Select
                        name="appStatus"
                        className="dashboard-group-input"
                        id="select-ym-option"
                        value={filterParams.appStatus}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      >
                        <option
                          value={""}
                          className="myform-input custom-select-autocomplete"
                        ></option>
                          {AppStatus &&
                            AppStatus
                              .filter(v => v.value != null)
                              .map((v, i) => {
                                return (
                                  <option
                                    className="myform-input custom-select-autocomplete"
                                    key={i}
                                    value={v.value}
                                  >
                                    {v.label}
                                  </option>
                                );
                              })
                          }
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Remark")}</FormLabel>
                      <Select
                        name="remark"
                        className="dashboard-group-input"
                        id="select-ym-option"
                        value={filterParams.remark}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      >
                        <option
                          value={""}
                          className="myform-input custom-select-autocomplete"
                        ></option>
                        {AppRemark &&
                          AppRemark.map((v, i) => {
                            return (
                              <option
                                className="myform-input custom-select-autocomplete"
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </option>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Policy Number")}</FormLabel>
                      <Input
                        name="policyNo"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.policyNo}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Product", {ns: "quoteSearch"})}</FormLabel>
                      <Select
                        name="product"
                        className="dashboard-group-input"
                        id="select-ym-option"
                        value={ProductList?.length === 1 ? ProductList[0].value : filterParams.product}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      >
                        {props.getProductList.data?.length > 1 ? //according to prev code
                        (<option
                          value={""}
                          className="myform-input custom-select-autocomplete"
                        ></option>)
                        : null}
                        {ProductList &&
                          ProductList.map((v, i) => {
                            return (
                              <option
                                className="myform-input custom-select-autocomplete"
                                key={i}
                                value={v.value}
                              >
                                {v.label}
                              </option>
                            );
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Agency Name")}</FormLabel>
                      <Input
                        name="agencyName"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.agencyName}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>{t("Agency Code")}</FormLabel>
                      <Input
                        name="agencyCode"
                        className="dashboard-group-input"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        value={filterParams.agencyCode}
                        onChange={(e) => handleChange(e)}
                        onKeyDown ={handleEnterQuoteSearch}
                      />
                    </FormControl>
                    <Flex>
                      <Button
                        leftIcon={<FaSearch />}
                        onClick={() => handleSearch(filterParams)}
                      >
                        {t("Search")}
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        leftIcon={<BiReset />}
                        className="tour-reset"
                        onClick={() => handleReset()}
                      >
                        {t("Reset", {ns: "quoteSearch"})}
                      </Button>
                    </Flex>
                  </Grid>
                </Box>
                <Box bgColor={"white"} p={5} borderRadius={"2xl"} boxShadow={'lg'} borderColor={"white"}>
                  <Box
                    className="ag-theme-alpine"
                    h={"70vh"}
                    minHeight={'400px'}
                    maxHeight={'600px'}
                    id="myGrid"
                    // borderRadius={"2xl"}
                    // border={"1px solid"}
                    // borderColor={"gray.200"}
                    overflow={"hidden"}
                    w={'100%'}
                  >
                    <AdvancedGridTable
                      gridRef={gridRef}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      // autoSizeStrategy={autoSizeStrategy}
                      // sideBar={sideBar}
                      // floatingFilter={true}
                      suppressMultiSort={true}
                      rowBuffer={0}
                      rowModelType={"serverSide"}
                      cacheBlockSize={25}
                      maxBlocksInCache={20}
                      onGridReady={(params) => onGridReady(params)}
                      enableCellTextSelection={true}
                    // enableAdvancedFilter={false}
                    />
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            {EncryptOrDecrypt(localStorage.getItem("is_active"), "D") ===
              "Y" && (
                <TabPanel pl={0} pr={0}>
                  {createQuote ? (
                    <SearchQuoteTab />
                  ) : (<>
                    <Box mb={6}>
                      <Box border={'1px solid white'} boxShadow={'lg'} borderRadius={"2xl"} p={8} bg={'white'}>
                        <Grid
                          alignItems={'end'}
                          templateColumns={{
                            base: 'repeat(1, 1fr)',
                            md: 'repeat(3, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            lg: 'repeat(4, 1fr)'
                          }}
                          gap={6}
                        >
                          {userProduct && userProduct.map((product) => {
                            switch (product) {
                              case 13:
                                return (
                                  <>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/quote/editApplication/13")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/home.svg" alt={t("Home")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("Home")}</Text>
                                      </Flex>
                                    </Flex>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/instaQuote/InstaSteps/address")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/home.svg" alt={t("Home (InstaQuote)")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("Home (InstaQuote)")}</Text>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              case 16:
                                return (
                                  <>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/quote/editApplication/13")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/condominium.svg" alt={t("Condominium")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("Condominium")}</Text>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              case 5:
                                return (
                                  <>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/quote/editApplication/13")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/mobilehome.svg" alt={t("Mobile Home Owners Program")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("Mobile Home Owners Program")}</Text>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              case 6:
                                return (
                                  <>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/quote/editApplication/13")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/mobilehome.svg" alt={t("Mobile Home Dwelling Program")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("Mobile Home Dwelling Program")}</Text>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              case 10:
                                return (
                                  <>
                                    {
                                   !disallowedFlood.includes(tenant) &&
                                        <>
                                          <Flex
                                            className="create-quote-card"
                                            // tabIndex="-1"
                                            id="floodTile"
                                            // onKeyDown={handleFloodEnter}
                                            onClick={() =>
                                              navigation("/flood/instantquote/step/address")
                                            }
                                            _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                          >
                                            <Flex
                                              pr={'10px'} //to set the image at center
                                              pt={'10px'} //to set the image at center
                                              height={'200px'}
                                              width={'100%'}
                                              align={'center'}
                                              justify={'center'}
                                            >
                                              <Image height={'110px'} width={'110px'} src="/assets/images/flood-home.svg" alt={t("Flood")} />
                                            </Flex>
                                            <Flex className="card-text-box" bg={'gray.200'}>
                                              <Text className="card-text">{t("Flood")}</Text>
                                            </Flex>
                                          </Flex>
                                        </>
                                    }
                                    {productCode && productCode === "HiscoxFloodPlus" && isAgentHasHiscoxAccess && (
                                      <Flex
                                        className="create-quote-card"
                                        onClick={() =>
                                          navigation("/quote/hiscox/create")
                                        }
                                        _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                      >
                                        <Flex width={'100%'} align={'center'} justify={'center'}>
                                          <Image height={'200px'} width={'200px'} src="/assets/images/hiscox_logo.jpg" alt={t("Hiscox")} />
                                        </Flex>
                                        <Flex className="card-text-box" bg={'gray.200'}>
                                          <Text className="card-text">{t("Hiscox")}</Text>
                                        </Flex>
                                      </Flex>
                                    )}
                                  </>
                                );
                              case 1:
                                return (
                                  <>
                                    <Flex
                                      className="create-quote-card"
                                      onClick={() =>
                                        navigation("/quote/starins/start")
                                      }
                                      _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                    >
                                      <Flex width={'100%'} align={'center'} justify={'center'}>
                                        <Image height={'200px'} width={'200px'} src="/assets/images/condominium.svg" alt={t("HO3 (Homeowners)")} />
                                      </Flex>
                                      <Flex className="card-text-box" bg={'gray.200'}>
                                        <Text className="card-text">{t("HO3 (Homeowners)")}</Text>
                                      </Flex>
                                    </Flex>
                                  </>
                                );
                              default:
                                return null;
                            }
                          })}
                        </Grid>
                      </Box>
                    </Box>
                  </>)}
                </TabPanel>
              )}
          </TabPanels>
        </Tabs>
      </Box>
      {/* <div className="quote-page-section">
        <Row>
          <Col xs="12" className="custom-tabs">
            <Tabs defaultActiveKey="search" id="uncontrolled-tab-example">
              <Tab eventKey="search" title={t("Search Quote")}>
              <Row className="align-items-baseline">
                <Col xs="12" className="quote-page-tab1">
                  <Row className="align-items-center">
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Quote No")}.
                        </Form.Label>
                        <Form.Control
                          name="quoteNo"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                          autoFocus={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Last Name")}
                        </Form.Label>
                        <Form.Control
                          name="lastName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("First Name")}
                        </Form.Label>
                        <Form.Control
                          name="firstName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("App. Status")}:
                        </Form.Label>
                        <Form.Select
                          name="appStatus"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        >
                          <option
                            value={""}
                            className="myform-input custom-select-autocomplete"
                          >
                            Select
                          </option>
                          {AppStatus &&
                            AppStatus.map((v, i) => {
                              return (
                                <option
                                  className="myform-input custom-select-autocomplete"
                                  key={i}
                                  value={v.value}
                                >
                                  {v.label}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Remark")}:
                        </Form.Label>
                        <Form.Select
                          name="remark"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        >
                          <option
                            value={""}
                            className="myform-input custom-select-autocomplete"
                          >
                            Select
                          </option>
                          {AppRemark &&
                            AppRemark.map((v, i) => {
                              return (
                                <option
                                  className="myform-input custom-select-autocomplete"
                                  key={i}
                                  value={v.value}
                                >
                                  {v.label}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Policy No")}
                        </Form.Label>
                        <Form.Control
                          name="policyNo"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Select Product")}
                        </Form.Label>
                        <Form.Select
                          name="product"
                          className="dashboard-group-input"
                          id="select-ym-option"
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        >
                          { props.getProductList.data?.length > 1 ?
                          <option
                            value={""}
                            className="myform-input custom-select-autocomplete"
                          >
                            Select
                          </option>
                          :null}
                          {ProductList &&
                            ProductList.map((v, i) => {
                              return (
                                <option
                                  className="myform-input custom-select-autocomplete"
                                  key={i}
                                  value={v.value}
                                >
                                  {v.label}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Agency Name")}
                        </Form.Label>
                        <Form.Control
                          name="agencyName"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="3" xl="3">
                      <Form.Group
                        className="dashboard-group-forms"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="dashboard-group-label">
                          {t("Agency Code")}
                        </Form.Label>
                        <Form.Control
                          name="agencyCode"
                          className="dashboard-group-input"
                          type="text"
                          placeholder=""
                          onChange={(e) => onHandleChange(e)}
                          onKeyDown ={handleEnterQuoteSearch}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Button
                        id="common-btn"
                        variant="primary"
                        onClick={() => searchQuoteList()}   
                      >
                        <FaSearch size={15} style={{ marginRight: "14px" }} />
                        {t("Search Policy")}
                      </Button>{" "}
                    </Col>
                  </Row>
                </Col>
                <Row
                  id="dashboard-table-section"
                  style={{ padding: "55px 0px 0px 0px" }}
                >
                  <Col xs="12" style={{ padding: 0 }}>
                    <div className="table-main-title-div">
                      <span className="table-main-title-name">
                        {t("Search Result")}
                      </span>
                      <div className="table-main-title-icons">
                        <div className="table-square"></div>
                        <FaBars size={15} color={"#fff"} />
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" style={{ padding: 0 }}>
                    <BootstrapTable
                      wrapperClasses="dashboard-table-main"
                      bootstrap4
                      remote
                      keyField="Policy_No"
                      data={TableData || []}
                      columns={columns}
                      hover
                      pagination={paginationFactory(options)}
                      onTableChange={onTableChange}
                    />
                  </Col>
                </Row>
              </Row>
            </Tab>
            {EncryptOrDecrypt(localStorage.getItem("is_active"), "D") ==
              "Y" && (
                <Tab eventKey="create" title={t("Create Quote")}>
                  {createQuote ? (
                    <SearchQuoteTab />
                  ) : (
                    <Row>
                      <Col xs="12" className="quote-page-tab2">
                        <Row>
                          {userProduct &&
                            userProduct.map((product) => {
                              switch (product) {
                                case 13:
                                  return (
                                    <React.Fragment>
                                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                        <div
                                          className="quote-page-cards-div qpc1"
                                          onClick={() =>
                                            navigation(
                                              "/quote/editApplication/13"
                                            )
                                          }
                                        >
                                          <div className="quote-page-cards-bg1"></div>
                                          <div className="quote-page-cards-bg2"></div>

                                          <img
                                            className="quote-page-cards-icon"
                                            src={Icon1}
                                          />

                                          <div className="quote-page-cards-title">
                                            {t("Home")}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                        <div
                                          className="quote-page-cards-div qpc1"
                                          onClick={() =>
                                            navigation(
                                              "/instaQuote/InstaSteps/address"
                                            )
                                          }
                                        >
                                          <div className="quote-page-cards-bg1"></div>
                                          <div className="quote-page-cards-bg2"></div>

                                          <img
                                            className="quote-page-cards-icon"
                                            src={Icon1}
                                          />

                                          <div className="quote-page-cards-title">
                                            {t("Home (InstaQuote)")}
                                          </div>
                                        </div>
                                      </Col>
                                    </React.Fragment>
                                  );
                                  case 1 : return (
                                    <>
                                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                      <div
                                        className="quote-page-cards-div qpc1"
                                        onClick={() =>
                                          navigation("/quote/starins/start")
                                        }
                                        _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                                      >
                                        <div className="quote-page-cards-bg1"></div>
                                          <div className="quote-page-cards-bg2"></div>
                                          <img
                                            className="quote-page-cards-icon"
                                            src={Icon1}
                                          />

                                          <div className="quote-page-cards-title">
                                            {t("HO3 (Homeowners)")}
                                          </div>
                                      </div>
                                      </Col>
                                    </>
                                  );
                                case 16:
                                  return (
                                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                      <div
                                        className="quote-page-cards-div qpc2"
                                        onClick={() =>
                                          navigation("/quote/editApplication/13")
                                        }
                                      >
                                        <div className="quote-page-cards-bg1"></div>
                                        <div className="quote-page-cards-bg2"></div>

                                        <img
                                          className="quote-page-cards-icon"
                                          src={Icon2}
                                        />

                                        <div className="quote-page-cards-title">
                                          {t("Condominium")}
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                case 5:
                                  return (
                                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                      <div
                                        className="quote-page-cards-div qpc3"
                                        onClick={() =>
                                          navigation("/quote/editApplication/13")
                                        }
                                      >
                                        <div className="quote-page-cards-bg1"></div>
                                        <div className="quote-page-cards-bg2"></div>

                                        <img
                                          className="quote-page-cards-icon"
                                          src={Icon3}
                                        />

                                        <div className="quote-page-cards-title">
                                          {t("Mobile Home Owners Program")}{" "}
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                case 6:
                                  return (
                                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                      <div
                                        className="quote-page-cards-div qpc4"
                                        onClick={() =>
                                          navigation("/quote/editApplication/13")
                                        }
                                      >
                                        <div className="quote-page-cards-bg1"></div>
                                        <div className="quote-page-cards-bg2"></div>

                                        <img
                                          className="quote-page-cards-icon"
                                          src={Icon4}
                                        />

                                        <div className="quote-page-cards-title">
                                          {t("Mobile Home Dwelling Program")}{" "}
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                case 10:
                                  return (
                                    <>
                                      {(tenant !== "starvantage" && tenant !== "starinsurance") ? (
                                      <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                        <div
                                          className="quote-page-cards-div qpc5"
                                          onClick={() =>
                                            navigation(
                                              "/flood/instantquote/step/address"
                                            )
                                          }
                                        >
                                          <div className="quote-page-cards-bg1"></div>
                                          <div className="quote-page-cards-bg2"></div>

                                          <img
                                            className="quote-page-cards-icon"
                                            src={Flood}
                                          />

                                          <div className="quote-page-cards-title">
                                            {t("Flood")}{" "}
                                          </div>
                                        </div>
                                      </Col> ): null }
                                      {productCode && productCode == "HiscoxFloodPlus" && isAgentHasHiscoxAccess && (
                                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                          <div
                                            className="quote-page-cards-div qpc5"
                                            onClick={() =>
                                              navigation("/quote/hiscox/create")
                                            }
                                          >
                                            <div className="quote-page-cards-bg1"></div>
                                            <div className="quote-page-cards-bg2"></div>

                                            <img
                                              className="quote-page-cards-icon"
                                              src={Icon3}
                                            />

                                            <div className="quote-page-cards-title">
                                              Hiscox
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                    </>
                                  );
                                default:
                                  return null;
                              }
                            })}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </Tab>
              )}
          </Tabs>
        </Col>
      </Row>
    </div> */}
    </LoadingOverlay>
    </>
  );
};

function mapStateToProps(state) {
  return {
    getQuoteList: state.common.getQuoteList ? state.common.getQuoteList : {},
    getProductList: state.common.getProductList
      ? state.common.getProductList
      : {},
    getAppStatusDDList: state.common.getAppStatusDDList
      ? state.common.getAppStatusDDList
      : {},
    getAppRemarkDDList: state.common.getAppRemarkDDList
      ? state.common.getAppRemarkDDList
      : {},
  };
}
export default connect(mapStateToProps)(Quote);