import React, { useState, useEffect } from "react";

import "./login.scss";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { authCheck, farmersLogin, getSiteData } from "../../services/authService";
import { toast } from 'react-toastify';
import { useParams ,useNavigate } from "react-router-dom";
import { EncryptOrDecrypt,checkPermission,setSiteDataInLocalStorage } from "../../services/commonService";
import Moment from "moment";
import { getCorePolicyData } from "../../services/coreSystemService";
import { resetCoreStore, resetFloodStore } from "../../store/actions";

const FarmersLogin = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    //const token = localStorage.getItem('access_token') || '';
    let params = useParams();

    useEffect(() => {
        localStorage.clear();
        // tokenLogin();
        callGetSiteData();
        /*if (token.length > 0) {
            window.location = '/';
        }*/
    }, []);

    const callGetSiteData = async () => {
        setLoading(true);
        await dispatch(getSiteData()).then((res) => {
            setSiteDataInLocalStorage(res.data.data);
            setLoading(false);
            tokenLogin();
        });
    };

    const tokenLogin = async () => {
        setLoading(true);
        const request = {
            'userToken': params.userToken
        };
        await dispatch(farmersLogin(request)).then(async (response) => {
            var EncryptUserData = '';
            if (response.success === true) {
                if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                    localStorage.setItem('access_token', response.data.access_token);
                    var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                    localStorage.setItem('access_token_expires_at', tokenExpireAt);
                    EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                    localStorage.setItem('user', EncryptUserData);
                    await dispatch(authCheck());
                    
                    if(response.data.extraDetails.accessRights.modules.includes('AGENTPORTAL')){
					await	dispatch(checkPermission('AGENTPORTAL')).then((ckresult)=>{
						});
					}
                   
                    var transPK = params.transPK ? params.transPK : 0; 
                    var policyNo = params.policyNo ? params.policyNo : 0;
                    if(policyNo != 0){
                        if(policyNo == 'CreateQuoteApp'){
                            navigation(`/flood/instantquote/step/address`);
                        } else {
                            await dispatch(getCorePolicyData(policyNo)).then(async (res) => {
                                // reset reducer state
                                dispatch(resetCoreStore());
                                dispatch(resetFloodStore());
                                if (res.status) {
                                    var transCycleCode = res.transCycleCode;
                                    var transPk = res.agentTransPK;
                                    var prTranTypeCode = res.prTranTypeCode;
                                    var transactionCycleCode = res.transactionCycleCode;
                                    if(transCycleCode == 'ISSUED' || (prTranTypeCode == 'NEWBUSINESS' && transactionCycleCode == 'QUOTE' && res.source == 'NFS')){
                                        navigation(`/core/policy/${policyNo}`);
                                        window.location.reload();
                                    }else{
                                        navigation(`/flood/quote/${transPk}`);
                                        window.location.reload();
                                    }
                                } else {
                                    toast.error(res.message);
                                    window.location = '/';
                                }
                            })
                        }
                    }else if(transPK != 0){
                        if(transPK == 'CreateQuoteApp'){
                            navigation(`/flood/instantquote/step/address`);
                        } else {
                            navigation(`/flood/quote/${transPK}`);
                            window.location.reload(); 
                        }
                    }else{
                        // redirect to the policy search
                        window.location = '/';
                    }
                } else {
                    toast.error(response.data.errors);
                    window.location = '/';
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t('Please Try again'));
        });

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim(); 
        return Moment(new Date(date)).format();
    }

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
        </LoadingOverlay>
    );
};

export default FarmersLogin;