import React, { Fragment, useState, useEffect } from "react";
// import {
//   Row,
//   Col,
//   Table
// } from "react-bootstrap";
import { Table, Thead, Tr, Th, Tbody, Td, Text } from "@chakra-ui/react";
import { TableContainer } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { getAssociateAgencyList } from "../../services/profileService.js";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { EncryptOrDecrypt } from "../../services/commonService";
import { useTranslation } from "react-i18next";

const AssociateAgency = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [associateAgencyList, setAssociateAgencyList] = useState({});
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    getAssociateAgencyListData();
    //eslint-disable-next-line
  }, []);

  const getAssociateAgencyListData = async () => {
        setLoading(true);
		const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
		const params= {
			producerPersonId: userDatas.n_PersonInfoId_FK,
			producerUPN:''
		};
        await dispatch(getAssociateAgencyList(params)).then((res) => { 
			setAssociateAgencyList(res.data);
			setLoading(false);
        });
  };


  return (
    <Fragment>
       <LoadingOverlay>
        <Loader loading={loading} />
        <div>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th border={"1px solid"} borderColor={"#ccc"} py={"8px"}>
                    <Text mb={0} fontWeight={600} fontSize={"16px"}>
                      {t("AGENCY CODE")}
                    </Text>
                  </Th>
                  <Th border={"1px solid"} borderColor={"#ccc"} py={"8px"}>
                    <Text mb={0} fontWeight={600} fontSize={"16px"}>
                      {t("AGENT WYO CODE")}
                    </Text>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(associateAgencyList).length === 0 && (
                  <Tr>
                    <Td
                      border={"1px solid"}
                      borderColor={"#ccc"}
                      py={"8px"}
                      colSpan={2}
                      textAlign={"center"}
                    >
                      {t("No Data")}
                    </Td>
                  </Tr>
                )}
                {Object.keys(associateAgencyList).length > 0 &&
                  associateAgencyList.map((data) => {
                    return (
                      <>
                        <Tr>
                          <Td
                            border={"1px solid"}
                            borderColor={"#ccc"}
                            py={"8px"}
                          >
                            {data.agency_wyo_code}
                          </Td>
                          <Td
                            border={"1px solid"}
                            borderColor={"#ccc"}
                            py={"8px"}
                          >
                            {data.agent_wyo_code}
                          </Td>
                        </Tr>
                      </>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
       </LoadingOverlay>
     </Fragment>
  );
};

export default AssociateAgency;
