import React from "react";
import PrivateFloodPlan from "../private-flood-plan";

const FloodPricingPlan = ({handleChange,setzDDetails}) => {
  return (
    <>
      {/*<NFIPPlan />*/}
      <PrivateFloodPlan handleChange={handleChange} setzDDetails={setzDDetails}/>
    </>
  );
};

export default FloodPricingPlan;
