import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";

const AdditionalInformation = (props) => {
    const { floodSystemDD, RC, applicationData, handleChange, propertyInfo, IsPrimaryResidence } = props;
    const { t } = useTranslation();

    const [replacementCost, setReplacementCost] = useState(0);
    const [billTo, setBillTo] = useState('');
    const [isRCSet, setIsRCSet] = useState(false);
    const [isPrimaryRes, setIsPrimaryRes] = useState("");

    useEffect(() => {
        if (applicationData && Object.keys(applicationData).length > 0 && applicationData.billPay && Object.keys(applicationData.billPay).length > 0) {
            setBillTo(applicationData.billPay.s_BillToType);
        }
        if (RC) {
            setReplacementCost(RC)
        }
    }, [RC])

    useEffect(() => {
        if (propertyInfo && Object.keys(propertyInfo).length > 0) {
            setIsPrimaryRes(propertyInfo.RA.s_InsuredResides);
        }
    }, [propertyInfo])

    const handleStateChange = (value, state) => {
        switch (state) {
            case 'replacementCost':
                if (!value) {
                    value = 0;
                }
                setReplacementCost(value);
                if (!isRCSet) {
                    setIsRCSet(true);
                } else {
                    handleChange("replacementCost", RC, value, "AdditionalInformation");
                }
                break;
            case 's_BillTo':
                setBillTo(value);
                handleChange("billTo", billTo, value, "AdditionalInformation");
                break;
            case 's_InsuredResides':
                handleChange("s_InsuredResides", isPrimaryRes, value, "AdditionalInformation");
                IsPrimaryResidence(value);
                setIsPrimaryRes(value);
                break;
        }
    }

    return (
        <Row>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Label className="dashboard-group-label">
                    {t('Replacement Cost')}
                </Form.Label>
                <NumberFormat
                    className="dashboard-group-input-flood"
                    style={{ width: '100%', textAlign: "right" }}
                    type="text"
                    displayType={'input'}
                    prefix={'$'}
                    thousandSeparator={true}
                    allowNegative={false}
                    name="replacementCost"
                    value={replacementCost || 0}
                    min={0}
                    onValueChange={(value) => handleStateChange(value.floatValue, 'replacementCost')}
                    autoComplete="off"
                />
            </Col>

            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Label className="dashboard-group-label">
                    {t('Renewal Bills sent to')}
                </Form.Label>
                <Form.Select
                    className="myform-input"
                    id="flood-pricing-plan-select"
                    name="s_BillTo"
                    parentobject="policyInfo"
                    title={t('Bill to')}
                    value={billTo}
                    onChange={(e) => {
                        setBillTo(e.currentTarget.value);
                        handleStateChange(e.target.value, 's_BillTo')
                    }}
                    required
                >
                    <option value=''>{t('Select')}</option>
                    {
                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.BILLTOFLOOD.map((v, i) => {
                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                        })
                    }
                </Form.Select>
            </Col>

            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Label className="dashboard-group-label">
                    {t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}?
                </Form.Label>
                <Form.Select
                    className="myform-input"
                    id="flood-pricing-plan-select"
                    name="s_InsuredResides"
                    value={isPrimaryRes}
                    onChange={(e) => {
                        handleStateChange(e.target.value, 's_InsuredResides')
                    }}
                    required
                    disabled
                >
                    <option value=''>{t('Select')}</option>
                    {
                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                        })
                    }
                </Form.Select>
            </Col>
        </Row>

    )
}

export default AdditionalInformation;
