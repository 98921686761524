import React from "react";
import { Table, Button, Form } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const PriorLossesTable = ({ requestBody, setRequestBody }) => {
  const handleAddRow = () => {
    const newRow = { year: 0, value: 0 };
    setRequestBody({
      ...requestBody,
      priorLosses: [...requestBody.priorLosses, newRow],
    });
  };

  const handleRemoveRow = (indexToRemove) => {
    const updatedPriorLosses = requestBody.priorLosses.filter(
      (_, index) => index !== indexToRemove
    );
    setRequestBody({
      ...requestBody,
      priorLosses: updatedPriorLosses,
    });
  };

  const handleChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = requestBody.priorLosses.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, [field]: parsedValue };
      }
      return row;
    });

    setRequestBody({
      ...requestBody,
      priorLosses: updatedPriorLosses,
    });
  };

  return (
    <>
      <div>
        <h6 style={{ display: "flex", justifyContent: "center" }}>
          Prior Losses
        </h6>
        <Table striped bordered hover style={{ width: "100%" }}>
          <thead>
            <tr className="text-center align-middle">
              <th>Loss Year</th>
              <th>Value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(requestBody?.priorLosses) &&
              requestBody?.priorLosses?.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      type="number"
                      min={0}
                      max={9999}
                      value={row.year}
                      onChange={(e) =>
                        handleChange("year", Number(e.target.value), index)
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      min={0}
                      type="number"
                      value={row.value}
                      onChange={(e) =>
                        handleChange("value", Number(e.target.value), index)
                      }
                    />
                  </td>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "1rem",
                    }}
                  >
                    <Button
                      size="sm"
                      variant="danger"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <MdDelete  />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <Button size="sm" onClick={handleAddRow} id="primary-colored-btn">
          Add
        </Button>
      </div>
    </>
  );
};

export default PriorLossesTable;
