import React,{ useState,useEffect } from "react";
import { Row,Alert} from "react-bootstrap";
import {getInForcePolicies,getTransactionCounts,getUpcomingRenewalData,getTotalClaims,getTotalClaimReserve,getTotalClaimPayment,pieChartData,graphData,getDashboardNotification} from '../../../services/dashboardService'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser'

const Resources = () => {
	const dispatch = useDispatch();
	const [topLayoutData, setTopLayoutData] = useState([])
	const [loading, setLoading] = useState(false);
	var layoutData=[];

	useEffect(() => {
		getDashboardNotificationData();
	}, [])
	
	const getDashboardNotificationData = async () =>{
		setLoading(true)
		const getData =  await dispatch(getDashboardNotification('Agent - Resources')).then((res)=>{
			setLoading(false)
			var delayTime=2000;
			res.data.map((e,i)=>{            
					let pop = JSON.parse(e.layout_type)
					  if(pop.popout=="POPUP"){
						toast.success(e.s_message_body.replace(/<[^>]+>/g, ' '),{position:toast.POSITION.TOP_RIGHT,delay:delayTime});
					}else if(pop.top_layout=='TOP LAYOUT'){
					  setTopLayoutData(prevArray=>([...prevArray,e]))
					}
				  delayTime=delayTime+5000;
			})
		});
	}

    return(
        <>
		<div className="search-policy-section">
		<LoadingOverlay>
    		<Loader loading={loading} />
			<Row>
        {
            topLayoutData.length>0?
              topLayoutData.map((e,i)=>{
                    return(
                      <TopLayoutView mesgBody={e.s_message_body}/>
                    )
              })
            :''
        }
      </Row>
		</LoadingOverlay>
	            <div className="search-policy-filters">
	                <h2>Resources</h2>
	                <Row style={{'paddingLeft' : '3%', 'paddingTop' : '1%' }}>
	                    <div className="report-select-div">
	                        <p className="dashboard-group-label">
	                            Helpful Links
	                        </p>
	                    </div>
	                </Row>
	                <Row style={{'paddingLeft' : '5%'}}>
	                        <a href="https://www.fema.gov/flood-insurance/work-with-nfip/manuals/current" target="_blank"> Flood Manual</a>
	                </Row>
	                <Row style={{'paddingLeft' : '3%', 'paddingTop' : '2%' }}>
	                    <div className="report-select-div">
	                        <p className="dashboard-group-label">
	                            Agent Training Material
	                        </p>
	                    </div>
	                </Row>
	                <Row style={{'paddingLeft' : '5%'}}>
	                        <p>Flood Facts</p>
	                </Row>
	                <Row style={{'paddingLeft' : '3%', 'paddingTop' : '2%' }}>
	                    <div className="report-select-div">
	                        <p className="dashboard-group-label">
	                            Webinar Schedule
	                        </p>
	                    </div>
	                </Row>
	                <Row style={{'paddingLeft' : '5%'}}>
	                        <p>Elevation certificate basics - Tuesday, October 18, 2022</p>
	                        <p>Flood after Fire - Thursday, October 27, 2022</p>
	                </Row>
	            </div>
	        </div>	
					
		</>
    )
}

const TopLayoutView = (props)=>{
	const [showLayoutView, setShowLayoutView] = useState(true)
	return (
		<>
			{
				showLayoutView ? (<Alert variant="success" onClose={() => setShowLayoutView(false)} dismissible>
					<p>{parse(props.mesgBody)}</p>
				</Alert>) : ''
			}
		</>
	)
}

export default Resources;