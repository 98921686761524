import {
  Box,
  FormControl,
  Text,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Tooltip,
  Flex,
} from "@chakra-ui/react";

import { useStarDataContext } from "../../StarDataContext";
import { useState } from "react";
import { FcInfo } from "react-icons/fc";

export default function RoofProperties() {
  const { baseDD, roofProperties, setRoofProperties, validationError } =
    useStarDataContext();

  // const [roofPartialComplete] = useState(baseDD?.STARROOFPARTIALCOMPLETE ?? []);
  const [roofMaterial] = useState(baseDD?.STARROOFMATERIAL ?? []);
  // const [roofAnchor] = useState(baseDD?.STARROOFANCHOR ?? []);
  // const [roofShape] = useState(baseDD?.STARROOFSHAPE ?? []);
  // const [roofDeckAttachment] = useState(baseDD?.STARROOFDECKATTACHMENT ?? []);
  // const [roofSecondaryWater] = useState(
  //   baseDD?.STARSECONDARYWATERRESISTANCE ?? []
  // );

  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear.toString();
  };

  return (
    <>
      <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6}>
        <Box
          background="var(--chakra-colors-primary-50)"
          p={"0.1rem"}
          borderRadius={"xl"}
        >
          <Text
            mt={3}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-semibold)"
            color="black"
            fontSize="1.5rem"
          >
            Roof Properties
          </Text>
        </Box>
        <Box w="100%" p={4}>
          <Grid
            w="100%"
            templateColumns={
              roofProperties?.roofUpdated === "true" ||
              roofProperties?.roofUpdated === true
                ? "repeat(4, 1fr)"
                : "repeat(2, 1fr)"
            }
            gap={4}
            pt={2}
            pb={2}
          >
            <GridItem>
              <FormControl isRequired>
                <Flex alignItems={"center"} gap={0}>
                  <FormLabel fontWeight={500} color="black">
                    Roof Age:
                  </FormLabel>
                  <Tooltip
                    placement="top"
                    hasArrow
                    p={3}
                    label="Roof age is calculated based on the year built."
                    aria-label="Roof Age Tooltip"
                  >
                    <Box mb={2} ml={"-5px"}>
                      {" "}
                      <FcInfo size={20} />
                    </Box>
                  </Tooltip>
                </Flex>
                <Input
                  // disabled={
                  //   !roofProperties?.roofUpdated ||
                  //   roofProperties?.roofUpdated === "false"
                  // }
                  className="inputBorder"
                  value={roofProperties?.roofAge || ""}
                  placeholder="Years"
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      setRoofProperties({
                        ...roofProperties,
                        roofAge: e.target.value,
                      });
                    }
                  }}
                  // bg={
                  //   !roofProperties?.roofUpdated ||
                  //   roofProperties?.roofUpdated === "false"
                  //     ? "gray.100"
                  //     : "white"
                  // }
                  _disabled={{ opacity: 1 }}
                  type="number"
                  size={"lg"}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                />
                {/* {roofProperties?.roofAge && typeof roofProperties?.roofAge === "string" &&
                roofProperties?.roofAge === "" && (
                  <Text color={"red"}>
                    {"is a Month in Number"}
                  </Text>
                )} */}
                {(validationError.underWritingPage &&
                  !roofProperties?.roofAge &&
                  roofProperties?.roofAge <= 0 && (
                    <Text color={"red"}>
                      {" Roof Age must be greater than 0 Years"}
                    </Text>
                  )) ||
                  (roofProperties?.roofAge >= 200 && (
                    <Text color={"red"}>
                      {"Roof Age must be less than or equal to 200 Years"}
                    </Text>
                  ))}
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  Roof Updated:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofUpdated}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofUpdated: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {validationError.underWritingPage &&
                  roofProperties?.roofUpdated === "" && (
                    <Text color={"red"}>{"Roof Updated is required"}.</Text>
                  )}
              </FormControl>
            </GridItem> */}
            {(roofProperties?.roofUpdated === "true" ||
              roofProperties?.roofUpdated === true) && (
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500} color="black">
                    Updated Year:{" "}
                  </FormLabel>
                  <Input
                    value={roofProperties?.roofUpdatedYear || ""}
                    onChange={(e) => {
                      if (e.target.value.length <= 4) {
                        setRoofProperties({
                          ...roofProperties,
                          roofUpdatedYear: e.target.value,
                        });
                      }
                    }}
                    type="number"
                    placeholder="YYYY"
                    className="inputBorder"
                    size={"lg"}
                    onWheel={(e) => {
                      e.target.blur();
                    }}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  {(roofProperties?.roofUpdatedYear === "" ||
                    roofProperties?.roofUpdatedYear <= 0) && (
                    <Text color={"red"}>{"Updated Year is a required"}</Text>
                  )}
                  {roofProperties?.roofUpdatedYear === null ||
                    roofProperties?.roofUpdatedYear === undefined ||
                    ((roofProperties?.roofUpdatedYear < 1970 ||
                      roofProperties?.roofUpdatedYear >
                        parseInt(getCurrentYear())) && (
                      <Text color={"red"}>
                        {`is a Year in Number between 1970 - ${getCurrentYear()}`}
                      </Text>
                    ))}
                </FormControl>
              </GridItem>
            )}
            {/* <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Roof Completion:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofCompletion || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofCompletion: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofPartialComplete?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {roofProperties?.roofCompletion === "" && (
                  <Text color={"red"}>Required.</Text>
                )}
              </FormControl>
            </GridItem> */}
            <GridItem>
              <FormControl isRequired>
                <FormLabel fontWeight={500} color="black">
                  Roof Material:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofMaterialUsed || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofMaterialUsed: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofMaterial?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}{" "}
                    </option>
                  ))}
                </Select>
                {validationError.underWritingPage &&
                  !roofProperties?.roofMaterialUsed && (
                    <Text color={"red"}>{"Please select a Roof Material."}</Text>
                  )}
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Shape of Roof:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofWhatShape || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofWhatShape: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofShape?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {roofProperties?.roofWhatShape === "" && (
                  <Text color={"red"}>{"Please Select an Option"}</Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Roof Modifiers Used?:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofModifier}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofModifier: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Select>
                {roofProperties?.roofModifier === "" && (
                  <Text color={"red"}>{"Please Select an Option"}</Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Roof Anchor:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofAnchorUsed || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofAnchorUsed: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofAnchor?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {roofProperties?.roofAnchorUsed === "" && (
                  <Text color={"red"}>{"Please Select an Option"}</Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Deck Attachments:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofDeckAttachments || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofDeckAttachments: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofDeckAttachment?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {roofProperties?.roofDeckAttachments === "" && (
                  <Text color={"red"}>{"Please Select an Option"}</Text>
                )}
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel fontWeight={500} color="black">
                  Secondary Water Resistance:{" "}
                </FormLabel>
                <Select
                  value={roofProperties?.roofSecondaryWaterResistance || ""}
                  onChange={(e) => {
                    setRoofProperties({
                      ...roofProperties,
                      roofSecondaryWaterResistance: e.target.value,
                    });
                  }}
                  className="inputBorder"
                  size={"lg"}
                >
                  <option value=""></option>
                  {roofSecondaryWater?.map((item) => (
                    <option
                      key={item?.s_AppCodeName}
                      value={item?.s_AppCodeName}
                    >
                      {item?.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
                {roofProperties?.roofSecondaryWaterResistance === "" && (
                  <Text color={"red"}>{"Please Select an Option"}</Text>
                )}
              </FormControl>
            </GridItem> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
