export const ResidentialDataDD = {
  OccupancyType: [
    {
      label: "Primary",
      value: "Primary",
    },
    {
      label: "Secondary",
      value: "Secondary",
    },
    {
      label: "Seasonal",
      value: "Seasonal",
    },
    {
      label: "Tenanted",
      value: "Tenanted",
    },
    {
      label: "Vacant",
      value: "Vacant",
    },
    {
      label: "Course Of Construction",
      value: "CourseOfConstruction",
    },
    {
      label: "Vacant Renovation",
      value: "VacantRenovation",
    },
  ],
  ConstructionType: [
    {
      label: "Brick Veneer",
      value: "BrickVeneer",
    },
    {
      label: "EIFS",
      value: "EIFS",
    },
    {
      label: "Frame",
      value: "Frame",
    },
    {
      label: "Log",
      value: "Log",
    },
    {
      label: "Masonry",
      value: "Masonry",
    },
    {
      label: "Stucco",
      value: "Stucco",
    },
    {
      label: "Asbestos",
      value: "Asbestos",
    },
  ],
  ContentsCostValueType: [
    {
      label: "Replacement Cost Value",
      value: "ReplacementCostValue",
    },
    {
      label: "Actual Cash Value",
      value: "ActualCashValue",
    },
  ],
  FoundationType: [
    {
      label: "Piers, Posts, Pilings",
      value: "PiersPostsPilings",
    },
    {
      label: "Reinforced Shear Walls",
      value: "ReinforcedShearWalls",
    },
    {
      label: "Solid Foundation Walls",
      value: "SolidFoundationWalls",
    },
    {
      label: "Foundation Wall",
      value: "FoundationWall",
    },
    {
      label: "Slab On Fill",
      value: "SlabOnFill",
    },
    {
      label: "Slab On Grade",
      value: "SlabOnGrade",
    },
  ],
  AdditionalFoundationType: [
    {
      label: "None",
      value: "None",
    },
    {
      label: "Finished Enclosure Full",
      value: "FinishedEnclosureFull",
    },
    {
      label: "Finished Enclosure Partial",
      value: "FinishedEnclosurePartial",
    },
    {
      label: "Unfinished Enclosure Full",
      value: "UnfinishedEnclosureFull",
    },
    {
      label: "Unfinished Enclosure Partial",
      value: "UnfinishedEnclosurePartial",
    },
    {
      label: "Finished Crawlspace",
      value: "FinishedCrawlspace",
    },
    {
      label: "Unfinished Crawlspace",
      value: "UnfinishedCrawlspace",
    },
  ],
  BasementType: [
    {
      label: "Finished",
      value: "Finished",
    },
    {
      label: "Unfinished",
      value: "Unfinished",
    },
    {
      label: "None",
      value: "None",
    },
  ],
  AttachedGarageType: [
    {
      value: "None",
      label: "None",
    },
    {
      value: "Finished",
      label: "Finished",
    },
    {
      value: "Unfinished",
      label: "Unfinished",
    },
  ],
  BuildingOverWaterType: [
    {
      value: "No",
      label: "Not Over Water",
    },
    {
      value: "Entirely",
      label: "Entirely",
    },
    {
      value: "Partially",
      label: "Partially",
    },
  ],
  BindPolicyType: [
    {
      value: "Primary",
      label: "Primary",
    },
    {
      value: "Excess",
      label: "Excess",
    },
  ],
};
