import { Box, Card } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Quote from "./Tabs/Quote";
import { starCommercialContext } from "./CommercialContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const StarTabs = () => {
  const { isQuoteSaved } = useContext(starCommercialContext);
  const { t } = useTranslation();

  const handleDisabledTabClick = () => {
    if (!isQuoteSaved) {
      toast.error("Please Save Quote", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box>
      <Card
        borderRadius="2xl"
        mb={4}
        p={2}
        boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
      >
        <Tabs size={"md"} variant="enclosed" p={2}>
          <TabList>
            <Tab
              ml={2}
              _selected={{
                bgColor: "var(--chakra-colors-primary-500)",
                color: "white",
              }}
            >
              {t("Quote")}
            </Tab>
            <Tab
              _selected={{
                bgColor: "var(--chakra-colors-primary-500)",
                color: "white",
              }}
              isDisabled={!isQuoteSaved}
              onClick={handleDisabledTabClick}
            >
              {t("Submit")}
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Quote />
            </TabPanel>
            <TabPanel></TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
};

export default StarTabs;
