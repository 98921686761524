import React, { useState } from 'react';
import { Row, Col, Form, Button, Modal } from "react-bootstrap";

const UwAppStatus = (props) => {
    const [isShow, setIsShow] = useState(true);

  return (
    <Modal
        className="custom-dashboard-modal"
        size="md"
        show={isShow}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>App UW. Status Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-dashboard-modal-body">
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  Select Reason
                </Form.Label>
                <Form.Select
                  className="dashboard-group-input"
                  id="select-ym-option"
                >
                  <option>App UW. Status Information</option>
                  <option>Open</option>
                  <option>Closed</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">Note</Form.Label>
                <Form.Control
                  className="dashboard-group-input"
                  as="textarea"
                  placeholder="Type here"
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <div className="d-grid gap-2">
                <Button
                  id="common-btn"
                  variant="primary"
                  style={{ padding: "12px 42px" }}
                >
                  Update Status
                </Button>
                <Button
                  onClick={() => {setIsShow(false); props.toggle()}}
                  id="common-cancel-btn"
                  variant="primary"
                  style={{ padding: "12px 42px" }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
  )
}

export default UwAppStatus