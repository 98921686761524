import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Select,
  Switch,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import { postDeletePolicyMortagee } from "../api/DeleteMortg";
import { toast } from "react-toastify";

const Interest = ({
  handleInterestChange,
  confirmation,
  setConfirmation,
  updateAdditionalInsuredData,
  quoteData,
  isBind,
}) => {
  const { t } = useTranslation();
  const [zipData, setZipData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [zipUpdated, setZipUpdated] = useState(false);
  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    confirmation.additionalInterests[0]?.address?.s_PostalCode,
    confirmation.additionalInterestsFlag && zipUpdated
  );
  const additionalInterestsFields = {
    n_PolicyAddInterest_PK: null,
    n_POTransaction_FK: null,
    n_PersonInfoId_PK: null,
    s_PersonType: "ADDITIONALINTEREST",
    s_MortgageeType: null,
    s_LoanNo: null,
    s_AddionalIntType: null,
    s_RelationType: null,
    s_HouseholdType: null,
    s_isDeleted: null,
    n_CorePersonPK: null,
    n_CorePersonAddressPK: null,
    n_CreatedUser: null,
    d_CreatedDate: null,
    n_UpdatedUser: null,
    d_UpdatedDate: null,
    person: {
      n_PersonInfoId_PK: null,
      s_PersonUniqueId: null,
      s_EntityType: "PERSON",
      s_IsMailingDiff: null,
      s_FirstName: null,
      s_MiddleName: null,
      s_LastOrganizationName: null,
      s_FullLegalName: "",
      s_PayeeName: null,
      d_BirthDate: null,
      d_DeathDate: null,
      s_MaritalStatusCode: null,
      s_Occupation: null,
      s_1099Flag: null,
      s_LegacyId: null,
      s_IsTempMailing: null,
      metadata: null,
      n_CreatedUser: null,
      d_CreatedDate: null,
      n_UpdatedUser: null,
      d_UpdatedDate: null,
      n_EditVersion: null,
    },
    address: {
      n_PersonAddressesId_PK: 0,
      n_PersonInfoId_PK: null,
      s_AddressTypeCode: "MAILING",
      s_IsDefaultAddress: null,
      s_AddressNickName: null,
      s_HouseNo: null,
      s_HouseDirection1: null,
      s_StreetName: null,
      s_HouseType: null,
      s_HouseDirection2: null,
      s_AddressLine1: "",
      s_AddressLine2: null,
      s_AddressLine3: null,
      s_AddressLine4: null,
      n_CountyId_FK: null,
      n_CityId_FK: null,
      n_StateId_FK: null,
      n_CountryId_FK: null,
      s_PostalCode: null,
      s_PostalCodeSuffix: null,
      s_Latitude: null,
      s_Longitude: null,
      s_CountryName: null,
      s_StateName: null,
      s_CountyName: null,
      s_CityName: null,
      s_UnitNo: null,
      s_ParcelId: null,
      s_countyFips: null,
      s_stateFips: null,
      n_CreatedUser: null,
      d_CreatedDate: null,
      n_UpdatedUser: null,
      d_UpdatedDate: null,
      n_EditVersion: null,
      php_session_id: null,
    },
    communication: [],
  };

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData && zipData?.s_CityName) {
      const newAdditionalInterests = [
        {
          ...confirmation.additionalInterests[0],
          address: {
            ...confirmation.additionalInterests[0].address,
            s_StateName: zipData?.s_StateCode,
            s_CityName: zipData?.s_CityName,
            n_CityId_FK: zipData?.city_id ?? 0,
            n_StateId_FK: zipData?.state_id ?? 0,
            n_CountryId_FK: zipData?.n_CountryId_FK ?? 1,
            n_CountyId_FK: zipData?.county_id ?? 0,
            s_CountyName: zipData?.s_CountyName ?? "",
          },
        },
        ...confirmation.additionalInterests.slice(1),
      ];
      setConfirmation((prev) => ({
        ...prev,
        additionalInterests: newAdditionalInterests,
      }));
      updateAdditionalInsuredData(
        confirmation.additionalInsured,
        newAdditionalInterests
      );
      setZipUpdated(false);
    }
  }, [zipData]);

  // const handleAddRow = () => {
  //   if (additionalInterests.length >= 2) {
  //     toast.warn("Currently Limited to 2 Additional Interests.");
  //   } else {
  //     setConfirmation((prev) => ({
  //       ...prev,
  //       additionalInterests: [
  //         ...additionalInterests,
  //       ],
  //     }));
  //   }
  // };

  const deleteMortagee = async (mortgagee) => {
    // console.log(mortgagee);
    // return;
    try {
      // setLoading(true);
      if (
        mortgagee?.n_PolicyAddInterest_PK === null ||
        mortgagee?.n_PolicyAddInterest_PK === undefined
      ) {
        toast.success(
          t("Additional Interest Deleted Successfully", { autoClose: 1500 })
        );
      } else {
        const reqBody = {
          transPK: quoteData?.transactionInfo?.n_potransaction_PK,
          n_PolicyAddInterest_PK: mortgagee?.n_PolicyAddInterest_PK,
        };
        const response = await postDeletePolicyMortagee(reqBody);
        if (response.success && response.data.Status === "Y") {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          toast.success(
            t("Additional Interest Deleted Successfully", { autoClose: 1500 })
          );
        }
      }
    } catch (error) {
      console.error("Error deleting mortgagee:", error);
      toast.error(t("Error deleting mortgagee. Please try again."));
    } finally {
      // setLoading(false);
    }
  };

  const handleRemoveRow = (index) => {
    if (confirmation.additionalInterests.length > 1) {
      setConfirmation((prev) => ({
        ...prev,
        additionalInterests: prev.additionalInterests.filter(
          (_, i) => i !== index
        ),
      }));
      updateAdditionalInsuredData(
        confirmation.additionalInsured,
        confirmation.additionalInterests.filter((_, i) => i !== index)
      );
    } else {
      setConfirmation((prev) => ({
        ...prev,
        additionalInterestsFlag: false,
        additionalInterests: [],
      }));
      updateAdditionalInsuredData(confirmation.additionalInsured, []);
    }
  };

  const handleSwitchChange = () => {
    let newAdditionalInterests = [];
    if (!confirmation.additionalInterestsFlag) {
      newAdditionalInterests = [additionalInterestsFields];
    }
    setConfirmation((prev) => ({
      ...prev,
      additionalInterestsFlag: !prev.additionalInterestsFlag,
      additionalInterests: newAdditionalInterests,
    }));
    updateAdditionalInsuredData(
      confirmation.additionalInsured,
      newAdditionalInterests
    );
  };

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Flex
        background="var(--chakra-colors-primary-50)"
        borderRadius="xl"
        p="0.1rem"
        mb="20px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text
          fontSize="xl"
          mt={3}
          pl="1.5rem"
          fontWeight="var(--chakra-fontWeights-medium)"
        >
          {t("additional_interests")}
        </Text>
        <Box mr={2}>
          <Switch
            isDisabled={isBind}
            isChecked={confirmation.additionalInterestsFlag}
            onChange={handleSwitchChange}
            size="lg"
          />
        </Box>
      </Flex>
      {confirmation?.additionalInterestsFlag && (
        <Box>
          {confirmation?.additionalInterests.map((row, index) => (
            <Box key={index} pl={5} pr={5}>
              <Card
                border="1px solid"
                borderColor="neutral.100"
                boxShadow={0}
                borderRadius="xl"
                mb={4}
              >
                <CardHeader
                  borderBottom="1px solid var(--chakra-colors-neutral-100)"
                  p={0}
                  borderTopRadius="inherit"
                >
                  <Flex
                    gap={2}
                    alignItems={"center"}
                    p={4}
                    borderTopRadius="inherit"
                    bg="var(--chakra-colors-primary-50)"
                    justifyContent="space-between"
                  >
                    <Flex ml={2} alignItems={"center"} gap={3}>
                      <Text mb={0} fontSize="lg" fontWeight={400}>
                        {t("additional_interests")}{" "}
                        {isLoading && (
                          <Spinner size={"md"} color="primary.500" />
                        )}
                      </Text>
                    </Flex>
                    <Box mr={2}>
                      <RiDeleteBin2Line
                        onClick={() => {
                          handleRemoveRow(index);
                          deleteMortagee(row);
                        }}
                        size="25px"
                      />
                    </Box>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Grid w="100%" templateColumns="repeat(3, 1fr)" gap={6} p={2}>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("Type")}:{" "}
                        </FormLabel>
                        <Select
                          isDisabled={isBind}
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          size={"lg"}
                          name="s_AddionalIntType"
                          value={row?.s_AddionalIntType}
                          onChange={(e) => handleInterestChange(e, index)}
                        >
                          <option value=""></option>
                          <option value="Property_Manager">
                            Property Manager
                          </option>
                          <option value="Others">Others</option>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem w="100%">
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("First Name")}:{" "}
                        </FormLabel>
                        <Input
                          isDisabled={isBind}
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          name="s_FirstName"
                          title="person"
                          type="text"
                          size="lg"
                          focusBorderColor="secondary.500"
                          value={row?.person?.s_FirstName}
                          onChange={(e) => handleInterestChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired>
                        <FormLabel pl={1} fontWeight={400}>
                          {" "}
                          {t("Last Name")}:{" "}
                        </FormLabel>
                        <Input
                          isDisabled={isBind}
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          borderRadius={"xl"}
                          name="s_LastOrganizationName"
                          title="person"
                          type="text"
                          size={"lg"}
                          focusBorderColor="secondary.500"
                          value={row?.person?.s_LastOrganizationName}
                          onChange={(e) => handleInterestChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <Grid p={2} templateColumns="repeat(4, 1fr)" gap={6}>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          Address
                        </FormLabel>
                        <Input
                          isDisabled={isBind}
                          name="s_AddressLine1"
                          title="address"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.address?.s_AddressLine1}
                          onChange={(e) => handleInterestChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          City
                        </FormLabel>
                        <Input
                          type="text"
                          name="s_CityName"
                          title="address"
                          isDisabled
                          _disabled={{ opacity: 1 }}
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.address?.s_CityName || ""}
                          onChange={(e) => handleInterestChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel fontWeight={"400"}>
                          State / State Code
                        </FormLabel>
                        <Input
                          isDisabled={isBind}
                          bg={"gray.100"}
                          cursor={"not-allowed"}
                          _disabled={{ opacity: 1 }}
                          name="s_StateName"
                          title="address"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.address?.s_StateName}
                          onChange={(e) => handleInterestChange(e, index)}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <FormLabel htmlFor="" fontWeight={"400"}>
                          Zip Code
                        </FormLabel>
                        <Input
                          isDisabled={isBind}
                          name="s_PostalCode"
                          title="address"
                          type="number"
                          className="inputBorder"
                          border={"2px solid"}
                          borderColor={"neutral.500"}
                          size={"lg"}
                          borderRadius={"xl"}
                          value={row?.address?.s_PostalCode}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-"].includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(e) => {
                            e.target.blur();
                          }}
                          onChange={(e) => {
                            if (e.target.value.length <= 5) {
                              handleInterestChange(e, index);
                              setZipUpdated(true);
                            }
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default Interest;
