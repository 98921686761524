import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { excludedEmailTenant } from "./common/variables";

const Validation = ({ currentStep, toggle, verified = false, closeModal = null, saveQuoteData = null }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const emptyQuote = { ...emptyQuoteData };

  const handleNextButton = () => {
    if (Object.keys(emptyQuote).length > 0) {
      if (currentStep === 1) {
        if (([null, ''].includes(emptyQuote.propertyInfo.s_YearBuilt) || emptyQuote.propertyInfo.s_YearBuilt.length !== 4 || emptyQuote.propertyInfo.s_YearBuilt > new Date().getFullYear()) && (emptyQuote.viewType!=="MOBILEHOME")) {
          toast.error(t('Enter Valid Year built'));
          toggle(true);
        } else if (![null, ''].includes(emptyQuote.propertyInfo.s_TotalSqFootage) && ![null, ''].includes(emptyQuote.propertyInfo.s_Foundation) && ![null, ''].includes(emptyQuote.propertyInfo.s_BldgConstructionType) && ![null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) ) {
          if (['ELEWATEDONCRAWLSPACE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(emptyQuote.propertyInfo.s_Foundation) && [null, ''].includes(emptyQuote.propertyInfo.s_AreaInSqFt)) {
            toggle(true);
          } else {
            if (emptyQuote.viewType === 'MOBILEHOME' ) {
              if ([null, ''].includes(emptyQuote.propertyInfo.d_DateOfConstruction)) {
                var label = emptyQuote.propertyInfo.s_ManufactureMobileHome === 'YES' ? 'Permanent Placement' : 'Park/Subdivision was established';
                toast.error(t('Please select date of ' + label));
                toggle(true);
              }else if ([null, ''].includes(emptyQuote.propertyInfo.s_Usage)) {
                toggle(true);
                toast.error(t('Please Select Usage Type'));
              }  
              else {
                toggle(false);
                navigateStep(13);
              }
            } else if (emptyQuote.viewType === 'UNIT' ) {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits)) {
                toggle(true);
              }else if ([null, ''].includes(emptyQuote.propertyInfo.s_Usage)) {
                toggle(true);
                toast.error(t('Please Select Usage Type'));
              } 
               else if (emptyQuote.propertyInfo.n_NoOfFloors > 0 && [null, ''].includes(emptyQuote.propertyInfo.s_UnitLocatedOnFloor)) {
                toggle(true);
              } else if ([null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else if (emptyQuote.viewType === 'RESIDENTIALBLDG') {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits)) {
                toggle(true);
              } else if (emptyQuote.propertyInfo.s_NoOfUnits > 0 && [null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else if (emptyQuote.viewType === 'NONRESIDENTIALBLDG') {
              if ([null, ''].includes(emptyQuote.propertyInfo.n_NoOfFloors) || [null, ''].includes(emptyQuote.propertyInfo.s_NoOfUnits) || [null, ''].includes(emptyQuote.propertyInfo.s_BuildingUse)) {
                toggle(true);
              } else {
                toggle(false);
                navigateStep(currentStep + 1);
              }
            } else if (emptyQuote.viewType === 'HOME' ) {
              if ([null, ''].includes(emptyQuote.propertyInfo.s_Usage)) {
                toggle(true);
                toast.error(t('Please Select Usage Type'));
              } 
              else if ([null, ''].includes(emptyQuote.propertyInfo.s_Occupancy)) {
                toggle(true);
                toast.error(t('Please Select Occupancy Type'));
              } else if (emptyQuote.propertyInfo.s_Occupancy === 'TWOFOURFAMILY20' && emptyQuote.propertyInfo.s_NoOfUnits < 2) {
                toggle(true);
                toast.error(t('Number of Unit must be 2, 3 OR 4 for occupancy 2-4 Family.'));
              } else if (["SINGLEFAMILY20", "TWOFOURFAMILY20"].includes(emptyQuote.propertyInfo.s_Occupancy)) {
                let msg = '';
                if (emptyQuote.propertyInfo.s_TotalSqFootage == 0) {
                  msg = 'Please enter a building square footage';
                }
                if (emptyQuote.propertyInfo.s_TotalSqFootage > 99998) {
                  msg = 'Please enter a building square footage that is less than 99,999';
                }
                if (msg!='') {
                  toggle(true);
                  toast.error(t(msg));
                }else{
                  toggle(false);
                  navigateStep(currentStep+1)
                }
              } else if (emptyQuote.propertyInfo.s_Occupancy === 'TWOFOURFAMILY20' && emptyQuote.propertyInfo.s_NoOfUnits > 4) {
                toggle(true);
                toast.error(t('Number of Unit must be 2, 3 OR 4 for occupancy 2-4 Family.'));
              } else {
                toggle(false);
                navigateStep(currentStep + 1)
              }
            } else {
              toggle(false);
              navigateStep(currentStep + 1)
            }
          }
        } else {
          toggle(true);
        }
      } else if (currentStep === 3) {
        var tenantName = window.location.host.split('.')[0];
        var isValid = false;
        if (
          (
            (
              (emptyQuote.personInfo.s_EntityType == "INDIVIDUAL") &&
              ![null, '', undefined].includes(emptyQuote?.personInfo?.s_FirstName) &&
              ![null, '', undefined].includes(emptyQuote?.personInfo?.s_LastOrganizationName)
            ) || (
              ["BUSINESS","ASSOCIATION"].includes(emptyQuote.personInfo.s_EntityType) &&
              ![null, '', undefined].includes(emptyQuote?.personInfo?.s_LastOrganizationName)
            )
          ) &&
          ((emptyQuote.personEmailContact.s_CommValue === "" && excludedEmailTenant.includes(tenantName)) ? true : //skip email validation
           (emptyQuote.personEmailContact.s_CommValue &&
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emptyQuote.personEmailContact.s_CommValue))
          ) &&
          ![null, ''].includes(emptyQuote.personPhoneContact.s_CommValue) &&
          (
            (
              emptyQuote.propertyInfo.s_IsAddInsureds === 'YES' &&
              ![null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_FirstName) &&
              ![null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_LastOrganizationName)
            ) || (
              emptyQuote.propertyInfo.s_IsAddInsureds === 'NO' &&
              [null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_FirstName) &&
              [null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_LastOrganizationName)
            )
          )
        ) {
          var phone = emptyQuote.personPhoneContact.s_CommValue;
          if (phone.indexOf('_') === -1) {
            isValid = true;
          } else {
            toggle(true);
            toast.error(t('Please Add Mobile Number Error'));
          }
        } else {
          if ([null, ''].includes(emptyQuote?.personInfo?.s_FullLegalName?.trim()) && emptyQuote.personInfo.s_EntityType === "BUSINESS") {
            toast.error(t('Please Add Full Name Error'));
          }
          if ([null, '', undefined].includes(emptyQuote?.personInfo?.s_FirstName?.trim()) && emptyQuote.personInfo.s_EntityType === "INDIVIDUAL") {
            toast.error(t('Please Add First Name'))
          }
          if ([null, '', undefined].includes(emptyQuote?.personInfo?.s_LastOrganizationName?.trim()) && emptyQuote.personInfo.s_EntityType === "INDIVIDUAL") {
            toast.error(t('Please Add Last Name'))
          }
          if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emptyQuote.personEmailContact.s_CommValue)) && !excludedEmailTenant.includes(tenantName)) {
            toast.error(t('Enter email address'));
          }
          if (emptyQuote.propertyInfo.s_IsAddInsureds === 'YES') {
            if ([null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_FirstName.trim() || '')) {
              toast.error(t('Please enter second insured first name'));
            }
            if ([null, '', undefined].includes(emptyQuoteData?.additionaInsuredData[0]?.person?.s_LastOrganizationName.trim() || '')) {
              toast.error(t('Please enter second insured last name'));
            }
          }
          toggle(true);
        }
        if (tenantName.toLowerCase() === 'pure' && ([null].includes(emptyQuote.personInfo.metadata) || [null, '', undefined].includes(emptyQuote.personInfo.metadata.member_id))) {
          isValid = false;
          toast.error(t('Enter Member#'));
          toggle(true);
        }
        if (isValid) {
          toggle(false);
          navigateStep(currentStep + 1)
        }
      } else if (currentStep === 4) {
        var isValid = true;
        var waitingPeriod = emptyQuote.propertyInfo.s_PolicyWaitingPeriod;
        var effectiveDate = emptyQuote.effectiveDate;

        if ([null, ''].includes(waitingPeriod)) {
          isValid = false;
        }
        if ([null, ''].includes(effectiveDate)) {
          toast.error(t('Enter Effective Date'));
          isValid = false;
        }
        if (
          waitingPeriod === 'ROLLOVER_RENEWAL' &&
          (
            [null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorPolicyNo) ||
            [null, '', undefined].includes(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC) ||
            [null, '', undefined].includes(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt)
          )
        ) {
          if ([null, '', undefined].includes(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC)) {
            toast.error(t('Enter Prior Company NAIC'));
          }
          if ([null, '', undefined].includes(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt)) {
            toast.error(t('Please Enter Prior Policy Expiration Date'));
          }
          if (!emptyQuote.propertyInfo.s_PriorPolicyNo) {
            toast.error(t("Please enter Prior Policy Number"));
          } else if (emptyQuote.propertyInfo.s_PriorPolicyNo.length !== 10) {
            toast.error(t("Please enter valid prior policy number"));
          }
          isValid = false;
        }

        if (isValid === true) {
          toggle(false);
          navigateStep(currentStep + 1);
        } else {
          toggle(true);
        }
      } else if (currentStep === 5) {
        if (emptyQuote.propertyInfo.s_SubstantialImprovement === 'YES' && ((![null, ''].includes(emptyQuote.propertyInfo.d_SubstantialImprovmentDate) && (emptyQuote.propertyInfo.d_SubstantialImprovmentDate < emptyQuote.propertyInfo.d_DateOfConstruction)))) {
          toast.error(t('Original Construction Date must be less than Substantial Improvement Date'));
          toggle(true);
        } else if (emptyQuote.propertyInfo.s_SubstantialImprovement === 'YES' && [null, ''].includes(emptyQuote.propertyInfo.d_SubstantialImprovmentDate)) {
          toast.error(t('Select Substantial Improvement Date'));
          toggle(true);
        } else {
          toggle(false);
          navigateStep(currentStep + 1)
        }
      } else if (currentStep === 6) {
        // show only If usage = Primary OR tenant
        if (((emptyQuote.propertyInfo.s_Occupancy === 'RESIDENTIALUNIT20' && emptyQuote.propertyInfo.s_CondoOwnership === 'NO') || !['NONRESIDENTIALUNIT20', 'RESIDENTIALCONDOBUILD20', 'OTHERRESIDENTIAL20', 'NONRESIDENTIALBUILD20'].includes(emptyQuote.propertyInfo.s_Occupancy)) && ['PRIMARY', 'TENANT'].includes(emptyQuote.propertyInfo.s_Usage)) {
          navigateStep(currentStep + 1);
        } else {
          var step = 8;
          /*if ((emptyQuote.propertyInfo.s_PolicyWaitingPeriod === "ROLLOVER_RENEWAL") || (emptyQuote.propertyInfo.s_Occupancy === "RESIDENTIALCONDOBUILD20" && emptyQuote.propertyInfo.s_CondoOwnership === "YES")) {
            step = step + 1;
          }*/
          navigateStep(step);
        }
      } else if (currentStep === 7) {
        var step = 8;
        /*if ((emptyQuote.propertyInfo.s_PolicyWaitingPeriod === "ROLLOVER_RENEWAL") || (emptyQuote.propertyInfo.s_Occupancy === "RESIDENTIALUNIT20" && emptyQuote.propertyInfo.s_CondoOwnership === "YES")) {
          step = step + 1;
        }*/
        navigateStep(step);
      } else if (currentStep === 8) {
        // ODS-860
        /*if (emptyQuote.propertyInfo.s_IsNewPurchase !== 'YES' && emptyQuoteData.propertyInfo.isSellerDec !== "YES") {
          Swal.fire({
            title: 'Error',
            text: t("Statutory discounts applied to the prior policy cannot be maintained without the sellers dec page"),
            icon: 'error',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(true);
              return false;
            }
          });
        } else {*/
        var isValid = true, step = currentStep + 2;
        if (
          emptyQuote.propertyInfo.isSellerDec === 'YES' &&
          ([null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorPolicyNo) || [null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorCompanyNAIC))
        ) {
          if ([null, '', undefined].includes(emptyQuote.propertyInfo.s_PriorCompanyNAIC)) {
            toast.error("Enter Prior Company NAIC");
          }
          if (!emptyQuote.propertyInfo.s_PriorPolicyNo) {
            toast.error(t("Please enter Prior Policy Number"));
          } else if (emptyQuote.propertyInfo.s_PriorPolicyNo.length !== 10) {
            toast.error(t("Please enter valid prior policy number"));
          }
          isValid = false;
        }
          // EC
          if (emptyQuote.propertyInfo.s_isValidElevationCert === 'YES') {
            if ([null, '', undefined].includes(emptyQuote.propertyInfo.d_CertificateDate)) {
              toast.error(t('Enter Certificate Signature Date'));
              isValid = false;
            }
            if ([null, '', undefined].includes(emptyQuote.propertyInfo.s_BuildingDiagramNoCode)) {
              toast.error(t('Please Select Diagram Number Error'));
              isValid = false;
            }
            if ([null, '', undefined].includes(emptyQuote.propertyInfo.s_SectionCompleted)) {
              toast.error(t('Please select the section of the elevation certificate that has been completed'));
              isValid = false;
            }
            
            var effectiveDate = emptyQuote.effectiveDate;
            // ODS-1835
            if (effectiveDate >= '2024-04-01' && ['8', '9'].includes(emptyQuote.propertyInfo.s_BuildingDiagramNoCode)) {
              if ([null, '', undefined].includes(emptyQuote.propertyInfo.n_TopHigherFloor)) {
                toast.error(t('The next higher floor elevation can not be blank'));
                isValid = false;
              }
            }
            
            // ODS-1281 - Add section H to the options for EC
            if (emptyQuote.propertyInfo.s_SectionCompleted == 'H' && ['6', '7', '8', '9'].includes(emptyQuote.propertyInfo.s_BuildingDiagramNoCode)) {
              if ([null, '', undefined].includes(emptyQuote.propertyInfo.n_TopBottomFloor)) {
                toast.error(t("Enter Top of Bottom floor"));
                isValid = false;
              }
              if ([null, '', undefined].includes(emptyQuote.propertyInfo.n_TopHigherFloor)) {
                toast.error(t("Enter Top of next higher floor"));
                isValid = false;
              }
            }
          }
        if (isValid) {
          if (emptyQuote.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM' && verified) {
            step = 11;
          } else if (['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuote.propertyInfo.s_Foundation)) {
            step = 12;
          } else {
            isValid = true;
          }
          navigateStep(step);
        }
        toggle(!isValid);
        // }
      } else if (currentStep === 9) {
        if (emptyQuote.propertyInfo.s_isValidElevationCert === 'YES' && ([null, ''].includes(emptyQuote.propertyInfo.s_BuildingDiagramNoCode) || [null, ''].includes(emptyQuote.propertyInfo.d_CertificateDate))) {
          if ([null, ''].includes(emptyQuote.propertyInfo.d_CertificateDate)) {
            toast.error(t('Enter Certificate Signature Date'));
          }
          toggle(true);
        } else {
          toggle(false);
          if (['SLABONGRADE20', 'BASEMENT20', 'ELEVATEDWITHOUTENCLOSURE20'].includes(emptyQuote.propertyInfo.s_Foundation) && currentStep === 9) {
            navigateStep(12);
          } else {
            navigateStep(currentStep + 1)
          }
        }
      } else if (currentStep === 13) {
        if ([null, ''].includes(emptyQuote.propertyInfo.s_SerialNo)) {
          toast.error(t('Serial / Vin number require for residential mobile home!'));
          toggle(true);
        } else {
          navigateStep(2);
        }
      } else {
        navigateStep(currentStep + 1);
      }
    }
  }

  const handlePreviousButton = () => {
    if (Object.keys(emptyQuote).length > 0) {
      if (currentStep === 8) {
        // show only If usage = Primary OR tenant
        if (((emptyQuote.propertyInfo.s_Occupancy === 'RESIDENTIALUNIT20' && emptyQuote.propertyInfo.s_CondoOwnership === 'NO') || !['NONRESIDENTIALUNIT20', 'OTHERRESIDENTIAL20', 'NONRESIDENTIALBUILD20'].includes(emptyQuote.propertyInfo.s_Occupancy)) && ['PRIMARY', 'TENANT'].includes(emptyQuote.propertyInfo.s_Usage) && currentStep === 8) {
          navigateStep(currentStep - 1);
        } else {
          navigateStep(6);
        }
      } else if (currentStep === 9) {
        var step = 8;
        if ((((emptyQuote.propertyInfo.s_Occupancy === 'RESIDENTIALUNIT20' && emptyQuote.propertyInfo.s_CondoOwnership === 'NO') || !['RESIDENTIALCONDOBUILD20', 'OTHERRESIDENTIAL20', 'NONRESIDENTIALBUILD20'].includes(emptyQuote.propertyInfo.s_Occupancy)) && ['PRIMARY', 'TENANT'].includes(emptyQuote.propertyInfo.s_Usage))) {
          step = step - 1;
        }
        if ((emptyQuote.propertyInfo.s_PolicyWaitingPeriod === "ROLLOVER_RENEWAL") || (emptyQuote.propertyInfo.s_Occupancy === "RESIDENTIALCONDOBUILD20" && emptyQuote.propertyInfo.s_CondoOwnership === "YES")) {
          step = step - 2;
        }
        navigateStep(step);
      } else if (currentStep === 10) {
        if (['X', 'B', 'C', 'A99', 'D'].includes(emptyQuote.propertyInfo.s_FloodZone)) {
          navigateStep(8);
        } /*else {
          navigateStep(currentStep - 1);
        }*/
      } else if (currentStep === 2) {
        var step = currentStep - 1;
        if (emptyQuote.viewType === 'MOBILEHOME') {
          step = 13;
        }
        navigateStep(step);
      } else if (currentStep === 13) {
        var step = currentStep - 1;
        if (emptyQuote.viewType === 'MOBILEHOME') {
          step = 1;
        }
        navigateStep(step);
      } else if (emptyQuote.propertyInfo.s_IsNewPurchase === 'YES' && emptyQuote.propertyInfo.s_FloodConstructionType === 'FLDPREFIRM') {
        navigateStep(8);
      } else {
        navigateStep(currentStep - 1);
      }
    } else {
      navigateStep(currentStep - 1);
    }
  }

  const navigateStep = async (step) => {
    if (saveQuoteData) {
      // Save quote data on every step
      await saveQuoteData();
    }
    navigation('/flood/instantquote/step/' + step);
  }

  return (
    <div className="flood-bts-div">
      {currentStep === 1 ? (
        <Button
          id="primary-grey-btn-flood"
          variant="outline-dark"
          onClick={() => closeModal(null)}
          disabled={false}
          tabIndex="0"
        >
          {t('Back')}
        </Button>
      ) : (
        <Button
          id="primary-grey-btn-flood"
          variant="outline-dark"
          onClick={() => handlePreviousButton()}
          disabled={false}
          tabIndex="0"
        >
          {t('Previous')}
        </Button>
      )
      }{" "}
      <Button
        id="primary-colored-btn"
        variant="primary"
        onClick={() => handleNextButton()}
        autoFocus={true}
        tabIndex="0"
      >
        {t('Next')}
      </Button>{" "}
    </div>
  );
};

export default Validation;