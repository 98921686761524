import React from "react";
import { Route, Routes } from "react-router-dom";
import Front from "./Front";
import InstaSteps from "./InstaSteps";

const InstaFrontRoute = () => {
    return(
        <>
            <Routes>
                <Route 
                    path="/InstaSteps/:number"
                    element={<InstaSteps />}
                />
            </Routes>
        </>
    )
}

export default InstaFrontRoute;