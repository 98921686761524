import Http from "../../../../Http";

export async function cancelPolicyHiscox({ requestBody, productID }) {
  try {
    const url = `api/v2/policy/hiscox/cancel/${productID}`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (err) {
    console.error(
      "Error in cancel Hiscox policy:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
