import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Text,
  FormLabel,
  Card,
  Select,
  Spinner,
  Divider,
  Grid,
  GridItem,
  FormControl
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { getAppDD } from "../../../services/coreSystemService";

const Management = ({ disabled, onChange, data }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [apiLoading, setApiLoading] = useState(false);
  const [appDD, setAppDD] = useState();

  const handleChange = (event) => {
    onChange(event);
  };
  useEffect(() => {
    getDropDown();
  }, []);
  // get dropdown
  async function getDropDown() {
    setApiLoading(true);
    let list = "AL3SYSTEMDD_V1,POLICYFORMATDD";
    const param = {
      list,
    };
    await dispatch(getAppDD(param)).then((res) => {
      if (res.status == 200) {
        setAppDD(res.data);
      }
    });
    setApiLoading(false);
  }
  return (
    <Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
      <Box p={6} mb={4}>
        <Text fontWeight={600} fontSize={"xl"}>
          {t("Management")}
        </Text>
        <Divider orientation="horizontal" />
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem>
            <FormControl>
              <FormLabel>{t("AL3 System")}</FormLabel>
              {apiLoading ? (
                <Spinner />
              ) : (
                <Select
                  className="dashboard-group-input"
                  name="al3_system"
                  id="al3_system"
                  onChange={handleChange}
                  value={data && data.al3_system}
                  isDisabled={disabled}
                  bgColor={disabled ? "gray.200" : ""}
                  _disabled={{ opacity: 1 }}
                >
                  <option value="default"></option>
                  {appDD?.AL3SYSTEMDD_V1?.map((option) => (
                    <option
                      key={option.s_AppCodeName}
                      value={option.s_AppCodeName}
                    >
                      {option.s_AppCodeNameForDisplay}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl>
              <FormLabel>{t("Originating Address")}</FormLabel>
              <Text fontWeight={500}>{data && data.originating_addr}</Text>
            </FormControl>
          </GridItem> */}
          <GridItem>
            <FormControl>
              <FormLabel>{t("Policy Format")}</FormLabel>
              {apiLoading ? (
                <Spinner />
              ) : (
                <Select
                  className="dashboard-group-input"
                  name="policy_format"
                  id="policy_format"
                  onChange={handleChange}
                  value={data && data.policy_format}
                  isDisabled={disabled}
                  bgColor={disabled ? "gray.200" : ""}
                  _disabled={{ opacity: 1 }}
                >
                  <option value="default"></option>
                  {appDD &&
                    appDD.POLICYFORMATDD &&
                    appDD?.POLICYFORMATDD?.map((option) => (
                      <option
                        key={option.s_AppCodeName}
                        value={option.s_AppCodeName}
                      >
                        {option.s_AppCodeNameForDisplay}
                      </option>
                    ))}
                </Select>
              )}
            </FormControl>
          </GridItem>
          {/* <GridItem>
            <FormControl>
              <FormLabel>{t("Destination Address")}</FormLabel>
              <Input
                className="dashboard-group-input"
                type="text"
                name="destination_addr"
                id="destination_addr"
                onChange={handleChange}
                value={data && data.destination_addr}
                isDisabled={disabled}
                bgColor={disabled ? "gray.200" : ""}
                _disabled={{ opacity: 1 }}
              />
            </FormControl>
          </GridItem> */}
        </Grid>
      </Box>
    </Card>
  );
};

export default Management;
