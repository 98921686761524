import React, { useMemo, useCallback, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  StreetViewPanorama,
} from "@react-google-maps/api";
import styled from "styled-components";

const MapContainer = styled.div`
  width: 100%;
  height: 425px;
  position: relative;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;

const GoogleMaps = ({ lat, lng, markerTitle, onMapClick }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: apiKey,
  });

  const markerPosition = useMemo(
    () => ({
      lat,
      lng,
    }),
    [lat, lng]
  );

  const options = useMemo(
    () => ({
      disableDefaultUI: false,
      zoomControl: true,
      mapTypeId: "satellite",
      zoom: 17.5,
    }),
    []
  );

  const handleMarkerClick = useCallback((marker) => {
    setSelectedMarker(marker);
  }, []);

  const handleMapClick = useCallback(
    (event) => {
      onMapClick?.(event.latLng.lat(), event.latLng.lng());
      setSelectedMarker(null);
    },
    [onMapClick]
  );

  return (
    <MapContainer>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={markerPosition}
          options={options}
          onClick={handleMapClick}
        >
          {/* <StreetViewPanorama position={markerPosition} visible={true} /> */}
          <Marker
            position={markerPosition}
            onClick={() => handleMarkerClick(markerPosition)}
            title={markerTitle}
          />
          {selectedMarker && (
            <InfoWindow
              position={selectedMarker}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>{markerTitle}</div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <LoadingOverlay>Loading...</LoadingOverlay>
      )}
    </MapContainer>
  );
};

export default GoogleMaps;
