import Http from "../../../../Http";

export async function postSaveHiscoxPremiumCard({ requestBody, quoteNo }) {
  //update premium card to save preference of user at Premium checkpoint.
  try {
    const url = `/api/v2/quote/hiscox/update-premium/${quoteNo}`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
  }
}
