import {
  Box,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function UserProductLicenseInformation({
  authTypeData,
  underWritterData,
  serviceRepresentativeData,
  stateData,
  ruleData,
  productData,
  licenceInfo,
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Text mb={0} fontWeight={500} fontSize="xl">
        {t("User Product")}/{t("License Information")}
      </Text>
      <Box
        bg={"transparent"}
        maxHeight={"15rem"}
        overflowX={"hidden"}
        overflowY={"auto"}
        borderRadius={"2xl"}
        boxShadow={"lg"}
        mt={2}
      >
        <TableContainer
          borderRadius={"2xl"}
          border={"1px solid"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          <Table
            variant="simple"
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            overflow={"hidden"}
          >
            <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
              <Tr>
                <Th>{t("PRODUCT")}</Th>
                <Th>{t("RULE")}</Th>
                <Th>{t("STATE")}</Th>
                <Th>{t("REPRESENTATIVE")}</Th>
                <Th>{t("U/W ASSIGNED")}</Th>
                <Th>{t("LICENSE")}#</Th>
                <Th>{t("AUTHORIZATION")}</Th>
                <Th>{t("AGENTNPN")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(licenceInfo || []).map(
                (row, index) => {
                  return (
                    <Tr
                      key={index}
                      _hover={{
                        bg: `rgba(0, 0, 0, 0.075)`,
                      }}
                    >
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="product"
                          id="product"
                          className="form-control producer-group-input"
                          value={row.n_Product_FK}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(productData || []).map(
                            (product, id) => {
                              return (
                                <option key={id} value={product.n_ProductId_PK}>
                                  {product.s_ProductName}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="rule"
                          id="rule"
                          className="form-control producer-group-input"
                          value={row.n_PrValidationRuleGroupMasters_FK}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(ruleData || []).map(
                            (rule, id) => {
                              return (
                                <option
                                  key={id}
                                  value={rule.n_PrValidationRuleGroupMasters_PK}
                                >
                                  {rule.s_RuleCode}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="state"
                          id="state"
                          className="form-control producer-group-input"
                          value={row.s_StateCode}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(stateData || []).map(
                            (state, id) => {
                              return (
                                <option key={id} value={state.n_stateId_PK}>
                                  {state.s_StateName}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="srp"
                          id="srp"
                          className="form-control producer-group-input"
                          value={row.n_ServiceRep}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(serviceRepresentativeData || []).map((srp, id) => {
                            return (
                              <option key={id} value={srp.Admin_ID}>
                                {srp.s_ScreenName}
                              </option>
                            );
                          })}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="n_UWAssigned"
                          id="n_UWAssigned"
                          className="form-control producer-group-input"
                          value={row.n_UWAssigned}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(underWritterData || []).map((uwd, id) => {
                            return (
                              <option key={id} value={uwd.Admin_ID}>
                                {uwd.s_ScreenName}
                              </option>
                            );
                          })}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          type="text"
                          name="lic_no"
                          id="lic_no"
                          className="form-control producer-group-input"
                          value={row.n_LicenseNo}
                          isDisabled={true}
                          borderRadius={"lg"}
                        />
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          icon={""}
                          name="auth"
                          id="auth"
                          className="form-control producer-group-input"
                          value={row.s_AuthorizationCode}
                          isDisabled={true}
                          borderRadius={"lg"}
                        >
                          <option value=""></option>
                          {(authTypeData || []).map(
                            (authData, id) => {
                              return (
                                <option key={id} value={authData.s_AppCodeName}>
                                  {authData.s_AppCodeNameForDisplay}
                                </option>
                              );
                            }
                          )}
                        </Select>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          type="text"
                          name="agent_npn"
                          id="agent_npn"
                          className="form-control producer-group-input"
                          value={row.n_AgentNPN}
                          isDisabled={true}
                          borderRadius={"lg"}
                        />
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
