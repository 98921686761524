import Http from "../../../../../Http";

export async function getRateHistory(poTransPK) {
  const url = `/api/reports/getRateHistoryList/${poTransPK}`;
  try {
    const data = await Http.get(url);
    return data;
  } catch (error) {
    console.error("Error in rate history api :", error);
    throw error;
  }
}
