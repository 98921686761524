import Http from "../../../../Http";

export async function getFees(requestBody, productName) {
  try {
    const url = `/api/v2/quote/${productName}/calculateFees`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
  }
}
