// temporary only
const locationDistanceToCoastFields = [
  "0-4.9",
  "5-9.9",
  "10-14.9",
  "15-19.9",
  "20-24.9",
  "25-29.9",
  "30-34.9",
  "35-39.9",
  "40-44.9",
  "45-49.9",
  "50+",
];

const coveredFerilsFields = [
  "Named Perils",
  "Named Perils + Burglary/Robbery",
  "Special Coverage",
];

const entityTypeFields = [
  "Corporation",
  "Limited Liability Entity",
  "Individual or Limited Partnership",
  "Partnership",
  "Other",
];

const buissinessEstablishmentFields = [
  "New Venture",
  "1-2 Years",
  "3-4 Years",
  "5-6 Years",
  "7-10 Years",
  "10+ Years",
];

const buissnessClassFields = [
  "HOA",
  "Hotels 1-4 Stories - No/Limited Cooking",
  "Hotels (Luxury) 1-4 Stories - No/Limited Cooking",
  "Hotels 1-4 Stories + Cooking",
  "Hotels (Luxury) 1-4 Stories + Cooking",
  "Hotels 5+ Stories - No/Limited Cooking",
  "Hotels (Luxury) 5+ Stories - No/Limited Cooking",
  "Hotels 5+ Stories + Cooking",
  "Hotels (Luxury) 5+ Stories + Cooking",
  "Convenience Store with Restaurant",
  "Convenience Store withOUT Restaurant",
];

const waterSublimitFields = [
  "Excluded",
  "$50,000",
  "$100,000",
  "$200,000",
  "Full Coverage",
];

const theftDeductibleFields = [
  "$5,000",
  "$10,000",
  "$25,000",
  "$50,000",
  "$75,000",
  "$100,000",
  "1%",
  "2%",
  "3%",
  "4%",
  "5%",
  "6%",
  "7%",
  "8%",
  "9%",
  "10%",
];

const fireAlarmFields = ["Central Station", "Local Alarm", "None"];

const threeYearLostCountFields = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "10+"];

const burglarAlarmFields = ["Central Station", "Local Alarm", "None"];

const lossesLastFiveYearsFields = [0, 1, "2+"];

const securityServicesFields = [
  "None",
  "Video Surveillance",
  "Security Guard",
  "Video + Security Guard",
];

const petsAndAnimalsFields = [
  "None",
  "Cats only",
  "Small dogs only",
  "Medium dogs only",
  "Small dogs and cats",
  "Medium dogs and cats",
  "Small dogs, cats and other benign animals/pets",
  "Medium dogs, cats and other benign animals/pets",
  "All other pets/animals",
];

const latestClosingHoursFields = ["24 Hours", "All other Closing Hours"];

const bbbRatingFields = ["A", "B", "C", "D", "E", "F", "Unknown"];

const elevatorSafetyFields = ["NA", "Certified", "None"];

const smokingFields = ["Allowed", "Not Allowed"];

const maintainenceFields = [
  "None",
  "Available 24 Hours",
  "Available < 24 Hours",
];
const wiringUpdatesFields = [
  "6-8 Years",
  "9-11 Years",
  "12-14 Years",
  "15-17 Years",
  "18-20 Years",
  "21-25 Years",
  "26-30 Years",
  "31+ Years",
];

const roofingUpdatesFields = [
  "Last 5 Years",
  "6-8 Years",
  "9-11 Years",
  "12-14 Years",
  "15-17 Years",
  "18-20 Years",
  "21-25 Years",
  "26-30 Years",
  "31+ Years",
];

const plumbingUpdatesFields = [
  "Last 5 Years",
  "6-8 Years",
  "9-11 Years",
  "12-14 Years",
  "15-17 Years",
  "18-20 Years",
  "21-25 Years",
  "26-30 Years",
  "31+ Years",
];

const bildingCoverageSection = ["100%", "90%", "80%"];

const buildingInterpetionsFields = [
  "0 Hours",
  "24 Hours",
  "48 Hours",
  "72 Hours",
];

const restorationFactorFields = [
  "6 Months",
  "12 Months",
  "18 Months",
  "24 Months",
];

const roofDeckAttachements = [
  "Wood Frame + Wood Decking",
  "Steel/metal Decking with Insulation Board",
  "Lightweight Insulated Concrete",
  "Reinforced/poured concrete decking",
];

const roofWallAnchorages = [
  "Toe Nails",
  "Clips",
  "Single Wraps",
  "Double Wraps",
  "Heavy Construction - (No Wood Elements)",
];

const roofGeometrys = [
  "Gable",
  "Hip",
  "Flat",
  "Mansard",
  "Dutch Hip",
  "Gambrel (Barn)",
  "Shed",
];

const roofCoverings = [
  "Composition Shingle",
  "Wood Shake/Shingle",
  "Built Up/Tar and Gravel",
  "Bult Up Gravel Balast",
  "Standing Seam/Sheet Metal",
  "Clay Tile",
  "Slate",
  "Membrane",
];

const causesOfLoss = [
  {
    s_AppCodeName: "Bodily Injury",
    s_AppCodeNameForDisplay: "Bodily Injury",
  },
  {
    s_AppCodeName: "Collapse",
    s_AppCodeNameForDisplay: "Collapse",
  },
  {
    s_AppCodeName: "Earthquake",
    s_AppCodeNameForDisplay: "Earthquake",
  },
  {
    s_AppCodeName: "Equipment Breakdown",
    s_AppCodeNameForDisplay: "Equipment Breakdown",
  },
  {
    s_AppCodeName: "Explosion",
    s_AppCodeNameForDisplay: "Explosion",
  },
  {
    s_AppCodeName: "Fire",
    s_AppCodeNameForDisplay: "Fire",
  },
  {
    s_AppCodeName: "Flood",
    s_AppCodeNameForDisplay: "Flood",
  },
  {
    s_AppCodeName: "Hail",
    s_AppCodeNameForDisplay: "Hail",
  },
  {
    s_AppCodeName: "Liability",
    s_AppCodeNameForDisplay: "Liability",
  },
  {
    s_AppCodeName: "Lightning",
    s_AppCodeNameForDisplay: "Lightning",
  },
  {
    s_AppCodeName: "Mold",
    s_AppCodeNameForDisplay: "Mold",
  },
  {
    s_AppCodeName: "Property Damage",
    s_AppCodeNameForDisplay: "Property Damage",
  },
  {
    s_AppCodeName: "Theft",
    s_AppCodeNameForDisplay: "Theft",
  },
  {
    s_AppCodeName: "Vandalism / Malicious Mischief",
    s_AppCodeNameForDisplay: "Vandalism / Malicious Mischief",
  },
  {
    s_AppCodeName: "Volcano",
    s_AppCodeNameForDisplay: "Volcano",
  },
  {
    s_AppCodeName: "Water",
    s_AppCodeNameForDisplay: "Water",
  },
  {
    s_AppCodeName: "WildFire",
    s_AppCodeNameForDisplay: "WildFire",
  },
  {
    s_AppCodeName: "Wind",
    s_AppCodeNameForDisplay: "Wind",
  },
];

const windHailDeductibles = [
  "1%",
  "2%",
  "3%",
  "4%",
  "5%",
  "6%",
  "7%",
  "8%",
  "9%",
  "10%",
];

const aopDed = [
  {
    s_DiscountCode: "COMMEQDED1000",
    s_ScreenName: "$1,000",
  },
  {
    s_DiscountCode: "COMMEQDED2500",
    s_ScreenName: "$2,500",
  },
  {
    s_DiscountCode: "COMMEQDED5000",
    s_ScreenName: "$5,000",
  },
  {
    s_DiscountCode: "COMEQDED10000",
    s_ScreenName: "$10,000",
  },
  {
    s_DiscountCode: "COMEQDED25000",
    s_ScreenName: "$25,000",
  },
  {
    s_DiscountCode: "COMMEQDED50000",
    s_ScreenName: "$50,000",
  },
  {
    s_DiscountCode: "COMMEQDED75000",
    s_ScreenName: "$75,000",
  },
  {
    s_DiscountCode: "COMMEQDED100000",
    s_ScreenName: "$100,000",
  },
  {
    s_DiscountCode: "COMMEQDED1",
    s_ScreenName: "1%",
  },
  {
    s_DiscountCode: "COMMEQDED2",
    s_ScreenName: "2%",
  },
  {
    s_DiscountCode: "COMMEQDED3",
    s_ScreenName: "3%",
  },
  {
    s_DiscountCode: "COMMEQDED4",
    s_ScreenName: "4%",
  },
  {
    s_DiscountCode: "COMMEQDED5",
    s_ScreenName: "5%",
  },
  {
    s_DiscountCode: "COMMEQDED6",
    s_ScreenName: "6%",
  },
  {
    s_DiscountCode: "COMMEQDED7",
    s_ScreenName: "7%",
  },
  {
    s_DiscountCode: "COMMEQDED8",
    s_ScreenName: "8%",
  },
  {
    s_DiscountCode: "COMMEQDED9",
    s_ScreenName: "9%",
  },
  {
    s_DiscountCode: "COMMEQDED10",
    s_ScreenName: "10%",
  },
];

const lossCounts = [
  { value: "0", label: "0 Claims" },
  { value: "1", label: "1 Claim" },
  { value: "2", label: "2 Claims" },
  { value: "3", label: "3 Claims" },
  { value: "4+", label: "4 or More Claims" },
  { value: "na", label: "N/A (Not Applicable)" },
];

export {
  locationDistanceToCoastFields,
  buissnessClassFields,
  waterSublimitFields,
  theftDeductibleFields,
  fireAlarmFields,
  threeYearLostCountFields,
  burglarAlarmFields,
  lossesLastFiveYearsFields,
  securityServicesFields,
  petsAndAnimalsFields,
  latestClosingHoursFields,
  bbbRatingFields,
  elevatorSafetyFields,
  smokingFields,
  maintainenceFields,
  wiringUpdatesFields,
  roofingUpdatesFields,
  plumbingUpdatesFields,
  coveredFerilsFields,
  entityTypeFields,
  buissinessEstablishmentFields,
  bildingCoverageSection,
  buildingInterpetionsFields,
  restorationFactorFields,
  causesOfLoss,
  windHailDeductibles,
  aopDed,
  roofDeckAttachements,
  roofWallAnchorages,
  roofGeometrys,
  roofCoverings,
  lossCounts,
};
