import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiDeleteBin2Line } from "react-icons/ri";
import { VscDiffAdded } from "react-icons/vsc";
import { toast } from "react-toastify";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import useDebounce from "../../utility/useDebounce";
import { getAddrByZip } from "../../api/getAddrByZip";
import { ZipSelectionModal } from "../QuoteApplication/components/ZipLocationSelectModal";
import "../style.css";
import { CiCalendar } from "react-icons/ci";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AdditionalInsured = () => {
  const { t } = useTranslation();
  const {
    additionalInsured,
    setAdditionalInsured,
    additionalInsuredFlag,
    setAdditionalInsuredFlag,
  } = useMyContext(CreateQuoteContext);
  const [showModal, setShowModal] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [zipData, setZipData] = useState([]);
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const [loading, setLoading] = useState(false);

  const additionalInsuredFields = {
    n_PolicyAddInterest_PK: null,
    personType: "ADDITIONALINSURED",
    mortgageeType: "",
    loanNo: null,
    person: {
      dob: "",
      entityType: "INDIVIDUAL",
      firstName: "",
      middleName: null,
      lastName: "",
      isMailingDiff: null,
      isTempMailing: null,
      isInsuredTenant: null,
    },
    address: {
      addressLine1: "",
      addressLine2: "",
      county: "",
      city: "",
      stateCode: "",
      country: "",
      latitude: null,
      longitude: null,
      zip: "",
    },
  };

  const [newAdditionalInsured, setNewAdditionalInsured] = useState(
    additionalInsuredFields
  );

  const isDisabled =
    (newAdditionalInsured?.person?.entityType === "ORGANIZATION" &&
      newAdditionalInsured?.person?.lastName === "") ||
    // newAdditionalInsured?.address?.zip === "" ||
    // newAdditionalInsured?.address?.city === "" ||
    // newAdditionalInsured?.address?.stateCode === "" ||
    // newAdditionalInsured?.address?.addressLine1 === ""
    (newAdditionalInsured?.person?.entityType === "INDIVIDUAL" &&
      (newAdditionalInsured?.person?.firstName === "" ||
        newAdditionalInsured?.person?.lastName === ""));
  // newAdditionalInsured?.address?.zip === "" ||
  // newAdditionalInsured?.address?.city === "" ||
  // newAdditionalInsured?.address?.stateCode === "" ||
  // newAdditionalInsured?.address?.addressLine1 === ""

  const debouncedZipCode = useDebounce(newAdditionalInsured.address?.zip, 1000);
  useEffect(() => {
    const fetchAddressData = async () => {
      if (debouncedZipCode && debouncedZipCode.length > 4) {
        try {
          setLoading(true);
          const data = await getAddrByZip({ zipCode: debouncedZipCode });
          if (
            data?.data?.status === "Y" &&
            Array.isArray(data?.data?.zipData)
          ) {
            if (data?.data?.zipData?.length > 1) {
              setIsOpenInternal(true);
              setZipData(data?.data?.zipData);
            } else if (data?.data?.zipData?.length === 1) {
              setSelectedLocation(data?.data?.zipData[0]);
            }
          } else if (data?.data?.status === "N" && data?.data?.errorMsg) {
            toast.warn(data?.data?.errorMsg, {
              autoClose: 2500,
            });
          }
        } catch (err) {
          console.error("Error fetching address data:", err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAddressData();
  }, [debouncedZipCode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdditionalInsured((prev) => ({
      ...prev,
      person: {
        ...prev.person,
        [name]: value,
      },
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setNewAdditionalInsured((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  const handleAddMortgagee = () => {
    if (additionalInsured.length < 10) {
      const newEntry = {
        ...newAdditionalInsured,
        person: {
          ...newAdditionalInsured.person,
        },
        address: {
          ...newAdditionalInsured.address,
          addressLine1: newAdditionalInsured.address.addressLine1 || "N/A",
        },
      };
      setAdditionalInsured([...additionalInsured, newEntry]);
      setNewAdditionalInsured(additionalInsuredFields);
    } else {
      toast.warn(t("Maximum of 10 Additional Insured are allowed"), {
        autoClose: 3500,
        toastId: "addMortgagee",
      });
    }
  };

  const handleDeleteMortgagee = (index) => {
    if (additionalInsured?.length > 0) {
      const updatedAdditionalInsured = [
        ...additionalInsured.slice(0, index),
        ...additionalInsured.slice(index + 1),
      ];
      setAdditionalInsured(updatedAdditionalInsured);
    }
  };

  const handleSwitchChange = (event) => {
    setAdditionalInsuredFlag(event.target.checked);
    if (!event.target.checked) {
      setAdditionalInsured([]);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setNewAdditionalInsured(additionalInsuredFields);
    toast.success(t("Insured Added"), {
      autoClose: 1000,
    });
  };

  useEffect(() => {
    if (selectedLocation) {
      setNewAdditionalInsured({
        ...newAdditionalInsured,
        address: {
          ...newAdditionalInsured.address,
          city: selectedLocation?.s_CityName || "",
          // state: selectedLocation?.state_id || "",
          // zip: selectedLocation?.n_Zipcodes_PK,
          cityId: selectedLocation?.n_CityId_PK ?? 0,
          stateId: selectedLocation?.n_StateId_PK ?? 0,
          country: "US",
          countryId:
            typeof selectedLocation?.n_CountryId_FK === "number"
              ? selectedLocation?.n_CountryId_FK
              : 1 || 1,
          county: selectedLocation?.s_CountyName || "",
          countyId: selectedLocation?.n_CountyId_FK ?? 0,
          stateCode: selectedLocation?.s_StateName || "",
        },
      });
    }
  }, [selectedLocation]);

  const renderEntityInputs = () => {
    switch (newAdditionalInsured?.person?.entityType) {
      case "INDIVIDUAL":
        return (
          <>
            <Grid templateColumns="repeat(3, 1fr)" gap={8}>
              <FormControl id="firstName" isRequired>
                <FormLabel>{t("First Name")}:</FormLabel>
                <Input
                  className="inputField"
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={newAdditionalInsured?.person?.firstName}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="lastName" isRequired>
                <FormLabel>{t("Last Name")}:</FormLabel>
                <Input
                  className="inputField"
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={newAdditionalInsured?.person?.lastName}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="dob">
                <FormLabel>
                  {t("Birth Date")}:{" "}
                  <Text as={"span"} fontWeight={400} opacity={0.7}>
                    (optional)
                  </Text>
                </FormLabel>
                <DatePicker
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  showIcon
                  placeholderText="MM/DD/YYYY"
                  toggleCalendarOnIconClick
                  icon={
                    <Box mt={"0.27rem"} ml={{ lg: "95%", md: "80%" }}>
                      <CiCalendar size={25} />
                    </Box>
                  }
                  className="dateInput"
                  wrapperClassName="dateWidth"
                  placeholder="Date of Birth"
                  name="dob"
                  max={maxDate}
                  selected={
                    newAdditionalInsured?.person?.dob
                      ? moment(newAdditionalInsured?.person?.dob).toDate()
                      : null
                  }
                  onChange={(e) => {
                    setNewAdditionalInsured((prev) => ({
                      ...prev,
                      person: {
                        ...prev.person,
                        dob: moment(e).format("YYYY-MM-DD"),
                      },
                    }));
                  }}
                />
              </FormControl>
            </Grid>
          </>
        );
      case "ORGANIZATION":
        return (
          <FormControl id="organizationName" isRequired>
            <FormLabel>{t("Organization Name")}:</FormLabel>
            <Input
              className="inputField"
              placeholder="Organization Name"
              type="text"
              name="lastName"
              value={newAdditionalInsured?.person?.lastName}
              onChange={handleInputChange}
            />
          </FormControl>
        );
      default:
        return null;
    }
  };

  const renderTableHeaders = () => {
    return (
      <>
        <Th>#</Th>
        <Th>Insured Type</Th>
        <Th>Name</Th>
        <Th>Date of Birth</Th>
        <Th>Address</Th>
        <Th>Action</Th>
      </>
    );
  };

  const renderTableRows = () => {
    return additionalInsured.map((insured, index) => (
      <Tr key={index}>
        <Td py={2}>{index + 1}</Td>
        <Td py={2}>
          <Badge
            variant={"subtle"}
            colorScheme={
              insured?.person?.entityType === "INDIVIDUAL" ? "green" : "orange"
            }
          >
            {insured?.person?.entityType === "INDIVIDUAL"
              ? "Individual"
              : "Organization"}
          </Badge>
        </Td>
        <Td py={2}>
          {insured?.person?.entityType === "ORGANIZATION"
            ? insured?.person?.lastName || ""
            : `${insured?.person?.firstName || ""} ${
                insured?.person?.lastName || ""
              }`}
        </Td>
        <Td py={2}>{insured?.person?.dob || ""}</Td>
        <Td py={2}>
          {insured?.address?.addressLine1 || ""} {insured?.address?.zip || ""}
        </Td>
        <Td py={2}>
          <Button size="sm" onClick={() => handleDeleteMortgagee(index)}>
            <RiDeleteBin2Line size={20} />
          </Button>
        </Td>
      </Tr>
    ));
  };

  return (
    <>
      <Box zIndex={1000}>
        <ZipSelectionModal
          isOpen={isOpenInternal}
          setOpenModal={setIsOpenInternal}
          setZipData={setSelectedLocation}
          selectedLocation={zipData}
        />
      </Box>
      <Box>
        <Flex
          mb={4}
          justifyContent={"space-between"}
          width={"36%"}
          align={"center"}
        >
          <Text mb={1} as={"h6"}>
            {t("Are there any additional insured entities?")}
          </Text>
          <Switch
            size={"lg"}
            isChecked={additionalInsuredFlag}
            onChange={(event) => {
              handleSwitchChange(event);
            }}
          />
        </Flex>
        <Flex>
          {additionalInsuredFlag && (
            <>
              <Button
                borderRadius={"lg"}
                leftIcon={<VscDiffAdded size={20} />}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("Add additional Insured")}
              </Button>
            </>
          )}
        </Flex>
        <Modal
          isCentered
          closeOnEsc={false}
          closeOnOverlayClick={false}
          size={"4xl"}
          isOpen={showModal}
          onClose={closeModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              mb={0}
              borderBottom={"1px solid"}
              borderColor={"gray.300"}
            >
              <Flex gap={3} alignItems={"center"}>
                <Text>
                  {" "}
                  {t("Add")} {t("Additional Insured")}{" "}
                </Text>{" "}
                {loading && <Spinner mb={4} color="primary.500" />}
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box px={2} my={1}>
                <Flex mb={4}>
                  <FormControl w={"31%"}>
                    <FormLabel>{t("Insured Type")}:</FormLabel>
                    <Select
                      className="selectField"
                      name="entityType"
                      value={newAdditionalInsured?.person?.entityType}
                      onChange={handleInputChange}
                    >
                      <option value="INDIVIDUAL">{t("Individual")}</option>
                      <option value="ORGANIZATION">{t("Organization")}</option>
                    </Select>
                  </FormControl>
                </Flex>
                {renderEntityInputs()}
                <Box
                  borderBottom={"1px solid"}
                  borderColor={"gray.100"}
                  mt={4}
                />
                <Grid templateColumns="repeat(2, 1fr)" gap={8} pt={4}>
                  <GridItem>
                    <FormControl>
                      <FormLabel ml={1}>{t("Address Line 1")}:</FormLabel>
                      <Input
                        className="inputField"
                        placeholder="Street Address"
                        type="text"
                        name="addressLine1"
                        value={newAdditionalInsured?.address?.addressLine1}
                        onChange={handleAddressChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel ml={1}>{t("Address Line 2")}:</FormLabel>
                      <Input
                        className="inputField"
                        type="text"
                        name="addressLine2"
                        placeholder="(optional)"
                        value={newAdditionalInsured?.address?.addressLine2}
                        onChange={handleAddressChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Grid templateColumns="repeat(3, 1fr)" gap={8} pt={4}>
                  <GridItem>
                    <FormControl>
                      <FormLabel ml={1}>{t("Zip Code")}:</FormLabel>
                      <Input
                        className="inputField"
                        type="number"
                        name="zip"
                        placeholder="Zip Code"
                        value={newAdditionalInsured?.address?.zip}
                        onChange={(e) => {
                          if (e.target.value.length <= 5) {
                            handleAddressChange(e);
                          }
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel ml={1}> {t("State")}:</FormLabel>
                      <Input
                        className="inputField"
                        type="text"
                        name="stateCode"
                        placeholder="State"
                        // isDisabled
                        // _disabled={{ bg: "gray.200", color: "black" }}
                        value={newAdditionalInsured?.address?.stateCode}
                        onChange={handleAddressChange}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel ml={1}>{t("City")}:</FormLabel>
                      <Input
                        className="inputField"
                        type="text"
                        name="city"
                        placeholder="City"
                        // isDisabled
                        // _disabled={{ bg: "gray.200", color: "black" }}
                        value={newAdditionalInsured?.address?.city}
                        onChange={handleAddressChange}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>

                <Flex mt={8} justifyContent={"flex-end"}>
                  <Button
                    leftIcon={<VscDiffAdded />}
                    onClick={handleAddMortgagee}
                    isDisabled={isDisabled}
                  >
                    {t("Add Additional Insured")}
                  </Button>
                </Flex>

                {additionalInsured.length !== 0 && (
                  <Box mt={8}>
                    <TableContainer>
                      <Table
                        variant="simple"
                        border={"1px solid"}
                        borderColor="gray.100"
                      >
                        <Thead>
                          <Tr>{renderTableHeaders()}</Tr>
                        </Thead>
                        <Tbody>{renderTableRows()}</Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </ModalBody>
            <ModalFooter borderTop={"1px solid"} borderColor="gray.100" mt={1}>
              <Button
                isDisabled={additionalInsured.length === 0}
                onClick={closeModal}
                ml={4}
              >
                {t("Save")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default AdditionalInsured;
