import React from "react";
// import { Table } from "react-bootstrap";
import "../agentuser.css";
import { useTranslation } from "react-i18next";
import {
  Box,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formateDate } from "../../common/FormatDate";

const AgencyLicense = (props) => {
  const { t } = useTranslation("translations","producer");
  return (
    <>
      <Box bg={"transparent"} maxHeight={"15rem"} overflowY={"auto"}>
        <TableContainer
          borderRadius={"2xl"}
          border={"1px solid"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          <Table
            variant="simple"
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            overflow={"hidden"}
          >
            <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
              <Tr>
                <Th px={"6px"}>{t("LICENSE TYPE")}</Th>
                <Th px={"6px"}>{t("LICENSE NUMBER", {ns: "producer"})}</Th>
                <Th px={"6px"} minWidth={"80px"}>{t("STATE")}</Th>
                <Th px={"6px"} minWidth={"180px"}>{t("APPOINTED")}</Th>
                <Th px={"6px"}>{t("ISSUE DATE")}</Th>
                <Th px={"6px"}>{t("EXPIRATION DATE", {ns: "producer"})}</Th>
                <Th px={"6px"}>{t("APPLICATION DATE", {ns: "producer"})}</Th>
                <Th px={"6px"}>{t("AGENCY NPN")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(props?.AgencyData ? props.AgencyData : []).map(
                (licManager, index) => {
                  return (
                    <Tr
                      key={index}
                      _hover={{
                        bg: `rgba(0, 0, 0, 0.075)`,
                      }}
                    >
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          borderRadius={"lg"}
                          id="n_LicenseType"
                          name="n_LicenseType"
                          className="form-control producer-group-input"
                          value={licManager?.n_LicenseType}
                          isDisabled={true}
                          icon={""}
                        >
                          <option value=""></option>
                          <option value="111">{t("Active", {ns: "producer"})}</option>
                          <option value="112">{t("Closed", {ns: "producer"})}</option>
                        </Select>
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          borderRadius={"lg"}
                          type="text"
                          id="s_LicenseNo"
                          name="s_LicenseNo"
                          className="form-control producer-group-input"
                          value={licManager?.s_LicenseNo}
                          isDisabled={true}
                        />
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          borderRadius={"lg"}
                          id="n_StateId_FK"
                          name="n_StateId_FK"
                          className="form-control producer-group-input"
                          value={licManager?.n_StateId_FK}
                          isDisabled={true}
                          icon={""}
                        >
                          <option value=""></option>
                          {(props?.StateData ? props?.StateData : []).map(
                            (state) => (
                              <option value={state.n_StateId_PK}>
                                {state.s_StateName}
                              </option>
                            )
                          )}
                        </Select>
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Select
                          size={"sm"}
                          borderRadius={"lg"}
                          id="n_StateId_FK"
                          name="n_StateId_FK"
                          className="form-control producer-group-input"
                          value={licManager?.n_appointyType}
                          isDisabled={true}
                          icon={""}
                        >
                          <option value="769" selected="selected">
                            {t("UNIFI APPOINTED", { ns: "producer" })}
                          </option>
                          <option value="770">
                            {t("LSA", { ns: "producer" })}
                          </option>
                          <option value="771">
                            {t("NOT APPOINTED", { ns: "producer" })}
                          </option>
                        </Select>
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          borderRadius={"lg"}
                          type="text"
                          id="d_IssueDate"
                          name="d_IssueDate"
                          className="form-control producer-group-input"
                          value={formateDate(licManager?.d_IssueDate)}
                          isDisabled={true}
                        />
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          borderRadius={"lg"}
                          type="text"
                          id="d_ExpireDate"
                          name="d_ExpireDate"
                          className="form-control producer-group-input"
                          value={formateDate(licManager?.d_ExpireDate)}
                          isDisabled={true}
                        />
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          borderRadius={"lg"}
                          type="text"
                          id="d_AppDate"
                          name="d_AppDate"
                          className="form-control producer-group-input"
                          value={formateDate(licManager?.d_AppDate)}
                          isDisabled={true}
                        />
                      </Td>
                      <Td
                        p={"6px"}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Input
                          size={"sm"}
                          borderRadius={"lg"}
                          type="text"
                          id="n_AgencyNPN"
                          name="n_AgencyNPN"
                          className="form-control producer-group-input"
                          value={licManager?.n_AgencyNPN}
                          isDisabled={true}
                        />
                      </Td>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {/* <div id="table-size">
        <Table striped bordered hover responsive>
          <thead >
            <tr >
              <th id="card-header">{t("LICENSE TYPE")}</th>
              <th id="card-header">{t("LICENSE NO")}.</th>
              <th id="card-header">{t("STATE")}</th>
              <th id="card-header">{t("APPOINTED")}</th>
              <th id="card-header">{t("ISSUE DATE")}</th>
              <th id="card-header">{t("EXP.DATE")}</th>
              <th id="card-header">{t("APP.DATE")}</th>
              <th id="card-header">{t("AGENCY NPN")}</th>
            </tr>
          </thead>
          <tbody >
          {(props?.AgencyData ? props?.AgencyData : []).map((licManager, index) => {

            return (
            <tr >
              <td>
                <select
                  // type="select"
                  name="version"
                  id="version"
                  className="form-control producer-group-input"
                  value={licManager?.n_LicenseType ? licManager?.n_LicenseType : null }
                  disabled={true}
                  >
                          <option value="">Select Type</option>
                          <option value="111">Active</option>
                          <option value="112">Closed</option>
                </select>
              </td>
              <td className="displayColor">
                <input
                  type="text"
                  name="version"
                  id="version"
                  className="form-control producer-group-input"
                  value={licManager?.s_LicenseNo ? licManager?.s_LicenseNo : null }
                  disabled={true}
                />
              </td>
              <td className="displayColor">
              <select
                  name="version"
                  id="version"
                  className="form-control producer-group-input"
                  value={licManager?.n_StateId_FK ? licManager?.n_StateId_FK : null }
                  disabled={true}
                  >
                          <option value="">Select State</option>
                          {(props?.StateData ? props?.StateData :[]).map((state) => (
                            <option value={state.n_StateId_PK}>
                              {state.s_StateName}
                            </option>
                          ))}
                </select>
              </td>
              <td className="displayColor">
              <select
                  name="version"
                  id="version"
                  className="form-control producer-group-input"
                  value={licManager?.n_appointyType ? licManager?.n_appointyType : null }
                  disabled={true}
                  >
                    <option value="769" selected="selected">
                      UNIFI APPOINTED
                    </option>
                    <option value="770">LSA</option>
                    <option value="771">NOT APPOINTED</option>
                </select>
              </td>
              <td className="displayColor">
                <input
                  type="date"
                  style={{ fontSize: "13px" }}
                  name="version"
                  id="version"
                  value={licManager?.d_IssueDate ? licManager?.d_IssueDate : null }
                  disabled={true}
                />
              </td>
              <td className="displayColor">
                <input
                  type="date"
                  style={{ fontSize: "13px" }}
                  name="version"
                  id="version"
                  value={licManager?.d_ExpireDate ? licManager?.d_ExpireDate : null }
                  disabled={true}
                />
              </td>
              <td className="displayColor">
                <input
                  type="date"
                  style={{ fontSize: "13px" }}
                  name="version"
                  id="version"
                  value={licManager?.d_AppDate ? licManager?.d_AppDate : null }
                  disabled={true}
                />
              </td>
              <td className="displayColor">
                <input
                  type="text"
                  name="version"
                  id="version"
                  className="form-control producer-group-input"
                  value={licManager?.n_AgencyNPN ? licManager?.n_AgencyNPN : null }
                  disabled={true}
                />
              </td>
            </tr>)
            })}
          </tbody>
        </Table>
      </div> */}
    </>
  );
};

export default AgencyLicense;
