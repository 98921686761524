import axios from "axios";

export async function updateStarQuote({ quoteId, requestBody, product }) {
  const url = `/api/v2/quote/${product}/update/${quoteId}`;

  try {
    const response = await axios.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error updating quote:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Request error:", error.message);
    }
    throw error;
  }
}
