import React from "react";
import AgencySearch from "../AgencySearch/AgencySearch";
import AgentSearch from "../AgentSearch/AgentSearch";

const ProducerModal = (props) => {
  return (
    <>
      <div>{props.handleSearchAgencyShow ? <AgencySearch agencyId = {props?.agencyId}/> : ""}</div>
      <div>{props.handleSearchAgentShow ? <AgentSearch agencyId = {props?.agencyId}/> : ""}</div>
    </>
  );
};

export default ProducerModal;
