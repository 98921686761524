import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import pdfIcon from "../../assets/pdf-icon.png";

const AvatarPolicyTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Usefull Communication Documents</span>
      </div>
      <div className="managment-tab-body">
        <Row id="marketing-document-tab-row">
          <Col xs="12" sm="12" md="6" lg="4" xl="4">
            <div className="marketing-document-tab-card">
              <img src={pdfIcon} />
              <span>Unifi policy cover letter</span>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default AvatarPolicyTab;
