import { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  Table,
  Spinner,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./Premium.css";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { MdEditNote } from "react-icons/md";
import { standardizeKeyName } from "./StandarizeNames";
import { postSaveHiscoxPremiumCard } from "../../api/postSaveHiscoxPremium";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FeeTable from "./FeeTable";
import { getFees } from "../../api/getFees";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Currency } from "../../../../common/Currency";
import { Button } from "@chakra-ui/react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export const PremiumModal = ({
  selectedCardData,
  showModal,
  handleCloseModal,
  setChoosedPreferredCard,
  premiumResponseData,
  preferredType,
  setLoading,
}) => {
  const { t } = useTranslation();
  const {
    includeLossOfUse,
    setIncludeLossOfUse,
    businessIncomePremium,
    setBusinessIncomePremium,
    includeContents,
    setIncludeContents,
    setTotalPremium,
    totalPremium,
    constructionDetails,
    locationQuoteObject,
    setPremiumFeesData,
  } = useMyContext(CreateQuoteContext);

  const calculateTotalPremium = () => {
    const total =
      (selectedCardData?.hasOwnProperty("improvementsAndBettermentsPremium")
        ? selectedCardData?.improvementsAndBettermentsPremium
        : 0) +
      (selectedCardData?.hasOwnProperty("buildingPremium")
        ? selectedCardData.buildingPremium
        : 0) +
      (includeContents && selectedCardData?.hasOwnProperty("contentsPremium")
        ? selectedCardData.contentsPremium
        : 0) +
      (includeLossOfUse && selectedCardData?.hasOwnProperty("lossOfUsePremium")
        ? selectedCardData.lossOfUsePremium
        : 0) +
      (selectedCardData?.hasOwnProperty("otherStructuresPremium")
        ? selectedCardData.otherStructuresPremium
        : 0) +
      (businessIncomePremium &&
      selectedCardData?.hasOwnProperty("businessIncomePremium")
        ? selectedCardData.businessIncomePremium
        : 0);

    return Number(total);
  };
  const [limits, setLimits] = useState({});
  const [feeLoader, setFeeLoader] = useState(false);
  const { coreSystem } = useSelector((state) => state);
  const { productID } = useParams();
  const [fees, setFees] = useState({
    fees: {},
    premium: 0,
    totalFee: 0,
    finalPremium: 0,
  });
  useEffect(() => {
    if (setChoosedPreferredCard) {
      setChoosedPreferredCard(false);
      setBusinessIncomePremium(true);
      setIncludeContents(true);
      setIncludeLossOfUse(true);
    }
  }, [selectedCardData]);

  const onSaveClick = () => {
    handleCloseModal();
    setChoosedPreferredCard(true);
    savePremiumCard();
  };

  useEffect(() => {
    if (selectedCardData && Object.keys(selectedCardData).length > 0) {
      setTotalPremium(Number(calculateTotalPremium()));
    }
  }, [
    includeLossOfUse,
    includeContents,
    businessIncomePremium,
    selectedCardData,
  ]);

  function findLimitsByCardData(selectedCardData, premiumResponseData) {
    if (
      !premiumResponseData ||
      !selectedCardData ||
      !premiumResponseData.data
    ) {
      return null;
    }
    const dataType = [
      "commercialOwned",
      "residential",
      "commercialTenanted",
    ].find((type) => premiumResponseData?.data?.hasOwnProperty(type));

    if (!dataType) {
      console.error("No matching data type found.");
      return null;
    }

    const apiResponseData = premiumResponseData.data[dataType];

    if (!apiResponseData) {
      console.error("No matching data type found.");
      return null;
    }

    const findMatchingPolicy = (policies) => {
      return policies?.filter((policy) =>
        policy?.deductibles?.some((deductible) =>
          Object.keys(selectedCardData).every(
            (key) => deductible[key] === selectedCardData[key]
          )
        )
      );
    };

    const findPolicies = (policyType) => {
      const policies = findMatchingPolicy(apiResponseData[policyType]);
      if (!policies?.length) {
        return null;
      }
      return policies;
    };

    const primaryPolicies = findPolicies("primary");
    const excessPolicies = findPolicies("excess");

    const policies = primaryPolicies || excessPolicies;
    if (!policies) {
      console.error("No matching policies found.");
      return null;
    }

    return {
      buildingLimit: policies[0].buildingLimit,
      contentsLimit: policies[0].contentsLimit,
      improvementsAndBettermentsLimit:
        policies[0].improvementsAndBettermentsLimit || null,
    };
  }

  useEffect(() => {
    setLimits(
      findLimitsByCardData(selectedCardData, premiumResponseData) || {}
    );
  }, [selectedCardData, premiumResponseData]);

  const savePremiumCard = async () => {
    const requestBody = {
      calculatedFees: fees || null,
      includeLossOfUse:
        constructionDetails?.productType === "Commercial"
          ? null
          : includeLossOfUse,
      includeContents: includeContents,
      includeBusinessIncomeAndExtraExpense:
        constructionDetails?.productType === "Residential"
          ? null
          : businessIncomePremium,
      deductible: selectedCardData?.deductible,
      chargedPremium: totalPremium,
      type: preferredType,
      building_coverage: limits?.buildingLimit,
      content_coverage: limits?.contentsLimit,
      improvement_betterment_coverage: limits?.improvementsAndBettermentsLimit,
    };
    const cleanedRequestBody = Object.fromEntries(
      Object.entries(requestBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = premiumResponseData?.data?.hiscoxId;
    try {
      setLoading(true);
      const data = await postSaveHiscoxPremiumCard({
        requestBody: cleanedRequestBody,
        quoteNo,
      });
      if (data?.status === true) {
        toast.success(
          data?.message == "Coverage Update successfully"
            ? "Coverage Updated"
            : data?.message,
          {
            autoClose: 1500,
          }
        );
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      totalPremium !== null &&
      totalPremium !== undefined &&
      Object.keys(selectedCardData).length > 0
    ) {
      fetchFees();
    }
  }, [totalPremium]);

  async function fetchFees() {
    if (!locationQuoteObject || !locationQuoteObject.stateCode) {
      console.error("Invalid locationQuoteObject or missing stateCode");
      return;
    }
    if (isNaN(Number(totalPremium) || totalPremium === "")) {
      console.error("Invalid totalPremium, cannot fetch fees");
      return;
    }
    const requestBody = {
      state: locationQuoteObject.stateCode || "",
      productId: coreSystem?.applicationData?.n_ProductId_FK || "26", //for example Hiscox Product ID : 26
      premium: Number(totalPremium),
      prTransTypeCode: "NEWBUSINESS",
    };
    setFeeLoader(true);
    try {
      const fees = await getFees(requestBody, productID);
      if (fees?.data?.success) {
        setFees(fees?.data?.data);
        setPremiumFeesData(fees?.data?.data);
      } else {
        setFees(null);
      }
      return fees;
    } catch (error) {
      console.error("Failed to calculate fees:", error);
      return null;
    } finally {
      setFeeLoader(false);
    }
  }

  return (
    <Modal
      size="xl"
      scrollable
      show={showModal}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center d-flex align-items-center gap-2">
          <h2>{t("Customize")}</h2>

          <MdEditNote size={40} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm mb-3">
                <Card.Header className="bg-light text-center">
                  <strong>
                    {t("Selected Deductible")}: $
                    {new Intl.NumberFormat("en-US").format(
                      selectedCardData?.deductible
                    ) || 0}
                  </strong>
                </Card.Header>
                <Card.Body>
                  <Table size="sm" className="table ">
                    <tbody>
                      {selectedCardData &&
                        typeof selectedCardData === "object" &&
                        !Array.isArray(selectedCardData) &&
                        Object.entries(selectedCardData)
                          .filter(([key]) => key !== "deductible")
                          .map(([key, value], index) => {
                            let rowClass = "";
                            if (
                              (key === "contentsPremium" && !includeContents) ||
                              (key === "lossOfUsePremium" &&
                                !includeLossOfUse) ||
                              (key === "businessIncomePremium" &&
                                !businessIncomePremium)
                            ) {
                              rowClass = "strikethrough";
                            }
                            return (
                              <tr key={index} className={rowClass}>
                                <td>{standardizeKeyName(key)}</td>
                                <td className="text-end text-nowrap align-middle">
                                  <Currency amount={value} />
                                </td>
                              </tr>
                            );
                          })}
                      <tr className="fw-bold">
                        <td>Total Premium</td>
                        <td className="text-end text-nowrap align-middle">
                          <Currency amount={calculateTotalPremium()} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm mb-3">
                <Card.Header className=" d-flex align-items-center gap-2 bg-light text-center justify-content-center">
                  <strong>Fees</strong>{" "}
                  {feeLoader && <Spinner animation="border" size="sm" />}
                </Card.Header>
                <Card.Body>
                  <FeeTable fees={fees} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={12} sm={10} md={8}>
              <Card
                className="shadow-sm"
                style={{ opacity: feeLoader ? 0.5 : 1 }}
              >
                <Card.Header className="bg-light text-center">
                  <strong>{t("Current Premium")}</strong>
                </Card.Header>
                <Card.Body>
                  <p className="display-2 text-center text-secondary">
                    $
                    {new Intl.NumberFormat("en-US").format(
                      Number(calculateTotalPremium() || 0) +
                        Number(fees?.totalFee || 0)
                    )}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={8}>
              <Card className="shadow-sm">
                <Card.Header className="bg-light text-center">
                  <strong>{t("Include Options")}</strong>
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    {selectedCardData &&
                      "lossOfUsePremium" in selectedCardData && (
                        <Form.Check
                          style={{ userSelect: "none" }}
                          className="custom-switch"
                          id="includeLossOfUseSwitch"
                          type="switch"
                          label={t("Include Loss of Use")}
                          checked={includeLossOfUse}
                          onChange={(e) =>
                            setIncludeLossOfUse(e.target.checked)
                          }
                        />
                      )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      style={{ userSelect: "none", cursor: "pointer" }}
                      className="custom-switch"
                      id="includeContentsSwitch"
                      type="switch"
                      label={t("Include Contents")}
                      checked={includeContents}
                      onChange={(e) => setIncludeContents(e.target.checked)}
                    />
                  </Form.Group>
                  {selectedCardData &&
                    "businessIncomePremium" in selectedCardData && (
                      <Form.Group className="mb-3">
                        <Form.Check
                          style={{ userSelect: "none", cursor: "pointer" }}
                          className="custom-switch"
                          id="businessIncomePremiumSwitch"
                          type="switch"
                          label="Business Income Premium"
                          checked={businessIncomePremium}
                          onChange={(e) =>
                            setBusinessIncomePremium(e.target.checked)
                          }
                        />
                      </Form.Group>
                    )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="gap-2 my-1">
        <Button
          borderRadius={"xl"}
          leftIcon={<AiFillCloseCircle size={20} />}
          onClick={handleCloseModal}
        >
          {t("Close")}
        </Button>
        <Button
          borderRadius={"xl"}
          leftIcon={<AiFillCheckCircle size={20} />}
          onClick={onSaveClick}
          isLoading={feeLoader}
        >
          {t("Save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
