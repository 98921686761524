import React from 'react';
import ReactDOM from 'react-dom';
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";
import './index.scss';
// Optional theme CSS
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "./i18n";
import LangContextProvider from "./contexts/LangContext";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store"
import { LicenseManager } from "ag-grid-enterprise";
import { ChakraProvider, extendTheme, withDefaultColorScheme, } from '@chakra-ui/react'
import { isTenancyEnabledForNewLayout } from "./pages/common/Helper";
import overrides from "./theme/base";
import blueTheme from "./theme/base/foundations/colorBlue";
import tenantStarlight from "./theme/base/foundations/tenantStarlight";
import tenantFarmers from "./theme/base/foundations/tenantFarmers";
import tenantPti from "./theme/base/foundations/tenantPti";

import { tenant } from "./pages/common/Helper";
import { starTenancySynonyms } from './pages/quote/common/tenantSynonyms';

let extendedOverrides = { ...overrides };
/*** TMP CODE ***/
if (starTenancySynonyms.includes(tenant)) {
  extendedOverrides = { ...overrides, ...{ colors: tenantStarlight } };
}
if (["farmers"].includes(tenant)) {
  extendedOverrides = { ...overrides, ...{ colors: tenantFarmers } };
}
if (["pti"].includes(tenant)) {
  extendedOverrides = { ...overrides, ...{ colors: tenantPti } };
}
/*** END ***/
const theme = extendTheme(
  extendedOverrides,
  withDefaultColorScheme({ colorScheme: "primary" })
);

if (process.env.REACT_APP_AGGRID_LICENSEKEY) {
  LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSEKEY);
}

ReactDOM.render(
  <React.StrictMode>
    <LangContextProvider>
      <PersistGate persistor={persistor}>
        {isTenancyEnabledForNewLayout ? (<ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>) : (<App />)}
      </PersistGate>
    </LangContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
