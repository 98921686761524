import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getFloodPaymentInfo, getEditPaymentData, payOnline } from "../../../../../services/floodService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import "react-overlay-loader/styles.css";
import "./Payment.scss";

const FloodCCPayment = (props) => {
    const { poTransPk } = props;
    const { apiLoading, initPaymentObject } = useSelector(state => state.flood);
    const [initPaymentInfo, setInitPaymentInfo] = useState({});
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [validated, setValidated] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    // trigger on load
    useEffect(() => {
        getPaymentInfo(poTransPk)
    }, []);

    useEffect(() => {
        if (Object.keys(initPaymentObject).length > 0) {
            setInitPaymentInfo(initPaymentObject)
            setPaymentAmount(initPaymentObject.paymentAmount);
        }
    }, [initPaymentObject]);

    const getPaymentInfo = async (poTransPk) => {
        await dispatch(getFloodPaymentInfo(poTransPk));
    };

    const handleChange = (key, value) => {
        var paymentData = { ...initPaymentObject };
        paymentData[key] = value;
        dispatch(getEditPaymentData(paymentData));
    };

    const handleSubmit = async () => {
        var isValid = true;
        var paymentData = { ...initPaymentObject };
        // validation
        for (const [key, value] of Object.entries(paymentData)) {
            if (key === 's_EmailAddress' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                isValid = false;
                toast.error(t('Enter email address'));
                break;
            }
            if ((!['accNumber', 'rtnNumber', 'accType'].includes(key)) && (['', null].includes(value))) {
                isValid = false;
                break;
            }
        }
        if (!['', null].includes(paymentData.expiry)) {
            var expiry = paymentData.expiry.replaceAll('_', '');
            expiry = expiry.replaceAll('-', '')
            if (expiry.length !== 6) {
                isValid = false;
                toast.error(t('Please Enter Expiry Date'));
            }
        }
        if (isValid === false) {
            setValidated(!isValid)
        } else {
            setValidated(!isValid)
            const [response, error] = await dispatch(payOnline(paymentData));
            if (error) {
                toast.error(error);
                // props.afterPayment is for endorsement process
                if (props.afterPayment) {
                    props.afterPayment(false);
                } else {
                    navigation("/flood/thank-you");
                }
            }
            if (response.data.transaction_status === 'Success') {
                toast.success(response.data.return_detail);
                // props.afterPayment is for endorsement process
                if (props.afterPayment) {
                    props.afterPayment(false);
                } else {
                    navigation("/flood/thank-you");
                }
            } else if (response.data.transaction_status === 'PaymentExists') {
                toast.error(response.data.return_detail);
                 // props.afterPayment is for endorsement process
                 if (props.afterPayment) {
                    props.afterPayment(false);
                } else {
                    navigation("/flood/payment-exist");
                }
            } else {
                toast.error(response.data.return_detail);
                // props.afterPayment is for endorsement process
                if (props.afterPayment) {
                    props.afterPayment(false);
                } else {
                    navigation("/flood/thank-you");
                }
            }
        }
    }

    return (
        <LoadingOverlay >
            <Loader loading={apiLoading} />
            <section className="flood-payment-section">
                {/* Form */}
                <Row className="justify-content-center">
                    <Col
                        xs="12"
                        sm="12"
                        md="10"
                        lg="8"
                        xl="8"
                        className="flood-payment-form"
                    >
                        <Row className="justify-content-center" id="flood-payment-div">
                            <Col xs="12" sm="10" md="10" lg="10" xl="10">
                                <div id="paid-amount">
                                    <h1>{t('Pay Now')}</h1>
                                    <div className="flood-pricing">
                                        <h5 className="flood-pricing-title">
                                            {t('Paid Amount')}
                                        </h5>
                                        <p className="flood-pricing-value">
                                            {'$' + paymentAmount}
                                        </p>
                                    </div>
                                </div>
                                <Row className="justify-content-start">
                                    <Col xs="12">
                                        <Form validated={validated} className="payment-form">
                                            <Row>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Policy Number')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Policy Number")}
                                                            name="policyNumber"
                                                            id="policyNumber"
                                                            title={t('Policy Number')}
                                                            value={initPaymentInfo.Risk_Id || ''}
                                                            required
                                                            readOnly
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Name as on card')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Name as on card")}
                                                            name="Insured_Name"
                                                            id='Insured_Name'
                                                            title={t('Name as on card')}
                                                            value={initPaymentInfo.Insured_Name || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Add Full Name Error")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Billing Address')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Billing Address")}
                                                            name="s_AddressLine1"
                                                            id='s_AddressLine1'
                                                            title={t('Billing Address')}
                                                            value={initPaymentInfo.s_AddressLine1 || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            readOnly
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please enter address!")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('City')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("City")}
                                                            name="s_CityName"
                                                            id='s_CityName'
                                                            title={t('City')}
                                                            value={initPaymentInfo.s_CityName || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            readOnly
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please enter City")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="3" lg="3" xl="3">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('State')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("State")}
                                                            name="s_StateCode"
                                                            id='s_StateCode'
                                                            title={t('City')}
                                                            value={initPaymentInfo.s_StateCode || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            readOnly
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please enter State")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="3" lg="3" xl="3">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Zip Code')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Zip Code")}
                                                            name="s_PostalCode"
                                                            id='s_PostalCode'
                                                            title={t('Zip Code')}
                                                            value={initPaymentInfo.s_PostalCode || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            readOnly
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please enter Zip")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Phone Number')}
                                                        </Form.Label><br />
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            type='text'
                                                            name='s_PhoneNumber'
                                                            id='s_PhoneNumber'
                                                            mask="(111)111-1111"
                                                            placeholder={t('Phone Number')}
                                                            title={t('Phone Number')}
                                                            value={initPaymentInfo.s_PhoneNumber || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Add Mobile Number Error")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Credit Card Number')}
                                                        </Form.Label><br />
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            type='text'
                                                            name='ccNumber'
                                                            id='ccNumber'
                                                            mask="1111-1111-1111-1111"
                                                            placeholder={t('Credit Card Number')}
                                                            title={t('Credit Card Number')}
                                                            value={initPaymentInfo.ccNumber || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Enter Credit Card Number")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="8" lg="8" xl="8">
                                                    <Form.Group className="payment-field" >
                                                        <Form.Label className="payment-field-label">
                                                            {t('Expiry Date')}
                                                        </Form.Label><br />
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            type='text'
                                                            name='expiry'
                                                            id='expiry'
                                                            mask="11-1111"
                                                            size={6}
                                                            placeholder={t('Expiry Date')}
                                                            title={t('Expiry Date')}
                                                            value={initPaymentInfo.expiry || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Enter Expiry Date")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('CVV')}
                                                        </Form.Label><br />
                                                        <MaskedFormControl
                                                            className="payment-field-input"
                                                            type='text'
                                                            name='cvv'
                                                            id='cvv'
                                                            mask="1111"
                                                            size={4}
                                                            placeholder={t('CVV')}
                                                            title={t('cvv')}
                                                            value={initPaymentInfo.cvv || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Enter CVV")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                    <Form.Group className="payment-field">
                                                        <Form.Label className="payment-field-label">
                                                            {t('Payment Amount')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="payment-field-input"
                                                            type="text"
                                                            placeholder={t("Payment Amount")}
                                                            name="Amount"
                                                            id="Amount"
                                                            title={t('Payment Amount')}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            value={'$' + (initPaymentInfo.Amount || 0)}
                                                            readOnly
                                                            required
                                                        />
                                                    </Form.Group>
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("Please Enter Payment Amount")}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col xs="12" sm="12" md="8" lg="8" xl="8">
                                                    <Form.Group
                                                        className="account-form-group"
                                                    >
                                                        <Form.Label className="myform-label">
                                                            {t('Confirmation Email To')}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="myform-input"
                                                            type="email"
                                                            placeholder={t("Confirmation Email To")}
                                                            name="s_EmailAddress"
                                                            title={t('Email')}
                                                            id='s_EmailAddress'
                                                            value={initPaymentInfo.s_EmailAddress || ''}
                                                            onChange={(e) => handleChange(e.currentTarget.name, e.currentTarget.value)}
                                                            required
                                                            disabled={apiLoading}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {t("Please Add Valid Email Address Error")}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs="12" className="contact-form-btns">
                                <div
                                    className="pay-now-action-btn"
                                    style={{ marginBottom: "18px" }}
                                >
                                    <Button
                                        id="primary-colored-btn"
                                        variant="primary"
                                        onClick={() => handleSubmit()}
                                    >
                                        <span>{t('Pay Now')}</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </LoadingOverlay >
    );
};

export default FloodCCPayment;
