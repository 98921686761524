import Http from "../../../../Http";

export function postEndorseBind({ requestBody, policyId }) {
  return new Promise((resolve, reject) => {
    const url = `api/v2/policy/hiscox/endorse/bind/${policyId}`;
    Http.post(url, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}
