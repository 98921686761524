import React from "react";
import {
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Box,
} from "@chakra-ui/react";
import { BsDashCircle } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const elevatorSafetyOptions = [
  { name: "NA", icon: "/starImages/miniIcons/none.svg" },
  { name: "Certified", icon: "/starImages/miniIcons/certified.svg" },
  { name: "None", icon: "/starImages/miniIcons/none.svg" },
];

const ElevatorSafetyCard = ({ name, icon: Icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={
      name === "NA" ? (
        <Box pr={1}>
          <BsDashCircle size={25} />
        </Box>
      ) : (
        <Image src={Icon || ""} width="2em" height="1.5em" />
      )
    }
    onClick={onClick}
  >
    {name}
  </Button>
);

export const ElevatorSafetySelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails } = useQuote();
  const { t } = useTranslation();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "elevatorSafety", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Elevator Safety")}:</Text>{" "}
        {buildingDetails[index]?.elevatorSafety && (
          <Text fontWeight="500" color={"neutral.300"}>
            ( {buildingDetails[index]?.elevatorSafety} )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {elevatorSafetyOptions.map(({ name, icon }) => (
          <GridItem key={name}>
            <ElevatorSafetyCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.elevatorSafety === name}
              onClick={() => handleSelection(name)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
