import React from "react";
import { Row, Col, Form, Tabs, Tab, Table, Button } from "react-bootstrap";
import { FaRegIdBadge, FaRegFileImage } from "react-icons/fa";
import BindingTab from "./BindingTab";
import ContactTab from "./ContactTab";
import FaqTab from "./FaqTab";
import "./other-links.scss";

const OtherLinks = () => {
  return (
    <div className="other-links-page-section">
      <h5 className="other-links-title">Other Important Links</h5>
      <Row>
        <Col xs="12" id="other-links-tabs-section" className="custom-tabs">
          <Tabs defaultActiveKey="application" id="uncontrolled-tab-example">
            <Tab eventKey="application" title="FAQs">
              <FaqTab />
            </Tab>
            <Tab eventKey="coverage" title="Contact Avatar">
              <ContactTab />
            </Tab>

            <Tab eventKey="property-info" title="Binding Suspended">
              <BindingTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default OtherLinks;
