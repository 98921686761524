import { Accordion, Button } from "react-bootstrap";
import { Premium } from "./components/Premium/Premium";
import { PropertyDetails } from "./components/Location/PropertyDetails";
import { ConstructionDetails } from "./components/ConstructionDetails/ConstructionDetails";
import ApplicantInformation from "./components/ApplicantInformation";
import { CreateQuoteContext, useMyContext } from "./CreateQuote";
import { useEffect, useState } from "react";
import getResidentialRequestBody from "./components/ApiRequestBodies/ResidentialRequestBody";
import getCommercialOwnedRequestBody from "./components/ApiRequestBodies/CommercialOwnedReqBody";
import getCommercialTenantedRequestBody from "./components/ApiRequestBodies/CommercialTenantedReqBody";
import { useHiscoxCreateQuote } from "./api/useHiscoxCreateQuote";
import { useNavigate, useParams } from "react-router-dom";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { toast } from "react-toastify";
import FinancialDetails from "./components/FinancialDetails/FinancialDetails";
import { t } from "i18next";
import AdditionalInsured from "./components/AdditionalInformation/AdditionalInsured";
import Mortgagees from "./components/AdditionalInformation/Mortgagees";

const FormMode = () => {
  const [quoteObject, setQuoteObject] = useState({});
  const { loading, quoteData, createQuote } = useHiscoxCreateQuote();
  const [hicoxQuoteID, setHicoxQuoteID] = useState("N/A");
  const { productID } = useParams();
  const navigation = useNavigate();
  const {
    choosedPreferredCard,
    constructionDetails,
    locationQuoteObject,
    financialDetails,
    applicantDetails,
    setIsNextClicked,
    additionalInsured,
  } = useMyContext(CreateQuoteContext);
  const continueToApplication = () => {
    if (choosedPreferredCard) {
      navigation(`/quote/${productID}/application/${hicoxQuoteID}`);
    }
  };

  useEffect(() => {
    if (quoteData) {
      setHicoxQuoteID(quoteData?.data?.hiscoxId);
    }
    setIsNextClicked(true);
  }, [quoteData]);

  useEffect(() => {
    const { productType, commercialProductType } = constructionDetails;
    let requestBody = {};
    if (productType === "Residential") {
      requestBody = getResidentialRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        additionalInsured,
      });
    } else if (
      productType === "Commercial" &&
      commercialProductType === "Owned"
    ) {
      requestBody = getCommercialOwnedRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        additionalInsured,
      });
    } else if (
      productType === "Commercial" &&
      commercialProductType === "Tenanted"
    ) {
      requestBody = getCommercialTenantedRequestBody({
        constructionDetails,
        locationQuoteObject,
        financialDetails,
        applicantDetails,
        additionalInsured,
      });
    }
    setQuoteObject(requestBody);
  }, [
    locationQuoteObject,
    constructionDetails,
    financialDetails,
    applicantDetails,
    additionalInsured,
  ]);

  const applicantDetailsElements = [
    {
      name: "firstName",
      value: applicantDetails?.firstName,
    },
    {
      name: "lastName",
      value: applicantDetails?.lastName,
    },
    {
      name: "email",
      value: applicantDetails?.email,
    },
    {
      name: "phoneNumber",
      value: applicantDetails?.contactNo,
    },
  ];

  const allDetailsFilled =
    applicantDetailsElements.every(
      (item) =>
        item.value !== "" && item.value !== null && item.value !== undefined
    ) || false;

  const onClickGeneratePremium = () => {
    if (allDetailsFilled) {
      createQuote(quoteObject);
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  const {
    occupancyType,
    constructionType,
    foundationType,
    buildingWaterType,
    yearBuilt,
    squareFootage,
    noOfStories,
    requestDate,
  } = constructionDetails;

  const constructionDetailsFilled = [
    occupancyType,
    constructionType,
    foundationType,
    buildingWaterType,
    yearBuilt == 0 ? "" : yearBuilt,
    squareFootage == 0 ? "" : squareFootage,
    noOfStories == 0 ? "" : noOfStories,
    requestDate,
  ].every((detail) => detail !== "");

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />

        <Accordion
          defaultActiveKey={["0", "1", "2", "3", "4"]}
          alwaysOpen
          className="custom-quote-accordion"
          style={{
            padding: "0.1rem",
          }}
        >
          <Accordion.Item eventKey="0" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Property Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <PropertyDetails />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Applicant Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <ApplicantInformation />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Construction Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <ConstructionDetails />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Financial Details")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <FinancialDetails />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Additional Information")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <Mortgagees />
              <AdditionalInsured />
              </Accordion.Body>
          </Accordion.Item>
          <div
            className="pt-4 pb-2"
            style={{
              display: "flex",
              padding: "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              id="primary-colored-btn"
              size="lg"
              style={{ width: "100%", maxWidth: "200px" }}
              onClick={onClickGeneratePremium}
              disabled={!allDetailsFilled || !constructionDetailsFilled}
            >
              {t("Generate Premium")}
            </Button>
          </div>
          <Accordion.Item eventKey="4" className="mt-2">
            <Accordion.Header className="search-quote-card-header">
              <span>{t("Premium")}</span>
            </Accordion.Header>
            <Accordion.Body className="search-quote-card-body">
              <Premium />
            </Accordion.Body>
            <div
              className="pt-4 pb-2"
              style={{
                display: "flex",
                padding: "1rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                id="primary-colored-btn"
                size="lg"
                disabled={!choosedPreferredCard}
                onClick={continueToApplication}
              >
                {t("Continue")}
              </Button>
            </div>
          </Accordion.Item>
        </Accordion>
      </LoadingOverlay>
    </>
  );
};

export default FormMode;
