import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { customStyles } from "../../../../../components/reactSelectCustomStyle";
import "react-datepicker/dist/react-datepicker.css";
import "./mode-waiting-period.scss";
import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../../services/floodService";


const ModeWaitingPeriod = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { initApiObject, floodSystemDD } = useSelector(state => state.flood);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [waitingPeriod, setWaitingPeriod] = useState('STANDARD30');
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isWPChange, setIsWPChange] = useState(false);
  const [NAICList, setNAICList] = useState({});
  const [PPED, setPPED] = useState(null);
  const [priorPolicyNo, setPriorPolicyNo] = useState('');
  const [priorCompanyNo, setPriorCompanyNo] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);
  // set initial state from reducer
  useEffect(() => {
    if (!isStateSet) {
      if (Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.initApiObject).length > 0) {
        if (props.checkDateisValid(props.emptyQuoteData.effectiveDate)) {
          setEffectiveDate(new Date(props.emptyQuoteData.effectiveDate));
        }
        // update date if null
        if ([null, ''].includes(props.emptyQuoteData.effectiveDate)) {
          var tempCurrDate = new Date();
          var date = tempCurrDate.setDate(tempCurrDate.getDate() + 30);
          props.handleDateChange(new Date(date), 'effectiveDate');
          setEffectiveDate(new Date(date));
        }
        if (![null, ''].includes(props.emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod)) {
          setWaitingPeriod(props.emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod);
        }
        if (props.checkDateisValid(props.emptyQuoteData.propertyInfo.s_PriorPolicyExpDt)) {
          setPPED(new Date(props.emptyQuoteData.propertyInfo.s_PriorPolicyExpDt) || null);
          setPriorPolicyNo(props.emptyQuoteData.propertyInfo.s_PriorPolicyNo || null);
          setPriorCompanyNo(props.emptyQuoteData.propertyInfo.s_PriorCompanyNAIC || null);
        }

        if (Object.keys(floodSystemDD).length > 0) {
          setNAICList(floodSystemDD.WYOList);
        }
        setIsStateSet(true);
      }
    }
  }, [props.emptyQuoteData, props.initApiObject])

  // trigger on update waiting period to set effective date based on waiting period type
  useEffect(() => {
    var date = new Date(); // current date
    var tempCurrDate = new Date(); // copy of current Date
    var minDate = '', maxdate = '';
    if (waitingPeriod === "STANDARD30" || waitingPeriod === "LENDER30") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 30); // ADD 30 DAYS
      minDate = date;
    } else if (waitingPeriod === "MAPREVONEDAY") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 1); // ADD 1 DAYS
      minDate = date;
    } else if (waitingPeriod === "LOANNOWAIT") {
      // Not For Risn Rating 2.0
      if (!props.isRiskRating2) {
        minDate = date;
        maxdate = tempCurrDate.setDate(tempCurrDate.getDate() + 60); // ADD 60 DAYS
      }
    } else if (waitingPeriod === "ROLLOVER_RENEWAL") {
      var temp = new Date(); // current date
      minDate = tempCurrDate.setDate(tempCurrDate.getDate() - 29); // SUB 29 DAYS
      maxdate = temp.setDate(temp.getDate() + 60); // ADD 60 DAYS
    }

    setMinDate(minDate); // setting min date
    setMaxDate(maxdate) // setting max date
    if (isWPChange) {
      setIsWPChange(false);
      var effDate = new Date(date);
      if (waitingPeriod === "ROLLOVER_RENEWAL") {
        effDate = '';
        setPPED(effDate);
        props.handleDateChange(effDate, 's_PriorPolicyExpDt', 'propertyInfo');
      }
      setEffectiveDate(effDate); // setting effective date
      props.handleDateChange(effDate, 'effectiveDate');
      if (waitingPeriod === "LOANNOWAIT") {
        props.handleDateChange(effDate, 'd_FloodLoanClosingDt', 'propertyInfo');
      }
    }

    if (Object.keys(props.emptyQuoteData).length > 0 && Object.keys(initApiObject).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...props.emptyQuoteData }, reqJson = { ...initApiObject }; // Copy
      emptyQuote.propertyInfo.s_PolicyWaitingPeriod = waitingPeriod
      var temp = 'NO', newRolloverTransferType = 'N';
      if (waitingPeriod === "ROLLOVER_RENEWAL") {
        temp = 'YES'; newRolloverTransferType = 'T';
      } else {
        emptyQuote['propertyInfo']['s_PriorPolicyNo'] = null;
        emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = null;
      }
      // emptyQuote.propertyInfo.s_IsNewPurchase = temp
      dispatch(getEditEmptyQuoteData(emptyQuote));

      // For Pivot Api Object Update
      reqJson.newRolloverTransferType = newRolloverTransferType;
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [waitingPeriod])

  useEffect(() => {
    if (Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.initApiObject).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...props.emptyQuoteData };
      emptyQuote.transactionInfo.d_TransEffectiveFrom = props.emptyQuoteData.effectiveDate

      // For Pivot Api Object Update
      const reqJson = { ...props.initApiObject }; // Copy
      reqJson.effectiveDate = props.emptyQuoteData.effectiveDate

      dispatch(getEditEmptyQuoteData(emptyQuote));
      dispatch(updateInitQuoteApi(reqJson));

      var loanClosingDate = null, priorPolixyExDate = null;
      if (['LOANNOWAIT'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        loanClosingDate = effectiveDate;
      }
      if (['ROLLOVER_RENEWAL'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        priorPolixyExDate = effectiveDate;
      }
      props.handleDateChange(loanClosingDate, 'd_FloodLoanClosingDt', 'propertyInfo');
      props.handleDateChange(priorPolixyExDate, 's_PriorPolicyExpDt', 'propertyInfo');
    }
  }, [effectiveDate]);

   // update state in reducer
   const updateReducerState = (value, type) => {
    var emptyQuote = props.emptyQuoteData, reqJson = { ...props.initApiObject }; // Copy
    if(type === 'PPN'){
      setPriorPolicyNo(value);
      emptyQuote['propertyInfo']['s_PriorPolicyNo'] = value;
      reqJson.priorPolicyNumber = value;
    } else {
      setPriorCompanyNo(value.s_NAICNumber);
      emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = value.s_NAICNumber;
      reqJson.priorNaicNumber = value.s_NAICNumber;
    }
    dispatch(getEditEmptyQuoteData(emptyQuote));
    dispatch(updateInitQuoteApi(reqJson));
  }

  return (
    <section className="waiting-period-section">
      <Form validated={validated} className="confirm-address-form">
        <Row>
          <Col xs="12">
            <Form.Group
              className="confirm-address-field"
              controlId="formBasicEmail"
            >
              <Form.Label className="confirm-address-label">
                {t('Waiting Period')}
              </Form.Label>
              <Form.Select
                className="confirm-address-input"
                name="s_PolicyWaitingPeriod"
                title={t('Waiting Period')}
                parentobject="propertyInfo"
                value={waitingPeriod}
                onChange={(e) => {
                  setWaitingPeriod(e.currentTarget.value);
                  setIsWPChange(true);
                }}
              // required
              >
                <option value={''}>{t('Select')}</option>
                {
                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODWAITINGPERIOD.map((v, i) => {
                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                  })
                }
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {t("Please Select Waiting Period Error")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {
            waitingPeriod === "ROLLOVER_RENEWAL" && (
              <>
                <Col xs="12">
                  <Form.Group
                    className="confirm-address-field"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="confirm-address-label">
                      {t('Prior Policy No')}
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input-flood"
                      type="number"
                      placeholder="0000000000"
                      name="s_PriorPolicyNo"
                      title={t('Policy NO#')}
                      parentobject="propertyInfo"
                    value={priorPolicyNo || ''}
                    min={0}
                    onChange={(e) => {
                      props.handleChange(e);
                      updateReducerState(e.currentTarget.value, 'PPN');
                    }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("Please Enter Prior Policy No")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs="12">
                  <Form.Group
                    className="confirm-address-field"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="confirm-address-label">
                      {t('Prior Company NAIC')}
                    </Form.Label>
                    {
                      Object.keys(NAICList).length > 0 && (
                        <Select
                          styles={customStyles}
                          title="propertyInfo"
                          name="s_PriorCompanyNAIC"
                          options={NAICList}
                        value={NAICList.filter(item => item.s_NAICNumber === priorCompanyNo)}
                        getOptionLabel={(option) => option.s_WYOCompany}
                        getOptionValue={(option) => option.s_NAICNumber}
                        onChange={(value) => updateReducerState(value, 'NAIC')}
                        />
                      )
                    }
                  </Form.Group>
                </Col>
                <Col xs="12">
                  <Form.Group
                    className="confirm-address-field"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="confirm-address-label">
                      {t('Prior Policy Exp')}
                    </Form.Label>
                    <DatePicker
                      className="custom-info-date-picker"
                      placeholderText="MM/DD/YYYY"
                      selected={PPED}
                      onChange={(date) => {
                        setPPED(date);
                        setEffectiveDate(date);
                        props.handleDateChange(date, 's_PriorPolicyExpDt', 'propertyInfo');
                        props.handleDateChange(date, 'effectiveDate');
                      }}
                      minDate={minDate}
                      maxDate={maxDate}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </>
            )
          }
          <Col xs="12">
            <Form.Group
              controlId="formBasicPassword"
              className="confirm-address-field"
            >
              <Form.Label className="confirm-address-label">
                {waitingPeriod === 'LOANNOWAIT' ? t('Loan Closing Date') : t('Effective Date')}
              </Form.Label>
              <DatePicker
                className="custom-info-date-picker"
                placeholderText="MM/DD/YYYY"
                selected={effectiveDate}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(date) => {
                  setEffectiveDate(date);
                  props.handleDateChange(date, 'effectiveDate');
                }}
                disabled={waitingPeriod === 'ROLLOVER_RENEWAL' ? true : false}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
    initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},

  };
}
export default connect(mapStateToProps)(ModeWaitingPeriod);