export default {
  ":root": {
    colorScheme: "only light",
  },
  "html, body": {
    fontSize: "16px",
  },
  "*:focus": {
    outline: 0,
    // borderColor: "inherit !important",
    boxShadow: "none !important",
  },
};
