import React from 'react'
import Moment from 'moment';
import Http from '../Http';

const authUrl = process.env.REACT_APP_AUTH_CORE_URL;

// DataTable Paging Showing Start, To and Total Data Text
export function showDataTableTotalText(start, to, total) {
  return (
    <p style={{ color: 'blue' }}>
      From {start} to {to}, total is {total}.
    </p>
  );
}

// Curency Formater
export function currencyFormat(amount) {
  var amt = 0;
  var currencyType = 'en-US'; // en-IN
  var currency = 'USD' // INR

  let amountFloat = parseFloat(amount);
  if (amountFloat > 0) {

    amt = new Intl.NumberFormat(currencyType, {
      style: 'currency',
      currency: currency
    }).format(amountFloat);

  } else {
    if (amountFloat == 0 || isNaN(amountFloat)) {
      amt = '$0.00';
    } else {
      var value = new Intl.NumberFormat(currencyType, {
        style: 'currency',
        currency: currency
      }).format(amountFloat);
      amt = <span style={{ color: 'red' }}>{'$(' + value.replace(/[-$]/g, '') + ')'}</span>;
    }
  }

  return amt;
}

// to encrypt the given Object and store in localStorage
export function EncryptOrDecrypt(data, condition = 'E') {
	
	var CryptoJS = require("crypto-js");
	var key = process.env.REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY;
	
	var returnData = '';
	if(condition == 'E') {
		
		returnData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
	}
	
	if(condition == 'D') {
    
    if(data !== null ){
      var bytes = CryptoJS.AES.decrypt(data, key);
      returnData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
	}
	return returnData;
}

// Converting Date into MM/DD/YYYY
export function dateFormat(paramsDate, dateFormat) {
  if (paramsDate != '' && paramsDate != undefined) {
    const date = new Date(paramsDate);
    return Moment(date).format(dateFormat);
  }
  // return new Intl.DateTimeFormat('en-US').format(date);
}

export function getCompanyDetails(field = "") {
  var result = EncryptOrDecrypt(localStorage.getItem('user'), 'D')['companyDetails'] || {};

  switch (field) {
    case "phone":
      result = result.phone_no || "NA";
      break;

    case "email":
      result = result.email || "NA";
      break;

    default:
      break;
  }

  return result;
}

export function checkPermission(moduleName) {
	return dispatch => (
	  new Promise((resolve, reject) => {
		if (moduleName != '') {
		  Http.get(authUrl + `/api/v1/checkPermission/${moduleName}`)
			.then((res) => {
			  if (res.data.status == 'Y') {
				switch (moduleName) {
				  case 'AGENTPORTAL':
					localStorage.setItem('agentSubModules', JSON.stringify(res.data.subModuleAccess))
					break;
				}
			  }
			  return resolve();
			})
		}
	  })
	)
  }
  
// to check pivot outage
export function checkPivotOutage() {
	var returnData = 'false';
	var pivot_outage = process.env.REACT_APP_PIVOT_OUTAGE ?? 'false';

	if(pivot_outage == 'true') {
		var pivot_outage_tenants = process.env.REACT_APP_PIVOT_OUTAGE_TENANTS ?? '';
		
		if(pivot_outage_tenants == ''){
			returnData = 'true';
		}else{
			var tenantName = window.location.host.split('.')[0];
		
			if(pivot_outage_tenants.includes(tenantName)){
				returnData = 'true';
			}
		}
		
	}
	
	return returnData;
}

/*
* check date if valid or not
* returns true if valid 
*/
export function checkDateisValid(date) {
  return !isNaN(Date.parse(date));
}

/**
 * to check tenant is active or inactive
 * @returns bool
 */
export function isTenantActive() {
  return EncryptOrDecrypt(localStorage.getItem('is_active'), 'D') === 'Y' ? true : false;
}

/**
 * to return currunt tenant id
 * @returns string
 */
export function getTenant() {
  return window.location.host.split('.')[0];
}

export function formatCeDate(date) {
	if(date == 'N/A'){
		return date;
	}else{
		return  dateFormat(date,'MM-DD-YYYY')
	}
  }

/**
 * Common function to set data into local storage from getSitedata api
 */
export function setSiteDataInLocalStorage(data) {
  var companyLogo = "https://uat-odyssey-images.s3.amazonaws.com/your-company-logo-here.png";
  let enableChatBot = "N";
  if (!["", null, undefined].includes(data.url)) {
      companyLogo = data.url;
  }
  if (!["", null, undefined].includes(data?.chat_bot)) {
      enableChatBot = data.chat_bot;
  }
  localStorage.setItem('enable_chat_bot', enableChatBot);
  localStorage.setItem('company_logo', companyLogo);
  localStorage.setItem('member_id',data.member_id );
  localStorage.setItem('is_active',EncryptOrDecrypt(data.is_tenancy_active,'E') );
  localStorage.setItem('sitePrimaryColor', data.mainData[0]['primary_color']);
  localStorage.setItem('siteSecondaryColor', data.mainData[0]['secondary_color']);
}