import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import MaskedFormControl from 'react-bootstrap-maskedinput'
import "./mode-contact-information.scss";
import { getEditEmptyQuoteData } from "../../../../../services/floodService";

const ModeContactInformation = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [memberNo, setMemberNo] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);

  const toggle = (e) => {
    setValidated(e);
  }

  // // Set State from reducer
  useEffect(() => {
    if (!isStateSet) {
      if (Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.initApiObject).length > 0) {
        setName(props.emptyQuoteData.personInfo.s_FullLegalName);
        setEmail(props.emptyQuoteData.personEmailContact.s_CommValue);
        setPhone(props.emptyQuoteData.personPhoneContact.s_CommValue);
        if (props.emptyQuoteData.personInfo.metadata) {
          setMemberNo(props.emptyQuoteData.personInfo.metadata.member_id);
          setIsStateSet(true);
        }
      }
    }
  }, [props.emptyQuoteData, props.initApiObject])

  // Set First Name -Last Name / Additional Interest
  useEffect(() => {
    if (name) {
      props.handleChange({}, name)
    }
  }, [name])

  // set membor no in emptyquote
  const handleMemborNoChange = (value) => {
    var emptyQuote = { ...props.emptyQuoteData };
    emptyQuote.personInfo.metadata = {
      member_id: value
    }
    dispatch(getEditEmptyQuoteData(emptyQuote));
    setMemberNo(value);
  }

  const handleChangeContact = (e) => {
    var quoteDataVar = { ...props.emptyQuoteData };
    const { name, value, attributes } = e.target;
    const parentKey = attributes.parentobject.value;
    quoteDataVar[parentKey][name] = value;
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  return (
    <section className="property-details-section">
      {/* Form */}
      <Form validated={validated} className="confirm-address-form">
        <Row>
          <Col xs="12">
            <Form.Group
              className="confirm-address-field"
              controlId="formBasicEmail"
            >
              <Form.Label className="confirm-address-label">
                {t('Full Name')}
              </Form.Label>
              <Form.Control
                className="confirm-address-input"
                type="text"
                placeholder={t("Enter your name")}
                name="s_FullLegalName"
                title={t('Full Name')}
                parentobject="personInfo"
                value={name}
                defaultValue={''}
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
                onBlur={(e) => {
                  handleChangeContact(e);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t("Please Add Full Name Error")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="6">
            <Form.Group
              controlId="formBasicPassword"
              className="confirm-address-field"
            >
              <Form.Label className="confirm-address-label">
                {t('Email')}
              </Form.Label>
              <Form.Control
                className="confirm-address-input"
                type="email"
                placeholder={t("Enter Email")}
                name="s_CommValue"
                title={t('Email')}
                parentobject="personEmailContact"
                value={email}
                defaultValue={''}
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onBlur={(e) => {
                  handleChangeContact(e);
                }}
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("Please Add Valid Email Address Error")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs="6">
            <Form.Group
              className="confirm-address-field"
              controlId="formBasicPassword"
            >
              <Form.Label className="confirm-address-label">
                {t('Phone Number')}
              </Form.Label>
              <MaskedFormControl
                type='text'
                name='s_CommValue'
                mask="(111)111-1111"
                placeholder={t("Enter Phone Number")}
                title={t('Phone Number')}
                parentobject="personPhoneContact"
                value={phone}
                defaultValue={''}
                onBlur={(e) => {
                  handleChangeContact(e);
                }}
                className="confirm-address-input"
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("Please Add Mobile Number Error")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          {localStorage.getItem('member_id') === 'Y' ?
            <Col xs="6">
              <Form.Group
                className="confirm-address-field"
                controlId="formBasicEmail"
              >
                <Form.Label className="confirm-address-label">
                  {t('Member')}#
                </Form.Label>
                <Form.Control
                  className="confirm-address-input"
                  type="text"
                  placeholder={t("Enter Member")}
                  title={t('Member')}
                  value={memberNo}
                  defaultValue={''}
                  onChange={(e) => {
                    handleMemborNoChange(e.currentTarget.value);
                  }}
                // required
                />
              </Form.Group>
              <Form.Control.Feedback type="invalid">
                {t("Please Add Member#")}
              </Form.Control.Feedback>
            </Col> : null}
        </Row>
      </Form>

      <Row>
        <Col
          xs="12"
          id="flood-contact-btns"
          className="contact-form-btns"
        >
        </Col>
      </Row>
    </section>
  );
};


const mapStateToProps = (state) => {
  return {
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
    initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},
  };
}
export default connect(mapStateToProps)(ModeContactInformation);