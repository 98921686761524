import React, { useEffect, useState } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import "react-overlay-loader/styles.css";
import { getEditEmptyQuoteData } from "../../../../../services/floodService";

const ModeConstructionFormMH = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { handleChange, handleSwitchChange } = props;
  const { emptyQuoteData, apiLoading, floodSystemDD } = useSelector(state => state.flood);
  const [isOnPremanentFoundation, setIsOnPremanentFoundation] = useState('');
  const [isBldgTravelTailer, setIsBldgTravelTailer] = useState('');
  const [isWheelsTiresRemoved, setIsWheelsTiresRemoved] = useState('YES');
  const [howAnchored, setHowAnchored] = useState('');
  const [isHowAnchoredChange, setIsHowAnchoredChange] = useState(false);
  const [serialNo, setSerialNo] = useState('');
  const [info, setInfo] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setIsOnPremanentFoundation(emptyQuoteData.propertyInfo.s_IsPermanentFoundation);
      setIsBldgTravelTailer(emptyQuoteData.propertyInfo.s_IsBldgTravelTailer);
      setHowAnchored(emptyQuoteData.propertyInfo.s_homeAnchored);
      setSerialNo(emptyQuoteData.propertyInfo.s_SerialNo);
    }
  }, [])

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0 && isHowAnchoredChange === true) {
      if (['', null, 'NOTANCHORED'].includes(howAnchored)) {
        const emptyQuote = { ...emptyQuoteData };
        emptyQuoteData.propertyInfo.s_SerialNo = '';
        dispatch(getEditEmptyQuoteData(emptyQuote));
        setSerialNo('');
        setIsHowAnchoredChange(false);
      }
    }
  }, [howAnchored, isHowAnchoredChange])

  // trigger on Update isOnPermanentFoun value
  useEffect(() => {
    var temp = [...info];
    var text = 'Mobile homes and Travel Trailers are not insurable if not on a permanent foundation.';
    if (isOnPremanentFoundation === 'NO') {
      if (temp.indexOf(text) === -1) {
        temp.push(text);
      }
    } else {
      temp.indexOf(text) !== -1 && temp.splice(temp.indexOf(text), 1)
    }
    setInfo(temp);
  }, [isOnPremanentFoundation])

  // trigger on Update isOntravelTail value
  useEffect(() => {
    var temp = [...info];
    var text = 'A Travel Trailers with wheels attached are not eligible for flood insurance.';
    if (isWheelsTiresRemoved === 'NO') {
      if (temp.indexOf(text) === -1) {
        temp.push(text);
      }
    } else {
      temp.indexOf(text) !== -1 && temp.splice(temp.indexOf(text), 1)
    }
    setInfo(temp);
  }, [isWheelsTiresRemoved])

  // to show warning alert box
  useEffect(() => {
    setShowInfo(info.length > 0 ? true : false);
  }, [info])

  // handle radio Change
  const handleStateChange = (e, state) => {
    var temp = 'NO';
    const { value, attributes, checked } = e.target;

    if (checked && ['s_IsPermanentFoundation', 's_IsBldgTravelTailer', 'isWheelsTiresRemoved'].includes(state)) {
      temp = attributes.checkedvalue.value;
    }

    switch (state) {
      case 's_IsPermanentFoundation':
        setIsOnPremanentFoundation(temp);
        break;
      case 's_IsBldgTravelTailer':
        setIsBldgTravelTailer(temp);
        break;
      case 'isWheelsTiresRemoved':
        setIsWheelsTiresRemoved(temp);
        break;
      case 's_homeAnchored':
        setHowAnchored(value);
        setIsHowAnchoredChange(true);
        break;
      case 's_SerialNo':
        setSerialNo(value);
        break;
      default:
        break;
    }

    if (['s_IsPermanentFoundation', 's_IsBldgTravelTailer', 'isWheelsTiresRemoved'].includes(state)) {
      handleSwitchChange(e);
    } else {
      handleChange(e);
    }
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-construction-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <p className="flood-construction-main-desc">
            {t('Please answer the questions to determine eligibility')}.
          </p>
          {/* </div>
                </div> */}
          <Row>
            {
              showInfo && (
                // Warning Info Array
                info.length > 0 && info.map((x, i) => {
                  return (
                    <Col key={i} md="12" className='p-0'>
                      <Alert color={'warning'} isOpen={showInfo} className='p-1 pl-2 m-0 mb-1' >{x}</Alert>
                    </Col>
                  );
                })
              )
            }
            <Col xs="12">
              <Row>
                <Col xs='8' lg='8' md="8" sm="8">
                  <p>
                    {t('Is the home on permanent foundation')}?
                  </p>
                </Col>
                <Col xs='4' lg='4' md="4" sm="4">
                  <div className="flood-construction-row-actions d-flex">
                    <span className="me-2">{t('No')}</span>
                    <div className="custom-main-swtich">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="flood-construction-row-switch"
                        label=""
                        parentobject="propertyInfo"
                        name="s_IsPermanentFoundation"
                        checked={isOnPremanentFoundation === 'YES' ? true : false}
                        onChange={(e) => handleStateChange(e, 's_IsPermanentFoundation')}
                        checkedvalue='YES'
                        uncheckedvalue='NO'
                      />
                    </div>
                    <span className="me-2">{t('Yes')}</span>
                  </div>
                </Col>

              </Row>
            </Col>
            <Col xs="12">
              <Row>
                <Col xs="8" md="8" lg="8" sm="8">
                  <p>
                    {t('Is this home a travel trailer')}?
                  </p>
                </Col>
                <Col xs="4" md="4" lg="4" sm="4">
                  <div className="flood-construction-row-actions d-flex">
                    <span className="me-2">{t('No')}</span>
                    <div className="custom-main-swtich">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="flood-construction-row-switch"
                        label=""
                        parentobject="propertyInfo"
                        name="s_IsBldgTravelTailer"
                        checked={isBldgTravelTailer === 'YES' ? true : false}
                        onChange={(e) => handleStateChange(e, 's_IsBldgTravelTailer')}
                        checkedvalue='YES'
                        uncheckedvalue='NA'
                      />
                    </div>
                    <span className="me-2">{t('Yes')}</span>
                  </div>
                </Col>
              </Row>
            </Col>
            {
              isBldgTravelTailer === 'YES' && (
                <Col xs="12">
                  <Row>
                    <Col xs="8" sm="8" md="8" lg="8">
                      <p>{t('Have the wheels & tires been removed?')}</p>
                    </Col>
                    <Col xs="4" sm="4" lg="4" md="4">
                      <div className="flood-construction-row-actions d-flex">
                        <span className="me-2">{t('No')}</span>
                        <div className="custom-main-swtich">
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="flood-construction-row-switch"
                            label=""
                            parentobject="propertyInfo"
                            name="s_IsBldgTravelTailer"
                            checked={isWheelsTiresRemoved === 'YES' ? true : false}
                            onChange={(e) => handleStateChange(e, 'isWheelsTiresRemoved')}
                            checkedvalue='YES'
                            uncheckedvalue='NO'
                          />
                        </div>
                        <span className="me-2">{t('Yes')}</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )
            }
            <Col xs="12">

              <Row>
                <Col xs="8" sm="8" md="8" lg="8">
                  <p>{t('How is the home anchored')}?</p>
                </Col>
                <Col xs="4" sm="4" md="4" lg="4">
                  <div className="flood-construction2-row-actions">
                    <Form.Select
                      style={{ width: '275px' }}
                      className="myform-input"
                      size='sm'
                      name="s_homeAnchored"
                      parentobject="propertyInfo"
                      value={howAnchored}
                      onChange={(e) => handleStateChange(e, 's_homeAnchored')}
                    >
                      <option value={''}>{t('Select')}</option>
                      {
                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLDHOMEANCHORED.map((v, i) => {
                          return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                        })
                      }
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="8" sm="8" md="8" lg="8">
                  <p>{t('What is the serial / vin number')}?</p>
                </Col>
                <Col xs="4" sm="4" md="4" lg="4">
                  <div className="flood-construction2-row-actions">
                    <Form.Control
                      className="myform-input"
                      type="text"
                      placeholder=""
                      id="flood-constructon-input"
                      title={t('What is the serial / vin number')}
                      name="s_SerialNo"
                      parentobject="propertyInfo"
                      value={serialNo || ''}
                      min={0}
                      onChange={(e) => handleStateChange(e, 's_SerialNo')}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </section>
    </LoadingOverlay>
  );
};

export default ModeConstructionFormMH;
