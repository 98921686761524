import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addiIntData } from "../../../services/coreSystemService";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "./cancel.scss";
import { checkDateisValid } from "../../../services/commonService";
import { InputAdapter, TextMask } from "react-text-mask-hoc";

const CancellationQuestions = (props) => {
    const { cancellationReason, transSubTypeDD, poTransPK, loading, setLoading, applicationData, cancelJSON, setCancelJSON, setDataInCancelJSON, occupancyType, cvgData } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const floodSystemDD = useSelector(state => state.coreSystem.baseData);

    const [supportingDocument, setSupportingDocument] = useState("");
    const [documentionDate, setDocumentionDate] = useState(null);
    const [isAddressMatch, setIsAddressMatch] = useState("NO");
    const [isNameMatch, setIsNameMatch] = useState("NO");
    const [policyHolderSignatute, setPolicyHolderSignatute] = useState("NO");
    const [supportingDocumentDD, setSupportingDocumentDD] = useState([]);
    const [policyHolderLabel, setPolicyHolderLabel] = useState('');

    const [isOtherNFIPPolicy, setIsOtherNFIPPolicy] = useState("NO");
    const [effectiveDateForNFIPPolicy, setEffectiveDateForNFIPPolicy] = useState(null);
    const [isPolicyHolderListedOnDuplicateDecMatch, setIsPolicyHolderListedOnDuplicateDecMatch] = useState("NO");
    const [isAddressMatchLabel, setIsAddressMatchLabel] = useState("");

    const [cancellationReasonForDisplay, setCancellationReasonForDisplay] = useState("");
    const [willPropertyTransNotTakingPlace, setWillPropertyTransNotTakingPlace] = useState("NO");
    const [propertyNotRequiredFI, setPropertyNotRequiredFI] = useState("NO");
    const [doAllLendersDontRequireFI, setDoAllLendersDontRequireFI] = useState("NO");

    // Supporting documents for cancel reason 01 (Building sold)
    const bldgSoldSupportingDocs = [
        'PROOFOFSALE', 
        'PROOFOFREMOVAL', 
        'PROOFOFDEMOLITION', 
        'PROOFOFFORCLOSURE'
    ];

    // Supporting documents for cancel reason 01 (Building sold)
    const contentSoldSupportingDocs = [
        'PROOFOFSALE', 
        'PROOFOFREMOVAL', 
        'PROOFOFDESTRUCTION'
    ];

    useEffect(() => {
        if (cancelJSON) {
            if (
                !cancelJSON.cancellationQuestions.hasOwnProperty("policyHolderLabel") ||
                cancelJSON.cancellationQuestions.policyHolderLabel.length === 0
            ) {
                getAddiInterestData();
            }
            if (Object.keys(cancelJSON.cancellationQuestions).length > 0) {
                setStatePreRequestData();
            }

            // remove document
            if (["04", "08", "28", "60"].includes(cancelJSON.cancelReason)) {
                // for remove from required doc
                var tempDocJSON = {
                    "remove": true,
                    "docScreenName": "Cancellation Supporting Document",
                };
                setDataInCancelJSON(tempDocJSON, "document");
            }

            // set dropdown 
            if (Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODCANCELDOC) {
                var dropdown = [];

                if (cancellationReason === "01") {
                    dropdown = floodSystemDD.FLOODCANCELDOC.filter(x => bldgSoldSupportingDocs.includes(x.s_AppCodeName));
                }

                if (cancellationReason === "02") {
                    var bldgCvgAmt = cvgData['FLDBLDCVGAMT'] || 0;
                    var cntCvgAmt = cvgData['FLDCONTCVGAMT'] || 0;

                    // for residential occupancy
                    if (['RESIDENTIALCONDOBUILD20', 'OTHERRESIDENTIAL20'].includes(occupancyType) && (bldgCvgAmt === 0 && cntCvgAmt > 0)) {
                        contentSoldSupportingDocs.push('SIGNEDSTATEMENT');
                    }
                    dropdown = floodSystemDD.FLOODCANCELDOC.filter(x => contentSoldSupportingDocs.includes(x.s_AppCodeName));
                }

                setSupportingDocumentDD(dropdown);
            }
        }
    }, [])

    // set label
    useEffect(() => {
        if (cancellationReason) {
            var tempLabel = 'Does the property address on the documentation match the policy';
            if (["04"].includes(cancellationReason)) {
                tempLabel = "Does the property address and building description on the duplicate declarations page match the policy";
            }
            setIsAddressMatchLabel(tempLabel);

            var reason = "";
            reason = transSubTypeDD.filter((val) => val.s_AppCodeName === cancelJSON.cancelReason) || [];
            if (reason.length > 0) {
                reason = reason[0].s_AppCodeNameForDisplay;
            }

            setCancellationReasonForDisplay(reason);
        }
    }, [cancellationReason])

    /** 
     * get additional insured
     */
    const getAddiInterestData = async () => {
        setLoading(true);
        var additionalInsuredName = "";
        await dispatch(addiIntData(poTransPK)).then((res) => {
            if (res.length > 0) {
                res.map((v) => {
                    if (![null, undefined].includes(v.s_FullLegalName)) {
                        if (additionalInsuredName) {
                            additionalInsuredName = additionalInsuredName + ", " + v.s_FullLegalName;
                        } else {
                            additionalInsuredName = v.s_FullLegalName;
                        }
                    }
                });
            }

            var tempLabel = t("Do you have the signature of ") + applicationData.s_FullLegalName;
            if (![null, undefined].includes(additionalInsuredName) && additionalInsuredName.length > 0) {
                tempLabel = tempLabel + " AND " + additionalInsuredName;
            }
            setDataInCancelJSON(tempLabel, "cancellationQuestions", "policyHolderLabel");
            setPolicyHolderLabel(tempLabel);
        }).catch((error) => {
            const { data } = error || {};
            var errorText = "Something Went Wrong";
            if (data.errors && data.errors.hasOwnProperty('text')) {
                errorText = data.errors.text;
            }
            toast.error(t(errorText));
        });
        setLoading(false);
    }

    // handle switch Change
    const handleSwitchChange = (e, state) => {
        var temp = 'NO';
        const { attributes, checked } = e.target;
        if (checked) {
            temp = attributes.checkedvalue.value;
        }

        switch (state) {
            case 'isAddressMatch':
                setIsAddressMatch(temp);
                break;
            case 'isNameMatch':
                setIsNameMatch(temp);
                break;
            case 'policyHolderSignatute':
                setPolicyHolderSignatute(temp);
                break;
            case 'isOtherNFIPPolicy':
                setIsOtherNFIPPolicy(temp);
                break;
            case 'isPolicyHolderListedOnDuplicateDecMatch':
                setIsPolicyHolderListedOnDuplicateDecMatch(temp);
                break;
            case 'willPropertyTransNotTakingPlace':
                setWillPropertyTransNotTakingPlace(temp);
                break;
            case 'propertyNotRequiredFI':
                setPropertyNotRequiredFI(temp);
                break;
            case 'doAllLendersDontRequireFI':
                setDoAllLendersDontRequireFI(temp);
                break;

            default:
                break;
        }

        if (!["", null, undefined].includes(state)) {
            setDataInCancelJSON(temp, "cancellationQuestions", state);
        }
    }

    // handle state Change
    const handleStateChange = (value, state) => {
        switch (state) {
            case 'supportingDocument':
                setSupportingDocument(value);
                // resetState();
                setDataInCancelJSON(value, "cancellationQuestions", state);
                value && updateDocType("Cancellation Supporting Document", value.replace(new RegExp("_", 'g'), ""), value);
                break;

            case 'documentionDate':
                if (checkDateisValid(value)) {
                    setDocumentionDate(value);
                    setDataInCancelJSON((Moment(value).format("YYYY-MM-DD") || null), "cancellationQuestions", state);
                }
                break;

            case 'effectiveDateForNFIPPolicy':
                if (checkDateisValid(value)) {
                    setEffectiveDateForNFIPPolicy(value);
                    setDataInCancelJSON((Moment(value).format("YYYY-MM-DD") || null), "cancellationQuestions", state);
                }
                break;

            default:
                break;
        }
    }

    // set state from pre request cancel quote
    const setStatePreRequestData = () => {
        var cancelQuestions = cancelJSON.cancellationQuestions;
        cancelQuestions.hasOwnProperty("isAddressMatch") && setIsAddressMatch(cancelQuestions.isAddressMatch);
        cancelQuestions.hasOwnProperty("isNameMatch") && setIsNameMatch(cancelQuestions.isNameMatch);
        cancelQuestions.hasOwnProperty("policyHolderSignatute") && setPolicyHolderSignatute(cancelQuestions.policyHolderSignatute);
        cancelQuestions.hasOwnProperty("supportingDocument") && setSupportingDocument(cancelQuestions.supportingDocument);
        cancelQuestions.hasOwnProperty("policyHolderLabel") && setPolicyHolderLabel(cancelQuestions.policyHolderLabel);
        cancelQuestions.hasOwnProperty("isOtherNFIPPolicy") && setIsOtherNFIPPolicy(cancelQuestions.isOtherNFIPPolicy);
        cancelQuestions.hasOwnProperty("isPolicyHolderListedOnDuplicateDecMatch") && setIsPolicyHolderListedOnDuplicateDecMatch(cancelQuestions.isPolicyHolderListedOnDuplicateDecMatch);
        cancelQuestions.hasOwnProperty("willPropertyTransNotTakingPlace") && setWillPropertyTransNotTakingPlace(cancelQuestions.willPropertyTransNotTakingPlace);
        cancelQuestions.hasOwnProperty("propertyNotRequiredFI") && setPropertyNotRequiredFI(cancelQuestions.propertyNotRequiredFI);
        cancelQuestions.hasOwnProperty("doAllLendersDontRequireFI") && setDoAllLendersDontRequireFI(cancelQuestions.doAllLendersDontRequireFI);
        if (cancelQuestions.hasOwnProperty("documentionDate")) {
            if (![null, "", undefined].includes(cancelQuestions.documentionDate) && checkDateisValid(cancelQuestions.documentionDate)) {
                setDocumentionDate(new Date(Moment(cancelQuestions.documentionDate)) || null);
            }
        }
        if (cancelQuestions.hasOwnProperty("effectiveDateForNFIPPolicy")) {
            if (![null, "", undefined].includes(cancelQuestions.effectiveDateForNFIPPolicy) && checkDateisValid(cancelQuestions.effectiveDateForNFIPPolicy)) {
                setEffectiveDateForNFIPPolicy(new Date(Moment(cancelQuestions.effectiveDateForNFIPPolicy)) || null);
            }
        }
    }

    // update doc type of Cancellation Supporting Document
    const updateDocType = (docScreenName, value, supportingDocument) => {
        if (value) {
            var currentJSON = { ...cancelJSON };
            if (currentJSON['document']) {
                var index = currentJSON['document'].findIndex((v) => { return v.docScreenName === docScreenName });
                if (index !== -1) {
                    var nameForDisplay = floodSystemDD.FLOODCANCELDOC.filter(x => x.s_AppCodeName === supportingDocument)[0];
                    currentJSON['document'][index]['docType'] = value;
                    currentJSON['document'][index]['docTypeForDisplay'] = nameForDisplay.s_AppCodeNameForDisplay;
                    setCancelJSON(currentJSON);
                }
            }
        }
    }

    return (
        <div className="policy-details-section mt-3 interview-questions-div">
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style={{ padding: 0 }}
                >
                    <h5>
                        {t('Cancellation Questions for')} {" "} {cancellationReasonForDisplay}
                    </h5>
                </Col>
                {
                    ["01", "02"].includes(cancellationReason) && (
                        <>
                            <Col xs="12">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row>
                                        <Col xs="7">
                                            <Form.Label
                                                className="group-label"
                                            >
                                                {t('What type of supporting document do you have')}?
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <Form.Select
                                                name="supportingDocumentType"
                                                className="group-input"
                                                id="select-ym-option"
                                                value={supportingDocument}
                                                onChange={(e) => {
                                                    handleStateChange(e.currentTarget.value, 'supportingDocument');
                                                }}
                                                disabled={loading}
                                            >
                                                <option value='' key="-1">{t('Select')}</option>
                                                {
                                                    supportingDocumentDD.length > 0 && supportingDocumentDD.map((v, i) => {
                                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row>
                                        <Col xs="7">
                                            <Form.Label
                                                className="group-label"
                                            >
                                                {t('Date of event on the documentation')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <DatePicker
                                                id="dashboard-datepicker"
                                                className={(loading ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                                placeholderText="MM/DD/YYYY"
                                                selected={documentionDate || null}
                                                onChange={(date) => {
                                                    handleStateChange(date, 'documentionDate');
                                                }}
                                                customInput={
                                                    <TextMask mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]} Component={InputAdapter} />
                                                }
                                                autoComplete="off"
                                                disabled={loading}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row className="question-div">
                                        <Col xs="7">
                                            <Form.Label>
                                                {t('Does the name on the documentation match policyholder')}?
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <div className="row-actions">
                                                <span>{t('No')}</span>
                                                <div className="custom-main-swtich">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="row-switch"
                                                        checked={isNameMatch === "YES" ? true : false}
                                                        onChange={(e) => { handleSwitchChange(e, "isNameMatch"); }}
                                                        checkedvalue='YES'
                                                        uncheckedvalue='NO'
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <span>{t('Yes')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </>
                    )
                }
                {
                    ["04"].includes(cancellationReason) && (
                        <>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row className="question-div">
                                        <Col xs="7">
                                            <Form.Label>
                                                {t('Is the other policy an NFIP policy')}?
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <div className="row-actions">
                                                <span>{t('No')}</span>
                                                <div className="custom-main-swtich">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="row-switch"
                                                        checked={isOtherNFIPPolicy === "YES" ? true : false}
                                                        onChange={(e) => { handleSwitchChange(e, "isOtherNFIPPolicy"); }}
                                                        checkedvalue='YES'
                                                        uncheckedvalue='NO'
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <span>{t('Yes')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row>
                                        <Col xs="7">
                                            <Form.Label
                                                className="group-label"
                                            >
                                                {t('Effective Date of other NFIP policy')}:
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <DatePicker
                                                id="dashboard-datepicker"
                                                className={(loading ? "dashboard-datepicker-main-bg" : "dashboard-datepicker-main")}
                                                placeholderText="MM/DD/YYYY"
                                                selected={effectiveDateForNFIPPolicy || null}
                                                onChange={(date) => {
                                                    handleStateChange(date, 'effectiveDateForNFIPPolicy');
                                                }}
                                                autoComplete="off"
                                                disabled={loading}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row className="question-div">
                                        <Col xs="7">
                                            <Form.Label>
                                                {t('Does the Policyholder listed on the duplicate declarations page match the policyholder on this policy')}?
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <div className="row-actions">
                                                <span>{t('No')}</span>
                                                <div className="custom-main-swtich">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="row-switch"
                                                        checked={isPolicyHolderListedOnDuplicateDecMatch === "YES" ? true : false}
                                                        onChange={(e) => { handleSwitchChange(e, "isPolicyHolderListedOnDuplicateDecMatch"); }}
                                                        checkedvalue='YES'
                                                        uncheckedvalue='NO'
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <span>{t('Yes')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </>
                    )
                }
                {
                    ["08"].includes(cancellationReason) && (
                        <Col xs="12" className="mt-3">
                            <Form.Group
                                controlId="formBasicEmail"
                            >
                                <Row className="question-div">
                                    <Col xs="7">
                                        <Form.Label>
                                            {t('The anticipated transfer of the property did not and will not be taking place')}?
                                        </Form.Label>
                                    </Col>
                                    <Col xs="5">
                                        <div className="row-actions">
                                            <span>{t('No')}</span>
                                            <div className="custom-main-swtich">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="row-switch"
                                                    checked={willPropertyTransNotTakingPlace === "YES" ? true : false}
                                                    onChange={(e) => { handleSwitchChange(e, "willPropertyTransNotTakingPlace"); }}
                                                    checkedvalue='YES'
                                                    uncheckedvalue='NO'
                                                    disabled={loading}
                                                />
                                            </div>
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    ["60"].includes(cancellationReason) && (
                        <Col xs="12" className="mt-3">
                            <Form.Group
                                controlId="formBasicEmail"
                            >
                                <Row className="question-div">
                                    <Col xs="7">
                                        <Form.Label>
                                            {t('The property is not subject to a requirement to obtain and maintain flood insurance pursuant to any statute, regulation, or contract')}?
                                        </Form.Label>
                                    </Col>
                                    <Col xs="5">
                                        <div className="row-actions">
                                            <span>{t('No')}</span>
                                            <div className="custom-main-swtich">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="row-switch"
                                                    checked={propertyNotRequiredFI === "YES" ? true : false}
                                                    onChange={(e) => { handleSwitchChange(e, "propertyNotRequiredFI"); }}
                                                    checkedvalue='YES'
                                                    uncheckedvalue='NO'
                                                    disabled={loading}
                                                />
                                            </div>
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    ["28"].includes(cancellationReason) && (
                        <Col xs="12" className="mt-3">
                            <Form.Group
                                controlId="formBasicEmail"
                            >
                                <Row className="question-div">
                                    <Col xs="7">
                                        <Form.Label>
                                            {t('Do all the lenders listed on policy no longer require flood insurance')}?
                                        </Form.Label>
                                    </Col>
                                    <Col xs="5">
                                        <div className="row-actions">
                                            <span>{t('No')}</span>
                                            <div className="custom-main-swtich">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="row-switch"
                                                    checked={doAllLendersDontRequireFI === "YES" ? true : false}
                                                    onChange={(e) => { handleSwitchChange(e, "doAllLendersDontRequireFI"); }}
                                                    checkedvalue='YES'
                                                    uncheckedvalue='NO'
                                                    disabled={loading}
                                                />
                                            </div>
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                    )
                }
                {
                    ["01", "02", "04"].includes(cancellationReason) && (
                        <>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row className="question-div">
                                        <Col xs="7">
                                            <Form.Label>
                                                {t(isAddressMatchLabel)}?
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <div className="row-actions">
                                                <span>{t('No')}</span>
                                                <div className="custom-main-swtich">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="row-switch"
                                                        checked={isAddressMatch === "YES" ? true : false}
                                                        onChange={(e) => { handleSwitchChange(e, "isAddressMatch"); }}
                                                        checkedvalue='YES'
                                                        uncheckedvalue='NO'
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <span>{t('Yes')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </>
                    )
                }
                {
                    ["01", "02", "04", "08", "28", "60"].includes(cancellationReason) && (
                        <>
                            <Col xs="12" className="mt-3">
                                <Form.Group
                                    controlId="formBasicEmail"
                                >
                                    <Row className="question-div">
                                        <Col xs="7">
                                            <Form.Label>
                                                {t(policyHolderLabel)}
                                            </Form.Label>
                                        </Col>
                                        <Col xs="5">
                                            <div className="row-actions">
                                                <span>{t('No')}</span>
                                                <div className="custom-main-swtich">
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        className="row-switch"
                                                        checked={policyHolderSignatute === "YES" ? true : false}
                                                        onChange={(e) => { handleSwitchChange(e, "policyHolderSignatute"); }}
                                                        checkedvalue='YES'
                                                        uncheckedvalue='NO'
                                                        disabled={loading}
                                                    />
                                                </div>
                                                <span>{t('Yes')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Col>
                        </>
                    )
                }
            </Row>
        </div>
    )
}

export default CancellationQuestions;
