const getEndorsementApplicationBody = ({ requestBody }) => {
  return {
    effectiveDate: requestBody?.effectiveDate,
    productType: requestBody?.productType,
    commercialProductType: requestBody?.commercialProductType,
    occupancyType: requestBody?.occupancyType,
    constructionType: requestBody?.constructionType,
    buildingReplacementCostValues: requestBody?.buildingReplacementCostValues,
    contentsReplacementCostValues: requestBody?.contentsReplacementCostValues,
    businessIncomeAndExtraExpenseAnnualValue:
      requestBody?.businessIncomeAndExtraExpenseAnnualValue == 0
        ? 0
        : requestBody?.businessIncomeAndExtraExpenseAnnualValue || null,
    stockReplacementCostValue:
      requestBody?.stockReplacementCostValue == 0
        ? 0
        : requestBody?.stockReplacementCostValue || null,
    contentsCostValueType: requestBody?.contentsCostValueType,
    foundationType: requestBody?.foundationType,
    additionalFoundationType: requestBody?.additionalFoundationType,
    basementType: requestBody?.basementType,
    attachedGarageType: requestBody?.attachedGarageType,
    yearBuilt: requestBody?.yearBuilt,
    squareFootage: requestBody?.squareFootage,
    numberOfStories: requestBody?.numberOfStories,
    elevationHeight: requestBody?.elevationHeight,
    buildingOverWaterType: requestBody?.buildingOverWaterType,
    improvementsAndBettermentsReplacementCostValues:
      requestBody?.improvementsAndBettermentsReplacementCostValues == 0
        ? 0
        : requestBody?.improvementsAndBettermentsReplacementCostValues || null,
    limits: requestBody?.residentialLimits || [],
    priorLosses: requestBody?.priorLosses || [],
  };
};
export default getEndorsementApplicationBody;
