import React, { useEffect } from "react";
import { useDispatch, useSelector, connect } from 'react-redux';
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import { pivotApiCall, updateWhichSection, updateLastSection, updateInitQuoteApi } from "../../../../services/floodService";

import "./mode-starting-estimate.scss";
import "react-overlay-loader/styles.css";
import Moment from "moment";

const ModeStartingEstimate = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = 2;


  // Call Pivot Api To Get Estimate Premium
  useEffect(() => {
    if (props.emptyQuoteData && props.initApiObject && Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.initApiObject).length > 0) {
      if (props.emptyQuoteData.viewType !== "") {

        const reqJson = { ...props.initApiObject }; // Copy
        // To update
        dispatch(updateWhichSection('DEFAULT'));
        reqJson.addressLine1 = props.emptyQuoteData.personAddr.s_AddressLine1;
        reqJson.addressLine2 = props.emptyQuoteData.personAddr.s_AddressLine1
        reqJson.addressType = 'PROPERTY_ADDRESS'
        reqJson.city = props.emptyQuoteData.personAddr.s_CityName
        reqJson.countyFips = ""
        reqJson.deleteAddress = false
        reqJson.propertyBeginningStreetNumber = props.emptyQuoteData.personAddr.s_HouseNo
        reqJson.nameOrDescriptiveType = 'N'
        reqJson.state = props.emptyQuoteData.personAddr.s_StateName
        reqJson.stateFips = ""
        reqJson.zipCode = props.emptyQuoteData.personAddr.s_PostalCode
        reqJson.zipPlus4 = props.emptyQuoteData.personAddr.s_PostalCodeSuffix
        reqJson.constructionType = props.emptyQuoteData.propertyInfo.s_BldgConstructionType
        reqJson.foundationType = props.emptyQuoteData.propertyInfo.s_Foundation
        reqJson.numberOfFloors = props.emptyQuoteData.propertyInfo.n_NoOfFloors
        reqJson.originalConstructionDate = props.emptyQuoteData.propertyInfo.d_DateOfConstruction
        reqJson.totalSquareFootage = props.emptyQuoteData.propertyInfo.s_TotalSqFootage
        reqJson.enclosureSize = props.emptyQuoteData.propertyInfo.s_AreaInSqFt
        reqJson.communityNumber = props.emptyQuoteData.propertyInfo.s_CommunityNumber
        reqJson.floodZone = props.emptyQuoteData.propertyInfo.s_FloodZone
        reqJson.currentMapDate = (props.emptyQuoteData.propertyInfo.d_CurrentFirmDate ? Moment(props.emptyQuoteData.propertyInfo.d_CurrentFirmDate).format('MM/DD/YYYY') : '');
        reqJson.initialFirmDate = (props.emptyQuoteData.propertyInfo.d_InitialFirmDate ? Moment(props.emptyQuoteData.propertyInfo.d_InitialFirmDate).format('MM/DD/YYYY') : '');
        reqJson.postFirmConstruction = ''
        reqJson.preFirmSubsidyEligible = ''
        reqJson.sqft = props.emptyQuoteData.propertyInfo.s_TotalSqFootage
        reqJson.industryReplacementCost = props.emptyQuoteData.propertyInfo.n_ReplacementCost
        reqJson.whichSection = props.whichSection;
        reqJson.buildingDescription = props.emptyQuoteData.propertyInfo.s_BuildingUse
        reqJson.floorOfUnit = props.emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor
        reqJson.numberOfUnits = props.emptyQuoteData.propertyInfo.s_NoOfUnits
        reqJson.latitude = props.emptyQuoteData.personAddr.s_Latitude
        reqJson.longitude = props.emptyQuoteData.personAddr.s_Longitude
        reqJson.type = props.emptyQuoteData.viewType;
        reqJson.uniqueKey = localStorage.getItem('uniqueKey');

        // Overwrite County & State Fips From ZD Details
        if (props.ZDDetails.success && Object.keys(props.ZDDetails.data).length > 0) {
          const zDData = props.ZDDetails.data.determination
          if (zDData && zDData.county && Object.keys(zDData.county).length > 0) {
            var identification = zDData.county;
            reqJson.countyFips = identification.countyfips.slice(-3);
            reqJson.stateFips = identification.statefips;
          }
        }


        // To update
        dispatch(updateInitQuoteApi(reqJson));
        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }, [props.s_YearBuilt,props.s_Usage,props.s_Foundation,props.s_BldgConstructionType ,props.n_NoOfFloors  ,props.s_AreaInSqFt ,props.s_TotalSqFootage,props.s_DtOfConstructionSource,props.d_DateOfConstruction ,props.s_NoOfUnits,props.s_UnitLocatedOnFloor,props.s_BuildingUse]);

  return (
    <>
      <p className="starting-estimate-price">
        <h3>
          ${(props.premium).toLocaleString("en-US")}<span className="se-slash">/</span>
          <span className="se-year">{t('Year')}</span>
        </h3>
      </p>
      <p>{t('This estimate is using basic information, please continue to get the most accurate rate')}.</p>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emptyQuoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},
    ZDDetails: state.flood.ZDDetails ? state.flood.ZDDetails : {},
    whichSection: state.flood.whichSection,
    // apiLoading: state.flood.apiLoading ? state.flood.apiLoading : false,

     //systemDD: state.common.systemDD.data,
     apiLoading: state.flood.apiLoading ? state.flood.apiLoading : false,
     propertyDataFromAddress: state.common.propertyDataFromAdd ? state.common.propertyDataFromAdd.data : {},
     s_YearBuilt: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_YearBuilt || '',
     s_Usage: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_Usage || '',
     s_Foundation: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_Foundation || '',
     s_BldgConstructionType: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_BldgConstructionType || '',
     n_NoOfFloors: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.n_NoOfFloors || '',
     s_AreaInSqFt: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_AreaInSqFt || '',
     s_TotalSqFootage: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_TotalSqFootage || '',
     s_DtOfConstructionSource: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_DtOfConstructionSource || '',
     d_DateOfConstruction: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.d_DateOfConstruction || '',
     s_NoOfUnits: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_NoOfUnits || '',
     s_UnitLocatedOnFloor: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor || '',
     s_BuildingUse: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_BuildingUse || '',
 
     effectiveDate: state.flood.emptyQuoteData ? (state.flood.emptyQuoteData.effectiveDate || '') : '',
  };
}

export default connect(mapStateToProps)(ModeStartingEstimate);
