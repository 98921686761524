import Http from '../Http';
import * as action from '../store/actions';

export function getInstantPropertyData(credentials) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/instantquote/getPropertyDataAddr', credentials)
        .then((res) => {
          dispatch(action.getInstantPropertyDataFromAddress(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getEmptyInstantQuote() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.get('/api/instantquote/emptyQuoteData/13')
            .then((res) => {
                dispatch(action.getEmptyInstantQuoteData(res.data));
                return resolve();
            })
        })
    );
}

export function getSystemInstantDD() {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/instantquote/getBaseData/13')
        .then((res) => {
          dispatch(action.getInstantSystemDD(res.data));
          return resolve();
        })
    })
  );
}

export function getEditEmptyInstantQuoteData(data) {
  return dispatch => (
    dispatch(action.getEditEmptyInstantQuoteData(data))

  );
}

export function InstantpropertyDataAutocompleteStore(data) {
  return dispatch => (
    dispatch(action.getInstantPropertyDataAutocompleteStore(data))

  );
}

export function saveMultipleQuoteData(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/instantquote/saveMultipleQuoteData', data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getBuyQuote(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/instantquote/getBuyQuote', data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getZipDetails(product, zipCode, source) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/instantquote/getZipCodeDetails/' + product + '/' + zipCode + '/' + source)
      .then((res) => {
          return resolve(res.data);
        })
    })
  );
}

export function getSearchMortgagee(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/instantquote/searchMortgagee', data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function saveQuoteData(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/instantquote/saveQuoteData', data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getSaveFinalPremiumInstantQuoteData(data) {
  return dispatch => (
    dispatch(action.getSaveFinalPremiumInstantQuoteData(data))

  );
}

export function getCVGAndDeductibleDD() {
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get('/api/instantquote/getCVGAndDeductibleDD')
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
  }

export function saveNRateCustom(data) {
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.post('/api/instantquote/saveNRateCustom', data)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}