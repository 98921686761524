import React, { Fragment,useState } from 'react';

import { Button, Col, Row, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PdfIcon from '../../../../../../assets/pdf-icon.png';

const ExcessProductModal = ({ data, isModalOpen, closeModal ,onGetPremiumClick  }) => {
  const { t } = useTranslation();
  const [coverage, setCoverage] = useState('');

  const handleGetPremiumClick = () => {
    onGetPremiumClick(coverage);
    closeModal();
  };

  return (
    <Fragment>
      <Modal size="md" show={isModalOpen} onHide={closeModal} id="confirmPropInfo">
        <Modal.Header closeButton>
          <Modal.Title>{t('Excess Coverage')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
        <Row className="align-items-center">
        <Col xs={3}>
              <label htmlFor="excess-coverage" className="form-label">
                Coverage:
              </label>
            </Col>
            <Col xs={3} >
              <label htmlFor="excess-coverage" className="mr-2">
                250,000  +
              </label>
            </Col>
            <Col xs={6}>
                <input
                  type="text"
                  name="excess-coverage"
                  id="excess-coverage"
                  className="form-control dashboard-group-input"
                  onChange={(e) => setCoverage(e.target.value)}
                />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button onClick={handleGetPremiumClick} id="primary-colored-btn" size="md">
            {'Get a Premium'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ExcessProductModal;