import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { RecentTransactionsPolicyList } from "../../../services/dashboardService";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getAgentTasksList } from  "../../../services/dashboardService";
import {
   setPolicyPropsData, verifyPolicy, getBaseData
} from "../../../services/coreSystemService";
import { useNavigate } from "react-router-dom";

const AgentTasksDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    page: 1,
    pageLength: 10,
  });
  const [agentTasks, setAgentTasks] = useState([]);

  useEffect(() => {
    getAgentAllTasks();
  }, []);
  
  useEffect(() => {
    getAgentAllTasks();
  }, [filter.page,filter.pageLength]);

  const getAgentAllTasks = async () => {
    const params = {
      limit: 0,
      page: filter.page,
      pageLength: filter.pageLength,
    }
    await dispatch(getAgentTasksList(params)).then((res)=>{
      setAgentTasks(res.data.data.taskdata);
      setTotal(res.data.data.total);
    })
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  }

  const columnFormatter = (cell, row) => {
    if (row && row.Policy_No) {
      return (
        <a href="#" onClick={() => verify(row.Policy_No)}>
          {cell}
        </a>
      );
    } else {
      return row.Policy_No;
    }
  };

  const verify = async (policyNumber) => {
    var params = {};
    await dispatch(verifyPolicy(policyNumber)).then((res) => {
      if (res.status) {
        params = {
          n_POPolicyMasterFK: res.policyIdPK,
          n_POTermMasterFK: res.termMasterPK,
          n_POTransactionFK: res.poTransPK,
        };
      } else {
        navigate(`/core/policy`);
      }
    });
    await dispatch(setPolicyPropsData(params));
    await dispatch(
      getBaseData(params.n_POTransactionFK)
    );
    navigate(`/core/policy/${policyNumber}`);
    window.location.reload();
  };

  const columns = [
    {
      dataField: "Policy_No",
      text: "Policy No",
      formatter: columnFormatter
    },
    {
      dataField: "s_PRTranTypeCode",
      text: "Transaction Type",
    },
    {
      dataField: "note",
      text: "Log Note",
    },
    {
      dataField: "created_at",
      text: "Date",
    },
  ];

  function numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }

  const renderShowsTotal = (from, to, size) => (
    <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
  );

  const handlePageChange = (page, sizePerPage) => {
    setFilter({ ...filter, page: page, pageLength: sizePerPage });
  };

  const sizePerPageListChange = (page, sizePerPage) => {
    setFilter({ ...filter, pageLength: sizePerPage });
  };

  const options = {
    page: filter.page,
    sizePerPage: filter.pageLength,
    sortIndicator: false,
    showTotal: true,
    totalSize: total,
    pageStartIndex: 1,
    // alwaysShowAllBtns:true,
    paginationTotalRenderer: renderShowsTotal,
    onPageChange: handlePageChange,
    onSizePerPageChange: sizePerPageListChange,
  };

  return (
    <Fragment>
      <h4 className="dashboard-cards-table-title">Dashboard</h4>
      <div className="dashboard-tab-header-fill">
        <span>Agent Tasks</span>
      </div>
      <div className="managment-tab-body">
        <div
          className="management-reports-table"
          id="management-reports-table-outer-div"
        >
          <LoadingOverlay>
           <Loader loading={loading} />
          <BootstrapTable
            wrapperClasses="dashboard-table-main"
            bootstrap4
            keyField="PolicyNo"
            data={agentTasks}
            columns={columns}
            hover
            remote
            bordered={false}
            pagination={paginationFactory(options)}
            onTableChange={onTableChange}
          />
          </LoadingOverlay>
        </div>
      </div>
    </Fragment>
  );
};

export default AgentTasksDetails;
