import React from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const securityServices = [
  { name: "None", icon: "/starImages/miniIcons/none.svg" },
  {
    name: "Video Surveillance",
    icon: "/starImages/miniIcons/videoSurveillance.svg",
  },
  { name: "Security Guard", icon: "/starImages/miniIcons/securityGuard.svg" },
  {
    name: "Video + Security Guard",
    icon: "/starImages/miniIcons/videoAndSecurityGuard.svg",
  },
];

const SecurityServiceCard = ({ name, icon: Icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={Icon || ""} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const SecurityServiceSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails } = useQuote();
  const { t } = useTranslation();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "securityService", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Security Service")}:</Text>{" "}
        {buildingDetails[index]?.securityService && (
          <Text fontWeight="500" color={"neutral.300"}>
            ( {buildingDetails[index]?.securityService} )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {securityServices.map(({ name, icon }) => (
          <GridItem key={name}>
            <SecurityServiceCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.securityService === name}
              onClick={() => handleSelection(name)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
