import React, { useEffect, useState } from "react";
import AgencyMain from "../AgencyMain/AgencyMain";
import AgencyMailingAddress from "../AgencyMailingAddress/AgencyMailingAddress";
import AgencyLocationAddress from "../AgencyLocationAddress/AgencyLocationAddress";
import AgencyDetails from "../AgencyDetail/AgencyDetail";
// import { Button, Card, Col, Row } from "react-bootstrap";
import AgencyLicense from "../AgencyLicense/AgencyLicense";
// import Modal from "react-bootstrap/Modal";
import AgencyUser from "../AgencyUser/AgencyUser";
import CeLicenseModal from "../CeLicenseModal/CeLicenseModal";
import { useDispatch, connect } from "react-redux";
import { setAgencyUserModal } from "../../../store/actions/index";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FaListUl } from "react-icons/fa";
import CommisionModal from "../CommisionModal/Commision";

const Agent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [agencyUser, setAgencyUser] = useState(false);
  const OpenAgencyUser = () => setAgencyUser(true);
  const closeAgencyUser = () => setAgencyUser(false);
  const [ceLicense, setCeLicense] = useState(false);
  const [commisions , setCommisions] =useState(false);
  const OpenCommisions=()=>setCommisions(true);
  const closeCommisions=()=>setCommisions(false);
  const OpenCeLicense = () => setCeLicense(true);
  const closeCeLicense = () => setCeLicense(false);
  
  const handleCloseAUModal = (e) => {
    dispatch(setAgencyUserModal(false))
    closeAgencyUser();
  }
  useEffect(()=>{
    setAgencyUser(props.isAgencyUserModalOpen)
  },[props.isAgencyUserModalOpen])
  return (
    <>
      <Flex mb={"6"} direction={"column"}>
        <Box>
          <Card boxShadow={"lg"} borderRadius={"2xl"}>
            <AgencyMain
              AgencyData={props.agencyData}
              BrandedCompanies={props.brandedCompanies}
            />
          </Card>
        </Box>
        <Flex mt={6} justifyContent={"space-between"}>
          <Flex direction={"column"}>
            <Card boxShadow={"lg"} borderRadius={"2xl"}>
              <Box>
                <AgencyMailingAddress AgencyData={props.agencyData?.main} />
              </Box>
              <Box>
                <AgencyLocationAddress AgencyData={props.agencyData?.main} />
              </Box>
            </Card>
            <Box mt={6}>
              <Card boxShadow={"lg"} borderRadius={"2xl"}>
                <AgencyDetails AgencyData={props.agencyData?.main} />
              </Card>
            </Box>
            <Box mt={6}>
              <Card boxShadow={"lg"} borderRadius={"2xl"}>
                <AgencyLicense
                  AgencyData={props.agencyData?.licManager_arr}
                  StateData={props.agencyData?.state_arr}
                />
              </Card>
            </Box>
          </Flex>
          <Flex direction={"column"} p={6} pt={0}>
            <Box>
              <Button
                width={"100%"}
                leftIcon={<FaListUl />}
                size={"sm"}
                onClick={OpenAgencyUser}
              >
                {t("Agency Users")}
              </Button>
            </Box>
            <Box>
              <Button
                mt={3}
                width={"100%"}
                leftIcon={<FaListUl />}
                size={"sm"}
                onClick={OpenCeLicense}
              >
                <Text width={"100%"} mb={0}>
                  {t("CE License")}
                </Text>
              </Button>
            </Box>
            <Box>
              <Button
                mt={3}
                width={"100%"}
                leftIcon={<FaListUl />}
                size={"sm"}
                onClick={OpenCommisions}
              >
                <Text width={"100%"} mb={0}>
                  {t("Commission")}
                </Text>
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Modal size={"full"} isOpen={agencyUser} onClose={handleCloseAUModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            {t("Agency Users")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AgencyUser
              agencyId={props.agencyId}
              agencyData={props.agencyData?.main}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"6xl"} isOpen={ceLicense} onClose={closeCeLicense}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            {t("CE License")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CeLicenseModal agencyId={props.agencyId} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal size={"6xl"} isOpen={commisions} onClose={closeCommisions}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
            {t("Commission Statement")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CommisionModal  agencyId={props.agencyId}/>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* <Row>
        <div style={{ backgroundColor: "white", padding: "3px" }}>
          <Card>
            <AgencyMain 
                AgencyData={props.agencyData}
                BrandedCompanies={props.brandedCompanies}
            />
          </Card>
        </div>
        <Col sm={10} style={{ backgroundColor: "white", padding: "3px" }}>
          <Card>
            <div>
              <AgencyMailingAddress 
                AgencyData={props.agencyData?.main}
              />
            </div>
            <div style={{ marginTop: "5px" }}>
              <AgencyLocationAddress 
                AgencyData={props.agencyData?.main}
              />
            </div>
          </Card>
          <div style={{ marginTop: "5px" }}>
            <Card>
              <AgencyDetails 
                AgencyData={props.agencyData?.main}
              />
            </Card>
          </div>
          <div style={{ marginTop: "5px", marginBottom: "2rem" }}>
            <Card>
              <AgencyLicense 
                AgencyData={props.agencyData?.licManager_arr}
                StateData = {props.agencyData?.state_arr}
              />
            </Card>
          </div>
        </Col>
        <Col sm={2} style={{ backgroundColor: "white", padding: "3px" }}>
          <Row>
            <Col>
              <div>
                <Button
                  id="producer-button-input-size"
                  onClick={OpenAgencyUser}
                >
                  {t("Agency Users")}
                </Button>
              </div>
              <div>
                <Button
                  id="producer-button-input-size"
                  onClick={OpenCeLicense}
                >
                  {t("CE License")}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
      {/* <Modal size="xl" show={agencyUser} onHide={(e) => {handleCloseAUModal(e)}}>
        <Modal.Header closeButton >
          <Modal.Title >{t("Agency Users")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AgencyUser agencyId={props.agencyId} agencyData={props.agencyData?.main} />
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={ceLicense} onHide={closeCeLicense}>
        <Modal.Header closeButton >
          <Modal.Title>{t("CE License")}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <CeLicenseModal agencyId={props.agencyId} />
        </Modal.Body>
      </Modal> */}
    </>
  );
};
const mapStateToProps = (state) => ({
  getProducerData: state.producerDataProcess.producerData,
  setLoader:state.producerDataProcess.loader,
  agencyUserSearchModal : state.producerDataProcess.openAgentUserModel
});
export default connect(mapStateToProps)(Agent);