import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BasicCoverages } from "./BasicCoverages";
import { AdditionalCoverages } from "./AdditionalCoverages";
import { useQuote } from "../../CommCreateQuote";
import { BlanketAdditional } from "./BlanketAddtional";
import { BlanketBasic } from "./BlanketBasics";
import { Deductibles } from "./Deductibles";
import { BlanketDeductibles } from "./BlanketDeductibles";

const CoverageAccordion = ({ title, buildingDetails, index = 0, children }) => (
  <AccordionItem
    mb={4}
    borderRadius="xl"
    border="1px solid"
    borderColor="neutral.100"
    overflow="hidden"
    width="100%"
  >
    <AccordionButton bg="primary.50">
      <Box as="span" flex="1" textAlign="left">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize="xl" my={2}>
            {title}
          </Text>
          {buildingDetails && buildingDetails[index] && (
            <Badge
              variant="outline"
              borderRadius="md"
              px={2}
              colorScheme="blue"
              color="primary.500"
              pl={1}
              fontSize="sm"
              fontWeight="800"
              my={2}
              mr={1}
              maxW="220px"
              maxH="40px"
              overflow="hidden"
            >
              {buildingDetails[index].locationIndetifier}
            </Badge>
          )}
        </Flex>
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel overflow="auto" pb={4}>
      {children}
    </AccordionPanel>
  </AccordionItem>
);

const CoverageContainer = ({ buildingDetails, index }) => (
  <Box>
    <Box
      p={6}
      bg="white"
      borderRadius="xl"
      boxShadow="md"
      border="1px solid"
      borderColor="gray.100"
      mb={6}
    >
      <Grid gap={5} templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}>
        <GridItem colSpan={1}>
          <Accordion allowToggle defaultIndex={[0]}>
            <CoverageAccordion
              title="Basic Coverages"
              buildingDetails={buildingDetails}
              index={index}
            >
              <BasicCoverages index={index} />
            </CoverageAccordion>
          </Accordion>
        </GridItem>
        <GridItem colSpan={1}>
          <Accordion allowToggle defaultIndex={[0]}>
            <CoverageAccordion
              title="Additional Coverages"
              buildingDetails={buildingDetails}
              index={index}
            >
              <AdditionalCoverages
                index={index}
                buildingDetails={buildingDetails}
              />
            </CoverageAccordion>
          </Accordion>
        </GridItem>
        <GridItem colSpan={2}>
          <Accordion allowToggle defaultIndex={[0]}>
            <CoverageAccordion
              title="Deductibles"
              buildingDetails={buildingDetails}
              index={index}
            >
              <Deductibles index={index} buildingDetails={buildingDetails} />
            </CoverageAccordion>
          </Accordion>
        </GridItem>
      </Grid>
    </Box>
    <Box borderTop={"1px solid"} borderColor={'gray.300'} my={6} />
  </Box>
);

export const Coverages = () => {
  const { t } = useTranslation();
  const { coverages, buildingDetails, masterQuoteData, setMasterQuoteData } =
    useQuote();

  return (
    <Box>
      <Flex justifyContent="flex-start" pb={3}>
        <FormLabel fontWeight={500} fontSize="lg" pl={1} my={0}>
          {t("Blanket")}?
        </FormLabel>
        <Box mt="4px">
          <Checkbox
            isChecked={masterQuoteData.applicantDetails?.blanket === "YES"}
            onChange={(e) => {
              setMasterQuoteData({
                ...masterQuoteData,
                applicantDetails: {
                  ...masterQuoteData.applicantDetails,
                  blanket: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
          />
        </Box>
      </Flex>

      {masterQuoteData.applicantDetails?.blanket === "NO" ? (
        <Box>
          {coverages.map((_, index) => (
            <CoverageContainer
              key={index}
              buildingDetails={buildingDetails}
              index={index}
            />
          ))}
        </Box>
      ) : (
        <Box
          p={6}
          bg="white"
          borderRadius="xl"
          boxShadow="sm"
          border="1px solid"
          borderColor="gray.100"
        >
          <Grid gap={5} templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}>
            <GridItem colSpan={1}>
              <Accordion allowToggle defaultIndex={[0]}>
                <CoverageAccordion title={t("Basic Coverages")}>
                  <BlanketBasic />
                </CoverageAccordion>
              </Accordion>
            </GridItem>
            <GridItem colSpan={1}>
              <Accordion allowToggle defaultIndex={[0]}>
                <CoverageAccordion title={t("Additional Coverages")}>
                  <BlanketAdditional />
                </CoverageAccordion>
              </Accordion>
            </GridItem>
            <GridItem colSpan={2}>
              <Accordion allowToggle defaultIndex={[0]}>
                <CoverageAccordion title={t("Deductibles")}>
                  <BlanketDeductibles />
                </CoverageAccordion>
              </Accordion>
            </GridItem>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
