import Http from "../../../../Http";

export async function fetchFees(requestBody, product) {
  try {
    const url = `/api/v2/quote/${product}/calculateFees`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (error) {
    console.error("Error fetching base data:", error);
  }
}
