import { Route, Routes } from "react-router-dom";
import { QuoteApplication } from "./components/QuoteApplication/QuoteApplication";

const QuoteRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:quoteNumber/*" element={<QuoteApplication />} />
      </Routes>
    </>
  );
};

export default QuoteRoutes;
