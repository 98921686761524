import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector, connect } from 'react-redux';
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall, updateWhichSection, updateLastSection } from "../../../../../services/floodService";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "./mode-construction.scss";
import "react-overlay-loader/styles.css";
import ReactTooltip from "react-tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
const ModeConstructionFormOne = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { apiLoading, whichSection, lastSection } = useSelector(state => state.flood);
    const [validated, setValidated] = useState(false);
    const [isPreFirm, setIsPreFirm] = useState(false);
    const [substantialImprovement, setSubstantialImprovement] = useState('NO');
    const [substantialImprovmentDate, setSubstantialImprovmentDate] = useState(null);
    const [isInsuredNonProfitEnt, setIsInsuredNonProfitEnt] = useState('NO');
    const [buildingCourseConstruction, setBuildingCourseConstruction] = useState('NO');
    const [isBldgNRHP, setIsBldgNRHP] = useState('NO');
    const [isStateSet, setIsStateSet] = useState(false);
    const currentStep = 5;

    const [buildingOverWater, setBuildingOverWater] = useState('');
    const [isBldgContainME, setIsBldgContainME] = useState('');
    const [allMachinaryElevated, setAllMachinaryElevated] = useState('NO');
    const [noOfElevator, setNoOfElevator] = useState('');
    const [noOfDetatchedStruct, setNoOfDetatchedStruct] = useState('');
    // // set Initial State from reducer
    useEffect(() => {
        if (Object.keys(props.emptyQuoteData).length > 0) {
            setSubstantialImprovement(props.emptyQuoteData.propertyInfo.s_SubstantialImprovement);
            if (props.checkDateisValid(props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate)) {
                setSubstantialImprovmentDate(new Date(props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate));
            }
            setIsInsuredNonProfitEnt(props.emptyQuoteData.propertyInfo.s_IsInsuredNonProfitEnt);
            setBuildingCourseConstruction(props.emptyQuoteData.propertyInfo.s_BuildingCourseConstruction);
            setIsBldgNRHP(props.emptyQuoteData.propertyInfo.s_IsBldgNRHP);
            setBuildingOverWater(props.emptyQuoteData.propertyInfo.s_BuildingOverWater);
            setNoOfElevator(props.emptyQuoteData.propertyInfo.n_NoOfElevator);
            setNoOfDetatchedStruct(props.emptyQuoteData.propertyInfo.n_NoOfDetatchedStruct);
            setIsBldgContainME(props.emptyQuoteData.propertyInfo.s_IsBldgContainME);
            setAllMachinaryElevated(props.emptyQuoteData.propertyInfo.s_AllMachinaryElevated);
            setIsStateSet(true);
        }
    }, [props.emptyQuoteData])

    useEffect(() => {
        if (isStateSet === true) {
            if (props.emptyQuoteData && props.initApiObject) {
                if (props.emptyQuoteData.viewType !== "") {
                    const reqJson = { ...props.initApiObject }; // Copy
                    reqJson.buildingUnderConstruction = props.emptyQuoteData.propertyInfo.s_BuildingCourseConstruction
                    reqJson.substantialImprovementDate = props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate

                    // To update
                    dispatch(updateWhichSection('FIRST'));

                    reqJson.whichSection = 'FIRST';

                    // To update
                    dispatch(updateInitQuoteApi(reqJson));

                    const code = 'QUOTE_NEWBUSINESS';
                    const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };

                    // Original Construction Date must be less than Substantial Improvement
                    if (props.emptyQuoteData.propertyInfo.s_SubstantialImprovement === 'YES' && ((![null, ''].includes(props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate) && (props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate < props.emptyQuoteData.propertyInfo.d_DateOfConstruction)) || [null, ''].includes(props.emptyQuoteData.propertyInfo.d_SubstantialImprovmentDate))) {
                        toast.error(t('Original Construction Date must be less than Substantial Improvement Date'));
                    } else {
                        // Call Api
                        dispatch(pivotApiCall(code, request));
                    }
                    // Check Firm Type
                    checkFirmType()
                }
            }
        }
    }, [isStateSet, buildingCourseConstruction, substantialImprovmentDate]);

    useEffect(() => {
        if (isStateSet === true) {
            var temp = null;
            if (['YES'].includes(substantialImprovement)) {
                temp = substantialImprovmentDate;
            }
            if (!['YES'].includes(substantialImprovement)) {
                temp = null;
            }
            const emptyQuote = { ...props.emptyQuoteData }; // Copy
            emptyQuote.propertyInfo.d_SubstantialImprovmentDate = temp;
            setSubstantialImprovmentDate(temp);
            dispatch(getEditEmptyQuoteData(emptyQuote));

            const reqJson = { ...props.initApiObject }; // Copy
            reqJson.substantialImprovementDate = temp;
            reqJson.whichSection = 'FIRST';

            // To update
            dispatch(updateInitQuoteApi(reqJson));
        }
    }, [isStateSet, substantialImprovement]);

    useEffect(() => {
        if (isStateSet === true) {
            if (props.emptyQuoteData && props.initApiObject) {
                const reqJson = { ...props.initApiObject }; // Copy
                reqJson.buildingOverWaterType = buildingOverWater;
                reqJson.machineryOrEquipmentAbove = allMachinaryElevated;
                // To update step
                dispatch(updateWhichSection('SECOND'));
                // if (currentStep > lastSection) {
                //   dispatch(updateLastSection(currentStep));
                // }
                reqJson.type = props.emptyQuoteData.viewType;
                reqJson.whichSection = whichSection;
                reqJson.uniqueKey = localStorage.getItem('uniqueKey');
                // To update
                dispatch(updateInitQuoteApi(reqJson));

                const code = 'QUOTE_NEWBUSINESS';
                const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
                // Call Api
                dispatch(pivotApiCall(code, request));
            }
        }
    }, [buildingOverWater, allMachinaryElevated]);

    // //Check If Firm is Pre-Firm Or not
    const checkFirmType = () => {
        const emptyQuote = { ...props.emptyQuoteData };
        var temp = emptyQuote.propertyInfo.s_FloodConstructionType;
        if ((new Date(props.emptyQuoteData.propertyInfo.d_DateOfConstruction) <= new Date('1974-12-31')) || (new Date(props.emptyQuoteData.propertyInfo.d_DateOfConstruction) < new Date(props.emptyQuoteData.propertyInfo.d_InitialFirmDate))) {
            temp = 'FLDPREFIRM';
        }
        if (substantialImprovement === 'YES' && ![null, ''].includes(substantialImprovmentDate)) {
            temp = 'FLDPOSTFIRM';
        }
        // default historical question to no if it is post firm, and if it is pre firm ask the question
        if (temp === 'FLDPOSTFIRM') {
            emptyQuote.propertyInfo.s_IsBldgNRHP = 'NO';
        }

        emptyQuote.propertyInfo.s_FloodConstructionType = temp;
        dispatch(getEditEmptyQuoteData(emptyQuote));
        setIsPreFirm(temp)
    }

    // handle switch Change
    const handleChange = (e, state) => {
        var temp = 'NO';
        const { attributes, checked } = e.target;
        if (checked) {
            temp = attributes.checkedvalue.value;
        }
        switch (state) {
            case 'substantialImprovement':
                setSubstantialImprovement(temp);
                break;
            case 'isInsuredNonProfitEnt':
                setIsInsuredNonProfitEnt(temp);
                break;
            case 'buildingCourseConstruction':
                setBuildingCourseConstruction(temp);
                break;
            case 'isBldgNRHP':
                setIsBldgNRHP(temp);
                break;
            default:
                break;
        }
        props.handleSwitchChange(e);
    }


    // handle radio Change
    const handleRadioChange = (e, state) => {
        var temp = 'NO';
        const { value, attributes, checked } = e.target;
        if (checked && ['isBldgContainME', 'allMachinaryElevated'].includes(state)) {
            temp = attributes.checkedvalue.value;
        }

        switch (state) {
            case 'buildingOverWater':
                setBuildingOverWater(value);
                break;
            case 'isBldgContainME':
                setIsBldgContainME(temp);
                break;
            case 'allMachinaryElevated':
                setAllMachinaryElevated(temp);
                break;
            default:
                break;
        }

        if (['isBldgContainME', 'allMachinaryElevated'].includes(state)) {
            props.handleSwitchChange(e);
        } else {
            props.handleRadioChange(e);
        }
    }

    return (
        <div>
            <Col xs="12" className="starting-estimate-content">
                <div className="starting-estimate-icon"></div>
                <p>{t('Current Premium')}</p>
                <p className="starting-estimate-price">
                    <h3>
                        ${(props.premium).toLocaleString("en-US")}<span className="se-slash">/</span>
                        <span className="se-year">{t('Year')}</span>
                    </h3>
                </p>
            </Col>
            <hr />
            <Row>
                <Col xs="8">
                    <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                            <p>{t('Has the building been substantially improved')}?<AiOutlineInfoCircle size={25} style={{ paddingLeft: '2%' }} data-tip data-for="improved" /></p>
                            <ReactTooltip id="improved" place="top" effect="solid">
                                {t('Reconstruction, rehabilitation, addition, or')} <br /> {t('other improvement, the cost of which equals')} <br /> {t('or exceeds 50% of the market value prior')} <br /> {t('to the “start of construction” of improvement')}
                            </ReactTooltip>
                        </div>
                    </div>
                </Col>
                <Col xs="4">
                    <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                        <span>{t('No')}</span>&nbsp;&nbsp;
                        <div className="custom-main-swtich">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="flood-construction-row-switch"
                                label=""
                                parentobject="propertyInfo"
                                name="s_SubstantialImprovement"
                                checked={substantialImprovement === 'YES' ? true : false}
                                onChange={(e) => { handleChange(e, 'substantialImprovement') }}
                                checkedvalue='YES'
                                uncheckedvalue='NO'
                            />
                        </div>
                        <span>{t('Yes')}</span>
                    </div>
                </Col>
                {substantialImprovement === 'YES' && (
                    <Row>
                        <Col xs="8">
                            <div className="flood-construction-answer-div">
                                <p>{t('Date of substantial improvement')}</p>
                                <div className="flood-construction-answer-actions">
                                    <DatePicker
                                        id="flood-construction-date-picker"
                                        className="custom-info-date-picker"
                                        placeholderText="MM/DD/YYYY"
                                        selected={substantialImprovmentDate}
                                        onChange={(date) => {
                                            setSubstantialImprovmentDate(date);
                                            props.handleDateChange(date, 'd_SubstantialImprovmentDate', 'propertyInfo');
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs="8">
                            <div className="flood-construction-row">
                                <div className="flood-construction-question-div">
                                    <p>{t('Is building listed in the National Register of Historic Places')}?</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs="4">
                            <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                                <span>{t('No')}</span>&nbsp;&nbsp;
                                <div className="custom-main-swtich">
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        className="flood-construction-row-switch"
                                        label=""
                                        parentobject="propertyInfo"
                                        name="s_IsBldgNRHP"
                                        checked={isBldgNRHP === 'YES' ? true : false}
                                        onChange={(e) => { handleChange(e, 'isBldgNRHP') }}
                                        checkedvalue='YES'
                                        uncheckedvalue='NO'
                                    />
                                </div>
                                <span>{t('YES')}</span>
                            </div>
                        </Col>
                    </Row>
                )}
            </Row>
            <hr />
            <Row>
                <Col xs="8">
                    <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                            <p>{t('Is the building currently under construction')}?</p>
                        </div>
                    </div>
                </Col>
                <Col xs="4">
                    <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                        <span>{t('No')}</span>&nbsp;&nbsp;
                        <div className="custom-main-swtich">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="flood-construction-row-switch"
                                label=""
                                parentobject="propertyInfo"
                                name="s_BuildingCourseConstruction"
                                checked={buildingCourseConstruction === 'YES' ? true : false}
                                onChange={(e) => { handleChange(e, 'buildingCourseConstruction') }}
                                checkedvalue='YES'
                                uncheckedvalue='NO'
                            />
                        </div>
                        <span>{t('Yes')}</span>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs="8">
                    <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                            <p>{t('Is the "Policyholder/Member a non-profit entity')}?</p>
                        </div>
                    </div>
                </Col>
                <Col xs="4">
                    <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                        <span>{t('No')}</span>&nbsp;&nbsp;
                        <div className="custom-main-swtich">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="flood-construction-row-switch"
                                label=""
                                parentobject="propertyInfo"
                                name="s_IsInsuredNonProfitEnt"
                                checked={isInsuredNonProfitEnt === 'YES' ? true : false}
                                onChange={(e) => { handleChange(e, 'isInsuredNonProfitEnt') }}
                                checkedvalue='YES'
                                uncheckedvalue='NO'
                            />
                        </div>
                        <span>{t('Yes')}</span>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs="4">
                    <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                            <p>{t('Is the building over water')}?</p>
                        </div>
                    </div>
                </Col>
                <Col xs="8">
                    <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                        <Row>
                            <Col>
                                <Form.Label style={{ marginLeft: "72%" }}>
                                    {t('No')}
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    id="propertyInfo"
                                    className="custom-radio-check0"
                                    label=""
                                    parentobject="propertyInfo"
                                    name="s_BuildingOverWater"
                                    title="propertyInfo"
                                    value={'NOTOVERWATER'}
                                    checked={buildingOverWater === "NOTOVERWATER" ? true : false}
                                    onChange={(e) => handleRadioChange(e, 'buildingOverWater')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ marginLeft: "35%" }}>
                                    {t('Partially')}
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    id="propertyInfo"
                                    className="custom-radio-check0"
                                    label=""
                                    parentobject="propertyInfo"
                                    name="s_BuildingOverWater"
                                    title="propertyInfo"
                                    value={'PARTIALLYOVERWATER'}
                                    checked={buildingOverWater === "PARTIALLYOVERWATER" ? true : false}
                                    onChange={(e) => handleRadioChange(e, 'buildingOverWater')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label style={{ marginLeft: "50%" }}>
                                    {t('Fully')}
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Check
                                    type="radio"
                                    id="propertyInfo"
                                    className="custom-radio-check0"
                                    label=""
                                    parentobject="propertyInfo"
                                    name="s_BuildingOverWater"
                                    title="propertyInfo"
                                    value={'FULLOVERWATER'}
                                    checked={buildingOverWater === "FULLOVERWATER" ? true : false}
                                    onChange={(e) => handleRadioChange(e, 'buildingOverWater')}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs="8" style={{ marginBottom: "23px" }}>
                    <Form.Group className="confirm-address-field" controlId="formBasicEmail" >
                        <Form.Label className="confirm-address-label">
                            {t('Number of Elevators')}
                        </Form.Label>
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="confirm-address-field" controlId="formBasicEmail" >
                        <Form.Control className="confirm-address-input"
                            type="number"
                            placeholder="0"
                            name='n_NoOfElevator'
                            parentobject="propertyInfo"
                            title={t('Number of Elevators')}
                            value={noOfElevator < 0 ? '' : noOfElevator > 20 ? '' : noOfElevator == 0 ? '' : noOfElevator}
                            min={0}
                            max={20}
                            onChange={(e) => {
                                props.handleChange(e);
                                setNoOfElevator(e.currentTarget.value);
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs="8" style={{ marginBottom: "23px" }}>
                    <Form.Group className="confirm-address-field" controlId="formBasicEmail" >
                        <Form.Label className="confirm-address-label">
                            {t('Number of Detached Structures')}?
                        </Form.Label>
                    </Form.Group>
                </Col>
                <Col xs="4">
                    <Form.Group className="confirm-address-field" controlId="formBasicEmail" >
                        <Form.Control className="confirm-address-input"
                            type="number"
                            placeholder="0"
                            name='n_NoOfDetatchedStruct'
                            parentobject="propertyInfo"
                            title={t('Number of Detached Structures')}
                            value={noOfDetatchedStruct < 0 ? '' : noOfDetatchedStruct > 20 ? '' : noOfDetatchedStruct == 0 ? '' : noOfDetatchedStruct}
                            min={0}
                            max={20}
                            onChange={(e) => {
                                props.handleChange(e);
                                setNoOfDetatchedStruct(e.currentTarget.value);
                            }}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs="8">
                    <div className="flood-construction-row">
                        <div className="flood-construction-question-div">
                            <p>{t('Does the building contain machinary or equipment that services the building')}?<AiOutlineInfoCircle size={25} style={{ paddingLeft: '1%' }} data-tip data-for="equipment" /></p>
                            <ReactTooltip id="equipment" place="top" effect="solid">
                                {t('Examples include Central Air')} <br /> {t('Conditioner (including External Compressor)')}, <br /> {t('Furnace, Hot Water Heater, Heat Pump (including External Compressor)')}, <br /> {t('Elevator Machinery and Equipment')}
                            </ReactTooltip>
                        </div>
                    </div>
                </Col>
                <Col xs="4">
                    <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                        <span>{t('No')}</span>&nbsp;&nbsp;
                        <div className="custom-main-swtich">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className="flood-construction-row-switch"
                                label=""
                                parentobject="propertyInfo"
                                name="s_IsBldgContainME"
                                checked={isBldgContainME === 'YES' ? true : false}
                                onChange={(e) => handleRadioChange(e, 'isBldgContainME')}
                                checkedvalue='YES'
                                uncheckedvalue='NO'
                            />
                        </div>
                        <span>{t('Yes')}</span>
                    </div>
                </Col>
            </Row>
            <hr />
            {isBldgContainME === 'YES' && (
                <Row>
                    <Col xs="8">
                        <div className="flood-construction-row">
                            <div className="flood-construction-question-div">
                                <p>{t('Is all machinary elevated to the height of attic or second floor or higher')}?</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs="4">
                        <div className="flood-construction-row-actions" style={{ float: "right", display: "flex" }}>
                            <span>{t('No')}</span>&nbsp;&nbsp;
                            <div className="custom-main-swtich">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    className="flood-construction-row-switch"
                                    label=""
                                    parentobject="propertyInfo"
                                    name="s_AllMachinaryElevated"
                                    checked={allMachinaryElevated === 'YES' ? true : false}
                                    onChange={(e) => handleRadioChange(e, 'allMachinaryElevated')}
                                    checkedvalue='YES'
                                    uncheckedvalue='NO'
                                />
                            </div>
                            <span>{t('Yes')}</span>
                        </div>
                    </Col>
                </Row>
            )}

        </div>

    );
};

const mapStateToProps = (state) => {
    return {
        emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
        initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},

    };
}
export default connect(mapStateToProps)(ModeConstructionFormOne);