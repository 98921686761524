import React from "react";
import { Box, Flex, Text, VStack, Switch } from "@chakra-ui/react";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import { waterSublimitFields } from "../../commercial-quote/data/commercialDD";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

const SelectField = ({ options, ...props }) => (
  <ChakraSelect w="30%" {...props}>
    <option value=""></option>
    {options.map((field, i) => (
      <option key={i} value={field}>
        {field}
      </option>
    ))}
  </ChakraSelect>
);

export const AdditionalCoverages = ({ index }) => {
  const { t } = useTranslation();
  const { coverages, setCoverages, selectedCard } = useQuote();

  const updateCoverageDetails = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        additionalCoverages: {
          ...updatedCoverages[index].additionalCoverages,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };

  const updateBasicCoverages = (index, name, value) => {
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[index] = {
        ...updatedCoverages[index],
        basicCoverages: {
          ...updatedCoverages[index].basicCoverages,
          [name]: value,
        },
      };
      return updatedCoverages;
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 680 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        {/* <FormField label={t("All-Perils Deductible")}>
          <ChakraSelect
            w={"30%"}
            value={coverages[index]?.additionalCoverages?.allPerilsDed || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "allPerilsDed", e.target.value);
            }}
          >
            <option value=""></option>
            {aopDed?.map((e, i) => (
              <option key={i} value={e?.s_DiscountCode}>
                {e?.s_ScreenName}
              </option>
            ))}
          </ChakraSelect>
        </FormField> */}

        <FormField label={t("Water Sublimit")}>
          <SelectField
            value={coverages[index]?.additionalCoverages?.waterSublimit || ""}
            onChange={(e) => {
              updateCoverageDetails(index, "waterSublimit", e.target.value);
            }}
            options={waterSublimitFields}
          />
        </FormField>

        {selectedCard === "ConvenienceStore" && (
          <>
            <FormField label={t("Canopy")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.canopy}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "canopy", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Pumps")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.pumps}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "pumps", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Sign")}>
              <CustomCurrencyInput
                value={coverages[index]?.additionalCoverages?.sign}
                onValueChange={(value) => {
                  updateCoverageDetails(index, "sign", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
          </>
        )}
        <FormField label={t("Exclude Sinkhole")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.excludSinkhole === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "excludSinkhole",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("Cosmetic Roof Damage")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.cosmeticRoofDamage ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "cosmeticRoofDamage",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("ACV Roof Surface")}>
          <Switch
            isChecked={
              coverages[index]?.additionalCoverages?.acvRoofSurface === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                index,
                "acvRoofSurface",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>
        <FormField label={t("Theft Exclusion")}>
          <Switch
            isChecked={
              coverages[index]?.basicCoverages?.theftExclusions === "YES"
            }
            onChange={(e) => {
              updateBasicCoverages(
                index,
                "theftExclusions",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>

        <FormField label={t("Wind Exclusion")}>
          <Switch
            isChecked={
              coverages[index]?.basicCoverages?.windExclusions === "YES"
            }
            onChange={(e) => {
              updateBasicCoverages(
                index,
                "windExclusions",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>
      </VStack>
    </Box>
  );
};
