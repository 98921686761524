import React, { Fragment } from "react";
import Header from "./Header";
import AccordionView from "./AccordionView";

const FloodApplication = () => {

  return (
    <Fragment>
      <Header />
      <AccordionView />
    </Fragment>
  );
};

export default FloodApplication;
