import Http from "../Http";
// import { toastAction } from "../store/actions/toast-actions";

const apiUrl = process.env.REACT_APP_API_URL;

export function loadCeLicenseDocumentDataTableServices(params) {
    return new Promise((resolve, reject) => {

        Http.get(
            `${apiUrl}/api/getCeLicenceDocumentList?Params=` +
            JSON.stringify(params)
        )
            .then((res) => {
                return resolve(res.data.data);
            })
            .catch((err) => {
                const { status, errors } = err.response.data;
                const data = {
                    status,
                    errors,
                };
                // toastAction(false, errors);
                return reject(false);
            });
    });
}

export function updateStatusDocument(params) {
    return new Promise((resolve, reject) => {
        Http.post(`${apiUrl}/api/updateCeLicenceDetails`, params)
            .then((res) => {
                return resolve(res);
            })
			.catch((err) => {
				return resolve(false);
            });
    });
}

export function getViewDocument(DocInfo_PK, Sources, module = 'NA') {
    const url = `${apiUrl}/api/corePolicies/generateTempurl/` + DocInfo_PK + '/' + Sources + '/' + module;
    return new Promise((resolve, reject) => {
        Http.get(url)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res)
                }
            })
    })
}

export function getCeLicenseData(id) {
    const url = `${apiUrl}/api/editCeLicenceDocumentData?id=` + id;
    return new Promise((resolve, reject) => {
        Http.get(url)
            .then(res => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(res)
                }
            })
    })
}

export function updateCelicenseInformation(params) {
    return new Promise((resolve, reject) => {
        Http.post(`${apiUrl}/api/updateCelicenseInformation`, params)
            .then((res) => {
                return resolve(res);
            })
			.catch((err) => {
				return resolve(false);
            });
    });
}

export function deleteCeLicenseData(params) {
    return new Promise((resolve, reject) => {
        Http.post(`${apiUrl}/api/deleteCeLicenceDocumentData`, params)
            .then((res) => {
                return resolve(res);
            })
    });
}
