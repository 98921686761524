import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const CommercialQuoteTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Get protection of your assets</span>
      </div>
      <div className="managment-tab-body">
        <div className="flood-quote-tab-main-div">
          <span className="flood-quote-tab-main-title">
            Please remit all commercial submission to commercial inbox
          </span>
          <Link
            className="flood-quote-tab-main-link"
            to={"/commercial@avatar.com"}
          >
            <span>commercial@avatar.com</span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default CommercialQuoteTab;
