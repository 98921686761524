import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Container,
  Row,
  Col,
  Table,
  Card,
  Spinner,
} from "react-bootstrap";
import { postBindApplicationHiscox } from "../../../../api/postBindApplicationHiscox";
import { toast } from "react-toastify";
import { postSaveHiscoxPremiumCard } from "../../../../api/postSaveHiscoxPremium";
import { useNavigate } from "react-router-dom";
import { getFees } from "../../../../api/getFees";
import FeeTable from "../updatePremium/Premium/FeeTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../../../common/Currency";

let minDate = "2000-01-01"; //minimum date for calender ui.

export const BindModal = ({
  show,
  handleClose,
  quoteDetailsData,
  setLoading,
  loading,
}) => {
  const { t } = useTranslation();
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [fees, setFees] = useState({
    fees: {},
    premium: 0,
    totalFee: 0,
    finalPremium: 0,
  });
  const [preferredCardData, setPreferredCardData] = useState(null);
  const { productID } = useParams();
  const { coreSystem } = useSelector((state) => state);
  const [feeLoader, setFeeLoader] = useState(false);
  const [formData, setFormData] = useState({
    hiscoxId: "",
    productType: "",
    commercialProductType: "",
    policyType: "",
    bindingReference: "",
    effectiveDate: getSevenDaysLaterDate(),
    premium: 0.0,
    fullName: "",
    includeLossOfUse: true,
    includeContents: true,
    includeBusinessIncomeAndExtraExpense: true,
    buildingLimit: "",
    contentsLimit: "",
    deductible: "",
    buildingCoverage: 0,
    improvementBettermentCoverage: 0,
    contentCoverage: 0,
    contentsLimitOrignal: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    minDate = getSevenDaysLaterDate();
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const valueToUse = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: valueToUse,
    }));
  };

  function getSevenDaysLaterDate() {
    const today = new Date();
    const sevenDaysLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return sevenDaysLater.toISOString().split("T")[0];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    bindApplication();
  };

  const calculateTotalPremium = () => {
    const total =
      (selectedCardData?.hasOwnProperty("improvementsAndBettermentsPremium")
        ? selectedCardData?.improvementsAndBettermentsPremium
        : 0) +
      (selectedCardData?.hasOwnProperty("buildingPremium")
        ? selectedCardData.buildingPremium
        : 0) +
      (formData.includeContents &&
      selectedCardData?.hasOwnProperty("contentsPremium")
        ? selectedCardData.contentsPremium
        : 0) +
      (formData.includeLossOfUse &&
      selectedCardData?.hasOwnProperty("lossOfUsePremium")
        ? selectedCardData.lossOfUsePremium
        : 0) +
      (selectedCardData?.hasOwnProperty("otherStructuresPremium")
        ? selectedCardData.otherStructuresPremium
        : 0) +
      (formData.includeBusinessIncomeAndExtraExpense &&
      selectedCardData?.hasOwnProperty("businessIncomePremium")
        ? selectedCardData.businessIncomePremium
        : 0);
    return total ===
      quoteDetailsData?.data?.premium?.selectedPremium?.chargedPremium
      ? quoteDetailsData?.data?.premium?.selectedPremium?.chargedPremium
      : Number(total);
  };

  useEffect(() => {
    if (quoteDetailsData?.data?.premium?.selectedCoverage) {
      setSelectedCardData(quoteDetailsData?.data?.premium?.selectedCoverage);
      setPreferredCardData(quoteDetailsData?.data?.premium?.selectedPremium);
      setFormData((prevData) => ({
        ...prevData,
        includeBusinessIncomeAndExtraExpense:
          quoteDetailsData?.data?.premium?.selectedPremium
            ?.includeBusinessIncomeAndExtraExpense,
        includeLossOfUse:
          quoteDetailsData?.data?.premium?.selectedPremium?.includeLossOfUse,
        includeContents:
          quoteDetailsData?.data?.premium?.selectedPremium?.includeContents,
        contentsLimit:
          quoteDetailsData?.data?.premium?.selectedPremium?.contentsLimit,
        buildingLimit:
          quoteDetailsData?.data?.premium?.selectedPremium?.buildingLimit ||
          quoteDetailsData?.data?.premium?.selectedPremium
            ?.improvement_betterment_coverage,
        contentsLimitOrignal:
          quoteDetailsData?.data?.premium?.selectedPremium
            ?.contentsLimitOrignal || "",
        hiscoxId: quoteDetailsData?.data?.quoteId || "",
        commercialProductType: quoteDetailsData?.data?.commercialProductType,
        productType: quoteDetailsData?.data?.productType,
        fullName: `${quoteDetailsData?.data?.firstName || ""} ${
          quoteDetailsData?.data?.lastName || ""
        }`,
      }));
    }
  }, [quoteDetailsData?.data?.premium?.selectedCoverage]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      premium: Number(calculateTotalPremium()) || 0.0,
      deductible: selectedCardData?.deductible,
      policyType: preferredCardData?.type,
    }));
  }, [
    selectedCardData,
    formData.includeContents,
    formData.includeLossOfUse,
    formData.includeBusinessIncomeAndExtraExpense,
  ]);

  async function bindApplication() {
    const {
      productType,
      commercialProductType,
      policyType,
      bindingReference,
      effectiveDate,
      premium,
      fullName,
      includeLossOfUse = formData?.includeBusinessIncomeAndExtraExpense,
      includeContents,
      buildingLimit,
      contentsLimit,
      deductible,
    } = formData || {};

    //this is bind req body
    const requestBody = {
      productType: formData?.productType,
      commercialProductType: formData?.commercialProductType,
      policyType: formData?.policyType,
      bindingReference: formData?.bindingReference,
      effectiveDate: formData?.effectiveDate,
      premium: formData?.premium,
      fullName: formData?.fullName,
      includeLossOfUse:
        formData?.includeLossOfUse ??
        formData?.includeBusinessIncomeAndExtraExpense,
      includeContents: formData?.includeContents,
      buildingLimit: formData?.buildingLimit,
      contentsLimit:
        formData?.contentsLimitOrignal !== null &&
        formData?.contentsLimitOrignal !== undefined
          ? formData?.contentsLimitOrignal
          : formData?.contentsLimit,
      deductible: formData?.deductible,
    };

    //this is update premium body
    const updateBody = {
      calculatedFees: fees,
      chargedPremium: premium,
      content_coverage:
        formData?.contentsLimitOrignal !== null &&
        formData?.contentsLimitOrignal !== undefined
          ? formData?.contentsLimitOrignal
          : formData?.contentsLimit,
      deductible: deductible,
      includeContents: includeContents,
      type: policyType,
    };
    if (productType === "Residential") {
      updateBody.includeLossOfUse = formData.includeLossOfUse;
      updateBody.building_coverage = buildingLimit;
    } else if (productType === "Commercial") {
      updateBody.includeBusinessIncomeAndExtraExpense =
        formData.includeBusinessIncomeAndExtraExpense;
      updateBody.improvement_betterment_coverage = buildingLimit;
    }
    try {
      setLoading(true);
      const updated = await postSaveHiscoxPremiumCard({
        requestBody: updateBody,
        quoteNo: formData?.hiscoxId,
      });
      if (updated?.status === true) {
        const data = await postBindApplicationHiscox({
          requestBody,
          quoteNo: formData?.hiscoxId,
        });

        if (data?.status === false && Array.isArray(data?.errors)) {
          data?.errors.forEach((error) => {
            toast.warn(error, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            });
          });
        } else if (data?.status === true) {
          toast.success("Application Bound In Progress!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4500,
          });
          // navigate(`/core/policy/${data?.data?.policyNo}`);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          handleClose();
        }
        if (
          data?.status === false &&
          data?.errors[0] == "Quote is already bound"
        ) {
          handleClose();
        }
      } else {
        toast.error("Failed To Update, Make Sure Premium is Selected", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Failed to bind application:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (formData?.premium !== undefined && formData?.premium !== null) {
      fetchFees();
    }
  }, [formData?.premium]);

  async function fetchFees() {
    if (!quoteDetailsData?.data || !quoteDetailsData?.data.stateCode) {
      return;
    }
    const requestBody = {
      prTransTypeCode: "NEWBUSINESS",
      state: quoteDetailsData?.data.stateCode || "",
      productId: coreSystem?.applicationData?.n_ProductId_FK || "26", // for ex 26 : hiscox product id
      premium: Number(formData?.premium) || 0,
    };
    setFeeLoader(true);
    try {
      const fees = await getFees(requestBody, productID);
      if (fees?.data?.success) {
        setFees(fees?.data?.data);
      } else {
        setFees(null);
      }
      return fees;
    } catch (error) {
      console.error("Failed to calculate fees:", error);
      return null;
    } finally {
      setFeeLoader(false);
    }
  }

  const handleClearDate = () => {
    setFormData((prevData) => ({
      ...prevData,
      effectiveDate: "",
    }));
  };

  const formatCurrency = (value) => {
    return <Currency amount={value} />;
  };
  const CustomSpinner = () => {
    return (
      <div className="custom-spinner-container d-flex align-items-center flex-column">
        <Spinner variant="primary" animation="border" role="status">
          <span className="visually-hidden">{t("Loading...")}</span>
        </Spinner>
        <p
          className="custom-spinner-text mt-2 text-center text-secondary"
          style={{ fontSize: "small" }}
        >
          {t("Loading...")}
        </p>
      </div>
    );
  };
  return (
    <Modal scrollable size="lg" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("bind_quote_application")}</Modal.Title>
      </Modal.Header>
      {loading && <CustomSpinner />}
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between mx-4">
          <div>
            <Form.Group
              controlId="includeContents"
              className="mb-0 d-flex align-items-center ml-2 w-100"
            >
              <Form.Label className="mr-2 mb-0">
                {t("include_contents")}
              </Form.Label>
              <Form.Check
                type="switch"
                id="includeContentsSwitch"
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    includeContents: e.target.checked,
                  }))
                }
                defaultChecked={formData.includeContents}
                className="mx-2"
              />
            </Form.Group>
          </div>
          <div>
            {preferredCardData?.includeBusinessIncomeAndExtraExpense == null ? (
              <Form.Group
                controlId="includeLossOfUse"
                className="mb-0 d-flex align-items-center"
              >
                <Form.Label className="mr-2 mb-0">
                  {t("include_loss_of_use")}
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="includeLossOfUseSwitch"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      includeLossOfUse: e.target.checked,
                    }))
                  }
                  defaultChecked={formData.includeLossOfUse}
                  className="mx-2"
                />
              </Form.Group>
            ) : (
              <Form.Group
                controlId="includeBusinessIncomeAndExtraExpense"
                className="mb-0 d-flex align-items-center"
              >
                <Form.Label className="mr-2 mb-0">
                  {t("include_business_income_and_extra_expense")}
                </Form.Label>
                <Form.Check
                  type="switch"
                  id="includeBusinessIncomeAndExtraExpenseSwitch"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      includeBusinessIncomeAndExtraExpense: e.target.checked,
                    }))
                  }
                  defaultChecked={formData.includeBusinessIncomeAndExtraExpense}
                  className="mx-2"
                />
              </Form.Group>
            )}
          </div>
        </div>

        <div
          style={{
            width: "75%",
            margin: " 0 auto",
            marginTop: "30px",
          }}
        >
          <Table hover responsive bordered style={{ borderRadius: "5px" }}>
            <thead>
              <tr className="font-weight-bold">
                <th>{t("quote_number")}</th>
                <th className="text-end" style={{ paddingRight: "10px" }}>
                  {formData?.hiscoxId}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "16px" }}>
              <>
                <tr>
                  <td>{t("type")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    {formData?.policyType}
                  </td>
                </tr>
                <tr
                  className={`${
                    !formData.includeContents && "text-decoration-line-through"
                  }`}
                >
                  <td>{t("contents_limit")}:</td>
                  <td
                    className={`${
                      !formData.includeContents
                        ? "text-decoration-line-through text-end"
                        : "text-end"
                    }`}
                    style={{ paddingRight: "10px" }}
                  >
                    {/* //contentsLimitOrignal */}
                    {/* {formData?.includeContents ? } */}
                    <Currency
                      amount={
                        formData?.contentsLimitOrignal ||
                        formData?.contentsLimit
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {formData?.commercialProductType === "Tenanted"
                      ? t("improvements_and_betterments_limit")
                      : t("building_limit")}
                    :
                  </td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    <Currency amount={formData.buildingLimit} />
                  </td>
                </tr>
                <tr>
                  <td>{t("Deductible")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    <Currency amount={selectedCardData?.deductible} />
                  </td>
                </tr>
              </>
              {selectedCardData?.hasOwnProperty("buildingPremium") && (
                <tr>
                  <td>{t("building_premium")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    <Currency amount={selectedCardData?.buildingPremium} />
                  </td>
                </tr>
              )}
              <tr
                className={`${
                  !formData.includeContents && "text-decoration-line-through"
                }`}
              >
                <td>{t("contents_premium")}:</td>
                <td className="text-end" style={{ paddingRight: "10px" }}>
                  <Currency amount={selectedCardData?.contentsPremium} />
                </td>
              </tr>
              {selectedCardData?.hasOwnProperty("businessIncomePremium") && (
                <tr
                  className={`${
                    !formData.includeBusinessIncomeAndExtraExpense &&
                    "text-decoration-line-through"
                  }`}
                >
                  <td>{t("business_income_premium")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    {formatCurrency(selectedCardData?.businessIncomePremium) ||
                      0}
                  </td>
                </tr>
              )}
              {selectedCardData?.hasOwnProperty(
                "improvementsAndBettermentsPremium"
              ) && (
                <tr>
                  <td>{t("improvements_and_betterments_premium")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    {formatCurrency(
                      selectedCardData?.improvementsAndBettermentsPremium
                    ) || 0}
                  </td>
                </tr>
              )}
              {selectedCardData?.hasOwnProperty("lossOfUsePremium") && (
                <tr
                  className={`${
                    !formData.includeLossOfUse && "text-decoration-line-through"
                  }`}
                >
                  <td>{t("loss_of_use_premium")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    <Currency amount={selectedCardData?.lossOfUsePremium} />
                  </td>
                </tr>
              )}
              {selectedCardData?.hasOwnProperty("otherStructuresPremium") && (
                <tr>
                  <td>{t("other_structures_premium")}:</td>
                  <td className="text-end" style={{ paddingRight: "10px" }}>
                    <Currency
                      amount={selectedCardData?.otherStructuresPremium}
                    />
                  </td>
                </tr>
              )}
              <tr className="font-weight-bold" style={{ fontSize: "16px" }}>
                <td>{t("total_premium")}:</td>
                <td className="text-end " style={{ paddingRight: "10px" }}>
                  <Currency amount={formData.premium} />
                </td>
              </tr>
            </tbody>
          </Table>
          <Card className="shadow-sm mb-3">
            <Card.Header className=" d-flex align-items-center gap-2 bg-light text-center justify-content-center">
              <strong>{t("fees")}</strong>{" "}
              {feeLoader && <Spinner animation="border" size="sm" />}
            </Card.Header>
            <Card.Body>
              <FeeTable fees={fees} />
            </Card.Body>
          </Card>

          <Card className="shadow-sm" style={{ opacity: feeLoader ? 0.5 : 1 }}>
            <Card.Header className="bg-light text-center">
              <strong>{t("current_premium")}</strong>
            </Card.Header>
            <Card.Body style={{ fontSize: "28px" }}>
              <p className="text-center text-secondary">
                $
                {new Intl.NumberFormat("en-US").format(
                  Number(calculateTotalPremium() || 0) +
                    Number(fees?.totalFee || 0)
                )}
              </p>
            </Card.Body>
          </Card>
        </div>

        <Form className="pt-4">
          <Container>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="bindingReference">
                  <Form.Label>{t("reference")}</Form.Label>
                  <Form.Control
                    placeholder="Binding Reference"
                    type="text"
                    name="bindingReference"
                    value={formData.bindingReference}
                    onChange={handleChange}
                  />
                  {formData?.bindingReference == null ||
                    (formData?.bindingReference == "" && (
                      <Form.Text
                        className="label-asterisk"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {t("binding_reference_required")}
                      </Form.Text>
                    ))}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="effectiveDate">
                  <Button
                    onClick={handleClearDate}
                    size="sm"
                    className="float-end"
                    id="primary-colored-btn"
                  >
                    {t("clear_date")}
                  </Button>
                  <Form.Label> {t("effective_date")}</Form.Label>
                  <Form.Control
                    type="date"
                    // min={minDate}
                    name="effectiveDate"
                    value={formData.effectiveDate}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Control
              disabled
              value={formData.premium}
              type="hidden"
              name="premium"
            />
            <Form.Control
              disabled
              type="hidden"
              name="buildingLimit"
              value={formData.buildingLimit}
              onChange={handleChange}
            />

            <Form.Control
              disabled
              type="hidden"
              name="contentsLimit"
              value={formData.contentsLimit}
              onChange={handleChange}
            />
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center gap-2">
        <Button id="primary-colored-btn" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button
          id="primary-colored-btn"
          type="submit"
          onClick={handleSubmit}
          disabled={
            formData?.bindingReference == null ||
            formData?.bindingReference === ""
          }
        >
          {t("bind_quote")}
        </Button>
      </Modal.Footer>
      {/* this is custom mini loader css */}
      <style>{`
      .custom-spinner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 9999;
  }
`}</style>
    </Modal>
  );
};
