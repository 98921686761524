import React, { useEffect, useState } from 'react';
import { Button, Modal,Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import '../Mortgagee/flood-add-mortages.scss';
import '../Mortgagee/add-mortage.scss';
import { useDispatch } from 'react-redux';
import { getFemaUpdates} from "../../../../../services/dashboardService";

const CreateApplication = ({ showSubmitAppModal, waitingPeriod, submitApplication, setShowSubmitAppModal }) => {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const { apiLoading } = useSelector(state => state.flood);
    const [waitingPeriodText, setWaitingPeriodText] = useState('');
    const [text, setText] = useState('');
    const [femaUpdateMsg, setFemaUpdateMsg] = useState({ __html: "" });
    const [isHiatus, setIsHiatus] = useState(false);
	
	useEffect(() => {
		checkFemaUpdates();
	  }, [localStorage.getItem('lang') || 'en']);
	  
	  const checkFemaUpdates = async () => {
		await dispatch(getFemaUpdates()).then((res) => {
			setIsHiatus(res.data.data.isHiatus);
			if(res.data.data.isHiatus == true){
				setFemaUpdateMsg({ __html: res.data.data.hiatusSignAndPayMsg });
			}
		})
	  }

    useEffect(() => {
        if (![null, ''].includes(waitingPeriod)) {
            switch (waitingPeriod) {
                case 'STANDARD30':
                    setWaitingPeriodText('Standard - 30 Day Wait')
                    setText('Please ensure that payment is received within 9 days from the signed application.')
                    break;
                case 'LENDER30':
                    setWaitingPeriodText('Lender Required – 30 days wait')
                    setText('Please ensure that payment is received within 9 days from the signed application.')
                    break;
                case 'LOANNOWAIT':
                    setWaitingPeriodText('Loan Transaction – No wait')
                    setText('Please ensure that payment is received within 9 days from the effective date. If the lender, title company, or settlement attorney pays the premium then payment must be received within 29 days from the effective date.')
                    break;
                case 'MAPREVONEDAY':
                    setWaitingPeriodText('Map Revision – One day')
                    setText('Please ensure that payment is received within 9 days from the signed application. If payment is received after 13 months from the map revision effective date, a 30-day wait will be applied.')
                    break;
                case 'ROLLOVER_RENEWAL':
                    setWaitingPeriodText('Transfer')
                    setText('Please ensure that payment is received within 29 days from the prior policy expiration date.')
                    break;
                default:
                    break;
            }
        }
    }, [waitingPeriod])

    return (
        <Modal size="lg" show={showSubmitAppModal} className="add-mortage-section add-mortage-modal">
            <Modal.Header id="add-mortage-modal-header">
                <Modal.Title>Create Application</Modal.Title>
            </Modal.Header>
            <LoadingOverlay>
                <Loader loading={apiLoading} />
                <Modal.Body className="flood-add-mortage-form-section" style={{ marginTop: '0px' }}>
                    <div className="add-mortage-form" style={{ textAlign: 'center' }}>
                        <h3>{t("Payment Requirement for ")}<b>{t(waitingPeriodText)}</b></h3><hr />
                        <p className='text-center' style={{ color: 'rgb(0, 112, 192)' }}><h5>{text}</h5></p>
                        <p className='text-center' style={{ marginTop: '40px' }}><h6>*{t('By Pressing the Submit button you are digitally signing the application, ensuring that payment will be made')}.</h6></p>
						{(isHiatus == true) &&
				          <>
				            <div>
				              <Alert variant="warning">
				                <div dangerouslySetInnerHTML={femaUpdateMsg}></div>
				              </Alert>
				            </div>
				          </>
				        }
                    </div>
					
                </Modal.Body>
            </LoadingOverlay >

            <Modal.Footer className="add-mortage-modal-footer">
                <Button
                    id="primary-grey-btn-flood"
                    variant="outline-dark"
                    onClick={() => setShowSubmitAppModal(false)}
                    style={{
                        marginRight: "1rem",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                    disabled={apiLoading}
                >
                    {t('Cancel')}
                </Button>

                <Button
                    onClick={() => submitApplication()}
                    id="primary-colored-btn"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    disabled={apiLoading}
                >
                    {t('Submit')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateApplication;