import React from "react";
import {
  Box,
  Text,
  FormLabel,
  Flex,
  Stack,
  HStack,
  Card,
  Switch,
  Divider
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Options = ({ disabled, onChange, data }) => {
  const { t } = useTranslation();
  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    const switchEvent = {
      target: { value: checked ? "Y" : "N", name: name }
    }
    onChange(switchEvent);
  };
  return (<Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
    <Box p={6} mb={4}>
      <Text fontWeight={600} fontSize={"xl"}>
        {t("Options")}
      </Text>
      <Divider orientation='horizontal' />
      <Stack>
        <Switches
          data={data}
          handleSwitchChange={handleSwitchChange}
          disabled={disabled}
        />
      </Stack>
    </Box>
  </Card>
  );
};

const switchConfig = [  
  
  {
    label: "Turn Off AL3 Downloads",
    name: "download_suspended",
    dataKey: "download_suspended"
  }
];

const Switches = ({ data, handleSwitchChange, disabled }) => {
  return (
    <>
      {switchConfig.map((config, index) => (
        <Flex key={index} justifyContent="space-between" alignItems="center">
          <HStack>
            <FormLabel>{config.label}</FormLabel>
          </HStack>
          <HStack>
            <Switch
              size="lg"
              name={config.name}
              isChecked={data && data[config.dataKey] === "Y"}
              onChange={handleSwitchChange}
              isDisabled={disabled}
            />
          </HStack>
        </Flex>
      ))}
    </>
  );
};
export default Options;
