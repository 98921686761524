import React, { useEffect, useState } from "react";
import MessageAlerts from "./MessegesAleart";

const addPrefix = (prefix, messages) => {
  return messages.map((message) => `${prefix}: ${message}`);
};

const countOccurrences = (messages) => {
  return messages.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});
};

const formatMessages = (messages, counts) => {
  return [...new Set(messages)].map((message) =>
    counts[message] > 1 ? `${message} (x${counts[message]})` : message
  );
};

const collectPrimaryErrors = (data) => {
  if (data && data.status === false) {
    return ["Error: " + data.errors[0]];
  }

  const errors = [
    ...(data?.errors || []),
    ...(data?.errors ? addPrefix("Error ", data.data.errors) : []),
    ...(data?.commercialOwned?.primary || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
    ...(data?.commercialTenanted?.primary || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
    ...(data?.residential?.primary || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
  ];

  return errors;
};

const collectPrimaryWarnings = (data) => {
  const warnings = [
    ...(data?.warnings || []),
    ...(data?.commercialOwned?.primary || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
    ...(data?.commercialTenanted?.primary || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
    ...(data?.residential?.primary || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
  ];

  return warnings;
};

const PrimaryErrorHandler = ({ premiumResponseData }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    let allErrors = [];
    let allWarnings = [];

    if (premiumResponseData?.data) {
      allErrors = collectPrimaryErrors(premiumResponseData.data);
      allWarnings = collectPrimaryWarnings(premiumResponseData.data);
    } else {
      allErrors = collectPrimaryErrors(premiumResponseData) || [];
      allWarnings = collectPrimaryWarnings(premiumResponseData) || [];
    }

    const errorCounts = countOccurrences(allErrors);
    const formattedErrors = formatMessages(allErrors, errorCounts);

    const warningCounts = countOccurrences(allWarnings);
    const formattedWarnings = formatMessages(allWarnings, warningCounts);

    setErrorMessages(formattedErrors);
    setWarningMessages(formattedWarnings);
  }, [premiumResponseData]);

  const handleDismissError = () => {
    setErrorMessages([]);
  };

  const handleDismissWarning = () => {
    setWarningMessages([]);
  };

  return (
    <div>
      <MessageAlerts
        errorMessages={errorMessages}
        warningMessages={warningMessages}
        onDismissError={handleDismissError}
        onDismissWarning={handleDismissWarning}
      />
    </div>
  );
};

export default PrimaryErrorHandler;
