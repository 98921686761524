import Http from "../../../../../Http";

export async function postDeletePolicyMortagee(requestBody) {
  try {
    const url = `/api/quote/deletePolicyInterest`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (err) {
    console.error(
      "Error in postHiscoxDeleteMortageeQuote:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
