import states from "../../../common/States";
import { TEMP_VEBDORS_CALL } from "../../../create-star-quote/database/CreateStarQuotePool";

const nonMSHurricaneDeductibles = {
  //  2, 5, 10, 15, 20, 25, 50
  HURRA005P: 2,
  HURRA01P: 5,
  HURRA02P: 10,
  HURRA03P: 15,
  HURRA04P: 20,
  HURRA05P: 25,
};

const windType = {
  fsl: "FSL",
  IRC: "2006 IRC",
  fehBronze: "FEH Bronze",
  fehSilver: "FEH Silver",
  fehGold: "FEH Gold",
};

function findResponseOfRisk(data) {
  if (typeof data !== "object" || data === null) {
    return "";
  }
  for (const [key, value] of Object?.entries(data)) {
    const response = value?.s_PoAdditionalStatsResponse;
    if (key.startsWith("Question_") && parseInt(key.split("_")[1], 10) === 11) {
      return response;
    }
  }
  return "";
}

function findResponseOfStructure(data) {
  if (typeof data !== "object" || data === null) {
    return "";
  }
  for (const [key, value] of Object?.entries(data)) {
    const response = value?.s_PoAdditionalStatsResponse;
    if (key.startsWith("Question_") && parseInt(key.split("_")[1], 10) === 9) {
      return response;
    }
  }
  return "";
}

function isNotEmpty(value) {
  return (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    value !== 0 &&
    value !== false
  );
}

const nonMSNonHurricaneDeductibles = {
  // 500, 1000, 2500, 5000, 10000, 20000, 25000, 50000
  DED500: 500,
  DED750: 500,
  DED1000: 1000,
  DED1500: 1000,
  DED2000: 2500,
  DED2500: 2500,
  DED3000: 5000,
  DED3500: 5000,
  DED4000: 5000,
  DED4500: 10000,
  DED5000: 10000,
};

const getMortgageeType = (personType) => {
  switch (personType) {
    case "PRIMARY":
      return "First mortgagee";
    case "SECOND":
      return "Second mortgagee";
    case "THIRD":
      return "Third mortgagee";
    default:
      return null;
  }
};

const getCountyName = (countyName) => {
  return countyName ? countyName.toUpperCase() + " COUNTY" : null;
};

const formatDate = (dateString) => {
  if (!dateString || typeof dateString !== "string") return null;
  const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
  if (regexYYYYMMDD.test(dateString)) return dateString;

  const regexMMDDYYYY = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  const match = dateString.match(regexMMDDYYYY);
  if (match) return `${match[3]}-${match[1]}-${match[2]}`;

  const regexYYYYDDMM = /^(\d{4})-(\d{2})-(\d{2})$/;
  const match2 = dateString.match(regexYYYYDDMM);
  if (match2) return dateString;

  return null;
};

function extractFirstNumberForCreditScore(s) {
  if (s === null || s === undefined) {
    return 700;
  }
  if (typeof s === "number") {
    return s;
  }
  const match = s.match(/^\D*(\d+)\D*/);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return 0;
  }
}

export const CreateStarApplicationPool = ({ quoteData, baseData, vendors }) => {
  if (
    Object.keys(quoteData?.propertyInfo?.metadata).length === 0 ||
    !quoteData?.propertyInfo?.metadata
  ) {
    return console.error("Quote data not found");
  }
  const {
    fireOtherProtectionDevice,
    fireMonitoAlarm,
    fireLocalAlarm,
    theftDeadboltLocks,
    theftLocalBurglarAlarm,
    theftLocalMonitorAlarm,
    // hasSprinklarSystem,
    // sprinklerType,
    // hasSmartHomeProtection,
    homeWaterDetection,
    isApplicantLocatedNorhI10,
  } = quoteData?.propertyInfo?.metadata || {};
  const additionaInsuredData = quoteData?.additionaInsuredData || [];

  const createAdditionalInsured = (insuredArr) => {
    if (
      !Array.isArray(insuredArr) ||
      (Array.isArray(insuredArr) && insuredArr.length < 1)
    ) {
      return [];
    }
    const additionalInsured = [];
    insuredArr.forEach((item) => {
      additionalInsured.push({
        first_name: item?.person?.firstName || "",
        last_name: item?.person?.lastName || "",
        phone_number: item?.person?.s_PhoneNumber || "",
      });
    });

    return additionalInsured;
  };
  let quotionKeyWithPoolManagerKey = {};
  if (Array.isArray(baseData?.GENERAL)) {
    baseData.GENERAL.map((question) => {
      quotionKeyWithPoolManagerKey[question.n_PRPolicyAddStatQuestions_Pk] =
        question.poolManagerKey;
    });
  }

  let setPoolManagerValues = {};

  for (const questionId in quoteData.allAnswerData) {
    const item = quoteData.allAnswerData[questionId];
    const poolManagerKey =
      quotionKeyWithPoolManagerKey[item.n_PRPolicyAddStatQuestions_FK];
    setPoolManagerValues[poolManagerKey] =
      item.s_PoAdditionalStatsAnswer === "false" ? false : true;
  }

  const sprinklerSystemType =
    quoteData?.propertyInfo?.metadata?.sprinklerSystemType;
  if (["partial", "fully automatic", "none"].includes(sprinklerSystemType)) {
  } else {
    return "";
  }

  let storeDiscountObj = {
    fsl: false,
    IRC: false,
    fehBronze: false,
    fehSilver: false,
    fehGold: false,
  };

  function splitStringIntoArray(inputString) {
    if (!inputString) {
      return [];
    }
    inputString = inputString.trim();
    const result = inputString.split(/,\s*/);
    return result.map((item) => item.trim());
  }

  const additionalRisk =
    splitStringIntoArray(findResponseOfRisk(quoteData?.allAnswerData)) || [];

  const addtionalStructure =
    findResponseOfStructure(quoteData?.allAnswerData) || "";

  const determineWindType = (metadata) => {
    let windType = "None";
    if (metadata?.fehBronze) {
      windType = "FEH Bronze";
    } else if (metadata?.fehSilver) {
      windType = "FEH Silver";
    } else if (metadata?.fehGold) {
      windType = "FEH Gold";
    } else if (metadata?.IRC) {
      windType = "2006 IRC";
    } else if (metadata?.fsl) {
      windType = "FSL";
    }
    return windType;
  };

  function findTrueCondition(storeDiscountObj) {
    if (!storeDiscountObj || typeof storeDiscountObj !== "object") {
      console.error("Invalid storeDiscountObj");
      return null;
    }
    const trueCondition = Object.entries(storeDiscountObj).find(
      ([key, value]) => value === true
    );
    return trueCondition ? trueCondition[0] : null;
  }
  // const trueConditionKey = findTrueCondition(storeDiscountObj);

  function getStateCode(input) {
    if (typeof input !== "string") {
      return "";
    }
    const normalizedInput = input.toLowerCase();

    const matchedStateByName = states.find(
      (state) => state.name.toLowerCase() === normalizedInput
    );

    if (matchedStateByName) {
      return matchedStateByName.abbr;
    }
    const matchedStateByAbbr = states.find(
      (state) => state.abbr.toLowerCase() === normalizedInput
    );

    if (matchedStateByAbbr) {
      return matchedStateByAbbr.abbr;
    } else {
      return "";
    }
  }

  const extractMortgagees = (data) => {
    if (!Array.isArray(data) || (Array.isArray(data) && data.length < 1)) {
      return [];
    }
    const mortgagees = [];
    data.forEach((item) => {
      if (
        !["ADDITIONALINTEREST", "ADDITIONALINSURED"].includes(item.s_PersonType)
      ) {
        mortgagees.push({
          n_PolicyAddInterest_PK: item.n_PolicyAddInterest_PK || null,
          personType: item.s_PersonType || "",
          mortgageeType: getMortgageeType(item.s_PersonType) || "",
          loanNo: item.s_LoanNo || "",
          person: {
            entityType:
              typeof item.person.s_EntityType === "string"
                ? item.person.s_EntityType.toUpperCase()
                : item.person.s_EntityType || "",
            firstName: item.person.s_FirstName || "",
            middleName: item.person.s_MiddleName || "",
            lastName: item.person.s_LastOrganizationName || "",
            isMailingDiff: item.person.s_IsMailingDiff || "",
            isTempMailing: item.person.s_IsTempMailing || "",
            n_PersonInfoId_PK: item.person.n_PersonInfoId_PK || null,
            isInsuredTenant: "",
          },
          address: {
            addressLine1: item.address.s_AddressLine1 || "",
            addressLine2: item.address.s_AddressLine2 || "",
            county:
              getCountyName(item.address.s_CountyName) ||
              item.address.s_CountyName ||
              "",
            city: item.address.s_CityName || "",
            cityId: item.address.n_CityId_FK || 0,
            stateId: item.address.n_StateId_FK || 0,
            countryId: item.address.n_CountryId_FK || 0,
            stateCode: item.address.s_StateName || "",
            country: "US",
            countyId: item.address.n_CountyId_FK || 1,
            latitude: parseFloat(item.address.s_Latitude) || null,
            longitude: parseFloat(item.address.s_Longitude) || null,
            zip: item.address.s_PostalCode || "",
          },
        });
      }
    });

    return mortgagees;
  };
  function calculateAverageCreditScore(score1, score2) {
    const isValidScore = (score) =>
      typeof score === "number" && score >= 350 && score <= 850;

    const validScore1 = isValidScore(score1) ? score1 : null;
    const validScore2 = isValidScore(score2) ? score2 : null;

    if (validScore1 === null && validScore2 === null) {
      return "No valid scores provided.";
    }

    if (validScore1 !== null && validScore2 === null) {
      return validScore1;
    }

    if (validScore1 === null && validScore2 !== null) {
      return validScore2;
    }

    return (validScore1 + validScore2) / 2;
  }

  function extractEmailAndPhoneNumber(communications) {
    if (
      !Array.isArray(communications) ||
      (Array.isArray(communications) && communications.length < 1)
    ) {
      return { email: "", phoneNumber: "" };
    }
    let email = "";
    let phoneNumber = "";
    for (const comm of communications) {
      if (comm.s_CommType === "EMAIL" && !email) {
        email = comm.s_CommValue;
      } else if (comm.s_CommType === "HOME" && !phoneNumber) {
        phoneNumber = comm.s_CommValue;
      }
    }
    return { email: email || "", phoneNumber: phoneNumber || "" };
  }
  const extractAdditionalInsured = (input) => {
    if (!Array.isArray(input) || input.length === 0) {
      return [];
    }
    return input
      .filter(
        (item) =>
          item?.s_PersonType === "ADDITIONALINTEREST" &&
          item?.s_AddionalIntType === "ADDITIONALINSURED"
      )
      .map((item) => {
        const { email, phoneNumber } = extractEmailAndPhoneNumber(
          item.communication || []
        );
        const person = item.person || {};
        const address = item.address || {};

        return {
          n_PolicyAddInterest_PK: item.n_PolicyAddInterest_PK,
          personType: item.s_PersonType,
          mortgageeType: item.s_MortgageeType || "",
          loanNo: item.s_LoanNo || "",
          person: {
            creditScore: person.metadata.creditScore ?? 0,
            dob: person.d_BirthDate || "",
            email,
            phoneNumber,
            entityType: person.s_EntityType || "",
            firstName: person.s_FirstName || "",
            middleName: person.s_MiddleName || "",
            lastName: person.s_LastOrganizationName || "",
            isMailingDiff: person.s_IsMailingDiff || "",
            isTempMailing: person.s_IsTempMailing || "",
            isInsuredTenant: "",
          },
          address: {
            sameAsMailingAddress: person.s_IsMailingDiff === "NO",
            addressLine1: address.s_AddressLine1 || "",
            addressLine2: address.s_AddressLine2 || "",
            county: address.s_CountyName || "",
            city: address.s_CityName || "",
            stateCode: address.s_StateName || "",
            zip: address.s_PostalCode || "",
            country: "US",
            latitude: 0,
            longitude: 0,
            cityId: address.n_CityId_FK ?? 0,
            stateId: address.n_StateId_FK ?? 0,
            countryId: address.n_CountryId_FK ?? 1,
            countyId: address.n_CountyId_FK ?? 0,
          },
        };
      });
  };

  const parseProperNumber = (str) => {
    let result;
    try {
      const cleanedStr = str.replace(/\.0+$/, "");
      result = parseFloat(cleanedStr);
    } catch (error) {
      result = 0;
    }
    return result;
  };

  const createClaimDetail = (input) => {
    if (!Array.isArray(input) || input.length === 0) {
      return [];
    }
    return input.map((item) => ({
      date_of_loss: formatDate(item.d_LossDate) ?? "",
      total_payout: parseFloat(item.n_LossAmount ?? ""),
      cause_of_loss: item.s_LossType ?? "",
      loss_description: item.s_LossDescription ?? "",
    }));
  };

  function filterAdditionalInterest(inputData) {
    if (!Array.isArray(inputData)) {
      return [];
    }
    const filteredData = inputData.filter(
      (item) => item.s_PersonType === "ADDITIONALINTEREST"
    );
    if (filteredData.length === 0) {
      return [];
    }
    return filteredData;
  }

  const vendorsCheck = vendors || false;

  const isPriorAddrress = (quoteData) => {
    const { personPriorAddr } = quoteData || {};
    const {
      s_StateName,
      s_AddressLine1,
      s_PostalCode,
      s_CityName,
      n_PersonAddressesId_PK,
    } = personPriorAddr;
    function isNotNullOREmpty(value) {
      return value !== null && value !== undefined && value !== "";
    }
    return (
      isNotNullOREmpty(s_CityName) &&
      isNotNullOREmpty(s_StateName) &&
      isNotNullOREmpty(s_AddressLine1) &&
      isNotNullOREmpty(s_PostalCode) &&
      isNotNullOREmpty(n_PersonAddressesId_PK)
    );
  };

  function mapAdditionalInterestData(data) {
    if (!Array.isArray(data) || (Array.isArray(data) && data.length < 1)) {
      return {
        has_additional_intrest: false,
        address: "",
        city: 0,
        cityId: 0,
        countryId: 1,
        county: "",
        countyId: 0,
        firstName: "",
        lastName: "",
        stateCode: "",
        stateId: 0,
        type: "",
        zipCode: "",
      };
    }
    let result = {};

    data.forEach((item) => {
      let formattedCity = item?.address?.s_CityName ?? "";
      if (/^[A-Z]+$/.test(formattedCity)) {
        formattedCity =
          formattedCity.charAt(0).toUpperCase() +
          formattedCity.slice(1).toLowerCase();
      }
      item.address.s_CityName = formattedCity;

      result = {
        ...result,
        has_additional_intrest: true,
        type: item.s_AddionalIntType,
        firstName: item?.person?.s_FirstName ?? "",
        lastName: item?.person?.s_LastOrganizationName ?? "",
        address: item?.address?.s_AddressLine1 ?? "",
        zipCode: item?.address?.s_PostalCode ?? "",
        stateCode: item?.address?.s_StateName ?? "",
        city: formattedCity ?? "",
        county: item?.address?.s_CountyName ?? "",
        cityId: item?.address?.n_CityId_FK ?? 0,
        stateId: item?.address?.n_StateId_FK ?? 0,
        countryId: item?.address?.n_CountryId_FK ?? 1,
        countyId: item?.address?.n_CountyId_FK ?? "",
      };
    });

    return result;
  }

  //Whole Home Water Detection & Shut Off = homeWaterDetection
  //Ting or Plug Device = tingOrPlugDevices
  //fire -> Monitored Fire Alarm = fireMonitoAlarm
  //fire -> Local Fire Alarm = fireLocalAlarm
  //fire -> Smart Home System = fireOtherProtectionDevice

  const identifyHomeProtection = ({
    tingOrPlugDevices,
    fireLocalAlarm,
    fireMonitoAlarm,
    fireOtherProtectionDevice,
  }) => {
    if (
      !tingOrPlugDevices &&
      !fireLocalAlarm &&
      !fireMonitoAlarm &&
      !fireOtherProtectionDevice
    ) {
      return "None";
    }
    if (tingOrPlugDevices) {
      if (fireLocalAlarm) {
        return "Local Fire + Smart Home Passive";
      }

      if (fireMonitoAlarm) {
        return "Monitored Fire + Smart Home Passive";
      }

      if (fireOtherProtectionDevice) {
        return "Smart Home Active + Smart Home Passive";
      }

      return "Smart Home Passive System";
    }
    if (fireLocalAlarm) {
      return "Local Fire Alarm";
    }

    if (fireMonitoAlarm) {
      return "Monitored Fire Alarm";
    }

    if (fireOtherProtectionDevice) {
      return "Smart Home Active System";
    }
  };

  const identifyTheftProtection = ({
    theftLocalMonitorAlarm,
    theftDeadboltLocks,
    theftLocalBurglarAlarm,
  }) => {
    if (
      !theftLocalMonitorAlarm &&
      !theftDeadboltLocks &&
      !theftLocalBurglarAlarm
    ) {
      return "None";
    }
    if (theftLocalMonitorAlarm) {
      return "Monitored Burglar Alarm";
    }
    if (theftDeadboltLocks) {
      return "Deadbolt Locks";
    }
    if (theftLocalBurglarAlarm) {
      return "Local Burglar Alarm";
    }
  };

  const secondaryInsured =
    Array.isArray(quoteData?.additionaInsuredData) &&
    quoteData?.additionaInsuredData.length > 0 &&
    extractAdditionalInsured(quoteData?.additionaInsuredData).length > 0
      ? extractAdditionalInsured(quoteData?.additionaInsuredData)[0]
      : [];

  const getAddressData = (data) => {
    const addressSource = isPriorAddrress(data)
      ? data?.personPriorAddr
      : data?.personAddr;

    return {
      street_address:
        addressSource?.s_AddressLine1 || addressSource?.s_StreetName || "",
      city: addressSource?.s_CityName || "",
      zipcode: addressSource?.s_PostalCode || "",
      state: addressSource?.s_StateName || "",
      county: addressSource?.s_CountyName || "",
    };
  };

  return {
    quote_request_details: {
      primary_insured: {
        first_name: quoteData?.personInfo?.s_FirstName,
        last_name: quoteData?.personInfo?.s_LastOrganizationName,
        email: quoteData?.personEmailContact?.s_CommValue,
        phone_number: quoteData?.personPhoneContact?.s_CommValue,
        customer_type:
          typeof quoteData?.personInfo?.s_EntityType === "string"
            ? quoteData?.personInfo?.s_EntityType.toLowerCase()
            : "individual",
        date_of_birth: quoteData?.personInfo?.d_BirthDate,
        current_address: getAddressData(quoteData),
        prior_policy_cancel_or_non_renew:
          setPoolManagerValues?.coverageDeclined || false,
        same_as_property_address:
          quoteData?.personInfo?.s_IsMailingDiff === "YES" ? false : true,
        mailing_address: {
          street_address:
            `${quoteData?.personMailingAddr?.s_StreetName}` ||
            quoteData?.personMailingAddr?.s_AddressLine1 ||
            quoteData?.personMailingAddr?.s_StreetName,
          city: quoteData?.personMailingAddr?.s_CityName,
          zipcode: quoteData?.personMailingAddr?.s_PostalCode,
          state: quoteData?.personMailingAddr?.s_StateName,
          cityId: quoteData?.personMailingAddr?.n_CityId_FK || 0,
          stateId: quoteData?.personMailingAddr?.n_StateId_FK || 0,
          countryId: quoteData?.personMailingAddr?.n_CountryId_FK || 1,
          countyId: quoteData?.personMailingAddr?.n_CountyId_FK || 0,
          county: quoteData?.personMailingAddr?.s_CountyName || "",
        },
        new_purchase: {
          is_this_new_purchase: isPriorAddrress(quoteData) || false,
          new_purchase_address: {
            street_address:
              `${quoteData?.personPriorAddr?.s_HouseNo} ${quoteData?.personPriorAddr?.s_AddressLine1}` ||
              quoteData?.personPriorAddr?.s_AddressLine1 ||
              quoteData?.personPriorAddr?.s_StreetName ||
              "",
            city: quoteData?.personPriorAddr?.s_CityName || "",
            zipcode: quoteData?.personPriorAddr?.s_PostalCode || "",
            state: quoteData?.personPriorAddr?.s_StateName || "",
            county: quoteData?.personPriorAddr?.s_StateName?.s_CountyName || "",
            cityId: quoteData?.personPriorAddr?.s_StateName?.n_CityId_FK || 0,
            stateId: quoteData?.personPriorAddr?.s_StateName?.n_StateId_FK || 0,
            countryId:
              quoteData?.personPriorAddr?.s_StateName?.n_CountryId_FK || 1,
            countyId:
              quoteData?.personPriorAddr?.s_StateName?.n_CountyId_FK || 0,
          },
        },
        actual_credit_score: 750,
        //  extractFirstNumberForCreditScore(
        //   quoteData?.personInfo?.metadata?.creditScore
        // ),
        credit_score: 750,
        // calculateAverageCreditScore(
        //   Number(quoteData?.personInfo?.metadata?.creditScore),
        //   Number(secondaryInsured?.person?.creditScore || 0)
        // ),
      },
      secondary_insured: {
        first_name: secondaryInsured?.person?.firstName || "",
        last_name: secondaryInsured?.person?.lastName || "",
        phone_number: secondaryInsured?.person?.phoneNumber || "" || "",
        date_of_birth: secondaryInsured?.person?.dob || "",
        current_address: {
          street_address: secondaryInsured?.address?.addressLine1 || "",
          city: secondaryInsured?.address?.city || "",
          zipcode: secondaryInsured?.address?.zip || "",
          state:
            getStateCode(secondaryInsured?.address?.stateCode) ||
            secondaryInsured?.address?.stateCode ||
            "",
        },
      },
      // additional_intrest:
      //   mapAdditionalInterestData(
      //     filterAdditionalInterest(additionaInsuredData)
      //   ) || {},
      additional_insured:
        Array.isArray(
          extractAdditionalInsured(quoteData?.additionaInsuredData)
        ) &&
        extractAdditionalInsured(quoteData?.additionaInsuredData).length > 0
          ? createAdditionalInsured(
              extractAdditionalInsured(quoteData?.additionaInsuredData).slice(1)
            )
          : [] || [],

      allowed_vendors: {
        credit_score: TEMP_VEBDORS_CALL || vendorsCheck,
        geocoding: true,
        replacement_cost: TEMP_VEBDORS_CALL || vendorsCheck,
        property_characteristics: TEMP_VEBDORS_CALL || vendorsCheck,
        cat_losses: TEMP_VEBDORS_CALL || vendorsCheck,
      },
      property_details: {
        address: {
          street_address:
            quoteData?.personAddr?.s_AddressLine1 ||
            quoteData?.personAddr?.s_StreetName ||
            "",
          lat: quoteData?.personAddr?.s_Latitude
            ? Number(quoteData?.personAddr?.s_Latitude)
            : 0,
          long: quoteData?.personAddr?.s_Longitude
            ? Number(quoteData?.personAddr?.s_Longitude)
            : 0,
          city: quoteData?.personAddr?.s_CityName || "",
          zipcode: quoteData?.personAddr?.s_PostalCode || "",
          state: quoteData?.personAddr?.s_StateName || "",
          cityId: quoteData?.personAddr?.n_CityId_FK ?? 0,
          stateId: quoteData?.personAddr?.n_StateId_FK ?? 0,
          countryId: quoteData?.personAddr?.n_CountryId_FK ?? 1,
          countyId: quoteData?.personAddr?.n_CountyId_FK ?? 0,
          county: quoteData?.personAddr?.s_CountyName || "",
        },

        // underwriting_questions: { ...setPoolManagerValues, acres: null },

        occupancy_properties: {
          occupancy: quoteData?.propertyInfo?.s_UsageTypeCode,
          vacant_indicator: Boolean(setPoolManagerValues?.inForeClosure),
          //  quoteData?.propertyInfo?.metadata?.isBuildingVacant,
          under_construction: Boolean(setPoolManagerValues?.inForeClosure),
          // quoteData?.propertyInfo?.metadata?.isUnderConstruction,
          // number_families: quoteData?.propertyInfo?.n_NoOfFamilies
          //   ? Number(quoteData?.propertyInfo?.n_NoOfFamilies)
          //   : null,
          residence_use: quoteData?.propertyInfo?.s_Occupancy,
          // number_buildings: quoteData?.propertyInfo?.n_NoOfStories
          //   ? Number(quoteData?.propertyInfo?.n_NoOfStories)
          //   : null,
        },
        construction_properties: {
          ppc: quoteData?.propertyInfo?.n_PPC
            ? quoteData?.propertyInfo?.n_PPC
            : "1",
          construction_type: quoteData?.propertyInfo?.s_ConstructionType,
          foundation_type: quoteData?.propertyInfo?.s_StructureTypeCode,
          cladding_type: quoteData?.propertyInfo?.metadata?.claddingType,
          dwelling_stories: quoteData?.propertyInfo?.n_NoOfStories
            ? Number(quoteData?.propertyInfo?.n_NoOfStories)
            : null,
          year_built: quoteData?.propertyInfo?.n_YearBuilt
            ? Number(quoteData?.propertyInfo?.n_YearBuilt)
            : null,
          effective_year_built: quoteData?.propertyInfo?.n_YearBuilt
            ? Number(quoteData?.propertyInfo?.n_YearBuilt)
            : null,
          living_area_square_footage: quoteData?.propertyInfo?.n_AreaSqtft
            ? Number(quoteData?.propertyInfo?.n_AreaSqtft)
            : null,
        },
        roof_properties: {
          roof_age: quoteData?.propertyInfo?.s_RoofAge
            ? Number(quoteData?.propertyInfo?.s_RoofAge)
            : null,
          roof_updated:
            (quoteData?.propertyInfo?.metadata?.isRoofUpdated && "true") ||
            quoteData?.propertyInfo?.metadata?.isRoofUpdated,
          year_roof_updated: quoteData?.propertyInfo?.metadata?.roofUpdatedYear
            ? Number(quoteData?.propertyInfo?.metadata?.roofUpdatedYear)
            : null,
          roof_partial_complete:
            quoteData?.propertyInfo?.metadata?.partialOrCompleteRoofUpdate,
          roof_material: quoteData?.propertyInfo?.s_RoofType,
          roof_shape: quoteData?.propertyInfo?.s_RoofShapeCode,
          roof_modifiers: quoteData?.propertyInfo?.metadata?.isRoofModifierUsed,
          roof_anchor: quoteData?.propertyInfo?.s_RoofWallConnectCode,
          roof_deck_attachment: quoteData?.propertyInfo?.s_RoofDeckAttachCode,
          secondary_water_resistance:
            quoteData?.propertyInfo?.s_SecWaterResCode,
        },
        protection_properties: {
          fire_smoke_alarm_type:
            fireOtherProtectionDevice || fireMonitoAlarm
              ? "central station"
              : fireLocalAlarm
              ? "local alarm"
              : "none",
          burglar_alarm_type:
            theftDeadboltLocks || theftLocalBurglarAlarm
              ? "local alarm"
              : theftLocalMonitorAlarm
              ? "central"
              : "none",
          automatic_water_shutoff_type:
            fireOtherProtectionDevice || homeWaterDetection
              ? "yes - leak defense alert"
              : theftLocalMonitorAlarm
              ? "yes, leak defense"
              : "none",
          sprinkler_system_type: sprinklerSystemType || "",
          smart_home: false, // hasSmartHomeProtection,
          hasSmartHomeProtection:
            quoteData?.propertyInfo?.metadata?.hasSmartHomeProtection,
          homeWaterDetection:
            quoteData?.propertyInfo?.metadata?.homeWaterDetection,
          tingOrPlugDevices:
            quoteData?.propertyInfo?.metadata?.tingOrPlugDevices,
          hasTheftProtection:
            quoteData?.propertyInfo?.metadata?.hasTheftProtection,
          theftLocalBurglarAlarm:
            quoteData?.propertyInfo?.metadata?.theftLocalBurglarAlarm,
          theftLocalMonitorAlarm:
            quoteData?.propertyInfo?.metadata?.theftLocalMonitorAlarm,
          theftDeadboltLocks:
            quoteData?.propertyInfo?.metadata?.theftDeadboltLocks,
          hasFireProtectionDevice:
            quoteData?.propertyInfo?.metadata?.hasFireProtectionDevice,
          fireMonitoAlarm: quoteData?.propertyInfo?.metadata?.fireMonitoAlarm,
          fireOtherProtectionDevice:
            quoteData?.propertyInfo?.metadata?.fireOtherProtectionDevice,
          fireLocalAlarm: quoteData?.propertyInfo?.metadata?.fireLocalAlarm,
          doesQualifyForMSwindDisc:
            quoteData?.propertyInfo?.metadata?.doesQualifyForMSwindDisc,
          hasHomeInsuranceInForce:
            quoteData?.propertyInfo?.metadata?.hasHomeInsuranceInForce,
          isApplicantLocatedNorhI10:
            quoteData?.propertyInfo?.metadata?.isApplicantLocatedNorhI10,
          hurricane_protection_type:
            determineHurricaneProtectionType(quoteData) || "unknown",
          fsl: quoteData?.propertyInfo?.metadata?.fsl,
          IRC: quoteData?.propertyInfo?.metadata?.IRC,
          fehBronze: quoteData?.propertyInfo?.metadata?.fehBronze,
          fehSilver: quoteData?.propertyInfo?.metadata?.fehSilver,
          fehGold: quoteData?.propertyInfo?.metadata?.fehGold,
        },

        exposure_properties: {
          farming_activity: setPoolManagerValues?.anyOtherBusiness || false,
          business_flag: setPoolManagerValues?.anyOtherBusiness || false,
          home_daycare_flag: setPoolManagerValues?.anyOtherBusiness || false,
          trampoline_flag:
            setPoolManagerValues?.hasTrampolineAndPoolSecurity || false,
          trampoline_protection:
            setPoolManagerValues?.hasTrampolineAndPoolSecurity || false,
          animals_on_premise:
            setPoolManagerValues?.hasAnimals ||
            setPoolManagerValues?.animals_on_premise ||
            false,
          has_pool: setPoolManagerValues?.hasTrampolineAndPoolSecurity || false,
          pool_enclosure:
            setPoolManagerValues?.hasTrampolineAndPoolSecurity || false,
          // (setPoolManagerValues?.hasSwimmingPool &&
          //   setPoolManagerValues?.poolSecurity) ||
          // false,
          has_solar_panels:
            setPoolManagerValues?.hasSolarPanels === true ? true : false,
          solar_panel_location:
            setPoolManagerValues?.hasSolarPanels === true
              ? addtionalStructure || "On the house"
              : null,

          property_acres: setPoolManagerValues?.propertyAcres || null,
          property_acres_exposure_flag:
            setPoolManagerValues?.propertyExposures || false,
          other_risks_associated:
            Array.isArray(additionalRisk) && additionalRisk.length > 0
              ? additionalRisk
              : ["None"],
        },
      },
      claim_details: createClaimDetail(quoteData?.lossHistoryData) || [],
      policy_details: {
        effective_date_timezone: "US/Central",
        general_effective_date: quoteData?.effectiveDate,
        general_policy_term: 12,
        product: "HO-3",
        line: "admitted",
        limits: {
          coverage_a:
            parseProperNumber(quoteData?.propertyCoverageInfo?.n_coverageA) ||
            quoteData?.propertyCoverageInfo?.n_coverageA,
          coverage_b:
            parseProperNumber(quoteData?.propertyCoverageInfo?.n_coverageB) ||
            quoteData?.propertyCoverageInfo?.n_coverageB,
          coverage_c:
            parseProperNumber(quoteData?.propertyCoverageInfo?.n_coverageC) ||
            quoteData?.propertyCoverageInfo?.n_coverageC,
          coverage_d:
            parseFloat(
              parseInt(quoteData?.propertyCoverageInfo?.n_coverageD).toFixed(2)
            ) || 0,
          coverage_e:
            parseProperNumber(quoteData?.propertyCoverageInfo?.n_coverageE) ||
            quoteData?.propertyCoverageInfo?.n_coverageE,
          coverage_f:
            parseProperNumber(quoteData?.propertyCoverageInfo?.n_CoverageF) ||
            quoteData?.propertyCoverageInfo?.n_CoverageF,
        },
        prior_policy: setPoolManagerValues?.hasHomeInsuranceInForce
          ? {
              priorPolicyExpiryDate:
                quoteData?.policyInfo?.d_PriorExpDate || "",
              priorPolicyNo: quoteData?.policyInfo?.s_PriorPolicyNo || "",
              priorCompanyNaic: quoteData?.policyInfo?.s_PriorCarrierName || "",
            }
          : {
              priorPolicyExpiryDate: "",
              priorPolicyNo: "",
              priorCompanyNaic: "",
            },
        deductibles: {
          aop:
            nonMSNonHurricaneDeductibles[
              quoteData?.propertyCoverageInfo?.s_AOPDedutible
            ] ?? 500,
          scs: quoteData?.propertyCoverageInfo?.metadata?.scsDeductible,
          roof:
            quoteData?.propertyCoverageInfo?.metadata?.roofDeductible === 0
              ? ""
              : quoteData?.propertyCoverageInfo?.metadata?.roofDeductible,
          hurricane:
            nonMSHurricaneDeductibles[
              quoteData?.propertyCoverageInfo?.s_HurricaneDedutible
            ] ?? 2,
        },
      },
      // mortgageeData:
      //   Array.isArray(quoteData?.mortgageeData) &&
      //   quoteData?.mortgageeData?.length > 0
      //     ? extractMortgagees(quoteData?.mortgageeData)
      //     : [] || [],
      // additionalInsuredData:
      //   Array.isArray(quoteData?.additionaInsuredData) &&
      //   quoteData?.additionaInsuredData?.length > 0
      //     ? extractAdditionalInsured(quoteData?.additionaInsuredData)
      //     : [] || [],
      ms_ho3_admitted: {
        roof_surface_type:
          quoteData?.propertyInfo?.s_RoofType === "3-tab Shingles"
            ? "3-Tab Shingles"
            : quoteData?.propertyInfo?.s_RoofType || "",
        years_insured: 1,
        aop_deductible: quoteData?.propertyCoverageInfo?.s_AOPDedutible,
        wind_hail_deductible:
          quoteData?.propertyCoverageInfo?.s_HurricaneDedutible,
        wind_type: determineWindType(quoteData?.propertyInfo?.metadata),
        home_protection_theft_type: identifyTheftProtection({
          theftLocalMonitorAlarm,
          theftDeadboltLocks,
          theftLocalBurglarAlarm,
        }),
        home_protection_fire_type: identifyHomeProtection({
          tingOrPlugDevices:
            quoteData?.propertyInfo?.metadata?.tingOrPlugDevices,
          fireLocalAlarm,
          fireMonitoAlarm,
          fireOtherProtectionDevice,
        }),
        watercraft_liability: 0,
        medical_liability: 0,
        cyber_standard: false,
        service_line: false,
        north_of_I10: isApplicantLocatedNorhI10,
        home_systems_protection: false, // hasSmartHomeProtection,
        personal_jewelry_valuation: 0,
        personal_furs_valuation: 0,
        personal_music_instruments_valuation: 0,
        personal_silverware_valuation: 0,
        personal_golfer_equipment_valuation: 0,
        personal_non_commercial_valuation: 0,
        personal_stamps_baseball_card_valuation: 0,
        personal_coins_valuation: 0,
        personal_fine_arts_valuation: 0,
        outboard_horse_power: 0,
        inboard_mph: 0,
        inboard_watercraft_liability: 0,
        credit_score: 750,
        // calculateAverageCreditScore(
        //   Number(quoteData?.personInfo?.metadata?.creditScore),
        //   Number(secondaryInsured?.person?.creditScore || 0)
        // ),
        prior_carrier: isNotEmpty(quoteData?.policyInfo?.s_PriorCarrierName),
        water_leak_protection: Boolean(
          quoteData?.propertyInfo?.metadata?.homeWaterDetection
        ),
        // inside_city_limits: false,
      },
    },
    submission_details: {
      id: quoteData?.propertyInfo?.metadata?.fpSubmissionId,
    },
  };
};

function determineHurricaneProtectionType(quoteData) {
  const aopDeductible = quoteData?.propertyCoverageInfo?.s_AOPDedutible;
  const hurricaneDeductible =
    quoteData?.propertyCoverageInfo?.s_HurricaneDedutible;

  const windTypeValue = quoteData?.propertyInfo?.metadata?.windType || "None";

  const aopDeductibleValue = nonMSNonHurricaneDeductibles[aopDeductible] ?? 500;
  const hurricaneDeductiblePercentage =
    nonMSHurricaneDeductibles[hurricaneDeductible] ?? 2;

  function getDeductibleProtectionLevel() {
    if (hurricaneDeductiblePercentage <= 2 && aopDeductibleValue <= 1000) {
      return "all";
    } else if (
      hurricaneDeductiblePercentage <= 5 &&
      aopDeductibleValue <= 2500
    ) {
      return "some(greater than 50 %)";
    } else if (
      hurricaneDeductiblePercentage <= 10 &&
      aopDeductibleValue <= 5000
    ) {
      return "other";
    } else {
      return "unknown";
    }
  }
  function getWindTypeProtectionLevel() {
    if (["FEH Gold", "FEH Silver", "FSL"].includes(windTypeValue)) {
      return "all";
    } else if (["FEH Bronze", "2006 IRC"].includes(windTypeValue)) {
      return "some(greater than 50 %)";
    } else if (windTypeValue === "None") {
      return "unknown";
    } else {
      return "unknown";
    }
  }
  const deductibleProtection = getDeductibleProtectionLevel();
  const windTypeProtection = getWindTypeProtectionLevel();

  const protectionLevels = [
    "all",
    "some(greater than 50 %)",
    "other",
    "unknown",
  ];
  return protectionLevels[
    Math.min(
      protectionLevels.indexOf(deductibleProtection),
      protectionLevels.indexOf(windTypeProtection)
    )
  ];
}

//   // Function to determine protection level based on wind type
//   function getWindTypeProtectionLevel() {
//     if (["FEH Gold", "FEH Silver", "FSL"].includes(windTypeValue)) {
//       return "all";
//     } else if (["FEH Bronze", "2006 IRC"].includes(windTypeValue)) {
//       return "some(greater than 50 %)";
//     } else if (windTypeValue === "None") {
//       return "unknown";
//     } else {
//       return "unknown";
//     }
//   }

//   // X the two assessments
//   const deductibleProtection = getDeductibleProtectionLevel();
//   const windTypeProtection = getWindTypeProtectionLevel();

//   // Choose the best protection level
//   const protectionLevels = [
//     "all",
//     "some(greater than 50 %)",
//     "other",
//     "unknown",
//   ];
//   return protectionLevels[
//     Math.min(
//       protectionLevels.indexOf(deductibleProtection),
//       protectionLevels.indexOf(windTypeProtection)
//     )
//   ];
// }
