import React, { Fragment } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DateInput from "../../../create-star-quote/utils/DateInput";
import { formateDate } from "../../../../common/FormatDate";
import Moment from "moment";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";

const PriorCoverage = ({ propsData, quoteData, isBind }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = propsData.handleChange;
  const priorCarrierValue = quoteData.policyInfo.s_PriorCarrierName || "";
  const priorPolicyNoValue = quoteData.policyInfo.s_PriorPolicyNo || "";
  const priorExpDateValue =
    formateDate(quoteData.policyInfo.d_PriorExpDate) || "";

  return (
    <Fragment>
      <Row className="align-items-end">
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Prior Carrier")}
            </Form.Label>
            <Form.Control
              name="s_PriorCarrierName"
              title="policyInfo"
              className="starQuotePageInput"
              type="text"
              placeholder=""
              onChange={handleChange}
              value={priorCarrierValue}
              disabled={isBind}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Prior Policy Number")}
            </Form.Label>
            <Form.Control
              name="s_PriorPolicyNo"
              title="policyInfo"
              className="starQuotePageInput"
              type="text"
              onChange={handleChange}
              value={priorPolicyNoValue}
              disabled={isBind}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Policy Expiration Date")}
            </Form.Label>
            <DateInput
              styleProps={{
                border: "2px solid",
                borderColor: "#9AA5B5",
                borderRadius: "12px",
                size: "lg",
                readOnly: false,
                disabled: isBind,
                backgroundColor: isBind ? "#e9ecef" : "",
              }}
              value={priorExpDateValue}
              name="d_PriorExpDate"
              title="policyInfo"
              isDisabled={isBind}
              onChange={(value) => {
                let quoteDataVar = {
                  ...quoteData,
                  policyInfo: {
                    ...quoteData.policyInfo,
                    d_PriorExpDate: Moment(value).format("YYYY-MM-DD"),
                  },
                };
                dispatch(getEditEmptyQuoteData(quoteDataVar));
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
  getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
});

export default connect(mapStateToProps)(PriorCoverage);
