import Http from "../../../../Http";

export async function getPropertyData({ policyNoID, termMasterPK }) {
  try {
    const url = `/api/corePolicies/getPropertyData/${policyNoID}/${termMasterPK}`;
    const response = await Http.get(url);
    return response.data;
  } catch (err) {
    console.error("Error fetching property data:", err);
    throw err; // Re-throw the error to be caught by the caller
  }
}
