import React, { useState, useEffect } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall } from "../../../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import Validation from "../../Validation";
import alertImg from "../../../../../../assets/flood/question-mark.png";
import "./discount.scss";
import "react-overlay-loader/styles.css";

const DiscountFormThree = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emptyQuoteData, initApiObject, apiLoading } = useSelector(state => state.flood);
  const [showOptions, setShowOptions] = useState(false);
  const [isProperOpening, setIsProperOpening] = useState('');
  const [noOfOpening, setNoOfOpening] = useState('');
  const [totalInchOfOpenArea, setTotalInchOfOpenArea] = useState('');
  const [isEnggVentUsed, setIsEnggVentUsed] = useState('');
  const [isStateSet, setIsStateSet] = useState(false);

  // set Initial State from reducer and get NAIC
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setIsProperOpening(emptyQuoteData.propertyInfo.s_IsProperOpening);
      setNoOfOpening(emptyQuoteData.propertyInfo.n_NoOfOpening);
      setTotalInchOfOpenArea(emptyQuoteData.propertyInfo.n_TotalinchOfOpenArea);
      setIsEnggVentUsed(emptyQuoteData.propertyInfo.s_IsEnggVentUsed);
      setIsStateSet(true);
    }
  }, []);

  useEffect(() => {
    if (isProperOpening === "NO" && isStateSet === true) {
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.propertyInfo.n_NoOfOpening = '';
      emptyQuote.propertyInfo.n_TotalinchOfOpenArea = '';
      emptyQuote.propertyInfo.s_IsEnggVentUsed = '';

      dispatch(getEditEmptyQuoteData(emptyQuote));

      const reqJson = { ...initApiObject }; // Copy
      reqJson.hasProperFloodOpenings = '';
      reqJson.numberOfFloodOpenings = '';
      reqJson.totalAreaFloodOpenings = '';
      reqJson.whichSection = 'DISCOUNT';

      // To update
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [isProperOpening]);

  const callPivotApi = () => {
    if (isProperOpening === 'YES' && noOfOpening !== '') {
      const reqJson = { ...initApiObject }; // Copy
      reqJson.hasProperFloodOpenings = isProperOpening;
      reqJson.numberOfFloodOpenings = noOfOpening;
      reqJson.totalAreaFloodOpenings = totalInchOfOpenArea;
      reqJson.whichSection = 'DISCOUNT';

      // To update
      dispatch(updateInitQuoteApi(reqJson));

      const code = 'QUOTE_NEWBUSINESS';
      const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
      // Call Api
      dispatch(pivotApiCall(code, request));
    }
  }

  // handle switch Change
  const handleSwitchChange = (e) => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    setIsProperOpening(temp);
    props.handleSwitchChange(e);
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-discount-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-discount-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="11">
                <div className="flood-discount-header">
                  <div className="flood-discount-title">
                    <h4 className="flood-discount-main-title">{t('Discounts')}</h4>
                    <p className="flood-discount-main-desc">
                      {t('Watch your premium drop as you add your discounts')}
                    </p>
                  </div>
                  <div className="flood-discount-pricing">
                    <h5 className="flood-discount-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-discount-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row>
                  {isProperOpening !== "YES" && (
                    <Col xs="12">
                      <div className="discount-house-check3-icon"></div>
                    </Col>
                  )}
                  <Col xs="12">
                    <Alert variant="primary" className="discount-form-alert">
                      <img src={alertImg} />
                      <p className="discount-form-alert-text">
                        {" "}
                        {t('Valid Flood Openings consist of a minimum of 2 openings, with positioning on at least 2 walls. In the case of a walkout basement the openings may be positioned on a single wall adjacent to the lowest grade next to the building. The bottom of the openings must be within 1 foot of the adjacent grade')}.
                      </p>
                    </Alert>
                  </Col>
                  <Col xs="12">
                    <div className="flood-discount-row">
                      <div className="flood-discount-question-div">
                        <p>{t('Does the property have proper openings')}?</p>
                        <div className="flood-discount-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-discount-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_IsProperOpening"
                              checked={isProperOpening === 'YES' ? true : false}
                              onChange={(e) => { setShowOptions(!showOptions); handleSwitchChange(e); }}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {(isProperOpening === "YES") && (
                  <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="myform-label"
                          id="discount-three-label"
                        >
                          {t('Number of Openings')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="number"
                          placeholder={`${t("e.g")}. 2`}
                          name="n_NoOfOpening"
                          title={t('Number of Openings')}
                          parentobject="propertyInfo"
                          value={noOfOpening || ''}
                          min={0}
                          onChange={(e) => {
                            props.handleChange(e);
                            setNoOfOpening(e.currentTarget.value);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="myform-label"
                          id="discount-three-label"
                        >
                          {t('Total square Inches of Open Area')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="number"
                          placeholder={`${"e.g"}. 200 ${t('inches')}`}
                          name="n_TotalinchOfOpenArea"
                          title={t('Total square Inches of Open Area')}
                          parentobject="propertyInfo"
                          value={totalInchOfOpenArea || ''}
                          min={0}
                          onChange={(e) => {
                            props.handleChange(e);
                            setTotalInchOfOpenArea(e.currentTarget.value);
                          }}
                          onBlur={callPivotApi}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="myform-label"
                          id="discount-three-label"
                        >
                          {t('Were Engineered Vents Used')}?
                        </Form.Label>
                        <Form.Select
                          className="myform-input"
                          id="discount-form-policy-input"
                          name="s_IsEnggVentUsed"
                          title={t('Were Engineered Vents Used')}
                          parentobject="propertyInfo"
                          value={isEnggVentUsed || ''}
                          onChange={(e) => {
                            props.handleChange(e);
                            setIsEnggVentUsed(e.currentTarget.value);
                          }}
                        >
                          <option value=''>{t('Select')}</option>
                          <option value="YES">{t('Yes')}</option>
                          <option value="NO">{t('No')}</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={10} saveQuoteData={props.saveQuoteData} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </LoadingOverlay >

  );
};

export default DiscountFormThree;
