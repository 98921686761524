import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { getAutoLocationData } from "../create-star-quote/apis/postGetAutoPropertyData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// @TheEinshine
// Do not modify this hook without verifying it's repsctive usage.
// Easted Data api call, US Only.

const useAutoEastedHook = ({ isEnabled = false }) => {
  const { t } = useTranslation();
  const propertyData = useSelector(
    (state) => state.flood.propertyDataAutocompleteStored
  );
  const [location, setLocation] = useState({});
  const [autoGenData, setAutoGenData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getValidStateCode = useCallback((state, stateCode) => {
    const isValidStateCode = (value) =>
      typeof value === "string" && /^[A-Z]{2}$/.test(value);
    return isValidStateCode(state)
      ? state
      : isValidStateCode(stateCode)
      ? stateCode
      : null;
  }, []);

  useEffect(() => {
    if (!propertyData) return;

    const {
      street_number = "",
      street_name = "",
      city = "",
      CountyName = "",
      PostalCode = "",
      country = "",
      latitude = "",
      longitude = "",
      value = "",
      autocomplete = "",
      state = "",
      s_PostalCodeSuffix = "",
      apt_Number = "",
      stateCode = "",
    } = propertyData;

    setLocation({
      streetNumber: street_number,
      streetName: street_name,
      city,
      countyName: CountyName,
      postalCode: PostalCode,
      country,
      latitude,
      longitude,
      value,
      fullAddress: autocomplete,
      state,
      postalCodeSuffix: s_PostalCodeSuffix,
      aptNumber: apt_Number,
      stateCode,
      validStateCode: getValidStateCode(state, stateCode) || state || stateCode,
    });
  }, [propertyData, getValidStateCode]);

  const autoFloodMapGen = useCallback(async () => {
    if (!location || (Object.keys(location).length === 0 && !isEnabled)) return;
    setIsLoading(true);
    try {
      const { data } = await getAutoLocationData({
        requestBody: {
          street_number: location.streetNumber,
          street_name: location.streetName,
          city: location.city,
          CountyName: location.countyName,
          PostalCode: location.postalCode,
          country: location.country,
          latitude: location.latitude,
          longitude: location.longitude,
          value: location.value,
          autocomplete: location.fullAddress,
          state: location.validStateCode,
          s_PostalCodeSuffix: location.postalCodeSuffix,
        },
      });
      setAutoGenData(data || {});
    } catch (error) {
      console.error(false, error, "in useAutoEastedHook");
      toast.info(t("Couldn't retrieve easted data for given address"), {
        toastId: "warn",
      });
    } finally {
      setIsLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (
      location.city &&
      location.validStateCode &&
      location.streetName &&
      isEnabled
    ) {
      autoFloodMapGen();
    }
  }, [location, autoFloodMapGen]);

  return { locationData: location, autoGenData, autoGenLoading: isLoading };

  //Usage : const { locationData, autoGenData, autoGenLoading } = useAutoEastedHook();
};

export default useAutoEastedHook;
