import React, { useState, useEffect } from "react";

import "./login.scss";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { authCheck, uwlogin, getSiteData } from "../../services/authService";
import { toast } from 'react-toastify';
import { useParams ,useNavigate } from "react-router-dom";
import { EncryptOrDecrypt,setSiteDataInLocalStorage } from "../../services/commonService";
import Moment from "moment";
import { getCorePolicyData } from "../../services/coreSystemService";
import { resetCoreStore, resetFloodStore } from "../../store/actions";

const HoiLogin = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    let params = useParams();

    useEffect(() => {
        localStorage.clear();
        callGetSiteData();
    }, []);

    const callGetSiteData = async () => {
        setLoading(true);
        await dispatch(getSiteData()).then((res) => {
            setSiteDataInLocalStorage(res.data.data);
            setLoading(false);
            tokenLogin();
        });
    };

    const tokenLogin = async () => {

        setLoading(true);
        const request = {
            'userToken': params.userToken
        };
        await dispatch(uwlogin(request)).then(async (response) => {
            var EncryptUserData = '';
            if (response.success === true) {
                if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                    localStorage.setItem('access_token', response.data.access_token);
                    var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                    localStorage.setItem('access_token_expires_at', tokenExpireAt);
                    EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                    localStorage.setItem('user', EncryptUserData);
                    await dispatch(authCheck());

                    var transPK = params.transPK ? params.transPK : '';
                    if(transPK != ''){
                        navigation(`/flood/quote/${transPK}`);
                        window.location.reload(); 
                    }else{
                        // redirect to the policy search
                        window.location = '/quote';
                    }
                    
                } else {
                    toast.error(response.data.errors);
                    window.location = '/';
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t('Please Try again'));
        });

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
        </LoadingOverlay>
    );
};

export default HoiLogin;