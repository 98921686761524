import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Modal, Table } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import  {getZipDetails} from "../../../../services/instaQuote";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { connect } from 'react-redux';
import "./MailingAddress.scss"

const MilingAdress = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const [propertyAddress, setPropertyAddress] = useState(false);
    const [electronicMail, setElectronicMail] = useState(false);
    const [mail, setMail] = useState(true);
    const [email, setEmail] = useState(false);
    const [search, setSearchProperty] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [zipData, setZipData] = useState([]);
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [streetName, setStreetName] = useState('');
    const [googlePlace, setGooglePlace] = useState('');
    const [validated, setValidated] = React.useState(false);
    const [isValid, setisValid] = useState(true);

    const handleOnclick = (step) => {
          //validation
          if(step == '8'){
            if(props.emptyQuote.propertyInfo.n_PriorClaimCount == 0){
                navigation('/instaQuote/InstaSteps/7')
            } else {
                navigation('/instaQuote/InstaSteps/' + step)
            }
        } else {
            var validateStatus = false;
            if(props.isChecked == "NO"){
                if(zipCode == "" || googlePlace == "" ){
                    validateStatus = true;
                }
            }
            setValidated(validateStatus);
            if(validateStatus == false){
                navigation('/instaQuote/InstaSteps/' + step)
            }
        }


          

        //navigation('/instaQuote/InstaSteps/' + step)
    }

    const customStyles = {
        control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ?
            '#ddd' : isValid ?
            '#ddd' : 'red',
        }),
    };

    useEffect(() => {
        if (validated && googlePlace == '') {
          setisValid(false);
        } else {
          setisValid(true);
        }
      }, [validated]);

    useEffect(() => {
        if (search != null) {
        setStreetName(search.value.structured_formatting.main_text);
        getGeoCode(search.value.description);
        setGooglePlace(search);
        }
    }, [search])

    useEffect(() => {
        if (props.mailingAddr.s_AddressLine1 == null) {
        setGooglePlace('');
        setStreetName('');
        setCounty('');
        setState('');
        setCity('');
        setZipCode('');
        }
    }, [props.mailingAddr.s_AddressLine1]);

    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important;}</style>`)
    }, [])

    const getGeoCode = (description) => {
        geocodeByAddress(description)
        .then(addr => {
            addr && addr[0].address_components.forEach(e => {
                if (e.types.includes('street_number')) {
                    const values = [];
                    values.push({
                        s_HouseNo: e.short_name,
                    });
                    props.setMailingAddrressData(values);
                }else if(e.types.includes('route')){
                    const values = [];
                    values.push({
                        s_HouseNo: props.mailingAddr.s_HouseNo,
                        s_StreetName: e.short_name,
                    });
                    props.setMailingAddrressData(values);
                }else if(e.types.includes('postal_code')){
                    setZipCode(e.short_name);
                    setLoadingStatus(true);
                    dispatch(getZipDetails('13', e.short_name, 'APPLICANT')).then((res) => {
                        setZipData(res.data.zipData);
                        setCounty(res.data.zipData[0].s_CountyCode);
                        setState(res.data.zipData[0].s_StateCode);
                        setCity(res.data.zipData[0].s_CityName);
                        setLoadingStatus(false);

                        const values = [];
                        values.push({
                            s_CountyName: res.data.zipData[0].s_CountyCode,
                            s_CityName: res.data.zipData[0].s_CityName,
                            s_PostalCode: e.short_name,
                            n_CityId_FK: res.data.zipData[0].n_CityId_PK,
                            n_CountryId_FK: res.data.zipData[0].n_CountryId_FK,
                            n_CountyId_FK: res.data.zipData[0].n_CountyId_FK,
                            n_StateId_PK: res.data.zipData[0].n_StateId_PK,
                            s_StateName: res.data.zipData[0].s_StateCode,
                            s_AddressLine1: search.value.structured_formatting.main_text,
                            s_HouseNo: props.mailingAddr.s_HouseNo,
                            s_StreetName: props.mailingAddr.s_StreetName,
                        })
                        props.setMailingAddrressData(values);
                    });
                }
            });
        })
    }

    const changeZip = (e) => {
        setZipCode(e.target.value);
    }   

    const zipCodeApi = () => {
        if (zipCode != '' && zipData.length >= 1) {
            setLoadingStatus(true);
            dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
                setZipData(res.data.zipData);
                setLoadingStatus(false);
                setShowModal(true);
            })
        }
    }

    const handleSwitchChange = (e) => {
        setPropertyAddress(!propertyAddress);
        setMail(!mail);
        setCounty('');
        setState('');
        setCity('');
        setZipCode('');
        props.handleSwitchChange(e);
        setGooglePlace('');
        setStreetName('');
    }

    const handleInputChange = (val, status) => {
        if (status.action == "input-change") {
            setStreetName(val);
        }else if(["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)){
            setStreetName(streetName.toUpperCase());
        }
    }

    const mailed = () => {
        setElectronicMail(!electronicMail)
    }

    const setZipDetailsData = (data) => {
        setCounty(data.s_CountyCode);
        setState(data.s_StateCode);
        setCity(data.s_CityName);
        setShowModal(false);
    }

    return(
        <LoadingOverlay>
            <Loader loading={loadingStatus} />
            {
                <section className="mailing-address-section">
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="12" lg="8" xl="8" className="mailing-address-form-section">
                            <Form validated={validated} className="confirm-address-form">
                                <div className="mailing-address-headings">
                                    <h3>{t('Mailing Address')}</h3>
                                </div>
                                <Row className="justify-content-center">
                                    <Col xs="12" className="mailing-address-form-main">
                                        <div className="mailing-address-card-row">
                                            <div className="mailing-address-question-div">
                                                <p>
                                                    {t('Is the mailing address the same as the property address')}?
                                                </p>
                                                <div className="mailing-address-card-actions">
                                                    <span>{t('No')}</span>
                                                    <div className="custom-main-swtich">
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            className="mailing-address-card-switch"
                                                            label=""
                                                            name="isSameMailingAddress"
                                                            parentobject="mapData"
                                                            // onChange={() => setPropertyAddress(!propertyAddress)}
                                                            onChange={handleSwitchChange}
                                                            checkedvalue='YES'
                                                            uncheckedvalue='NO'
                                                            checked={props.isChecked === 'YES' ? true : false}
                                                        />
                                                    </div>
                                                    <span>{t('Yes')}</span>
                                                </div>
                                            </div>
                                            {props.isChecked === 'NO' && (
                                                <div className="mailing-address-card-input">
                                                    <Row>
                                                        <Col xs="8" sm="8" >
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Label className="myform-label">
                                                                    {t('Mailing Address')}
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <GooglePlacesAutocomplete 
                                                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                                selectProps={{
                                                                    styles: customStyles,
                                                                    inputValue: streetName,
                                                                    value: googlePlace,
                                                                    onChange: setSearchProperty,
                                                                    onInputChange: handleInputChange,
                                                                    name: "googlePlacesStreetName",
                                                                    id: "googlePlacesStreetName",
                                                                    placeholder: "Enter a location",
                                                                }}
                                                            />
                                                            {isValid == false && googlePlace == '' ?
                                                                <label style={{ color: "#dc3545" }} >{t("Temporary Mailing Address")}</label> : null
                                                            }
                                                        </Col>
                                                        <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                            >
                                                                <Form.Label className="myform-label">{t('Zip Code')}</Form.Label>
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="zipCode"
                                                                    value={zipCode}
                                                                    onChange={(e) => changeZip(e)}
                                                                    onBlur={() => zipCodeApi()}
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {t("Enter Zip Code Error")}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                            >
                                                                <Form.Label className="myform-label">{t('City')}</Form.Label>
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="city"
                                                                    value={city}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                            >
                                                                <Form.Label className="myform-label">{t('Country')}</Form.Label>
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="country"
                                                                    value={county}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                            >
                                                                <Form.Label className="myform-label">{t('State')}</Form.Label>
                                                                <Form.Control 
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="state"
                                                                    value={state}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mailing-address-card-row">
                                            <div className="mailing-address-question-div">
                                                <p>{t('Would you like to sign up for electronic mail')}?</p>
                                                <div className="mailing-address-card-actions">
                                                    <span>{t('No')}</span>
                                                    <div className="custom-main-swtich">
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            className="mailing-address-card-switch"
                                                            label=""
                                                            onClick={mailed}
                                                        />
                                                    </div>
                                                    <span>{t('Yes')}</span>
                                                </div>
                                            </div>
                                            {electronicMail == true ? 
                                            
                                                <div className="mailing-address-card-input">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Label className="myform-label">
                                                                    {t('Email Address')}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder={t('Enter your email Address')}
                                                                    name="s_CommValue"
                                                                    title="personEmailContact"
                                                                    value={props.email ? props.email : ''}
                                                                    onChange={props.handleChange}
                                                                    required
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            : ""}
                                        </div>
                                    </Col>
                                    <div className="flood-bts-div">
                                        <Button
                                            id="primary-grey-btn-flood"
                                            variant="outline-dark"
                                            onClick={() => handleOnclick('8')}
                                        >
                                            {t('Previous')}
                                        </Button>
                                        <Button
                                            id="primary-colored-btn"
                                            variant="primary"
                                            onClick={() => handleOnclick('10')}
                                        >
                                            {t('Next')}
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Modal size="md" show={showModal} className="add-mortage-modal">
                        <Modal.Header id="add-mortage-modal-header">
                            <Modal.Title>Select City</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="add-mortage-modal-body">
                            <Row>
                                <Table className="p-0 m-0" size="sm" responsive striped>
                                    <thead>
                                        <tr>
                                            <th>City</th>
                                            <th>County</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {zipData.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                <td><a href='#' onClick={() => setZipDetailsData(data)}>{data.s_CityName}</a></td>
                                                <td>{data.s_ZipCounty}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </section>
            }
        </LoadingOverlay>
    )
}

function mapStateToProps(state) {
    return {
        email : state.common.emptyInstantQuoteData.personEmailContact.s_CommValue,
        isChecked : state.common.emptyInstantQuoteData.mapData.isSameMailingAddress ? state.common.emptyInstantQuoteData.mapData.isSameMailingAddress : "NO",
        mailingAddr : state.common.emptyInstantQuoteData.mailingAddr,
        emptyQuote : state.common.emptyInstantQuoteData,
    };
}

export default connect(mapStateToProps)(MilingAdress)