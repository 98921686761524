import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Table, Modal } from "react-bootstrap";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import  {getZipDetails} from "../../../../services/instaQuote";
import {getSearchMortgagee} from "../../../../services/instaQuote";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Typeahead } from 'react-bootstrap-typeahead';
// import delIcon from "../../../assets/delete-icon.svg";
import delIcon from "../../../../../src/assets/delete-icon.svg";
import { toast } from 'react-toastify';
import "./Mortage.scss";

const Mortage = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [selectInterest, setSelectInterest] = useState("PRIMARY");
    const [selectedName, setSelectedName] = useState(null);
    const [options, setOptions] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [interestValue, setInterestValue] = useState({
        value: "PRIMARY",
        label: "First Mortgagee",
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [inputFields, setInputFields] = useState([]);
    const [interestOptions, setIntrestOptions] = useState([]);
    const [search, setSearchProperty] = useState(null);
    const [streetName, setStreetName] = useState('');
    const [googlePlace, setGooglePlace] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipData, setZipData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        name: "",
        fullname: "",
        firstName: "",
        middleName: "",
        lastName: "",
        type: "",
        mailAddress1: "",
        mailAddress2: "",
        city: "",
        state: "",
        zipCode: null,
        loanNumber: "",
        PersonInfoIdPK: "",
        PersonAddressesIdPK: "",

        n_CityId_FK: "",
        n_CountryId_FK: "",
        n_StateId_FK: ""
    });
    const [isValid, setisValid] = useState(true);
    const [validated, setValidated] = useState(false);
    useEffect(() => {
    }, [interestValue]);

    useEffect(() => {
    }, [selectInterest]);

    useEffect(() => {
        props.setDefaultData('mortgageeData', inputFields);
    }, [inputFields]);

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    

    const customStyles = {
        control: (base, state) => ({
        ...base,
        borderColor: state.isFocused ?
            '#ddd' : isValid ?
            '#ddd' : 'red',
        }),
    };

    useEffect(() => {
        if (validated && googlePlace == '') {
          setisValid(false);
        } else {
          setisValid(true);
        }
    }, [validated]);

    useEffect(() => {
        let interestOptionsDD = [{ value: "PRIMARY", label: "First Mortgagee" },
        { value: "SECOND", label: "Second Mortgagee" },
        { value: "THIRD", label: "Third Mortgagee" },
        { value: "ADDITIONALINSURED", label: "Additional Insured" },
        { value: "ADDITIONALINTEREST", label: "Additional Interest" }];

        let selectedInterestOptions = [];
        if (inputFields.length === 0) {
            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
            setSelectInterest('PRIMARY');
        }else {
            inputFields.filter(function (item) {
                if (item.type == 'First Mortgagee') {
                    selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
                    setSelectInterest('SECOND');
                }
                if (item.type == 'Second Mortgagee') {
                    selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
                    setSelectInterest('THIRD');
                }
                if (item.type == 'Third Mortgagee') {
                    selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
                    setSelectInterest('ADDITIONALINSURED');
                }
                var interestOptionAdditional = '';
                var firstStatusExist = false;
                var secondStatusExist = false;
                var thirdStatusExist = false;
                var additionalStatusExist = false;
                if (item.type == 'Additional Insured') {
                    inputFields.map((value) => {
                        if (value.type == 'First Mortgagee') {
                            firstStatusExist = true;
                        }else if(value.type == 'Second Mortgagee'){
                            secondStatusExist = true;
                        }else if(value.type == 'Third Mortgagee') {
                            thirdStatusExist = true;
                        }else if (value.type == 'Additional Insured') {
                            additionalStatusExist = true;
                        }
                        if (additionalStatusExist && !firstStatusExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
                            setSelectInterest('PRIMARY');
                        }
                        if (additionalStatusExist && firstStatusExist && !secondStatusExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
                            setSelectInterest('SECOND');
                        }
                        if (additionalStatusExist && firstStatusExist && secondStatusExist && !thirdStatusExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
                            setSelectInterest('THIRD');
                        }
                        if (additionalStatusExist && firstStatusExist && secondStatusExist && thirdStatusExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
                            setSelectInterest('ADDITIONALINSURED');
                        }
                    })
                }

                var interestOptionAdditional = '';
                var firstStatusInterestExist = false;
                var secondStatusInterestExist = false;
                var thirdStatusInterestExist = false;
                var additionalStatusInterestExist = false;
                if (item.type == 'Additional Interest') {
                    inputFields.map((value) => {
                        if (value.type == 'First Mortgagee') {
                            firstStatusInterestExist = true;
                        }else if (value.type == 'Second Mortgagee') {
                            secondStatusInterestExist = true;
                        }else if (value.type == 'Third Mortgagee') {
                            thirdStatusInterestExist = true;
                        }else if (value.type == 'Additional Interest') {
                            additionalStatusInterestExist = true;
                        }
                        if (additionalStatusInterestExist && !firstStatusInterestExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD');
                            setSelectInterest('PRIMARY');
                        }
                        if (additionalStatusInterestExist && firstStatusInterestExist && !secondStatusInterestExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'PRIMARY' && item.value !== 'THIRD');
                            setSelectInterest('SECOND');
                        }
                        if (additionalStatusInterestExist && firstStatusInterestExist && secondStatusInterestExist && !thirdStatusInterestExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'PRIMARY');
                            setSelectInterest('THIRD');
                        }
                        if (additionalStatusInterestExist && firstStatusInterestExist && secondStatusInterestExist && thirdStatusInterestExist) {
                            selectedInterestOptions = interestOptionsDD.filter(item => item.value !== 'SECOND' && item.value !== 'THIRD' && item.value !== 'PRIMARY');
                            setSelectInterest('ADDITIONALINSURED');
                        }
                    })
                }
            })
        }
        setIntrestOptions(selectedInterestOptions);
    }, [inputFields.length]);

    useEffect(() => {
        if (search != null) {
        setStreetName(search.value.structured_formatting.main_text);
        getGeoCode(search.value.description);
        setGooglePlace(search);
        //setisValid(true);
        //setInputFields(values);
        }
    }, [search]);

    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important;}</style>`)
    }, [])

    const getGeoCode = (description) => {
        geocodeByAddress(description)
        .then( addr => {
            addr && addr[0].address_components.forEach(e => {
                if (e.types.includes('postal_code')) {
                    setZipCode(e.short_name);
                    setLoadingStatus(true);dispatch(getZipDetails('13', e.short_name, 'APPLICANT')).then((res) => {
                        setZipData(res.data.zipData);
                        const values = data;
                        values.city = res.data.zipData[0].s_CityName;
                        values.state = res.data.zipData[0].s_StateCode;
                        values.zipCode = e.short_name;
                        values.n_CityId_FK = res.data.zipData[0].n_CityId_PK;
                        values.n_CountryId_FK = res.data.zipData[0].n_CountryId_FK;
                        values.n_StateId_FK = res.data.zipData[0].n_StateId_PK;

                        setData(values);

                        setLoadingStatus(false);
                    })
                }
            })
        })
    }

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        const firstMortgageefound = values.some(el => el.type === 'First Mortgagee');
        const secondMortgageefound = values.some(el => el.type === 'Second Mortgagee');
        const thirdMortgageefound = values.some(el => el.type === 'Third Mortgagee');
        if(values[index]['type'] == "First Mortgagee"){
            if (thirdMortgageefound) {
                toast.error(t('Please Remove Third Mortgagee First!'));
                return false;
            }
            if (secondMortgageefound) {
                toast.error(t('Please Remove Second Mortgagee First!'));
                return false;
            }
            if (values[index]['type'] == "Second Mortgagee") {
                if (thirdMortgageefound) {
                    toast.error(t('Please Remove Third Mortgagee First!'));
                    return false;
                }
            }
        }

        values.splice(index, 1);
        setInputFields(values);
        setErrorMsg(null);
        setData({});
    }; 

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;

        setInputFields(values);
    }

    const handleInputDataChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    const handleSaveInterest = () => {
        const values = [...inputFields];
        var status = 1;
        if (values.length === 1) {
            if (values[0].type === "") {
                values.splice(0, 1);
            }
        }
        if (selectInterest === "ADDITIONALINSURED" || selectInterest === "ADDITIONALINTEREST") {
            if (data.firstName == "" || data.lastName == "" || streetName == "" || data.city == "") {
                status = 0;
            }
            if (selectInterest === "ADDITIONALINTEREST" && data.type == "") {
                status = 0;
            }
            if (data.firstName != undefined && data.lastName != undefined) {
                setData({
                    ...data,
                    name: `${data.firstName} ${data.middleName === undefined ? '' : data.middleName} ${data.lastName}`,
                });
                values.push({
                    ...data,
                    name: `${data.firstName} ${data.middleName === undefined ? '' : data.middleName} ${data.lastName}`,
                    type: interestValue.label,
                    mailAddress1: streetName
                });
            }else{
                status = 0;
            }
        }else{
            if (data.loanNumber == "" || data.fullname == "") {
                status = 0;
            }

            if (data.loanNumber != undefined && googlePlace != null) {
                setData({
                    ...data,
                    name: data.fullname,
                    PersonInfoIdPK: data.PersonInfoIdPK,
                    PersonAddressesIdPK: data.PersonAddressesIdPK
                });
                values.push({
                    ...data,
                    name: data.fullname,
                    PersonInfoIdPK: data.PersonInfoIdPK,
                    PersonAddressesIdPK: data.PersonAddressesIdPK
                })
            }else{
                status = 0;
            }
        }

        if (status == 1) {
            setInputFields(values);
            setData({});
            setShow(false);
            setOptions([]);
            setSearchProperty(null);
            setStreetName('');
            setGooglePlace('');
            setValidated(false);
          } else {
            setValidated(true);
          }
    }

    const handleChange = (event) => {
        if (event != "") {
            setSelectedName(event);
            if (event != '' && event.length >= 4) {
                const params = { searchField: event }
                let actions = dispatch(getSearchMortgagee(params)).then((res) => {
                const data = res.data.map(({ s_FullLegalName: label, n_PersonAddressesId_PK: value, ...rest }) => ({ label, value, ...rest }));
                data.map((item) => {
                    item.s_FullLegalName = item.label;
                    item.n_PersonAddressesId_PK = item.value;
                    item.label = item.s_FullLegalName + ' - ' + item.s_AddressLine1 + ',' + item.s_CityName + ',' + item.s_CountyName + ',' + item.s_StateCode + ',' + item.s_PostalCode;
                })
                setOptions(data);

                })
            }
        }
    };

    const handleOnChangeDropDown = (e) => {
        setSelectInterest(e.target.value);
    };


    const handleOnChange = (event) => {
        if (event.length > 0) {
            const eventData = event[0];
            setSelectedName(eventData);
            const values = data;
            values.fullname = eventData.s_FullLegalName;
            values.firstName = "";
            values.middleName = "";
            values.lastName = "";
            values.type = interestValue.label;
            values.mailAddress1 = eventData.s_AddressLine1;
            values.mailAddress2 = "";
            values.city = eventData.s_CityName;
            values.state = eventData.s_StateCode;
            values.zipCode = eventData.s_PostalCode;
            values.name = eventData.s_FullLegalName;
            values.PersonInfoIdPK = eventData.n_PersonInfoId_PK;
            values.PersonAddressesIdPK = eventData.n_PersonAddressesId_PK;

            values.n_CityId_FK = eventData.n_CityId_FK;
            values.n_CountryId_FK = eventData.n_CountryId_FK;
            values.n_StateId_FK = eventData.n_StateId_FK;
            setData(values);
        }

    };

    const handleGooglAutoComplete = (val, status) => {
        if (status.action == "input-change") {
        setStreetName(val);
        } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
        setStreetName(streetName.toUpperCase());
        }
    }

    const clearData = () => {
        setData({});
        setShow(false);
        setOptions([]);
        setSearchProperty(null);
        setStreetName('');
        setGooglePlace('');
        setData({
        ...data,
        name: "",
        fullname: "",
        firstName: "",
        middleName: "",
        lastName: "",
        type: "",
        mailAddress1: "",
        mailAddress2: "",
        city: "",
        state: "",
        zipCode: data.zipCode != '' ? null : data.zipCode == undefined ? null : null,
        loanNumber: "",
        PersonInfoIdPK: "",
        PersonAddressesIdPK: "",

        n_CityId_FK: "",
        n_CountryId_FK: "",
        n_StateId_FK: ""
        });
        setZipCode('');

        //setSelectInterest('PRIMARY');
    }

    // on select clear data
    const selectClearData = () => {
        setData({});
        // setOptions([]);
        setSearchProperty(null);
        setStreetName('');
        setGooglePlace('');
        setValidated(false);
        setData({
        ...data,
        name: "",
        fullname: "",
        firstName: "",
        middleName: "",
        lastName: "",
        type: "",
        mailAddress1: "",
        mailAddress2: "",
        city: "",
        state: "",
        zipCode: data.zipCode != '' ? null : data.zipCode == undefined ? null : null,
        loanNumber: "",
        PersonInfoIdPK: "",
        PersonAddressesIdPK: "",

        n_CityId_FK: "",
        n_CountryId_FK: "",
        n_StateId_FK: ""
        });
        setZipCode('');
    }


    const changeZip = (e) => {
        setZipCode(e.target.value);
    }

    const setInterestValueFunc = (e) => {
        if (e === "PRIMARY") {
            setInterestValue({ value: "PRIMARY", label: "First Mortgagee" });
        } else if (e === "SECOND") {
            setInterestValue({ value: "SECOND", label: "Second Mortgagee" });
        } else if (e === "THIRD") {
            setInterestValue({ value: "THIRD", label: "Third Mortgagee" });
        } else if (e === "ADDITIONALINSURED") {
            setInterestValue({ value: "ADDITIONALINSURED", label: "Additional Insured" });
        } else if (e === "ADDITIONALINTEREST") {
            setInterestValue({ value: "ADDITIONALINTEREST", label: "Additional Interest" });
        }
    }

    const setSelectInterestFunc = (e) => {
        setSelectInterest(e);
    }

    const zipCodeApi = () => {
        if (zipCode != '' && zipData.length >= 1) {
            setLoadingStatus(true);
            dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
                setZipData(res.data.zipData);
                setLoadingStatus(false);
                setShowModal(true);
            });
        }
    }

    const setZipDetailsData = (datas) => {
        const values = data;
        values.city = datas.s_CityName;
        values.state = datas.s_StateCode;
        setData(values);
        setShowModal(false);
    }

    return(
        <LoadingOverlay>
            <Loader loading={loadingStatus} />
            {
                <section className="add-mortage-section">
                    
                        <Row className="justify-content-center">
                            <Col xs="12" sm="12" md="12" lg="8" xl="8" className="add-mortage-form-section">
                                <Row className="justify-content-center">
                                    <Col xs="12" className="add-mortage-form-main">
                                        <div className="add-mortage-headings">
                                            <h3>{t('Add Mortgages and Additional Interests')}</h3>
                                        </div>
                                        <div className="add-mortage-form">
                                            <div className="add-mortage-add-btn">
                                                <div></div>
                                                <Button
                                                    variant="primary"
                                                    className="mybtn"
                                                    id="primary-colored-btn"
                                                    onClick={() => setShow(true)}
                                                    >
                                                    {" "}
                                                    <span> + </span> {t('Add')}
                                                </Button>
                                            </div>
                                            <div className="add-mortage-table-div">
                                                <Table className="add-mortage-table" borderless responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>{t('Name')}</th>
                                                            <th>{t('Interest Type')}</th>
                                                            <th>{t('Loan No')}</th>
                                                            <th>{t('Action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {inputFields.length > 0 && (
                                                            <>
                                                                {inputFields.map((field, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <Form.Label className="myform-label">{field.name}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Label className="myform-label">{field.type}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <Form.Label className="myform-label">{field.loanNumber}</Form.Label>
                                                                        </td>
                                                                        <td className="add-mortage-action-row">
                                                                            <div className="add-mortage-action">
                                                                                <img
                                                                                    src={delIcon}
                                                                                    onClick={() => handleRemoveFields(index)}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </>
                                                        )}
                                                    </tbody>
                                                    <tfoot>

                                                    </tfoot>
                                                </Table>
                                                <Form.Label style={{ color: 'red' }}>{errorMsg}</Form.Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <div className="flood-bts-div">
                                    <Button
                                        id="primary-grey-btn-flood"
                                        variant="outline-dark"
                                        onClick={() => handleOnclick('9')}
                                    >
                                        {t('Previous')}
                                    </Button>
                                    <Button
                                        id="primary-colored-btn"
                                        variant="primary"
                                        onClick={() => handleOnclick('11')}
                                    >
                                        {t('Next')}
                                    </Button>
                                </div>
                                </Row>
                            </Col>
                        </Row>
                        <Modal size="lg" show={show} className="add-mortage-modal">
                            <Modal.Header id="add-mortage-modal-header">
                                <Modal.Title>{t('Add Interest')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="add-mortage-modal-body">
                                <Form validated = {validated}>
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                            <Form.Select className="myform-input custom-select-autocomplete"
                                                onChange={(e) => {
                                                    setInterestValueFunc(e.target.value);
                                                    setSelectInterestFunc(e.target.value);
                                                    setData({});
                                                    setValidated(false);
                                                    selectClearData();
                                                }}
                                                required
                                            >
                                                <option value={''} className="myform-input custom-select-autocomplete">{t('Select')}</option>
                                                {
                                                    interestOptions.map((v, i) => {
                                                        return <option className="myform-input custom-select-autocomplete" key={i} value={v.value} >{t(v.label)}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <br></br>
                                    {(selectInterest === "PRIMARY" ||
                                        selectInterest === "SECOND" ||
                                        selectInterest === "THIRD") && (
                                            <Row>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Form.Group
                                                        lassName="account-form-group"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Typeahead 
                                                            className="is-invalid"
                                                            onChange={(e) => {
                                                                handleOnChange(e)
                                                            }}
                                                            onInputChange={(e) => { handleChange(e) }}
                                                            options={options}
                                                            placeholder="Search name..."
                                                            value={selectedName}
                                                            inputProps={{ required: true }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            {selectInterest === "ADDITIONALINSURED" ||
                                            selectInterest === "ADDITIONALINTEREST" ? (
                                                <Fragment>
                                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('First Name')}</Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="firstName"
                                                                value={data.firstName}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Middle Name')}</Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="middleName"
                                                                value={data.middleName}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Last Name')}</Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="lastName"
                                                                value={data.lastName}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Fragment>
                                            ) : (
                                                <Col xs="12">
                                                    <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Name')}</Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="name"
                                                                value={data.fullname}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                                required
                                                            />
                                                        </Form.Group>
                                                </Col>
                                            )}
                                            {selectInterest === "ADDITIONALINTEREST" && (
                                                <Col xs="12">
                                                    <Row>
                                                        <Col xs="12" sm="12" md="12" lg="6" xl="6">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Type')}</Form.Label>
                                                            <Form.Select 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="type"
                                                                value={data.type}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                                required
                                                            >
                                                                <option value="">{t('Select')}</option>
                                                                <option value="PROPMGR">Property Manager</option>
                                                                <option value="OTHERS">Others</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    </Row>
                                                </Col>
                                            )}

                                            {selectInterest === "ADDITIONALINSURED" ||
                                                selectInterest === "ADDITIONALINTEREST" ? (
                                                    <Fragment>
                                                        <Col xs="12">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                                >
                                                                <Form.Label className="myform-label">
                                                                    Mailing Address 1{" "}
                                                                </Form.Label>
                                                                <GooglePlacesAutocomplete
                                                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                                    selectProps={{
                                                                    styles: customStyles,
                                                                    inputValue: streetName,
                                                                    value: googlePlace,
                                                                    onInputChange: handleGooglAutoComplete,
                                                                    onChange: setSearchProperty,
                                                                    name: "googlePlacesStreetName",
                                                                    id: "googlePlacesStreetName",
                                                                    placeholder: "Enter a location",
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <Col xs="12">
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="add-mortage-form-group"
                                                                >
                                                                <Form.Label className="myform-label">
                                                                    {t('Mailing Address')} 1{" "}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="myform-input"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="mailAddress1"
                                                                    value={data.mailAddress1}
                                                                    onChange={(e) => handleInputDataChange(e)}
                                                                    disabled={
                                                                    selectInterest === "PRIMARY" ||
                                                                    selectInterest === "SECOND" ||
                                                                    selectInterest === "THIRD"
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Fragment>
                                                )}
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="add-mortage-form-group"
                                                >
                                                    <Form.Label className="myform-label">{t('City')}</Form.Label>
                                                    <Form.Control 
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={
                                                            selectInterest === "PRIMARY" ||
                                                            selectInterest === "SECOND" ||
                                                            selectInterest === "THIRD" ||
                                                            selectInterest === "ADDITIONALINSURED" ||
                                                            selectInterest === "ADDITIONALINTEREST"
                                                        }
                                                        name="city"
                                                        value={data.city}
                                                        onChange={(e) => handleInputDataChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="add-mortage-form-group"
                                                >
                                                    <Form.Label className="myform-label">{t('State')}</Form.Label>
                                                    <Form.Control 
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        disabled={
                                                            selectInterest === "PRIMARY" ||
                                                            selectInterest === "SECOND" ||
                                                            selectInterest === "THIRD" ||
                                                            selectInterest === "ADDITIONALINSURED" ||
                                                            selectInterest === "ADDITIONALINTEREST"
                                                        }
                                                        name="state"
                                                        value={data.state}
                                                        onChange={(e) => handleInputDataChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                {selectInterest === "ADDITIONALINSURED" ||
                                                selectInterest === "ADDITIONALINTEREST" ? (
                                                    <Fragment>
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                            >
                                                            <Form.Label className="myform-label">{t('Zip Code')}</Form.Label>
                                                            <Form.Control
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="zipCode"
                                                                value={zipCode}
                                                                onChange={(e) => changeZip(e)}
                                                                onBlur={() => zipCodeApi()}
                                                                disabled={true}

                                                            />
                                                        </Form.Group>  
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Zip Code')}</Form.Label>
                                                            <Form.Control 
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                disabled={
                                                                    selectInterest === "PRIMARY" ||
                                                                    selectInterest === "SECOND" ||
                                                                    selectInterest === "THIRD"
                                                                }
                                                                name="zipCode"
                                                                value={data.zipCode}
                                                                onChange={(e) => handleInputDataChange(e)}
                                                            />
                                                        </Form.Group>
                                                    </Fragment>
                                                )}
                                            </Col>
                                            <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                {selectInterest === "PRIMARY" || selectInterest === "SECOND" || selectInterest === "THIRD" ? (
                                                    <Form.Group
                                                        controlId="formBasicEmail"
                                                        className="add-mortage-form-group"
                                                    >
                                                        <Form.Label className="myform-label">Loan Number</Form.Label>
                                                        <Form.Control
                                                            className="myform-input"
                                                            type="text"
                                                            placeholder=""
                                                            name="loanNumber"
                                                            value={data.loanNumber}
                                                            onChange={(e) => handleInputDataChange(e)}
                                                            required
                                                        />
                                                    </Form.Group>
                                                ) : null}
                                            </Col>
                                        </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="add-mortage-modal-footer">
                                <Button
                                    variant="outline-dark"
                                    onClick={() => clearData()}
                                    className="mybtn-light"
                                    style={{
                                        marginRight: "1rem",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                    }}
                                    id="primary-grey-btn-flood"
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    onClick={() => handleSaveInterest()}
                                    disabled={loadingStatus}
                                    className="mybtn"
                                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                                    id="primary-colored-btn"
                                    variant="primary"
                                >
                                    {t('Save')}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal size="md" show={showModal} className="add-mortage-modal" style={{ borderRight: '5px solid' }}>
                            <Modal.Header id="add-mortage-modal-header">
                                <Modal.Title>Select City</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="add-mortage-modal-body">
                                <Row>
                                    <Table className="p-0 m-0" size="sm" responsive striped>
                                        <thead>
                                            <tr>
                                                <th>City</th>
                                                <th>County</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {zipData.map((data, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td><a href='#' onClick={() => setZipDetailsData(data)}>{data.s_CityName}</a></td>
                                                        <td>{data.s_ZipCounty}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Modal.Body>
                        </Modal>
                </section>
            }
        </LoadingOverlay>
    )
}

export default Mortage;