import Http from "../../../../../Http";

export async function rateViewer(sheetpk) {
  const url = `/api/reports/generate/RATEHISTORYHO3/${sheetpk}`;
  try {
    const { data } = await Http.get(url);
    return data;
  } catch (error) {
    console.error("Error in rate history api :", error);
    throw error;
  }
}
