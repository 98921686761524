import React from "react";
import FloodACHPayment from "./FloodACHPayment";
import FloodCCPayment from "./FloodCCPayment";
import { useParams } from "react-router-dom";

const FloodPayment = () => {
    // get type of error
    let { type, poTransPk } = useParams();
    let view = null;

    switch (type) {
        case 'ACH':
            view =
                <FloodACHPayment
                    poTransPk={poTransPk}
                    type={type}
                />
            break;
        case 'ECHECK':
            view =
                <FloodCCPayment
                    poTransPk={poTransPk}
                />
            break;
        default:
            view = null;
            break;
    }

    return (
        view
    );
};

export default FloodPayment;