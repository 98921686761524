import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import React from "react";
// import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AgencyMailingAddress = ({ AgencyData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box p={6} pb={0}>
        <Grid alignItems={"flex-end"} templateColumns="repeat(6, 1fr)" gap={4}>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>{t("Mailing Address")}</FormLabel>
              <Input
                type="text"
                id="mail_s_AddressLine1"
                name="mail_s_AddressLine1"
                className="form-control producer-group-input col-form-input input-producer"
                value={AgencyData?.mail_s_AddressLine1}
                isDisabled={true}
              />
            </FormControl>
          </GridItem>
          <FormControl>
            <FormLabel>{t("Zip")}</FormLabel>
            <Input
              type="text"
              id="mail_s_PostalCode"
              name="mail_s_PostalCode"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.mail_s_PostalCode}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("City")}</FormLabel>
            <Input
              type="text"
              id="mail_s_CityName"
              name="mail_s_CityName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.mail_s_CityName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("State")}</FormLabel>
            <Input
              type="text"
              id="mail_s_StateName"
              name="mail_s_StateName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.mail_s_StateName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("County")}</FormLabel>
            <Input
              type="text"
              id="mail_s_CountyName"
              name="mail_s_CountyName"
              className="form-control producer-group-input col-form-input input-producer"
              value={AgencyData?.mail_s_CountyName}
              isDisabled={true}
            />
          </FormControl>
        </Grid>
      </Box>
      {/* <div className="dashboard-table-div" id="dashboard-table-section">
      <Row>
        <Col xs="12">
          <Row>
            <Col xs={2} sm={5}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label p-0">
                  <b>{t("Mailing Address")}</b>
                </label>
                <Col sm="8" className="p-0">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    //
                    value={
                      props.AgencyData?.mail_s_AddressLine1
                        ? props.AgencyData?.mail_s_AddressLine1
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={2}>
              <Row className="form-group">
                <label className="col-sm-3 col-form-label p-0">
                  <b>{t("Zip")}</b>
                </label>
                <Col sm="8" className="p-0">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={
                      props?.AgencyData?.mail_s_PostalCode
                        ? props?.AgencyData?.mail_s_PostalCode
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={2}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label p-0">
                  <b>{t("City")}</b>
                </label>
                <Col sm="8" className="p-0">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={
                      props?.AgencyData?.mail_s_CityName
                        ? props?.AgencyData?.mail_s_CityName
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={2}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label p-0">
                  <b>{t("State")}</b>
                </label>
                <Col sm="8" className="p-0">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={
                      props?.AgencyData?.mail_s_StateName
                        ? props?.AgencyData?.mail_s_StateName
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={2} sm={3} style={{ marginTop: "15px" }}>
              <Row className="form-group">
                <label className="col-sm-4 col-form-label p-0">
                  <b>{t("County")}</b>
                </label>
                <Col sm="8" className="p-0">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control producer-group-input col-form-input input-producer"
                    value={
                      props?.AgencyData?.mail_s_CountyName
                        ? props?.AgencyData?.mail_s_CountyName
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div> */}
    </>
  );
};

export default AgencyMailingAddress;
