import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const FloodQuoteTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Get protection of your assets</span>
      </div>
      <div className="managment-tab-body">
        <div className="flood-quote-tab-main-div">
          <span className="flood-quote-tab-main-title">
            Visit flood quote for flood insurance
          </span>
          <Link className="flood-quote-tab-main-link">
            <span>unifimanagement.com</span>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default FloodQuoteTab;
