import {
  Box,
  Text,
  Grid,
  GridItem,
  Input,
  Divider,
  Flex,
  Badge,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { postDeletePolicyMortagee } from "../../../api/postDeletePolicyMortagee";
import { toast } from "react-toastify";
import moment from "moment";

export const AdditionalDetails = ({ quoteDetailsData, displayQuote }) => {
  const { t } = useTranslation();
  const mortgagees =
    quoteDetailsData?.get_quote_response?.data?.mortgagees?.data || [];

  const additionalInsured =
    quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data || [];

  const deleteMortagee = async (mortgagee) => {
    const reqBody = {
      transPK: quoteDetailsData?.get_quote_response?.data?.poTransactionPk,
      n_PolicyAddInterest_PK: mortgagee?.n_PolicyAddInterest_PK,
    };
    const response = await postDeletePolicyMortagee(reqBody);
    if (response.success && response.data.Status === "Y") {
      toast.success(t("Deleted!", { autoClose: 1500 }));
      await displayQuote();
    }
  };

  return (
    <>
      {mortgagees.length > 0 && (
        <div
          style={{
            background: "white",
            padding: "0.5rem",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: "0.8rem",
          }}
        >
          <Box p={3}>
            {mortgagees.map((mortgagee, index) => (
              <Box key={index} mb={6}>
                <Flex alignItems="center" mb={2} justifyContent="space-between">
                  <Flex alignItems="center">
                    <Badge
                      colorScheme={
                        mortgagee.isDeleted === "N" ? "green" : "red"
                      }
                      mr={2}
                      mb={4}
                    >
                      {mortgagee.isDeleted === "N"
                        ? t("Active")
                        : t("InActive")}
                    </Badge>
                    <Text size="sm">{t(mortgagee.mortgageeType) || ""}</Text>
                  </Flex>
                  <Box mr={3}>
                    <Button
                      colorScheme={"red"}
                      opacity={0.8}
                      size={"sm"}
                      w={"95%"}
                      onClick={() => deleteMortagee(mortgagee)}
                    >
                      <AiOutlineDelete size={20} color="white" />
                    </Button>
                  </Box>
                </Flex>

                <Grid templateColumns="repeat(3, 1fr)" gap={"1rem"}>
                  <GridItem>
                    <Text fontWeight={500} mb={1}>
                      {t("Mortgagee Name")}:
                    </Text>
                    <Input
                      className="inputField"
                      value={`${mortgagee.person?.firstName || ""} ${
                        mortgagee.person?.middleName || ""
                      } ${mortgagee.person?.lastName || ""}`}
                      isDisabled
                      _disabled={{
                        color: "black",
                        backgroundColor: "var(--chakra-colors-neutral-50)",
                      }}
                      placeholder="Name"
                      bg={"#e9ecef"}
                    />
                  </GridItem>
                  <GridItem>
                    <Text fontWeight={500} mb={1}>
                      {t("Loan Number")}:
                    </Text>
                    <Input
                      className="inputField"
                      value={mortgagee.loanNo || ""}
                      isDisabled
                      placeholder="Loan Number"
                      _disabled={{
                        color: "black",
                        backgroundColor: "var(--chakra-colors-neutral-50)",
                      }}
                      bg={"#e9ecef"}
                    />
                  </GridItem>

                  <GridItem>
                    <Text fontWeight={500} mb={1}>
                      {t("Address")}:
                    </Text>
                    <Input
                      className="inputField"
                      value={
                        mortgagee.address?.addressLine2
                          ? `${mortgagee.address?.addressLine1 || ""}, ${
                              mortgagee.address?.addressLine2 || ""
                            }, ${mortgagee.address?.city || ""}, ${
                              mortgagee.address?.county || ""
                            }, ${mortgagee.address?.zip || ""}`
                          : `${mortgagee.address?.addressLine1 || ""}, ${
                              mortgagee.address?.city || ""
                            }, ${mortgagee.address?.county || ""}, ${
                              mortgagee.address?.zip || ""
                            }`
                      }
                      isDisabled
                      _disabled={{
                        color: "black",
                        backgroundColor: "var(--chakra-colors-neutral-50)",
                      }}
                      placeholder="Address"
                      bg={"#e9ecef"}
                    />
                  </GridItem>
                </Grid>
                {index !== mortgagees.length - 1 && <Divider my={4} />}
              </Box>
            ))}
          </Box>
        </div>
      )}
      {additionalInsured.length > 0 && (
        <div
          className="mt-3"
          style={{
            background: "white",
            padding: "0.5rem",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
            borderRadius: "0.8rem",
          }}
        >
          <Box p={3}>
            {additionalInsured.map((mortgagee, index) => (
              <Box key={index} mb={6}>
                <Flex alignItems="center" mb={2} justifyContent="space-between">
                  <Flex alignItems="center">
                    <Badge
                      colorScheme={
                        mortgagee.isDeleted === "N" ? "green" : "red"
                      }
                      mr={2}
                      mb={4}
                    >
                      {mortgagee.isDeleted === "N"
                        ? t("Active")
                        : t("InActive")}
                    </Badge>
                    <Text size="sm">
                      {mortgagee.personType === "ADDITIONALINSURED"
                        ? "Additional Insured"
                        : ""}
                    </Text>
                  </Flex>
                  <Box mr={3}>
                    <Button
                      colorScheme={"red"}
                      opacity={0.8}
                      size={"sm"}
                      w={"95%"}
                      onClick={() => deleteMortagee(mortgagee)}
                    >
                      <AiOutlineDelete size={20} color="white" />
                    </Button>
                  </Box>
                </Flex>
                {!mortgagee.person?.firstName && mortgagee.person?.lastName ? (
                  <Grid templateColumns="repeat(2, 1fr)" gap={"1rem"}>
                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("Organization Name")}:
                      </Text>
                      <Input
                        className="inputField"
                        value={`${mortgagee.person?.lastName || ""}`}
                        isDisabled
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        placeholder="Name"
                        bg={"#e9ecef"}
                      />
                    </GridItem>
                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("Address")}
                      </Text>
                      <Input
                        className="inputField"
                        value={
                          mortgagee.address?.addressLine2
                            ? `${mortgagee.address?.addressLine1 || ""}, ${
                                mortgagee.address?.addressLine2 || ""
                              }, ${mortgagee.address?.city || ""}, ${
                                mortgagee.address?.county || ""
                              }, ${mortgagee.address?.zip || ""}`
                            : `${mortgagee.address?.addressLine1 || ""}, ${
                                mortgagee.address?.city || ""
                              }, ${mortgagee.address?.county || ""}, ${
                                mortgagee.address?.zip || ""
                              }`
                        }
                        isDisabled
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        placeholder="Address"
                        bg={"#e9ecef"}
                      />
                    </GridItem>
                  </Grid>
                ) : (
                  <Grid templateColumns="repeat(4, 1fr)" gap={"1rem"}>
                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("First Name")}:
                      </Text>
                      <Input
                        className="inputField"
                        value={`${mortgagee.person?.firstName || ""}`}
                        isDisabled
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        placeholder="Name"
                        bg={"#e9ecef"}
                      />
                    </GridItem>
                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("Last Name")}:
                      </Text>
                      <Input
                        className="inputField"
                        value={mortgagee.person?.lastName || "" || ""}
                        isDisabled
                        placeholder="lastName"
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        bg={"#e9ecef"}
                      />
                    </GridItem>

                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("Date Of Birth")}:
                      </Text>
                      <Input
                        className="inputField"
                        value={
                          mortgagee.person?.dob !== null &&
                          mortgagee.person?.dob !== ""
                            ? moment(mortgagee.person?.dob).format("MM/DD/YYYY")
                            : "" || ""
                        }
                        isDisabled
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        placeholder="DOB (Optional)"
                        bg={"#e9ecef"}
                      />
                    </GridItem>
                    <GridItem>
                      <Text fontWeight={500} mb={1}>
                        {t("Address")}:
                      </Text>
                      <Input
                        className="inputField"
                        value={
                          mortgagee.address?.addressLine2
                            ? `${mortgagee.address?.addressLine1 || ""}, ${
                                mortgagee.address?.addressLine2 || ""
                              }, ${mortgagee.address?.city || ""}, ${
                                mortgagee.address?.county || ""
                              }, ${mortgagee.address?.zip || ""}`
                            : `${mortgagee.address?.addressLine1 || ""}, ${
                                mortgagee.address?.city || ""
                              }, ${mortgagee.address?.county || ""}, ${
                                mortgagee.address?.zip || ""
                              }`
                        }
                        isDisabled
                        _disabled={{
                          color: "black",
                          backgroundColor: "var(--chakra-colors-neutral-50)",
                        }}
                        placeholder="Address"
                        bg={"#e9ecef"}
                      />
                    </GridItem>
                  </Grid>
                )}
                {index !== additionalInsured.length - 1 && <Divider my={4} />}
              </Box>
            ))}
          </Box>
        </div>
      )}
    </>
  );
};
