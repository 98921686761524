import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateInitQuoteApi, pivotApiCall, updateWhichSection, updateLastSection } from "../../../../../../services/floodService";
import { Navigate } from "react-router-dom";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Validation from "../../Validation";
import "./discount.scss";
import "react-overlay-loader/styles.css";
import ModeRouter from "./../../../form-mode/ModeRouter";

const DiscountForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading, whichSection, lastSection , pageMode } = useSelector(state => state.flood);
  const [insuredReside, setInsuredReside] = useState('NO');
  const [isStateSet, setIsStateSet] = useState(false);
  const currentStep = 7;

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setInsuredReside(emptyQuoteData.propertyInfo.s_InsuredResides);
      setIsStateSet(true);
    }
  }, [])

  useEffect(() => {
    if (isStateSet === true) {
      if (emptyQuoteData && initApiObject) {
        const reqJson = { ...initApiObject }; // Copy
        reqJson.primaryResidence = insuredReside;
        // To update
        if (currentStep > lastSection) {
          dispatch(updateWhichSection('DISCOUNT'));
          dispatch(updateLastSection(currentStep));
        }
        reqJson.whichSection = whichSection;
        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }, [insuredReside]);

  useEffect(() => {
    if (emptyQuoteData) {
      // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
      var temp = 'YES';
      if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(emptyQuoteData.propertyInfo.s_Usage)) {
        temp = 'NO'
      }
      emptyQuoteData.propertyInfo.s_InsuredResides = temp
    }
  }, [emptyQuoteData]);

  const handleChange = (e) => {
    props.handleSwitchChange(e);
    var temp = 'NO';
    const { checked } = e.target;
    if (checked) {
      temp = 'YES';
    }
    setInsuredReside(temp)
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      {pageMode ? <ModeRouter /> : 
      (
      <section className="flood-discount-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-discount-form-section"
          >
            <Row className="justify-content-center">
              <Col xs="11">
                <div className="flood-discount-header">
                  <div className="flood-discount-title">
                    <h4 className="flood-discount-main-title">{t('Discounts')}</h4>
                    <p className="flood-discount-main-desc">
                      {t('Watch your premium drop as you add your discounts')}
                    </p>
                  </div>
                  <div className="flood-discount-pricing">
                    <h5 className="flood-discount-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-discount-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div>
                <Row>
                  <Col xs="12">
                    <div
                      className="flood-discount-row"
                      id="discount-form-one-row"
                    >
                      <div className="flood-discount-question-div">
                        <p>
                          {t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}?
                        </p>
                        <div className="flood-discount-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-discount-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_InsuredResides"
                              checked={insuredReside === 'YES' ? true : false}
                              onChange={handleChange}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={7} saveQuoteData={props.saveQuoteData} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      )}
    </LoadingOverlay >
  );
};

export default DiscountForm;
