// import React from "react";
import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { forgotUsername } from "../../services/authService";

const ForgotUsername = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName) {
      toast.error('Please enter first name!');
      return false;
    }
    if (!lastName) {
      toast.error('Please enter last name!');
      return false;
    }
    if (!email) {
      toast.error('Please enter email address!');
      return false;
    }

    const request = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      sourceView: "AGENTPORTAL"
    };
    setLoading(true);
    dispatch(forgotUsername(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          navigate("/login");
        } else {
          setFirstName("");
          setLastName("");
          setEmail("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t("Please Try again"));
      });
  };

  return (
    <>
      <div className="login-page-section">
        <Form>
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="8" lg="6" xl="5">
              <div className="login-page-card">
                {/* <img src={logo} /> */}
                <span className="login-card-title">{t("Forgot Username")}</span>
                <span className="login-card-subtitle">
                  {t("Reset Your User Name")}
                </span>
                <div className="login-card-line-breaker"> </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("First Name")}
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    placeholder=""
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("Last Name")}
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="text"
                    placeholder=""
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("Email")}
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    id="common-btn"
                    variant="primary"
                    type="submit"
                    style={{ padding: "12px" }}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {t("Reset")}
                  </Button>{" "}
                  <Form.Text id="login-page-form-subtext" >
                    <a href={'/login'}>{t('Login Here')}</a>
                  </Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
    </>
  );
};

export default ForgotUsername;
