import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';

const FloodVents = (props) => {
    const { emptyQuoteData, floodSystemDD, validated, isBind, foundationType, handleChange } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isValidFloodOpeningE, setIsValidFloodOpeningE] = useState('');
    const [NoOE, setNoOE] = useState('');
    const [totalOpenAreaE, setTotalOpenAreaE] = useState('');
    const [isCertifiedE, setIsCertifiedE] = useState('');
    const [isValidFloodOpeningC, setIsValidFloodOpeningC] = useState('');
    const [NoOC, setNoOC] = useState('');
    const [totalOpenAreaC, setTotalOpenAreaC] = useState('');
    const [isCertifiedC, setIsCertifiedC] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            if (emptyQuoteData.propertyInfo) {
                if (['ELEVATEDWITHOUTENCLOSURE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(emptyQuoteData.propertyInfo.s_Foundation)) {
                    setIsValidFloodOpeningE(emptyQuoteData.propertyInfo.s_IsProperOpening || null);
                    setNoOE(emptyQuoteData.propertyInfo.n_NoOfOpening || null);
                    setTotalOpenAreaE(emptyQuoteData.propertyInfo.n_TotalinchOfOpenArea || null);
                    setIsCertifiedE(emptyQuoteData.propertyInfo.s_IsEnggVentUsed || null);
                }
                if (['ELEWATEDONCRAWLSPACE20'].includes(emptyQuoteData.propertyInfo.s_Foundation)) {
                    setIsValidFloodOpeningC(emptyQuoteData.propertyInfo.s_IsProperOpening || null);
                    setNoOC(emptyQuoteData.propertyInfo.n_NoOfOpening || null);
                    setTotalOpenAreaC(emptyQuoteData.propertyInfo.n_TotalinchOfOpenArea || null);
                    setIsCertifiedC(emptyQuoteData.propertyInfo.s_IsEnggVentUsed || null);
                }
            }
        }
    }, [emptyQuoteData])

    // update state in reducer
    useEffect(() => {
        var emptyQuote = emptyQuoteData;
        if (Object.keys(emptyQuote).length > 0 && emptyQuote.propertyInfo && (foundationType != emptyQuote.propertyInfo.s_Foundation)) {
            if (['ELEVATEDWITHOUTENCLOSURE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundationType)) {
                emptyQuote['propertyInfo']['s_IsProperOpening'] = null;
                emptyQuote['propertyInfo']['n_NoOfOpening'] = null;
                emptyQuote['propertyInfo']['n_TotalinchOfOpenArea'] = null;
                emptyQuote['propertyInfo']['s_IsEnggVentUsed'] = null;
                setIsValidFloodOpeningE('NO');
                setNoOE(null);
                setTotalOpenAreaE(null);
                setIsCertifiedE(null);
            }
            if (['ELEWATEDONCRAWLSPACE20'].includes(foundationType)) {
                emptyQuote['propertyInfo']['s_IsProperOpening'] = null;
                emptyQuote['propertyInfo']['n_NoOfOpening'] = null;
                emptyQuote['propertyInfo']['n_TotalinchOfOpenArea'] = null;
                emptyQuote['propertyInfo']['s_IsEnggVentUsed'] = null;
                setIsValidFloodOpeningC('NO');
                setNoOC(null);
                setTotalOpenAreaC(null);
                setIsCertifiedC(null);
            }
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }, [foundationType])

    // update state in reducer
    useEffect(() => {
        var emptyQuote = emptyQuoteData;
        if (Object.keys(emptyQuote).length > 0 && emptyQuote.propertyInfo) {
            if (isValidFloodOpeningC !== 'YES' && ['ELEWATEDONCRAWLSPACE20'].includes(foundationType)) {
                emptyQuote['propertyInfo']['s_IsProperOpening'] = null;
                emptyQuote['propertyInfo']['n_NoOfOpening'] = null;
                emptyQuote['propertyInfo']['n_TotalinchOfOpenArea'] = null;
                emptyQuote['propertyInfo']['s_IsEnggVentUsed'] = null;
                setIsValidFloodOpeningC('NO');
                setNoOC(null);
                setTotalOpenAreaC(null);
                setIsCertifiedC(null);
            }
            if (isValidFloodOpeningE !== 'YES' && ['ELEVATEDWITHOUTENCLOSURE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundationType)) {
                emptyQuote['propertyInfo']['s_IsProperOpening'] = null;
                emptyQuote['propertyInfo']['n_NoOfOpening'] = null;
                emptyQuote['propertyInfo']['n_TotalinchOfOpenArea'] = null;
                emptyQuote['propertyInfo']['s_IsEnggVentUsed'] = null;
                setIsValidFloodOpeningE('NO');
                setNoOE(null);
                setTotalOpenAreaE(null);
                setIsCertifiedE(null);
            }
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }, [isValidFloodOpeningC, isValidFloodOpeningE])

    return (
        <Form validated={validated}>
            <Row>
                <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA' }}><i>{t('Valid Flood Openings consist of a minimum of 2 openings, with positioning on at least 2 walls. In the case of a walkout basement the openings may be positioned on a single wall adjacent to the lowest grade next to the building. The bottom of the openings must be within 1 foot of the adjacent grade')}.</i></p>
                {/* Enclosure Venting */}
                {
                    (['ELEVATEDWITHOUTENCLOSURE20', 'ELEVATEDWITHENCLOSURE20', 'ELEVATEDWITHENCLOSURENOTPPP20'].includes(foundationType)) && (
                        <Col md='6' className='p-1'>
                            <Card className='mb-1'>
                                <Card.Body>
                                    <Card.Title><b>{t('Enclosure')}</b></Card.Title>
                                    <hr />
                                    <Row>
                                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Does the enclosure have proper openings')}?
                                                </Form.Label>
                                                <Form.Select
                                                    className="dashboard-group-input-flood"
                                                    id="select-ym-option"
                                                    name="s_IsProperOpening"
                                                    title={t('Does the enclosure have proper openings')}
                                                    parentobject="propertyInfo"
                                                    value={isValidFloodOpeningE}
                                                    onChange={(e) => {
                                                        setIsValidFloodOpeningE(e.currentTarget.value);
                                                        handleChange(e);
                                                    }}
                                                    disabled={isBind}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    {
                                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {
                                        isValidFloodOpeningE === 'YES' &&
                                        <Row>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        {t('Number of openings')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="dashboard-group-input-flood"
                                                        type="number"
                                                        placeholder="000"
                                                        name="n_NoOfOpening"
                                                        title={t('Number of openings')}
                                                        parentobject="propertyInfo"
                                                        value={NoOE || ''}
                                                        min={0}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setNoOE(e.currentTarget.value);
                                                        }}
                                                        autoComplete="off"
                                                        disabled={isBind}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        {t('Total square inches of Open Area')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="dashboard-group-input-flood"
                                                        type="number"
                                                        placeholder="000"
                                                        name="n_TotalinchOfOpenArea"
                                                        title={t('Total square inches of Open Area')}
                                                        parentobject="propertyInfo"
                                                        value={totalOpenAreaE || ''}
                                                        min={0}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setTotalOpenAreaE(e.currentTarget.value);
                                                        }}
                                                        autoComplete="off"
                                                        disabled={isBind}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        <b>a)</b>{t('Were engineered vents used')}?
                                                    </Form.Label>
                                                    <Form.Select
                                                        className="dashboard-group-input-flood"
                                                        id="select-ym-option"
                                                        name="s_IsEnggVentUsed"
                                                        title={t('Were engineered vents used')}
                                                        parentobject="propertyInfo"
                                                        value={isCertifiedE}
                                                        onChange={(e) => {
                                                            setIsCertifiedE(e.currentTarget.value);
                                                            handleChange(e);
                                                        }}
                                                        disabled={isBind}
                                                    >
                                                        <option value={''}>{t('Select')}</option>
                                                        {
                                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
                {/* Crawlspace Venting */}
                {
                    (['ELEWATEDONCRAWLSPACE20'].includes(foundationType)) && (
                        <Col md='6' className='p-1'>
                            <Card className='mb-1'>
                                <Card.Body>
                                    <Card.Title><b>{t('Crawlspace')}</b></Card.Title>
                                    <hr />
                                    <Row>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Does the crawlspace have proper openings')}?
                                                </Form.Label>
                                                <Form.Select
                                                    className="dashboard-group-input-flood"
                                                    id="select-ym-option"
                                                    name="s_IsProperOpening"
                                                    title={t('Does the crawlspace have proper openings')}
                                                    parentobject="propertyInfo"
                                                    value={isValidFloodOpeningC}
                                                    onChange={(e) => {
                                                        setIsValidFloodOpeningC(e.currentTarget.value);
                                                        handleChange(e);
                                                    }}
                                                    disabled={isBind}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    {
                                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {
                                        isValidFloodOpeningC === 'YES' &&
                                        <Row>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        {t('Number of openings')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="dashboard-group-input-flood"
                                                        type="number"
                                                        placeholder="000"
                                                        name="n_NoOfOpening"
                                                        title={t('Number of openings')}
                                                        parentobject="propertyInfo"
                                                        value={NoOC || ''}
                                                        min={0}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setNoOC(e.currentTarget.value);
                                                        }}
                                                        autoComplete="off"
                                                        disabled={isBind}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        {t('Total square inches of Open Area')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="dashboard-group-input-flood"
                                                        type="number"
                                                        placeholder="000"
                                                        name="n_TotalinchOfOpenArea"
                                                        title={t('Total square inches of Open Area')}
                                                        parentobject="propertyInfo"
                                                        value={totalOpenAreaC || ''}
                                                        min={0}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setTotalOpenAreaC(e.currentTarget.value);
                                                        }}
                                                        autoComplete="off"
                                                        disabled={isBind}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                                <Form.Group
                                                    className="search-quote-form-group"
                                                    controlId="formBasicEmail"
                                                >
                                                    <Form.Label className="dashboard-group-label">
                                                        <b>a)</b>{t('Were engineered vents used')}?
                                                    </Form.Label>
                                                    <Form.Select
                                                        className="dashboard-group-input-flood"
                                                        id="select-ym-option"
                                                        name="s_IsEnggVentUsed"
                                                        title={t('Were engineered vents used')}
                                                        parentobject="propertyInfo"
                                                        value={isCertifiedC}
                                                        onChange={(e) => {
                                                            setIsCertifiedC(e.currentTarget.value);
                                                            handleChange(e);
                                                        }}
                                                        disabled={isBind}
                                                    >
                                                        <option value={''}>{t('Select')}</option>
                                                        {
                                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                }
            </Row>
        </Form >
    )
}

export default FloodVents;