import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  Button,
  VStack,
  useColorModeValue,
  Flex,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { FaMoneyBillWave, FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

const PaymentModal = ({ isOpen, onClose, isBind, quoteData }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const accentColor = useColorModeValue("blue.500", "blue.300");

  const copyQuoteNumber = (quoteNumber) => {
    navigator.clipboard.writeText(quoteNumber).then(
      function () {
        toast.success("Copied to clipboard!");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="blackAlpha.700" />
      <ModalContent
        bg={bgColor}
        color={textColor}
        borderRadius="lg"
        boxShadow="lg"
      >
        <ModalHeader
          fontSize="4xl"
          fontWeight="bold"
          textAlign="center"
          pt={10}
          pb={6}
        >
          <Icon as={FaMoneyBillWave} color={accentColor} mr={2} />
          Payment Information
        </ModalHeader>
        <ModalCloseButton
          size="lg"
          onClick={() => {
            if (!isBind) {
              window.location.reload();
            }
          }}
        />
        <Divider borderColor={accentColor} />
        <ModalBody py={8}>
          <VStack spacing={8} align="stretch">
            <Box>
              <Text fontSize="2xl" mb={4} textAlign="center">
                Please make your check payable to{" "}
                <Text as="span" fontWeight="bold">
                  StarLight Insurance Group
                </Text>{" "}
                and include your policy number :{" "}
                {quoteData?.policyInfo?.Policy_No || ""}
                {quoteData?.policyInfo?.Policy_No && (
                  <Button
                    ml={2}
                    size={"sm"}
                    variant={"outline"}
                    onClick={() =>
                      copyQuoteNumber(quoteData?.policyInfo?.Policy_No)
                    }
                  >
                    <FaRegCopy />
                  </Button>
                )}
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Mailing Address:
              </Text>
              <Text fontSize="xl">PO Box 2057</Text>
              <Text fontSize="xl">Kalispell, MT 59903</Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Express Overnight Address (for Mortgagees & Late Payments):
              </Text>
              <Text fontSize="xl">Flood Processing Center</Text>
              <Text fontSize="xl">555 Corporate Drive, Suite 101</Text>
              <Text fontSize="xl">Kalispell, MT 59901</Text>
            </Box>
          </VStack>
          <Flex justifyContent="center" mt={10}>
            <Button
              colorScheme="blue"
              size="lg"
              fontWeight="bold"
              px={8}
              py={6}
              borderRadius="full"
              boxShadow="md"
              _hover={{ boxShadow: "lg" }}
              onClick={() => {
                onClose();
                if (!isBind) {
                  window.location.reload();
                }
              }}
            >
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
