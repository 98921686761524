import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Validation from "../../Validation";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { updateInitQuoteApi, pivotApiCall } from "../../../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Navigate } from "react-router-dom";
import "react-overlay-loader/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "./discount.scss";
import { getEditEmptyQuoteData } from "../../../../../../services/floodService";

const DiscountFormTwo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, apiLoading, floodSystemDD } = useSelector(state => state.flood);
  const [showFields, setShowFields] = useState(false);
  // const [validated, setValidated] = useState(false);
  const [isValidEC, setIsValidEC] = useState(false);
  const [certDate, setCertDate] = useState(null);
  const [diagramNo, setDiagramNo] = useState(false);
  const [topBottomFloor, setTopBottomFloor] = useState(false);
  const [LAG, setLAG] = useState(false);
  const [topHigherFloor, setTopHigherFloor] = useState(false);
  const [isStateSet, setIsStateSet] = useState(false);
  const [sectionForCertificate, setSectionForCertificate] = useState('');
  const [tHFMeasure, setTHFMeasure] = useState('');
  const [tBFMeasure, setTBFMeasure] = useState('');
  const [ToBFAboveBelow, setToBFAboveBelow] = useState('');
  const [THFAboveBelow, setTHFAboveBelow] = useState('');

  const tenant = window.location.host.split('.')[0];

  // set Initial State from reducer and get NAIC
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setIsValidEC(emptyQuoteData.propertyInfo.s_isValidElevationCert);
      if(props.checkDateisValid(emptyQuoteData.propertyInfo.d_CertificateDate)) {
        setCertDate(new Date(emptyQuoteData.propertyInfo.d_CertificateDate));
      }
      setDiagramNo(emptyQuoteData.propertyInfo.s_BuildingDiagramNoCode);
      setTopBottomFloor(emptyQuoteData.propertyInfo.n_TopBottomFloor);
      setLAG(emptyQuoteData.propertyInfo.n_LowestAdjacent);
      setTopHigherFloor(emptyQuoteData.propertyInfo.n_TopHigherFloor);
      setSectionForCertificate(emptyQuoteData.propertyInfo.s_SectionCompleted || null);
      setTHFMeasure(emptyQuoteData.propertyInfo.s_THFMeasure || null);
      setTBFMeasure(emptyQuoteData.propertyInfo.s_TBFMeasure || null);
      setTHFAboveBelow(emptyQuoteData.propertyInfo.s_THFAboveBelow || null);
      setToBFAboveBelow(emptyQuoteData.propertyInfo.s_TBFAboveBelow || null);

      setIsStateSet(true);
    }
  }, []);

  useEffect(() => {
    if (isValidEC === 'NO' && isStateSet === true) {
      const emptyQuote = { ...emptyQuoteData }; // Copy
      emptyQuote.propertyInfo.d_CertificateDate = '';
      emptyQuote.propertyInfo.s_BuildingDiagramNoCode = '';
      emptyQuote.propertyInfo.n_TopBottomFloor = '';
      emptyQuote.propertyInfo.n_LowestAdjacent = '';
      emptyQuote.propertyInfo.n_TopHigherFloor = '';
      emptyQuote.propertyInfo.s_THFMeasure = '';
      emptyQuote.propertyInfo.s_TBFMeasure = '';
      emptyQuote.propertyInfo.s_TBFAboveBelow = '';
      emptyQuote.propertyInfo.s_THFAboveBelow = '';

      const reqJson = { ...initApiObject }; // Copy
      reqJson.ecDate = '';
      reqJson.ecDiagramNumber = '';
      reqJson.topBottomFloor = '';
      reqJson.LAG = '';
      reqJson.topHigherFloor = '';
      reqJson.sectionForCertificate = '';
      reqJson.tHFMeasure = 'FEET'
      reqJson.tBFMeasure = 'FEET'
      reqJson.TBFAboveBelow = ''

      reqJson.whichSection = 'DISCOUNT';

      // To update
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [isValidEC])

  const callPivotApi = () => {
    if (isStateSet === true) {
      // Trina - LAG is not required and only need for section C
      if (isValidEC === 'YES' && ![certDate, sectionForCertificate, diagramNo, topBottomFloor, topHigherFloor].some(val => (val === null || val === '' || val === undefined))) {
        const reqJson = { ...initApiObject }; // Copy
        reqJson.isValidEC = isValidEC
        reqJson.ecDate = certDate
        reqJson.ecDiagramNumber = diagramNo
        reqJson.topBottomFloor = topBottomFloor
        reqJson.LAG = LAG
        reqJson.topHigherFloor = topHigherFloor
        reqJson.sectionForCertificate = sectionForCertificate
        reqJson.tHFMeasure = tHFMeasure
        reqJson.tBFMeasure = tBFMeasure
        reqJson.TBFAboveBelow = ToBFAboveBelow
        reqJson.whichSection = 'DISCOUNT';

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }

  /*const toggle = (e) => {
    setValidated(e);
  }*/

  // handle switch Change
  const handleSwitchChange = (e) => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    setIsValidEC(temp);
    props.handleSwitchChange(e);
  }

  const handleEnterInput = (e) => {
    if (e.key === "Enter") {
      setIsValidEC(e.target.checked ? "NO" : "YES");
    }
  };
  const handleClear=()=>{
    setTHFAboveBelow('');
    setToBFAboveBelow('');
    setTHFMeasure('');
    setTBFMeasure('');
    setTopBottomFloor(0);
    setTopHigherFloor(0);
    setLAG(0);

    const quoteDataVar={...emptyQuoteData}
    quoteDataVar.propertyInfo.s_THFAboveBelow='';
    quoteDataVar.propertyInfo.s_TBFAboveBelow='';
    quoteDataVar.propertyInfo.s_THFMeasure='';
    quoteDataVar.propertyInfo.s_TBFMeasure='';
    quoteDataVar.propertyInfo.n_TopBottomFloor='';
    quoteDataVar.propertyInfo.n_TopHigherFloor='';
    quoteDataVar.propertyInfo.n_LowestAdjacent='';
    dispatch(getEditEmptyQuoteData(quoteDataVar))
  }

  return (
    <LoadingOverlay >
      <Loader loading={apiLoading} />
      <section className="flood-discount-section">
        {
          Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
        }
        {/* Form */}
        {/* <Row className="justify-content-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="8"
            xl="8"
            className="flood-discount-form-section"
          >
            <Row className="justify-content-center"> */}
              <Col xs="12" className="mt-4">
                {/* <div className="flood-discount-header">
                  <div className="flood-discount-title">
                    <h4 className="flood-discount-main-title">{t('Discounts')}</h4>
                    <p className="flood-discount-main-desc">
                      {t('Watch your premium drop as you add your discounts')}
                    </p>
                  </div>
                  <div className="flood-discount-pricing">
                    <h5 className="flood-discount-pricing-title">
                      {t('Current Premium')}
                    </h5>
                    <p className="flood-discount-pricing-value">
                      {'$' + props.premium}<span>/{t('Year')}</span>
                    </p>
                  </div>
                </div> */}
                <Row>
                  {/* {!showFields && (
                    <Col xs="12">
                      {tenant == 'farmers' ? <div className="discount-healthIcon"></div> : <div className="discount-health-icon"></div>}
                    </Col>
                  )} */}
                  <Col xs="12">
                    <div
                      className="flood-discount-row"
                      id="flood-discount-one-row"
                    >
                      <div className="flood-discount-question-div">
                        <p>{t('Do you have an Elevation Certificate (may lower your premium)')}?</p>
                        <div className="flood-discount-row-actions">
                          <span>{t('No')}</span>
                          <div className="custom-main-swtich">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              className="flood-discount-row-switch"
                              label=""
                              parentobject="propertyInfo"
                              name="s_isValidElevationCert"
                              onChange={handleSwitchChange}
                              checkedvalue='YES'
                              uncheckedvalue='NO'
                              checked={isValidEC === 'YES' ? true : false}
                              onClick={() => setShowFields(!showFields)}
                              onKeyDown={handleEnterInput}
                            />
                          </div>
                          <span>{t('Yes')}</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {(isValidEC === "YES") && (
                  <Form validated={props.validated}>
                    <Row>
                      <Col xs="12">
                        <Row>
                          <Col xs="12" sm="12" md="7" lg="7" xl="7">
                            <Form.Group
                              className="discount-form-two-form"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="myform-label">
                                {t('Certificate Signature Date')}
                              </Form.Label>
                              <DatePicker
                                id="flood-discount-date-picker"
                                className="custom-info-date-picker"
                                placeholderText="MM/DD/YYYY"
                                selected={certDate || ''}
                                onChange={(date) => {
                                  props.handleDateChange(date, 'd_CertificateDate', 'propertyInfo');
                                  setCertDate(date);
                                  callPivotApi()
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="5" xl="5">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Please select the section of the elevation certificate that has been completed')}:
                                    </Form.Label>
                                    <Form.Select
                                        className="table-show-entries-select"
                                        name="s_SectionCompleted"
                                        title={t('Please select the section of the elevation certificate that has been completed')}
                                        parentobject="propertyInfo"
                                        value={sectionForCertificate}
                                        onBlur={callPivotApi}
                                        onChange={(e) => {
                                            setSectionForCertificate(e.currentTarget.value);
                                            props.handleChange(e);
                                            handleClear()
                                        }}
                                        required
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECCOMPLETEDSECTION.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                  <Row className="align-items-baseline">
                    <Col xs="12" sm="12" md="5" lg="5" xl="5">
                      <Form.Group
                        className="discount-form-two-form"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="myform-label">
                          {t('Diagram Number')}
                        </Form.Label>
                        <Form.Select 
                          className="myform-input"
                          name="s_BuildingDiagramNoCode"
                          title={t('Diagram Number')}
                          parentobject="propertyInfo"
                          value={diagramNo || ''}
                          onBlur={callPivotApi}
                          onChange={(e) => {
                            props.handleChange(e);
                            setDiagramNo(e.currentTarget.value);
                          }}
                          required
                        >
                          <option value={''}>{t('Select')}</option>
                          {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECBUILDDIAGRAMNO.map((v, i) => {
                              return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                          }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {t("Please Select Diagram Number Error")}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                        <Row className="align-items-baseline">
                          
                          <Col xs="12" sm="12" md="7" lg="6" xl="6">
                            <Form.Group
                              className="discount-form-two-form"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="myform-label">
                                {t('Top of bottom floor')}{" "}
                                <span>
                                  {t('(basement, crawlspace or enclosure floor)')}
                                </span>
                              </Form.Label>
                              <Form.Control
                                className="myform-input"
                                type="number"
                                placeholder={t("feet")}
                                name="n_TopBottomFloor"
                                title={t('(basement, crawlspace or enclosure floor)')}
                                parentobject="propertyInfo"
                                value={topBottomFloor || ''}
                                onBlur={callPivotApi}
                                min={0}
                                onChange={(e) => {
                                  props.handleChange(e);
                                  setTopBottomFloor(e.currentTarget.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                              className="search-quote-form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="dashboard-group-label">
                                {t('Measure')}
                              </Form.Label>
                              <Form.Select
                                className="table-show-entries-select"
                                name="s_TBFMeasure"
                                title={t('Measure')}
                                parentobject="propertyInfo"
                                value={tBFMeasure}
                                onChange={(e) => {
                                  props.handleChange(e);
                                  setTBFMeasure(e.currentTarget.value);
                                }}
                              >
                                <option value={''}>{t('Select')}</option>
                                {
                                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECFEETMETERS &&
                                  floodSystemDD.FLOODECFEETMETERS.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                  })
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          {
                            ["E", "H"].includes(sectionForCertificate) && (
                            <>
                              <Col xs="12" sm="12" md="12" lg="3" xl="3">
                                <Form.Group
                                  className="search-quote-form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="dashboard-group-label">
                                    {t('Above/Below LAG')}
                                  </Form.Label>
                                  <Form.Select
                                    className="table-show-entries-select"
                                    name="s_TBFAboveBelow"
                                    title=''
                                    parentobject="propertyInfo"
                                    value={ToBFAboveBelow}
                                    onBlur={callPivotApi}
                                    onChange={(e) => {
                                      props.handleChange(e);
                                      setToBFAboveBelow(e.currentTarget.value);
                                    }}
                                  >
                                    <option value={''}>{t('Select')}</option>
                                    {
                                      Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECHAGLAG.map((v, i) => {
                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                      })
                                    }
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </>
                            )
                          }
                        </Row>
                        <Row className="align-items-baseline">
                          <Col xs="12" sm="12" md="5" lg="6" xl="6">
                            <Form.Group
                              className="discount-form-two-form"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="myform-label">
                                {t('Top of the next higher floor')}
                              </Form.Label>
                              <Form.Control
                                className="myform-input"
                                type="number"
                                placeholder={t("feet")}
                                name="n_TopHigherFloor"
                                title={t('Top of the next higher floor')}
                                parentobject="propertyInfo"
                                value={topHigherFloor || ''}
                                onBlur={callPivotApi}
                                min={0}
                                onChange={(e) => {
                                  props.handleChange(e);
                                  setTopHigherFloor(e.currentTarget.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                              className="search-quote-form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="dashboard-group-label">
                                {t('Measure')}
                              </Form.Label>
                              <Form.Select
                                className="table-show-entries-select"
                                name="s_THFMeasure"
                                title=''
                                parentobject="propertyInfo"
                                value={tHFMeasure}
                                onChange={(e) => {
                                  setTHFMeasure(e.currentTarget.value);
                                  props.handleChange(e);
                                }}
                              >
                                <option value={''}>{t('Select')}</option>
                                {
                                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECFEETMETERS &&
                                  floodSystemDD.FLOODECFEETMETERS.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                  })
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                    {
                      ["E", "H"].includes(sectionForCertificate) && (
                        <>
                          <Col xs="12" sm="12" md="12" lg="3" xl="3">
                            <Form.Group
                              className="search-quote-form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="dashboard-group-label">
                                {t('Above/Below HAG')}
                              </Form.Label>
                              <Form.Select
                                className="table-show-entries-select"
                                name="s_THFAboveBelow"
                                title=''
                                parentobject="propertyInfo"
                                value={THFAboveBelow}
                                onBlur={callPivotApi}
                                onChange={(e) => {
                                  setTHFAboveBelow(e.currentTarget.value);
                                  props.handleChange(e);
                                }}
                              >
                                <option value={''}>{t('Select')}</option>
                                {
                                  Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECHAGLAG.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                  })
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </>
                      )
                    }
                        </Row>
                        {
                          sectionForCertificate == 'C' && (
                        <Row className="align-items-baseline">
                        <Col xs="12" sm="12" md="7" lg="6" xl="6">
                            <Form.Group
                              className="discount-form-two-form"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="myform-label" style={{lineHeight:"25px"}}>
                                {t('Lowest adjacent (finished) grade next to building (LAG)')}
                              </Form.Label>
                              <Form.Control
                                className="myform-input"
                                type="number"
                                placeholder={t("feet")}
                                name="n_LowestAdjacent"
                                title={t('Lowest adjacent (finished) grade next to building (LAG)')}
                                parentobject="propertyInfo"
                                value={LAG || ''}
                                onBlur={callPivotApi}
                                min={0}
                                onChange={(e) => {
                                  props.handleChange(e);
                                  setLAG(e.currentTarget.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                          )
                        }
                      </Col>
                    </Row>
                  </Form>
                )}
                {/* <Row>
                  <Col xs="12" className="form-btns">
                    <Validation currentStep={9} toggle={toggle} />
                  </Col>
                </Row> */}
              </Col>
            {/* </Row>
          </Col>
        </Row> */}
      </section>
    </LoadingOverlay >

  );
};

export default DiscountFormTwo;
