import Http from "../../../../../Http";

export async function getPrintQuoteDoc(transactionPolicyNo, printCode) {
  try {
    const url = `/api/reports/generate/${printCode}/${transactionPolicyNo}`;
    const response = await Http.get(url);
    return response.data;
  } catch (err) {
    console.error(
      "Error in getPrintQuoteDoc:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
