import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaUpload, FaWindowClose } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { toast } from 'react-toastify';
import { generateTempUrl, getDocList } from "../../../../services/coreSystemService";
import BootstrapTable from "react-bootstrap-table-next";
import AddAttachment from "./AddAttachment";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'moment';
import "react-overlay-loader/styles.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "../../../../pages/activity-log/inbox/inbox.scss";

const ListAttachment = (props) => {
    const { policyNumber, policyPK } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [textLable, setTextLable] = useState('Add New');
    const [tableData, setTableData] = useState([]);
    const [showAddAttachment, setShowAddAttachment] = useState(false);
    const [text, setText] = useState('Document List');
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        "pageLength": 10,
        "page": 1,
        "sourceId": policyPK,
        "module": "Policy",
        "submodule": "ATTACHMENTS_ADD_POLICY",
        "searchKey": ""
    });

    // get data
    useEffect(() => {
        if (showAddAttachment === false) {
            searchActivityList(filter);
        }
    }, [showAddAttachment])

    const searchActivityList = async (filter) => {
        setLoading(true);
        await dispatch(getDocList(filter)).then((response) => {
            setTableData(response.data || []);
            setTotal(response.total)
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        })
        setLoading(false);
    };

    // generate Temp url for document
    const getTempUrl = async (row) => {
        var param = {
            id: row.DocInfo_PK,
            type: row.systemSource ?? 'NEW_SYSTEM',
            module: 'Policy',
        }
        setLoading(true);
        await dispatch(generateTempUrl(param, 'CORE')).then((url) => {
            if (![null, ''].includes(url)) {
                window.open(url);
            } else {
                toast.error(t('File Not Found!'));
            }
        }).catch(() => {
            toast.error(t('Something Went Wrong!'));
        })
        setLoading(false);
    }

    const handleOnclick = (value) => {
        var label = (value === true ? 'Close' : 'Add New')
        var text = (value === true ? 'Add New' : 'Document List')
        setShowAddAttachment(value);
        setTextLable(label);
        setText(text);
    };

    const renderFormatter = (value, row) => {
        if(row.systemSource==='FloodPro'){
            return ( <a
            className="management-reports-link"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                   window.open(row.DocURL)
                }
            }>  {value}</a>)
        }
        else{
            return (

                <a
                    className="management-reports-link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if (![null, '', undefined].includes(row.DocInfo_PK)) {
                            getTempUrl(row);
                        }
                    }}
                >
                    {value}
                </a>
            )
        }
       
    };

    const formateDate = (date) => {
        return (
            Moment(date).format('YYYY-MM-DD hh:mm A')
        )
    };

    const headerWrapper = { whiteSpace: 'pre-wrap' };
    const wordWrapper = { whiteSpace: 'pre-wrap', wordBreak: 'break-all', fontSize: 'small' };

    const columns = [
        {
            dataField: "s_UserModuleName",
            text: t("Module Name"),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
        {
            dataField: "s_DocTypeNameForDisplay",
            text: t("Doc Type"),
            style: { fontSize: 'small' }
        },
        {
            dataField: "Doc_OriginalName",
            text: t("File Name"),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
        {
            dataField: "DocNameAsUploded",
            text: t("Doc Original Name"),
            formatter: (value, row) => renderFormatter(value, row),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
        {
            dataField: "UploadDate",
            text: t("Upload Date"),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
        {
            dataField: "s_ScreenName",
            text: t("Uploaded User"),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
        {
            dataField: "systemSource",
            text: t("Source"),
            headerStyle: headerWrapper,
            style: wordWrapper
        },
    ];

    const renderShowsTotal = (start, to, total) => {
        return (
            <span style={{ paddingLeft: 10 }}>{t("Showing")} {start} {t('to')} {to} {t('of')} {total} {t('Results')}</span>
        )
    };

    const onTableChange = (type, { page, sizePerPage }) => {
        var filterArray = { ...filter, page: page, pageLength: sizePerPage };
        searchActivityList(filterArray);
        setFilter(filterArray);
    };

    const options = {
        page: filter.page,
        sizePerPage: filter.pageLength,
        sortIndicator: false,
        pageStartIndex: 1,
        totalSize: total,
        showTotal: true,
        paginationTotalRenderer: renderShowsTotal,
    };

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <div>
                <Row>
                    <Col>
                        <div
                            className="inbox-table"
                            style={{ marginTop: 0 }}
                            id="inbox-table-outer-div"
                        >
                            <div className="card-header-main">
                                <div className="card-header-main-title">
                                    <span className="card-header-main-text">{t(text)}</span>
                                </div>
                                <div className="card-header-btns-div">
                                    <Button
                                        id="common-outline-btn"
                                        className="card-header-btn0"
                                        variant="outline-primary"
                                        onClick={() => handleOnclick(!showAddAttachment)}
                                    >
                                        {
                                            textLable !== 'Close' ?
                                                <FaUpload size={15} />
                                                :
                                                <FaWindowClose size={15} />
                                        }{' '}
                                        {t(textLable)}
                                    </Button>{" "}
                                </div>
                            </div>
                            {
                                showAddAttachment && (
                                    <AddAttachment
                                        policyNumber={policyNumber}
                                        policyPK={policyPK}
                                    />
                                )
                            }
                            {
                                !showAddAttachment && (
                                    <Col>
                                        <BootstrapTable
                                            bootstrap4
                                            remote
                                            keyField="DocInfo_PK"
                                            bordered={false}
                                            data={tableData}
                                            columns={columns}
                                            hover
                                            pagination={paginationFactory(options)}
                                            noDataIndication={t("No data to show.")}
                                            onTableChange={onTableChange}
                                        />
                                    </Col>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </LoadingOverlay>
    );
};

export default ListAttachment;
