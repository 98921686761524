import Http from "../../../../Http";

export async function getHiscoxQuoteDetail({ quoteNo }) {
  try {
    const url = `/api/v2/quote/hiscox/${quoteNo}`;
    const response = await Http.get(url);
    return response.data;
  } catch (err) {
    console.error("Error fetching base data:", err);
  }
}
