import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEditEmptyQuoteData, updateInitQuoteApi } from "../../../../../../services/floodService";
import DatePicker from "react-datepicker";
import Validation from "../../Validation";
import Select from 'react-select';
import { customStyles } from "../../../../../../components/reactSelectCustomStyle";
import "react-datepicker/dist/react-datepicker.css";
import "./waiting-period.scss";
import ModeRouter from "./../../../form-mode/ModeRouter";
import Moment from 'moment';

const WaitingPeriod = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData, initApiObject, floodSystemDD ,pageMode} = useSelector(state => state.flood);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [waitingPeriod, setWaitingPeriod] = useState('STANDARD30');
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isWPChange, setIsWPChange] = useState(false);
  const [NAICList, setNAICList] = useState({});
  const [PPED, setPPED] = useState(null);
  const [priorPolicyNo, setPriorPolicyNo] = useState('');
  const [priorCompanyNo, setPriorCompanyNo] = useState('');

  // set initial state from reducer
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      if (props.checkDateisValid(emptyQuoteData.effectiveDate)) {
        setEffectiveDate(new Date(Moment(emptyQuoteData.effectiveDate)));
      }
      // update date if null
      if ([null, ''].includes(emptyQuoteData.effectiveDate)) {
        var tempCurrDate = new Date();
        var date = tempCurrDate.setDate(tempCurrDate.getDate() + 30);
        let effectiveDate = new Date(Moment(date))
        props.handleDateChange(effectiveDate, 'effectiveDate');
        setEffectiveDate(effectiveDate);
      }
      if (![null, ''].includes(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod)) {
        setWaitingPeriod(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod);
        updateReducerDataBasedOnWP(emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod);
      }
      if (props.checkDateisValid(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt)) {
        setPPED(new Date(emptyQuoteData.propertyInfo.s_PriorPolicyExpDt) || null);
        setPriorPolicyNo(emptyQuoteData.propertyInfo.s_PriorPolicyNo || null);
        setPriorCompanyNo(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC || null);
      }

      if (Object.keys(floodSystemDD).length > 0) {
        setNAICList(floodSystemDD.WYOList);
      }
    }
  }, [])

  // trigger on update waiting period to set effective date based on waiting period type
  useEffect(() => {
    var date = new Date(); // current date
    var tempCurrDate = new Date(); // copy of current Date
    var minDate = '', maxdate = '';
    if (waitingPeriod === "STANDARD30" || waitingPeriod === "LENDER30") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 30); // ADD 30 DAYS
      minDate = date;
    } else if (waitingPeriod === "MAPREVONEDAY") {
      date = tempCurrDate.setDate(tempCurrDate.getDate() + 1); // ADD 1 DAYS
      minDate = date;
    } else if (waitingPeriod === "LOANNOWAIT") {
      // Not For Risn Rating 2.0
      if (!props.isRiskRating2) {
        minDate = date;
        maxdate = tempCurrDate.setDate(tempCurrDate.getDate() + 60); // ADD 60 DAYS
      }
    } else if (waitingPeriod === "ROLLOVER_RENEWAL") {
      var temp = new Date(); // current date
      minDate = tempCurrDate.setDate(tempCurrDate.getDate() - 29); // SUB 29 DAYS
      maxdate = temp.setDate(temp.getDate() + 90); // ADD 90 DAYS
    }

    setMinDate(minDate); // setting min date
    setMaxDate(maxdate) // setting max date
    if (isWPChange) {
      setIsWPChange(false);
      var effDate = new Date(Moment(date));
      if (waitingPeriod === "ROLLOVER_RENEWAL") {
        effDate = '';
        setPPED(effDate);
        props.handleDateChange(effDate, 's_PriorPolicyExpDt', 'propertyInfo');
      }
      setEffectiveDate(effDate); // setting effective date
      props.handleDateChange(effDate, 'effectiveDate');
      if (waitingPeriod === "LOANNOWAIT") {
        props.handleDateChange(effDate, 'd_FloodLoanClosingDt', 'propertyInfo');
      }
    }
  }, [waitingPeriod])

  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0 && Object.keys(initApiObject).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...emptyQuoteData };
      emptyQuote.transactionInfo.d_TransEffectiveFrom = emptyQuoteData.effectiveDate

      // For Pivot Api Object Update
      const reqJson = { ...initApiObject }; // Copy
      reqJson.effectiveDate = emptyQuoteData.effectiveDate

      dispatch(getEditEmptyQuoteData(emptyQuote));
      dispatch(updateInitQuoteApi(reqJson));

      var loanClosingDate = null, priorPolixyExDate = null;
      if (['LOANNOWAIT'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        loanClosingDate = effectiveDate;
      }
      if (['ROLLOVER_RENEWAL'].includes(waitingPeriod) && props.checkDateisValid(effectiveDate)) {
        priorPolixyExDate = effectiveDate;
      }
      props.handleDateChange(loanClosingDate, 'd_FloodLoanClosingDt', 'propertyInfo');
      props.handleDateChange(priorPolixyExDate, 's_PriorPolicyExpDt', 'propertyInfo');
    }
  }, [effectiveDate]);

  const toggle = (e) => {
    setValidated(e);
  }

  // update state in reducer
  const updateReducerState = (value, type) => {
    var emptyQuote = emptyQuoteData, reqJson = { ...initApiObject }; // Copy
    if(type === 'PPN'){
      setPriorPolicyNo(value);
      emptyQuote['propertyInfo']['s_PriorPolicyNo'] = value;
      reqJson.priorPolicyNumber = value;
      props.handleUpdateArray('propertyInfo', 's_PriorPolicyNo', value);
    } else {
      setPriorCompanyNo(value.s_NAICNumber);
      emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = value.s_NAICNumber;
      reqJson.priorNaicNumber = value.s_NAICNumber;
      props.handleUpdateArray('propertyInfo', 's_PriorCompanyNAIC', value.s_NAICNumber);
    }
    dispatch(getEditEmptyQuoteData(emptyQuote));
    dispatch(updateInitQuoteApi(reqJson));
  }

  /**
   * Null Prior Policy no and NAIC if WP != ROLLOVER_RENEWAL(Transfer)
   * @param {String} waitingPeriod 
   */
  const updateReducerDataBasedOnWP = (waitingPeriod) => {
    if (Object.keys(emptyQuoteData).length > 0 && Object.keys(initApiObject).length > 0) {
      // For Quote Details Object Update
      const emptyQuote = { ...emptyQuoteData }, reqJson = { ...initApiObject }; // Copy
      emptyQuote.propertyInfo.s_PolicyWaitingPeriod = waitingPeriod
      var temp = 'YES', newRolloverTransferType = 'N';
      if (waitingPeriod == "ROLLOVER_RENEWAL") {
        temp = 'NO'; newRolloverTransferType = 'T';
      } else {
        emptyQuote['propertyInfo']['s_PriorPolicyNo'] = null;
        emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = null;
        setPriorPolicyNo(null);
        setPriorCompanyNo(null);
      }
      emptyQuote.propertyInfo.s_IsNewPurchase = temp
      dispatch(getEditEmptyQuoteData(emptyQuote));

      // For Pivot Api Object Update
      reqJson.newRolloverTransferType = newRolloverTransferType;
      dispatch(updateInitQuoteApi(reqJson));
    }
  }

  return (
    <div>
      {pageMode ? <ModeRouter /> : 
      (
    <section className="waiting-period-section">
      {
        Object.keys(emptyQuoteData).length === 0 && <Navigate to={'/flood/instantquote/step/address'} />
      }
      {/* Form */}
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="12"
          md="10"
          lg="8"
          xl="8"
          className="waiting-period-form"
        >
          <Row className="justify-content-center" id="waiting-period-div">
            <Col xs="12" sm="10" md="10" lg="10" xl="10">
              <h1>{t('Waiting Period')}</h1>
              <Row className="justify-content-start">
                <Col xs="12">
                  <Form validated={validated} className="confirm-address-form">
                    <Row>
                      <Col xs="12">
                        <Form.Group
                          className="confirm-address-field"
                          controlId="formBasicEmail"
                        >
                          <Form.Label className="confirm-address-label">
                            {t('Waiting Period')}
                          </Form.Label>
                          <Form.Select
                            className="confirm-address-input"
                            name="s_PolicyWaitingPeriod"
                            title={t('Waiting Period')}
                            parentobject="propertyInfo"
                            value={waitingPeriod}
                            onChange={(e) => {
                              setWaitingPeriod(e.currentTarget.value);
                              updateReducerDataBasedOnWP(e.currentTarget.value);
                              props.handleUpdateArray('propertyInfo', 's_PolicyWaitingPeriod', e.currentTarget.value);
                              setIsWPChange(true);
                            }}
                            required
                          >
                            <option value={''}>{t('Select')}</option>
                            {
                              Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODWAITINGPERIOD.map((v, i) => {
                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t("Please Select Waiting Period Error")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      {
                        waitingPeriod === "ROLLOVER_RENEWAL" && (
                          <>
                            <Col xs="12">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Prior Policy No')}
                                </Form.Label>
                                <Form.Control
                                  className="dashboard-group-input-flood"
                                  type="text"
                                  placeholder="0000000000"
                                  name="s_PriorPolicyNo"
                                  title={t('Policy NO#')}
                                  parentobject="propertyInfo"
                                  value={priorPolicyNo || ''}
                                  min={0}
                                  onChange={(e) => {
                                    // props.handleChange(e);
                                    updateReducerState(e.currentTarget.value, 'PPN');
                                  }}
                                  required
                                />
                                <Form.Control.Feedback type="invalid">
                                  {t("Please Enter Prior Policy No")}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xs="12">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Prior Company NAIC')}
                                </Form.Label>
                                {
                                  Object.keys(NAICList).length > 0 && (
                                    <Select
                                      styles={customStyles}
                                      title="propertyInfo"
                                      name="s_PriorCompanyNAIC"
                                      options={NAICList}
                                      value={NAICList.filter(item => item.s_NAICNumber === priorCompanyNo)}
                                      getOptionLabel={(option) => option.s_WYOCompany}
                                      getOptionValue={(option) => option.s_NAICNumber}
                                      onChange={(value) => updateReducerState(value, 'NAIC')}
                                    />
                                  )
                                }
                              </Form.Group>
                            </Col>
                            <Col xs="12">
                              <Form.Group
                                className="confirm-address-field"
                                controlId="formBasicEmail"
                              >
                                <Form.Label className="confirm-address-label">
                                  {t('Prior Policy Exp')}
                                </Form.Label>
                                <DatePicker
                                  className="custom-info-date-picker"
                                  placeholderText="MM/DD/YYYY"
                                  selected={PPED}
                                  onChange={(date) => {
                                    setPPED(date);
                                    setEffectiveDate(date);
                                    props.handleDateChange(date, 's_PriorPolicyExpDt', 'propertyInfo');
                                    props.handleDateChange(date, 'effectiveDate');
                                  }}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  autoComplete="off"
                                />
                              </Form.Group>
                            </Col>
                          </>
                        )
                      }
                      <Col xs="12">
                        <Form.Group
                          controlId="formBasicPassword"
                          className="confirm-address-field"
                        >
                          <Form.Label className="confirm-address-label">
                            {waitingPeriod === 'LOANNOWAIT' ? t('Loan Closing Date') : t('Effective Date')}
                          </Form.Label>
                          <DatePicker
                            className="custom-info-date-picker"
                            placeholderText="MM/DD/YYYY"
                            selected={effectiveDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(date) => {
                              setEffectiveDate(date);
                              props.handleDateChange(date, 'effectiveDate');
                            }}
                            disabled={waitingPeriod === 'ROLLOVER_RENEWAL' ? true : false}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
            <Col xs="12" className="contact-form-btns">
              <Validation currentStep={4} toggle={toggle} saveQuoteData={props.saveQuoteData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
      )}
      </div>
  );
};

export default WaitingPeriod;
