import { Box, Button, Flex } from "@chakra-ui/react";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { useQuote } from "../../CommCreateQuote";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Footer = () => {
  const {
    activeStep,
    setActiveStep,
    steps,
    masterQuoteData,
    saveMortgagee,
    hasMortagee,
    confirmation,
    validationError,
    setValidationError,
    agreements,
    selectedCard
  } = useQuote();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validateMortagee = () => {
    if (
      hasMortagee &&
      Array.isArray(saveMortgagee) &&
      saveMortgagee.length === 0
    ) {
      setValidationError({
        ...validationError,
        confirmation: true,
      });
      toast.warn(
        t(
          "Morgatee Toggled: You must add at least one mortgagee before proceeding to the next step."
        )
      );
      return false;
    }

    return true;
  };

  const validateTermsAndConditions = () => {
    if (!confirmation.readSubscriberAgreement) {
      setValidationError({
        ...validationError,
        subscriberAgreement: true,
        confirmation: true,
      });
    } else if (!confirmation.readTermsAndConditions) {
      setValidationError({
        ...validationError,
        termsAndConditions: true,
        confirmation: true,
      });
    } else if (!confirmation.readFraudStatement) {
      setValidationError({
        ...validationError,
        fraudStatement: true,
        confirmation: true,
      });
    }
  };

  const checkConfirmationDetails = () => {
    return (
      confirmation?.checkboxes?.readSubscriberAgreement &&
      confirmation?.checkboxes?.readTermsAndConditions &&
      confirmation?.checkboxes?.readFraudStatement
    );
  };

  const checkAgreements = () => {
    return (
      agreements?.acknowledge1 &&
      agreements?.acknowledge2 &&
      agreements?.acknowledge3 &&
      agreements?.acknowledge4 &&
      agreements?.acknowledge5 &&
      agreements?.acknowledge6 &&
      agreements?.acknowledge7 &&
      agreements?.acknowledge8
    );
  };

  const validatePolicy = () => {
    if (!selectedCard) {
      toast.warn(t("Please select a policy type"));
      return false;
    }

    return true;
  };

  const handleNextButton = () => {
    switch (activeStep) {
      case 0:
        if (validatePolicy()) {
          setActiveStep(activeStep + 1);
        }
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        setActiveStep(activeStep + 1);
        break;
      case 4:
        if (validateMortagee() && checkConfirmationDetails()) {
          setActiveStep(activeStep + 1);
        } else {
          validateTermsAndConditions();
        }
        break;
      case 5:
        if (checkAgreements()) {
          setActiveStep(activeStep + 1);
        } else {
          toast.info(
            t(
              "Please review and acknowledge the important disclosures before proceeding."
            ),
            { toastId: "agreementsErr1", autoClose: 4500 }
          );
        }
        break;
      case 6:
        navigate("/create-star-commercial/summary");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box mt={4} mb={6} pb={4}>
        <Flex justifyContent={"center"} gap={2}>
          <Button
            size="lg"
            variant="ghost"
            borderWidth="2px"
            borderColor="var(--chakra-colors-neutral-500)"
            color="var(--chakra-colors-black)"
            borderRadius="12px"
            padding="20px"
            leftIcon={<GrFormPreviousLink />}
            onClick={() => {
              if (activeStep > 0) setActiveStep(activeStep - 1);
            }}
          >
            {t("Back")}
          </Button>
          <Button
            rightIcon={<GrFormNextLink />}
            onClick={() => {
              handleNextButton();
            }}
            size="lg"
            bg={"primary.500"}
            color="white"
            borderWidth={"2px"}
            padding="20px"
            borderRadius="12px"
            hidden={activeStep === steps.length - 1}
            isDisabled={activeStep === steps.length - 1}
          >
            {t("Next")}
          </Button>
          <Button
            rightIcon={<GrFormNextLink />}
            onClick={() => {
              navigate(`/quote/starcom/${masterQuoteData?.quoteNumber}`);
            }}
            size="lg"
            bg={"primary.500"}
            color="white"
            borderWidth={"2px"}
            padding="20px"
            borderRadius="12px"
            hidden={activeStep !== steps.length - 1}
            isDisabled={!masterQuoteData?.quoteNumber}
          >
            {t("Continue to Quote")}
          </Button>
        </Flex>
      </Box>
    </>
  );
};
