import { Center, Checkbox, Link, Select } from "@chakra-ui/react";
import { ListItem, UnorderedList } from "@chakra-ui/react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Spacer,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
// import { FcInfo } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import { IoIosClose } from "react-icons/io";

export default function Discounts() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");
  const {
    discounts,
    setDiscounts,
    baseDD,
    stateCodeVal,
    acknowledgementModal,
    setIsAcknowledgementModal,
  } = useStarDataContext();
  const [sprinkersDD] = useState(baseDD?.STARSPRINKLERWATERTYPE ?? []);

  const handleModalClose = () => {
    setIsAcknowledgementModal(false);
  };

  const renderTootipText = (id) => {
    return (
      <ReactTooltip
        id={id}
        delayHide={300}
        effect="solid"
        className={id}
        place="right"
      >
        {id === "wholeWaterDetection" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            “Whole Home Water Detection and Shut Off” is a single-point or
            whole-home water device that alerts the customer and/or the
            designated security company, and automatically shuts off the
            corresponding water valve
          </Box>
        )}
        {id === "electricDevice" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            A plug-in type surge or electrical hazard detection device that
            alerts the customer and or designated security company when an event
            occurs
          </Box>
        )}
        {id === "fsl" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            The "Fortified for Safer Living" (FSL) designation by Home Safety
            signifies a commitment to enhanced resilience against severe weather
            conditions such as high winds, hail, hurricanes, and tornadoes.
            Homes awarded the FSL designation by the Insurance Institute for
            Business & Home Safety (IBHS) are recognized for adhering to a
            voluntary construction standard that exceeds typical building
            requirements, thereby qualifying for our maximum discount
          </Box>
        )}
        {id === "irc" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            This refers to the 2006 International Residential Code, a
            comprehensive set of guidelines that ensure the safety and health of
            occupants in residential structures
          </Box>
        )}
        {id === "fehBronze" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            This level focuses on the integrity of the roof and attic
            ventilation systems, requiring specific standards for roof
            sheathing, weatherproofing, and fastening to prevent leaks. It
            emphasizes the importance of fortified roofs and stronger
            connections to keep the roof secured to the house
          </Box>
        )}
        {id === "fehSilver" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            Building upon the Bronze level, the Silver designation adds
            protection for door and window openings, attached structures, and
            gable end walls. It requires that porches, carports, and garage
            doors are built to withstand uplift pressures determined by the
            site's wind speed and exposure category, thereby extending
            protection beyond the roof to other vulnerable areas.
          </Box>
        )}
        {id === "fehGold" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            The highest level of protection aimed at minimizing hurricane
            damage. This designation mandates that chimneys are securely
            connected to the roof structure, and windows, skylights, and glass
            doors are rated for specific design pressures. It ensures a
            continuous load path from the roof to the foundation and requires
            walls to have a minimum thickness of structural sheathing. The Gold
            level encapsulates all the protections of the Bronze and Silver
            levels and integrates them into a comprehensive strategy that binds
            the entire structure together for maximum resilience.
          </Box>
        )}
      </ReactTooltip>
    );
  };

  const handleAcknowlege = () => {
    setTimeout(() => {
      setIsAcknowledgementModal(false);
    }, 700);
  };

  return (
    <>
      {acknowledgementModal && (
        <Modal
          size={"4xl"}
          isCentered
          isOpen={acknowledgementModal}
          onClose={handleModalClose}
        >
          <ModalOverlay bg="blackAlpha.300" />

          <ModalContent>
            <ModalHeader>
              <Box display={"flex"} alignItems={"baseline"} m={1}>
                <Text
                  mb={1}
                  fontWeight={"bold"}
                  fontSize={"2xl"}
                  color={"#507CFF"}
                >
                  Congratulations!
                </Text>
                <Spacer />
                <IoIosClose
                  color="#808082"
                  size={35}
                  fontWeight={900}
                  onClick={handleModalClose}
                  cursor={"pointer"}
                />
              </Box>
            </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody mb={4}>
              <Text fontWeight={"bold"}>
                You may be eligible for one of our Wind Mitigation Discounts. If
                the home secures this discount, please submit the required
                documentation within the next 10 days after the policy binding
                date.  Keep in mind that eligibility may be confirmed through an
                on-site home inspection.
              </Text>
              <Center>
                <Box
                  m={4}
                  p={5}
                  color={"#fff"}
                  background={"#507CFF"}
                  borderRadius={"17px"}
                >
                  <Checkbox
                    onChange={() => handleAcknowlege()}
                    bg={"transparent"}
                  >
                    Acknowledge
                  </Checkbox>
                  {/* <Button onClick={handleModalClose} size={"lg"} leftIcon={<FaCheck style={{border:"1px solid #fff",padding:"2px"}}/>}>Acknowledge</Button> */}
                </Box>
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {/* Checkpoint */}
      <Accordion
        borderColor={"transparent"}
        width={"100%"}
        index={discounts?.hasSmartHomeProtection ? 0 : 1}
      >
        <AccordionItem>
          {({ isExpanded }) => (
            <Box
              border={
                isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
              }
              borderRadius={"xl"}
            >
              <h2>
                <AccordionButton
                  pr={0}
                  borderTopRadius={"xl"}
                  bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                  borderBottom={
                    !isExpanded
                      ? "1px solid var(--chakra-colors-neutral-100)"
                      : ""
                  }
                  borderBottomRadius={!isExpanded ? "xl" : "0"}
                  pt={"2"}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Text
                      fontSize={"18px"}
                      pt={3}
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          hasSmartHomeProtection:
                            !discounts.hasSmartHomeProtection,
                        });
                      }}
                    >
                      Does the Applicant's premises have or would they like to
                      purchase Smart Home System Protection for an instant
                      discount?
                    </Text>
                  </Box>

                  <Switch
                    size="lg"
                    isChecked={isExpanded || discounts?.hasSmartHomeProtection}
                    mr={"1rem"}
                    onChange={() =>
                      setDiscounts({
                        ...discounts,
                        hasSmartHomeProtection:
                          !discounts.hasSmartHomeProtection,
                      })
                    }
                  />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                {(discounts?.hasSmartHomeProtection || isExpanded) && (
                  <>
                    <UnorderedList>
                      <Flex justifyContent="space-between">
                        <ListItem>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            <Link
                              href="https://shop.moen.com/products/flo-smart-water-monitor-and-shutoff"
                              target="_blank"
                              textDecor={"underline"}
                              color={"blue.500"}
                            >
                              {t("Whole Home Water Detection and Shut Off")}
                            </Link>
                            <a data-tip data-for="wholeWaterDetection">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                          </Text>
                          {renderTootipText("wholeWaterDetection")}
                        </ListItem>
                        <Switch
                          isChecked={discounts?.homeWaterDetection}
                          onChange={(e) => {
                            setDiscounts({
                              ...discounts,
                              homeWaterDetection: e.target.checked,
                            });
                          }}
                          size="lg"
                        />
                      </Flex>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <Flex justifyContent="space-between">
                        <ListItem>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            <Link
                              href="https://www.tingfire.com/"
                              target="_blank"
                              textDecor={"underline"}
                              color={"blue.500"}
                            >
                              Ting
                            </Link>{" "}
                            {t("or Plug-in Electrical Device")}
                            <a data-tip data-for="electricDevice">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("electricDevice")}
                          </Text>
                        </ListItem>

                        <Switch
                          isChecked={discounts?.tingOrPlugDevices}
                          onChange={(e) => {
                            setDiscounts({
                              ...discounts,
                              tingOrPlugDevices: e.target.checked,
                            });
                          }}
                          size="lg"
                        />
                      </Flex>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                    </UnorderedList>
                  </>
                )}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>
      <Flex pt={2}>
        <Accordion
          borderColor={"transparent"}
          width={"100%"}
          index={discounts?.hasTheftProtection ? 0 : 1}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    borderBottomRadius={!isExpanded ? "xl" : "none"}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    pt={"2"}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          hasTheftProtection: !discounts.hasTheftProtection,
                          theftLocalBurglarAlarm: false,
                          theftLocalMonitorAlarm: false,
                          theftDeadboltLocks: false,
                        });
                      }}
                    >
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Applicant's premises have a Theft Protection Device?"
                        )}
                      </Text>
                    </Box>
                    <Switch
                      size="lg"
                      isChecked={isExpanded || discounts?.hasTheftProtection}
                      mr={"1rem"}
                      onChange={() =>
                        setDiscounts({
                          ...discounts,
                          hasTheftProtection: !discounts.hasTheftProtection,
                          theftLocalBurglarAlarm: false,
                          theftLocalMonitorAlarm: false,
                          theftDeadboltLocks: false,
                        })
                      }
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {(discounts?.hasTheftProtection || isExpanded) && (
                    <UnorderedList>
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>
                            <Link
                              href="https://www.jetsonsliving.com/"
                              target="_blank"
                              textDecor={"underline"}
                              color={"blue.500"}
                            >
                              {t("Monitored Burglar Alarm")}
                            </Link>
                          </Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.theftLocalMonitorAlarm}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                theftLocalBurglarAlarm: false,
                                theftLocalMonitorAlarm: e.target.checked,
                                theftDeadboltLocks: false,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>
                            {t("Local Burglar Alarm")}
                          </Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.theftLocalBurglarAlarm}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                theftLocalBurglarAlarm: e.target.checked,
                                theftLocalMonitorAlarm: false,
                                theftDeadboltLocks: false,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>{t("Deadbolt Locks")}</Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.theftDeadboltLocks}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                theftLocalBurglarAlarm: false,
                                theftLocalMonitorAlarm: false,
                                theftDeadboltLocks: e.target.checked,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                    </UnorderedList>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
      <Flex pt={2}>
        <Accordion
          borderColor={"transparent"}
          width={"100%"}
          index={discounts?.hasFireProtectionDevice ? 0 : 1}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    borderBottomRadius={!isExpanded ? "xl" : "none"}
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    pt={"2"}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          hasFireProtectionDevice:
                            !discounts?.hasFireProtectionDevice,
                          fireMonitoAlarm: false,
                          fireLocalAlarm: false,
                          fireOtherProtectionDevice: false,
                        });
                      }}
                    >
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Applicant's premises have a Fire Protection Device?"
                        )}
                      </Text>
                    </Box>
                    <Switch
                      size="lg"
                      isChecked={
                        discounts?.hasFireProtectionDevice || isExpanded
                      }
                      mr={"1rem"}
                      onChange={() =>
                        setDiscounts({
                          ...discounts,
                          hasFireProtectionDevice:
                            !discounts.hasFireProtectionDevice,
                          fireMonitoAlarm: false,
                          fireLocalAlarm: false,
                          fireOtherProtectionDevice: false,
                        })
                      }
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {(discounts?.fireMonitoAlarm || isExpanded) && (
                    <UnorderedList>
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>
                            <Link
                              href="https://www.jetsonsliving.com/"
                              target="_blank"
                              textDecor={"underline"}
                              color={"blue.500"}
                            >
                              {t("Monitored Fire Alarm")}
                            </Link>
                          </Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.fireMonitoAlarm}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                fireMonitoAlarm: e.target.checked,
                                fireLocalAlarm: false,
                                fireOtherProtectionDevice: false,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>{t("Local Fire Alarm")}</Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.fireLocalAlarm}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                fireMonitoAlarm: false,
                                fireLocalAlarm: e.target.checked,
                                fireOtherProtectionDevice: false,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text fontSize={"18px"}>
                            <Link
                              href="https://www.jetsonsliving.com/"
                              target="_blank"
                              textDecor={"underline"}
                              color={"blue.500"}
                            >
                              {t("Smart Home System")}
                            </Link>
                          </Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.fireOtherProtectionDevice}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                fireMonitoAlarm: false,
                                fireLocalAlarm: false,
                                fireOtherProtectionDevice: e.target.checked,
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                    </UnorderedList>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
      <Flex pt={2}>
        <Accordion
          borderColor={"transparent"}
          width={"100%"}
          index={discounts?.doesQualifyForMSwindDisc ? 0 : 1}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    borderBottomRadius={!isExpanded ? "xl" : "none"}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    pt={"2"}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      onClick={() => {
                        setDiscounts({
                          ...discounts,
                          doesQualifyForMSwindDisc:
                            !discounts.doesQualifyForMSwindDisc,
                          storeDiscountObj: {
                            ...discounts.storeDiscountObj,
                            fsl: false,
                            IRC: false,
                            fehBronze: false,
                            fehSilver: false,
                            fehGold: false,
                          },
                        });
                      }}
                    >
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Applicant's premises qualify for any Mississippi Wind Mitigation Discounts?"
                        )}
                      </Text>
                    </Box>
                    <Switch
                      size="lg"
                      mr={"1rem"}
                      isChecked={
                        isExpanded || discounts?.doesQualifyForMSwindDisc
                      }
                      onChange={() =>
                        setDiscounts({
                          ...discounts,
                          doesQualifyForMSwindDisc:
                            !discounts.doesQualifyForMSwindDisc,
                          storeDiscountObj: {
                            ...discounts.storeDiscountObj,
                            fsl: false,
                            IRC: false,
                            fehBronze: false,
                            fehSilver: false,
                            fehGold: false,
                          },
                        })
                      }
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {(discounts?.doesQualifyForMSwindDisc || isExpanded) && (
                    <UnorderedList>
                      <ListItem>
                        <Flex>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t("FSL")}
                            <a data-tip data-for="fsl">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("fsl")}
                          </Text>
                          <Spacer />
                          <Switch
                            size="lg"
                            isChecked={discounts?.storeDiscountObj?.fsl}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                storeDiscountObj: {
                                  ...discounts.storeDiscountObj,
                                  fsl: e.target.checked,
                                  IRC: false,
                                  fehBronze: false,
                                  fehSilver: false,
                                  fehGold: false,
                                },
                              })
                            }
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t("2006 IRC")}
                            <a data-tip data-for="irc">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("irc")}
                          </Text>
                          <Spacer />
                          <Switch
                            isChecked={discounts?.storeDiscountObj?.IRC}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                storeDiscountObj: {
                                  ...discounts.storeDiscountObj,
                                  fsl: false,
                                  IRC: e.target.checked,
                                  fehBronze: false,
                                  fehSilver: false,
                                  fehGold: false,
                                },
                              })
                            }
                            size="lg"
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t("FEH Bronze")}
                            <a data-tip data-for="fehBronze">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("fehBronze")}
                          </Text>
                          <Spacer />
                          <Switch
                            isChecked={discounts?.storeDiscountObj?.fehBronze}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                storeDiscountObj: {
                                  ...discounts.storeDiscountObj,
                                  fsl: false,
                                  IRC: false,
                                  fehBronze: e.target.checked,
                                  fehSilver: false,
                                  fehGold: false,
                                },
                              })
                            }
                            size="lg"
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t("FEH Silver")}
                            <a data-tip data-for="fehSilver">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("fehSilver")}
                          </Text>
                          <Spacer />
                          <Switch
                            isChecked={discounts?.storeDiscountObj?.fehSilver}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                storeDiscountObj: {
                                  ...discounts.storeDiscountObj,
                                  fsl: false,
                                  IRC: false,
                                  fehBronze: false,
                                  fehSilver: e.target.checked,
                                  fehGold: false,
                                },
                              })
                            }
                            size="lg"
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                      <ListItem>
                        <Flex>
                          <Text
                            fontSize={"18px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={3}
                          >
                            {t("FEH Gold")}
                            <a data-tip data-for="fehGold">
                              <FcInfo bg="primary" cursor={"pointer"} />
                            </a>
                            {renderTootipText("fehGold")}
                          </Text>
                          <Spacer />
                          <Switch
                            isChecked={discounts?.storeDiscountObj?.fehGold}
                            onChange={(e) =>
                              setDiscounts({
                                ...discounts,
                                storeDiscountObj: {
                                  ...discounts.storeDiscountObj,
                                  fsl: false,
                                  IRC: false,
                                  fehBronze: false,
                                  fehSilver: false,
                                  fehGold: e.target.checked,
                                },
                              })
                            }
                            size="lg"
                          />
                        </Flex>
                      </ListItem>
                      <Box
                        mb={4}
                        borderTop={"1px solid"}
                        borderColor={"primary.50"}
                      />
                    </UnorderedList>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
      </Flex>
      {stateCodeVal !== "ms" && (
        <Flex pt={2}>
          <Accordion
            borderColor={"transparent"}
            width={"100%"}
            index={discounts?.hasSprinklarSystem ? 0 : 1}
          >
            <AccordionItem>
              {({ isExpanded }) => (
                <Box
                  border={
                    isExpanded
                      ? "1px solid var(--chakra-colors-neutral-50)"
                      : ""
                  }
                  borderRadius={"xl"}
                >
                  <h2>
                    <AccordionButton
                      pr={0}
                      borderBottomRadius={!isExpanded ? "xl" : "0"}
                      borderBottom={
                        !isExpanded
                          ? "1px solid var(--chakra-colors-neutral-100)"
                          : ""
                      }
                      borderRadius={"xl"}
                      bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                      pt={"2"}
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        onClick={() =>
                          setDiscounts({
                            ...discounts,
                            hasSprinklarSystem: !discounts?.hasSprinklarSystem,
                          })
                        }
                      >
                        <Text fontSize={"18px"} pt={3}>
                          {t(
                            "Does your premises currently have a sprinkler system installed?"
                          )}
                        </Text>
                      </Box>
                      <Switch
                        size="lg"
                        mr={"1rem"}
                        isChecked={discounts?.hasSprinklarSystem}
                        onChange={(e) =>
                          setDiscounts({
                            ...discounts,
                            hasSprinklarSystem: e.target.checked,
                          })
                        }
                      />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Flex
                      w={"100%"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <UnorderedList mb={0}>
                        <ListItem>
                          <Text fontSize={"18px"}>
                            Please specify the type of sprinkler system
                            installed
                          </Text>
                        </ListItem>
                      </UnorderedList>
                      <Select
                        border={"1px solid"}
                        className="inputBorder"
                        value={discounts?.sprinklerType}
                        onChange={(e) => {
                          setDiscounts({
                            ...discounts,
                            sprinklerType: e.target.value,
                          });
                        }}
                        w={"15%"}
                        mb={2}
                      >
                        {sprinkersDD.map((item) => (
                          <option
                            key={item?.s_AppCodeName}
                            value={item?.s_AppCodeName}
                          >
                            {item?.s_AppCodeNameForDisplay}
                          </option>
                        ))}
                      </Select>
                    </Flex>
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          </Accordion>
        </Flex>
      )}
    </>
  );
}
