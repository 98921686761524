import { StarDataProvider } from "./StarDataContext";
import { StepsArrangements } from "./Steps";

export const StepperPage = () => {
  return (
    <StarDataProvider>
      <StepsArrangements />
      <style>
        {`
        
          .inputField {
            border: 1px solid var(--chakra-colors-neutral-100);
            padding-top: 25px;
            padding-bottom: 25px;
            border-radius: 12px;
          }

          .input-switch {
            display: none;
          }

          .label-switch {
            display: inline-block;
            position: relative;
          }

          .label-switch::before,
          .label-switch::after {
            content: "";
            display: inline-block;
            cursor: pointer;
            transition: all 0.5s;
          }

          .label-switch::before {
            width: 3em;
            height: 1em;
            border: 1px solid var(--chakra-colors-neutral-500);
            border-radius: 4em;
            background: var(--chakra-colors-neutral-500);
          }

          .label-switch::after {
            position: absolute;
            left: 0;
            top: -20%;
            width: 1.5em;
            height: 1.5em;
            border: 1px solid var(--chakra-colors-neutral-500);
            border-radius: 4em;
            background: var(--chakra-colors-white);
          }

          .input-switch:checked ~ .label-switch::before {
            background: var(--chakra-colors-primary-500);
            border-color: var(--chakra-colors-primary-500);
          }

          .input-switch:checked ~ .label-switch::after {
            left: unset;
            right: 0;
            background: var(--chakra-colors-primary-500);
            border-color: var(--chakra-colors-primary-500);
          }

          .clearfix:after {
            clear: both;
            content: "";
            display: block;
            height: 0;
          }

          .arrow-steps .cui-steps__horizontal-step {
            font-size: 14px;
            text-align: center;
            color: var(--chakra-colors-neutral-500);
            cursor: default;
            margin: 0 3px;
            padding: 14px 10px 14px 30px;
            min-width: 13.8%;
            float: left;
            position: relative;
            background-color: var(--chakra-colors-neutral-100);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            transition: background-color 0.2s ease;
          }
          .arrow-steps .cui-steps__horizontal-step:after,
          .arrow-steps .cui-steps__horizontal-step:before {
            content: " ";
            position: absolute;
            top: 0px;
            right: -16px;
            width: 0;
            height: 0;
            border-top: 26px solid transparent;
            border-bottom: 27px solid transparent;
            border-left: 17px solid var(--chakra-colors-neutral-100);
            z-index: 2;
            transition: border-color 0.2s ease;
          }

          .arrow-steps .cui-steps__horizontal-step:before {
            right: auto;
            left: 0;
            border-left: 17px solid var(--chakra-colors-white);
            z-index: 0;
          }

          .arrow-steps .cui-steps__horizontal-step:first-child:before {
            border: none;
          }

          .arrow-steps .cui-steps__horizontal-step:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          .arrow-steps .cui-steps__horizontal-step:last-child:after {
            border: none;
          }

          .arrow-steps .cui-steps__horizontal-step:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .cui-steps__step-icon-container {
            display: none;
          }

          .cui-steps__horizontal-step-container .css-0 span {
            color: var(--chakra-colors-black);
            font-size: 16px;
            font-weight: bold;
          }

          .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"] {
            color: var(--chakra-colors-white);
            background-color: var(--chakra-colors-primary-500);
          }

          .arrow-steps
            .cui-steps__horizontal-step[aria-disabled="false"]
            .cui-steps__horizontal-step-container
            .css-0
            span {
            color: var(--chakra-colors-white);
          }

          .arrow-steps .cui-steps__horizontal-step[aria-disabled="false"]:after {
            border-left: 17px solid var(--chakra-colors-primary-500);
          }


          .inputPolicyField {
            border: 2px solid var(--chakra-colors-neutral-500);
            padding-top: 25px;
            padding-bottom: 25px;
            border-radius: 12px;
          }

          .coverageField {
            border: 1px solid var(--chakra-colors-neutral-500);
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 9px;
            width: 25%;
            margin-top: 4px;
          }

          .jewelry-coverage-Field {
            border: 2px solid var(--chakra-colors-neutral-200);
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 9px;
            width: 50%;
            margin-top: 4px;
          }

          .coverageSelectField {
            height: 42px;
            margin-top: 4px;
          }

          .coverageSelectLeftField {
            height: 42px;
            margin-left: -6px;
            margin-top: 4px;
          }

          .chakra-switch {
            width: fit-content !important;
            margin-top: 1.5px;
          }

          .chakra-switch .chakra-switch__track::after {
            content: "NO" !important;
            color: var(--chakra-colors-white);
            display: block;
            position: absolute;
            top: 15px;
            left: 58%;
            font-size: 11px;
            font-weight: bold;
          }

          .chakra-switch[data-checked] .chakra-switch__track::after {
            content: "YES" !important;
            left: 7.7%;
            font-size: 10px;
            font-weight: normal;
          }
          .coverageTitle {
            padding: 20px;
            background-color: var(--chakra-colors-primary-50);
            font-size: 24px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            font-weight: var(--chakra-fontWeights-bold);
            height: 4rem;
          }
          .inputBorder {
            border: 2px solid #9AA5B5;
            padding-y: 25px;
            border-radius: 12px;
            h:53.5px;     
          }
        `}
      </style>
    </StarDataProvider>
  );
};
