import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Icon1, Icon2, Icon3, Icon4, Icon5 } from './LoadIcons.js';
import { getEmptyQuoteData, getEditEmptyQuoteData, getInitQuoteApi, setTempQuoteNo } from "../../../../../services/floodService";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import './flood-home.scss';
import { connect } from 'react-redux';
import ModeRouter from "../../form-mode/ModeRouter.js";

const FloodHome = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const tenant = window.location.host.split('.')[0];
  const { apiLoading, propertyDataFromAdd, propertyDataAutocompleteStored, tempQuoteNo,selectedAgencyWyoCodeData } = useSelector(state => state.flood);
  // Updated Type To The Object For The Further Process
  const navigatAction = (type) => {
    if (['HOME', 'MOBILEHOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(type)) {
      let address = propertyDataAutocompleteStored;
      if (![null, ''].includes(tempQuoteNo)) {
        address = {};
      }
      if (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length > 0) {
        address['rcv'] = propertyDataFromAdd.PropDetails?.RCV ?? 0;
      }
      
      dispatch(getEmptyQuoteData(10, type, address, tempQuoteNo,selectedAgencyWyoCodeData)).then((res) => {
        if (res && Object.keys(res).length > 0) {
          const allData = res;
          allData.viewType = type;
          if (allData.propertyInfo) {
            if (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length > 0) {
              let year = propertyDataFromAdd.PropDetails.ACT_YR_BLT || '';
              let cod = '';
              if(year && (year !== '' || year !== null)) {
                year = year.toString();
                cod = new Date(propertyDataFromAdd.PropDetails.ACT_YR_BLT + '-01-01');
              }
              allData.propertyInfo.s_YearBuilt = year;
              allData.propertyInfo.d_DateOfConstruction = cod;

              allData.propertyInfo.s_NoOfUnits = propertyDataFromAdd.PropDetails.NO_RES_UNTS || '';
              allData.propertyInfo.n_NoOfFloors = propertyDataFromAdd.PropDetails.NO_STORIES || '';
              allData.propertyInfo.s_BldgConstructionType = propertyDataFromAdd.ConstructionType;

              allData.propertyInfo.s_TotalSqFootage = propertyDataFromAdd.PropDetails.TOT_LVG_AR || '';
              allData.propertyInfo.s_Usage =  getUseageData();
              allData.propertyInfo.metadata = propertyDataFromAdd.EastedData.data.taxes ? {estedTaxsesData:propertyDataFromAdd.EastedData.data.taxes} : {estedTaxsesData:[]};

              // check lat/lng
              if(propertyDataFromAdd.s_Latitude && propertyDataFromAdd.s_Longitude){
                allData.personAddr.s_Latitude = propertyDataFromAdd.s_Latitude ? parseFloat(propertyDataFromAdd.s_Latitude) : '';
                allData.personAddr.s_Longitude = propertyDataFromAdd.s_Longitude ? parseFloat(propertyDataFromAdd.s_Longitude) : '';
              }

              const buyerFirstName = propertyDataFromAdd?.EastedData?.data?.deeds[0]?.buyer_first_name || '';
              const buyerLastName = propertyDataFromAdd?.EastedData?.data?.deeds[0]?.buyer_last_name || '';

              allData.personInfo.s_FirstName = buyerFirstName;
              allData.personInfo.s_LastOrganizationName = buyerLastName;

              let fullLegalName = buyerFirstName;
              if (fullLegalName) fullLegalName += ' ';
              fullLegalName += buyerLastName
              allData.personInfo.s_FullLegalName = fullLegalName;

              let entityType = 'INDIVIDUAL';
              if (['', null].includes(buyerFirstName) && buyerLastName) entityType = 'BUSINESS';
              allData.personInfo.s_EntityType = entityType;

              allData.personAddr.s_ParcelId = propertyDataFromAdd.PropDetails.PARCEL_ID || '';
              allData.personInfo.s_IsMailingDiff = 'NO';
              allData.personPhoneContact.s_CommType = 'Home';
            }
            else{
              allData.propertyInfo.metadata = {estedTaxsesData:[]}
            }
            if (propertyDataAutocompleteStored) {
              allData.personAddr.s_CityName = propertyDataAutocompleteStored.city;
              allData.personAddr.s_CountyName = propertyDataAutocompleteStored.CountyName;
              allData.personAddr.s_StateName = propertyDataAutocompleteStored.state;
              allData.personAddr.s_CountryName = propertyDataAutocompleteStored.country;
              allData.personAddr.s_StreetName = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
              allData.personAddr.s_PostalCode = propertyDataAutocompleteStored.PostalCode;
              allData.personAddr.s_PostalCodeSuffix = propertyDataAutocompleteStored.s_PostalCodeSuffix;
              allData.personAddr.s_HouseNo = propertyDataAutocompleteStored.street_number;
              allData.personAddr.s_AddressLine1 = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
              allData.personAddr.s_AddressLine2 = propertyDataAutocompleteStored.apt_Number;
            }
          }
          dispatch(getEditEmptyQuoteData(allData));
          // set temp quote no in reducer
          dispatch(setTempQuoteNo(allData.policyInfo.Policy_No_Quote));
          // To get Initial Quote Api Object
          dispatch(getInitQuoteApi()).then(() => {
            navigation("/flood/instantquote/step/1");
          });
        }
      }).catch(() => toast.error(t('Something Went Wrong!')));
    } else {
      toast.info(t('Not Active'));
    }
  }
  const getUseageData = () => {
    var currentYear = new Date().getFullYear();
    var previousYear = (currentYear - 1);
    let taxes = propertyDataFromAdd.EastedData.data.taxes ? propertyDataFromAdd.EastedData.data.taxes : [];
    let exemptions = taxes?.[0]?.exemptions || [];
    let usage = '';
    if (taxes && taxes?.[0]?.year >= previousYear && exemptions.includes('HOMESTEAD')) {
      usage = "PRIMARY";
    }
    return usage;
  }

  useEffect(() => {
    let card = document.getElementById("home-tile");
    if(card !== null){
      card.focus();
    }
  }, []);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target.title == "homeTile") navigatAction("HOME");
      if (e.target.title == "mobilehomeTile") navigatAction("MOBILEHOME");
      if (e.target.title == "unitTile") navigatAction("UNIT");
      if (e.target.title == "residentbldgTile")
        navigatAction("RESIDENTIALBLDG");
      if (e.target.title == "nonresidentbldgTile")
        navigatAction("NONRESIDENTIALBLDG");
    }
  }

  return (
    <LoadingOverlay>
      <Loader loading={apiLoading} />
      {props.pageMode ? <ModeRouter /> : 
        <Container>
          {
            (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length === 0) && <Navigate to={'/flood/instantquote/step/address'} />
          }
          <div className="justify-content-center">
            <Row>
              <Col xs="12" className="flood-div">
                <Row className="justify-content-center">
                  <div className="home-type-headings">
                    <h4 className="home-type-title">{t('Select the property type')}</h4>
                    <p className="home-type-desc">
                      {/* {t('Choose a property you want to issue with Unify')} */}
                    </p>
                  </div>
                  <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <div
                      className="flood-quote-page-cards-div"
                      onClick={() => navigatAction('HOME')}
                      id="home-tile"
                      title="homeTile"
                      tabIndex="0"
                      onKeyDown={handleEnterPress}
                    >
                      <img className="flood-quote-page-cards-icon" src={Icon1} />
                      <div className="flood-quote-page-cards-title">{t('Home')}</div>
                      <br />
                      <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>1 - 4 Residential occupancies</p>
                    </div>
                  </Col>

                  <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <div
                      className="flood-quote-page-cards-div"
                      onClick={() => navigatAction('MOBILEHOME')}
                      id={"other-tile"}
                      title="mobilehomeTile"
                      tabIndex="0"
                      onKeyDown={handleEnterPress}
                    >
                      <img className="flood-quote-page-cards-icon" src={Icon2} />
                      <div className="flood-quote-page-cards-title">{t('Mobile Home')}</div>
                      <br />
                      <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>Residential or Non-Residential</p>
                    </div>
                  </Col>

                  <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <div
                      className="flood-quote-page-cards-div"
                      onClick={() => navigatAction('UNIT')}
                      id={"other-tile"}
                      title="unitTile"
                      tabIndex="0"
                      onKeyDown={handleEnterPress}
                    >
                      <img className="flood-quote-page-cards-icon" src={Icon3} />
                      <div className="flood-quote-page-cards-title">{t('Unit')}</div>
                      <br />
                      <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>Residential or Non-Residential</p>
                    </div>
                  </Col>

                  <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <div
                      className="flood-quote-page-cards-div"
                      onClick={() => navigatAction('RESIDENTIALBLDG')}
                      id={"other-tile"}
                      title="residentbldgTile"
                      tabIndex="0"
                      onKeyDown={handleEnterPress}
                    >
                      <img className="flood-quote-page-cards-icon" src={Icon4} />
                      <div className="flood-quote-page-cards-title">{t('Residential Building')}</div>
                      <br />
                      <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>5 or more Occupancies and RCBAP</p>
                    </div>
                  </Col>

                  <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <div
                      className="flood-quote-page-cards-div"
                      onClick={() => navigatAction('NONRESIDENTIALBLDG')}
                      id={"other-tile"}
                      title="nonresidentbldgTile"
                      tabIndex="0"
                      onKeyDown={handleEnterPress}
                    >
                      <img className="flood-quote-page-cards-icon" src={Icon5} alt='flood' />
                      <div className="flood-quote-page-cards-title">{t('Non-Residential Building')}</div>
                      <br />
                      <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>All Non-Residential, including Detached Structures</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      }
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => {
  return {
    pageMode : state.flood.pageMode
  };
}

export default connect(mapStateToProps)(FloodHome);