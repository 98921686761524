import {
  Box,
  Card,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { postStarAgency } from "../Api/postStarAgency";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { starCommercialContext } from "../CommercialContext";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import DateInput from "../../../create-star-quote/utils/DateInput";
import moment from "moment";
import { formateDate } from "../../../../common/FormatDate";
const ApplicationDetails = () => {
  const {
    commercialData,
    setCommercialData,
    quoteData,
    agentNameData,
    appStatus,
  } = useContext(starCommercialContext);
  const { t } = useTranslation();

  const loadOptions = async (searchTerm) => {
    if (!searchTerm) {
      return [];
    }
    try {
      const response = await postStarAgency({ searchParams: searchTerm });
      const results = response.map(({ label, value }) => ({ label, value }));
      return results;
    } catch (error) {
      console.error(`Failed to load options: ${error}`);
      return [];
    }
  };

  const filterAgentOptions = async (data) => {
    if (typeof data !== "string") {
      return [];
    }

    try {
      const filteredOptionsAgent = agentNameData
        .filter((i) => i.label?.toLowerCase().includes(data.toLowerCase()))
        .map((item) => ({
          label: item.label,
          value: item.value,
        }));

      return filteredOptionsAgent;
    } catch (error) {
      console.error("Error filtering agent options:", error);
      return [];
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "2px solid var(--chakra-colors-neutral-200)",
      borderRadius: "12px",
      padding: "0 10px",
      width: "100%",
      height: "45px",
      backgroundColor: state.isDisabled
        ? "var(--chakra-colors-neutral-50)"
        : "white",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
  };

  return (
    <Box>
      <Card p={5} borderRadius="2xl" boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(3, 1fr)",
          }}
          gap={{ base: "0.75rem", md: "1.5rem" }}
        >
          <GridItem>
            <FormControl>
              <FormLabel>{t("Quote Number")}:</FormLabel>
              <Input
                isDisabled
                _disabled={{ bg: "neutral.50" }}
                className="inputSelectBoxes"
                focusBorderColor="secondary.500"
                placeholder="#"
                type="text"
                value={commercialData.quoteNo}
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    quoteNo: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>
                {t("Effective From")} {t("Date")}:
              </FormLabel>
              <DateInput
                styleProps={{
                  border: "2px solid var(--chakra-colors-neutral-200)",
                  height: "45px",
                  borderRadius: "12px",
                  size: "lg",
                  readOnly: false,
                }}
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    effectiveFromDate: moment(e).format("YYYY-MM-DD"),
                    effectiveToDate: moment(e)
                      .add(1, "year")
                      .format("YYYY-MM-DD"),
                  }));
                }}
                value={
                  commercialData?.effectiveFromDate
                    ? formateDate(commercialData?.effectiveFromDate)
                    : null
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>
                {t("Effective To")} {t("Date")}:
              </FormLabel>
              <DateInput
                styleProps={{
                  border: "2px solid var(--chakra-colors-neutral-200)",
                  height: "45px",
                  bg: "neutral.50",
                  borderRadius: "12px",
                  size: "lg",
                  readOnly: false,
                }}
                isDisabled={true}
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    effectiveToDate: moment(e).format("YYYY-MM-DD"),
                  }));
                }}
                value={
                  commercialData?.effectiveToDate
                    ? formateDate(commercialData?.effectiveToDate)
                    : null
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{t("Binder Date")}:</FormLabel>
              <DateInput
                styleProps={{
                  border: "2px solid var(--chakra-colors-neutral-200)",
                  height: "45px",
                  borderRadius: "12px",
                  size: "lg",
                  readOnly: false,
                }}
                value={
                  commercialData?.binderDate
                    ? formateDate(commercialData?.binderDate)
                    : null
                }
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    binderDate: moment(e).format("YYYY-MM-DD"),
                  }));
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{t("Estimated Premium")}:</FormLabel>
              <CustomCurrencyInput
                value={commercialData.estimatedPremium}
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    estimatedPremium: Number(
                      e.target.value.replace(/[^\d.]/g, "")
                    ),
                  }));
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{t("UW.APP Status")}:</FormLabel>
              <Select
                className="inputSelectBoxes"
                value={commercialData.appStatus || ""}
                onChange={(e) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    appStatus: e.target.value,
                    uwStatusData: {
                      ...commercialData.uwStatusData,
                      s_UWAppStatusCode: e.target.value,
                    },
                  }));
                }}
              >
                <option value=""> </option>
                {appStatus?.map((item, index) => {
                  return (
                    <option key={index} value={item.s_AppCodeName}>
                      {item.s_AppCodeNameForDisplay}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(3, 1fr)",
          }}
          gap={{ base: "0.75rem", md: "1.5rem" }}
          mt={4}
        >
          <GridItem>
            <FormControl>
              <FormLabel>{t("Agency Name")}:</FormLabel>
              <AsyncSelect
                isDisabled
                styles={customStyles}
                loadOptions={loadOptions}
                defaultOptions
                cacheOptions
                value={commercialData?.selectedAgency}
                onChange={(option) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    selectedAgency: {
                      ...prev?.selectedAgency,
                      ...option,
                    },
                  }));
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{t("Producer Name")}:</FormLabel>
              <AsyncSelect
                styles={customStyles}
                loadOptions={filterAgentOptions}
                defaultOptions
                cacheOptions
                value={commercialData?.selectedAgent}
                onChange={(option) => {
                  setCommercialData((prev) => ({
                    ...prev,
                    selectedAgent: {
                      ...prev?.selectedAgent,
                      ...option,
                    },
                  }));
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormLabel>{t("Phone Number")}:</FormLabel>
            <Input
              _disabled={{ bg: "neutral.50" }}
              isDisabled
              className="inputSelectBoxes"
              type="text"
              value={quoteData?.agencyPhone}
            />
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
          }}
          gap={{ base: "0.75rem", md: "1.5rem" }}
          mt={4}
        >
          <GridItem>
            <FormLabel>{t("Agency Address")}:</FormLabel>
            <Input
              _disabled={{ bg: "neutral.50" }}
              isDisabled
              className="inputSelectBoxes"
              type="text"
              value={quoteData?.agencyAddress}
            />
          </GridItem>
          <GridItem>
            <FormLabel>{t("Email Address")}:</FormLabel>
            <Input
              _disabled={{ bg: "neutral.50" }}
              isDisabled
              className="inputSelectBoxes"
              type="text"
              value={quoteData?.agencyEmail}
            />
          </GridItem>
        </Grid>
      </Card>
      <style>
        {`
        .inputSelectBoxes{
          border: 2px solid var(--chakra-colors-neutral-200);
          border-radius: 12px;
          padding-right: 10px;
          padding-left: 15px;
          width: 100%;
          height: 45px;
        }
        `}
      </style>
    </Box>
  );
};

export default ApplicationDetails;
