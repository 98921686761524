import Http from "../Http";
import * as action from "../store/actions";

const authBaseUrl = process.env.REACT_APP_AUTH_API_URL;
const authCoreUrl = process.env.REACT_APP_AUTH_CORE_URL;

export function login(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/Login", params)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function logout() {
  return (dispatch) => dispatch(action.authLogout());
}

export function authCheck() {
  return (dispatch) => dispatch(action.authCheck());
}

export function refreshToken(userId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("/api/refreshToken/" + userId)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}


export function getLogo(params) {
  return (dispatch) => dispatch(action.getLogo(params));
}

export function getSiteData(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const url = "/api/getSiteData";
      Http.get(url)
        .then((res) => {
          dispatch(action.getSiteData(res.data));
          return resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    });
}

export function uwlogin(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/uwlogin", params)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function forgotPass(credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(authCoreUrl + "/api/v1/forget-password", credentials)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          const { error } = err.response.data;
          const data = {
            error,
          };
          return reject(data);
        });
    });
}

export function addEamil(credentials) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(authCoreUrl + "/api/v1/add-email", credentials)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          const { error } = err.response.data;
          const data = {
            error,
          };
          return reject(data);
        });
    });
}


export function forgotUsername(credentials) {
  const data = (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(authCoreUrl + "/api/v1/forget-username", credentials)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          const { error } = err.response.data;
          const data = {
            error,
          };
          return reject(data);
        });
    });
  return data;
}

export function verifyToken(token) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(authCoreUrl + "/api/v1/verifyToken/" + token)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function resetPass(resetParams) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(authCoreUrl + "/api/v1/reset-password", resetParams)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function resetUsername(resetParams) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(authCoreUrl + "/api/v1/reset-username", resetParams)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function resetPasswordUserName(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/resetPasswordNewUser", params)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function farmersLogin(params) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/farmersLogin", params)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          return resolve(err.response.data);
        }
      });
  });
}


export function getUserDebardStatus(username) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("/api/getUserDebardData/"+username)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function setUserDebardStatus(payload) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/setUserDebardData",payload)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function logoutSession() {
	if(localStorage.getItem('access_token')){
		Http.post(authCoreUrl + '/api/v1/logout')
	}
  }

