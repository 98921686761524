import React from "react";
import { PropertyDetails } from "../Location/PropertyDetails";
import { Box } from "@chakra-ui/react";

const StepSearchAddress = ({ allLocationDetailsFilled }) => {
  return (
    <Box pt={3}>
      <Box px={5} pb={2}>
        <Box mt={2}>
          <PropertyDetails />
        </Box>
      </Box>
    </Box>
  );
};

export default StepSearchAddress;