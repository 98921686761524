import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { MdEditNote } from "react-icons/md";
import { SaveModalBody } from "./SavePremiumModalBody";
import { postEndorseBind } from "../api/postEndorseBind";
import { toast } from "react-toastify";
import { postSaveHiscoxPremiumCard } from "../api/postSaveHiscoxPremium";
import { fetchFees } from "../api/getFees";
import { Button } from "@chakra-ui/react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export const PremiumModal = ({
  selectedCardData,
  showModal,
  handleCloseModal,
  setChoosedPreferredCard,
  premiumResponseData,
  preferredType,
  setLoading,
  includeLossOfUse,
  setIncludeLossOfUse,
  businessIncomePremium,
  setBusinessIncomePremium,
  includeContents,
  setIncludeContents,
  setTotalPremium,
  totalPremium,
  productType,
  handleTransaction,
  setDataForEndorsement,
  handleClose,
  commercialProductType,
  handleCloseMain,
  loading,
  productCode,
  productId,
  saveLoading,
}) => {
  const calculateTotalPremium = () => {
    let total = 0;
    if (selectedCardData) {
      total += selectedCardData?.buildingAdditionalPremium ?? 0;
      total += selectedCardData?.otherStructuresAdditionalPremium ?? 0;
      total +=
        selectedCardData?.improvementsAndBettermentsAdditionalPremium ?? 0;
    }
    if (includeLossOfUse === false) {
      total += selectedCardData?.excludeLossOfUseAdditionalPremium ?? 0;
    } else if (includeLossOfUse === true) {
      total += selectedCardData?.includeLossOfUseAdditionalPremium ?? 0;
    }
    if (includeContents === false) {
      total += selectedCardData?.excludeContentsAdditionalPremium ?? 0;
    } else if (includeContents === true) {
      total += selectedCardData?.includeContentsAdditionalPremium ?? 0;
    }
    if (businessIncomePremium === false) {
      total += selectedCardData?.excludeBusinessIncomeAdditionalPremium ?? 0;
    } else if (businessIncomePremium === true) {
      total += selectedCardData?.includeBusinessIncomeAdditionalPremium ?? 0;
    }
    return Number(total);
  };

  const [limits, setLimits] = useState({});
  const [feeLoader, setFeeLoader] = useState(false);
  const [fees, setFees] = useState({
    fees: {},
    premium: 0,
    totalFee: 0,
    finalPremium: 0,
  });

  useEffect(() => {
    setTotalPremium(Number(calculateTotalPremium()) || 0);
  }, [
    includeLossOfUse,
    includeContents,
    businessIncomePremium,
    selectedCardData,
    setTotalPremium,
  ]);

  const findLimitsByCardData = (selectedCardData, premiumResponseData) => {
    if (!selectedCardData || !premiumResponseData) {
      return null;
    }
    const dataType = [
      "commercialOwned",
      "residential",
      "commercialTenanted",
    ].find((type) => premiumResponseData?.data?.hasOwnProperty(type));

    if (!dataType) {
      console.error("No matching data type found.");
      return null;
    }
    const apiResponseData = premiumResponseData.data[dataType];
    if (!apiResponseData) {
      console.error("No matching data type found.");
      return null;
    }
    const findMatchingPolicy = (policies) =>
      policies?.filter((policy) =>
        policy?.deductibles?.some((deductible) =>
          Object.keys(selectedCardData).every(
            (key) => deductible[key] === selectedCardData[key]
          )
        )
      );
    const findPolicies = (policyType) => {
      const policies = findMatchingPolicy(apiResponseData[policyType]);
      if (!policies?.length) {
        return null;
      }
      return policies;
    };

    const primaryPolicies = findPolicies("primary");
    const excessPolicies = findPolicies("excess");

    const policies = primaryPolicies || excessPolicies;
    if (!policies) {
      console.error("No matching policies found.");
      return null;
    }
    return {
      buildingLimit: policies[0].buildingLimit,
      contentsLimit: policies[0].contentsLimit,
      otherStructureLimit: policies[0].otherStructuresLimit ?? null,
      lossOfUseLimit: policies[0].lossOfUseLimit ?? null,
      improvementsAndBettermentsLimit:
        policies[0].improvementsAndBettermentsLimit || null,
      businessIncomeAndExtraExpenseAnnualValue:
        policies[0].businessIncomeAndExtraExpenseAnnualValue || null,
    };
  };

  useEffect(() => {
    setLimits(findLimitsByCardData(selectedCardData, premiumResponseData));
  }, [selectedCardData, premiumResponseData]);

  useEffect(() => {
    setDataForEndorsement({
      limits: limits,
      totalPremium: totalPremium,
      selectedCardData: selectedCardData,
      options: {
        includeLossOfUse,
        includeContents,
        businessIncomePremium,
      },
    });
  }, [selectedCardData, totalPremium]);

  useEffect(() => {
    if (totalPremium !== null && totalPremium !== undefined) {
      getFees();
    }
  }, [totalPremium]);

  async function getFees() {
    const requestBody = {
      state: premiumResponseData?.data?.geocodedLocation?.stateCode || "",
      productId: productId || "",
      premium: Number(totalPremium) ?? 0,
      prTransTypeCode: "ENDORSE",
    };

    const product = productCode === "HiscoxFloodPlus" ? "hiscox" : productCode;
    setFeeLoader(true);
    try {
      const fees = await fetchFees(requestBody, product);
      if (fees?.data?.success) {
        setFees(fees?.data?.data);
      } else {
        setFees(null);
      }
      return fees;
    } catch (error) {
      console.error("Failed to calculate fees:", error);
      return null;
    } finally {
      setFeeLoader(false);
    }
  }

  const onSaveClick = async () => {
    try {
      // await updatePremium();
      // await bindPremium();
      await handleTransaction(); // save & transaction
      handleClose(); //premium selector
      handleCloseModal(); // this modal
      handleCloseMain(); // main modal
    } catch (error) {
      console.error("Error updating premium:", error);
    }
  };

  useEffect(() => {
    if (productType === "Residential") {
      setIncludeLossOfUse(true);
      setIncludeContents(true);
    } else if (productType === "Commercial") {
      setIncludeLossOfUse(null);
      setIncludeContents(true);
      setBusinessIncomePremium(true);
    } else {
      setIncludeLossOfUse(false);
      setIncludeContents(false);
      setBusinessIncomePremium(false);
    }
  }, [productType]);

  const updatePremium = async () => {
    const requestBody = {
      commercialProductType,
      productType,
      includeLossOfUse: productType === "Commercial" ? null : includeLossOfUse,
      includeContents,
      includeBusinessIncomeAndExtraExpense:
        productType === "Residential" ? null : businessIncomePremium,
      deductible: selectedCardData?.deductible,
      chargedPremium: Number(parseFloat(totalPremium).toFixed(2)),
      type: preferredType,
      building_coverage: limits?.buildingLimit,
      content_coverage: limits?.contentsLimit,
      improvement_betterment_coverage: limits?.improvementsAndBettermentsLimit,
    };

    const cleanedRequestBody = Object.fromEntries(
      Object.entries(requestBody).filter(([_, value]) => value !== null)
    );

    const quoteNo = premiumResponseData?.data?.hiscoxId;

    try {
      setLoading(true);
      const res = await postSaveHiscoxPremiumCard({
        requestBody: cleanedRequestBody,
        quoteNo,
      });

      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) => toast.error(error));
      } else if (
        res?.status === true &&
        res?.message === "Coverage Update successfully"
      ) {
        toast.success("Premium Updated Successfully", {
          autoClose: 5000,
          toastId: "premiumUpdated",
        });
        await bindPremium();
        handleClose(); //premium selector
        handleCloseModal(); // this modal
        handleCloseMain(); // main modal
      }
      if (res?.status === false && res?.message) {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("Error updating premium:", error);
    } finally {
      setLoading(false);
    }
  };
  const bindPremium = async () => {
    const reqBody = {
      productType,
      commercialProductType,
      policyType: preferredType,
      premium: Number(parseFloat(totalPremium).toFixed(2)),
      includeLossOfUse: !commercialProductType
        ? includeLossOfUse
        : businessIncomePremium,
      includeContents,
      buildingLimit:
        commercialProductType === "Tenanted"
          ? limits?.improvementsAndBettermentsLimit
          : limits?.buildingLimit ?? null,
      contentsLimit: limits?.contentsLimit ?? null,
      deductible: selectedCardData?.deductible,
    };

    const cleanedRequestBody = Object.fromEntries(
      Object.entries(reqBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = premiumResponseData?.data?.hiscoxId;
    try {
      setLoading(true);
      const res = await postEndorseBind({
        requestBody: cleanedRequestBody,
        policyId: quoteNo,
      });

      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) =>
          toast.error(error, { autoClose: 5000, toastId: "premiumUpdated" })
        );
      } else if (res?.status === true) {
        toast.success("Quote Bound Successfully", {
          autoClose: 5000,
          toastId: "premiumUpdated1",
        });
      }
      handleTransaction(); // save & transaction
      handleClose(); //premium selector
      handleCloseModal(); // this modal
      handleCloseMain(); // main modal
    } catch (error) {
      console.error("Error binding premium:", error);
    } finally {
      setLoading(false);
    }
  };

  const CustomSpinner = () => {
    return (
      <div className="custom-spinner-container d-flex align-items-center flex-column">
        <Spinner variant="primary" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p
          className="custom-spinner-text mt-2 text-center text-secondary"
          style={{ fontSize: "small" }}
        >
          Loading...
        </p>
      </div>
    );
  };

  return (
    <Modal
      size="xl"
      scrollable
      show={showModal}
      onHide={handleCloseModal}
      centered
      className="box-shadow custom-dashboard-modal"
    >
      {loading && <CustomSpinner />}
      {saveLoading && <CustomSpinner />}
      <Modal.Header closeButton style={{ borderBottom: "1px solid orange" }}>
        <Modal.Title className="text-center d-flex align-items-center gap-2">
          <h2>Customize</h2>

          <MdEditNote size={40} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SaveModalBody
          selectedCardData={selectedCardData}
          includeLossOfUse={includeLossOfUse}
          setIncludeLossOfUse={setIncludeLossOfUse}
          includeContents={includeContents}
          setIncludeContents={setIncludeContents}
          businessIncomePremium={businessIncomePremium}
          setBusinessIncomePremium={setBusinessIncomePremium}
          calculateTotalPremium={calculateTotalPremium}
          fees={fees}
          feeLoader={feeLoader}
        />
      </Modal.Body>
      <Modal.Footer className="gap-2" style={{ borderTop: "1px solid orange" }}>
        <Button
          leftIcon={<AiFillCloseCircle size={18} />}
          borderRadius={"xl"}
          onClick={handleCloseModal}
        >
          Close
        </Button>
        {loading ? (
          <Button
            borderRadius={"xl"}
            variant="primary"
            isDisabled
            className="d-flex gap-2 align-items-center"
          >
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Saving...
          </Button>
        ) : (
          <Button
            leftIcon={<AiFillCheckCircle size={18} />}
            borderRadius={"xl"}
            onClick={onSaveClick}
            isLoading={feeLoader}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
      {/* this is custom mini loader css */}
      <style>{`
      .custom-spinner-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 9999;
      }
      `}</style>
    </Modal>
  );
};
