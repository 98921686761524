import React, { useState } from "react";
import { Button, Modal, Tabs, Row, Col, Tab } from "react-bootstrap";
import PivotLog from "../../quote/flood/quote/Modal/PivotLog";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegIdBadge } from "react-icons/fa";
export default function ActivityLogs() {
  const [pivotLog, showPivotLog] = useState(null);
  const params = useParams();
  const { policyNumber } = params;
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      {pivotLog}
      <Button
        className="px-2 py-1"
        id="common-btn"
        variant="primary"
        size="sm"
        onClick={() => setShow(true)}
      >
        <FaRegIdBadge size={18} /> &nbsp; {t("Logs")}
      </Button>
      <Modal
        size="xl"
        show={show}
        className="custom-dashboard-modal"
        onHide={() => setShow(!show)}
        backdrop="static"
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>{t("API Logs")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" className="custom-tabs">
              <PivotLog
                showPivotLog={showPivotLog}
                poTransPK={0}
                uuid={policyNumber}
                from="POLICY_VIEW"
                productType="HISCOX"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
