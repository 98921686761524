import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import CvgOptions from "./CvgOptions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getOptionsData } from "../../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";

const CvgOptionsModal = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { isLoading, showCvgOptionsModal, setShowCvgOptionsModal, poTransPK, paymentType, navigatePayment } = props;

    const [loading, setLoading] = useState(false);
    const [cvgOptions, setCvgOptions] = useState([]);
    const [selectedCvg, setSelectedCvg] = useState('');

    useEffect(() => {
        getCoverageOptionsData();
    }, [showCvgOptionsModal])

    const getCoverageOptionsData = async () => {
        if (showCvgOptionsModal === false) {
            return false;
        }
        setLoading(true);
        await dispatch(getOptionsData(poTransPK)).then((res) => {
            if (res.status === 'Y') {
                if (res.data.length === 0) {
                    toast.error(t('No data to display'));
                    return false;
                }
                let data = res.data;
                let selected = 'INCREASED';

                let increased = data.filter((x) => x.renewalCvgType === 'INCREASED')[0] || {};
                data = data.filter(option => option.renewalCvgType !== "INCREASED");

                if (Object.keys(increased).length > 0) {
                    data.unshift(increased);
                } else {
                    // if increased not present
                    selected = data[0].renewalCvgType || 'CURRENT';
                }
                data.map((value, index) => {
                    if (value.renewalCvgType === 'CURRENT') {
                        value['name'] = 'OPTION-A';
                        value['title'] = 'Current Coverage';
                        value['description'] = 'Does not account for rising replacement costs for building and contents';
                    }
                    if (value.renewalCvgType === 'INCREASED') {
                        value['name'] = 'OPTION-B';
                        value['title'] = 'Recommended Coverage';
                        value['description'] = 'Considers the rising replacement costs for building and/or contents';
                    }
                    if (value.renewalCvgType === 'CUSTOMIZED') {
                        value['renewalCvgType'] = value.renewalCvgType + '_' + index;
                        value['name'] = 'CUSTOMIZED';
                        value['title'] = 'Customized Coverage';
                        value['description'] = 'Customised replacement costs for building and/or contents';
                    }
                });

                setSelectedCvg(selected);
                setCvgOptions(data);
            } else {
                toast.error(t('Something Went Wrong'));
            }
        }).catch((error) => {
            const { errors } = error.data || {};
            toast.error(errors.text || t('Something Went Wrong'));
        })
        setLoading(false);
    }

    const handleOptionSelect = async () => {
        let amount = cvgOptions.filter((x) => x.renewalCvgType === selectedCvg)[0].premium || 0;
        await navigatePayment(paymentType, amount);
    }

    return (
        <Modal show={showCvgOptionsModal} size="xl" backdrop='static'>
            <Modal.Body>
                <LoadingOverlay>
                    <Loader loading={loading || isLoading} />
                    <CvgOptions
                        loading={loading}
                        selectedCvg={selectedCvg}
                        setSelectedCvg={setSelectedCvg}
                        cvgOptions={cvgOptions}
                    />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="mr-2"
                    id="common-btn-sm"
                    size="sm"
                    variant="primary"
                    onClick={() => handleOptionSelect()}
                    disabled={loading || isLoading}
                >
                    {t('Continue')}
                </Button>
                <Button
                    size="sm"
                    id="common-close-btn-sm"
                    variant="danger"
                    onClick={() => setShowCvgOptionsModal(false)}
                    disabled={loading || isLoading}
                >
                    {t('Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CvgOptionsModal;
