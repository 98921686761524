import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Error = ({ show, error, toggle,errorHeadingText = '' }) => {
    const { t } = useTranslation();

    return (
        <Modal
            size="lg"
            show={show}
            className="custom-dashboard-modal"
            onHide={() => toggle(!show)}
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
            >
                <Modal.Title>{t('Error')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="custom-dashboard-modal-body"
                style={{ overflow: 'scroll', maxHeight: '500px' }}
            >
                {
                    errorHeadingText && 
                    <div style={{
                        padding: '10px',
                        backgroundColor: '#f8d7da',
                        borderRadius: '5px',
                        border: '1px solid #dc3545',
                        marginBottom: '20px',
                        }}>
                        <p style={{
                            color: '#721c24',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            margin: '0',
                        }}>{errorHeadingText}</p>
                    </div>
                }
                {
                    error.length > 0 && (
                        <ul>
                            {error.map((v,i) => {
                                return (
                                    ![null, ''].includes(v) &&
                                    <li style={{ color: 'red' }} key={i}>{t(v)}</li>
                                )
                            })}
                        </ul>
                    )
                }
            </Modal.Body>
        </Modal>
    );
}

export default Error;