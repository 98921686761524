import React, { useState } from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

const AutocompleteForm = ({
  onAutocompleteSubmit,
  setSelectedPropertyData,
  toggleManualEntry,
}) => {
  const [search, setSearch] = useState(null);
  const { t } = useTranslation();

  const handleSearch = (e) => {
    setSearch(e);
    geocodeByAddress(e.value.description).then((addr) => {
      const propData = {};
      addr &&
        addr[0].address_components.forEach((e) => {
          if (e.types.includes("postal_code")) {
            propData.PostalCode = e.short_name;
          } else if (e.types.includes("street_number")) {
            propData.street_number = e.short_name;
          } else if (e.types.includes("route")) {
            propData.street_name = e.short_name;
          } else if (e.types.includes("locality", "political")) {
            propData.city = e.short_name;
          } else if (e.types.includes("country", "political")) {
            propData.country = e.short_name;
          } else if (
            e.types.includes("administrative_area_level_2", "political")
          ) {
            propData.CountyName = e.short_name;
          } else if (
            e.types.includes("administrative_area_level_1", "political")
          ) {
            propData.state = e.short_name;
          } else if (e.types.includes("postal_code_suffix")) {
            propData.zipPlus4 = e.short_name;
            propData.s_PostalCodeSuffix = e.short_name;
          } else if (e.types.includes("subpremise")) {
            propData.apt_Number = "#" + e.short_name;
          }
        });
      propData.autocomplete = addr[0].formatted_address;
      if (addr && addr[0]) {
        getLatLng(addr[0]).then(({ lat, lng }) => {
          propData.latitude = lat.toFixed(7);
          propData.longitude = lng.toFixed(7);
        });
      }
      setSelectedPropertyData(propData);
    });
  };

  return (
    <>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        gap={3}
      >
        <Box width={{ base: "100%", md: "55%" }}>
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            selectProps={{
              value: search,
              onChange: handleSearch,
              placeholder: "Enter Your Address",
              styles: {
                container: (provided) => ({
                  ...provided,
                  border: "1px solid",
                  borderColor: "blue",
                  borderRadius: "5px",
                }),
              },
            }}
          />
        </Box>
        <Button
          fontWeight={400}
          size="md"
          variant="outline"
          borderRadius="lg"
          borderWidth={1}
          borderColor="blue"
          onClick={onAutocompleteSubmit}
          isDisabled={!search}
        >
          {t("Continue")}
        </Button>
      </Flex>
      <Text
        cursor="pointer"
        onClick={toggleManualEntry}
        fontSize="sm"
        textAlign="left"
        textDecor="underline"
        color="gray.400"
      >
        {t("Manual Address Entry")}
      </Text>
    </>
  );
};

export default AutocompleteForm;
