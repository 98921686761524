import React,{useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Modal  } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { customStyles } from "../../../components/reactSelectCustomStyle";
import {  getZipDetails } from '../../../services/floodService';
import ZipDetailsView from '../../quote/flood/quote/Modal/ZipDetailsView';
import MaskedFormControl from 'react-bootstrap-maskedinput';
const MailingAddress = (props) => {
    const { applicationData, handleChange, showReview } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [streetName, setStreetName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [countryName, setCountyName] = useState('');
    const [stateName, setStateName] = useState('');
    const [cityName, setCityName] = useState('');
    const [multipleCity, setMultipleCity] = useState(null);
    const [googlePlace, setGooglePlace] = useState('');
    const [isGPUpdate, setIsGPUpdate] = useState(false);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');

    useEffect(() => {
        if(applicationData && Object.keys(applicationData).length > 0 ){   
            if(applicationData.mailingAddress){
                setStreetName(applicationData.mailingAddress.s_AddressLine1 || '');
                setCountyName(applicationData.mailingAddress.s_CountyName || '');
                setStateName(applicationData.mailingAddress.s_StateName || '');
                setCityName(applicationData.mailingAddress.s_CityName || '');
                setZipCode(applicationData.mailingAddress.s_PostalCode || '');
            }
            if(applicationData.personContact	){  
                setphoneNumber(applicationData.personContact.phone.s_PhoneNumber);
                setEmail(applicationData.personContact.email.s_EmailAddress); 	
            }
        }
        
        
    },[applicationData])
    // trigger on update streetName
    useEffect(() => {
        if (streetName && isGPUpdate) {
            geocodeByAddress(streetName)
                .then(addr => {
                    addr && addr[0].address_components.forEach(e => {
                        if (e.types.includes('postal_code')) {
                            setZipCode(e.short_name);
                        }
                    });
                });
        }
    }, [streetName])

    // trigger on update zipCode
    useEffect(() => {
        if (zipCode && isGPUpdate) {
            getDataFromZip(zipCode);
            setIsGPUpdate(false);
        }
    }, [zipCode])

    /**
     * Handle Google Place Autocomplete
     * @param {element values} e 
     */
    const handleGooglePlace = (e) => {
        setGooglePlace(e); // setting element value to google place autocomplete
        setStreetName(e.value.structured_formatting.main_text); // setting only address line 1 as streen name
        setIsGPUpdate(true); // to trigger dependent function
    }

    // trigger to set selected address street name
    const handleGooglAutoComplete = (val, status) => {
        if (val) {
            if (status.action == "input-change") {
                setStreetName(val);
            } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
                setStreetName(streetName.toUpperCase());
            }
        }
    }

    // get zip details from address
    const getDataFromZip = (zipCodeVal) => {
        if (streetName === '') {
            toast.error('Please Enter Street Name!');
            setZipCode('')
            return false;
        }
        if (zipCodeVal !== '') {
            dispatch(getZipDetails('10', zipCodeVal, 'APPLICANT'))
                .then((res) => {
                    if (res.success === true) {
                        var data = res.data;
                        if (data.status === 'Y') {
                            if (data.count === 1) {
                                setZipDetailsData(data.zipData[0]);
                            } else {
                                showMultipleCityModal(data.zipData);
                            }
                        } else {
                            toast.error(res.errorMsg);
                        }
                    }
                });
        } else {
            toast.error('Zip Code should not be empty!');
        }
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
        setShow(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData) => {
        const tempObj = { ...applicationData.mailingAddress };
        // tempObj.s_HouseNo = '';             
        // tempObj.s_HouseDirection1 = '';     
        tempObj.s_StreetName = streetName;          
        // tempObj.s_HouseType = '';           
        // tempObj.s_HouseDirection2 = '';     
        tempObj.s_AddressLine1 = streetName;        
        tempObj.n_CountyId_FK = zipData.n_CountryId_FK;         
        tempObj.n_CityId_FK = zipData.n_CityId_PK;           
        tempObj.n_StateId_FK = zipData.n_StateId_FK;          
        tempObj.s_AddressLine2 = '';        
        tempObj.n_CountryId_FK = zipData.n_CountryId_FK;        
        tempObj.s_PostalCode = zipData.s_ZipCode;          
        // tempObj.s_Latitude = '';            
        // tempObj.s_Longitude = '';           
        // tempObj.s_CountryName = '';         
        tempObj.n_Zipcodes_PK = zipData.n_Zipcodes_PK;         
        // tempObj.s_Description = '';         
        // tempObj.d_EffectiveDateFrom = '';   
        // tempObj.d_EffectiveDateTo = '';     
        tempObj.s_CityCode = zipData.s_CityCode;            
        tempObj.s_CityName = zipData.s_CityName;            
        tempObj.s_CountyCode = zipData.s_CountyCode;          
        tempObj.s_CountyName = zipData.s_CountyName;          
        tempObj.s_StateCode = zipData.s_StateCode;           
        tempObj.s_StateName = zipData.s_StateName;           
        // tempObj.s_PostalCodeSuffix = '';    
        // tempObj.n_FIPS = '';                
        // tempObj.stateFIPS = '';        
        handleChange("MailingAddress", applicationData.mailingAddress, tempObj, "InsuredInformation");
        var addressPK = {
            'n_CityId_FK': zipData.n_CityId_PK,
            's_ZipCounty': zipData.s_CountyCode.toUpperCase(), //s_CountyCode changed from s_ZipCounty need to confirm
            'n_CountyId_FK': zipData.n_CountyId_FK,
            's_StateCode': zipData.s_StateCode.toUpperCase(),
            's_StateName': zipData.s_StateCode.toUpperCase(),
            'n_StateId_PK': zipData.n_StateId_PK,
            's_CityName': zipData.s_CityName.toUpperCase(),
            'n_CityId_PK': zipData.n_CityId_PK,
            'n_CountryId_FK': zipData.n_CountryId_FK,
            'n_Zipcodes_PK': zipData.n_Zipcodes_PK,
            's_CountyName': zipData.s_CountyCode.toUpperCase(), //s_CountyCode changed from s_ZipCounty need to confirm
        
        };
        setCountyName(zipData.s_CountyCode.toUpperCase());//s_CountyCode changed from s_ZipCounty need to confirm
        setStateName(zipData.s_StateCode.toUpperCase());
        setCityName(zipData.s_CityName.toUpperCase());
        setMultipleCity(null);
        setShow(false);
        //handleMailingAddressChange('', addressPK);
    }


    // Inserting style only for address field
    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>#react-select-3-input { opacity: 2 !important; width: 250px !important;}</style>`)
    }, []);

    const handleStateChange = (value, state) => {
        
        switch (state) {
            case 'email':
                setEmail(value);
                handleChange("email", applicationData.personContact.email.s_EmailAddress , value, "Email");
                break;
                case 'phone':
                setphoneNumber(value);
                handleChange("phone", applicationData.personContact.phone.s_PhoneNumber, value, "Phone");
                break;
            }
        }

    return (
        <>
        <Row className="mt-2">
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Mailing Address')}
                    </Form.Label>
                    {
                            showReview ? (
                                <Form.Control
                                    className="dashboard-group-input-flood"
                                    type="text"
                                    placeholder={t("Mailing Address")}
                                    title={t('Mailing Address')}
                                    value={streetName}
                                    disabled={true}
                                />
                            ) : 
                            <GooglePlacesAutocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                selectProps={{
                                    styles: customStyles,
                                    inputValue: streetName,
                                    value: googlePlace,
                                    onInputChange: handleGooglAutoComplete,
                                    onChange: handleGooglePlace,
                                    name: "googlePlacesStreetName",
                                    id: "googlePlacesStreetName",
                                    placeholder: "Enter a location",
                                    //isDisabled: isBind
                                }}
                            />
                    }
                </Form.Group>
            </Col>

            <Col xs="12" sm="12" md="6" lg="1" xl="1">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Zip')}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t("Zip")}
                        name="s_PostalCode"
                        title={t('Zip')}
                        parentobject="personAddrMail"
                        value={zipCode}
                        onChange={(e) => {
                            // handleChange(e);
                            setZipCode(e.currentTarget.value);
                        }}
                        required
                        autoComplete="off"
                        disabled={showReview}
                    />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t("County")}
                    </Form.Label>
                    <Form.Control
                        className="dashboard-group-input-flood"
                        type="text"
                        placeholder={t("County")}
                        name="s_CountyName"
                        title={t('County')}
                        parentobject="personAddrMail"
                        value={countryName}
                        readOnly
                    />
                </Form.Group>
            </Col>

            <Col xs="12" sm="12" md="6" lg="1" xl="1">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('State')}
                    </Form.Label>
                    <Form.Control
                        className="dashboard-group-input-flood"
                        type="text"
                        placeholder={t("State")}
                        name="s_StateName"
                        title={t('State')}
                        parentobject="personAddrMail"
                        value={stateName}
                        readOnly
                    />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t("City")}
                    </Form.Label>
                    <Form.Control
                        className="dashboard-group-input-flood"
                        type="text"
                        placeholder={t("City")}
                        name="s_CityName"
                        title={t('City')}
                        parentobject="personAddrMail"
                        value={cityName}
                        readOnly
                    />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="confirm-address-label">
                            {t('Phone Number')}
                          </Form.Label>
                          <MaskedFormControl
                            type='text'
                            name='s_CommValue'
                            mask="(111)111-1111"
                            placeholder=""
                            title={t('Phone Number')}
                            parentobject="personPhoneContact"
                            value={phoneNumber}
                            defaultValue={''}
                            onChange={(e) => {
                                handleStateChange(e.target.value,'phone');
                          }}
                            className="confirm-address-input"
                            disabled={showReview}
                          />

                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Email')}
                    </Form.Label>
                    <Form.Control
                        className="dashboard-group-input-flood"
                        type="text"
                        placeholder={t("Email")}
                        name="Email"
                        title={t('Email')}
                        //parentobject="personAddrMail"
                        value={email}
                        onChange={(e) => {
                              handleStateChange(e.target.value,'email');
                        }}
                        disabled={showReview}
                    />
                </Form.Group>
            </Col>
        </Row>
        {/* Zip Details Modal */}
        <Modal size="lg" show={show} className="zip-details-modal">
            <Modal.Body className="zip-details-modal-body">
                {multipleCity}
            </Modal.Body>
        </Modal>
    </>

    )
}

export default MailingAddress;
