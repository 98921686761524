import moment from "moment";
function parsePhoneNumber(input) {
  if (!input) return "";
  const digitsOnly = input.replace(/\D/g, "");
  return digitsOnly;
}

const getPKIDs = (arrayOfData, city) => {
  if (
    !arrayOfData ||
    !Array.isArray(arrayOfData) ||
    !city ||
    typeof city !== "string"
  )
    return;

  return (
    arrayOfData.find(
      (data) =>
        typeof data?.s_CityName === "string" &&
        data.s_CityName.toLowerCase().includes(city.toLowerCase())
    ) ||
    arrayOfData[0] ||
    null
  );
};

const createMortgageeData = (mortgageeItem) => ({
  n_PolicyAddInterest_PK: null,
  personType:
    mortgageeItem?.type?.toLowerCase() === "primary"
      ? "PRIMARY"
      : mortgageeItem?.type?.toLowerCase() === "secondary"
      ? "SECOND"
      : "THIRD",
  mortgageeType:
    mortgageeItem?.type?.toLowerCase() === "primary"
      ? "First mortgagee"
      : mortgageeItem?.type?.toLowerCase() === "secondary"
      ? "Second mortgagee"
      : "Third mortgagee",
  loanNo: mortgageeItem?.loanNumber ?? "",
  person: {
    entityType: "ORGANISATION",
    firstName: "", // FOR INDIVIDUAL ONLY
    middleName: null, // -- / --
    lastName: mortgageeItem?.bankName ?? "",
    isMailingDiff: null, // -- / --
    isTempMailing: null, // -- / --
    // isInsuredTenant: null, // -- / --
  },
  address: {
    addressLine1: mortgageeItem?.address ?? "",
    addressLine2: "",
    county: mortgageeItem?.county ?? "",
    city: mortgageeItem?.city ?? "",
    cityId: mortgageeItem?.cityId ?? "",
    stateId: mortgageeItem?.stateId ?? "",
    countryId: mortgageeItem?.countryId ?? "",
    stateCode: mortgageeItem?.stateCode ?? "",
    country: 1, //USA
    countyId: mortgageeItem?.countyId ?? "",
    latitude: null, //NA
    longitude: null, // NA
    zip: mortgageeItem?.zipCode ?? "",
  },
});

export const CreateCommPool = ({
  masterQuoteData,
  coverages,
  location,
  selectedCard,
  underWriting,
  currentZipData,
  lossHistoryData,
  agent,
  hotelResponses,
  convenienceStoreResponses,
  blanketDisplayCoverages,
  discounts,
  buildingDetails,
  hasMortagee,
  saveMortgagee,
}) => {
  const { policy, applicantDetails } = masterQuoteData || {};
  const {
    streetName,
    streetNumber,
    city,
    countyName,
    postalCode,
    country,
    latitude,
    longitude,
    // value,
    fullAddress,
    state,
    // postalCodeSuffix,
    // aptNumber,
    // stateCode,
    validStateCode,
  } = location || {};

  const {
    address,
    zipCode,
    // city,
    stateCode,
    county,
    cityId,
    stateId,
    countryId,
    countyId,
  } = applicantDetails.mailingAddress || {};

  const isEveryBuildingSprinklered = (buildingDetails) => {
    return (
      buildingDetails?.length > 0 &&
      buildingDetails.every((building) => building?.fireSprinklers === "YES")
    );
  };

  const noBuildingIsMansory = (buildingDetails) => {
    return (
      buildingDetails?.length > 0 &&
      buildingDetails.every(
        (building) => building?.constructionType !== "Masonry Non-combustible"
      )
    );
  };

  const calculateAgeOfBuildings = (buildingDetails) => {
    return (
      (buildingDetails?.length > 0 &&
        buildingDetails
          .map((building) =>
            moment().diff(moment(building?.yearBuilt), "years")
          )
          .reduce((a, b) => a + b, 0) / buildingDetails.length) ||
      0
    );
  };

  const everyCoverageTeftExclusions = (coverages) => {
    return (
      coverages?.length > 0 &&
      coverages.every(
        (coverage) => coverage?.basicCoverages?.theftExclusions === "YES"
      )
    );
  };

  const everyCoverageExcludeSinkhole = (coverages) => {
    return (
      coverages?.length > 0 &&
      coverages.every(
        (coverage) => coverage?.additionalCoverages?.excludSinkhole === "YES"
      )
    );
  };

  const everyCoverageExcludeWind = (coverages) => {
    return (
      coverages?.length > 0 &&
      coverages.every(
        (coverage) => coverage?.basicCoverages?.windExclusions === "YES"
      )
    );
  };

  const calculateAgeOfCurrentBuilding = (yearBuilt) => {
    return moment().diff(moment(yearBuilt), "years");
  };

  const isBlanket = applicantDetails?.blanket === "YES";

  const businessClassIdentifier = (
    selectedCard,
    hotelResponses,
    convenienceStoreResponses
  ) => {
    let businessClass = "";

    if (selectedCard === "HOA") {
      businessClass = "HOA";
    } else if (selectedCard === "Hotel") {
      const stories = parseInt(hotelResponses.number_of_stories);
      const isLuxury =
        isEveryBuildingSprinklered(buildingDetails) &&
        calculateAgeOfBuildings(buildingDetails) < 20 &&
        hotelResponses?.room_access === "Interior Hallway" &&
        noBuildingIsMansory(buildingDetails);
      const cooking = hotelResponses.restaurant_exposure;

      if (stories <= 4) {
        if (cooking === "None" || cooking === "Limited Cooking") {
          businessClass = isLuxury
            ? "Hotels (Luxury) 1-4 Stories - No/Limited Cooking"
            : "Hotels 1-4 Stories - No/Limited Cooking";
        } else if (cooking === "Full Restaurant") {
          businessClass = isLuxury
            ? "Hotels (Luxury) 1-4 Stories + Cooking"
            : "Hotels 1-4 Stories + Cooking";
        }
      } else {
        if (cooking === "None" || cooking === "Limited Cooking") {
          businessClass = isLuxury
            ? "Hotels (Luxury) 5+ Stories - No/Limited Cooking"
            : "Hotels 5+ Stories - No/Limited Cooking";
        } else if (cooking === "Full Restaurant") {
          businessClass = isLuxury
            ? "Hotels (Luxury) 5+ Stories + Cooking"
            : "Hotels 5+ Stories + Cooking";
        }
      }
    } else if (selectedCard === "ConvenienceStore") {
      businessClass = convenienceStoreResponses.is_restaurant
        ? "Convenience Store with Restaurant"
        : "Convenience Store withOUT Restaurant";
    }

    return businessClass;
  };

  const identifyConstructionType = (type) => {
    if (type === "Frame") {
      return "1";
    } else if (type === "Joisted Masonry") {
      return "2";
    } else if (type === "Non-Combustible") {
      return "3";
    } else if (type === "Masonry Non-combustible") {
      return "4";
    } else if (type === "Modified Fire-resistive") {
      return "5";
    } else if (type === "Fire-resistive") {
      return "6";
    }
  };

  return {
    quoteNo: masterQuoteData?.quoteNumber || "",
    binderDate: "",
    effectiveFromDate: moment(policy?.effectiveDate).format("YYYY-MM-DD") || "",
    effectiveToDate: moment(policy?.effectiveToDate).format("YYYY-MM-DD") || "",
    applicantDetail: {
      address: applicantDetails?.sameMailingAddress
        ? streetNumber && streetNumber
          ? `${streetNumber ? streetNumber + " " : ""}${
              streetName ? streetName + "," : ""
            } ${city ? city + "," : ""} ${countyName ? countyName : ""}`
          : fullAddress
        : address,
      city: applicantDetails?.sameMailingAddress
        ? city
        : applicantDetails.mailingAddress?.city || "",
      state: applicantDetails?.sameMailingAddress ? state : stateCode,
      zip: applicantDetails?.sameMailingAddress ? postalCode : zipCode,
      stateCode: applicantDetails?.sameMailingAddress
        ? validStateCode
        : stateCode,
      county: applicantDetails?.sameMailingAddress ? countyName : county,
      country: country || "US",
      latitude: latitude || "",
      longitude: longitude || "",
      lastName: applicantDetails?.lastName || "",
      entityType: applicantDetails?.entityType || "",
      email: applicantDetails?.email || "",
      phone: parsePhoneNumber(applicantDetails?.phoneNumber) || "",
      cityId: applicantDetails?.sameMailingAddress
        ? getPKIDs(currentZipData?.data?.zipData, city)?.city_id || 0
        : cityId,
      stateId: applicantDetails?.sameMailingAddress
        ? getPKIDs(currentZipData?.data?.zipData, city)?.state_id || 0
        : stateId,
      countryId,
      countyId: applicantDetails?.sameMailingAddress
        ? getPKIDs(currentZipData?.data?.zipData, city)?.county_id || 0
        : countyId,
      buissinessEstablishment: policy?.businessEstablishment || "",
      blanket: applicantDetails?.blanket || "NO",
      shuttleServices: underWriting?.shuttleServices ? "YES" : "NO",
      franchise: underWriting?.franchise ? "YES" : "NO",
      onlyOwnerOccupied: underWriting?.onlyOwnerOccupied ? "YES" : "NO",
      franchiseDescription: underWriting?.franchiseDescription || "",
      onlyOwnerOccupiedDescription:
        underWriting?.onlyOwnerOccupiedDescription || "",
    },
    policySummaryDetails: {
      COMMBLDCVGAMT: blanketDisplayCoverages?.basicCoverages?.buildingRCV,
      COMMCONTCVGAMT:
        blanketDisplayCoverages?.basicCoverages?.buildingPersonalPropertyCVG,
      COMMVANDAEXCL:
        applicantDetails?.blanket === "NO"
          ? everyCoverageTeftExclusions(coverages)
            ? "YES"
            : "NO"
          : blanketDisplayCoverages?.basicCoverages?.theftExclusions || "",
      COMMSINKCVG:
        applicantDetails?.blanket === "NO"
          ? everyCoverageExcludeSinkhole(coverages)
            ? "YES"
            : "NO"
          : blanketDisplayCoverages?.additionalCoverages?.excludSinkhole || "",
      COMMWINDHAILEXC:
        applicantDetails?.blanket === "NO"
          ? everyCoverageExcludeWind(coverages)
            ? "YES"
            : "NO"
          : blanketDisplayCoverages?.basicCoverages?.windExclusions || "",
    },
    ...agent,
    propertyDetail: buildingDetails?.map((propertyDetails, index) => ({
      address:
        propertyDetails?.locationAddress || (streetNumber && streetNumber)
          ? `${streetNumber ? streetNumber + " " : ""}${
              streetName ? streetName : ""
            }`
          : "",
      locationIdentifier: propertyDetails?.locationIndetifier,
      zip: propertyDetails?.locationZip || postalCode,
      city: propertyDetails?.locationCity || city,
      state: propertyDetails?.locationState || stateCode,
      cityId:
        propertyDetails?.locationCityPk || applicantDetails?.sameMailingAddress
          ? getPKIDs(currentZipData?.data?.zipData, city)?.city_id || 0
          : cityId,
      stateId:
        propertyDetails?.locationStatePk || applicantDetails?.sameMailingAddress
          ? getPKIDs(currentZipData?.data?.zipData, city)?.state_id || 0
          : stateId,
      countryId,
      countyId:
        propertyDetails?.locationCountyPk ||
        applicantDetails?.sameMailingAddress
          ? getPKIDs(currentZipData?.data?.zipData, city)?.county_id || 0
          : countyId,
      county: propertyDetails?.locationCounty || countyName,
      outdoorType: "",
      shfa: "",
      locationDistanceToCoast: "",
      aopDeductible: isBlanket
        ? blanketDisplayCoverages?.deductibles?.allPerilsDed
        : coverages[index]?.deductibles?.allPerilsDed || "",
      ppc: propertyDetails?.ppc,
      bceg: "",
      buissnessClass: businessClassIdentifier(
        selectedCard,
        hotelResponses,
        convenienceStoreResponses
      ),
      sprinklered: propertyDetails?.fireSprinklers || "",
      ageOfBuilding:
        calculateAgeOfCurrentBuilding(propertyDetails?.yearBuilt) || "",
      construction:
        identifyConstructionType(propertyDetails?.constructionType) || "",
      roofType: "",
      roofShape: "",
      roofAttach: propertyDetails?.roofDeckAttachment || "",
      roofWall: propertyDetails?.roofWallAnchorage || "",
      roofGeomentry: propertyDetails?.roofGeometry || "",
      occupancy: "",
      noOfUnits: propertyDetails?.noOfUnits || "",
      yearBuilt: propertyDetails?.yearBuilt || 0,
      noOfStories: propertyDetails?.noOfStories || "",
      roofCover: propertyDetails?.roofCovering || "",
      openingProtection: "",
      roofSupport: "",
      swr: "",
      convertedStructure: propertyDetails?.convertedStructure,
      acvRoofSurface: isBlanket
        ? blanketDisplayCoverages?.additionalCoverages?.acvRoofSurface
        : coverages[index]?.additionalCoverages?.acvRoofSurface || "",
      fireAlarm: discounts?.fireAlarmSystem || "",
      burglarAlarm: discounts?.burglarAlarmSystem || "",
      windHailDeductible: isBlanket
        ? blanketDisplayCoverages?.deductibles?.windHailDed
        : coverages[index]?.deductibles?.windHailDed || "",
      distanceFromTheClosestBuildingInFeet: "",
      totalLocations: policy?.numberOfBuildings || "",
      theftExclusion: isBlanket
        ? blanketDisplayCoverages?.basicCoverages?.theftExclusions
        : coverages[index]?.basicCoverages?.theftExclusions || "",
      waterSublimit: isBlanket
        ? blanketDisplayCoverages?.additionalCoverages?.waterSublimit
        : coverages[index]?.additionalCoverages?.waterSublimit || "",
      theftDeductible: isBlanket
        ? blanketDisplayCoverages?.deductibles?.theftDed
        : coverages[index]?.deductibles?.theftDed || "",
      threeYearLostCount: propertyDetails?.threeYearLossCount || "0",
      securityServices: propertyDetails?.securityService || "",
      cosmeticRoofDamage: isBlanket
        ? blanketDisplayCoverages?.additionalCoverages?.cosmeticRoofDamage
        : coverages[index]?.additionalCoverages?.cosmeticRoofDamage || "",
      petsAndAnimals: propertyDetails?.petAnimals || "",
      latestClosingHours: propertyDetails?.closingHour || "",
      plumbingUpdates: propertyDetails?.plumbingUpdates || "",
      roofingUpdates: propertyDetails?.roofingUpdates || "",
      wiringUpdates: propertyDetails?.wiringUpdates || "",
      maintainence: propertyDetails?.maintenanceService || "",
      smoking: propertyDetails?.smokingPolicy || "",
      elevatorSafety: propertyDetails?.elevatorSafety || "",
      roofWarranty: propertyDetails?.roofWarrenty || "",
      bbbRating: propertyDetails?.bbaRating || "",
      buildingCoverage: coverages[index]?.basicCoverages?.buildingRCV || "",
      contentCoverage:
        coverages[index]?.basicCoverages?.buildingPersonalPropertyCVG || "",
      buissnessIncomeAndExtraExpenses:
        coverages[index]?.basicCoverages?.buildingExtraExpenses || "",
      buissnessInterruptionWaitingPeriod: isBlanket
        ? blanketDisplayCoverages?.basicCoverages?.buissnessWaitingPeriod
        : coverages[index]?.basicCoverages?.buissnessWaitingPeriod || "",
      restorationFactor: isBlanket
        ? blanketDisplayCoverages?.basicCoverages?.restorationFactor
        : coverages[index]?.basicCoverages?.restorationFactor || "",
      iotDevice: propertyDetails?.iotDevice || "",
      buildingGroupIndicator: "",
      lossesLastFiveYears:
        underWriting?.lossHistoryQuestion && lossHistoryData.length > 2
          ? "2+"
          : lossHistoryData.length,
      canopy: coverages[index]?.additionalCoverages?.canopy || 0,
      pumps: coverages[index]?.additionalCoverages?.pumps || 0,
      sign: coverages[index]?.additionalCoverages?.sign || 0,
      hurricaneDeductible: isBlanket
        ? blanketDisplayCoverages?.deductibles?.hurricaneDed
        : coverages[index]?.deductibles?.hurricaneDed,
      coveredPerils: isBlanket
        ? blanketDisplayCoverages?.coveredPerils
        : coverages[index]?.basicCoverages?.coveredPerils || "",
      n_PropertyCoverages_PK: null,
    })),
    lossHistoryData:
      underWriting?.lossHistoryQuestion && lossHistoryData.length > 2
        ? lossHistoryData
        : [],
    estimatedPremium: 1.0, // :)
    appStatus: "UWOPEN",
    // coveredPerils: coveredPerils || "",
    // aopDeductible: allPerilsDed || "",
    // hurricaneDeductible: hurricaneDed || "",
    payPlan: "FULLPAY",
    mortgageeData: hasMortagee
      ? Array.isArray(saveMortgagee) && saveMortgagee.length > 0
        ? saveMortgagee.map(createMortgageeData)
        : []
      : [],
  };
};
