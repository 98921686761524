import React from 'react';
import { Table } from 'react-bootstrap';
import '../../quote/quote.scss';

const ZipDetailsView = ({ zipData, setZipDetailsData }) => {
    var tableBody = zipData.map((data, i) => {
        return (
            <tr key={i}>
                <td><a href='#' onClick={(e) => { e.preventDefault(); setZipDetailsData(data) }}>{data.s_CityName}</a></td>
                <td>{data.s_ZipCounty}</td>
            </tr>
        )
    });

    return (
        <>
            <div className="zip-details-table-div">
                Please select preferred city
                <Table className="zip-details-table" responsive>
                    <thead>
                        <tr>
                            <th>City</th>
                            <th>County</th>
                        </tr>
                    </thead>
                    <tbody>{tableBody}</tbody>
                </Table>
            </div>
        </>
    );
}



export default ZipDetailsView;