import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button,Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { useTranslation } from "react-i18next";
const ClaimPayment = (props) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [TableData, setTableData] = useState([]);
  
  useEffect(() => {
    if(props.TableData){
        setTableData(props.TableData);
    }
  }, [props.TableData]);

  const getNumberFormat = (number = 0) => {
    if(number !== undefined && number != null && number != ''){
       return "$"+parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return "$0.00";
 }


  const columns = [
    {
      dataField: "payment_reason",
      text: t("Reasons"),
    },
    {
      dataField: "payment_type",
      text: t("Type"),
    },
    {
      dataField: "amount",
      text: t("Amount"),
      formatter:getNumberFormat
    },
    {
      dataField: "payment_date",
      text: t("Date"),
    },
    {
      dataField: "status",
      text: t("Status"),
    },
    {
      dataField: "Check_No",
      text: t("Check Number"),
    },
    {
      dataField: "s_ScreenName",
      text: t("Inserted User"),
    }
  ];

  return (
    <>
    <LoadingOverlay>
      <Loader loading={loading} />
      <div className="search-policy-section">
        <Row id="dashboard-table-section">
          <Col xs="12" style={{ marginTop: "50px" }}>
          <div className="table-main-title-div" style={{background:"#64d6cd"}}>
              <span className="table-main-title-name">{t('Payments')}</span>
            </div>
          </Col>
          <Col xs="12">
            <BootstrapTable
              wrapperClasses="dashboard-table-main"
              noDataIndication={t("No data to display")}
              bootstrap4
              remote
              keyField="ClaimReserveId_PK"
              data={TableData}
              columns={columns}
              hover
            />
          </Col>
        </Row>
      </div>
    </LoadingOverlay>
    
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    TableData: state.coreSystem.coreClaimPaymentData,
  };
}

export default connect(mapStateToProps)(ClaimPayment);
