import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import './page.scss';

const Page200 = () => {
    const { source } = useParams();
    const navigation = useNavigate();
    const [label, setLabel] = useState();

    useEffect(() => {
        switch (source) {
            case 'reset-username':
                setLabel('reset your username');
                break;
            case 'reset-password':
                setLabel('reset your password');
                break;
            default:
                break;
        }
    }, [source]);

    return (
        <div className="page-section">
            <Form>
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="8" lg="6" xl="5">
                        <div className="page-card text-center">
                            <i className='fa fa-check'></i>
                            <h4 style={{ color: 'green' }}>"Congratulation, You have successfully {label}".</h4>
                            <Button type='button' className='mt-2' color='primary' onClick={() => navigation('/login')}>Return to Login</Button>
                        </div>
                    </Col>
                </Row>
                <br />
            </Form>
        </div>
    )
}

export default Page200