import React, { useState, useEffect } from "react";
import { Row, Col, Form, Table, Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, connect } from "react-redux";
import {
  getZipDetails,
  getEditEmptyQuoteData,
} from "../../../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./address.scss";
import { toast } from "react-toastify";
import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";

const CurrentAddress = (props) => {
  const dispatch = useDispatch();

  //Zip
  const [quoteData, setQuoteData] = useState(props.quoteData);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [zipCode, setZipCode] = useState();

  const [zipData, setZipData] = useState([]);
  const [isMailingAddrPOBOX, setIsMailingAddrPOBOX] = useState(false);

  useEffect(() => {
    if (
      props?.quoteData[props?.addressType]?.s_StreetName &&
      !isNaN(parseInt(props?.quoteData[props?.addressType]?.s_StreetName)) &&
      !(
        !isNaN(props?.quoteData[props?.addressType]?.s_StreetName) &&
        !isNaN(parseFloat(props?.quoteData[props?.addressType]?.s_StreetName))
      )
    ) {
      let quoteDataVar = props.quoteData;
      const streetAddress =
        quoteDataVar[props?.addressType]?.s_StreetName?.split(/(\d+)/g);
      quoteDataVar[props?.addressType].s_HouseNo =
        streetAddress[1] || quoteDataVar[props?.addressType].s_HouseNo;
      quoteDataVar[props?.addressType].s_StreetName =
        streetAddress[2]?.trim() ||
        quoteDataVar[props?.addressType].s_StreetName;
      quoteDataVar[props?.addressType].s_AddressLine1 = `${
        quoteDataVar[props?.addressType].s_HouseNo || ""
      } ${quoteDataVar[props?.addressType].s_StreetName || ""}`;
      dispatch(getEditEmptyQuoteData(quoteDataVar));
    }
    if (
      props?.quoteData?.personMailingAddr?.s_StreetName
        ?.toLowerCase()
        ?.includes("po box") ||
      (props?.quoteData?.personMailingAddr?.s_StreetName
        ?.toLowerCase()
        ?.includes("po") &&
        props?.quoteData?.personMailingAddr?.s_StreetName
          ?.toLowerCase()
          ?.includes("box")) ||
      (props?.quoteData?.personMailingAddr?.s_StreetName
        ?.toLowerCase()
        ?.includes("post") &&
        props?.quoteData?.personMailingAddr?.s_StreetName
          ?.toLowerCase()
          ?.includes("office")) ||
      (!isNaN(props?.quoteData[props?.addressType]?.s_StreetName) &&
        !isNaN(parseFloat(props?.quoteData[props?.addressType]?.s_StreetName)))
    ) {
      let quoteDataVar = props.quoteData;
      quoteDataVar.personMailingAddr.isPostalAddress = true;
      dispatch(getEditEmptyQuoteData(quoteDataVar));
      setIsMailingAddrPOBOX(true);
    }
  }, []);

  const changeZip = (e) => {
    setZipCode(e.target.value);
  };

  useEffect(() => {
    dispatch(getEditEmptyQuoteData(quoteData));
  }, [quoteData]);

  useEffect(() => {
    if (props.addressType == "personMailingAddr") {
      setZipCode(props.quoteData.personMailingAddr.s_PostalCode);
    } else if (props.addressType == "personPriorAddr") {
      setZipCode(props.quoteData.personPriorAddr.s_PostalCode);
    }
  }, []);

  const zipCodeApi = () => {
    var zipAddrFor = props.addressType;
    if (zipAddrFor == "personMailingAddr") {
      if (
        props.quoteData.personMailingAddr.s_PostalCode != zipCode &&
        zipCode != "" &&
        zipCode >= 1
      ) {
        setLoadingStatus(true);
        dispatch(getZipDetails("13", zipCode, "APPLICANT")).then((res) => {
          if (res.data.status == "Y") {
            if (res.data.count > 1) {
              setZipData(res.data.zipData);
              setLoadingStatus(false);
              setShowModal(true);
            } else {
              setZipDetailsData(res.data.zipData[0]);
              setLoadingStatus(false);
            }
          } else {
            setLoadingStatus(false);
            toast.error(res.data.errorMsg);
            setZipCode("");
          }
        });
      }
    } else if (zipAddrFor == "personPriorAddr") {
      if (
        props.quoteData.personPriorAddr.s_PostalCode != zipCode &&
        zipCode != "" &&
        zipCode >= 1
      ) {
        setLoadingStatus(true);
        dispatch(getZipDetails("13", zipCode, "APPLICANT")).then((res) => {
          if (res.data.status == "Y") {
            if (res.data.count > 1) {
              setZipData(res.data.zipData);
              setLoadingStatus(false);
              setShowModal(true);
            } else {
              setZipDetailsData(res.data.zipData[0]);
              setLoadingStatus(false);
            }
          } else {
            setLoadingStatus(false);
            toast.error(res.data.errorMsg);
            setZipCode("");
          }
        });
      }
    }
  };

  const setZipDetailsData = (data) => {
    var quoteDataVar = props.quoteData;
    const tempArr = quoteData;
    var zipAddrFor = props.addressType;
    tempArr[zipAddrFor]["s_CountyName"] = data.s_CountyName;
    quoteDataVar[zipAddrFor]["s_CountyName"] = data.s_CountyName;
    quoteDataVar[zipAddrFor]["s_StateName"] = data.s_StateCode;
    quoteDataVar[zipAddrFor]["s_CityName"] = data.s_CityName;
    quoteDataVar[zipAddrFor]["s_PostalCode"] = zipCode;
    quoteDataVar[zipAddrFor]["n_CountyId_FK"] = data.n_CountyId_FK;
    quoteDataVar[zipAddrFor]["n_CityId_FK"] = data.n_CityId_PK;
    quoteDataVar[zipAddrFor]["n_StateId_FK"] = data.n_StateId_PK;
    quoteDataVar[zipAddrFor]["n_CountryId_FK"] = data.n_CountyId_FK;

    setShowModal(false);
    setQuoteData(quoteDataVar);
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  };

  const renderTootTip = (id) => {
    return (
      <>
      <ReactTooltip id={id} place="top" effect="solid" backgroundColor="#333" className="custom-tooltip">
        {id === "physicalAddress" && (
          <p>
            A 'prior physical residence address' refers to the location where a
            person lived before their current residence.
          </p>
        )}
      </ReactTooltip>
      <style>
        {`
          .custom-tooltip {
            max-width: 400px;
          }
        `}
      </style>
        </>

    );
  };

  return (
    <LoadingOverlay>
      <Loader loading={loadingStatus} />
      <Box p={2}></Box>
      <Text display={"flex"} alignItems={"center"} gap={2}>
        Please provide your prior physical residence address{" "}
        <a
          style={{
            cursor: "pointer",
          }}
          data-tip
          data-for={"physicalAddress"}
        >
          {" "}
          <div ml={1}>
            <FcInfo size={18} />
          </div>
          {renderTootTip("physicalAddress")}
        </a>
      </Text>
      {props.addressType === "personMailingAddr" && (
        <Flex alignItems={"center"} gap={2}>
          <Text fontWeight={"500"} ml={3}>
            Is the postal address used for mail?
          </Text>
          <Switch
            mb={3.5}
            isChecked={isMailingAddrPOBOX}
            size={"lg"}
            isDisabled={props.isBind}
            onChange={(e) => {
              setIsMailingAddrPOBOX(e.target.checked);
              let quoteData = props.quoteData;
              quoteData.personMailingAddr.s_HouseNo = "";
              quoteData.personMailingAddr.isPostalAddress = e.target.checked;
              dispatch(getEditEmptyQuoteData(quoteData));
            }}
          />
          <style>
            {`
            .chakra-switch {
              width: fit-content !important;
              margin-top: 1.5px;
            }

            .chakra-switch .chakra-switch__track::after {
              content: "NO" !important;
              color: var(--chakra-colors-white);
              display: block;
              position: absolute;
              top: 15px;
              left: 58%;
              font-size: 11px;
              font-weight: bold;
            }

            .chakra-switch[data-checked] .chakra-switch__track::after {
              content: "YES" !important;
              left: 7.7%;
              font-size: 10px;
              font-weight: normal;
            }
         `}
          </style>
        </Flex>
      )}
      <Row
        className="align-items-end"
        style={{
          display: "grid",
          gridTemplateColumns:
            props.addressType === "personMailingAddr" && isMailingAddrPOBOX
              ? "repeat(5, 1fr)"
              : "repeat(4, 1fr)",
          gap: "0.5rem",
          columnGap: "0.25rem",
          paddingTop: "1rem",
        }}
      >
        {(props.addressType === "personMailingAddr"
          ? !isMailingAddrPOBOX
          : true) && (
          <Col>
            <Form.Group
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <Form.Label className="dashboard-group-label">
                House Number: <span className="label-asterisk">*</span>
              </Form.Label>
              <Form.Control
                className="starQuotePageInput"
                type="number"
                placeholder=""
                value={
                  props.addressData && props.addressData.s_HouseNo
                    ? props.addressData.s_HouseNo
                    : ""
                }
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                name="s_HouseNo"
                title={props.addressType}
                onChange={props.handleChange}
                disabled={props.isBind}
                required
              />
            </Form.Group>
          </Col>
        )}
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {props.addressType === "personMailingAddr" && isMailingAddrPOBOX
                ? "PO BOX"
                : "Street Address"}
              : <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              type="text"
              placeholder=""
              value={
                props.addressData && props.addressData.s_StreetName
                  ? props.addressData.s_StreetName
                  : ""
              }
              name="s_StreetName"
              title={props.addressType}
              onChange={props.handleChange}
              disabled={props.isBind}
              required
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              County: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              type="text"
              placeholder=""
              disabled
              value={
                props.addressData && props.addressData.s_CountyName
                  ? props.addressData.s_CountyName
                  : ""
              }
              name="s_CountyName"
              title={props.addressType}
              onChange={props.handleChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              State: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              type="text"
              placeholder=""
              disabled
              value={
                props.addressData && props.addressData.s_StateName
                  ? props.addressData.s_StateName
                  : ""
              }
              name="s_StateName"
              title={props.addressType}
              onChange={props.handleChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              City: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              type="text"
              placeholder=""
              disabled
              value={
                props.addressData && props.addressData.s_CityName
                  ? props.addressData.s_CityName
                  : ""
              }
              name="s_CityName"
              title={props.addressType}
              onChange={props.handleChange}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              Zip: <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              placeholder=""
              type="tel" //to use the maxLength prop
              maxLength="5"
              name="s_PostalCode"
              title={props.addressType}
              value={isNaN(parseInt(zipCode)) ? "" : parseInt(zipCode)}
              onBlur={() => zipCodeApi()}
              required
              onChange={(e) => changeZip(e)}
              disabled={props.isBind}
            />
          </Form.Group>
        </Col>
        {/* Modal */}
        <Modal size="md" show={showModal} className="add-mortage-modal">
          <Modal.Header id="add-mortage-modal-header">
            <Modal.Title>Select City</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-mortage-modal-body">
            <Row>
              <Table className="p-0 m-0" size="sm" responsive striped>
                <thead>
                  <tr>
                    <th>City</th>
                    <th>County</th>
                  </tr>
                </thead>
                <tbody>
                  {zipData.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <a href="#" onClick={() => setZipDetailsData(data)}>
                            {data.s_CityName}
                          </a>
                        </td>
                        <td>{data.s_CountyName}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </Modal.Body>
        </Modal>
      </Row>
    </LoadingOverlay>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(CurrentAddress);
