import React from "react";
import { Button, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./flood-error.scss";

const FloodSeverRepetitiveLossError = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();

  const handleOnclick = (step) => {
    navigation('/flood/instantquote/step/' + step)
  }

  return (
    <Container >
      <div className="flood-div">
        <Col md="8" className="flood-div-border">
          <div className="flood-error-heading">
            <h6>{t("Severe Repetitive Loss")}!</h6>
          </div>
          <p>
            {t(
              "This property has been determined by FEMA to be a Severe Repetitive Loss and must be written directly to FEMA. If you think this is not correct, please contact the NFIP Help Desk at"
            )}{" "}
            <span>
              1-800-638-6620
            </span>
          </p>
          <div className="flood-bts-div">
            <Button
              id="primary-grey-btn-flood"
              variant="outline-dark"
              onClick={() => handleOnclick('address')}
            >
              {t('Previous')}
            </Button>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default FloodSeverRepetitiveLossError;