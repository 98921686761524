
import moment from "moment";

const defaultDateFormat = "MM/DD/Y";

export function formateDate(date, format) {
  if (!moment(date).isValid()) return "";

  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format(defaultDateFormat);
}
