import { FaRegCopy } from "react-icons/fa";
import { useRef } from "react";
import { toast } from "react-toastify";

const PolicyNumberCell = (props) => {
  const { value, onPolicyNumberClick } = props;
  const policyNumberRef = useRef(null);

  const handlePolicyNumberClick = () => {
    onPolicyNumberClick();
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(value);
    toast.success("Policy Number Copied", {
      autoClose: 1000,
    });
  };

  return (
    <div
      ref={policyNumberRef}
      style={{
        display: "flex",
        alignItems: "center",
        color: "var(--chakra-colors-linkColor)",
        cursor: "pointer",
        textDecoration: "underline",
      }}
      onClick={handlePolicyNumberClick}
    >
      <div
        style={{
          marginRight: "8px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleCopyClick();
        }}
      >
        <FaRegCopy size={16} />
      </div>
      {value}
    </div>
  );
};

export default PolicyNumberCell;
