import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import MapComponent from "../../../../../components/MapComponent";
import { useDispatch, connect } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./address.scss";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useTranslation } from "react-i18next";
import "../../star.css";
import DateInput from "../../../create-star-quote/utils/DateInput";
import { formateDate } from "../../../../common/FormatDate";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";
import {Switch} from "@chakra-ui/react";

const ApplicantInfo = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [onLoad, setOnLoad] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameOnDec, setNameOnDec] = useState("");
  const [quoteData, setQuoteData] = useState(props?.quoteData);
  const [loadingStatus, setLoadingStatus] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [zipCode, setZipCode] = useState("");
  // const [zipData, setZipData] = useState([]);
  const [lat, setLat] = useState();
  const [long, setLong] = useState();

  // const changeZip = (e) => {
  //   setZipCode(e.target.value);
  // };

  useEffect(() => {
    setZipCode(props?.quoteData?.personAddr?.s_PostalCode);
    setLat(props?.quoteData?.personAddr?.s_Latitude);
    setLong(props?.quoteData?.personAddr?.s_Longitude);

    if (
      props?.quoteData?.personAddr?.s_StreetName &&
      !isNaN(parseInt(props?.quoteData?.personAddr?.s_StreetName))
    ) {
      let quoteDataVar = props.quoteData;
      const streetAddress =
        quoteDataVar?.personAddr?.s_StreetName?.split(/(\d+)/g);
      quoteDataVar.personAddr.s_HouseNo =
        streetAddress[1] || quoteDataVar.personAddr.s_HouseNo;
      quoteDataVar.personAddr.s_StreetName =
        streetAddress[2]?.trim() || quoteDataVar.personAddr.s_StreetName;
      dispatch(getEditEmptyQuoteData(quoteDataVar));
    }
  }, []);

  useEffect(() => {
    dispatch(getEditEmptyQuoteData(quoteData));
    //Applicant Name
    setFirstName(props?.quoteData?.personInfo?.s_FirstName);
    setMiddleName(props?.quoteData?.personInfo?.s_MiddleName);
    setLastName(props?.quoteData?.personInfo?.s_LastOrganizationName);
    setNameOnDec(props?.quoteData?.personInfo?.s_FullLegalName);

    if (props?.quoteData) {
      setLat(props?.quoteData?.personAddr?.s_Latitude);
      setLong(props?.quoteData?.personAddr?.s_Longitude);
    }
  }, [quoteData]);

  // useEffect(() => {
  //   var first = "";
  //   if (firstName != null) {
  //     first = firstName;
  //   }
  //   var last = "";
  //   if (lastName != null) {
  //     last = lastName;
  //   }
  //   var fullName = "";
  //   fullName = first + " " + last;
  //   if (onLoad != true) {
  //     setNameOnDec(fullName);
  //   }
  // }, [firstName, lastName]);

  // const zipCodeApi = () => {
  //   if (
  //     props?.quoteData?.personAddr?.s_PostalCode != zipCode &&
  //     zipCode != "" &&
  //     zipCode >= 1
  //   ) {
  //     setLoadingStatus(true);
  //     dispatch(getZipDetails("13", zipCode, "APPLICANT")).then((res) => {
  //       if (res?.data?.status == "Y") {
  //         if (res?.data?.count > 1) {
  //           setZipData(res?.data?.zipData);
  //           setLoadingStatus(false);
  //           setShowModal(true);
  //         } else {
  //           setZipDetailsData(res?.data?.zipData[0]);
  //           setLoadingStatus(false);
  //         }
  //       } else {
  //         setLoadingStatus(false);
  //         toast.error(res?.data?.errorMsg);
  //         setZipCode("");
  //       }
  //     });
  //   }
  // };

  // const setZipDetailsData = (data) => {
  //   var quoteDataVar = props?.quoteData;
  //   const tempArr = props?.quoteData;
  //   var zipAddrFor = props?.zipAddrFor;
  //   tempArr[zipAddrFor]["s_CountyName"] = data?.s_CountyName;
  //   quoteDataVar[zipAddrFor]["s_CountyName"] = data?.s_CountyName;
  //   quoteDataVar[zipAddrFor]["s_StateName"] = data?.s_StateCode;
  //   quoteDataVar[zipAddrFor]["s_CityName"] = data?.s_CityName;
  //   quoteDataVar[zipAddrFor]["s_PostalCode"] = zipCode;
  //   quoteDataVar[zipAddrFor]["n_CountyId_FK"] = data?.n_CountyId_FK;
  //   quoteDataVar[zipAddrFor]["n_CityId_FK"] = data?.n_CityId_PK;
  //   quoteDataVar[zipAddrFor]["n_StateId_FK"] = data?.n_StateId_PK;
  //   quoteDataVar[zipAddrFor]["n_CountryId_FK"] = data?.n_CountyId_FK;

  //   getMapDetails(quoteDataVar);
  // };

  // const getMapDetails = async (quoteDataVar) => {
  //   var zipAddrFor = props?.zipAddrFor;

  //   quoteDataVar[zipAddrFor]["s_Latitude"] = null;
  //   quoteDataVar[zipAddrFor]["s_Longitude"] = null;
  //   quoteDataVar[zipAddrFor]["s_PostalCodeSuffix"] = null;
  //   await dispatch(getPersonAddrWithMapData(quoteDataVar?.personAddr)).then(
  //     (res) => {
  //       if (res) {
  //         setLat(res?.data?.s_Latitude);
  //         setLong(res?.data?.s_Longitude);
  //         quoteDataVar[zipAddrFor]["s_Latitude"] = res?.data?.s_Latitude;
  //         quoteDataVar[zipAddrFor]["s_Longitude"] = res?.data?.s_Longitude;
  //         quoteDataVar[zipAddrFor]["s_PostalCodeSuffix"] =
  //           res?.data?.s_PostalCodeSuffix;

  //         const tempArr = props?.quoteData;
  //         tempArr[zipAddrFor]["s_Latitude"] = res?.data?.s_Latitude;

  //         setQuoteData(quoteDataVar);
  //         dispatch(getEditEmptyQuoteData(quoteDataVar));
  //         setShowModal(false);
  //       }
  //     }
  //   );
  // };

  const phoneNumberFormatter = (phoneNumber) => {
    if (!phoneNumber) return "";
    if (phoneNumber.startsWith("+1")) {
      phoneNumber = phoneNumber.slice(2);
    }
    return phoneNumber.replace(/[()\-\s]/g, "").trim();
  };

  const renderTootTip = (id) => {
    return (
      <>
      <ReactTooltip
        id={id}
        delayHide={500}
        effect="solid"
        className={id && "custom-tooltip"}
        place="right"
      >
        {id === "mailingAddress" && (
          <p fontSize={"15px"}>
            Same as the Property address used in the quote. will be assign to
            Primary Insured
          </p>
        )}
      </ReactTooltip>
      <style>
      {`
        .custom-tooltip {
          max-width: 400px;
        }
      `}
    </style>
    </>
    );
  };

  return (
    <LoadingOverlay>
      <Loader loading={loadingStatus} />
      {
        <>
          <Col>
            <MapComponent lat={lat} long={long} />
          </Col>
          <Row
            className="align-items-end"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(290px, 1fr))",
              gap: "0.5rem",
              columnGap: "0.25rem",
              paddingTop: "1rem",
            }}
          >
            <Col style={{ marginTop: "12px" }}>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("First Name")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={firstName}
                  name="s_FirstName"
                  className="starQuotePageInput"
                  title="personInfo"
                  // onChange={(e) => {
                  //   props?.handleChange(e);
                  //   setFirstName(e.target.value);
                  //   setOnLoad(false);
                  // }}
                  required
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Last Name")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={lastName}
                  className="starQuotePageInput"
                  name="s_LastOrganizationName"
                  title="personInfo"
                  // onChange={(e) => {
                  //   props?.handleChange(e);
                  //   setLastName(e.target.value);
                  //   setOnLoad(false);
                  // }}
                  required
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Name(s) On Dec")}:{" "}
                  <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={nameOnDec}
                  name="s_FullLegalName"
                  title="personInfo"
                  // onChange={(e) => {
                  //   props?.handleChange(e);
                  //   setNameOnDec(e.target.value);
                  // }}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Date Of Birth")}:{" "}
                  <span className="label-asterisk">*</span>
                </Form.Label>
                <DateInput
                  styleProps={{
                    border: "2px solid",
                    borderColor: "#9AA5B5",
                    borderRadius: "12px",
                    size: "lg",
                    readOnly: false,
                    backgroundColor: "#e9ecef",
                  }}
                  maxDate={new Date()}
                  value={
                    props?.quoteData?.personInfo?.d_BirthDate
                      ? formateDate(props?.quoteData?.personInfo?.d_BirthDate)
                      : ""
                  }
                  name="d_BirthDate"
                  title="personInfo"
                  // onChange={props?.handleChange}
                  // onChange={(value) => {
                  //   let quoteDataVar = quoteData;
                  //   quoteDataVar.personInfo.d_BirthDate = Moment(value)
                  //     .toISOString()
                  //     .split("T")[0];
                  //   dispatch(getEditEmptyQuoteData(quoteDataVar));
                  // }}
                  isDisabled={true}
                />
                {/* <Form.Control
                  className="starQuotePageInput"
                  type="date"
                  value={props?.quoteData?.personInfo?.d_BirthDate || ""}
                  name="d_BirthDate"
                  title="personInfo"
                  onChange={props?.handleChange}
                  disabled={props?.isBind}
                /> */}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Email Address")}:{" "}
                  <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={
                    props?.quoteData?.personEmailContact?.s_CommValue || ""
                  }
                  name="s_CommValue"
                  title="personEmailContact"
                  // onChange={props?.handleChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Phone Number")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <MaskedFormControl
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={
                    phoneNumberFormatter(
                      props?.quoteData?.personPhoneContact?.s_CommValue
                    ) || ""
                  }
                  mask="(111)111-1111"
                  name="s_CommValue"
                  title="personPhoneContact"
                  // onChange={props?.handleChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("House Number")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={
                    props?.quoteData &&
                    props?.quoteData?.personAddr &&
                    props?.quoteData?.personAddr?.s_HouseNo
                      ? props?.quoteData?.personAddr?.s_HouseNo
                      : ""
                  }
                  name="s_HouseNo"
                  title="personAddr"
                  // onChange={props?.handleChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Street Name")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={
                    props?.quoteData &&
                    props?.quoteData?.personAddr &&
                    props?.quoteData?.personAddr?.s_StreetName
                      ? props?.quoteData?.personAddr?.s_StreetName
                      : ""
                  }
                  name="s_StreetName"
                  title="personAddr"
                  // onChange={props?.handleChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Zip")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="tel" //to use the maxLength prop
                  maxLength="5"
                  placeholder=""
                  name="s_PostalCode"
                  title="personAddr"
                  //  oonChange={(e) => changeZip(e)}
                  value={isNaN(parseInt(zipCode)) ? "" : parseInt(zipCode)}
                  // onBlur={() => zipCodeApi()}
                  required
                  // onChange={(e) => changeZip(e)}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("County")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  value={
                    props?.quoteData &&
                    props?.quoteData?.personAddr &&
                    props?.quoteData?.personAddr?.s_CountyName
                      ? props?.quoteData?.personAddr?.s_CountyName
                      : ""
                  }
                  name="s_CountyName"
                  title="personAddr"
                  // onChange={props?.handleChange}
                  disabled
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("State")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  disabled
                  value={
                    props?.quoteData &&
                    props?.quoteData?.personAddr &&
                    props?.quoteData?.personAddr?.s_StateName
                      ? props?.quoteData?.personAddr?.s_StateName
                      : ""
                  }
                  name="s_StateName"
                  title="personAddr"
                  // onChange={props?.handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("City")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="text"
                  placeholder=""
                  disabled
                  value={
                    props?.quoteData &&
                    props?.quoteData?.personAddr &&
                    props?.quoteData?.personAddr?.s_CityName
                      ? props?.quoteData?.personAddr?.s_CityName
                      : ""
                  }
                  name="s_CityName"
                  title="personAddr"
                  // onChange={props?.handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label className="dashboard-group-label">
                  {t("Credit Score")}: <span className="label-asterisk">*</span>
                </Form.Label>
                <Form.Control
                  className="starQuotePageInput"
                  type="number"
                  placeholder=""
                  value={
                    props?.quoteData?.personInfo?.metadata?.creditScore || ""
                  }
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      props?.handleChange(e);
                    }
                  }}
                  name="creditScore"
                  title="personInfo"
                  disabled={true}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group
                // className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Form.Label className="dashboard-group-label">
                    {t("Is mailing address same as location address?")}{" "}
                    
                    <span className="label-asterisk">*</span> 
                    <a
                      style={{
                        marginBottom: "5px",
                        cursor: "pointer",
                        display: "inline-flex",
                        marginTop: "5px",
                        marginLeft: "5px",
                        verticalAlign: "middle",


                      }}
                      data-tip
                      data-for={"mailingAddress"}
                    >
                      {" "}
                      <div ml={1}>
                        <FcInfo size={18} />
                      </div>
                      {renderTootTip("mailingAddress")}
                    </a>
                    </Form.Label>
                    </div>
                   
               
                <div
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  className="custom-radio-check0"
                >

                  <Form.Check
                    type="radio"
                    label="Yes"
                    style={{ marginRight: "10%" }}
                    value={"NO"}
                    checked={
                      props?.quoteData?.personInfo?.s_IsMailingDiff === "NO"
                        ? true
                        : false
                    }
                    name="s_IsMailingDiff"
                    id="personInfo"
                    onChange={props?.handleRadioChange}
                    disabled={props?.isBind}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    value={"YES"}
                    checked={
                      props?.quoteData?.personInfo?.s_IsMailingDiff === "YES"
                        ? true
                        : false
                    }
                    name="s_IsMailingDiff"
                    id="personInfo"
                    onChange={props?.handleRadioChange}
                    disabled={props?.isBind}
                  />
                </div>
              </Form.Group>
            </Col>

            {/* Modal */}
            {/* <Modal size="md" show={showModal} className="add-mortage-modal">
              <Modal.Header id="add-mortage-modal-header">
                <Modal.Title>Select City</Modal.Title>
              </Modal.Header>
              <Modal.Body className="add-mortage-modal-body">
                <Row>
                  <Table className="p-0 m-0" size="sm" responsive striped>
                    <thead>
                      <tr>
                        <th>City</th>
                        <th>County</th>
                      </tr>
                    </thead>
                    <tbody>
                      {zipData?.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <a
                                href="#"
                                onClick={() => setZipDetailsData(data)}
                              >
                                {data?.s_CityName}
                              </a>
                            </td>
                            <td>{data?.s_CountyName}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </Modal.Body>
            </Modal> */}
          </Row>
        </>
      }
    </LoadingOverlay>
  );
};
function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(ApplicantInfo);
