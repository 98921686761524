export const HomeOwnersTerretory = {
  County: [
    { Adams: "5" },
    { Alcorn: "7" },
    { Amite: "4" },
    { Attala: "7" },
    { Benton: "7" },
    { Bolivar: "7" },
    { Calhoun: "7" },
    { Carroll: "7" },
    { Chicksaw: "7" },
    { Choctaw: "7" },
    { Claiborne: "6" },
    { Clarke: "6" },
    { Clay: "7" },
    { Coahoma: "7" },
    { Copiah: "6" },
    { Covington: "5" },
    { Desoto: "7" },
    { Forrest: "4" },
    { Franklin: "5" },
    { George: "3" },
    { Greene: "4" },
    { Grenada: "7" },
    { Hancock: "2", northIssed: true },
    { Harrison: "2", northIssed: true },
    { Hinds: "6" }, // under discussion
    { Holmes: "7" },
    { Humphreys: "7" },
    { Issaquena: "6" },
    { Itawamba: "7" },
    { Jackson: "2", northIssed: true },
    { Jasper: "6" },
    { Jefferson: "6" },
    { "Jefferson Davis": "5" },
    { Jones: "5" },
    { Kemper: "6" },
    { Lafayette: "7" },
    { Lamar: "4" },
    { Lauderdale: "6" },
    { Lawrence: "5" },
    { Leake: "6" },
    { Lee: "7" },
    { Leflore: "7" },
    { Lincoln: "5" },
    { Lowndes: "7" },
    { Madison: "6" },
    { Marion: "4" },
    { Marshall: "7" },
    { Monroe: "7" },
    { Montgomery: "7" },
    { Neshoba: "6" },
    { Newton: "6" },
    { Noxubee: "7" },
    { Oktibbeha: "7" },
    { Panola: "7" },
    { "Pearl River": "3" },
    { Perry: "4" },
    { Pike: "4" },
    { Pontotoc: "7" },
    { Prentiss: "7" },
    { Quitman: "7" },
    { Rankin: "6" },
    { Scott: "6" },
    { Sharkey: "6" },
    { Simpson: "6" },
    { Smith: "6" },
    { Stone: "3" },
    { Sunflower: "7" },
    { Tallahatchie: "7" },
    { Tate: "7" },
    { Tippah: "7" },
    { Tishomingo: "7" },
    { Tunica: "7" },
    { Union: "7" },
    { Walthall: "4" },
    { Warren: "6" },
    { Washington: "7" },
    { Wayne: "5" },
    { Webster: "7" },
    { Wilkinson: "4" },
    { Winston: "7" },
    { Yalobusha: "7" },
    { Yazoo: "6" },
  ],
};
