import React from 'react';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import './page.scss';

const Page419 = () => {
    const navigation = useNavigate();
    return (
        <div className="page-section">
            <Form>
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="8" lg="6" xl="5">
                        <div className="page-card text-center">
                            <h1>419</h1>
                            <h4>Oops! Link expired.</h4>
                            The page you are looking for was expired.
                            <Button type='button' className='mt-2' color='primary' onClick={() => navigation('/login')}>Go to Home</Button>
                        </div>
                    </Col>
                </Row>
                <br />
            </Form>
        </div>
    )
}

export default Page419