import React from "react";
import { Premium } from "../Premium/Premium";
import { Box, Card } from "@chakra-ui/react";

const StepPremiumDetails = () => {
  return (
    <Box mt={2}>
      <Card p={"2rem"} borderRadius="2xl" >
          <Premium />
      </Card>
    </Box>
  );
};

export default StepPremiumDetails;