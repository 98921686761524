import { Badge, Box, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
// import man from "../../../assets/manWithClac.png";
import Autocomplete from "./support/Autocomplete";
import al3 from "../help/Agency_Management_AL3.pdf";
import profile from "../help/Agency_Management_Profile.pdf";
import CELicence from "../help/Agency_Management_CE.pdf";
import commission from "../help/Agency_Management_Commission.pdf";
import claimSystem from "../help/Claims_System_Create_Claim.pdf";
import claimSearch from "../help/Claims_System_Claim_Search.pdf";
import claimView from "../help/Claims_System_Claim_View.pdf";
import policyCancellations from "../help/Policy_System_Cancellations.pdf";
import policyQuote from "../help/Policy_System_Quote.pdf";
import policyMoneyEndosement from "../help/Policy_System_Money_Endorsements.pdf";
import { policySearch } from "./s3Pdf.js";
import policyNonMoneyEndosement from "../help/Policy_System_NonMoney_Endorsements.pdf";
import policyTransfer from "../help/Policy_System_Transfer.pdf";
import balance from "../help/Agency_Management-Negative_Balance.pdf";
import { useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import { useTranslation } from "react-i18next";

export default function Help2() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const [selectedObj, setSelectedObj] = useState({});
  const helpSearch = [
    //AL3 Docs
    {
      label: "Agency Downloads",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "AL3",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "IVANS",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "Policy Downloads",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "Commission Downloads",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "AL3 Management",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "IVANs Management",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "Policy Download Management",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "Commission Download Management",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },
    {
      label: "AL3 System",
      card: "Agency_Management",
      cardId: "4",
      subSection: "AL3",
      section: "AgencyManagementSection",
    },

    //CE Docs
    {
      label: "Edit CE License",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },
    {
      label: "Manage CE License",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },
    {
      label: "Update CE License",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },
    {
      label: "Continuing Education",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },
    {
      label: "CE License",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },
    {
      label: "CE",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CELicense",
      section: "AgencyManagementSection",
    },

    //Commission
    {
      label: "Commission Statement",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CommissionStatement",
      section: "AgencyManagementSection",
    },
    {
      label: "Commissions",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CommissionStatement",
      section: "AgencyManagementSection",
    },
    {
      label: "Monthly Commission",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CommissionStatement",
      section: "AgencyManagementSection",
    },

    // Commission Negative balance
    {
      label: "Balance Due",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CommissionStatementBalance",
      section: "AgencyManagementSection",
    },
    {
      label: "Negative Balance",
      card: "Agency_Management",
      cardId: "4",
      subSection: "CommissionStatementBalance",
      section: "AgencyManagementSection",
    },

    //Update Profile
    {
      label: "Edit contact information",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Update Email",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Update Number",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Update Phone",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Update Telephone",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Edit producer",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Edit user",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Manage producer",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },
    {
      label: "Manage user",
      card: "Agency_Management",
      cardId: "4",
      subSection: "UpdateProfile",
      section: "AgencyManagementSection",
    },

    //Create a Claim
    {
      label: "Create a Flood Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "CreateClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Report Flood Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "CreateClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Create a Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "CreateClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Report Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "CreateClaim",
      section: "ClaimsSystemSection",
    },

    //Search a Claim
    {
      label: "Find a Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "SearchClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Locate a Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "SearchClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Search a Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "SearchClaim",
      section: "ClaimsSystemSection",
    },

    //View a Claim
    {
      label: "Claim Status",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Description of loss",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Event Name",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Payments",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Read the Ledger",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Representation",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Review Reserves",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "Vendors",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },
    {
      label: "View a Claim",
      card: "Create_Claim",
      cardId: "3",
      subSection: "ViewClaim",
      section: "ClaimsSystemSection",
    },

    //Cancel a Policy
    {
      label: "Cancel a Client",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Cancel a Customer",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Cancel a Policy",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Cancellation",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Cancel Policy",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Building Sold",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Contents Sold",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Property closing did not occur",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Policy canceled and rewritten",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Condominium converting to RCBAP",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Duplicate policy from source other than NFIP",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Insurance no longer required by lender",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Invalid payment",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Nullification",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Nullification prior to policy effective date",
      card: "Cancel_Policy",
      cardId: "2",
      subSection: "CancelPolicy",
      section: "PolicySystemSection",
    },

    //Create a Quote
    {
      label: "Create a Client",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Create a Customer",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Create a Policy",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Write a Policy",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "New Policy",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Home",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Mobile Home",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Manufactured Home",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Unit",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Residential Building",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Quote Non-residential Building",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Residential",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Non-Residential",
      card: "Create_Quote",
      cardId: "2",
      subSection: "CreateQuote",
      section: "PolicySystemSection",
    },

    //Endorse a Policy
    {
      label: "Endorse a Client",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Endorse a Customer",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Endorse a Policy",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Coverage",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Building Coverage",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Property Coverage",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Contents Coverage",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Deductible",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Building Deductible",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Contents Deductible",
      card: "Endorse_Policy",
      cardId: "2",
      subSection: "EndorsePolicy",
      section: "PolicySystemSection",
    },

    //Find a Policy
    {
      label: "Find a Client",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Find a Customer",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Find a Policy",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Flood Policy",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Locate a Client",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Locate a Customer",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Locate a Policy",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Payment",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Search a Client",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Search a Customer",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "Search a Policy",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "View a Client",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "View a Customer",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },
    {
      label: "View a Policy",
      card: "Find_Policy",
      cardId: "2",
      subSection: "FindPolicy",
      section: "PolicySystemSection",
    },

    //Non Money Endorsement
    {
      label: "Mortgagee",
      card: "Non_Money",
      cardId: "2",
      subSection: "NonMoney",
      section: "PolicySystemSection",
    },
    {
      label: "Lender",
      card: "Non_Money",
      cardId: "2",
      subSection: "NonMoney",
      section: "PolicySystemSection",
    },
    {
      label: "Bank",
      card: "Non_Money",
      cardId: "2",
      subSection: "NonMoney",
      section: "PolicySystemSection",
    },
    {
      label: "Loan",
      card: "Non_Money",
      cardId: "2",
      subSection: "NonMoney",
      section: "PolicySystemSection",
    },
    {
      label: "Update Insured Contact Info",
      card: "Non_Money",
      cardId: "2",
      subSection: "NonMoney",
      section: "PolicySystemSection",
    },

    //Transfer Quote
    {
      label: "Transfer Quote",
      card: "Transfer_Quote",
      cardId: "2",
      subSection: "TransferQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Rollover Quote",
      card: "Transfer_Quote",
      cardId: "2",
      subSection: "TransferQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Transfer",
      card: "Transfer_Quote",
      cardId: "2",
      subSection: "TransferQuote",
      section: "PolicySystemSection",
    },
    {
      label: "Rollover",
      card: "Transfer_Quote",
      cardId: "2",
      subSection: "TransferQuote",
      section: "PolicySystemSection",
    },
  ];
  const handleSearchClick = (value) => {
    let node = helpSearch.find((a) => a.label === value);
    // setSelectedObj(node);

    if (node.card === "Agency_Management" && node.subSection === "AL3") {
      window.open(al3);
    } else if (
      node.card === "Agency_Management" &&
      node.subSection === "CELicense"
    ) {
      window.open(CELicence);
    } else if (
      node.card === "Agency_Management" &&
      node.subSection === "CommissionStatement"
    ) {
      window.open(commission);
    } else if (
      node.card === "Agency_Management" &&
      node.subSection === "UpdateProfile"
    ) {
      window.open(profile);
    } else if (
      node.card === "Create_Claim" &&
      node.subSection === "CreateClaim"
    ) {
      window.open(claimSystem);
    } else if (
      node.card === "Create_Claim" &&
      node.subSection === "SearchClaim"
    ) {
      window.open(claimSearch);
    } else if (
      node.card === "Create_Claim" &&
      node.subSection === "ViewClaim"
    ) {
      window.open(claimView);
    } else if (
      node.card === "Cancel_Policy" &&
      node.subSection === "CancelPolicy"
    ) {
      window.open(policyCancellations);
    } else if (
      node.card === "Create_Quote" &&
      node.subSection === "CreateQuote"
    ) {
      window.open(policyQuote);
    } else if (
      node.card === "Endorse_Policy" &&
      node.subSection === "EndorsePolicy"
    ) {
      window.open(policyMoneyEndosement);
    } else if (
      node.card === "Find_Policy" &&
      node.subSection === "FindPolicy"
    ) {
      window.open(policySearch);
    } else if (node.card === "Non_Money" && node.subSection === "NonMoney") {
      window.open(policyNonMoneyEndosement);
    } else if (
      node.card === "Transfer_Quote" &&
      node.subSection === "TransferQuote"
    ) {
      window.open(policyTransfer);
    } else if (
      node.card === "Agency_Management" &&
      node.subSection === "CommissionStatementBalance"
    ) {
      window.open(balance);
    }

    navigate(`/${node.section}/${node.cardId}`);
  };

  return (
    <>
      <Box
        boxShadow={"lg"}
        pb={2}
        pos={"relative"}
        backgroundImage={"/assets/images/manWithCalc.png"}
        objectFit={"cover"}
        backgroundRepeat={"no-repeat"}
        objectPosition={"center"}
        borderRadius={"2xl"}
        height={"100%"}
        width={"100%"}
      >
        <Text
          color={"white"}
          fontWeight={700}
          fontSize={"2xl"}
          ml={8}
          mb={4}
          pt={8}
          pl={4}
        >
          {t("how_can_we_help_you")}?
        </Text>
        <Box borderRadius={"2xl"} m={8} mt={2} pl={4}>
          <CiSearch
            size={25}
            style={{
              position: "absolute",
              top: "90px",
              left: "60px",
              color: "lightgray",
              zIndex: "1",
            }}
          />
          <Autocomplete
            type="text"
            style={{ color: "white" }}
            placeholder={t("Search")}
            suggestions={[
              "Agency Downloads",
              "AL3",
              "IVANS",
              "Policy Downloads",
              "Commission Downloads",
              "AL3 Management",
              "IVANs Management",
              "Policy Download Management",
              "Commission Download Management",
              "AL3 System",
              "Event Name",
              "Payments",
              "Read the Ledger",
              "Representation",
              "Review Reserves",
              "Vendors",
              "View a Claim",
              "Cancel a Client",
              "Edit CE License",
              "Manage CE License",
              "Update CE License",
              "Continuing Education",
              "CE License",
              "CE",
              "Commission Statement",
              "Commissions",
              "Monthly Commission",
              "Balance Due",
              "Negative Balance",
              "Edit contact information",
              "Update Email",
              "Update Number",
              "Update Phone",
              "Update Telephone",
              "Edit producer",
              "Edit user",
              "Manage producer",
              "Manage user",
              "Create a Flood Claim",
              "Report Flood Claim",
              "Create a Claim",
              "Report Claim",
              "Find a Claim",
              "Locate a Claim",
              "Claim Status",
              "Description of loss",
              "Cancel a Customer",
              "Cancel a Policy",
              "Cancellation",
              "Cancel Policy",
              "Building Sold",
              "Contents Sold",
              "Property closing did not occur",
              "Policy canceled and rewritten",
              "Duplicate NFIP policy",
              "Condominium converting to RCBAP",
              "Duplicate policy from source other than NFIP",
              "Insurance no longer required by lender",
              "Invalid payment",
              "Nullification",
              "Nullification prior to policy effective date",
              "Create a Client",
              "Create a Customer",
              "Create a Policy",
              "Write a Policy",
              "New Policy",
              "Quote Home",
              "Quote Mobile Home",
              "Quote Manufactured Home",
              "Quote Unit",
              "Quote Residential Building",
              "Quote Non-residential Building",
              "Residential",
              "Non-Residential",
              "Endorse a Client",
              "Endorse a Customer",
              "Endorse a Policy",
              "Coverage",
              "Building Coverage",
              "Property Coverage",
              "Contents Coverage",
              "Deductible",
              "Building Deductible",
              "Contents Deductible",
              "Find a Client",
              "Find a Customer",
              "Find a Policy",
              "Flood Policy",
              "Locate a Client",
              "Locate a Customer",
              "Locate a Policy",
              "Payment",
              "Search a Client",
              "Search a Customer",
              "Search a Policy",
              "View a Client",
              "View a Customer",
              "View a Policy",
              "Mortgagee",
              "Lender",
              "Bank",
              "Loan",
              "Update Insured Contact Info",
              "Transfer Quote",
              "Rollover Quote",
              "Transfer",
              "Rollover",
            ]}
            handleSearchClick={handleSearchClick}
          />
        </Box>
        <Flex gap={4} alignItems={"center"}>
          <Text color={"white"} fontWeight={600} ml={4} pl={8} fontSize={"md"}>
            {t("common_search")}
          </Text>
          <Link href={policyQuote} target="_blank">
            <Badge
              as={Text}
              textTransform={"none"}
              borderRadius={"6px"}
              bg={"#4f5563"}
              color={"#c5c9cc"}
              p={"6px"}
              fontWeight={500}
              fontSize={"sm"}
              textDecoration={"underline"}
              cursor={"pointer"}
              _hover={{ textDecoration: "none", color: "white" }}
              px={4}
            >
              {t("create_a_policy")}
            </Badge>
          </Link>
          <Link href={claimSystem} target="_blank">
            <Badge
              as={Text}
              textTransform={"none"}
              borderRadius={"6px"}
              bg={"#4f5563"}
              color={"#c5c9cc"}
              p={"6px"}
              fontWeight={500}
              cursor={"pointer"}
              textDecoration={"underline"}
              fontSize={"sm"}
              _hover={{ textDecoration: "none", color: "white" }}
              px={4}
            >
              {t("Open a Claim")}
            </Badge>
          </Link>
          <Link href={policySearch} target="_blank">
            <Badge
              as={Text}
              textTransform={"none"}
              borderRadius={"6px"}
              bg={"#4f5563"}
              color={"#c5c9cc"}
              p={"6px"}
              fontWeight={500}
              cursor={"pointer"}
              textDecoration={"underline"}
              fontSize={"sm"}
              _hover={{ textDecoration: "none", color: "white" }}
              px={4}
            >
              {t("find_a_policy")}
            </Badge>
          </Link>
        </Flex>
      </Box>
    </>
  );
}
