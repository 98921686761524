import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

export async function getGeoCode(description, selectedPropertyData) {
  return geocodeByAddress(description).then((addr) => {
    const propData = { ...selectedPropertyData };
    addr &&
      addr[0] &&
      addr[0].address_components.forEach((e) => {
        if (e.types.includes("postal_code")) {
          propData.postalCode = e.short_name;
        } else if (e.types.includes("street_number")) {
          propData.streetNumber = e.short_name;
        } else if (e.types.includes("route")) {
          propData.streetName = e.short_name;
        } else if (
          e.types.includes("locality") &&
          e.types.includes("political")
        ) {
          propData.city = e.short_name;
        } else if (
          e.types.includes("country") &&
          e.types.includes("political")
        ) {
          propData.country = e.short_name;
        } else if (
          e.types.includes("administrative_area_level_2") &&
          e.types.includes("political")
        ) {
          propData.countyName = e.short_name;
        } else if (
          e.types.includes("administrative_area_level_1") &&
          e.types.includes("political")
        ) {
          propData.stateCode = e.short_name;
        } else if (e.types.includes("postal_code_suffix")) {
          propData.zipPlus4 = e.short_name;
          propData.postalCodeSuffix = e.short_name;
        }
      });
    propData.addressLine = addr[0] ? addr[0].formatted_address : "";
    if (addr && addr[0]) {
      getLatLng(addr[0]).then(({ lat, lng }) => {
        propData.latitude = lat.toFixed(7);
        propData.longitude = lng.toFixed(7);
      });
    }

    return propData;
  });
}
