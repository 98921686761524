import React, { createContext, useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import "react-overlay-loader/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStarComBaseData } from "./Api/starCommercialDD";
import { postCommercialQuote } from "./Api/postCommercialQuote";
import { useTranslation } from "react-i18next";
import useAutoEastedHook from "../../common/AutoLocationDate";
import ChakraLoader from "../../create-star-quote/utils/ChakraLoader";
import moment from "moment";
import { getCommercialQuote } from "./Api/getCommercialQuote";
import { bindStarQuote } from "../../create-star-quote/apis/bindStarQuote";

export const starCommercialContext = createContext();

function parsePhoneNumber(input) {
  if (!input) return "";
  const digitsOnly = input.replace(/\D/g, "");
  return digitsOnly;
}

const CommercialContext = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { quoteNumber } = useParams();
  const [callPostQuote, setCallPostQuote] = useState(false);
  const [commercialData, setCommercialData] = useState({
    quoteNo: quoteNumber || "",
    binderDate: "",
    effectiveFromDate: "",
    effectiveToDate: "",
    applicantDetail: {},
    policySummaryDetails: {},
    propertyDetail: [{}],
  });
  const [accordiansArray, setAccordianArray] = useState([0, 1, 2, 3]);
  const defaultAccordion = [0, 1, 2, 3];
  const [isQuoteSaved, setIsQuoteSaved] = useState(false);
  const [quoteData, setQuoteData] = useState({});
  const [appStatus, setAppStatus] = useState([]);
  const [agentNameData, setAgentNameData] = useState([]);
  const [isBind, setIsBind] = useState(false);
  const [loading, setLoading] = useState(false);
  const { locationData, autoGenData, autoGenLoading } = useAutoEastedHook({
    isEnabled: !quoteNumber,
  });

  const postQuote = useCallback(async () => {
    const requestBody = commercialData || {};
    const toastId = toast.loading(
      quoteNumber ? t("Updating Quote...") : t("Creating Quote..."),
      {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "createQuoteLoading",
        autoClose: 3000,
      }
    );
    try {
      setLoading(true);

      const response = await postCommercialQuote(quoteNumber, requestBody);
      if (response?.status === true) {
        toast.update(toastId, {
          render: quoteNumber
            ? t("Quote Updated Successfully")
            : t("Quote Created Successfully"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
          toastId: "createQuoteSuccess",
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.update(toastId, {
          render:
            response?.message ||
            t(`Failed to ${quoteNumber ? "update" : "create"} quote`),
          type: "error",
          isLoading: false,
          autoClose: 2000,
          toastId: "createQuoteError1",
        });
      }
      if (response?.data?.policyNo && response?.status && !quoteNumber) {
        setCommercialData(() => ({
          ...commercialData,
          quoteNo: response?.data?.policyNo,
        }));
        setTimeout(() => {
          navigate(`/quote/starcom/${response?.data?.policyNo}`);
        }, 2000);
      }
    } catch (error) {
      toast.update(toastId, {
        render: t("Failed to create quote"),
        type: "error",
        isLoading: false,
        autoClose: 2000,
        toastId: "createQuoteError",
      });
    } finally {
      setCallPostQuote(false);
      setLoading(false);
      if (commercialData?.quoteNo) {
        fetchCommercialData();
      }
    }
  }, [quoteNumber, commercialData]);

  useEffect(() => {
    if (callPostQuote) {
      postQuote();
    }
  }, [callPostQuote, postQuote]);

  const fetchCommercialData = async () => {
    try {
      const response = await getCommercialQuote(quoteNumber);

      if (response?.data) {
        const {
          selectedAgency,
          selectedAgent,
          transaction,
          person,
          phoneNumber,
          email,
          mailing,
          property,
          quoteId,
          aopDeductible,
          hurricaneDeductible,
          coveredPerils,
          payPlan,
          productCode,
          policySummaryDetails,
          estimatedPremium,
          uwStatusData,
          mortgagees,
        } = response.data || {};
        setIsBind(
          moment(response?.data?.quoteBindDate).isValid() ? true : false
        );
        setCommercialData((prevData) => ({
          ...response?.data,
          ...prevData,
          effectiveToDate: moment(transaction?.effectiveTo).format(
            "YYYY-MM-DD"
          ),
          effectiveFromDate: moment(transaction?.effectiveFrom).format(
            "YYYY-MM-DD"
          ),
          quoteNo: quoteId,
          // binderDate: "",
          // estimatedPremium: 10000,
          appStatus: uwStatusData?.s_UWAppStatusCode || "",
          estimatedPremium: estimatedPremium,
          aopDeductible,
          hurricaneDeductible,
          coveredPerils,
          payPlan,
          productCode,
          applicantDetail: {
            ...prevData?.applicantDetail,
            lastName: person?.lastName,
            firstName: person?.firstName,
            phone: parsePhoneNumber(phoneNumber),
            email,
            address: mailing?.addressLine1,
            city: mailing?.city,
            state: mailing?.stateCode,
            zip: mailing?.zip,
            county: mailing?.county,
            entityType: person?.entityType,
            buissinessEstablishment: person?.metadata?.buissinessEstablishment,
            blanket: person?.metadata?.blanket === "YES" ? "YES" : "NO",
          },
          policySummaryDetails: {
            COMMBLDCVGAMT: policySummaryDetails?.buildingCoverageAmount ?? 0,
            COMMCONTCVGAMT: policySummaryDetails?.contentCoverageAmount ?? 0,
            COMMVANDAEXCL: policySummaryDetails?.theftExclusion || "NO",
            COMMSINKCVG: policySummaryDetails?.sinkholeCoverage || "NO",
            COMMWINDHAILEXC:
              policySummaryDetails?.windStormAndHailCvgExclusion || "NO",
            ...policySummaryDetails,
          },
          selectedAgency: {
            NPN: "",
            selected: true,
            value: null,
            ...selectedAgency,
          },
          selectedAgent: {
            NPN: "",
            selected: true,
            state: "",
            value: null,
            ...selectedAgent,
            label: selectedAgent?.label,
          },
          propertyDetail: property?.propertyInfoData?.map((e) => {
            const { address, property, propertyDetailsMappingPK, coverage } = e;
            return {
              address: address?.addressLine1,
              zip: address?.zip,
              city: address?.city,
              state: address?.stateCode,
              county: address?.county,
              ...property?.metadata,
              roofShape: property?.s_RoofShapeCode || "",
              roofAttach: property?.s_RoofDeckAttachCode || "",
              bceg: property?.s_BCEG || "",
              // noOfUnits: property?.n_NoOfUnits || "",
              yearBuilt: property?.n_YearBuilt || "",
              // noOfStories: property?.n_NoOfStories || "",
              distanceToFire: property?.n_DistanceToFire || "",
              distanceToHydrant: property?.n_DistanceToHydrant || "",
              surfaceRoughness: property?.n_SurfaceRoughness || "",
              ppc: property?.n_PPC || "",
              // constructionType: property?.s_ConstructionType || "",
              fireAlarmCode: property?.s_FireAlarmCode || "",
              sprinklerCode: property?.s_SprinklerCode || "",
              burglarAlarmCode: property?.s_BurglarAlarmCode || "",
              priorClaimCount: property?.n_PriorClaimCount || 0,
              renewalCount: property?.n_RenewalCount || 0,
              roofShapeCode: property?.s_RoofShapeCode || "",
              roofDeckAttachCode: property?.s_RoofDeckAttachCode || "",
              roofCoverCode: property?.s_RoofCoverCode || "",
              roofWallConnectCode: property?.s_RoofWallConnectCode || "",
              sinkholeRiskRelativity: property?.n_SinkholeRiskRelativity || "",
              roofType: property?.s_RoofType || "",
              occupancy: property?.s_Occupancy || "",
              propertyDetailsMappingPK: propertyDetailsMappingPK || null,
              locationIdentifier: address?.houseType || "",
              construction: property?.s_ConstructionType || "",
              roofWall: property?.s_RoofWallConnectCode || "",
              locationDistanceToCoast:
                property?.metadata?.n_DitanceToCost || "",
              roofCover: property?.s_RoofCoverCode || "",
              fireAlarm: property?.s_FireAlarmCode || "",
              burglarAlarm: property?.s_BurglarAlarmCode || "",
              distanceFromTheClosestBuildingInFeet:
                property?.n_DistanceToHydrant || "",
              buildingCoverage: coverage?.n_BuildingCvg || "",
              contentCoverage: coverage?.n_ContentCvg || "",
              n_PropertyCoverages_PK: coverage?.n_PropertyCoverages_PK || null,
              coveredPerils,
            };
          }),
          uwStatusData:
            {
              ...uwStatusData,
            } || {},
          mortgagees: mortgagees || [],
          removedProperties: [],
        }));
      }
    } catch (error) {
      console.error("Error fetching commercial quote:", error);
      toast.error("Failed to fetch commercial quote");
    }
  };

  useEffect(() => {
    if (quoteNumber) {
      fetchCommercialData();
    }
  }, [quoteNumber]);

  const bindComQuote = async () => {
    setLoading(true);
    try {
      const res = await bindStarQuote("commercial", quoteNumber, {});
      if (res && res?.core_service_response) {
        toast.success(t("Quote Bound Successfully"), {
          toastId: "bind",
          autoClose: 2500,
        });
      } else if (res && !res?.core_service_response) {
        toast.error(t("Something went wrong, please try again later"), {
          toastId: "bind",
          autoClose: 2500,
        });
      }
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "unknown error occurred",
        {
          toastId: "bind",
          autoClose: 2500,
        }
      );
    } finally {
      setLoading(false);
      fetchCommercialData();
    }
  };

  useEffect(() => {
    const {
      streetNumber,
      streetName,
      city,
      countyName,
      postalCode,
      country,
      latitude,
      longitude,
      // value,
      fullAddress,
      state,
      postalCodeSuffix,
      aptNumber,
      // stateCode,
      validStateCode,
    } = locationData || {};

    if (!quoteNumber && locationData && Object.keys(locationData).length > 0) {
      const addressLine1 = aptNumber
        ? `${streetNumber} ${streetName}, Apt ${aptNumber}`
        : `${streetNumber} ${streetName}`;

      const addressLine2 = countyName
        ? `${countyName}, ${state} ${postalCode} ${postalCodeSuffix || ""}`
        : `${state} ${postalCode}-${postalCodeSuffix || ""}`;

      setCommercialData({
        ...commercialData,
        effectiveFromDate: moment().add(30, "days").format("YYYY-MM-DD"),
        effectiveToDate: moment()
          .add(1, "year")
          .add(30, "days")
          .format("YYYY-MM-DD"),
        applicantDetail: {
          ...commercialData.applicantDetail,
          address: addressLine1 + " " + addressLine2 || fullAddress || "",
          city: city,
          state: state,
          zip: postalCode,
          stateCode: validStateCode,
          county: countyName,
          country,
          latitude,
          longitude,
          lastName:
            autoGenData?.PropDetails &&
            typeof autoGenData?.PropDetails?.OWN_NAME === "string"
              ? autoGenData?.PropDetails?.OWN_NAME
              : "",
        },
      });
    }
  }, [locationData, quoteNumber]);

  const handleSubmitQuote = () => {
    if (!commercialData?.applicantDetail?.lastName) {
      toast.error(t("Insured Name is Required"), {
        toastId: "nameError",
      });
      return;
    }
    postQuote();
  };

  const submitBound = () => {
    toast.success("Quote Bound successfull", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "boundSuccess",
    });
  };

  const submitUnBound = () => {
    toast.warning("Quote UnBound successfull", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "unboundSuccess",
    });
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchStarComBaseData();
      if (data) {
        setQuoteData(data || {});
        setAppStatus(data.UWAPPSTATUS || []);
        setAgentNameData(data.AgentDropDown?.data || []);
      } else {
        toast.error("Failed to fetch data", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const value = {
    commercialData,
    setCommercialData,
    isQuoteSaved,
    setIsQuoteSaved,
    handleSubmitQuote,
    submitBound,
    submitUnBound,
    quoteData,
    appStatus,
    agentNameData,
    loading,
    setLoading,
    callPostQuote,
    setCallPostQuote,
    accordiansArray,
    setAccordianArray,
    defaultAccordion,
    autoGenData,
    quoteNumber,
    bindComQuote,
    isBind,
  };

  return (
    <starCommercialContext.Provider value={value}>
      <ChakraLoader isLoading={loading || autoGenLoading}>
        {children}
      </ChakraLoader>
    </starCommercialContext.Provider>
  );
};

export default CommercialContext;
