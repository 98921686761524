import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";

const GoogleMaps = () => {
  const { locationQuoteObject } = useMyContext(CreateQuoteContext);

  const containerStyle = {
    width: "100%",
    minHeight: "30vh",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [position, setPosition] = useState({ lat: "", lng: "" });

  useEffect(() => {
    var lat = 0;
    var long = 0;

    if (Object.keys(locationQuoteObject).length > 0) {
      lat = locationQuoteObject.latitude;
      long = locationQuoteObject.longitude;

      setPosition({ lat: parseFloat(lat), lng: parseFloat(long) });
    }
  }, [locationQuoteObject]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={18}
      mapTypeId="satellite"
      label={true}
    >
      <Marker position={position} />
    </GoogleMap>
  ) : null;
};

export default GoogleMaps;
