import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const GoogleMaps = ({ from = 'INSTANT_QUOTE' }) => {
  const { propertyDataAutocompleteStored, emptyQuoteData, apiLoading } = useSelector(state => state.flood);

  const containerStyle = {
    width: '100%',
    height: '232px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  })

  const [position, setPosition] = useState({ 'lat': '', 'lng': '' });

  useEffect(() => {
    var lat = 0;
    var long = 0;

    if (Object.keys(propertyDataAutocompleteStored).length > 0) {
      lat = propertyDataAutocompleteStored.latitude;
      long = propertyDataAutocompleteStored.longitude;
    }
    if (from === 'QUOTE') {
      if (Object.keys(emptyQuoteData).length > 0) {
        if (emptyQuoteData.personAddr.s_Latitude) {
          lat = emptyQuoteData.personAddr.s_Latitude;
          long = emptyQuoteData.personAddr.s_Longitude;
        }
      }
    }

    setPosition({ 'lat': parseFloat(lat), 'lng': parseFloat(long) });
  }, [propertyDataAutocompleteStored, emptyQuoteData, apiLoading]);

  return (
    isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={position}
        zoom={18}
        mapTypeId='satellite'
        label={true}
      >
        { /* Child components, such as markers, info windows, etc. */}
        <Marker position={position} />
      </GoogleMap>
    ) : null
  )
}

export default GoogleMaps;

