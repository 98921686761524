import React from "react";
import {Link} from 'react-scroll'

import climage1 from "../../../../assets/helpcenter-images/create-log/cl-image-1.png";
import climage2 from "../../../../assets/helpcenter-images/create-log/cl-image-2.png";
import climage3 from "../../../../assets/helpcenter-images/create-log/cl-image-3.png";

import slimage1 from "../../../../assets/helpcenter-images/search-log/sl-image-1.png";
import slimage2 from "../../../../assets/helpcenter-images/search-log/sl-image-2.png";
import slimage3 from "../../../../assets/helpcenter-images/search-log/sl-image-3.png";
import slimage4 from "../../../../assets/helpcenter-images/search-log/sl-image-4.png";

import vlimage1 from "../../../../assets/helpcenter-images/view-log/vl-image-1.png";
import vlimage2 from "../../../../assets/helpcenter-images/view-log/vl-image-2.png";
import vlimage3 from "../../../../assets/helpcenter-images/view-log/vl-image-3.png";
import vlimage4 from "../../../../assets/helpcenter-images/view-log/vl-image-4.png";
import vlimage5 from "../../../../assets/helpcenter-images/view-log/vl-image-5.png";
import {useParams } from 'react-router-dom'; 
import { getCompanyDetails } from "../../../../services/commonService";

const TasksAndRecentWorks = () => {
    const { div } = useParams();
    const selectedDiv = div;
    const hideDivObject =  {
        createalog:false,
        searchalog:false,
        viewalog:false,
    }
    if(selectedDiv){
        Object.keys(hideDivObject).forEach(key => {
            hideDivObject[key] = true;
        });
        hideDivObject[selectedDiv] = false;
    }

    const companyPhone = getCompanyDetails("phone");

    return(
        <div className="row">
                <div className="col-md-7">
                    <div id="createalog" hidden={hideDivObject.createalog}>
                        <p className="font-weight-bold mt-3">Create a Log</p>
                        <p>A log can be created at any time, but is best utilized from within a policy or quote.  This will automatically fill in the policy information for the user.</p>
                        <p>From the policy system, click on Log in the upper right (Note that Attachment will have a list of all documents attached to logs for quick reference)</p>
                        <img src={climage1} className="img-fluid" alt />
                        <p>In the upper right hand corner, click on Add New.</p>
                        <img src={climage2} className="img-fluid" alt />
                        <p>A log tied to the policy will be created from the user.</p>
                        <img src={climage3} className="img-fluid" alt />
                        <p>To choose who the log will be sent to, click Get Recipient.  The default recipient is the underwriter for the policy.</p>
                        <p>You can send a message under the content section, and attached any necessary documents.  The log by default will send immediately, but can be scheduled to appear at a later date, if the intent of the log is a follow-up or verification at a later date.</p>
                        
                        <br></br>
                        <hr />  

                    </div>
                    <div id="searchalog" hidden={hideDivObject.searchalog}>
                        <p className="font-weight-bold mt-3">Search a Log</p>
                        <p>By default, opening the activity log shows the log for the current user.  </p>
                        <img src={slimage1} className="img-fluid" alt />
                        <p>This can be changed to view the logs of anyone the user has permission to view.</p>
                        <img src={slimage2} className="img-fluid" alt />
                        <p>Logs can be filtered by time, status, if it was created by a user or the system, etc.  From here you can also change the status of multiple logs, read, unread, open, or closed.  Click on the link in the from section to open the log. </p>
                        <img src={slimage3} className="img-fluid" alt />
                        <img src={slimage4} className="img-fluid" alt />
                    </div>
                    <br></br>
                    <hr />  

                    <div id="viewalog" hidden={hideDivObject.viewalog}>
                        <p className="font-weight-bold mt-3">View a Log</p>
                        <p>At the top of the dashboard, click on “Activity Log”.  This will also display the number of unread logs currently sent to the user.  In this example, there are seven unread activity logs.</p>
                        <img src={vlimage1} className="img-fluid" alt />
                        <p>The inbox filter allows you to filter for certain types of activities, such as system or user generated, read or unread, open or closed, and logs created within a specific time frame.</p>
                        <img src={vlimage2} className="img-fluid" alt />
                        <p>Opening an activity log generated by the system will display a message that looks like this:</p>
                        <img src={vlimage3} className="img-fluid" alt />
                        <p>Opening an activity log generated by another user will display a message that looks like this:</p>
                        <img src={vlimage4} className="img-fluid" alt />
                        <p>Notice the attachment as well as the user generated note in the grey box.  The message by default will send immediately, but can be scheduled for a future date by selecting a different date in the “Schedule Date” field.</p>
                        <p>In general, your workflow will include all logs with the Open status.  If you have viewed the activity log, it will change the Display from Unread to Read, but will not close the log.  If you have completed any necessary work on this task, you can change the status to “Closed”</p>
                        <img src={vlimage5} className="img-fluid" alt />
                    </div>
                </div>
                {
                    selectedDiv ? null :
                    (<div className="col-md-5">
                        <div className="rightdv p-5">
                            <h5 className="font-weight-bold">Quick Links</h5>
                            <br></br>
                            <p><Link activeClass="active" to="createalog" spy={true} smooth={true}>Create a Log</Link></p><hr />
                            <p><Link activeClass="active" to="searchalog" spy={true} smooth={true}>Search a Log</Link></p><hr />
                            <p><Link activeClass="active" to="viewalog" spy={true} smooth={true}>View a Log</Link></p><hr />
                        </div>
                        <div className="rightdv p-5 mt-3">
                            <h5 className="font-weight-bold">Need Support?</h5>
                            <p className="buttonicon"><i className="fa fa-commenting-o" />&nbsp;&nbsp;Live chat with our support Assistant</p>
                        <p>Click on the link to connect with our support assistant.24/7 service is available for our Valuable customers.</p>
                            <button className="btnCustom btnCustom-info pr-4 pl-4">Chat Now</button>
                            <hr />
                            <p><b>Need Support?</b></p>
                            <p className="buttonicon"><i className="fa fa-phone buttonicon" />&nbsp;&nbsp;{companyPhone}</p>
                        <p>Connect directly with our support team.24/7 service is available for our Valuable customers.</p>
                        </div>
                    </div>)
                }
            </div>

    )
}

export default TasksAndRecentWorks;