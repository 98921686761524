import React from "react";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const AlertContainer = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const AlertList = styled.ul`
  margin: 0;
  padding: 0;
  padding-left: 30px;
  list-style-type: disc;
`;

const AlertItem = styled.li`
  margin-bottom: 5px;
`;

const MessageAlerts = ({
  errorMessages,
  warningMessages,
  dismissible = true,
  onDismissError,
  onDismissWarning,
}) => {
  const handleDismissError = () => {
    if (onDismissError) {
      onDismissError();
    }
  };

  const handleDismissWarning = () => {
    if (onDismissWarning) {
      onDismissWarning();
    }
  };

  return (
    <>
      {Array.isArray(errorMessages) && errorMessages.length > 0 && (
        <AlertContainer>
          <Alert
            className="d-flex align-items-center"
            variant="danger"
            dismissible={dismissible}
            onClose={handleDismissError}
            aria-label="Error Alert"
            role="alert"
            aria-live="assertive"
          >
            <AlertList>
              {errorMessages.map((message, index) => (
                <AlertItem key={index}>{message}</AlertItem>
              ))}
            </AlertList>
          </Alert>
        </AlertContainer>
      )}

      {Array.isArray(warningMessages) && warningMessages.length > 0 && (
        <AlertContainer>
          <Alert
            className="d-flex align-items-center"
            variant="warning"
            dismissible={dismissible}
            onClose={handleDismissWarning}
            aria-label="Warning Alert"
            role="alert"
            aria-live="assertive"
          >
            <AlertList>
              {warningMessages.map((message, index) => (
                <AlertItem key={index}>{message}</AlertItem>
              ))}
            </AlertList>
          </Alert>
        </AlertContainer>
      )}
    </>
  );
};

MessageAlerts.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  warningMessages: PropTypes.arrayOf(PropTypes.string),
  dismissible: PropTypes.bool,
  onDismissError: PropTypes.func,
  onDismissWarning: PropTypes.func,
};

export default MessageAlerts;
