import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Form ,Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Sign.scss";

const Sign = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    return(
       <section className="sign-off-info-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="sign-off-info-form-section">
                    <Col xs="12" className="sign-off-info-form-main">
                        <div className="sign-off-info-heading">
                            <h4>{t('Sign Off')}</h4>
                            <p>{t('Please read following instruction carefully and check each box to continue')}</p>
                        </div>
                        <Form className="confirm-address-form">
                            <Col xs="12">
                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="vacantUnoccupiedSale"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Vacant / Unoccupied / Sale</b><br/><p>The applicant acknowledges  that this policy does not cover vacant homes, unoccupied homes, homes  under construction, or homes listed for sale.</p></span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="propertyConditionAcknowledgement"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Property Condition Acknowledgement </b><br/> The applicant acknowledges and agrees that the home and attached or detached structure(s) have no unrepaired property damage and that any unrepaired damages are not eligible for coverage</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="sinkholeAcknowledgement"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Sinkhole Acknowledgement </b><br/> Applicant has never reported any potential sinkhole, settlement, or cracking damage, subsidence, or any sinkhole-related loss to this residence premises. Nor does the applicant have any knowledge that any settlement or cracking exists, or that any prior owner of the residence premises reported any such damage.</span>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="floodCoverageExcluded"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Flood Coverage Excluded</b><br/> <p> I understand that this policy does not cover loss or damage caused by flooding and that such coverage is available on a separate policy. I also understand that if the residence premises covered by this policy is located in a special flood hazard area (any and all A or V zones), Avatar requires me to maintain a separate flood policy. If I fail to maintain flood coverage when required, Avatar may cancel or non-renew this policy. </p></span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="ScreenedPatioEnclosureExcluded"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Screened Patio Enclosure(s) Excluded  </b><br/> I understand that this policy does not cover  hurricane   damage   to  the   aluminum-framed  screen  enclosures   unless   specifically endorsed and for which I have paid an additional  premium. This optional coverage, if purchased, is provided at Replacement Cost Value and does not increase the Coverage “A” Limit of the policy.</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="buriedPortableHeaterFlame"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Buried Oil Tank, Portable Space Heater, or Open Flame </b><br/> The applicant acknowledges this policy excludes coverage for loss resulting from buried oil tanks, portable space heaters, or any open fire sources on the residence premises; or the supervision by an insured of the usage off of the residence premises</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="unusualorExcessiveLiabilityExclusion"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Unusual or Excessive Liability Exclusion </b><br/> The   applicant   acknowledges   this policy excludes coverage for loss resulting from the use of a trampoline, skate ramps, unprotected   pools   and   spas,   and   gas   and   electric   powered   vehicle   on   the   residence premises; or the supervision by an insured of the usage off of the residence premises.</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{float: "right"}}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="animalLiabilityExclusion"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Animal Liability Exclusion </b><br/> The applicant acknowledges that this policy excludes or limits coverage for loss caused by any animal owned or kept by an insured.</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    </Col>
                    <div className="flood-bts-div">
                        <Button
                            id="primary-grey-btn-flood"
                            variant="outline-dark"
                            onClick={() => handleOnclick('11')}
                        >
                            {t('Previous')}
                        </Button>
                        <Button
                            id="primary-colored-btn"
                            variant="primary"
                            onClick={() => handleOnclick('13')}
                        >
                            {t('Next')}
                        </Button>
                    </div>
                </Col>
            </Row>
           
       </section>
    )
}

export default Sign;