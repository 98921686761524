import GoogleMap from "./GoogleMap";
import { Location } from "./Location";

export const PropertyDetails = () => {
  return (
    <>
      <div
        style={{
          padding: "0.5rem",
          backgroundColor: "white",
          borderRadius: "1rem",
        }}
      >
        <GoogleMap />
      </div>

      <p className="pt-2 pb-2"></p>
      <Location />
    </>
  );
};
