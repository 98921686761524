import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "./policy-details.scss";
import {
    Row,
    Col,
    Table,
} from "react-bootstrap";
import { currencyFormat,dateFormat } from '../../../services/commonService';
import { useTranslation } from "react-i18next";
import Moment from 'moment';

function CommonHeader(props) {

    const [applicantData, setApplicantData] = useState({});
    const [premiumChange, setPremiumChange] = useState({});
    const [personInfoMemberId, setpersonInfoMemberId] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        var premChange = props.applicationData.n_PremiumChange;
        // Only For Product 10 - Flood (Premium Changes + Fees) as Total Premium Change
        if (props.applicationData.n_ProductId_FK === 10) {
            premChange = (parseInt(props.applicationData.n_PremiumChange) || 0) + (parseInt(props.applicationData.n_Policyfees) || 0)
        }
        setPremiumChange(premChange);
        setApplicantData(props.applicationData)
        if(props.applicationData.metadata != '' && props.applicationData.metadata != null){
            const memberIdObj = JSON.parse(props.applicationData.metadata);
            setpersonInfoMemberId(memberIdObj.member_id);
        }
    }, [props.applicationData]);

    const newDateFormat = (date) => {
        return Moment(date).format('MM/DD/YYYY'); // Converting Date into MM/DD/YYYY
    }

    return (
        <div>
            <div id="dashboard-table-section">
             <div className="table-main-title-div">
                <span className="table-main-title-name">{(Object.keys(applicantData).length > 0 && applicantData.Policy_No) }</span>
              </div>
           
            <div className="billing-tab-row1-body">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Policy #/Holder Name')}</th>
                                    <td>
                                        {
                                            (Object.keys(applicantData).length > 0 && applicantData.Policy_No) + '/' + (Object.keys(applicantData).length > 0 && applicantData.n_TermSequence) + '/' + (Object.keys(applicantData).length > 0 && applicantData.s_FullLegalName)
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Terms Start date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && newDateFormat(applicantData.d_TermStartDate)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Effective date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && newDateFormat(applicantData.d_TransEffectiveFrom)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Type')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.s_TranTypeScreenName + ' - ' + applicantData.s_TranSubtypeScreenName}</td>
                                </tr>
                                <tr>
                                    <th>{t('Total Premium Change')}</th>
                                    <td>{currencyFormat(premiumChange)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Total Premuim')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && currencyFormat(applicantData.totalPremium)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Total Claim')}</th>
                                    <td>
                                        {Object.keys(applicantData).length > 0 && applicantData.totalClaims} <span className="span-diff-color">Policyholder/Member Copy Agent Copy</span>{" "}
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t('Serv Rep')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.serviceRep.s_ScreenName
                                    }</td>
                                </tr>
                                
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="6">
                        <Table borderless className="policy-details-info-table">
                            <tbody>
                                <tr>
                                    <th>{t('Payment Plan')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.billPay.s_PayPlanScreenName
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Term End Date')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && newDateFormat(applicantData.d_TermEndDate)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Exp. Dt')}.</th>
                                    <td> {Object.keys(applicantData).length > 0 && newDateFormat(applicantData.d_TransEffectiveTo)}</td>
                                </tr>
                                <tr>
                                    <th>{t('Bill to')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.billPay.s_BillToType
                                    }</td>
                                </tr>
                                <tr>
                                    <th>{t('Product Name')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.s_ProductName}</td>
                                </tr>
                                <tr>
                                    <th>{t('U/writer')}</th>
                                    <td>{Object.keys(applicantData).length > 0 && applicantData.underwriter.s_ScreenName}</td>
                                </tr>
                                <tr>
                                    <th>{t('Transaction Note')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && applicantData.s_Note
                                    }</td>
                                </tr>
                                    {personInfoMemberId != '' &&
                                    <tr>

                                        <th>{t('Member Id')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && personInfoMemberId
                                    }</td>
                                    </tr>
                                    }
                                <tr>
                                    <th>{t('Rating Method')}</th>
                                    <td>{
                                        Object.keys(applicantData).length > 0 && (applicantData.ratingMethod || null)
                                    }</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        applicationData: state.coreSystem.applicationData,
    };
};
export default connect(mapStateToProps)(CommonHeader);