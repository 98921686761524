import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ListRequiredDoc from "./ListRequiredDoc";

const SummaryofChanges = (props) => {
    const { endorsementJSON, floodSystemDD, showReview, isDocRequired, poTransPK, applicationData, policyPK } = props;
    const { t } = useTranslation();

    const [tableData, setTableData] = useState([]);
    const [reviewText, setReviewText] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');

    useEffect(() => {
        if (showReview === true && Object.keys(endorsementJSON).length > 0 && floodSystemDD) {
            var tableData = [];
            var currentValues = endorsementJSON.current
            var newValues = endorsementJSON.new

            if (Object.keys(newValues).length > 0) {
                if (newValues.ApplicantName && Object.keys(newValues.ApplicantName).length > 0) {
                    if (newValues.ApplicantName.hasOwnProperty('firstName')) {
                        tableData = pushToTableData(tableData, "First Name", currentValues.ApplicantName.firstName, newValues.ApplicantName.firstName.firstName);
                    }
                    if (newValues.ApplicantName.hasOwnProperty('middleName')) {
                        tableData = pushToTableData(tableData, "Middle Name", currentValues.ApplicantName.middleName, newValues.ApplicantName.middleName.middleName);
                    }
                    if (newValues.ApplicantName.hasOwnProperty('lastName')) {
                        tableData = pushToTableData(tableData, "Last Name", currentValues.ApplicantName.lastName, newValues.ApplicantName.lastName.lastName);
                    }
                    if (newValues.ApplicantName.hasOwnProperty('fullLegalName')) {
                        tableData = pushToTableData(tableData, "Full Name", currentValues.ApplicantName.fullLegalName, newValues.ApplicantName.fullLegalName.fullLegalName);
                    }
                }
                // coverage and ded
                if (newValues.Coverage && Object.keys(newValues.Coverage).length > 0) {
                    var cvgChanges = newValues.Coverage;
                    // building coverage
                    if (cvgChanges && Object.keys(cvgChanges).length > 0) {
                        if (cvgChanges.hasOwnProperty('buildingCoverage')) {
                            if (cvgChanges.buildingCoverage && Object.keys(cvgChanges.buildingCoverage).length > 0) {
                                tableData = pushToTableData(tableData, "Building Coverage", "$" + currentValues.Coverage.buildingCoverage, "$" + cvgChanges.buildingCoverage.value);
                            }
                        }

                        // building deductible
                        if (cvgChanges.hasOwnProperty('buildingDeductible')) {
                            if (cvgChanges.buildingDeductible && Object.keys(cvgChanges.buildingDeductible).length > 0) {
                                if (floodSystemDD && Object.keys(floodSystemDD.deductible).length > 0) {
                                    var currentDedValue = floodSystemDD.deductible.filter(x => currentValues.Coverage.buildingDeductible == x.s_DiscountCode);
                                    var newDedValue = floodSystemDD.deductible.filter(x => cvgChanges.buildingDeductible.value == x.s_DiscountCode);
                                    if (currentDedValue.length == 0) {
                                        currentDedValue.push({ "s_DiscountCode": "", "s_ScreenName": "$0 Deductible" });
                                    }
                                    if (newDedValue.length == 0) {
                                        newDedValue.push({ "s_DiscountCode": "", "s_ScreenName": "$0 Deductible" });
                                    }
                                    if (currentDedValue.length > 0) {
                                        tableData = pushToTableData(tableData, "Building Deductible", currentDedValue[0].s_ScreenName, newDedValue[0].s_ScreenName);
                                    }
                                }
                            }
                        }

                        // content coverage
                        if (cvgChanges.hasOwnProperty('contentCoverage')) {
                            if (cvgChanges.contentCoverage && Object.keys(cvgChanges.contentCoverage).length > 0) {
                                tableData = pushToTableData(tableData, "Content Coverage", "$" + currentValues.Coverage.contentCoverage, "$" + cvgChanges.contentCoverage.value);
                            }
                        }

                        // content deductible
                        if (cvgChanges.hasOwnProperty('contentDeductible')) {
                            if (cvgChanges.contentDeductible && Object.keys(cvgChanges.contentDeductible).length > 0) {
                                if (floodSystemDD && Object.keys(floodSystemDD.deductible).length > 0) {
                                    var currentDedValue = floodSystemDD.deductible.filter(x => currentValues.Coverage.contentDeductible == x.s_DiscountCode);
                                    var newDedValue = floodSystemDD.deductible.filter(x => cvgChanges.contentDeductible.value == x.s_DiscountCode);
                                    if (currentDedValue.length == 0) {
                                        currentDedValue.push({ "s_DiscountCode": "", "s_ScreenName": "$0 Deductible" });
                                    }
                                    if (newDedValue.length == 0) {
                                        newDedValue.push({ "s_DiscountCode": "", "s_ScreenName": "$0 Deductible" });
                                    }
                                    if (currentDedValue.length > 0) {
                                        tableData = pushToTableData(tableData, "Content Deductible", currentDedValue[0].s_ScreenName, newDedValue[0].s_ScreenName);
                                    }
                                }
                            }
                        }
                    }
                }

                // MailingAddress
                if (newValues.InsuredInformation && Object.keys(newValues.InsuredInformation).length > 0) {
                    if (newValues.InsuredInformation.hasOwnProperty('MailingAddress')) {
                        if (Object.keys(newValues.InsuredInformation.MailingAddress).length > 0) {
                            var newMailingAddr = newValues.InsuredInformation.MailingAddress;
                            var currentMailingAddr = currentValues.InsuredInformation.MailingAddress;
                            var currentAddr = currentMailingAddr.s_AddressLine1 + ", " + currentMailingAddr.s_CityName + ", " + (currentMailingAddr.s_StateCode || currentMailingAddr.s_StateName) + ", " + currentMailingAddr.s_PostalCode;
                            var newAddr = newMailingAddr.s_AddressLine1 + ", " + newMailingAddr.s_CityName + ", " + (newMailingAddr.s_StateCode || newMailingAddr.s_StateName) + ", " + newMailingAddr.s_PostalCode;

                            tableData = pushToTableData(tableData, "Mailing Address", currentAddr, newAddr);
                        }
                    }
                }

                // Phone
                if (newValues.Phone && Object.keys(newValues.Phone).length > 0) {
                    if (newValues.Phone.hasOwnProperty('phone')) {
                        if (Object.keys(newValues.Phone.phone).length > 0) {
                            tableData = pushToTableData(tableData, "Phone", currentValues.Phone.phone, newValues.Phone.phone.s_PhoneNumber);
                        }
                    }
                }

                // Email
                if (newValues.Email && Object.keys(newValues.Email).length > 0) {
                    if (newValues.Email.hasOwnProperty('email')) {
                        if (Object.keys(newValues.Email.email).length > 0) {
                            tableData = pushToTableData(tableData, "Email", currentValues.Email.email, newValues.Email.email.s_EmailAddress);
                        }
                    }
                }

                // Additional Insured
                if (newValues.InsuredInformation && Object.keys(newValues.InsuredInformation).length > 0) {
                    let newAdInsured = newValues.InsuredInformation;
                    if (newAdInsured.hasOwnProperty('isAdditionalInsuredDeleted')) {
                        if (newAdInsured['isAdditionalInsuredDeleted'] == 'YES') {
                            tableData = pushToTableData(tableData,
                                "Additional Insured",
                                `-`,
                                `Additional Insured Deleted`);
                        }
                    }

                    if (newValues.InsuredInformation.hasOwnProperty('AdditionalInsured')) {
                        /**
                         * get length base on datatype
                         * @link https://implevision.atlassian.net/browse/ODS-1920
                         */
                        let newAdditInsured = ((typeof newValues.InsuredInformation.AdditionalInsured == 'object') ? Object.keys(newValues.InsuredInformation.AdditionalInsured).length : newValues.InsuredInformation.AdditionalInsured.length)
                        const currentAdditionalInsuredCount = getAdditionalInsuredCount(currentValues);
                        const differenceInAdditionalInsured = Math.abs(newAdditInsured - currentAdditionalInsuredCount);
                        tableData = pushToTableData(tableData,
                            "Additional Insured",
                            `${currentAdditionalInsuredCount} Additional Insured`,
                            `${differenceInAdditionalInsured} Additional Insured Added`);
                    }
                }

                // Additional Information
                if (newValues.AdditionalInformation && Object.keys(newValues.AdditionalInformation).length > 0) {
                    if (newValues.AdditionalInformation.hasOwnProperty('replacementCost')) {
                        tableData = pushToTableData(tableData, "Replacement Cost", "$" + currentValues.AdditionalInformation.replacementCost, "$" + newValues.AdditionalInformation.replacementCost.value);
                    }

                    if (newValues.AdditionalInformation.hasOwnProperty('s_InsuredResides')) {
                        tableData = pushToTableData(tableData, "Will the applicant or applicant's spouse live in this building more than 50% of the year ", currentValues.AdditionalInformation.s_InsuredResides, newValues.AdditionalInformation.s_InsuredResides);
                    }

                    // Bill To
                    if (newValues.AdditionalInformation.hasOwnProperty('billTo')) {
                        if (floodSystemDD && Object.keys(floodSystemDD.BILLTOFLOOD).length > 0) {
                            var currentBillTo = floodSystemDD.BILLTOFLOOD.filter(x => currentValues.AdditionalInformation.billTo == x.s_AppCodeName);
                            var newBillTo = floodSystemDD.BILLTOFLOOD.filter(x => newValues.AdditionalInformation.billTo.billTo == x.s_AppCodeName);

                            if (currentBillTo.length > 0) {
                                tableData = pushToTableData(tableData, "Renewal Bills sent to", currentBillTo[0].s_AppCodeNameForDisplay, newBillTo[0].s_AppCodeNameForDisplay);
                            }
                        }
                    }
                }

                // Lender
                if (newValues.Lender && Object.keys(newValues.Lender).length > 0) {
                    if (newValues.Lender.hasOwnProperty('Lender') && currentValues.Lender.hasOwnProperty('Lender')) {
                        tableData = pushToTableData(tableData, "Lender", currentValues.Lender.Lender.length || 0 + " Lender", Math.abs(newValues.Lender.Lender.length - (currentValues.Lender.Lender.length || 0)) + " Lender Added/Deleted");
                    }

                    if (newValues.Lender.hasOwnProperty('isLenderDeleted') && newValues.Lender.isLenderDeleted == 'YES') {
                        tableData = pushToTableData(tableData, "Lender", "-", " Lender Deleted");
                    }
                }

                // Review Text
                if (newValues.RequestUnderwriterReview && Object.keys(newValues.RequestUnderwriterReview).length > 0) {
                    if (newValues.RequestUnderwriterReview.hasOwnProperty('review')) {
                        setReviewText(newValues.RequestUnderwriterReview.review.review);
                    }
                }
            }

            // s_InsuredResides
            if (currentValues.isPrimaryRes && newValues.isPrimaryRes) {
                if (currentValues.isPrimaryRes != newValues.isPrimaryRes) {
                    tableData = pushToTableData(tableData, "Will the applicant or applicant's spouse live in this building more than 50% of the year", currentValues.isPrimaryRes, newValues.isPrimaryRes);
                }
            }

            // set policy number
            if (Object.keys(applicationData).length > 0) {
                setPolicyNumber(applicationData.Policy_No);
            }

            setTableData(tableData);
        }
    }, [showReview, floodSystemDD])

    const getAdditionalInsuredCount = (values) => {
        return values.InsuredInformation && values.InsuredInformation.AdditionalInsured ? values.InsuredInformation.AdditionalInsured.length : 0
    }
    
    const pushToTableData = (currentTableData, fieldName, currentValue, newValue) => {
        var temp = {
            "fieldName": fieldName,
            "current": currentValue,
            "new": newValue
        }

        currentTableData.push(temp);

        return currentTableData;
    }

    return (
        <section className="add-mortage-section">
            <Row className="flood-add-mortage-form-section" hidden={tableData.length == 0 ? true : false}>
                {
                    reviewText && reviewText.length > 0 && (
                        <p
                            className="mb-1"
                            style={{ textAlign: 'justify', color: '#008CBA', fontStyle: 'italic' }}
                        >
                            {t("Request Underwriter Review") + " - " + reviewText}
                        </p>
                    )
                }

                <Col xs="12" className="add-mortage-form-main">
                    <div className="add-mortage-form">
                        {
                            tableData.length > 0 && (
                                <div className="add-mortage-table-div">
                                    <Table className="add-mortage-table" borderless responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t('Current')}</th>
                                                <th>{t('New')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.length > 0 && (
                                                    <>
                                                        {tableData.map((data, index) => (
                                                            <tr key={index}>
                                                                <td style={{ "textAlign": "right" }}>
                                                                    {t(data.fieldName)}
                                                                </td>
                                                                <td>
                                                                    {data.current}
                                                                </td>
                                                                <td>
                                                                    {data.new}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            )
                        }
                    </div>
                </Col>
            </Row>
            {
                tableData.length === 0 && (
                    <h4 className="text-center">{t('No Changes')}</h4>
                )
            }
            {
                showReview === true && isDocRequired === true && (
                    <ListRequiredDoc
                        isOpen={true}
                        poTransPK={poTransPK}
                        policyNumber={policyNumber}
                        policyPK={policyPK}
                    />
                )
            }
        </section>

    )
}

export default SummaryofChanges;
