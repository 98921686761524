import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../flood-thank-you/flood-thank-you.scss";
import { getCompanyDetails } from "../../../../../services/commonService";

const FloodPaymentExist = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const companyPhone = getCompanyDetails("phone");
  const companyEmail = getCompanyDetails("email");

  return (
    <section className="thank-you-section">
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          className="thank-you-form-section"
          style={{ paddingBottom: "80px" }}
        >
          <div className="thank-you-form-main">
            <div className="thank-you-main">
              <div className="thank-you-heading">
                <h6 className="text-danger">{t("Payment already exists")}!</h6>
              </div>
              <div className="thank-you-details">
                <p>
                  {t(
                    "Have any more questions, please contact one of our agents at"
                  )}{" "}
                  <br />
                  <span>
                  {companyPhone}  <br />{" "}
                  </span>
                  {t("Or, leave us an email at")}{" "}
                  <span>{companyEmail}.</span>
                </p>
              </div>
              <div
                className="pay-now-action-btn"
                style={{ marginBottom: "18px" }}
              >
                <Button
                  id="primary-colored-btn"
                  variant="primary"
                  onClick={() => navigation('/flood')}
                >
                  <span>{t('Create Quote')}</span>
                </Button>
              </div>
            </div>
          </div>
        </Col>

      </Row>
    </section>
  );
};

export default FloodPaymentExist;
