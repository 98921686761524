// import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import {React, useState, useEffect, useRef, useMemo, useCallback} from "react";
import { useDispatch, connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { getAgencyUsers, getAUsersDetails } from '../../../services/producerService'
// import { dateFormat } from '../../../services/commonService'
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { toast } from 'react-toastify';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import {InputAdapter, TextMask} from "react-text-mask-hoc";
import {setAgencyUserPk} from "../../../store/actions/index";
import "../agentuser.css";
// import ToolkitProvider, {
  // Search,
// } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import  CELicenseList  from "./../../CE-License/CELicenseList";
import { Badge, Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import AgencyUserForm from "../AgencyUserForm/AgencyUserForm";
import UserProductLicenseInformation from "../UserProductOrLicenseInformation/UserProductLicenseInformation";
import DebarmentInformation from "../DebarmentInformation/DebarmentInformation";

const AgencyUser = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [TableData, setTableData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [agencyPersonPk, setAgencyPersonPk] = useState(props.agencyId);
  const [tableTotal, setTableTotal] = useState([]);
  const [licenceInfo, setLicenceInfo] = useState([]);
  const [debarrmentData, setDebarrmentData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [ruleData, setRuleData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [serviceRepresentativeData, setServiceRepresentativeData] = useState([]);
  const [underWritterData, setUnderWritterData] = useState([]);
  const [authTypeData, setAuthTypeData] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [userLevels, setUserLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState();
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 25, 50];

  useEffect(()=>{
    if(agencyPersonPk !== undefined){
      fetchAgencyUsers(agencyPersonPk)
    }
  },[props.agencyId])

  useEffect(() => {
    if(props.preloadAgencyUserPersonPk != 0 && props.preloadAgencyUserPersonPk != undefined ){
      fetchUserDetails(agencyPersonPk,props.preloadAgencyUserPersonPk)
    }
  },[props.preloadAgencyUserPersonPk])
  const fetchAgencyUsers = async (agencyPersonPk) =>{
    setLoading(true);
    await dispatch(getAgencyUsers(agencyPersonPk)).then((res) => {
        if( res.status === 200 ){
            setTableData(res.data?.AUserData_arr)
            setTableTotal((res.data?.AUserData_arr).length)
            setStateData(res.data?.state_arr)
            setProductData(res.data?.productType_arr)
            setRuleData(res.data?.ruleType_arr)
            setServiceRepresentativeData(res.data?.servReppol_arr)
            setUnderWritterData(res.data?.underWriter_arr)
            setAuthTypeData(res.data?.authType_arr)
            setUserLevels(res.data?.userLevel_arr)
            setLoading(false);
        }
        else
        {
          toast.error('No data Found!')
        }

        
    });
  }

  const fetchUserDetails = async (agencyPersonPk,agencyUserPersonPk) =>{
    setLoading(true);
    await dispatch(getAUsersDetails(agencyPersonPk,agencyUserPersonPk)).then((res) => {
        if( res.status === 200 ){
          setUserData(res.data?.agentData)
          setLicenceInfo(res.data?.AUserDetail_arr)
          setDebarrmentData(res.data?.user_debarred_data)
          setMetadata(res.data?.metaData)
          setLoading(false);
        }
        else
        {
          toast.error('No data Found!')
        }
        if(props.preloadAgencyUserPersonPk != 0){
           dispatch(setAgencyUserPk(0));
        }
        
    });
  }
  const handleAgencyUserClick = (e,row) =>{
	setUserId(row.Admin_ID);
    setUserData([])
    setLicenceInfo([])
    setDebarrmentData([])
    setMetadata([])
    fetchUserDetails(agencyPersonPk,row.n_PersonInfoId_FK)
    // call user details api and display in form
  }

  // const columns = [
  //   {
  //     dataField: "Username",
  //     text: t("USER ID"),
  //     formatter: (value, row) => (
  //         <span onClick={(e) => handleAgencyUserClick(e,row)} style={{ borderBottom: '1px solid', borderColor: 'blue', color:'blue' }}>
  //             <a href="#" >{row.Username || ''}</a>
  //         </span>
  //     ),
  // },

  //   {
  //       dataField: "s_ScreenName",
  //       text: t("SCREEN NAME"),
  //   },
  //   {
  //       dataField: "s_AppCodeName",
  //       text: t("STATUS"),
  //   },
  //   {
  //       dataField: "UserLevel_Name",
  //       text: t("LEVEL"),
  //   },
  // ];

  // const { SearchBar } = Search;

  const userIDRenderer = (params) => {
    return (
      <Text
        as={"span"}
        mb={0}
        style={{
          borderBottom: "1px solid",
          borderColor: "var(--chakra-colors-linkColor)",
          color: "var(--chakra-colors-linkColor)",
          cursor: "pointer",
        }}
        onClick={(e) => handleAgencyUserClick(e, params.data)}
      >
        {params.value || ""}
      </Text>
    );
  };

  const screenNameRenderer = (params) => {
    return (
      <Flex direction={"column"}>
        <Text mb={0}>{params.value}</Text>
        <Badge
          mb={1}
          lineHeight={"16px"}
          width={"max-content"}
          variant="outline"
          colorScheme={params.data.s_AppCodeName === "Active" ? "green" : "red"}
        >
          {params.data.s_AppCodeName}
        </Badge>
      </Flex>
    );
  };

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      filter: false,
      floatingFilter: true,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressHeaderMenuButton: true,
      suppressFloatingFilterButton: true,
    }),
    []
  );

  const [columnDefs] = useState([
    {
      headerName: t("USER ID"),
      field: "Username",
      filter: "agTextColumnFilter",
      minWidth: 150,
      cellRenderer: userIDRenderer,
    },
    {
      headerName: t("SCREEN NAME"),
      field: "s_ScreenName",
      filter: "agTextColumnFilter",
      cellRenderer: screenNameRenderer,
      minWidth: 150,
    },
    // {
    //   headerName: t("STATUS"),
    //   field: "s_AppCodeName",
    // },
    {
      headerName: t("LEVEL"),
      field: "UserLevel_Name",
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
  ]);

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box p={6} pb={2}>
          <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
            <GridItem>
              <Box
                borderRadius={"2xl"}
                boxShadow={"0 0 4px rgba(0, 0, 0, 0.5)"}
                className="ag-theme-alpine"
                height={"100%"}
                id="myGrid"
                w={"100%"}
              >
                <AdvancedGridTable
                  gridRef={gridRef}
                  rowData={TableData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={pagination}
                  paginationPageSize={paginationPageSize}
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  onGridReady={(params) => onGridReady(params)}
                  enableCellTextSelection={true}
                />
              </Box>
            </GridItem>
            <GridItem>
              <AgencyUserForm
                userData={userData}
                metadata={metadata}
                userLevels={userLevels}
                stateData={stateData}
              />
            </GridItem>
          </Grid>
          <Box mt={6}>
            <UserProductLicenseInformation
              ruleData={ruleData}
              authTypeData={authTypeData}
              productData={productData}
              underWritterData={underWritterData}
              serviceRepresentativeData={serviceRepresentativeData}
              stateData={stateData}
              licenceInfo={licenceInfo}
            />
          </Box>
          <Box mt={6}>
            <DebarmentInformation debarrmentData={debarrmentData} />
          </Box>
          <Box mt={6}>
            <Text mb={0} fontWeight={500} fontSize="xl">
              {t("CE Information")}
            </Text>
            <Box>
              <CELicenseList userId={userId} />
            </Box>
          </Box>
        </Box>
      </LoadingOverlay>
      {/* <LoadingOverlay>
      <Loader loading={loading} />
      <Row>
        <Col xs="6" >
          <Card className="displayColorHeaders">
              <Card.Header id="card-header">
                {t("Agency Users")}
              </Card.Header>
              <Card.Body>
              <div id="producer-agency-modal-table">
              <ToolkitProvider
                  keyField="BaseMessageLink_IdFk"
                  data={TableData}
                  columns={columns}
                  bootstrap4
                  search
                  tabIndexCell
                >
                  {(props) => (
                    <div >
                      <SearchBar {...props.searchProps} />
                      <div>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          hover
                          bordered={false}
                          pagination={paginationFactory()}
                          // selectRow={{ mode: "checkbox" }}
                          tabIndexCell
                          noDataIndication={
                            <div className="center-div-class">No data</div>
                          }
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
                
              </Card.Body>
          </Card>
        </Col>
        <Col xs="6">
          <Card>
            <Card.Header id="card-header">{t("Agency User Form")}</Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("First Name")}:
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input input-producer"
                      type="text"
                      placeholder=""
                      
                      value={userData.First_Name}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="dashboard-group-forms"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Last Name")}:
                    </Form.Label>
                    <Form.Control
                      name="quoteNo"
                      className="dashboard-group-input input-producer"
                      type="text"
                      placeholder=""
                      
                      value={userData.Last_Name}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>

                <Col className="mt-3" xs={1} sm={6}>
                  <b>{t("E-Mail")}:</b>
                </Col>
                <Col className="mt-3" xs={3} sm={6}>

                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.Email}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("Phone")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  {/* <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.s_PhoneNumber}
                    disabled={true}
                  /> */}
      {/* <TextMask
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      Component={InputAdapter}
                      className="form-control"
                      type="text"
                      id="s_PhoneNumber"
                      name="s_PhoneNumber"
                      value={userData.s_PhoneNumber}
                      placeholder=""
                      disabled={true}
                    />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("Agent Flood Code")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.s_UserCode}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("WYO Agent Code")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={metadata.s_CompanyCode}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("WYO UPN")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.Sub_Agent_ID}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("Previous WYO Agent Code")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={metadata.pre_agency_code}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("User ID")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <input
                    type="text"
                    name="version"
                    id="version"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.Username}
                    disabled={true}
                  />
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("Status")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <select
                    type="select"
                    name="tax_type"
                    id="tax_type"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.s_UserStatus}
                    disabled={true}
                  >
                    <option value="">Select Status</option>
                    <option value="111">Active</option>
                    <option value="112">Closed</option>
                  </select>
                </Col>
                {
                  window.location.host.split('.')[0] == 'farmers' ?
                  <>
                    
                    <Col xs={1} sm={6} className="mt-3">
                      <b htmlFor="s_business_suspence_date" className="pr-1">{t("New Business Suspense Date")}: </b>
                    </Col>
                    
                    <Col xs={1} sm={6} className="mt-3">
                      <input
                        type="text"
                        className="form-control dashboard-group-input input-producer"
                        name="s_business_suspence_date"
                        id="s_business_suspence_date"
                        value={metadata?.newbusiness_suspend_date != null || metadata?.newbusiness_suspend_date != '' ? dateFormat(metadata.newbusiness_suspend_date,'MM/DD/YYYY') : ''}
                        disabled={true}
                      />
                      
                      
                    </Col>
                 
                  
                    <Col xs={1} sm={6} className="mt-3">
                      <b htmlFor="s_sales_channel" className="pr-1">{t("Sales Channel")}: </b>
                    </Col>
                    <Col xs={1} sm={6} className="mt-3">
                      <input
                        type="text"
                        className="form-control dashboard-group-input input-producer"
                        name="s_sales_channel"
                        id="s_sales_channel"
                        value={metadata?.sales_channel}
                        // onChange={this.props.handleChange}
                        disabled={true}
                      />
                    
                    </Col>
                  </>:null
                }
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("User Level")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <select
                    type="select"
                    name="tax_type"
                    id="tax_type"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={userData.Level}
                    disabled={true}
                  >
                    <option value="">Select User Level</option>
                    {
                      userLevels.map((level,index) => {
                       return (<option key={index} value={level.UserLevel_ID}>
                          {level.UserLevel_Name}
                        </option>)
                      })
                    }
                  </select>
                </Col>
                <Col xs={1} sm={6} className="mt-3">
                  <b>{t("Resident State")}:</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <select
                    type="select"
                    name="tax_type"
                    id="tax_type"
                    className="form-control dashboard-group-input input-producer"
                    
                    value={metadata?.resident_state_id}
                  >
                    <option value="">Select Resident State: </option>
                    {
                      stateData.map((state,index) => {
                        return (<option key={index} value={state.n_stateId_PK}>
                          {state.s_StateName}
                        </option>)
                      })
                    }
                  </select>
                </Col>
                {/* <Col xs={1} sm={6} style={{ marginTop: "1.9rem" }}>
                  <b>Password</b>
                </Col>
                <Col xs={3} sm={6} className="mt-3">
                  <Button
                    style={{
                      background: "#64D6CD",
                      border: "1px solid #64D6CD",
                      marginTop: "10px",
                    }}
                  >
                    Password Generate
                  </Button>
                </Col> */}
      {/* </Row>
            </Card.Body>
          </Card>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "0.9rem"
            }}
          > */}
      {/* <Button id="card-header">
              {t("Send Welcome Letter")}
            </Button> */}
      {/* </Col>
        </Col>
        <Col style={{ marginTop: "1.5rem" }}>
          <Card>
            <Card.Header id="card-header">{t("User Product/License Information")}</Card.Header>
            <Card.Body >
              <Table striped bordered hover >
                <thead>
                  <tr>
                    <th>{t("PRODUCT")}</th>
                    <th>{t("RULE")}</th>
                    <th>{t("STATE")}</th>
                    <th>{t("REPRESENTATIVE")}</th>
                    <th>{t("U/W ASSIGNED")}</th>
                    <th>{t("LICENSE")}#</th>
                    <th>{t("AUTHORIZATION")}</th>
                    <th>{t("AGENTNPN")}</th>
                    {/* <th>ACTION</th> */}
      {/* </tr>
                </thead>
                <tbody>
                   {(licenceInfo !== undefined ? licenceInfo : []).map((row,index) => {
                    return (
                      <tr>
                       
                      <td>
                        <select
                          type="select"
                          name="product"
                          id="product"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_Product_FK}
                          disabled
                        >
                        { (productData !== undefined  ? productData : []).map((product,id) => {
                             return <option key={id} value={ product.n_ProductId_PK }>{ product.s_ProductName }</option>
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          type="select"
                          name="rule"
                          id="rule"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_PrValidationRuleGroupMasters_FK}
                          disabled
                          >
                        { (ruleData !== undefined ? ruleData : [] ).map((rule,id) => {
                              return <option key={id} value={ rule.n_PrValidationRuleGroupMasters_PK }>{ rule.s_RuleCode }</option>
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          type="select"
                          name="state"
                          id="state"
                          className="form-control dashboard-group-input input-producer"
                          value={row.s_StateCode}
                          disabled
                        >
                        { (stateData !== undefined ? stateData : []).map((state,id) => {
                             return <option key={id} value={ state.n_stateId_PK }>{ state.s_StateName }</option>
                          })}
                        </select>
                      </td>
                      <td>
                        <select
                          type="select"
                          name="srp"
                          id="srp"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_ServiceRep}
                          disabled
                        >
                          <option value="">Select Representative</option>
                          {
                             (serviceRepresentativeData !== undefined ? serviceRepresentativeData : []).map((srp,id) => {
                              return  <option key={id} value={ srp.Admin_ID }>{ srp.s_ScreenName }</option>
                            })}
                        </select>
                      </td>
                      <td>
                        <select
                          type="select"
                          name="n_UWAssigned"
                          id="n_UWAssigned"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_UWAssigned}
                          disabled
                        >
                          <option value="">Select U/W Assigned</option>
                          {
                             (underWritterData !== undefined ? underWritterData : []).map((uwd,id) => {
                              return  <option key={id} value={ uwd.Admin_ID }>{ uwd.s_ScreenName }</option>
                            })}
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="lic_no"
                          id="lic_no"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_LicenseNo}
                          disabled
                        />
                        
                      </td>
                      <td>
                        <select
                          type="select"
                          name="auth"
                          id="auth"
                          className="form-control dashboard-group-input input-producer"
                          value={row.s_AuthorizationCode}
                          disabled
                          >
                          <option value="">Select</option>
                          {
                             (authTypeData !== undefined ? authTypeData : []).map((authData,id) => {
                              return  <option key={id} value={ authData.s_AppCodeName }>{ authData.s_AppCodeNameForDisplay }</option>
                            })}
                          </select>
                        </td>
                        <td>
                        <input
                          type="text"
                          name="agent_npn"
                          id="agent_npn"
                          className="form-control dashboard-group-input input-producer"
                          value={row.n_AgentNPN}
                          disabled
                        />
                        
                      </td>
                    </tr>)
                   })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Col style={{ marginTop: "1.5rem" }}>
            <Card>
              <Card.Header id="card-header">{t("Debarment Information")}</Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={1} sm={2}>
                    <b>{t("Debarment Approval Date")}:</b>
                  </Col>
                  <Col xs={3} sm={2}>
                    <input
                      type="text"
                      name="version"
                      id="version"
                      className="form-control dashboard-group-input input-producer"
                      value={debarrmentData.debarment_approval_date != null ? dateFormat(debarrmentData.debarment_approval_date,'MM-DD-YYYY') : '' }
                      disabled
                    />
                  </Col>
                  <Col xs={1} sm={2}>
                    <b>{t("Debarment Expiration Date")}:</b>
                  </Col>
                  <Col xs={3} sm={2}>
                    <input
                      type="text"
                      name="version"
                      id="version"
                      className="form-control dashboard-group-input input-producer"
                      value={debarrmentData.debarment_expiration_date != null ? dateFormat(debarrmentData.debarment_expiration_date,'MM-DD-YYYY') : '' }
                      disabled
                    />
                  </Col>
                  <Col xs={1} sm={2}>
                    <b>{t("Debarred/Suspended Date")}:</b>
                  </Col>
                  <Col xs={3} sm={2}>
                    <input
                      type="text"
                      name="version"
                      id="version"
                      className="form-control dashboard-group-input input-producer"
                      value={ debarrmentData.debarred_date != null ? dateFormat(debarrmentData.debarred_date,'MM-DD-YYYY') : ''}
                      disabled
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{ marginTop: "1.5rem" }}>
            <Card>
              <Card.Header id="card-header">{t("CE Information")}</Card.Header>
              <Card.Body>
			  	<CELicenseList userId={userId}/>
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </Row>
      </LoadingOverlay> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  getProducerData: state.producerDataProcess.producerData,
  preloadAgencyUserPersonPk : state.producerDataProcess.agencyUserPk
});
export default connect(mapStateToProps)(AgencyUser);
