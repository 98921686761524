import React, { useEffect, useState } from 'react'
import { fetchProductDetails } from '../../services/privateFloodServicing';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import { Card, Col, Row } from 'react-bootstrap'
import './private-service.scss'
import { useNavigate } from 'react-router-dom'
import { LoadingOverlay, Loader } from 'react-overlay-loader'
import { toast } from 'react-toastify'
import { Box, Flex, Grid, Heading, Image, Alert, AlertIcon, Link, Text } from '@chakra-ui/react';


function PrivateFloodService() {
    const navigate = useNavigate ();
    const [agentSubModulesAccess, setAgentSubModulesAccess] = useState(localStorage.getItem("agentSubModules") ? 
    JSON.parse(localStorage.getItem("agentSubModules")) : []);
    if(agentSubModulesAccess && !agentSubModulesAccess.includes('PRIVATE_PRODUCT_SERVICING')){
        navigate('/');
    }
    const [agentProductData, setagentProductData] = useState([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation();

    useEffect(() => {
        setLoading(true)
        dispatch(fetchProductDetails()).then((res)=>{
            const nfiProduct = res?.data?.nfipproduct
            const privateProduct = res?.data?.privateproduct
            if(Object.keys(nfiProduct).length>0 && Object.keys(privateProduct).length>0){
                const privateProductArray = Object.values(privateProduct)
                setagentProductData(privateProductArray)
            }
            setLoading(false)
          }).catch((e)=> {
            toast.error(e.message)
          })
    }, [])
	
  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Text mb={4} fontWeight={600} fontSize={'2xl'} lineHeight="32px">
          {t("Private Flood Servicing")}
        </Text>
        <Box mb={6}>
          <Alert status='warning' mb={4}>
            <AlertIcon />
            {t("Access to integrated private flood carriers for existing policy servicing")}.
          </Alert>
          <Alert status='warning'>
            <AlertIcon />
            {t("New business quoting should be completed through the quote multirater")}.
          </Alert>
        </Box>
        <Box bg={'white'} borderRadius={'2xl'} p={'2.5rem'} mb={8} boxShadow={"lg"}>
          <Box>
            {
              agentProductData?.length > 0 ? (
                <Grid
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(4, 1fr)',
                  }}
                  gap={6}
                  pl={'12px'}
                  pr={'12px'}
                >
                  {agentProductData?.map((product, index) => {
                    return (
                      <Link
                        className="product-link"
                        href={product.productId + "/portal"}
                        isExternal={true}
                        _hover={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                        key={index}
                      >
                        <Flex className="private-product-card">
                          <Flex 
                            height={'100%'} 
                            width={'100%'} 
                            align={'center'} 
                            justify={'center'}
                          >
                            <Image height={'50px'} src={product?.logo} alt={t("product logo")} />
                          </Flex>
                          <Flex className="card-text-box">
                            <Text className="card-text">
                              {product?.productName}
                            </Text>
                          </Flex>
                        </Flex>
                      </Link>
                    )
                  })}
                </Grid>
              ) : (
                <Flex className='m-0 p-0' align={'center'} justify={'center'}>
                  <Box className="not-available-card text-center">
                    <Heading fontWeight={600} as={'h6'} size={'lg'}>
                      {t('Private products are not available')}
                    </Heading>
                  </Box>
                </Flex>
              )
            }
          </Box>
        </Box>
        {/* <section className="private-header text-center">
            <h3 className="">{t("Private Flood Servicing")}</h3>
            <strong>{t("Access to integrated private flood carriers for existing policy servicing")}.</strong>
            <br/>
            <strong>{t("New business quoting should be completed through the quote multirater")}</strong>
       </section>
       <section className="private-main">
        <Row>
        {
            agentProductData?.length>0 ? agentProductData?.map((product, index) => {
				return (
					<Col xs="12" sm="6" md="4" lg="3" xl="3" className='products'>   
						<a target='_blank' className='product-link' style={{textDecoration:'none'}} href={product.productId+"/portal"}>
							<Card key={index}  className='product-card' >
								<Card.Body className="product-card text-center" style={{background: "linear-gradient(rgb(224, 245, 243), rgb(252, 251, 255))",border: '1px solid black',maxHeight:'25rem'}}>
									<div className='my-2 '>
										<img  src={product?.logo} className="product-image rounded img-fluid" alt="product logo"/>
									</div>
									<h6 className="product-image rounded img-fluid" style={{height:'25px'}}>{product?.productName}</h6>
								</Card.Body>
							</Card>
						</a>
					</Col>
				)
        }) :  
        (
            <Card className='m-0 p-0'>
            <Card.Body className="product-card text-center">
                <Col>
                    <h3>{t('Private products are not available')}</h3>
                </Col>
            </Card.Body>
        </Card>
        )

        }
        </Row>


       </section> */}
      </LoadingOverlay>
    </>
  )
}

export default PrivateFloodService