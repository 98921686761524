import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

export const Discounts = () => {
  const { t } = useTranslation();
  const { discounts, setDiscounts } = useQuote();
  return (
    <>
      <Box px={1}>
        {/* <Accordion borderColor={"transparent"} width={"100%"}>
          <AccordionItem>
            {({ isExpanded }) => (
              <Box borderRadius={"xl"}>
                <h2>
                  <AccordionButton
                    pr={0}
                    borderBottomRadius={"xl"}
                    borderBottom={"1px solid var(--chakra-colors-neutral-100)"}
                    borderRadius={"xl"}
                    pt={"2"}
                    // onClick={(e) => {
                    //   setPropertyDetails({
                    //     ...propertyDetails,
                    //     fireSprinklers:
                    //       propertyDetails?.fireSprinklers === "YES"
                    //         ? "NO"
                    //         : "YES",
                    //   });
                    // }}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Building's premises has a Automatic Fire Sprinkler System"
                        )}
                        ?
                      </Text>
                    </Box>
                    <Switch
                      isChecked={propertyDetails?.fireSprinklers === "YES"}
                      onChange={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          fireSprinklers: e.target.checked ? "YES" : "NO",
                        });
                      }}
                      size="lg"
                      mr={"1rem"}
                    />
                  </AccordionButton>
                </h2>
              </Box>
            )}
          </AccordionItem>
        </Accordion> */}
        <Accordion
          allowToggle
          index={[discounts?.fireDiscountSys ? 0 : 1]}
          borderColor={"transparent"}
          width={"100%"}
          mt={2}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                    borderBottomRadius={!isExpanded ? "xl" : "0"}
                    pt={"2"}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Building's premises has a Fire Alarm System"
                        )}
                        ?
                      </Text>
                    </Box>

                    <Switch
                      isChecked={discounts?.fireDiscountSys}
                      onChange={(e) => {
                        setDiscounts({
                          ...discounts,
                          fireDiscountSys: e.target.checked,
                        });
                      }}
                      size="lg"
                      mr={"1rem"}
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {isExpanded && (
                    <>
                      <UnorderedList>
                        <ListItem>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("Central Station")}
                            </Text>

                            <Switch
                              isChecked={
                                discounts?.fireAlarmSystem === "Central Station"
                              }
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  fireAlarmSystem: e.target.checked
                                    ? "Central Station"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                          <Box
                            mb={4}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </ListItem>
                        <ListItem>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("Local Alarm")}
                            </Text>

                            <Switch
                              isChecked={
                                discounts?.fireAlarmSystem === "Local Alarm"
                              }
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  fireAlarmSystem: e.target.checked
                                    ? "Local Alarm"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                          <Box
                            mb={4}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </ListItem>
                        <ListItem mb={"-5px"}>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("None")}
                            </Text>

                            <Switch
                              isChecked={discounts?.fireAlarmSystem === "None"}
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  fireAlarmSystem: e.target.checked
                                    ? "None"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                        </ListItem>
                      </UnorderedList>
                    </>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
        <Accordion
          borderColor={"transparent"}
          width={"100%"}
          mt={2}
          allowToggle
          index={[discounts?.burglarAlarmSys ? 0 : 1]}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                    borderBottomRadius={!isExpanded ? "xl" : "0"}
                    pt={"2"}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize={"18px"} pt={3}>
                        {t(
                          "Does the Building's premises has a Burglar Alarm System"
                        )}
                        ?
                      </Text>
                    </Box>

                    <Switch
                      isChecked={discounts?.burglarAlarmSys}
                      onChange={(e) => {
                        setDiscounts({
                          ...discounts,
                          burglarAlarmSys: e.target.checked,
                        });
                      }}
                      size="lg"
                      mr={"1rem"}
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {isExpanded && (
                    <>
                      <UnorderedList>
                        <ListItem>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("Central Station")}
                            </Text>

                            <Switch
                              isChecked={
                                discounts?.burglarAlarmSystem ===
                                "Central Station"
                              }
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  burglarAlarmSystem: e.target.checked
                                    ? "Central Station"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                          <Box
                            mb={4}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </ListItem>
                        <ListItem>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("Local Alarm")}
                            </Text>

                            <Switch
                              isChecked={
                                discounts?.burglarAlarmSystem === "Local Alarm"
                              }
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  burglarAlarmSystem: e.target.checked
                                    ? "Local Alarm"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                          <Box
                            mb={4}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </ListItem>
                        <ListItem mb={"-5px"}>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              {t("None")}
                            </Text>

                            <Switch
                              isChecked={
                                discounts?.burglarAlarmSystem === "None"
                              }
                              onChange={(e) => {
                                setDiscounts({
                                  ...discounts,
                                  burglarAlarmSystem: e.target.checked
                                    ? "None"
                                    : "",
                                });
                              }}
                              size="lg"
                            />
                          </Flex>
                        </ListItem>
                      </UnorderedList>
                    </>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion>
        {/* <Accordion
          borderColor={"transparent"}
          width={"100%"}
          mt={2}
          allowToggle
          index={[propertyDetails?.hasIOTSys ? 0 : 1]}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <Box
                border={
                  isExpanded ? "1px solid var(--chakra-colors-neutral-50)" : ""
                }
                borderRadius={"xl"}
              >
                <h2>
                  <AccordionButton
                    pr={0}
                    borderTopRadius={"xl"}
                    bg={isExpanded ? "var(--chakra-colors-primary-50) " : ""}
                    borderBottom={
                      !isExpanded
                        ? "1px solid var(--chakra-colors-neutral-100)"
                        : ""
                    }
                    borderBottomRadius={!isExpanded ? "xl" : "0"}
                    pt={"2"}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize={"18px"} pt={3}>
                        {t("Does the Building's premises has a IOT Device")}?
                      </Text>
                    </Box>

                    <Switch
                      isChecked={propertyDetails?.hasIOTSys}
                      onChange={(e) => {
                        setPropertyDetails({
                          ...propertyDetails,
                          hasIOTSys: e.target.checked,
                        });
                      }}
                      size="lg"
                      mr={"1rem"}
                    />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  {isExpanded && (
                    <>
                      <UnorderedList>
                        <ListItem>
                          <Flex justifyContent="space-between">
                            <Text
                              fontSize={"18px"}
                              display={"flex"}
                              alignItems={"center"}
                              gap={3}
                            >
                              N/A (TBD)
                            </Text>

                            <Switch
                              isChecked={
                                propertyDetails?.burglarAlarmSystem === ""
                              }
                              onChange={(e) => {
                                setPropertyDetails({
                                  ...propertyDetails,
                                  burglarAlarmSystem: e.target.checked
                                    ? ""
                                    : "",
                                });
                              }}
                              isDisabled
                              size="lg"
                            />
                          </Flex>
                          <Box
                            mb={4}
                            borderTop={"1px solid"}
                            borderColor={"primary.50"}
                          />
                        </ListItem>
                      </UnorderedList>
                    </>
                  )}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        </Accordion> */}
      </Box>
    </>
  );
};
