import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import useAutoEastedHook from "../common/AutoLocationDate";
import moment from "moment";
import { formateDate } from "../../common/FormatDate";
import { getAutoZipData } from "../create-star-quote/apis/getAutoZipData";
import { EncryptOrDecrypt } from "../../../services/commonService";
import { propertySchema } from "./commercial-quote/data/propertySchema";
import { coveragesSchema } from "./commercial-quote/data/coveragesSchema";
import { fetchStarComBaseData } from "./commercial-quote/Api/starCommercialDD";
import { toast } from "react-toastify";

const QuoteContext = createContext();

const LoggedUserDetails =
  EncryptOrDecrypt(localStorage.getItem("user"), "D") || {};

const steps = [
  "Policy",
  "Underwriting",
  "Discounts",
  "Coverages",
  "Confirmation",
  "Agreements",
  "Payment",
];

const identifyBusinessType = (businessName) => {
  if (!businessName) {
    return "Other";
  }
  const lowerCaseName = businessName.toLowerCase();
  if (
    lowerCaseName.includes("llc") ||
    lowerCaseName.includes("limited liability")
  ) {
    return "Limited Liability Entity";
  } else if (lowerCaseName.includes("corp") || lowerCaseName.includes("inc")) {
    return "Corporation";
  } else if (
    lowerCaseName.includes("lp") ||
    lowerCaseName.endsWith("limited partnership")
  ) {
    return "Individual or Limited Partnership";
  } else if (lowerCaseName.includes("partnership")) {
    return "Partnership";
  } else {
    return "Other";
  }
};

export const QuoteProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [masterQuoteData, setMasterQuoteData] = useState({
    quoteNumber: null,
    premiumToDisplay: 0,
    policy: {
      businessEstablishment: "New Venture",
      numberOfBuildings: 1,
      effectiveDate: formateDate(moment().add(30, "days")),
      effectiveToDate: "",
    },
    applicantDetails: {
      lastName: "",
      phoneNumber: "",
      email: "",
      entityType: "",
      sameMailingAddress: true,
      blanket: "NO",
      mailingAddress: {
        address: "",
        zipCode: "",
        city: "",
        stateCode: "",
        county: "",
        //-------//
        cityId: 0,
        stateId: 0,
        countryId: 1,
        countyId: 0,
      },
    },
  });
  const [hotelResponses, setHotelResponses] = useState({
    restaurant_exposure: "Full Restaurant",
    room_access: "Exterior Hallway",
    number_of_stories: "1",
  });
  const [convenienceStoreResponses, setConvenienceStoreResponses] = useState({
    is_gas_station: false,
    is_convenience_store: true,
    is_restaurant: false,
  });

  const [coverages, setCoverages] = useState([
    {
      ...coveragesSchema,
    },
  ]);

  const [blanketDisplayCoverages, setBlanketDisplayCoverages] = useState({
    ...coveragesSchema,
  });
  const [saveMortgagee, setSaveMortgagee] = useState([]);
  const [hasMortagee, setHasMortagee] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [agent, setAgent] = useState({
    selectedAgency: {
      NPN: "",
      selected: true,
      value: null,
      label: "",
    },
    selectedAgent: {
      NPN: "",
      selected: true,
      state: "",
      value: null,
      label: "",
    },
  });
  const [discounts, setDiscounts] = useState({
    fireDiscountSys: false,
    fireAlarmSystem: "",
    burglarAlarmSys: false,
    burglarAlarmSystem: "",
    hasIOTSys: false,
    iotDevice: "",
  });
  const [buildingDetails, setBuildingDetails] = useState([
    {
      ...propertySchema,
      locationIndetifier: "Building 01",
    },
  ]);

  const [baseDD, setBaseDD] = useState([]);
  const [lossHistoryData, setLossHistoryData] = useState([]);
  const [validationError, setValidationError] = useState({
    confirmation: false,
    subscriberAgreement: false,
    termsAndConditions: false,
    fraudStatement: false,
  });

  const [underWriting, setUnderWriting] = useState({
    lossHistoryQuestion: false,
    shuttleServices: false,
    franchise: false,
    onlyOwnerOccupied: true,
    franchiseDescription: "",
    onlyOwnerOccupiedDescription: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selections, setSelections] = useState({});
  const [currentZipData, setCurrentZipData] = useState({});
  const [confirmation, setConfirmation] = useState({
    checkboxes: {
      readSubscriberAgreement: false,
      readTermsAndConditions: false,
      readFraudStatement: false,
    },
  });

  const [agreements, setAgreements] = useState({
    acknowledge1: false,
    acknowledge2: false,
    acknowledge3: false,
    acknowledge4: false,
    acknowledge5: false,
    acknowledge6: false,
    acknowledge7: false,
    acknowledge8: false,
  });
  const {
    locationData: location,
    autoGenData,
    autoGenLoading,
  } = useAutoEastedHook({
    isEnabled: true,
  });

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchStarComBaseData();
      if (data) {
        setBaseDD(data || {});
      } else {
        toast.error("Failed to fetch data", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const calculateSummation = () => {
      const summation = coverages.reduce(
        (acc, coverage) => {
          // Basic Coverages
          acc.basicCoverages.buildingRCV +=
            parseInt(coverage.basicCoverages.buildingRCV, 10) || 0;
          acc.basicCoverages.buildingPersonalPropertyCVG +=
            parseInt(coverage.basicCoverages.buildingPersonalPropertyCVG, 10) ||
            0;
          acc.basicCoverages.buildingExtraExpenses +=
            parseInt(coverage.basicCoverages.buildingExtraExpenses, 10) || 0;

          // Additional Coverages
          acc.additionalCoverages.canopy +=
            parseInt(coverage.additionalCoverages.canopy, 10) || 0;
          acc.additionalCoverages.pumps +=
            parseInt(coverage.additionalCoverages.pumps, 10) || 0;
          acc.additionalCoverages.sign +=
            parseInt(coverage.additionalCoverages.sign, 10) || 0;

          return acc;
        },
        {
          basicCoverages: {
            buildingRCV: 0,
            buildingPersonalPropertyCVG: 0,
            buildingExtraExpenses: 0,
            buildingCVPercent: 80,
            buissnessWaitingPeriod: "48 Hours",
            restorationFactor: "12 Months",
            coveredPerils: "Named Perils",
            theftExclusions: "YES",
            windExclusions: "YES",
          },
          additionalCoverages: {
            waterSublimit: "",
            allPerilsDed: "",
            excludSinkhole: "NO",
            cosmeticRoofDamage: "NO",
            acvRoofSurface: "NO",
            canopy: 0,
            pumps: 0,
            sign: 0,
          },
          deductibles: {
            allPerilsDed: "COMMEQDED1000",
            hurricaneDed: "1%",
            theftDed: "",
            windHailDed: "",
          },
        }
      );

      setBlanketDisplayCoverages(summation);
    };

    calculateSummation();
  }, [coverages]);

  const addNewCoverage = (count = 1) => {
    const newCoverage = Array.from({ length: count }, (_, i) => ({
      ...coveragesSchema,
    }));
    setCoverages([...coverages, ...newCoverage]);
  };

  const updateBuildingDetails = (updatedDetails) => {
    setBuildingDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      newDetails[0] = { ...newDetails[0], ...updatedDetails };
      return newDetails;
    });
  };

  useEffect(() => {
    if (Array.isArray(buildingDetails) && buildingDetails.length > 0) {
      addNewCoverage(buildingDetails.length - coverages.length);
    }
  }, [buildingDetails]);

  useEffect(() => {
    if (!location || !autoGenData || Object.keys(autoGenData).length === 0)
      return;

    const getZipIds = async () => {
      if (!location || !location.postalCode) {
        return;
      }
      const result = await getAutoZipData(location?.postalCode);

      if (result) {
        setCurrentZipData(result);
      }
    };
    const propDetails = autoGenData?.PropDetails;
    const ownerName = propDetails?.OWN_NAME || "";
    const actualYearBuilt = propDetails?.ACT_YR_BLT || 0;

    setMasterQuoteData((prevData) => ({
      ...prevData,
      applicantDetails: {
        ...prevData.applicantDetails,
        lastName: ownerName,
        entityType: identifyBusinessType(ownerName),
      },
    }));
    setCoverages((prevCoverages) => {
      const updatedCoverages = [...prevCoverages];
      updatedCoverages[0] = {
        ...updatedCoverages[0],
        basicCoverages: {
          ...updatedCoverages[0].basicCoverages,
          buildingRCV: propDetails?.RCV || 0,
        },
      };
      return updatedCoverages;
    });

    updateBuildingDetails({
      // ageOfBuilding,
      yearBuilt: actualYearBuilt,
      noOfStories: propDetails?.NO_STORIES > 10 ? "11-30" : "1-10",
      noOfUnits: propDetails?.NO_RES_UNTS || null,
      squareFootage: propDetails?.TOT_LVG_AR || null,
      locationAddress: location?.fullAddress || "",
      locationCity: location?.city || "",
      locationState: location?.validStateCode || "",
      locationZip: location?.postalCode || "",
      locationCounty: location?.countyName || "",
    });

    getZipIds();
  }, [autoGenData, location]);

  useEffect(() => {
    if (masterQuoteData?.policy?.effectiveDate) {
      setMasterQuoteData(() => ({
        ...masterQuoteData,
        policy: {
          ...masterQuoteData?.policy,
          effectiveToDate: moment(masterQuoteData?.policy?.effectiveDate)
            .add(1, "years")
            .format("YYYY-MM-DD"),
        },
      }));
    }
  }, [masterQuoteData?.policy?.effectiveDate]);

  useEffect(() => {
    setAgent({
      selectedAgency: {
        NPN: "0",
        selected: false,
        ...agent,
        ...agent.selectedAgency,
        value: LoggedUserDetails?.persInfoAgencyFK || 3,
        label:
          LoggedUserDetails?.agencyName || "0001 - STARLIGHT INSURANCE GROUP",
      },
      selectedAgent: {
        NPN: "0",
        selected: true,
        ...agent.selectedAgent,
        value: LoggedUserDetails?.Admin_ID || 1,
        label: LoggedUserDetails?.s_ScreenName || "STARINSURANCE_ADMIN",
      },
    });
  }, [LoggedUserDetails]);

  const value = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      location,
      masterQuoteData,
      setMasterQuoteData,
      autoGenData,
      isLoading,
      setIsLoading,
      selectedCard,
      setSelectedCard,
      selections,
      setSelections,
      autoGenLoading,
      baseDD,
      setBaseDD,
      lossHistoryData,
      setLossHistoryData,
      underWriting,
      setUnderWriting,
      coverages,
      setCoverages,
      confirmation,
      setConfirmation,
      agreements,
      setAgreements,
      steps,
      apiResponse,
      setApiResponse,
      currentZipData,
      agent,
      hotelResponses,
      setHotelResponses,
      convenienceStoreResponses,
      setConvenienceStoreResponses,
      buildingDetails,
      setBuildingDetails,
      discounts,
      setDiscounts,
      blanketDisplayCoverages,
      setBlanketDisplayCoverages,
      saveMortgagee,
      setSaveMortgagee,
      hasMortagee,
      setHasMortagee,
      validationError,
      setValidationError,
    }),
    [
      activeStep,
      location,
      masterQuoteData,
      autoGenData,
      isLoading,
      selectedCard,
      selections,
      autoGenLoading,
      baseDD,
      lossHistoryData,
      underWriting,
      coverages,
      confirmation,
      agreements,
      apiResponse,
      currentZipData,
      agent,
      hotelResponses,
      convenienceStoreResponses,
      buildingDetails,
      discounts,
      blanketDisplayCoverages,
      saveMortgagee,
      hasMortagee,
      validationError,
    ]
  );

  return (
    <QuoteContext.Provider value={value}>{children}</QuoteContext.Provider>
  );
};

export const useQuote = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useQuote must be used within a QuoteProvider.");
  }
  return context;
};
