export const standardizeKeyName = (key) => {
  const standardizedNames = {
    improvementsAndBettermentsPremium: "Improvements and Betterments Premium",
    contentsPremium: "Contents Premium",
    businessIncomePremium: "Business Income Premium",
    buildingPremium: "Building Premium",
    otherStructuresPremium: "Other Structures Premium",
    lossOfUsePremium: "Loss of Use Premium",
    fees: "Fees",
    premium: "Premium",
    totalFee: "Total Fees",
    finalPremium: "Final Premium",
  };
  return standardizedNames[key] || key;
};
