import React from "react";
// import { Table } from "react-bootstrap";
import { TextMask, InputAdapter } from "react-text-mask-hoc";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
} from "@chakra-ui/react";

const phoneNoMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const AgencyDetails = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Box p={6}>
        <Grid alignItems={"flex-end"} templateColumns="repeat(3, 1fr)" gap={4}>
          <FormControl>
            <FormLabel>{t("Web")}</FormLabel>
            <Input
              type="text"
              id="web_s_WebsiteURL"
              name="web_s_WebsiteURL"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.web_s_WebsiteURL}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Phone")}</FormLabel>
            <TextMask
              mask={phoneNoMask}
              Component={InputAdapter}
              className="form-control text-mask-input producer-group-input col-form-input input-producer"
              type="text"
              id="phone_s_PhoneNumber"
              name="phone_s_PhoneNumber"
              value={props.AgencyData?.phone_s_PhoneNumber}
              disabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Fax")}</FormLabel>
            <TextMask
              mask={phoneNoMask}
              Component={InputAdapter}
              className="form-control text-mask-input producer-group-input col-form-input input-producer"
              type="text"
              id="fax_s_FaxNumber"
              name="fax_s_FaxNumber"
              value={props.AgencyData?.fax_s_FaxNumber}
              disabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Manager")}</FormLabel>
            <Input
              type="text"
              id="manager_s_FirstName"
              name="manager_s_FirstName"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.manager_s_FirstName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Manager Phone")}</FormLabel>
            <TextMask
              mask={phoneNoMask}
              Component={InputAdapter}
              className="form-control text-mask-input producer-group-input col-form-input input-producer"
              type="text"
              id="manager_s_PhoneNumber"
              name="manager_s_PhoneNumber"
              value={props.AgencyData?.manager_s_PhoneNumber}
              disabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Manager Email")}</FormLabel>
            <Input
              type="email"
              id="manager_s_EmailAddress"
              name="manager_s_EmailAddress"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.manager_s_EmailAddress}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Principal")}</FormLabel>
            <Input
              type="text"
              id="contact_s_ContactName"
              name="contact_s_ContactName"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.contact_s_ContactName}
              isDisabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Principal Phone")}</FormLabel>
            <TextMask
              mask={phoneNoMask}
              Component={InputAdapter}
              className="form-control text-mask-input producer-group-input col-form-input input-producer"
              type="text"
              id="contact_s_PhoneNumber"
              name="contact_s_PhoneNumber"
              value={props.AgencyData?.contact_s_PhoneNumber}
              disabled={true}
            />
          </FormControl>
          <FormControl>
            <FormLabel>{t("Principal Email")}</FormLabel>
            <Input
              type="email"
              id="contact_s_Email"
              name="contact_s_Email"
              className="form-control producer-group-input col-form-input input-producer"
              value={props.AgencyData?.contact_s_Email}
              isDisabled={true}
            />
          </FormControl>
        </Grid>
      </Box>
      {/* <div>
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <td>{t("Web")}</td>
              <td>
                <input className="producer-group-input-size" type="text" name="Web" value={props.AgencyData?.web_s_WebsiteURL ? props.AgencyData?.web_s_WebsiteURL : null }
                disabled={true} />
              </td>
              <td>{t("Phone")}</td>
              <td>
              <TextMask
                mask={phoneNoMask}
                Component={InputAdapter}
                className="form-control producer-group-input-size"
                type="text"
                id="phone_s_PhoneNumber"
                name="phone_s_PhoneNumber"
                value={props.AgencyData?.phone_s_PhoneNumber ? props.AgencyData?.phone_s_PhoneNumber : null }
                disabled={true}
              />
              </td>
              <td>{t("Fax")}</td>
              <td>
              <TextMask
                mask={phoneNoMask}
                Component={InputAdapter}
                className="form-control producer-group-input-size"
                type="text"
                id="fax_s_FaxNumber"
                name="fax_s_FaxNumber"
                value={props.AgencyData?.fax_s_FaxNumber ? props.AgencyData?.fax_s_FaxNumber : null }
                disabled={true}
              />
              </td>
            </tr>
            <tr>
              <td>{t("Manager")}</td>
              <td>
                <input type="text" className="producer-group-input-size" name="version" id="version" value={props.AgencyData?.manager_s_FirstName ? props.AgencyData?.manager_s_FirstName : null }
                disabled={true}
                />
              </td>
              <td>{t("Manager Phone")}</td>
              <td>
              <TextMask
                mask={phoneNoMask}
                Component={InputAdapter}
                className="form-control producer-group-input-size"
                type="text"
                id="manager_s_PhoneNumber"
                name="manager_s_PhoneNumber"
                value={props.AgencyData?.manager_s_PhoneNumber ? props.AgencyData?.manager_s_PhoneNumber : null }
                disabled={true}
              />
              </td>
              <td>{t("Manager Email")}</td>
              <td className="">
                <input type="email" className="producer-group-input-size" name="Manager Email" id="version" value={props.AgencyData?.manager_s_EmailAddress ? props.AgencyData?.manager_s_EmailAddress : null }
                disabled={true}/>
              </td>
            </tr>
            <tr>
              <td>{t("Principal")}</td>
              <td>
                <input type="text" className="producer-group-input-size" name="Principal" id="version" value={props.AgencyData?.contact_s_ContactName ? props.AgencyData?.contact_s_ContactName : null }
                disabled={true}/>
              </td>
              <td>{t("Principal Phone")}</td>
              <td>
              <TextMask
                mask={phoneNoMask}
                Component={InputAdapter}
                className="form-control producer-group-input-size"
                type="text"
                id="contact_s_PhoneNumber"
                name="contact_s_PhoneNumber"
                value={props.AgencyData?.contact_s_PhoneNumber ? props.AgencyData?.contact_s_PhoneNumber : null }
                disabled={true}
              />
              </td>
              <td>{t("Principal Email")}</td>
              <td>
                <input type="email" className="producer-group-input-size" name="Principal_Email" id="version" value={props.AgencyData?.contact_s_Email ? props.AgencyData?.contact_s_Email : null }
                disabled={true}/>
              </td>
            </tr>
          </tbody>
        </Table>
      </div> */}
    </>
  );
};

export default AgencyDetails;
