import React, { useState } from "react";
import ActivityLog from "../../activity-log/inbox/Inbox";
import MessageView from "../../activity-log/message-view/MessageView";
import Reply from "../../activity-log/reply/Reply";

const ActivityLogModal = (props) => {
    const { policyNumber, from } = props;
    const [messageId, setMessageId] = useState(0);
    const [viewPart, setViewPart] = useState('VIEW');

    const setMessagePk = (type, id = null) => {
        console.log(type, id)
        id !== null ? setMessageId(id) : setMessageId(0);
        setViewPart(type);
    }

    return (
        <>
            {
                viewPart === "VIEW" ?
                    <ActivityLog
                        activityType='POLICY'
                        sourceCode={policyNumber}
                        viewPart={setMessagePk}
                        setValuesCondition={setMessagePk}
                        from={from}
                    />
                    : viewPart === "DETAIL" ?
                        <MessageView
                            sourceCode={policyNumber}
                            viewPart={setMessagePk}
                            messageId={messageId}
                            setValuesCondition={setMessagePk}
                        />
                        : <Reply
                            activityType='POLICY'
                            sourceCode={policyNumber}
                            viewPart={setMessagePk}
                            messageId={messageId}
                        />
            }
        </>
    )
}

export default ActivityLogModal;