import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";
import MortgageeModal from "./MortgageeModal";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { VscDiffAdded } from "react-icons/vsc";

const Mortgagees = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const {
    mortgageeDetails,
    setMortgageeDetails,
    hasMortgagee,
    setHasMortgagee,
  } = useMyContext(CreateQuoteContext);

  useEffect(() => {
    if (!hasMortgagee) {
      setMortgageeDetails([]);
    }
  }, [hasMortgagee]);

  return (
    <div>
      <Box mb={5}>
        <Flex as={"h6"} gap={2} flexDir={"row"} width="60%">
          <Text fontSize="md" mb="0" pt={2}>
            {t("Do you have Mortgagees")}?
          </Text>
          <Flex flexDir={"row"} gap={2} width="20%" margin="auto">
            <Switch
              size={"lg"}
              defaultChecked={mortgageeDetails?.length > 0 ? true : false}
              onChange={(e) => setHasMortgagee(e.target.checked)}
            ></Switch>
          </Flex>
        </Flex>
        {hasMortgagee && (
          <Flex pb={3} mt={3}>
            <Button
              borderRadius={"lg"}
              onClick={() => setIsOpen(true)}
              color="white"
              leftIcon={<VscDiffAdded size={20} />}
            >
              {t("Add mortgagee")}
            </Button>
            <MortgageeModal isOpen={isOpen} setIsOpen={setIsOpen} />
          </Flex>
        )}
      </Box>
    </div>
  );
};

export default Mortgagees;
