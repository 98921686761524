export const standardizeKeyName = (key) => {
  const standardizedNames = {
    improvementsAndBettermentsPremium: "Improvements and Betterments Premium",
    contentsPremium: "Contents Premium",
    businessIncomePremium: "Business Income Premium",
    buildingPremium: "Building Premium",
    otherStructuresPremium: "Other Structures Premium",
    lossOfUsePremium: "Loss of Use Premium",
    deductible: "Deductible",
    buildingAdditionalPremium: "Building Additional Premium",
    includeContentsAdditionalPremium: "Include Contents Additional Premium",
    excludeContentsAdditionalPremium: "Exclude Contents Additional Premium",
    otherStructuresAdditionalPremium: "Other Structures Additional Premium",
    includeLossOfUseAdditionalPremium: "Include Loss of Use Additional Premium",
    excludeLossOfUseAdditionalPremium: "Exclude Loss of Use Additional Premium",
    buildingAnnualizedPremium: "Building Annualized Premium",
    includeContentsAnnualizedPremium: "Include Contents Annualized Premium",
    excludeContentsAnnualizedPremium: "Exclude Contents Annualized Premium",
    otherStructuresAnnualizedPremium: "Other Structures Annualized Premium",
    includeLossOfUseAnnualizedPremium: "Include Loss of Use Annualized Premium",
    excludeLossOfUseAnnualizedPremium: "Exclude Loss of Use Annualized Premium",
    totalFee: "Total Fees",
  };
  return standardizedNames[key] || key;
};
