import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import Select from 'react-select';
import alertImg from "../../../../../assets/flood/question-mark.png";

import { getEditEmptyQuoteData, updateInitQuoteApi, pivotApiCall, updateWhichSection } from "../../../../../services/floodService";
import { customStyles } from "../../../../../components/reactSelectCustomStyle";
import DatePicker from "react-datepicker";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./discount.scss";
import "react-overlay-loader/styles.css";

const ModeDiscountForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { initApiObject, apiLoading, quoteResponseDetails, floodSystemDD, whichSection } = useSelector(state => state.flood);
  const [showOptions, setShowOptions] = useState(false);
  const [doVerify, setDoVerify] = useState(false);
  const [validated, setValidated] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isNewPurchase, setIsNewPurchase] = useState('NO');
  const [priorPolicyNo, setPriorPolicyNo] = useState(null);
  const [priorCompanyNo, setPriorCompanyNo] = useState(null);
  const [isStateSet, setIsStateSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [NAICList, setNAICList] = useState({});
  const [isSellerDec, setIsSellerDec] = useState(null);
  const tenant = window.location.host.split('.')[0];

  const [insuredReside, setInsuredReside] = useState('NO');
  const [isValidEC, setIsValidEC] = useState(false);
  const [certDate, setCertDate] = useState(null);
  const [diagramNo, setDiagramNo] = useState(false);
  const [topBottomFloor, setTopBottomFloor] = useState(false);
  const [LAG, setLAG] = useState(false);
  const [topHigherFloor, setTopHigherFloor] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [residentialsCondition, setresidentialsCondition] = useState(false)

  // const tenantFarmer = 'farmers';

  // set Initial State from reducer and get NAIC
  useEffect(() => {
    if (Object.keys(props.emptyQuoteData).length > 0) {
      setIsNewPurchase(props.emptyQuoteData.propertyInfo.s_IsNewPurchase);
      setPriorPolicyNo(props.emptyQuoteData.propertyInfo.s_PriorPolicyNo);
      setPriorCompanyNo(props.emptyQuoteData.propertyInfo.s_PriorCompanyNAIC);
      setIsSellerDec(props.emptyQuoteData.propertyInfo.isSellerDec);
      setIsStateSet(true);
      // check reducer state 
      if (Object.keys(floodSystemDD).length > 0) {
        setNAICList(floodSystemDD.WYOList);
      }
    }
  }, []);

  useEffect(() => {
    if (isValidEC === 'NO' && isStateSet === true) {
      const emptyQuote = { ...props.emptyQuoteData }; // Copy
      emptyQuote.propertyInfo.d_CertificateDate = '';
      emptyQuote.propertyInfo.s_BuildingDiagramNoCode = '';
      emptyQuote.propertyInfo.n_TopBottomFloor = '';
      emptyQuote.propertyInfo.n_LowestAdjacent = '';
      emptyQuote.propertyInfo.n_TopHigherFloor = '';

      const reqJson = { ...props.initApiObject }; // Copy
      reqJson.ecDate = '';
      reqJson.ecDiagramNumber = '';
      reqJson.topBottomFloor = '';
      reqJson.LAG = '';
      reqJson.topHigherFloor = '';
      reqJson.whichSection = 'DISCOUNT';

      // To update
      dispatch(updateInitQuoteApi(reqJson));
    }
  }, [isValidEC])

  const callPivotApi = () => {
    if (isStateSet === true) {
      if (isValidEC === 'YES' && ![certDate, diagramNo, topBottomFloor, LAG].some(val => (val === null || val === '' || val === undefined))) {
        const reqJson = { ...props.initApiObject }; // Copy
        reqJson.isValidEC = isValidEC
        reqJson.ecDate = certDate
        reqJson.ecDiagramNumber = diagramNo
        reqJson.topBottomFloor = topBottomFloor
        reqJson.LAG = LAG
        reqJson.topHigherFloor = topHigherFloor
        reqJson.whichSection = 'DISCOUNT';

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }

  const handleChange = (e) => {
    var quoteDataVar = props.emptyQuoteData;
    quoteDataVar['propertyInfo']['s_PriorCompanyNAIC'] = e.s_NAICNumber;
    setPriorCompanyNo(e.s_NAICNumber);
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  useEffect(() => {
    if (isStateSet === true) {
      if (props.emptyQuoteData && initApiObject && doVerify) {
        setLoading(true);
        const callPivot = async () => {
          const reqJson = { ...initApiObject }; // Copy
          reqJson.isNewPurchase = isNewPurchase;
          reqJson.isSellerDec = isSellerDec;
          reqJson.priorPolicyNumber = priorPolicyNo;
          reqJson.priorNaicNumber = priorCompanyNo;
          reqJson.whichSection = 'DISCOUNT';

          // To update
          dispatch(updateInitQuoteApi(reqJson));

          const code = 'QUOTE_NEWBUSINESS';
          const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
          // Call Api
          const resp = await dispatch(pivotApiCall(code, request));
          if (resp.success && resp.data && resp.data.code === 201) {
            setVerified(true);
          } else {
            setVerified(false);
          }
          setLoading(false);
        }
        callPivot();
      }
    }
  }, [doVerify]);


  useEffect(() => {
    doVerify && setDoVerify(false);
  }, [quoteResponseDetails]);

  useEffect(() => {
    if (isStateSet === true) {
      if (isSellerDec !== 'YES' || priorPolicyNo === "" || priorCompanyNo === "") {
        var tempPriorPolicyNo = '';
        var tempPriorCompanyNo = '';
        if (isSellerDec === 'YES') {
          tempPriorPolicyNo = priorPolicyNo;
          tempPriorCompanyNo = priorCompanyNo;
        }
        const emptyQuote = { ...props.emptyQuoteData }; // Copy
        emptyQuote.propertyInfo.s_PriorPolicyNo = tempPriorPolicyNo;
        emptyQuote.propertyInfo.s_PriorCompanyNAIC = tempPriorCompanyNo;
        emptyQuote.propertyInfo.s_IsPriorNFIPPolicy = '';
        emptyQuote.propertyInfo.s_IsPriorNFIPLapsed = '';
        emptyQuote.propertyInfo.s_IsCommunitySuspension = '';

        dispatch(getEditEmptyQuoteData(emptyQuote));

        const reqJson = { ...initApiObject }; // Copy
        delete reqJson.s_PriorPolicyNo;
        delete reqJson.s_PriorCompanyNAIC;
        reqJson.whichSection = 'DISCOUNT';

        // To update
        dispatch(updateInitQuoteApi(reqJson));

        setVerified(false);
      }
    }
  }, [isSellerDec, priorPolicyNo, priorCompanyNo]);


  // // handle switch Change
  const handleSwitchChange = (e, from = 'IsNewPurchase') => {
    var temp = 'NO';
    const { attributes, checked } = e.target;
    if (checked) {
      temp = attributes.checkedvalue.value;
    }
    if (from === 'IsNewPurchase') {
      setIsNewPurchase(temp);
      if (temp === 'NO') {
        setIsSellerDec(temp);
      }
    } else if (from === 'isValidEC') {
      setIsValidEC(temp);
    } else {
      setIsSellerDec(temp);
    }
    props.handleSwitchChange(e);
  }

  // set Initial State from reducer
  useEffect(() => {
    if (Object.keys(props.emptyQuoteData).length > 0) {
       setInsuredReside(props.emptyQuoteData.propertyInfo.s_InsuredResides);
      setIsStateSet(true);
    }
  }, [props.emptyQuoteData.propertyInfo.s_InsuredResides])

  useEffect(() => {
    if (isStateSet === true) {
      if (props.emptyQuoteData && initApiObject) {
        const reqJson = { ...initApiObject }; // Copy
        reqJson.primaryResidence = insuredReside;
        // To update
        dispatch(updateWhichSection('DISCOUNT'));

        reqJson.whichSection = whichSection;
        // To update
        dispatch(updateInitQuoteApi(reqJson));

        const code = 'QUOTE_NEWBUSINESS';
        const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0 };
        // Call Api
        dispatch(pivotApiCall(code, request));
      }
    }
  }, [insuredReside]);

  useEffect(() => {
    if (Object.keys(props.emptyQuoteData).length > 0) {
      // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
      var temp = 'YES';
      if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(props.emptyQuoteData.propertyInfo.s_Usage)) {
        temp = 'NO'
      }
      props.emptyQuoteData.propertyInfo.s_InsuredResides = temp
      if(props.emptyQuoteData.viewType!='RESIDENTIALBLDG' &&  props.emptyQuoteData.viewType!='NONRESIDENTIALBLDG'){
          setresidentialsCondition(true)
      }
    }
  }, [props.emptyQuoteData]);

  const handleChangeDiscount = (e) => {
    props.handleSwitchChange(e);
    var temp = 'NO';
    const { checked } = e.target;
    if (checked) {
      temp = 'YES';
    }
    setInsuredReside(temp)
  }

  return (
    <div>
      <Row>
        {residentialsCondition && (
          <>
            <Col xs="8">
              <p>
                {t("Will the applicant or applicant's spouse live in this building more than 50% of the year")}?
              </p>
            </Col>
            <Col xs="4">
              <div className="flood-discount-question-div" >
                <div className="flood-discount-row-actions" style={{ float: "right", display: "flex" }}>
                  <span>{t('No')}</span>&nbsp;&nbsp;
                  <div className="custom-main-swtich">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className="flood-discount-row-switch"
                      label=""
                      parentobject="propertyInfo"
                      name="s_InsuredResides"
                      checked={insuredReside === 'YES' ? true : false}
                      onChange={handleChangeDiscount}
                      checkedvalue='YES'
                      uncheckedvalue='NO'
                    />
                  </div>
                  <span>{t('Yes')}</span>
                </div>
              </div>
            </Col>
          </>
        )}
        <Col xs="8">
          <p>
            {t('Is this a new real estate purchase (within 1 year)')}?

          </p>
        </Col>
        <Col xs="4">

          <div className="flood-discount-question-div">
            <div className="flood-discount-row-actions" style={{ float: "right", display: "flex" }}>
              <span>{t('No')}</span>&nbsp;&nbsp;
              <div className="custom-main-swtich">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  className="flood-discount-row-switch"
                  label=""
                  name="s_IsNewPurchase"
                  parentobject="propertyInfo"
                  checked={isNewPurchase === "YES" ? true : false}
                  onChange={(e) => { setShowOptions(!showOptions); handleSwitchChange(e); }}
                  checkedvalue='YES'
                  uncheckedvalue='NO'
                />
              </div>
              <span>{t('Yes')}</span>
            </div>
          </div>

        </Col>
        <Col xs="12">
          <Alert variant="primary" className="discount-form-alert">
            <img src={alertImg} />
            <p className="discount-form-alert-text">
              {" "}
              {t("You may qualify for a statutory discount if the prior owner had a National Flood Insurance Program (NFIP) policy that had statutory discounts applied. In order to obtain any applicable discounts, please enter the prior owner's policy information")}

            </p>
          </Alert>
        </Col>

        <Col xs="8">
          <p>
            {t('Do you have sellers policy information')}?
          </p>
        </Col>
        <Col xs="4">
          <div className="flood-discount-question-div" >
            <div className="flood-discount-row-actions" style={{ float: "right", display: "flex" }}>
              <span>{t('No')}</span>&nbsp;&nbsp;
              <div className="custom-main-swtich">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  className="flood-discount-row-switch"
                  label=""
                  name="isSellerDec"
                  parentobject="propertyInfo"
                  checked={isSellerDec === "YES" ? true : false}
                  onChange={(e) => { handleSwitchChange(e, 'isSellerDec'); }}
                  checkedvalue='YES'
                  uncheckedvalue='NO'
                />
              </div>
              <span>{t('Yes')}</span>
            </div>
          </div>
        </Col>
        <Row >
          <Col>
            {isSellerDec === 'YES' && (
              <Row>
                <Col xs="12">
                  <Row>
                    <Col
                      xs="12"
                      sm="6"
                      md="4"
                      lg="4"
                      xl="4"
                      id="discount-one-fields"
                    >
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="myform-label"
                          id="discount-form-policy-label"
                        >
                          {t('Policy NO#')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="number"
                          placeholder="0000000000"
                          name="s_PriorPolicyNo"
                          title={t('Policy NO#')}
                          parentobject="propertyInfo"
                          value={priorPolicyNo || ''}
                          min={0}
                          onChange={(e) => {
                            props.handleChange(e);
                            setPriorPolicyNo(e.currentTarget.value);
                          }}
                        // required={isNewPurchase === "YES" ? true : false}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        {t("Enter Policy NO")}
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs="12" sm="6" md="8" lg="8" xl="8">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label
                          className="myform-label"
                          id="discount-form-policy-label"
                        >
                          {t('Prior Company NAIC')}
                        </Form.Label>
                        {
                          Object.keys(NAICList).length > 0 && (
                            <Select
                              styles={customStyles}
                              title="propertyInfo"
                              name="s_PriorCompanyNAIC"
                              options={floodSystemDD.WYOList}
                              value={NAICList.filter(item => item.s_NAICNumber === priorCompanyNo)}
                              getOptionLabel={(option) => option.s_WYOCompany}
                              getOptionValue={(option) => option.s_NAICNumber}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={() => setDoVerify(true)}
                            />
                          )
                        }
                      </Form.Group>
                    </Col>
                    <Row>
                    </Row>
                  </Row>
                </Col >
              </Row>
            )}
          </Col>
        </Row>

        <Col xs="8" className="mt-5">
          <p>{t('Do you have an Elevation Certificate (may lower your premium)')}?</p>
        </Col>
        <Col xs="4" className="mt-5">
          <div className="flood-discount-question-div">
            <div className="flood-discount-row-actions" style={{ float: "right", display: "flex" }}>
              <span>{t('No')}</span>&nbsp;&nbsp;
              <div className="custom-main-swtich">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  className="flood-discount-row-switch"
                  label=""
                  parentobject="propertyInfo"
                  name="s_isValidElevationCert"
                  onChange={(e) => { handleSwitchChange(e, 'isValidEC'); }}
                  checkedvalue='YES'
                  uncheckedvalue='NO'
                  checked={isValidEC === 'YES' ? true : false}
                  onClick={() => setShowFields(!showFields)}
                />
              </div>
              <span>{t('Yes')}</span>
            </div>
          </div>
        </Col>


        {(isValidEC === "YES") && (
          <div>
            <Row>
              <Col xs="12" sm="12" md="7" lg="7" xl="7">
                <Form.Group
                  className="discount-form-two-form"
                  controlId="formBasicEmail"
                >
                  <p>
                    <strong>
                      {t('Certificate Signature Date')}
                    </strong>
                  </p>
                  <DatePicker
                    id="flood-discount-date-picker"
                    className="custom-info-date-picker"
                    placeholderText="MM/DD/YYYY"
                    selected={certDate || ''}
                    onChange={(date) => {
                      props.handleDateChange(date, 'd_CertificateDate', 'propertyInfo');
                      setCertDate(date);
                      callPivotApi()
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-baseline">
              <Col xs="12" sm="12" md="5" lg="5" xl="5">
                <Form.Group
                  className="discount-form-two-form"
                  controlId="formBasicEmail"
                >
                  <p>
                  <strong>
                    {t('Diagram Number')}
                  </strong>
                  </p>
                  <Form.Select className="myform-input"
                    name="s_BuildingDiagramNoCode"
                    title={t('Diagram Number')}
                    parentobject="propertyInfo"
                    value={diagramNo || ''}
                    onBlur={callPivotApi}
                    onChange={(e) => {
                      props.handleChange(e);
                      setDiagramNo(e.currentTarget.value);
                    }}
                    required
                  >
                    <option value={''}>{t('Select')}</option>
                    {
                      Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODECBUILDDIAGRAMNO.map((v, i) => {
                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                      })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {t("Please Select Diagram Number Error")}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col xs="12" sm="12" md="7" lg="7" xl="7">
                <Form.Group
                  className="discount-form-two-form"
                  controlId="formBasicEmail"
                >
                  <p>
                    <strong>
                        {t('Top of Bottom Floor')}{" "}
                        {t('(basement, crawlspace or enclosure flood)')}
                    </strong>
                    <span>
                    </span>
                  </p>
                  <Form.Control
                    className="myform-input"
                    type="number"
                    placeholder={t("feet")}
                    name="n_TopBottomFloor"
                    title={t('(basement, crawlspace or enclosure flood)')}
                    parentobject="propertyInfo"
                    value={topBottomFloor || ''}
                    onBlur={callPivotApi}
                    min={0}
                    onChange={(e) => {
                      props.handleChange(e);
                      setTopBottomFloor(e.currentTarget.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-baseline">
              <Col xs="12" sm="12" md="7" lg="7" xl="7">
                <Form.Group
                  className="discount-form-two-form"
                  controlId="formBasicEmail"
                >
                  <p>
                    <strong>
                      {t('Lowest Adjacent (finished) Grade Next To Building (LAG)')}
                    </strong>
                  </p>
                  <Form.Control
                    className="myform-input"
                    type="number"
                    placeholder={t("feet")}
                    name="n_LowestAdjacent"
                    title={t('Lowest Adjacent (finished) Grade Next To Building (LAG)')}
                    parentobject="propertyInfo"
                    value={LAG || ''}
                    onBlur={callPivotApi}
                    min={0}
                    onChange={(e) => {
                      props.handleChange(e);
                      setLAG(e.currentTarget.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="5" lg="5" xl="5">
                <Form.Group
                  className="discount-form-two-form"
                  controlId="formBasicEmail"
                >
                  <p>
                    <strong>
                      {t('Top of the next higher floor')}
                    </strong>
                  </p>
                  <Form.Control
                    className="myform-input"
                    type="number"
                    placeholder={t("feet")}
                    name="n_TopHigherFloor"
                    title={t('Top of the next higher floor')}
                    parentobject="propertyInfo"
                    value={topHigherFloor || ''}
                    onBlur={callPivotApi}
                    min={0}
                    onChange={(e) => {
                      props.handleChange(e);
                      setTopHigherFloor(e.currentTarget.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        )}
      </Row>
    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    apiLoading: state.flood.apiLoading ? state.flood.apiLoading : false,
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
  };
}
export default connect(mapStateToProps)(ModeDiscountForm);