import {
  Box,
  Center,
  HStack,
  Image,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  VStack,
  Wrap,
  WrapItem,
  TabList,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GettingStartedSection from "./support/section/GettingStartedSection";
import PolicySystemSection from "./support/section/PolicySystemSection";
import ClaimsSystemSection from "./support/section/ClaimsSystemSection";
import AgencyManagementSection from "./support/section/AgencyManagementSection";
import { useTranslation } from "react-i18next";

export default function HelpTopics2(props) {
  const { t } = useTranslation();
  const { selectedObj } = props;
  const navigate = useNavigate();
  useEffect(() => {
    var card = "";
    if (Object.keys(selectedObj).length > 0) {
      if (selectedObj.card === "Getting Started") {
        card = 1;
      } else if (selectedObj.card === "Policy System") {
        card = 2;
      } else if (selectedObj.card === "Claims System") {
        card = 3;
      } else if (selectedObj.card === "Agency Management") {
        card = 4;
      }
      handleClick1(card, selectedObj.subSection);
    }
    //eslint-disable-next-line
  }, [selectedObj]);

  const handleClick1 = (cardNumber, div = "") => {
    if (div === "") {
      navigate(`/GettingStartedSection/${cardNumber}`);
    } else {
      navigate(`/GettingStartedSection/${cardNumber}/${div}`);
    }
  };

  // const handleClick2 = (cardNumber, div = "") => {
  //   if (div === "") {
  //     navigate(`/PolicySystemSection/${cardNumber}`);
  //   } else {
  //     navigate(`/PolicySystemSection/${cardNumber}/${div}`);
  //   }
  // };

  // const handleClick3 = (cardNumber, div = "") => {
  //   if (div === "") {
  //     navigate(`/ClaimsSystemSection/${cardNumber}`);
  //   } else {
  //     navigate(`/ClaimsSystemSection/${cardNumber}/${div}`);
  //   }
  // };

  // const handleClick4 = (cardNumber, div = "") => {
  //   if (div === "") {
  //     navigate(`/AgencyManagementSection/${cardNumber}`);
  //   } else {
  //     navigate(`/AgencyManagementSection/${cardNumber}/${div}`);
  //   }
  // };

  const TabContent = ({ title, imgSrc }) => {
    return (
      <Wrap>
        <WrapItem
          p={5}
          w="120px"
          h="120px"
          border={"1px solid #F3F6F9"}
          background={"#F3F6F9"}
          ml={5}
          boxShadow={'lg'}
          borderRadius={"10px"}
          cursor={"pointer"}
        >
          <VStack m={"auto"}>
            <Box>
              <Center>
                <Image src={imgSrc} boxSize={"25px"} />
              </Center>
            </Box>
            <Box>
              <Center>
                <Text fontSize={"sm"} textAlign={"center"}>
                  {title}
                </Text>
              </Center>
            </Box>
          </VStack>
        </WrapItem>
      </Wrap>
    );
  };

  return (
    <>
      <Box px={'3px'} height={"100%"} width={"100%"}  boxShadow={"lg"} borderBottomRadius={'2xl'}>
        <Box>
          <Box m="12px">
            <Text fontSize="20px" fontWeight={600} pl={3}>
              <HStack spacing="10px">
                <Box>
                  <Image src={"/assets/icons/messageQuestionIcon.svg"} alt="tag" width={25} height={25} />
                </Box>
                <Box>{t("help_topics")}</Box>
              </HStack>
            </Text>
          </Box>
        </Box>
        <Box my={"14px"} borderTop={"2px solid"} borderColor={"gray.200"} />
        <Box borderBottomRadius={"2xl"}>
          <Tabs>
            <TabList>
              <Tab key="gettingStarted">
                <TabContent
                  title={t("getting_started")}
                  imgSrc={"/assets/icons/sendIcon.svg"}
                />
              </Tab>
              <Tab key="policySystem">
                <TabContent title={t("policy_system")} imgSrc={"/assets/icons/cardTickIcon.svg"} />
              </Tab>
              <Tab key="claimSystem">
                <TabContent title={t("claim_system")} imgSrc={"/assets/icons/triangleResourceIcon.svg"} />
              </Tab>
              <Tab key="agencyManagement">
                <TabContent
                  title={t("agency_management")}
                  imgSrc={"/assets/icons/cardTickIcon.svg"}
                />
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel key="gettingStarted">
                <GettingStartedSection />
              </TabPanel>
              <TabPanel key="policySystem">
                <PolicySystemSection />
              </TabPanel>
              <TabPanel key="claimSystem">
                <ClaimsSystemSection />
              </TabPanel>
              <TabPanel key="agencyManagement">
                <AgencyManagementSection />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
}
