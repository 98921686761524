import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { FaUpload, FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AddAttachment from "./AddAttachment";
import { generateTempUrl } from "../../../services/floodService";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ListRequiredDoc = (props) => {
    const { loading, setLoading, cancelJSON } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [docList, setDocList] = useState([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [docData, setDocData] = useState({});

    useEffect(() => {
        setList();
    }, [cancelJSON])

    const setList = async () => {
        setLoading(true);
        var tempDocList = [];
        if (cancelJSON.document.length > 0) {
            cancelJSON.document.map((v, i) => {
                tempDocList.push({
                    "id": i,
                    ...v
                });
            });

            setDocList(tempDocList);
        }
        setLoading(false);
    }

    // handle on click
    const handleOnClick = (row) => {
        setDocData(row);
        setShowAttachmentModal(true);
    }

    // view documet
    const viewDocument = async (selectedDocData) => {
        const params = {
            'path': selectedDocData.path
        };

        setLoading(true);
        await dispatch(generateTempUrl(params)).then((res) => {
            if (res.status === 'Y') {
                window.open(res.data);
            } else {
                toast.error(res.data);
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            toast.error(t("Something Went Wrong"));
        });
    }

    const uploadOrViewFile = (cell, row) => {
        if (row.is_attached === "N") {
            return <Button
                variant="light"
                id="search-quote-btn"
                onClick={() => handleOnClick(row)}
            >
                <FaUpload size={20} />
            </Button>
        } else {
            return <Button
                variant="link"
                id="search-quote-btn"
                onClick={() => {
                    if (![null, "", undefined].includes(row.path)) {
                        viewDocument(row);
                    }
                }}
            >
                <FaEye size={20} />
            </Button>
        }
    }

    const columns = [
        {
            dataField: "id",
            hidden: true
        },
        {
            dataField: "docTypeForDisplay",
            text: t("Name"),
        },
        {
            dataField: "is_attached",
            text: t("Is Attached"),
        },
        {
            dataField: "url",
            text: t("Action"),
            formatter: uploadOrViewFile
        }
    ];

    const handleOnAttachmentClose = (value) => {
        setShowAttachmentModal(value);
        setDocData({});
    }

    return (
        <>
            {
                !showAttachmentModal && (
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <BootstrapTable
                            noDataIndication='No data to display'
                            wrapperClasses="dashboard-table-main"
                            bootstrap4
                            keyField="id"
                            data={docList}
                            columns={columns}
                            hover
                        />
                    </Col>
                )
            }
            {
                showAttachmentModal && (
                    <AddAttachment
                        {...props}
                        loading={loading}
                        setLoading={setLoading}
                        handleOnAttachmentClose={handleOnAttachmentClose}
                        docData={docData}
                        setDocData={setDocData}
                    />
                )
            }
        </>
    )
}

export default ListRequiredDoc;
