import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  getProfileInfo,
  // getuserNameSavedprofile,
  getOtherInfoSavedProfile,
  createPassword,
  getPasswordInfoSavedProfile,
  GetSelectedFileUploaded,
  getAgentExcessInfo,
  saveAgentExcessInfo,
  getExcessProducts,
} from "../../services/profileService.js";
import { useTranslation } from "react-i18next";
import { FaCloudUploadAlt, FaCopy } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { EncryptOrDecrypt } from "../../services/commonService";
import MaskedFormControl from 'react-bootstrap-maskedinput';
import PasswordChecklist from "react-password-checklist";
import AssociateAgency from './AssociateAgency';
import "./ProfileStyle.css";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { FaKey, FaRegSave } from "react-icons/fa";
import { IconButton } from "@chakra-ui/react";

import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  InputRightElement,
  InputGroup,
  Button,
  Text,
  Tooltip,
  VStack,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  // ModalCloseButton,
  Spacer,
  Switch,
} from "@chakra-ui/react";
import { MdHelpOutline } from "react-icons/md";
import { VscSaveAs } from "react-icons/vsc";
import { GrUpdate } from "react-icons/gr";

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [profileInfo, setProfileInfo] = useState({});
  // const [excessInfo, setExcessInfo] = useState();
  const [excessList, setExcessList] = useState({});
  const [fileslected, setFile] = React.useState([]);
  const [passwordInfo, setPasswordInfo] = useState({
      old_Password : null,
      new_Password : null,
      confirm_Password : null
  })
  const [loading, setLoading] = React.useState(false);
  // const [Username, setUserName] = React.useState("");
  const [aonUsername, setAonusername] = React.useState("");
  const [aonPassword, setAonpassword] = React.useState("");
  const [aonId, setAonid] = React.useState(0);
  const [credentialType, setAoncredentialtype] = React.useState("");
  const [passwordModel, setPasswordModel] = React.useState(false);
  const [generatePassinput, setGeneratePassinput] = React.useState('');
  const [toggleSwitch, setToggleSwitch] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const [pass, setPass] = useState("");
  const [rePass, setRePass] = useState("");
  const [showPassValidator, setShowPassValidator] = useState(false);
  // const [passValidation, setPassValidation] = useState(false);
  const [passwordUserIdMatchValidation, setPasswordUserIdMatchValidation] =
    useState(false);
  const [agentUPN, setAgentUPN] = useState("");
  const [showAssociateUPN, setShowAssociateUPN] = useState(false);
  // const [showOldPassword, setShowOldPassword] = useState(false);
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [showpasswordRules, setShowpasswordRules] = useState(false);
  useEffect(() => {
	getExcessList();
    getProfileInformation();
    getAgentExcessData();
    //eslint-disable-next-line
  }, []);

 useEffect(() => {
     if (![null, '', undefined].includes(profileInfo.ImageURL)) {
    var EncryptUserData = '';
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
    userDatas.profileImage = profileInfo.ImageURL;
    EncryptUserData = EncryptOrDecrypt(userDatas, 'E');
    localStorage.setItem('user', EncryptUserData);
    }
    }, [profileInfo]);

  useEffect(() => {
    if (pass !== "" && pass !== undefined) {
      if (profileInfo?.Username.toLowerCase() === pass.toLowerCase()) {
        setPasswordUserIdMatchValidation(true);
      } else {
        setPasswordUserIdMatchValidation(false);
      }
    }
  }, [pass, profileInfo?.Username]);

  useEffect(() => {
    const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");
    if (
      userDatas?.personUPN !== null &&
      userDatas?.personUPN !== "" &&
      userDatas?.personUPN.length !== 0
    ) {
      setShowAssociateUPN(true);
      setAgentUPN(userDatas?.personUPN);
    }
  }, []);

  const getProfileInformation = async (isProfileChange = false) => {
    setLoading(true);
    await dispatch(getProfileInfo()).then((res) => {
      setProfileInfo(res.data.data);
      setLoading(false);
      if (isProfileChange === true) {
        window.location.reload();
      }
    });
  };
  
  const getAgentExcessData = async () => {
    setLoading(true);
    await dispatch(getAgentExcessInfo()).then((res) => {
      // setExcessInfo(res.data.data); //[]
      setAonusername(res?.data?.data?.aon_edge?.username ?? "");
      setAonpassword(res?.data?.data?.aon_edge?.password ?? "");
      setAonid(res?.data?.data?.aon_edge?.id ?? 0);
      setAoncredentialtype(
        res?.data?.data?.aon_edge?.credential_type ?? "AON_EDGE"
      );
      setLoading(false);
    });
  };
  
  const getExcessList = async () => {
            setLoading(true);
        await dispatch(getExcessProducts()).then((res) => { 
			setExcessList(res.data.data);

			setLoading(false);
        });
  };
  
  const submitExcessInfo = async () => {
    if (aonUsername === "" || aonUsername === null) {
      return toast.error("Please enter username");
    }
    if (aonPassword === "" || aonPassword === null) {
      return toast.error("Please enter password");
    }
    setLoading(true);

    const params = {
      aon_edge: {
        username: aonUsername,
        password: aonPassword,
        credential_type: credentialType,
        id: aonId,
      },
    };
    await dispatch(saveAgentExcessInfo(params)).then((res) => {
      if (res.data.data === "Y") {
        toast.success("Excess configuration saved successfully...");
      } else {
        toast.error(res.data.data.MessageString);
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  const onDrop = useCallback((event) => {
    const file = event[0];
    const allowedMimeTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (allowedMimeTypes.includes(file.type)) {
      setFile(file);
    } else {
      setFile(null);
      toast.error("Please upload .png, .jpeg, .jpg file");
    }
  }, []);
  const { getRootProps, getInputProps } =
    useDropzone({ onDrop });
  // var files = acceptedFiles.map((file, i) => (
  //   <li key={file.path}>{file.name} &nbsp;&nbsp;</li>
  // ));

  const handleInfoChange = (event) => {
    setProfileInfo({
        ...profileInfo,
        [event.target.name]: event.target.value,
      });
  };

  // const submitUser = () => {
  //   if (Username === "") {
  //     toast.error("Please Enter New User Name");
  //   } else if (
  //     Username &&
  //     !Username.match(/^(?=.*[a-zA-Z])([a-zA-Z0-9@._]+)$/)
  //   ) {
  //     toast.error("Please Enter Valid User Name");
  //   } else {
  //     let param = { Username };
  //     getuserNameSaved(param);
  //   }
  // };

  // const getuserNameSaved = async (param) => {
  //   setLoading(true);
  //   await dispatch(getuserNameSavedprofile(param)).then((res) => {
  //     if (res.data.data.Status === "N") {
  //       toast.error(res.data.data.MessageString);
  //     } else {
  //       toast.success(res.data.data.MessageString);
  //       getProfileInformation();
  //     }
  //     setLoading(false);
  //   });
  // };

  const submitOtherInfo = () => {
    let param = profileInfo;
    let error = "N";
    let msg = "";
    if (param.Email === "") {
      error = "Y";
      msg = "Please Enter Email";
    }
    if (param.s_ScreenName === "") {
      error = "Y";
      msg = "Please Enter Screen Name";
    }
    if (param.Last_Name === "") {
      error = "Y";
      msg = "Please Enter Last Name";
    }
    // ODS-2403 - expected result: User profile “middle name” is optional and not required. User does not receive error trying to save changes.
    /*if (param.s_MiddleName === "") {
    error = "Y";
    msg = "Please Enter Middle Name";
    }*/
    if (param.First_Name === "") {
      error = "Y";
      msg = "Please Enter First Name";
    }
    if (error === "Y") {
      toast.error(msg);
    } else {
        getOtherInfoSaved(param);
    }
  }

  const getOtherInfoSaved = async (param) => {
    setLoading(true);
    await dispatch(getOtherInfoSavedProfile(param)).then((res) => {
      if (res.data.data.Status === "N") {
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.MessageString);
        getProfileInformation(); 
      }
      setLoading(false);
    });
  };

  const newGeneratedPassword = (e) => {
    const char = createPassword(8);
    setGeneratePassinput(char);
  };

  // const checkForUse = (e) => {
  //   setToggleSwitch(!toggleSwitch);
  //   if (toggleSwitch === true) {
  //     setDisableButton(true);
  //   } else {
  //     setDisableButton(false);
  //   }
  // };
  const setPasswordInput = (e) => {
    if (toggleSwitch === true) {
      setPasswordInfo({
        ...passwordInfo,
        new_Password: generatePassinput,
        confirm_Password: generatePassinput,
      });
      setPass(generatePassinput);
      setShowPassValidator(true);
      setRePass(generatePassinput);
      setPasswordModel(false);
      setToggleSwitch(false);
      setDisableButton(true);
    }
 }

 const handlePassInfoChange = (event) => {
    setPasswordInfo({
        ...passwordInfo,
        [event.target.name]: event.target.value,
      });

    if (event.target.name === "confirm_Password") {
      setRePass(event.target.value);
      setShowPassValidator(true);
    }
    if (event.target.name === "new_Password") {
      setPass(event.target.value);
      setShowPassValidator(true);
    }
  };

  // const handleOldPassword = () => {
  //   setShowOldPassword(!showOldPassword);
  // };

  // const handleNewPassword = () => {
  //   setShowNewPassword(!showNewPassword);
  // };

  // const handleConfirmPassword = () => {
  //   setShowConfirmPassword(!showConfirmPassword);
  // };
  
  const submitPasswordInfo = () => {
    let param = passwordInfo;
    let error = "N";
    let msg = "";
    const isContainFlood = /flood/i.test(pass); 
    if(isContainFlood){
        return toast.error('The password must not include the word flood.');
    }
    const isContainPassword = /password/i.test(pass);
    if(isContainPassword){
        return toast.error('The password must not include the word Password.');
    }

    const isValidPassword =
      //eslint-disable-next-line
      /^(?!.*password)(?!.*flood)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]).{8,}$/.test(
        pass
      );
    if (!isValidPassword) {
      error = "Y";
      msg = "Password must satisfy the mentioned conditions";
    }
    if (rePass === "" || rePass === null) {
      error = "Y";
      msg = "Please enter confirm password";
    }
    if (pass === "" || pass === null) {
      error = "Y";
      msg = "Please enter new password";
    }
    if (passwordInfo.old_Password === "" || passwordInfo.old_Password === null) {
      error = "Y";
      msg = "Please enter old password";
    }
    if (pass !== rePass) {
      error = "Y";
      msg = "The password must match the confirm password.";
    }

    if (profileInfo.Username.toLowerCase() === pass.toLowerCase()) {
      toast.error("Password cannot be the same as username");
      return false;
    }

    if (error === "Y") {
      toast.error(msg);
    } else {
        getPasswordInfoSaved(param);
    }
  }

  const getPasswordInfoSaved = async (param) => {
    setLoading(true);
    await dispatch(getPasswordInfoSavedProfile(param)).then((res) => {
      if (res.data.data.Status === "N") {
        toast.error(res.data.data.MessageString);
      }
      else {
        toast.success(res.data.data.MessageString);
        setPasswordInfo({
            old_Password : null,
            new_Password : null,
            confirm_Password : null
          });
        getProfileInformation(true); 
      }
      setLoading(false);
    });
  };

  const uploadImage = async () => {
      if (!isEmpty(fileslected)) {
        getFileUploaded(fileslected);
      }
      else {
        toast.error("Please Select File");
      }
  }

  const getFileUploaded = async (newFile) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", newFile, newFile.name);
    await dispatch(GetSelectedFileUploaded(formData)).then((res) => {
      if (res.status === 200) {
        if (res.data.data.status === "N") {
          toast.error(res.data.data.MessageString);
        } else {
          getProfileInformation(true);
          setFile([]);
          toast.success(res.data.data.message);
        }
      } else {
        toast.error("Invalid Image");
      }
      setLoading(false);
    });
  };

  const closeIcon = <svg className="sc-eqUAAy bSdOrC checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path fill="#FF0033" d="M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z"></path></svg>;
  const checkIcon = <svg className="sc-eqUAAy bSdOrC checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path fill="#4BCA81" d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path></svg>;

  return (
    <>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Box pl={"40px"} pb={0}>
          <Text fontWeight={600} fontSize={"2xl"}>
            {t("Edit Profile")}
          </Text>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} p={4} m={4} pt={0}>
          <GridItem>
            <Card
              pos={"relative"}
              borderRadius={"2xl"}
              w={"100%"}
              boxShadow={"lg"}
              p={5}
              bg={"white"}
              display={"flex"}
              flexDir={"row"}
              justifyContent={"space-between"}
            >
              <GridItem height={showpasswordRules ? "550px" : "350px"}>
                <Text fontSize={"xl"} fontWeight={600} p={2} pb={0} pl={"20px"}>
                  {t("Change Password")}
                </Text>
                <Box borderTop={"1px solid lightgray"} w={"95%"} m={"auto"} />
                <CardBody p={8} pt={3}>
                  <Box>
                    <form autocomplete="off">
                      <HStack spacing="24px">
                        <FormControl isRequired>
                          <Text> {t("Username")} </Text>
                          <Input
                            className="dashboard-group-input"
                            autoComplete="off"
                            type="text"
                            name="Username"
                            htmlSize={30}
                            width="100%"
                            bg="neutral.100"
                            value={profileInfo.Username}
                            isDisabled={true}
                          />
                        </FormControl>
                        <FormControl isRequired>
                          <Text>{t("Old Password")}</Text>
                          <Input
                            className="dashboard-group-input"
                            autoComplete="new-password"
                            type="password"
                            name="old_Password"
                            htmlSize={30}
                            width="100%"
                            borderColor="neutral.400"
                            onChange={(e) => handlePassInfoChange(e)}
                            value={passwordInfo.old_Password}
                          />
                        </FormControl>
                      </HStack>

                      <HStack spacing="24px" mt={4}>
                        <FormControl isRequired>
                          <Text>{t("New Password")}</Text>
                          <HStack>
                            <InputGroup width={"80%"}>
                              <Input
                                className="dashboard-group-input"
                                name="new_Password"
                                autoComplete="new-password"
                                borderColor="neutral.400"
                                type={showPassword ? "text" : "password"}
                                onChange={(e) => handlePassInfoChange(e)}
                                value={passwordInfo.new_Password}
                                maxLength={25}
                              />
                              <InputRightElement
                                cursor={"pointer"}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <MdOutlineVisibility />
                                ) : (
                                  <MdOutlineVisibilityOff />
                                )}
                              </InputRightElement>
                            </InputGroup>
                            <IconButton
                              size={"md"}
                              _hover={{ bg: "secondary.800", color: "white" }}
                              fontWeight={400}
                              title="Generate Password"
                              onClick={(e) => {
                                setPasswordModel(e);
                              }}
                              maxLength={25}
                            >
                              <FaKey />
                            </IconButton>
                          </HStack>
                        </FormControl>

                        <FormControl isRequired>
                          <Text>{t("Confirm Password")}</Text>
                          <InputGroup width={"100%"}>
                            <Input
                              className="dashboard-group-input"
                              name="confirm_Password"
                              autoComplete="new-password"
                              borderColor="neutral.400"
                              type={showSecondPassword ? "text" : "password"}
                              onChange={(e) => handlePassInfoChange(e)}
                              value={passwordInfo.confirm_Password}
                              maxLength={25}
                            />
                            <InputRightElement
                              cursor={"pointer"}
                              onClick={() =>
                                setShowSecondPassword(!showSecondPassword)
                              }
                            >
                              {showSecondPassword ? (
                                <MdOutlineVisibility />
                              ) : (
                                <MdOutlineVisibilityOff />
                              )}
                            </InputRightElement>
                          </InputGroup>
                        </FormControl>
                      </HStack>
                      <Box m={2} p={2} pl={0} ml={0} mt={5}>
                        <Button
                          _hover={{ bg: "secondary.800", color: "white" }}
                          size={"sm"}
                          // id="common-btn"
                          // variant={"primary"}
                          fontWeight={400}
                          onClick={() => submitPasswordInfo()}
                          maxLength={25}
                          leftIcon={<VscSaveAs />}
                        >
                          {t("Update Password")}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                  <Box>
                    {showPassValidator === true ? (
                      <div style={{ fontSize: "15px" }}>
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "capital",
                            "lowercase",
                            "number",
                            "specialChar",
                            "match",
                          ]}
                          minLength={8}
                          value={pass}
                          valueAgain={rePass}
                          messages={{
                            minLength:
                              "Must be 8-25 characters in length and cannot contain any spaces.",
                            capital:
                              "Must contain at least one uppercase alphabetic character: A-Z",
                            lowercase:
                              "Must contain at least one lowercase alphabetic character: a-z",
                            number: "Must contain at least one number",
                            specialChar:
                              "Must contain at least one non-alphabetic 'special' character: i.e.: -#&$*!~@%+",
                            match: "Password match",
                          }}
                          onChange={(isValid) => {
                            setShowpasswordRules(true);
                            // setPassValidation(isValid);
                          }}
                        />
                        <Flex alignItems={"center"}>
                          {pass !== "" && pass !== undefined
                            ? passwordUserIdMatchValidation || pass === ""
                              ? closeIcon
                              : checkIcon
                            : closeIcon}
                          &nbsp;
                          <label
                            style={{
                              opacity:
                                passwordUserIdMatchValidation || pass === ""
                                  ? 0.5
                                  : null,
                            }}
                          >
                            {" "}
                            Password cannot be the same as username.
                          </label>
                        </Flex>
                        <br></br>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Box>
                </CardBody>
              </GridItem>
            </Card>
          </GridItem>

          <GridItem>
            <Card
              pos={"relative"}
              borderRadius={"2xl"}
              w={"100%"}
              boxShadow={"lg"}
              p={5}
              bg={"white"}
              justifyContent={"space-between"}
            >
              <GridItem height={showpasswordRules ? "550px" : "350px"}>
                <Text
                  fontSize={"xl"}
                  fontWeight={600}
                  p={2}
                  pb={0}
                  pl={"20px"}
                // pt={"12px"}
                >
                  {t("Upload Profile Image")}
                </Text>
                <Box
                  borderTop={"1px solid lightgray"}
                  w={"95%"}
                  p={0}
                  m={"auto"}
                />
                <Box p={4} pt={3}>
                  <Flex gap={6} alignItems={"center"} flexDir={showpasswordRules ? "column" : "row"}>
                    <Box h={"100%"}>
                      <VStack {...getRootProps({ className: "dropzone" })}>
                        <Input
                          type="file"
                          accept=".png, .jpg, .jpeg, image/png, image/jpeg"
                          multiple=""
                          autoComplete="off"
                          className="inp-file"
                          // onChange={uploadFile}
                          {...getInputProps()}
                        />
                        <img
                          src={"/assets/images/uploadImageFrame.svg"}
                          alt="frame"
                          height={"400px"}
                          width={350}
                          style={{ cursor: "pointer", objectFit: "cover" }}
                        />
                      </VStack>
                      {!isEmpty(fileslected) ? (
                        <aside className="reply-document-name">
                          {fileslected.name}
                        </aside>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box p={4} pt={0}>
                      <VStack>
                        <Text>{t("Image Preview")}: </Text>
                      </VStack>
                      <Box p={2} borderTop={"1px solid lightgray"} w={"95%"} m={"auto"}/>
                      <VStack>
                        <img
                          alt=""
                          src={`https://images.weserv.nl/?url=${encodeURIComponent(
                            profileInfo?.ImageURL || ""
                          )}`}
                          width="100"
                          height="100"
                        />
                      </VStack>
                      <VStack
                        display={"flex"}
                        justifyContent={"start"}
                        marginTop={"10px"}
                      >
                        <Button
                          _hover={{ bg: "secondary.800", color: "white" }}
                          // id="common-btn"
                          // variant="primary"
                          size={"sm"}
                          style={{ padding: "10px 30px 10px 30px" }}
                          onClick={() => uploadImage()}
                          leftIcon={<FaCloudUploadAlt />}
                        >
                          {t("Save")}
                        </Button>{" "}
                      </VStack>
                    </Box>
                  </Flex>
                </Box>
              </GridItem>
            </Card>
          </GridItem>

          <GridItem>
            <Card
              pos={"relative"}
              borderRadius={"2xl"}
              w={"100%"}
              boxShadow={"lg"}
              p={5}
              bg={"white"}
              display={"flex"}
              flexDir={"row"}
              justifyContent={"space-between"}
            >
              <GridItem height={"420px"}>
                <Text fontSize={"xl"} fontWeight={600} p={2} pb={0} pl={"20px"}>
                  {t("Other Info")}
                </Text>
                <Box borderTop={"1px solid lightgray"} w={"95%"} m={"auto"} />
                <CardBody p={8} pt={3}>
                  <HStack spacing="24px">
                    <FormControl isRequired>
                      <Text> {t("First Name")} </Text>
                      <Input
                        className="dashboard-group-input"
                        autoComplete="off"
                        type="text"
                        name="First_Name"
                        htmlSize={30}
                        width="100%"
                        onChange={(e) => handleInfoChange(e)}
                        value={profileInfo.First_Name}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Text whiteSpace={"nowrap"}> {t("Middle Name")} </Text>
                      <Input
                        className="dashboard-group-input"
                        autoComplete="off"
                        type="text"
                        name="s_MiddleName"
                        htmlSize={30}
                        width="100%"
                        onChange={(e) => handleInfoChange(e)}
                        value={profileInfo.s_MiddleName}
                      />
                    </FormControl>
                  </HStack>

                  <HStack spacing="24px" mt={4}>
                    <FormControl isRequired>
                      <Text> {t("Last Name")} </Text>
                      <Input
                        className="dashboard-group-input"
                        autoComplete="off"
                        type="text"
                        name="Last_Name"
                        htmlSize={30}
                        width="100%"
                        onChange={(e) => handleInfoChange(e)}
                        value={profileInfo.Last_Name}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Text> {t("Phone")} </Text>
                      <Box width={"47%"}>
                        <MaskedFormControl
                          className="dashboard-group-input"
                          type="text"
                          name="s_PhoneNumber"
                          htmlSize={30}
                          onChange={(e) => handleInfoChange(e)}
                          value={profileInfo.s_PhoneNumber}
                          mask="(111)111-1111"
                          style={{ width: "210%" }}
                        />
                      </Box>
                    </FormControl>
                  </HStack>

                  <HStack spacing="24px" mt={4}>
                    <FormControl isRequired>
                      <Text> {t("Email Address")} </Text>
                      <Input
                        className="dashboard-group-input"
                        autoComplete="off"
                        type="email"
                        name="email"
                        width="100%"
                        htmlSize={30}
                        bg="neutral.100"
                        disabled
                        onChange={(e) => handleInfoChange(e)}
                        value={profileInfo.Email}
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <Text whiteSpace={"nowrap"}> {t("Screen Name")} </Text>
                      <Input
                        className="dashboard-group-input"
                        autoComplete="off"
                        type="text"
                        name="s_ScreenName"
                        disabled
                        htmlSize={30}
                        width="100%"
                        bg="neutral.100"
                        onChange={(e) => handleInfoChange(e)}
                        value={profileInfo.s_ScreenName}
                      />
                    </FormControl>
                  </HStack>

                  <Box p={2} pl={0} m={2} ml={0} mt={5}>
                    <Button
                      _hover={{ bg: "secondary.800", color: "white" }}
                      size={"sm"}
                      // id="common-btn"
                      // variant="primary"
                      style={{ padding: "10px 30px 10px 30px" }}
                      onClick={() => submitOtherInfo()}
                      leftIcon={<GrUpdate />}
                    >
                      {t("Update Info")}
                    </Button>{" "}
                  </Box>
                </CardBody>
              </GridItem>
            </Card>
          </GridItem>

          {excessList?.privateproduct?.AonEdgeExcess && (
            <GridItem>
              <Card
                pos={"relative"}
                borderRadius={"2xl"}
                w={"100%"}
                boxShadow={"lg"}
                p={5}
                bg={"white"}
                display={"flex"}
                flexDir={"row"}
                justifyContent={"space-between"}
              >
                <GridItem height={"420px"}>
                  <Text fontSize={"xl"} fontWeight={600} p={2} pl={"20px"} pb={0}>
                    {t("Aon Edge Excess")}
                  </Text>
                  <Box borderTop={"1px solid lightgray"} w={"95%"} m={"auto"} />

                  <>
                    <CardBody p={6} pt={0}>
                      <Box mt={2}>
                        <Text>
                          {t("Update your login credentials to your Aon Edge products")}.{" "}
                          {t("This should be the same as your existing Aon Edge login credentials")}.
                        </Text>
                      </Box>
                      <form autocomplete="off">
                        <HStack spacing="24px" mt={4}>
                          <FormControl isRequired>
                            <Text>{t("Username")}</Text>
                            <Input
                              className="dashboard-group-input"
                              type="text"
                              name="username"
                              htmlSize={30}
                              minW={"100%"}
                              borderColor="neutral.400"
                              onChange={(e) => setAonusername(e.target.value)}
                              value={aonUsername}
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <Box
                              display={"flex"}
                              flexDir={"row"}
                              justifyContent={"start"}
                              alignItems={"center"}
                              gap={2}
                            >
                              <Text mt={2}>{t("Password")}</Text>
                              <Tooltip
                                placement="left"
                                label="For security reason we are not showing the saved password here, kindly update only if you want to change your old password"
                              >
                                <Box as="span">
                                  <MdHelpOutline
                                    cursor={"pointer"}
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "8px",
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Box>
                            <Box>
                              <Flex
                                alignItems={"center"}
                                justifyContent={"start"}
                              ></Flex>
                              <Input
                                className="dashboard-group-input"
                                autoComplete="new-password"
                                type="password"
                                name="password"
                                htmlSize={30}
                                minW={"100%"}
                                mb={2}
                                borderColor="neutral.400"
                                onChange={(e) => setAonpassword(e.target.value)}
                                value={aonPassword}
                              />
                            </Box>
                          </FormControl>
                        </HStack>
                      </form>
                      <HStack
                        spacing="24px"
                        mt={4}
                        flexDirection={{ base: "column", md: "row" }}
                      ></HStack>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            _hover={{ bg: "secondary.800", color: "white" }}
                            style={{ padding: "10px 30px 10px 30px" }}
                            onClick={() => submitExcessInfo()}
                            size={"sm"}
                            leftIcon={<FaRegSave />}
                          >
                            {t("Save Changes")}
                          </Button>{" "}
                        </Box>
                    </CardBody>
                  </>
                </GridItem>
              </Card>
            </GridItem>
          )}
          {showAssociateUPN === true && (
            <GridItem>
              <Card
                pos={"relative"}
                borderRadius={"2xl"}
                w={"100%"}
                boxShadow={"lg"}
                p={5}
                bg={"white"}
                display={"flex"}
                flexDir={"row"}
                justifyContent={"space-between"}
              >
                <GridItem height={"420px"}>
                  <Text fontSize={"xl"} fontWeight={600} p={2} pl={'20px'} pb={0}>
                    {t("Associate Agency Code With UPN")} - {agentUPN}
                  </Text>
                  <Box borderTop={"1px solid lightgray"} w={"95%"} m={"auto"} />
                  <CardBody p={8} pt={3}>
                    <Box
                      overflowX={"hidden"}
                      overflowY={"scroll"}
                      w={"540px"}
                      h={"320px"}
                    >
                      <>
                        <Box sx={{ width: "100%" }}>
                          <AssociateAgency />
                        </Box>
                      </>
                    </Box>
                  </CardBody>
                </GridItem>
              </Card>
            </GridItem>
          )}
        </Grid>

        <Modal
          className="custom-dashboard-modal"
          size="md"
          isOpen={passwordModel}
          onClose={() => {
            setPasswordModel(false);
          }}
          closeOnOverlayClick={false}
          closeOnEsc={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("Generate Password")}</ModalHeader>
            <ModalBody>
              <Flex>
                <HStack>
                  <Box>
                    <FormControl>
                      <Input
                        className="dashboard-group-input"
                        type="text"
                        name="s_passwordgenerated"
                        value={generatePassinput}
                      />
                    </FormControl>
                  </Box>
                  <Spacer />
                  <Box>
                    <FormControl>
                      <HStack>
                        {generatePassinput.length > 0 && (
                          <IconButton
                            size={"sm"}
                            onClick={() => {
                              navigator.clipboard.writeText(generatePassinput);
                              toast.success("Password copied Successfully", {
                                toastId: "password-copied",
                              });
                            }}
                          >
                            <FaCopy />
                          </IconButton>
                        )}

                        <Button
                          size={"sm"}
                          onClick={(e) => newGeneratedPassword(e)}
                        >
                          {t("Generate")}
                        </Button>
                      </HStack>
                    </FormControl>
                  </Box>
                </HStack>
              </Flex>
              <Box mt={4}>
                <FormControl display="flex" alignItems="center">
                  <Switch
                    mr={2}
                    isChecked={toggleSwitch}
                    onChange={(event) => {
                      setToggleSwitch(event.target.checked);
                      if (event.target.checked) {
                        setDisableButton(false);
                      } else {
                        setDisableButton(true);
                      }
                    }}
                    isDisabled={generatePassinput.length ? false : true}
                  />
                  <FormLabel htmlFor="email-alerts" mb="0">
                    {t("I have copied this password in a safe place")}.
                  </FormLabel>
                </FormControl>
              </Box>

              <ModalFooter>
                <Button
                  size={"sm"}
                  _hover={{ bg: "secondary.800", color: "white" }}
                  bg={"transparent"}
                  color={"black"}
                  mr={3}
                  onClick={() => {
                    setPasswordModel(false);
                    setToggleSwitch(false);
                    setDisableButton(true);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  size={"sm"}
                  colorScheme="primary"
                  isDisabled={disableButton}
                  onClick={setPasswordInput}
                >
                  {t("Use Password")}
                </Button>
              </ModalFooter>
            </ModalBody>
          </ModalContent>
        </Modal>
      </LoadingOverlay>
    </>
  );
};

export default Profile;
