import React from "react";
import { Text } from "@chakra-ui/react";

const Currency = ({ amount, negetiveAmountStyle, ...restProps }) => {
  if (!amount) {
    amount = 0;
  }

  if (!negetiveAmountStyle) {
    negetiveAmountStyle = "default";
  }

  if (!Object.keys(restProps).includes("float")) {
    restProps = { ...restProps, float: "right" };
  }

  let isNegativeAmount = false;
  if (amount < 0) {
    isNegativeAmount = true;
    amount *= -1;
  }

  let formattedAmount = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

  // If its negative amount then we would like to convert amount to $(123.00)
  if (isNegativeAmount ) {
    if ( negetiveAmountStyle === "default"){
      formattedAmount = formattedAmount.replace("$", "$(") + ")";
    } else {
      formattedAmount = formattedAmount.replace("$", "- $");
    }
    
  } 

  return (
    <Text as="span" {...restProps}>
      {formattedAmount}
    </Text>
  );
};

export { Currency };
