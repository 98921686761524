import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import HelpTopics2 from "./HelpTopics2";
import { useTranslation } from "react-i18next";
import Help2 from "./Help2";
import QuickLinks from "./QuickLinks";
import Support2 from "./Support2";
import CELicence from "../help/Agency_Management_CE.pdf";
import al3 from "../help/Agency_Management_AL3.pdf";
import profile from "../help/Agency_Management_Profile.pdf";
import commission from "../help/Agency_Management_Commission.pdf";
import claimSystem from "../help/Claims_System_Create_Claim.pdf";
import claimSearch from "../help/Claims_System_Claim_Search.pdf";
import claimView from "../help/Claims_System_Claim_View.pdf";
import policyCancellations from "../help/Policy_System_Cancellations.pdf";
import policyQuote from "../help/Policy_System_Quote.pdf";
import policyMoneyEndosement from "../help/Policy_System_Money_Endorsements.pdf";
import policySearch from "../help/Policy_System_Policy_Search.pdf";
import policyNonMoneyEndosement from "../help/Policy_System_NonMoney_Endorsements.pdf";
import policyTransfer from "../help/Policy_System_Transfer.pdf";
import balance from "../help/Agency_Management-Negative_Balance.pdf";
import { useNavigate } from "react-router-dom";

export default function HelpSection() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState({});
  const helpSearch = [
    //AL3 Docs
    { label: "Agency Downloads", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "AL3", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "IVANS", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "Policy Downloads", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "Commission Downloads", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "AL3 Management", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "IVANs Management", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "Policy Download Management", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "Commission Download Management", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },
    { label: "AL3 System", card: "Agency_Management", cardId: "4", subSection: "AL3", section: "AgencyManagementSection" },

    //CE Docs
    { label: "Edit CE License", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },
    { label: "Manage CE License", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },
    { label: "Update CE License", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },
    { label: "Continuing Education", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },
    { label: "CE License", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },
    { label: "CE", card: "Agency_Management", cardId: "4", subSection: "CELicense", section: "AgencyManagementSection" },

    //Commisiion
    { label: "Commission Statement", card: "Agency_Management", cardId: "4", subSection: "CommissionStatement", section: "AgencyManagementSection" },
    { label: "Commissions", card: "Agency_Management", cardId: "4", subSection: "CommissionStatement", section: "AgencyManagementSection" },
    { label: "Monthly Commission", card: "Agency_Management", cardId: "4", subSection: "CommissionStatement", section: "AgencyManagementSection" },

    // Commisiion Neagative balnce
    { label: "Balance Due", card: "Agency_Management", cardId: "4", subSection: "CommissionStatementBalance", section: "AgencyManagementSection" },
    { label: "Negative Balance", card: "Agency_Management", cardId: "4", subSection: "CommissionStatementBalance", section: "AgencyManagementSection" },

    //Update Profile
    { label: "Edit contact information", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Update Email", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Update Number", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Update Phone", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Update Telephone", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Edit producer", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Edit user", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Manage producer", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },
    { label: "Manage user", card: "Agency_Management", cardId: "4", subSection: "UpdateProfile", section: "AgencyManagementSection" },

    //Create a Claim
    { label: "Create a Flood Claim", card: "Create_Claim", cardId: "3", subSection: "CreateClaim", section: "ClaimsSystemSection" },
    { label: "Report Flood Claim", card: "Create_Claim", cardId: "3", subSection: "CreateClaim", section: "ClaimsSystemSection" },
    { label: "Create a Claim", card: "Create_Claim", cardId: "3", subSection: "CreateClaim", section: "ClaimsSystemSection" },
    { label: "Report Claim", card: "Create_Claim", cardId: "3", subSection: "CreateClaim", section: "ClaimsSystemSection" },

    //Search a Claim
    { label: "Find a Claim", card: "Create_Claim", cardId: "3", subSection: "SearchClaim", section: "ClaimsSystemSection" },
    { label: "Locate a Claim", card: "Create_Claim", cardId: "3", subSection: "SearchClaim", section: "ClaimsSystemSection" },
    { label: "Search a Claim", card: "Create_Claim", cardId: "3", subSection: "SearchClaim", section: "ClaimsSystemSection" },

    //View a Claim
    { label: "Claim Status", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Description of loss", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Event Name", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Payments", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Read the Ledger", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Representation", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Review Reserves", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "Vendors", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },
    { label: "View a Claim", card: "Create_Claim", cardId: "3", subSection: "ViewClaim", section: "ClaimsSystemSection" },

    //Cancel a Policy
    { label: "Cancel a Client", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Cancel a Customer", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Cancel a Policy", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Cancellation", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Cancel Policy", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Building Sold", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Contents Sold", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Property closing did not occur", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Policy canceled and rewritten", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Condominium converting to RCBAP", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Duplicate policy from source other than NFIP", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Insurance no longer required by lender", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Invalid payment", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Nullification", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },
    { label: "Nullification prior to policy effective date", card: "Cancel_Policy", cardId: "2", subSection: "CancelPolicy", section: "PolicySystemSection" },

    //Create a Quote
    { label: "Create a Client", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Create a Customer", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Create a Policy", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Write a Policy", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "New Policy", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Home", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Mobile Home", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Manufactured Home", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Unit", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Residential Buidling", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Quote Non-residential Building", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Residential", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },
    { label: "Non-Residential", card: "Create_Quote", cardId: "2", subSection: "CreateQuote", section: "PolicySystemSection" },

    //Endorse a Policy
    { label: "Endorse a Client", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Endorse a Customer", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Endorse a Policy", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Coverage", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Building Coverage", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Property Coverage", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Contents Coverage", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Deductible", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Building Deductible", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },
    { label: "Contents Deductible", card: "Endorse_Policy", cardId: "2", subSection: "EndorsePolicy", section: "PolicySystemSection" },

    //Find a Policy
    { label: "Find a Client", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Find a Customer", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Find a Policy", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Flood Policy", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Locate a Client", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Locate a Customer", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Locate a Policy", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Payment", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Search a Client", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Search a Customer", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "Search a Policy", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "View a Client", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "View a Customer", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },
    { label: "View a Policy", card: "Find_Policy", cardId: "2", subSection: "FindPolicy", section: "PolicySystemSection" },

    //Non Money Endorsement
    { label: "Mortgagee", card: "Non_Money", cardId: "2", subSection: "NonMoney", section: "PolicySystemSection" },
    { label: "Lender", card: "Non_Money", cardId: "2", subSection: "NonMoney", section: "PolicySystemSection" },
    { label: "Bank", card: "Non_Money", cardId: "2", subSection: "NonMoney", section: "PolicySystemSection" },
    { label: "Loan", card: "Non_Money", cardId: "2", subSection: "NonMoney", section: "PolicySystemSection" },
    { label: "Update Insured Contact Info", card: "Non_Money", cardId: "2", subSection: "NonMoney", section: "PolicySystemSection" },

    //Transfer Quote
    { label: "Transfer Quote", card: "Transfer_Quote", cardId: "2", subSection: "TransferQuote", section: "PolicySystemSection" },
    { label: "Rollover Quote", card: "Transfer_Quote", cardId: "2", subSection: "TransferQuote", section: "PolicySystemSection" },
    { label: "Transfer", card: "Transfer_Quote", cardId: "2", subSection: "TransferQuote", section: "PolicySystemSection" },
    { label: "Rollover", card: "Transfer_Quote", cardId: "2", subSection: "TransferQuote", section: "PolicySystemSection" },
  ]
  const handleSearchClick = (value) => {
    let node = helpSearch.find(a => a.label == value);
    setSelectedObj(node);

    if (node.card == "Agency_Management" && node.subSection == "AL3") {
      window.open(al3);
    } else if (node.card == "Agency_Management" && node.subSection == "CELicense") {
      window.open(CELicence);
    } else if (node.card == "Agency_Management" && node.subSection == "CommissionStatement") {
      window.open(commission);
    } else if (node.card == "Agency_Management" && node.subSection == "UpdateProfile") {
      window.open(profile);
    } else if (node.card == "Create_Claim" && node.subSection == "CreateClaim") {
      window.open(claimSystem);
    } else if (node.card == "Create_Claim" && node.subSection == "SearchClaim") {
      window.open(claimSearch);
    } else if (node.card == "Create_Claim" && node.subSection == "ViewClaim") {
      window.open(claimView);
    } else if (node.card == "Cancel_Policy" && node.subSection == "CancelPolicy") {
      window.open(policyCancellations);
    } else if (node.card == "Create_Quote" && node.subSection == "CreateQuote") {
      window.open(policyQuote);
    } else if (node.card == "Endorse_Policy" && node.subSection == "EndorsePolicy") {
      window.open(policyMoneyEndosement);
    } else if (node.card == "Find_Policy" && node.subSection == "FindPolicy") {
      window.open(policySearch);
    } else if (node.card == "Non_Money" && node.subSection == "NonMoney") {
      window.open(policyNonMoneyEndosement);
    } else if (node.card == "Transfer_Quote" && node.subSection == "TransferQuote") {
      window.open(policyTransfer);
    } else if (node.card == "Agency_Management" && node.subSection == "CommissionStatementBalance") {
      window.open(balance);
    }

    navigate(`/${node.section}/${node.cardId}`);
  }
  return (
    <Box mb={6}>
      <Box>
        <Text fontSize={"2xl"} fontWeight={600}>
          {t("Agent Training and Resources")}
        </Text>
      </Box>
      <VStack gap={4}>
        <Flex gap={4} flexDir={"row"} alignItems={"center"}>
          <Box flex={1.75}>
            <Help2 />
          </Box>

          <Box flex={1}>
            <QuickLinks />
          </Box>
        </Flex>
        <HStack
          w="100%"
          justifyContent="space-between"
          gap={4}
          alignItems="flex-start"
        >
          <Box bgColor={"white"} width="63%" height="100%" borderRadius={"2xl"}>
            <HelpTopics2 selectedObj={selectedObj} />
          </Box>
          <Box bgColor={"white"} width="37%" height="100%" borderRadius={"2xl"}>
            <Support2 />
          </Box>
        </HStack>
      </VStack>
    </Box>
  )
}