import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form ,Modal , Table, Button} from "react-bootstrap";
import GooglePlacesAutocomplete , { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { connect } from "react-redux";
import  {getZipDetails} from "../../../../services/instaQuote";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./PropertyInformation.scss";
import { isEmpty } from "lodash";

const PropertyInformation = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const dispatch = useDispatch();

    const [search, setSearchProperty] = useState(null);
    const [zipCode, setZipCode] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isValid, setisValid] = useState(true);
    const [googlePlace, setGooglePlace] = useState('');
    const [streetName, setStreetName] = useState('');
    const [zipData, setZipData] = useState([]);
    const [inputFields, setInputFields] = useState([
        { s_CountyName: "", s_CityName: "", s_PostalCode: "" },
    ]);
    const [validated, setValidated] = useState(false); 

    // const [open, setOpen] = useState(false);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ?
            '#ddd' : isValid ?
            '#ddd' : 'red',
        }),
    };

    useEffect(() => {
        if(validated && googlePlace == ''){
          setisValid(false);
        }else{
          setisValid(true);
        }
      }, [googlePlace,validated]);

    useEffect(() => {
        if(search != null){
            setStreetName(search.value.structured_formatting.main_text);
            getGeoCode(search.value.description);
            setGooglePlace(search);
        }
    }, [search]);

    useEffect(() => {
        if(props.priorAddr.s_AddressLine1 == null){
            setGooglePlace('');
            setStreetName('');
            setCounty('');
            setState('');
            setCity('');
            setZipCode('');
        }
    }, [props.priorAddr.s_AddressLine1]);

    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important;}</style>`)
    }, [])

    const handleOnclick = (step) => {
         //validation
         if(step == '5'){
            navigation('/instaQuote/InstaSteps/' + step)
         } else {
             var validateStatus = false;
             if(props.isChecked == "YES"){
                 if(zipCode == "" || googlePlace == "" ){
                     validateStatus = true;
                 }
             }
             setValidated(validateStatus);
             if(validateStatus == false){
                 navigation('/instaQuote/InstaSteps/' + step)
             }
         }
    }

    const getGeoCode = (description) => {
        geocodeByAddress(description)
        .then(addr => {
            addr && addr[0].address_components.forEach(e => {
                if (e.types.includes('street_number')){
                    const values = [];
                    values.push({
                        s_HouseNo : e.short_name,
                    });
                    props.setPriorAddrressData(values);
                }else if(e.types.includes('route')){
                    const values = [];
                    values.push({
                        s_HouseNo : props.priorAddr.s_HouseNo,
                        s_StreetName : e.short_name,
                    });
                    props.setPriorAddrressData(values);
                }else if(e.types.includes('postal_code')){
                    setZipCode(e.short_name);
                    setLoadingStatus(true);
                    dispatch(getZipDetails('13', e.short_name, 'APPLICANT')).then((res) => {
                        setZipData(res.data.zipData);
                        setCounty(res.data.zipData[0].s_CountyCode);
                        setState(res.data.zipData[0].s_StateCode);
                        setCity(res.data.zipData[0].s_CityName);
                        setLoadingStatus(false);

                        const values = [];
                        values.push({
                            s_CountyName: res.data.zipData[0].s_CountyCode,
                            s_CityName: res.data.zipData[0].s_CityName,
                            s_PostalCode: e.short_name,
                            n_CityId_FK : res.data.zipData[0].n_CityId_PK,
                            n_CountryId_FK : res.data.zipData[0].n_CountryId_FK,
                            n_CountyId_FK : res.data.zipData[0].n_CountyId_FK,
                            n_StateId_PK : res.data.zipData[0].n_StateId_PK,
                            s_StateName : res.data.zipData[0].s_StateCode,
                            s_AddressLine1 : search.value.structured_formatting.main_text,
                            s_HouseNo : props.priorAddr.s_HouseNo,
                            s_StreetName : props.priorAddr.s_StreetName,
                        })
                        props.setPriorAddrressData(values); 
                    })
                }
            })
        })
    }

    const handleSwitchChange = (e) => {
        // setOpen(!open);
        setCounty('');
        setState('');
        setCity('');
        setZipCode('');
        setGooglePlace('');
        setStreetName('');
        props.handleSwitchChange(e);
    }

    const changeZip = (e) => {
        setZipCode(e.target.value);
    }

    const setZipDetailsData = (data) => {
        setCounty(data.s_CountyCode);
        setState(data.s_StateCode);
        setCity(data.s_CityName);
        setShowModal(false);
    }

    const zipCodeApi = () => {
        if(zipCode != '' && zipData.length >= 1){
            setLoadingStatus(true);
            dispatch(getZipDetails('13', zipCode, 'APPLICANT')).then((res) => {
                setZipData(res.data.zipData);
                setLoadingStatus(false);
                // setOpen(true)
                setShowModal(true);
            })
        }
    }

    const handleInputChange = (val, status) => {
        if (status.action == "input-change") {
            setStreetName(val);
        } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
            setStreetName(streetName.toUpperCase());
        }
    }

    return(
        <LoadingOverlay>
            <Loader loading={loadingStatus}/>
                {
                <section className="property-info-section">
                    <Row className="justify-content-center">
                            <Col xs="12" sm="12" md="12" lg="8" xl="8" className="property-info-form-section" >
                                <div className="property-info-headings">
                                    <h3>{t('Prior Property Information')}</h3>
                                </div>
                                <Row className="justify-content-center">
                                    <Col xs="12" className="property-info-form-main">
                                    <Form validated={validated} className="confirm-address-form">
                                        <div className="ph-container">
                                            <div className="property-home-icon"></div>
                                        </div>
                                        <Row>
                                            <Col xs="12">
                                                <div className="property-form-row">
                                                    <p>{t('Is this property a new purchase')}?</p>
                                                    <div className="property-form-actions">
                                                        <span>{t('No')}</span>
                                                        <div className="custom-main-swtich">
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                className="property-form-switch"
                                                                name="isPriorAddress"
                                                                parentobject="mapData"
                                                                label=""
                                                                // onChange={() => setOpen(!open)}
                                                                onChange={handleSwitchChange}
                                                                checkedvalue = 'YES'
                                                                uncheckedvalue = 'NO'
                                                                checked={props.isChecked === 'YES' ? true : false}
                                                            />
                                                        </div>
                                                        <span>{t('Yes')}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {props.isChecked === 'YES' && ( 
                                            <div>
                                                <Row>
                                                    <Col xs="8">
                                                        <Form.Group
                                                            className="property-info-field"
                                                            controlId="formBasicEmail"
                                                            style={{
                                                            marginBottom: "20px",
                                                            }}
                                                        >
                                                            <Form.Label className="property-info-label">
                                                                {t('Temporary Mailing Address')}
                                                            </Form.Label>
                                                            <GooglePlacesAutocomplete
                                                                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                                                selectProps={{
                                                                    styles: customStyles,
                                                                    // inputValue: streetName,
                                                                    value: googlePlace,
                                                                    onInputChange: handleInputChange,
                                                                    onChange: setSearchProperty,
                                                                    name: "googlePlacesStreetName",
                                                                    id: "googlePlacesStreetName",
                                                                    //placeholder={t('Type temporary mailing address')}
                                                                }}
                                                            />
                                                            { !isValid ?
                                                                <label style={{color : "#dc3545"}} >{t("Temporary Mailing Address")}</label> : null
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('Zip Code')}</Form.Label><Form.Control
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="zipCode"
                                                                value={zipCode}
                                                                onChange={(e) => changeZip(e)}
                                                                onBlur={() => zipCodeApi()}
                                                                required
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {t("Enter Zip Code Error")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('City')}</Form.Label><Form.Control
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="city"
                                                                value={city}
                                                                disabled={true}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('County')}</Form.Label><Form.Control
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="County"
                                                                value={county}
                                                                disabled={true}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="add-mortage-form-group"
                                                        >
                                                            <Form.Label className="myform-label">{t('State')}</Form.Label>
                                                            <Form.Control
                                                                className="myform-input"
                                                                type="text"
                                                                placeholder=""
                                                                name="State"
                                                                value={state}
                                                                disabled={true}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                        <br />
                                        <br />
                                        </Form>
                                    </Col>
                                    <div className="flood-bts-div">
                                        <Button
                                            id="primary-grey-btn-flood"
                                            variant="outline-dark"
                                            onClick={() => handleOnclick('5')}
                                        >
                                            {t('Previous')}
                                        </Button>
                                        <Button
                                            id="primary-colored-btn"
                                            variant="primary"
                                            onClick={() => handleOnclick('7')}
                                        >
                                            {t('Next')}
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                    </Row>
                    <Modal size="md" show={showModal} className="add-mortage-modal">
                        <Modal.Header id="add-mortage-modal-header">
                            <Modal.Title>Select City</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="add-mortage-modal-body">
                            <Row>
                                <Table className="p-0 m-0" size="sm" responsive striped>
                                    <thead>
                                        <tr>
                                            <th>City</th>
                                            <th>County</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {zipData.map((data, i)=>{
                                                return (
                                                <tr key={i}>
                                                    <td><a href='#' onClick={()=> setZipDetailsData(data)}>{data.s_CityName}</a></td>
                                                    <td>{data.s_ZipCounty}</td>
                                                </tr>
                                                )
                                            })}
                                    </tbody>
                                </Table>
                            </Row>
                        </Modal.Body>
                    </Modal>
                </section>
                }
       </LoadingOverlay>
    )
}

function mapStateToProps(state) {
    return {
        priorAddr : state.common.emptyInstantQuoteData.priorAddr,
        isChecked : state.common.emptyInstantQuoteData.mapData.isPriorAddress ? state.common.emptyInstantQuoteData.mapData.isPriorAddress : "NO"
    };
}

export default connect(mapStateToProps)(PropertyInformation)