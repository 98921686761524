export const al3Data = [
    {
        flood: {
            ivans_account: "",
            ivans_user_id: "",
            is_personal: "Y",
            is_commercial: "Y",
            renewalbilloptionB: "Y",
            renewalbilloptionA: "Y",
            download_suspended: "N",
            contract: "",
            destination_addr: "",
            al3_system: "",
            policy_format: "",
            from_date: "",
            to_date: "",
            inital_download: "N",
            name: "",
            email: "",
            phone: "",
            is_non_renewal: "N",
            non_renewal_code: "",
            non_renewal_days: "",
            header_sequence: 0,
            record_sequence: 0,
            override_naic: ""
        },
        commission: {
            ivans_account: "",
            ivans_user_id: "",
            is_personal: "Y",
            is_commercial: "Y",
            renewalbilloptionB: "Y",
            renewalbilloptionA: "Y",
            download_suspended: "Y",
            contract: "",
            destination_addr: "",
            al3_system: "",
            policy_format: "",
            from_date: "",
            to_date: "",
            inital_download: "N",
            name: "",
            email: "",
            phone: "",
            is_non_renewal: "N",
            non_renewal_code: "",
            non_renewal_days: "",
            header_sequence: 0,
            record_sequence: 0,
            override_naic: ""
        }
    }]