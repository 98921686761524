import React, { useEffect, useState } from "react";
import MessageAlerts from "./MessegesAleart";

const addPrefix = (prefix, messages) => {
  return messages.map((message) => `${prefix}: ${message}`);
};

const collectExcessWarnings = (data) => {
  const warnings = [
    ...(data?.commercialOwned?.excess || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
    ...(data?.commercialTenanted?.excess || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
    ...(data?.residential?.excess || []).flatMap((item) =>
      addPrefix("Warning ", item.warnings)
    ),
  ];

  return warnings;
};

const countOccurrences = (messages) => {
  return messages.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});
};

const formatMessages = (messages, counts) => {
  return [...new Set(messages)].map((message) =>
    counts[message] > 1 ? `${message} (x${counts[message]})` : message
  );
};

const collectExcessErrors = (data) => {
  const errors = [
    ...(data?.commercialOwned?.excess || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
    ...(data?.commercialTenanted?.excess || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
    ...(data?.residential?.excess || []).flatMap((item) =>
      addPrefix("Error ", item.errors)
    ),
  ];

  return errors;
};

const ExcessErrorHandler = ({ premiumResponseData }) => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    let allErrors = [];
    let allWarnings = [];

    if (premiumResponseData?.data) {
      allErrors = collectExcessErrors(premiumResponseData.data);
      allWarnings = collectExcessWarnings(premiumResponseData.data);
    } else {
      allErrors = collectExcessErrors(premiumResponseData) || [];
      allWarnings = collectExcessWarnings(premiumResponseData) || [];
    }

    const errorCounts = countOccurrences(allErrors);
    const formattedErrors = formatMessages(allErrors, errorCounts);

    const warningCounts = countOccurrences(allWarnings);
    const formattedWarnings = formatMessages(allWarnings, warningCounts);

    setErrorMessages(formattedErrors);
    setWarningMessages(formattedWarnings);
  }, [premiumResponseData]);

  const handleDismissError = () => {
    setErrorMessages([]);
  };

  const handleDismissWarning = () => {
    setWarningMessages([]);
  };
  return (
    <div>
      <MessageAlerts
        errorMessages={errorMessages}
        warningMessages={warningMessages}
        onDismissError={handleDismissError}
        onDismissWarning={handleDismissWarning}
      />
    </div>
  );
};

export default ExcessErrorHandler;
