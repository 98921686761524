import React from "react";
import { Text, Button, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const closingHoursOptions = [
  { name: "24 Hours", icon: "/starImages/miniIcons/24Hours.svg" },
  {
    name: "All other Closing Hours",
    icon: "/starImages/miniIcons/allOtherClosingHours.svg",
  },
];

const ClosingHourCard = ({ name, icon: Icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    py={6}
    leftIcon={<Image src={Icon || ""} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const LatestClosingHoursSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails } = useQuote();
  const { t } = useTranslation();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    updateBuildingDetails(index, "closingHour", name);
  };

  return (
    <>
      <Flex flexDir={"column"} w={"100%"}>
        <Flex gap={2} my={0}>
          <Text fontWeight="500">{t("Latest Closing Hours")}:</Text>{" "}
          {buildingDetails[index]?.closingHour && (
            <Text fontWeight="500" color={"neutral.300"}>
              ( {buildingDetails[index]?.closingHour} )
            </Text>
          )}
        </Flex>
        <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
          {closingHoursOptions.map(({ name, icon }) => (
            <GridItem key={name}>
              <ClosingHourCard
                name={name}
                icon={icon}
                isSelected={buildingDetails[index]?.closingHour === name}
                onClick={() => handleSelection(name)}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </>
  );
};
