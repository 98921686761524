import { useState } from "react";
import { postHiscoxCreateQuote } from "./postHiscoxCreateQuote";
import { CreateQuoteContext, useMyContext } from "../CreateQuote";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useHiscoxCreateQuote = () => {
  const [loading, setLoading] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [error, setError] = useState(null);
  const { setHiscoxCreateQuoteData,status,quoteNumber } = useMyContext(CreateQuoteContext);
  const createQuote = async (requestBody) => {
    setLoading(true);
    try {
      const responseData = await toast.promise(
        postHiscoxCreateQuote({ requestBody,status,quoteNo:quoteNumber}),
        {
          pending: "Creating Quote...",
          // success: "Handshake Successful",
          error: "Failed to Create Quote, Please try again",
        },
        {
          autoClose: 2000,
          toastId: "hiscox-create-quote",
          position: "top-right",
          pauseOnHover: false,
        }
      );
      setQuoteData(responseData);
      setHiscoxCreateQuoteData(responseData);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { loading, quoteData, error, createQuote };
};
