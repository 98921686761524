import React from "react";
import {
  Box,
  Text,
  FormLabel,
  Flex,
  HStack,
  Card,
  Switch,
  Divider,
  Grid,
  GridItem,
  FormControl,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DateInput from "../../quote/create-star-quote/utils/DateInput";
import { formateDate } from "../../common/FormatDate";

const RunDate = ({ disabled, onChange, data, setData }) => {
  const { t } = useTranslation();

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    const switchEvent = {
      target: { value: checked ? "Y" : "N", name: name }
    }
    onChange(switchEvent);
  };

  return (
    <Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
      <Box p={6} mb={4}>
        <Text fontWeight={600} fontSize={"xl"}>
          {t("Run Date")}
        </Text>
        <Divider orientation="horizontal" />
        <Grid templateColumns={"repeat(2, 1fr)"} gap={4}>
          <GridItem>
            <FormControl>
              <FormLabel>{t("Start Date")}</FormLabel>
              <DateInput
                className="dashboard-group-input"
                type="date"
                id="from_date"
                name="from_date"
                placeholder={"MM/DD/YYYY"}
                value={
                  data && data.from_date && data.from_date != "0"
                    ? formateDate(data.from_date)
                    : ""
                }
                onChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    from_date: formateDate(value, "YYYY-MM-DD"),
                  }));
                }}
                isDisabled={disabled}
                bgColor={disabled ? "gray.200" : ""}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel>{t("To Date")}</FormLabel>
              <DateInput
                className="dashboard-group-input"
                type="date"
                id="to_date"
                name="to_date"
                placeholder={"MM/DD/YYYY"}
                value={
                  data && data.to_date && data.to_date != "0"
                    ? formateDate(data.to_date)
                    : ""
                }
                onChange={(value) => {
                  setData((prevState) => ({
                    ...prevState,
                    to_date: formateDate(value, "YYYY-MM-DD"),
                  }));
                }}
                isDisabled={disabled}
                styleProps={{
                  bgColor: disabled ? "gray.200" : "",
                }}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Flex mt={4} justifyContent="space-between">
          <HStack>
            <FormLabel>{t("Initial Download")}</FormLabel>
          </HStack>
          <HStack>
            <Switch
              name="inital_download"
              size="lg"
              isChecked={data && data.inital_download === "Y"}
              onChange={handleSwitchChange}
              isDisabled={disabled}
            />
          </HStack>
        </Flex>
        <style>
        {`
          .react-datepicker__day--keyboard-selected,
          .react-datepicker__month-text--keyboard-selected,
          .react-datepicker__quarter-text--keyboard-selected,
          .react-datepicker__year-text--keyboard-selected {
            background-color: transparent !important;
          }
        `}
      </style>
      </Box>
    </Card>
  );
};

export default RunDate;
