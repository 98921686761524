import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  Flex,
} from "@chakra-ui/react";
import AL3Type from "./components/AL3Type";
import { al3Data } from "./al3_data";
import { useDispatch } from "react-redux";
import {
  getResourceData,
  saveResourceData,
} from "../../services/resourceService";
import AL3Submission from "./components/AL3Submission";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { toast } from "react-toastify";

function AL3() {

  const [al3Info, setAl3Info] = useState(al3Data);
  const [personId, setPersonId] = useState('');
  const [id, setId] = useState('');
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isFloodDownloadSuspended, setFloodDownloadSuspended] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const FLOOD_DOWNLOAD = 'FLOOD';
  const COMMISSION_DOWNLOAD = 'COMMISSION';
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");

  useEffect(() => {
    fetchData()
  }, []);

  const handleData = (floodData, commissionData) => {

    updateAl3Info(FLOOD_DOWNLOAD, floodData);
    updateAl3Info(COMMISSION_DOWNLOAD, commissionData);
    const newCommission = { ...floodData, download_suspended: commissionData };

    var apiMetadata = al3Info;
    if (floodData) {
      apiMetadata[0].flood = floodData;
    }
    if (commissionData && commissionData==='N') {
      apiMetadata[0].commission = newCommission;
    }


    setIsDisabled(true);
    const saveDataWithPromise = new Promise((resolve, reject) => {
      const param = {
        metadata: apiMetadata,
        person_id: personId,
        id: id,
      };
      dispatch(saveResourceData(param))
        .then((res) => {
          setIsDisabled(false);
          fetchData();
          resolve(res);
        })
        .catch((error) => {
          setIsDisabled(false);
          reject(error);
        });
    });

    const title = "Al3 Download";
    toast.promise(saveDataWithPromise, {
      toastId: title,
      success: t("Data saved successfully"),
      error: t("Something went wrong"),
      pending: t("Please wait"),
    });
  };

  const handleDownloadSuspended = (data, downloadType) => {
    if (downloadType === FLOOD_DOWNLOAD) {
      setFloodDownloadSuspended(data);
    }
  };

  const updateAl3Info = (downloadType, data) => {
    setAl3Info(prevState => {
      const updatedElement = {
        ...prevState[0],
        [downloadType === FLOOD_DOWNLOAD ? 'flood' : 'commission']: data
      };
      return [updatedElement];
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await dispatch(getResourceData());
      if (res.status === 200) {
        const { metadata, person_id, id, niac_number } = res.data.data;
        const al3metadata = addOrUpdateOriginatingAddr(metadata ?? al3Info, `IBM4060${niac_number}`);
        setAl3Info(al3metadata);
        setPersonId(person_id);
        setId(id);
        setFloodDownloadSuspended(al3metadata && al3metadata[0].flood.download_suspended === 'Y' ? true : false)
        setSubmissionDate(res.data.data.submission_date)
        setSubmissionStatus(res.data.data.submission_status)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }
  const addOrUpdateOriginatingAddr = (jsonArray, naicNumber) => {
    return jsonArray.map(item => {
      for (let key in item) {
        item[key]['originating_addr'] = naicNumber;
      }
      return item;
    });
  };

  return (
    <LoadingOverlay>
      <Loader loading={loading} />
      {isFloodDownloadSuspended && (
        <Center mb={4}>
          <Alert status="warning" variant="subtle" alignItems="center">
            <AlertIcon />
            <AlertTitle fontSize="lg">{t("POLICY")}</AlertTitle>
            <AlertDescription ml={2}>
              {t(
                "To opt-in for AL3/IVANs downloads, toggle the 'Turn off AL3 Downloads' so it is not highlighted. This will enable downloads."
              )}
            </AlertDescription>
          </Alert>
        </Center>
      )}
      <Flex alignItems="center" justifyContent={"space-between"}>
        <Text fontSize={"2xl"} mb={0} fontWeight={600}>
          {t("Policy")}
        </Text>
        <AL3Submission
          submissionDate={submissionDate}
          submissionStatus={submissionStatus}
        />
      </Flex>
      {!loading && (
        <AL3Type
          disabled={isDisabled}
          floodData={al3Info[0].flood}
          commData={al3Info[0].commission.download_suspended}
          onSubmit={handleData}
          onDownloadSuspended={handleDownloadSuspended}
          downloadType={FLOOD_DOWNLOAD}
        />
      )}
    </LoadingOverlay>
  );
}
export default AL3;
