import {
  Box,
  Select,
  Flex,
  Icon,
  Input,
  Switch,
  Text,
  Tooltip,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CiCircleAlert } from "react-icons/ci";
import { useStarDataContext } from "../../StarDataContext";
import CurrencyInput from "react-currency-input-field";
import { useState } from "react";
import { MdOutlineAddBox } from "react-icons/md";
import DateInput from "../../utils/DateInput";
import { LossHistoryTable } from "./LossHistoryTable";
import moment from "moment";
import { FcInfo } from "react-icons/fc";
const todayMaxDate = moment().toDate() || "";

export default function LossHistory() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");

  const {
    underWriting,
    setUnderWriting,
    validationError,
    setValidationError,
    baseDD,
    lossHistoryData,
    setLossHistoryData,
  } = useStarDataContext();

  const [lossHistoryFields, setLossHistoryFields] = useState({
    causeOfLoss: "",
    dateOfLoss: "",
    totalPayout: "",
    lossDescription: "",
  });

  const [lossTypeDD] = useState(baseDD?.STARCAUSEOFLOSS ?? []);

  const handleSwitchChange = (event) => {
    setUnderWriting((prev) => ({
      ...prev,
      lossHistoryQuestion: event.target.checked,
    }));
    setLossHistoryData([]);
    setValidationError((prev) => ({ ...prev, lossHistory: false }));
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setLossHistoryFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddLossHistory = () => {
    setLossHistoryData((prev) => [...prev, { ...lossHistoryFields }]);
    setLossHistoryFields({
      causeOfLoss: "",
      dateOfLoss: "",
      totalPayout: "",
      lossDescription: "",
    });
    setValidationError((prev) => ({ ...prev, lossHistory: false }));
  };

  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid",
    padding: "0.62rem",
    borderRadius: "12px",
    borderColor: "#9AA5B5",
    width: "100%",
  };

  return (
    <>
      <Box border="1px solid #E6E9ED" borderRadius="12px" mb={6} pb={4}>
        <Box bg="primary.50" borderRadius="xl" p={"0.1rem"}>
          <Text
            mt={3}
            borderRadius={"xl"}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-semibold)"
            color="black"
            fontSize="1.5rem"
          >
            {t("loss_history")}
          </Text>
        </Box>
        <Flex p={4} pb={0} alignItems="center" justifyContent="space-between">
          <Text fontSize="18px" pl={2} mb={0}>
            Has the applicant experienced any significant losses in the past (3)
            years?
            <Tooltip
              hasArrow
              color="white"
              textAlign="center"
              p={5}
              label={t(
                "exclude_losses_with_amount_paid_less_than_catastrophic_losses"
              )}
              bg="black"
            >
              <Box as="span" pl={2}>
                <Icon as={FcInfo} width={22} height={22} alt="" />
              </Box>
            </Tooltip>
          </Text>
          <Switch
            isChecked={underWriting.lossHistoryQuestion}
            onChange={(event) => handleSwitchChange(event)}
            size="lg"
          />
        </Flex>
        <Flex
          display={underWriting.lossHistoryQuestion ? "flex" : "none"}
          mt={4}
        >
          <Flex flexDir={"column"} py={1} w={"100%"} px={4}>
            <Grid w={"100%"} templateColumns="repeat(3, 1fr)" gap={3}>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>Date of Loss:</FormLabel>
                  <DateInput
                    maxDate={todayMaxDate}
                    value={lossHistoryFields.dateOfLoss}
                    onChange={(e) => {
                      setLossHistoryFields((prev) => ({
                        ...prev,
                        dateOfLoss: e,
                      }));
                    }}
                    styleProps={{
                      border: "2px solid",
                      borderColor: "#9AA5B5",
                      borderRadius: "12px",
                      size: "lg",
                      readOnly: false,
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>Loss Type:</FormLabel>
                  <Select
                    size="lg"
                    name="causeOfLoss"
                    value={lossHistoryFields.causeOfLoss}
                    onChange={handleFieldChange}
                    border="2px solid #9AA5B5"
                    borderRadius={"12px"}
                  >
                    <option value=""></option>
                    {lossTypeDD.map((dd) => (
                      <option key={dd.s_AppCodeName} value={dd.s_AppCodeName}>
                        {dd.s_AppCodeNameForDisplay}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired>
                  <FormLabel fontWeight={500}>Amount:</FormLabel>
                  <CurrencyInput
                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                    placeholder="$0.00"
                    size="lg"
                    style={CurrencyStyles}
                    prefix="$"
                    name="totalPayout"
                    value={lossHistoryFields.totalPayout}
                    onChange={(e) => {
                      setLossHistoryFields((prev) => ({
                        ...prev,
                        totalPayout: Number(
                          e.target.value.replace(/[^\d.]/g, "")
                        ),
                      }));
                    }}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <FormControl pt={4}>
              <FormLabel fontWeight={500}>Loss Description:</FormLabel>
              <Input
                size="lg"
                // className="inputBorder"
                type="text"
                name="lossDescription"
                placeholder="Description of Loss Occured (Optional)"
                value={lossHistoryFields.lossDescription}
                onChange={handleFieldChange}
                border="2px solid #9AA5B5"
                borderRadius={"12px"}
              />
            </FormControl>
            <Flex justifyContent={"flex-end"}>
              <Button
                fontWeight={500}
                borderRadius={"lg"}
                mt={4}
                isDisabled={
                  !lossHistoryFields.dateOfLoss ||
                  !lossHistoryFields.causeOfLoss ||
                  !lossHistoryFields.totalPayout
                }
                onClick={handleAddLossHistory}
              >
                <Flex gap={2} alignItems={"center"} justifyContent={"center"}>
                  <MdOutlineAddBox />
                  <Text pt={4}>{t("Add")}</Text>
                </Flex>
              </Button>
            </Flex>
            {validationError?.lossHistory && (
              <Flex justifyContent={"flex-end"}>
                <Text fontSize={"sm"} fontWeight={400} color={"red.400"} mt={2}>
                  Please either add or remove this loss history
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex>
          {lossHistoryData?.length > 0 && (
            <LossHistoryTable
              lossHistoryData={lossHistoryData}
              setLossHistoryData={setLossHistoryData}
            />
          )}
        </Flex>
      </Box>
    </>
  );
}
