import Http from "../../../../Http";

export function postHiscoxCreateQuote({ requestBody ,status,quoteNo }) {
  return new Promise((resolve, reject) => {
    const url = `/api/v2/quote/hiscox/create`;
    const updateUrl = `/api/v2/quote/hiscox/${status}/${quoteNo}`;
    Http.post((quoteNo!==0 && status=="update")?updateUrl:url, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}
