import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { getClaimListData } from "../../../services/dashboardService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FiZoomIn } from "react-icons/fi";
import ReactTooltip from "react-tooltip";
import "./policy-details.scss"
import { toast } from "react-toastify";
import { currencyFormat } from "../../../services/commonService";

const ClaimTab = (props) => {
    const [loading, setLoading] = useState(false);
    const [claimData, setClaimData] = useState([]);
    const [displayClaimData, setDispalyClaimData] = useState([]);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        page: 1,
        sizePerPage: 10,
    });
    const [total, setTotal] = useState(0);
    const [sortField, setSortField] = useState("Date_Of_Loss");
    const [sortOrder, setSortOrder] = useState('desc');
    const [tenancyNaicPrefix, setTenancyNaicPrefix] = useState("");


    const { t } = useTranslation();

    var policyNum = props.policyNumber;

    useEffect(() => {
        if (!isEmpty(policyNum)) {
            claimDataByPolicyNo()
        }
    }, [filter.page, filter.sizePerPage, sortOrder, sortField, policyNum])

    useEffect(()=>{
        if(claimData.length > 0) {
            let claimArr=claimData.map((e)=>{
                e.Claim_Number=tenancyNaicPrefix+e.Claim_Number;
                return e;
            })
            setDispalyClaimData([...claimArr]);
        }
    },[sortField, sortOrder, claimData]);

    const handleSort = (col, order) => {
        setSortField(col);
        setSortOrder(order);
    };

    
    const claimDataByPolicyNo = async (type = 'all', transType = "Open", page = filter.page, sizePerPage = filter.sizePerPage, policyNo = policyNum) => {
        setLoading(true);
        try {
            const res = await dispatch(getClaimListData(type, transType, sizePerPage, page, sortOrder, sortField, policyNo));
            setTenancyNaicPrefix(res.data?.tenancy.toUpperCase().charAt(0)+res.data?.naic);
            setClaimData(res.data.data);
            setTotal(res.data.count);
        } catch (error) {
            toast.error('Something Went Wrong');
        } finally {
            setLoading(false);
        }
    };
    const handlePageChange = (page, sizePerPage) => {
        setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    };

    const sizePerPageListChange = (page, sizePerPage) => {
        setFilter({ ...filter, sizePerPage: sizePerPage });
    };

    const onTableChange = (type, { page, sizePerPage }) => {
        setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    };

    const renderShowsTotal = (from, to, size) => (
        <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
    );

    const urlBuilder = async (row) => {
        let originalClaimNo="";
        if(row.Claim_Number.length >= 8) {
            originalClaimNo=row.Claim_Number.slice(-8);
        }else{
         originalClaimNo=row.Claim_Number
        }
        navigation(`/core/claim/${originalClaimNo }`);
    }

    const options = {
        page: filter.page,
        sizePerPage: filter.sizePerPage,
        sortIndicator: false,
        pageStartIndex: 1,
        totalSize: displayClaimData.length,
        totalSize: total,
        showTotal: true,
        paginationTotalRenderer: renderShowsTotal,
        onPageChange: handlePageChange,
        onSizePerPageChange: sizePerPageListChange,
    };

    const actionFormatter = (cell, row) => {
        if (row && row.Claim_Number) {
            return (
                <>
                    <FiZoomIn size={26} id="iconclaimdetails" onClick={() => urlBuilder(row)} data-tip data-for="details">
                        {cell}
                    </FiZoomIn>&nbsp;
                    <ReactTooltip id="details" place="top" effect="solid">
                        View Claim Details
                    </ReactTooltip>
                </>
            )
        }
    }

    const claimColumns = [
        {
            dataField: "Date_Of_Loss",
            text: t("Loss Date"),
            sort: true,
            onSort: handleSort
        },
        {
            dataField: "Claim_Number",
            text: t("Claim No"),
            sort: true,
            onSort: handleSort
        },
        {
            dataField: "Policy_Number",
            text: t("Policy No"),
            sort: true,
            onSort: handleSort
        },
        {
            dataField: "Insured_Name",
            text: t("Policyholder Name"),
            sort: true,
            onSort: handleSort
        },
        {
            dataField: "s_AddressLine1",
            text: t("Property Address"),
            formatter: (value, row) => {
                return value + ' ' + row.s_CountyName + ' ' + row.s_CityName + ' ' + row.s_StateCode + ' ' + row.s_PostalCode
            },
        },
        {
            dataField: "Building_Reserve_Amount",
            text: t("Building Reserve"),  
            formatter: (value) => currencyFormat(value),
        },
        {
            dataField: "Building_Coverage_Amount",
            text: t("Building Payment"),
            formatter: (value) => currencyFormat(value),
        },
        {
            dataField: "Content_Reserve_Amount",
            text: t("Content Reserve"),
            formatter: (value) => currencyFormat(value),
        },
        {
            dataField: "Content_Coverage_Amount",
            text: t("Content Payment"),
            formatter: (value) => currencyFormat(value),
        },
        {
            dataField: "actions",
            text: t("Action"),
            formatter: actionFormatter
        },
    ];

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <Row>
                <div id="dashboard-table-section">
                    <div className="table-main-title-div">
                        <span className="table-main-title-name">
                            {t('Claim')}
                        </span>
                    </div>
                </div>
                <BootstrapTable
                    wrapperClasses="coverage-detail-table-main cdtmm"
                    bootstrap4
                    keyField="f_claim"
                    data={displayClaimData}
                    columns={claimColumns}
                    hover
                    onTableChange={onTableChange}
                    pagination={paginationFactory(options)}
                />
            </Row>
        </LoadingOverlay>
    )
}

export default ClaimTab;