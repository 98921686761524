import Http from "../../../../../Http";

export async function LogsApi(page, pageLength, poTransPK) {
  const url = `/api/flood/getPivotApiLog?=&page=${page}&pageLength=${pageLength}&poTransPK=${poTransPK}`;
  try {
    const { data } = await Http.get(url);
    return data;
  } catch (error) {
    console.error("Error binding star quote:", error);
    throw error;
  }
}
