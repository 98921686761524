import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import "./PriorInformation.scss";

const PriorInformation = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    // const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [validated, setValidated] = useState(false); 
    const handleOnclick = (step) => {

        if(step == '6'){
            navigation('/instaQuote/InstaSteps/' + step)
        } else {
            var validateStatus = false;
            if(props.uwData == "YES"){
                if(props.s_PriorPolicyNo == "" || props.s_PriorCarrierName == "" || props.d_PriorExpDate == "" ){
                    validateStatus = true;
                }
            }
            setValidated(validateStatus);
            if(validateStatus == false){
                if(props.emptyQuote.propertyInfo.n_PriorClaimCount == 1){
                    navigation('/instaQuote/InstaSteps/8')
                } else {
                    navigation('/instaQuote/InstaSteps/' + step)
                }
            }
        }

        //navigation('/instaQuote/InstaSteps/' + step);
    }

    const handleSwitchChange = (e) => {
        // setOpen(!open);
        setToggle(!toggle);
        props.handleSwitchChange(e);
    }

    return(
        <section className="property-info-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="property-info-form-section">
                    <div className="property-info-headings">
                        <h3>{t('Prior Property Information')}</h3>
                    </div>
                    <Row className="justify-content-center">
                        <Col xs="12" className="property-info-form-main">
                        <Form validated={validated} className="confirm-address-form">
                            <div className="ph-container">
                                <div className="property-home-icon"></div>
                            </div>
                            <Row>
                                <Col xs="12">
                                    <div className="property-form-row">
                                        <p>
                                            {t('Has the property been insured for at least one full year')}?
                                        </p>
                                        <div className="property-form-actions">
                                            <span>{t('No')}</span>
                                            <div className="custom-main-swtich">
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    className="property-form-switch"
                                                    name="s_PoAdditionalStatsAnswer"
                                                    parentobject="uwData"
                                                    subparent="priorPolicyQuestionInfo" 
                                                    label=""
                                                    onChange={handleSwitchChange}
                                                    questionId = "underWritingQ_12984362"
                                                    checkedvalue = 'YES'
                                                    uncheckedvalue = 'NO'
                                                    checked={props.uwData === "YES" ? true : false}
                                                />
                                            </div>
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {props.uwData === 'YES' && (
                                <Row>
                                    <Col xs="12">
                                        <Form.Group className="property-info-field" controlId="formBasicEmail">
                                            <Form.Label className="property-info-label">
                                                {t('Policy Number')}
                                            </Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder={t("Policy Number")}
                                                name="s_PriorPolicyNo"
                                                title="policyInfo"
                                                value={props.s_PriorPolicyNo}
                                                onChange = {props.handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Please Add Policy Number Error')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="property-info-field" controlId="formBasicEmail">
                                            <Form.Label className="property-info-label">
                                                {t('Prior Carrier Name')}
                                            </Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder={t("Prior Carrier Name")}
                                                name="s_PriorCarrierName"
                                                title="policyInfo"
                                                value={props.s_PriorCarrierName}
                                                onChange = {props.handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {t('Please Add Prior Carrier Name Error')}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="confirm-address-field" controlId="formBasicEmail">
                                            <Form.Label className="confirm-address-label">
                                                {t("Policy Expiration Date")}
                                            </Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="date"
                                                placeholder={t("Enter Date")}
                                                name="d_PriorExpDate"
                                                title="policyInfo"
                                                value={props.d_PriorExpDate}
                                                onChange = {props.handleChange}
                                                required
                                            />
                                             <Form.Control.Feedback type="invalid">
                                                {t("Please Add Policy Expiration Date Error")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                        </Col>
                        <div className="flood-bts-div">
                            <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                onClick={() => handleOnclick('6')}
                            >
                                {t('Previous')}
                            </Button>
                            <Button
                                id="primary-colored-btn"
                                variant="primary"
                                onClick={() => handleOnclick('9')}
                            >
                                {t('Next')}
                            </Button>
                        </div>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}

function mapStateToProps(state) {
    return {
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
        s_PriorPolicyNo : state.common.emptyInstantQuoteData.policyInfo.s_PriorPolicyNo,
        s_PriorCarrierName : state.common.emptyInstantQuoteData.policyInfo.s_PriorCarrierName,
        d_PriorExpDate : state.common.emptyInstantQuoteData.policyInfo.d_PriorExpDate ? state.common.emptyInstantQuoteData.policyInfo.d_PriorExpDate : "",
        uwData : state.common.emptyInstantQuoteData.uwData.priorPolicyQuestionInfo.s_PoAdditionalStatsAnswer ? state.common.emptyInstantQuoteData.uwData.priorPolicyQuestionInfo.s_PoAdditionalStatsAnswer : "NO",
    };
} 

export default connect(mapStateToProps)(PriorInformation);