import Http from "../../../../Http";

export async function printQuote({ formType, poTransPK }) {
  try {
    const policyCoreApiUrl = process.env.REACT_APP_POLICY_CORE_URL;
    const url = `${policyCoreApiUrl}/api/v1/privateProduct/forms/generate/${formType}/${poTransPK}`;
    const response = await Http.get(url);
    return response.data;
  } catch (err) {
    console.error("Error while generating print quote:", err);
    throw err; // Re-throw the error to be caught by the caller
  }
}
