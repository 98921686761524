import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {AiOutlineInfoCircle} from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { checkPivotOutage } from "../../services/commonService";


const PivotOutageMsg = () => {

  const { t } = useTranslation();
  const isPivotOutage = checkPivotOutage();


  return (
      <div>
		{isPivotOutage == 'true' && 
			<>
				<br />
				<Alert variant="info" style={{ padding: "8px" }} className="text-center">
                    <label>
                        <b>Provisional Rates Alert</b><AiOutlineInfoCircle size={25} style={{paddingLeft : '4%',color:"blue",float:'right'}} data-tip data-for="pivot-outage-msg" />
                        <ReactTooltip id="pivot-outage-msg" place="bottom" type="info" effect="float">
                            Provisional Rates are used when FEMA's rating engine is unavailable <br /> and full risk rates cannot be provided <br /> <b>Possible Actions:</b> <br /> You may periodically re-check rates to determine if actual full risk<br />are available
                        </ReactTooltip>
                    </label>
              </Alert>
			</>
			
		}
        
      </div>
      
     
  );
};
export default PivotOutageMsg;
