import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { PremiumModal } from "./SavePremiumModal";
import { PremiumCardDisplay } from "./PremiumCardDisplay";
// import ErrorWarningHandler from "../utils/ErrorWarningHandler";
import PrimaryErrorHandler from "../../../quote/create-quote/utility/PrimaryErrorHandler";
import ExcessErrorHandler from "../../../quote/create-quote/utility/ExcessErrorHandler";
import { Currency } from "../../../common/Currency";

const EndorsementModal = ({
  premiumResponseData,
  productType,
  show,
  setShow,
  handleTransaction,
  setDataForEndorsement,
  commercialProductType,
  handleCloseMain,
  productCode,
  productId,
  saveLoading,
}) => {
  const [selectedCardData, setSelectedCardData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [preferredType, setPreferredType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeLossOfUse, setIncludeLossOfUse] = useState(null);
  const [includeContents, setIncludeContents] = useState(null);
  const [businessIncomePremium, setBusinessIncomePremium] = useState(null);
  const [choosedPreferredCard, setChoosedPreferredCard] = useState({});
  const [totalPremium, setTotalPremium] = useState(0);

  const formatLimitValue = (limit) => `$ ${limit?.toLocaleString()}`;

  const LimitDisplay = ({ label, value }) => (
    <p>
      {label}:&nbsp;
      <span style={{ fontWeight: "800" }}>{formatLimitValue(value)}</span>
    </p>
  );

  const handleCardClick = (deductible, deductibleType) => {
    setPreferredType(deductibleType);
    setSelectedCardData(deductible);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClose = () => setShow(false);

  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      centered
      size={"xl"}
      className={`${showModal && "d-none"}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Endorsement Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PremiumModal
          productType={productType}
          showModal={showModal}
          selectedCardData={selectedCardData}
          handleCloseModal={handleCloseModal}
          setChoosedPreferredCard={setChoosedPreferredCard}
          premiumResponseData={premiumResponseData}
          preferredType={preferredType}
          setLoading={setLoading}
          includeLossOfUse={includeLossOfUse}
          setIncludeLossOfUse={setIncludeLossOfUse}
          businessIncomePremium={businessIncomePremium}
          setBusinessIncomePremium={setBusinessIncomePremium}
          includeContents={includeContents}
          setIncludeContents={setIncludeContents}
          setTotalPremium={setTotalPremium}
          totalPremium={totalPremium}
          handleTransaction={handleTransaction}
          setDataForEndorsement={setDataForEndorsement}
          handleClose={handleClose}
          commercialProductType={commercialProductType}
          handleCloseMain={handleCloseMain}
          loading={loading}
          productCode={productCode}
          productId={productId}
          saveLoading={saveLoading}
        />
        <PrimaryErrorHandler premiumResponseData={premiumResponseData} />
        <Col>
          <Row className="justify-content-between">
            <Col xs="auto" className="text-left">
              <p>Policy No: {premiumResponseData.data?.hiscoxId}</p>
            </Col>
            <Col xs="auto" className="text-right">
              <h5>PRIMARY</h5>
            </Col>
            {/* this map is for residential primary */}
            <Row className="mt-2">
              {premiumResponseData?.data?.residential?.primary?.map(
                (primaryItem, primaryIndex) => (
                  <Fragment key={`primary-${primaryIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Building Limit"
                          value={primaryItem.buildingLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={primaryItem.contentsLimit}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Other Structures Limit"
                          value={primaryItem.otherStructuresLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Loss of Use Limit"
                          value={primaryItem.lossOfUseLimit}
                        />
                      </Col>
                    </Row>
                    <hr />
                    {primaryItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`primary-deductible-${deductibleIndex}`}>
                          {renderDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>

            {/* this map is for primary of commercaial tenanted */}
            <Row className="mt-2">
              {premiumResponseData?.data?.commercialTenanted?.primary?.map(
                (primaryItem, primaryIndex) => (
                  <Fragment key={`primary-${primaryIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Improvements and Betterments Limit"
                          value={primaryItem.improvementsAndBettermentsLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={primaryItem.contentsLimit}
                        />
                      </Col>
                    </Row>

                    <hr className="mt-1 mb-4" />
                    {primaryItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`primary-deductible-${deductibleIndex}`}>
                          {renderDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>

            {/* this map is for primary of commercaial owned */}
            <Row className="mt-2">
              {premiumResponseData?.data?.commercialOwned?.primary?.map(
                (primaryItem, primaryIndex) => (
                  <Fragment key={`primary-${primaryIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Building Limit"
                          value={primaryItem.buildingLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={primaryItem.contentsLimit}
                        />
                      </Col>
                    </Row>

                    <hr className="mt-1 mb-4" />
                    {primaryItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`primary-deductible-${deductibleIndex}`}>
                          {renderDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>
          </Row>
          <hr className="mt-1 mb-4" />
          <ExcessErrorHandler premiumResponseData={premiumResponseData} />
          <Row className="justify-content-end mt-2">
            <Col xs="auto">
              <h5 className="text-right">EXCESS</h5>
            </Col>
            <Row className="mt-2">
              {premiumResponseData?.data?.residential?.excess?.map(
                (excessItem, excessIndex) => (
                  <Fragment key={`excess-${excessIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Building Limit"
                          value={excessItem.buildingLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={excessItem.contentsLimit}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Other Structures Limit"
                          value={excessItem.otherStructuresLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Loss of Use Limit"
                          value={excessItem.lossOfUseLimit}
                        />
                      </Col>
                    </Row>
                    <hr className="mt-1 mb-4" />
                    {excessItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`excess-deductible-${deductibleIndex}`}>
                          {renderExcessDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>
            {/* this map is for excess of commercaial tenanted */}
            <Row className="mt-2">
              {premiumResponseData?.data?.commercialTenanted?.excess?.map(
                (excessItem, excessIndex) => (
                  <Fragment key={`excess-${excessIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Improvements and Betterments Limit"
                          value={excessItem.improvementsAndBettermentsLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={excessItem.contentsLimit}
                        />
                      </Col>
                    </Row>

                    <hr className="mt-1 mb-4" />
                    {excessItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`excess-deductible-${deductibleIndex}`}>
                          {renderExcessDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>

            {/* this map is for excess of commercaial owned */}
            <Row className="mt-2">
              {premiumResponseData?.data?.commercialOwned?.excess?.map(
                (excessItem, excessIndex) => (
                  <Fragment key={`excess-${excessIndex}`}>
                    <Row>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Building Limit"
                          value={excessItem.buildingLimit}
                        />
                      </Col>
                      <Col sm={6}>
                        <LimitDisplay
                          label="Contents Limit"
                          value={excessItem.contentsLimit}
                        />
                      </Col>
                    </Row>

                    <hr className="mt-1 mb-4" />
                    {excessItem.deductibles?.map(
                      (deductible, deductibleIndex) => (
                        <Fragment key={`primary-deductible-${deductibleIndex}`}>
                          {renderExcessDeductibles(
                            [deductible],
                            handleCardClick,
                            selectedCardData,
                            choosedPreferredCard,
                            includeLossOfUse,
                            includeContents,
                            businessIncomePremium
                          )}
                        </Fragment>
                      )
                    )}
                  </Fragment>
                )
              )}
            </Row>
          </Row>
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          onClick={handleClose}
          id="common-btn"
          style={{ padding: "9px  6px", color: "white" }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const renderDeductibles = (
  deductibles,
  handleCardClick,
  selectedCardData,
  choosedPreferredCard,
  includeLossOfUse,
  includeContents,
  businessIncomePremium
) => {
  const calculateTotalPremium = (deductible, isSelectedCard) => {
    let totalPremium = 0;
    // Check if the current card is the choosedPreferredCard
    if (isSelectedCard && choosedPreferredCard) {
      // Include premiums based on state
      if (
        includeLossOfUse &&
        deductible.hasOwnProperty("includeLossOfUseAdditionalPremium")
      ) {
        totalPremium += deductible.includeLossOfUseAdditionalPremium;
      } else if (
        !includeLossOfUse &&
        deductible.hasOwnProperty("excludeLossOfUseAdditionalPremium")
      ) {
        totalPremium += deductible.excludeLossOfUseAdditionalPremium;
      }

      if (
        includeContents &&
        deductible.hasOwnProperty("includeContentsAdditionalPremium")
      ) {
        totalPremium += deductible.includeContentsAdditionalPremium;
      } else if (
        !includeContents &&
        deductible.hasOwnProperty("excludeContentsAdditionalPremium")
      ) {
        totalPremium += deductible.excludeContentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("buildingAdditionalPremium")) {
        totalPremium += deductible.buildingAdditionalPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresAdditionalPremium")) {
        totalPremium += deductible.otherStructuresAdditionalPremium;
      }
    } else {
      // For other cards, include all premiums

      if (deductible.hasOwnProperty("buildingAdditionalPremium")) {
        totalPremium += deductible.buildingAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeContentsAdditionalPremium")) {
        totalPremium += deductible.includeContentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresAdditionalPremium")) {
        totalPremium += deductible.otherStructuresAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeLossOfUseAdditionalPremium")) {
        totalPremium += deductible.includeLossOfUseAdditionalPremium;
      }
      if (
        deductible.hasOwnProperty("improvementsAndBettermentsAdditionalPremium")
      ) {
        totalPremium += deductible.improvementsAndBettermentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeBusinessIncomeAdditionalPremium")) {
        totalPremium += deductible.includeBusinessIncomeAdditionalPremium;
      }
    }

    return totalPremium;
  };

  return deductibles.map((deductible, index) => {
    const isChosenPreferredCard = deductible === selectedCardData;
    const totalPremium = calculateTotalPremium(
      deductible,
      isChosenPreferredCard
    );

    return (
      <Col key={index} md={6} className="mb-4">
        <Card
          onClick={() => {
            handleCardClick(deductible, "Primary");
          }}
          className={`premium-card rounded-lg shadow-sm ${
            deductible !== undefined &&
            deductible === selectedCardData &&
            choosedPreferredCard
              ? "border-success"
              : ""
          }`}
        >
          <Card.Header className="d-flex justify-content-center align-items-center bg-light pt-3">
            <Card.Title>
              <div className="flex-grow-1 text-center">
                <p className="mb-0 fw-bold">
                  Deductible: ${" "}
                  {new Intl.NumberFormat("en-US").format(
                    deductible.deductible
                  ) || 0}
                </p>
              </div>
              {deductible !== undefined &&
                deductible === selectedCardData &&
                choosedPreferredCard && (
                  <IoCheckmarkDone color="green" size={25} />
                )}
            </Card.Title>
          </Card.Header>
          <Card.Body className="py-2">
            <PremiumCardDisplay deductible={deductible} />
          </Card.Body>
          <Card.Footer className="bg-light text-center pb-3">
            <span className="fw-bold fs-5">
              Total Premium Change: <Currency amount={totalPremium} float="center" negetiveAmountStyle="none" />
            </span>
          </Card.Footer>
        </Card>
      </Col>
    );
  });
};

const renderExcessDeductibles = (
  excessDeductibles,
  handleCardClick,
  selectedCardData,
  choosedPreferredCard,
  includeLossOfUse,
  includeContents,
  businessIncomePremium
) => {
  const calculateTotalPremium = (deductible, isSelectedCard) => {
    let totalPremium = 0;
    // Check if the current card is the choosedPreferredCard
    if (isSelectedCard && choosedPreferredCard) {
      // Include premiums based on state
      if (
        includeLossOfUse &&
        deductible.hasOwnProperty("includeLossOfUseAdditionalPremium")
      ) {
        totalPremium += deductible.includeLossOfUseAdditionalPremium;
      } else if (
        !includeLossOfUse &&
        deductible.hasOwnProperty("excludeLossOfUseAdditionalPremium")
      ) {
        totalPremium += deductible.excludeLossOfUseAdditionalPremium;
      }

      if (
        includeContents &&
        deductible.hasOwnProperty("includeContentsAdditionalPremium")
      ) {
        totalPremium += deductible.includeContentsAdditionalPremium;
      } else if (
        !includeContents &&
        deductible.hasOwnProperty("excludeContentsAdditionalPremium")
      ) {
        totalPremium += deductible.excludeContentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("buildingAdditionalPremium")) {
        totalPremium += deductible.buildingAdditionalPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresAdditionalPremium")) {
        totalPremium += deductible.otherStructuresAdditionalPremium;
      }
    } else {
      // For other cards, include all premiums

      if (deductible.hasOwnProperty("buildingAdditionalPremium")) {
        totalPremium += deductible.buildingAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeContentsAdditionalPremium")) {
        totalPremium += deductible.includeContentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresAdditionalPremium")) {
        totalPremium += deductible.otherStructuresAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeLossOfUseAdditionalPremium")) {
        totalPremium += deductible.includeLossOfUseAdditionalPremium;
      }
      if (
        deductible.hasOwnProperty("improvementsAndBettermentsAdditionalPremium")
      ) {
        totalPremium += deductible.improvementsAndBettermentsAdditionalPremium;
      }
      if (deductible.hasOwnProperty("includeBusinessIncomeAdditionalPremium")) {
        totalPremium += deductible.includeBusinessIncomeAdditionalPremium;
      }
    }

    return totalPremium;
  };

  return excessDeductibles.map((deductible, index) => {
    const isChosenPreferredCard = deductible === selectedCardData;
    const totalPremium = calculateTotalPremium(
      deductible,
      isChosenPreferredCard
    );
    return (
      <Col key={index} md={4} className="mb-3 p-3">
        <Card
          onClick={() => {
            handleCardClick(deductible, "Excess");
          }}
          className="premium-card"
          style={{
            maxWidth: "25vw",
            border: `${
              deductible !== undefined &&
              deductible === selectedCardData &&
              choosedPreferredCard
                ? "1px solid green"
                : ""
            }`,
            borderRadius: "0.25rem",
          }}
        >
          <Card.Header className="bg-light text-center">
            <Card.Title className="m-0 d-flex align-items-center">
              <div className="flex-grow-1 text-center">
                <p className="mb-0">
                  Deductible: <Currency amount={deductible.deductible} float="center" negetiveAmountStyle="none" />
                </p>
              </div>
              {deductible !== undefined &&
                deductible === selectedCardData &&
                choosedPreferredCard && <FaCheck color="green" size={25} />}
            </Card.Title>
          </Card.Header>

          <Card.Body className="py-2">
            <PremiumCardDisplay deductible={deductible} />
          </Card.Body>
          <Card.Footer className="bg-light text-center py-3">
            <span className="fw-bold fs-5">
              Total Premium Change: <Currency amount={totalPremium} float="center" negetiveAmountStyle="none" />
              
            </span>
          </Card.Footer>
        </Card>
      </Col>
    );
  });
};

export default EndorsementModal;