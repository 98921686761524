import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, InputGroup, Card, CardBody, Table, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {InputAdapter, TextMask} from "react-text-mask-hoc";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Switch from "react-switch";
import { enrollUser, stateList, getZipDetails, userProductsLicense, downloadW9CopyServer } from "../../services/enrollService";
import ZipDetailsView from './ZipDetailsView';

const EnrolAgent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [licManagerArray, setLicManagerArray] = useState([])
    const [userDataArray, setUserDataArray] = useState([])
    const [copymail, setCopymail] = useState(false)
    const [formDataArray, setFormDataArray] = useState({
        agency_name:'',
        agency_code:'',
        n_AgencyNPN:'',
        corp_status:'',
        DBA_name:'',
        tax_type:'',
        tax_number:'',
        mail_s_AddressLine1:'',
        mail_s_PostalCode:'',
        mail_n_Zipcodes_PK:'',
        mail_n_CityId_FK:'',
        mail_s_CityName:'',
        mail_n_StateId_FK:'',
        mail_s_StateName:'',
        mail_n_CountyId_FK:'',
        mail_s_CountyName:'',
        mail_n_CountryId_FK:'',
        loc_s_AddressLine1:'',
        loc_s_PostalCode:'',
        loc_n_Zipcodes_PK:'',
        loc_n_CityId_FK:'',
        loc_s_CityName:'',
        loc_n_StateId_FK:'',
        loc_s_StateName:'',
        loc_n_CountyId_FK:'',
        loc_s_CountyName:'',
        loc_n_CountryId_FK:'',
        web_s_WebsiteURL:'',
        phone_s_PhoneNumber:'',
        fax_s_FaxNumber:'',
        contact_s_Email:'',
        contact_s_ContactName:'',
        W9_Form:'',
        license_Information:licManagerArray,
        user_data:userDataArray,
    })
    const [states,setStates] = useState([])
    const [isConfirmation,setIsConfirmation] = useState(false)
    const [multipleCity, setMultipleCity] = useState(null);
    const [show, setShow] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const userData = {
        'first_name':'',
        'last_name':'',
        'email':'',
        'phone':'',
        'npn':'',
        'agent_code':'',
        'resident_state_id':'',
        'user_detail': [
            {
            's_LicenseStatus':'',
            'n_LicenseNo':'',
            'n_StateId_FK':'',
            'n_AgentNPN':'',
            'd_ExpireDate':''
            }
        ]
    }

    const licManagerData = {
        'n_LicenseType': '',
        's_LicenseNo': '',
        'n_StateId_FK': '',
        'd_IssueDate': '',
        'd_ExpireDate': ''
    }

    useEffect(() => {
        (async () => {
            const stateData = await dispatch(stateList())
            setStates(stateData.data)
            const productsLicenseList = await dispatch(userProductsLicense())
        })();

        setUserDataArray([userData])
        setLicManagerArray([licManagerData])
    }, [])

    useEffect( () => {
        const newData = { ...formDataArray }
        newData['license_Information'] = licManagerArray
        setFormDataArray(newData)
    }, [licManagerArray])

    useEffect( () => {
        const newData = { ...formDataArray }
        newData['user_data'] = userDataArray
        setFormDataArray(newData)
    }, [userDataArray])

    const handleSubmit = (e) => {
        e.preventDefault();
        let hasError = false

        if(formDataArray.agency_name.trim() == ''){
            toast.error("Agency Name is required")
            return false
        }
        if(formDataArray.corp_status.trim() == ''){
            toast.error("Corp Status is required")
            return false
        }
        if(formDataArray.tax_type.trim() == ''){
            toast.error("Tax Type is required")
            return false
        }
        if(formDataArray.tax_number.trim() == ''){
            toast.error("FEIN Number / SS Number is required")
            return false
        }
        if(formDataArray.n_AgencyNPN.trim() == ''){
            toast.error("Agency NPN is required")
            return false
        }
        
        if(formDataArray.contact_s_Email.trim() == ''){
            toast.error("Email is required")
            return false
        }
        if(!validateEmail(formDataArray.contact_s_Email)){
            toast.error('Contact Email is invalid!')
            return false
        }
        if(formDataArray.contact_s_ContactName.trim() == ''){
            toast.error("Contact name is required")
            return false
        }
        
        if(formDataArray.phone_s_PhoneNumber.trim() == ''){
            toast.error("Phone Number is required")
            return false
        }

        if(!formDataArray.W9_Form){
            toast.error("W9 Form is required")
            return false
        }
        
        if(formDataArray.mail_s_AddressLine1.trim() == ''){
            toast.error("Mailing Address Line 1 is required")
            return false
        }
        if(formDataArray.mail_s_PostalCode.trim() == ''){
            toast.error("Mailing Address Zip is required")
            return false
        }
        if(formDataArray.mail_s_CityName.trim() == ''){
            toast.error("Mailing Address Cit Name is required")
            return false
        }
        if(formDataArray.mail_s_StateName.trim() == ''){
            toast.error("Mailing Address State is required")
            return false
        }
        
        if(licManagerArray.length > 0){
            const validate = new Promise(() => {
                for(var row = 0; row < licManagerArray.length; row++){
                    if(licManagerArray[row].s_LicenseNo.trim() == ''){
                        toast.error('License No at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(licManagerArray[row].n_StateId_FK == 0){
                        toast.error('License State at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                }
            })
        }

        if(userDataArray.length > 0){
            const validate = new Promise(() => {
                for(var row = 0; row < userDataArray.length; row++){
                    if(userDataArray[row].first_name == 0){
                        toast.error('First Name at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(userDataArray[row].last_name.trim() == ''){
                        toast.error('Last Name at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(userDataArray[row].email == ''){
                        toast.error('Email at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(!validateEmail(userDataArray[row].email)){
                        toast.error('Email at row '+ (parseInt(row) + 1) + ' is invalid!')
                        hasError = true
                        break;
                    }
                    if(userDataArray[row].phone == 0){
                        toast.error('Phone at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(userDataArray[row].npn == 0){
                        toast.error('Agent NPN at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    if(userDataArray[row].resident_state_id == 0){
                        toast.error('Resident State at row '+ (parseInt(row) + 1) + ' is required')
                        hasError = true
                        break;
                    }
                    const userDetail = userDataArray[row].user_detail;
                    if(userDetail) {
                        for(var i = 0; i < userDetail.length; i++){
                            if(userDetail[i].n_LicenseNo.trim() == ''){
                                toast.error('User License No is required')
                                hasError = true
                                break;
                            }
                            if(userDetail[i].n_StateId_FK == 0){
                                toast.error('User License State is required')
                                hasError = true
                                break;
                            }
                        }
                    }
                }
            })
        }
        
        if(userDataArray.length > 0){
            const validate = new Promise(() => {
                var checkArr = userDataArray.map(function(item){ return item.email });
                var isDuplicate = checkArr.some(function(item, idx){ 
                    return checkArr.indexOf(item) != idx 
                });
                if(isDuplicate) {
                    toast.error('Please enter unique email for every user!')
                    hasError = true
                }
            });
        }
        if(hasError){
            setIsDisabled(false);
            setLoading(false);
            return false
        } else {
            setLoading(true);
            setIsDisabled(true);
            const data = new FormData();
            data.append('formDataArray', JSON.stringify(formDataArray));
            data.append('W9_Form', formDataArray.W9_Form);
            dispatch(enrollUser(data))
                .then(async (response) => {
                if(response?.status) {
                    setLicManagerArray([])
                    setUserDataArray([userData])
                    setFormDataArray({
                        agency_name:'',
                        agency_code:'',
                        n_AgencyNPN:'',
                        corp_status:'',
                        DBA_name:'',
                        tax_type:'',
                        tax_number:'',
                        mail_s_AddressLine1:'',
                        mail_s_PostalCode:'',
                        mail_n_Zipcodes_PK:'',
                        mail_n_CityId_FK:'',
                        mail_s_CityName:'',
                        mail_n_StateId_FK:'',
                        mail_s_StateName:'',
                        mail_n_CountyId_FK:'',
                        mail_s_CountyName:'',
                        mail_n_CountryId_FK:'',
                        loc_s_AddressLine1:'',
                        loc_s_PostalCode:'',
                        loc_n_Zipcodes_PK:'',
                        loc_n_CityId_FK:'',
                        loc_s_CityName:'',
                        loc_n_StateId_FK:'',
                        loc_s_StateName:'',
                        loc_n_CountyId_FK:'',
                        loc_s_CountyName:'',
                        loc_n_CountryId_FK:'',
                        web_s_WebsiteURL:'',
                        phone_s_PhoneNumber:'',
                        fax_s_FaxNumber:'',
                        contact_s_Email:'',
                        contact_s_ContactName:'',
                        W9_Form:''
                    })
                    setLoading(false);
                    setIsConfirmation(true);
                } else {
                    if(response?.errors?.W9_Form) {
                        toast.error("W9 form should be in PDF format and less than 5MB");
                    }
                    setIsConfirmation(false);
                    setLoading(false);
                    setIsDisabled(false);
                }
            })
            .catch(() => {
                toast.error("Please Try again");
                setIsConfirmation(false);
                setLoading(false);
                setIsDisabled(false);
            });
        }
    }

    const addLicenseInformationRow = () => {
        const oldLicManagerArray = [...licManagerArray]
        oldLicManagerArray.push(licManagerData)
        setLicManagerArray(oldLicManagerArray)
    }

    const addUserRow = () => {
        const oldUserDataArray = [...userDataArray]
        oldUserDataArray.push(userData)
        setUserDataArray(oldUserDataArray)
    }

    const addProductsLicenseRow = (user, i) => {
        const item = {
            s_LicenseStatus:'',
            n_LicenseNo:'',
            n_StateId_FK:'',
            n_AgentNPN:'',
            d_ExpireDate:''
        }
        user?.user_detail.push(item)
        const oldUserDataArray = [...userDataArray]
        oldUserDataArray[i] = user;
        setUserDataArray(oldUserDataArray)
    }
    
    const removeLicenseInformationRow = (i) => {
        const licManagerNewArr = [...licManagerArray]
        if(licManagerNewArr.length > 1) {
            licManagerNewArr.splice(i, 1)
            setLicManagerArray(licManagerNewArr)
        } else {
            toast.error('At least one License Information row is required!');
            return false;
        }
    }

    const handleRemoveUserRow = async (i) => {
        const oldUserDataArray = [...userDataArray]
        if(oldUserDataArray.length > 1) {
            oldUserDataArray.splice(i, 1);
            setUserDataArray(oldUserDataArray)
        } else {
            toast.error('At least one row is required!');
            return false;
        }
    }

    const removeProductsLicenseRow = (userInIndex, index, user) => {
        if(user?.user_detail.length > 1) {
            user?.user_detail.splice(userInIndex, 1);
            const oldUserDataArray = [...userDataArray]
            oldUserDataArray[index] = user;
            setUserDataArray(oldUserDataArray)
        } else {
            toast.error('At least one row is required!');
            return false;
        }
    }
    
    const handleChangeAgency = (index, dataType, value) => {
        const licManager_arr = licManagerArray.map((item, i) => {
          if (i == index) {
            return {...item, [dataType]: value};
          }
          return item;
        });
        setLicManagerArray(licManager_arr)
    }

    const handleChangeUserProdLic = (userInIndex, index, user, dataType, value) => {
        const newUserDataArray = user?.user_detail.map((item, i) => {
            if (i == userInIndex) {
                return {...item, [dataType]: value};
            }
            return item;
        });

        const oldUserDataArray = [...userDataArray]
        oldUserDataArray[index].user_detail = newUserDataArray;
        setUserDataArray(oldUserDataArray)
    }
    
    const handleChangeUser = (index, dataType, value) => {
        const newUserDataArray = userDataArray.map((item, i) => {
            if (i == index) {
            return {...item, [dataType]: value};
            }
            return item;
        });
        setUserDataArray(newUserDataArray)
    }

    const validateEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const handleChange = (field, value) => {
        const newData = { ...formDataArray }
        newData[field] = value
        setFormDataArray(newData)
    }

    const handleFileChange = (field, e) => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        
        const newData = { ...formDataArray }
        newData[field] = e.target.files[0]
        setFormDataArray(newData)
      }

    const handleSwitchChange = (checkedValue, event, name) => {
        const newData = { ...formDataArray }
        if(checkedValue == true) {
            newData['loc_s_AddressLine1'] = newData['mail_s_AddressLine1'];
            newData['loc_s_PostalCode'] = newData['mail_s_PostalCode'];
            newData['loc_n_Zipcodes_PK'] = newData['mail_n_Zipcodes_PK'];
            newData['loc_n_CityId_FK'] = newData['mail_n_CityId_FK'];
            newData['loc_s_CityName'] = newData['mail_s_CityName'];
            newData['loc_n_StateId_FK'] = newData['mail_n_StateId_FK'];
            newData['loc_s_StateName'] = newData['mail_s_StateName'];
            newData['loc_n_CountyId_FK'] = newData['mail_n_CountyId_FK'];
            newData['loc_s_CountyName'] = newData['mail_s_CountyName'];
            newData['loc_n_CountryId_FK'] = newData['mail_n_CountryId_FK'];
        } else {
            newData['loc_s_AddressLine1'] = '';
            newData['loc_s_PostalCode'] = '';
            newData['loc_n_Zipcodes_PK'] = '';
            newData['loc_n_CityId_FK'] = '';
            newData['loc_s_CityName'] = '';
            newData['loc_n_StateId_FK'] = '';
            newData['loc_s_StateName'] = '';
            newData['loc_n_CountyId_FK'] = '';
            newData['loc_s_CountyName'] = '';
            newData['loc_n_CountryId_FK'] = '';
        }
        setFormDataArray(newData)
        setCopymail(!copymail);
	}
    // get zip details from address
    const getDataFromZip = (zipCodeVal, target) => {
        /*
            Required in future
            if (streetName === '') {
                toast.error('Please Enter Street Name!');
                setZipCode('')
                return false;
            }
        */
        if (zipCodeVal !== '') {
            setLoading(true);
            dispatch(getZipDetails(zipCodeVal))
                .then((res) => {
                    if (res.success) {
                        setLoading(false);
                        var result = res?.data;
                        if (result.status === 'Y') {
                            if (result.count === 1) {
                                setZipDetailsData(result.zipData[0], target);
                            } 
                            else {
                                showMultipleCityModal(result.zipData, target);
                            }
                        } else {
                            toast.error(res.message);
                            if(target == 'mail') {
                                setFormDataArray({
                                    ...formDataArray,
                                    mail_n_CityId_FK: '',
                                    mail_s_CityName: '',
                                    mail_n_StateId_FK: '',
                                    mail_s_StateName: '',
                                    mail_n_CountyId_FK: '',
                                    mail_s_CountyName: '',
                                    mail_n_CountryId_FK:'',
                                    mail_n_Zipcodes_PK: '',
                                });
                            }
                            if(target == 'loc') {
                                setFormDataArray({
                                    ...formDataArray,
                                    loc_n_CityId_FK: '',
                                    loc_s_CityName: '',
                                    loc_n_StateId_FK: '',
                                    loc_s_StateName: '',
                                    loc_n_CountyId_FK: '',
                                    loc_s_CountyName: '',
                                    loc_n_CountryId_FK:'',
                                    loc_n_Zipcodes_PK: '',
                                });
                            }
                        }
                    }
                });
        } else {
            toast.error('Zip Code should not be empty!');
        }
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData, target) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} target={target}/>)
        setShow(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData, target) => {
        if(target == 'mail') {
            setFormDataArray({
                ...formDataArray,
                mail_n_CityId_FK: zipData.n_CityId_PK,
                mail_s_CityName: zipData.s_CityName,
                mail_n_StateId_FK: zipData.n_StateId_PK,
                mail_s_StateName: zipData.s_StateCode,
                mail_n_CountyId_FK: zipData.n_CountyId_FK,
                mail_s_CountyName: zipData.s_CountyCode,
                mail_n_CountryId_FK:zipData.n_CountryId_FK,
                mail_n_Zipcodes_PK: zipData.n_Zipcodes_PK,
            })
        }

        if(target == 'loc') {
            setFormDataArray({
                ...formDataArray,
                loc_n_CityId_FK: zipData.n_CityId_PK,
                loc_s_CityName: zipData.s_CityName,
                loc_n_StateId_FK: zipData.n_StateId_PK,
                loc_s_StateName: zipData.s_StateCode,
                loc_n_CountyId_FK: zipData.n_CountyId_FK,
                loc_s_CountyName: zipData.s_CountyCode,
                loc_n_CountryId_FK:zipData.n_CountryId_FK,
                loc_n_Zipcodes_PK: zipData.n_Zipcodes_PK,
            })
        }
        setMultipleCity(null);
        setShow(false);
    }

    const downloadW9Copy = () => {
        dispatch(downloadW9CopyServer())
        .then(async (res) => {
            if (res?.success) {
                window.open(res?.url);
            } else {
                toast.error(res?.message);
            }
        })
    }
    return (
        <>
        <LoadingOverlay>
            <Loader loading={loading} />
            <div className="container p-5">
                {
                !isConfirmation ?
                (
                    <Form onSubmit={handleSubmit} id="agencyForm">
                        <Row className="m-5">
                            <Col xs="12">
                                <div className="card-header-main">
                                    <Col md="6">
                                        <div className="card-header-main-title m-0">
                                            <h6>{t("Agency Detail")}</h6>
                                        </div>
                                    </Col>
                                    <Col md="6" className="text-end">
                                        <span className="text-danger">*</span> {t("Required Fields")}
                                    </Col>
                                </div>
                                <div className="card-body-main">
                                    <div>
                                        <Row className="float-center">
                                            <Col md={4} className="">
                                                <div>
                                                    <label className="mb-1 mt-1" htmlFor="agency_name">{t("Agency Name")}
                                                    <span className="text-danger">*</span></label>
                                                    <input
                                                    type="text"
                                                    id="agency_name"
                                                    name="agency_name"
                                                    value={formDataArray.agency_name}
                                                    className="form-control dashboard-group-input"
                                                    onChange={(e) => handleChange('agency_name', e.target.value)}
                                                    placeholder={t("Agency Name")}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="corp_status">{t("Corp Status")} <span className="text-danger">*</span></label>
                                                <select
                                                type="select"
                                                name="corp_status"
                                                value={formDataArray.corp_status}
                                                id="corp_status"
                                                className="form-control dashboard-group-input"
                                                onChange={(e) => handleChange('corp_status', e.target.value)}
                                                >
                                                    <option value="">-Select-</option>
                                                    <option value="YES">Yes</option>
                                                    <option value="NO">No</option>
                                                </select>
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="DBA_name">{t("DBA Name")}</label>
                                                <input
                                                type="text"
                                                id="DBA_name"
                                                value={formDataArray.DBA_name}
                                                name="DBA_name"
                                                className="form-control dashboard-group-input"
                                                placeholder={t("DBA Name")}
                                                onChange={(e) => handleChange('DBA_name', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="tax_type">{t("Tax Type")} <span className="text-danger">*</span></label>
                                                <select
                                                id="tax_type"
                                                name="tax_type"
                                                className="form-control dashboard-group-input"
                                                value={formDataArray.tax_type}
                                                onChange={(e) => handleChange('tax_type', e.target.value)}
                                                >
                                                    <option value="">-Select-</option>
                                                    <option value="FEIN">FEIN</option> 
                                                    <option value="SS">SS</option>
                                                </select>
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="tax_number">{t("FEIN Number / SS Number")} <span className="text-danger">*</span></label>
                                                <TextMask
                                                    mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    Component={InputAdapter}
                                                    className="form-control"
                                                    type="text"
                                                    id="tax_number"
                                                    name="tax_number"
                                                    value={formDataArray.tax_number}
                                                    placeholder={t("FEIN Number / SS Number")}
                                                    onChange={(e) => handleChange('tax_number', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="agency_code">{t("Agency Code")}</label>
                                                <input
                                                type="text"
                                                id="agency_code"
                                                name="agency_code"
                                                value={formDataArray.agency_code}
                                                className="form-control dashboard-group-input"
                                                placeholder={t("Agency Code")}
                                                onChange={(e) => handleChange('agency_code', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="contact_s_ContactName">{t("Contact Person")} <span className="text-danger">*</span></label>
                                                <input
                                                type="text"
                                                id="contact_s_ContactName"
                                                name="contact_s_ContactName"
                                                value={formDataArray.contact_s_ContactName}
                                                className="form-control dashboard-group-input"
                                                placeholder={t("Contact Person")}
                                                onChange={(e) => handleChange('contact_s_ContactName', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="web_s_WebsiteURL">{t("Website")}</label>
                                                <input
                                                type="text"
                                                id="web_s_WebsiteURL"
                                                name="web_s_WebsiteURL"
                                                value={formDataArray.web_s_WebsiteURL}
                                                className="form-control dashboard-group-input"
                                                placeholder={t("Website")}
                                                onChange={(e) => handleChange('web_s_WebsiteURL', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="phone_s_PhoneNumber">{t("Phone Number")} <span className="text-danger">*</span></label>
                                                <TextMask
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    Component={InputAdapter}
                                                    className="form-control dashboard-group-input"
                                                    style={{ flex: 1 }}
                                                    type="text"
                                                    id="phone_s_PhoneNumber"
                                                    name="phone_s_PhoneNumber"
                                                    placeholder={t("Phone Number")}
                                                    autoComplete="none"
                                                    onChange={(e) => handleChange('phone_s_PhoneNumber', e.target.value)}
                                                    parentobject="mainNode"
                                                    value={formDataArray.phone_s_PhoneNumber}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="fax_s_FaxNumber">{t("Fax")}</label>
                                                <TextMask
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    Component={InputAdapter}
                                                    className="form-control dashboard-group-input"
                                                    style={{ flex: 1 }}
                                                    type="text"
                                                    id="fax_s_FaxNumber"
                                                    name="fax_s_FaxNumber"
                                                    placeholder={t("Fax")}
                                                    autoComplete="none"
                                                    onChange={(e) => handleChange('fax_s_FaxNumber', e.target.value)}
                                                    parentobject="mainNode"
                                                    value={formDataArray.fax_s_FaxNumber}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="contact_s_Email">{t("Email")} <span className="text-danger">*</span></label>
                                                <input
                                                type="text"
                                                id="contact_s_Email"
                                                name="contact_s_Email"
                                                value={formDataArray.contact_s_Email}
                                                className="form-control dashboard-group-input"
                                                placeholder={t("Email")}
                                                onChange={(e) => handleChange('contact_s_Email', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="n_AgencyNPN">{t("AGENCY NPN")}
                                                <span className="text-danger">*</span></label>
                                                <input 
                                                    bsSize="sm" 
                                                    type="n_AgencyNPN" 
                                                    className="form-control"
                                                    value={formDataArray.n_AgencyNPN}
                                                    name="n_AgencyNPN"
                                                    placeholder={t("AGENCY NPN")}
                                                    onChange={(e) => handleChange('n_AgencyNPN', e.target.value)}
                                                />
                                            </Col>
                                            <Col md={4} className="">
                                                <label className="mb-1 mt-1" htmlFor="W9_Form">{t("Upload W9 Form")} 
                                                <span className="text-danger">*</span></label>
                                                <input 
                                                    bsSize="sm" 
                                                    type="file" 
                                                    className="form-control"
                                                    name="W9_Form"
                                                    placeholder="AGENCY NPN"
                                                    onChange={(e) => handleFileChange('W9_Form', e)}
                                                />
                                            </Col>
                                            <Col md={1} className="mt-3">
                                                <label htmlFor="W9_Form"></label>
                                                <h4 title="Download W9 Form Blank Copy" ><i className="fa fa-download d-block" onClick={() => downloadW9Copy()}></i></h4>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-5">
                            <Col xs="12">
                                <div className="card-header-main">
                                    <div className="card-header-main-title m-0">
                                        <h6>{t("Mailing Address")}</h6>
                                    </div>
                                </div>
                                <div className="card-body-main">
                                <Row>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="mail_s_AddressLine1">{t("Address Line 1")} <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            id="mail_s_AddressLine1"
                                            name="mail_s_AddressLine1"
                                            value={formDataArray.mail_s_AddressLine1}
                                            className="form-control dashboard-group-input"
                                            placeholder={t("Address Line 1")}
                                            onChange={(e) => handleChange('mail_s_AddressLine1', e.target.value)}
                                            
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="mail_s_PostalCode">{t("Zip")} <span className="text-danger">*</span></label>
                                        <input
                                            type="text" 
                                            id="mail_s_PostalCode" 
                                            name="mail_s_PostalCode" 
                                            value={formDataArray.mail_s_PostalCode}
                                            className="form-control dashboard-group-input"
                                            placeholder={t("Zip")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('mail_s_PostalCode', e.target.value)}
                                            onBlur={ (e) => getDataFromZip(e.target.value, 'mail')}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="mail_s_CityName">{t("City")} <span className="text-danger">*</span></label>
                                        <input
                                            type="text" 
                                            id="mail_s_CityName" 
                                            name="mail_s_CityName" 
                                            value={formDataArray.mail_s_CityName}
                                            className="form-control dashboard-group-input"
                                            placeholder={t("City")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('mail_s_CityName', e.target.value)}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="mail_s_StateName">{t("State")} <span className="text-danger">*</span></label>
                                        <input
                                            type="text" 
                                            id="s_Stamail_s_StateNameteName" 
                                            name="mail_s_StateName" 
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.mail_s_StateName}
                                            placeholder={t("State")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('mail_s_StateName', e.target.value)}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="mail_s_CountyName">{t("County")}</label>
                                        <input
                                            type="text" 
                                            id="mail_s_CountyName" 
                                            name="mail_s_CountyName" 
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.mail_s_CountyName}
                                            placeholder={t("County")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('mail_s_CountyName', e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-5">
                            <Col xs="12">                        
                                <div className="card-header-main">
                                    <div className="card-header-main-title m-0">
                                        <h6>{t("Location Address")}</h6>
                                    </div>
                                    <Row style={{paddingTop:'4px'}}>
                                        <Col xs={9} sm={9}>
                                        <b>{t("Copy as mailing Address")}</b>:
                                        </Col>
                                        <Col xs={3} sm={3}>
                                            <label>
                                                <Switch
                                                    checked={copymail && copymail == true ? true : false}
                                                    onChange={(checkedValue,event,name) => {
                                                        handleSwitchChange(checkedValue,event,name)
                                                    }}
                                                    onColor="#66E3D8"
                                                    onHandleColor="#66E3D8"
                                                    handleDiameter={30}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={20}
                                                    width={48}
                                                    className="react-switch"
                                                    id="commission.rollover"
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-body-main">
                                <Row>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="loc_s_AddressLine1">{t("Address Line 1")}</label>
                                        <input
                                            type="text"
                                            id="loc_s_AddressLine1"
                                            name="loc_s_AddressLine1"
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.loc_s_AddressLine1}
                                            placeholder={t("Address Line 1")}
                                            onChange={(e) => handleChange('loc_s_AddressLine1', e.target.value)}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="loc_s_PostalCode">{t("Zip")}</label>
                                        <input
                                            type="text" 
                                            id="loc_s_PostalCode" 
                                            name="loc_s_PostalCode" 
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.loc_s_PostalCode}
                                            placeholder={t("Zip")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('loc_s_PostalCode', e.target.value)}
                                            onBlur={ (e) => getDataFromZip(e.target.value, 'loc')}
                                        />
                                        
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="loc_s_CityName">{t("City")}</label>
                                        <input
                                            type="text" 
                                            id="loc_s_CityName" 
                                            name="loc_s_CityName" 
                                            className="form-control dashboard-group-input"
                                            placeholder={t("City")}
                                            autoComplete="none"
                                            value={formDataArray.loc_s_CityName}
                                            onChange={(e) => handleChange('loc_s_CityName', e.target.value)}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="loc_s_StateName">{t("State")}</label>
                                        <input
                                            type="text" 
                                            id="s_Staloc_s_StateNameteName" 
                                            name="loc_s_StateName" 
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.loc_s_StateName}
                                            placeholder={t("State")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('loc_s_StateName', e.target.value)}
                                        />
                                    </Col>
                                    <Col md="4">
                                        <label className="mb-1 mt-1" htmlFor="loc_s_CountyName">{t("County")}</label>
                                        <input
                                            type="text" 
                                            id="loc_s_CountyName" 
                                            name="loc_s_CountyName" 
                                            className="form-control dashboard-group-input"
                                            value={formDataArray.loc_s_CountyName}
                                            placeholder={t("County")}
                                            autoComplete="none"
                                            onChange={(e) => handleChange('loc_s_CountyName', e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-5">
                            <Col xs="12">
                                <div className="card-header-main">
                                    <div className="card-header-main-title m-0">
                                        <h6>
                                            {t("License Information")}
                                        </h6>
                                    </div>
                                    <Button
                                        className="pull-right"
                                        variant="secondary"
                                        onClick={() => addLicenseInformationRow()}
                                    >
                                        {t("Add Row")}
                                    </Button>
                                </div>
                                <div className="card-body-main">
                                    <Table hover bordered striped responsive size="sm" className="small">
                                    <thead>
                                    <tr>
                                        <td>{t("LICENSE STATUS")}</td>
                                        <td>{t("LICENSE NO")}.<span className="text-danger">*</span></td>
                                        <td>{t("STATE")}<span className="text-danger">*</span></td>
                                        <td>{t("ISSUE DATE")}</td>
                                        <td>{t("EXP.DATE")}</td>
                                        <td>{t("ACTIONS")}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {licManagerArray.map((licManager, index) =>{ 
                                        return (
                                        <tr key={index}>
                                            <td>
                                            <select 
                                                type="select" 
                                                value={licManager.n_LicenseType} 
                                                className="form-control"
                                                name="n_LicenseType"
                                                bsSize="sm"
                                                placeholder="LICENSE TYPE"
                                                onChange={(e) => handleChangeAgency(index, 'n_LicenseType', e.target.value)}  
                                            >
                                                <option value="">Select Type</option>
                                                <option value="111">Active</option>
                                                <option value="112">Closed</option>
                                            </select>
                                            </td>
                                            <td>
                                            <input 
                                                bsSize="sm" 
                                                type="text" 
                                                value={licManager.s_LicenseNo}
                                                name="s_LicenseNo"
                                                className="form-control"
                                                placeholder="LICENSE NO"
                                                onChange={(e) => handleChangeAgency(index, 's_LicenseNo', e.target.value)}  
                                                
                                                />
                                            </td>
                                            <td>
                                            <select 
                                                type="select" 
                                                value={licManager.n_StateId_FK} 
                                                className="form-control"
                                                name="n_StateId_FK"
                                                onChange={(e) => handleChangeAgency(index, 'n_StateId_FK', e.target.value)}  
                                                bsSize="sm"
                                            >
                                                <option value="">Select State</option>
                                                {states.map((state) => (
                                                    <option value={state.n_StateId_PK}>
                                                    {state.s_StateName}
                                                    </option>
                                                ))}
                                            </select>
                                            </td>
                                            <td>
                                            <input 
                                                bsSize="sm" 
                                                type="date" 
                                                placeholder="Issue date" 
                                                className="form-control"
                                                value={licManager.d_IssueDate}
                                                name="d_IssueDate"
                                                onChange={(e) => handleChangeAgency(index, 'd_IssueDate', e.target.value)}  
                                            />
                                            </td>
                                            <td>
                                            <input 
                                                bsSize="sm" 
                                                type="date" 
                                                placeholder="Expire date" 
                                                className="form-control"
                                                value={licManager.d_ExpireDate}
                                                name="d_ExpireDate"
                                                onChange={(e) => handleChangeAgency(index, 'd_ExpireDate', e.target.value)}  
                                            />
                                            </td>
                                            <td>
                                            <Button 
                                                size="sm" 
                                                color="danger" 
                                                className="btn-pill btn-sm"
                                                onClick={(e) => removeLicenseInformationRow(index)}
                                            >
                                                <i className="fa fa-trash d-block pt-1"></i>
                                            </Button>
                                            </td>
                                        </tr>
                                        )
                                    })}
                                    </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <Row className="m-5">
                            <Col xs="12">
                                <div className="card-header-main">
                                    <div className="card-header-main-title m-0">
                                        <h6>{t("Add Agency Users")}</h6>
                                    </div>
                                    <Button
                                        className="pull-right"
                                        variant="secondary"
                                        onClick={() => addUserRow()}
                                    >
                                        {t("Add User")}
                                    </Button>
                                </div>
                                <div className="card-body-main">
                                { userDataArray.map((user, index) => { 
                                    return (
                                        <div>
                                            {
                                                (index > 0) && <hr></hr> 
                                            }
                                            <div className="">
                                            <Row>
                                                <Col md="6" className="mt-2"></Col>
                                                <Col md="6">
                                                    <Button
                                                        className="pull-right btn-pill"
                                                        color="danger"
                                                        onClick={() => handleRemoveUserRow(index)}
                                                    >
                                                       <i className="fa fa-trash d-block pt-1"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="first_name">{t("First Name")} <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text" 
                                                            id="first_name" 
                                                            name="first_name" 
                                                            className="form-control dashboard-group-input"
                                                            value={user.first_name}
                                                            placeholder={t("First Name")}
                                                            onChange={(e) => handleChangeUser(index, 'first_name', e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="last_name">{t("Last Name")} <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text" 
                                                            id="last_name" 
                                                            name="last_name" 
                                                            className="form-control dashboard-group-input"
                                                            value={user.last_name}
                                                            placeholder={t("Last Name")}
                                                            onChange={(e) => handleChangeUser(index, 'last_name', e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="email">{t("Email")} <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text" 
                                                            id="email" 
                                                            name="email" 
                                                            className="form-control dashboard-group-input"
                                                            value={user.email}
                                                            placeholder={t("Email")}
                                                            onChange={(e) => handleChangeUser(index, 'email', e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="phone">{t("Phone Number")} <span className="text-danger">*</span></label>
                                                        <TextMask
                                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                            Component={InputAdapter}
                                                            className="form-control dashboard-group-input"
                                                            style={{ flex: 1 }}
                                                            type="text"
                                                            id="phone"
                                                            name="phone"
                                                            placeholder={t("Phone Number")}
                                                            autoComplete="none"
                                                            onChange={(e) => handleChangeUser(index, 'phone', e.target.value)}
                                                            parentobject="mainNode"
                                                            value={user.phone}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="agent_code">{t("Agent Code")}</label>
                                                        <input
                                                            type="text" 
                                                            id="agent_code" 
                                                            name="agent_code" 
                                                            className="form-control dashboard-group-input"
                                                            value={user.agent_code}
                                                            placeholder={t("Agent Code")}
                                                            onChange={(e) => handleChangeUser(index, 'agent_code', e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="npn">{t("Agent NPN")} <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text" 
                                                            id="npn" 
                                                            name="npn" 
                                                            className="form-control dashboard-group-input"
                                                            value={user.npn}
                                                            placeholder={t("Agent NPN")}
                                                            onChange={(e) => handleChangeUser(index, 'npn', e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <label className="mb-1 mt-1" htmlFor="resident_state_id">{t("Resident State")} <span className="text-danger">*</span></label>
                                                        <select 
                                                            type="select" 
                                                            value={user.resident_state_id} 
                                                            className="form-control dashboard-group-input"
                                                            name="resident_state_id"
                                                            onChange={(e) => handleChangeUser(index, 'resident_state_id', e.target.value)}
                                                            bsSize="sm"
                                                        >
                                                            <option value="">Select State</option>
                                                            {states.map((state) => (
                                                                <option value={state.n_StateId_PK}>
                                                                {state.s_StateName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <div className="mt-2">
                                                        <hr></hr>
                                                        <Row>
                                                            <Col md="6" className="mt-2">
                                                                <h6 >{t("User Products/License Information")}</h6>
                                                            </Col>
                                                            <Col md="6">
                                                                <Button
                                                                    className="pull-right"
                                                                    variant="secondary"
                                                                    onClick={() => addProductsLicenseRow(user, index)}
                                                                >
                                                                    <i className="fa fa-plus d-block pt-1"></i>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <hr></hr>
                                                        <Table hover bordered striped responsive size="sm" className="small mt-2">
                                                        <thead>
                                                        <tr>
                                                            <td>{t("LICENSE STATUS")}</td>
                                                            <td>{t("LICENSE NO")}. <span className="text-danger">*</span></td>
                                                            <td>{t("STATE")} <span className="text-danger">*</span></td>
                                                            <td>{t("EXP.DATE")}</td>
                                                            <td>{t("ACTIONS")}</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {user?.user_detail.map((userIn, userInIndex) =>{ 
                                                            return (
                                                            <tr key={userInIndex}>
                                                                <td>
                                                                <select 
                                                                    type="select" 
                                                                    value={userIn.s_LicenseStatus} 
                                                                    className="form-control"
                                                                    name="s_LicenseStatus"
                                                                    onChange={(e) => handleChangeUserProdLic(userInIndex, index, user, 's_LicenseStatus', e.target.value)}  
                                                                    bsSize="sm"
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    <option value="111">Active</option>
                                                                    <option value="112">Closed</option>
                                                                </select>
                                                                </td>
                                                                <td>
                                                                <input 
                                                                    bsSize="sm" 
                                                                    type="text" 
                                                                    value={userIn.n_LicenseNo}
                                                                    name="n_LicenseNo"
                                                                    className="form-control"
                                                                    placeholder="LICENSE NO"
                                                                    onChange={(e) => handleChangeUserProdLic(userInIndex, index, user, 'n_LicenseNo', e.target.value)}  
                                                                    
                                                                    />
                                                                </td>
                                                                <td>
                                                                <select 
                                                                    type="select" 
                                                                    value={userIn.n_StateId_FK} 
                                                                    className="form-control"
                                                                    name="n_StateId_FK"
                                                                    onChange={(e) => handleChangeUserProdLic(userInIndex, index, user, 'n_StateId_FK', e.target.value)}  
                                                                    bsSize="sm"
                                                                >
                                                                    <option value="">Select State</option>
                                                                    {states.map((state) => (
                                                                        <option value={state.n_StateId_PK}>
                                                                        {state.s_StateName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                </td>
                                                                <td>
                                                                <input 
                                                                    bsSize="sm" 
                                                                    type="date" 
                                                                    placeholder="Expiration Date" 
                                                                    className="form-control"
                                                                    value={userIn.d_ExpireDate}
                                                                    name="d_ExpireDate"
                                                                    onChange={(e) => handleChangeUserProdLic(userInIndex, index, user, 'd_ExpireDate', e.target.value)}  
                                                                />
                                                                </td>
                                                                <td className="text-center">
                                                                    <Button 
                                                                        size="sm" 
                                                                        color="danger" 
                                                                        className="btn-pill btn-sm"
                                                                        onClick={(e) => removeProductsLicenseRow(userInIndex, index, user)}
                                                                    >
                                                                        <i className="fa fa-trash d-block pt-1"></i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                        </Table>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    )
                                    })
                                }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center align-items-center">
                                <Button
                                    className="align-items-center"
                                    variant="secondary"
                                    type="submit"
                                    disabled={isDisabled}
                                >
                                    {t("Submit")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) :
                (
                    <section className="property-details-section">
                        <Row className="justify-content-center">
                            <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
                                <div className="hero-section-headings">
                                    <h4 className="">{t("Submission Confirmation")}</h4>
                                    <h3>{t("Thank you for submitting your enrollment form")}!</h3>
                                </div>
                                <div className="text-center">
                                    <Col xs="12" className="">
                                        <h4>{t("Next: your application will be reviewed. You will be notified if further information is needed. Once approved, you will receive a welcome letter")}. </h4>
                                    </Col>
                                </div>
                                <Col xs="12" className="form-btns">
                                    <div className="flood-bts-div">
                                        <Button
                                            id="primary-colored-btn"
                                            variant="primary"
                                            onClick={() => navigate('/login')}
                                        >
                                            {t("Close")}
                                        </Button>{" "}
                                    </div>
                                </Col>
                        </Col> 
                        </Row>
                    </section>
                )
                }
            </div>
        </LoadingOverlay>
        {/* Zip Details Modal */}
        <Modal size="lg" show={show} className="zip-details-modal">
            <Modal.Header id="add-mortage-modal-header">
                <Modal.Title>{t("Select City and County")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="zip-details-modal-body">
                {multipleCity}
            </Modal.Body>
            <Modal.Footer className="">
                <Button
                    className="align-items-right"
                    variant="secondary"
                    onClick={() => setShow(false)}
                >
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default EnrolAgent;