import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  ListItem,
  OrderedList,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";

import { useStarDataContext } from "../StarDataContext";
import { bindStarQuote } from "../apis/bindStarQuote";

const Thanks = () => {
  const {
    starDataObject,
    uniqueID,
    boundData,
    setBoundData,
    saveMortgagee,
    hasMortagee,
  } = useStarDataContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [date] = useState(moment().format("MM/DD/YYYY"));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const quoteNo = starDataObject?.save_quote_response?.data?.policyNo;
        if (!quoteNo) return;
        const reqBody = {
          submission_details: { id: uniqueID },
          mortgagee: hasMortagee
            ? Array.isArray(saveMortgagee) &&
              saveMortgagee && {
                name: saveMortgagee[0]?.bankName ?? "",
                loan_id: saveMortgagee[0]?.loanNumber || "N/A",
                current_address: {
                  street_address: saveMortgagee[0]?.address ?? "",
                  city: saveMortgagee[0]?.city ?? "",
                  zipcode: saveMortgagee[0]?.zipCode ?? "",
                  state: saveMortgagee[0]?.stateCode ?? "",
                },
              }
            : {},
        };
        const res = await bindStarQuote("star", quoteNo, reqBody);
        if (
          res?.core_service_response?.transferData?.Msg ||
          res?.core_service_response?.data?.Msg
        ) {
          toast.success(
            res.core_service_response.transferData.Msg ||
              res.core_service_response.data.Msg === "Policy Rated Successfully"
              ? "Application Bound Successfully"
              : res.core_service_response.data.Msg,
            {
              position: "top-center",
            }
          );
          setBoundData(res.core_service_response);
        } else {
          throw new Error("Failed to bind quote");
        }
      } catch (error) {
        console.error(error);
        toast.error(
          t(
            "An error occurred while binding the quote. Please try again later."
          ),
          { position: "top-center" }
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [starDataObject, uniqueID]);

  return (
    <>
      {loading ? (
        <Stack justify="center" align="center" h="50vh">
          <Spinner size="xl" color="primary.500" />
          <Text mt={2}>Binding Your Quote...</Text>
        </Stack>
      ) : (
        <Container maxW="1200px" minH="55vh">
          <Divider />
          <Flex direction={{ base: "column", md: "row" }} wrap="wrap">
            <HStack p="7" w={{ base: "full", md: "auto" }}>
              <Text fontSize="40px" fontWeight="bold" color="primary.500">
                {t("Congratulations")}
              </Text>
            </HStack>
            <Spacer />
            <VStack p="7" w={{ base: "full", md: "auto" }}>
              <HStack>
                <Text fontSize="18px" fontWeight="medium">
                  {t("Policy Number")}:
                </Text>
                <Text fontSize="18px" color="primary.500" fontWeight="medium">
                  {boundData?.transferData?.Policy_No ||
                    boundData?.data?.Policy_No ||
                    "-"}
                </Text>
              </HStack>
              <HStack>
                <Text fontSize="18px" fontWeight="medium">
                  {t("Bind Date")}:
                </Text>
                <Text fontSize="18px" color="primary.500" fontWeight="medium">
                  {/* {boundData?.transferData?.bindDate
                    ? `${moment(boundData.transferData.bindDate).format(
                        "DD/MM/YYYY"
                      )} | ${boundData.transferData.bindDate.split(" ")[1]}`
                    : ""} */}
                  {boundData?.transferData?.Policy_No ||
                  boundData?.data?.Policy_No
                    ? date
                    : "-"}
                </Text>
              </HStack>
            </VStack>
          </Flex>
          <Container maxW="1000">
            <Box
              padding="4"
              bg="primary.50"
              color="black"
              mb="50px"
              borderRadius="10px"
            >
              <Text fontSize="18px" mb="10px">
                {t(
                  "What Next? Your journey to security does not stop here. Take the next steps to ensure you are fully set up:"
                )}
              </Text>
              <OrderedList pl="30px" lineHeight="30px">
                <ListItem>
                  {t(
                    "Set Up Your Account: Access your documents manage your information and more in our customer portal."
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    "Review Your Policy: Get familiar with your coverage details and benefits."
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    "Inspection of your Property: Our inspections company will contact you within 10 days."
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    "Documentation Submission: Some documents that are required should be submitted."
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    "Welcome Call: Our team will contact you to review your policy and try to find you additional savings."
                  )}
                </ListItem>
              </OrderedList>
              <Text fontSize="18px" mt="10px">
                {t(
                  "Need Assistance? Our friendly support team is here for you. Whether you have questions or need help navigating your new policy, we are just a click away."
                )}
              </Text>
            </Box>
          </Container>
        </Container>
      )}
    </>
  );
};

export default Thanks;
