import Http from "../../../../../Http";

export async function postStarAgency(requestBody) {
  try {
    const url = `${process.env.REACT_APP_POLICY_CORE_URL}/api/v1/quoteEPIC/getAgency`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (err) {
    console.error(err.response?.status, err.response?.data || err.message);
    throw err;
  }
}
