import React from "react";
import { Form } from "react-bootstrap";

export const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  const activeStyle = {};
  activeStyle.padding = ".375rem .75rem";
  activeStyle.border = "1px solid #d9d9d9";
  activeStyle.borderRadius = "2px";
  activeStyle.textDecoration = "none";
  if (active) {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "#64d6cd";
    activeStyle.border = "1px solid #64d6cd";
  } else {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "black";
  }
  if (typeof page === "string") {
    activeStyle.backgroundColor = "white";
    activeStyle.color = "black";
  }
  return (
    <li className="pa" style={{ paddingRight: "8px" }}>
      <a href="#" onClick={handleClick} style={activeStyle}>
        {page}
      </a>
    </li>
  );
};

export const sizePerPageRenderer = ({ options, onSizePerPageChange }) => (
  <>
    <div className="table-show-entries" id="management-reports-entries">
      <span className="table-show-entries-text">Show</span>
      <Form.Select
        className="table-show-entries-select"
        onChange={(e) => onSizePerPageChange(e.target.value)}
      >
        {options.map((option) => {
          return <option value={option.text}>{option.text}</option>;
        })}
      </Form.Select>
      <span className="table-show-entries-text">entries</span>
    </div>
  </>
);
