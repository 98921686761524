import React from 'react'
import {Button, Col, Form, Row} from "react-bootstrap";
import "./index.scss";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Http from "../../../../../../Http";
import Swal from "sweetalert2";
import {Loader, LoadingOverlay} from "react-overlay-loader";
import {selectHiscoxProduct} from "../../../../../../store/selectors/private-flood";
import {useSelector} from "react-redux";

const FloodHiscoxBind = () => {
  const navigation = useNavigate();
  const {t} = useTranslation()
  const hiscoxProduct = useSelector(state=>selectHiscoxProduct(state))
  const [confirmed, setConfirmed] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [isApiSuccess, setApiSuccess] = React.useState(false);
  const goBack = () => {
    navigation('/flood/instantquote/result/macneill')
  }
  const bind = async () => {
    try {
      setProcessing(true);
      setApiSuccess(false);
      const urlParams = new URLSearchParams(window.location.search);
      const quoteId = urlParams.get('quote_id');
      const optionId = urlParams.get('option_id');
      const reportId = urlParams.get('reportId');
      const requestURL = `/api/flood/private-products/macneill/selectPremium?quote_id=${quoteId}&premium_option_id=${optionId}&reportId=${reportId}`;
      const bindResponse = await Http.put(requestURL)
      setProcessing(false);
      setApiSuccess(true);
    } catch (e) {
      setProcessing(false);
      setApiSuccess(false);
      Swal.fire({
        title: "API Error",
        text: t(e.message),
        icon: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          return false;
        }
      });
    }
  }
  return (
    <>
      <LoadingOverlay style={{ width: '100%', height: '100%', backgroundColor: '#F8F8F8' }}>
        <section className="pricing-details-section">
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="12" lg="8" xl="8">
              <h5 className="fw-bold">MacNeill insurance options</h5>
            </Col>
            <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pricing-table-main">
              {
                !processing && isApiSuccess ?
                  (
                    <Row className="pt-5 pb-5 ps-3 pe-3">
                      <Col sm="12" md="8" className="offset-md-2">
                        <div className="pt-5 pb-5 ps-3 pe-3">
                          <div className="submit-section pt-5 pb-5 ps-2 pe-2">
                            <div className="mb-3 d-flex justify-content-center">
                              <img src={hiscoxProduct?.logo} height={50} alt=""/>
                            </div>
                            <div className="text-container">
                              <p className="fw-bold">
                                Thank you for your request to submit to bind coverage with MacNeill Group!
                              </p>
                              <p>You will receive an email response shortly.</p>
                              <p>In the meantime, please call or email us with any questions.</p>
                              <p className="fw-bold">We appreciate your business!</p>
                              <p>800-432-3072 Ext. 6</p>
                              <p>flood@macneillgroup.com</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                  : (
                    <div className="pt-5 pb-5 ps-3 pe-3">
                      <div className="submit-section p-2">
                        <div className="mb-3">
                          <img src={hiscoxProduct?.logo} height={50} alt=""/>
                        </div>
                        <div className="d-flex flex-row justify-lg-content-between">
                          <div className="w-50">
                            <Form.Check
                              type="checkbox"
                              disabled={processing}
                              checked={confirmed}
                              onClick={(e) => setConfirmed(e.target.checked)}
                              label="Producer hereby acknowledges that in order to bind this quote they will be
                              required to complete the MacNeill Group, Inc. standard Producer Agreement
                              (and related appointment package).  Failure to complete and submit the required
                              documents within 30 days of binding will result in the ownership of the
                              renewal/expiration of any issued policy to vest in MacNeill."
                            />
                          </div>
                          <div className="w-50 d-flex flex-row justify-content-end">
                            <div>
                              <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                className="previous-btn"
                                disabled={processing}
                                onClick={() => goBack()}
                              >
                                {t('Back')}
                              </Button>
                              <Button
                                disabled={!confirmed || processing}
                                id="primary-colored-btn"
                                className="primary ms-3"
                                onClick={() => bind()}
                              >
                                {t('Submit And Bind')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              }
            </Col>
          </Row>
        </section>
        <Loader  loading={processing}/>
      </LoadingOverlay>
    </>
  )
}
export default FloodHiscoxBind
