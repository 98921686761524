import {
  Box,
  Card,
  Text
} from "@chakra-ui/react";
import React from "react";
import ApplicationDetails from "./Components/ApplicationDetails";
import StarTabs from "./StarTabs";
import { useTranslation } from "react-i18next";

import { QuoteButtons } from "./Components/QuoteButtons";
import CommercialContext from "./CommercialContext";
import { InfoBox } from "./Components/AdditionalData/InfoBox";

const StarCommercialLayout = () => {
  const { t } = useTranslation();

  return (
    <CommercialContext>
      <Box>
        <Card
          mb={4}
          p={4}
          border={"1px solid white"}
          borderRadius={"2xl"}
          boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
        >
          <Box
            border={"1px solid var(--chakra-colors-neutral-200)"}
            background="var(--chakra-colors-primary-50)"
            p={"0.1rem"}
            borderRadius={"2xl"}
          >
            <Text fontSize={"xl"} mt={3} pl="1.5rem" fontWeight={600}>
              {t("Commercial Program")}
            </Text>
          </Box>
          <Box p={2} mt={4}>
            <ApplicationDetails />
          </Box>
          <InfoBox />
          <Box p={2} mt={4}>
            <QuoteButtons />
          </Box>
          <Box p={2} mt={4}>
            <StarTabs />
          </Box>
        </Card>
      </Box>
    </CommercialContext>
  );
};

export default StarCommercialLayout;
