import { Box, Card, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Currency } from "../../common/Currency";
import { MdArrowBackIos } from "react-icons/md";

const ClientInfo = ({ displayName, address }) => (
  <Flex direction="column" gap={0} pt={2} pr={2} textAlign="right">
    <Text my={0} fontWeight="medium" color="primary.500">
      {displayName}
    </Text>
    <Text my={0} fontWeight="medium">
      {address}
    </Text>
  </Flex>
);

const QuoteAmount = ({ amount, amoutFooterText }) => {
  const bgColor = useColorModeValue("primary.500", "primary.200");
  return (
    <Box pl={2} pt={1} pb={2} pr={4} mb={4}>
      <Flex
        px={4}
        py={0.5}
        align="center"
        direction="column"
        bg={bgColor}
        borderRadius="2xl"
        color="white"
      >
        <Text my={1} fontSize="xl">
          <Currency amount={amount} />
        </Text>
        <Text my={1} fontSize="xs">
          {amoutFooterText}
        </Text>
      </Flex>
    </Box>
  );
};

export const QuotationCardLayout = ({
  children,
  quoteId = "TMPXXXXXXXXXXXX",
  displayName = "-",
  address = "-",
  amount = 0,
  amoutFooterText = "",
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Card
      {...props}
      borderRadius="3xl"
      boxShadow={"0 0 6px rgba(0, 0, 0, 0.3)"}
    >
      <Flex
        fontSize={{ base: "12px", md: "16px", lg: "20px" }}
        mt={4}
        mr={2}
        align="center"
        justifyContent={"space-between"}
      >
        <Flex gap={2} alignItems={"center"}>
          <Box pl={10} pb={4}>
            <MdArrowBackIos size={18} />
          </Box>
          <Text fontWeight="medium">{t("quote_id")}</Text>
          <Text color="primary.500" fontWeight="medium">
            {quoteId}
          </Text>
        </Flex>
        <Flex gap={2}>
          <ClientInfo displayName={displayName} address={address} />
          <QuoteAmount
            amount={amount}
            amoutFooterText={amoutFooterText || t("ESTIMATED ANNUAL QUOTE")}
          />
        </Flex>
      </Flex>
      {children}
    </Card>
  );
};
