import React, { useEffect, useState } from "react";
import { Row, Col, Table, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { toast } from 'react-toastify';

const Coverage = (props) => {
    const { floodSystemDD, cvgDetails, occupancyType, RC, NOU, preFirmSubsidyEligible, buildingType, handleChange } = props;
    const { t } = useTranslation();

    const [newBldgCvg, setNewBldgCvg] = useState(0);
    const [newBldgDed, setNewBldgDed] = useState('');
    const [newCntCvg, setNewCntCvg] = useState(0);
    const [newCntDed, setNewCntDed] = useState('');
    const [bldgCvg, setBldgCvg] = useState(0);
    const [bldgDed, setBldgDed] = useState('');
    const [cntCvg, setCntCvg] = useState(0);
    const [cntDed, setCntDed] = useState('');
    const [isLimitExceed, setIsLimitExceed] = useState(false);
    const [deductible, setDeductible] = useState([]);
    const [bDedDD, setBDedDD] = useState([]);
    const [cDedDD, setCDedDD] = useState([]);
    const [maxBCvg, setMaxBCvg] = useState(0);
    const [maxCCvg, setMaxCCvg] = useState(0);
    const [disableRemoveBCvg, setDisableRemoveBCvg] = useState(false);
    const [disableRemoveCCvg, setDisableRemoveCCvg] = useState(false);

    // set DD
    useEffect(() => {
        if (Object.keys(floodSystemDD).length > 0) {
            setDeductible(floodSystemDD.deductible);
        }
    }, [floodSystemDD])

    useEffect(() => {
        // get cvg info
        if (cvgDetails && Object.keys(cvgDetails).length > 0) {
            var basicCvgData = cvgDetails;
            if (basicCvgData.length > 0) {
                var buildingCvg = basicCvgData.filter((x) => x.s_CoverageCode === 'FLDBLDCVGAMT');
                if (buildingCvg.length > 0) {
                    setBldgCvg(buildingCvg[0].n_InsuredValue || 0);
                    setBldgDed(buildingCvg[0].s_PrDiscountCode || '');
                }
                var contentCvg = basicCvgData.filter((x) => x.s_CoverageCode === 'FLDCONTCVGAMT');
                if (contentCvg.length > 0) {
                    setCntCvg(contentCvg[0].n_InsuredValue || 0);
                    setCntDed(contentCvg[0].s_PrDiscountCode || '');
                }
            }
        }
    }, [cvgDetails]);

    // error
    useEffect(() => {
        if (isLimitExceed === true) {
            toast.error(t('Coverage Max Limit Exceed!'));
        }
    }, [isLimitExceed]);

    // error buildingType
    useEffect(() => {
        // get Max allowed coverage
        validateCoverage();
        // “Please select the value that best describes the building” does not = Residential condo unit - residential building then Building coverage must = 0
        if ((occupancyType === 'RESIDENTIALUNIT20' && (buildingType && buildingType !== 'RESIDENTIALCONDOUNITRB20')) || occupancyType === 'NONRESIDENTIALUNIT20') {
            handleStateChange(0, 'bldgIsuredValue');
            handleStateChange(0, 'newBldgDed');
        }
    }, [occupancyType, buildingType]);

    // set dropdown
    useEffect(() => {
        var dd = [];
        if (Object.keys(deductible).length > 0) {
            if ((newBldgCvg > 0 && newBldgCvg <= 100000) || bldgCvg > 0) {
                // For Occupancy Type 1-4 Family
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
            }
            if ((newBldgCvg > 0 && newBldgCvg > 100000) || bldgCvg > 0) {
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED1250', 25000].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
            }
            setBDedDD([{ 's_DiscountCode': 0, 's_ScreenName': "$0 Deductible" }, ...dd]);
            if (dd.length > 0 && (bldgDed && bldgDed.length > 0)) {
                var currentDed = bldgDed;
                currentDed = Number(currentDed.replace(/FLDDED/g, '')) || 0
                var dd = dd.filter((item) => {
                    return Number(item.s_DiscountCode.replace(/FLDDED/g, '')) > currentDed
                }) || [];
            }
            setBDedDD(dd);
            dd = [];
            // content
            if ((newBldgCvg > 0 && newBldgCvg <= 100000) || bldgCvg > 0) {
                // For Occupancy Type 1-4 Family 
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }
            if ((newBldgCvg > 0 && newBldgCvg > 100000) || bldgCvg > 0) {
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED1250', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }
            if (newBldgCvg == 0) {
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                } else { // For Any Occupancy
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }

            setCDedDD([{ 's_DiscountCode': 0, 's_ScreenName': "$0 Deductible" }, ...dd]);
            if (dd.length > 0 && (cntDed && cntDed.length > 0)) {
                var currentDed = cntDed;
                currentDed = Number(currentDed.replace(/FLDDED/g, '')) || 0
                var dd = dd.filter((item) => {
                    return Number(item.s_DiscountCode.replace(/FLDDED/g, '')) > currentDed
                }) || [];
            }
            setCDedDD(dd);
            // If preFirmSubsidyEligible is true then all occupancy types may have 1500 deducible, in addition to others, if building coverage < or =  100,000. 
            if (preFirmSubsidyEligible && dd.some(x => x.s_DiscountCode === 'FLDDED1250') && newBldgCvg < 100000) {
                // If 1250 Present Then Find Index and Replace with 1500
                var index = dd.findIndex(x => x.s_DiscountCode === 'FLDDED1250')
                dd[index] = deductible.filter(x => ['FLDDED1500'].includes(x.s_DiscountCode))[0]
            }
            setCDedDD(dd);
        }
    }, [newCntCvg, newBldgCvg, occupancyType, deductible])

    const handleStateChange = (value, state) => {
        if (!value) {
            value = 0;
        }
        switch (state) {
            case 'bldgIsuredValue':
                setNewBldgCvg(value);
                handleChange("buildingCoverage", bldgCvg, value, "Coverage");
                // ODS-1025 Deductible not removing when coverage is removed on Endorsements.
                if(value == 0) {
                    handleStateChange("", "newBldgDed");
                }
                break;
            case 'cntIsuredValue':
                setNewCntCvg(value);
                handleChange("contentCoverage", cntCvg, value, "Coverage");
                // ODS-1025 Deductible not removing when coverage is removed on Endorsements.
                if(value == 0) {
                    handleStateChange("", "newCntDed");
                }
                break;
            case 'newBldgDed':
                setNewBldgDed(value);
                handleChange("buildingDeductible", bldgDed, value, "Coverage");
                break;
            case 'newCntDed':
                setNewCntDed(value);
                handleChange("contentDeductible", cntDed, value, "Coverage");
                break;
        }
    }

    // Validate Coverage Max Amount
    const validateCoverage = (cvgType) => {
        cvgType === 'BUILDING' && setNewBldgCvg(1000 * Math.ceil(newBldgCvg / 1000))
        cvgType === 'CONTENT' && setNewCntCvg(1000 * Math.ceil(newCntCvg / 1000))
        // Building coverage maxes out at 250k and contents at 100k for residential
        if (['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
            setMaxBCvg(250000);
            setMaxCCvg(100000);
            if (cvgType === 'BUILDING' && newBldgCvg !== '' && newBldgCvg > 250000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && newCntCvg !== '' && newCntCvg > 100000) {
                setIsLimitExceed(true)
            }
        }

        // Building coverage maxes out at 250k and contents at 100k for residential
        if (['OTHERRESIDENTIAL20'].includes(occupancyType)) {
            setMaxBCvg(500000);
            setMaxCCvg(100000);
            if (cvgType === 'BUILDING' && newBldgCvg !== '' && newBldgCvg > 500000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && newCntCvg !== '' && newCntCvg > 100000) {
                setIsLimitExceed(true)
            }
        }

        // For a non-residential, it is 500k and 500k.
        if (['NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
            setMaxBCvg(500000);
            setMaxCCvg(500000);
            if (cvgType === 'BUILDING' && newBldgCvg !== '' && newBldgCvg > 500000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && newCntCvg !== '' && newCntCvg > 500000) {
                setIsLimitExceed(true)
            }
        }

        // Coverage limit max for building if RCBAP - can be the RCE value or 250k * number of units. wich ever is less. Contents max is 100k
        if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
            var val1 = RC, val2 = (250000 * NOU);
            setMaxBCvg(val2);
            setMaxCCvg(100000);
            if (cvgType === 'BUILDING' && newBldgCvg !== '' && ((val1 < val2 && newBldgCvg > val1) || (val2 < val1 && newBldgCvg > val2))) {
                setIsLimitExceed(true);
            }
            if (cvgType === 'CONTENT' && newCntCvg !== '' && newCntCvg > 100000) {
                setIsLimitExceed(true)
            }
        }
    }

    return (
        <>
            {
                (disableRemoveBCvg || disableRemoveCCvg) && (
                    <>
                        <Col md='3' hidden={!disableRemoveBCvg}><Alert variant="danger" style={{ padding: "1px" }}><FaExclamationTriangle />&nbsp;&nbsp;{t('Building coverage removed')}</Alert></Col>
                        <Col md='3' hidden={!disableRemoveCCvg}><Alert variant="danger" style={{ padding: "1px" }}><FaExclamationTriangle />&nbsp;&nbsp;{t('Content coverage removed')}</Alert></Col>
                    </>
                )
            }
            <Row className="justify-content-left">
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    <b>{t('Coverage')}</b>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b>{t('Current')}</b>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b>{t('New')}</b>
                </Col>
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    <b>{t('Deductible')}</b>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b>{t('Current')}</b>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b>{t('New')}</b>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2"></Col>
            </Row>
            <Row className="justify-content-left mt-4">
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    {t('Building')}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    {"$" + bldgCvg}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <NumberFormat
                        className="dashboard-group-input-flood"
                        style={{ width: '100%', textAlign: "right", backgroundColor: (maxBCvg === bldgCvg || disableRemoveBCvg) ? '#E9ECEF' : '#FFFFFF' }}
                        type="text"
                        displayType={'input'}
                        prefix={'$'}
                        thousandSeparator={true}
                        allowNegative={false}
                        name="bldgIsuredValue"
                        value={newBldgCvg || 0}
                        min={0}
                        onValueChange={(value) => handleStateChange(value.floatValue, 'bldgIsuredValue')}
                        onBlur={() => validateCoverage('BUILDING')}
                        autoComplete="off"
                        disabled={maxBCvg === bldgCvg || disableRemoveBCvg}
                    /><br />
                    <p style={{ textAlign: "right" }}>{"Max : $" + maxBCvg}</p>
                </Col>
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    {t('Building')}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <Form.Select
                        className="table-show-entries-select"
                        id="select-ym-option"
                        name="buildingDeductible"
                        style={{ width: "200px" }}
                        title={t('Building Deductible')}
                        value={bldgDed}
                        disabled
                    >
                        <option value={''}>{t('Select')}</option>
                        {
                            deductible.length > 0 && deductible.map((v, i) => {
                                return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                            })
                        }
                    </Form.Select>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <Form.Select
                        className="table-show-entries-select"
                        id="select-ym-option"
                        name="buildingDeductible"
                        title={t('Building Deductible')}
                        value={newBldgDed}
                        onChange={(e) => handleStateChange(e.currentTarget.value, 'newBldgDed')}
                        disabled={disableRemoveBCvg}
                    >
                        <option value={''}>{t('Select')}</option>
                        {
                            bDedDD.length > 0 && bDedDD.map((v, i) => {
                                return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                            })
                        }
                    </Form.Select>
                </Col>
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    <Button
                        variant="link"
                        id="search-quote-btn"
                        disabled={disableRemoveBCvg}
                        onClick={() => {
                            handleStateChange(0, 'bldgIsuredValue');
                            setDisableRemoveBCvg(true);
                        }}
                    >
                        {t('Remove')}
                    </Button>
                </Col>
            </Row>
            <Row className="justify-content-left mt-3">
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    {t('Content')}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    {"$" + cntCvg}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <NumberFormat
                        className="dashboard-group-input-flood"
                        style={{ width: '100%', textAlign: "right", backgroundColor: (maxCCvg === cntCvg || disableRemoveCCvg) ? '#E9ECEF' : '#FFFFFF' }}
                        type="text"
                        displayType={'input'}
                        prefix={'$'}
                        thousandSeparator={true}
                        allowNegative={false}
                        name="cntIsuredValue"
                        value={newCntCvg || 0}
                        min={0}
                        onValueChange={(value) => handleStateChange(value.floatValue, 'cntIsuredValue')}
                        onBlur={() => validateCoverage('CONTENT')}
                        autoComplete="off"
                        disabled={maxCCvg === cntCvg || disableRemoveCCvg}
                    /><br />
                    <p style={{ textAlign: "right" }}>{"Max : $" + maxCCvg}</p>
                </Col>
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    {t('Content')}
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <Form.Select
                        className="table-show-entries-select"
                        id="select-ym-option"
                        name="contentDeductible"
                        style={{ width: "200px" }}
                        title={t('Content Deductible')}
                        value={cntDed}
                        disabled
                    >
                        <option value={''}>{t('Select')}</option>
                        {
                            deductible.length > 0 && deductible.map((v, i) => {
                                return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                            })
                        }
                    </Form.Select>
                </Col>
                <Col xs="12" sm="12" md="2" lg="2" xl="2">
                    <Form.Select
                        className="table-show-entries-select"
                        id="select-ym-option"
                        name="contentDeductible"
                        title={t('Content Deductible')}
                        value={newCntDed}
                        onChange={(e) => handleStateChange(e.currentTarget.value, 'newCntDed')}
                        disabled={disableRemoveCCvg}
                    >
                        <option value={''}>{t('Select')}</option>
                        {
                            cDedDD.length > 0 && cDedDD.map((v, i) => {
                                return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                            })
                        }
                    </Form.Select>
                </Col>
                <Col xs="12" sm="12" md="1" lg="1" xl="1">
                    <Button
                        variant="link"
                        id="search-quote-btn"
                        disabled={disableRemoveCCvg}
                        onClick={() => {
                            handleStateChange(0, 'cntIsuredValue');
                            setDisableRemoveCCvg(true);
                        }}
                    >
                        {t('Remove')}
                    </Button>
                </Col>
            </Row>
            <br />
            <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA', fontStyle: 'italic' }} >If you would like to decrease coverage, please enter your request in the Other Endorsements section</p>
        </>
    )
}

export default Coverage;
