import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Box,
  Text,
  Flex,
} from "@chakra-ui/react";
import { MdDeleteForever, MdCancel } from "react-icons/md";
import { FaExclamationTriangle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const EnhancedAlertDialog = ({
  isOpen,
  onClose,
  onDelete,
  cancelRef,
  address,
}) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      isCentered
      size={"lg"}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="xl" boxShadow="lg">
          <AlertDialogHeader
            borderRadius="xl"
            bg={"primary.50"}
            fontSize="lg"
            fontWeight="bold"
            display="flex"
            color="red.600"
            justifyContent={"center"}
            borderBottom="1px solid"
            borderColor="gray.200"
            py={4}
          >
            <MdDeleteForever size={24} />
            <Text ml={2}>{t("Delete Building Details")}</Text>
          </AlertDialogHeader>

          <AlertDialogBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={8}
            textAlign="center"
            color="gray.600"
            fontSize="md"
          >
            <Flex direction="column" alignItems="center">
              <Text
                fontWeight="bold"
                borderBottom={"1px solid"}
                borderColor={"primary"}
              >
                {address || ""}
              </Text>
              <FaExclamationTriangle size={40} color="orange.400" />
              <Box mt={4}>
                {t(
                  "Are you sure you want to delete this building detail? This action cannot be undone."
                )}
              </Box>
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter
            justifyContent="center"
            borderTop="1px solid"
            borderColor="gray.200"
            py={4}
          >
            <Button
              borderRadius={"xl"}
              ref={cancelRef}
              onClick={onClose}
              leftIcon={<MdCancel />}
              variant="outline"
              colorScheme="gray"
              mr={3}
            >
              {t("Cancel")}
            </Button>
            <Button
              borderRadius={"xl"}
              colorScheme="red"
              onClick={onDelete}
              leftIcon={<MdDeleteForever />}
            >
              {t("Delete")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default EnhancedAlertDialog;
