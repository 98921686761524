import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Alert,
  Table,
} from "react-bootstrap";
import { cancelPolicyHiscox } from "./api/CancelPolicyHiscox";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { saveUpdates } from "./api/saveUpdates";
import { useSelector } from "react-redux";
import { FaBan } from "react-icons/fa";
export default function CancelPolicy({
  productType,
  commercialProductType,
  quoteId,
}) {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const store = useSelector((state) => state);
  const [cancellationData, setCancellationData] = useState(null);
  const [inputFields, setInputFields] = useState({
    cancellationDate: getSevenDaysLaterDate(),
  });

  function getSevenDaysLaterDate() {
    const today = new Date();
    const sevenDaysLater = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    return sevenDaysLater.toISOString().split("T")[0] || "";
  }
  const [premiumCalculation, setPremiumCalculation] = useState("");

  const calculationValues = [
    {
      value: "",
      label: "",
    },
    {
      value: "Flat",
      label: "Flat",
    },
    {
      value: "ProRate",
      label: "Pro Rate",
    },
    {
      value: "ShortRate",
      label: "Short Rate",
    },
  ];

  useEffect(() => {
    if (!showCancelModal) {
      setCancellationData(null);
    }
  }, [showCancelModal]);

  const handleSubmit = async () => {
    setLoading(true);
    const requestBody = {
      productType,
      commercialProductType,
      cancellationDate: inputFields.cancellationDate,
      premiumCalculation,
    };
    const productID = quoteId || 0;

    try {
      const res = await cancelPolicyHiscox({ requestBody, productID });
      handleErrors(res);
      await handleSuccessResponse(res, inputFields, premiumCalculation, store);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleErrors = (res) => {
    if (res?.status === false && Array.isArray(res?.errors)) {
      res.errors.forEach((error) => toast.error(error));
    }
  };
  const handleSuccessResponse = async (
    res,
    inputFields,
    premiumCalculation,
    store
  ) => {
    if (res?.status === true && res?.data) {
      setCancellationData(res.data);
      setShowCancelModal(true);
      let returnPremium = res?.data?.returnPremium;
      const payload = {
        poTransPK: store?.coreSystem?.n_POTransactionFK,
        transactionEffectiveDate: inputFields?.cancellationDate,
        premiumCalculation,
        returnPremium,
      };

      try {
        setLoading(true);
        await saveUpdates({ payload, transType: "cancel" });
      } catch (err) {
        console.error("Error saving updates:", err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmCancel = () => {
    setShowConfirmModal(false);
    setShowCancelModal(true);
  };

  return (
    <div>
      <Button
        id="common-btn"
        variant="primary"
        size="sm"
        style={{ padding: "4px  6px" }}
        onClick={handleCancel}
      >
        <FaBan size={18} /> &nbsp; {t("Cancel Policy")}
      </Button>
      <Modal
        centered
        size="lg"
        show={showCancelModal}
        onHide={loading ? () => {} : () => setShowCancelModal(false)}
        backdrop="static" // Prevent closing on click outside the modal
        keyboard={false} // Prevent closing with the Escape key
      >
        <Modal.Header id="common-btn">
          <Modal.Title className="d-flex align-items-center gap-3 text-white">
            Cancel Policy
            {loading && (
              <Spinner animation="border" variant="white" size="md" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cancellationData ? (
            <>
              <Alert variant="success">
                <Alert.Heading>
                  {t("The policy has been successfully canceled")}.
                </Alert.Heading>
              </Alert>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Hiscox ID</td>
                    <td>{cancellationData.hiscoxId || "-"}</td>
                  </tr>
                  <tr>
                    <td>Cancellation Date</td>
                    <td>
                      {cancellationData.cancellationDate
                        ? new Date(
                            cancellationData.cancellationDate
                          ).toLocaleDateString()
                        : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td>Return Premium</td>
                    <td>
                      {cancellationData?.returnPremium
                        ? cancellationData.returnPremium.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <Form>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Cancellation Date
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="date"
                    value={inputFields.cancellationDate}
                    onChange={(e) =>
                      setInputFields({
                        ...inputFields,
                        cancellationDate: e.target.value,
                      })
                    }
                  />
                  {inputFields.cancellationDate === "" && (
                    <Form.Text className="text-danger">
                      Cancellation date is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}>
                  Premium Calculation
                </Form.Label>
                <Col sm={8}>
                  <Form.Select
                    value={premiumCalculation}
                    onChange={(e) => setPremiumCalculation(e.target.value)}
                  >
                    {calculationValues.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                  {premiumCalculation === "" && (
                    <Form.Text className="text-danger">
                      Rating is required
                    </Form.Text>
                  )}
                </Col>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            id="common-btn"
            variant="primary"
            style={{ padding: "9px  6px" }}
            onClick={() => {
              setShowCancelModal(false);
              {
                cancellationData && window.location.reload();
              }
            }}
          >
            {t("Close")}
          </Button>

          {cancellationData === null && (
            <Button
              id="common-btn"
              style={{ padding: "9px  6px" }}
              variant="primary"
              onClick={handleSubmit}
              disabled={
                loading ||
                inputFields.cancellationDate === "" ||
                premiumCalculation === ""
              }
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" /> {t("Cancelling")}...
                </>
              ) : (
                "Cancel Policy"
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <Modal.Header closeButton id="common-modal-header" className="px-4">
          <Modal.Title className="d-flex align-items-center gap-2 text-white">
            {t("Confirm Cancellation")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Are you sure you want to cancel the policy?")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-btn"
            variant="secondary"
            style={{ padding: "5px  15px" }}
            onClick={() => setShowConfirmModal(false)}
          >
            {t("No")}
          </Button>
          <Button
            id="common-btn"
            style={{ padding: "5px  15px" }}
            onClick={handleConfirmCancel}
          >
            {t("Yes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
