import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ResidentialDataDD } from "../../../data/ResidentialData";
import { CommercialDataDD } from "../../../data/CommercialData";
import PriorLossesTable from "./PriorLosses/PriorLosses";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import "../../style.css";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Switch,
  Text,
  GridItem,
  Table,
  Thead,
  Button,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import MortgageModal from "./MortageeModal";
import AdditionalInsuredModal from "./AdditonalInsuredModal";
import MailingAddress from "./MailingAddress";
import { CustomCurrencyInput } from "../../../../../common/CustomCurrencyInput";

export default function PropertyOtherDetails({
  quoteDetailsData,
  bindDetails,
  setBindDetails,
  edit,
  setSaveMortgagee,
  setSaveAdditionalInsured,
  setShowUpdatePremiumModal,
  productId,
  loading,
  setLoading,
  setSaveMailingAddress,
  updateQuote,
}) {
  const [financialDetails, setFinancialDetails] = useState({ priorLosses: [] });
  const [isTenanted, setIsTenanted] = useState(false);
  const [limits, setLimits] = useState([]);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMailingAddressModalOpen, setIsMailingAddressModalOpen] =
    useState(false);
  const [hasMortgagee, setHasMortgagee] = useState(false);
  const [isInsuredOpen, setIsInsuredOpen] = useState(false);
  const [hasInsured, setHasInsured] = useState(false);
  const [isMailingAdressSwitchChecked, setIsMailingAddressSwitchChecked] =
    useState(quoteDetailsData?.data?.isMailingAddressSame);
  useEffect(() => {
    const mergedQuoteDetails = {
      ...quoteDetailsData?.data,
      residentialLimits: limits,
      elevationHeight: quoteDetailsData?.data?.elevationHeight || 0,
      squareFootage: Number(quoteDetailsData?.data?.squareFootage) || 0,
      yearBuilt: Number(quoteDetailsData?.data?.yearBuilt) || 0,
      zip: Number(quoteDetailsData?.data?.zip) || 0,
      buildingOverWaterType:
        quoteDetailsData?.hiscox_api_data?.data?.buildingOverWaterType || "No",
      priorLosses: financialDetails?.priorLosses || [],
      additionalInsuredData:
        quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data ||
        [],
      mortgageeData:
        quoteDetailsData?.get_quote_response?.data?.mortgagees?.data || [],
      doesPolicyHaveAdditionalInsured: hasInsured === true ? "YES" : "NO",
      doesPolicyHaveMortgagee: hasMortgagee === true ? "YES" : "NO",
    };

    setBindDetails(mergedQuoteDetails);
    if (
      Array.isArray(
        quoteDetailsData?.get_quote_response?.data?.mortgagees?.data
      ) &&
      quoteDetailsData?.get_quote_response?.data?.mortgagees?.data &&
      quoteDetailsData?.get_quote_response?.data?.mortgagees?.data.length > 0
    ) {
      setHasMortgagee(true);
    }
    if (
      Array.isArray(
        quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data
      ) &&
      quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data &&
      quoteDetailsData?.get_quote_response?.data?.additionalInsured?.data
        ?.length > 0
    ) {
      setHasInsured(true);
    }
  }, [quoteDetailsData, financialDetails?.priorLosses, limits]);

  useEffect(() => {
    setFinancialDetails({
      priorLosses: quoteDetailsData?.data?.priorLosses || [],
    });
    if (quoteDetailsData?.data?.commercialProductType === "Tenanted") {
      setIsTenanted(true);
    }
    setLimits(quoteDetailsData?.data?.residentialLimits || []);
  }, [quoteDetailsData]);

  const handleLimitChange = (index, field, value) => {
    const updatedLimits = [...limits];
    updatedLimits[index][field] = Number(value) || 0;
    setLimits(updatedLimits);
  };

  const handleAddLimit = () => {
    setLimits([...limits, getInitialLimitValues()]);
  };

  const handleRemoveLimit = (index) => {
    const updatedLimits = [...limits];
    updatedLimits.splice(index, 1);
    setLimits(updatedLimits);
  };

  const getInitialLimitValues = () => {
    if (isTenanted) {
      return { improvementsAndBetterments: 0, contents: 0 };
    } else if (bindDetails?.productType === "Commercial") {
      return { building: 0, contents: 0 };
    } else {
      return { building: 0, contents: 0 };
    }
  };
  useEffect(() => {
    setIsMailingAddressSwitchChecked(
      quoteDetailsData?.data?.isMailingAddressSame
    );
  }, [quoteDetailsData?.data?.isMailingAddressSame, updateQuote]);

  const CurrencyStyles = {
    marginTop: "0.2rem",
    textAlign: "right",
    border: "2px solid",
    padding: "0.62rem",
    borderRadius: "12px",
    borderColor: "var(--chakra-colors-gray-300)",
    width: "100%",
    height: "51px",
    backgroundColor: edit ? "white" : "var(--chakra-colors-neutral-50)",
  };

  return (
    <>
      <Form>
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={6}
          p={6}
        >
          <GridItem>
            <FormControl>
              <FormLabel>{t("Construction Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.constructionType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    constructionType: e.target.value,
                  });
                }}
              >
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.ConstructionType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.ConstructionType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Occupancy Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.occupancyType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    occupancyType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.OccupancyType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.OccupancyType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Year Built")}:</FormLabel>
              <Input
                className="inputField"
                border={"1px solid gray"}
                type="number"
                value={bindDetails?.yearBuilt}
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    yearBuilt: Number(e.target.value),
                  });
                }}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Foundation Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.foundationType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,

                    foundationType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.FoundationType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.FoundationType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Additional Foundation Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.additionalFoundationType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,

                    additionalFoundationType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.AdditionalFoundationType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )
                  )}
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.AdditionalFoundationType?.map(
                    (item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )
                  )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Basement Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.basementType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    basementType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.BasementType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.BasementType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Number of Stories")}:</FormLabel>
              <Input
                className="inputField"
                border={"1px solid gray"}
                type="number"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.numberOfStories}
                onChange={(e) =>
                  setBindDetails({
                    ...bindDetails,
                    numberOfStories: Number(e.target.value),
                  })
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Square Footage")}:</FormLabel>
              <Input
                className="inputField"
                border={"1px solid gray"}
                type="number"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.squareFootage}
                onChange={(e) =>
                  setBindDetails({
                    ...bindDetails,
                    squareFootage: Number(e.target.value),
                  })
                }
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Elevation Height")}:</FormLabel>
              <Input
                className="inputField"
                border={"1px solid"}
                type="number"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.elevationHeight}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    elevationHeight: Number(e.target.value),
                  });
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Building Over Water Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.buildingOverWaterType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    buildingOverWaterType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {ResidentialDataDD?.BuildingOverWaterType?.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Contents Cost Value Type")}:</FormLabel>

              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                value={bindDetails?.contentsCostValueType}
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                onChange={(e) =>
                  setBindDetails({
                    ...bindDetails,
                    contentsCostValueType: e.target.value,
                  })
                }
              >
                <option value="">{t("Select")}</option>
                {ResidentialDataDD?.ContentsCostValueType?.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Attached Garage Type")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.attachedGarageType}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    attachedGarageType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Select")}</option>
                {bindDetails?.productType === "Commercial" &&
                  CommercialDataDD?.AttachedGarageType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {" "}
                      {item.label}{" "}
                    </option>
                  ))}
                {bindDetails?.productType === "Residential" &&
                  ResidentialDataDD?.AttachedGarageType?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          {bindDetails?.commercialProductType !== "Tenanted" && (
            <GridItem>
              <FormControl
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel>{t("Building Replacement Cost")}: </FormLabel>
                <CustomCurrencyInput
                  style={CurrencyStyles}
                  disabled={!edit}
                  bgColor={edit ? "white" : "neutral.50"}
                  _disabled={{ color: "black", opacity: 0.9 }}
                  value={bindDetails?.buildingReplacementCostValues}
                  onChange={(e) => {
                    setBindDetails({
                      ...bindDetails,
                      buildingReplacementCostValues: Number(
                        e.target.value.replace(/[^\d.]/g, "")
                      ),
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          )}
          {bindDetails?.productType === "Commercial" && isTenanted && (
            <GridItem>
              <FormControl
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel>{t("Improvements & Betterment")} (RCV): </FormLabel>
                <CustomCurrencyInput
                  style={CurrencyStyles}
                  disabled={!edit}
                  bgColor={edit ? "white" : "neutral.50"}
                  _disabled={{ color: "black", opacity: 0.9 }}
                  value={
                    bindDetails?.improvementsAndBettermentsReplacementCostValues
                  }
                  onChange={(e) => {
                    setBindDetails({
                      ...bindDetails,
                      improvementsAndBettermentsReplacementCostValues: Number(
                        e.target.value.replace(/[^\d.]/g, "")
                      ),
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          )}

          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Contents Replacement Cost")}:</FormLabel>
              <CustomCurrencyInput
                style={CurrencyStyles}
                disabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.contentsReplacementCostValues}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    contentsReplacementCostValues: Number(
                      e.target.value.replace(/[^\d.]/g, "")
                    ),
                  });
                }}
              />
            </FormControl>
          </GridItem>
          {bindDetails?.productType === "Commercial" && (
            <GridItem>
              <FormControl
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel>
                  {t("Business Income & Extra Annual Cost")}:{" "}
                </FormLabel>
                <CustomCurrencyInput
                  style={CurrencyStyles}
                  disabled={!edit}
                  bgColor={edit ? "white" : "neutral.50"}
                  _disabled={{ color: "black", opacity: 0.9 }}
                  value={bindDetails?.businessIncomeAndExtraExpenseAnnualValue}
                  onChange={(e) => {
                    setBindDetails({
                      ...bindDetails,
                      businessIncomeAndExtraExpenseAnnualValue: Number(
                        e.target.value.replace(/[^\d.]/g, "")
                      ),
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          )}
          {bindDetails?.productType === "Commercial" && isTenanted && (
            <GridItem>
              <FormControl
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel>{t("Stock Replacement Cost")}: </FormLabel>
                <CustomCurrencyInput
                  style={CurrencyStyles}
                  disabled={!edit}
                  bgColor={edit ? "white" : "neutral.50"}
                  _disabled={{ color: "black", opacity: 0.9 }}
                  value={bindDetails?.stockReplacementCostValue}
                  onChange={(e) => {
                    setBindDetails({
                      ...bindDetails,
                      stockReplacementCostValue: Number(
                        e.target.value.replace(/[^\d.]/g, "")
                      ),
                    });
                  }}
                />
              </FormControl>
            </GridItem>
          )}
          <GridItem>
            <FormControl
              className="search-quote-form-group"
              controlId="formBasicEmail"
            >
              <FormLabel>{t("Flood Zone")}:</FormLabel>
              <Select
                className="selectField"
                pt={0.5}
                size={"lg"}
                type="text"
                isDisabled={!edit}
                bgColor={edit ? "white" : "neutral.50"}
                _disabled={{ color: "black", opacity: 0.9 }}
                value={bindDetails?.floodZone}
                onChange={(e) => {
                  setBindDetails({
                    ...bindDetails,
                    floodZone: e.target.value,
                  });
                }}
              >
                <option value=""></option>
                {CommercialDataDD?.FloodZoneGroups?.map((item, index) => (
                  <option key={index} value={item.s_FloodZone}>
                    {" "}
                    {item.s_FloodZone}{" "}
                  </option>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem
            style={{
              gridGridItemumn: "span 1",
            }}
          >
            <h6>{t("Limits")}:</h6>
            <Table border={"1px solid gray.300"}>
              <Thead>
                <Tr className="text-center align-middle">
                  <Th>
                    {isTenanted ? "Improvements & Betterments" : "Building"}
                  </Th>
                  <Th>{t("Contents")}</Th>
                  <Th>{t("Action")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {limits.map((limit, index) => (
                  <Tr key={index}>
                    {isTenanted ? (
                      <Td>
                        <CustomCurrencyInput
                          style={CurrencyStyles}
                          disabled={!edit}
                          bgColor={edit ? "white" : "neutral.50"}
                          _disabled={{ color: "black", opacity: 0.9 }}
                          value={limit.improvementsAndBetterments}
                          onChange={(e) =>
                            handleLimitChange(
                              index,
                              "improvementsAndBetterments",
                              Number(e.target.value.replace(/[^\d.]/g, ""))
                            )
                          }
                        />
                      </Td>
                    ) : (
                      <Td>
                        <CustomCurrencyInput
                          style={CurrencyStyles}
                          disabled={!edit}
                          bgColor={edit ? "white" : "neutral.50"}
                          _disabled={{ color: "black", opacity: 0.9 }}
                          value={limit.building}
                          onChange={(e) =>
                            handleLimitChange(
                              index,
                              "building",
                              Number(e.target.value.replace(/[^\d.]/g, ""))
                            )
                          }
                        />
                      </Td>
                    )}
                    <Td>
                      <CustomCurrencyInput
                        style={CurrencyStyles}
                        disabled={!edit}
                        bgColor={edit ? "white" : "neutral.50"}
                        _disabled={{ color: "black", opacity: 0.9 }}
                        value={limit.contents}
                        onChange={(e) =>
                          handleLimitChange(
                            index,
                            "contents",
                            Number(e.target.value.replace(/[^\d.]/g, ""))
                          )
                        }
                      />
                    </Td>

                    <Td>
                      <Button
                        size="sm"
                        isDisabled={!edit}
                        variant="danger"
                        onClick={() => handleRemoveLimit(index)}
                      >
                        <MdDelete color="red" size={22} />
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {edit && (
              <Flex justifyContent={"flex-end"} pt={1}>
                <Button
                  size="sm"
                  isDisabled={!edit}
                  bgColor={edit ? "white" : "neutral.50"}
                  _disabled={{ color: "black", opacity: 0.9 }}
                  onClick={handleAddLimit}
                  id="primary-colored-btn"
                >
                  {t("Add")}
                </Button>
              </Flex>
            )}
          </GridItem>
          {
            // Check if there are prior losses OR if the user is in edit mode.
            // This ensures the table is shown when editing and adding an entry,
            // without duplicating the table.
            (Array.isArray(financialDetails?.priorLosses) &&
              financialDetails?.priorLosses.length > 0) ||
            edit ? (
              <GridItem>
                <FormControl
                  className="search-quote-form-group"
                  controlId="formBasicEmail"
                >
                  <h6>{t("Prior Losses")}</h6>

                  <PriorLossesTable
                    edit={edit}
                    financialDetails={financialDetails}
                    setFinancialDetails={setFinancialDetails}
                  />
                </FormControl>
              </GridItem>
            ) : null
          }

          {/*{edit && (
            <GridItem>
              <FormControl
                className="search-quote-form-group"
                controlId="formBasicEmail"
              >
                <FormLabel >
                  {t("Modify Premium")}
 :               </FormLabel>
                <br />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="update-premium-tooltip">
                      Save & Update Premium First to Modify
                    </Tooltip>
                  }
                >
                  <Button
                    type="button"
                    id="primary-colored-btn"
                    variant="primary"
                    onClick={() => setShowUpdatePremiumModal(true)}
                  >
                    {t("Change / Update")}
                  </Button>
                </OverlayTrigger>
              </FormControl>
            </GridItem>
          )}*/}
          {edit && (
            <Box>
              <Flex as={"h6"} gap={2} flexDir={"column"}>
                <Text fontSize="md" mb="0">
                  Additional Insured Present?
                </Text>
                <Flex flexDir={"row"} gap={2} p={2}>
                  <Text mr={2} pt={1.5}>
                    {t("No")}
                  </Text>
                  <Switch
                    size={"lg"}
                    isChecked={hasInsured}
                    onChange={(e) => setHasInsured(e.target.checked)}
                  ></Switch>
                  <Text ml={2} pt={1.5}>
                    {t("Yes")}
                  </Text>
                </Flex>
                {hasInsured && (
                  <Flex pt={3}>
                    <Button
                      id="primary-colored-btn"
                      onClick={() => setIsInsuredOpen(true)}
                      size={"sm"}
                      _hover={{ bgColor: "neutral.400" }}
                    >
                      Add Additional Insured
                    </Button>
                    <AdditionalInsuredModal
                      isOpen={isInsuredOpen}
                      setIsOpen={setIsInsuredOpen}
                      bindDetails={bindDetails}
                      setBindDetails={setBindDetails}
                      setSaveAdditionalInsured={setSaveAdditionalInsured}
                      quoteDetailsData={quoteDetailsData}
                    />
                  </Flex>
                )}
              </Flex>
            </Box>
          )}
          {edit && (
            <>
              <Box>
                <Flex as={"h6"} gap={2} flexDir={"column"}>
                  <Text fontSize="md" mb="0">
                    {t("Do you have Mortgagees")}?
                  </Text>
                  <Flex flexDir={"row"} gap={2} p={2}>
                    <Text mr={2} pt={1.5}>
                      {t("No")}
                    </Text>
                    <Switch
                      size={"lg"}
                      isChecked={hasMortgagee}
                      onChange={(e) => setHasMortgagee(e.target.checked)}
                    ></Switch>
                    <Text ml={2} pt={1.5}>
                      {t("Yes")}
                    </Text>
                  </Flex>
                  {hasMortgagee && (
                    <Flex pt={3}>
                      <Button
                        color={"white"}
                        bg={"primary"}
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={() => setIsOpen(true)}
                        size={"sm"}
                        _hover={{ bgColor: "neutral.400" }}
                      >
                        {t("Add mortgagees")}
                      </Button>
                      <MortgageModal
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        bindDetails={bindDetails}
                        setBindDetails={setBindDetails}
                        setSaveMortgagee={setSaveMortgagee}
                        quoteDetailsData={quoteDetailsData}
                      />
                    </Flex>
                  )}
                </Flex>
              </Box>
              <Box>
                <Flex as={"h6"} gap={2} flexDir={"column"}>
                  <Text fontSize="md" mb="0">
                    {t("Mailing Address Same as the Property Address?")}
                  </Text>
                  <Flex flexDir={"row"} gap={2} p={2}>
                    <Text mr={2} pt={1}>
                      {t("No")}
                    </Text>
                    <Switch
                      size={"lg"}
                      // isChecked={isMailingAdressSwitchChecked}
                      defaultChecked={
                        quoteDetailsData?.data?.isMailingAddressSame
                      }
                      onChange={(e) => {
                        setIsMailingAddressSwitchChecked(e.target.checked);
                        setBindDetails({
                          ...bindDetails,
                          mailingAddress:
                            quoteDetailsData?.data?.mailingAddress,
                          mailingAddressAddressLine2:
                            quoteDetailsData?.data?.mailingAddressAddressLine2,
                          mailingAddressZipCode:
                            quoteDetailsData?.data?.mailingAddressZipCode,
                          mailingAddressCounty:
                            quoteDetailsData?.data?.mailingAddressCounty,
                          mailingAddressState:
                            quoteDetailsData?.data?.mailingAddressState,
                          mailingAddressCity:
                            quoteDetailsData?.data?.mailingAddressCity,
                          isMailingAddressSame: e.target.checked,
                        });
                      }}
                    ></Switch>
                    <Text ml={2} pt={1}>
                      {t("Yes")}
                    </Text>
                  </Flex>
                  {!isMailingAdressSwitchChecked && (
                    <Box>
                      <Button
                        color={"white"}
                        bg={"primary"}
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={() => setIsMailingAddressModalOpen(true)}
                        size={"sm"}
                        _hover={{ bgColor: "neutral.400" }}
                        width={"50%"}
                      >
                        {t("Update Mailing Address")}
                      </Button>
                      {isMailingAddressModalOpen && (
                        <MailingAddress
                          isMailingAddressModalOpen={isMailingAddressModalOpen}
                          setIsMailingAddressModalOpen={
                            setIsMailingAddressModalOpen
                          }
                          bindDetails={bindDetails}
                          setBindDetails={setBindDetails}
                          productId={productId}
                          edit={edit}
                          loading={loading}
                          setLoading={setLoading}
                          setSaveMailingAddress={setSaveMailingAddress}
                          quoteDetailsData={quoteDetailsData}
                        />
                      )}
                    </Box>
                  )}
                </Flex>
              </Box>
            </>
          )}
        </Grid>
      </Form>
      <style>
        {`
         .chakra-switch {
          width: fit-content !important;
          margin-top: 1.5px;
        }

        .chakra-switch .chakra-switch__track::after {
          content: "" !important;
          color: var(--chakra-colors-white);
          display: block;
          position: absolute;
          top: 15px;
          left: 58%;
          font-size: 11px;
          font-weight: bold;
        }

        .chakra-switch[data-checked] .chakra-switch__track::after {
          content: "" !important;
          left: 7.7%;
          font-size: 10px;
          font-weight: normal;
        }
        `}
      </style>
    </>
  );
}
