import React , {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import "./HomeUpdate.scss";

const HomeUpdate = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const [validated, setValidated] = useState(false); 
    const handleOnclick = (step) => {
        //validation
        if(step == "4"){
            navigation('/instaQuote/InstaSteps/' + step)
        } else {
            var validateStatus = false;
            if(props.roofYear.toString().length != 4 || props.electricityyear.toString().length != 4 || props.plumbingyear.toString().length != 4 || props.waterheateryear.toString().length != 4  ){
                validateStatus = true;
            }
        
            setValidated(validateStatus);
            if(validateStatus == false){
                navigation('/instaQuote/InstaSteps/' + step)
            }
        }
        
    }

    return(
        <section className="home-updates-section">
            <Container className="main-content-section">
                <Row className="justify-content-center">
                    <Col xs="12" sm="12" md="12" lg="8" xl="8" className="updates-form-section">
                        <Row className="justify-content-center">
                            <Col xs="12" className="updates-form-main">
                            <Form validated={validated} className="confirm-address-form">
                                <div className="updates-headings">
                                    <h3>{t('Home Updates')}</h3>
                                    <p>{t('Tell us the year your systems was updated')}</p>
                                </div>
                                <Row className="justify-content-center">
                                    <Col xs="12" sm="6" className="updates-card">
                                        <p className="card-h">{t('Roof updated year')}</p>
                                        <div className="updates-vector1"></div>
                                        <Form.Control
                                            id="updates-form-input"
                                            className="myform-input"
                                            type="text"
                                            title="riskRenovationData"
                                            subtitle = "roofInfo"
                                            name="s_RenovationYear"
                                            placeholder="YYYY"
                                            value={props.roofYear}
                                            onChange = {props.handleChange}
                                            renovationcode = {props.renovationcodeRoofInfo}
                                            maxlength="4"
                                            pattern=".{4,}"
                                            required
                                        />
                                         <Form.Control.Feedback type="invalid">
                                            {t('Please Add Home Updates Error')}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col xs="12" sm="6" className="updates-card">
                                        <p className="card-h">{t('Electricity updated year')}</p>
                                        <div className="updates-vector2"></div>
                                        <Form.Control
                                            id="updates-form-input"
                                            className="myform-input"
                                            type="text"
                                            title="riskRenovationData"
                                            subtitle = "electricityInfo"
                                            name="s_RenovationYear"
                                            placeholder="YYYY"
                                            value={props.electricityyear}
                                            onChange = {props.handleChange}
                                            renovationcode = {props.renovationcodeElectricityInfo}
                                            maxlength="4"
                                            pattern=".{4,}"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('Please Add Electricity updated year Error')}
                                        </Form.Control.Feedback>  
                                    </Col>
                                    <Col xs="12" sm="6" className="updates-card">
                                        <p className="card-h">{t('Plumbing updated year')}</p>
                                        <div className="updates-vector3"></div>
                                        <Form.Control
                                            id="updates-form-input"
                                            className="myform-input"
                                            type="text"
                                            title="riskRenovationData"
                                            subtitle = "plumbingInfo"
                                            name="s_RenovationYear"
                                            placeholder="YYYY"
                                            value={props.plumbingyear}
                                            onChange = {props.handleChange}
                                            renovationcode = {props.renovationcodePlumbingInfo}
                                            maxlength="4"
                                            pattern=".{4,}"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t('Please Add Plumbing updated year Error')}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col xs="12" sm="6" className="updates-card">
                                        <p className="card-h">{t('Water heater updated year')}</p>
                                        <div className="updates-vector4"></div>
                                        <Form.Control
                                            id="updates-form-input"
                                            className="myform-input"
                                            type="text"
                                            title="riskRenovationData"
                                            subtitle = "waterHeaterInfo"
                                            name="s_RenovationYear"
                                            placeholder="YYYY"
                                            value={props.waterheateryear}
                                            onChange = {props.handleChange}
                                            renovationcode = {props.renovationcodeWaterHeaterInfo}
                                            maxlength="4"
                                            pattern=".{4,}"
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {t('Please Add Water heater updated Error')}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <div className="flood-bts-div">
                                        <Button
                                            id="primary-grey-btn-flood"
                                            variant="outline-dark"
                                            onClick={() => handleOnclick('4')}
                                        >
                                            {t('Previous')}
                                        </Button>
                                        <Button
                                            id="primary-colored-btn"
                                            variant="primary"
                                            onClick={() => handleOnclick('6')}
                                        >
                                            {t('Next')}
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

function mapStateToProps(state) {  
    return {
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},

        roofYear : state.common.emptyInstantQuoteData.riskRenovationData.roofInfo.s_RenovationYear,
        renovationcodeRoofInfo : state.common.emptyInstantQuoteData.riskRenovationData.roofInfo.s_RenovationCode,
        
        electricityyear : state.common.emptyInstantQuoteData.riskRenovationData.electricityInfo.s_RenovationYear,
        renovationcodeElectricityInfo : state.common.emptyInstantQuoteData.riskRenovationData.electricityInfo.s_RenovationCode,

        plumbingyear : state.common.emptyInstantQuoteData.riskRenovationData.plumbingInfo.s_RenovationYear,
        renovationcodePlumbingInfo : state.common.emptyInstantQuoteData.riskRenovationData.plumbingInfo.s_RenovationCode,

        waterheateryear : state.common.emptyInstantQuoteData.riskRenovationData.waterHeaterInfo.s_RenovationYear,
        renovationcodeWaterHeaterInfo : state.common.emptyInstantQuoteData.riskRenovationData.waterHeaterInfo.s_RenovationCode,
    };
}

export default connect(mapStateToProps)(HomeUpdate)