import Http from "../../../../../Http";

export async function rateSheet(poTransPK) {
  const url = `/api/reports/generate/RATESHEET/${poTransPK}`;
  try {
    const { data } = await Http.get(url);
    return data;
  } catch (error) {
    console.error("Error in rate sheet api :", error);
    throw error;
  }
}
