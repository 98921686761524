import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
  Text,
  Grid,
  GridItem,
  Spinner,
  Select,
} from "@chakra-ui/react";
import { RiDeleteBin2Line } from "react-icons/ri";
import { getAddrByZip } from "../../../api/getAddrByZip";
import useDebounce from "../../../utility/useDebounce";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import { VscDiffAdded } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { postDeletePolicyMortagee } from "../../../api/postDeletePolicyMortagee";
import { ZipSelectionModal } from "./ZipLocationSelectModal";

const MortgageModal = ({
  isOpen,
  setIsOpen,
  bindDetails,
  setBindDetails,
  setSaveMortgagee,
  quoteDetailsData,
}) => {
  const { t } = useTranslation();
  const [mortgagees, setMortgagees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mortgageeType, setMortgageeType] = useState("PRIMARY");
  const [zipData, setZipData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const [newMortgagee, setNewMortgagee] = useState({
    loanNo: "",
    person: {
      firstName: "",
      lastName: "",
    },
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const handleCloseModal = () => {
    setIsOpen(false);
    setNewMortgagee({
      loanNo: "",
      person: {
        firstName: "",
        lastName: "",
      },
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
      },
    });
  };

  const handleAddMortgagee = () => {
    if (mortgagees.length < 2) {
      setMortgagees([...mortgagees, newMortgagee]);
      setNewMortgagee({
        loanNo: "",
        person: {
          firstName: "",
          lastName: "",
        },
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zip: "",
        },
      });
      setMortgageeType("SECOND");
    } else {
      toast.warn(t("Maximum of 2 mortgagees are allowed"), {
        autoClose: 3500,
      });
    }
  };

  useEffect(() => {
    if (
      quoteDetailsData?.get_quote_response?.data &&
      quoteDetailsData?.get_quote_response?.data?.mortgagees &&
      Array.isArray(
        quoteDetailsData?.get_quote_response?.data?.mortgagees?.data
      )
    ) {
      setMortgagees(
        quoteDetailsData?.get_quote_response?.data?.mortgagees?.data
      );
    }
  }, [quoteDetailsData]);

  useEffect(() => {
    const hasSecondMortgagee =
      quoteDetailsData?.get_quote_response?.data?.mortgagees?.data?.some(
        (mortgagee) => mortgagee.personType === "PRIMARY"
      );
    const hasSecondMortagee = mortgagees?.some(
      (mortgagee) => mortgagee.personType === "PRIMARY"
    );
    if (hasSecondMortgagee && hasSecondMortagee) {
      setMortgageeType("SECOND");
    }
  }, [quoteDetailsData, mortgagees]);

  const deleteMortagee = async (mortgagee) => {
    try {
      setLoading(true);
      if (
        mortgagee?.n_PolicyAddInterest_PK === null ||
        mortgagee?.n_PolicyAddInterest_PK === undefined
      ) {
        setMortgagees(mortgagees.filter((m) => m.loanNo !== mortgagee.loanNo));
      } else {
        const reqBody = {
          transPK: quoteDetailsData?.get_quote_response?.data?.poTransactionPk,
          n_PolicyAddInterest_PK: mortgagee?.n_PolicyAddInterest_PK,
        };
        const response = await postDeletePolicyMortagee(reqBody);
        if (response.success && response.data.Status === "Y") {
          toast.success(
            t("Mortgagee Deleted Successfully", { autoClose: 1500 })
          );
          setMortgagees(
            mortgagees.filter((m) => m.loanNo !== mortgagee.loanNo)
          );
        }
      }
    } catch (error) {
      console.error("Error deleting mortgagee:", error);
      toast.error(t("Error deleting mortgagee. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedNewMortgagee;
    if (mortgageeType === "SECOND") {
      updatedNewMortgagee = {
        ...newMortgagee,
        mortgageeType: "Second mortgagee",
        personType: mortgageeType,
        loanNo: name === "loanNo" ? value : newMortgagee.loanNo || "",
        person: {
          ...newMortgagee.person,
          entityType: "INDIVIDUAL",
          firstName:
            name === "firstName"
              ? value
              : newMortgagee?.person?.firstName || "",
          lastName:
            name === "lastName" ? value : newMortgagee?.person?.lastName || "",
        },
        address: {
          ...newMortgagee?.address,
          addressLine1:
            name === "mailingAddress1"
              ? value
              : newMortgagee.address?.addressLine1 || "",
          addressLine2:
            name === "mailingAddress2"
              ? value
              : newMortgagee?.address?.addressLine2 || "",
          zip: name === "zipCode" ? value : newMortgagee?.address?.zip || "",
          city: name === "city" ? value : newMortgagee?.address?.city,
        },
      };
    } else if (mortgageeType === "PRIMARY") {
      updatedNewMortgagee = {
        ...newMortgagee,
        mortgageeType: "First mortgagee",
        personType: mortgageeType,
        loanNo: name === "loanNo" ? value : newMortgagee?.loanNo || "",
        person: {
          ...newMortgagee?.person,
          entityType: "INDIVIDUAL",
          firstName:
            name === "firstName"
              ? value
              : newMortgagee?.person?.firstName || "",
          lastName:
            name === "lastName" ? value : newMortgagee?.person?.lastName || "",
        },
        address: {
          ...newMortgagee?.address,
          addressLine1:
            name === "mailingAddress1"
              ? value
              : newMortgagee?.address?.addressLine1 || "",
          addressLine2:
            name === "mailingAddress2"
              ? value
              : newMortgagee?.address?.addressLine2 || "",
          zip: name === "zipCode" ? value : newMortgagee?.address?.zip || "",
          city: name === "city" ? value : newMortgagee?.address?.city,
        },
      };
    }
    setNewMortgagee(updatedNewMortgagee);
  };

  const handleDeleteMortgagee = (index) => {
    deleteMortagee(mortgagees[index]);
  };

  const debouncedZipCode = useDebounce(newMortgagee.address?.zip, 1000);
  useEffect(() => {
    const fetchAddressData = async () => {
      if (debouncedZipCode && debouncedZipCode.length > 4) {
        try {
          setLoading(true);
          const data = await getAddrByZip({ zipCode: debouncedZipCode });
          if (
            data?.data?.status === "Y" &&
            Array.isArray(data?.data?.zipData)
          ) {
            if (data?.data?.zipData?.length > 1) {
              setIsOpenInternal(true);
              setZipData(data?.data?.zipData);
            } else if (data?.data?.zipData?.length === 1) {
              setSelectedLocation(data?.data?.zipData[0]);
            }
          } else if (data?.data?.status === "N" && data?.data?.errorMsg) {
            toast.warn(data?.data?.errorMsg, {
              autoClose: 2500,
            });
          }
        } catch (err) {
          console.error("Error fetching address data:", err);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAddressData();
  }, [debouncedZipCode]);

  useEffect(() => {
    if (selectedLocation) {
      setNewMortgagee({
        ...newMortgagee,
        address: {
          ...newMortgagee.address,
          city: selectedLocation?.s_CityName || "",
          state: selectedLocation?.state_id || "",
          // zip: selectedLocation?.n_Zipcodes_PK,
          cityId: selectedLocation?.city_id || "",
          stateId: selectedLocation?.n_StateId_PK ?? 0,
          country: "US",
          countryId:
            typeof selectedLocation?.n_CountryId_FK === "number"
              ? selectedLocation?.n_CountryId_FK
              : 1 || 1,
          county: selectedLocation?.s_CountyName || "",
          countyId: selectedLocation?.county_id || "",
          stateCode: selectedLocation?.s_StateName || "",
        },
      });
    }
  }, [selectedLocation]);

  const handleSaveMortgagee = () => {
    const mortgageeData = mortgagees?.map((mortgagee) => ({
      n_PolicyAddInterest_PK: null,
      personType: mortgagee?.personType,
      mortgageeType: mortgagee?.mortgageeType,
      loanNo: mortgagee?.loanNo,
      person: {
        entityType: "INDIVIDUAL",
        firstName: mortgagee?.person?.firstName || "",
        middleName: null,
        lastName: mortgagee?.person?.lastName,
        isMailingDiff: null,
        isTempMailing: null,
        isInsuredTenant: null,
      },
      address: {
        addressLine1: mortgagee?.address?.addressLine1 || "",
        addressLine2: mortgagee?.address?.addressLine2 || "",
        county: mortgagee?.address?.county,
        city: mortgagee?.address?.city,
        cityId: mortgagee?.address?.cityId,
        stateId: mortgagee?.address?.state,
        countryId: 1, // US CODE STICTLY
        // cityId:mortgagee?.address?.cityId,
        stateCode: mortgagee?.address?.stateCode,
        country: "US",
        countyId: mortgagee?.address?.countyId,
        latitude: null,
        longitude: null,
        zip: mortgagee?.address?.zip,
      },
    }));
    if (mortgagees.length <= 2 && mortgageeData.length > 0) {
      setBindDetails({
        ...bindDetails,
        mortgageeData: mortgageeData,
        doesPolicyHaveMortgagee: "YES",
      });
      setSaveMortgagee(true);
      handleCloseModal();
    }
  };
  return (
    <>
      <ZipSelectionModal
        isOpen={isOpenInternal}
        setOpenModal={setIsOpenInternal}
        setZipData={setSelectedLocation}
        selectedLocation={zipData}
      />
      <Modal isCentered size={"6xl"} isOpen={isOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottom={"1px solid orange"}
            display={"flex"}
            alignItems={"center"}
            gap={3}
          >
            {t("Add Mortgagee")} {loading && <Spinner />}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            {mortgagees.length < 2 && (
              <Grid gap={6} templateColumns="repeat(2, 1fr)">
                <GridItem colSpan={2}>
                  <Flex flexDir={"row"} gap={4}>
                    <FormControl id="mortgageeType" isRequired>
                      <FormLabel>{t("Mortgagee Type")}</FormLabel>
                      <Select
                        type="text"
                        name="mortgageeType"
                        value={mortgageeType}
                        onChange={(e) => setMortgageeType(e.target.value)}
                      >
                        <option
                          value="PRIMARY"
                          disabled={mortgagees.some(
                            (m) => m.personType === "PRIMARY"
                          )}
                        >
                          {t("Primary")}
                        </option>
                        <option value="SECOND">{t("Secondary")}</option>
                      </Select>
                    </FormControl>
                    {/* <FormControl id="mortgageeName" isRequired>
                      <FormLabel>{t("First Name")}</FormLabel>
                      <Input
                        type="text"
                        name="firstName"
                        value={newMortgagee.person?.firstName}
                        onChange={handleInputChange}
                      />
                    </FormControl> */}
                    <FormControl id="mortgageeName" isRequired>
                      <FormLabel>{t("Mortgagee Name")}</FormLabel>
                      <Input
                        type="text"
                        name="lastName"
                        value={newMortgagee.person?.lastName}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl id="loanNo">
                      <FormLabel>{t("Loan Number")}</FormLabel>
                      <Input
                        name="loanNo"
                        value={newMortgagee.loanNo}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                  </Flex>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl id="mailingAddress1" isRequired>
                    <FormLabel>{t("Mailing Address 1")}</FormLabel>
                    <Input
                      type="text"
                      name="mailingAddress1"
                      value={newMortgagee.address?.addressLine1}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </GridItem>

                <GridItem colSpan={1}>
                  <FormControl id="mailingAddress2">
                    <FormLabel>
                      {t("Mailing Address 2")} ({t("Optional")})
                    </FormLabel>
                    <Input
                      type="text"
                      name="mailingAddress2"
                      value={newMortgagee.address?.addressLine2}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <Flex flexDir={"row"} gap={4}>
                    <FormControl id="zipCode" isRequired>
                      <Flex align="center" gap={2}>
                        <Text fontWeight={500}>{t("Zip Code")}</Text>{" "}
                        <span
                          className="label-asterisk"
                          style={{ color: "red", marginTop: "-17px" }}
                        >
                          *
                        </span>
                        <Tooltip
                          label="Get City and State from Zip Code"
                          aria-label="A tooltip"
                        >
                          <span
                            style={{ cursor: "pointer", marginTop: "-15px" }}
                          >
                            <FaInfoCircle color="blue" cursor="pointer" />
                          </span>
                        </Tooltip>
                      </Flex>
                      <Input
                        my={"-10px"}
                        type="number"
                        name="zipCode"
                        value={newMortgagee.address?.zip}
                        onChange={(e) => {
                          if (e.target.value.length <= 5) {
                            handleInputChange(e);
                          }
                        }}
                      />
                    </FormControl>

                    <FormControl id="city" isRequired>
                      <FormLabel>{t("City")}</FormLabel>
                      <Input
                        disabled={true}
                        type="text"
                        name="city"
                        value={newMortgagee.address?.city}
                        onChange={handleInputChange}
                      />
                    </FormControl>

                    <FormControl id="state" isRequired>
                      <FormLabel>{t("State")}</FormLabel>
                      <Input
                        disabled={true}
                        type="text"
                        name="state"
                        value={newMortgagee.address?.stateCode}
                        onChange={handleInputChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel></FormLabel>
                      <Box mt={8}>
                        <Button
                          _hover={{ bgColor: "primary.400" }}
                          onClick={handleAddMortgagee}
                          isDisabled={
                            newMortgagee.person?.lastName === "" ||
                            newMortgagee.address?.city === "" ||
                            newMortgagee.address?.addressLine1 === "" ||
                            newMortgagee.address?.zip === "" ||
                            (newMortgagee?.personType === "PRIMARY" &&
                              mortgagees[0]?.personType === "PRIMARY")
                          }
                        >
                          <Box mr={1}>
                            <VscDiffAdded />
                          </Box>
                          {t(
                            `Add ${
                              mortgageeType === "PRIMARY"
                                ? "Primary"
                                : "Secondary"
                            } Mortgagee`
                          )}
                        </Button>
                      </Box>
                    </FormControl>
                  </Flex>
                </GridItem>
              </Grid>
            )}

            {mortgagees.length !== 0 && (
              <Box mt={8} overflowX="auto">
                <Table
                  variant="simple"
                  border={"1px solid"}
                  borderColor="gray.100"
                >
                  <Thead>
                    <Tr>
                      <Th>{t("Type")}</Th>
                      <Th>{t("Mortgagee Name")}</Th>
                      <Th style={{ whiteSpace: "nowrap" }}>{t("Address 1")}</Th>
                      <Th style={{ whiteSpace: "nowrap" }}>{t("Address 2")}</Th>
                      <Th>{t("Zip Code")}</Th>
                      <Th>{t("City")}</Th>
                      <Th>{t("State")}</Th>
                      <Th>{t("Loan Number")}</Th>
                      <Th>{t("Action")}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Array.isArray(mortgagees) &&
                      mortgagees.map((mortgagee, index) => (
                        <Tr key={index}>
                          <Td>
                            {mortgagee.personType === "PRIMARY"
                              ? t("Primary")
                              : t("Secondary")}
                          </Td>
                          <Td>
                            {`${mortgagee?.person?.firstName || ""} ${
                              mortgagee?.person?.lastName || ""
                            }`}{" "}
                          </Td>
                          <Td>{mortgagee?.address?.addressLine1}</Td>
                          <Td>{mortgagee?.address?.addressLine2}</Td>
                          <Td>{mortgagee?.address?.zip}</Td>
                          <Td>{mortgagee?.address?.city}</Td>
                          <Td>{mortgagee.address?.stateCode}</Td>
                          <Td>{mortgagee.loanNo}</Td>
                          <Td>
                            <Button
                              size="sm"
                              _hover={{
                                bgColor: "primary.400",
                              }}
                              onClick={() => handleDeleteMortgagee(index)}
                            >
                              <RiDeleteBin2Line size={20} />
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>

          <ModalFooter borderTop={"1px solid"} borderColor={"primary.500"}>
            {mortgagees.length !== 0 && (
              <Button
                _hover={{ bgColor: "primary.400" }}
                w={"4rem"}
                size={"md"}
                mr={3}
                onClick={handleSaveMortgagee}
              >
                {t("Save")}
              </Button>
            )}
            <Button
              color={"primary.500"}
              border={"1px solid"}
              size={"md"}
              borderColor={"neutral.300"}
              variant="ghost"
              onClick={handleCloseModal}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <style>
        {`
        td {
          border: 1px solid;
          border-color: primary.200;
        }
      `}
      </style>
    </>
  );
};

export default MortgageModal;