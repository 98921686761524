import React, { useState, Fragment, useEffect } from "react";
import {
    Row,
    Col,
    Form,
    Button,
    Table,
    Modal,
} from "react-bootstrap";
import Select from "react-select";
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import delIcon from "../../../assets/delete-icon.svg";
import editIcon from "../../../assets/edit-icon.svg";
import { customStyles } from "../../../components/reactSelectCustomStyle";
import { useDispatch, useSelector } from 'react-redux';
import { getSearchMortgagee, getZipDetails } from "../../../services/floodService";
import { deleteInformation } from "../../../services/coreSystemService";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import ZipDetailsView from "../../quote/flood/quote/Modal/ZipDetailsView";
import MortgageeSearch from "../../quote/flood/quote/Mortgagee/MortgageeSearch";
import { isEmpty } from "lodash";
import Swal from "sweetalert2";

var isEdit = false, selectedMortIndex = -1;
const Lender = (props) => {
    const { poTransPk, mortgageeInfo, handleChangeLender, propertyInfo } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { apiLoading } = useSelector(state => state.flood);
    const key = process.env.GOOGLE_API_KEY

    var initOptions = [
        { value: "PRIMARY", label: t("First Mortgagee") },
        { value: "SECOND", label: t("Second Mortgagee") },
        { value: "LOSSPAYEE", label: t("Loss Payee") },
        { value: "DISASTER", label: t("Disaster") }
    ];

    const mortgageeJson = {
        "n_PORiskMortgagee_Pk": null,
        "n_POTransaction_FK": null,
        "n_PersonInfoId_PK": null,
        "s_PoMortgageeTypeCode": null,
        "s_MortgageeType": null,
        "s_LoanNumber": null,
        "s_AddionalIntType": null,
        "s_RelationType": null,
        "s_HouseholdType": null,
        "s_isDeleted": "N",
        "n_CorePersonPK": null,
        "n_CorePersonAddressPK": null,
        "n_CreatedUser": null,
        "d_CreatedDate": null,
        "n_UpdatedUser": null,
        "d_UpdatedDate": null,
        "person": {
            "n_PersonInfoId_PK": null,
            "s_PersonUniqueId": null,
            "s_EntityType": "ORGANISATION",
            "s_FirstName": null,
            "s_MiddleName": null,
            "s_LastOrganizationName": null,
            "s_FullLegalName": null,
            "d_BirthDate": null,
            "s_Occupation": null,
            "s_IsMailingDiff": null,
            "s_IsTempMailing": null,
            "n_CreatedUser": null,
            "d_CreatedDate": null,
            "n_UpdatedUser": null,
            "d_UpdatedDate": null
        },
        "address": [{
            "n_PersonAddressesId_PK": null,
            "n_PersonInfoId_PK": null,
            "s_AddressTypeCode": "MAILING",
            "s_IsDefaultAddress": "Y",
            "s_AddressLine1": null,
            "s_AddressLine2": null,
            "n_Zipcodes_FK": null,
            "n_CountyId_FK": null,
            "n_CityId_FK": null,
            "n_StateId_FK": null,
            "n_CountryId_FK": null,
            "s_PostalCode": null,
            "s_HouseNo": null,
            "s_StreetName": null,
            "s_PostalCodeSuffix": null,
            "s_Latitude": null,
            "s_Longitude": null,
            "s_CountryName": null,
            "s_StateName": null,
            "s_CountyName": null,
            "s_CityName": null,
            "s_ParcelId": null,
            "n_CreatedUser": null,
            "d_CreatedDate": null,
            "n_UpdatedUser": null,
            "d_UpdatedDate": null
        }]
    }

    const [show, setShow] = useState(false);
    const [selectInterest, setSelectInterest] = useState(initOptions[0]);
    const [selectedName, setSelectedName] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [options, setOptions] = useState([]);
    const [interestOptions, setIntrestOptions] = useState([]);
    const [loanNo, setLoanNo] = useState('');
    const [search, setSearchProperty] = useState(null);
    const [streetName, setStreetName] = useState('');
    const [googlePlace, setGooglePlace] = useState('');
    const [mortgageeData, setMortgageeData] = useState(mortgageeJson);
    const [isZipSet, setIsZipSet] = useState(false);
    const [showZipModal, setShowZipModal] = useState(false);
    const [multipleCity, setMultipleCity] = useState(null);
    const [isPolicyMortgage, setIsPolicyMortgage] = useState(false);
    const [showMortgageeSearchModal, setShowMortgageeSearchModal] = useState(false);
    const [mortData, setMortData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showMultipleCity, setShowMultipleCity] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [isValidate, setIsValidate] = useState(true);
    const [edit, setEdit] = useState(false);

    // For Display
    useEffect(() => {
        if (mortgageeInfo && Object.keys(mortgageeInfo).length > 0) {
            const tempArray = [];
            mortgageeInfo.map((data) => {
                if (data.s_PoMortgageeTypeCode == 'PRIMARY') {
                    data.s_MortgageeType = 'First Mortgagee';
                } else if (data.s_PoMortgageeTypeCode == 'SECOND') {
                    data.s_MortgageeType = 'Second Mortgagee';
                } else if (data.s_PoMortgageeTypeCode == 'LOSSPAYEE') {
                    data.s_MortgageeType = 'Loss Payee';
                } else if (data.s_PoMortgageeTypeCode == 'DISASTER') {
                    data.s_MortgageeType = 'Disaster';
                }
                tempArray.push(data);
            })
            setTableData(tempArray)
        }
    }, [mortgageeInfo]);

    // For Display
    useEffect(() => {
        if (propertyInfo && Object.keys(propertyInfo).length > 0) {
            if (['Yes', 'YES'].includes(propertyInfo.RA.s_IsPolicyMortgage)) {
                setIsPolicyMortgage(true);
            }
        }
    }, [propertyInfo]);

    // trigger to update loan number on respective row
    const handleInputChange = (index, event) => {
        const values = [...tableData];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;
        setTableData(values);
        handleChangeLender("Lender", mortgageeInfo, values, "Lender");
    };

    // to update mortgagee data
    const inputChange = (event) => {
        const { name, value, attributes } = event.target;
        const parentKey = attributes.parentobject.value;

        if (parentKey == 'address' && [name == 's_AddressLine1' || name == 's_AddressLine2']) {
            mortgageeData[parentKey][0][name] = value;
        }

        if (parentKey.length > 0 && parentKey !== 'address') {
            mortgageeData[parentKey][name] = value;
        } else {
            mortgageeData[name] = value;
        }
        mortgageeData.s_PoMortgageeTypeCode = selectInterest?.value;
        mortgageeData.s_MortgageeType = selectInterest?.label;
        setMortgageeData({...mortgageeData});
    };

    const validation = () => {      
        if ([null, '', undefined].includes(mortgageeData.person.s_FullLegalName) || isEmpty(mortgageeData.person.s_FullLegalName)) {
          if (mortgageeData.s_PoMortgageeTypeCode !== "DISASTER") {
            toast.error("Enter Mortgagee Name");
            setIsValidate(false);
            setEnableSave(false);
          }
        }
        else if ([null, '', undefined].includes(mortgageeData?.address?.[0]?.s_AddressLine1) || isEmpty(mortgageeData?.address?.[0]?.s_AddressLine1)) {
          toast.error("Enter Mailing Address 1");
          setIsValidate(false);
          setEnableSave(false);
        }
        else if ([null, '', undefined].includes(mortgageeData?.address?.[0]?.s_CityName) || [null, '', undefined].includes(mortgageeData?.address?.[0]?.s_StateName) || [null, '', undefined].includes(mortgageeData?.address?.[0]?.s_PostalCode)) {
          toast.error("We are not writing business in this area");
          setIsValidate(false);
          setEnableSave(false);
        }
        else if ([null, '', undefined].includes(mortgageeData.s_LoanNumber) || isEmpty(mortgageeData.s_LoanNumber)) {
          toast.error("Enter Loan Number");
          setIsValidate(false);
          setEnableSave(false);
        }
        else {
          setIsValidate(true);
          setEnableSave(true);
          handleSaveInterest();
        }
  
        if (mortgageeData.s_PoMortgageeTypeCode == "DISASTER") {
          if ([null, '', undefined].includes(mortgageeData.person.s_FirstName)) {
            toast.error("Enter First Name");
            setIsValidate(false);
            setEnableSave(false);
          }
          else if ([null, '', undefined].includes(mortgageeData.person.s_LastOrganizationName)) {
            toast.error("Enter Last Name");
            setIsValidate(false);
            setEnableSave(false);
          }
          else {
            setIsValidate(true);
            setEnableSave(true);
            handleSaveInterest();
          }
        }
      };

      const handleMortgageChange=()=>{
        if(propertyInfo?.RA?.s_Occupancy==="RESIDENTIALCONDOBUILD20"){
            Swal.fire({
                title: t('Warning'),
                text: t('A mortgagee/lender can only be listed on an RCBAP if the loan is in the name of Condominium Association.'),
                icon: 'warning',
                confirmButtonColor: 'var(--chakra-colors-primary-500)',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((res)=>{
                if(res.isConfirmed===true){
                  setShow(true); 
                }
              })
        }
        else{
          setShow(true); 
        }
      }

    // to push selected mortgagee date
    const handleSaveInterest = () => {
        // validate name and address
        // var isValid = true;
        // if (selectInterest.value === 'DISASTER') {
        //     if ([null, "", undefined].includes(mortgageeData.person.s_FirstName)) {
        //         isValid = false;
        //         toast.error(t("Please Add First Name"));
        //     }
        //     if ([null, "", undefined].includes(mortgageeData.person.s_LastOrganizationName)) {
        //         isValid = false;
        //         toast.error(t("Please Add Last Name"));
        //     }
        //     if (
        //         [null, "", undefined].includes(mortgageeData.address[0].s_AddressLine1) ||
        //         [null, "", undefined].includes(mortgageeData.address[0].s_CityName) ||
        //         [null, "", undefined].includes(mortgageeData.address[0].s_PostalCode) ||
        //         [null, "", undefined].includes(mortgageeData.address[0].s_StateName) ||
        //         [null, "", undefined].includes(mortgageeData.address[0].s_StateName)
        //     ) {
        //         isValid = false;
        //         toast.error(t("Please enter address!"));
        //     }
        // } else {
        //     // validate
        //     if (mortgageeData) {
        //         if ([null, "", undefined].includes(mortgageeData.s_PoMortgageeTypeCode)) {
        //             isValid = false;
        //             toast.error(t("Please select Mortgagee type"));
        //         }
        //         if ([null, "", undefined].includes(mortgageeData.person.n_PersonInfoId_PK)) {
        //             isValid = false;
        //             toast.error(t("Please select Mortgagee"));
        //         }
        //     }
        // }

        // if (isValid === true) {
            if (selectInterest.value === 'DISASTER') {
                var fullName = mortgageeData.person.s_FirstName || '';
                if (fullName && fullName !== '') {
                    if (mortgageeData.person.s_LastOrganizationName && mortgageeData.person.s_LastOrganizationName !== '') {
                        fullName += ' ';
                        fullName += mortgageeData.person.s_MiddleName || '';
                    }
                    fullName += ' ';
                    fullName += mortgageeData.person.s_LastOrganizationName || '';
                }
                mortgageeData.person.s_FullLegalName = fullName;
                mortgageeData.s_PoMortgageeTypeCode = selectInterest.value;
                mortgageeData.s_MortgageeType = selectInterest.label;
            }
            setMortgageeData(mortgageeJson);
            var temp = [...tableData];

            if (isEdit === true && selectedMortIndex !== -1) {
                temp[selectedMortIndex] = mortgageeData;
            } else {
                temp.push(mortgageeData);
            }

            setTableData(temp);
            handleChangeLender("Lender", mortgageeInfo, temp, "Lender");
            setShow(false);
        // }

        return false;
    };

    // trigger to remove delete row from the table details
    const handleRemoveFields = async (index, row) => {
        const values = [...tableData];
        if (values[index].n_PORiskMortgagee_Pk && values[index].n_PORiskMortgagee_Pk != null) {

            if (await deleteInterest(row.n_PORiskMortgagee_Pk)) {
                // to delete FIRST Mortgagee
                if (values[index].s_PoMortgageeTypeCode === 'PRIMARY') {
                    var secondMortgagee = values.filter(x => x.s_PoMortgageeTypeCode === 'SECOND');
                    if (secondMortgagee.length > 0) {
                        values[index] = secondMortgagee[0];
                        values[index].s_MortgageeType = 'First Mortgagee';
                        values[index].s_PoMortgageeTypeCode = 'PRIMARY';
                        index = values.findIndex(x => x.s_PoMortgageeTypeCode === 'SECOND');
                    }
                }
                values.splice(index, 1);
                setTableData(values);
                setMortgageeData(mortgageeJson);
            }
        } else {
            values.splice(index, 1);
            setTableData(values);
        }
        toast.success('Entry Deleted.');
    };

    /**
     * To delete policy additional interest details
     * @param {int} n_PORiskMortgagee_Pk 
     * @returns boolean
     */
    const deleteInterest = async (n_PORiskMortgagee_Pk) => {
        if (poTransPk) {
            const params = {
                selectedPK: n_PORiskMortgagee_Pk,
                source: "MORTGAGEE",
                isUpdateBillTo: 'N'
            }
            var res = await dispatch(deleteInformation(params));
            if (res.success && res.data.status === 'Y') {
                toast.success('Entry Deleted.');
                // update isLenderDeleted in endorsement json 
                handleChangeLender("isLenderDeleted", '', 'YES', "Lender");
                return true;
            }
        } else {
            return true;
        }
    }

    // trigger to fetch list of clients
    const handleChange = (event) => {
        setSelectedName(event);
        if (event !== '' && event.length >= 4) {
            const params = { searchField: event }
            dispatch(getSearchMortgagee(params)).then((res) => {
                const data = res.data.map(({ s_FullLegalName: label, n_PersonAddressesId_PK: value, ...rest }) => ({ label, value, ...rest }));
                data.map((item) => {
                    item.s_FullLegalName = item.label;
                    item.n_PersonAddressesId_PK = item.value;
                    item.label = item.s_FullLegalName + ' - ' + item.s_AddressLine1 + ',' + item.s_CityName + ',' + item.s_CountyName + ',' + item.s_StateCode + ',' + item.s_PostalCode;
                    return item;
                })
                setOptions(data);
            })
        }
    };

    // trigger to update selected client details
    // const handleOnChange = (event) => {
    //     setSelectedName(event);
    //     const values = mortgageeData;
    //     values.n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    //     values.s_PoMortgageeTypeCode = selectInterest.value;
    //     values.s_MortgageeType = selectInterest.label;
    //     values.s_LoanNumber = event.s_LoanNumber || loanNo;
    //     // person
    //     values.person.n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    //     values.person.s_EntityType = event.s_EntityType;
    //     values.person.s_FullLegalName = event.s_FullLegalName;
    //     // address
    //     values.address[0].n_PersonAddressesId_PK = event.n_PersonAddressesId_PK;
    //     values.address[0].n_PersonInfoId_PK = event.n_PersonInfoId_PK;
    //     values.address[0].s_AddressTypeCode = event.s_AddressTypeCode;
    //     values.address[0].s_AddressLine1 = event.s_AddressLine1;
    //     values.address[0].n_CityId_FK = event.n_CityId_FK;
    //     values.address[0].n_CountyId_FK = event.n_CountyId_FK;
    //     values.address[0].n_StateId_FK = event.n_StateId_FK;
    //     values.address[0].n_CountryId_FK = event.n_CountryId_FK;
    //     values.address[0].s_PostalCode = event.s_PostalCode;
    //     values.address[0].s_StateName = event.s_StateCode;
    //     values.address[0].s_HouseNo = event.s_HouseNo;
    //     values.address[0].s_StreetName = event.s_StreetName;
    //     values.address[0].s_CountyName = event.s_CountyName;
    //     values.address[0].s_CityName = event.s_CityName;
    //     setMortgageeData(values);
    // };

    // trigger to set selected address street name
    const handleGooglAutoComplete = (val, status) => {
        if (val) {
            if (status.action === "input-change") {
                setStreetName(val);
            } else if (["set-value", "menu-close", "input-blur", "menu-close"].includes(status.action)) {
                setStreetName(streetName.toUpperCase());
            }
        }
    }

    // trigger to set mortgagee type options
    useEffect(() => {
        if (isEdit === false) {
            let selectedInterestOptions = [];
            if (tableData.length === 0) {
                selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
            } else {
                tableData.filter(function (val) {
                    if (val.s_PoMortgageeTypeCode === 'PRIMARY' && selectInterest !== 'SECOND') {
                        selectedInterestOptions = initOptions.filter(item => item.value !== 'PRIMARY');
                        initOptions = selectedInterestOptions;
                    }
                    if (val.s_PoMortgageeTypeCode === 'SECOND') {
                        selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
                        initOptions = selectedInterestOptions;
                    }
                    if (selectedInterestOptions.length == 0) {
                        selectedInterestOptions = initOptions.filter(item => item.value !== 'SECOND');
                    }
                    return selectedInterestOptions;
                });
            }
            setIntrestOptions(selectedInterestOptions);
            setSelectInterest(selectedInterestOptions[0]);
        }
    }, [tableData, show]);

    // trigger to update selected address
    useEffect(() => {
        if (search != null) {
            setStreetName(search.value.structured_formatting.main_text);
            getGeoCode(search.value.description);
            setGooglePlace(search);
        }
    }, [search]);

    // Inserting style only for address field
    useEffect(() => {
        document.head.insertAdjacentHTML("beforeend", `<style>input[id^='react-select-'] { opacity: 2 !important; width: 250px !important; }</style>`)
    }, [])

    // to clear all details on close modal
    useEffect(() => {
        if (!show) {
            isEdit = false;
            selectedMortIndex = -1;
            setLoanNo('')
            setMortgageeData(mortgageeJson);
            setSearchProperty(null)
            setOptions([]);
            setStreetName(null)
            setGooglePlace(null)
            setSelectedName(null)
            setSelectInterest(initOptions[0])
        }
    }, [show]);

    const getGeoCode = (description) => {
        geocodeByAddress(description)
            .then(addr => {
                addr && addr[0].address_components.forEach(e => {
                    if (e.types.includes('postal_code')) {
                        getZipData(e.short_name);
                    }
                });
            })
    }

    // get zip details 
    const getZipData = (zip) => {
        if (zip !== '') {
            dispatch(getZipDetails('10', zip, 'APPLICANT')).then((res) => {
                if (res.success === true) {
                    var data = res.data;
                    if (data.status === 'Y') {
                        if (data.count === 1) {
                            setZipDetailsData(res.data.zipData[0]);
                        } else {
                            showMultipleCityModal(data.zipData);
                        }
                    } else {
                        toast.error(res.errorMsg);
                    }
                }
            });
        } else {
            toast.error('Zip Code should not be empty!');
        }
    }

    // open Zip Modal
    const showMultipleCityModal = (zipData) => {
        setMultipleCity(<ZipDetailsView zipData={zipData} setZipDetailsData={setZipDetailsData} />)
        setShowZipModal(true);
    }

    // get selected zip data
    const setZipDetailsData = (zipData) => {
        const values = mortgageeData;
        // var addressLine = zipData.s_AddressLine1
        // if (search !== null) {
        //     addressLine = search.value.structured_formatting.main_text;
        // }
        // values.address[0].s_AddressLine1 = addressLine;
        values.address[0].s_PostalCode = zipData.s_ZipCode;
        values.address[0].n_Zipcodes_FK = zipData.n_Zipcodes_PK;
        values.address[0].s_StateName = zipData.s_StateCode;
        values.address[0].n_CityId_FK = zipData.n_CityId_PK;
        values.address[0].n_CountyId_FK = zipData.n_CountyId_FK;
        values.address[0].n_StateId_FK = zipData.n_StateId_PK;
        values.address[0].n_CountryId_FK = zipData.n_CountryId_FK;
        values.address[0].s_CityName = zipData.s_CityName;
        setMortgageeData(values);
        setIsZipSet(!isZipSet);
        setShowZipModal(false);
    }

    // handle edit
    const handleEditMortgagee = (selectedIndex, mortgageeData) => {
        isEdit = true;
        selectedMortIndex = selectedIndex;
        var option = [
            {
                "label": mortgageeData.person.s_FullLegalName + ' - ' + mortgageeData.address[0].s_AddressLine1 + ',' + mortgageeData.address[0].s_CityName + ',' + mortgageeData.address[0].s_CountyName + ',' + mortgageeData.address[0].s_StateCode + ',' + mortgageeData.address[0].s_PostalCode,
                "value": mortgageeData.address[0].n_PersonAddressesId_PK,
                "n_PersonInfoId_PK": mortgageeData.person.n_PersonInfoId_PK,
                "s_PersonUniqueId": mortgageeData.person.s_PersonUniqueId,
                "s_EntityType": mortgageeData.person.s_EntityType,
                "s_PersonStatusCode": mortgageeData.person.s_PersonStatusCode,
                "s_AddressTypeCode": mortgageeData.address[0].s_AddressTypeCode,
                "s_HouseNo": mortgageeData.address[0].s_HouseNo,
                "s_StreetName": mortgageeData.address[0].s_StreetName,
                "s_AddressLine1": mortgageeData.address[0].s_AddressLine1,
                "n_CountyId_FK": mortgageeData.address[0].n_CountyId_FK,
                "n_CityId_FK": mortgageeData.address[0].n_CityId_FK,
                "n_StateId_FK": mortgageeData.address[0].n_StateId_FK,
                "n_CountryId_FK": mortgageeData.address[0].n_CountryId_FK,
                "s_PostalCode": mortgageeData.address[0].s_PostalCode,
                "s_CityName": mortgageeData.address[0].s_CityName,
                "s_CountyName": mortgageeData.address[0].s_CountyName ?? "",
                "s_StateCode": mortgageeData.address[0].s_StateCode ?? "",
                "s_FullLegalName": mortgageeData.person.s_FullLegalName,
                "n_PersonAddressesId_PK": mortgageeData.address[0].n_PersonAddressesId_PK,
                "s_LoanNumber": mortgageeData.s_LoanNumber
            }
        ];
        setShow(true);
        setOptions(option);
        setMortgageeData(mortgageeData);
        // handleOnChange(option[0]);

        // mortgagee 
        var selectedInterestOptions = initOptions.filter(item => item.value === mortgageeData.s_PoMortgageeTypeCode) ?? [];
        setIntrestOptions(selectedInterestOptions);
        setSelectInterest({ value: mortgageeData.s_PoMortgageeTypeCode, label: mortgageeData.s_MortgageeType });

        if (mortgageeData.s_PoMortgageeTypeCode === "DISASTER") {
            setStreetName(mortgageeData.address[0].s_AddressLine1);
        }
    }

    return (
        <section className="add-mortage-section">
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Does the policy have a Mortgage')}?
                    </Form.Label>
                    <div
                        style={{ display: "flex", justifyContent: "flex-start" }}
                        className="custom-radio-check0"
                    >
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="flood-construction-row-switch"
                            label=""
                            parentobject="propertyInfo"
                            name="s_IsPolicyMortgage"
                            checked={isPolicyMortgage}
                            onChange={(e) => { setIsPolicyMortgage(e.currentTarget.checked) }}
                        />
                    </div>
                </Form.Group>
            </Col>
            {/* Form */}
            <Row className="justify-content-center flood-add-mortage-form-section" hidden={!isPolicyMortgage}>
                <Col xs="12" className="add-mortage-form">
                    <div className="add-mortage-add-btn">
                        <div></div>
                        <Button
                            variant="primary"
                            id="add-input-field"
                            onClick={() => {
                               handleMortgageChange();
                               setShowMortgageeSearchModal(false)
                            } }
                        >
                            {" "}
                            <span> + </span> {t('Add')}
                        </Button>
                    </div>
                    <div className="add-mortage-table-div">
                        <Table className="add-mortage-table" borderless responsive>
                            <thead>
                                <tr>
                                    <th>{t('Name')}</th>
                                    <th>{t('Lender Type')}</th>
                                    <th>{t('Loan No')}.</th>
                                    <th>{t('Action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.length > 0 && (
                                    <>
                                        {tableData.map((field, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Form.Label
                                                        className="myform-label"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        {field?.person?.s_FullLegalName}
                                                    </Form.Label>
                                                </td>
                                                <td>
                                                    <Form.Label
                                                        className="myform-label"
                                                        style={{ marginLeft: '10px' }}
                                                    >
                                                        {field?.s_MortgageeType}
                                                    </Form.Label>
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder={t("Loan No")}
                                                        name="s_LoanNumber"
                                                        parentobject=""
                                                        value={field.s_LoanNumber}
                                                        onChange={(event) =>
                                                            handleInputChange(index, event)
                                                        }
                                                    />
                                                </td>
                                                <td className="add-mortage-action-row">
                                                    <div className="add-mortage-action">
                                                        <img
                                                            src={editIcon}
                                                            alt='Edit'
                                                            className="editIcon"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={() => handleEditMortgagee(index, field)}
                                                        />
                                                        <img
                                                            src={delIcon}
                                                            alt='Delete'
                                                            onClick={() => handleRemoveFields(index, field)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>

            {/* Modal */}
            {
                show && (
                    <Modal size="lg" show={show} className="add-mortage-modal">
                        <Modal.Header id="add-mortage-modal-header">
                            <Modal.Title className="text-white">{t('Add Interest')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="add-mortage-modal-body">
                            <LoadingOverlay >
                                <Loader loading={apiLoading} />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="6" xl="6" className="mb-2">
                                        <Select
                                            options={interestOptions}
                                            className="custom-select-autocomplete"
                                            value={selectInterest}
                                            defaultValue={isEdit ? selectInterest : ""}
                                            style={{
                                                marginBottom: "5px !important",
                                            }}
                                            onChange={(e) => {
                                                setSelectInterest(e);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest?.value)) && (
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2">
                                            {/* <Select
                                                options={options}
                                                className="custom-select-autocomplete"
                                                selected={selectedName}
                                                defaultValue={isEdit ? selectedName : ""}
                                                onChange={(e) => {
                                                    handleOnChange(e);
                                                }}
                                                onInputChange={(e) => { handleChange(e) }}
                                                placeholder={t("Search Name")}
                                            /> */}
                                            {/* <Button
                                                variant="primary"
                                                className="mybtn"
                                                onClick={() => setShowMortgageeSearchModal(true)}
                                            >
                                                {t('Search')}
                                            </Button> */}
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                {/* {selectInterest.value === "DISASTER" ? (
                                        <Fragment>
                                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="add-mortage-form-group"
                                                >
                                                    <Form.Label className="myform-label">{t('First Name')}</Form.Label>
                                                    <Form.Control
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        name="s_FirstName"
                                                        parentobject="person"
                                                        value={mortgageeData.person.s_FirstName}
                                                        onChange={(e) => inputChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="add-mortage-form-group"
                                                >
                                                    <Form.Label className="myform-label">
                                                        {t('Middle Name')}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        name="s_MiddleName"
                                                        parentobject="person"
                                                        value={mortgageeData.person.s_MiddleName}
                                                        onChange={(e) => inputChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="4" xl="4">
                                                <Form.Group
                                                    controlId="formBasicEmail"
                                                    className="add-mortage-form-group"
                                                >
                                                    <Form.Label className="myform-label">{t('Last Name')}</Form.Label>
                                                    <Form.Control
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        name="s_LastOrganizationName"
                                                        parentobject="person"
                                                        value={mortgageeData.person.s_LastOrganizationName}
                                                        onChange={(e) => inputChange(e)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Fragment>
                                    ) : (
                                        <Col xs="12">
                                            <Form.Group
                                                controlId="formBasicEmail"
                                                className="add-mortage-form-group"
                                            >
                                                <Form.Label className="myform-label">{t('Name')}</Form.Label>
                                                <Form.Control
                                                    className="myform-input"
                                                    type="text"
                                                    placeholder=""
                                                    disabled
                                                    name="s_FullLegalName"
                                                    parentobject="person"
                                                    value={mortgageeData.person.s_FullLegalName}
                                                    onChange={(e) => inputChange(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col xs="12">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">
                                                {t('Mailing Address')} 1{" "}
                                            </Form.Label>
                                            {
                                                selectInterest.value === "DISASTER" ?
                                                    <GooglePlacesAutocomplete
                                                        apiKey={key}
                                                        selectProps={{
                                                            styles: customStyles,
                                                            inputValue: streetName,
                                                            value: googlePlace,
                                                            onInputChange: handleGooglAutoComplete,
                                                            onChange: setSearchProperty,
                                                            name: "googlePlacesStreetName",
                                                            id: "googlePlacesStreetName",
                                                            placeholder: "Enter a location",
                                                        }}
                                                    />
                                                    :
                                                    <Form.Control
                                                        className="myform-input"
                                                        type="text"
                                                        placeholder=""
                                                        name="s_AddressLine1"
                                                        parentobject="address"
                                                        value={mortgageeData.address[0].s_AddressLine1}
                                                        onChange={(e) => inputChange(e)}
                                                        disabled={
                                                            selectInterest === "PRIMARY" ||
                                                            selectInterest === "SECOND" ||
                                                            selectInterest === "LOSSPAYEE"
                                                        }
                                                    />
                                            }
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">
                                                {t('Mailing Address')} 2{" "}
                                            </Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder=""
                                                disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                                                name="s_AddressLine2"
                                                parentobject="address"
                                                value={mortgageeData.address[0].s_AddressLine2 || ''}
                                                onChange={(e) => inputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">{t('City')}</Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder=""
                                                disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                                                name="s_CityName"
                                                parentobject="address"
                                                value={mortgageeData.address[0].s_CityName}
                                                onChange={(e) => inputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">{t('State')}</Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder=""
                                                disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                                                name="s_StateName"
                                                parentobject="address"
                                                value={mortgageeData.address[0].s_StateName}
                                                onChange={(e) => inputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">{t('Zip Code')}</Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder=""
                                                disabled={(["PRIMARY", "SECOND", "LOSSPAYEE"].includes(selectInterest.value))}
                                                name="s_PostalCode"
                                                parentobject="address"
                                                value={mortgageeData.address[0].s_PostalCode}
                                                onChange={(e) => inputChange(e)}
                                                onBlur={(e) => getZipData(e.currentTarget.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <Form.Group
                                            controlId="formBasicEmail"
                                            className="add-mortage-form-group"
                                        >
                                            <Form.Label className="myform-label">{t('Loan No')}</Form.Label>
                                            <Form.Control
                                                className="myform-input"
                                                type="text"
                                                placeholder=""
                                                name="s_LoanNumber"
                                                parentobject=""
                                                value={mortgageeData.s_LoanNumber}
                                                onChange={(e) => inputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col> */}
                                </Row>
                            </LoadingOverlay >
                        </Modal.Body>
                        <Modal.Footer className="add-mortage-modal-footer">
                            <Button
                                id="primary-grey-btn-flood"
                                variant="outline-dark"
                                onClick={() => setShow(false)}
                                style={{
                                    marginRight: "1rem",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                }}
                                disabled={apiLoading}
                            >
                                {t('Cancel')}
                            </Button>

                            <Button
                                onClick={() => setShowMortgageeSearchModal(true)}
                                id="primary-colored-btn"
                                style={{ paddingLeft: "20px", paddingRight: "20px" }}
                                disabled={apiLoading}
                            >
                                {t('Next')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            {
                showZipModal && (
                    //  Zip Details Modal 
                    <Modal size="md" show={showZipModal} className="zip-details-modal">
                        <Modal.Body className="zip-details-modal-body">
                            {multipleCity}
                        </Modal.Body>
                    </Modal>
                )
            }
            {
                showMortgageeSearchModal && (
                    // <MortgageeSearch
                    //     show={showMortgageeSearchModal}
                    //     setShow={setShowMortgageeSearchModal}
                    //     setSelectedMortgageeData={handleOnChange}
                    // />
                    <>
        <Modal size="lg" show={show} className="add-mortage-modal">
          <Modal.Header id="add-mortage-modal-header">
            <Modal.Title className="text-white">{t("Search Interest")}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-mortage-modal-body">
            <LoadingOverlay>
              {
                !isEmpty(selectInterest) && selectInterest.value === "DISASTER" ? (
                <Fragment>
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('First Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_FirstName"
                          parentobject="person"
                          value={mortgageeData.person.s_FirstName ?? null}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">
                          {t('Middle Name')}
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_MiddleName"
                          parentobject="person"
                          value={mortgageeData.person.s_MiddleName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="4" xl="4">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="add-mortage-form-group"
                      >
                        <Form.Label className="myform-label">{t('Last Name')}
                        <span className="label-asterisk" color="#FF1E1E"> *</span>
                        </Form.Label>
                        <Form.Control
                          className="myform-input"
                          type="text"
                          placeholder=""
                          name="s_LastOrganizationName"
                          parentobject="person"
                          value={mortgageeData.person.s_LastOrganizationName}
                          onChange={(e) => {
                            inputChange(e);
                            setMortData(true);
                          }}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Fragment>
              ) : (<Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="add-mortage-form-group"
                  >
                    <Form.Label className="myform-label">
                      {t("Mortgagee(s) Name")}
                      <span className="label-asterisk" color="#FF1E1E"> *</span>
                    </Form.Label>
                    <Form.Control
                      className="myform-input"
                      type="text"
                      placeholder=""
                      name="s_FullLegalName"
                      parentobject="person"
                      value={mortgageeData?.person?.s_FullLegalName}
                      onChange={(e) => {
                        inputChange(e);
                        setMortData(true);
                      }}
                      maxLength={50}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 1{" "}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine1"
                    parentobject="address"
                    value={mortgageeData?.address?.[0]?.s_AddressLine1}
                    onChange={(e) => {inputChange(e); setMortData(true);}}
                    maxLength={150}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Mailing Address")} 2
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_AddressLine2"
                    parentobject="address"
                    value={mortgageeData?.address?.[0]?.s_AddressLine2}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={50}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Zip Code")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_PostalCode"
                    parentobject="address"
                    value={mortgageeData?.address?.[0]?.s_PostalCode}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    onBlur={(e) => getZipData(e.currentTarget.value)}
                    maxLength={5}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">{t("City")}
                  <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_CityName"
                    parentobject="address"
                    value={mortgageeData?.address?.[0]?.s_CityName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">{t("State")}
                  <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_StateName"
                    parentobject="address"
                    value={mortgageeData?.address?.[0]?.s_StateName}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col xs="12" sm="12" md="12" lg="6" xl="6">
                <Form.Group
                  controlId="formBasicEmail"
                  className="add-mortage-form-group"
                >
                  <Form.Label className="myform-label">
                    {t("Loan No")}
                    <span className="label-asterisk" color="#FF1E1E"> *</span>
                  </Form.Label>
                  <Form.Control
                    className="myform-input"
                    type="text"
                    placeholder=""
                    name="s_LoanNumber"
                    parentobject=""
                    value={mortgageeData?.s_LoanNumber}
                    onChange={(e) => {
                      inputChange(e);
                      setMortData(true);
                    }}
                    maxLength={20}
                  />
                </Form.Group>
              </Col>
              
            </LoadingOverlay>
          </Modal.Body>

          <Modal.Footer className="add-mortage-modal-footer">
            <Button
              variant="outline-dark"
              onClick={() => setShow(false)}
              className="mybtn-light"
              style={{
                marginRight: "1rem",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
              disabled={loading}
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                validation(); setEnableSave(true)
              }}
              id="primary-colored-btn"
              style={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              {t("Submit")}
            </Button>
          </Modal.Footer>
        </Modal>
        {showMultipleCity && (
          <Modal size="md" show={show} className="zip-details-modal">
            <Modal.Body className="zip-details-modal-body">
              {multipleCity}
            </Modal.Body>
          </Modal>
        )}
      </>
                )
            }
        </section>
    );
}

export default Lender;
