import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPasswordUserName, authCheck, login } from "../../services/authService";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import { EncryptOrDecrypt, checkPermission } from "../../services/commonService";
import Http from "../../Http";
import Moment from "moment";
import PasswordChecklist from "react-password-checklist";

const OdysseyLogin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordUserIdMatchValidation, setPasswordUserIdMatchValidation] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(password != '' && password != undefined){
            if(username.toLowerCase() == password.toLowerCase()){
                setPasswordUserIdMatchValidation(true);
            } else {
                setPasswordUserIdMatchValidation(false);
            }
        }    
    }, [password]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!username) {
            toast.error('Please enter username!');
            return false;
        }

        if (!password) {
            toast.error('Please enter Password!');
            return false;
        }

        if (!confirmPass) {
            toast.error('Please enter re-Password!');
            return false;
        }

        if (confirmPass != password) {
            toast.error('your password is not match');
            return false;
        }

        if (username.toLowerCase() == password.toLowerCase()) {
            toast.error('Password cannot be the same as username');
            return false;
        }
        const isContainFlood = /flood/i.test(password); 
        if(isContainFlood){
            return toast.error('The password must not include the word flood.');
        }
        const isContainPassword = /password/i.test(password);
        if(isContainPassword){
            return toast.error('The password must not include the word Password.');
        }

        const isValidPassword = /^(?!.*password)(?!.*flood)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!~#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]).{8,}$/.test(password);
        if(!isValidPassword){
            toast.error('Password Validation Failed.');
            return false;
        }

        const params = {
            'username': username,
            'password': password,
            'confirmPass': confirmPass,
        }

        setLoading(true);
        dispatch(resetPasswordUserName(params)).then(async (res) => {
            if (res.data.status == true) {
                dispatch(login(params)).then(async (response) => {
                    var EncryptUserData = '';
                    var accessToken = '';
                    if (response.success === true) {
                        if (Object.keys(response.data).length > 0 && response.data.status === 200) {
                            accessToken = response.data.access_token;
                            localStorage.setItem('access_token', response.data.access_token);
                            Http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                            var tokenExpireAt = EncryptOrDecrypt(UTCDateToLocalDate(response.data.access_token_expires_at), 'E');
                            localStorage.setItem('access_token_expires_at', tokenExpireAt);
                            EncryptUserData = EncryptOrDecrypt(response.data.extraDetails, 'E');
                            localStorage.setItem('user', EncryptUserData);
                            localStorage.setItem('is_first_login', response.data.is_first_login);
                            await dispatch(authCheck());
                            navigate('/login');
                        }
                    }
                })
                toast.success(res.data.message);
            }else{
                setUsername("");
                setPassword("");
                setConfirmPass("");
                toast.error(res.data.message);
            }
           
            setLoading(false);
        })

    }

    const UTCDateToLocalDate = (date) => {
        date = date.replace('UTC', '').trim();
        return Moment.utc(date).local().format();
    }

    const forgotusername = () => {
        navigate('/forgotUsername');
    }
    const closeIcon = <svg className="sc-eqUAAy bSdOrC checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path fill="#FF0033" d="M507.331 411.33c-0.002-0.002-0.004-0.004-0.006-0.005l-155.322-155.325 155.322-155.325c0.002-0.002 0.004-0.003 0.006-0.005 1.672-1.673 2.881-3.627 3.656-5.708 2.123-5.688 0.912-12.341-3.662-16.915l-73.373-73.373c-4.574-4.573-11.225-5.783-16.914-3.66-2.080 0.775-4.035 1.984-5.709 3.655 0 0.002-0.002 0.003-0.004 0.005l-155.324 155.326-155.324-155.325c-0.002-0.002-0.003-0.003-0.005-0.005-1.673-1.671-3.627-2.88-5.707-3.655-5.69-2.124-12.341-0.913-16.915 3.66l-73.374 73.374c-4.574 4.574-5.784 11.226-3.661 16.914 0.776 2.080 1.985 4.036 3.656 5.708 0.002 0.001 0.003 0.003 0.005 0.005l155.325 155.324-155.325 155.326c-0.001 0.002-0.003 0.003-0.004 0.005-1.671 1.673-2.88 3.627-3.657 5.707-2.124 5.688-0.913 12.341 3.661 16.915l73.374 73.373c4.575 4.574 11.226 5.784 16.915 3.661 2.080-0.776 4.035-1.985 5.708-3.656 0.001-0.002 0.003-0.003 0.005-0.005l155.324-155.325 155.324 155.325c0.002 0.001 0.004 0.003 0.006 0.004 1.674 1.672 3.627 2.881 5.707 3.657 5.689 2.123 12.342 0.913 16.914-3.661l73.373-73.374c4.574-4.574 5.785-11.227 3.662-16.915-0.776-2.080-1.985-4.034-3.657-5.707z"></path></svg>;
    const checkIcon = <svg className="sc-eqUAAy bSdOrC checklist-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512"><path fill="#4BCA81" d="M432 64l-240 240-112-112-80 80 192 192 320-320z"></path></svg>;
    return (
        <>
            <div className="login-page-section">
                <Form onSubmit={handleSubmit}>
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="8" lg="6" xl="5">
                            <div className="login-page-card" style={{ marginTop: "8%" }}>
                                <p><b>It's your first time logging in!</b></p>
                                <p>To secure your account, Please set a password</p>
                                <div className="login-card-line-breaker"> </div>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Username")} 
                                        {/* <AiOutlineQuestionCircle 
                                        onClick={forgotusername} 
                                        style={{ cursor: "pointer" }} size={18} data-tip data-for="questionmark" />&nbsp; */}
                                    </Form.Label>
                                    <ReactTooltip id="questionmark" effect="solid">
                                        <div>
                                            Forgot your username?
                                        </div>
                                    </ReactTooltip>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="text"
                                        placeholder=""
                                        value={username}
                                        onChange={(e) => setUsername(e.currentTarget.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Password")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="password"
                                        placeholder=""
                                        value={password}
                                        maxLength={25}
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="dashboard-group-label">
                                        {t("Re-type Password")}
                                    </Form.Label>
                                    <Form.Control
                                        className="dashboard-group-input"
                                        type="password"
                                        placeholder=""
                                        value={confirmPass}
                                        onChange={(e) => setConfirmPass(e.currentTarget.value)}
                                        maxLength={25}
                                    />
                                </Form.Group>
                                {password != '' &&
                                <div>
                                <Fragment>
                                    <PasswordChecklist
                                        rules={["minLength", "capital","lowercase", "number", "specialChar", "match"]}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPass}
                                        messages={{
                                            minLength: "Must be 8-25 characters in length and cannot contain any spaces.",
                                            capital: "Must contain at least one uppercase alphabetic character: A-Z",
                                            lowercase:"Must contain at least one lowercase alphabetic character: a-z",
                                            number: "Must contain at least one number",
                                            specialChar: "Must contain at least one non-alphabetic 'special' character: i.e.: -#&$*!~@%+",
                                            match: "Password match",
                                        }}
                                        
                                        />
                                    {password != '' && password != undefined ? 
                                        passwordUserIdMatchValidation || password == '' ?  
                                        (closeIcon) : (checkIcon): closeIcon
                                    }
                                    &nbsp;<label style={{opacity: passwordUserIdMatchValidation || password == '' ? 0.5 : null}}> Password cannot be the same as username.</label><br></br>

                                </Fragment>
                                    </div>
                                }
                                <Button
                                    id="common-btn"
                                    variant="primary"
                                    type="submit"
                                    style={{ padding: "12px" }}
                                    disabled={loading}
                                >
                                    {t("Set Password & Login")}
                                </Button>{" "}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default OdysseyLogin; 