import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  Code,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export const AdditionalInfoToDisplay = ({
  starDataObject,
  boundData,
  editQuoteRes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // Use theme colors for consistency across different themes
  const primaryColor = useColorModeValue("blue.500", "teal.500");
  const textColor = useColorModeValue("black", "white");

  return (
    <>
      <Button
        size="md"
        onClick={handleOpen}
        borderWidth="1px"
        borderColor={primaryColor}
        color={textColor}
        borderRadius="12px"
        variant="outline"
      >
        View Additional Log(s)
      </Button>

      <Modal
        variant={"unstyled"}
        isCentered
        scrollBehavior="inside"
        size="full"
        preserveScrollBarGap
        isOpen={isOpen}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent bg="gray.200">
          <ModalHeader>Quotation to Bind Logs</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              <Box>
                <Text fontSize={"xl"} color={'neutral.500'}>Quote Response</Text>
                <Code fontWeight={600} as={"pre"} bg={"wheat"} fontSize="md">
                  {JSON.stringify(starDataObject, null, 2)}
                </Code>
              </Box>
              <Box>
                <Text fontSize={"xl"} color={'neutral.500'}>Updated Vendors Response</Text>
                <Code
                  as={"pre"}
                  bg={"blanchedalmond"}
                  fontWeight={600}
                  fontSize="md"
                >
                  {JSON.stringify(editQuoteRes, null, 2)}
                </Code>
              </Box>
              <Box>
                <Text fontSize={"xl"} color={'neutral.500'}>Bound Response</Text>
                <Code as={"pre"} bg={"tinyrose"} fontWeight={600} fontSize="md">
                  {JSON.stringify(boundData, null, 2)}
                </Code>
              </Box>
            </Grid>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"sm"}
              borderRadius={"sl"}
              mr={3}
              onClick={handleClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
