import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { postEndorseBind } from "../api/postEndorseBind";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getCvg } from "../../../../services/coreSystemService";
import { isAllowedToBindQuoteInHiscoxFloodPlus } from "../../../../services/moduleVisibilityService";
import { EncryptOrDecrypt } from "../../../../services/commonService";
import { FaLink } from "react-icons/fa";
import { getBindStatus } from "../api/saveBindAndIssueToDB";

export const BindEndorsement = ({
  policyNumber,
  productType,
  commercialProductType,
  coreIDs,
  quoteDetailData,
  setLoading,
}) => {
  const [coverageData, setCoverageData] = useState(null);
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state);
  const [bindClicked, setBindClicked] = useState(false);
  const [callBind, setCallBind] = useState(false);
  const userDatas = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const userLevel = userDatas.accessRights.levelName;
  const userSubLevel = userDatas.accessRights.subLevelName;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleBindClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmBind = () => {
    setShowConfirmModal(false);
    setBindClicked(true);
  };

  const handleCancelBind = () => {
    setShowConfirmModal(false);
  };

  const getCoverageInfo = async () => {
    const policyNoID = coreIDs.PolicyPK;
    const termMasterPK = coreIDs.TransPK;
    try {
      setLoading(true);
      const res = await dispatch(getCvg(policyNoID, termMasterPK));
      if (res?.data) {
        setCoverageData([
          ...(res?.data?.basicCvgData || {}),
          ...(res?.data?.optionalCvgData || {}),
        ]);
        setCallBind(true);
      } else {
        setCoverageData(null);
        toast.error("Can't Fetch Policy Details!");
      }
    } catch (error) {
      toast.error("Something Went Wrong!");
    } finally {
      setBindClicked(false);
      setLoading(false);
    }
  };

  async function fetchBindStatus() {
    try {
      setLoading(true);
      const res = await getBindStatus({ trans_pk: coreIDs.TransPK });
      if (res && res?.status === true) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function findCoverageInfo(data, coverageCode) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].s_CoverageCode === coverageCode) {
        return data[i];
      }
    }
    return null;
  }

  useEffect(() => {
    if (bindClicked) {
      getCoverageInfo();
    }
  }, [bindClicked]);

  useEffect(() => {
    if (callBind && coverageData !== null) {
      bindPremium();
    }
  }, [coverageData, callBind]);

  const bindPremium = async () => {
    const reqBody = {
      productType,
      commercialProductType: commercialProductType || "",
      policyType:
        quoteDetailData?.RA?.metadata?.premium?.selectedPremium?.type || "",
      premium: Number(
        parseFloat(
          reduxStore?.coreSystem?.applicationData?.n_PremiumChange
        ).toFixed(2)
      ),
      includeLossOfUse: !commercialProductType
        ? findCoverageInfo(coverageData, "HOMCVGD")?.n_InsuredValue * 1 !== 0
        : findCoverageInfo(coverageData, "BUSIINCOMEEXP")?.n_InsuredValue *
            1 !==
          0,
      includeContents:
        findCoverageInfo(coverageData, "FLDCONTCVGAMT")?.n_InsuredValue * 1 !==
        0,
      buildingLimit:
        commercialProductType === "Tenanted"
          ? findCoverageInfo(coverageData, "FLDBLDCVGAMT")?.n_InsuredValue * 1
          : findCoverageInfo(coverageData, "FLDBLDCVGAMT")?.n_InsuredValue * 1,
      contentsLimit:
        quoteDetailData?.RA?.metadata?.premium?.selectedPremium
          ?.endorsementContentsOriginalLimit ||
        quoteDetailData?.RA?.metadata?.premium?.selectedPremium
          ?.contentsLimitOrignal,
      deductible:
        findCoverageInfo(
          coverageData,
          "FLDCONTCVGAMT"
        )?.s_PrDiscountCode?.replace("FLDDED", "") * 1,
    };

    const cleanedRequestBody = Object.fromEntries(
      Object.entries(reqBody).filter(([_, value]) => value !== null)
    );
    const quoteNo = policyNumber;
    try {
      setLoading(true);
      if (reduxStore?.coreSystem?.applicationData?.noPremiumChangeEndorse) {
        fetchBindStatus();
        return;
      }
      const res = await postEndorseBind({
        requestBody: cleanedRequestBody,
        policyId: quoteNo,
      });

      if (res?.status === false && Array.isArray(res?.errors)) {
        res.errors.forEach((error) =>
          toast.error(error, { autoClose: 5000, toastId: "premiumUpdated" })
        );
      } else if (res?.status === true) {
        toast.success("Quote Bound Successfully", {
          autoClose: 5000,
          toastId: "premiumUpdated1",
        });
        fetchBindStatus();
      }
    } catch (error) {
      console.error("Error binding premium:", error);
    } finally {
      setLoading(false);
      setCallBind(false);
    }
  };

  return (
    <>
      {isAllowedToBindQuoteInHiscoxFloodPlus(userLevel, userSubLevel) && (
        <>
          <Button
            className="d-flex alignt-items-center gap-2"
            onClick={handleBindClick}
            id="common-btn"
            variant="primary"
            size="sm"
            style={{ padding: "4px  6px" }}
          >
            <FaLink size={18} />
            Bind Endorsement
          </Button>
        </>
      )}

      <Modal size="lg" show={showConfirmModal} onHide={handleCancelBind}>
        <Modal.Header id="common-btn" closeButton>
          <Modal.Title>Confirm Binding Endorsement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to bind the endorsement for this policy?
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="common-btn"
            style={{ padding: "6px 8px" }}
            variant="secondary"
            onClick={handleCancelBind}
          >
            Cancel
          </Button>
          <Button
            id="common-btn"
            style={{ padding: "6px 8px" }}
            variant="primary"
            onClick={handleConfirmBind}
          >
            Bind Endorsement
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
