import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import Inbox from "./pages/activity-log/inbox/Inbox";
import MessageView from "./pages/activity-log/message-view/MessageView";
import Reply from "./pages/activity-log/reply/Reply";
import Dashboard from "./pages/dashboard/Dashboard";
import ManagementReports from "./pages/management-reports/ManagementReports";
import OtherLinks from "./pages/other-links/OtherLinks";
import PolicyDetails from "./pages/policy/policy-details/PolicyDetails";
import SearchPolicy from "./pages/policy/search-policy/SearchPolicy";
import Quote from "./pages/quote/Quote";
import Report from "./pages/report/Report";
import BlankLayout from "./components/layout/BlankLayout/BlankLayout";
import CancelTransactions from "./pages/dashboard/cancel-transactions/CancelTransaction";
import NewClaims from "./pages/dashboard/new-claims/NewClaims";
import PaymentDue from "./pages/dashboard/payment-due/PaymentDue";
import RecentTransactions from "./pages/dashboard/recent-transactions/RecentTransactions";
import UpcomingRenewals from "./pages/dashboard/upcoming-renewals/UpcomingRenewals";
import MarketingTools from "./pages/marketing-tools/MarketingTools";
import ProductOfferings from "./pages/product-offerings/ProductOfferings";
import FloodRoute from "./pages/quote/flood/floodroutes";
import InstaFrontRoute from "./pages/quote/instaQuote/InstaFrontRoute";
import Login from "./pages/login/Login";
import Statistics from "./pages/statistics/Statistics";
import EditApplication from "./pages/quote/EditApplication";
import ProfileInfo from "./pages/profile/Profile";
import Mortgagee from "./pages/mortgagee/Mortgagee";
import MortgageeInformation from "./pages/mortgagee/MortgageeInformation";
import Resources from "./pages/dashboard/resources/Resources";
import Help from "./pages/dashboard/help/Help";
import FindPolicySupport from "./pages/dashboard/help/support/FindPolicySupport";
import PolicySystemSection from "./pages/dashboard/help/support/section/PolicySystemSection";
import DocumentCenter from "./pages/dashboard/Document-Center/DocumentCenter";
import AgencyManagementSection from "./pages/dashboard/help/support/section/AgencyManagementSection";
import ClaimsSystemSection from "./pages/dashboard/help/support/section/ClaimsSystemSection";
import TasksAndRecentWorksSection from "./pages/dashboard/help/support/section/TasksAndRecentWorksSection";
import GettingStartedSection from "./pages/dashboard/help/support/section/GettingStartedSection";
import ApplicationDashboard from "./pages/dashboard/application/ApplicationDashboard";
import ExpireRenewals from "./pages/dashboard/expire-renewal/ExpireRenewals";
import OpenClaims from "./pages/dashboard/open-claim/OpenClaims";
import PaidApplication from "./pages/dashboard/paid-application/PaidApplication";
import PaidRenewal from "./pages/dashboard/paid-renewal/PaidRenewal";
import Cancellation from "./pages/dashboard/cancellation/Cancellation";
import ClosedClaim from "./pages/dashboard/closed-claims/ClosedClaim";
import ACHInformation from "./pages/ACH-Infromation/ACHInformation";
import ClaimDetails from "./pages/claim/ClaimDetails";

import { Provider } from "react-redux";
import store from "./store";
import * as actions from "./store/actions";
import UwLogin from "./pages/login/UwLogin";
import ForgotPassword from "./pages/login/ForgotPassword";
import ForgotUsername from "./pages/login/ForgotUsername";
import ResetPassword from "./pages/login/ResetPassword";
import ResetUsername from "./pages/login/ResetUsername";
import Page404 from "./components/Pages/Page404";
import Page419 from "./components/Pages/Page419";
import Page200 from "./components/Pages/Page200";
import { Helmet } from "react-helmet";
import { EncryptOrDecrypt } from "./services/commonService";
import ReportAClaim from "./pages/claim/ReportAClaim";
import CommissionStatement from "./pages/commission-statement/CommissionStatement";
import { setButtonColor, setTitleColor } from "./setButtonColor";
import CELincense from "./pages/CE-License/CELincense";
import AL3 from "./pages/AL3/AL3";
import AgentTasksDetails from "./pages/dashboard/agent-tasks/AgentTasksDetails";
import OdysseyLogin from "./pages/login/OdysseyLogin";
import EnrolAgent from "./pages/login/EnrolAgent";
import AdvancedSearchPage from "./components/layout/header/AdvancedSearchPage";
import AdvanceSearchQuick from "./components/layout/header/AdvanceSearchQuick";
import HoiLogin from "./pages/login/HoiLogin";
import FarmersLogin from "./pages/login/FarmersLogin";
import HoiQuoteLogin from "./pages/login/HoiQuoteLogin";
import CreateQuote from "./pages/quote/create-quote/CreateQuote";
import QuoteRoutes from "./pages/quote/create-quote/QuoteRoutes";
import PrivateFloodService from "./pages/product-offerings/PrivateFloodService";
import ProducerContainer from "./pages/Producer/ProducerContainer/ProducerContainer";
import PvtFloodServiceForwarder from "./pages/PvtFloodService/Forwarder";
import Index from "./pages/PendingTransaction";
import QuoteStartLandingPage from "./pages/quote/common/ProductBasedQuoteStart";
import HelpSection from "./pages/dashboard/help/HelpSection";
import { isTenancyEnabledForNewLayout } from "./pages/common/Helper";
import StarCommercialLayout from "./pages/quote/create-star-commercial/commercial-quote/StarLayout";
// Check Login
function CheckAuth({ children, redirectTo }) {
  store.dispatch(actions.authCheck());
  let isAuthenticated = store.getState().auth.isAuthenticated;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function App() {
  const tenant = window.location.host.split(".")[0];
  const title =
    tenant.charAt(0).toUpperCase() + tenant.slice(1) + " - Agent Portal";

  const sitePrimaryColor = localStorage.getItem("sitePrimaryColor");
  const siteSecondaryColor = localStorage.getItem("siteSecondaryColor");

  if(sitePrimaryColor && siteSecondaryColor){
    setButtonColor({ color: sitePrimaryColor });
    setTitleColor({ color: siteSecondaryColor });
  }
  
  //to prevent redirection to dashboard if token expired
  var now = new Date();
  var checkTokenExist = localStorage.getItem("access_token_expires_at");
  if (checkTokenExist != null) {
    var tokenExpiresAt = EncryptOrDecrypt(
      localStorage.getItem("access_token_expires_at"),
      "D"
    );
    tokenExpiresAt = new Date(tokenExpiresAt);
    var milliSec = tokenExpiresAt - now;
    const check = Math.sign(milliSec); //returns 1 or -1
    if (check === -1) {
      localStorage.clear();
    }
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              path="/login"
              element={
                <BlankLayout>
                  <Login />
                </BlankLayout>
              }
            />
            <Route
              path="/uwlogin/:userToken"
              element={
                <BlankLayout>
                  <UwLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/forgotPassword"
              element={
                <BlankLayout>
                  <ForgotPassword />
                </BlankLayout>
              }
            />
            <Route
              path="/auth/reset-password/:token"
              element={
                <BlankLayout>
                  <ResetPassword />
                </BlankLayout>
              }
            />
            <Route
              path="/forgotUsername"
              element={
                <BlankLayout>
                  <ForgotUsername />
                </BlankLayout>
              }
            />
            <Route
              path="/auth/reset-username/:token"
              element={
                <BlankLayout>
                  <ResetUsername />
                </BlankLayout>
              }
            />
            <Route
              path="/404"
              element={
                <BlankLayout>
                  <Page404 />
                </BlankLayout>
              }
            />
            <Route
              path="/419"
              element={
                <BlankLayout>
                  <Page419 />
                </BlankLayout>
              }
            />
            <Route
              path="/200/:source"
              element={
                <BlankLayout>
                  <Page200 />
                </BlankLayout>
              }
            />
            <Route
              path="/odysseyLogin"
              element={
                <BlankLayout>
                  <OdysseyLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/enrol-agent"
              element={
                <BlankLayout>
                  <EnrolAgent />
                </BlankLayout>
              }
            />
            <Route
              path="/"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/cancel-transactions"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <CancelTransactions />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/new-claims"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <NewClaims />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/editApplication/:productID/:transID"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <EditApplication />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/editApplication/:productID"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <EditApplication />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/payment-due"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PaymentDue />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/recent-transactions"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <RecentTransactions />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/upcoming-renewals"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <UpcomingRenewals />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/quote"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Quote />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/core/policy"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <SearchPolicy />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/private/floodService"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PrivateFloodService />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/core/policy/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicyDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/core/policy/:type/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicyDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Inbox />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/:productID/create/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <CreateQuote />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/:productID/start/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <QuoteStartLandingPage />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/quote/:productID/application/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <QuoteRoutes />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <MessageView />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/:id/reply"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Reply />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/active-log/new-activity"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Reply />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/commission-statement"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <CommissionStatement />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/reports"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Report />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/other-links"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <OtherLinks />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/statistics"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Statistics />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/management-reports"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ManagementReports />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/marketing-tools"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <MarketingTools />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/flood/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <FloodRoute />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/instaQuote/*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <InstaFrontRoute />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/product-offerings"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ProductOfferings />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="*"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ProfileInfo />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/mortgagee"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Mortgagee />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/mortgagee/information/:policyNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <MortgageeInformation />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/celicense"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <CELincense />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/resources"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Resources />
                  </MainLayout>
                </CheckAuth>
              }
            />

            {isTenancyEnabledForNewLayout ? (
              <Route
                path="/help"
                element={
                  <CheckAuth redirectTo="/login">
                    <MainLayout>
                      <HelpSection />
                    </MainLayout>
                  </CheckAuth>
                }
              />
            ) : (
              <Route
                path="/help"
                element={
                  <CheckAuth redirectTo="/login">
                    <MainLayout>
                      <Help />
                    </MainLayout>
                  </CheckAuth>
                }
              />
            )
            }

            <Route
              path="/documentcenter"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <DocumentCenter />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/findPolicySupport/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <FindPolicySupport />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/findPolicySupport/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <FindPolicySupport />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/PolicySystemSection/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicySystemSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/PolicySystemSection/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PolicySystemSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/AgencyManagementSection/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AgencyManagementSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/AgencyManagementSection/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AgencyManagementSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="ClaimsSystemSection/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ClaimsSystemSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="ClaimsSystemSection/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ClaimsSystemSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="TasksAndRecentWorksSection/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <TasksAndRecentWorksSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="TasksAndRecentWorksSection/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <TasksAndRecentWorksSection />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="GettingStartedSection/:id"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <GettingStartedSection />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="GettingStartedSection/:id/:div"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <GettingStartedSection />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/claim/report-a-claim/:policyNo"
              element={
                <MainLayout>
                  <ReportAClaim />
                </MainLayout>
              }
            />
            <Route
              path="/claim/view-a-claim/:policyNo"
              element={
                <MainLayout>
                  <ReportAClaim />
                </MainLayout>
              }
            />
            <Route
              path="/uwlogin/:userToken/:policyNo"
              element={
                <BlankLayout>
                  <UwLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/al3"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AL3 />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/agent-tasks"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AgentTasksDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/applications"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ApplicationDashboard />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/expire-renewals"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ExpireRenewals />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/open-claim"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <OpenClaims />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/paid-application"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PaidApplication />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/paid-renewal"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <PaidRenewal />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/cancellation"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Cancellation />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/closed-claim"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ClosedClaim />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/ACH-Information"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ACHInformation />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/advanced-search"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AdvancedSearchPage />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/quick-search"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <AdvanceSearchQuick />
                  </MainLayout>
                </CheckAuth>
              }
            />

            <Route
              path="/hoiLogin/:userToken/:transPK"
              element={
                <BlankLayout>
                  <HoiLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/FarmersLogin/:userToken"
              element={
                <BlankLayout>
                  <FarmersLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/FarmersLogin/:userToken/:policyNo/:transPK"
              element={
                <BlankLayout>
                  <FarmersLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/HoiQuoteLogin/:userToken/:transPK"
              element={
                <BlankLayout>
                  <HoiQuoteLogin />
                </BlankLayout>
              }
            />
            <Route
              path="/pending-transaction"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <Index />
                  </MainLayout>
                </CheckAuth>
              }
            />
             <Route
              path="/producer"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ProducerContainer />
                  </MainLayout>
                </CheckAuth>
              }
            />
            <Route
              path="/producer/:agencyId"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ProducerContainer />
                  </MainLayout>
                </CheckAuth>
              }
              />
            <Route
              path="/core/claim/:claimNumber"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <ClaimDetails />
                  </MainLayout>
                </CheckAuth>
              }
            />
             <Route
              path="quote/starcom/:quoteNumber?"
              element={
                <CheckAuth redirectTo="/login">
                  <MainLayout>
                    <StarCommercialLayout />
                  </MainLayout>
                </CheckAuth>
              }
            />
			      <Route
              path="private/:privateProductType/portal"
              element={
                <CheckAuth redirectTo="/">
                    <PvtFloodServiceForwarder />
                </CheckAuth>
              }
            />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
