import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ManualAddress = (props) => {
    const { selectedPropertyData, setSelectedPropertyData, validateManualInput } = props;
    const { t } = useTranslation();

    // set data
    const handleChange = (state, value) => {
        if (state === 'PostalCode') {
            value = value.substring(0, 5) || null;
        }
        if (state === 's_PostalCodeSuffix') {
            value = value.substring(0, 4) || null;
        }

        const propData = { ...selectedPropertyData };
        propData[state] = value;
        setSelectedPropertyData(propData);
    }

    return (
        <Row className="justify-content-center confirm-address-form">
            <Form validated={validateManualInput}>
                <Col xs="12">
                    <Row>
                        <Col xs="3">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Street Number')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Street Number")}
                                    name="street_number"
                                    title={t('Street Number')}
                                    value={selectedPropertyData.street_number || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="9">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Street Name')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Street Name")}
                                    name="street_name"
                                    title={t('Street Name')}
                                    value={selectedPropertyData.street_name || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Zip Code')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Zip Code")}
                                    name="PostalCode"
                                    title={t('Zip Code')}
                                    value={selectedPropertyData.PostalCode || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Zip Suffix')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Zip Suffix")}
                                    name="s_PostalCodeSuffix"
                                    title={t('Zip Suffix')}
                                    value={selectedPropertyData.s_PostalCodeSuffix || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('County')}
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("County")}
                                    name="CountyName"
                                    title={t('County')}
                                    value={selectedPropertyData.CountyName || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('State')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("State")}
                                    name="state"
                                    title={t('State')}
                                    value={selectedPropertyData.state || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="3">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('City')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("City")}
                                    name="city"
                                    title={t('City')}
                                    value={selectedPropertyData.city || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="2">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Country')}<span className="span-alert">*</span>
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Country")}
                                    name="country"
                                    title={t('Country')}
                                    value={selectedPropertyData.country || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="6">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Latitude')}
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Latitude")}
                                    name="latitude"
                                    title={t('latitude')}
                                    value={selectedPropertyData.latitude || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="6">
                            <Form.Group
                                className="confirm-address-field"
                            >
                                <Form.Label className="confirm-address-label">
                                    {t('Longitude')}
                                </Form.Label>
                                <Form.Control
                                    className="confirm-address-input"
                                    type="text"
                                    placeholder={t("Longitude")}
                                    name="longitude"
                                    title={t('Longitude')}
                                    value={selectedPropertyData.longitude || ''}
                                    onChange={(e) => {
                                        handleChange(e.currentTarget.name, e.currentTarget.value);
                                    }}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Col xs="12">
                <span className="span-diff-color">{"** "} {t("To find your coordinates go to google maps, locate your structure, right click the structure (or the spot the structure will be) and you will see the coordinates, click, to copy, and paste into fields above. The first number is latitude, second is longitude")}. </span>
            </Col>
        </Row>
    )
}

export default ManualAddress;