import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Select,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import states from "../../../common/States";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import { MdOutlineAddBox } from "react-icons/md";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import { useDispatch } from "react-redux";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { postDeletePolicyMortagee } from "../api/DeleteMortg";

export default function Mortgagee({ quoteData, isBind }) {
  const { t } = useTranslation();
  const [saveMortgagee, setSaveMortgagee] = useState([]);
  const [hasMortgagee, setHasMortgagee] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [mortgagees, setMortgagees] = useState({
    type: "Primary",
    bankName: "",
    loanNumber: "",
    address: "",
    zipCode: "",
    stateCode: "",
    city: "",
    county: "",
    cityPk: "",
    statePk: "",
    countyPk: "",
    countryPk: "",
  });
  const [addedMortgages, setAddedMortgages] = useState([]);
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();

  const getMortgageeType = (personType) => {
    switch (personType) {
      case "PRIMARY":
        return "Primary";
      case "SECOND":
        return "Secondary";
      case "THIRD":
        return "Third";
      default:
        return null;
    }
  };

  const getMortgageeTypeDB = (personType) => {
    switch (personType) {
      case "PRIMARY":
        return "First mortgagee";
      case "SECOND":
        return "Second mortgagee";
      case "THIRD":
        return "Third mortgagee";
      default:
        return null;
    }
  };

  const extractMortgagees = (data) => {
    if (!Array.isArray(data) || (Array.isArray(data) && data.length < 1)) {
      return [];
    }
    const mortgagees = [];
    data.forEach((item) => {
      if (
        !["ADDITIONALINTEREST", "ADDITIONALINSURED"].includes(item.s_PersonType)
      ) {
        mortgagees.push({
          n_PolicyAddInterest_PK: item.n_PolicyAddInterest_PK || null,
          personType: getMortgageeTypeDB(item.s_PersonType) || "",
          type: getMortgageeType(item.s_PersonType) || "",
          loanNumber: item.s_LoanNo || "",
          bankName: item.person.s_LastOrganizationName || "",
          address: item.address.s_AddressLine1 || "",
          zipCode: item.address.s_PostalCode || "",
          stateCode: item.address.s_StateName || "",
          city: item.address.s_CityName || "",
          county: item.address.s_CountyName || "",
          cityPk: item.address.n_CityId_FK || "",
          statePk: item.address.n_StateId_FK || "",
          countyPk: item.address.n_CountyId_FK || "",
          countryPk: 1,
          person: {
            entityType:
              typeof item.person.s_EntityType === "string"
                ? item.person.s_EntityType.toUpperCase()
                : item.person.s_EntityType || "",
            firstName: item.person.s_FirstName || "",
            middleName: item.person.s_MiddleName || "",
            lastName: item.person.s_LastOrganizationName || "",
            isMailingDiff: item.person.s_IsMailingDiff || "",
            isTempMailing: item.person.s_IsTempMailing || "",
            n_PersonInfoId_PK: item.person.n_PersonInfoId_PK || null,
            isInsuredTenant: "",
          },
          addresss: {
            addressLine1: item.address.s_AddressLine1 || "",
            addressLine2: item.address.s_AddressLine2 || "",
            county: item.address.s_CountyName || "",
            city: item.address.s_CityName || "",
            cityId: item.address.n_CityId_FK || 0,
            stateId: item.address.n_StateId_FK || 0,
            countryId: item.address.n_CountryId_FK || 1,
            stateCode: item.address.s_StateName || "",
            country: "US",
            countyId: item.address.n_CountyId_FK || 0,
            latitude: parseFloat(item.address.s_Latitude) || null,
            longitude: parseFloat(item.address.s_Longitude) || null,
            zip: item.address.s_PostalCode || "",
          },
        });
      }
    });

    return mortgagees;
  };

  const deleteMortgagee = (index) => {
    const newMortgagees = [...saveMortgagee];
    newMortgagees.splice(index, 1);
    setSaveMortgagee(newMortgagees);
    setAddedMortgages(newMortgagees);
    AddDeleteMortgagee(newMortgagees);
  };

  const deleteMortagee = async (mortgagee) => {
    // return;
    try {
      // setLoading(true);
      if (
        mortgagee?.n_PolicyAddInterest_PK === null ||
        mortgagee?.n_PolicyAddInterest_PK === undefined
      ) {
        toast.success(t("Mortgagee Deleted Successfully", { autoClose: 1500 }));
      } else {
        const reqBody = {
          transPK: quoteData?.transactionInfo?.n_potransaction_PK,
          n_PolicyAddInterest_PK: mortgagee?.n_PolicyAddInterest_PK,
        };
        const response = await postDeletePolicyMortagee(reqBody);
        if (response.success && response.data.Status === "Y") {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          toast.success(
            t("Mortgagee Deleted Successfully", { autoClose: 1500 })
          );
        }
      }
    } catch (error) {
      console.error("Error deleting mortgagee:", error);
      toast.error(t("Error deleting mortgagee. Please try again."));
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    setHasMortgagee(saveMortgagee.length > 0);
    if (saveMortgagee.length > 0) {
      setMortgagees({
        ...mortgagees,
        type: "Primary",
      });
    } else if (saveMortgagee.length > 1) {
      setMortgagees({
        ...mortgagees,
        type: "Secondary",
      });
    } else if (saveMortgagee.length > 2) {
      setMortgagees({
        ...mortgagees,
        type: "Third",
      });
    }
  }, [saveMortgagee]);

  const createMortgageeData = (mortgageeItem) => ({
    n_PersonAddressesId_PK: 0,
    s_PersonType:
      mortgageeItem?.type?.toLowerCase() === "primary"
        ? "PRIMARY"
        : mortgageeItem?.type?.toLowerCase() === "secondary"
        ? "SECOND"
        : "THIRD",
    s_MortgageeType:
      mortgageeItem?.type?.toLowerCase() === "primary"
        ? "First mortgagee"
        : mortgageeItem?.type?.toLowerCase() === "secondary"
        ? "Second mortgagee"
        : "Third mortgagee",
    s_LoanNo: mortgageeItem?.loanNumber ?? "",
    person: {
      s_EntityType: "INDIVIDUAL",
      s_FirstName: "",
      middleName: "",
      s_FullLegalName: mortgageeItem?.bankName ?? "",
      s_LastOrganizationName: mortgageeItem?.bankName ?? "",
      isMailingDiff: "",
      isTempMailing: "",
      isInsuredTenant: "",
    },
    address: {
      s_AddressLine1: mortgageeItem?.address ?? "",
      s_AddressLine2: "",
      s_CountyName: mortgageeItem?.county ?? "",
      s_CityName: mortgageeItem?.city ?? "",
      n_CityId_FK: mortgageeItem?.cityId ?? "",
      n_StateId_FK: mortgageeItem?.stateId ?? "",
      s_StateName: mortgageeItem?.stateCode ?? "",
      n_CountryId_FK: 1,
      n_CountyId_FK: mortgageeItem?.countyId ?? "",
      latitude: 0,
      longitude: 0,
      s_PostalCode: mortgageeItem?.zipCode ?? "",
    },
  });

  function AddDeleteMortgagee(mortg) {
    let tempquoteData = quoteData;
    tempquoteData.mortgageeData = mortg;
    dispatch(getEditEmptyQuoteData(tempquoteData));
  }

  useEffect(() => {
    setSaveMortgagee(extractMortgagees(quoteData.mortgageeData));
  }, [quoteData]);

  useEffect(() => {
    if (showError) {
      setShowError(false);
    }
  }, [mortgagees]);

  useEffect(() => {
    setMortgagees({
      type: mortgagees.type,
      bankName: "",
      loanNumber: "",
      address: "",
      zipCode: "",
      stateCode: "",
      city: "",
      county: "",
      cityPk: "",
      statePk: "",
      countyPk: "",
      countryPk: "",
    });
  }, [mortgagees.type]);

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    mortgagees.zipCode,
    true
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData) {
      setMortgagees({
        ...mortgagees,
        stateCode: zipData?.s_StateCode,
        city: zipData?.s_CityName,
        cityId: zipData.city_id ?? 0,
        stateId: zipData.state_id ?? 0,
        countryId: zipData.n_CountryId_FK ?? 1,
        countyId: zipData.county_id ?? 0,
        county: zipData.s_CountyName ?? "",
      });
    }
  }, [zipData]);

  const handleAddMortgagee = () => {
    const availableTypes = ["Primary", "Secondary", "Third", ""].filter(
      (type) => !addedMortgages.some((m) => m.type === type)
    );

    if (availableTypes.includes(mortgagees.type)) {
      if (
        mortgagees.type &&
        mortgagees.bankName &&
        mortgagees.address &&
        mortgagees.zipCode &&
        mortgagees.stateCode &&
        mortgagees.city
      ) {
        const newMortgagees = [...saveMortgagee, mortgagees];
        setSaveMortgagee(newMortgagees);
        setAddedMortgages([...addedMortgages, mortgagees]);
        let morgArr =
          Array.isArray(newMortgagees) && newMortgagees.length > 0
            ? newMortgagees.map(createMortgageeData)
            : [];
        AddDeleteMortgagee(morgArr);
        const nextMortgageType = availableTypes[0] || "";
        setMortgagees({ type: nextMortgageType });

        const updatedAvailableTypes = availableTypes.filter(
          (type) => type !== nextMortgageType
        );
        if (updatedAvailableTypes.length > 0) {
          setMortgagees({ type: updatedAvailableTypes[0] });
          // toast.info(`Next mortgage type: ${updatedAvailableTypes[0]}`);
        } else {
          if (mortgagees.type !== "Third") {
            toast.info("No more mortgage types available.");
          }
        }
      } else {
        toast.warn("Please fill all the required fields.");
        setShowError(true);
      }
    } else {
      toast.warn(`You have already added a ${mortgagees.type} mortgage.`);
    }
  };

  const handleSwitchChange = () => {
    if (hasMortgagee) {
      AddDeleteMortgagee([]);
    }
    setHasMortgagee(!hasMortgagee);
  };

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Box background="var(--chakra-colors-white)" mt={5} padding="0px">
        <Flex
          background="var(--chakra-colors-primary-50)"
          borderRadius={"xl"}
          p={"0.1rem"}
          mb="20px"
          alignItems={"center"}
          pr={3}
        >
          <Text
            fontSize={"xl"}
            mt={3}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-medium)"
          >
            {t("mortgagees")}
          </Text>
          <Spacer />
          <Switch
            isDisabled={isBind}
            isChecked={hasMortgagee}
            onChange={handleSwitchChange}
            size={"lg"}
          />
        </Flex>
        <Box>
          {hasMortgagee && (
            <>
              {saveMortgagee?.length < 3 && addMore && (
                <Box p="1.5rem" pt={0}>
                  <Accordion
                    borderRadius="xl"
                    border="1px solid"
                    borderColor={"neutral.100"}
                    overflow={"hidden"}
                    defaultIndex={[0]}
                    width="100%"
                    allowMultiple
                  >
                    <AccordionItem>
                      <AccordionButton p={4} bg="primary.50">
                        <Flex
                          alignItems={"center"}
                          gap={3}
                          ml={4}
                          textAlign="left"
                          fontSize={"lg"}
                        >
                          Add Mortgagees{" "}
                          {isLoading && (
                            <Spinner size={"lg"} color="primary.500" />
                          )}
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Grid p={2} templateColumns="repeat(3, 1fr)" gap={6}>
                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="type" fontWeight={"400"}>
                                Mortgage Type
                              </FormLabel>
                              <Select
                                border={"2px solid"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                                size={"lg"}
                                value={mortgagees.type}
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    type: e.target.value,
                                  });
                                }}
                              >
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                                <option value="Third">Third</option>
                                <option value=""></option>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                (Bank / Organization / Mortgage) Name
                              </FormLabel>
                              <Input
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    bankName: e.target.value,
                                  });
                                }}
                                border={"2px solid"}
                                value={mortgagees.bankName}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              />
                            </FormControl>
                            {showError && !mortgagees.bankName && (
                              <Text fontSize={"sm"} color={"red.500"}>
                                Required
                              </Text>
                            )}
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                Loan Number / ID
                              </FormLabel>
                              <Input
                                type="text"
                                value={mortgagees.loanNumber}
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    loanNumber: e.target.value,
                                  });
                                }}
                                border={"2px solid"}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                        <Box
                          mx={2}
                          my={2}
                          borderTop={"1px solid"}
                          borderColor={"gray.200"}
                        />
                        <Grid p={2} templateColumns="repeat(4, 1fr)" gap={6}>
                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                Address
                              </FormLabel>
                              <Input
                                value={mortgagees.address}
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    address: e.target.value,
                                  });
                                }}
                                border={"2px solid"}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              />
                              {showError && !mortgagees.address && (
                                <Text fontSize={"sm"} color={"red.500"}>
                                  Required
                                </Text>
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                City
                              </FormLabel>
                              <Input
                                bg={"gray.100"}
                                cursor={"not-allowed"}
                                isDisabled
                                _disabled={{ opacity: 1 }}
                                type="text"
                                value={mortgagees.city}
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    city: e.target.value,
                                  });
                                }}
                                border={"2px solid"}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              />
                              {showError && !mortgagees.city && (
                                <Text fontSize={"sm"} color={"red.500"}>
                                  Required
                                </Text>
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                State / State Code
                              </FormLabel>
                              <Select
                                bg={"gray.100"}
                                cursor={"not-allowed"}
                                isDisabled
                                _disabled={{ opacity: 1 }}
                                value={mortgagees.stateCode}
                                onChange={(e) => {
                                  setMortgagees({
                                    ...mortgagees,
                                    stateCode: e.target.value,
                                  });
                                }}
                                border={"2px solid"}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              >
                                <option value=""></option>
                                {states.map((state) => (
                                  <option key={state.name} value={state.abbr}>
                                    {state.name} ({state.abbr})
                                  </option>
                                ))}
                              </Select>
                              {showError && !mortgagees.stateCode && (
                                <Text fontSize={"sm"} color={"red.500"}>
                                  Required
                                </Text>
                              )}
                            </FormControl>
                          </GridItem>

                          <GridItem>
                            <FormControl isRequired>
                              <FormLabel htmlFor="" fontWeight={"400"}>
                                Zip Code
                              </FormLabel>
                              <Input
                                value={mortgagees.zipCode}
                                onChange={(e) => {
                                  if (e.target.value.length <= 5) {
                                    setMortgagees({
                                      ...mortgagees,
                                      zipCode: e.target.value,
                                    });
                                  }
                                }}
                                type="number"
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                  e.preventDefault()
                                }
                                border={"2px solid"}
                                size={"lg"}
                                borderColor={"neutral.500"}
                                borderRadius={"xl"}
                              />
                              {showError && !mortgagees.zipCode && (
                                <Text fontSize={"sm"} color={"red.500"}>
                                  Required
                                </Text>
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>
                        <Flex justifyContent="flex-end" mt={3} mr={2} mb={0}>
                          <Flex flexDir={"column"} gap={2}>
                            <Flex
                              gap={2}
                              w={"100%"}
                              justifyContent={"flex-end"}
                            >
                              <Button
                                size={"sm"}
                                onClick={handleAddMortgagee}
                                fontWeight={400}
                                borderRadius={"xl"}
                                variant={"outline"}
                                isDisabled={
                                  Array.isArray(saveMortgagee) &&
                                  saveMortgagee.length === 3
                                }
                              >
                                <Flex alignItems="center" gap={2}>
                                  <MdOutlineAddBox color="primary.500" />
                                  Add {mortgagees.type} Mortgage
                                </Flex>
                              </Button>
                            </Flex>
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              )}
              {saveMortgagee?.length === 0 && (
                <Flex px={8} pb={1} justifyContent={"space-between"}>
                  <Text>Would you like to add mortgagees?</Text>

                  <Spacer />
                  <Switch
                    isDisabled={isBind}
                    isChecked={addMore}
                    onChange={(e) => {
                      setAddMore(e.target.checked);
                    }}
                    size={"lg"}
                  />
                </Flex>
              )}
              {saveMortgagee?.length > 0 && saveMortgagee?.length < 3 && (
                <Flex px={8} pb={1} justifyContent={"space-between"}>
                  <Text>Would you like to add additional mortgagees?</Text>

                  <Spacer />
                  <Switch
                    isDisabled={isBind}
                    isChecked={addMore}
                    onChange={(e) => {
                      setAddMore(e.target.checked);
                    }}
                    size={"lg"}
                  />
                </Flex>
              )}
              {Array.isArray(saveMortgagee) && saveMortgagee.length > 0 && (
                <Box px={6} pb={3} overflowX="auto">
                  <Box
                    border={"1px solid"}
                    borderColor={"primary.50"}
                    borderRadius={"xl"}
                  >
                    <Table
                      variant="simple"
                      border={"1px solid"}
                      borderColor={"gray.200"}
                      borderRadius={"xl"}
                      overflow={"hidden"}
                    >
                      <Thead bg={"primary.50"}>
                        <Tr>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Type
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Bank Name
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Loan Number / ID
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Address
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Zip Code
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            State / State Code
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            City
                          </Th>
                          <Th border={"1px solid"} borderColor={"primary.50"}>
                            Actions
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Array.isArray(saveMortgagee) &&
                          saveMortgagee?.length > 0 &&
                          saveMortgagee.map((mortgagee, index) => (
                            <Tr key={index} _hover={{ bg: "gray.100" }}>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.type}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.bankName}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.loanNumber}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                <Tooltip
                                  label={mortgagee.address}
                                  placement="top"
                                >
                                  <span>
                                    {mortgagee.address.length > 25
                                      ? `${mortgagee.address.substring(
                                          0,
                                          25
                                        )}...`
                                      : mortgagee.address}
                                  </span>
                                </Tooltip>
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.zipCode}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.stateCode}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                {mortgagee.city}
                              </Td>
                              <Td
                                border={"1px solid"}
                                borderColor={"primary.50"}
                              >
                                <Flex
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <IconButton
                                    aria-label="Delete"
                                    icon={<FaTrash />}
                                    size="sm"
                                    onClick={() => {
                                      deleteMortgagee(index);
                                      deleteMortagee(mortgagee);
                                    }}
                                  />
                                </Flex>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
