import React from "react";
import profile from "../../../help/Agency_Management_Profile.pdf";
import al3 from "../../../help/Agency_Management_AL3.pdf";
import CELicence from "../../../help/Agency_Management_CE.pdf";
import { negativeButton, commissions } from "../../s3Pdf";
import { Box, Center, Image, Text, VStack, Wrap, WrapItem, Link } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const AgencyManagementSection = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Wrap width={"52rem"}>
            <Link href={profile} target="_blank"  p={1}>
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/sendIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("Edit Profile")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={CELicence} target="_blank">
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"xl"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("ce_license")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={al3} target="_blank">
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"xl"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/triangleResourceIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("al3")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={commissions} target="_blank">
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"xl"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("Commissions")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={negativeButton} target="_blank">
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"xl"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("negative_button")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
            </Wrap>
        </div>
    )
}

export default AgencyManagementSection;