import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RequestUnderwriterReview = (props) => {
    const { handleChange, endorsementJSON } = props;

    const { t } = useTranslation();

    const [review, setReview] = useState('');

    useEffect(() => {
        if (Object.keys(endorsementJSON.new.RequestUnderwriterReview).length > 0) {
            setReview(endorsementJSON.new.RequestUnderwriterReview.review.review)
        }
    }, [endorsementJSON])

    const handleStateChange = (value, state) => {
        setReview(value);
        handleChange(state, '', value, "RequestUnderwriterReview");
    }

    return (
        <>
            <Row>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="8">
                    <Form.Control
                        as="textarea"
                        onChange={(e) => { handleStateChange(e.target.value, 'review'); }}
                        rows={4}
                        value={review}
                    />
                </Col>
            </Row>
        </>
    )
}

export default RequestUnderwriterReview;
