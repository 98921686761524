import React, { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Input,
  InputGroup,
  InputRightElement,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Popover,
  Box,
} from "@chakra-ui/react";

const DateInputs = ({
  value,
  onChange,
  styleProps,
  error,
  placeholder,
  minDate,
  maxDate,
  isDisabled,
  placement
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [format] = useState("MM/DD/YYYY");

  const handleDatePick = (newDate) => {
    const newDateMoment = moment(newDate);
    if (!newDateMoment.isValid()) {
      console.log("Invalid time value");
      return;
    }
    onChange(moment(newDate).format(format));
    setIsOpen(false);
  };

  const parseDate = (date) => {
    return moment(date, ["MM/DD/YYYY", "YYYY-MM-DD"]).toDate();
  };

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        placement={placement ? "start-end" : "bottom-start"}
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              placeholder={placeholder || ""}
              value={value}
              isDisabled={isDisabled || false}
              {...styleProps}
              _disabled={{opacity: 1}}
              onChange={(e) => {
                const val = e.target.value;
                const dateMoment = moment(val, format);
                if (!dateMoment.isValid()) {
                  console.log("Invalid time value");
                  onChange("");
                } else {
                  onChange(val);
                }
              }}
              isInvalid={!!error}
            />
            {isDisabled !== true && (
              <InputRightElement>
                <Box onClick={() => setIsOpen(!isOpen)} mt={1.5}>
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </Box>
              </InputRightElement>
            )}
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <DatePicker
              selected={value ? moment(value, ["MM/DD/YYYY", "YYYY-MM-DD"]).toDate() : null}
              onChange={handleDatePick}
              inline
              showYearDropdown
              dropdownMode="select"
              showMonthDropdown
              dateFormat="MM/DD/YYYY"
              minDate={minDate ? parseDate(minDate) : null}
              maxDate={maxDate ? parseDate(maxDate) : null}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default DateInputs;
