import React from 'react'
import {Button, Col, Row, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";

import "./index.scss";
import {selectHiscoxProduct} from "../../../../../../store/selectors/private-flood";
import {updatePrivateProductReport} from "../../../../../../services/floodService";
const FloodHiscoxResult = () => {
  const dispatch = useDispatch();
  const hiscoxProduct = useSelector(state=>selectHiscoxProduct(state))
  const navigation = useNavigate();
  const {t} = useTranslation();
  const hiscox = useSelector(state => selectHiscoxProduct(state))
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  /**
   * go back to previous screen
   */
  const handlePrevious = () => {
    navigation('/flood/instantquote/step/12');
  }
  const hiscoxPremium = hiscox?.premium;
  const getPremiumCoverage = (key, coverageId) => {
    let coverageValue;
    try {
      coverageValue = hiscoxPremium[key].coverages[coverageId]
    }catch (e) {
      coverageValue = 0;
    }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
    return formatter.format(coverageValue);
  }

  const selectPremium = (key) => {
    const reportId = hiscox?.reportId ?? 0;
    const premium = hiscox?.premium[key]?.totalPremium;
    const selectedPremium = hiscox?.premium[key]
    navigation(`/flood/instantquote/bind/macneill?option_id=${selectedPremium.id}&quote_id=${hiscox.quoteNumber}&reportId=${reportId}`);
  }
  return (
    <section className="pricing-details-section">
      <Row className="justify-content-center">
        <Col xs="12" sm="12" md="12" lg="8" xl="8">
          <h5 className="fw-bold">MacNeill insurance options</h5>
        </Col>
        <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pricing-table-main"
        >
          <Table responsive borderless className="pricing-table">
            <thead>
            <tr>
              <th className="flood-pricing-col1">
                <span style={{visibility: "hidden"}}>.</span>
                <div className="pay-plan-col-main">
                  <img src={hiscoxProduct?.logo} height={50} alt="" />
                </div>
              </th>
              <th className="flood-pricing-col2">
                <div>
                  <h5>{t('Good')}</h5>
                  <span className="per-month">{formatter.format(hiscoxPremium.good.totalPremium)} /{t('Year')}</span>
                </div>
              </th>
              <th className="flood-pricing-col3">
                <div>
                  <h5>{t('Better')}</h5>
                  <span className="per-month">{formatter.format(hiscoxPremium.better.totalPremium)} /{t('Year')}</span>
                </div>
              </th>
              <th className="flood-pricing-col4">
                <div>
                  <h5>{t('Best')}</h5>
                  <span className="per-month">{formatter.format(hiscoxPremium.best.totalPremium)} /{t('Year')}</span>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{t('Building')}</td>
              <td>{getPremiumCoverage('good', 'dwelling')}</td>
              <td>{getPremiumCoverage('better', 'dwelling')}</td>
              <td>{getPremiumCoverage('best', 'dwelling')}</td>
            </tr>
            <tr>
              <td>{t('Contents')}</td>
              <td>{getPremiumCoverage('good', 'personalProperty')}</td>
              <td>{getPremiumCoverage('better', 'personalProperty')}</td>
              <td>{getPremiumCoverage('best', 'personalProperty')}</td>
            </tr>
            <tr>
              <td>{t('Other Structures')}</td>
              <td>{getPremiumCoverage('good', 'otherStructures')}</td>
              <td>{getPremiumCoverage('better', 'otherStructures')}</td>
              <td>{getPremiumCoverage('best', 'otherStructures')}</td>
            </tr>
            <tr>
              <td>{t('Loss of Use')}</td>
              <td>{getPremiumCoverage('good', 'lossOfUse')}</td>
              <td>{getPremiumCoverage('better', 'lossOfUse')}</td>
              <td>{getPremiumCoverage('best', 'lossOfUse')}</td>
            </tr>

            <tr>
              <td
                id="flood-pricing-buy-now"
                style={{display: "flex", justifyContent: "center"}}
              >
                <Button
                  id="primary-grey-btn-flood"
                  variant="outline-dark"
                  onClick={() => handlePrevious()}
                  className="previous-btn"
                >
                  {t('Previous')}
                </Button>
              </td>
              <td id="flood-pricing-buy-now">
                <Button
                  id="primary-colored-btn"
                  className="primary"
                  onClick={() => selectPremium('good')}
                >
                  {" "}
                  {t('Select')}{" "}
                </Button>
              </td>
              <td id="flood-pricing-buy-now">
                {" "}
                <Button
                  id="primary-colored-btn"
                  className="primary"
                  onClick={() => selectPremium('better')}
                >
                  {" "}
                  {t('Select')}{" "}
                </Button>
              </td>
              <td id="flood-pricing-buy-now" className="range-col">
                <Button
                  id="primary-colored-btn"
                  className="primary"
                  onClick={() => selectPremium('best')}
                >
                  {" "}
                  {t('Select')}{" "}
                </Button>
              </td>
            </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </section>
  )
}
export default FloodHiscoxResult
