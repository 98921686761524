import { Box, Spinner, Center } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { StepperPage } from "./StarIndex";
import { CommCreateQuote } from "../create-star-commercial/CommQuoteContext";
import { useState, useEffect } from "react";

export const StarLandingPage = () => {
  const { productID } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box>
      {productID === "starinscom" && <CommCreateQuote />}
      {productID === "starins" && <StepperPage />}
    </Box>
  );
};
