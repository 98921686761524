import React, {useEffect, useState}  from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button } from "react-bootstrap";
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import "./Contact.scss";

const Contact = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const [validated, setValidated] = useState(false); 

    var today = new Date();
    var minDate = new Date(today.setDate(today.getDate() + 1)).toISOString().split("T")[0];

    const handleOnclick = (step) => {
        //validation
        if(step == '2'){
            navigation('/instaQuote/InstaSteps/' + step)
        } else {
            var validateStatus = false;
            if(props.s_FullLegalName == "" || props.email == "" || props.phone == "" || props.d_TransEffectiveFrom == ""  ){
                validateStatus = true;
            }
            setValidated(validateStatus);
            if(validateStatus == false){
                navigation('/instaQuote/InstaSteps/' + step)
            }
        }
    }

    return(
        <section className="property-details-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
                    <Row className="justify-content-center" id="contact-info-div">
                        <Col xs="11">
                            <h1>{t("Please Add Your Contact Information")}</h1>
                            <Form validated={validated} className="confirm-address-form">
                            <Row className="justify-content-start">
                                <Col xs="12">
                                        <Row>
                                            <Col xs="12">
                                                <Form.Group className="confirm-address-field" controlId="formBasicEmail">
                                                    <Form.Label className="confirm-address-label">
                                                        {t("Full Name")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="confirm-address-input"
                                                        type="text"
                                                        placeholder={t("Enter your name")}
                                                        name="s_FullLegalName"
                                                        title="personInfo"
                                                        value={props.s_FullLegalName}
                                                        onChange={props.handleChange}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("Please Add Full Name Error")}
                                                    </Form.Control.Feedback>
                                                    
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="confirm-address-field" controlId="formBasicPassword">
                                                    <Form.Label className="confirm-address-label">
                                                        {t("Email")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        className="confirm-address-input"
                                                        type="email"
                                                        placeholder={t("Enter Email")}
                                                        name="s_CommValue"
                                                        title="personEmailContact"
                                                        onChange={props.handleChange}
                                                        value={props.email ? props.email : ""}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                    {t("Please Add Valid Email Address Error")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12">
                                                <Form.Group className="confirm-address-field" controlId="formBasicPassword">
                                                    <Form.Label className="confirm-address-label">
                                                        {t("Phone Number")}
                                                    </Form.Label>
                                                    <MaskedFormControl 
                                                        type='text'
                                                        name='s_CommValue'
                                                        mask="(111)111-1111"
                                                        placeholder=""
                                                        title="personPhoneContact"
                                                        className="confirm-address-input"
                                                        id='contact-phone-number' 
                                                        onChange={props.handleChange}
                                                        value={props.phone ? props.phone : ""}
                                                        required
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                     {t("Please Add Mobile Number Error")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                            <Row>
                                <h1>{t("Waiting Period")}</h1>
                                <Col xs="12">
                                    <Form.Group className="confirm-address-field" controlId="formBasicEffectiveDate">
                                        <Form.Label className="confirm-address-label">
                                            {t("Effective Date")}
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder={t("Enter Date")}
                                            min={minDate}
                                            name="d_TransEffectiveFrom"
                                            title="transactionInfo"
                                            onChange = {props.handleChange}
                                            value={props.d_TransEffectiveFrom ? props.d_TransEffectiveFrom : ""}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {t("Please Add Effective Date Error")}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            </Form>
                            <div className="flood-bts-div">
                                <Button
                                    id="primary-grey-btn-flood"
                                    variant="outline-dark"
                                    onClick={() => handleOnclick('2')}
                                >
                                    {t('Previous')}
                                </Button>
                                <Button
                                    id="primary-colored-btn"
                                    variant="primary"
                                    onClick={() => handleOnclick('4')}
                                >
                                    {t('Next')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
// emptyInstantQuoteData
function mapStateToProps(state) {
    return {
        s_FullLegalName : state.common.emptyInstantQuoteData.personInfo.s_FullLegalName,
        email : state.common.emptyInstantQuoteData.personEmailContact.s_CommValue,
        phone : state.common.emptyInstantQuoteData.personPhoneContact.s_CommValue,
        d_TransEffectiveFrom : state.common.emptyInstantQuoteData.transactionInfo.d_TransEffectiveFrom ? state.common.emptyInstantQuoteData.transactionInfo.d_TransEffectiveFrom : "",
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
    };
}

export default connect(mapStateToProps)(Contact)