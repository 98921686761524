import React, { useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";

const LimitsTable = ({
  commercialProductType,
  requestBody,
  setRequestBody,
}) => {
  const handleAddRow = () => {
    setRequestBody((prevState) => ({
      ...prevState,
      residentialLimits: [
        ...prevState.residentialLimits,
        {
          ...(commercialProductType === "Tenanted"
            ? { improvementsAndBetterments: 0 }
            : { building: 0 }),
          contents: 0,
        },
      ],
    }));
  };

  const handleRemoveRow = (index) => {
    setRequestBody((prevState) => ({
      ...prevState,
      residentialLimits: prevState.residentialLimits.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const handleChange = (index, field, value) => {
    const updatedLimits = [...requestBody.residentialLimits];
    updatedLimits[index][field] = parseInt(value);
    setRequestBody((prevState) => ({
      ...prevState,
      residentialLimits: updatedLimits,
    }));
  };

  return (
    <div>
      <h6 style={{ display: "flex", justifyContent: "center" }}>Limits</h6>
      <Table striped bordered hover width={"50%"}>
        <thead>
          <tr className="text-center align-middle">
            <th>
              {commercialProductType === "Tenanted"
                ? "Improvements And Betterments"
                : "Building"}
            </th>
            <th>Contents</th>
            <th>Action</th>
          </tr>
        </thead>
        {Array.isArray(requestBody.residentialLimits) &&
          requestBody.residentialLimits.map((row, index) => (
            <tbody key={index}>
              <tr>
                <td>
                  <Form.Control
                    type="number"
                    min={0}
                    value={
                      commercialProductType === "Tenanted"
                        ? row.improvementsAndBetterments
                        : row.building
                    }
                    onChange={(e) =>
                      handleChange(
                        index,
                        commercialProductType === "Tenanted"
                          ? "improvementsAndBetterments"
                          : "building",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    min={0}
                    value={row.contents}
                    onChange={(e) =>
                      handleChange(index, "contents", e.target.value)
                    }
                  />
                </td>
                <td className="d-flex justify-content-center align-middle">
                  <Button
                    style={{ backgroundColor: "#64D6CD", border: "none" }}
                    onClick={() => handleRemoveRow(index)}
                  >
                    <MdDelete />
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
      </Table>
      <div>
        <Button id="primary-colored-btn" size="sm" onClick={handleAddRow}>
          Add
        </Button>
      </div>
    </div>
  );
};

export default LimitsTable;
