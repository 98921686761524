import { Box, Grid, GridItem } from "@chakra-ui/react";
import {
  ChakraSelect,
  CustomFormControl,
  CustomInput,
} from "../../commercial-quote/Components/CustomComponents";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import { useEffect, useState } from "react";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import states from "../../../common/States";

export const LocationAddress = ({ index }) => {
  const { t } = useTranslation();
  const { buildingDetails, setBuildingDetails } = useQuote();
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    buildingDetails[index]?.locationZip || "",
    true
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  useEffect(() => {
    if (zipData && Object.keys(zipData).length > 0) {
      setBuildingDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[index] = {
          ...updatedDetails[index],
          locationCity: zipData?.s_CityName || "",
          locationState: zipData?.s_StateCode || "",
          locationCounty: zipData.s_CountyName ?? "",
          locationStatePk: zipData.state_id ?? 0,
          locationCountyPk: zipData.county_id ?? 0,
          locationCityPk: zipData.city_id ?? 0,
        };
        return updatedDetails;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipData]);

  return (
    <Box>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Grid
        opacity={isLoading ? 0.5 : 1}
        templateColumns="repeat(4, 1fr)"
        gap={4}
      >
        <CustomFormControl label={t("Location Identifier")}>
          <CustomInput
            defaultValue={buildingDetails[index]?.locationIndetifier || ""}
            onBlur={(e) => {
              updateBuildingDetails(
                index,
                "locationIndetifier",
                e.target.value
              );
            }}
          />
        </CustomFormControl>
        <GridItem colSpan={2}>
          <CustomFormControl label={t("Location Address")}>
            <CustomInput
              defaultValue={buildingDetails[index]?.locationAddress || ""}
              onBlur={(e) => {
                updateBuildingDetails(index, "locationAddress", e.target.value);
              }}
            />
          </CustomFormControl>
        </GridItem>
        <CustomFormControl label={t("Zip Code")}>
          <CustomInput
            defaultValue={buildingDetails[index]?.locationZip || ""}
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(e) => {
              e.target.blur();
            }}
            onBlur={(e) => {
              if (e.target.value.length <= 5) {
                updateBuildingDetails(index, "locationZip", e.target.value);
              }
            }}
          />
        </CustomFormControl>
        <CustomFormControl label={t("State")}>
          <ChakraSelect
            value={buildingDetails[index]?.locationState || ""}
            onBlur={(e) => {
              updateBuildingDetails(index, "locationState", e.target.value);
            }}
          >
            <option value=""></option>
            {states.map((state) => (
              <option key={state?.abbr} value={state?.abbr}>
                {state?.name} ({state?.abbr})
              </option>
            ))}
          </ChakraSelect>
        </CustomFormControl>
        <CustomFormControl label={t("City")}>
          <CustomInput
            value={buildingDetails[index]?.locationCity || ""}
            onChange={(e) => {
              updateBuildingDetails(index, "locationCity", e.target.value);
            }}
          />
        </CustomFormControl>
        <CustomFormControl label={t("County")}>
          <CustomInput
            value={buildingDetails[index]?.locationCounty || ""}
            onChange={(e) => {
              updateBuildingDetails(index, "locationCounty", e.target.value);
            }}
          />
        </CustomFormControl>
        <CustomFormControl label={t("Country")}>
          <CustomInput defaultValue={"USA"} isDisabled />
        </CustomFormControl>
      </Grid>
    </Box>
  );
};
