import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import delIcon from "../../../../../assets/delete-icon.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getEditEmptyQuoteData } from "../../../../../services/floodService";
import { deletePolicyInterest } from "../../../../../services/quoteService";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "react-overlay-loader/styles.css";
import "./add-mortage.scss";
import "./flood-add-mortages.scss";
import { toast } from "react-toastify";

const FloodAddInsured = (props) => {
  const { poTransPk } = useParams();
  const { showAddInsureds } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const [additionalInsuredData, setAdditionalInsuredData] = useState([]);
  const [personAddr, setPersonAddr] = useState({});

  /**
   * Set State
   */
  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setAdditionalInsuredData(emptyQuoteData.additionaInsuredData || []);
      setPersonAddr(emptyQuoteData.personAddr || {});
    }
  }, [showAddInsureds, emptyQuoteData])

  const handleAdditionalInsuredChange = (index, name, value) => {
    var quoteDataVar = emptyQuoteData;
    const additionalInsured = [...additionalInsuredData];
    additionalInsured[index]['person'][name] = value.toUpperCase();
    additionalInsured[index]['person']['s_FullLegalName'] = additionalInsured[index]['person']['s_FirstName'] + ' ' + additionalInsured[index]['person']['s_LastOrganizationName'];
    setAdditionalInsuredData(additionalInsured);
    quoteDataVar['additionaInsuredData'] = additionalInsured;
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  const handleAdditionalInsuredAdd = () => {
    var quoteDataVar = emptyQuoteData;
    const additionalInsured = [...additionalInsuredData];
    var addInJson = {
      "n_PolicyAddInterest_PK": null,
      "n_POTransaction_FK": null,
      "n_PersonInfoId_PK": null,
      "s_PersonType": "ADDITIONALINSURED",
      "s_MortgageeType": null,
      "s_LoanNo": null,
      "s_AddionalIntType": null,
      "s_RelationType": null,
      "s_HouseholdType": null,
      "s_isDeleted": 'N',
      "n_CorePersonPK": null,
      "n_CorePersonAddressPK": null,
      "n_CreatedUser": null,
      "d_CreatedDate": null,
      "n_UpdatedUser": null,
      "d_UpdatedDate": null,
      "person": {
        "n_PersonInfoId_PK": null,
        "s_PersonUniqueId": null,
        "s_EntityType": "PERSON",
        "s_FirstName": '',
        "s_MiddleName": null,
        "s_LastOrganizationName": emptyQuoteData.personInfo.s_LastOrganizationName,
        "s_FullLegalName": null,
        "d_BirthDate": null,
        "s_Occupation": null,
        "s_IsMailingDiff": null,
        "s_IsTempMailing": null,
        "n_CreatedUser": null,
        "d_CreatedDate": null,
        "n_UpdatedUser": null,
        "d_UpdatedDate": null
      },
      "address": {
        "n_PersonAddressesId_PK": null,
        "n_PersonInfoId_PK": null,
        "s_AddressTypeCode": "MAILING",
        "s_IsDefaultAddress": "Y",
        "s_AddressLine1": personAddr['s_AddressLine1'],
        "n_CountyId_FK": personAddr['n_CountryId_FK'],
        "n_CityId_FK": personAddr['n_CityId_FK'],
        "n_StateId_FK": personAddr['n_StateId_FK'],
        "n_CountryId_FK": personAddr['n_CountyId_FK'],
        "s_PostalCode": personAddr['s_PostalCode'],
        "s_HouseNo": personAddr['s_HouseNo'],
        "s_StreetName": personAddr['s_AddressLine1'],
        "s_PostalCodeSuffix": personAddr['s_PostalCodeSuffix'],
        "s_Latitude": personAddr['s_Latitude'],
        "s_Longitude": personAddr['s_Longitude'],
        "s_CountryName": personAddr['s_CountryName'],
        "s_StateName": personAddr['s_StateName'],
        "s_CountyName": personAddr['s_CountyName'],
        "s_CityName": personAddr['s_CityName'],
        "s_ParcelId": personAddr['s_ParcelId'],
        "n_CreatedUser": null,
        "d_CreatedDate": null,
        "n_UpdatedUser": null,
        "d_UpdatedDate": null
      }
    }
    additionalInsured.push(addInJson)
    setAdditionalInsuredData(additionalInsured);
    quoteDataVar['additionaInsuredData'] = additionalInsured;
    dispatch(getEditEmptyQuoteData(quoteDataVar));
  }

  const handleAdditionalInsuredDelete = async (index, row) => {
    if (await deleteInterest(row.n_PolicyAddInterest_PK)) {
      var quoteDataVar = emptyQuoteData;
      const additionalInsured = [...additionalInsuredData];
      additionalInsured.splice(index, 1);
      setAdditionalInsuredData(additionalInsured);
      quoteDataVar['additionaInsuredData'] = additionalInsured;
      dispatch(getEditEmptyQuoteData(quoteDataVar));
    }
  }

  /**
   * To delete policy additional interest details
   * @param {int} policyAddiInterestPK 
   * @returns boolean
   */
  const deleteInterest = async (policyAddiInterestPK) => {
    if (poTransPk) {
      const params = {
        transPK: poTransPk,
        n_PolicyAddInterest_PK: policyAddiInterestPK
      }
      var res = await dispatch(deletePolicyInterest(params));
      if(res.success && res.data.Status === 'Y'){
        toast.success('Entry Deleted.');
        return true;
      }
    } else {
      return true;
    }
  }

  return (
    <section className="add-mortage-section">
      {/* Form */}
      <Row className="justify-content-center flood-add-mortage-form-section">
        <Col xs="12" className="add-mortage-form-main">
          <div className="add-mortage-form">
            <div className="add-mortage-add-btn">
              <div></div>
              <Button
                variant="primary"
                id="add-input-field"
                onClick={() => handleAdditionalInsuredAdd()}
              >
                {" "}
                <span> + </span> {t('Add')}
              </Button>
            </div>
            <div className="add-mortage-table-div">
              <Table className="add-mortage-table" borderless responsive>
                <thead>
                  <tr>
                    <th>{t('First name')}</th>
                    <th>{t('Last Name')}</th>
                    <th>{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {additionalInsuredData.map((field, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="add-insured-input"
                          type="text"
                          placeholder={t("Name")}
                          name="s_FirstName"
                          value={field.person.s_FirstName}
                          onChange={(e) => handleAdditionalInsuredChange(index, e.currentTarget.name, e.currentTarget.value)}
                          readOnly={false}
                        />
                      </td>
                      <td>
                        <Form.Control
                          className="add-insured-input"
                          type="text"
                          placeholder={t("Last Name")}
                          name="s_LastOrganizationName"
                          value={field.person.s_LastOrganizationName}
                          onChange={(e) => handleAdditionalInsuredChange(index, e.currentTarget.name, e.currentTarget.value)}
                          readOnly={false}
                        />
                      </td>
                      <td className="add-mortage-action-row">
                        <div className="add-mortage-action">
                          <img
                            src={delIcon}
                            onClick={() => handleAdditionalInsuredDelete(index, field)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default FloodAddInsured;