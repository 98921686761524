export default {
  black: "#000000",
  white: "#FFFFFF",
  primary: {
    50: "#edf2ff",
    100: "#FB6777",
    200: "#F64E5F",
    300: "#A36EFF",
    400: "#edf2ff",
    500: "var(--createQuoteHeaderButton)",
    600: "#0E71BD",
    700: "#3365D4",
    800: "#055CA6",
    900: "#00519A",
  },
  secondary: {
    50: "#B9EAE5",
    100: "#A4E5DF",
    200: "#8FE0D9",
    300: "#79DBD3",
    400: "#64D6CD",
    500: "#00A8F6",
    600: "#029AE7",
    700: "#0387D3",
    800: "0376BF",
    900: "#04569D",
  },
  neutral: {
    50: "#F5F5F7",
    100: "#EAEAEC",
    200: "#DCDCDE",
    300: "#C8C8CA",
    400: "#A2A2A4",
    500: "#808082",
    600: "#5A5A5B",
    700: "#474749",
    800: "#2A2A2C",
    900: "#0B0B1A",
  },
  danger: "#E53E3E",
  warning: "#FFCB3D",
  success: "#27AE60",
  info: "#72C8CC",
  agGridHeaderBg: "#D6E2FF",
  linkColor: "#ED1B2E",
  agGridHeaderFontColor: "#474747"
};
