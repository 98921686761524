import {React,useEffect,useState} from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { showDataTableTotalText, EncryptOrDecrypt } from "../../services/commonService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { CSVLink } from 'react-csv';

const CommissionStatementDetails = (props) => {
    const [pageFilter, setPageFilter] = useState({page: 1,pageLength: 10});
  
    const amtPriceFormatter = (cell) => {
      return (
        <div
            style={{color: cell < 0 ? 'red' : ''}}
          >
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(Math.abs(cell < 0 ? cell : cell))}
          </div>
      )
    }

  const headers = [
    { label: "Agency Flood Code", key: "AgencyCode" },
    { label: "Agency Name", key: "AgencyName" },
    { label: "Policy No", key: "PolicyNo" },
    { label: "Transaction", key: "s_StatementDetailCode" },
    { label: "Comm %", key: "n_CommissionRate" },
    { label: "Comm Amt", key: "n_PostedCommAmount" },
    { label: "Prem/Written", key: "n_CommBaseAmt" },
  ]

    const columns = [
        {
          dataField: "AgencyCode",
          text: "Agency Flood Code",
        },
        {
          dataField: "AgencyName",
          text: "Agency Name",
        },
        {
          dataField: "PolicyNo",
          text: "Policy No",
        },
        {
          dataField: "s_StatementDetailCode",
          text: "Transaction",
        },
        {
          dataField: "n_CommissionRate",
          text: "Comm %",
        },
        {
          dataField: "n_PostedCommAmount",
          text: "Comm Amt",
          formatter: amtPriceFormatter
        },
        {
          dataField: "n_CommBaseAmt",
          text: "Prem/Written",
          formatter: amtPriceFormatter
        },
      ];

      const handlePageChange = (page, sizePerPage) => {
        setPageFilter({ ...pageFilter, page: page, pageLength: sizePerPage });
      };
    
    
      const renderShowsTotal = (start, to, total) => {
        return showDataTableTotalText(start, to, total);
      };
      const options = {
        page: pageFilter.page,
        sizePerPage: pageFilter.pageLength,
        sortIndicator: false,
        pageStartIndex: 1,
        paginationShowsTotal: renderShowsTotal,
        onPageChange: handlePageChange,
        totalSize: props.detailsTotal
      };
  return (
    <div className="" id="commissionStatementDetails">
      <Col xs="12" style={{ padding: 0 }}>
          <div className="table-main-title-div">
            <span className="table-main-title-name">
            Commission Statement Details
            </span>
            <span className="table-main-title-name">
              <CSVLink data={props.detailsData} headers={headers} filename={'Commission_Statement_Data.csv'}>
                <button className="btnCustom btnCustom-info pr-4 pl-4" style={{border:"1px solid", fontSize:"15px", background:"var(--buttonColor)"}} size="sm" color="primary">Export To CSV</button>
              </CSVLink>
            </span>
          </div>
      </Col>
      <Col xs="12" style={{ padding: 0 }}>
        <LoadingOverlay>
                <Loader loading={props.isDetailLoading} />
        <BootstrapTable
        wrapperClasses="management-functional-table"
        bootstrap4
        keyField="n_PAAgentStatementDetail_PK"
        data={props.detailsData}
        columns={columns}
        hover
        pagination={paginationFactory(options)}
        />
        </LoadingOverlay>
      </Col>

    </div>
  );
};

export default CommissionStatementDetails;
