import { Image } from '@chakra-ui/react'
import React from 'react'

function SecureImage({ src, ...props }) {
  let imageSrc = src;

  if(process.env.REACT_APP_NODE_ENV !== "PROD" && !imageSrc.startsWith("https")) {
    imageSrc = encodeURIComponent(imageSrc);
    imageSrc = `https://images.weserv.nl/?url=${imageSrc}`;
  }

  return <Image src={imageSrc} {...props} />
}

export default SecureImage