import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useStarDataContext } from "../../StarDataContext";
import { LossHistoryTable } from "../under-writing/LossHistoryTable";

export default function LossHistoryForDisplay() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, "quote");

  const { lossHistoryData, setLossHistoryData } = useStarDataContext();

  return (
    <>
      <Box border="1px solid #E6E9ED" borderRadius="12px" mb={0} pb={4}>
        <Box bg="primary.50" borderRadius="xl" p={"0.1rem"}>
          <Text
            mt={3}
            borderRadius={"xl"}
            pl="1.5rem"
            fontWeight="var(--chakra-fontWeights-semibold)"
            color="black"
            fontSize="1.5rem"
          >
            {t("loss_history")}
          </Text>
        </Box>
        <Flex mx={"-5px"} mb={"-20px"}>
          {lossHistoryData?.length > 0 && (
            <LossHistoryTable
              lossHistoryData={lossHistoryData}
              setLossHistoryData={setLossHistoryData}
            />
          )}
        </Flex>
      </Box>
    </>
  );
}
