import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Text,
  Divider,
  useColorModeValue,
  Box,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import {
  IoCloseCircleSharp,
  IoInformationCircle,
  IoHome,
  IoWallet,
  IoShield,
  IoAirplane,
  IoMedkit,
} from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Currency } from "../../../../common/Currency";

const PremiumDetailsModal = ({
  isOpen,
  onClose,
  premiumDetails = {},
  feesData = {},
}) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const headerBgColor = "primary.500";

  const coverages = [
    {
      key: "n_coverageA",
      label: "Cost to Rebuild Home (Coverage A)",
      icon: IoHome,
    },
    {
      key: "n_coverageB",
      label: "Other Structures (Coverage B)",
      icon: IoHome,
    },
    { key: "n_coverageC", label: "Contents (Coverage C)", icon: IoWallet },
    { key: "n_coverageD", label: "Loss of Use (Coverage D)", icon: IoAirplane },
    {
      key: "n_coverageE",
      label: "Personal Liability (Coverage E)",
      icon: IoShield,
    },
    {
      key: "n_CoverageF",
      label: "Medical Payments to Others (Coverage F)",
      icon: IoMedkit,
    },
  ];

  const formatCurrency = (amount) => <Currency amount={amount} />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="xl" boxShadow="xl">
        <ModalHeader
          bg={headerBgColor}
          color="white"
          borderTopRadius="xl"
          fontSize="2xl"
          fontWeight="bold"
          pt={4}
          pb={0}
        >
          <HStack>
            <Icon mb={4} as={IoInformationCircle} boxSize={6} />
            <Text>{t("Premium Details")}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={6}>
          <VStack spacing={6} align="stretch">
            <Box
              bg={bgColor}
              borderRadius="2xl"
              border="1px"
              borderColor={borderColor}
              p={6}
              boxShadow="md"
            >
              <Text fontSize="xl" fontWeight="semibold" mb={4}>
                {t("Coverages")}
              </Text>
              <Divider mb={4} />
              <VStack spacing={4} align="stretch">
                {coverages.map(({ key, label, icon }) => (
                  <HStack key={key} justify="space-between">
                    <HStack>
                      <Icon mb={4} as={icon} boxSize={5} color="blue.500" />
                      <Text>{label}</Text>
                    </HStack>
                    <Text fontWeight="medium">
                      {formatCurrency(premiumDetails[key] || 0)}
                    </Text>
                  </HStack>
                ))}
              </VStack>
              <Divider my={4} />
              <HStack justify="space-between">
                <Text fontWeight="bold" fontSize="lg">
                  {t("Base Premium")}
                </Text>
                <Text fontWeight="bold" fontSize="lg">
                  {formatCurrency(feesData?.premium || 0)}
                </Text>
              </HStack>
            </Box>

            <Box
              bg={bgColor}
              borderRadius="2xl"
              border="1px"
              borderColor={borderColor}
              p={6}
              boxShadow="md"
            >
              <Text fontSize="xl" fontWeight="semibold" mb={4}>
                {t("Premiums & Fees")}
              </Text>
              <Divider mb={4} />
              <VStack spacing={3} align="stretch">
                {Object.entries(feesData?.fees || {}).map(([key, value]) => (
                  <HStack key={key} justify="space-between">
                    <Tooltip label={`${key}`} hasArrow>
                      <HStack>
                        <Icon
                          mb={4}
                          as={IoInformationCircle}
                          boxSize={4}
                          color="blue.500"
                        />
                        <Text>{key}</Text>
                      </HStack>
                    </Tooltip>
                    <Text fontWeight="medium">{formatCurrency(value)}</Text>
                  </HStack>
                ))}
              </VStack>
              <Divider my={4} />
              <HStack justify="space-between">
                <Text fontWeight="bold" fontSize="lg">
                  Total Fees
                </Text>
                <Text fontWeight="bold" fontSize="lg">
                  {formatCurrency(feesData?.totalFee || 0)}
                </Text>
              </HStack>
            </Box>

            <Divider />

            <Box
              bg={useColorModeValue("blue.50", "blue.900")}
              p={4}
              borderRadius="2xl"
              boxShadow="md"
            >
              <HStack justify="space-between">
                <VStack align="start" spacing={0}>
                  <Text fontSize="2xl" fontWeight="bold">
                    {t("Total Premium")}
                  </Text>
                  <Text
                    fontWeight="normal"
                    fontSize="sm"
                    color={useColorModeValue("gray.600", "gray.400")}
                  >
                    {/* used custom currency component specifically for display purpose only */}
                    (
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(feesData?.premium || 0)}{" "}
                    +{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(feesData?.totalFee || 0)}
                    )
                  </Text>
                </VStack>
                <Text fontSize="2xl" fontWeight="bold" color="blue.500">
                  {formatCurrency(feesData?.finalPremium || 0)}
                </Text>
              </HStack>
            </Box>
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="center" py={6}>
          <Button
            leftIcon={<IoCloseCircleSharp />}
            colorScheme="blue"
            size="lg"
            w="100%"
            borderRadius="xl"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PremiumDetailsModal;
