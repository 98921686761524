import React, { Fragment,useState,useEffect } from 'react';

import { Button, Col, Row, Modal , Spinner,OverlayTrigger ,Tooltip} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PdfIcon from '../../../../../../assets/pdf-icon.png';
import {selectExcessProducts} from "../../../../../../store/selectors/private-flood"
import { useDispatch,useSelector } from "react-redux";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { toast } from 'react-toastify';
import NumberFormat from "react-number-format";
import {getAgentExcessInfo,saveAgentExcessInfo } from "../../../../../../services/profileService.js";
import {Loader, LoadingOverlay} from "react-overlay-loader";
import ReactTooltip from "react-tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  updatePrivateProductReport,
} from "../../../../../../services/floodService";

const ExcessProductModal = ({ data, isModalOpen, closeModal ,onGetPremiumClick  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [coverage, setCoverage] = useState('');
  const excessProducts = useSelector(state => selectExcessProducts(state))
  const [spinner, setSpinner] = useState(false);
  const [productList, setProductList] = useState( [
    {label:"NFIP" , value:"nfip"},
    {label:"MacNeill Group" , value:"macneill"},
    {label:"Neptune" , value:"neptune"},
    {label:"CATCoverage" , value:"cat"},
    {label:"AonEdgeEZFlood" , value:"ezflood"}
]);
const [selectedOption, setSelectedOption] = useState({ value: 'nfip', label: 'NFIP' });
const [policyNumber, setPolicyNumber] = useState(data.policyInfo.Policy_No);
const [effectiveDate, setEffectiveDate] = useState(new Date(Moment(data.effectiveDate)) ?? null);

const [aonUsername, setAonusername] =  React.useState('');
const [aonPassword, setAonpassword] =  React.useState('');
const [aonId, setAonid] =  React.useState(0);
const [credentialType, setAoncredentialtype] =  React.useState('');
const [displayUserScreen, setDisplayUserScreen] =  React.useState(false);
const [loading, setLoading] =  React.useState(false);

const handleChange = (selectedOption) => {
  setSelectedOption(selectedOption);
};
const defaultOption = { value: 'nfip', label: 'NFIP' };

const tooltip = excessProducts?.excessAonEdgeproduct?.error ? (
  <Tooltip id="tooltip">
    {excessProducts?.excessAonEdgeproduct?.error}
  </Tooltip>
) : null;

useEffect(() => {
	getAgentExcessData();
}, [])


useEffect(() => {
  if (selectedOption.value != 'nfip') {
    setPolicyNumber('');
    setEffectiveDate('');
  } else {
    setPolicyNumber(data.policyInfo.Policy_No);
    setEffectiveDate(new Date(Moment(data.effectiveDate)) ?? null);
  }
}, [selectedOption])

useEffect(() => {
  if (Object.keys(excessProducts).length > 0) {
    if(excessProducts?.excessAonEdgeproduct?.premium?.best || excessProducts?.excessAonEdgeproduct?.error){
      setSpinner(false);
    }
  }
}, [excessProducts])

const handleGetPremiumClick = async() => {
  if(coverage == '' || coverage == 0 || coverage == undefined){
      toast.error("Please add coverage");
    }else if(policyNumber == ''){
      toast.error("Please add policy number");
    } else if(effectiveDate == '' || effectiveDate == null){
      toast.error("Please add effective date");
    } else {
      setSpinner(true);
      await onGetPremiumClick(coverage,selectedOption,policyNumber,effectiveDate);
    }

  };
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const goToAonEdgeExcessQuoteLink = (excessProducts) => {
    const reportId = excessProducts?.excessAonEdgeproduct?.reportId ?? 0;
    const premium = excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium ?? 0;
    reportId !== 0 && dispatch(updatePrivateProductReport(reportId,premium))
    window.open(excessProducts?.excessAonEdgeproduct?.quoteLink, '_blank');
  }
  const getAgentExcessData = async () => {
    setLoading(true);
    await dispatch(getAgentExcessInfo()).then((res) => { 
      setAonusername(res?.data?.data?.aon_edge?.username ?? '');
      setAonpassword(res?.data?.data?.aon_edge?.password ?? ''); 
      setAonid(res?.data?.data?.aon_edge?.id ?? 0); 
      setAoncredentialtype(res?.data?.data?.aon_edge?.credential_type ?? 'AON_EDGE'); 
      if(res?.data?.data.length == 0){
        setDisplayUserScreen(true);
      }
      });
      setLoading(false);
  };

  const submitExcessInfo = async () => {
    if(aonUsername == '' || aonUsername == null){
      return toast.error('Please enter username');
    }
    if(aonPassword == '' || aonPassword == null){
      return toast.error('Please enter password');
    }
	const params = {
		aon_edge:{
			username:aonUsername,
			password:aonPassword,
			credential_type:credentialType,
			id:aonId
		}
	}
    await dispatch(saveAgentExcessInfo(params)).then((res) => { 
      if(res.data.data == "Y"){
		  toast.success('Excess configuration saved successfully...');
      setDisplayUserScreen(false);
      }
      else {
        toast.error(res.data.data.MessageString);
      }
    });
  }

  return (
    <Fragment>
      <Modal size="md" show={isModalOpen} onHide={closeModal} id="confirmPropInfo">
        <Modal.Header closeButton>
          <Modal.Title>{t('Excess Coverage')} 
          {
            !displayUserScreen && !loading &&
            <span>
              <AiOutlineInfoCircle size={30} style={{ paddingLeft: '5%' }} data-tip data-for="image-dropdown" />
                <ReactTooltip id="image-dropdown" place="right" effect="solid">
                  You can update the excess user credentials in the "Edit Profile / Excess Configuration" section of the user profile.
                </ReactTooltip>
            </span>   
          }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'justify' }}>
          {
            <LoadingOverlay>
            <Loader loading={loading}/>
            {
            !loading ?
              displayUserScreen ? 
              <div>
                  <Row className="align-items-center">
                <label>Do you have Excess credentials?</label>

                  </Row>
                <br></br>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <label htmlFor="excess-coverage" className="form-label">
                      Username 
                    </label>
                  </Col>
                  <Col xs={6}>
                      <input
                        type="text"
                        className="dashboard-group-input"
                        name = "username"
                        onChange={(e) => setAonusername(e.target.value)}
                        value={ aonUsername }
                      />
                  </Col>
                </Row>
                <br></br>
                <Row className="align-items-center">
                  <Col xs={4}>
                    <label htmlFor="excess-coverage" className="form-label">
                      Password 
                    </label>
                  </Col>
                  <Col xs={6}>
                      <input
                        className="dashboard-group-input"
                        type="password"
                        name = "password"
                        onChange={(e) => setAonpassword(e.target.value)}
                        value={ aonPassword }
                      />
                  </Col>
                </Row>
              </div>
              :
              <div>
            <Row className="align-items-center">
            <Col xs={3}>
                  <label htmlFor="excess-coverage" className="form-label">
                    Coverage:
                  </label>
                </Col>
                <Col xs={3} >
                  <label htmlFor="excess-coverage" className="">
                    $250,000  +
                  </label>
                </Col>
                <Col xs={6}>
                    <NumberFormat
                      className="dashboard-group-input-flood"
                      style={{textAlign: "right", maxWidth: '200px'}}
                      type="text"
                      displayType={'input'}
                      prefix={'$'}
                      thousandSeparator={true}
                      allowNegative={false}
                      name="excess-coverage"
                      title={t('Building Coverage Amount')}
                      min={0}
                      autoComplete="off"
                      onValueChange={(value) => setCoverage(value.floatValue)}
                    />
                </Col>
            </Row>
            
              <br></br>
              <div
                  style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
                >
                  <div style={{flex: 1, height: '1px', backgroundColor: 'lightgray'}} />

                  <div style={{marginTop:"10px"}}>
                    <p style={{width: '190px', textAlign: 'center'}}>Primary Policy Details</p>
                  </div>

                  <div style={{flex: 1, height: '1px', backgroundColor: 'lightgray'}} />
                </div>

            <Row className="align-items-center">
            <Col xs={3}>
                  <label htmlFor="excess-coverage" className="form-label">
                    Product:
                  </label>
                </Col>
                <Col xs={3}>
                </Col>
                <Col xs={6}>
                  <Select
                      name="productlist"
                      options={productList}
                      value={selectedOption || defaultOption}
                      onChange={handleChange}
                    />
                  </Col>
              </Row>
              <br></br>
              <Row className="align-items-center">
            <Col xs={6}>
                  <label htmlFor="excess-coverage" className="form-label">
                    Policy No :
                  </label>
                </Col>
                <Col xs={6}>
                    <input
                      type="text"
                      name="excess-coverage"
                      id="excess-coverage"
                      className="form-control dashboard-group-input"
                      value={policyNumber}
                      onChange={(e) => setPolicyNumber(e.target.value)}
                    />
                </Col>
              </Row>
              <br></br>
              <Row className="align-items-center">
            <Col xs={6}>
                  <label htmlFor="excess-coverage" className="form-label">
                    Effective Date:
                  </label>
                </Col>
                <Col xs={6}>
                <DatePicker
                    id="dashboard-datepicker"
                    className={"dashboard-datepicker-main"}
                    placeholderText="MM/DD/YYYY"
                    selected={effectiveDate}
                    onChange={(date) => {
                        setEffectiveDate(date);
                    }}
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    disabled={selectedOption.value == 'nfip' ? true : false}
                />
                </Col>
              </Row>
              <hr></hr>
              <br></br>
              {
                excessProducts && excessProducts?.excessAonEdgeproduct?.error ? (
                  <Row className="align-items-center">
                    <Col xs={5}>
                      <label htmlFor="excess-coverage" className="form-label">
                      {excessProducts?.excessAonEdgeproduct && "Premium"}

                        
                      </label>
                    </Col>
                    <Col xs={4}>
                    <p className="form-label fw-bold">
                      Risk not Eligible
                    </p>
                    </Col>
                    <Col xs={3}>
                    {excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium? 
                      <Button
                      variant="outline-none"
                      style={{backgroundColor: '#64d6cd'}}
                      size="sm"
                      onClick={() => goToAonEdgeExcessQuoteLink(excessProducts)}
                    >
                      Buy now
                    </Button>
                    : ''}

                    </Col>
                  </Row>
                ) : 
                <Row className="align-items-center">
                <Col xs={5}>
                  <label htmlFor="excess-coverage" className="form-label">
                  {excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium && "Premium"}

                    
                  </label>
                </Col>
                <Col xs={4}>
                  <label htmlFor="excess-coverage" className="mr-2">
                    {excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium? formatter.format(excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium) : ''}
                  </label>
                </Col>
                <Col xs={3}>
                {excessProducts?.excessAonEdgeproduct?.premium?.best?.totalPremium? 
                  <Button
                  variant="outline-none"
                  style={{backgroundColor: '#64d6cd'}}
                  size="sm"
                  onClick={() => goToAonEdgeExcessQuoteLink(excessProducts)}
                >
                  Buy now
                </Button>
                : ''}

                </Col>
              </Row>
              }
              </div>
            : 
            null
            }
          </LoadingOverlay>
          }
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        {
          !loading ?
            displayUserScreen ? 
            <Fragment>
            <Button onClick={submitExcessInfo} id="primary-colored-btn" size="md" >
              {'Save'}
            </Button>&nbsp; 
            <Button onClick={() => setDisplayUserScreen(false)} id="primary-colored-btn" size="md" >
            {'Skip'}
          </Button> 
            </Fragment>
            :
          <Button onClick={handleGetPremiumClick} id="primary-colored-btn" size="md" disabled={spinner}>
          {spinner ? (<><Spinner
				          as="span"
				          variant="light"
				          size="sm"
				          role="status"
				          aria-hidden="true"
				          animation="border" /> &nbsp;</>) : null}

            {'Get a Premium'}
          </Button>
          : null
        }
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ExcessProductModal;