import React from "react";
import {
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Box,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";

const constructionTypes = [
  { name: "Frame", icon: "/starImages/miniIcons/frame.svg" },
  { name: "Joisted Masonry", icon: "/starImages/miniIcons/joistedMasonry.svg" },
  { name: "Non-Combustible", icon: "/starImages/miniIcons/nonCombustible.svg" },
  {
    name: "Masonry Non-combustible",
    icon: "/starImages/miniIcons/masonryNonCombustile.svg",
  },
  {
    name: "Modified Fire-resistive",
    icon: "/starImages/miniIcons/modifiedFireResistive.svg",
  },
  { name: "Fire-resistive", icon: "/starImages/miniIcons/fireResistive.svg" },
];

const ConstructionTypeCard = ({ name, icon: Icon, isSelected, onClick }) => (
  <Button
    width="full"
    justifyContent={"flex-start"}
    borderRadius={"xl"}
    py={6}
    variant={isSelected ? "outline" : "outline"}
    colorScheme={isSelected ? "primary" : "gray"}
    leftIcon={<Image src={Icon || ""} width="2em" height="1.5em" />}
    onClick={onClick}
  >
    {name}
  </Button>
);

export const ConstructionTypeSelection = ({ index }) => {
  const { buildingDetails, setBuildingDetails } = useQuote();

  const { t } = useTranslation();

  const updateBuildingDetails = (index, name, value) => {
    setBuildingDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };
      return updatedDetails;
    });
  };

  const handleSelection = (name) => {
    // setUnderWriting({
    //   ...underWriting,
    //   constructionType: name,
    // });
    updateBuildingDetails(index, "constructionType", name);
  };

  return (
    <>
      <Flex gap={2} my={0}>
        <Text fontWeight="500">{t("Construction Type")}:</Text>{" "}
        {buildingDetails[index]?.constructionType && (
          <Text fontWeight="500" color={"neutral.300"}>
            ( {buildingDetails[index]?.constructionType} )
          </Text>
        )}
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={4} align="stretch">
        {constructionTypes.map(({ name, icon }) => (
          <GridItem key={name}>
            <ConstructionTypeCard
              name={name}
              icon={icon}
              isSelected={buildingDetails[index]?.constructionType === name}
              onClick={() => handleSelection(name)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
