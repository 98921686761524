import React from "react";
import {Link} from 'react-scroll'
import attachment from "../../../../assets/helpcenter-images/attachment.png";
import attachment2 from "../../../../assets/helpcenter-images/rectangle-4568.png";

import ccimage1 from "../../../../assets/helpcenter-images/create-claim/cc-image-1.png";
import ccimage11 from "../../../../assets/helpcenter-images/create-claim/cc-image-11.png";
import ccimage12 from "../../../../assets/helpcenter-images/create-claim/cc-image-12.png";
import ccimage13 from "../../../../assets/helpcenter-images/create-claim/cc-image-13.png";
import ccimage2 from "../../../../assets/helpcenter-images/create-claim/cc-image-2.png";
import ccimage3 from "../../../../assets/helpcenter-images/create-claim/cc-image-3.png";
import ccimage4 from "../../../../assets/helpcenter-images/create-claim/cc-image-14.jpg";

import scimage1 from "../../../../assets/helpcenter-images/searching-claim/sc-image-1.png";
import scimage2 from "../../../../assets/helpcenter-images/searching-claim/sc-image-2.png";
import scimage3 from "../../../../assets/helpcenter-images/searching-claim/sc-image-3.png";
import scimage4 from "../../../../assets/helpcenter-images/searching-claim/sc-image-4.png";
import scimage5 from "../../../../assets/helpcenter-images/searching-claim/sc-image-5.png";

import cvimage1 from "../../../../assets/helpcenter-images/claim-view/cv-image-1.png";
import cvimage2 from "../../../../assets/helpcenter-images/claim-view/cv-image-2.png";
import cvimage3 from "../../../../assets/helpcenter-images/claim-view/cv-image-3.png";
import cvimage4 from "../../../../assets/helpcenter-images/claim-view/cv-image-4.png";
import {useParams } from 'react-router-dom'; 
import { getCompanyDetails } from "../../../../services/commonService";

const ClaimsSystem = () => {
    const { div } = useParams();
    const selectedDiv = div;
    const hideDivObject =  {
        createaclaim:false,
        searchaclaim:false,
        viewaclaim:false,
    }
    if(selectedDiv){
        Object.keys(hideDivObject).forEach(key => {
            hideDivObject[key] = true;
        });
        hideDivObject[selectedDiv] = false;
    }

    const companyPhone = getCompanyDetails("phone");

    return(
        <div className="row">
                <div className="col-md-7">
                    <div id="createaclaim" hidden={hideDivObject.createaclaim}>
                        <p className="font-weight-bold mt-3">Create a Claim</p>
                        <p>Log into the system and select “Claim” from the headers across the top.</p>
                        <img src={ccimage11} className="img-fluid" alt />
                        <br /><br />
                        <p>Select “Add Claim” from the claims search menu</p>
                        <br />
                        <img src={ccimage13} className="img-fluid" alt />
                        <br /><br />
                        <p>Locate the policy related to the claim by either the policy number, or the name of the insured.</p>
                        <img src={ccimage12} className="img-fluid" alt />
                        <br /><br />
                        <p>Click on the policy number to generate a new claim.  This will generate a new claim, ready for the claims intake.</p>
                        <p>In addition to this, a new claim can be added by clicking “Add New” at the bottom of an existing claim under the Claims View.</p>
                        <p>15 questions then click save claim to record the claim.</p>
                        <img src={ccimage2} className="img-fluid" alt />
                        {/* <p>15 questions then click save claim to record the claim.</p> */}

                        {/* <p>Log into the system and select “Claim” from the headers across the top.</p>
                        <img src={ccimage1} className="img-fluid" alt />

                        <p>Select “Add Claim” from the claims search menu</p>
                        <p>15 questions then click save claim to record the claim.</p>
                        <img src={ccimage2} className="img-fluid" alt />

                        <img src={ccimage3} className="img-fluid" alt />

                        <p>Locate the policy related to the claim by either the policy number, or the name of the Policyholder.</p>
                        <img src={ccimage4} className="img-fluid" alt />

                        <p>Click on the policy number to generate a new claim.  This will generate a new claim, ready for the claims intake.
                            In addition to this, a new claim can be added by clicking “Add New” at the bottom of an existing claim under the Claims View.
                        </p> */}
                        <hr />        
                    </div>
                    <div id="searchaclaim" hidden={hideDivObject.searchaclaim}>
                        
                    <p className="font-weight-bold mt-3">Search a Claim</p>
                    <p>Log into the system and select “Claim” from the headers across the top.</p>
                    <img src={ccimage11} className="img-fluid" alt />
                    <p>A claim can be searched for by policy number, claim number, the Policyholder's name, the status of the claim, the type of claim, or any combination of these attributes.</p>
                    <img src={scimage4} className="img-fluid" alt />
                    <p>To open a claim, click on the claim or policy number of the claim you are searching for.</p>
                    <p>Some of the claim information is frozen to the top of the claim regardless of the portion of the claim being viewed.  This includes the name, claim number, policy number, address, deductibles, number of working days, etc.</p>
                    <img src={scimage5} className="img-fluid" alt />
                    <p>The rest of the claim information is grouped into different tabs.</p>
                    <hr />    
                    </div>
                    <div id="viewaclaim" hidden={hideDivObject.viewaclaim}>
                    <p className="font-weight-bold mt-3">View a Claim</p>
                    <p>The claim view displays an overview of the claim, including contact information, claim type and date of loss, a description of the claim from the claim intake, claim status and total payouts.</p>
                    <img src={cvimage3} className="img-fluid" alt />
                    <p>To assist in workflow management, in addition to open, re-opened, and closed claims, the claims sub-status can be updated to track where along it’s life the claim currently is.  The options include pending property access, engineering report, pending estimates, etc.</p>
                    <img src={cvimage4} className="img-fluid" alt />
                    
                    <hr />    
                    </div>
                    
                </div>
                {
                    selectedDiv ? null :
                    (<div className="col-md-5">
                        <div className="rightdv p-5">
                            <h5 className="font-weight-bold">Quick Links</h5>
                            <br></br>
                            <p><Link activeClass="active" to="createaclaim" spy={true} smooth={true}>Create a Claim</Link></p><hr />
                            <p><Link activeClass="active" to="searchaclaim" spy={true} smooth={true}>Search a Claim</Link></p><hr />
                            <p><Link activeClass="active" to="viewaclaim" spy={true} smooth={true}>View a Claim</Link></p><hr />
                        </div>
                        <div className="rightdv p-5 mt-3">
                            <h5 className="font-weight-bold">Need Support?</h5>
                            <p className="buttonicon"><i className="fa fa-commenting-o" />&nbsp;&nbsp;Live chat with our support Assistant</p>
                            <p>Click on the link to connect with our support assistant. 24/7 service is available for our valueable customers.</p>
                            <button className="btnCustom btnCustom-info pr-4 pl-4">Chat Now</button>
                            <hr />
                            <p><b>Need Support?</b></p>
                            <p className="buttonicon"><i className="fa fa-phone buttonicon" />&nbsp;&nbsp;{companyPhone}</p>
                            <p>Connect directly with our support team. 24/7 service is available for our valueable customers.</p>
                        </div>
                    </div>)
                }
            </div>

    )
}

export default ClaimsSystem;