import React from "react";
import "./Discount.scss";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const Discount = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step)
    }

    return(
        <section className="property-details-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="discount-form">
                    <h1>{t('Please Answer All Discount Related Questions')}</h1>
                    <Row className="justify-content-start">
                        <Col xs="12">
                            <Row>
                                <Col xs="12">
                                    <div className="discount-form-row">
                                        <p>{t('Are you 55 years older')}?</p>
                                        <div className="discount-form-actions">
                                            <span>{t('No')}</span>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                name="s_Is55YearOlder"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                onChange = {props.handleSwitchChange}
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                defaultChecked={props.emptyQuote.propertyInfo.s_Is55YearOlder == "YES" ? true : false}
                                            />
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div className="discount-form-row">
                                        <p>{t('Does the property have a central alarm')}?</p>
                                        <div className="discount-form-actions">
                                            <span>{t('No')}</span>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                name="s_FireAlarmCode"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                onChange = {props.handleSwitchChange}
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                defaultChecked={props.emptyQuote.propertyInfo.s_FireAlarmCode == "YES" ? true : false}
                                            />
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div className="discount-form-row">
                                        <p>{t('Does the property have a central burglar alarm')}?</p>
                                        <div className="discount-form-actions">
                                            <span>{t('No')}</span>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                name="s_BurglarAlarmCode"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                onChange = {props.handleSwitchChange}
                                                checkedvalue = 'YES'
                                                uncheckedvalue = 'NO'
                                                defaultChecked={props.emptyQuote.propertyInfo.s_BurglarAlarmCode == "YES" ? true : false}
                                            />
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12">
                                    <div className="discount-form-row">
                                        <p>{t('Have you had any prior claim')}?</p>
                                        <div className="discount-form-actions">
                                            <span>{t('No')}</span>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                name="n_PriorClaimCount"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                onChange = {props.handleSwitchChange}
                                                checkedvalue = '1'
                                                uncheckedvalue = '0'
                                                defaultChecked={props.emptyQuote.propertyInfo.n_PriorClaimCount == "1" ? true : false}
                                            />
                                            <span>{t('Yes')}</span>
                                        </div>
                                    </div>
                                </Col>
                                <div className="flood-bts-div">
                                    <Button
                                        id="primary-grey-btn-flood"
                                        variant="outline-dark"
                                        onClick={() => handleOnclick('1')}
                                    >
                                        {t('Previous')}
                                    </Button>
                                    <Button
                                        id="primary-colored-btn"
                                        variant="primary"
                                        onClick={() => handleOnclick('3')}
                                    >
                                        {t('Next')}
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    )
}
// emptyInstantQuoteData
function mapStateToProps(state) {
    return {
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
    };
}

export default connect(mapStateToProps)(Discount)