import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import ApplicantInfo from "./components/QuoteTabComponents/ApplicantInfo";
import PropertyDetails from "./components/QuoteTabComponents/PropertyDetails";
import CurrentAddress from "./components/QuoteTabComponents/CurrentAddress";
import Coverage from "./components/QuoteTabComponents/Coverage";
import Deductible from "./components/QuoteTabComponents/Deductible";
import Discount from "./components/QuoteTabComponents/Discount";
import UnderwritingQuestion from "./components/QuoteTabComponents/UnderwritingQuestion";
import FooterButton from "./components/FooterButton";
import { getEditEmptyQuoteData } from "../../../services/quoteService";
import { useTranslation } from "react-i18next";

const QuoteTab = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const quoteData = useSelector((state) => state.common.emptyQuoteData || {});
  const [isPriorAddr, setIsPriorAddr] = useState(false);

  const setPriorAddrFunc = (data) => {
    const updateValue = data === "YES" ? "Yes" : "No";
    if (updateValue === "Yes") {
      setIsPriorAddr(true);
    } else {
      setIsPriorAddr(false);
    }
    if (quoteData && quoteData.personPriorAddr) {
      quoteData.personPriorAddr.isNewPurchase = updateValue;
      dispatch(getEditEmptyQuoteData(quoteData));
    } else {
      const tempArray = {
        ...quoteData,
        personPriorAddr: {
          ...quoteData?.personPriorAddr,
          isNewPurchase: updateValue,
          s_IsDefaultAddress: updateValue === "Yes" ? "Y" : "N",
        },
      };
      dispatch(getEditEmptyQuoteData(tempArray));
    }
  };

  useEffect(() => {
    if (quoteData?.personPriorAddr?.n_PersonAddressesId_PK) {
      setIsPriorAddr(true);
      setPriorAddrFunc("YES");
    }
  }, [quoteData]);

  return (
    <>
      <Fragment>
        <div className="search-quote-tab-div py-2 radius-xl shadow-sm">
          <Accordion mx={5} mb={5} mt={3} allowToggle>
            {/* application information */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Applicant Information")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ApplicantInfo
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  getZipCodeDetails={props.getZipCodeDetails}
                  zipAddrFor={"personAddr"}
                  isBind={props.isBind}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* conditionally render mailing address based on quoteData */}
            {quoteData?.personInfo?.s_IsMailingDiff === "YES" && (
              <AccordionItem
                mb={4}
                style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
                borderRadius={"xl"}
              >
                <AccordionButton
                  background="var(--chakra-colors-primary-50)"
                  borderRadius={"xl"}
                >
                  <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                    Mailing Address
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <CurrentAddress
                    addressType={"personMailingAddr"}
                    addressData={quoteData?.personMailingAddr}
                    handleChange={props.handleChange}
                    isBind={props.isBind}
                  />
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* property details */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Property Details")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <PropertyDetails
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  isBind={props.isBind}
                  setPriorAddrFunc={setPriorAddrFunc}
                  isPriorAddr={isPriorAddr}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* prior address */}
            {isPriorAddr && (
              <AccordionItem
                mb={4}
                style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
                borderRadius={"xl"}
              >
                <AccordionButton
                  background="var(--chakra-colors-primary-50)"
                  borderRadius={"xl"}
                >
                  <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                    Prior or Current Address
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <CurrentAddress
                    addressType={"personPriorAddr"}
                    addressData={quoteData?.personPriorAddr}
                    handleChange={props.handleChange}
                    isBind={props.isBind}
                  />
                </AccordionPanel>
              </AccordionItem>
            )}
            {/* coverage */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Coverage")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Coverage
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  handleCheckboxChange={props.handleCheckboxChange}
                  isBind={props.isBind}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* deductible */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Deductible")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Deductible
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  handleCheckboxChange={props.handleCheckboxChange}
                  isBind={props.isBind}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* discount */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Discount")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Discount
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  handleCheckboxChange={props.handleCheckboxChange}
                  isBind={props.isBind}
                />
              </AccordionPanel>
            </AccordionItem>
            {/* claim question */}
            {/* underwriting questions */}
            <AccordionItem
              mb={4}
              style={{ border: "1px solid #E6E9ED", borderTop: 0 }}
              borderRadius={"xl"}
            >
              <AccordionButton
                background="var(--chakra-colors-primary-50)"
                borderRadius={"xl"}
              >
                <Box as="span" flex="1" fontSize="1.2rem" textAlign="left">
                  {t("Underwriting Question")}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <UnderwritingQuestion
                  {...props}
                  handleChange={props.handleChange}
                  handleRadioChange={props.handleRadioChange}
                  handleCheckboxChange={props.handleCheckboxChange}
                  isBind={props.isBind}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <FooterButton rateSubmit={props.rateSubmit} isBind={props.isBind} />
      </Fragment>
    </>
  );
};

export default QuoteTab;
