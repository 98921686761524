import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTenant } from "../../services/commonService";
import {
 Box,
 Tab,
 TabList,
 TabPanel,
 TabPanels,
 Tabs,
 Text,
} from "@chakra-ui/react";
import "./components/agGrid.css";
import { NewBusiness } from "./components/NewBusiness";
import { BindRequested } from "./components/BindRequested";

const Index = () => {
 const [activeTab, setActiveTab] = useState("NEWBUSINESS");
 const { t } = useTranslation();

 const handleTabChange = (key) => {
    setActiveTab(key);
 };

 const renderContent = useMemo(() => {
    switch (activeTab) {
      case "NEWBUSINESS":
        return <NewBusiness transType={activeTab} />;
      case "BIND_REUQESTED":
        return <BindRequested transType={activeTab} />;
      default:
        return <NewBusiness transType={activeTab} />;
    }
 }, [activeTab]);

 return (
    <Box>
      <Text fontWeight={600} fontSize={"2xl"} ml={4}>
        {t("Pending Transactions")}
      </Text>
      <Box>
        <Tabs
          borderBottom={"1px solid"}
          borderColor={"gray.200"}
          index={activeTab === "NEWBUSINESS" ? 0 : 1}
          onChange={(index) =>
            handleTabChange(index === 0 ? "NEWBUSINESS" : "BIND_REUQESTED")
          }
        >
          <TabList pl={4} borderBottom={"none"}>
            <Tab
              // bg={"#d6f0ee"}
              _selected={{ bg: "primary.500", color: "white" }}
            >
              {t("New Business")}
            </Tab>
            {["amfam"].includes(getTenant()) && (
              <Tab
                // bg={"#d6f0ee"}
                _selected={{ bg: "primary.500", color: "white" }}
              >
                {t("Bind Requested")}
              </Tab>
            )}
          </TabList>
          <Box mt={"1px"} mb={1} mx={4} borderTop={"2px solid"} borderColor={"gray.200"} />
          <TabPanels>
            <TabPanel>{renderContent}</TabPanel>
            <TabPanel>{renderContent}</TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
 );
};

export default Index;
