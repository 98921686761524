import React from "react";
import FloodCBRS from "./FloodCBRS";
import FloodSevereRepetitive from "./FloodSeverRepetitiveLoss";
import { useParams } from "react-router-dom";

const FloodError = () => {
    // get type of error
    let { type } = useParams();
    let view = null;

    switch (type) {
        case 'srl':
            view = <FloodSevereRepetitive />
            break;
        case 'cbrs':
            view = <FloodCBRS />
            break;
        default:
            view = null;
            break;
    }

    return (
        view
    );
};

export default FloodError;