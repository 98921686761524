import React from "react";
import {Link} from 'react-scroll'
import attachment from "../../../../assets/helpcenter-images/attachment.png";
import attachment2 from "../../../../assets/helpcenter-images/rectangle-4568.png";

import fpimage1 from "../../../../assets/helpcenter-images/find-policy/fp-image-1.png";
import fpimage2 from "../../../../assets/helpcenter-images/find-policy/fp-image-2.png";
import fpimage3 from "../../../../assets/helpcenter-images/find-policy/fp-image-3.png";
import fpimage4 from "../../../../assets/helpcenter-images/find-policy/fp-image-4.jpg";

import cqimage1 from "../../../../assets/helpcenter-images/create-quotes/cq-image-1.png";
import cqimage2 from "../../../../assets/helpcenter-images/create-quotes/cq-image-2.png";
import cqimage3 from "../../../../assets/helpcenter-images/create-quotes/cq-image-3.png";
import cqimage4 from "../../../../assets/helpcenter-images/create-quotes/cq-image-4.png";
import cqimage5 from "../../../../assets/helpcenter-images/create-quotes/cq-image-5.png";
import cqimage6 from "../../../../assets/helpcenter-images/create-quotes/cq-image-6.png";

import ccimage1 from "../../../../assets/helpcenter-images/create-claim/cc-image-1.png";
import ccimage2 from "../../../../assets/helpcenter-images/create-claim/cc-image-2.png";
import ccimage3 from "../../../../assets/helpcenter-images/create-claim/cc-image-3.png";
import ccimage4 from "../../../../assets/helpcenter-images/create-claim/cc-image-14.jpg";

import cvimage1 from "../../../../assets/helpcenter-images/claim-view/cv-image-1.png";
import cvimage2 from "../../../../assets/helpcenter-images/claim-view/cv-image-2.png";
import { getCompanyDetails } from "../../../../services/commonService";

const GettingStarted = () => {
    const companyPhone = getCompanyDetails("phone");

    return(
        <div className="row">
                <div className="col-md-7">
                    <div id="findapolicy">
                        <p className="font-weight-bold mt-3">Find a Policy</p>
                        <p>From the dashboard, or from any screen within the system, select Policy from the list of tabs across the top of the page.</p>
                        <img src={fpimage1} className="img-fluid" alt />
                        <p> The Search Policy is displayed across the main screen.  From here you can search for a policy by customer name, policy number, product, address, etc. </p>
                        <img src={fpimage2} className="img-fluid" alt />

                        <p>Policies matching the search criteria are displayed under Search Result.</p>
                        <img src={fpimage3} className="img-fluid" alt />
                        <p>Click on the policy number to open the policy.</p>
                        <img src={fpimage4} className="img-fluid" alt />
                        <hr />
                    </div>
                    <div id="createaquote">
                        <p className="font-weight-bold mt-3">Create a Quote</p>
                        <p>From the dashboard, or from any screen within the system, select Policy from the list of tabs across the top of the page.</p>
                        <img src={cqimage1} className="img-fluid" alt />

                        <p>The system defaults to the policy, search, select “Create Quote/Bind” from the vertical selections.</p>
                        <img src={cqimage2} className="img-fluid" alt />

                        <p>Note that this box can be expanded or collapsed by user preference, if it is collapsed, you can expand the window by selecting the three dashes across the top 
                            All products available for quoting are now displayed, select the product you would like to quote.
                        </p>
                        <img src={cqimage3} className="img-fluid" alt />

                        <p>Follow the on screen instructions to create the quote.
                            Once the quote is complete and you would like to go forward with the policy, click on “Create Application” 
                        </p>
                        <img src={cqimage4} className="img-fluid" alt />

                        <p>The system will prompt you to ensure that payment is made within the acceptable timeframe based on the policy’s waiting period.  Click “Submit” to digitally sign the application, and create the application.</p>
                        <p>Once you refresh the policy, you will be able to generate the application</p>
                        <img src={cqimage5} className="img-fluid" alt />

                        <p>and make a payment for the policy.</p>
                        <img src={cqimage6} className="img-fluid" alt />

                        <hr />    
                    </div>
                    <div id="createaclaim">
                        <p className="font-weight-bold mt-3">Create a Claim</p>
                        <p>Log into the system and select “Claim” from the headers across the top.</p>
                        <img src={ccimage1} className="img-fluid" alt />

                        <p>Select “Add Claim” from the claims search menu</p>
                        <p>15 questions then click save claim to record the claim.</p>
                        <img src={ccimage2} className="img-fluid" alt />

                        <img src={ccimage3} className="img-fluid" alt />

                        <p>Locate the policy related to the claim by either the policy number, or the name of the Policyholder.</p>
                        <img src={ccimage4} className="img-fluid" alt />

                        <p>Click on the policy number to generate a new claim.  This will generate a new claim, ready for the claims intake.
                            In addition to this, a new claim can be added by clicking “Add New” at the bottom of an existing claim under the Claims View.
                        </p>
                        <hr />    
                    </div>
                    <div id="viewaclaim">
                    <p className="font-weight-bold mt-3">View a Claim</p>
                    <p>The claim view displays an overview of the claim, including contact information, claim type and date of loss, a description of the claim from the claim intake, claim status and total payouts.</p>
                    <img src={cvimage1} className="img-fluid" alt />
                    <p>To assist in workflow management, in addition to open, re-opened, and closed claims, the claims sub-status can be updated to track where along it’s life the claim currently is.  The options include pending property access, engineering report, pending estimates, ect.</p>
                    <img src={cvimage2} className="img-fluid" alt />
                    <hr />    
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="rightdv p-5">
                        <h5 className="font-weight-bold">Quick Links</h5>
                        <br></br>
                        <p><Link activeClass="active" to="findapolicy" spy={true} smooth={true}>Find a Policy</Link></p><hr />
                        <p><Link activeClass="active" to="createaquote" spy={true} smooth={true}>Create a Quote</Link></p><hr />
                        <p><Link activeClass="active" to="createaclaim" spy={true} smooth={true}>Create a Claim</Link></p><hr />
                        <p><Link activeClass="active" to="viewaclaim" spy={true} smooth={true}>View a Claim</Link></p>
                    </div>
                    <div className="rightdv p-5 mt-3">
                        <h5 className="font-weight-bold">Need Support?</h5>
                        <p className="buttonicon"><i className="fa fa-commenting-o" />&nbsp;&nbsp;Live chat with our support Assistant</p>
                        <p>Click on the link to connect with our support assistant. 24/7 service is available for our valueable customers.</p>
                        <button className="btnCustom btnCustom-info pr-4 pl-4">Chat Now</button>
                        <hr />
                        <p><b>Need Support?</b></p>
                        <p className="buttonicon"><i className="fa fa-phone buttonicon" />&nbsp;&nbsp;{companyPhone}</p>
                        <p>Connect directly with our support team. 24/7 service is available for our valueable customers.</p>
                    </div>
                </div>
            </div>

    )
}

export default GettingStarted;