import {
  Box,
  Flex,
  Grid,
  GridItem,
  Input,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  ChakraSelect,
  CustomFormControl,
  CustomInput,
} from "../../commercial-quote/Components/CustomComponents";
import { entityTypeFields } from "../../commercial-quote/data/commercialDD";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { FcInfo } from "react-icons/fc";
import states from "../../../common/States";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import { useEffect, useState } from "react";
import LocationSelectionModal from "../../../common/LocationSelectionModal";

export const ApplicantDetails = () => {
  const { t } = useTranslation();
  const { masterQuoteData, setMasterQuoteData } = useQuote();
  const [zipTriggered, setZipTriggered] = useState(false);
  const [zipData, setZipData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
    masterQuoteData?.applicantDetails?.mailingAddress?.zipCode,
    zipTriggered
  );

  useEffect(() => {
    if (typeof selectedLocation === "object" && selectedLocation !== null) {
      setZipData(selectedLocation);
    }
    if (showModal) {
      setOpenModal(true);
    }
  }, [selectedLocation, showModal]);

  useEffect(() => {
    if (zipData && Object.keys(zipData).length > 0) {
      setMasterQuoteData({
        ...masterQuoteData,
        applicantDetails: {
          ...masterQuoteData.applicantDetails,
          mailingAddress: {
            ...masterQuoteData.applicantDetails.mailingAddress,
            city: zipData?.s_CityName || "",
            stateCode: zipData?.s_StateCode || "",
            cityId: zipData.city_id ?? 0,
            stateId: zipData.state_id ?? 0,
            countryId: zipData.n_CountryId_FK ?? 1,
            countyId: zipData.county_id ?? 0,
            county: zipData.s_CountyName ?? "",
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipData]);

  return (
    <>
      <LocationSelectionModal
        isOpen={openModal}
        selectedLocation={selectedLocation}
        setOpenModal={setOpenModal}
        setZipData={setZipData}
      />
      <Box>
        <Grid templateColumns={"repeat(4, 1fr)"} gap={6}>
          <CustomFormControl label={t("Entity Type")}>
            <ChakraSelect
              value={masterQuoteData?.applicantDetails?.entityType}
              onChange={(e) => {
                setMasterQuoteData({
                  ...masterQuoteData,
                  applicantDetails: {
                    ...masterQuoteData.applicantDetails,
                    entityType: e.target.value,
                  },
                });
              }}
            >
              <option value=""></option>
              {entityTypeFields.map((e, i) => (
                <option key={i} value={e}>
                  {e}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>
        </Grid>
        <Grid templateColumns={"repeat(4, 1fr)"} gap={6} pt={4}>
          <GridItem colSpan={2}>
            <CustomFormControl label={t("Insured / Business Name")}>
              <CustomInput
                value={masterQuoteData?.applicantDetails?.lastName}
                onChange={(e) => {
                  setMasterQuoteData({
                    ...masterQuoteData,
                    applicantDetails: {
                      ...masterQuoteData.applicantDetails,
                      lastName: e.target.value,
                    },
                  });
                }}
              />
            </CustomFormControl>
          </GridItem>
          <GridItem>
            <CustomFormControl label={t("Phone Number")}>
              <Input
                value={masterQuoteData?.applicantDetails?.phoneNumber}
                onChange={(e) => {
                  setMasterQuoteData({
                    ...masterQuoteData,
                    applicantDetails: {
                      ...masterQuoteData.applicantDetails,
                      phoneNumber: e.target.value,
                    },
                  });
                }}
                className="inputSelectBoxes"
                _placeholder={{ color: "gray.500" }}
                placeholder=""
                as={MaskedFormControl}
                mask="(111) 111-1111"
              />
            </CustomFormControl>
          </GridItem>
          <GridItem>
            <CustomFormControl label={t("Email Address")}>
              <CustomInput
                value={masterQuoteData?.applicantDetails?.email}
                onChange={(e) => {
                  setMasterQuoteData({
                    ...masterQuoteData,
                    applicantDetails: {
                      ...masterQuoteData.applicantDetails,
                      email: e.target.value,
                    },
                  });
                }}
              />
            </CustomFormControl>
          </GridItem>
        </Grid>
        <Flex
          mt={2}
          px={1}
          justifyContent={"flex-start"}
          alignItems={"center"}
          alignContent={"center"}
          gap={3}
          width={"45%"}
        >
          <Text
            fontSize={"sm"}
            pt={"1.1rem"}
            fontWeight={500}
            color="neutral.500"
          >
            {t("Is the mailing address the same as the property address")}?
          </Text>
          <Tooltip
            textAlign={"center"}
            hasArrow
            p={2}
            label={
              "Same as the Property address used in the quote. will be assign to Primary Insured"
            }
            placement="top"
          >
            <Box mt={1}>
              <FcInfo size={20} />
            </Box>
          </Tooltip>
          <Switch
            isChecked={masterQuoteData?.applicantDetails?.sameMailingAddress}
            onChange={(e) => {
              setMasterQuoteData({
                ...masterQuoteData,
                applicantDetails: {
                  ...masterQuoteData.applicantDetails,
                  sameMailingAddress: e.target.checked,
                },
              });
            }}
            size={"lg"}
          />
        </Flex>
        {!masterQuoteData?.applicantDetails?.sameMailingAddress && (
          <>
            <Box borderTop={"1px solid"} borderColor={"gray.200"} />
            <Grid
              opacity={isLoading ? 0.5 : 1}
              templateColumns={"repeat(4, 1fr)"}
              gap={6}
              pt={4}
            >
              <GridItem colSpan={2}>
                <CustomFormControl label={t("Mailing Address")}>
                  <CustomInput
                    value={
                      masterQuoteData?.applicantDetails?.mailingAddress
                        ?.address || ""
                    }
                    onChange={(e) => {
                      setMasterQuoteData({
                        ...masterQuoteData,
                        applicantDetails: {
                          ...masterQuoteData.applicantDetails,
                          mailingAddress: {
                            ...masterQuoteData.applicantDetails.mailingAddress,
                            address: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </CustomFormControl>
              </GridItem>
              <GridItem>
                <CustomFormControl label={t("Zip Code")}>
                  <CustomInput
                    value={
                      masterQuoteData?.applicantDetails?.mailingAddress
                        ?.zipCode || ""
                    }
                    type="number"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    onWheel={(e) => {
                      e.target.blur();
                    }}
                    name="zip"
                    onChange={(e) => {
                      if (e.target.value.length <= 5) {
                        setZipTriggered(true);
                        setMasterQuoteData({
                          ...masterQuoteData,
                          applicantDetails: {
                            ...masterQuoteData.applicantDetails,
                            mailingAddress: {
                              ...masterQuoteData.applicantDetails
                                .mailingAddress,
                              zipCode: e.target.value,
                            },
                          },
                        });
                      }
                    }}
                  />
                </CustomFormControl>
              </GridItem>
              <GridItem>
                <CustomFormControl label={t("City")}>
                  <CustomInput
                    value={
                      masterQuoteData?.applicantDetails?.mailingAddress?.city ||
                      ""
                    }
                    onChange={(e) => {
                      setMasterQuoteData({
                        ...masterQuoteData,
                        applicantDetails: {
                          ...masterQuoteData.applicantDetails,
                          mailingAddress: {
                            ...masterQuoteData.applicantDetails.mailingAddress,
                            city: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </CustomFormControl>
              </GridItem>
              <GridItem>
                <CustomFormControl label={t("State")}>
                  <ChakraSelect
                    value={
                      masterQuoteData?.applicantDetails?.mailingAddress
                        ?.stateCode || ""
                    }
                    onChange={(e) => {
                      setMasterQuoteData({
                        ...masterQuoteData,
                        applicantDetails: {
                          ...masterQuoteData.applicantDetails,
                          mailingAddress: {
                            ...masterQuoteData.applicantDetails.mailingAddress,
                            stateCode: e.target.value,
                          },
                        },
                      });
                    }}
                  >
                    <option value=""></option>
                    {states.map((state) => (
                      <option key={state?.abbr} value={state?.abbr}>
                        {state?.name} ({state?.abbr})
                      </option>
                    ))}
                  </ChakraSelect>
                </CustomFormControl>
              </GridItem>
              <GridItem>
                <CustomFormControl label={t("County")}>
                  <CustomInput
                    value={
                      masterQuoteData?.applicantDetails?.mailingAddress
                        ?.county || ""
                    }
                    onChange={(e) => {
                      setMasterQuoteData({
                        ...masterQuoteData,
                        applicantDetails: {
                          ...masterQuoteData.applicantDetails,
                          mailingAddress: {
                            ...masterQuoteData.applicantDetails.mailingAddress,
                            county: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                </CustomFormControl>
              </GridItem>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
