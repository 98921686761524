import React, {useEffect, useState} from "react";
import { Row, Col, Table, Button, Tooltip, Popover } from "react-bootstrap";
import {saveMultipleQuoteData, getBuyQuote, getEditEmptyInstantQuoteData, getCVGAndDeductibleDD,saveNRateCustom} from "../../../../services/instaQuote";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Slider from "rc-slider";
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from "lodash";
import "rc-slider/assets/index.css";
import "./BuyPlan.scss";

const BuyPlan = (props) => {

    const navigation = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState(true);
    const [payPlan, setPayPlan] = useState(150);
    const [basicPropertyCoverage, setBasicPropertyCoverage] = useState({});
    const [recommendedPropertyCoverage, setRecommendedPropertyCoverage] = useState({});
    const [customizedPropertyCoverage, setCustomizedPropertyCoverage] = useState({});
    const [basicDeductibles, setBasicDeductibles] = useState({});
    const [basicPremium, setBasicPremium] = useState(0);
    const [recommendedPremium, setRecommendedPremium] = useState(0);
    const [secondInstallment, setSecondInstallment] = useState(0);
    const [thirdInstallment, setThirdInstallment] = useState(0);
    const [fourthInstallment, setFourthInstallment] = useState(0);
    const [recommendedSecondInstallment, setRecommendedSecondInstallment] = useState(0);
    const [recommendedDeductibles, setRecommendedDeductibles] = useState({});
    const [customizedDeductibles, setCustomizedDeductibles] = useState({});
    const [transactionPK, setTransactionPK] = useState('');

    const [recommendedThirdInstallment, setRecommendedThirdInstallment] = useState(0);
    const [recommendedFourthInstallment, setRecommendedFourthInstallment] = useState(0);
	
	//Custom parameters
	
	const [customSecondInstallment, setCustomSecondInstallment] = useState(0);
	const [customThirdInstallment, setCustomThirdInstallment] = useState(0);
	const [customFourthInstallment, setCustomFourthInstallment] = useState(0);
	const [customPremium, setCustomPremium] = useState(0);
	const [customPremiumDisplay, setCustomPremiumDisplay] = useState(0);
	const [isCustomChanged, setIsCustomChanged] = useState(false);
  
	const [cvgAMin, setCvgAMin] = useState();
	const [cvgAMax, setCvgAMax] = useState();
	const [cvgAStep, setCvgAStep] = useState();
	const [cvgAVal, setCvgAVal] = useState(0);
	
	const [cvgBMin, setCvgBMin] = useState();
	const [cvgBMax, setCvgBMax] = useState();
	const [cvgBStep, setCvgBStep] = useState();
	const [cvgBPercent, setCvgBPercent] = useState(0);
	const [cvgBVal, setCvgBVal] = useState(0);
	
	const [cvgCMin, setCvgCMin] = useState();
	const [cvgCMax, setCvgCMax] = useState();
	const [cvgCStep, setCvgCStep] = useState();
	const [cvgCPercent, setCvgCPercent] = useState(0);
	const [cvgCVal, setCvgCVal] = useState(0);
	
	const [cvgDMin, setCvgDMin] = useState();
	const [cvgDMax, setCvgDMax] = useState();
	const [cvgDStep, setCvgDStep] = useState();
	const [cvgDPercent, setCvgDPercent] = useState(0);
	const [cvgDVal, setCvgDVal] = useState(0);
	const [cvgDMarks, setCvgDMarks] = useState([]);
	
	const [cvgEMin, setCvgEMin] = useState();
	const [cvgEMax, setCvgEMax] = useState();
	const [cvgEStep, setCvgEStep] = useState();
	const [cvgEVal, setCvgEVal] = useState(0);
	const [cvgEMarks, setCvgEMarks] = useState([]);
	
	const [cvgFMin, setCvgFMin] = useState();
	const [cvgFMax, setCvgFMax] = useState();
	const [cvgFStep, setCvgFStep] = useState();
	const [cvgFVal, setCvgFVal] = useState(0);
	const [cvgFMarks, setCvgFMarks] = useState([]);
	
	const [cvgHurMin, setCvgHurMin] = useState();
	const [cvgHurMax, setCvgHurMax] = useState();
	const [cvgHurVal, setCvgHurVal] = useState(0);
	const [cvgHurMarks, setCvgHurMarks] = useState([]);
	const [cvgHurDD, setCvgHurDD] = useState({});
	
	const [cvgNonHurMin, setCvgNonHurMin] = useState();
	const [cvgNonHurMax, setCvgNonHurMax] = useState();
	const [cvgNonHurVal, setCvgNonHurVal] = useState(0);
	const [cvgNonHurMarks, setCvgNonHurMarks] = useState([]);
	const [cvgNonHurDD, setCvgNonHurDD] = useState({});
	
	const numberToPercent = (coverageA, otherCvg) => {
		var data = 0;
		 data =  Math.round((Math.round(otherCvg) / Math.round(coverageA)) * 100  );
		 return data;
	}
   
	const percentToNumber = (coverageA, percent) => {
		var data = 0;
	  data = Math.round((percent * Math.round(coverageA)) / 100);
	  return data;
  }
  
  const getMinMaxSliderFunc = async () => {
	//set slider MinMax
	let actions2 = await dispatch(getCVGAndDeductibleDD()).then((res2) => {
		//Coverage A Slider
		setCvgAMin(res2.data.sliderA.min);
		setCvgAMax(res2.data.sliderA.max)
		setCvgAStep(res2.data.sliderA.step)
		
		//Coverage B Slider
		setCvgBMin(res2.data.sliderB.min);
		setCvgBMax(res2.data.sliderB.max)
		setCvgBStep(res2.data.sliderB.step)
		
		
		//Coverage C Slider
		setCvgCMin(res2.data.sliderC.min);
		setCvgCMax(res2.data.sliderC.max)
		setCvgCStep(res2.data.sliderC.step)
		
		
		//Coverage D Slider
		setCvgDMin(res2.data.sliderD.min);
		setCvgDMax(res2.data.sliderD.max)
		setCvgDStep(res2.data.sliderD.step)
		setCvgDMarks(res2.data.sliderD.marks);
		
		
		//Coverage E Slider
		setCvgEMin(res2.data.sliderE.min);
		setCvgEMax(res2.data.sliderE.max)
		setCvgEStep(res2.data.sliderE.step)
		setCvgEMarks(res2.data.sliderE.marks);
		
		
		//Coverage F Slider
		setCvgFMin(res2.data.sliderF.min);
		setCvgFMax(res2.data.sliderF.max)
		setCvgFStep(res2.data.sliderF.step)
		setCvgFMarks(res2.data.sliderF.marks);
		
		setCvgHurMin(res2.data.sliderHur.min);
		setCvgHurMax(res2.data.sliderHur.max);
		setCvgHurMarks(res2.data.sliderHur.marks);
		setCvgHurDD(res2.data.sliderHur.baseData); //1st
		
		setCvgNonHurMin(res2.data.sliderNonHur.min);
		setCvgNonHurMax(res2.data.sliderNonHur.max);
		setCvgNonHurMarks(res2.data.sliderNonHur.marks);
		setCvgNonHurDD(res2.data.sliderNonHur.baseData); //1st
		
	})
}

useEffect(() => {
		if (saveStatus && Object.keys(cvgNonHurDD).length) {
			let actions = dispatch(saveMultipleQuoteData(props.emptyQuote)).then((res) => {
			  
			  //Custom
			  setCustomizedDeductibles(res.data.CUSTOM.RateResult[0]);
			  setCustomPremium(res.data.CUSTOM.RateResult[0].FinalPremium || 0);
			  setCustomPremiumDisplay(res.data.CUSTOM.RateResult[0].FinalPremium || 0);
			  
			  setCvgAVal(res.data.customPlanDetail[0].n_coverageA)
			  
			  setCvgBPercent(numberToPercent(res.data.customPlanDetail[0].n_coverageA, res.data.customPlanDetail[0].n_coverageB));
			  setCvgBVal(res.data.customPlanDetail[0].n_coverageB);
			  
			  setCvgCPercent(numberToPercent(res.data.customPlanDetail[0].n_coverageA, res.data.customPlanDetail[0].n_coverageC));
			  setCvgCVal(res.data.customPlanDetail[0].n_coverageC);
			  
			  setCvgDPercent(numberToPercent(res.data.customPlanDetail[0].n_coverageA, res.data.customPlanDetail[0].n_coverageD));
			  setCvgDVal(res.data.customPlanDetail[0].n_coverageD);
			  
			  setCvgEVal(res.data.customPlanDetail[0].n_coverageE);

			  setCvgFVal(res.data.customPlanDetail[0].n_CoverageF);

			  setCvgHurVal(cvgHurDD[res.data.customPlanDetail[0].s_HurricaneDedutible]); //2nd

			  setCvgNonHurVal(cvgNonHurDD[res.data.customPlanDetail[0].s_AOPDedutible]);
			  
			  setBasicPropertyCoverage(res.data.basicPlanDetail[0]);
			  setRecommendedPropertyCoverage(res.data.recommendedPlanDetail[0]);
			  setCustomizedPropertyCoverage(res.data.customPlanDetail[0]);
			  setBasicDeductibles(res.data.BASIC.RateResult[0]);
			  setRecommendedDeductibles(res.data.RECOMMENDED.RateResult[0]);
			  setTransactionPK(res.data.transactionPK);
			  setBasicPremium(basicDeductibles.FinalPremium);
			  setRecommendedPremium(recommendedDeductibles.FinalPremium);
			  setSaveStatus(false);
			  setLoading(false);
			})
		}
	
	
  }, [cvgNonHurDD]);
  
  useEffect(() => {
      if (saveStatus) {
		setLoading(true);
		getMinMaxSliderFunc();
		
      }
  }, []);
  
  useEffect(() => {
	  if(isCustomChanged){
		  
		  callCustomChangeAPI();
	  }
  }, [isCustomChanged]);
  
  const callCustomChangeAPI = () => {
	setLoading(true);
	var MainCustomParams = customizedPropertyCoverage;
	MainCustomParams['n_coverageA'] = cvgAVal;
	MainCustomParams['n_coverageB'] = cvgBVal;
	MainCustomParams['n_coverageC'] = cvgCVal;
	MainCustomParams['n_coverageD'] = cvgDVal;
	MainCustomParams['n_coverageE'] = cvgEVal;
	MainCustomParams['n_CoverageF'] = cvgFVal;
	MainCustomParams['s_HurricaneDedutible'] = cvgHurVal;
	MainCustomParams['s_AOPDedutible'] = cvgNonHurVal;
	
	const params =  {
		trans_id: transactionPK,
		coverageData: MainCustomParams
	};
	let actions = dispatch(saveNRateCustom(params)).then((res) => {
		setCustomPremium(res.data.CUSTOM.RateResult[0].FinalPremium || 0);
		
			if (payPlan == 0) {
			  setCustomPremiumDisplay(((res.data.CUSTOM.RateResult[0].FinalPremium) * 60) / 100);
			  setCustomSecondInstallment(((customPremium) * 40) / 100);
			} else if (payPlan == 50) {
			  setCustomPremiumDisplay(((res.data.CUSTOM.RateResult[0].FinalPremium) * 40) / 100);
			  setCustomSecondInstallment(((res.data.CUSTOM.RateResult[0].FinalPremium) * 20) / 100);
			  setCustomThirdInstallment(((res.data.CUSTOM.RateResult[0].FinalPremium) * 20) / 100);
			  setCustomFourthInstallment(((res.data.CUSTOM.RateResult[0].FinalPremium) * 20) / 100);
			} else {			  
			  setCustomPremiumDisplay(res.data.CUSTOM.RateResult[0].FinalPremium);
			}
		setLoading(false);
		setIsCustomChanged(false);
	})
  }

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    useEffect(() => {
        props.emptyQuote.lossHistoryData = props.emptyQuote.lossHistoryData === null ? { 'lossHistoryData' : []} : props.emptyQuote.lossHistoryData;
        props.emptyQuote.mortgageeData = props.emptyQuote.mortgageeData === null ? {'mortgageeData' : []} : props.emptyQuote.mortgageeData;
        
    }, [props]);

    useEffect(() => {
		let emptyQuote = { ...props.emptyQuote };
        if (payPlan == 0) {
			setBasicPremium(((basicDeductibles.FinalPremium) * 60) / 100);
			setRecommendedPremium(((recommendedDeductibles.FinalPremium) * 60) / 100);
			setSecondInstallment(((basicDeductibles.FinalPremium) * 40) / 100);
			setRecommendedSecondInstallment(((recommendedDeductibles.FinalPremium) * 40) / 100);
			setRecommendedThirdInstallment(0);
			setRecommendedFourthInstallment(0);
			setThirdInstallment(0);
			setFourthInstallment(0);
			
			setCustomPremiumDisplay(((customPremium) * 60) / 100);
			setCustomSecondInstallment(((customPremium) * 40) / 100);
			emptyQuote.policyInfo.s_PayPlan_Code = '2PAY';
		  } else if (payPlan == 50) {
			setBasicPremium(((basicDeductibles.FinalPremium) * 40) / 100);
			setSecondInstallment(((basicDeductibles.FinalPremium) * 20) / 100);
			setThirdInstallment(((basicDeductibles.FinalPremium) * 20) / 100);
			setFourthInstallment(((basicDeductibles.FinalPremium) * 20) / 100);
	
			setRecommendedPremium(((recommendedDeductibles.FinalPremium) * 40) / 100);
			setRecommendedSecondInstallment(((recommendedDeductibles.FinalPremium) * 20) / 100);
			setRecommendedThirdInstallment(((recommendedDeductibles.FinalPremium) * 20) / 100);
			setRecommendedFourthInstallment(((recommendedDeductibles.FinalPremium) * 20) / 100);
			
			setCustomPremiumDisplay(((customPremium) * 40) / 100);
			setCustomSecondInstallment(((customPremium) * 20) / 100);
			setCustomThirdInstallment(((customPremium) * 20) / 100);
			setCustomFourthInstallment(((customPremium) * 20) / 100);
			emptyQuote.policyInfo.s_PayPlan_Code = '4PAY';
		  } else {
			setBasicPremium(basicDeductibles.FinalPremium);
			setRecommendedPremium(recommendedDeductibles.FinalPremium);
			setCustomPremiumDisplay(customPremium);
			emptyQuote.policyInfo.s_PayPlan_Code = 'FULLPAY';
		  }
		dispatch(getEditEmptyInstantQuoteData(emptyQuote));
    }, [payPlan]);

    const handleSubmit = (e, plan) => {
        var logPK = '';
        if(plan == "Basic"){
            logPK = basicPropertyCoverage.n_datalog_PK;
        }else if(plan == "Recommended"){
            logPK = recommendedPropertyCoverage.n_datalog_PK;
        }else{
            logPK = customizedPropertyCoverage.n_datalog_PK
        }

        const params = {
            trans_id : transactionPK,
            datalog_id: logPK || ''
        }

        setLoading(true);
        let actions = dispatch(getBuyQuote(params)).then((res) => {
            dispatch(getEditEmptyInstantQuoteData(res.data));
            const allData = res.data;
            if(!isEmpty(props.emptyQuote.mapData)){
                allData.mapData.autocomplete = props.emptyQuote.mapData.autocomplete;
                allData.mapData.latitude = props.emptyQuote.mapData.latitude;
                allData.mapData.longitude = props.emptyQuote.mapData.longitude;
                allData.mapData.street_number = props.emptyQuote.mapData.street_number;
                allData.mapData.street_name = props.emptyQuote.mapData.street_name;
                allData.mapData.state = props.emptyQuote.mapData.state;
                allData.mapData.country = props.emptyQuote.mapData.country;
                allData.mapData.city = props.emptyQuote.mapData.city;
                allData.mapData.PostalCode = props.emptyQuote.mapData.PostalCode;
                allData.mapData.CountyName = props.emptyQuote.mapData.CountyName;
            }

            if(!isEmpty(res.data.propertyInfo)){
                allData.riskRenovationData.roofInfo.s_RenovationYear = res.data.propertyInfo.n_YearBuilt;
                allData.riskRenovationData.electricityInfo.s_RenovationYear = res.data.propertyInfo.n_YearBuilt;
                allData.riskRenovationData.plumbingInfo.s_RenovationYear = res.data.propertyInfo.n_YearBuilt;
                allData.riskRenovationData.waterHeaterInfo.s_RenovationYear = res.data.propertyInfo.n_YearBuilt;
                allData.riskRenovationData.roofInfo.s_RenovationCode = 'RVROOF';
                allData.riskRenovationData.electricityInfo.s_RenovationCode = 'RVELECTRIC';
                allData.riskRenovationData.plumbingInfo.s_RenovationCode = 'RVPLUMB';
                allData.riskRenovationData.waterHeaterInfo.s_RenovationCode = 'RVHWHEAT';
            }
            var fullName = res.data.personInfo.s_FullLegalName;
            allData.personInfo.s_FirstName = fullName.substr(0, fullName.indexOf(' '));
            allData.personInfo.s_LastOrganizationName = fullName.substr(fullName.indexOf(' ') + 1);
            dispatch(getEditEmptyInstantQuoteData(allData));
            navigation('/instaQuote/InstaSteps/5');
            setLoading(false);
        })
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Simple tooltip
        </Tooltip>
    );

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Premium</Popover.Header>
            <Popover.Body>
                {payPlan == 0 &&
                    <><span>First Installment - {basicPremium}</span><br></br><span>Second Installment - {secondInstallment}</span></>
                }
                {payPlan == 50 &&
                    <><span>First Installment - {basicPremium}</span><br></br><span>Second Installment - {secondInstallment}</span><br></br>{thirdInstallment != 0 && <span>Third Installment - {thirdInstallment}</span>}<br></br>{fourthInstallment != 0 && <span>Fourth Installment - {fourthInstallment}</span>}</>
                }
                {payPlan == 100 &&
                    <><span>{(Math.round(basicPremium / 12)).toLocaleString()}/<span>month</span></span></>
                }
            </Popover.Body>
        </Popover>
    )
	  
	  const handleChangeSlider = (e,coverage) => {
		  if(coverage == 'CoverageA'){
			setCvgAVal(e);
			setCvgBVal(percentToNumber(cvgAVal,cvgBPercent));
			setCvgCVal(percentToNumber(cvgAVal,cvgCPercent));
			setCvgDVal(percentToNumber(cvgAVal,cvgDPercent));
			setIsCustomChanged(true);
		  } else if(coverage == 'CoverageB'){
			setCvgBPercent(e);
			setCvgBVal(percentToNumber(cvgAVal,e));
			setIsCustomChanged(true);
		  } else if(coverage == 'CoverageC'){
			setCvgCPercent(e);
			setCvgCVal(percentToNumber(cvgAVal,e));
			setIsCustomChanged(true);
		  } else if(coverage == 'CoverageD'){
			setCvgDPercent(e);
			setCvgDVal(percentToNumber(cvgAVal,e));
			setIsCustomChanged(true);
		  } else if(coverage == 'CoverageE'){
			setCvgEVal(e);
			if(e == 100000){
				setCvgFVal(1000);
			  } else if(e == 300000){
				setCvgFVal(3000);
			  } else if(e == 500000){
				setCvgFVal(5000);
			  } else {
				setCvgFVal(0);
			  }
			  setIsCustomChanged(true);
		  } else if(coverage == 'CoverageF'){
			setCvgFVal(e);
			if(e == 1000){
				setCvgEVal(100000);
			  } else if(e == 3000){
				setCvgEVal(300000);
			  } else if(e == 5000){
				setCvgEVal(500000);
			  } else {
				setCvgEVal(0);
			  }
			  setIsCustomChanged(true);
		  } else if(coverage == 'Hur'){
			setCvgHurVal(e);
			setIsCustomChanged(true);
		  } else if(coverage == 'NonHur'){
			setCvgNonHurVal(e);
			setIsCustomChanged(true);
		  }
		  
		  
	  }

    return(
        <LoadingOverlay>
            <Loader loading={loading} />
            {
                <section className="pricing-details-section">
                    <style>
                        {`.rc-slider-mark {
                        display:Block  !important
                        }`}
                    </style>
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pricing-page-heading">
                            <Row className="justify-content-center">
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <h5>{t("Buy Your Plan")}</h5>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="pricing-table-main">
                            <Table responsive borderless className="pricing-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="pay-plan-col-main">
                                                <span style={{ visibility: "hidden" }}>dfdfdf</span>
                                                <div className="pay-plan-col">
                                                    <span className="pay-plan-h">{t("Pay Plan")}</span>
                                                    <div className="pay-range" style={{ width: "69%", paddingRight: "2rem" }}>
                                                        <Slider 
                                                            min={0} 
                                                            max={150} 
                                                            defaultValue={150}
                                                            marks={{
                                                                0: `2 ${t("Pay")}`,
                                                                50: `4 ${t("Pay")}`,
                                                                100: <><span>Mortgagee /</span><br></br><span>Escrow Bill</span></>,
                                                                150: `Full ${t("Pay")}`
                                                            }}
                                                            step={null}
                                                            onChange={(e) => setPayPlan(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <h5>{t("Basic")}</h5>
                                                {/* basic 2 Pay */}
                                                {payPlan == 0 && <><span className="first-installment per-month">
                                                    ${(Math.round(basicPremium)).toLocaleString()}/<span> 1st installment</span> <br></br> </span></>
                                                }
                                                {payPlan == 0 && secondInstallment != 0 && <><span className="per-year">
                                                    ${(Math.round(secondInstallment)).toLocaleString()}/<span> Remaining installment</span> <br></br> </span></>
                                                }

                                                {/* basic 4 Pay - new */}
                                                {payPlan == 50 && <><span className="first-installment per-month">
                                                    ${(Math.round(basicPremium)).toLocaleString()}/<span>1st installment</span> <br></br></span></>
                                                }
                                                {payPlan == 50 && secondInstallment != 0 && <><span className="per-year">
                                                    ${(Math.round(secondInstallment)).toLocaleString()}/<span> 3 Remaining installments</span> <br></br></span></>
                                                }

                                                {/* basic Mortgagee */}
                                                {payPlan == 100 && <><span className="per-month">
                                                    ${(Math.round(basicPremium / 12)).toLocaleString()}/<span>month</span><br></br></span></>
                                                }
                                                {payPlan == 100 && <><span className="per-year">
                                                    ${(Math.round(basicPremium)).toLocaleString()}/<span>Year</span> <br></br></span></>
                                                }

                                                {/* basic Full Pay */}
                                                {payPlan == 150 && <><span className="per-month">
                                                    ${(Math.round(basicDeductibles.FinalPremium)).toLocaleString()}/<span>Year</span><br></br></span></>
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <h5>{t("Recommended")}</h5>
                                                {/* basic 2 Pay - new */}
                                                {payPlan == 0 && <><span className="first-installment per-month">
                                                    ${(Math.round(recommendedPremium)).toLocaleString()}/<span> 1st installment</span> <br></br> </span></>
                                                }
                                                {payPlan == 0 && recommendedSecondInstallment != 0 && <><span className="per-year">
                                                    ${(Math.round(recommendedSecondInstallment)).toLocaleString()}/<span> Remaining installment</span> <br></br> </span></>
                                                }

                                                {/* basic 4 Pay - new  */}
                                                {payPlan == 50 && <><span className="first-installment per-month">
                                                    ${(Math.round(recommendedPremium)).toLocaleString()}/<span> 1st installment</span> <br></br> </span></>
                                                }
                                                {payPlan == 50 && recommendedSecondInstallment != 0 && <><span className="per-year">
                                                    ${(Math.round(recommendedSecondInstallment)).toLocaleString()}/<span> 3 Remaining installments</span> <br></br></span></>
                                                }

                                                {/* basic Mortgagee */}
                                                {payPlan == 100 && <><span className="per-month">
                                                    ${(Math.round(recommendedPremium / 12)).toLocaleString()}/<span>month</span><br></br> </span></>
                                                }
                                                {payPlan == 100 && <><span className="per-year">
                                                    ${(Math.round(recommendedPremium)).toLocaleString()}/<span>Year</span> <br></br> </span></>
                                                }

                                                {/* basic Full Pay */}
                                                {payPlan == 150 && <><span className="per-month">
                                                    ${(Math.round(recommendedDeductibles.FinalPremium)).toLocaleString()}/<span>Year</span><br></br></span></>
                                                }
                                            </div>
                                        </th>
                                        <th>
										<div>
					                          <h5>{t("Customize Your Way")}</h5>
											  {payPlan == 0 && <><span className="first-installment per-month">
					                            ${(Math.round(customPremiumDisplay)).toLocaleString()}/<span> 1st installment</span> <br></br>
					                          </span></>
					                          }
					                          {payPlan == 0 && customSecondInstallment != 0 && <><span className="per-year">
					                            ${(Math.round(customSecondInstallment)).toLocaleString()}/<span> Remaining installment</span> <br></br>
					                          </span></>
					                          }
											  {payPlan == 50 && <><span className="first-installment per-month">
					                            ${(Math.round(customPremiumDisplay)).toLocaleString()}/<span> 1st installment</span> <br></br>
					                          </span></>
					                          }
					                          {payPlan == 50 && customSecondInstallment != 0 && <><span className="per-year">
					                            ${(Math.round(customSecondInstallment)).toLocaleString()}/<span> 3 Remaining installments</span> <br></br>
					                          </span></>
					                          }

					                          {/* basic Mortgagee */}
					                          {payPlan == 100 && <><span className="per-month">
					                            ${(Math.round(customPremiumDisplay / 12)).toLocaleString()}/<span>month</span><br></br>
					                          </span></>
					                          }
					                          {payPlan == 100 && <><span className="per-year">
					                            ${(Math.round(customPremiumDisplay)).toLocaleString()}/<span>Year</span> <br></br>
					                          </span></>
					                          }

					                          {/* basic Full Pay */}
					                          {payPlan == 150 && <><span className="per-month">
					                            ${(Math.round(customPremiumDisplay)).toLocaleString()}/<span>Year</span><br></br>
					                          </span></>
					                          }
					                          {/* <span className="per-month">
					                        $200/<span>m</span>
					                      </span>
					                      <br />
					                      <span className="per-year">$3000 /{t("Year")}</span> */}
					                        </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="special-row">
                                        <td>{t("Property Coverage")}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>{t("dwelling (Coverage A)")}</td>
                                        <td>${basicPropertyCoverage.n_coverageA ? (Math.round(basicPropertyCoverage.n_coverageA)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_coverageA ? (Math.round(recommendedPropertyCoverage.n_coverageA)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
					                            {/*${customCvgA}*/}
												${cvgAVal ? (Math.round(cvgAVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
													<Slider
						                              min={cvgAMin}
						                              max={cvgAMax}
						                              value={cvgAVal}
						                              step={cvgAStep}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageA') }}
						                            />
												  {/*<Slider
					                                min={200000}
					                                max={800000}
					                                defaultValue={800000}
					                                step={5000}
					                                onChange={(e) => setDwellingVal(e)}
					                              />*/}
					                            </div>
					                          </Col>
					                        </Row>
				                      </td>
                                    </tr>

                                    <tr>
                                        <td>{t("Other Structures (Coverage B)")}</td>
                                        <td>${basicPropertyCoverage.n_coverageB ? (Math.round(basicPropertyCoverage.n_coverageB)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_coverageB ? (Math.round(recommendedPropertyCoverage.n_coverageB)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
					                            
												${cvgBVal ? (Math.round(cvgBVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
													<Slider
						                              min={cvgBMin}
						                              max={cvgBMax}
						                              value={cvgBPercent}
						                              step={cvgBStep}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageB') }}
						                            />
					                              {/*<Slider
					                                min={0}
					                                max={15}
					                                defaultValue={15}
					                                step={1}
					                                onChange={(e) => setStrVal(e)}
					                              />*/}
					                            </div>
					                          </Col>
											  <Col xs="12">
												  <div style={{'margin-right':'-25%'}} >
													  <small style={{fontSize:'13px',marginLeft: "1rem" }}> {cvgBPercent}%</small>
												  </div>
											  </Col>
					                        </Row>
					                    </td>
                                    </tr>

                                    <tr>
                                        <td>{t("Personal property (Coverage C)")}</td>
                                        <td>${basicPropertyCoverage.n_coverageC ? (Math.round(basicPropertyCoverage.n_coverageC)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_coverageC ? (Math.round(recommendedPropertyCoverage.n_coverageC)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
											  	${cvgCVal ? (Math.round(cvgCVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
													<Slider
						                              min={cvgCMin}
						                              max={cvgCMax}
						                              value={cvgCPercent}
						                              step={cvgCStep}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageC') }}
						                            />
												  {/*<Slider
					                                min={0}
					                                max={75}
					                                defaultValue={75}
					                                step={1}
					                                onChange={(e) => setPropertyVal(e)}
					                              />*/}
					                            </div>
					                          </Col>
											  <Col xs="12">
												  <div style={{'margin-right':'-25%'}} >
													  <small style={{fontSize:'13px',marginLeft: "1rem" }}> {cvgCPercent}%</small>
												  </div>
											  </Col>
					                        </Row>
					                    </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>{t("Loss of Use (Coverage D)")}</td>
                                        <td>${basicPropertyCoverage.n_coverageD ? (Math.round(basicPropertyCoverage.n_coverageD)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_coverageD ? (Math.round(recommendedPropertyCoverage.n_coverageD)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
											  	${cvgDVal ? (Math.round(cvgDVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
												<div className="slider">
													<Slider
						                              min={cvgDMin}
						                              max={cvgDMax}
						                              value={cvgDPercent}
													  marks={cvgDMarks}
						                              step={null}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageD') }}
						                            />
					                              {/*<Slider
					                                min={10}
					                                max={20}
					                                defaultValue={20}
					                                step={10}
					                                onChange={(e) => setLossVal(e)}
					                              />*/}
					                            </div>
					                          </Col>
											  <Col xs="12">
												  <div style={{'margin-right':'-25%'}} >
													  <small style={{fontSize:'13px',marginLeft: "1rem" }}>  {cvgDPercent}%</small>
												  </div>
											  </Col>
					                        </Row>
					                    </td>
                                    </tr>

                                    <tr>
                                        <td>{t("Personal Liability (Coverage E)")}</td>
                                        <td>${basicPropertyCoverage.n_coverageE ? (Math.round(basicPropertyCoverage.n_coverageE)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_coverageE ? (Math.round(recommendedPropertyCoverage.n_coverageE)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
											  	${cvgEVal ? (Math.round(cvgEVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
													<Slider
						                              min={cvgEMin}
						                              max={cvgEMax}
						                              value={cvgEVal}
						                              step={null}
													  marks={cvgEMarks}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageE') }}
						                            />
					                            </div>
					                          </Col>
					                        </Row>
					                    </td>
                                    </tr>

                                    <tr>
                                        <td>{t("Medical Payments (Coverage F)")}</td>
                                        <td>${basicPropertyCoverage.n_CoverageF ? (Math.round(basicPropertyCoverage.n_CoverageF)).toLocaleString() : 0}</td>
                                        <td>${recommendedPropertyCoverage.n_CoverageF ? (Math.round(recommendedPropertyCoverage.n_CoverageF)).toLocaleString() : 0}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
												${cvgFVal ? (Math.round(cvgFVal)).toLocaleString() : 0}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
													<Slider
						                              min={cvgFMin}
						                              max={cvgFMax}
						                              value={cvgFVal}
						                              step={null}
													  marks={cvgFMarks}
						                            //  onAfterChange={callCustomChangeAPI}
						                              onChange={(e) => {  handleChangeSlider(e,'CoverageF') }}
						                            />
					                            </div>
					                          </Col>
					                        </Row>
				                      </td>
                                    </tr>

                                    <tr className="special-row">
                                        <td>{t("Deductibles")}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td>{t("Hurricane")}</td>
                                        <td>{basicPropertyCoverage.hurricanDed}</td>
                                        <td>{recommendedPropertyCoverage.hurricanDed}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
					                            {cvgHurVal}%
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
					                              <Slider
					                                min={cvgHurMin}
					                                max={cvgHurMax}
													marks={cvgHurMarks}
					                                value={cvgHurVal}
													step={null}
													//onAfterChange={callCustomChangeAPI}
					                                onChange={(e) => {  handleChangeSlider(e,'Hur') }}
					                              />
					                            </div>
					                          </Col>
					                        </Row>
					                    </td>
                                    </tr>

                                    <tr>
                                        <td>{t("Non Hurricane")}</td>
                                        <td>{basicPropertyCoverage.nonHurricanDed}</td>
                                        <td>{recommendedPropertyCoverage.nonHurricanDed}</td>
                                        <td className="range-col">
					                        <Row className="range-full-row">
					                          <Col xs="4" className="range-col-text">
											  ${cvgNonHurVal}
					                          </Col>
					                          <Col xs="8">
					                            <div className="slider">
					                              <Slider
					                                min={cvgNonHurMin}
					                                max={cvgNonHurMax}
					                                value={cvgNonHurVal}
													marks={cvgNonHurMarks}
													step={null}
													//onAfterChange={callCustomChangeAPI}
					                                onChange={(e) => {  handleChangeSlider(e,'NonHur') }}
					                              />
					                            </div>
					                          </Col>
					                        </Row>
					                      </td>
                                    </tr>

                                    <tr>
                                        <td>
                                        </td>
                                        <td>
                                            <Button className="buy-btn" id="primary-colored-btn" variant="primary" onClick={(e) => handleSubmit(e, 'Basic')}> {t("Buy Now")} </Button>
                                        </td>
                                        <td>
                                            {" "}
                                            <Button className="buy-btn" id="primary-colored-btn" variant="primary" onClick={(e) => handleSubmit(e, 'Recommended')}> {t("Buy Now")} </Button>
                                        </td>
                                        <td>
                                            
											<Button className="buy-btn" id="primary-colored-btn" variant="primary" onClick={(e) => handleSubmit(e, 'Custom')}> {t("Buy Now")} </Button>
                                            
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </section>
            }
        </LoadingOverlay>
    )
}

function mapStateToProps(state) {
  return {
    emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
  };
}

export default connect(mapStateToProps)(BuyPlan)