import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Dropdown from 'react-bootstrap/Dropdown';
// import FloodACHPayment from "../../quote/flood/quote/Payment/FloodACHPayment";
// import FloodCCPayment from "../../quote/flood/quote/Payment/FloodCCPayment";
// import { getHostUrl } from "../../common/HostUrl";
// import { getPayGovToken } from "../../../services/paymentService";
import { useDispatch } from "react-redux";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
// import { toast } from 'react-toastify';

const EndorsementDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { floodSystemDD, applicationData, propertyInfo, newPremium, poTransPK, premiumChange, setLoading, showPaymentBtn, isRateSuccessfully } = props;

    const [effectiveDate, setEffectiveDate] = useState(null);
    const [waitingPeriod, setWaitingPeriod] = useState('');
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [paymentModalView, setPaymentModalView] = useState(null);
    const [policyNo, setPolicyNo] = useState(null);

    useEffect(() => {
        if (applicationData && Object.keys(applicationData).length > 0) {
            setEffectiveDate(new Date(applicationData.transInfo[0].d_TransEffectiveFrom));
            setPolicyNo(applicationData.Policy_No || null);
        }
        if (propertyInfo && Object.keys(propertyInfo).length > 0) {
            setWaitingPeriod(propertyInfo.RA.s_PolicyWaitingPeriod);
        }
    }, [applicationData])

    /*const handlePaymentOption = (type) => {
        if (![undefined, null, ''].includes(type)) {
            if (type === 'ECHECK') {
                var hostUrl = getHostUrl();

                const paymentSuccessUrl = hostUrl + `/core/policy/` + policyNo;
                const paymentCancelUrl = paymentSuccessUrl;

                const params = {
                    'transaction_amount': premiumChange,
                    'url_success': paymentSuccessUrl,
                    'url_cancel': paymentCancelUrl,
                    'payment_type': 'PLASTIC_CARD',
                    'policyNo': policyNo,
                };

                setLoading(true);
                dispatch(getPayGovToken(params)).then((res) => {
                    setLoading(false);
                    if (res.data.token != '') {
                        window.location = res.data.payment_page_url;
                    }
                }).catch(() => {
                    toast.error(t('Something Went Wrong!'));
                });
            } else {
                setPaymentModalView(<FloodACHPayment type={type} poTransPk={poTransPK} afterPayment={handlePaymentModalClose} />);
                setShowPaymentModal(true);
            }
        }
    }*/

    const handlePaymentModalClose = (value) => {
        setPaymentModalView(null);
        setShowPaymentModal(value);
    }

    return (
        <Row className="mt-2">
            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Waiting Period')}
                    </Form.Label>
                    <Form.Select
                        className="table-show-entries-select"
                        name="s_PolicyWaitingPeriod"
                        title={t('Waiting Period')}
                        parentobject="propertyInfo"
                        value={waitingPeriod}
                        disabled={true}
                    >
                        <option value={''}>{t('Select')}</option>
                        {
                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.FLOODWAITINGPERIOD.map((v, i) => {
                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Effective Date')}
                    </Form.Label>
                    <DatePicker
                        id="dashboard-datepicker"
                        className={("dashboard-datepicker-main")}
                        placeholderText="MM/DD/YYYY"
                        selected={effectiveDate}
                        autoComplete="off"
                        readOnly={true}
                    />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Quoted Premium')}
                    </Form.Label>
                    <NumberFormat
                        className="dashboard-group-input-flood"
                        style={{ width: '100%', textAlign: "right", backgroundColor: '#E9ECEF' }}
                        type="text"
                        displayType={'input'}
                        prefix={'$'}
                        thousandSeparator={true}
                        allowNegative={false}
                        name="premium"
                        value={newPremium}
                        min={0}
                        autoComplete="off"
                        readOnly={true}
                    />
                </Form.Group>
            </Col>
            <Col xs="12" sm="12" md="6" lg="2" xl="2">
                <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                >
                    <Form.Label className="dashboard-group-label">
                        {t('Total Premium Change')}{" "}
                        {
                            isRateSuccessfully == 'N' && (<AiOutlineInfoCircle size={25} data-tip data-for="rate-not-found" />)
                        }
                    </Form.Label>
                    <ReactTooltip id="rate-not-found" place="top" effect="solid">
                        Please Click on <b>Submit</b> to Rate
                    </ReactTooltip>
                    <NumberFormat
                        className="dashboard-group-input-flood"
                        style={{ width: '100%', textAlign: "right", backgroundColor: '#E9ECEF' }}
                        type="text"
                        displayType={'input'}
                        prefix={'$'}
                        thousandSeparator={true}
                        allowNegative={false}
                        name="premiumChange"
                        value={premiumChange}
                        min={0}
                        autoComplete="off"
                        readOnly={true}
                    />
                </Form.Group>
            </Col>
            {/* {
                showPaymentBtn && (
                    <Col xs="12" sm="12" md="6" lg="2" xl="2" style={{ marginTop: '30px' }}>
                        <ButtonGroup>
                            <DropdownButton
                                as={ButtonGroup}
                                title={t('Pay Now')}
                                id="primary-grey-btn-flood"
                            >
                                <Dropdown.Item onClick={() => handlePaymentOption("ECHECK")}>{t('Credit Card')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handlePaymentOption("ACH")}>{t('eCheck')}</Dropdown.Item>
                            </DropdownButton>
                        </ButtonGroup>
                    </Col>
                )
            } */}
            {
                showPaymentModal && (
                    <Modal
                        size="xl"
                        show={showPaymentModal}
                        className="custom-dashboard-modal"
                        onHide={() => handlePaymentModalClose(false)}
                    >
                        <Modal.Header
                            closeButton
                            className="custom-dashboard-modal-header"
                        >
                            <Modal.Title>{t('Pay Now')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            className="custom-dashboard-modal-body"
                        >
                            {paymentModalView}
                        </Modal.Body>
                    </Modal>
                )
            }
        </Row>
    )
}

export default EndorsementDetails;