import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommercialDataDD } from "../../../data/CommercialData";

export default function ApplicantInfoUpdateModal({
  personDetails,
  showApplicantModal,
  setShowApplicantModal,
  setSaveApplicantInfo,
  setBindDetails,
}) {
  const { t } = useTranslation();
  const applicantInfoFields = {
    propertyType: "",
    firstName: "",
    lastName: "",
  };
  const [applicantInfo, setApplicantInfo] = useState(applicantInfoFields);
  const [applicantInfoError, setApplicantInfoError] = useState({
    propertyTypeError: false,
    firstNameError: false,
    lastNameError: false,
  });
  const [lastNameLabel, setLastNameLabel] = useState();
  const [isApplicantTypeChanged, setIsApplicantTypeChanged] = useState(false);

  useEffect(() => {
    setApplicantInfo((prev) => ({
      ...prev,
      propertyType: personDetails?.entityType,
      firstName: personDetails?.firstName,
      lastName: personDetails?.lastName,
    }));
  }, [personDetails]);

  useEffect(() => {
    const propertyType = applicantInfo?.propertyType?.toLowerCase();
    setLastNameLabel(
      propertyType === "individual" ? "Last Name" : "Organisation Name"
    );

    if (isApplicantTypeChanged) {
      setApplicantInfo((prev) => ({
        ...prev,
        firstName: "",
        lastName: "",
      }));
      setIsApplicantTypeChanged(false);
    }

    setApplicantInfoError({
      propertyTypeError: false,
      firstNameError: false,
      lastNameError: false,
    });
  }, [applicantInfo?.propertyType, isApplicantTypeChanged]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApplicantInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "propertyType") {
      setIsApplicantTypeChanged(true);
    }
  };

  const saveApplicantData = () => {
    const propertyType = applicantInfo?.propertyType?.toLowerCase();
    if (!propertyType) {
      setApplicantInfoError((prev) => ({
        ...prev,
        propertyTypeError: true,
        lastNameError: true,
      }));
      return;
    }

    if (
      propertyType === "individual" &&
      (!applicantInfo?.firstName || !applicantInfo?.lastName)
    ) {
      setApplicantInfoError((prev) => ({
        ...prev,
        firstNameError: true,
        lastNameError: true,
      }));
      return;
    }

    if (propertyType === "organisation" && !applicantInfo?.lastName) {
      setApplicantInfoError((prev) => ({
        ...prev,
        lastNameError: true,
      }));
      return;
    }

    setBindDetails((prev) => ({
      ...prev,
      firstName: applicantInfo?.firstName,
      lastName: applicantInfo?.lastName,
      propertyType: applicantInfo?.propertyType,
    }));
    setSaveApplicantInfo(true);
    setShowApplicantModal(false);
    setApplicantInfo(applicantInfoFields);
    setApplicantInfoError({
      propertyTypeError: false,
      firstNameError: false,
      lastNameError: false,
    });
  };

  return (
    <Box>
      <Modal
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size={"4xl"}
        isOpen={showApplicantModal}
        onClose={() => setShowApplicantModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader borderBottom={"1px solid"} borderColor={"primary.500"}>
            {t("Edit Applicant Information")}
          </ModalHeader>
          <ModalBody>
            <Box pt={4}>
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <FormControl isRequired>
                  <FormLabel>{t("Property Type")}</FormLabel>
                  <Select
                    name="propertyType"
                    value={applicantInfo?.propertyType?.toLowerCase()}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    {CommercialDataDD?.PropertyType.map((row, index) => (
                      <option key={index} value={row?.s_AppCodeName}>
                        {row?.s_AppCodeNameForDisplay}
                      </option>
                    ))}
                  </Select>
                  {!applicantInfo?.propertyType &&
                    applicantInfoError?.propertyTypeError && (
                      <Text color={"red"} mb={0}>
                        {t("This field cannot be empty.")}
                      </Text>
                    )}
                </FormControl>
                {applicantInfo?.propertyType?.toLowerCase() ===
                  "individual" && (
                  <FormControl isRequired>
                    <FormLabel>{t("First Name")}</FormLabel>
                    <Input
                      name="firstName"
                      value={applicantInfo?.firstName}
                      onChange={handleChange}
                    />
                    {!applicantInfo?.firstName &&
                      applicantInfoError?.firstNameError && (
                        <Text color={"red"} mb={0}>
                          {t("This field cannot be empty.")}
                        </Text>
                      )}
                  </FormControl>
                )}
                <FormControl isRequired>
                  <FormLabel>{t(lastNameLabel)}</FormLabel>
                  <Input
                    name="lastName"
                    value={applicantInfo?.lastName}
                    onChange={handleChange}
                  />
                  {!applicantInfo?.lastName &&
                    applicantInfoError?.lastNameError && (
                      <Text color={"red"} mb={0}>
                        {t("This field cannot be empty.")}
                      </Text>
                    )}
                </FormControl>
              </Grid>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={saveApplicantData}>{t("Save")}</Button>
            <Button
              ml={2}
              onClick={() => {
                setShowApplicantModal(false);
                setApplicantInfoError({
                  propertyTypeError: false,
                  firstNameError: false,
                  lastNameError: false,
                });
              }}
            >
              {t("Close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
