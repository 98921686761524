import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { useTranslation } from "react-i18next";
import { MdDelete, MdOutlineAddBox } from "react-icons/md";
import { CustomCurrencyInput } from "../../../../common/CustomCurrencyInput";
import { Button } from "@chakra-ui/react";

export const LimitsTable = () => {
  const { t } = useTranslation();
  const {
    financialDetails,
    setFinancialDetails,
    isNextClicked,
    setLimitsValidationError,
    constructionDetails,
  } = useMyContext(CreateQuoteContext);

  const handleResidentialAddRow = () => {
    const newRow = { building: "", contents: "" };
    setFinancialDetails({
      ...financialDetails,
      residential: {
        ...financialDetails.residential,
        limits: [...financialDetails.residential.limits, newRow],
      },
    });
  };
  const handleOwnedAddRow = () => {
    const newRow = { building: "", contents: "" };
    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        owned: {
          ...financialDetails.commercial.owned,
          limits: [...financialDetails.commercial.owned.limits, newRow],
        },
      },
    });
  };
  const handleTenantedAddRow = () => {
    const newRow = {
      improvementsAndBetterments: "",
      contents: "",
    };
    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        tenanted: {
          ...financialDetails.commercial.tenanted,
          limits: [...financialDetails.commercial.tenanted.limits, newRow],
        },
      },
    });
  };
  const handleResidentialRemoveRow = (indexToRemove) => {
    const updatedPriorLosses = financialDetails.residential.limits.filter(
      (_, index) => index !== indexToRemove
    );
    setFinancialDetails({
      ...financialDetails,
      residential: {
        ...financialDetails.residential,
        limits: updatedPriorLosses,
      },
    });
  };
  const handleOwnedRemoveRow = (indexToRemove) => {
    const updatedPriorLosses = financialDetails.commercial.owned.limits.filter(
      (_, index) => index !== indexToRemove
    );
    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        owned: {
          ...financialDetails.commercial.owned,
          limits: updatedPriorLosses,
        },
      },
    });
  };
  const handleTenantedRemoveRow = (indexToRemove) => {
    const updatedPriorLosses =
      financialDetails.commercial.tenanted.limits.filter(
        (_, index) => index !== indexToRemove
      );
    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        tenanted: {
          ...financialDetails.commercial.tenanted,
          limits: updatedPriorLosses,
        },
      },
    });
  };
  const handleResidentialChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = financialDetails.residential.limits.map(
      (row, index) => {
        if (index === rowIndex) {
          return { ...row, [field]: parsedValue };
        }
        return row;
      }
    );

    setFinancialDetails({
      ...financialDetails,
      residential: {
        ...financialDetails.residential,
        limits: updatedPriorLosses,
      },
    });
  };
  const handleOwnedChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = financialDetails.commercial.owned.limits.map(
      (row, index) => {
        if (index === rowIndex) {
          return { ...row, [field]: parsedValue };
        }
        return row;
      }
    );

    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        owned: {
          ...financialDetails.commercial.owned,
          limits: updatedPriorLosses,
        },
      },
    });
  };
  const handleTenantedChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = financialDetails.commercial.tenanted.limits.map(
      (row, index) => {
        if (index === rowIndex) {
          return { ...row, [field]: parsedValue };
        }
        return row;
      }
    );

    setFinancialDetails({
      ...financialDetails,
      commercial: {
        ...financialDetails.commercial,
        tenanted: {
          ...financialDetails.commercial.tenanted,
          limits: updatedPriorLosses,
        },
      },
    });
  };

  const productType = constructionDetails?.productType;
  const isTenanted = constructionDetails?.commercialProductType === "Tenanted";

  useEffect(() => {
    const isResidentialLimitInvalid =
      productType === "Residential" &&
      (financialDetails?.residential?.limits[0]?.building === 0 ||
        financialDetails?.residential?.limits[0]?.building === null);

    const isCommercialTenantedLimitInvalid =
      productType === "Commercial" &&
      isTenanted &&
      (financialDetails.commercial.tenanted.limits[0]
        ?.improvementsAndBetterments === 0 ||
        financialDetails.commercial.tenanted.limits[0]
          ?.improvementsAndBetterments === null);

    const isCommercialOwnedLimitInvalid =
      productType === "Commercial" &&
      !isTenanted &&
      (financialDetails?.commercial?.owned?.limits[0]?.building === 0 ||
        financialDetails?.commercial?.owned?.limits[0]?.building === null);

    const hasError =
      isResidentialLimitInvalid ||
      isCommercialTenantedLimitInvalid ||
      isCommercialOwnedLimitInvalid;

    setLimitsValidationError(hasError);
  }, [
    financialDetails,
    constructionDetails,
    setLimitsValidationError,
    isNextClicked,
    productType,
    isTenanted,
  ]);

  return (
    <>
      <Table striped bordered hover width={"50%"}>
        <thead>
          <tr className="text-center align-middle">
            {productType === "Commercial" && isTenanted ? (
              <th>
                {t("Improvements and Betterments")}{" "}
                <span className="label-asterisk" style={{ fontSize: "18px" }}>
                  *
                </span>
              </th>
            ) : (
              <th>
                {t("Building")}{" "}
                <span className="label-asterisk" style={{ fontSize: "18px" }}>
                  *
                </span>
              </th>
            )}
            <th>{t("Contents")} </th>
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {productType === "Residential" &&
            financialDetails &&
            financialDetails?.residential?.limits?.map((row, index) => (
              <tr key={index}>
                <td>
                  <CustomCurrencyInput
                    placeholder="Building Limit"
                    name="building"
                    value={row.building || ""}
                    onChange={(e) =>
                      handleResidentialChange(
                        "building",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {isNextClicked && row.building === 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("Value can't be zero")}
                    </span>
                  )}
                </td>
                <td>
                  <CustomCurrencyInput
                    placeholder="Contents Limit"
                    name="contents"
                    value={row.contents || ""}
                    onChange={(e) =>
                      handleResidentialChange(
                        "contents",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {/* {isNextClicked && row.contents == 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("Value can't be zero")}
                    </span>
                  )} */}
                </td>
                <td className="text-center align-middle">
                  <Button
                    colorScheme={"red"}
                    p={0}
                    px={2}
                    borderRadius={"xl"}
                    size={"sm"}
                    onClick={() => handleResidentialRemoveRow(index)}
                  >
                    <MdDelete size={19} />
                  </Button>
                </td>
              </tr>
            ))}
          {productType === "Commercial" &&
            !isTenanted &&
            financialDetails &&
            financialDetails?.commercial?.owned?.limits?.map((row, index) => (
              <tr key={index}>
                <td>
                  <CustomCurrencyInput
                    placeholder="Building Limit"
                    name="building"
                    value={row.building || ""}
                    onChange={(e) =>
                      handleOwnedChange(
                        "building",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {isNextClicked && row.building === 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("Value can't be zero")}
                    </span>
                  )}
                </td>
                <td>
                  <CustomCurrencyInput
                    placeholder="Contents Limit"
                    name="contents"
                    value={row.contents || ""}
                    onChange={(e) =>
                      handleOwnedChange(
                        "contents",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {/* {isNextClicked && row.contents == 0 && (
                    <span
                      className="text-danger font-weight-bold"
                      style={{ fontSize: "small" }}
                    >
                      {t("Value can't be zero")}
                    </span>
                  )} */}
                </td>
                <td className="text-center align-middle">
                  <Button
                    colorScheme={"red"}
                    p={0}
                    px={2}
                    borderRadius={"xl"}
                    size={"sm"}
                    onClick={() => handleOwnedRemoveRow(index)}
                  >
                    <MdDelete size={19} />
                  </Button>
                </td>
              </tr>
            ))}
          {productType === "Commercial" &&
            isTenanted &&
            financialDetails &&
            financialDetails?.commercial?.tenanted?.limits?.map(
              (row, index) => (
                <tr key={index}>
                  <td>
                    <CustomCurrencyInput
                      placeholder="Improvements and Betterments Limit"
                      name="improvementsAndBetterments"
                      value={row.improvementsAndBetterments || ""}
                      onChange={(e) =>
                        handleTenantedChange(
                          "improvementsAndBetterments",
                          Number(e.target.value.replace(/[^\d.]/g, "")),
                          index
                        )
                      }
                    />
                    {isNextClicked && row.improvementsAndBetterments === 0 && (
                      <span
                        className="text-danger font-weight-bold"
                        style={{ fontSize: "small" }}
                      >
                        {t("Value can't be zero")}
                      </span>
                    )}
                  </td>
                  <td>
                    <CustomCurrencyInput
                      placeholder="Contents Limit"
                      value={row.contents || ""}
                      onChange={(e) =>
                        handleTenantedChange(
                          "contents",
                          Number(e.target.value.replace(/[^\d.]/g, "")),
                          index
                        )
                      }
                    />
                    {/* {isNextClicked && row.contents == 0 && (
                      <span
                        className="text-danger font-weight-bold"
                        style={{ fontSize: "small" }}
                      >
                        {t("Value can't be zero")}
                      </span>
                    )} */}
                  </td>
                  <td className="text-center align-middle">
                    <Button
                      colorScheme={"red"}
                      p={0}
                      px={2}
                      borderRadius={"xl"}
                      size={"sm"}
                      onClick={() => handleTenantedRemoveRow(index)}
                    >
                      <MdDelete size={19} />
                    </Button>
                  </td>
                </tr>
              )
            )}
        </tbody>
      </Table>
      {productType === "Residential" && (
        <Button
          borderRadius={"xl"}
          size={"sm"}
          leftIcon={<MdOutlineAddBox />}
          onClick={handleResidentialAddRow}
          style={{ backgroundColor: "#64D6CD", border: "none" }}
        >
          {t("Add Row")}
        </Button>
      )}
      {productType === "Commercial" && !isTenanted && (
        <Button
          borderRadius={"xl"}
          size={"sm"}
          leftIcon={<MdOutlineAddBox />}
          onClick={handleOwnedAddRow}
          style={{ backgroundColor: "#64D6CD", border: "none" }}
        >
          {t("Add Row")}
        </Button>
      )}
      {productType === "Commercial" && isTenanted && (
        <Button
          borderRadius={"xl"}
          size={"sm"}
          leftIcon={<MdOutlineAddBox />}
          onClick={handleTenantedAddRow}
          style={{ backgroundColor: "#64D6CD", border: "none" }}
        >
          {t("Add Tenanted Row")}
        </Button>
      )}
    </>
  );
};
