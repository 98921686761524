import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { FcInfo } from "react-icons/fc";
import ReactTooltip from "react-tooltip";

const PropertyDetails = (props) => {
  const { t } = useTranslation();
  const [roofType, setRoofType] = useState("");
  const [roofSubType, setRoofSubType] = useState("");
  const [hideRoofSubType, setHideRoofSubType] = useState(true);
  const [renovation, setRenovation] = useState("");
  const [roofOfAge, setRoofOfAge] = useState("");
  const [isValidRoofOfAge, setIsValidRoofOfAge] = useState(false);
  const [isValidYearBuilt, setIsValidYearBuilt] = useState(false);

  useEffect(() => {
    setRoofType(
      props.quoteData.propertyInfo.s_RoofType
        ? props.quoteData.propertyInfo.s_RoofType
        : ""
    );
    setRoofSubType(
      props.quoteData.propertyInfo.s_RoofSubType
        ? props.quoteData.propertyInfo.s_RoofSubType
        : ""
    );
    setRoofOfAge(
      props.quoteData.propertyInfo.s_RoofAge
        ? props.quoteData.propertyInfo.s_RoofAge
        : ""
    );
    setRenovation(
      props.quoteData.riskRenovationTypeData.roofInfo != undefined
        ? props.quoteData.riskRenovationTypeData.roofInfo.s_RenovationYear
        : ""
    );
  }, [props.quoteData]);

  useEffect(() => {
    if (roofType === "COMPOSITION") {
      setHideRoofSubType(false);
    } else {
      setHideRoofSubType(true);
    }
  }, [roofType]);

  const roofAge = (e) => {
    const d = new Date();
    let year = d.getFullYear();
    setRoofOfAge(year - renovation);

    if (year >= e) {
      setIsValidRoofOfAge(true);
    } else {
      setIsValidRoofOfAge(false);
      toast.error("Year Should Not Be Greater Than Current Year");
    }
  };

  const yearBuiltD = (e) => {
    const d = new Date();
    let year = d.getFullYear();
    if (year >= e && e / 1000 > 1) {
      setIsValidYearBuilt(true);
    } else {
      setIsValidYearBuilt(false);
      toast.error("Please enter an appropriate year");
    }
  };

  const renderTootTip = (id) => {
    return (
      <>
        <ReactTooltip
          id={id}
          delayHide={500}
          effect="solid"
          className={id && "custom-tooltip"}
          place="right"
        >
          {id === "ppc" && (
            <p fontSize={"15px"}>
              {` The Public Protection Classification (PPC) program is a community
              fire protection scoring system based on a Fire Suppression Rating
              Schedule (FSRS) used by the Insurance Services Office, also known
              as ISO Mitigation. Communities get a PPC score from 1 to 10. 1
              means the community has superior property fire protection. 10
              means the community's fire protection efforts don't meet ISO's
              minimum criteria. Most home and business insurers use PPC scores
              to determine insurance premiums. Businesses and individuals in a
              community with a good PPC score typically pay less for fire
              insurance.`}
            </p>
          )}
        </ReactTooltip>
        <style>
          {`
          .custom-tooltip {
            max-width: 400px;
          }
        `}
        </style>
      </>
    );
  };

  return (
    <Row
      // className="align-items-end"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(290px, 1fr))",
        gap: "0.5rem",
        columnGap: "0.25rem",
        paddingTop: "1rem",
      }}
    >
      {/* <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Structure Type")} <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            id="select-ym-option"
            value={
              props.quoteData.propertyInfo.s_StructureTypeCode
                ? props.quoteData.propertyInfo.s_StructureTypeCode
                : ""
            }
            name="s_StructureTypeCode"
            title="propertyInfo"
            disabled={props.isBind}
            onChange={props.handleChange}
            required
          >
            <option value=""></option>
            {props.getBaseData &&
              props.getBaseData.STRUCTYPE &&
              props.getBaseData.STRUCTYPE.map((x) => (
                <option value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col> */}

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Area SqFt")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Control
            className="starQuotePageInput"
            placeholder=""
            type="number"
            value={
              props.quoteData.propertyInfo.n_AreaSqtft
                ? parseFloat(props.quoteData.propertyInfo.n_AreaSqtft)
                : ""
            }
            name="n_AreaSqtft"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onWheel={(e) => {
              e.target.blur();
            }}
          />
          {props?.quoteData?.propertyInfo?.n_AreaSqtft >= 20000 && (
            <Text mb={0} color="orange">
              {t("Possibly a Excess Square Footage.")}
            </Text>
          )}
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Number of Stories")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            placeholder=""
            value={
              props.quoteData.propertyInfo.n_NoOfStories
                ? props.quoteData.propertyInfo.n_NoOfStories
                : ""
            }
            name="n_NoOfStories"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>
            {props.getBaseData &&
              props.getBaseData.STARDWELLINGSTORIES &&
              props.getBaseData.STARDWELLINGSTORIES.map((x, index) => (
                <option key={index} value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Year Built")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Control
            className="starQuotePageInput"
            placeholder=""
            maxLength="4"
            alt={"number"}
            type="tel" //to use the maxLength prop
            isValid={!isValidYearBuilt ? "" : "Please Select Proper Year"}
            onBlur={(e) => yearBuiltD(e.target.value)}
            // onKeyPress="/^[0-9\b]+$/"
            value={
              props.quoteData.propertyInfo.n_YearBuilt
                ? isNaN(parseInt(props.quoteData.propertyInfo.n_YearBuilt))
                  ? ""
                  : parseInt(props.quoteData.propertyInfo.n_YearBuilt)
                : ""
            }
            name="n_YearBuilt"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Usage")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={
              props.quoteData.propertyInfo.s_UsageTypeCode
                ? props.quoteData.propertyInfo.s_UsageTypeCode
                : ""
            }
            name="s_UsageTypeCode"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
          >
            <option value=""></option>
            {props.getBaseData &&
              props.getBaseData.HOUSAGETYPE &&
              props.getBaseData.HOUSAGETYPE.map((x, index) => (
                <option key={index} value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Construction Type")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={props.quoteData.propertyInfo.s_ConstructionType || ""}
            name="s_ConstructionType"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
          >
            <option value=""></option>
            {props.getBaseData.CONSTRTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Foundation Type")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={
              props.quoteData.propertyInfo.s_StructureTypeCode
                ? props.quoteData.propertyInfo.s_StructureTypeCode
                : ""
            }
            name="s_StructureTypeCode"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
          >
            <option value=""></option>
            {props.getBaseData.STARFOUNDATIONTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Cladding Type")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={props.quoteData.propertyInfo.metadata.claddingType || ""}
            name="claddingType"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
            required
          >
            <option value=""></option>
            {props.getBaseData.STARCLADDINGTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Roof Type")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={roofType}
            name="s_RoofType"
            title="propertyInfo"
            onChange={(e) => {
              props.handleChange(e);
              setRoofType(e.target.value);
            }}
            disabled={props.isBind}
          >
            <option value=""></option>
            {props.getBaseData.ROOFTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col hidden={hideRoofSubType}>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Roof Sub-Type")}:<span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={roofSubType}
            name="s_RoofSubType"
            title="propertyInfo"
            onChange={(e) => {
              props.handleChange(e);
              setRoofSubType(e.target.value);
            }}
            disabled={props.isBind}
          >
            <option value=""></option>
            {props.getBaseData.ROOFSUBTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Roof Age")}: <span className="label-asterisk">*</span>
          </Form.Label>

          <Form.Control
            className="starQuotePageInput"
            placeholder=""
            value={roofOfAge}
            name="s_RoofAge"
            title="propertyInfo"
            disabled={props.isBind}
            onChange={(e) => {
              const value = e.target.value;
              if (value < 0) {
                setRoofOfAge("0");
                toast.warn(t("Roof age cannot be negative"), {
                  toastId: "roofAge",
                });
              } else if (value > 200) {
                setRoofOfAge("200");
                toast.warn(t("Roof age cannot be greater than 200 years"), {
                  toastId: "roofAge",
                });
              } else {
                setRoofOfAge(value);
                props.handleChange(e);
              }
            }}
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Occupancy Type")}: <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            value={
              props.quoteData.propertyInfo.s_Occupancy
                ? props.quoteData.propertyInfo.s_Occupancy
                : ""
            }
            name="s_Occupancy"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>
            {props.getBaseData.OCCUPYTYPE.map((x, index) => (
              <option key={index} value={x.s_AppCodeName}>
                {x.s_AppCodeNameForDisplay}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Form.Label className="dashboard-group-label">
              {t("PPC")}: <span className="label-asterisk">*</span>
              <a
                style={{
                  marginBottom: "5px",
                  cursor: "pointer",
                  display: "inline-flex",
                  marginTop: "5px",
                  marginLeft: "5px",
                  verticalAlign: "middle",
                }}
                data-tip
                data-for={"ppc"}
              >
                {" "}
                <div ml={1}>
                  <FcInfo size={18} />
                </div>
                {renderTootTip("ppc")}
              </a>
            </Form.Label>
          </div>
          <Form.Select
            className="starQuotePageInput"
            value={
              props?.quoteData?.propertyCoverageInfo?.metadata?.quotePremiums
                ?.protection_class || ""
            }
            name="n_PPC"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={true}
            required
          >
            <option value=""></option>
            {props.getBaseData &&
              props.getBaseData.PROTECTIONCLS &&
              props.getBaseData.PROTECTIONCLS.map((x, index) => (
                <option key={index} value={x.s_AppCodeName}>
                  {x.s_AppCodeNameForDisplay}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>

      {renovation && (
        <Col>
          <Form.Group
            className="search-quote-form-group"
            controlId="formBasicEmail"
          >
            <Form.Label className="dashboard-group-label">
              {t("Roof Renovation Year")}?{" "}
              <span className="label-asterisk">*</span>
            </Form.Label>
            <Form.Control
              className="starQuotePageInput"
              placeholder=""
              value={renovation}
              name="noOfResident"
              title="propertyInfo"
              maxLength="4"
              isValid={!isValidRoofOfAge ? "" : "Please Select Proper Year"}
              onChange={(e) => {
                props.handleChange(e);
                setRenovation(e.target.value);
              }}
              disabled={props.isBind}
              onBlur={(e) => roofAge(e.target.value)}
              required
            />
          </Form.Group>
        </Col>
      )}

      {/* <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Building Is Currently Vacant")}:{" "}
            <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            type="text"
            placeholder=""
            value={props.quoteData.propertyInfo?.metadata?.isBuildingVacant}
            name="isBuildingVacant"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Form.Select>
        </Form.Group>
      </Col> */}
      {/* <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Building Is Under Construction")}:{" "}
            <span className="label-asterisk">*</span>
          </Form.Label>
          <Form.Select
            className="starQuotePageInput"
            type="text"
            placeholder=""
            value={props.quoteData.propertyInfo?.metadata?.isUnderConstruction}
            name="isUnderConstruction"
            title="propertyInfo"
            onChange={props.handleChange}
            disabled={props.isBind}
          >
            <option value=""></option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Form.Select>
        </Form.Group>
      </Col> */}

      <Col>
        <Form.Group
          className="search-quote-form-group"
          controlId="formBasicEmail"
        >
          <Form.Label className="dashboard-group-label">
            {t("Is this a new purchase")}?{" "}
            <span className="label-asterisk">*</span>
          </Form.Label>
          <div
            style={{ display: "flex", justifyContent: "flex-start" }}
            className="custom-radio-check0 mt-2"
          >
            <Form.Check
              type="radio"
              label="Yes"
              style={{ marginRight: "10%" }}
              value={"YES"}
              checked={props.isPriorAddr == true ? true : false}
              name="isPriorAddr"
              id=""
              onChange={(e) => props.setPriorAddrFunc("YES")}
              // disabled={props.isBind}
              disabled
            />
            <Form.Check
              type="radio"
              label="No"
              value={"NO"}
              checked={props.isPriorAddr == false ? true : false}
              name="isPriorAddr"
              id=""
              onChange={(e) => props.setPriorAddrFunc("NO")}
              // disabled={props.isBind}
              disabled
            />
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
};
function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(PropertyDetails);
