import React from "react";
import claimSystem from "../../../help/Claims_System_Create_Claim.pdf";
import claimSearch from "../../../help/Claims_System_Claim_Search.pdf";
import claimView from "../../../help/Claims_System_Claim_View.pdf";
import { Box, Center, Text, VStack, Wrap, WrapItem, Link, Image } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const ClaimsSystemSection = () => {
    const { t } = useTranslation();

    return (
        <div >
            <Wrap width={"52rem"}>
                <Link href={claimSystem} target="_blank"  p={1}>
                    <WrapItem p={5} w='120px' h='120px' boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/sendIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("create_a_claim")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={claimSearch} target="_blank"  p={1}>
                    <WrapItem p={5} w='120px' h='120px'  boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/cardTickIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("search_a_claim")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
                <Link href={claimView} target="_blank"  p={1}>
                    <WrapItem p={5} w='120px' h='120px'  boxShadow={"lg"} background={"#F3F6F9"} ml={5} borderRadius={"10px"} cursor={"pointer"}>
                        <VStack m={"auto"}>
                            <Box>
                                <Center>
                                    <Image src={"/assets/icons/triangleResourceIcon.svg"} boxSize={"25px"} />
                                </Center>
                            </Box>
                            <Box>
                                <Center>
                                    <Text fontSize={"sm"} textAlign={"center"}>
                                        {t("view_a_claim")}
                                    </Text>
                                </Center>
                            </Box>
                        </VStack>
                    </WrapItem>
                </Link>
            </Wrap>
        </div>
    )
}

export default ClaimsSystemSection;