import Http from "../../../../Http";

export async function postHiscoxUpdateQuote({ requestBody, quoteNo }) {
  try {
    const url = `/api/v2/quote/hiscox/update/${quoteNo}`;
    const response = await Http.post(url, requestBody);
    return response.data;
  } catch (err) {
    console.error(
      "Error in postHiscoxUpdateQuote:",
      err.response?.status,
      err.response?.data || err.message
    );
    throw err;
  }
}
