import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sizePerPageRenderer,
  pageButtonRenderer,
} from "../../components/CustomTablePagination";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";

const BindingTab = () => {
  const [startDate, setStartDate] = useState(new Date());

  const productsGenerator = (quantity) => {
    const items = [];
    for (let i = 0; i < quantity; i++) {
      items.push({
        message_id: "1232",
        message_title: "Good Afternoon Agency Partners",
        effective_from: "07-13-2021",
        effective_to: "07-13-2021",
        status: "Closed",
        details: "2",
        action: "2",
      });
    }
    return items;
  };

  const products = productsGenerator(100);

  const columns = [
    {
      dataField: "message_id",
      text: "Message Id",
    },
    {
      dataField: "message_title",
      text: "Message Title",
    },
    {
      dataField: "effective_from",
      text: "Effective From",
    },
    {
      dataField: "effective_to",
      text: "Effective To",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "details",
      text: "Details",
      formatter: (value, row) => (
        <Dropdown className="view-details-dropdown">
          <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
            View
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Item>Action</Dropdown.Item>
            <Dropdown.Item>Action</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (value, row) => (
        <Button
          id="common-btn"
          variant="primary"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const options = {
    sizePerPageRenderer,
    pageButtonRenderer,
    showTotal: true,
    alwaysShowAllBtns: true,
  };

  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Binding Suspended</span>
      </div>
      <div className="custom-tab-body" style={{ padding: "20px 0px 20px 0px" }}>
        <div className="faq-tab-accordion">
          <Row>
            <Col xs="12" sm="12" md="12" lg="10" xl="10">
              <Row>
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Message Header
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Header"
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Message Title
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Title"
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Message Body
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      as="textarea"
                      placeholder="Message"
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Message Footer
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Footer message"
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Effective From
                    </Form.Label>
                    <DatePicker
                      id="dashboard-datepicker"
                      className="dashboard-datepicker-main"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Effective To
                    </Form.Label>
                    <DatePicker
                      id="dashboard-datepicker"
                      className="dashboard-datepicker-main"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Status
                    </Form.Label>
                    <Form.Select
                      className="dashboard-group-input"
                      id="select-ym-option"
                    >
                      <option>All</option>
                      <option>User genrated</option>
                      <option>System Genrated</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs="12" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <span className="common-headings">Restricted County Name</span>
              <div className="select-document-type-div">
                <Row>
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>All</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>BAKER</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA </span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA </span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA </span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="12" lg="3" xl="3">
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA </span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                    <div className="document-type-subdiv">
                      <Form.Check
                        type="checkbox"
                        label=""
                        className="table-checkbox"
                        id="custom-checker"
                      />
                      <span>ALACHUA</span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              xs="12"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                id="common-btn"
                variant="primary"
                style={{ padding: "10px 30px 10px 30px" }}
              >
                Submit
              </Button>{" "}
            </Col>
            <Col xs="12" style={{ padding: 0 }}>
              <div
                className="binding-tab-table"
                id="binding-tab-table-outer-div"
              >
                <div className="binding-tab-header">Search Results</div>

                <BootstrapTable
                  wrapperClasses="management-functional-table"
                  bootstrap4
                  keyField="message_id"
                  data={products}
                  columns={columns}
                  hover
                  bordered={false}
                  pagination={paginationFactory(options)}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default BindingTab;
