import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getZipDetails } from "../../../../../../services/floodService";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  ModalFooter,
  Button,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const MailingAddress = ({
  edit,
  productId,
  bindDetails,
  setBindDetails,
  isMailingAddressModalOpen,
  setIsMailingAddressModalOpen,
  setSaveMailingAddress,
  quoteDetailsData,
}) => {
  const dispatch = useDispatch();
  const [multipleCity, setMultipleCity] = useState(null);
  const { t } = useTranslation();
  const [prevZipCode, setPrevZipCode] = useState("");
  const [showMultipleCityData, setShowMultipleCityData] = useState([]);
  const [onSaveClick, setOnSaveClick] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const zipCode = bindDetails?.mailingAddressZipCode;
    const isValidZipCode = zipCode && zipCode?.toString().length === 5;
    if (isValidZipCode && zipCode !== prevZipCode) {
      getDataFromZip(zipCode);
    }
    setPrevZipCode(zipCode);
  }, [bindDetails?.mailingAddressZipCode, bindDetails?.mailingAddress]);

  const getDataFromZip = (zipCodeVal) => {
    setLoading(true);
    const zipCodeStr = zipCodeVal.toString();
    var numberOfDigits = zipCodeStr.length;
    if (numberOfDigits === 5) {
      dispatch(getZipDetails(productId, zipCodeVal, "MAILING")).then((res) => {
        setLoading(false);
        if (res.success === true) {
          var data = res.data;
          if (data.status === "Y") {
            if (data.count === 1) {
              setMultipleCity(null);
              setBindDetails({
                ...bindDetails,
                mailingAddressCounty: data.zipData[0].s_CountyName,
                mailingAddressState: data.zipData[0].s_StateDisplayName,
                mailingAddressCity: data?.zipData[0].s_CityName,
              });
            } else {
              showMultipleCityModal(data.zipData);
              setShowMultipleCityData(data.zipData);
              setLoading(false);
            }
          } else {
            toast.error(data?.errorMsg, {
              toastId: "errorZip",
              position: "top-center",
            });
            setBindDetails({
              ...bindDetails,
              mailingAddressCounty: "",
              mailingAddressState: "",
              mailingAddressCity: "",
            });
          }
          setLoading(false);
        }
      });
    } else {
      setBindDetails({
        ...bindDetails,
        mailingAddressCounty: "",
        mailingAddressState: "",
        mailingAddressCity: "",
      });
    }
  };
  const showMultipleCityModal = (zipData) => {
    setMultipleCity(true);
  };
  const handleMultipleCities = (el) => {
    setBindDetails({
      ...bindDetails,
      mailingAddressCounty: el.s_CountyName,
      mailingAddressState: el.s_StateDisplayName,
      mailingAddressCity: el?.s_CityName,
    });
    setMultipleCity(false);
  };
  const handleSave = () => {
    setOnSaveClick(true);
    if (
      !bindDetails?.mailingAddressZipCode ||
      !bindDetails?.mailingAddress ||
      !bindDetails?.mailingAddressCity ||
      !bindDetails?.mailingAddressState ||
      !bindDetails?.mailingAddressCounty
    ) {
      return;
    }
    setSaveMailingAddress(true);
    setIsMailingAddressModalOpen(false);
  };
  useEffect(() => {
    setBindDetails({
      ...quoteDetailsData?.data,
      ...bindDetails,
      mailingAddress: quoteDetailsData?.data?.mailingAddress,
      mailingAddressAddressLine2:
        quoteDetailsData?.data?.mailingAddressAddressLine2,
      mailingAddressZipCode: quoteDetailsData?.data?.mailingAddressZipCode,
      mailingAddressCounty: quoteDetailsData?.data?.mailingAddressCounty,
      mailingAddressState: quoteDetailsData?.data?.mailingAddressState,
      mailingAddressCity: quoteDetailsData?.data?.mailingAddressCity,
    });
  }, [quoteDetailsData?.data]);
  return (
    <>
      <Modal
        isCentered
        isOpen={isMailingAddressModalOpen}
        onClose={() => setIsMailingAddressModalOpen(false)}
        size="4xl"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex alignItems={"center"} gap={2}>
              Set Mailing Address{" "}
              {loading && <Spinner color="blue" size={"md"} />}
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setOnSaveClick(false);
              setBindDetails({
                ...bindDetails,
                mailingAddress: quoteDetailsData?.data?.mailingAddress,
                mailingAddressAddressLine2:
                  quoteDetailsData?.data?.mailingAddressAddressLine2,
                mailingAddressZipCode:
                  quoteDetailsData?.data?.mailingAddressZipCode,
                mailingAddressCounty:
                  quoteDetailsData?.data?.mailingAddressCounty,
                mailingAddressState:
                  quoteDetailsData?.data?.mailingAddressState,
                mailingAddressCity: quoteDetailsData?.data?.mailingAddressCity,
              });
            }}
          />
          <ModalBody>
            <Form>
              <Row
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, minmax(140px, 1fr))",
                  gap: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Mailing Address")}{" "}
                      <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={!edit}
                      type="text"
                      placeholder=""
                      value={bindDetails?.mailingAddress}
                      onChange={(e) => {
                        setBindDetails({
                          ...bindDetails,
                          mailingAddress: e.target.value,
                        });
                      }}
                    />
                    {onSaveClick && bindDetails.mailingAddress === "" && (
                      <Text color="red">Mailing Address is required</Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Address Line 2")}{" "}
                    </Form.Label>
                    <Form.Control
                      disabled={!edit}
                      type="text"
                      placeholder=""
                      value={
                        bindDetails?.mailingAddressAddressLine2 
                      }
                      onChange={(e) => {
                        setBindDetails({
                          ...bindDetails,
                          mailingAddressAddressLine2: e.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("Zip")} <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={!edit}
                      type="number"
                      placeholder=""
                      value={bindDetails?.mailingAddressZipCode}
                      onChange={(e) => {
                        const zipCode = parseInt(e.target.value);
                        setBindDetails({
                          ...bindDetails,
                          mailingAddressZipCode: zipCode,
                        });
                      }}
                    />

                    {onSaveClick &&
                      (!bindDetails.mailingAddressZipCode ||
                        (bindDetails.mailingAddressZipCode &&
                          bindDetails.mailingAddressZipCode.toString()
                            .length !== 5)) && (
                        <Text color="red">Zip code must be 5 digits</Text>
                      )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("County")} <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      disabled={!edit}
                      value={
                        bindDetails?.mailingAddressCounty 
                      }
                      onChange={(e) => {
                        setBindDetails({
                          ...bindDetails,
                          mailingAddressCounty: e.target.value,
                        });
                      }}
                    />
                    {onSaveClick && bindDetails.mailingAddressCounty === "" && (
                      <Text color="red">County is required</Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("State")} <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      disabled={!edit}
                      value={
                        bindDetails?.mailingAddressState 
                      }
                      onChange={(e) => {
                        setBindDetails({
                          ...bindDetails,
                          mailingAddressState: e.target.value,
                        });
                      }}
                    />
                    {onSaveClick && bindDetails.mailingAddressState === "" && (
                      <Text color="red">State is required</Text>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                    className="search-quote-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      {t("City")} <span className="label-asterisk">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      disabled={!edit}
                      value={
                        bindDetails?.mailingAddressCity 
                      }
                      onChange={(e) => {
                        setBindDetails({
                          ...bindDetails,
                          mailingAddressCity: e.target.value,
                        });
                      }}
                    />
                    {onSaveClick && bindDetails.mailingAddressCity === "" && (
                      <Text color="red">City is required</Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              id="primary-colored-btn"
              variant="primary"
              size={"sm"}
              _hover={{ bgColor: "neutral.400" }}
              color={"white"}
              onClick={handleSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Form>
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(240px, 1fr))",
            gap: "2rem",
            paddingTop: "1rem",
          }}
        >
          <>
            {multipleCity && (
              <Modal
                scrollable
                isOpen={true}
                onClose={() => setMultipleCity(false)}
                size="lg"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Select City</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>City</Th>
                          <Th>County</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {showMultipleCityData?.map((el, i) => (
                          <>
                            <Tr
                              key={i}
                              cursor={"pointer"}
                              onClick={() => handleMultipleCities(el)}
                              _hover={{ bgColor: "neutral.100" }}
                            >
                              <Td>{el.s_CityName}</Td>
                              <Td>{el.s_CountyName}</Td>
                            </Tr>
                          </>
                        ))}
                      </Tbody>
                    </Table>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </>
        </Row>
      </Form>
    </>
  );
};

export default MailingAddress;
