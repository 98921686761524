import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Input,
  Link,
  ListItem,
  Switch,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import DateInput from "../../../create-star-quote/utils/DateInput";
import { formateDate } from "../../../../common/FormatDate";
import Moment from "moment";
import ReactTooltip from "react-tooltip";
import { FcInfo } from "react-icons/fc";

const Discount = (props) => {
  const [discountAnswers, setDiscountAnswers] = useState({
    ...props?.quoteData?.propertyInfo?.metadata,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const discountsData = [
    {
      dQuestion:
        "Does the Applicant's premises have or would they like to purchase Smart Home System Protection for an instant discount?",
      dKey: "hasSmartHomeProtection",
      allowMultipleSelection: true,
      subQuestions: [
        {
          dQuestion: "Whole Home Water Detection and Shut Off",
          dKey: "homeWaterDetection",
        },
        {
          dQuestion: "Ting or Plug-in Electrical Device",
          dKey: "tingOrPlugDevices",
        },
      ],
    },
    {
      dQuestion:
        "Does the Applicant's premises have a Theft Protection Device?",
      dKey: "hasTheftProtection",
      allowMultipleSelection: false,
      subQuestions: [
        {
          dQuestion: "Monitored Burglar Alarm",
          dKey: "theftLocalMonitorAlarm",
        },
        {
          dQuestion: "Local Burglar Alarm",
          dKey: "theftLocalBurglarAlarm",
        },
        {
          dQuestion: "Deadbolt Locks",
          dKey: "theftDeadboltLocks",
        },
      ],
    },
    {
      dQuestion: "Does the Applicant's premises have a Fire Protection Device?",
      dKey: "hasFireProtectionDevice",
      allowMultipleSelection: false,
      subQuestions: [
        {
          dQuestion: "Monitored Fire Alarm",
          dKey: "fireMonitoAlarm",
        },
        {
          dQuestion: "Local Fire Alarm",
          dKey: "fireLocalAlarm",
        },
        {
          dQuestion: "Smart Home System",
          dKey: "fireOtherProtectionDevice",
        },
      ],
    },
    {
      dQuestion:
        "Does the Applicant's premises qualify for any Mississippi Wind Mitigation Discounts?",
      dKey: "doesQualifyForMSwindDisc",
      allowMultipleSelection: false,
      subQuestions: [
        {
          dQuestion: "FSL",
          dKey: "fsl",
        },
        {
          dQuestion: "2006 IRC",
          dKey: "IRC",
        },
        {
          dQuestion: "FEH Bronze",
          dKey: "fehBronze",
        },
        {
          dQuestion: "FEH Silver",
          dKey: "fehSilver",
        },
        {
          dQuestion: "FEH Gold",
          dKey: "fehGold",
        },
      ],
    },

    {
      dQuestion: "Does the Applicant currently have home insurance in force?",
      dKey: "hasHomeInsuranceInForce",
    },

    {
      dQuestion: "Is the Applicant's premises located north of I-10 ?",
      dKey: "isApplicantLocatedNorhI10",
    },
  ];
  const [discounts, setDiscounts] = useState();

  useEffect(() => {
    if (
      props?.quoteData?.propertyInfo?.metadata &&
      Object.keys(props?.quoteData?.propertyInfo?.metadata).length > 0
    ) {
      setDiscounts(discountsData);
    }
  }, [props?.quoteData?.propertyInfo?.metadata]);

  const handleDiscountChange = (key, value) => {
    let parentKey = "";
    let childKey = "";
    let allowMultipleSelection = true;
    let subQuestionKeys = [];
    const tempArr = props.quoteData;
    discountsData.forEach((discountItem) => {
      if (parentKey) {
        return;
      }
      if (discountItem.dKey == key.dKey) {
        parentKey = key.dKey;
        allowMultipleSelection = discountItem.allowMultipleSelection;

        if (discountItem.dKey === "hasHomeInsuranceInForce") {
          tempArr["policyInfo"]["s_PriorCarrierName"] = "";
          tempArr["policyInfo"]["s_PriorPolicyNo"] = "";
          tempArr["policyInfo"]["d_PriorExpDate"] = "";
        }

        if (discountItem?.subQuestions) {
          //GET OTHER SUB QUESTION KEYS IN CASE OF PARENT QUESTION SET TO FALSE
          subQuestionKeys = [];
          if (!value) {
            discountItem.subQuestions.forEach((subQuestionItem) => {
              subQuestionKeys.push(subQuestionItem.dKey);
            });
          }
        }
      } else if (discountItem?.subQuestions) {
        discountItem.subQuestions.forEach((discountSubQuestionItem) => {
          if (discountSubQuestionItem.dKey === key.dKey) {
            allowMultipleSelection = discountItem.allowMultipleSelection;
            parentKey = discountItem.dKey;
            childKey = discountSubQuestionItem?.dKey;

            //GET OTHER SUB QUESTION KEYS IN CASE OF SINGLE SLECTION
            subQuestionKeys = [];
            if (!allowMultipleSelection) {
              discountItem.subQuestions.forEach((subQuestionItem) => {
                if (subQuestionItem.dKey !== childKey) {
                  subQuestionKeys.push(subQuestionItem.dKey);
                }
              });
            }
          }
        });
      }
    });

    let setToFalse = {};
    subQuestionKeys.forEach((item) => {
      setToFalse = { ...setToFalse, ...{ [item]: false } };
    });

    setDiscountAnswers((prevState) => ({
      ...prevState,
      [key.dKey]: value,
      ...setToFalse,
    }));

    const tempDiscountAnswer = {
      ...discountAnswers,
      [key.dKey]: value,
      ...setToFalse,
    };

    tempArr["propertyInfo"]["metadata"][key.dKey] = value;
    for (const dkey in discountAnswers) {
      tempArr["propertyInfo"]["metadata"][dkey] = tempDiscountAnswer[dkey];
    }

    dispatch(getEditEmptyQuoteData(tempArr));
  };

  const links = [
    {
      key: "homeWaterDetection",
      link: "https://shop.moen.com/products/flo-smart-water-monitor-and-shutoff/",
    },
    {
      key: "tingOrPlugDevices",
      link: "https://www.tingfire.com/",
    },
    {
      key: "theftLocalMonitorAlarm",
      link: "https://www.jetsonsliving.com/",
    },
    {
      key: "fireMonitoAlarm",
      link: "https://www.jetsonsliving.com/",
    },
    {
      key: "fireOtherProtectionDevice",
      link: "https://www.jetsonsliving.com/",
    },
  ];

  const renderDiscountTexts = (text) => {
    const link = links.find((item) => item.key === text?.dKey)?.link;
    if (link) {
      const words = text?.dQuestion.split(" ");
      if (text?.dKey === "tingOrPlugDevices") {
        return (
          <>
            <Link
              textDecor={"underline"}
              color={"blue.500"}
              href={link}
              target="_blank"
            >
              {words[0]}
            </Link>{" "}
            {words.slice(1).join(" ")}
          </>
        );
      } else {
        return (
          <Link
            textDecor={"underline"}
            color={"blue.500"}
            href={link}
            target="_blank"
          >
            {text?.dQuestion}
          </Link>
        );
      }
    }
    return <>{text?.dQuestion}</>;
  };

  const renderTootipText = (id) => {
    if (
      id === "theftLocalMonitorAlarm" ||
      id === "theftLocalBurglarAlarm" ||
      id === "theftDeadboltLocks"
    ) {
      return;
    }
    return (
      <ReactTooltip
        id={id}
        delayHide={300}
        effect="solid"
        className={id}
        place="right"
      >
        {id === "homeWaterDetection" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            “Whole Home Water Detection and Shut Off” is a single-point or
            whole-home water device that alerts the customer and/or the
            designated security company, and automatically shuts off the
            corresponding water valve
          </Box>
        )}
        {id === "tingOrPlugDevices" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            A plug-in type surge or electrical hazard detection device that
            alerts the customer and or designated security company when an event
            occurs
          </Box>
        )}
        {id === "fsl" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            The "Fortified for Safer Living" (FSL) designation by Home Safety
            signifies a commitment to enhanced resilience against severe weather
            conditions such as high winds, hail, hurricanes, and tornadoes.
            Homes awarded the FSL designation by the Insurance Institute for
            Business & Home Safety (IBHS) are recognized for adhering to a
            voluntary construction standard that exceeds typical building
            requirements, thereby qualifying for our maximum discount
          </Box>
        )}
        {id === "IRC" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            This refers to the 2006 International Residential Code, a
            comprehensive set of guidelines that ensure the safety and health of
            occupants in residential structures
          </Box>
        )}
        {id === "fehBronze" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            This level focuses on the integrity of the roof and attic
            ventilation systems, requiring specific standards for roof
            sheathing, weatherproofing, and fastening to prevent leaks. It
            emphasizes the importance of fortified roofs and stronger
            connections to keep the roof secured to the house
          </Box>
        )}
        {id === "fehSilver" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            {" "}
            Building upon the Bronze level, the Silver designation adds
            protection for door and window openings, attached structures, and
            gable end walls. It requires that porches, carports, and garage
            doors are built to withstand uplift pressures determined by the
            site's wind speed and exposure category, thereby extending
            protection beyond the roof to other vulnerable areas.
          </Box>
        )}
        {id === "fehGold" && (
          <Box style={{ maxWidth: "300px" }} fontSize={"15px"}>
            The highest level of protection aimed at minimizing hurricane
            damage. This designation mandates that chimneys are securely
            connected to the roof structure, and windows, skylights, and glass
            doors are rated for specific design pressures. It ensures a
            continuous load path from the roof to the foundation and requires
            walls to have a minimum thickness of structural sheathing. The Gold
            level encapsulates all the protections of the Bronze and Silver
            levels and integrates them into a comprehensive strategy that binds
            the entire structure together for maximum resilience.
          </Box>
        )}
      </ReactTooltip>
    );
  };

  return (
    <>
      <div className="search-quote-question-body">
        <div className="search-questions-subdiv">
          <ListGroup className="search-questions-list" as="ol" numbered>
            {discounts?.map((discount, index) => {
              return discount?.dKey === "hasHomeInsuranceInForce" ||
                discount?.dKey === "isApplicantLocatedNorhI10" ? (
                <></>
              ) : (
                <Box key={index}>
                  <Flex py={4} justifyContent={"space-between"}>
                    {discount?.dQuestion}
                    <Flex flexDir={"row"} gap={2} p={2}>
                      <Switch
                        mt={-2}
                        size="lg"
                        label={t("Yes")}
                        // name={`check-ques-radio_${index}`}
                        isChecked={discountAnswers[discount?.dKey]}
                        disabled={props?.isBind}
                        onChange={(e) =>
                          handleDiscountChange(discount, e.target.checked)
                        }
                      />
                    </Flex>
                  </Flex>

                  {index < discounts.length - 1 &&
                    discounts[index + 1]?.dKey !== "hasHomeInsuranceInForce" &&
                    discounts[index + 1]?.dKey !==
                      "isApplicantLocatedNorhI10" && (
                      <Box borderTop={"1px solid"} borderColor={"gray.200"} />
                    )}

                  {/* Render sub-questions if they exist */}
                  {discount.subQuestions && discountAnswers[discount.dKey] && (
                    <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                      {discount.subQuestions.map((subQuestion, subIndex) => (
                        <li key={subIndex}>
                          <Flex
                            justifyContent={"space-between"}
                            key={subIndex}
                            pt={3}
                          >
                            <Box>
                              {renderDiscountTexts(subQuestion)}
                              {!(
                                subQuestion?.dKey ===
                                  "theftLocalMonitorAlarm" ||
                                subQuestion?.dKey ===
                                  "theftLocalBurglarAlarm" ||
                                subQuestion?.dKey === "theftDeadboltLocks" ||
                                subQuestion?.dKey === "fireMonitoAlarm" ||
                                subQuestion?.dKey === "fireLocalAlarm" ||
                                subQuestion?.dKey ===
                                  "fireOtherProtectionDevice"
                              ) && (
                                <a
                                  data-tip
                                  data-for={subQuestion?.dKey}
                                  style={{
                                    marginLeft: "5px",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <FcInfo bg="primary" cursor={"pointer"} />
                                </a>
                              )}
                              {renderTootipText(subQuestion?.dKey)}
                            </Box>
                            <Flex flexDir={"row"} gap={2} p={2}>
                              <Switch
                                mt={-2}
                                size="lg"
                                label={t("Yes")}
                                isChecked={discountAnswers[subQuestion?.dKey]}
                                onChange={(e) =>
                                  handleDiscountChange(
                                    subQuestion,
                                    e.target.checked
                                  )
                                }
                                disabled={props?.isBind}
                              />
                            </Flex>
                          </Flex>
                          {index < discounts.length - 1 && (
                            <Box
                              pt={2}
                              borderTop={"1px solid"}
                              borderColor={"gray.200"}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </Box>
              );
            })}
          </ListGroup>
        </div>
      </div>

      <style>
        {`
          .chakra-switch {
            width: fit-content !important;
            margin-top: 1.5px;
          }

          .chakra-switch .chakra-switch__track::after {
            content: "NO" !important;
            color: var(--chakra-colors-white);
            display: block;
            position: absolute;
            top: 15px;
            left: 58%;
            font-size: 11px;
            font-weight: bold;
          }

          .chakra-switch[data-checked] .chakra-switch__track::after {
            content: "YES" !important;
            left: 7.7%;
            font-size: 10px;
            font-weight: normal;
          }
        `}
      </style>
    </>
  );
};
function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(Discount);
