import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import Icon1 from "../../assets/Icon1.png";
import Icon2 from "../../assets/Icon2.png";
import Icon3 from "../../assets/Icon3.png";
import Icon4 from "../../assets/Icon4.png";

const ProductQuoteTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Get protection of your assets</span>
      </div>
      <div className="managment-tab-body">
        <div className="product-quote-tab-main-div">
          <Row>
            <Col xs="12">
              <span className="product-quote-tab-main-title">
                Select the type of insurance you need
              </span>
            </Col>
            <Col xs="12">
              <Row>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div className="product-quote-card-main-div qpc1">
                    <img className="quote-page-cards-icon" src={Icon1} />
                    <div className="product-quote-card-main-title">Home</div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div className="product-quote-card-main-div qpc2">
                    <img className="quote-page-cards-icon" src={Icon2} />
                    <div className="product-quote-card-main-title">
                      Condominium
                    </div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div className="product-quote-card-main-div qpc3">
                    <img className="quote-page-cards-icon" src={Icon3} />

                    <div className="product-quote-card-main-title">
                      Mobil Home Owners Program{" "}
                    </div>
                  </div>
                </Col>

                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                  <div className="product-quote-card-main-div qpc4">
                    <img className="quote-page-cards-icon" src={Icon4} />
                    <div className="product-quote-card-main-title">
                      Mobil Home Dwelling Program{" "}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductQuoteTab;
