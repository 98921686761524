import React from "react";
import FinancialDetails from "../FinancialDetails/FinancialDetails";
import { Box} from "@chakra-ui/react";

const StepFinancialDetails = () => {
  return (
    <Box>
      <Box pb={"1.5rem"} p={2}>
        <Box p={2}>
          <FinancialDetails />
        </Box>
      </Box>
    </Box>
  );
};

export default StepFinancialDetails;