import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import PaymentTab from "./PaymentTab";
import PendingTab from "./PendingTab";
import RecentTab from "./RecentTab";
import UpcomingTab from "./UpcomingTab";
import Report from "../report/Report";
import "./management-reports.scss";

const ManagementReports = () => {
  return (
    <div className="management-page-section">
      <h5 className="management-page-title">Management Reports</h5>

      <Row>
        <Col xs="12" className="custom-tabs">
          <Tabs defaultActiveKey="Payment Due" id="uncontrolled-tab-example">
            <Tab eventKey="Payment Due" title="Payment Due">
              <PaymentTab />
            </Tab>
            <Tab eventKey="Upcoming Renewals" title="Upcoming Renewals">
              <UpcomingTab />
            </Tab>
            <Tab eventKey="Pending Cancelation" title="Pending Cancelation">
              <PendingTab />
            </Tab>
            <Tab eventKey="Recent Transaction" title="Recent Transaction">
              <RecentTab />
            </Tab>
            <Tab eventKey="Download Reports" title="Download Reports">
              <Report />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ManagementReports;
