import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getList } from "../../../services/documentCenter";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { Box, Text, Grid, Stack } from "@chakra-ui/react";
import PolicyNumber from "./PolicyNumber";
import PolicyHolder from "./PolicyHolder";
import SearchResult from "./SearchResult";

const DocumentCenter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchedPolicyNo, setSearchedPolicyNo] = useState(null);
    const [policyNo, setPolicyNo] = useState(null);
    const [docData, setDocData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [policyHolder, setPolicyHolder] = useState(null);

    const onPolicyNoChange = (searchedPolicyNo) => {
        setSearchedPolicyNo(searchedPolicyNo);
    };

    useEffect(() => {
        const policyDetails = async () => {
            setLoading(true);
            await dispatch(getList(searchedPolicyNo)).then((res) => {
                if (res && res.data) {
                    if (res.data.status === 'Y') {
                        setDocData(res.data.data)
                        if (res.data.policyHolderData) {
                            setPolicyHolder(res.data.policyHolderData[0]);
                        }
                        if (res.data.policyData) {
                            setPolicyNo(res.data.policyData[0].Policy_No);
                        }
                        toast.success(res.data.msg);
                    } else {
                        toast.error(res.data.msg);
                        setPolicyHolder("");
                    }
                    setLoading(false);
                }
            }).catch((err) => {
                toast.error('Something Went Wrong');
                setLoading(false);
            });
        }
        if (searchedPolicyNo) {
            policyDetails();
        }
        //eslint-disable-next-line
    }, [searchedPolicyNo]);

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <>
                <Box mb={8}>
                    <Box borderRadius={"2xl"}>
                        <Text
                            fontSize={"2xl"}
                            fontWeight="var(--chakra-fontWeights-semibold)"
                            mb={2}
                        >
                            {t("Document Center")}
                        </Text>
                    </Box>
                    <Box py={2} borderRadius={"2xl"}>
                        <Grid
                            templateColumns={{
                                base: "1fr",
                                md: "repeat(2, 1fr)",
                            }}
                            gap={{ base: "0.75rem", md: "1.5rem" }}
                        >
                            <Stack>
                                <PolicyNumber onPolicyNoChange={onPolicyNoChange} />
                            </Stack>
                            <Stack>
                                {searchedPolicyNo && policyHolder &&
                                    <PolicyHolder policyNo={policyNo} policyHolder={policyHolder} />
                                }
                            </Stack>
                        </Grid>
                    </Box>
                    <Box mt={"1rem"} borderRadius={"2xl"}>
                        {searchedPolicyNo && policyHolder && <SearchResult result={docData} setLoading={setLoading} />}
                    </Box>
                </Box>
            </>
        </LoadingOverlay>
    )
}

export default DocumentCenter;