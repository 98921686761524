import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { CreateQuoteContext, useMyContext } from "../../CreateQuote";
import { ResidentialDataDD } from "../../data/ResidentialData";
import { CommercialDataDD } from "../../data/CommercialData";
import { PriorLossesTable } from "./PriorLossesTable";
import { LimitsTable } from "./LimitsTable";
import { useTranslation } from "react-i18next";
import { FormLabel, FormControl, Select } from "@chakra-ui/react";
import { CustomCurrencyInput } from "../../../../common/CustomCurrencyInput";
import "../style.css";

const FinancialDetails = () => {
  const { t } = useTranslation();
  const {
    product,
    tenant,
    financialDetails,
    setFinancialDetails,
    isNextClicked,
    setRcvValidationError,
    constructionDetails,
  } = useMyContext(CreateQuoteContext);
  const [productType, setProductType] = useState("Residential");
  const [tenancyType, setTenancyType] = useState("Owned");
  useEffect(() => {
    if (typeof product == "string") {
      setProductType(product);
    }
    if (typeof tenant == "string") {
      setTenancyType(tenant);
    }
  }, [product, tenant]);

  useEffect(() => {
    const handleRcvValidation = () => {
      if (
        constructionDetails?.productType === "Commercial" &&
        constructionDetails?.commercialProductType === "Owned" &&
        (financialDetails?.commercial?.owned?.replacementCostValues?.building ==
          null ||
          financialDetails?.commercial?.owned?.replacementCostValues
            ?.building == 0 ||
          financialDetails?.commercial?.owned?.replacementCostValues
            ?.contents == null ||
          financialDetails?.commercial?.owned?.replacementCostValues
            ?.contents == 0)
      ) {
        setRcvValidationError(true);
      } else if (
        constructionDetails?.productType === "Commercial" &&
        constructionDetails?.commercialProductType === "Tenanted" &&
        (financialDetails?.commercial?.tenanted?.replacementCostValues
          ?.contents == 0 ||
          financialDetails?.commercial?.tenanted?.replacementCostValues
            ?.improvementsAndBetterments == 0)
      ) {
        setRcvValidationError(true);
      } else if (
        productType === "Residential" &&
        (financialDetails?.residential?.replacementCostValues?.building ==
          null ||
          financialDetails?.residential?.replacementCostValues?.building == 0 ||
          financialDetails?.residential?.replacementCostValues?.contents ==
            null ||
          financialDetails?.residential?.replacementCostValues?.contents == 0)
      ) {
        setRcvValidationError(true);
      } else {
        setRcvValidationError(false);
      }
    };

    handleRcvValidation();
  }, [
    constructionDetails,
    financialDetails,
    productType,
    setRcvValidationError,
    tenancyType,
  ]);

  return (
    <>
      <Form
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "2rem",
          padding: "1.5rem",
        }}
      >
        {productType === "Residential" && (
          <>
            <FormControl>
              <FormLabel>
                {t(" Building")} {t("Replacement Cost Value")}:{" "}
                <span className="label-asterisk" style={{ fontSize: "18px" }}>
                  *
                </span>
              </FormLabel>
              <CustomCurrencyInput
                placeholder="Building Replacement Cost Value"
                decimalsLimit={3}
                value={
                  financialDetails?.residential?.replacementCostValues
                    ?.building || ""
                }
                onChange={(e) =>
                  setFinancialDetails({
                    ...financialDetails,
                    residential: {
                      ...financialDetails.residential,
                      replacementCostValues: {
                        ...financialDetails.residential.replacementCostValues,
                        building: Number(e.target.value.replace(/[^\d.]/g, "")),
                      },
                    },
                  })
                }
              />
              {/* <Form.Control
                value={
                  financialDetails?.residential?.replacementCostValues
                    ?.building || ""
                }
                type="number"
                required
                min={0}
                onChange={(e) =>
                  setFinancialDetails({
                    ...financialDetails,
                    residential: {
                      ...financialDetails.residential,
                      replacementCostValues: {
                        ...financialDetails.residential.replacementCostValues,
                        building: Number(e.target.value),
                      },
                    },
                  })
                }
                placeholder="Building"
              /> */}
              {isNextClicked &&
                (financialDetails?.residential?.replacementCostValues
                  ?.building == null ||
                  financialDetails?.residential?.replacementCostValues
                    ?.building == 0) && (
                  <Form.Text style={{ color: "red" }}>
                    {" "}
                    {t(" Building Replacement Cost Value is required")}{" "}
                  </Form.Text>
                )}
            </FormControl>
            <FormControl>
              <FormLabel>
                {t("Contents")} {t("Replacement Cost Value")}:{" "}
                <span className="label-asterisk" style={{ fontSize: "18px" }}>
                  *
                </span>{" "}
              </FormLabel>
              <CustomCurrencyInput
                value={
                  financialDetails?.residential?.replacementCostValues
                    ?.contents || ""
                }
                onChange={(e) =>
                  setFinancialDetails({
                    ...financialDetails,
                    residential: {
                      ...financialDetails.residential,
                      replacementCostValues: {
                        ...financialDetails.residential.replacementCostValues,
                        contents: Number(e.target.value.replace(/[^\d.]/g, "")),
                      },
                    },
                  })
                }
                placeholder="Contents Replacement Cost Value"
              />
              {/* <Form.Control
                value={
                  financialDetails?.residential?.replacementCostValues
                    ?.contents || ""
                }
                type="number"
                min={0}
                onChange={(e) =>
                  setFinancialDetails({
                    ...financialDetails,
                    residential: {
                      ...financialDetails.residential,
                      replacementCostValues: {
                        ...financialDetails.residential.replacementCostValues,
                        contents: Number(e.target.value),
                      },
                    },
                  })
                }
                placeholder="Contents"
              /> */}
              {isNextClicked &&
                (financialDetails?.residential?.replacementCostValues
                  ?.contents == null ||
                  financialDetails?.residential?.replacementCostValues
                    ?.contents == 0) && (
                  <Form.Text style={{ color: "red" }}>
                    {" "}
                    {t("Contents Replacement Cost Value is required")}{" "}
                  </Form.Text>
                )}
            </FormControl>
            <FormControl>
              <FormLabel>{t("Contents Cost Value Type")}:</FormLabel>
              <Select
                className="selectField"
                type="text"
                placeholder="Contents Cost Value Type"
                value={financialDetails?.contentsCostValueType}
                onChange={(e) =>
                  setFinancialDetails({
                    ...financialDetails,
                    contentsCostValueType: e.target.value,
                  })
                }
                title="Extra Expanse Annual"
              >
                {ResidentialDataDD?.ContentsCostValueType?.map(
                  (item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
          </>
        )}
        {productType === "Commercial" && (
          <>
            {tenancyType === "Owned" && (
              <>
                <FormControl>
                  <FormLabel>
                    {t("Building Replacement Cost Value")}
                    <span
                      className="label-asterisk"
                      style={{ fontSize: "18px" }}
                    >
                      *
                    </span>
                    :{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.building || ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          owned: {
                            ...financialDetails.commercial.owned,
                            replacementCostValues: {
                              ...financialDetails.commercial.owned
                                .replacementCostValues,
                              building: Number(
                                e.target.value.replace(/[^\d.]/g, "")
                              ),
                            },
                          },
                        },
                      })
                    }
                    placeholder="Building Replacement Cost Value"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.building || ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          owned: {
                            ...financialDetails.commercial.owned,
                            replacementCostValues: {
                              ...financialDetails.commercial.owned
                                .replacementCostValues,
                              building: Number(e.target.value),
                            },
                          },
                        },
                      })
                    }
                    placeholder="Building"
                  /> */}
                  {isNextClicked &&
                    (financialDetails?.commercial?.owned?.replacementCostValues
                      ?.building == null ||
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.building == 0) && (
                      <Form.Text style={{ color: "red", fontSize: "12px" }}>
                        {t("Building Replacement Cost Value is required")}
                      </Form.Text>
                    )}
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("Contents Replacement Cost Value")}{" "}
                    <span
                      className="label-asterisk"
                      style={{ fontSize: "18px" }}
                    >
                      *
                    </span>
                    :{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.contents || ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          owned: {
                            ...financialDetails.commercial.owned,
                            replacementCostValues: {
                              ...financialDetails.commercial.owned
                                .replacementCostValues,
                              contents: Number(
                                e.target.value.replace(/[^\d.]/g, "")
                              ),
                            },
                          },
                        },
                      })
                    }
                    placeholder="Contents Replacement Cost Value"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.contents || ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          owned: {
                            ...financialDetails.commercial.owned,
                            replacementCostValues: {
                              ...financialDetails.commercial.owned
                                .replacementCostValues,
                              contents: Number(e.target.value),
                            },
                          },
                        },
                      })
                    }
                    placeholder="Contents"
                  /> */}
                  {isNextClicked &&
                    (financialDetails?.commercial?.owned?.replacementCostValues
                      ?.contents == null ||
                      financialDetails?.commercial?.owned?.replacementCostValues
                        ?.contents === "") && (
                      <Form.Text
                        className="label-asterisk"
                        style={{ color: "red" }}
                      >
                        {t("Contents Replacement Cost Value is required")}
                      </Form.Text>
                    )}
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("Annual Business Income & Extra Expenses")}:{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial
                        ?.businessIncomeAndExtraExpenseAnnualValue || ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          businessIncomeAndExtraExpenseAnnualValue: Number(
                            e.target.value.replace(/[^\d.]/g, "")
                          ),
                        },
                      })
                    }
                    placeholder="Business Income & Extra Expense Annual Value"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial
                        ?.businessIncomeAndExtraExpenseAnnualValue || ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          businessIncomeAndExtraExpenseAnnualValue: Number(
                            e.target.value
                          ),
                        },
                      })
                    }
                    placeholder="Business Income & Extra Expense Annual Value"
                  /> */}
                </FormControl>

                <FormControl>
                  <FormLabel>{t("Stock Replacement Cost Value")}</FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial?.stockReplacementCostValue ||
                      ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          stockReplacementCostValue: Number(
                            e.target.value.replace(/[^\d.]/g, "")
                          ),
                        },
                      })
                    }
                    placeholder="Stock Replacement Cost Value"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.stockReplacementCostValue ||
                      ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          stockReplacementCostValue: Number(e.target.value),
                        },
                      })
                    }
                    placeholder="Stock Replacement Cost Value"
                  /> */}
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Contents Cost Value Type")}</FormLabel>
                  <Select
                    className="selectField"
                    type="text"
                    placeholder="Contents Cost Value Type"
                    title="Extra Expanse Annual"
                    value={financialDetails?.contentsCostValueType}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        contentsCostValueType: e.target.value,
                      })
                    }
                  >
                    {CommercialDataDD?.ContentsCostValueType?.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl></FormControl>
              </>
            )}
            {tenancyType === "Tenanted" && (
              <>
                <FormControl>
                  <FormLabel>
                    {t("Contents")} {t("Replacement Cost Value")}:{" "}
                    <span
                      className="label-asterisk"
                      style={{ fontSize: "18px" }}
                    >
                      *
                    </span>{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    placeholder={t("Contents Replacement Cost Value")}
                    value={
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.contents || ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          tenanted: {
                            ...financialDetails.commercial.tenanted,
                            replacementCostValues: {
                              ...financialDetails.commercial.tenanted
                                .replacementCostValues,
                              contents: Number(
                                e.target.value.replace(/[^\d.]/g, "")
                              ),
                            },
                          },
                        },
                      })
                    }
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.contents || ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          tenanted: {
                            ...financialDetails.commercial.tenanted,
                            replacementCostValues: {
                              ...financialDetails.commercial.tenanted
                                .replacementCostValues,
                              contents: Number(e.target.value),
                            },
                          },
                        },
                      })
                    }
                  /> */}
                  {isNextClicked &&
                    (financialDetails?.commercial?.tenanted
                      ?.replacementCostValues?.contents == null ||
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.contents == 0) && (
                      <Form.Text style={{ color: "red", fontSize: "12px" }}>
                        {t("Contents Replacement Cost Value is required")}
                      </Form.Text>
                    )}
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("Annual Business Income & Extra Expenses")}:{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial
                        ?.businessIncomeAndExtraExpenseAnnualValue || ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          businessIncomeAndExtraExpenseAnnualValue: Number(
                            e.target.value.replace(/[^\d.]/g, "")
                          ),
                        },
                      })
                    }
                    placeholder="Annual Business Income & Extra Expenses"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial
                        ?.businessIncomeAndExtraExpenseAnnualValue || ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          businessIncomeAndExtraExpenseAnnualValue: Number(
                            e.target.value
                          ),
                        },
                      })
                    }
                    placeholder="Annual Business Income & Extra Expenses"
                  /> */}
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Stock Replacement Cost Value")}:</FormLabel>
                  <CustomCurrencyInput
                    value={
                      financialDetails?.commercial?.stockReplacementCostValue ||
                      ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          stockReplacementCostValue: Number(
                            e.target.value.replace(/[^\d.]/g, "")
                          ),
                        },
                      })
                    }
                    placeholder="Stock Replacement Cost Value"
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.stockReplacementCostValue ||
                      ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,

                          stockReplacementCostValue: Number(e.target.value),
                        },
                      })
                    }
                    placeholder="Stock Replacement Cost Value"
                  /> */}
                </FormControl>
                <FormControl>
                  <FormLabel>{t("Contents Cost Value Type")}:</FormLabel>
                  <Select
                    className="selectField"
                    type="text"
                    placeholder="Contents Cost Value Type"
                    title="Extra Expanse Annual"
                    value={financialDetails?.contentsCostValueType}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        contentsCostValueType: e.target.value,
                      })
                    }
                  >
                    {CommercialDataDD?.ContentsCostValueType?.map(
                      (item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      )
                    )}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>
                    {t("Improvements and betterment")}:{" "}
                    <span
                      className="label-asterisk"
                      style={{ fontSize: "18px" }}
                    >
                      *
                    </span>{" "}
                  </FormLabel>
                  <CustomCurrencyInput
                    placeholder={t("Improvements and Betterments")}
                    value={
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.improvementsAndBetterments ||
                      ""
                    }
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          tenanted: {
                            ...financialDetails.commercial.tenanted,
                            replacementCostValues: {
                              ...financialDetails.commercial.tenanted
                                .replacementCostValues,
                              improvementsAndBetterments: Number(
                                e.target.value.replace(/[^\d.]/g, "")
                              ),
                            },
                          },
                        },
                      })
                    }
                  />
                  {/* <Form.Control
                    value={
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.improvementsAndBetterments ||
                      ""
                    }
                    type="number"
                    min={0}
                    onChange={(e) =>
                      setFinancialDetails({
                        ...financialDetails,
                        commercial: {
                          ...financialDetails.commercial,
                          tenanted: {
                            ...financialDetails.commercial.tenanted,
                            replacementCostValues: {
                              ...financialDetails.commercial.tenanted
                                .replacementCostValues,
                              improvementsAndBetterments: Number(
                                e.target.value
                              ),
                            },
                          },
                        },
                      })
                    }
                  /> */}
                  {isNextClicked &&
                    (financialDetails?.commercial?.tenanted
                      ?.replacementCostValues?.improvementsAndBetterments ==
                      null ||
                      financialDetails?.commercial?.tenanted
                        ?.replacementCostValues?.improvementsAndBetterments ==
                        0) && (
                      <Form.Text style={{ color: "red", fontSize: "12px" }}>
                        Improvements and betterments is required
                      </Form.Text>
                    )}
                </FormControl>
                <FormControl></FormControl>
              </>
            )}
          </>
        )}
        <div>
          <FormLabel>{t("Prior Losses")}:</FormLabel>
          <PriorLossesTable />
        </div>
        <div>
          <FormLabel>{t("Limits")}:</FormLabel>
          <LimitsTable />
        </div>
      </Form>
    </>
  );
};

export default FinancialDetails;
