import React from "react";
import { Box, Flex, Spinner, useColorModeValue, Text } from "@chakra-ui/react";

const ChakraLoader = ({ isLoading, children }) => {
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.8)",
    "rgba(45, 55, 72, 0.8)"
  );

  return (
    <Box position="relative" w="100%" h="100%">
      {children}
      {isLoading && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          backgroundColor={bgColor}
          zIndex="999"
          flexDir="column"
        >
          <Spinner size="xl" color="primary.500" />
          <Text size="md" mt={4} color={"neutral.500"}>
            Loading ...
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default ChakraLoader;
