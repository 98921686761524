import Http from '../Http';
import * as action from '../store/actions';

export function getQuoteListData(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      let url = `/api/quote/list?`;
      Object.entries(params).forEach(v => {
        if (v[1] !== '') {
          url = url + '&' + v[0] + '=' + v[1];
        }
      });
      Http.get(url)
        .then((res) => {
          dispatch(action.getQuoteListData(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getQuoteData(n_potransaction_PK) {
  const url = "/api/quoteHO3/getQuoteData/" + n_potransaction_PK;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getBaseData(product = 13) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = "/api/quote/getBaseData/" + product;
      Http.get(url)
        .then((res) => {
          dispatch(action.getBaseData(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getEditEmptyQuoteData(data) {
  return dispatch => (
    dispatch(action.getEditEmptyQuoteData(data))

  );
}


export function getEmptyQuoteData(product = 13) {

  var url = '/api/quote/emptyQuoteData/' + product;

  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getProductListData() {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/quote/getProductsList`;
      Http.get(url)
        .then((res) => {
          dispatch(action.getProductListData(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getAppStatusDD() {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/quote/getAppStatusList`;
      Http.get(url)
        .then((res) => {
          dispatch(action.getAppStatusDD(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getSearchMortgagee(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(`/api/quote/searchMortgagee`, data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getZipDetails(product, zipCode, source) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/quote/getZipCodeDetails/' + product + '/' + zipCode + '/' + source)
        .then((res) => {
          return resolve(res.data);
        })
    })
  );
}

export function saveAndRate(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(`/api/quoteHO3/saveAndRate`, data)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function deletePolicyInterest(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post(`/api/quote/deletePolicyInterest`, params)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

/**
 * To Get Agency List based on value
 * @returns Object
 */
export function agencyList(search) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quote/agencyList', search)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  );
}

/**
* To Get Agent List based on agencyID
* @returns Object
*/
export function agentList(agencyID, productPk) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/quote/agentList/' + agencyID + '/' + productPk)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  );
}

/**
* To Get Agent Data
* @returns Object
*/
export function getAgencyDetails(agencyID) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/quote/getAgencyDetails/' + agencyID)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  );
}

export function getDocInfoSubmit(transactionPK) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get('/api/quote/getDocInfoSubmit/' + transactionPK)
        .then((res) => {
          return resolve(res.data);
        })
    })
  );
}

export function getPersonAddrWithMapData(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quote/getPersonAddrWithMapData', data)
        .then((res) => {
          return resolve(res.data);
        })
    })
  );
}

export function dataTransferToCore(transferData) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quoteHO3/generateQuote', transferData)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getBindApplicationHO3(paramData) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quoteHO3/bindAppHo3', paramData)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getErrorLogListData(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/errorlog/list?n_potransaction_PK=+${params.n_potransaction_PK}&page=+${params.page}&pageLength=+${params.pageLength}`;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function getErrorMsg(data) {
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.post('/api/quote/getErrorMsg', data)
        .then((res) => {
          return resolve(res.data);
        })
    })
  );
}

export function callGeneratePdfApi(source, n_potransaction_PK) {
  const url = "/api/reports/generate/" + source + "/" + n_potransaction_PK;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getHistoryDataListData(params) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/historydata/list?n_potranpremdetails_PK=+${params.n_potranpremdetails_PK}&page=+${params.page}&pageLength=+${params.pageLength}`;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}

export function callGenerateHistoryRateSheetApi(n_datalog_PK) {
  const url = "/api/reports/generateRateHistory/RATEHISTORY/" + n_datalog_PK;
  return dispatch => (
    new Promise((resolve, reject) => {
      Http.get(url)
        .then((res) => {
          return resolve(res.data);
        }).catch(function (error) { // catch
          return reject(error);
        });
    })
  )
}

export function getAppRemarkDD() {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/quote/getAppRemarkList`;
      Http.get(url)
        .then((res) => {
          dispatch(action.getAppRemarkDD(res.data));
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}
export function checkAccessibilityOfProduct (productCode) {
  return dispatch => (
    new Promise((resolve, reject) => {
      const url = `/api/v2/${productCode}/check/accessibility`;
      Http.get(url)
        .then((res) => {
          return resolve(res);
        }).catch((err) => {
          if (err.response) {
            return resolve(err.response);
          }
        });
    })
  );
}