import { Box, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  aopDed,
  theftDeductibleFields,
  windHailDeductibles,
} from "../../commercial-quote/data/commercialDD";
import {
  ChakraSelect,
  CustomFormControl,
} from "../../commercial-quote/Components/CustomComponents";
import { useQuote } from "../../CommCreateQuote";

export const BlanketDeductibles = () => {
  const { t } = useTranslation();
  const { blanketDisplayCoverages, setBlanketDisplayCoverages } = useQuote();

  const updateCoverageDetails = (name, value) => {
    setBlanketDisplayCoverages({
      ...blanketDisplayCoverages,
      deductibles: {
        ...blanketDisplayCoverages.deductibles,
        [name]: value,
      },
    });
  };

  return (
    <>
      <Box>
        <Grid
          templateColumns={"repeat(auto-fit, minmax(200px, 1fr))"}
          gap={6}
          p={2}
        >
          <CustomFormControl label={t("All-Perils Deductible")}>
            <ChakraSelect
              value={blanketDisplayCoverages.deductibles?.allPerilsDed || ""}
              onChange={(value) => {
                updateCoverageDetails("allPerilsDed", value?.target?.value);
              }}
            >
              <option value=""></option>
              {aopDed?.map((e, i) => (
                <option key={i} value={e?.s_DiscountCode}>
                  {e?.s_ScreenName}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>
          <CustomFormControl label={t("Hurricane Deductible")}>
            <ChakraSelect
              value={blanketDisplayCoverages.deductibles?.hurricaneDed || ""}
              onChange={(value) => {
                updateCoverageDetails("hurricaneDed", value?.target?.value);
              }}
            >
              <option value=""></option>
              {windHailDeductibles?.map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </ChakraSelect>
          </CustomFormControl>
          {blanketDisplayCoverages.basicCoverages?.theftExclusions === "NO" && (
            <CustomFormControl label={t("Theft Deductible")}>
              <ChakraSelect
                value={blanketDisplayCoverages.deductibles?.theftDed || ""}
                onChange={(e) => {
                  updateCoverageDetails("theftDed", e.target.value);
                }}
              >
                <option value=""></option>
                {theftDeductibleFields?.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </ChakraSelect>
            </CustomFormControl>
          )}
          {blanketDisplayCoverages.basicCoverages?.windExclusions === "NO" && (
            <CustomFormControl label={t("Wind/Hail Deductible")}>
              <ChakraSelect
                value={blanketDisplayCoverages.deductibles?.windHailDed || ""}
                onChange={(e) => {
                  updateCoverageDetails("windHailDed", e.target.value);
                }}
              >
                <option value=""></option>
                {windHailDeductibles?.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </ChakraSelect>
            </CustomFormControl>
          )}
        </Grid>
      </Box>
    </>
  );
};
