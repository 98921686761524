import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import { checkPivotOutage } from "../../../../services/commonService";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Coverage = (props) => {
    const pivotOutage = checkPivotOutage();
    const { emptyQuoteData, validated, NOU, preFirmSubsidyEligible, isTheInsured, occupancyType, buildingType, isBind, handleChange, isDataChanged } = props;
    const { floodSystemDD } = useSelector(state => state.flood);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [RC, setRC] = useState('');
    const [RCR, setRCR] = useState('');
    const [cCVG, setCCVG] = useState(0);
    const [cDED, setCDED] = useState('');
    const [bCVG, setBCVG] = useState(0);
    const [bDED, setBDED] = useState('');
    const [bDedDD, setBDedDD] = useState([]);
    const [cDedDD, setCDedDD] = useState([]);
    const [isLimitExceed, setIsLimitExceed] = useState(false);
    const [info, setInfo] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const [deductible, setDeductible] = useState([]);
    const [isChange, setIsChange] = useState(false);

    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setRC(emptyQuoteData.propertyInfo.n_ReplacementCost || 0);
            setRCR(emptyQuoteData.propertyInfo.n_ReplacementCostRatio || 0);
            setCCVG(emptyQuoteData.propertyCoverageInfo.n_ContentCvg || 0);
            setBCVG(emptyQuoteData.propertyCoverageInfo.n_BuildingCvg || 0);
            setBDED(emptyQuoteData.propertyCoverageInfo.s_BuildingDeductible || "0");
            setCDED(emptyQuoteData.propertyCoverageInfo.s_ContentDeductible || "0");
        }
        if (Object.keys(floodSystemDD).length > 0) {
            setDeductible(floodSystemDD.deductible);
        }
    }, [emptyQuoteData, floodSystemDD])

    // set dropdown
    useEffect(() => {
        // For Occupancy Type 1-4 Family
        var dd = [];
        if (Object.keys(deductible).length > 0) {
            if (bCVG > 0 && bCVG <= 100000) {
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
            }
            if (bCVG > 0 && bCVG > 100000) {
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED1250', 25000].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                    }
                }
            }
            setBDedDD([{ 's_DiscountCode': 0, 's_ScreenName': "$0 Deductible" }, ...dd]);
            dd = [];
            // content
            if ((bCVG > 0 && bCVG <= 100000)) {
                // For Occupancy Type 1-4 Family 
                // For Occupancy Type 1-4 Family 
                // For Occupancy Type 1-4 Family 
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    if (preFirmSubsidyEligible) {
                        dd = deductible.filter(x => ['FLDDED1500', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    } else {
                        dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                    }
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }
            if (bCVG > 0 && bCVG > 100000) {
                if (['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1250', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                }
                // condo building
                if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED1250', 'FLDDED25000'].includes(x.s_DiscountCode))
                }
                // For REsidential Mobile Home & Unit
                if (['RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }
            if (bCVG == 0) {
                // Other-res & non-res
                if (['OTHERRESIDENTIAL20', 'NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000', 'FLDDED25000', 'FLDDED50000'].includes(x.s_DiscountCode))
                } else { // For Any Occupancy
                    dd = deductible.filter(x => ['FLDDED1000', 'FLDDED2000', 'FLDDED5000', 'FLDDED10000'].includes(x.s_DiscountCode))
                }
            }

            setCDedDD([{ 's_DiscountCode': 0, 's_ScreenName': "$0 Deductible" }, ...dd]);
            // If preFirmSubsidyEligible is true then all occupancy types may have 1500 deducible, in addition to others, if building coverage < or =  100,000. 
            // If preFirmSubsidyEligible is true then all occupancy types may have 1500 deducible, in addition to others, if building coverage < or =  100,000. 
            // If preFirmSubsidyEligible is true then all occupancy types may have 1500 deducible, in addition to others, if building coverage < or =  100,000. 
            if (preFirmSubsidyEligible && deductible.some(x => x.s_DiscountCode === 'FLDDED1250') && bCVG < 100000) {
                // If 1250 Present Then Find Index and Replace with 1500
                var index = deductible.findIndex(x => x.s_DiscountCode === 'FLDDED1250')
                dd[index] = deductible.filter(x => [1500].includes(x.s_DiscountCode))[0]
            }
        }
    }, [cCVG, bCVG, occupancyType, deductible, preFirmSubsidyEligible])

    // set content deductible to 2000
    useEffect(() => {
        if (![0, null, ''].includes(cCVG) && cCVG > 0) {
            if (cDED === "0") {
                handleStateChange('FLDDED2000', 'cDED');
            }
        }
        if (cCVG === 0 && !["0", null, ''].includes(cDED) ) {
            handleStateChange("0", 'cDED');
        }
    }, [cCVG]);

    // set building deductible 0 if building coverage 0
    useEffect(() => {
        if (emptyQuoteData.propertyCoverageInfo.n_BuildingCvg === 0) {
            handleStateChange("0", 'bDED');
        }
    }, [emptyQuoteData]);

    // error
    useEffect(() => {
        if (isLimitExceed === true) {
            toast.error(t('Coverage Max Limit Exceed!'));
        }
    }, [isLimitExceed]);

    // error
    useEffect(() => {
        // “Please select the value that best describes the building” does not = Residential condo unit - residential building then Building coverage must = 0
        if ((occupancyType === 'RESIDENTIALUNIT20' && (buildingType && buildingType !== 'RESIDENTIALCONDOUNITRB20')) || occupancyType === 'NONRESIDENTIALUNIT20') {
            handleStateChange(0, 'bCVG');
            handleStateChange("0", 'bDED');
        }
    }, [occupancyType, buildingType]);

    // show info
    useEffect(() => {
        var temp = [...info], text = 'While a tenant can have building coverage if the landlord requires it, it must be building only coverage and the landlord must be listed on the policy. Tenant owned contents must be on a separate policy.';
        if (isTheInsured === 'TENANT' && ['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType) && bCVG > 0) {
            if (temp.indexOf(text) === -1) {
                temp.push(text);
            }
        } else {
            temp.indexOf(text) !== -1 && temp.splice(temp.indexOf(text), 1)
        }
        setShowInfo(temp.length > 0 ? true : false);
        setInfo(temp);
    }, [cCVG, bCVG, isTheInsured, occupancyType]);

    // update RC
    useEffect(() => {
        updateReplacementCostRatio();
    }, [bCVG, RC])

    /**
    * To update replacement cost ratio
    */
    const updateReplacementCostRatio = () => {
        var buildingCvg = bCVG || 0, replacementCost = RC || 0;
        var ratio = 0;
        if (replacementCost) {
            ratio = ((buildingCvg * 100) / replacementCost); // to get ratio
        }
        handleStateChange(ratio, 'RCR');
    }

    // Validate Coverage Max Amount
    const validateCoverage = (cvgType) => {
        cvgType === 'BUILDING' && setBCVG(1000 * Math.ceil(bCVG / 1000))
        cvgType === 'CONTENT' && setCCVG(1000 * Math.ceil(cCVG / 1000))
        // Building coverage maxes out at 250k and contents at 100k for residential
        if (['SINGLEFAMILY20', 'TWOFOURFAMILY20', 'RESIDENTIALUNIT20', 'RESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
            if (cvgType === 'BUILDING' && bCVG !== '' && bCVG > 250000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && cCVG !== '' && cCVG > 100000) {
                setIsLimitExceed(true)
            }
        }

        // Building coverage maxes out at 250k and contents at 100k for residential
        if (['OTHERRESIDENTIAL20'].includes(occupancyType)) {
            if (cvgType === 'BUILDING' && bCVG !== '' && bCVG > 500000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && cCVG !== '' && cCVG > 100000) {
                setIsLimitExceed(true)
            }
        }

        // For a non-residential, it is 500k and 500k.
        if (['NONRESIDENTIALUNIT20', 'NONRESIDENTIALBUILD20', 'NONRESIDENTIALMOBILEHOME20'].includes(occupancyType)) {
            if (cvgType === 'BUILDING' && bCVG !== '' && bCVG > 500000) {
                setIsLimitExceed(true)
            }
            if (cvgType === 'CONTENT' && cCVG !== '' && cCVG > 500000) {
                setIsLimitExceed(true)
            }
        }

        // Coverage limit max for building if RCBAP - can be the RCE value or 250k * number of units. wich ever is less. Contents max is 100k
        if (['RESIDENTIALCONDOBUILD20'].includes(occupancyType)) {
            var val1 = RC, val2 = (250000 * NOU);
            if (cvgType === 'BUILDING' && bCVG !== '' && ((val1 < val2 && bCVG > val1) || (val2 < val1 && bCVG > val2))) {
                setIsLimitExceed(true);
            }
            if (cvgType === 'CONTENT' && cCVG !== '' && cCVG > 100000) {
                setIsLimitExceed(true)
            }
        }

        var emptyQuote = emptyQuoteData;
        if(cvgType === 'BUILDING' && emptyQuote['propertyInfo']['s_IsNewPurchase'] == 'YES' && emptyQuote['propertyInfo']['isSellerDec'] == 'YES' && bCVG == 0){
            Swal.fire({
                title: 'Warning',
                text: t("Annual cap increase discount is not available for policies without building coverage"),
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continue',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    emptyQuote['propertyCoverageInfo']['n_BuildingCvg'] = bCVG;
                    setBCVG(bCVG);
                    isDataChanged(true); 
                    dispatch(getEditEmptyQuoteData(emptyQuote));
                    return false;
                }
              });
        }
    }

    // update state
    const handleStateChange = (value, state) => {
        if (!value) {
            value = "0";
        }
        var emptyQuote = emptyQuoteData;

        switch (state) {
            case 'RC':
                emptyQuote['propertyInfo']['n_ReplacementCost'] = value;
                setRC(value);
                break;
            case 'RCR':
                emptyQuote['propertyInfo']['n_ReplacementCostRatio'] = value;
                setRCR(Math.round(value) || 0);
                break;
            case 'bCVG':
                emptyQuote['propertyCoverageInfo']['n_BuildingCvg'] = value;
                setBCVG(value);
                if(value === "0") {
                    emptyQuote['propertyCoverageInfo']['s_BuildingDeductible'] = value;
                    setBDED(value);
                }
                break;
            case 'cCVG':
                emptyQuote['propertyCoverageInfo']['n_ContentCvg'] = value;
                setCCVG(value);
                if(value === 0) {
                    emptyQuote['propertyCoverageInfo']['s_ContentDeductible'] = value;
                    setCDED(value);
                }
                break;
            case 'bDED':
                if (bCVG === 0) {
                    value = "0";
                }
                if(bCVG > 0 && pivotOutage === 'true') {
                    value = "FLDDED2000";
                }
                emptyQuote['propertyCoverageInfo']['s_BuildingDeductible'] = value;
                setBDED(value);
                break;
            case 'cDED':
                if (cCVG === 0) {
                    value = "0";
                }
                if (cCVG > 0 && pivotOutage === 'true') {
                    value = "FLDDED2000";
                }
                emptyQuote['propertyCoverageInfo']['s_ContentDeductible'] = value;
                setCDED(value);
                break;
            default:
                break;
        }
        if(isChange){
            isDataChanged(true);
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    return (
        <Form validated={validated}>
            {
                // Warning Info Array
                showInfo && (
                    info.length > 0 && info.map((x, i) => {
                        return (
                            <Col key={i} md="12" className='p-0'>
                                <Alert color={'warning'} isOpen={showInfo} className='p-1 pl-2 m-0 mb-1'>{x}</Alert>
                            </Col>
                        );
                    })
                )
            }
            <Row className="align-items-end">
                <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA', fontStyle: 'italic' }} > {t('Coverage amounts must be set in increments of $1,000')} </p>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Replacement Cost')}
                        </Form.Label>
                        <NumberFormat
                            className="dashboard-group-input-flood"
                            style={{ width: '100%', textAlign: "right", backgroundColor: isBind ? '#E9ECEF' : '#FFFFFF' }}
                            type="text"
                            displayType={'input'}
                            prefix={'$'}
                            thousandSeparator={true}
                            allowNegative={false}
                            name="n_ReplacementCost"
                            title={t('Replacement Cost')}
                            parentobject="propertyCoverageInfo"
                            value={RC || 0}
                            min={0}
                            onValueChange={(value) => {handleStateChange(value.floatValue, 'RC');setIsChange(true)}}
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="1">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Replacement Cost Ratio")}
                        </Form.Label>
                        <Form.Control
                            className="dashboard-group-input-flood"
                            style={{ textAlign: "right" }}
                            type="text"
                            placeholder={t("Replacement Cost Ratio")}
                            name="n_ReplacementCostRatio"
                            title={t('Ratio')}
                            parentobject="propertyCoverageInfo"
                            value={RCR + '%'}
                            onChange={(e) => {
                                handleChange(e);
                                setRCR(e.currentTarget.value);
                            }}
                            required
                            autoComplete="off"
                            readOnly
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t("Building Coverage Amount")}
                        </Form.Label>
                        <NumberFormat
                            className="dashboard-group-input-flood"
                            style={{ width: '100%', textAlign: "right", backgroundColor: isBind ? '#E9ECEF' : '#FFFFFF' }}
                            type="text"
                            displayType={'input'}
                            prefix={'$'}
                            thousandSeparator={true}
                            allowNegative={false}
                            name="n_BuildingCvg"
                            title={t('Building Coverage Amount')}
                            parentobject="propertyCoverageInfo"
                            value={bCVG}
                            min={0}
                            onValueChange={(value) => handleStateChange(value.floatValue, 'bCVG')}
                            onBlur={() => validateCoverage('BUILDING')}
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Building Deductible')}
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_BuildingDeductible"
                            title={t('Building Deductible')}
                            parentobject="propertyCoverageInfo"
                            value={bDED}
                            onChange={(e) => handleStateChange(e.currentTarget.value, 'bDED')}
                            required
                            disabled={isBind || (pivotOutage === 'true')}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                bDedDD.length > 0 && bDedDD.map((v, i) => {
                                    return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Content Coverage Amount')}
                        </Form.Label>
                        <NumberFormat
                            className="dashboard-group-input-flood"
                            style={{ width: '100%', textAlign: "right", backgroundColor: isBind ? '#E9ECEF' : '#FFFFFF' }}
                            type="text"
                            displayType={'input'}
                            prefix={'$'}
                            thousandSeparator={true}
                            allowNegative={false}
                            name="n_ContentCvg"
                            title={t('Content Coverage Amount')}
                            parentobject="propertyCoverageInfo"
                            value={cCVG}
                            min={0}
                            onValueChange={(value) => handleStateChange(value.floatValue, 'cCVG')}
                            onBlur={() => validateCoverage('CONTENT')}
                            autoComplete="off"
                            disabled={isBind}
                        />
                    </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="6" lg="2" xl="2">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Content Deductible')}
                        </Form.Label>
                        <Form.Select
                            className="table-show-entries-select"
                            id="select-ym-option"
                            name="s_ContentDeductible"
                            title={t('Content Deductible')}
                            parentobject="propertyCoverageInfo"
                            value={cDED}
                            onChange={(e) => handleStateChange(e.currentTarget.value, 'cDED')}
                            required
                            disabled={isBind || (pivotOutage === 'true')}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                cDedDD.length > 0 && cDedDD.map((v, i) => {
                                    return <option key={i} value={v.s_DiscountCode}>{v.s_ScreenName}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default Coverage