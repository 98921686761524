const getCommercialOwnedRequestBody = ({
  constructionDetails,
  locationQuoteObject,
  financialDetails,
  applicantDetails,
  additionalInsured,
  mortgageeDetails,
  mailingAddress,
  isMailingAddressSame,
  manualEntry,
}) => {
  return {
    commercialProductType: constructionDetails?.commercialProductType,
    productType: constructionDetails?.productType,
    addressLine1:
      locationQuoteObject?.streetNumber !== "" &&
      locationQuoteObject?.streetName !== ""
        ? locationQuoteObject?.streetNumber +
          " " +
          locationQuoteObject?.streetName
        : locationQuoteObject?.addressLine,
    addressLine2: locationQuoteObject?.city,
    addressLine3: "",
    county: locationQuoteObject?.countyName,
    stateCode: locationQuoteObject?.stateCode,
    zip: locationQuoteObject?.postalCode,
    latitude: manualEntry ? Number(locationQuoteObject?.latitude || 0) : 0,
    longitude: manualEntry ? Number(locationQuoteObject?.longitude || 0) : 0,
    occupancyType: constructionDetails?.occupancyType,
    constructionType: constructionDetails?.constructionType,
    buildingReplacementCostValues:
      financialDetails?.commercial?.owned?.replacementCostValues?.building,
    contentsReplacementCostValues:
      financialDetails?.commercial?.owned?.replacementCostValues?.contents,

    residentialLimits: financialDetails?.commercial?.owned?.limits,
    businessIncomeAndExtraExpenseAnnualValue:
      Number(
        financialDetails?.commercial?.businessIncomeAndExtraExpenseAnnualValue
      ) || 0,
    stockReplacementCostValue:
      Number(financialDetails?.commercial?.stockReplacementCostValue) || 0,

    contentsCostValueType: financialDetails?.contentsCostValueType,
    foundationType: constructionDetails?.foundationType,
    additionalFoundationType: constructionDetails?.additionalFondationType,
    basementType: constructionDetails?.basementType,
    attachedGarageType: constructionDetails?.attachedGarageType,
    yearBuilt: constructionDetails?.yearBuilt,
    squareFootage: constructionDetails?.squareFootage,
    numberOfStories: constructionDetails?.noOfStories,
    elevationHeight: Number(constructionDetails?.elevationHeight) || 0,
    buildingOverWaterType: constructionDetails?.buildingWaterType,
    priorLosses: financialDetails?.priorLosses,
    firstName:
      applicantDetails?.propertyType === "individual"
        ? applicantDetails?.firstName
        : "",
    lastName:
      applicantDetails?.propertyType === "individual"
        ? applicantDetails?.lastName
        : applicantDetails?.organisationName,
    phoneNumber: applicantDetails?.contactNo,
    email: applicantDetails?.email,
    propertyType: applicantDetails?.propertyType,
    effectiveDate: constructionDetails?.effectiveDate,
    floodZone: constructionDetails?.floodZone,
    isMailingAddressSame: isMailingAddressSame,
    mailingAddress: mailingAddress?.mailingAddressData,
    mailingAddressAddressLine2: mailingAddress?.addressLine,
    mailingAddressCounty: mailingAddress?.s_CountyName,
    mailingAddressCity: mailingAddress?.s_CityName,
    mailingAddressState: mailingAddress?.s_StateName,
    mailingAddressZipCode: parseInt(mailingAddress?.s_ZipCode),
    mortgageeData: mortgageeDetails,
    additionalInsuredData: additionalInsured,
    doesPolicyHaveAdditionalInsured:
      Array.isArray(additionalInsured) && additionalInsured.length > 0
        ? "YES"
        : "NO",
    doesPolicyHaveMortgagee:
      Array.isArray(mortgageeDetails) && mortgageeDetails.length > 0
        ? "YES"
        : "NO",
  };
};

export default getCommercialOwnedRequestBody;
