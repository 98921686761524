import React, { useState } from 'react'
import { Box, FormControl, FormLabel, HStack, InputGroup, InputLeftElement, Input, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

export default function PolicyNumber({ onPolicyNoChange }) {
  const { t } = useTranslation();
  const [policyNo, setPolicyNo] = useState("");

  const policyDetails = (event) => {
    event.preventDefault();
    setPolicyNo(policyNo);
    onPolicyNoChange(policyNo);
  };

  return (
    <>
      <Box
        bgColor={"#010E1E"}
        color={"white"}
        p={"1.91rem"}
        boxShadow={"lg"}
        borderRadius={"2xl"}
        bgImage={"/assets/images/docCenterSearch.svg"}
        bgRepeat={"no-repeat"}
        bgPos={"right"}
        bgSize={"12rem"}
      >
        <FormControl>
          <FormLabel fontSize={"2xl"} fontWeight={600}>
            {t("Policy Number")}
          </FormLabel>
          <HStack>
            <InputGroup>
              <InputLeftElement pt={2} pointerEvents="none">
                <FaSearch />
              </InputLeftElement>
              <Input
                bgColor={"gray.800"}
                borderColor={"transparent"}
                borderRadius={"2xl"}
                size="lg"
                htmlSize={20}
                width="auto"
                type="text"
                placeholder={t("Policy Number")}
                autoComplete="off"
                value={policyNo}
                onChange={(event) => setPolicyNo(event.target.value)}
              />
              <Box pl={2}>
                <Button
                  leftIcon={<FaSearch />}
                  p={6}
                  fontWeight={400}
                  borderRadius={"2xl"}
                  type="submit"
                  onClick={(event) => policyDetails(event)}
                  isDisabled={policyNo.trim() === ""}
                >
                  {t("Search")}
                </Button>
              </Box>
            </InputGroup>
          </HStack>
        </FormControl>
      </Box>
    </>
  )
}
