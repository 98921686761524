import { Tooltip, Badge, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formateDate } from "../../common/FormatDate";

function AL3Submission({ submissionDate, submissionStatus }) {
    const { t } = useTranslation();

    var badgeColor = '';
    switch (submissionStatus) {
        case "Approved":
            badgeColor = 'green';
            break;
        case "Pending":
            badgeColor = 'orange';
            break;
        case "Declined":
            badgeColor = 'red';
            break;
        default:
            submissionStatus = t('Not Available')
            break;
    }
    const displaySubmissionDate =
        submissionDate === undefined ||
        submissionDate === "0000-00-00" ||
        submissionDate === ""
        ? t("Not Available")
        : formateDate(submissionDate);
    return (
        <Text fontSize={"lg"} mb={0} fontWeight={600}>
            {t("AL3 Submission")} :{" "}
            <Tooltip hasArrow label={"Submitted Date : " + displaySubmissionDate}>
                <Badge colorScheme={badgeColor}>{t(submissionStatus)}</Badge>
            </Tooltip>
        </Text>
    );
}

AL3Submission.propTypes = {
    submissionDate: PropTypes.string.isRequired,
    submissionStatus: PropTypes.string.isRequired,
};

export default AL3Submission;
