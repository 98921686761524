import React, { Fragment } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

const ContactTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>FAQs</span>
      </div>
      <div className="custom-tab-body">
        <div className="faq-tab-accordion">
          <Row>
            <Col xs="12" sm="12" md="12" lg="10" xl="10">
              <Row>
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      First Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="First name"
                    />
                  </Form.Group>
                </Col>

                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Last Name
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Last name"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Email address"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="6" xl="6">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Mailing Address
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      type="text"
                      placeholder="Mailling address"
                    />
                  </Form.Group>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <Form.Group
                    className="contact-tab-form-group"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="dashboard-group-label">
                      Message
                    </Form.Label>
                    <Form.Control
                      className="dashboard-group-input"
                      as="textarea"
                      placeholder="Message"
                    />
                  </Form.Group>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    id="common-btn"
                    variant="primary"
                    style={{ padding: "10px 30px 10px 30px" }}
                  >
                    Submit
                  </Button>{" "}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactTab;
