import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ListAttachment from "../Common/Attachment/ListAttachment";
import { FaRegFileImage } from "react-icons/fa";

export default function DocumentModal(props) {
  const [showModal, setShowModal] = useState(false);
  const { policyNumber, policyPK } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Button
        className="px-2 py-1"
        id="common-btn"
        variant="primary"
        size="sm"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <FaRegFileImage size={18} /> &nbsp; {t("Documents")}
      </Button>
      <Modal
        size="xl"
        show={showModal}
        className="custom-dashboard-modal"
        onHide={() => {
          setShowModal(!showModal);
        }}
      >
        <Modal.Header closeButton className="custom-dashboard-modal-header">
          <Modal.Title>{t("Attachment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="custom-dashboard-modal-body p-2"
          style={{ padding: 0 }}
        >
          <ListAttachment policyNumber={policyNumber} policyPK={policyPK} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
