import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Container,Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import delIcon from "../../../../assets/delete-icon.svg";
import "./Claiminformation.scss";
import { toast } from 'react-toastify';
const ClaimInformation = (props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const [showTable, setShowTable] = useState(false);
    const [inputFields, setInputFields] = useState(props.emptyQuote.lossHistoryData['lossHistoryData']);
    const [validated, setValidated] = React.useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [msgError, setMsgError] = useState("");

    var today = new Date();
    var maxDate = new Date(today.setDate(today.getDate())).toISOString().split("T")[0];
    useEffect(() => {
      props.setDefaultData('lossHistoryData', inputFields);
    }, [inputFields]);

    const toggleValidate = (e) => {
      setValidated(e);
    }
    const handleAddFields = () => {
      const values = [...inputFields];
      values.push({
        d_LossDate: "",
        s_LossType: "",
        s_LossDescription: "",
        n_LossAmount: "",
      });
      setInputFields(values);
    };

    const handleRemoveFields = (index) => {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    };

    const handleInputChange = (index, event) => {
      const values = [...inputFields];
      const updatedValue = event.target.name;
      values[index][updatedValue] = event.target.value;
      setInputFields(values);
    };

    const handleDateChange = (index, date) => {
      const values = [...inputFields];
      values[index].d_LossDate = date;

      setInputFields(values);
    };

    const handleSwitchChange = (e) => {
      setShowTable(!showTable);
      props.handleSwitchChange(e);
    }

      const handleOnclick = (step) => {
          if(step == '7'){
              navigation('/instaQuote/InstaSteps/' + step)
            } else {
              if (props.PriorClaimCount == 1 && props.emptyQuote.lossHistoryData['lossHistoryData'].length == 0) {
                toast.error(t('Please Add Claim Information!'));
                return false;
              } else {
                  var status = 0;
                  props.emptyQuote.lossHistoryData['lossHistoryData'].map((item) => {
                    if (item.d_LossDate == '' || item.n_LossAmount == '' || item.s_LossDescription == '' || item.s_LossType == '') {
                      status = 1;
                    } else {
                      status = 0;
                    }
                  })
                  if (status == 1) {
                    setValidated(true);
                  } else {
                    setValidated(false);
                    navigation('/instaQuote/InstaSteps/' + step);
                  }
              }
            }
      }

      return(
        <section className="claim-info-section">
          {/* Background Image */}
          <div className="claim-info-bg">
            <div className="claim-info-house1"></div>
            <div className="claim-info-house2"></div>
          </div>
              
          {/* Form */}
          <Container className="main-content-section">
            <Row className="justify-content-center">
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="8"
                xl="8"
                className="claim-info-form-section"
              >
                <Row className="justify-content-center">
                  {/* <Col xs="12" className="claim-info-actions-btns">
                    <Steps step={8} {...props} toggle={toggleValidate} />
                  </Col> */}
                  <Form validated={validated}>
                    <Row className="justify-content-center">
                      <Col xs="12" className="claim-info-form-main">
                        <div className="claim-headings">
                          <h3>{t('Claim Information if any')}</h3>
                        </div>
                        <div className="claim-form">
                          <div className="claim-card-row">
                            <p>{t('Have you had any prior claim')}?</p>
                            <div className="property-form-actions">
                              <span>{t('No')}</span>
                              <div className="custom-main-swtich">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  className="property-form-switch"
                                  label=""
                                  name="n_PriorClaimCount"
                                  parentobject="propertyInfo"
                                  onChange={handleSwitchChange}
                                  checkedvalue='1'
                                  uncheckedvalue='0'
                                  checked={props.PriorClaimCount === '1' ? true : false}
                                />
                              </div>
                              <span>{t('Yes')}</span>
                            </div>
                          </div>
                          {props.PriorClaimCount === '1' && (
                            <Fragment>
                              <div className="claim-add-btn">
                                <Button
                                  id="primary-colored-btn"
                                  variant="primary"
                                  className="mybtn"
                                  onClick={() => handleAddFields()}
                                >
                                  {" "}
                                  <span> + </span> {t('Add')}
                                </Button>
                              </div>
                              <div className="claim-table-div">
                                <Table className="claim-table" borderless responsive>
                                  <thead>
                                    <tr>
                                      <th style={{ width: "20%" }}>{t('Last date')}</th>
                                      <th style={{ width: "20%" }}>{t('Loss Type')}</th>
                                      <th style={{ width: "40%", minWidth: '180px' }}>{t('Loss Desc')}.</th>
                                      <th style={{ width: "20%" }}>{t('Loss Amt')}</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {inputFields.length > 0 && (
                                      <>
                                        {inputFields.map((field, index) => (
                                          <tr key={index}>
                                            <td>
                                              {/* <DatePicker
                                              className="custom-info-date-picker"
                                              id='claim-table-datepicker'
                                              placeholderText="MM/DD/YYYY"
                                              selected={field.d_LossDate}
                                              onChange={(date) =>
                                                handleDateChange(index, date)
                                              }
                                              onKeyDown={(e) => {
                                                e.preventDefault();
                                              }}
                                            /> */}
                                              <Form.Group
                                                className="property-info-field"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Control
                                                  type="date"
                                                  placeholder={t("Enter Date")}
                                                  // name="d_PriorExpDate"
                                                  // title="policyInfo"
                                                  value={field.d_LossDate ? field.d_LossDate : ''}
                                                  onKeyDown={(e) => {
                                                    e.preventDefault();
                                                  }}
                                                  onChange={(e) =>
                                                    handleDateChange(index, e.target.value)
                                                  }
                                                  max={maxDate}
                                                  required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {t("Please Add Last date")}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </td>
                                            <td>
                                              <Form.Group
                                                className="property-info-field"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Select
                                                  className="myform-input"
                                                  name="s_LossType"
                                                  value={field.s_LossType}
                                                  onChange={(event) =>
                                                    handleInputChange(index, event)
                                                  }
                                                  required
                                                >
                                                  <option value="">{t('Select')}</option>
                                                  <option value="LIABILITY">
                                                    {t('Liability')}
                                                  </option>
                                                  <option value="PROPERTY">
                                                    {t('Property')}
                                                  </option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                  {t('Please Add Lose Type Error')}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </td>
                                            <td>
                                              <Form.Group>
                                                <Form.Control
                                                  className="myform-input"
                                                  type="text"
                                                  name="s_LossDescription"
                                                  placeholder={`${t("Loss Desc")}.`}
                                                  value={field.s_LossDescription}
                                                  onChange={(event) =>
                                                    handleInputChange(index, event)
                                                  }
                                                  required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {t('Please Add Loss Desc Error')}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </td>
                                            <td>
                                              <Form.Group>
                                                <Form.Control
                                                  className="myform-input"
                                                  type="text"
                                                  name="n_LossAmount"
                                                  placeholder={t("Loss Amt")}
                                                  value={field.n_LossAmount}
                                                  onChange={(event) =>
                                                    handleInputChange(index, event)
                                                  }
                                                  required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                  {t('Please Add Lose Amt Error')}
                                                </Form.Control.Feedback>
                                              </Form.Group>
                                            </td>
                                            <td className="tr-action-row">
                                              <div className="tr-action-btns">
                                                <img
                                                  src={delIcon}
                                                  onClick={() =>
                                                    handleRemoveFields(index)
                                                  }
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                              <div className="claim-card-row">
                                <p>{t('Have these claims been repaired')}?</p>
                                <div className="property-form-actions">
                                  <span>{t('No')}</span>
                                  <div className="custom-main-swtich">
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      className="property-form-switch"
                                      label=""
                                    />
                                  </div>
                                  <span>{t('Yes')}</span>
                                </div>
                              </div>
                            </Fragment>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div className="flood-bts-div">
                    <Button
                        id="primary-grey-btn-flood"
                        variant="outline-dark"
                        onClick={() => handleOnclick('7')}
                    >
                        {t('Previous')}
                    </Button>
                    <Button
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={() => handleOnclick('9')}
                    >
                        {t('Next')}
                    </Button>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      )
}

function mapStateToProps(state) {
    return {
        PriorClaimCount: state.common.emptyInstantQuoteData.propertyInfo ? String(state.common.emptyInstantQuoteData.propertyInfo.n_PriorClaimCount) : '0',
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
        s_PriorPolicyNo : state.common.emptyInstantQuoteData.policyInfo.s_PriorPolicyNo,
        s_PriorCarrierName : state.common.emptyInstantQuoteData.policyInfo.s_PriorCarrierName,
        d_PriorExpDate : state.common.emptyInstantQuoteData.policyInfo.d_PriorExpDate ? state.common.emptyInstantQuoteData.policyInfo.d_PriorExpDate : "",
        uwData : state.common.emptyInstantQuoteData.uwData.priorPolicyQuestionInfo.s_PoAdditionalStatsAnswer ? state.common.emptyInstantQuoteData.uwData.priorPolicyQuestionInfo.s_PoAdditionalStatsAnswer : "NO",
    };
} 

export default connect(mapStateToProps)(ClaimInformation);