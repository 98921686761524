import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { useDispatch } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { FaUpload, FaFileUpload, FaPlus, FaTimes, FaWindowClose, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { getDocumentList, uploadDocument, viewDocument } from '../../../../../services/floodService';
import Moment from 'moment';
import "react-overlay-loader/styles.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ListDocument = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { poTransPK, toggle, show } = props;
  
  const maxNumberOfFiles = 5;
  const [loading, setLoading] = useState(false);
  const [docList, setDocList] = useState([]);
  const [viewAttachment, setViewAttachment] = useState(false);
  const [docData, setDocData] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isFileSelected, setIsFileSelected] = useState(true);
  const [docCount, setDocCount] = useState(0);
  const [isDocPhoto, setIsDocPhoto] = useState(false);
  const [isMaxFileCountExcided, setIsMaxFileCountExcided] = useState(false);

  useEffect(() => {
    getRequiredDocuments();
  }, [])

  useEffect(() => {
    filterDocList();
  }, [docList])

  // Get List
  const getRequiredDocuments = async () => {
    setLoading(true);
    await dispatch(getDocumentList(poTransPK)).then((res) => {
      setDocList(res.data);
      setLoading(false);
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
      setLoading(false);
    })
  }

  /**
   * filter document list for Elevation Certificate & EC Photos
   */
  const filterDocList = () => {
    let data = docList.filter(x => (x.s_SourceCode === "ECDOCUMENT" || x.s_SourceCode === "ECPHOTOS"));
    var ECphoto = data.filter(x => (x.s_SourceCode === "ECPHOTOS"));
    if (ECphoto && ECphoto.length === 1) {
      setIsDocPhoto(true);
    }
    setDocCount(data.length);
  }

  // on drop file
  const onDrop = useCallback(event => {
    if (event.length <= maxNumberOfFiles) {
      setIsMaxFileCountExcided(false);
      setSelectedFiles(event);
    }
    else {
      setIsMaxFileCountExcided(true);
    }
  }, [])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, multiple: isDocPhoto });

  // display file name 
  var files = acceptedFiles.map((file, i) => (
    <li key={file.path}>
      {file.name} &nbsp;&nbsp;<FaTimes size={15} onClick={() => removeFile(i)} />
    </li>
  ));

  // to delete file
  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    acceptedFiles.splice(file, 1);
    setSelectedFiles(newFiles);
    if (newFiles.length <= maxNumberOfFiles) {
      setIsMaxFileCountExcided(false);
    }
  };

  // reset state
  const resetAttachmentState = () => {
    setDocData({});
    setSelectedFiles([]);
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const uploadOrViewFile = (cell, row) => {
    let action = 'view', view = <FaEye size={20} />;
    if (row.s_IsAttached === 'N') {
      action = 'upload';
      view = <FaUpload size={15} />
    }

    return <Button
      variant="light"
      id="search-quote-btn"
      onClick={() => handleOnClick(row, action)}
    >
      {view}
    </Button>
  }

  const handleOnClick = async (row, action) => {
    if (action == 'view') {
      await getTempUrl(row.n_PoDocumenttrackTrans_PK);
      return false;
    }
    setIsDocPhoto(row.s_SourceCode === "ECPHOTOS");
    setDocData(row);
    setViewAttachment(!viewAttachment);
  }

  const handleCloseAttachment = () => {
    resetAttachmentState();
    setViewAttachment(!viewAttachment);
  }

  // validation
  const validate = () => {
    var isValid = true;
    if (selectedFiles === []) {
      toast.error(t('Please Select File'))
      isValid = false;
      setIsFileSelected(false);
    }
    return isValid;
  };

  // submit form
  const handleSubmit = async () => {
    const isValid = validate();
    if (isValid) {
      const formData = new FormData();
      selectedFiles.forEach(element => {
        formData.append("files[]", element);
      });
      formData.append('docName', docData.s_SourceName);
      formData.append("docType", docData.s_SourceCode);
      formData.append('docTrackTransFK', docData.n_PoDocumenttrackTrans_PK);

      setLoading(true);
      await dispatch(uploadDocument(formData)).then((res) => {
        toast.success(t(res.msg));
        handleCloseAttachment();
        getRequiredDocuments();
        if (res.status === 'Y') {
          props.updateReqDocCount();
        }
      }).catch(() => {
        toast.error(t('Something Went Wrong!'));
      })
      setLoading(false);
    }
  };

  // get temp url
  const getTempUrl = async (poDocumenttrackTransPK) => {
    setLoading(true);
    await dispatch(viewDocument({ "n_PoDocumenttrackTrans_PK": poDocumenttrackTransPK })).then((res) => {
      if (res.status && res.status == 'N') {
        toast.error(res.message);
      }
      if (res.status && res.status == 'Y') {
        window.open(res.url);
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
    setLoading(false);
  }

  const columns = [
    {
      dataField: "n_PoDocumenttrackTrans_PK",
      hidden: true
    },
    {
      dataField: "s_SourceName",
      text: t("Name"),
    },
    {
      dataField: "s_IsAttached",
      text: t("Is Attached"),
    },
    {
      dataField: "",
      text: t("Action"),
      formatter: uploadOrViewFile
    }
  ];

  return (
    <Modal
      className="custom-dashboard-modal"
      size="lg"
      show={show}
    >
      <Modal.Header closeButton className="custom-dashboard-modal-header" onClick={() => { !loading && toggle(!show) }}>
        <Modal.Title>Document List</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-dashboard-modal-body">
        <LoadingOverlay >
          <Loader loading={loading} />
          {
            // 
            docCount === 1 && (
              <Row>
                <Col>
                  <label style={{ color: "red" }}>
                    *Please Attach Property Photos Along with the Elevation Certificate in PDF.
                  </label>
                </Col>
              </Row>
            )
          }
          <Row style={{ paddingBottom: "1rem", paddingTop: "1rem" }}>
            {
              !viewAttachment && (
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                  <BootstrapTable
                    noDataIndication='No Data'
                    wrapperClasses="dashboard-table-main"
                    bootstrap4
                    keyField="n_PoDocumenttrackTrans_PK"
                    data={docList}
                    columns={columns}
                    hover
                  />
                </Col>
              )
            }
            {
              viewAttachment && (
                <>
                  <span className="card-header-main-flood">
                    <Button
                      id="common-outline-btn-flood"
                      variant="outline-primary"
                      style={{ float: 'right' }}
                      onClick={handleCloseAttachment}
                      disabled={loading}
                    >
                      {t('Close')} <FaWindowClose size={20} />
                    </Button>
                  </span>
                  <div
                    id="drop-document-type-div"
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input {...getInputProps()} />
                    <FaFileUpload size={34} color={"#64D6CD"} />
                    <p>{t('Drag and Drop File Here')}</p>
                    <Button id="common-btn" variant="primary">
                      <FaPlus size={15} />
                      {t('Add File')}
                    </Button>{" "}
                  </div>

                  <aside className="reply-document-name">
                    <ul>{files}</ul>
                  </aside>
                  {!isFileSelected && (
                    <aside className="reply-document-name" style={{ color: 'red' }}>
                      <ul>{t('Please Select File')}</ul>
                    </aside>
                  )}
                  {
                    isMaxFileCountExcided && (
                      <aside className="reply-document-name" style={{ color: 'red' }}>
                        <ul>Max 5 files are Allowed to upload!</ul>
                      </aside>
                    )
                  }
                  <div className="card-header-main-flood">
                    <Button
                      id="common-outline-btn-flood"
                      variant="outline-primary"
                      onClick={() => handleSubmit()}
                      disabled={isMaxFileCountExcided || loading}
                    >
                      {t('Submit')}
                    </Button>{" "}

                  </div>
                </>
              )
            }
          </Row>
        </LoadingOverlay>
      </Modal.Body>
    </Modal>
  )
}

export default ListDocument;