import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FcInfo } from "react-icons/fc";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";
import {
  aopDed,
  bbbRatingFields,
  bildingCoverageSection,
  buildingInterpetionsFields,
  buissnessClassFields,
  burglarAlarmFields,
  elevatorSafetyFields,
  fireAlarmFields,
  latestClosingHoursFields,
  lossesLastFiveYearsFields,
  maintainenceFields,
  petsAndAnimalsFields,
  plumbingUpdatesFields,
  restorationFactorFields,
  roofCoverings,
  roofDeckAttachements,
  roofGeometrys,
  roofingUpdatesFields,
  roofWallAnchorages,
  securityServicesFields,
  smokingFields,
  theftDeductibleFields,
  threeYearLostCountFields,
  waterSublimitFields,
  wiringUpdatesFields,
} from "../data/commercialDD";
import { useAutoZipSelection } from "../../../create-star-quote/utils/useAutoZipDetection";
import LocationSelectionModal from "../../../common/LocationSelectionModal";
import { cloneDeep, set } from "lodash";
import { starCommercialContext } from "../CommercialContext";

const NewLocation = React.memo(
  ({
    quoteData,
    id = 1,
    commercialData,
    location,
    setCommercialData,
    isBind,
  }) => {
    const { t } = useTranslation();
    const [zipTriggered, setZipTriggered] = useState(false);
    const { quoteNumber } = useContext(starCommercialContext);
    const handleChange = (e, index) => {
      const { name, value } = e.target;
      if (index >= 0) {
        setCommercialData((prev) => {
          const newState = cloneDeep(prev);
          set(newState, `propertyDetail[${index}].${name}`, value);
          return newState;
        });
      }
    };

    const [zipData, setZipData] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const { selectedLocation, showModal, isLoading } = useAutoZipSelection(
      location?.zip,
      zipTriggered || !quoteNumber
    );
    useEffect(() => {
      if (typeof selectedLocation === "object" && selectedLocation !== null) {
        setZipData(selectedLocation);
      }
      if (showModal) {
        setOpenModal(true);
      }
    }, [selectedLocation, showModal]);

    useEffect(() => {
      if (zipData && Object.keys(zipData).length > 0) {
        setCommercialData((prevData) => ({
          ...prevData,
          propertyDetail: prevData.propertyDetail.map((detail, index) =>
            index === id
              ? {
                  ...detail,
                  city: zipData?.s_CityName || "",
                  state: zipData?.s_StateCode || "",
                  cityId: zipData.city_id ?? 0,
                  stateId: zipData.state_id ?? 0,
                  countryId: zipData.n_CountryId_FK ?? 1,
                  countyId: zipData.county_id ?? 0,
                  county: zipData.s_CountyName ?? "",
                }
              : detail
          ),
        }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zipData, id]);

    const handleValueChange = (value, name, index) => {
      const updatedPropertyDetails = [...commercialData.propertyDetail];
      if (index >= 0 && index < updatedPropertyDetails.length) {
        const updatedProperty = {
          ...updatedPropertyDetails[index],
          [name]: value,
        };
        updatedPropertyDetails[index] = updatedProperty;
      }
      setCommercialData((prev) => ({
        ...prev,
        propertyDetail: updatedPropertyDetails,
      }));
    };

    const coverageFields = [
      {
        name: "buildingCoverage",
        label: "Building Coverage",
        visible: true,
        isSelect: false,
      },
      {
        name: "contentCoverage",
        label: "Buissness Personal Property",
        isSelect: false,
        visible: true,
      },
      {
        name: "buissnessIncomeAndExtraExpenses",
        label: "Buissness Income and Extra Expenses",
        isSelect: false,
        visible: true,
      },
      {
        name: "buissnessInterruptionWaitingPeriod",
        label: "Buissness Interruption Waiting Period",
        isSelect: true,
        visible: true,
      },
      {
        name: "restorationFactor",
        label: "Restoration Factor",
        isSelect: true,
        visible: true,
      },
      {
        name: "canopy",
        label: "Canopy",
        isSelect: false,
        visible: location?.buissnessClass?.includes("Convenience"),
      },
      {
        name: "pumps",
        label: "Pumps",
        isSelect: false,
        visible: location?.buissnessClass?.includes("Convenience"),
      },
      {
        name: "sign",
        label: "Sign",
        isSelect: false,
        visible: location?.buissnessClass?.includes("Convenience"),
      },
      {
        name: "aopDeductible",
        label: "All-Perils Deductible",
        isSelect: true,
        behaviour: "custom",
        dropDown: aopDed,
        dropDownLabelValue: true,
        visible: true,
      },
      // {
      //   name: "s_HurricaneDedutible",
      //   label: "Hurricane Deductible",
      //   isSelect: true,
      //   behaviour: "custom",
      //   dropDown: [...Array(10).keys()]
      //     .map((i) => i + 1)
      //     .map((percentage) => `${percentage}%`),
      // },
      {
        name: "windHailDeductible",
        label: "Wind/Hail Deductible",
        isSelect: true,
        behaviour: "custom",
        dropDown: [...Array(10).keys()]
          .map((i) => i + 1)
          .map((percentage) => `${percentage}%`),
        visible: true,
      },
     
      {
        name: "theftDeductible",
        label: "Theft Deductible",
        isSelect: true,
        behaviour: "custom",
        dropDown: theftDeductibleFields,
        visible: true,
      },
      {
        name: "waterSublimit",
        label: "Water Sublimit",
        isSelect: true,
        behaviour: "custom",
        dropDown: waterSublimitFields,
        visible: true,
      },
      {
        name: "theftExclusion",
        label: "Theft Exclusion",
        isSelect: true,
        behaviour: "custom",
        dropDown: ["YES", "NO"],
        visible: true,
      },

      // {
      //   name: "windStormAndHailCvgExclusion",
      //   label: "Windstorm and Hail Exclusion",
      //   isSelect: true,
      //   behaviour: "custom",
      //   dropDown: ["YES", "NO"],
      // },
    ];

    const handleCopyAndDuplicate = () => {
      navigator.clipboard.writeText(
        JSON.stringify(commercialData.propertyDetail[id])
      );
      setCommercialData((prev) => ({
        ...prev,
        propertyDetail: [
          ...prev.propertyDetail,
          commercialData.propertyDetail[id],
        ],
      }));
    };

    return (
      <Box opacity={isLoading ? 0.5 : 1}>
        <LocationSelectionModal
          isOpen={openModal}
          selectedLocation={selectedLocation}
          setOpenModal={setOpenModal}
          setZipData={setZipData}
        />
        <Card p={4} borderRadius="xl" border="1px solid" borderColor="gray.200">
          <Grid
            templateColumns={"repeat(auto-fit, minmax(300px, 1fr))"}
            gap={{ base: "0.75rem", md: "1.5rem", lg: "1.5rem" }}
          >
            <GridItem>
              <FormControl>
                <FormLabel whiteSpace={"nowrap"}>
                  {t("Location Identifier")}:
                </FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="locationIdentifier"
                  defaultValue={location?.locationIdentifier}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>{t("Location Address")}:</FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="address"
                  defaultValue={location?.address}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Location Zip Code")}:</FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="number"
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                  }
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  name="zip"
                  defaultValue={location?.zip}
                  onBlur={(e) => {
                    if (e.target.value.length <= 5) {
                      handleChange(e, id);
                      setZipTriggered(true);
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Location County")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="county"
                  value={location?.county}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("State")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="state"
                  value={location?.state}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("City")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="city"
                  value={location?.city}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Territory")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="territory"
                  defaultValue={location?.territory}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("DTC Category")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="dtcCatogory"
                  defaultValue={location?.dtcCatogory}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Industry")}:</FormLabel>
                <Input
                  h={"45px"}
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  border={"2px solid"}
                  borderColor={"gray.300"}
                  borderRadius={"xl"}
                  type="text"
                  name="industry"
                  defaultValue={location?.industry}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Age of Building")}:</FormLabel>
                {/*   based on yearBuilt calculation , todo */}
                <Select
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  className="inputSelectBoxes"
                  name="ageOfBuilding"
                  value={location?.ageOfBuilding}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {[...Array(60)].map(
                    (
                      _,
                      i //temporary till available on backend
                    ) => (
                      <option key={i + 1} value={i + 1}>
                        {i < 59 ? i + 1 : `${i + 1}+`}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Tier Factor")}:</FormLabel>
                <Select
                  isDisabled
                  _disabled={{ bg: "neutral.50" }}
                  className="inputSelectBoxes"
                  name="tierFactor"
                  value={location?.tierFactor}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {[...Array(500).keys()].map((i) => {
                    //Tier factors range from (-149) to 350
                    const tierFactor = i - 149;
                    return (
                      <option key={tierFactor} value={tierFactor}>
                        {tierFactor}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Outdoor Type")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="outdoorType"
                  value={location?.outdoorType}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMPROPERTYTYPE?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>{t("Business Class")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="buissnessClass"
                  value={location?.buissnessClass}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {buissnessClassFields?.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl>
                <FormLabel>{t("SFHA")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="shfa"
                  value={location?.shfa}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem> */}
            {/* <GridItem>
              <FormControl>
                <FormLabel
                  display={"inline-flex"}
                  alignItems={"center"}
                  gap={2}
                >
                  {t("Distance to Coast")}:{" "}
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={t("Location Distance to Coast")}
                  >
                    <Box as="span">
                      {" "}
                      <FcInfo />
                    </Box>
                  </Tooltip>
                </FormLabel>

                <Select
                  className="inputSelectBoxes"
                  name="locationDistanceToCoast"
                  value={location?.locationDistanceToCoast}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {locationDistanceToCoastFields.map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem> */}

            <GridItem>
              <FormControl>
                <FormLabel display={"flex"} flexDirection={"row"}>
                  {t("PPC")}: &nbsp;
                  <FcInfo mt={3} style={{ marginTop: "5px" }} />
                </FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="ppc"
                  value={location?.ppc}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.PROTECTIONCLS?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("BCEG")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="bceg"
                  value={location?.bceg}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.HOBCEGCODE?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Sprinklered")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="sprinklered"
                  value={location?.sprinklered}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Construction Type")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="construction"
                  value={location?.construction}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMMCONSTRTYPE?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Type")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofType"
                  value={location?.roofType}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMMROOFTYPE?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Warranty")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofWarranty"
                  value={location?.roofWarranty}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Geomentry")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofGeomentry"
                  value={location?.roofGeomentry}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {roofGeometrys?.map((type, i) => {
                    return (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Shape")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofShape"
                  value={location?.roofShape}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMMPLROOFSHAPE?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            {/* <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Attachment")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofAttach"
                  value={location?.roofAttach}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMMHOROOFDECKATT?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem> */}
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof-Wall")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofWall"
                  value={location?.roofWall}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {roofWallAnchorages?.map((type, i) => {
                    return (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Year Built")}:</FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="yearBuilt"
                  defaultValue={location?.yearBuilt}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Number of Stories")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="noOfStories"
                  value={location?.noOfStories}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.NOOFUNITESCOMM?.map((type, i) => {
                    return (
                      <option key={i} value={type.s_AppCodeName}>
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Number of Units")}:</FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="noOfUnits"
                  defaultValue={location?.noOfUnits}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Occupancy %")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="occupancy"
                  value={location?.occupancy}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.OCCUPANCYPERCENTNEW?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Opening Protection")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="openingProtection"
                  value={location?.openingProtection}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.HOWINDOWPROTECT?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Cover (FBC)")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofCover"
                  value={location?.roofCover}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {roofCoverings?.map((type, i) => {
                    return (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Support")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofSupport"
                  value={location?.roofSupport}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.COMMHOROOFSUPPORT?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Deck Attachment")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofAttach"
                  value={location?.roofAttach}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {roofDeckAttachements?.map((type, i) => {
                    return (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("SWR")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="swr"
                  value={location?.swr}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Burglar Alarm")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="burglarAlarm"
                  value={location?.burglarAlarm}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {burglarAlarmFields?.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Fire Alarm")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="fireAlarm"
                  value={location?.fireAlarm}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {fireAlarmFields?.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("ACV Roof Surface")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="acvRoofSurface"
                  value={location?.acvRoofSurface}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Converted Structure")}?</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="convertedStructure"
                  value={location?.convertedStructure}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Total Locations")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="totalLocations"
                  value={location?.totalLocations}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {[...Array(10)].map(
                    (
                      _,
                      i //temporary till available on backend
                    ) => (
                      <option key={i + 1} value={i + 1}>
                        {i < 9 ? i + 1 : `${i + 1}+`}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>
                  {t("Distance from the Closest Building (feet)")}:
                </FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="distanceFromTheClosestBuildingInFeet"
                  defaultValue={location?.distanceFromTheClosestBuildingInFeet}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>

            {/* <GridItem>
              <FormControl>
                <FormLabel>{t("Theft Exclusion")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="theftExclusion"
                  value={location?.theftExclusion}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem> */}

            <GridItem>
              <FormControl>
                <FormLabel>{t("3 Year Lost Count")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="threeYearLostCount"
                  value={location?.threeYearLostCount}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {threeYearLostCountFields?.map((type) => {
                    return (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Losses Last 5 Years")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="lossesLastFiveYears"
                  value={location.lossesLastFiveYears || 0}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {lossesLastFiveYearsFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("IOT Device")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="iotDevice"
                  value={location?.iotDevice}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  <option>{t("N/A")}</option>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem colSpan={1}>
              <FormControl>
                <FormLabel>{t("Building Group Indicator (Number)")}:</FormLabel>
                <Input
                  className="inputSelectBoxes"
                  type="text"
                  name="buildingGroupIndicator"
                  defaultValue={location?.buildingGroupIndicator}
                  onBlur={(e) => {
                    handleChange(e, id);
                  }}
                />
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl>
                <FormLabel>{t("Security Services")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="securityServices"
                  value={location.securityServices}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {securityServicesFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Cosmetic Roof Damage")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="cosmeticRoofDamage"
                  value={location?.cosmeticRoofDamage}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Pets/Animals")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="petsAndAnimals"
                  value={location.petsAndAnimals}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {petsAndAnimalsFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Latest Closing Hours")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="latestClosingHours"
                  value={location.latestClosingHours}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {latestClosingHoursFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Plumbing Updates")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="plumbingUpdates"
                  value={location.plumbingUpdates}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {plumbingUpdatesFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roofing Updates")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofingUpdates"
                  value={location.roofingUpdates}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {roofingUpdatesFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Wiring Updates")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="wiringUpdates"
                  value={location.wiringUpdates}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {wiringUpdatesFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Maintenance")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="maintainence"
                  value={location.maintainence}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {maintainenceFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Smoking")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="smoking"
                  value={location.smoking}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {smokingFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Elevator Safety")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="elevatorSafety"
                  value={location.elevatorSafety}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {elevatorSafetyFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("Roof Warranty")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="roofWarranty"
                  value={location.roofWarranty}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {quoteData?.YESNOONLY?.map((type) => {
                    return (
                      <option
                        key={type.s_AppCodeName}
                        value={type.s_AppCodeName}
                      >
                        {type.s_AppCodeNameForDisplay}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>{t("BBA Rating")}:</FormLabel>
                <Select
                  className="inputSelectBoxes"
                  name="bbbRating"
                  value={location.bbbRating}
                  onChange={(e) => {
                    handleChange(e, id);
                  }}
                >
                  <option></option>
                  {bbbRatingFields.map((data, index) => {
                    return (
                      <option value={data} key={index}>
                        {data}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
          <Box>
            <Box
              borderTop={"1px solid"}
              borderColor={"gray.200"}
              my={4}
              mt={6}
            />
            <Box p={4} width="65%">
              {coverageFields.map((field, index) => (
                <Flex
                  key={index}
                  mt={3}
                  alignItems="center"
                  display={!field?.visible ? "none" : "flex"}
                >
                  <Box w="170px" flex={1}>
                    <Text display="flex" alignItems="center">
                      {t(field.label)}{" "}
                      <FcInfo
                        aria-label={`Info for ${field.label}`}
                        style={{ marginLeft: "5px" }}
                      />
                    </Text>
                  </Box>
                  <Box w="200px">
                    <FormControl pb={4}>
                      {field.isSelect && field?.behaviour !== "custom" && (
                        <Select
                          h="45px"
                          className="inputSelectBoxes"
                          name={field.name}
                          value={location[field.name]}
                          onChange={(e) => handleChange(e, id)}
                        >
                          <option value="" key={0}></option>
                          {quoteData?.allCvgOpt
                            ?.find((type) => type.s_ScreenName === field.label)
                            ?.dropDown.map((data) => (
                              <option
                                key={data.s_LimitCode}
                                value={data.s_LimitCode}
                              >
                                {data.s_LimitScreenName}
                              </option>
                            ))}
                          {field.name === "buildingCoverageSelection" &&
                            bildingCoverageSection?.map((data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            ))}
                          {field.name ===
                            "buissnessInterruptionWaitingPeriod" &&
                            buildingInterpetionsFields?.map((data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            ))}
                          {field.name === "restorationFactor" &&
                            restorationFactorFields?.map((data) => (
                              <option key={data} value={data}>
                                {data}
                              </option>
                            ))}
                        </Select>
                      )}
                      {field.isSelect && field?.behaviour === "custom" && (
                        <Select
                          h="45px"
                          className="inputSelectBoxes"
                          name={field.name}
                          value={location[field.name]}
                          onChange={(e) => handleChange(e, id)}
                        >
                          <option value="" key={0}></option>
                          {!field?.dropDownLabelValue &&
                            field?.dropDown?.map((data, index) => (
                              <option key={index} value={data}>
                                {data}
                              </option>
                            ))}
                          {field?.dropDownLabelValue &&
                            field?.dropDown?.map((data, index) => (
                              <option key={index} value={data.s_DiscountCode}>
                                {data.s_ScreenName}
                              </option>
                            ))}
                        </Select>
                      )}
                      {!field.isSelect && (
                        <CustomCurrencyInput
                          className="inputSelectBoxes"
                          value={location[field.name]}
                          onValueChange={(value) =>
                            handleValueChange(value, field.name, id)
                          }
                          aria-label={field.label}
                        />
                      )}
                    </FormControl>
                  </Box>
                </Flex>
              ))}
            </Box>
            <Box>
              <Button
                leftIcon={<FaCopy />}
                float="right"
                size="sm"
                onClick={handleCopyAndDuplicate}
                isDisabled={isBind}
              >
                {t("Copy and Duplicate Location")}
              </Button>
            </Box>
          </Box>
        </Card>
        <style>
          {`
        .inputSelectBoxes{
          border: 2px solid var(--chakra-colors-neutral-200);
          border-radius: 12px;
          padding-right: 10px;
          padding-left: 15px;
          width: 100%;
          height: 45px;
        }
        `}
        </style>
      </Box>
    );
  }
);

export default NewLocation;
