import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';
import Select from 'react-select';
import { customStyles } from "../../../../components/reactSelectCustomStyle";

const PriorPolicyInfo = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, DOC, initFirmDate, checkFirmType, handleStateChange, checkDateisValid, firmType, isInsuredResides, waitingPeriod, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isHadFIP, setIsHadFIP] = useState('');
    const [isNFIP, setIsNFIP] = useState('');
    const [isComSuspension, setIsComSuspension] = useState('');
    const [isNewPurchase, setIsNewPurchase] = useState('');
    const [PPN, setPPN] = useState('');
    const [PCNAIC, setPCNAIC] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [NAICList, setNAICList] = useState({});
    const [isSellerDec, setIsSellerDec] = useState(null);
    const [isChange, setIsChanged] = useState(false);

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setIsHadFIP(emptyQuoteData.propertyInfo.s_IsPriorFIPPolicy || '');
            setIsNFIP(emptyQuoteData.propertyInfo.s_IsPriorNFIPLapsed || '');
            setIsComSuspension(emptyQuoteData.propertyInfo.s_IsCommunitySuspension || '');
            setIsNewPurchase(emptyQuoteData.propertyInfo.s_IsNewPurchase || '');
            setPPN(emptyQuoteData.propertyInfo.s_PriorPolicyNo || '');
            setIsSellerDec(emptyQuoteData.propertyInfo.isSellerDec || '');
            var PCNAIC = '';
            if (![null, ""].includes(emptyQuoteData.propertyInfo.s_PriorCompanyNAIC)) {
                PCNAIC = emptyQuoteData.propertyInfo.s_PriorCompanyNAIC;
            }
            setPCNAIC(PCNAIC);
        }
    }, [emptyQuoteData]);

    // to set WYO List
    useEffect(() => {
        if (Object.keys(floodSystemDD).length > 0) {
            setNAICList(floodSystemDD.WYOList);
        }
    }, [floodSystemDD]);

    // check firm type
    useEffect(() => {
        if (checkDateisValid(DOC) || checkDateisValid(initFirmDate)) {
            checkFirmType();
        }
    }, [DOC, initFirmDate]);

    // To update preFirmSubsidyEligible
    useEffect(() => {
        var temp = false;
        if ([isHadFIP, isNFIP].includes('NO') || ['YES'].includes(isComSuspension)) {
            temp = true;
        }
        handleStateChange('PREFIRM', temp, 'preFirmSubsidyEligible');
    }, [isHadFIP, isNFIP, isComSuspension])

    // Only Show if PREFIRM & Will the applicant or applicant's spouse live in this building more than 50% of the year? = yes
    useEffect(() => {
        var temp = false;
        if (firmType === 'FLDPREFIRM' && isInsuredResides) {
            temp = true;
        }
        setIsVisible(temp);
    }, [firmType, isInsuredResides])


    // update state in reducer
    useEffect(() => {
        if (isChange && isNewPurchase !== 'YES' && waitingPeriod !== 'ROLLOVER_RENEWAL') {
            var emptyQuote = { ...emptyQuoteData };
            emptyQuote['propertyInfo']['isSellerDec'] = null;
            emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = null;
            emptyQuote['propertyInfo']['s_PriorPolicyNo'] = null;
            dispatch(getEditEmptyQuoteData(emptyQuote));

            setIsSellerDec(''); setPPN(''); setPCNAIC(''); setIsChanged(false);
        }
    }, [isNewPurchase])

    // update state in reducer
    useEffect(() => {
        var emptyQuote = emptyQuoteData;
        if (isVisible === false) {
            emptyQuote['propertyInfo']['s_IsPriorNFIPLapsed'] = null;
            emptyQuote['propertyInfo']['s_IsCommunitySuspension'] = null;
            setIsNFIP(null);
            setIsComSuspension(null);
        }
        if (isHadFIP !== 'YES') {
            emptyQuote['propertyInfo']['s_IsPriorNFIPLapsed'] = null;
            setIsNFIP(null);
        }
        if (isNFIP !== 'YES') {
            emptyQuote['propertyInfo']['s_IsCommunitySuspension'] = null;
            setIsComSuspension(null);
        }
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }, [isVisible, isHadFIP])

    // update state in reducer
    const handleNAICChange = (value, priorPolicyNo = '') => {
        var emptyQuote = emptyQuoteData;
        var tempPriorPolicyNo = priorPolicyNo;
        if (PPN.length === 0) {
            tempPriorPolicyNo = PPN;
        }
        emptyQuote['propertyInfo']['s_PriorCompanyNAIC'] = value.s_NAICNumber;
        emptyQuote['propertyInfo']['s_PriorPolicyNo'] = tempPriorPolicyNo;
        setPPN(tempPriorPolicyNo);
        setPCNAIC(value.s_NAICNumber);
        dispatch(getEditEmptyQuoteData(emptyQuote));
    }

    return (
        <Form validated={validated}>
            <Row>
                {
                    waitingPeriod !== 'ROLLOVER_RENEWAL' && (
                        <>
                            {
                                isNewPurchase === 'YES' && (
                                    <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA' }}><i>{t("You may qualify for a statutory discount if the prior owner had a National Flood Insurance Program (NFIP) policy that had statutory discounts applied. In order to obtain any applicable discounts, please enter the prior owner's policy information below")}.</i></p>
                                )
                            }
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Is this a new purchase (within the last year)')}?
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_IsNewPurchase"
                                        title={t('Is Newly Mapped')}
                                        parentobject="propertyInfo"
                                        value={isNewPurchase}
                                        onChange={(e) => {
                                            setIsNewPurchase(e.currentTarget.value);
                                            handleChange(e);
                                            setIsChanged(true)
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                (isNewPurchase === "YES") && (
                                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                        <Form.Group
                                            className="search-quote-form-group"
                                            controlId="formBasicEmail"
                                        >
                                            <Form.Label className="dashboard-group-label">
                                                {t('Do you have sellers policy information')}?
                                            </Form.Label>
                                            <Form.Select
                                                className="dashboard-group-input-flood"
                                                id="select-ym-option"
                                                name="isSellerDec"
                                                title={t('Is Newly Mapped')}
                                                parentobject="propertyInfo"
                                                value={isSellerDec}
                                                onChange={(e) => {
                                                    setIsSellerDec(e.currentTarget.value);
                                                    handleChange(e);
                                                }}
                                                disabled={isBind}
                                            >
                                                <option value={''}>{t('Select')}</option>
                                                {
                                                    Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                        return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                )
                            }
                            {
                                isSellerDec === 'YES' && (
                                    <>
                                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Prior Policy NO')}
                                                </Form.Label>
                                                <Form.Control
                                                    className="dashboard-group-input-flood"
                                                    type="text"
                                                    placeholder="0000000000"
                                                    name="s_PriorPolicyNo"
                                                    title={t('Prior Policy NO#')}
                                                    parentobject="propertyInfo"
                                                    value={PPN || ''}
                                                    min={0}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setPPN(e.currentTarget.value);
                                                    }}
                                                    disabled={isBind}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Prior Company NAIC')}
                                                </Form.Label>
                                                {
                                                    Object.keys(NAICList).length > 0 && (
                                                        <Select
                                                            styles={customStyles}
                                                            title="propertyInfo"
                                                            name="s_PriorCompanyNAIC"
                                                            options={NAICList}
                                                            value={NAICList.filter(item => item.s_NAICNumber === PCNAIC)}
                                                            getOptionLabel={(option) => option.s_WYOCompany}
                                                            getOptionValue={(option) => option.s_NAICNumber}
                                                            onChange={(value) => handleNAICChange(value, PPN)}
                                                            disabled={isBind}
                                                        />
                                                    )
                                                }
                                            </Form.Group>
                                        </Col>
                                    </>
                                )
                            }
                        </>
                    )
                }
                {
                    isVisible && (
                        <>
                            <hr />
                            <span className="underwriting-title">
                                <b> Prefirm Eligibility</b>
                            </span>
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                <Form.Group
                                    className="search-quote-form-group"
                                    controlId="formBasicEmail"
                                >
                                    <Form.Label className="dashboard-group-label">
                                        {t('Has the applicant had a prior NFIP policy for structure')}?
                                    </Form.Label>
                                    <Form.Select
                                        className="dashboard-group-input-flood"
                                        id="select-ym-option"
                                        name="s_IsPriorFIPPolicy"
                                        title={t('Has the applicant had a prior NFIP policy for structure')}
                                        parentobject="propertyInfo"
                                        value={isHadFIP}
                                        onChange={(e) => {
                                            setIsHadFIP(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        disabled={isBind}
                                    >
                                        <option value={''}>{t('Select')}</option>
                                        {
                                            Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {
                                isHadFIP === 'YES' && (
                                    <>
                                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                            <Form.Group
                                                className="search-quote-form-group"
                                                controlId="formBasicEmail"
                                            >
                                                <Form.Label className="dashboard-group-label">
                                                    {t('Did the prior policy lapse while receiving a pre-firm discount')}?
                                                </Form.Label>
                                                <Form.Select
                                                    className="dashboard-group-input-flood"
                                                    id="select-ym-option"
                                                    name="s_IsPriorNFIPLapsed"
                                                    title={t('Did the prior policy lapse while receiving a pre-firm discount')}
                                                    parentobject="propertyInfo"
                                                    value={isNFIP}
                                                    onChange={(e) => {
                                                        setIsNFIP(e.currentTarget.value);
                                                        handleChange(e);
                                                    }}
                                                    disabled={isBind}
                                                >
                                                    <option value={''}>{t('Select')}</option>
                                                    {
                                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {
                                            isNFIP === 'YES' && (
                                                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                                                    <Form.Group
                                                        className="search-quote-form-group"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label className="dashboard-group-label">
                                                            {t('Was the lapse the result of a community suspension')}?
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="dashboard-group-input-flood"
                                                            id="select-ym-option"
                                                            name="s_IsCommunitySuspension"
                                                            title={t('Was the lapse the result of a community suspension')}
                                                            parentobject="propertyInfo"
                                                            value={isComSuspension}
                                                            onChange={(e) => {
                                                                setIsComSuspension(e.currentTarget.value);
                                                                handleChange(e);
                                                            }}
                                                            disabled={isBind}
                                                        >
                                                            <option value={''}>{t('Select')}</option>
                                                            {
                                                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }

            </Row>
        </Form>
    )
}

export default PriorPolicyInfo