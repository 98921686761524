import React from "react";
import { Table } from "react-bootstrap";
import { Currency } from "../../../common/Currency";

export const PremiumCardDisplay = ({ deductible }) => {
  return (
    <Table borderless responsive style={{ fontSize: "16px" }}>
      <tbody>
        {deductible?.buildingAnnualizedPremium !== undefined && (
          <tr>
            <td> Building Premium:</td>
            <td>
              {deductible?.buildingAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.buildingAnnualizedPremium -
                    deductible?.buildingAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.buildingAdditionalPremium !== undefined && (
          <tr>
            <td>Building Additional Premium:</td>
            <td>
              {deductible?.buildingAdditionalPremium ? (
                <Currency amount={deductible?.buildingAdditionalPremium} />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.includeContentsAnnualizedPremium !== undefined && (
          <tr>
            <td>Contents Premium:</td>
            <td>
              {deductible?.includeContentsAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.includeContentsAnnualizedPremium -
                    deductible?.includeContentsAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible.includeContentsAdditionalPremium !== undefined && (
          <tr>
            <td>Contents Additional Premium:</td>
            <td>
              {deductible?.includeContentsAdditionalPremium ? (
                <Currency
                  amount={deductible?.includeContentsAdditionalPremium}
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.otherStructuresAnnualizedPremium !== undefined && (
          <tr>
            <td>Other Structure Premium:</td>
            <td>
              {deductible?.otherStructuresAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.otherStructuresAnnualizedPremium -
                    deductible?.otherStructuresAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.otherStructuresAdditionalPremium !== undefined && (
          <tr>
            <td>Other Structure Additional Premium:</td>
            <td>
              {deductible?.otherStructuresAdditionalPremium ? (
                <Currency
                  amount={deductible?.otherStructuresAdditionalPremium}
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.includeLossOfUseAnnualizedPremium !== undefined && (
          <tr>
            <td>Loss of Use Premium:</td>
            <td>
              {deductible?.includeLossOfUseAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.includeLossOfUseAnnualizedPremium -
                    deductible?.includeLossOfUseAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.improvementsAndBettermentsAnnualizedPremium !==
          undefined && (
          <tr>
            <td>Improvements and Betterments Premium:</td>
            <td>
              {deductible?.improvementsAndBettermentsAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.improvementsAndBettermentsAnnualizedPremium -
                    deductible?.improvementsAndBettermentsAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.includeLossOfUseAdditionalPremium !== undefined && (
          <tr>
            <td>Loss of Use Additional Premium:</td>
            <td>
              {deductible?.includeLossOfUseAdditionalPremium ? (
                <Currency
                  amount={deductible?.includeLossOfUseAdditionalPremium}
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.improvementsAndBettermentsAdditionalPremium !==
          undefined && (
          <tr>
            <td>Improvements and Betterments Additional Premium:</td>
            <td>
              {deductible?.improvementsAndBettermentsAdditionalPremium ? (
                <Currency
                  amount={
                    deductible?.improvementsAndBettermentsAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.includeBusinessIncomeAnnualizedPremium !== undefined && (
          <tr>
            <td>Business Income Premium:</td>
            <td>
              {deductible?.includeBusinessIncomeAnnualizedPremium ? (
                <Currency
                  amount={
                    deductible?.includeBusinessIncomeAnnualizedPremium -
                    deductible?.includeBusinessIncomeAdditionalPremium
                  }
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
        {deductible?.includeBusinessIncomeAdditionalPremium !== undefined && (
          <tr>
            <td>Business Income Additional Premium:</td>
            <td>
              {deductible?.includeBusinessIncomeAdditionalPremium ? (
                <Currency
                  amount={deductible?.includeBusinessIncomeAdditionalPremium}
                />
              ) : (
                <Currency amount={0} />
              )}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
