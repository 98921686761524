import React, { useContext, useMemo } from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { starCommercialContext } from "../CommercialContext";
import ApplicantTab from "../Components/ApplicantTab";
import NewLocation from "../Components/NewLocation";
import OptionalCoverage from "../Components/OptionalCoverage";
import LocationDeleteButton from "../Components/Modals/ConfirmationModal";
import { Mortgagees } from "../Components/AdditionalData/Mortgagees";
import { FaSave } from "react-icons/fa";

const INITIAL_PROPERTY_DETAIL = {
  address: "",
  locationIdentifier: "",
  zip: "",
  county: "",
  state: "",
  city: "",
};

const AccordionSection = ({ index, title, children, isOpen, onToggle }) => (
  <AccordionItem mt={index === 0 ? 0 : 3} borderTopWidth="0">
    <AccordionButton
      border="1px solid var(--chakra-colors-neutral-200)"
      fontWeight={500}
      fontSize="lg"
      py="12px"
      background="var(--chakra-colors-primary-50)"
      borderRadius="xl"
      onClick={() => onToggle(index)}
      _hover={{ background: "var(--chakra-colors-primary-50)" }}
    >
      <Box as="span" flex="1" textAlign="left" color="black">
        {title}
      </Box>
      <AccordionIcon color="black" />
    </AccordionButton>
    <AccordionPanel
      my={2}
      mx={1}
      border="1px solid var(--chakra-colors-neutral-100)"
      borderRadius="2xl"
    >
      {children}
    </AccordionPanel>
  </AccordionItem>
);

const LocationAccordion = ({
  location,
  index,
  address,
  onRemove,
  children,
  isBind
}) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="flex-start"
    gap={2}
  >
    <Accordion borderColor="transparent" allowToggle flex={1}>
      <AccordionItem>
        <AccordionButton
          fontWeight={500}
          my={1}
          py={3}
          background="var(--chakra-colors-primary-50)"
          border="1px solid var(--chakra-colors-neutral-100)"
          borderRadius="xl"
          _hover={{ background: "var(--chakra-colors-primary-50)" }}
        >
          <Box as="span" flex="1" textAlign="left" color="black">
            {address || `New Location ${index + 1}`}
          </Box>
          <AccordionIcon color="black" />
        </AccordionButton>
        <AccordionPanel>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
    <LocationDeleteButton
      index={index}
      onDelete={onRemove}
      totalLocations={location.length}
      address={address}
      isBind={isBind}
    />
  </Box>
);

const Quote = () => {
  const { t } = useTranslation();
  const {
    commercialData,
    setCommercialData,
    accordiansArray,
    quoteData,
    setAccordianArray,
    handleSubmitQuote,
    isBind,
  } = useContext(starCommercialContext);

  const toggleAccordion = (index) => {
    setAccordianArray((prev) => {
      const newArray = [...prev];
      if (newArray.includes(index)) {
        return newArray.filter((i) => i !== index);
      }
      return [...newArray, index];
    });
  };

  const handleSwitchChange = () => {
    setCommercialData((prev) => ({
      ...prev,
      lossHistoryQuestion: !prev.lossHistoryQuestion,
      claimDetails: !prev.lossHistoryQuestion ? [] : [INITIAL_PROPERTY_DETAIL],
    }));
  };

  const handleAddLocation = () => {
    setCommercialData((prev) => ({
      ...prev,
      propertyDetail: [...prev.propertyDetail, INITIAL_PROPERTY_DETAIL],
    }));
  };

  const handleRemoveLocation = (index) => {
    if (commercialData.propertyDetail.length <= 1) {
      toast.error("At least one location is required.", {
        toastId: "minimum-location-error",
      });
      return;
    }

    setCommercialData((prev) => ({
      ...prev,
      propertyDetail: prev.propertyDetail.filter((_, i) => i !== index),
    }));
  };

  const formatAddress = (details) => {
    if (!details?.address) return "";

    const parts = [
      details.locationIdentifier,
      details.address,
      details.city || "-",
      details.state || "-",
      details.zip || "-",
    ];

    return parts.join(", ");
  };

  const sections = useMemo(
    () => [
      {
        title: t("Applicant Information"),
        content: <ApplicantTab quoteData={quoteData} />,
      },
      {
        title: t("Policy Summary"),
        content: <OptionalCoverage quoteData={quoteData} />,
      },
      {
        title: t("Mortgagee(s)"),
        content: <Mortgagees mortgageeData={commercialData?.mortgagees} />,
      },
      {
        title: t("Property Details"),
        content: (
          <>
            {commercialData?.propertyDetail?.map((location, index) => (
              <LocationAccordion
                key={index}
                location={location}
                index={index}
                address={formatAddress(location)}
                onRemove={handleRemoveLocation}
                isBind={isBind}
              >
                <NewLocation
                  id={index}
                  quoteData={quoteData}
                  location={location}
                  handleAddRow={handleAddLocation}
                  handleRemoveRow={handleRemoveLocation}
                  handleSwitchChange={handleSwitchChange}
                  commercialData={commercialData}
                  setCommercialData={setCommercialData}
                  isBind={isBind}
                />
              </LocationAccordion>
            ))}
            <Box mt={3} ml={1}>
              <Button
                borderRadius="xl"
                onClick={handleAddLocation}
                variant="outline"
                leftIcon={<MdAdd size={20} />}
                isDisabled={isBind}
              >
                {t("Add More Locations")}
              </Button>
            </Box>
          </>
        ),
      },
    ],
    [commercialData, quoteData, t]
  );

  return (
    <Box>
      <Accordion
        borderColor="transparent"
        allowToggle
        index={accordiansArray || [0]}
      >
        {sections.map((section, index) => (
          <AccordionSection
            key={index}
            index={index}
            title={section.title}
            isOpen={accordiansArray?.includes(index)}
            onToggle={toggleAccordion}
          >
            {section.content}
          </AccordionSection>
        ))}
      </Accordion>
      <Flex justifyContent={"flex-end"}>
        <Button
          variant={"outline"}
          borderRadius={"xl"}
          leftIcon={<FaSave />}
          isDisabled={isBind}
          onClick={() => {
            handleSubmitQuote();
          }}
        >
          {t("Save & Update")}
        </Button>
      </Flex>
    </Box>
  );
};

export default Quote;
