import React, { useEffect, useState } from "react";
// import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, connect } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import Agent from "../Agent/Agent";
import {
  getProducerData,
  getBrandedCompanies,
} from "../../../services/producerService";
import { EncryptOrDecrypt } from "../../../services/commonService";
// import Modal from "react-bootstrap/Modal";
import ProducerModal from "../ProducerModal/ProducerModal";
import { useParams, useNavigate } from "react-router-dom";
import { checkProducerVisibility } from "../../../services/moduleVisibilityService";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

const ProducerContainer = (props) => {
let params = useParams();
const navigate = useNavigate();
const { t } = useTranslation("translations","producer");
const dispatch = useDispatch();
const [agencyData,setAgencyData] = useState({}); 
const [brandedCompanies,setBrandedCompanies] = useState([]); 
const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
const [showSearchAgent, setShowSearchAgent] = useState(false);
const handleSearchAgentShow = () => setShowSearchAgent(true);
const handleCloseSearchAgentShow = () => setShowSearchAgent(false);
const [showSearchAgency, setShowSearchAgency] = useState(false);
const handleSearchAgencyShow = () => setShowSearchAgency(true);
const handleCloseSearchAgencyShow = () => setShowSearchAgency(false);
const [agencyId, setAgencyId] = useState(0);
const [isLoading, setIsLoading] = useState(false);
const [isAgencyUserModalOpen, setIsAgencyUserModalOpen] = useState(false);
const userLevel = userDatas.accessRights.levelName;
const userSubLevel = userDatas.accessRights.subLevelName;
const [agentSubModulesAccess, setAgentSubModulesAccess] = useState(localStorage.getItem("agentSubModules") ? JSON.parse(	localStorage.getItem("agentSubModules")) : []);

useEffect(()=>{
  if(agentSubModulesAccess != [] && userLevel != undefined && userSubLevel != undefined){
    if( ! checkProducerVisibility(agentSubModulesAccess,userLevel,userSubLevel) ){
        navigate('/');
    }
  }
},[agentSubModulesAccess,userLevel,userSubLevel])

useEffect(() => {
  if(params.agencyId != '' && params.agencyId != undefined){
    setAgencyId(params.agencyId);
  }
  else{
    if(userDatas.persInfoAgencyFK != undefined)
    setAgencyId(userDatas.persInfoAgencyFK);
  }
},[])

useEffect(()  => {
  if(agencyId !== 0){
	  getProducerDataFromApi(); 
    getBrandedCompaniesFromApi();        
  }
  }, [agencyId]);
 
 const getProducerDataFromApi = async () => {
    setIsLoading(true)
    await dispatch(getProducerData(agencyId)).then((res) => {
        setAgencyData(res);
        setIsAgencyUserModalOpen(props.agencyUserSearchModal)
        setIsLoading(false)
    });
  }
 const getBrandedCompaniesFromApi = async () => {
    setIsLoading(true)
    await dispatch(getBrandedCompanies()).then((res) => {
        setBrandedCompanies(res.data);
        setIsLoading(false)
    });
  }

  return (
    <>
      <LoadingOverlay>
        <Loader loading={isLoading} />
        <Box>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Text mb={0} fontWeight={600} fontSize="2xl" lineHeight="32px">
                {t("Producers", {ns: "producer"})}
              </Text>
            </Box>
            <Box>
              <Button
                size="sm"
                leftIcon={<FaSearch/>}
                onClick={handleSearchAgentShow}
              >
                {t("Search Agent")}
              </Button>
              <Button
                ml={4}
                size="sm"
                leftIcon={<FaSearch/>}
                onClick={handleSearchAgencyShow}
              >
                {t("Search Agency")}
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box mt={4}>
          <Agent
            agencyId={agencyId}
            agencyData={agencyData}
            brandedCompanies={brandedCompanies}
            isAgencyUserModalOpen={isAgencyUserModalOpen}
          />
        </Box>
        <Modal
          size={"6xl"}
          isOpen={showSearchAgent}
          onClose={handleCloseSearchAgentShow}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
              {t("Agent Search")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ProducerModal handleSearchAgentShow={handleSearchAgentShow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Modal
          size={"6xl"}
          isOpen={showSearchAgency}
          onClose={handleCloseSearchAgencyShow}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
              {t("Agency Search")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ProducerModal handleSearchAgencyShow={handleSearchAgencyShow} />
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* <div>
        <Row>
          <Col style={{ float: "left" }}> */}
        {/* <div>
              <Button
                size="sm"
                style={{
                  background: "#64D6CD",
                  border: "1px solid #64D6CD",
                }}
              >
                Save Agency
              </Button>
            </div> */}
        {/* </Col>
          <Col
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          > */}
        {/* <div>
              <Button
                size="sm"
                disabled={true}
                style={{
                  background: "#64D6CD",
                  border: "1px solid #64D6CD",
                }}
              >
                Add Agency
              </Button>
            </div> */}
        {/* <div>
              <Button
                size="sm"
                id="card-header"
                onClick={handleSearchAgentShow}
              >
                {t("Search Agent")}
              </Button>
            </div>
            <div>
              <Button
                size="sm"
                id="card-header"
                onClick={handleSearchAgencyShow}
              >
                {t("Search Agency")}
              </Button>
            </div>
          </Col>
        </Row> */}
        {/* <hr style={{ color: "blue", marginTop: "5px" }} /> */}
        {/* </div>
      <div>
        <Agent
            agencyId = {agencyId}
            agencyData = {agencyData}
            brandedCompanies = {brandedCompanies}
            isAgencyUserModalOpen = {isAgencyUserModalOpen}
        />
      </div> */}

        {/* <Modal
        size="xl"
        show={showSearchAgent}
        onHide={handleCloseSearchAgentShow}
      >
        <Modal.Header closeButton className="displayColorHeaders">
          <Modal.Title >{t("Agent Search")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProducerModal handleSearchAgentShow={handleSearchAgentShow}/>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showSearchAgency}
        onHide={handleCloseSearchAgencyShow}
      >
        <Modal.Header closeButton className="displayColorHeaders">
          <Modal.Title>{t("Agency Search")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProducerModal handleSearchAgencyShow={handleSearchAgencyShow} agencyId = {agencyId} />
        </Modal.Body>
      </Modal> */}
      </LoadingOverlay>
    </>
  );
};
function mapStateToProps(state) {
  return {
    getProducerData: state.producerDataProcess.producerData,
    setLoader:state.producerDataProcess.loader,
    agencyUserSearchModal : state.producerDataProcess.openAgentUserModel
  };
}
export default connect(mapStateToProps)(ProducerContainer);