import React from "react";
import {
  Box,
  Text,
  FormLabel,
  Flex,
  Stack,
  HStack,
  Card,
  Switch,
  Divider
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
const Commission = ({ disabled, onCommissionChange, data }) => {
  const { t } = useTranslation();

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    const switchEvent = {
      target: { value: checked ? "N" : "Y", name: name }
    }
    onCommissionChange(switchEvent);
  };

  return (<Card height={"100%"} boxShadow={"lg"} borderRadius={"2xl"}>
    <Box p={6} mb={4}>
      <Text fontWeight={600} fontSize={"xl"}>
        {t("Commission")}
      </Text>
      <Divider orientation='horizontal' />
      <Stack>
        <Flex justifyContent="space-between">
          <HStack>
            <FormLabel>{t("Include Commission Downloads?")}</FormLabel>
          </HStack>
          <HStack>
            <Switch
              name="download_suspended"
              size="lg"
              isChecked={
                data && data === "N"
              }
              onChange={handleSwitchChange}
              isDisabled={disabled}
            />
          </HStack>
        </Flex>                
      </Stack>
    </Box>
  </Card>
  );
};

export default Commission;
