import { Box } from '@chakra-ui/react'
import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { generateTempUrl } from '../../../services/coreSystemService';
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { formateDate } from '../../common/FormatDate';

export default function SearchResult({ result, setLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  const linkFormatter = (params) => {
    if (params.data.systemSource === 'FloodPro') {
      return (
        <Box
          className="management-reports-link"
          style={{ color: "var(--chakra-colors-linkColor)", }}
          onClick={() => {
            window.open(params.data.DocURL)
          }}
        >
          {"View Document"}
        </Box>
      )
    }
    else {
      return (
        <Box
          className="management-reports-link"
          style={{ color: "var(--chakra-colors-linkColor)", }}
          onClick={() => {
            if (![null, '', undefined].includes(params.data.DocInfo_PK)) {
              getTempUrl(params);
            }
          }}
        >
          {"View Document"}
        </Box>
      )
    }
  };

  const [columnDefs] = useState([
    {
      headerName: t("Module Name"),
      field: "s_UserModuleName",
      filter: "agTextColumnFilter",
      sortable: true,
      minWidth: 170,
    },
    {
      headerName: t("Document Type"),
      field: "s_DocTypeNameForDisplay",
      filter: "agTextColumnFilter",
      sortable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 210,
    },
    {
      headerName: t("Document Name"),
      field: "DocNameAsUploded",
      filter: "agTextColumnFilter",
      sortable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 310,
    },
    {
      headerName: t("Date"),
      field: "UploadDate",
      sortable: true,
      valueFormatter: (params) => formateDate(params.value),
      filter: "agDateColumnFilter",
      minWidth: 170,
    },
    {
      headerName: t("Actions"),
      field: "DocURL",
      filter: false,
      sortable: true,
      minWidth: 170,
      cellRenderer: linkFormatter,
      cellStyle: {
        // textAlign: "center",
        color: "var(--chakra-colors-linkColor)",
        cursor: "pointer",
        fontWeight: 600,
        textDecoration: "underline",
      },
    },
  ]);


  const getTempUrl = async (params) => {
    var param = {
      id: params.data.DocInfo_PK,
      type: params.data.systemSource ?? 'NEW_SYSTEM',
      module: 'Policy',
    }
    setLoading(true);
    await dispatch(generateTempUrl(param, 'CORE')).then((url) => {
      if (![null, ''].includes(url)) {
        window.open(url);
      } else {
        toast.error(t('File Not Found!'));
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    })
    setLoading(false);
  };

  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
    sortable: true,
    floatingFilter: true,
    sizeColumnsToFit: true,
  }), []);

  return (
    <Box bg={'white'} borderRadius={"2xl"} boxShadow={"lg"} p={5}>
      <Box
        className="ag-theme-alpine"
        h={"350px"}
        overflow={"hidden"}
      >
        <AdvancedGridTable
          gridRef={gridRef}
          rowData={result}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableCellTextSelection={true}
        />
      </Box>
    </Box>
  )
}
