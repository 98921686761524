import React from 'react'
import { Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function PolicyHolder({ policyNo, policyHolder }) {
    const { t } = useTranslation();
    const address = `${policyHolder?.s_AddressLine1}, ${policyHolder?.s_CityName}, ${policyHolder?.s_StateCode}, ${policyHolder?.s_PostalCode}`;

    return (
        <>
            <Flex
                direction={'column'}
                p={4}
                borderRadius={"2xl"}
                // border={"1px solid"}
                // borderColor={"gray.400"}
                height={'100%'}
                bgColor={'white'}
                boxShadow={"lg"}
            >
                <Text mb={'4px'} fontWeight={"var(--chakra-fontWeights-semibold)"} fontSize={"md"}>
                    {t("Policy Holder Details")}
                </Text>
                <Flex height={'100%'}>
                    <Flex direction='column' justifyContent={'space-between'}>
                        <Text mb={'4px'} >{t("Policy Number")}</Text>
                        <Text mb={'4px'} >{t("Policy Holder/Member Name")}</Text>
                        <Text mb={'4px'} >{t("Property Address")}</Text>
                    </Flex>
                    <Flex direction='column' justifyContent={'space-between'} ml={4}>
                        <Text mb={'4px'} >{policyNo}</Text>
                        <Text mb={'4px'} >{policyHolder?.s_FullLegalName}</Text>
                        <Text mb={'4px'} >{policyHolder ? address : ""}</Text>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}
