import Http from "../Http";
import * as action from "../store/actions";

const baseURL = process.env.REACT_APP_API_URL;

export function enrollUser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post("/api/enroll-agent", data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function stateList(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("/api/state-list")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function getZipDetails(zipCode) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`/api/zipcode-details/${zipCode}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function userProductsLicense() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("/api/user-products-license")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}

export function downloadW9CopyServer() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get("/api/download-w9")
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          if (err.response) {
            return resolve(err.response.data);
          }
        });
    });
}