export const standardizeKeyName = (key) => {
  const standardizedNames = {
    improvementsAndBettermentsPremium: "Improvements and Betterments Premium",
    contentsPremium: "Contents Premium",
    businessIncomePremium: "Business Income Premium",
    buildingPremium: "Building Premium",
    otherStructuresPremium: "Other Structures Premium",
    lossOfUsePremium: "Loss of Use Premium",
    totalFee: "Total Fees",
  };
  return standardizedNames[key] || key;
};
