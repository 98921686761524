import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEditEmptyQuoteData } from '../../../../services/floodService';

const MachineryEquipment = (props) => {
    const { emptyQuoteData, floodSystemDD, handleChange, foundationType, validated, isBind } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isContainME, setIsContainME] = useState('');
    const [isHeightofAttic, setIsHeightofAttic] = useState('');
    const [label, setLabel] = useState('');

    // set state
    useEffect(() => {
        if (Object.keys(emptyQuoteData).length > 0) {
            setIsContainME(emptyQuoteData.propertyInfo.s_IsBldgContainME);
            setIsHeightofAttic(emptyQuoteData.propertyInfo.s_AllMachinaryElevated);
        }
    }, [emptyQuoteData]);

    // set label based on building foundation type
    useEffect(() => {
        if (![null, ''].includes(foundationType)) {
            var text = 'Is all machinery Elevated to the height of ';
            switch (foundationType) {
                case 'SLABONGRADE20':
                    text += " attic or second floor";
                    break;
                case 'BASEMENT20':
                    text += " the floor above the basement";
                    break;
                case 'ELEVATEDWITHENCLOSURE20':
                case 'ELEVATEDWITHOUTENCLOSURE20':
                case 'ELEVATEDWITHENCLOSURENOTPPP20':
                    text += " the lowest elevated floor";
                    break;
                case 'ELEWATEDONCRAWLSPACE20':
                    text += " the floor above the crawlspace";
                    break;
                default:
                    text = "";
                    break;
            }
            text += ' or higher';
            setLabel(text)
        }
    }, [foundationType])

    // update state in reducer
    useEffect(() => {
        if (isContainME === 'NO') {
            var emptyQuote = emptyQuoteData;
            emptyQuote['propertyInfo']['s_AllMachinaryElevated'] = null;
            setIsHeightofAttic(null);
            dispatch(getEditEmptyQuoteData(emptyQuote));
        }
    }, [isContainME])

    return (
        <Form validated={validated}>
            <Row className="align-items-end">
                <p className="mb-1" style={{ textAlign: 'justify', color: '#008CBA' }}><i>{t('Includes all machinery that has a permanent attachment to the building. Including but not limited to: Central air conditioner (including exterior compressor), Furnace, Heat pump (including exterior compressor), and Hot water heater')}.</i></p>
                <Col xs="12" sm="12" md="6" lg="3" xl="3">
                    <Form.Group
                        className="search-quote-form-group"
                        controlId="formBasicEmail"
                    >
                        <Form.Label className="dashboard-group-label">
                            {t('Does the building contain Machinery and equipment that services the building')}?
                        </Form.Label>
                        <Form.Select
                            className="dashboard-group-input-flood"
                            id="select-ym-option"
                            name="s_IsBldgContainME"
                            title={t('Does the building contain Machinery and equipment that services the building')}
                            parentobject="propertyInfo"
                            value={isContainME}
                            onChange={(e) => {
                                setIsContainME(e.currentTarget.value);
                                handleChange(e);
                            }}
                            required
                            disabled={isBind}
                        >
                            <option value={''}>{t('Select')}</option>
                            {
                                Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                    return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                {
                    (![null, ''].includes(foundationType) && isContainME === 'YES') && (
                        <Col xs="12" sm="12" md="6" lg="3" xl="3">
                            <Form.Group
                                className="search-quote-form-group"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="dashboard-group-label">
                                    {t(label)}?
                                </Form.Label>
                                <Form.Select
                                    className="dashboard-group-input-flood"
                                    id="select-ym-option"
                                    name="s_AllMachinaryElevated"
                                    title={t(label)}
                                    parentobject="propertyInfo"
                                    value={isHeightofAttic}
                                    onChange={(e) => {
                                        setIsHeightofAttic(e.currentTarget.value);
                                        handleChange(e);
                                    }}
                                    required
                                    disabled={isBind}
                                >
                                    <option value={''}>{t('Select')}</option>
                                    {
                                        Object.keys(floodSystemDD).length > 0 && floodSystemDD.YESNOONLY.map((v, i) => {
                                            return <option key={i} value={v.s_AppCodeName}>{t(v.s_AppCodeNameForDisplay)}</option>
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    )
                }
            </Row>
        </Form>
    )
}

export default MachineryEquipment