import Http from "../../../../Http";

export const fetchPendingTransactions = async (params, transType) => {
  try {
    const queryString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    const response = await Http.get(
      `api/pending-transactions/${transType}?${queryString}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};