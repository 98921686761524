import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { toast } from 'react-toastify';
import { getPolicyNotes, printPolicyNotes, savePolicyNotes } from "../../../services/coreSystemService";
import { useDispatch } from "react-redux";
import Moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const PolicyNote = (props) => {
    const { t } = useTranslation();
    const { policyPK, poTransPK } = props;
    const dispatch = useDispatch();

    const [note, setNote] = useState('');
    const [noteLength, setNoteLength] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);

    useEffect(() => {
        getAllPolicyNotes();
    }, [])

    // Indicate - max 500 char allowed
    useEffect(() => {
        if (note.length > 500) {
            // set 500 char 
            setNote(note.substring(0, 500));
            setNoteLength(500);
        }
    }, [note])

    // reset state
    const resetState = () => {
        setNote('');
        setNoteLength(0);
    }

    // get table data
    const getAllPolicyNotes = async () => {
        setLoading(true);
        await dispatch(getPolicyNotes(policyPK))
            .then((res) => {
                var tempData = [];

                if (res.success === true) {
                    if (res.data.status === 'Y') {
                        if (res.data.data.length > 0) {
                            res.data.data.map((value) => {
                                tempData.push({
                                    'id': value.PolicyLogId_PK,
                                    'date': Moment(value.metadata.d_CreatedDate).format("MM-DD-YYYY h:mm:ss A"),
                                    'description': value.metadata.note,
                                    'insertedBy': value.metadata.n_CreatedUser,
                                });
                            });
                        }
                        setTableData(tempData);
                    } else {
                        toast.error(res.data.msg || t("Something Went Wrong"));
                    }
                }

                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                toast.error(t("Something Went Wrong"));
            });
    }

    // save note
    const handleSubmit = async () => {
        if (note.length === 0) {
            toast.error(t("Please Enter Note"));
            return false;
        }
        if (note.length > 500) {
            toast.error(t("Max 500 character allowed"));
            return false;
        }

        setLoading(true);

        var request = {
            "policyPK": policyPK,
            "poTransPK": poTransPK,
            "note": note,
            "isHidden": "N",
            "flag": "USER"
        }
        await dispatch(savePolicyNotes(request))
            .then((res) => {
                if (res.success === true) {
                    if (res.data.status === 'Y') {
                        toast.success(res.data.msg);
                        resetState();
                        getAllPolicyNotes();
                    } else {
                        toast.error(res.data.msg || t("Something Went Wrong"));
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                toast.error(t("Something Went Wrong"));
            });
    }

    // print log 
    const printLog = async () => {
        setLoading(true);
        await dispatch(printPolicyNotes(policyPK))
            .then((res) => {
                setLoading(false);
                if (res.success === true) {
                    if (res.data.status === 'Y') {
                        window.open(res.data.url);
                    } else {
                        toast.error(res.data.msg || t("Something Went Wrong"));
                    }
                }
            })
            .catch(() => {
                setLoading(false);
                toast.error(t("Something Went Wrong"));
            });
    }

    const columns = [
        {
            dataField: "date",
            text: t("Date"),
        },
        {
            dataField: "description",
            text: t("Description"),
        },
        {
            dataField: "insertedBy",
            text: t("Inserted By"),
        }
    ];

    const handlePageChange = (iPage) => {
        setPage(iPage);
    };

    const onTableChange = (type, { page, sizePerPage }) => {
        setPage(page);
        setSizePerPage(sizePerPage);
    };

    const options = {
        page: page,
        sizePerPage: sizePerPage,
        sortIndicator: false,
        pageStartIndex: 1,
        totalSize: tableData.length,
        onPageChange: handlePageChange,
    };

    return (
        <Card className="mt-3">
            <Card.Header>
                <Button
                    id="common-btn"
                    variant="primary"
                    size="sm"
                    style={{ float: "right" }}
                    onClick={printLog}
                    disabled={loading || (tableData.length === 0)}
                >
                    {t("Print Log")}
                </Button>
            </Card.Header>

            <Card.Body>
                <Row className="align-items-end">
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Form.Group
                            className="search-quote-form-group"
                            controlId="formBasicEmail"
                        >
                            <Form.Control
                                as="textarea"
                                placeholder={t("Type note here")}
                                value={note}
                                onChange={(e) => {
                                    setNote(e.currentTarget.value);
                                    setNoteLength(e.currentTarget.value.length);
                                }}
                                className='mt-1'
                                style={{ height: '100px' }}
                                disabled={loading}
                            />
                            <p style={{ 'textAlign': 'right' }}>{noteLength + "/500"}</p>
                        </Form.Group>
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="2" xl="2">
                        <Button
                            id="common-btn"
                            variant="primary"
                            size="sm"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {t('Save')}
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <BootstrapTable
                            wrapperClasses="dashboard-table-main"
                            bootstrap4
                            remote={false}
                            keyField="id"
                            bordered={false}
                            data={tableData}
                            columns={columns}
                            hover
                            pagination={paginationFactory(options)}
                            noDataIndication={t("No data to display")}
                            onTableChange={onTableChange}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PolicyNote;