import home from "../../../../../assets/flood/home-type/home-light.png";
import unit from "../../../../../assets/flood/home-type/unit-light.png";
import mobile from "../../../../../assets/flood/home-type/mobile-home-light.png";
import residential from "../../../../../assets/flood/home-type/residential-light.png";
import non_residential from "../../../../../assets/flood/home-type/non-residential-light.png";

// Farmers
import Icon1_Farmers from "../../../../../assets/flood/home-type/home-light-farmer.png";
import Icon2_Farmers from "../../../../../assets/flood/home-type/mobile-home-light-farmer.png";
import Icon3_Farmers from "../../../../../assets/flood/home-type/unit-light-farmer.png";
import Icon4_Farmers from "../../../../../assets/flood/home-type/residential-light-farmer.png";
import Icon5_Farmers from "../../../../../assets/flood/home-type/non-residential-light-farmer.png";

// get Tenant from URL
const tenant = window.location.host.split('.')[0];
var Icon1 = home, Icon2 = mobile, Icon3 = unit, Icon4 = residential, Icon5 = non_residential;

// Overwrite only for farmers tenancy
if(tenant === 'farmers'){
    Icon1 = Icon1_Farmers;
    Icon2 = Icon2_Farmers;
    Icon3 = Icon3_Farmers;
    Icon4 = Icon4_Farmers;
    Icon5 = Icon5_Farmers;
}

export {Icon1, Icon2, Icon3, Icon4, Icon5};