
export const tenant =  window.location.host.split('.')[0];

export const isTenancyEnabledForAmazonChat = process.env.REACT_APP_ENABLED_TENANCY_AMAZON_CONNECT && (process.env.REACT_APP_ENABLED_TENANCY_AMAZON_CONNECT == "*" || process.env.REACT_APP_ENABLED_TENANCY_AMAZON_CONNECT.split(",").includes(tenant));

export const isTenancyEnabledForNewLayout = process.env.REACT_APP_NEW_LAYOUT_ENABLED_TENANCY && (process.env.REACT_APP_NEW_LAYOUT_ENABLED_TENANCY == "*" || process.env.REACT_APP_NEW_LAYOUT_ENABLED_TENANCY.split(",").includes(tenant));

export const PhoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];