import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { resetUsername, verifyToken } from "../../services/authService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ResetUsername = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    checkToken();
  }, []);

  function checkToken() {
    dispatch(verifyToken(token))
      .catch((err) => {
        const { status } = err.response;
        if (status === 419 || status === 404) {
          navigate("/" + status);
        }
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username) {
      toast.error(t('Please enter username!'));
      return false;
    }

    const request = {
      username: username,
      token: token,
    };
    setLoading(true);
    dispatch(resetUsername(request))
      .then(async (response) => {
        if (response.status === true) {
          toast.success(response.message);
          navigate("/200/reset-username");
        } else if (response.status === false) {
          toast.error(response.error);
        } else {
          setUsername("");
          toast.error(response.data.errors);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error(t("Please Try again"));
      });
  };

  return (
    <>
      <div className="login-page-section">
        <Form>
          <Row className="justify-content-center">
            <Col xs="12" sm="12" md="8" lg="6" xl="5">
              <div className="login-page-card">
                {/* <img src={logo} /> */}
                <span className="login-card-title">{t("Reset Username")}</span>
                <span className="login-card-subtitle">
                  {t("Set New Username To Your Account")}
                </span>
                <div className="login-card-line-breaker"> </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="dashboard-group-label">
                    {t("New Username")}
                  </Form.Label>
                  <Form.Control
                    className="dashboard-group-input"
                    type="username"
                    placeholder=""
                    value={username}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Button
                    id="common-btn"
                    variant="primary"
                    type="submit"
                    style={{ padding: "12px" }}
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {t("Reset")}
                  </Button>{" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <span>{t("Login Here")} ?</span>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <br />
        </Form>
      </div>
    </>
  );
};

export default ResetUsername;
