// React-Select Custome Style
export const customStyles = {
    control: base => ({
        ...base,
        height: 34,
        minHeight: 34,
        // borderRadius: '8px',
    }),
    singleValue: base => ({
        ...base,
        top: '45%'
    }),
    indicatorSeparator: base => ({
        ...base,
    }),
    indicatorsContainer: base => ({
        ...base,
        height: 34,
    }),
};