import { EncryptOrDecrypt } from "../../../services/commonService";

const CUSTOM_COMPANY_NAME_MAPPINGS = {
  starlight: "StarLight",
  allied: "Allied Trust",
};

export const getCompanyName = () => {
  try {
    const encryptedUser = localStorage.getItem("user");
    if (!encryptedUser) return "";
    const user = EncryptOrDecrypt(encryptedUser, "D");
    const companyName = user?.companyDetails?.company_name || "";

    if (typeof companyName === "string") {
      const lowerCaseName = companyName.toLowerCase();
      for (const [key, value] of Object.entries(CUSTOM_COMPANY_NAME_MAPPINGS)) {
        if (lowerCaseName.includes(key)) {
          return value;
        }
      }
      // Default case: return the original company name if no mapping is found
      return companyName;
    }
    // If companyName is not a string, return an empty string
    return "";
  } catch (error) {
    console.error("Error:", error);
    return "";
  }
};

export const getArticle = (companyName) => {
  if (!companyName) return "a";
  const firstLetter = companyName.charAt(0).toLowerCase();
  return ["a", "e", "i", "o", "u"].includes(firstLetter) ? "an" : "a";
};
