import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import "./quote-start.scss";
import { CreateQuoteContext, useMyContext } from "../CreateQuote";
import { useTranslation } from "react-i18next";
import { getGeoCode } from "../utility/googleAddressHelper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EncryptOrDecrypt } from "../../../../services/commonService";
import { getAgentAuthorizeState } from "../../../../services/floodService";
import { toast } from "react-toastify";
import { Loader, LoadingOverlay } from "react-overlay-loader";

export const QuoteStart = () => {
  const {
    locationQuoteObject,
    setLocationQuoteObject,
    inputValue,
    setInputValue,
    setProductSelection,
    setManualEntry,
  } = useMyContext(CreateQuoteContext);
  useEffect(() => {
    setProductSelection(false);
  }, []);

  const { productID } = useParams();
  const { coreSystem } = useSelector((state) => state);
  const navigation = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = EncryptOrDecrypt(localStorage.getItem("user"), "D");
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    const userAccess = userData.accessRights
      ? userData.accessRights.levelName
      : "";
    let params = {
      'userPersonInfoFK': userData.n_PersonInfoId_FK,
      'product':  coreSystem?.applicationData?.n_ProductId_FK || "26",
      'state': locationQuoteObject?.stateCode
    };
    if (userAccess !== "EMPLOYEE") {
      setLoading(true);
      await dispatch(getAgentAuthorizeState(params)).then((res) => {
        setLoading(false);
        if (res.userAuthorizedState === false) {
          toast.error(
            t("State Not Authorized To Agent!", {
              position: "top-center",
              toastId: "stateNotAuthorized",
            })
          );
        } else if (res.userAuthorizedState && inputValue) {
          setLoading(false);
          navigation(`/quote/${productID}/create/stepper`);
        }
      });
    } else {
      if (inputValue) navigation(`/quote/${productID}/create/stepper`);
    }
  };
  const displayTextMapper = {
    poultonXS: "POULTON XS",
    hiscox: "HISCOX",
  };
  const geoCallar = async () => {
    if (inputValue != null) {
      const propData = await getGeoCode(
        inputValue?.value?.description,
        locationQuoteObject
      );

      setLocationQuoteObject({
        ...propData,
        full_address: inputValue.value.description,
      });
    }
  };
  useEffect(() => {
    geoCallar();
  }, [inputValue]);

  return (
    <>
      <div style={{ minHeight: "15vh" }}></div>
      <LoadingOverlay>
        <Loader loading={loading} />
        <Container
          style={{
            borderRadius: "10px",
            width: "60%",
            minHeight: "35vh",
            margin: "auto",
            backgroundColor: "white",
            boxShadow: "3px 3px 5px 6px #ccc",
            padding: "10px",
          }}
          className="d-flex align-items-center justify-content-center pt-5"
        >
          <section className="quort-start-section">
            <Row className="justify-content-center">
              <Col xs="12" sm="12" md="20" lg="18" xl="18" className="p-form">
                <div
                  className="hero-section-headings"
                  style={{ marginTop: "12px" }}
                >
                  <h6
                    className="h-1"
                    style={{
                      fontSize: "40px",
                      marginTop: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {displayTextMapper[productID]} QUOTES
                  </h6>
                  {/* <h4 style={{ marginTop: "16px" }}>THE HISCOX QUOTES</h4> */}
                </div>
                <div
                  className=" justify-content-center"
                  style={{ width: "25vw", margin: "auto", marginTop: "16px" }}
                >
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                    name="personAddr.s_StreetName"
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ["us"],
                      },
                    }}
                    selectProps={{
                      onChange: (e) => {
                        setInputValue(e);
                      },

                      placeholder: "Search Address...",

                      styles: {
                        placeholder: (provided) => ({
                          ...provided,
                          color: "gray",
                          overflow: "hidden",
                        }),
                      },
                    }}
                  />
                  <div className="pt-2">
                    <span
                      onClick={() => {
                        setManualEntry(true);
                        navigation(`/quote/${productID}/create/stepper`);
                      }}
                      className="pt-2"
                      style={{
                        color: "gray",
                        fontSize: "12px",
                        textDecoration: "underline",
                        paddingLeft: "0.2rem",
                        cursor: "pointer",
                      }}
                    >
                      {t("Manual Address Entry")}
                    </span>
                  </div>
                </div>

                <div>
                  <Col xs="12" className="form-btns">
                    <div className="flood-bts-div pb-1">
                      <Button
                        disabled={inputValue == null}
                        id="primary-colored-btn"
                        variant="primary"
                        onClick={handleNext}
                      >
                        {t("Next")}
                      </Button>{" "}
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </LoadingOverlay>
    </>
  );
};
