export const propertySchema = {
  ageOfBuilding: null,
  plumbingUpdates: null,
  yearBuilt: null,
  noOfStories: null,
  noOfUnits: null,
  ppc: "",
  roofingUpdates: "",
  wiringUpdates: "",
  bbaRating: "",
  threeYearLossCount: "",
  petAnimals: "",
  roofWarrenty: "NO",
  convertedStructure: "NO",
  fireSprinklers: "NO",
  // fireDiscountSys: false,
  // fireAlarmSystem: "",
  // burglarAlarmSys: false,
  // burglarAlarmSystem: "",
  // hasIOTSys: false,
  // iotDevice: "",
  squareFootage: "",
  roofDeckAttachment: "",
  roofWallAnchorage: "",
  roofGeometry: "",
  roofCovering: "",
  exteriorWalls: "",
  hasPets: false,
  constructionType: "",
  securityService: "",
  maintenanceService: "",
  elevatorSafety: "",
  closingHour: "",
  smokingPolicy: "",
  locationAddress: "",
  locationCity: "",
  locationState: "",
  locationZip: "",
  locationCounty: "",
  locationStatePk: 0,
  locationCountyPk: 0,
  locationCityPk: 0,
  locationIndetifier: "",
};
