import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import AvatarPolicyTab from "./AvatarPolicyTab";
import CoBrandingTab from "./CoBrandingTab";
import DocumentTab from "./DocumentTab";
import "./marketing-tools.scss";
import SocialMediaTab from "./SocialMediaTab";

const MarketingTools = () => {
  return (
    <div className="marketing-tools-page-section">
      <h5 className="marketing-tools-page-title">Marketing Tools</h5>

      <Row>
        <Col xs="12" className="custom-tabs">
          <Tabs defaultActiveKey="Documents" id="uncontrolled-tab-example">
            <Tab eventKey="Documents" title="Documents">
              <DocumentTab />
            </Tab>
            <Tab
              eventKey="Avatar Policy Cover Letter"
              title="Avatar Policy Cover Letter"
            >
              <AvatarPolicyTab />
            </Tab>
            <Tab eventKey="Co-Branding Documents" title="Co-Branding Documents">
              <CoBrandingTab />
            </Tab>
            <Tab eventKey="Social Media" title="Social Media">
              <SocialMediaTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default MarketingTools;
