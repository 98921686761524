import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Box,
  Spinner,
  Center,
  VStack,
} from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export const EndorseConfirmation = ({
  isOpen,
  setOpenConfirmationModal,
  setShowModal,
  handleTransaction,
  isLoading,
  setNoPremiumChangeEndorse,
  noPremiumChangeEndorse  ,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (noPremiumChangeEndorse) {
      handleTransaction();
    }
  }, [noPremiumChangeEndorse]);

  return (
    <>
      <Modal
        isCentered
        size={"2xl"}
        isOpen={isOpen}
        onClose={() => setOpenConfirmationModal(false)}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"2xl"}>
          <ModalHeader bg={"agGridHeaderBg"} borderTopRadius={"2xl"}>
            <Flex justifyContent={"center"} gap={2} alignItems={"center"}>
              <Box mb={4}>
                {" "}
                <FiAlertCircle />
              </Box>
              <Text>{t("Endorsement Inquiry")}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          {isLoading ? (
            <Center p={10}>
              <VStack>
                <Spinner size="xl" color="primary.500" />
                <Text>{t("Creating Endorsement")}...</Text>
              </VStack>
            </Center>
          ) : (
            <>
              <ModalBody p={4}>
                <Text>
                  {t(
                    "Does this endorsement require any changes to coverage or limits?"
                  )}
                </Text>
              </ModalBody>

              <ModalFooter className="space-x-2" gap={2}>
                <Button
                  color="black"
                  variant={"outline"}
                  borderRadius={"xl"}
                  leftIcon={<AiFillCheckCircle color="gray" size={20} />}
                  onClick={() => {
                    setOpenConfirmationModal(false);
                    setShowModal(true);
                  }}
                  borderColor="var(--createQuoteHeaderButton)"
                  isLoading={isLoading}
                >
                  {t("Yes")}
                </Button>
                <Button
                  color="black"
                  variant={"outline"}
                  onClick={() => {
                    setNoPremiumChangeEndorse(true);
                  }}
                  borderRadius={"xl"}
                  leftIcon={<AiFillCloseCircle color="gray" size={20} />}
                  borderColor="var(--createQuoteHeaderButton)"
                  isLoading={isLoading}
                >
                  {t("No")}
                </Button>
                <Button
                  color="black"
                  variant={"outline"}
                  onClick={() => setOpenConfirmationModal(false)}
                  borderRadius={"xl"}
                  leftIcon={<AiFillCloseCircle color="gray" size={20} />}
                  isLoading={isLoading}
                  borderColor="var(--createQuoteHeaderButton)"
                >
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
