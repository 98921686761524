import { Box } from "@chakra-ui/react";
import { QuotationCardLayout } from "../common/QuotationCardLayout";
import { QuoteProvider, useQuote } from "./CommCreateQuote";
import { Policy } from "./components/Policy/Policy";
import { Footer } from "./components/Footer/Footer";
import ChakraLoader from "../create-star-quote/utils/ChakraLoader";
import { Underwriting } from "./components/Underwriting/Underwriting";
import { Discounts } from "./components/Discounts/Discounts";
import { Coverages } from "./components/Coverages/Coverages";
import { Confirmations } from "./components/Confirmations/Confirmations";
import { Agreements } from "./components/Agreements/Agreements";
import { Payment } from "./components/Payments/Payment";
import { BreadCrumbs } from "../common/BreadCrumbs";

const components = [
  <Policy />,
  <Underwriting />,
  <Discounts />,
  <Coverages />,
  <Confirmations />,
  <Agreements />,
  <Payment />,
];

const Layout = () => {
  const {
    activeStep,
    location,
    isLoading,
    autoGenLoading,
    masterQuoteData,
    steps,
  } = useQuote();
  return (
    <>
      <ChakraLoader isLoading={isLoading || autoGenLoading}>
        <QuotationCardLayout
          quoteId={masterQuoteData?.quoteNumber || "TMPXXXXXXXXXXXX"}
          address={location.fullAddress || ""}
          displayName={masterQuoteData?.applicantDetails?.lastName || ""}
          amount={masterQuoteData?.premiumToDisplay || 0}
        >
          <Box ml="2rem">
            <BreadCrumbs
              steps={steps}
              activeStep={activeStep}
              crumbWidth="13.5%"
              margin="0 0 0 5px"
            />
          </Box>
          <Box p={10}> {components[activeStep]}</Box>
          <Box>
            <Footer />
          </Box>
        </QuotationCardLayout>
      </ChakraLoader>
      <style>
        {`.inputSelectBoxes {
            border: 2px solid var(--chakra-colors-gray-400);
            border-radius: 12px;
            padding-right: 10px;
            padding-left: 15px;
            width: 100%;
            height: 45px;
        } .chakra-switch {
            width: fit-content !important;
            margin-top: 1.5px;
          } .chakra-switch .chakra-switch__track::after {
            content: "NO" !important;
            color: var(--chakra-colors-white);
            display: block;
            position: absolute;
            top: 15px;
            left: 58%;
            font-size: 11px;
            font-weight: bold;
          } .chakra-switch[data-checked] .chakra-switch__track::after {
            content: "YES" !important;
            left: 7.7%;
            font-size: 10px;
            font-weight: normal;
          }
            ::-webkit-scrollbar {
            width: 5px;
            }
          ::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb {
              background: #888;
              border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb:hover {
              background: #555;
          } 
          ::-webkit-scrollbar-thumb:active {
              background: #333;
          }
        `}
      </style>
    </>
  );
};

export const CommCreateQuote = () => {
  return (
    <QuoteProvider>
      <Box mb={8}>
        <Layout />
      </Box>
    </QuoteProvider>
  );
};
