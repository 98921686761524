import React from "react";
import Header from "./header/Header";
import AdvancedHeader from "./advancedHeader/AdvancedHeader";
import Sidebar from "./sidebar/Sidebar";
import AmazonConnectChatWidget from "../../pages/dashboard/help/AmazonConnectChatWidget";
import { isTenancyEnabledForAmazonChat, isTenancyEnabledForNewLayout } from "../../pages/common/Helper";

import "./layout.scss";
import { Box, Flex } from "@chakra-ui/react";
import { ToastContainer, toast } from 'react-toastify';

const MainLayout = ({ children, ...rest }) => {
  
  return (
    isTenancyEnabledForNewLayout ? (
      <Flex
        width="100%"
        height="100%"
        direction="column"
        minHeight="100vh"
        m="auto" 
      >
          <ToastContainer
                  style={{
                    zIndex: 20000
                  }}
                  theme={'light'}
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
          <Box position={"sticky"} width={"100%"} top={0} zIndex={1000} background={"transparent"}>
            <AdvancedHeader />
          </Box>
        <Box flex="1" maxWidth="1440px" width="100%" height="auto" m="auto">
          <Box mx="2rem">
            {children}
          </Box>
        </Box>
        {isTenancyEnabledForAmazonChat && <AmazonConnectChatWidget />}
      </Flex>
    ) : (<div className="dashboard-container">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="header-container">
        <Header />
      </div>
      <div className="dashboard-content-container">{children}</div>
      {isTenancyEnabledForAmazonChat && <AmazonConnectChatWidget />}
    </div>
    )
  )
};

export default MainLayout;
