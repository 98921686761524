import Http from "../../../../Http";

export function postEndorsePolicy({ requestBody,productId }) {
  return new Promise((resolve, reject) => {
    const url = `api/v2/policy/hiscox/endorse/${productId}`;
    Http.post(url, requestBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error("Error fetching base data:", err);
        reject(err);
      });
  });
}
