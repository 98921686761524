import React, { useState, useEffect } from "react";
import "./license.scss";
// import { Row, Col, Button, Form, Modal, Table} from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "react-datepicker/dist/react-datepicker.css";
import { getCeLicenseData } from "../../services/ceDocumentService";
import {getViewDocument } from "../../services/producerService";
import Moment from 'moment';
import {EncryptOrDecrypt } from "../../services/commonService";
import { stateList } from "../../services/enrollService";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select } from "@chakra-ui/react";
import { t } from "i18next";
import { formateDate } from "../common/FormatDate";
import { FaEye } from "react-icons/fa";

const ViewCELicense = (props) => {
	
	const dispatch = useDispatch();
    const [courseCompletionDate, setCourseCompletionDate] = React.useState();
    const [courseExpirationDate, setCourseExpirationDate] = React.useState('');
    const [docname, setDocName] = useState('');
    const [courseHours, setCourseHours] = useState('');
    const [courseNumber, setCourseNumber] = useState('');
    const [courseName, setCourseName] = useState('');
    const [trainingProviderNumber, setTrainingProviderNumber] = useState('');
    const [trainingProviderName, setTrainingProviderName] = useState('');
    const [individualLicenseNumber, setIndividualLicenseNumber] = useState('');
    const [residentialState, setResidentialState] = useState(null);
    const userDatas = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
	const [states,setStates] = useState([])
	const [isShow, setIsShow] = useState(true);
	const [docPk, setDocPk] = useState([]);
	const [docData, setDocData] = useState([]);
	const [systemSource, setSystemSource] = useState('');
	const [issueDate, setIssueDate] = useState();
	const [expireDate, setExpireDate] = useState();
	const [fileFromDb, setFileFromDb] = useState();

	useEffect(() => {
		setIsShow(true);
        (async () => {
            const stateData = await dispatch(stateList())
            setStates(stateData.data)
        })();
    }, [props])
	
	const viewDocument = async (e) => {
		e.preventDefault();
		if(docData.length > 0){
			docData?.map(async (doc) => {
				let data = await getViewDocument(doc.DocInfo_PK, doc.systemSource, 'Producer');
		        let docUrl = data.data;
		        if (docUrl != '') {
		            window.open(docUrl);
		        }
			});
			
		}else{
			toast.error('Document Not Available');
		}
        
    }

	useEffect(() => {
        (async () => {
            let rowId = props?.rowData?.id ?? 0;
			if(rowId){
				let getFileData =  await getCeLicenseData(rowId);
		        if(getFileData.success == true){
					let metadata = getFileData?.data?.metadata;
					setResidentialState(userDatas?.residentState || null);
					setIndividualLicenseNumber(metadata?.LicenseNo || '');
					setTrainingProviderName(metadata?.TrainingProvider || '');
					setTrainingProviderNumber(metadata?.TrainingProviderNo || '');
					setCourseName(metadata?.CourseName || '');
					setCourseNumber(metadata?.CourseNumber || '');
					setCourseHours(metadata?.CourseHours || '');
					setDocName(getFileData?.data?.Doc_Name || '');
					setCourseCompletionDate(new Date(Moment(metadata?.CourseCompletionDate)) || '');
					setCourseExpirationDate(new Date(Moment(metadata?.CourseExpirationDate)) || '');
					setIssueDate(metadata?.issue_date);
					setExpireDate(metadata?.expiry_date);
					setFileFromDb(getFileData?.data?.Doc_OriginalName);
					
					var docData = [];
					getFileData?.data?.docInfo?.map((docInfoData) => {
						docData.push(docInfoData);
					});
					setDocData(docData);
		        }
			}
        })();
    }, [props])
	
    return (
      <Box>
        <Modal
          size={"5xl"}
          isOpen={isShow}
          onClose={() => {
            setIsShow(false);
            props?.toggle();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>
              {t("View CE Information")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid
                mb={6}
                alignItems={"end"}
                templateColumns="repeat(3, 1fr)"
                gap={6}
              >
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Company")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      value={userDatas?.companyDetails?.company_name || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Individual Producer Name")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      value={userDatas?.s_ScreenName || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Residential State")}:</FormLabel>
                    <Select
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      icon={""}
                      onChange={(e) => setResidentialState(e.target.value)}
                      value={residentialState}
                      isDisabled
                    >
                      <option value={""}></option>
                      {states.map((state) => {
                        return (
                          <option value={state.n_StateId_PK}>
                            {state.s_StateDisplayName} ({state.s_StateName})
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Individual License Number")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) =>
                        setIndividualLicenseNumber(e.target.value)
                      }
                      value={individualLicenseNumber || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Training Provider Name")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) => setTrainingProviderName(e.target.value)}
                      value={trainingProviderName || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Training Provider Number")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) =>
                        setTrainingProviderNumber(e.target.value)
                      }
                      value={trainingProviderNumber || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Flood Course Name")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) => setCourseName(e.target.value)}
                      value={courseName || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Flood Course Number")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) => setCourseNumber(e.target.value)}
                      value={courseNumber || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Flood Course Hours")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      onChange={(e) => setCourseHours(e.target.value)}
                      value={courseHours || ""}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>{t("Flood Course Completion Date")}:</FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      name="issue date"
                      value={
                        courseCompletionDate
                          ? formateDate(courseCompletionDate)
                          : ""
                      }
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>
                      {t("State Flood CE Expiration Date")}:
                    </FormLabel>
                    <Input
                      className="form-control producer-group-input dashboard-group-input input-producer"
                      type="text"
                      value={
                        courseExpirationDate
                          ? formateDate(courseExpirationDate)
                          : ""
                      }
                      isDisabled
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Flex mb={6}>
                <Button
                  size={"sm"}
                  leftIcon={<FaEye size={18} />}
                  onClick={(e) => viewDocument(e)}
                >
                  {t("View Document")}
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
        {/* <Modal
	        className="custom-dashboard-modal"
	        size="lg"
	        show={isShow}
	      >
	        <Modal.Header closeButton  onClick={() => {setIsShow(false); }} className="custom-dashboard-modal-header">
	          <Modal.Title>View CE Information</Modal.Title>
	        </Modal.Header>
	        <Modal.Body className="custom-dashboard-modal-body" style={{ border:'1px solid black' }}>
			<Row>
                <Col xs="12">
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Company:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                value={userDatas?.companyDetails?.company_name || ''}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Individual Producer Name:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input-small"
                                type="text"
								value={userDatas?.s_ScreenName || ''}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Residential State:
                            </Form.Label>
						</Col>
						<Col xs="6">
						  	<Form.Select
					        	className="table-show-entries-select"
					        	onChange={(e) => setResidentialState(e.target.value)}
								value={residentialState}
								disabled
					      	>
								<option >Please Select State</option>
						        {states.map((state) => {
									
						          return  <option value={state.n_StateId_PK}>
								   {state.s_StateDisplayName} ({state.s_StateName}) 
								  </option>
						        })}
					      	</Form.Select>
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Individual License Number:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                onChange={(e) => setIndividualLicenseNumber(e.target.value)}
								value={individualLicenseNumber}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Training Provider Name:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                onChange={(e) => setTrainingProviderName(e.target.value)}
								value={trainingProviderName}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Training Provider Number:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                onChange={(e) => setTrainingProviderNumber(e.target.value)}
								value={trainingProviderNumber}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Flood Course Name:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                onChange={(e) => setCourseName(e.target.value)}
								value={courseName}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Flood Course Number:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
								required
                                onChange={(e) => setCourseNumber(e.target.value)}
								value={courseNumber}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Flood Course Hours:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<Form.Control
                                className="dashboard-group-input"
                                type="text"
                                onChange={(e) => setCourseHours(e.target.value)}
								value={courseHours}
								disabled
                            />
						</Col>
					</Row>
					
					

					
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							Flood Course Completion Date:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<DatePicker
                                id="dashboard-datepicker"
                                className="datepicker-full-width dashboard-datepicker-main-bg"
                                name="issue date"
                                selected={courseCompletionDate}
								onKeyDown={(e) => {
									e.preventDefault();
								 }}
								disabled
                            />
						</Col>
					</Row>
					<Row style={{'padding':'1px'}}>
						<Col xs="6" className="textAlignPadding">
							<Form.Label className="small">
							State Flood CE Expiration Date:
                            </Form.Label>
						</Col>
						<Col xs="6">
							<DatePicker
                                id="dashboard-datepicker"
                                className="datepicker-full-width dashboard-datepicker-main-bg"
                                name="issue date"
                                selected={courseExpirationDate}
								disabled
                            />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col xs="12">
                            <Table>
                                <tr>
                                    <th style={{ textAlign: "center" }}><Button id="common-btn" variant="primary" onClick={(e) => viewDocument(e)}>
                                    
		                                View Document
                                    </Button>{" "}</th>
                                </tr>
                            </Table>
                        </Col>
					</Row>
                </Col>
            </Row>
        </Modal.Body>
      </Modal> */}
      </Box>
    );
}

export default ViewCELicense;