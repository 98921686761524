import React, {useEffect} from "react";
import InstaProperty from "./Insta-Property/InstaProperty";
import Contact from "../instaQuote/Contact-Information/Contact";
import Discount from "./Discount-Question/Discount";
import Front from "./Front";
import BuyPlan from "./BuyPlan/BuyPlan";
import HomeUpdate from "./Home-Update/HomeUpdate";
import PropertyInformation from "./PropertyInformation/PropertyInformation";
import PriorInformation from "./Prior-Information/PriorInformation";
import ClaimInformation from "./Claim-Information/Claiminformation";
import MilingAdress from "./Mailing-Address/MilingAddress";
import Mortage from "./Mortage/Mortage";
import AccountInfo from "./Account-Information/AccountInfo";
import {getEditEmptyInstantQuoteData} from "../../../services/instaQuote";
import Sign from "./Sign-off/Sign";
import Sign2 from "./Sign-off/Sign2";
import Payment from "./Pay-off/Payment";
import { useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from "lodash";

const InstaSteps = (props) => {
    let { number } = useParams();
    let view = null;
    const dispatch = useDispatch();

    useEffect(() => {
        const allData = props.emptyQuote;
        if(!isEmpty(props.emptyQuote)){
            if(!isEmpty(props.emptyQuote.propertyInfo) && !isEmpty(props.propertyDataFromAddressInstant)){
                allData.propertyInfo.n_AreaSqtft = props.emptyQuote.propertyInfo.n_AreaSqtft != null ? props.emptyQuote.propertyInfo.n_AreaSqtft : props.propertyDataFromAddressInstant.PropDetails.TOT_LVG_AR;
                allData.propertyInfo.n_YearBuilt = props.emptyQuote.propertyInfo.n_YearBuilt != null ? props.emptyQuote.propertyInfo.n_YearBuilt : props.propertyDataFromAddressInstant.PropDetails.ACT_YR_BLT;
                allData.propertyInfo.n_NoOfStories = props.emptyQuote.propertyInfo.n_NoOfStories != null ? props.emptyQuote.propertyInfo.n_NoOfStories : props.propertyDataFromAddressInstant.PropDetails.NO_RES_UNTS;
                allData.propertyInfo.s_ConstructionType = props.emptyQuote.propertyInfo.s_ConstructionType != null ? props.emptyQuote.propertyInfo.s_ConstructionType : props.propertyDataFromAddressInstant.ConstructionType;
                allData.propertyInfo.s_UsageTypeCode = props.emptyQuote.propertyInfo.s_UsageTypeCode != null ? props.emptyQuote.propertyInfo.s_UsageTypeCode : props.propertyDataFromAddressInstant.Usage;
                if(props.propertyDataFromAddressInstant.RoofType == 'COMPOSITION' || props.propertyDataFromAddressInstant.RoofType == 'WOODSHAKE'){
                    allData.propertyInfo.s_RoofType = props.emptyQuote.propertyInfo.s_RoofType != null ? props.emptyQuote.propertyInfo.s_RoofType : '3TAB';
                }else{
                    allData.propertyInfo.s_RoofType = props.propertyDataFromAddressInstant.RoofType
                }
            }

            if(!isEmpty(props.emptyQuote.personInfo) && !isEmpty(props.propertyDataFromAddressInstant)){
                allData.personInfo.s_FullLegalName = props.emptyQuote.personInfo.s_FullLegalName != null ? props.emptyQuote.personInfo.s_FullLegalName : props.propertyDataFromAddressInstant.PropDetails.OWN_NAME;
                
            }

            if(!isEmpty(props.propertyDataAutocompleteStored)){
                allData.mapData.autocomplete = props.propertyDataAutocompleteStored.autocomplete;
                allData.mapData.latitude = props.propertyDataAutocompleteStored.latitude;
                allData.mapData.longitude = props.propertyDataAutocompleteStored.longitude;
                allData.mapData.street_number = props.propertyDataAutocompleteStored.street_number;
                allData.mapData.street_name = props.propertyDataAutocompleteStored.street_name;
                allData.mapData.state = props.propertyDataAutocompleteStored.state;
                allData.mapData.country = props.propertyDataAutocompleteStored.country;
                allData.mapData.city = props.propertyDataAutocompleteStored.city;
                allData.mapData.PostalCode = props.propertyDataAutocompleteStored.PostalCode;
                allData.mapData.CountyName = props.propertyDataAutocompleteStored.CountyName;
                allData.personAddr.s_CityName = props.propertyDataAutocompleteStored.city;
                allData.personAddr.s_CountyName = props.propertyDataAutocompleteStored.CountyName;
                allData.personAddr.s_StateName = props.propertyDataAutocompleteStored.state;
                allData.personAddr.s_CountryName = props.propertyDataAutocompleteStored.country;
                allData.personAddr.s_StreetName = props.propertyDataAutocompleteStored.street_name;
                allData.personAddr.s_PostalCode = props.propertyDataAutocompleteStored.PostalCode;
                allData.personAddr.s_PostalCodeSuffix = props.propertyDataAutocompleteStored.s_PostalCodeSuffix;
                allData.personAddr.s_HouseNo = props.propertyDataAutocompleteStored.street_number;
                allData.personAddr.s_Latitude = props.propertyDataAutocompleteStored.latitude ? parseFloat(props.propertyDataAutocompleteStored.latitude) : "";
                allData.personAddr.s_Longitude = props.propertyDataAutocompleteStored.longitude ? parseFloat(props.propertyDataAutocompleteStored.longitude) : "";
                allData.personAddr.s_AddressLine1 = props.propertyDataAutocompleteStored.street_number + ' ' + props.propertyDataAutocompleteStored.street_name;
                
            }

            if(!isEmpty(props.propertyDataFromAddressInstant)){
                allData.personAddr.s_ParcelId = props.propertyDataFromAddressInstant.PropDetails.PARCEL_ID;
                
            }

            dispatch(getEditEmptyInstantQuoteData(allData));
        }
    }, [props.emptyQuote])

    const handleChange = (e) => {
        const { name, value, title, attributes } = e.target;
        var quoteDataVar = props.emptyQuote;
        if (title === 'riskRenovationData') {
            var subtitle = attributes.subtitle.value;
            var renovationcode = attributes.renovationcode.value;

            const re = /^[0-9\b]+$/;
            if (value === '' || re.test(value)) {
                quoteDataVar[title][subtitle][name] = value;
            }

            quoteDataVar[title][subtitle]['s_RenovationCode'] = renovationcode;
        } else {
            quoteDataVar[title][name] = value;
        }
        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    const handleSwitchChange = (e) => {
        const { name, attributes, checked } = e.target;
        var parentObjectName = attributes.parentobject.value;
        var subparent = attributes.subparent ? attributes.subparent.value : '';
        var quoteDataVar = props.emptyQuote;
        if (checked) {
            if (parentObjectName === 'uwData') {
                quoteDataVar[parentObjectName][subparent][name] = attributes.checkedvalue.value;
                quoteDataVar[parentObjectName][subparent]['n_PRPolicyAddStatQuestions_FK'] = attributes.questionId.value;
            } else {
                quoteDataVar[parentObjectName][name] = attributes.checkedvalue.value;
            }
        } else {
            if (parentObjectName === 'uwData') {
                quoteDataVar[parentObjectName][subparent][name] = attributes.unCheckedValue.value;
                quoteDataVar[parentObjectName][subparent]['n_PRPolicyAddStatQuestions_FK'] = attributes.questionId.value;
            } else {
                quoteDataVar[parentObjectName][name] = attributes.unCheckedValue.value;
            }
        }

        if (parentObjectName === 'uwData' && subparent == 'priorPolicyQuestionInfo' && checked === false) {
            quoteDataVar['policyInfo']['s_PriorPolicyNo'] = '';
            quoteDataVar['policyInfo']['s_PriorCarrierName'] = '';
            quoteDataVar['policyInfo']['d_PriorExpDate'] = '';
        }
        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    const handleDateChange = (date, name, parent) => {
        var quoteDataVar = props.emptyQuote;
        quoteDataVar[parent][name] = date;
        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    const setDefaultData = (objectName, objectValue) => {
        var quoteDataVar = props.emptyQuote;
        quoteDataVar[objectName][objectName] = objectValue;
        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    const setPriorAddrressData = (data) => {
        var quoteDataVar = props.emptyQuote;
        quoteDataVar['priorAddr']['s_CountyName'] = data[0].s_CountyName ? data[0].s_CountyName : '';
        quoteDataVar['priorAddr']['s_CityName'] = data[0].s_CityName ? data[0].s_CityName : '';
        quoteDataVar['priorAddr']['s_PostalCode'] = data[0].s_PostalCode ? data[0].s_PostalCode : '';
        quoteDataVar['priorAddr']['s_PostalCodeSuffix'] = data[0].s_PostalCodeSuffix ? data[0].s_PostalCodeSuffix : '';
        quoteDataVar['priorAddr']['n_CityId_FK'] = data[0].n_CityId_FK ? data[0].n_CityId_FK : '';
        quoteDataVar['priorAddr']['n_CountryId_FK'] = data[0].n_CountryId_FK ? data[0].n_CountryId_FK : '';
        quoteDataVar['priorAddr']['n_CountyId_FK'] = data[0].n_CountyId_FK ? data[0].n_CountyId_FK : '';
        quoteDataVar['priorAddr']['n_StateId_PK'] = data[0].n_StateId_PK ? data[0].n_StateId_PK : '';
        quoteDataVar['priorAddr']['s_StateName'] = data[0].s_StateName ? data[0].s_StateName : '';
        quoteDataVar['priorAddr']['s_AddressLine1'] = data[0].s_AddressLine1 ? data[0].s_AddressLine1 : '';
        quoteDataVar['priorAddr']['s_HouseNo'] = data[0].s_HouseNo ? data[0].s_HouseNo : '';
        quoteDataVar['priorAddr']['s_StreetName'] = data[0].s_StreetName ? data[0].s_StreetName : '';

        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    const setMailingAddrressData = (data) => {
        var quoteDataVar = props.emptyQuote;
        quoteDataVar['mailingAddr']['s_CountyName'] = data[0].s_CountyName ? data[0].s_CountyName : '';
        quoteDataVar['mailingAddr']['s_CityName'] = data[0].s_CityName ? data[0].s_CityName : '';
        quoteDataVar['mailingAddr']['s_PostalCode'] = data[0].s_PostalCode ? data[0].s_PostalCode : '';
        quoteDataVar['mailingAddr']['s_PostalCodeSuffix'] = data[0].s_PostalCodeSuffix ? data[0].s_PostalCodeSuffix : '';
        quoteDataVar['mailingAddr']['n_CityId_FK'] = data[0].n_CityId_FK ? data[0].n_CityId_FK : '';
        quoteDataVar['mailingAddr']['n_CountryId_FK'] = data[0].n_CountryId_FK ? data[0].n_CountryId_FK : '';
        quoteDataVar['mailingAddr']['n_CountyId_FK'] = data[0].n_CountyId_FK ? data[0].n_CountyId_FK : '';
        quoteDataVar['mailingAddr']['n_StateId_PK'] = data[0].n_StateId_PK ? data[0].n_StateId_PK : '';
        quoteDataVar['mailingAddr']['s_StateName'] = data[0].s_StateName ? data[0].s_StateName : '';
        quoteDataVar['mailingAddr']['s_AddressLine1'] = data[0].s_AddressLine1 ? data[0].s_AddressLine1 : '';
        quoteDataVar['mailingAddr']['s_HouseNo'] = data[0].s_HouseNo ? data[0].s_HouseNo : '';
        quoteDataVar['mailingAddr']['s_StreetName'] = data[0].s_StreetName ? data[0].s_StreetName : '';

        dispatch(getEditEmptyInstantQuoteData(quoteDataVar));
    }

    switch(number){
        case 'address':
            view = <Front />;
            break;
        case '1':
            view = <InstaProperty handleChange={handleChange} />;
            break;
        case '2':
            view = <Discount handleSwitchChange={handleSwitchChange} />;
            break;
        case '3':
            view = <Contact handleChange={handleChange}/>;
            break;
        case '4':
            view = <BuyPlan />;
            break;
        case '5':
            view = <HomeUpdate handleChange={handleChange} setDefaultData={setDefaultData}/>;
            break;
        case '6':
            view = <PropertyInformation handleSwitchChange={handleSwitchChange} setPriorAddrressData={setPriorAddrressData}/>;
            break;
        case '7':
            view = <PriorInformation handleSwitchChange={handleSwitchChange} handleDateChange={handleDateChange} setDefaultData={setDefaultData} handleChange={handleChange}/>;
            break;
        case '8':
            view = <ClaimInformation handleSwitchChange={handleSwitchChange} setDefaultData={setDefaultData} />;
            break;
        case '9':
            view = <MilingAdress handleChange={handleChange} setMailingAddrressData={setMailingAddrressData} handleSwitchChange={handleSwitchChange}/>;
            break;
        case '10':
            view = <Mortage setDefaultData={setDefaultData}/>;
            break;
        case '11':
            view = <AccountInfo handleChange={handleChange}/>;
            break;
        case '12':
            view = <Sign />;
            break;
        case '13':
            view = <Sign2 />;
            break;
        case '14':
            view = <Payment />;
            break;
        default:
            view = <Front />;
    }

    return(
        <>
            {view}
        </>
    )
}

function mapStateToProps(state) {
    return{
        propertyDataFromAddressInstant: state.common.propertyDataFromAdd ? state.common.propertyDataFromAdd.data : {},
        emptyQuote: state.common.emptyInstantQuoteData ? state.common.emptyInstantQuoteData : {},
        propertyDataAutocompleteStored: state.common.propertyInstantDataAutocompleteStored ? state.common.propertyInstantDataAutocompleteStored : {},
    }   
}

export default connect(mapStateToProps)(InstaSteps)