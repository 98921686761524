import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner, Modal,Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import "./flood-pay-now.scss";
import { getPayGovToken } from "../../../../../services/paymentService";
import { EncryptOrDecrypt } from "../../../../../services/commonService";
import { isAchConfigAvailable, submitAchPayment } from "../../../../../services/producerService";
import { getHostUrl } from "../../../../common/HostUrl";
import { toast } from "react-toastify";
import { FaReceipt } from "react-icons/fa";
import Swal from 'sweetalert2';
import { getFemaUpdates} from "../../../../../services/dashboardService";

const tenant = window.location.host.split('.')[0];
const title = tenant.charAt(0).toUpperCase() + tenant.slice(1);

const FloodPayNow = () => {
  let { corePoTransPK } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { emptyQuoteData } = useSelector(state => state.flood);
  const [quoteNo, setQuoteNo] = useState('');
  const [policyNo, setPolicyNo] = useState('');
  const [mortgageeData, setMortgageeData] = useState({});
  const [premium, setPremium] = useState(0);
  const [policyNoIdPk, setPolicyNoIdPk] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCheckClicked, setIsCheckClicked] = useState(false);
  const [achConfigStatus, setAchConfigStatus] = useState(false);
  const [showAchModal, setShowAchModal] = useState(false);
  const [showAchSuccessModal, setShowAchSuccessModal] = useState(false);
  const [rolloverBillAlert, setRolloverBillAlert] = useState(false);
  const [femaUpdateMsg, setFemaUpdateMsg] = useState({ __html: "" });
  const [isHiatus, setIsHiatus] = useState(false);

  const user = EncryptOrDecrypt(localStorage.getItem('user'), 'D');
  const company_name = user.companyDetails.company_name ?? "";
  const company_address = user.companyDetails.company_address ?? {};
  const payment_overnight_address = user.companyDetails.payment_overnight_address ?? {};
  const devKey = process.env.REACT_APP_NODE_ENV;

  useEffect(() => {
    if (Object.keys(emptyQuoteData).length > 0) {
      setPremium(emptyQuoteData.premium || 0);
      setPolicyNo(emptyQuoteData.policyInfo.Policy_No || null);
      setQuoteNo(emptyQuoteData.policyInfo.Policy_No_Quote || null);
      setMortgageeData(emptyQuoteData.mortgageeData || [])
      setPolicyNoIdPk(emptyQuoteData.policyInfo.n_PolicyNoId_PK || null);
      setRolloverBillAlert((emptyQuoteData.propertyInfo && emptyQuoteData.propertyInfo.s_PolicyWaitingPeriod === 'ROLLOVER_RENEWAL'));
      getAchConfigStatus();
    }
  }, [emptyQuoteData]);

  const handleNavigate = (from) => {
    var path = '';
    switch (from) {
      case 'Mortgage':
        path = "/flood/thank-you";
        break;
      case 'ACH':
        path = "/flood/payment/ACH/" + corePoTransPK;
        break;
      case 'ECHECK':
        path = "/flood/payment/ECHECK/" + corePoTransPK;
        break;
      default:
        break;
    }
    path.length > 0 && navigation(path);
  }
  const handleSubmit = async (from) => {
    // for local and UAT
    // if(process.env.REACT_APP_NODE_ENV !== 'PROD') {
    //   handleNavigate(from);
    //   return false;
    // }

    var paymentType = "ACH";
    if (from === 'ECHECK') {
      paymentType = "PLASTIC_CARD";
    }

    var hostUrl = getHostUrl();

    const url_success = hostUrl + `/flood/thank-you/` + corePoTransPK;
    const url_cancel = hostUrl + `/flood/pay-now/` + corePoTransPK;

    const params = {
      'transaction_amount': premium,
      'url_success': url_success,
      'url_cancel': url_cancel,
      'payment_type': paymentType,
      'policyNo': policyNo,
      'source': "AgentSide_Quote"
    };
    setLoading(true);
    await dispatch(getPayGovToken(params)).then((res) => {
      if (res.data.token != '') {
        window.location = res.data.payment_page_url;
      }
    }).catch(() => {
      toast.error(t('Something Went Wrong!'));
    });
    setLoading(false);

  }

  const getAchConfigStatus = async () => {
    setLoading(true);
    let result = await isAchConfigAvailable();
    setLoading(false);
    setAchConfigStatus(result);
  }

  const handleAchPayment = async () => {
    setLoading(true);
    if (corePoTransPK > 0) {
      let params = {
        poTransPK: corePoTransPK,
        source: "AgentSide_Quote"
      }
      let res = await submitAchPayment(params)
      setShowAchModal(false)
      setShowAchSuccessModal(true)
    }
    setLoading(false)
  }
  
  useEffect(() => {
	checkFemaUpdates();
  }, [localStorage.getItem('lang') || 'en']);
  
  const checkFemaUpdates = async () => {
	await dispatch(getFemaUpdates()).then((res) => {
		setIsHiatus(res.data.data.isHiatus);
		if(res.data.data.isHiatus == true){
			setFemaUpdateMsg({ __html: res.data.data.hiatusSignAndPayMsg });
		}
	})
  }

  /**
   * renewal bill alert
   */
  const rRolloverBillAlert = () => {
    Swal.fire({
      title: t('Rollover Bill'),
      html: t("A renewal invoice will be generated & mailed once the policy is approved by underwriting. You will be contacted if additional information is required before an invoce can be sent.") + '<br><br>' + t('Premium can be submitted via the other methods of payment at any time.'),
      icon: 'info',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        return false;
      }
    });
  }

  return (
    <>
      <LoadingOverlay >
        <Loader loading={loading} />
        <section className="pay-now-section">
          {/* Form */}
          <Row className="justify-content-center">
            <Col
              xs="12"
              sm="12"
              md="12"
              lg="8"
              xl="8"
              className="pay-now-form-section"
            >
              <div className="pay-now-headings" id="flood-pay-now-headings">
                <h6>{t('Pay Now')}</h6>
                <div className="yearly-plan">
                  <span>
                    <span className="pn-amount" id="flood-pn-amount">
                      ${premium}
                    </span>
                    <span className="pn-slash">/</span>
                    <span className="pn-year">{t('Year')}</span><br />
                  </span>
                </div>
              </div>
              <div className="pay-now-form-main" id="flood-pay-now-form-main">
                <Row>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div className="flood-policy-no-div" >
                      <div className="flood-policy-no-row">
                        <p>
                          {t('Temp Policy Number')}
                        </p>
                        <div className="flood-policy-no">
                          {quoteNo}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div className="flood-policy-no-div" >
                      <div className="flood-policy-no-row">
                        <p>
                          {t('Policy Number')}
                        </p>
                        <div className="flood-policy-no">
                          {policyNo}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <p
                  className="payment-method-title"
                  id="flood-payment-method-title"
                >
                  {t('Please add a payment method')}
                </p>

                <div className="credit-card-section">
                  {mortgageeData.length > 0 && (
                    <Button
                      type="button"
                      variant="light"
                      className="credit-card-main"
                      style={{ marginRight: "25px" }}
                      onClick={() => handleNavigate('Mortgage')}
                    >
                      <div className="credit-card-icon3"></div>
                      <span className="credit-card-h">{t('Mortgage')}</span>
                    </Button>
                  )}
                  <Button
                    className="credit-card-main"
                    type="button"
                    variant="light"
                    style={{ marginRight: "25px" }}
                    onClick={() => handleSubmit('ECHECK')}
                  >
                    <div className="credit-card-icon1"></div>
                    <span className="credit-card-h">{t('Credit Card')}</span>
                  </Button>
                  <Button
                    className="credit-card-main"
                    variant="light"
                    type="button"
                    style={{ marginRight: "25px" }}

                    onClick={() => handleSubmit('ACH')}
                  >
                    <div className="credit-card-icon2"></div>
                    <span className="credit-card-h">{t('eCheck')}</span>
                  </Button>
                  <Button
                    className="credit-card-main"
                    variant="light"
                    type="button"
                    style={{ marginRight: "25px" }}
                    onClick={() => setIsCheckClicked(true)}
                  >
                    <div className="credit-card-icon1"></div>
                    <span className="credit-card-h">{t('Check')}</span>
                  </Button>
                  {
                    // show only on rollover renewal 
                    rolloverBillAlert && (
                      <Button
                        className="credit-card-main"
                        variant="light"
                        type="button"
                        style={{ marginRight: "25px" }}
                        onClick={() => rRolloverBillAlert()}
                      >
                        <FaReceipt size={30} color="#64d6cd"/>&nbsp;
                        <span className="credit-card-h">{t('Rollover Bill')}</span>
                      </Button>
                    )
                  }
                  {devKey !== 'PROD' && achConfigStatus != false && achConfigStatus['premium'] == true ? <Button
                    className="credit-card-main"
                    variant="light"
                    type="button"
                    onClick={() => setShowAchModal(true)}
                  >
                    <div className="credit-card-icon1"></div>
                    <span className="credit-card-h">{t('ACH')}</span>
                  </Button> : null
                  }
				  
				  {(isHiatus == true) &&
				          <>
				            <div>
				              <Alert variant="warning">
				                <div dangerouslySetInnerHTML={femaUpdateMsg}></div>
				              </Alert>
				            </div>
				          </>
				        }
                </div>
                <div
                  className="payment-method-details"
                  id="flood-payment-method-details"
                  hidden={!isCheckClicked}
                >
                  <p className="pmd-heading">
                    {t('Make your check payable to')}{" "}
                    <span className="avatar-insurance">{company_name}</span> {t('and include your policy number')}
                  </p>
                  <div className="pmd-address-div">
                    <span className="pmd-address">{t('Our Address')}:</span>
                    <div className="pmd-address-details">
                      <p>{company_address.address || ""}</p>
                      <p>{company_address.city || ""}, {company_address.state || ""} {company_address.zip || ""}</p>
                    </div>
                  </div>

                  <div className="pmd-mortage-div">
                    <span className="pmd-mortage-address">
                      {t('For Mortgagees & Late Payments, please use our Express Overnight Address')}:
                    </span>
                    <div className="pmd-mortage-details">
                      <p>Flood Processing Center</p>
                      <p>{payment_overnight_address.address || ""} </p>
                      <p>{payment_overnight_address.city || ""}, {payment_overnight_address.state || ""} {payment_overnight_address.zip || ""}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </LoadingOverlay>
      {
        showAchModal === true ?
          <>
            <Modal show={showAchModal} size="md" backdrop='static'>
              <LoadingOverlay>
                <Loader loading={loading} />
                <Modal.Header>
                  Payment Via ACH
                </Modal.Header>
                <Modal.Body>
                  <section className="pay-now-section">
                    <Row className="justify-content-center">
                      <p className="pmd-heading">
                        <b>
                          A payment in the amount of ${premium} will be withdrawn from the bank account listed in your profile
                        </b>
                      </p>
                    </Row>
                  </section>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    id="common-close-btn-sm"
                    variant="primary"
                    onClick={() => handleAchPayment()}
                  >
                    {t('Confirm')}
                  </Button>
                  <Button
                    size="sm"
                    id="common-close-btn-sm"
                    variant="secondary"
                    onClick={() => setShowAchModal(false)}
                  >
                    {t('Close')}
                  </Button>
                </Modal.Footer>
              </LoadingOverlay>
            </Modal>
          </>
          : null
      }
      {
        showAchSuccessModal === true ?
          <>
            <Modal show={showAchSuccessModal} size="md" backdrop='static'>
              <LoadingOverlay>
                <Loader loading={loading} />
                <Modal.Header>
                  Payment Via ACH
                </Modal.Header>
                <Modal.Body>
                  <section className="pay-now-section">
                    <Row className="justify-content-center">
                      <>
                        <p className="pmd-heading">
                          <b>
                            Thank You!
                          </b>
                        </p>
                        <p>
                          <b>
                            Your payment has been submitted successfully.
                          </b>
                        </p>
                        <p>
                          <b>
                            Please allow upto 24 hours for processing
                          </b>
                        </p>
                      </>
                    </Row>
                  </section>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    id="common-close-btn-sm"
                    variant="secondary"
                    onClick={() => {
                      setShowAchSuccessModal(false)
                      window.location = '/flood/thank-you'
                    }}
                  >
                    {t('Close')}
                  </Button>
                </Modal.Footer>
              </LoadingOverlay>
            </Modal>
          </>
          : null
      }
    </>
  );
};

export default FloodPayNow;
