import React, { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useDispatch } from "react-redux";
import Mortgagee from "./Mortgagee";
import Interest from "./Interest";
import Insure from "./Insure";
import { getEditEmptyQuoteData } from "../../../../../services/quoteService";
import { debounce } from "lodash";

const PolicyInterests = (props) => {
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = useState({
    additionalInterestsFlag: false,
    additionalInterests: [],
    additionalInsuredFlag: false,
    additionalInsured: [],
  });

  const debouncedDispatch = useCallback(
    debounce((data) => {
      dispatch(getEditEmptyQuoteData(data));
    }, 0),
    [dispatch]
  );

  const extractAdditionalIntrest = (input) => {
    if (!Array.isArray(input) || input.length < 1) {
      return [];
    }
    return input
      .filter(
        (item) =>
          item?.s_PersonType === "ADDITIONALINTEREST" &&
          item?.s_AddionalIntType !== "ADDITIONALINSURED"
      )
      .map((item) => ({
        ...item,
      }));
  };

  const extractAdditionalInsured = (input) => {
    if (!Array.isArray(input) || input.length < 1) {
      return [];
    }
    return input
      .filter(
        (item) =>
          item?.s_PersonType === "ADDITIONALINTEREST" &&
          item?.s_AddionalIntType === "ADDITIONALINSURED"
      )
      .map((item) => ({
        ...item,
      }));
  };

  const isAdditionalInsuredPresent = (input) => {
    if (!Array.isArray(input)) {
      return false;
    }

    return input.some(
      (item) =>
        item?.s_PersonType === "ADDITIONALINTEREST" &&
        item?.s_AddionalIntType === "ADDITIONALINSURED"
    );
  };

  const isInterestPresent = (input) => {
    if (!Array.isArray(input)) {
      return false;
    }

    return input.some(
      (item) =>
        item?.s_PersonType === "ADDITIONALINTEREST" &&
        item?.s_AddionalIntType !== "ADDITIONALINSURED"
    );
  };

  useEffect(() => {
    if (
      props?.quoteData?.additionaInsuredData &&
      Array.isArray(props?.quoteData?.additionaInsuredData) &&
      props?.quoteData?.additionaInsuredData.length > 0
    ) {
      const inputData = props?.quoteData?.additionaInsuredData;
      setConfirmation((prev) => ({
        ...prev,
        additionalInterests: extractAdditionalIntrest(inputData),
        additionalInterestsFlag: isInterestPresent(inputData),
        additionalInsured: extractAdditionalInsured(inputData),
        additionalInsuredFlag: isAdditionalInsuredPresent(inputData),
      }));
    }
  }, [props?.quoteData?.additionaInsuredData]);

  const handleInterestChange = (e, index) => {
    const { name, value, title } = e.target;
    const newAdditionalInterests = [...confirmation.additionalInterests];

    if (name === "s_AddionalIntType") {
      newAdditionalInterests[index][name] = value;
    } else if (name === "s_FirstName" || name === "s_LastOrganizationName") {
      newAdditionalInterests[index][title][name] = value;
      newAdditionalInterests[index][title]["s_FullLegalName"] =
        name === "s_LastOrganizationName"
          ? newAdditionalInterests[index][title]?.s_FirstName + " " + value
          : name === "s_FirstName"
          ? value +
            " " +
            newAdditionalInterests[index][title]?.s_LastOrganizationName
          : newAdditionalInterests[index][title]?.s_FullLegalName;
    } else {
      newAdditionalInterests[index][title][name] = value;
    }

    setConfirmation((prev) => ({
      ...prev,
      additionalInterests: newAdditionalInterests,
    }));
    updateAdditionalInsuredData(
      confirmation.additionalInsured,
      newAdditionalInterests
    );
  };

  const handleInsuredChange = (e, index) => {
    const { name, value, checked, title } = e.target;
    const newAdditionalInsured = [...confirmation.additionalInsured];

    if (name === "s_IsMailingDiff") {
      newAdditionalInsured[index]["person"][name] =
        checked === true ? "NO" : "YES";
    } else if (name === "s_FirstName" || name === "s_LastOrganizationName") {
      newAdditionalInsured[index][title][name] = value;
      newAdditionalInsured[index][title]["s_FullLegalName"] =
        name === "s_LastOrganizationName"
          ? newAdditionalInsured[index][title]?.s_FirstName + " " + value
          : name === "s_FirstName"
          ? value +
            " " +
            newAdditionalInsured[index][title]?.s_LastOrganizationName
          : newAdditionalInsured[index][title]?.s_FullLegalName;
    } else {
      newAdditionalInsured[index][title][name] = value;
    }

    setConfirmation((prev) => ({
      ...prev,
      additionalInsured: newAdditionalInsured,
    }));
    updateAdditionalInsuredData(
      newAdditionalInsured,
      confirmation.additionalInterests
    );
  };

  function updateAdditionalInsuredData(insuredData, interestsData) {
    let tempQuoteData = props.quoteData;
    tempQuoteData.additionaInsuredData = [...insuredData, ...interestsData];
    debouncedDispatch(tempQuoteData);
  }

  return (
    <>
      <Mortgagee quoteData={props.quoteData} isBind={props?.isBind} />
      <Interest
        isBind={props?.isBind}
        quoteData={props.quoteData}
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        handleInterestChange={handleInterestChange}
        updateAdditionalInsuredData={updateAdditionalInsuredData}
      />
      <Insure
        quoteData={props.quoteData}
        confirmation={confirmation}
        setConfirmation={setConfirmation}
        handleInsuredChange={handleInsuredChange}
        updateAdditionalInsuredData={updateAdditionalInsuredData}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    quoteData: state.common.emptyQuoteData ? state.common.emptyQuoteData : {},
    getBaseData: state.common.getBaseData ? state.common.getBaseData : {},
  };
}
export default connect(mapStateToProps)(PolicyInterests);
