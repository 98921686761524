import React, { useEffect, useState } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getRequiredDocList } from "../../../services/coreSystemService";
import { FaUpload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import UploadRequiredDoc from "./UploadRequiredDoc";
import Moment from 'moment';
import { Loader, LoadingOverlay } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const ListRequiredDoc = (props) => {
    const { isOpen, poTransPK, policyNumber, policyPK } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [docList, setDocList] = useState([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [selectedDocPK, setSelectedDocPK] = useState(0);
    const [selectedDocName, setSelectedDocName] = useState('');

    useEffect(() => {
        if (isOpen === true && ![undefined, null, ""].includes(poTransPK)) {
            getList();
        }
    }, [isOpen])

    const getList = async () => {
        setLoading(true);
        await dispatch(getRequiredDocList({ "poTransPK": poTransPK })).then((res) => {
            if (res.success === true) {
                setDocList(res.data.data);
            }
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            toast.error(t('Something Went Wrong!'));
        });
    }

    const handleOnClick = (row) => {
        setSelectedDocPK(row.n_PoDocumenttrackTrans_PK);
        setSelectedDocName(row.s_SourceName);
        setShowAttachmentModal(true);
    }

    const uploadOrViewFile = (cell, row) => {
        if (row.s_IsAttached == 'N') {
            return <Button
                variant="light"
                id="search-quote-btn"
                onClick={() => handleOnClick(row)}
            >
                <FaUpload size={15} />
            </Button>
        } else {
            return Moment(row.d_UpdatedDate).format('MM-DD-YYYY') || '';
        }
    }

    const columns = [
        {
            dataField: "n_PoDocumenttrackTrans_PK",
            hidden: true
        },
        {
            dataField: "s_SourceName",
            text: t("Name"),
        },
        {
            dataField: "s_IsAttached",
            text: t("Is Attached"),
        },
        {
            dataField: "n_PoDocumenttrackTrans_PK",
            text: t("Action"),
            formatter: uploadOrViewFile
        }
    ];

    const handleOnClose = (value) => {
        setShowAttachmentModal(value);
        getList();
    }
    
    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                <BootstrapTable
                    noDataIndication='No Data'
                    wrapperClasses="dashboard-table-main"
                    bootstrap4
                    keyField="n_PoDocumenttrackTrans_PK"
                    data={docList}
                    columns={columns}
                    hover
                />
            </Col>
            {
                showAttachmentModal && (
                    <Modal
                        size="xl"
                        show={showAttachmentModal}
                        className="custom-dashboard-modal"
                        onHide={() => setShowAttachmentModal(false)}
                    >
                        <Modal.Header
                            closeButton
                            className="custom-dashboard-modal-header"
                        >
                            <Modal.Title>{t('Upload Document')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                            className="custom-dashboard-modal-body"
                        >
                            <UploadRequiredDoc
                                policyNumber={policyNumber}
                                policyPK={policyPK}
                                poTransPK={poTransPK}
                                documenttrackTransPK={selectedDocPK}
                                documentName={selectedDocName}
                                setShow={handleOnClose}
                            />
                        </Modal.Body>
                    </Modal>
                )
            }
        </LoadingOverlay>
    )
}


export default ListRequiredDoc;
