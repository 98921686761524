import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import instaIcon from "../../assets/Instagram.png";
import twitterIcon from "../../assets/Twitter.png";
import linkIcon from "../../assets/Linkedin.png";
import youtubeIcon from "../../assets/YouTube.png";
import { Link } from "react-router-dom";

const CoBrandingTab = () => {
  return (
    <Fragment>
      <div className="custom-tab-header-fill">
        <span>Usefull Communication Documents</span>
      </div>
      <div className="managment-tab-body">
        <Row id="marketing-document-tab-row">
          <Col xs="12">
            <span className="cobranding-tab-title">Co-Branding Documents</span>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CoBrandingTab;
