import Http from '../Http';

export function reportTypeDD() {

	const url = "/api/reports/reportTypeDD";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.get(url)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}

export function getReportDetails(params) {
	
	const url = "/api/reports/getReportDetails";
	return dispatch => (
	  new Promise((resolve, reject) => {
		Http.post(url, params)
		  .then((res) => {
			return resolve(res.data);
		  }).catch(function (error) { // catch
			return reject(error);
		  });
	  })
	)
}