import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./policy-details.scss";
import CommonHeader from "./CommonHeader";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function PropertyInfoHiscox(props) {
  const [propertyInfo, setPropertyInfo] = useState({});
  const [applicationData, setApplicationData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(props.propertyInfo).length > 0) {
      setPropertyInfo(props.propertyInfo);
    }

    if (Object.keys(props.applicationData).length > 0) {
      setApplicationData(props.applicationData);
    }

  }, [props.propertyInfo, props.applicationData, props.baseData]);

  return (
    <Row>
      {/* <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col> */}
      
      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Policy')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Effective Date')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="effectiveDate"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(applicationData).length > 0
                      ? applicationData.d_TransEffectiveFrom
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Construction')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t("Construction Date")}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="constructionDate"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.d_DateOfConstruction
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Occupancy')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Please Select an Occupancy Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="occupancyType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                    ? propertyInfo.RA.s_Occupancy
                    : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Building And Foundation')}
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Building Foundation Type')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_Foundation"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                    ? propertyInfo.RA.metadata.foundationType
                    : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Is the building over water')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="s_BuildingOverWater"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                    ? propertyInfo.RA.s_BuildingOverWater
                    : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  Total sq footage of{" "}
                  {Object.keys(propertyInfo).length > 0 &&
                    ["RESIDENTIALUNIT20", "NONRESIDENTIALUNIT20"].includes(
                      propertyInfo.RA.s_Occupancy
                    )
                    ? "unit"
                    : "building"}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="sqFootage"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_TotalSqFootage
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Total No Of Floor')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="totalFloors"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_NoOfFloors
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Building Construction Type')}?
                </Form.Label>
                <Form.Control
                  type="text"
                  name="constructionType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.s_BldgConstructionType
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row1">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Additional Info')}</span>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(37, 33, 63, 0.3)",
              padding: "10px",
            }}
          >
            <Row>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Additional Foundation Type')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="additionalFoundationType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.metadata.additionalFoundationType
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Contents Cost Value Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contentsCostValueType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.metadata.contentsCostValueType
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Basement Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="basementType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.metadata.basementType
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="2" sm="2" md="2" lg="2" xl="2">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Attached Garbage Type')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="attachedGarageType"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.metadata.attachedGarageType
                      : ""
                  }
                  readOnly
                />
              </Col>
              <Col xs="3" sm="3" md="3" lg="3" xl="3">
                <Form.Label
                  className="dashboard-group-label"
                  style={{ marginRight: "10px" }}
                >
                  {t('Elevation Height')}:
                </Form.Label>
                <Form.Control
                  type="text"
                  name="elevationHeight"
                  className="dashboard-group-input"
                  id="select-ym-option"
                  value={
                    Object.keys(propertyInfo).length > 0
                      ? propertyInfo.RA.metadata.elevationHeight
                      : ""
                  }
                  readOnly
                />
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    applicationData: state.coreSystem.applicationData,
    propertyInfo: state.coreSystem.propertyInfo,
    baseData: state.coreSystem.baseData,
  };
};
export default connect(mapStateToProps)(PropertyInfoHiscox);
