import React from "react";
import {Link} from 'react-scroll'
import attachment from "../../../../assets/helpcenter-images/attachment.png";
import attachment2 from "../../../../assets/helpcenter-images/rectangle-4568.png";

import UpdatePicture1 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture1.png";
import UpdatePicture2 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture2.png";
import UpdatePicture3 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture3.png";
import UpdatePicture4 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture4.png";
import UpdatePicture5 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture5.png";
import UpdatePicture6 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture6.png";
import UpdatePicture7 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture7.png";
import UpdatePicture8 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture8.png";
import UpdatePicture12 from "../../../../assets/helpcenter-images/general-profile-update/UpdatePicture12.png";

import AddUserPicture1 from "../../../../assets/helpcenter-images/add-user/AddUserPicture1.png";
import AddUserPicture2 from "../../../../assets/helpcenter-images/add-user/AddUserPicture2.png";
import AddUserPicture3 from "../../../../assets/helpcenter-images/add-user/AddUserPicture3.png";
import AddUserPicture4 from "../../../../assets/helpcenter-images/add-user/AddUserPicture4.png";
import AddUserPicture5 from "../../../../assets/helpcenter-images/add-user/AddUserPicture5.png";
import AddUserPicture6 from "../../../../assets/helpcenter-images/add-user/AddUserPicture6.png";
import AddUserPicture7 from "../../../../assets/helpcenter-images/add-user/AddUserPicture7.png";
import AddUserPicture8 from "../../../../assets/helpcenter-images/add-user/AddUserPicture8.png";
import AddUserPicture9 from "../../../../assets/helpcenter-images/add-user/AddUserPicture9.png";
import AddUserPicture10 from "../../../../assets/helpcenter-images/add-user/AddUserPicture10.png";


import UpdateUserPicture1 from "../../../../assets/helpcenter-images/update-user/UpdateUserPicture1.png";
import UpdateUserPicture2 from "../../../../assets/helpcenter-images/update-user/UpdateUserPicture2.png";
import UpdateUserPicture3 from "../../../../assets/helpcenter-images/update-user/UpdateUserPicture3.png";

import DocumentsUserPicture1 from "../../../../assets/helpcenter-images/attach-documents/DocumentsUserPicture1.png";
import DocumentsPicture2 from "../../../../assets/helpcenter-images/attach-documents/DocumentsPicture2.png";
import DocumentsPicture3 from "../../../../assets/helpcenter-images/attach-documents/DocumentsPicture3.png";
import DocumentsPicture4 from "../../../../assets/helpcenter-images/attach-documents/DocumentsPicture4.png";

import addAgency from "../../../../assets/helpcenter-images/add-agency/addagency.png";
import productBar from "../../../../assets/helpcenter-images/add-agency/productbar.png";
import agencyPhoto from "../../../../assets/helpcenter-images/add-agency/addPhoto.png";
import productButton from "../../../../assets/helpcenter-images/add-agency/productbutton.png";
import productImage from "../../../../assets/helpcenter-images/add-agency/productimage.png";
import addRow from "../../../../assets/helpcenter-images/add-agency/addrowbutton.png";
import saveImage from "../../../../assets/helpcenter-images/add-agency/saveimage.png";
import {useParams } from 'react-router-dom'; 
import { getCompanyDetails } from "../../../../services/commonService";


const AgencyManagement = () => {
    const { div } = useParams();
    const selectedDiv = div;
    const hideDivObject =  {
        addaprofile:false,
        searchaprofile:false,
        updateaprofile:false,
        addauser:false,
        updateuser:false,
        attachdocuments:false,

    }
    if(selectedDiv){
        Object.keys(hideDivObject).forEach(key => {
            hideDivObject[key] = true;
        });
        hideDivObject[selectedDiv] = false;
    }

    const companyPhone = getCompanyDetails("phone");

    return(
        <div className="row">
                <div className="col-md-7">
                    <div id="addaprofile" hidden={hideDivObject.addaprofile}>
                        <p className="font-weight-bold mt-3">Add a Profile</p>
                        <p>From the dashboard, or from any screen within the system, select Producer from the list of tabs across the top of the page.</p>
                        <img src={productBar} className="img-fluid" alt />
                        <p>The Add Agency is displayed in the top right corner of the main screen. </p>
                        <img src={addAgency} className="img-fluid" alt />
                        <p>Complete the agency details in full.</p>
                        <img src={agencyPhoto} className="img-fluid" alt />
                        <p>The Products is displayed on the right vertical menu of the profile.</p>
                        <img src={productButton} className="img-fluid" alt />
                        <br /><br />
                        <img src={productImage} className="img-fluid" alt />
                        <p>Select Add Row, Select Policy product and Save.</p>
                        <img src={addRow} className="img-fluid" alt />
                        <br /><br />
                        <img src={saveImage} className="img-fluid" alt />
                    </div>
                    <div id="searchaprofile" hidden={hideDivObject.searchaprofile}>
                    <p className="font-weight-bold mt-3">Search a Profile</p>
                    <p>From the dashboard, or from any screen within the system, select Producer from the list of tabs across the top of the page
                    </p>
                    <img src={UpdatePicture1} className="img-fluid" alt />
                    <p>The Search Agency is displayed in the top right corner of the main screen</p>
                    <img src={UpdatePicture2} className="img-fluid" alt />
                    <p>Producers can be searched by Agency Code, Agency Name, FEIN Number, DBA Name, etc. </p>
                    <img src={UpdatePicture3} className="img-fluid" alt />
                    <p>Agencies matching the search criteria are displayed under Search Result.</p>
                    <img src={UpdatePicture4} className="img-fluid" alt />
                    <p>Click on the policy Agency Code to open the producer.</p>
                    <img src={UpdatePicture5} className="img-fluid" alt />
                    {/* <p>Change respective field(s) and Update Agency with the button in the top left corner of the main screen.</p> */}
                    {/* <img src={UpdatePicture6} className="img-fluid" alt /> */}
                    {/* <p>Annotate the changes in the Description box at the bottom of the profile and then select Add Log. </p> */}
                    {/* <img src={UpdatePicture7} className="img-fluid" alt /> */}
                    {/* <p>The new log will be timestamped below the detail box.</p> */}
                    {/* <img src={UpdatePicture8} className="img-fluid" alt /> */}
                    <hr />    
                    </div>
                    <div id="updateaprofile" hidden={hideDivObject.updateaprofile}>
                        <p className="font-weight-bold mt-3">Update Profile</p>
                        <p>General Profile Update including Agency Name, Agency Contact, Agency Banking, Agency License</p>
                        <p>From the dashboard, or from any screen within the system, select Producer from the list of tabs across the top of the page</p>
                        <img src={UpdatePicture1} className="img-fluid" alt />
                        <p>The Search Agency is displayed in the top right corner of the main screen.</p>
                        <img src={UpdatePicture2} className="img-fluid" alt />
                        <p>Producers can be searched by Agency Code, Agency Name, FEIN Number, DBA Name, etc.</p>
                        <img src={UpdatePicture3} className="img-fluid" alt />
                        <p>Agencies matching the search criteria are displayed under Search Result.</p>
                        <img src={UpdatePicture4} className="img-fluid" alt />
                        <p>Click on the policy Agency Code to open the producer.</p>
                        <img src={UpdatePicture5} className="img-fluid" alt />
                        <p>Change respective field(s) and Update Agency with the button in the top left corner of the main screen.</p>
                        <img src={UpdatePicture6} className="img-fluid" alt />
                        <p>Annotate the changes in the Description box at the bottom of the profile and then select Add Log. </p>
                        <img src={UpdatePicture7} className="img-fluid" alt />
                        <p>The new log will be timestamped below the detail box.</p>
                        <img src={UpdatePicture12} className="img-fluid" alt />
                    </div>
                    <div id="addauser" hidden={hideDivObject.addauser}>
                    <p className="font-weight-bold mt-3">Add a User</p>
                    <p>
                        Refer to Search Agency help module.
                    </p>
                    <p>Once in the producer profile, Agency Users is displayed on the right vertical menu of the profile.</p>
                    <img src={AddUserPicture1} className="img-fluid" alt />

                    <img src={AddUserPicture2} className="img-fluid" alt />
                    <p>Complete the Agency User Form </p>
                    <img src={AddUserPicture3} className="img-fluid" alt />

                    <p>Add password by selecting Password Generator, Password Generate, check “I have copied this password in a safe place.”</p>
                    <img src={AddUserPicture4} className="img-fluid" alt />
                    <img src={AddUserPicture5} className="img-fluid" alt />

                    <p>Add Row to User Products at the bottom of the window</p>
                    <img src={AddUserPicture6} className="img-fluid" alt />
                    <p>Complete the fields accordingly and Save Agency User.</p>
                    <img src={AddUserPicture7} className="img-fluid" alt />
                    <img src={AddUserPicture8} className="img-fluid" alt />
                    <p>The new user will populate in the top left corner of the window.</p>
                    <img src={AddUserPicture9} className="img-fluid" alt />
                    <img src={AddUserPicture10} className="img-fluid" alt />
                    <hr />    
                    </div>
                    <div id="updateuser" hidden={hideDivObject.updateuser}>
                    <p className="font-weight-bold mt-3">Update a User</p>
                    <p>Refer to Search Agency help module.</p>
                    <p>Once in the producer profile, Agency Users is displayed on the right vertical menu of the profile.</p>
                    <img src={UpdateUserPicture1} className="img-fluid" alt />
                    <p>Select the Unique User ID to be updated.</p>
                    <img src={UpdateUserPicture2} className="img-fluid" alt />
                    <p>Update Agency User Form or User Products fields accordingly and Update Agency User.</p>
                    <img src={UpdateUserPicture3} className="img-fluid" alt />       
                    <hr />    
                    </div>
                    <div id="attachdocuments" hidden={hideDivObject.attachdocuments}>
                    <p className="font-weight-bold mt-3">Attach Documents</p>
                    <p>Refer to Search Agency help module.</p>
                    <p>Once in the producer profile, Documents is displayed on the right vertical menu of the profile.</p>
                    <img src={DocumentsUserPicture1} className="img-fluid" alt />
                    <img src={DocumentsPicture2} className="img-fluid" alt />

                    <p>1. Enter File Name: Name and Date</p>
                    <p>2. Select Doc Type (220 License, Acknowledgement Letter, Bind/Sell Agreement, Insurance Certificate, Limited Service Agreement, Miscellaneous Documents, Other Licenses, Producer Agreement, Voiced Check, W9)</p>
                    <p>3. Enter Description</p>
                    <p>4. Drag &amp; Drop or Select Files to attach</p>
                    <img src={DocumentsPicture3} className="img-fluid" alt />
                    <p>The new document will be timestamped below the document list</p>
                    <img src={DocumentsPicture4} className="img-fluid" alt />
                    <p>Policies matching the search criteria are displayed under Search Result.</p>
                    
                    <hr />    
                    </div>
                    
                </div>
                {
                    selectedDiv ? null :
                    (<div className="col-md-5">
                        <div className="rightdv p-5">
                            <h5 className="font-weight-bold">Quick Links</h5>
                            <br></br>
                            <p><Link activeClass="active" to="addaprofile" spy={true} smooth={true}>Add a Profile</Link></p><hr />
                            <p><Link activeClass="active" to="searchaprofile" spy={true} smooth={true}>Search a Profile</Link></p><hr />
                            <p><Link activeClass="active" to="updateaprofile" spy={true} smooth={true}>Update Profile</Link></p><hr />
                            <p><Link activeClass="active" to="addauser" spy={true} smooth={true}>Add a User</Link></p><hr />
                            <p><Link activeClass="active" to="updateuser" spy={true} smooth={true}>Update a User</Link></p><hr />
                            <p><Link activeClass="active" to="attachdocuments" spy={true} smooth={true}>Attach Documents</Link></p>
                            
                        </div>
                        <div className="rightdv p-5 mt-3">
                            <h5 className="font-weight-bold">Need Support?</h5>
                            <p className="buttonicon"><i className="fa fa-commenting-o" />&nbsp;&nbsp;Live chat with our support Assistant</p>
                            <p>Click on the link to connect with our support assistant. 24/7 service is available for our valueable customers.</p>
                            <button className="btnCustom btnCustom-info pr-4 pl-4">Chat Now</button>
                            <hr />
                            <p><b>Need Support?</b></p>
                            <p className="buttonicon"><i className="fa fa-phone buttonicon" />&nbsp;&nbsp;{companyPhone}</p>
                            <p>Connect directly with our support team. 24/7 service is available for our valueable customers.</p>
                        </div>
                    </div>)
                }
            </div>

    )
}

export default AgencyManagement;