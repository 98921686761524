import React from "react";
import ApplicantInformation from "../ApplicantInformation";
import { Box } from "@chakra-ui/react";

const StepApplicantDetails = ({ allDetailsFilled }) => {
  return (
    <Box pt={3}>
      <Box p={2} >
        <Box mt={2}>
          <ApplicantInformation />
        </Box>
      </Box>
    </Box>
  );
};

export default StepApplicantDetails;