import React, { useCallback } from "react";
import {
  FormControl,
  FormLabel,
  Stack,
  Flex,
  Icon,
  RadioGroup,
  Radio,
  Card,
  Box,
} from "@chakra-ui/react";
import { FaDoorOpen, FaUtensils, FaBuilding } from "react-icons/fa";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { useQuote } from "../../CommCreateQuote";

const QUESTIONS = [
  {
    key: "room_access",
    label: "How are the rooms accessed?",
    type: "radio",
    options: [
      "Exterior Hallway",
      "Interior Hallway",
      "Both (Interior and Exterior)",
    ],
    icon: FaDoorOpen,
  },
  {
    key: "restaurant_exposure",
    label: "What is the restaurant exposure?",
    type: "radio",
    options: ["Full Restaurant", "Limited Cooking", "None"],
    icon: FaUtensils,
  },
  {
    key: "number_of_stories",
    label: "How many stories is the building?",
    type: "select",
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"],
    icon: FaBuilding,
  },
];

const QuestionItem = ({ question, value, onChange }) => {
  const { key, label, type, options, icon } = question;

  return (
    <Flex
      w="100%"
      p={4}
      borderBottom={key === "number_of_stories" ? "none" : "1px"}
      borderColor="gray.200"
      alignItems="center"
    >
      <FormControl>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gap={2} p={1}>
            <Box
              border={"1px solid"}
              borderColor={"gray.200"}
              p={2}
              borderRadius={"2xl"}
            >
              <Icon as={icon} boxSize={8} color="blue.600" mb={1} />
            </Box>
            <FormLabel fontSize="lg" fontWeight="medium" mb={0}>
              {label}
            </FormLabel>
          </Flex>
          {type === "radio" ? (
            <RadioGroup
              onChange={(val) => onChange(key, val)}
              value={value || ""}
            >
              <Stack w="220px" direction="column">
                {options.map((option) => (
                  <Radio key={option} value={option} size="md">
                    {option}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          ) : (
            <ChakraSelect
              w="17%"
              mr={4}
              onChange={(e) => onChange(key, e.target.value)}
              value={value || ""}
            >
              <option value=""></option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </ChakraSelect>
          )}
        </Flex>
      </FormControl>
    </Flex>
  );
};

export const HotelQuestionaireCard = () => {
  const { hotelResponses, setHotelResponses } = useQuote();

  const handleChange = useCallback((key, value) => {
    setHotelResponses(() => ({
      ...hotelResponses,
      [key]: value,
    }));
  }, []);

  return (
    <Card
      bg="white"
      boxShadow="0 0 6px rgba(0, 0, 0, 0.2)"
      borderRadius="2xl"
      p={6}
      mb={6}
      mr={1}
    >
      <Flex direction="column" gap={4}>
        {QUESTIONS.map((question) => (
          <QuestionItem
            key={question.key}
            question={question}
            value={hotelResponses[question.key]}
            onChange={handleChange}
          />
        ))}
      </Flex>
    </Card>
  );
};

export default HotelQuestionaireCard;
