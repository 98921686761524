import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
// import BootstrapTable from "react-bootstrap-table-next";
// import { Col } from "react-bootstrap";
import { CSVLink } from 'react-csv';
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useDispatch } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { BsZoomIn } from "react-icons/bs";
import { BsArrowLeftSquare } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { getClaimListData } from "../../../services/dashboardService";
// import { verifyPolicy, resetCoreStore, setPolicyPropsData, getBaseData } from "../../../services/coreSystemService";
// import ReactTooltip from "react-tooltip";
// import { getNumberFormat } from "../../../services/claimService";
import { Box, Button, Flex, Select, Text, Tooltip } from "@chakra-ui/react";
import AdvancedGridTable from "../../../components/AdvancedGridTable";
import { useTranslation } from "react-i18next";
import NumberCell from "../NumberClick";
import { formateDate } from "../../common/FormatDate";
import { Currency } from "../../common/Currency";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const devKey = process.env.REACT_APP_NODE_ENV;
const isEnabledDataToggleOptionForDashboardData = process.env.REACT_APP_EMABLED_DATA_TOGGLE_OPTION_FOR_DASHBOARD_DATA;

const ClosedClaim = () => {
    const { t } = useTranslation();
    const gridRef = useRef(null);
    const [nameOption, setNameOption] = useState('self');
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [closeClaimData, setCloseClaimgData] = useState([]);
    const [filter, setFilter] = useState({
        page: 1,
        sizePerPage: 10,
    });
    const [total, setTotal] = useState(0);


    const [sortField, setSortField] = useState("Date_Of_Loss");
    const [sortOrder, setSortOrder] = useState('desc');
    const [claimData, setClaimData]=useState([]);
    const [tenancyNaicPrefix, setTenancyNaicPrefix] = useState("");

// code commented temporarily because 2 API requests are fired and then it takes twice the time to load the data
//     useEffect(() => { 
//         ClosedClaimData();
// }, [filter.page, filter.sizePerPage, sortOrder, sortField])

    useEffect(()=>{
        if(closeClaimData.length > 0) {
            let claimArr=closeClaimData.map((e)=>{
                e.Claim_Number=tenancyNaicPrefix+e.Claim_Number;
                return e;
            })
           setClaimData([...claimArr].sort(customSort));
        }
    },[sortField, sortOrder, closeClaimData]);
   
      const handleSort = (col, order) => {
        setSortField(col);
        setSortOrder(order);
      };
  
      const customSort = (a, b) => {
        if(sortField)
        if (sortOrder === 'asc') {
          return a[sortField].localeCompare(b[sortField]);
        } else {
          return b[sortField].localeCompare(a[sortField]);
        }
        
      };

    const headers = [
        { label: "Loss Date", key: "Date_Of_Loss" },
        { label: "Claim No", key: "Claim_Number" },
        { label: "Policy No", key: "Policy_Number" },
        { label: "Policyholder/Member Name", key: "Insured_Name" },
        { label: "Property Address", key: "property_address" },
        { label: "Building Reserve", key: "Building_Reserve_Amount" },
        { label: "Building Payment", key: "Building_Coverage_Amount" },
        { label: "Content Reserve", key: "Content_Reserve_Amount" },
        { label: "Content Payment", key: "Content_Coverage_Amount" },
        { label: "Claim Status", key: "Claim_Status_Code" },
    ]

    const namelist = [
        { value: 'all', label: 'All Data' },
        { value: 'self', label: 'My Data' }
    ]

    const ClosedClaimData = async (type = nameOption, transType = "Close", page = filter.page, sizePerPage = filter.sizePerPage, policyNo = '') => {
        // setLoading(true);
        await dispatch(getClaimListData(type, transType, sizePerPage, page, sortOrder, sortField, policyNo)).then((res) => {
            setTenancyNaicPrefix(res.data?.tenancy.toUpperCase().charAt(0)+res.data?.naic);
            setCloseClaimgData(res.data.data);
            setTotal(res.data.count);
        })
        // setLoading(false);
    }

    const namelistdata = (e) => {
        setNameOption(e.target.value);
        ClosedClaimData(e.target.value);
    }

    // const actionFormatter = (cell, row) => {
    //     if (row && row.Claim_Number) {
    //         return (
    //             <>
    //                 <BsZoomIn size={26} style={{ backgroundColor: "#66D7CD", border: "1px solid #66D7CD", cursor: "pointer" }} onClick={() => urlBuilder(row)} data-tip data-for="claimDetails">
    //                     {cell}
    //                 </BsZoomIn>
    //                 <ReactTooltip id="claimDetails" place="top" effect="solid">
    //                     View Claim Details
    //                 </ReactTooltip>
    //             </>
    //         )
    //     }
    // }

    const urlBuilder = async (row) => {
        let originalClaimNo="";
        if(row.Claim_Number.length >= 8) {
            originalClaimNo=row.Claim_Number.slice(-8);
        }else{
         originalClaimNo=row.Claim_Number
        }
        navigation(`/core/claim/${originalClaimNo }`);
    }

    // const columns = [
    //     {
    //         dataField: "Date_Of_Loss",
    //         text: "Loss Date",
    //         sort: true,
    //         onSort: handleSort
    //     },
    //     {
    //         dataField: "Claim_Number",
    //         text: "Claim No",
    //         sort: true,
    //         onSort: handleSort
    //     },
    //     {
    //         dataField: "Policy_Number",
    //         text: "Policy No",
    //         sort: true,
    //         onSort: handleSort
    //     },
    //     {
    //         dataField: "Insured_Name",
    //         text: "Policyholder Name",
    //         sort: true,
    //         onSort: handleSort
    //     },
    //     {
    //         dataField: "property_address",
    //         text: "Property Address",
    //     },
    //     {
    //         dataField: "Building_Reserve_Amount",
    //         text: "Building Reserve",
    //         formatter: getNumberFormat
    //     },
    //     {
    //         dataField: "Building_Coverage_Amount",
    //         text: "Building Payment",
    //         formatter: getNumberFormat
    //     },
    //     {
    //         dataField: "Content_Reserve_Amount",
    //         text: "Content Reserve",
    //         formatter: getNumberFormat
    //     },
    //     {
    //         dataField: "Content_Coverage_Amount",
    //         text: "Content Payment",
    //         formatter: getNumberFormat
    //     },
    //     {
    //         dataField: "Claim_Status_Code",
    //         text: "Claim Status",
    //     },
    //     {
    //        dataField: "action",
    //         text: "Action",
    //         formatter: actionFormatter
    //     },
    // ];

    // const handlePageChange = (page, sizePerPage) => {
    //     setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    // };

    // const sizePerPageListChange = (page, sizePerPage) => {
    //     setFilter({ ...filter, sizePerPage: sizePerPage });
    // };

    // const onTableChange = (type, { page, sizePerPage }) => {
    //     setFilter({ ...filter, page: page, sizePerPage: sizePerPage });
    // };

    // const renderShowsTotal = (from, to, size) => (
    //     <span style={{ paddingLeft: 10 }}>Showing {from} to {to} of {size} Results</span>
    // );

    // const options = {
    //     page: filter.page,
    //     sizePerPage: filter.sizePerPage,
    //     sortIndicator: false,
    //     pageStartIndex: 1,
    //     totalSize: closeClaimData.length,
    //     totalSize: total,
    //     showTotal: true,
    //     paginationTotalRenderer: renderShowsTotal,
    //     onPageChange: handlePageChange,
    //     onSizePerPageChange: sizePerPageListChange,
    // };

    const homePage = () => {
        navigation("/home");
    }

    const onNumberClick = (params) => {
        navigation(`/core/policy/${params.data.Policy_Number}`);
    };

    const ActionCellRenderer = (params) => {
        const rowData = params.data;
        if(rowData && rowData.Claim_Number) {
            return (
                <Flex align={'center'} pt={'4px'}>
                    <Tooltip hasArrow label='View Claim Details' placement='top'>
                        <Button size={'sm'} onClick={() => urlBuilder(rowData)}>
                            <BsZoomIn size={'20px'} />
                        </Button>
                    </Tooltip>
                </Flex>
            );
        } else {
            return rowData.Claim_Number;
        }
    };

    const loadData = async (type, transType, sizePerPage, page, sortOrder, sortField, policyNo) => {
        try {
            const res = await dispatch(getClaimListData(type, transType, sizePerPage, page, sortOrder, sortField, policyNo));
            return res.data;
        } catch (e) {
            return {
                rows: 0,
                total: 0,
            };
        }
    };

    const createServerSideDataSource = () => {
        return {
            getRows: async (params) => {
                const pageLength = params.request.endRow - params.request.startRow;
                const page = params.request.endRow / pageLength;
                let policyNo = "";
                let sortOrder = "desc";
                let sortField = "Date_Of_Loss";
                let transType= "Close";

                if (params.request?.sortModel?.length > 0) {
                    sortOrder=params.request?.sortModel[0]?.sort;
                    sortField=params.request?.sortModel[0]?.colId;
                }

                const res = await loadData(nameOption, transType, pageLength, page, sortOrder, sortField, policyNo);
                
                setTimeout(function () {
                    if (res?.count) {
                        res.data.map((item) => {
                            item.Claim_Number=res?.tenancy.toUpperCase().charAt(0) + res?.naic + item.Claim_Number;
                            return item;
                        });
                        // supply rows for requested block to grid
                        params.success({
                            rowData: res.data,
                            rowCount: res.count,
                        });
                        params.api.hideOverlay();
                    } else {
                        params.api.showNoRowsOverlay();
                        params.success({
                            rowData: [],
                            rowCount: 0,
                        });
                        // params.fail();
                    }
                }, 500);
            },
        };
    };

    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
        const dataSource = createServerSideDataSource();
        params.api.updateGridOptions({ serverSideDatasource: dataSource });
        const columnState = {
            state: [
              {
                colId: "Date_Of_Loss", //By default sorted according to Date Of Loss
                sort: "desc",
              },
            ],
          };
          params.columnApi.applyColumnState(columnState);
    }, []);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        floatingFilter: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        suppressHeaderMenuButton: true,
        suppressFloatingFilterButton: true,
        wrapText: true,
        autoHeight: true,
    }), []);

    const [columnDefs] = useState([
        {
            headerName: "Loss Date",
            field: "Date_Of_Loss",
            // filter: "agDateColumnFilter",
            floatingFilter: false,
            minWidth: 100,
            valueFormatter: (params) => formateDate(params.value),
        },
        {
            headerName: "Claim Number",
            field: "Claim_Number",
            filter: "agTextColumnFilter",
            minWidth: 140,
        },
        {
            headerName: "Policy Number",
            field: "Policy_Number",
            filter: "agTextColumnFilter",
            cellStyle: {
                textDecoration: "underline",
                color: "blue",
                cursor: "pointer",
            },
            minWidth: 150,
            cellRenderer: (params) => (
                <NumberCell
                    value={params.value}
                    onNumberClick={() => onNumberClick(params)}
                />
            ),
        },
        {
            headerName: "Policyholder Name",
            field: "Insured_Name",
            filter: "agTextColumnFilter",
            minWidth: 160,
            cellRenderer: (params) => {
                return (
                    <div style={{ wordBreak: "break-word" }}>
                        {params.value}
                    </div>
                )
            },
        },
        {
            headerName: "Property Address",
            field: "property_address",
            filter: false,
            sortable: false,
            minWidth: 210,
            cellRenderer: (params) => {
                return (
                    <div style={{ wordBreak: "break-word" }}>
                        {params.value}
                    </div>
                )
            },
        },
        {
            headerName: "Building Reserve",
            field: "Building_Reserve_Amount",
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: (params) => <Currency amount={params?.value}/>,
        },
        {
            headerName: "Building Payment",
            field: "Building_Coverage_Amount",
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: (params) => <Currency amount={params?.value}/>,
        },
        {
            headerName: "Content Reserve",
            field: "Content_Reserve_Amount",
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: (params) => <Currency amount={params?.value}/>,
        },
        {
            headerName: "Content Payment",
            field: "Content_Coverage_Amount",
            filter: false,
            sortable: false,
            minWidth: 100,
            cellRenderer: (params) => <Currency amount={params?.value}/>,
        },
        {
            headerName: "Claim Status",
            field: "Claim_Status_Code",
            filter: false,
            sortable: false,
            minWidth: 80,
        },
        {
            headerName: "Action",
            field: "",
            filter: false,
            sortable: false,
            minWidth: 85,
            cellRenderer: ActionCellRenderer,
        },
    ]);

    return (
        <>
        <LoadingOverlay>
          <Loader loading={loading} />
          <Box mb={8}>
            <Flex mb={4} justifyContent={"space-between"}>
              <Flex>
                <Text mb={0} mr={4} fontWeight={600} fontSize="2xl">
                  {t("Closed Claim")}
                </Text>
              </Flex>
              <Flex direction={"row"} align={"center"}>
                {isEnabledDataToggleOptionForDashboardData == "SHOW" ? (
                  <>
                    <Select
                      size={"sm"}
                      mr={4}
                      value={nameOption}
                      onChange={(e) => namelistdata(e)}
                    >
                      {namelist &&
                        namelist.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                    </Select>
                  </>
                ) : (
                  ""
                )}
                <Box>
                  <Button
                    mr={4}
                    leftIcon={<MdOutlineKeyboardBackspace />}
                    size={"sm"}
                    onClick={homePage}
                  >
                    {t("Back")}
                  </Button>
                </Box>
                <CSVLink
                  data={closeClaimData}
                  headers={headers}
                  filename={"Closed_Claim_Data.csv"}
                >
                  <Button leftIcon={<FaFileDownload />} size="sm">
                    {t("Export To CSV")}
                  </Button>
                </CSVLink>
              </Flex>
            </Flex>
            <Box
              bgColor={"white"}
              p={5}
              boxShadow={"lg"}
              borderRadius={"2xl"}
              borderColor={"white"}
            >
              <Box
                className="ag-theme-alpine"
                h={"70vh"}
                minHeight={"400px"}
                maxHeight={"600px"}
                id="myGrid"
                w={"100%"}
              >
                <AdvancedGridTable
                  gridRef={gridRef}
                //   rowData={claimData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowBuffer={0}
                  rowModelType={"serverSide"}
                  cacheBlockSize={50}
                  maxBlocksInCache={20}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 25, 50]}
                  enableCellTextSelection={true}
                  onGridReady={(params) => onGridReady(params)}
                />
              </Box>
            </Box>
          </Box>
        </LoadingOverlay>
        {/* <Fragment>
            <LoadingOverlay>
                <Loader loading={loading} />
                <h4 className="dashboard-cards-table-title"><BsArrowLeftSquare onClick={homePage} style={{ cursor: "pointer" }} /> &nbsp;&nbsp;Dashboard</h4>
                <Col xs="12" className="billing-tab-row2">
                    <div id="dashboard-table-section">
                        <div className="table-main-title-div">
                            <span className="table-main-title-name">Closed Claim</span>
                            {
                                devKey !== "PROD" ?
                                    <>
                                        <span className="table-main-title-name" style={{ marginLeft: "50%" }}>
                                            <label htmlFor="">Select Data View</label>
                                        </span>
                                        <select style={{ width: "9%", padding: "8px" }} value={nameOption} onChange={(e) => namelistdata(e)}>
                                            {namelist && namelist.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    </>
                            : ""}
                            <span className="table-main-title-name">
                                <CSVLink data={closeClaimData} headers={headers} filename={'Closed_Claim_Data.csv'}>
                                    <button className="btnCustom btnCustom-info pr-4 pl-4" style={{ border: "1px solid", fontSize: "15px" }} size="sm" color="primary">Export To CSV</button>
                                </CSVLink>
                            </span>
                        </div>
                        <div className="managment-tab-body" style={{ paddingBottom: "2rem" }}>
                            <div className="management-reports-table">
                                <BootstrapTable
                                    wrapperClasses="dashboard-table-main"
                                    bootstrap4
                                    keyField="PolicyNo"
                                    columns={columns}
                                    data={claimData}
                                    hover
                                    remote
                                    bordered={false}
                                    pagination={paginationFactory(options)}
                                    onTableChange={onTableChange}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </LoadingOverlay>
        </Fragment> */}
        </>
    );
};

export default ClosedClaim;