import React, {  } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-overlay-loader/styles.css";
import Mortgagee from "../../../quote/Mortgagee/FloodAddMortages";
import "./flood-property-detail.scss";

const AddMortgagee = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  // React-Select Custome Style
  const handleNext = () => {
    navigation("/flood/quote");
  };
  const handlePrevious = () => {
    navigation("/flood/instantquote/step/14");
  };

  return (
    <section className="property-details-section">
      <Row className="justify-content-center">
        <span>{t('MORTGAGEE(S)')}</span>
        <Col xs="12" sm="12" md="10" lg="8" xl="8" className="p-form">
          <div>
            <Mortgagee showMortgagee={true} wantAddLender={false} />
          </div>

          <Col xs="12" className="form-btns">
            <div className="flood-bts-div">
              <Button
                id="primary-grey-btn-flood"
                variant="outline-dark"
                onClick={handlePrevious}
              >
                {t("Previous")}
              </Button>{" "}
              <Button
                id="primary-colored-btn"
                variant="primary"
                onClick={handleNext}
              >
                {t("Next")}
              </Button>{" "}
            </div>
          </Col>
        </Col>
      </Row>
    </section>
  );
};

export default AddMortgagee;
