import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import translationEng from "./locales/en/translation.json";
import translationSpa from "./locales/spa/translation.json";
import policySearchEng from "./locales/en/policySearch.json";
import policySearchSpa from "./locales/spa/policySearch.json";
import quoteSearchEng from "./locales/en/quoteSearch.json";
import quoteSearchSpa from "./locales/spa/quoteSearch.json";
import producerEng from "./locales/en/producer.json";
import producerSpa from "./locales/spa/producer.json";
import underwritingEng from "./locales/en/underwriting.json";
import underwritingSpa from "./locales/spa/underwriting.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        translations: translationEng,
        policySearch: policySearchEng,
        quoteSearch: quoteSearchEng,
        producer: producerEng,
        underwriting: underwritingEng,
      },
      spa: {
        translations: translationSpa,
        policySearch: policySearchSpa,
        quoteSearch: quoteSearchSpa,
        producer: producerSpa,
        underwriting: underwritingSpa,
      },
    },
    // have a common namespace used around the full app
    ns: ["translations", "policySearch", "quoteSearch", "producer", "underwriting"],
    defaultNS: "translations",
  });

export default i18n;
