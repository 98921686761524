import React from "react";
import SimpleNavbar from "../../simple-navbar/SimpleNavbar";

const BlankLayout = ({ children, ...rest }) => {
  return (
    <div className="blank-layout-main">
      <div className="black-layout-navbar">
        <SimpleNavbar />
      </div>
      <div className="black-layout-children">{children}</div>
    </div>
  );
};

export default BlankLayout;
