import React, { useState } from "react";
import {Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import ChangeInsuredName from "./ChangeInsuredName";

const CommonModal = (props) => {
    const { applicantNameEndorseJSON = {} } = props;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    return (
        <LoadingOverlay>
            <Loader loading={loading} />
            <Modal
            size="md"
            show={props.isOpen}
            className="custom-dashboard-modal"
        >
            <Modal.Header
                closeButton
                className="custom-dashboard-modal-header"
                onClick={()=>props.resetModal()} 
            >
                <Modal.Title>{t('Change Insured Name')}</Modal.Title>
            </Modal.Header>
            <Modal.Body
                className="custom-dashboard-modal-body"
            >
               <ChangeInsuredName handleChange={props.handleChange} applicantData= {props.applicantData} resetModal = {props.resetModal} updateParentState={props.updateParentState} applicantNameEndorseJSON={applicantNameEndorseJSON}/>
            </Modal.Body>
        </Modal>
        </LoadingOverlay>
    )
}

export default CommonModal;