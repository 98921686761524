/*************** AUTH *******************/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SITE_DATA = 'SITE_DATA';
export const GET_QUOTE_LIST_DATA = 'GET_QUOTE_LIST_DATA';
export const GET_LOGO = 'GET_LOGO';

/*************** FLOOD *******************/
export const GET_PROPERTY_AUTOCOMPLETE_STORED = 'GET_PROPERTY_AUTOCOMPLETE_STORED';
export const GET_PROPERTY_DATA_FROM_ADDRESS = 'GET_PROPERTY_DATA_FROM_ADDRESS';
export const GET_FLOOD_SYSTEM_DD = 'GET_FLOOD_SYSTEM_DD';
export const GET_BASE_DATA = 'GET_BASE_DATA';
export const GET_EMPTY_QUOTE_DATA = 'GET_EMPTY_QUOTE_DATA';
export const EDIT_EMPTY_QUOTE_DATA = 'EDIT_EMPTY_QUOTE_DATA';
export const IS_LOADING = 'IS_LOADING';
export const GET_INIT_QUOTE_API = 'GET_INIT_QUOTE_API';
export const GET_ZD_DETAILS = 'GET_ZD_DETAILS';
export const GET_QUOTE_RESPONSE_DETAILS = 'GET_QUOTE_RESPONSE_DETAILS';
export const GET_BASIC_COVERAGE_DETAILS = 'GET_BASIC_COVERAGE_DETAILS';
export const GET_RECOMMENDED_COVERAGE_DETAILS = 'GET_RECOMMENDED_COVERAGE_DETAILS';
export const GET_CUSTOM_COVERAGE_DETAILS = 'GET_CUSTOM_COVERAGE_DETAILS';
export const GET_BASIC_COVERAGE_RESPONSE = 'GET_BASIC_COVERAGE_RESPONSE';
export const GET_RECOMMENDED_COVERAGE_RESPONSE = 'GET_RECOMMENDED_COVERAGE_RESPONSE';
export const GET_CUSTOM_COVERAGE_RESPONSE = 'GET_CUSTOM_COVERAGE_RESPONSE';
export const GET_WHICH_SECTION = 'GET_WHICH_SECTION';
export const GET_LAST_SECTION = 'GET_LAST_SECTION';
export const GET_DEDUCTIBLE_OPTIONS = 'GET_DEDUCTIBLE_OPTIONS';
export const GET_CREATE_QUOTE_RESPONSE = 'GET_CREATE_QUOTE_RESPONSE';
export const GET_INIT_PAYMENT_DATA = 'GET_INIT_PAYMENT_DATA';
export const GET_QUOTE_DATA_HO3 = 'GET_QUOTE_DATA_HO3';
export const GET_PRODUCT_LIST_DATA = 'GET_PRODUCT_LIST_DATA';
export const GET_APP_STATUS_DATA = 'GET_APP_STATUS_DATA';
export const RESET_FLOOD_STORE = 'RESET_FLOOD_STORE';
export const GET_APP_REMARK_DATA = 'GET_APP_REMARK_DATA';
export const GET_ADD_GEO_DATA = 'GET_ADD_GEO_DATA';
export const SET_PRIVATE_PRODUCT_DATA = 'SET_PRIVATE_PRODUCT_DATA';
export const INIT_PRIVATE_PRODUCTS = 'INIT_PRIVATE_PRODUCTS';
export const INIT_EXCESS_PRODUCTS = 'INIT_EXCESS_PRODUCTS';

export const SET_ACTIVE_PRIVATE_PRODUCT_TAB = 'SET_ACTIVE_PRIVATE_PRODUCT_TAB';
export const SET_TEMP_ZD_DATA = 'SET_TEMP_ZD_DATA';
export const SET_TEMP_QUOTE_NO = 'SET_TEMP_QUOTE_NO';
export const SAVE_LOADING = 'SAVE_LOADING';
export const UPDATE_AGENCY_AGENT_NAME = 'UPDATE_AGENCY_AGENT_NAME';
export const SET_EXCESS_PRODUCT_DATA = 'SET_EXCESS_PRODUCT_DATA';

/*************** CORE SYSTEM *******************/
export const RESET_CORE_STORE = 'RESET_CORE_STORE';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_CORE_POLICY_DATA = 'SET_CORE_POLICY_DATA';
export const SET_CORE_POLICY_TERM = 'SET_CORE_POLICY_TERM';
export const SET_CORE_POLICY_TRANS = 'SET_CORE_POLICY_TRANS';
export const SET_TERM_SELECTION = 'SET_TERM_SELECTION';
export const SET_TRANSACTION_DD = 'SET_TRANSACTION_DD';
export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA';
export const SET_POLICY_COVERAGE_DATA = 'SET_POLICY_COVERAGE_DATA';
export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA';
export const SET_MORTGAGEE_DATA = 'SET_MORTGAGEE_DATA';
export const SET_HOUSEHOLD_DATA = 'SET_HOUSEHOLD_DATA';
export const SET_ADDITIONAL_INTEREST_DATA = 'SET_ADDITIONAL_INTEREST_DATA';
export const SET_PRIOR_POLICY_LOSS_DATA = 'SET_PRIOR_POLICY_LOSS_DATA';
export const SET_FORM_COPY_DATA = 'SET_FORM_COPY_DATA';
export const SET_BILLING_DATA = 'SET_BILLING_DATA';
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_RECEIVABLE_DATA = 'SET_RECEIVABLE_DATA';
export const SET_INVOICE_DATA = 'SET_INVOICE_DATA';
export const SET_INVOICE_PREMIUM_FEE_DATA = 'SET_INVOICE_PREMIUM_FEE_DATA';
export const SET_POLICY_PROPERTY_INFO = 'SET_POLICY_PROPERTY_INFO';
export const SET_BASE_DATA = 'SET_BASE_DATA';
export const GET_ACTIVITY_LIST_DATA = 'GET_ACTIVITY_LIST_DATA';
export const SET_ACTION_TYPE_DD = 'SET_ACTION_TYPE_DD';
export const SET_CORE_CLAIM_DATA = 'SET_CORE_CLAIM_DATA';
export const SET_CORE_CLAIM_PAYMENT_DATA = 'SET_CORE_CLAIM_PAYMENT_DATA';
export const SET_CLAIM_DAMAGE_DATA = 'SET_CLAIM_DAMAGE_DATA';

/*************** Insta Quote *******************/

export const GET_INSTANT_PROPERTY_DATA_FROM_ADDRESS = 'GET_INSTANT_PROPERTY_DATA_FROM_ADDRESS';
export const GET_EMPTY_INSTANT_QUOTE_DATA = 'GET_EMPTY_INSTANT_QUOTE_DATA';
export const GET_INSTANT_SYSTEM_DD = 'GET_INSTANT_SYSTEM_DD';
export const EDIT_EMPTY_INSTANT_QUOTE_DATA = 'EDIT_EMPTY_INSTANT_QUOTE_DATA';
export const GET_INSTANT_PROPERTY_AUTOCOMPLETE_STORED = 'GET_INSTANT_PROPERTY_AUTOCOMPLETE_STORED';
export const GET_SAVE_FINAL_PREMIUM_INSTA_QUOTE = 'GET_SAVE_FINAL_PREMIUM_INSTA_QUOTE'



/*************** Mortgegee *******************/

export const GET_MORTGAGEE_LIST_DATA = 'GET_MORTGAGEE_LIST_DATA';

export const GET_NEW_TRANSACTION_BASE_DATA = 'GET_NEW_TRANSACTION_BASE_DATA';

export const GET_PAGE_MODE = "GET_PAGE_MODE";


/*****************selected Agency wyo code details ****************  */
export const SET_SELECTED_WYO_AGENCY_DATA = "SET_SELECTED_WYO_AGENCY_DATA";


/*******************PRODUCER DATA ********************/

export const GET_PRODUCER_DATA = "GET_PRODUCER_DATA";

/**************** DYNAMIC LOADER ****************/  


export const SET_DYNAMIC_LOADER = "SET_DYNAMIC_LOADER";
/*****************auto open AgencyUserForm ****************  */
export const SET_AGENCY_USER_MODAL = "SET_AGENCY_USER_MODAL";
/*****************auto load Agency user Form from PK ****************  */
export const SET_AGENCY_USER_PK = "SET_AGENCY_USER_PK";

