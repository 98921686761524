import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Form ,Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Sign.scss";

const Sign2 = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const handleOnclick = (step) => {
        navigation('/instaQuote/InstaSteps/' + step);
    }

    return(
        <section className="sign-off-info-section">
            <Row className="justify-content-center">
                <Col xs="12" sm="12" md="12" lg="8" xl="8" className="sign-off-info-form-section">
                    <Col xs="12" className="sign-off-info-form-main">
                        <div className="sign-off-info-heading">
                            <h4>{t('Sign Off')}</h4>
                            <p>{t('Please read following instruction carefully and check each box to continue')}</p>
                        </div>
                        <Form className="confirm-address-form">
                            <Col xs="12">
                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{ float: "right" }}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="waterDamageExclusion"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue='YES'
                                                uncheckedvalue='NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Water Damage Exclusion </b><br /> The applicant acknowledges that for a reduced premium, the   insurance   policy   includes   a   Water   Damage   Exclusion   Endorsement.   With   this endorsement, the company will not pay any amounts for loss caused by water damage, as described   in   the   Water   Damage   Exclusion   Endorsement.   Water   entering   the   dwelling resulting from rain entering through openings directly caused by a “Hurricane Loss” is covered under “Hurricane Coverage” and is subject to the hurricane deductible selected by the applicant.</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{ float: "right" }}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="limitedWaterDamageCoverage"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue='YES'
                                                uncheckedvalue='NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Limited Water Damage Coverage </b><br /> The applicant acknowledges that the insurance policy has a $10,000 limit for losses caused by water damage, as described in the Limited Water Damage Coverage Endorsement. The applicant acknowledges that the $10,000 limit applies per occurrence, to all damages and expenses that are covered within the policy. Water entering the dwelling resulting from rain entering through openings directly caused by a “Hurricane Loss” is covered under “Hurricane Coverage” and is subject to the hurricane deductible selected by the applicant. Personal   information   about   you,   including   information   from   a   credit   or   other investigative report, may be collected from persons other than you in connection with this application for insurance and subsequent amendments and renewals. Such information as well as other personal and privileged information collected by us or our agents may in certain circumstances be disclosed to third parties with your authorization. Credit scoring information may be used to help determine either your eligibility for insurance or the premium you will be charged. We may use a third party in connection with the development of your score. You have the right to review your personal information in our files and can request corrections of any inaccuracies. A more detailed description of your rights and our practices regarding such   information   is   available   upon   request.   Contact   your   agent   or   broker   for instructions   on   how   to   submit   a   request   to   us. Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or is leading information is guilty of a felony of the third degree.</span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="1">
                                        <div className="discount-form-actions" style={{ float: "right" }}>
                                            <Form.Check
                                                type="checkbox"
                                                id="custom-switch"
                                                name="noticeofPropertyInspection"
                                                parentobject="propertyInfo"
                                                className="discount-form-switch"
                                                label=""
                                                checkedvalue='YES'
                                                uncheckedvalue='NO'
                                                required
                                            />
                                        </div>
                                    </Col>
                                    <Col sm="11">
                                        <span><b>Notice of Property Inspection </b><br /> Applicant authorizes Avatar Property & Casualty Insurance Company and their agents or employees’ access to the applicant’s residence premises   for   the   limited   purpose   of   obtaining   relevant   underwriting   data.   Inspections requiring access to the interior of the dwelling will be scheduled in advance with the applicant. Avatar Property & Casualty Insurance Company is under no obligation to inspect the property and if an inspection is made, Avatar in no way implies warrants or guarantees the property is safe, structurally sound, or meets any building codes or requirements. Applicant's statement: I have read the above application and any attachments and I declare that the information in them is true, complete, and correct. This information is being offered to the company as an inducement to issue the policy for which I am applying and I understand that misrepresentation, omission, concealment of fact, or incorrect statement may prevent recovery under the policy.</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Form>
                    </Col>
                    <div className="flood-bts-div">
                        <Button
                            id="primary-grey-btn-flood"
                            variant="outline-dark"
                            onClick={() => handleOnclick('12')}
                        >
                            {t('Previous')}
                        </Button>
                        <Button
                            id="primary-colored-btn"
                            variant="primary"
                            onClick={() => handleOnclick('14')}
                        >
                            {t('Next')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default Sign2;