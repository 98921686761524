import React, { Fragment, useState } from "react";
import { Modal, Button, Row, Col, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoCheckmarkDone } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { t } from "i18next";
import { standardizeKeyName } from "../StandarizeNames";
import { SavePremiumModal } from "./RenewSaveModal";
// import ErrorWarningHandler from "../../utils/ErrorWarningHandler";
import PrimaryErrorHandler from "../../../../quote/create-quote/utility/PrimaryErrorHandler";
import ExcessErrorHandler from "../../../../quote/create-quote/utility/ExcessErrorHandler";
import { Currency } from "../../../../common/Currency";

const PremiumModal = ({
  show,
  onHide,
  premiumResponseData,
  productType,
  commercialProductType,
  quoteDetailData,
  productCode,
  productId,
  setDataForRenewal,
  handleTransaction,
  handleClosePremiumModal,
  rootLoading,
}) => {
  const { t } = useTranslation();
  const [preferredType, setPreferredType] = useState("");
  const [selectedCardData, setSelectedCardData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [choosedPreferredCard, setChoosedPreferredCard] = useState(false);
  const [includeContents, setIncludeContents] = useState(false);
  const [includeLossOfUse, setIncludeLossOfUse] = useState(false);
  const [businessIncomePremium, setBusinessIncomePremium] = useState(false);
  const [totalPremium, setTotalPremium] = useState(0);

  const [loading, setLoading] = useState(false);
  const formatLimitValue = (limit) => `$ ${limit?.toLocaleString()}`;
  const LimitDisplay = ({ label, value }) => (
    <p>
      {label}:&nbsp;
      <span style={{ fontWeight: "800" }}>{formatLimitValue(value)}</span>
    </p>
  );

  const handleCardClick = (deductible, deductibleType) => {
    setPreferredType(deductibleType);
    setSelectedCardData(deductible);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      size="xl"
      scrollable
      show={show}
      onHide={onHide}
      centered
      className={`${showModal && "d-none"}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Premium Details")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SavePremiumModal
          handleClosePremiumModal={handleClosePremiumModal}
          handleTransaction={handleTransaction}
          setDataForRenewal={setDataForRenewal}
          showModal={showModal}
          selectedCardData={selectedCardData}
          handleCloseModal={handleCloseModal}
          setChoosedPreferredCard={setChoosedPreferredCard}
          premiumResponseData={premiumResponseData}
          preferredType={preferredType}
          includeLossOfUse={includeLossOfUse}
          setIncludeLossOfUse={setIncludeLossOfUse}
          businessIncomePremium={businessIncomePremium}
          setBusinessIncomePremium={setBusinessIncomePremium}
          includeContents={includeContents}
          setIncludeContents={setIncludeContents}
          setTotalPremium={setTotalPremium}
          totalPremium={totalPremium}
          productType={productType}
          setLoading={setLoading}
          loading={loading}
          commercialProductType={commercialProductType}
          quoteDetailData={quoteDetailData}
          onHide={onHide}
          productCode={productCode}
          productId={productId}
          rootLoading={rootLoading}
        />
        <div>
          <PrimaryErrorHandler premiumResponseData={premiumResponseData} />
          <Col>
            <Row className="justify-content-between">
              <Col xs="auto" className="text-left">
                <p>
                  {t("Policy No")}: {premiumResponseData?.hiscoxId || "-"}
                </p>
              </Col>
              <Col xs="auto" className="text-right">
                <h5>{t("PRIMARY")}</h5>
              </Col>
              {/* this map is for residential primary */}
              <Row className="mt-2">
                {premiumResponseData?.residential?.primary?.map(
                  (primaryItem, primaryIndex) => (
                    <Fragment key={`primary-${primaryIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Building Limit"
                            value={primaryItem.buildingLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={primaryItem.contentsLimit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Other Structures Limit"
                            value={primaryItem.otherStructuresLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Loss of Use Limit"
                            value={primaryItem.lossOfUseLimit}
                          />
                        </Col>
                      </Row>
                      <hr />
                      {primaryItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`primary-deductible-${deductibleIndex}`}
                          >
                            {renderDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>

              {/* this map is for primary of commercaial tenanted */}
              <Row className="mt-2">
                {premiumResponseData?.commercialTenanted?.primary?.map(
                  (primaryItem, primaryIndex) => (
                    <Fragment key={`primary-${primaryIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Improvements and Betterments Limit"
                            value={primaryItem.improvementsAndBettermentsLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={primaryItem.contentsLimit}
                          />
                        </Col>
                      </Row>

                      <hr className="mt-1 mb-4" />
                      {primaryItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`primary-deductible-${deductibleIndex}`}
                          >
                            {renderDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>

              {/* this map is for primary of commercaial owned */}
              <Row className="mt-2">
                {premiumResponseData?.commercialOwned?.primary?.map(
                  (primaryItem, primaryIndex) => (
                    <Fragment key={`primary-${primaryIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Building Limit"
                            value={primaryItem.buildingLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={primaryItem.contentsLimit}
                          />
                        </Col>
                      </Row>

                      <hr className="mt-1 mb-4" />
                      {primaryItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`primary-deductible-${deductibleIndex}`}
                          >
                            {renderDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>
            </Row>
            <hr className="mt-1 mb-4" />
            <ExcessErrorHandler premiumResponseData={premiumResponseData} />
            <Row className="justify-content-end mt-2">
              <Col xs="auto">
                <h5 className="text-right">{t("EXCESS")}</h5>
              </Col>
              <Row className="mt-2">
                {premiumResponseData?.residential?.excess?.map(
                  (excessItem, excessIndex) => (
                    <Fragment key={`excess-${excessIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Building Limit"
                            value={excessItem.buildingLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={excessItem.contentsLimit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Other Structures Limit"
                            value={excessItem.otherStructuresLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Loss of Use Limit"
                            value={excessItem.lossOfUseLimit}
                          />
                        </Col>
                      </Row>
                      <hr className="mt-1 mb-4" />
                      {excessItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`excess-deductible-${deductibleIndex}`}
                          >
                            {renderExcessDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>
              {/* this map is for excess of commercaial tenanted */}
              <Row className="mt-2">
                {premiumResponseData?.commercialTenanted?.excess?.map(
                  (excessItem, excessIndex) => (
                    <Fragment key={`excess-${excessIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Improvements and Betterments Limit"
                            value={excessItem.improvementsAndBettermentsLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={excessItem.contentsLimit}
                          />
                        </Col>
                      </Row>

                      <hr className="mt-1 mb-4" />
                      {excessItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`excess-deductible-${deductibleIndex}`}
                          >
                            {renderExcessDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>

              {/* this map is for excess of commercaial owned */}
              <Row className="mt-2">
                {premiumResponseData?.commercialOwned?.excess?.map(
                  (excessItem, excessIndex) => (
                    <Fragment key={`excess-${excessIndex}`}>
                      <Row>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Building Limit"
                            value={excessItem.buildingLimit}
                          />
                        </Col>
                        <Col sm={6}>
                          <LimitDisplay
                            label="Contents Limit"
                            value={excessItem.contentsLimit}
                          />
                        </Col>
                      </Row>

                      <hr className="mt-1 mb-4" />
                      {excessItem.deductibles?.map(
                        (deductible, deductibleIndex) => (
                          <Fragment
                            key={`primary-deductible-${deductibleIndex}`}
                          >
                            {renderExcessDeductibles(
                              [deductible],
                              handleCardClick,
                              selectedCardData,
                              choosedPreferredCard,
                              includeLossOfUse,
                              includeContents,
                              businessIncomePremium
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )
                )}
              </Row>
            </Row>

            <Row className="mb-0 mt-5"></Row>
          </Col>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const renderDeductibles = (
  deductibles,
  handleCardClick,
  selectedCardData,
  choosedPreferredCard,
  includeLossOfUse,
  includeContents,
  businessIncomePremium
) => {
  const calculateTotalPremium = (deductible, isSelectedCard) => {
    let totalPremium = 0;
    // Check if the current card is the choosedPreferredCard
    if (isSelectedCard && choosedPreferredCard) {
      // Include premiums based on state
      if (includeLossOfUse && deductible.hasOwnProperty("lossOfUsePremium")) {
        totalPremium += deductible.lossOfUsePremium;
      }
      if (includeContents && deductible.hasOwnProperty("contentsPremium")) {
        totalPremium += deductible.contentsPremium;
      }
      if (
        businessIncomePremium &&
        deductible.hasOwnProperty("businessIncomePremium")
      ) {
        totalPremium += deductible.businessIncomePremium;
      }
      if (deductible.hasOwnProperty("improvementsAndBettermentsPremium")) {
        totalPremium += deductible.improvementsAndBettermentsPremium;
      }
      if (deductible.hasOwnProperty("buildingPremium")) {
        totalPremium += deductible.buildingPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresPremium")) {
        totalPremium += deductible.otherStructuresPremium;
      }
    } else {
      // For other cards, include all premiums
      if (deductible.hasOwnProperty("improvementsAndBettermentsPremium")) {
        totalPremium += deductible.improvementsAndBettermentsPremium;
      }
      if (deductible.hasOwnProperty("buildingPremium")) {
        totalPremium += deductible.buildingPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresPremium")) {
        totalPremium += deductible.otherStructuresPremium;
      }
      if (deductible.hasOwnProperty("lossOfUsePremium")) {
        totalPremium += deductible.lossOfUsePremium;
      }
      if (deductible.hasOwnProperty("contentsPremium")) {
        totalPremium += deductible.contentsPremium;
      }
      if (deductible.hasOwnProperty("businessIncomePremium")) {
        totalPremium += deductible.businessIncomePremium;
      }
    }

    return totalPremium;
  };

  return deductibles.map((deductible, index) => {
    const isChosenPreferredCard = deductible === selectedCardData;
    const totalPremium = calculateTotalPremium(
      deductible,
      isChosenPreferredCard
    );

    return (
      <Col key={index} md={4} className="mb-3 p-3">
        <Card
          onClick={() => {
            handleCardClick(deductible, "Primary");
          }}
          className={"premium-card"}
          style={{
            maxWidth: "25vw",
            border: `${
              deductible != undefined &&
              deductible === selectedCardData &&
              choosedPreferredCard
                ? "1px solid green"
                : ""
            }`,
            borderRadius: "0.25rem",
          }}
        >
          <Card.Header className="bg-light text-center">
            <Card.Title className="m-0 d-flex align-items-center ">
              <div className="flex-grow-1 text-center ">
                <p className="mb-0">
                  {t("Deductible")}: $
                  {new Intl.NumberFormat("en-US").format(
                    deductible.deductible
                  ) || 0}
                </p>
              </div>
              {deductible != undefined &&
                deductible === selectedCardData &&
                choosedPreferredCard && (
                  <IoCheckmarkDone color="green" size={25} />
                )}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <tbody>
                {Object.entries(deductible).map(
                  ([key, value]) =>
                    key !== "deductible" &&
                    key !== "policyType" &&
                    key !== "errors" &&
                    key !== "warnings" && (
                      <tr
                        key={key}
                        className={`${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !includeLossOfUse &&
                          key === "lossOfUsePremium"
                            ? "strikethrough"
                            : ""
                        } ${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !includeContents &&
                          key === "contentsPremium"
                            ? "strikethrough"
                            : ""
                        } ${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !businessIncomePremium &&
                          key === "businessIncomePremium"
                            ? "strikethrough"
                            : ""
                        }`}
                      >
                        <td className="text-right">
                          {standardizeKeyName(key)}:
                        </td>
                        <td className="text-left">
                        {/* {`$${value || 0}`} */}
                         <Currency amount={value} float="center" />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </Card.Body>

          <Card.Footer
            className="bg-light text-center"
            style={{ fontWeight: "600", fontSize: "1.2rem" }}
          >
            {t("Total Premium")}: <Currency amount={totalPremium} float="center" />
            
          </Card.Footer>
        </Card>
      </Col>
    );
  });
};

const renderExcessDeductibles = (
  excessDeductibles,
  handleCardClick,
  selectedCardData,
  choosedPreferredCard,
  includeLossOfUse,
  includeContents,
  businessIncomePremium
) => {
  const calculateTotalPremium = (deductible, isSelectedCard) => {
    let totalPremium = 0;
    // Check if the current card is the choosedPreferredCard
    if (isSelectedCard && choosedPreferredCard) {
      // Include premiums based on state
      if (includeLossOfUse && deductible.hasOwnProperty("lossOfUsePremium")) {
        totalPremium += deductible.lossOfUsePremium;
      }
      if (includeContents && deductible.hasOwnProperty("contentsPremium")) {
        totalPremium += deductible.contentsPremium;
      }
      if (
        businessIncomePremium &&
        deductible.hasOwnProperty("businessIncomePremium")
      ) {
        totalPremium += deductible.businessIncomePremium;
      }
      if (deductible.hasOwnProperty("improvementsAndBettermentsPremium")) {
        totalPremium += deductible.improvementsAndBettermentsPremium;
      }
      if (deductible.hasOwnProperty("buildingPremium")) {
        totalPremium += deductible.buildingPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresPremium")) {
        totalPremium += deductible.otherStructuresPremium;
      }
    } else {
      // For other cards, include all premiums
      if (deductible.hasOwnProperty("improvementsAndBettermentsPremium")) {
        totalPremium += deductible.improvementsAndBettermentsPremium;
      }
      if (deductible.hasOwnProperty("buildingPremium")) {
        totalPremium += deductible.buildingPremium;
      }
      if (deductible.hasOwnProperty("otherStructuresPremium")) {
        totalPremium += deductible.otherStructuresPremium;
      }
      if (deductible.hasOwnProperty("lossOfUsePremium")) {
        totalPremium += deductible.lossOfUsePremium;
      }
      if (deductible.hasOwnProperty("contentsPremium")) {
        totalPremium += deductible.contentsPremium;
      }
      if (deductible.hasOwnProperty("businessIncomePremium")) {
        totalPremium += deductible.businessIncomePremium;
      }
    }

    return totalPremium;
  };

  return excessDeductibles.map((deductible, index) => {
    const isChosenPreferredCard = deductible === selectedCardData;
    const totalPremium = calculateTotalPremium(
      deductible,
      isChosenPreferredCard
    );
    return (
      <Col key={index} md={4} className="mb-3 p-3">
        <Card
          onClick={() => {
            handleCardClick(deductible, "Excess");
          }}
          className="premium-card"
          style={{
            maxWidth: "25vw",
            border: `${
              deductible != undefined &&
              deductible === selectedCardData &&
              choosedPreferredCard
                ? "1px solid green"
                : ""
            }`,
            borderRadius: "0.25rem",
          }}
        >
          <Card.Header className="bg-light text-center">
            <Card.Title className="m-0 d-flex align-items-center">
              <div className="flex-grow-1 text-center">
                <p className="mb-0">
                  {t("Deductible")}: $
                  {new Intl.NumberFormat("en-US").format(
                    deductible.deductible
                  ) || 0}
                </p>
              </div>
              {deductible != undefined &&
                deductible === selectedCardData &&
                choosedPreferredCard && <FaCheck color="green" size={25} />}
            </Card.Title>
          </Card.Header>

          <Card.Body>
            <Table responsive>
              <tbody>
                {Object.entries(deductible).map(
                  ([key, value]) =>
                    key !== "deductible" &&
                    key !== "policyType" &&
                    key !== "errors" &&
                    key !== "warnings" && (
                      <tr
                        key={key}
                        className={`mb-2 justify-content-between ${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !includeLossOfUse &&
                          key === "lossOfUsePremium"
                            ? "strikethrough"
                            : ""
                        } ${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !includeContents &&
                          key === "contentsPremium"
                            ? "strikethrough"
                            : ""
                        } ${
                          isChosenPreferredCard &&
                          choosedPreferredCard &&
                          !businessIncomePremium &&
                          key === "businessIncomePremium"
                            ? "strikethrough"
                            : ""
                        }`}
                      >
                        <td className="text-right">
                          {standardizeKeyName(key)}:
                        </td>
                        <td className="text-left">
                        {/* {`$${value || 0}`} */}
                         <Currency amount={value} float="center" />
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer className="bg-light text-center font-weight-bold">
            {t("Total Premium")}: <Currency amount={totalPremium} float="center" />
             
          </Card.Footer>
        </Card>
      </Col>
    );
  });
};

export default PremiumModal;
