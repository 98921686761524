import React, { useEffect, useState } from "react";
import { Col,Button, Container, Row, Form, Modal, ModalDialog } from "react-bootstrap";
import "./mode-home-type.scss";
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { getEmptyQuoteData, getEditEmptyQuoteData, getInitQuoteApi, pivotApiCall, updateInitQuoteApi, updateWhichSection, updateLastSection } from "../../../../services/floodService";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import ModePropertyDetails from "../form-mode/ModePropertyDetails";
import ModeStartingEstimate from "../form-mode/ModeStartingEstimate";
import ModeContactInformation from "../form-mode/Mode-Contact/ModeContactInformation";
import ModeWaitingPeriod from "../form-mode/Mode-Waiting-Period/ModeWaitingPeriod";

import ModeConstructionFormOne from "../form-mode/Mode-Construction/ModeConstructionFormOne";
import ModeDiscountForm from "../form-mode/Mode-Discounts/ModeDiscountForm";
import ModeSteps from "./ModeSteps";
import DatePicker from "react-datepicker";
import { AiFillCheckCircle } from "react-icons/ai";
import { Icon1, Icon2, Icon3, Icon4, Icon5 } from './../instantQuote/flood-home/LoadIcons';
import ModeConstructionFormMH from './Mode-Construction/ModeConstructionFormMH'
import Draggable from 'react-draggable';

const ModeHomeType = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [quoteData, setQuoteData] = useState('');
  const [mobileHomeCheck, setmobileHomeCheck] = useState(false)
  const [model, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstTimeState, setFirstTimeState] = useState(false);
  const [onTypeSet, setOnTypeSet] = useState('');

  const currentDate = new Date();
  let params = useParams();
  const { apiLoading, propertyDataFromAdd, propertyDataAutocompleteStored, quoteResponseDetails } = useSelector(state => state.flood);

  const toggle = (e) => {
    setModal(null);
  }
  const navigatAction = (type) => {
    if (['HOME', 'MOBILEHOME', 'UNIT', 'RESIDENTIALBLDG', 'NONRESIDENTIALBLDG'].includes(type)) {
      if(type=='MOBILEHOME'){
        setmobileHomeCheck(true)
       }else{
        setmobileHomeCheck(false)
       }
      dispatch(getEmptyQuoteData(10, type)).then((res) => {
        if (res && Object.keys(res).length > 0) {
          const allData = res;
          allData.viewType = type;
          if (allData.propertyInfo) {
            if (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length > 0) {
              let year = propertyDataFromAdd.PropDetails.ACT_YR_BLT || '';
              let cod = '';
              if (year && (year !== '' || year !== null)) {
                year = year.toString();
                cod = new Date(propertyDataFromAdd.PropDetails.ACT_YR_BLT + '-01-01');
              }
              allData.propertyInfo.s_YearBuilt = year;
              allData.propertyInfo.d_DateOfConstruction = cod;

              allData.propertyInfo.s_NoOfUnits = propertyDataFromAdd.PropDetails.NO_RES_UNTS || '';
              allData.propertyInfo.n_NoOfFloors = propertyDataFromAdd.PropDetails.NO_STORIES || '';
              allData.propertyInfo.s_BldgConstructionType = propertyDataFromAdd.ConstructionType;

              allData.propertyInfo.s_TotalSqFootage = propertyDataFromAdd.PropDetails.TOT_LVG_AR || '';
              allData.propertyInfo.s_Usage = propertyDataFromAdd.Usage;
              allData.personAddr.s_Latitude = propertyDataFromAdd.s_Latitude ? parseFloat(propertyDataFromAdd.s_Latitude) : '';
              allData.personAddr.s_Longitude = propertyDataFromAdd.s_Longitude ? parseFloat(propertyDataFromAdd.s_Longitude) : '';
              allData.personInfo.s_FullLegalName = propertyDataFromAdd.PropDetails.OWN_NAME;
              allData.personAddr.s_ParcelId = propertyDataFromAdd.PropDetails.PARCEL_ID || '';
              allData.personInfo.s_IsMailingDiff = 'NO';
              allData.personPhoneContact.s_CommType = 'Home';
              allData.propertyInfo.s_BuildingUse = 'APARTMENTUNIT20';
            }
            if (propertyDataAutocompleteStored) {
              allData.personAddr.s_CityName = propertyDataAutocompleteStored.city;
              allData.personAddr.s_CountyName = propertyDataAutocompleteStored.CountyName;
              allData.personAddr.s_StateName = propertyDataAutocompleteStored.state;
              allData.personAddr.s_CountryName = propertyDataAutocompleteStored.country;
              allData.personAddr.s_StreetName = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
              allData.personAddr.s_PostalCode = propertyDataAutocompleteStored.PostalCode;
              allData.personAddr.s_PostalCodeSuffix = propertyDataAutocompleteStored.s_PostalCodeSuffix;
              allData.personAddr.s_HouseNo = propertyDataAutocompleteStored.street_number;
              allData.personAddr.s_AddressLine1 = propertyDataAutocompleteStored.street_number + ' ' + propertyDataAutocompleteStored.street_name;
              allData.personAddr.s_AddressLine2 = propertyDataAutocompleteStored.autocomplete;
            }
            setOnTypeSet(type)
          }
          dispatch(getEditEmptyQuoteData(allData));
        }
      }).catch(() => toast.error(t('Something Went Wrong!')));
    } else {
      toast.info(t('Not Active'));
    }
  }

    // Draggable Modal
    const DraggableModalDialog = (props) => {
      return (
        <Draggable handle={'#confirmPropInfo'}>
          <ModalDialog {...props} />
        </Draggable>
      )
    }
  useEffect(() => {
    if (props.emptyQuoteData) {
      callPivotApi();
    }
  }, [props.s_YearBuilt, props.s_Usage, props.s_Foundation, props.s_BldgConstructionType, props.n_NoOfFloors, props.s_AreaInSqFt, props.s_TotalSqFootage, props.s_DtOfConstructionSource, props.d_DateOfConstruction, props.s_NoOfUnits, props.s_UnitLocatedOnFloor, props.s_BuildingUse]);

  useEffect(() => {
    if (!firstTimeState) {
      if (props.emptyQuoteData && props.initApiObject && Object.keys(props.emptyQuoteData).length > 0) {
        callPivotApi();
        setFirstTimeState(true);
      }
    }
  }, [props.emptyQuoteData]);

  useEffect(()=>{
    setModal(
      <Modal size="lg" style={{marginLeft:"12rem", marginTop:'15rem'}} id='confirmPropInfo' dialogAs={DraggableModalDialog} show={true}>
      <Modal.Header>
        <Modal.Title>{t('Confirm Your Property Information')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'justify' }}>
        <h4>{t("Please verify the information displayed. By confirming the details, we can provide the most accurate premium. Then click 'Next' to continue")}.</h4>
      </Modal.Body>
    <Modal.Footer>
      <Button className="m-auto" onClick={()=> setModal(null)}>Ok</Button>
    </Modal.Footer>
      </Modal >
    )
  }, [])

  const callPivotApi = () => {
    if (props.emptyQuoteData && props.initApiObject && Object.keys(props.emptyQuoteData).length > 0) {
      if (props.emptyQuoteData.viewType != '') {
        // Allow Primary Residency Discount If Usage not equal to SEASONAL, RENT OR UNDERCONST
        var temp = 'YES';
        if (['SEASONAL', 'RENT', 'UNDERCONST'].includes(props.s_Usage)) {
          temp = 'NO'
        }
        if (props.emptyQuoteData.viewType !== "") {
          const reqJson = { ...props.initApiObject };
          reqJson.primaryResidence = temp;

          reqJson.buildingDescription = props.s_BuildingUse;
          reqJson.numberOfUnits = props.s_NoOfUnits;
          reqJson.floorOfUnit = props.s_UnitLocatedOnFloor;

          reqJson.addressLine1 = props.emptyQuoteData.personAddr.s_AddressLine1
          reqJson.addressLine2 = props.emptyQuoteData.personAddr.s_AddressLine1
          reqJson.addressType = 'PROPERTY_ADDRESS'
          reqJson.city = props.emptyQuoteData.personAddr.s_CityName
          reqJson.countyFips = props.emptyQuoteData.personAddr.s_countyFips
          reqJson.deleteAddress = false
          reqJson.propertyBeginningStreetNumber = props.emptyQuoteData.personAddr.s_HouseNo
          reqJson.nameOrDescriptiveType = 'N'
          reqJson.state = props.emptyQuoteData.personAddr.s_StateName
          reqJson.stateFips = props.emptyQuoteData.personAddr.s_stateFips
          reqJson.zipCode = props.emptyQuoteData.personAddr.s_PostalCode
          reqJson.zipPlus4 = props.emptyQuoteData.personAddr.s_PostalCodeSuffix
          reqJson.constructionType = props.emptyQuoteData.propertyInfo.s_BldgConstructionType
          reqJson.effectiveDate = props.emptyQuoteData.effectiveDate
          reqJson.foundationType = props.emptyQuoteData.propertyInfo.s_Foundation
          reqJson.numberOfFloors = props.emptyQuoteData.propertyInfo.n_NoOfFloors
          reqJson.originalConstructionDate = props.emptyQuoteData.propertyInfo.d_DateOfConstruction
          reqJson.totalSquareFootage = props.emptyQuoteData.propertyInfo.s_TotalSqFootage
          reqJson.enclosureSize = props.emptyQuoteData.propertyInfo.s_AreaInSqFt
          reqJson.communityNumber = props.emptyQuoteData.propertyInfo.s_CommunityNumber
          reqJson.floodZone = props.emptyQuoteData.propertyInfo.s_FloodZone
          reqJson.postFirmConstruction = ''
          reqJson.preFirmSubsidyEligible = ''
          reqJson.priorNaicNumber = null;
          reqJson.priorPolicyNumber = null;
          reqJson.substantialImprovementDate = "";
          reqJson.sqft = props.emptyQuoteData.propertyInfo.s_TotalSqFootage;
          reqJson.type = props.emptyQuoteData.viewType;
          reqJson.uniqueKey = localStorage.getItem('uniqueKey');
          reqJson.whichSection = props.whichSection ?? 'DEFAULT';

          dispatch(updateInitQuoteApi(reqJson));
          if (reqJson.addressLine1 !== null && Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.ZDDetails).length > 0 ) {
            const code = 'QUOTE_NEWBUSINESS';
            const request = { 'code': code, 'requestParam': reqJson, 'sourceId': 0};
            // Call Api
            if (reqJson.addressLine1 !== null && Object.keys(props.emptyQuoteData).length > 0 && Object.keys(props.ZDDetails).length > 0 ) {
              dispatch(pivotApiCall(code, request));              
            }
          }

          const emptyQuoteData = { ...props.emptyQuoteData };
          emptyQuoteData.propertyInfo.s_InsuredResides = temp;
          emptyQuoteData.personAddr.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.personEmailContact.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.personInfo.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.personPhoneContact.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.policyInfo.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.propertyCoverageInfo.n_UpdatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.propertyInfo.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.transactionInfo.n_CreatedUser = localStorage.getItem('userID') || '';
          emptyQuoteData.uniqueKey = localStorage.getItem('uniqueKey');
          dispatch(getEditEmptyQuoteData(emptyQuoteData));
        }
      }
    }

  }
  return (
    <LoadingOverlay>
      <Loader loading={props.apiLoading || loading} />
      <section className="home-type-section">
        {/* Background Image */}
        <div className="home-type-bg">
          <div className="home-type-buidling"></div>
          <div className="home-type-house"></div>
        </div>

        {/* Form */}
        <Container style={{ maxWidth: '1000px' }}>
          {/* {
                (propertyDataFromAdd && Object.keys(propertyDataFromAdd).length === 0) && <Navigate to={'/flood/instantquote/step/address'} />
              } */}
          <div className="justify-content-center">
            <Row>
              <Col xs="12" className="flood-div">
                <Row className="justify-content-center">
                  <div className="home-type-headings">
                    <h4 className="home-type-title">{t('Select the property type')}</h4>
                    <p className="home-type-desc">
                      {/* {t('Choose a property you want to issue with Unify')} */}
                    </p>
                  </div>
                  <Row>
                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                      <div
                        className="flood-quote-page-cards-div"
                        onClick={() => navigatAction('HOME')}
                      >
                        <div className="home-type-option-icon" id="hto-icon1">
                          {(Object.keys(props.emptyQuoteData).length > 0 && props.emptyQuoteData.viewType == "HOME") || (onTypeSet === 'HOME') ? <AiFillCheckCircle size={25} style={{ paddingLeft: '0%', marginTop: '10%', marginLeft: '325%', color: '#61d3c9' }} data-tip data-for="equipment" /> : ''}
                        </div>
                        <img className="flood-quote-page-cards-icon" src={Icon1} />
                        <div className="flood-quote-page-cards-title">{t('Home')}</div>
                        <br />
                        <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>{t('1 - 4 Residential occupancies')}</p>
                      </div>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                      <div
                        className="flood-quote-page-cards-div"
                        onClick={() => navigatAction('MOBILEHOME')}
                      >
                        <div className="home-type-option-icon" id="hto-icon1">
                          {(Object.keys(props.emptyQuoteData).length > 0 && props.emptyQuoteData.viewType == "MOBILEHOME") || (onTypeSet === 'MOBILEHOME') ? <AiFillCheckCircle size={25} style={{ paddingLeft: '0%', marginTop: '10%', marginLeft: '325%', color: '#61d3c9' }} data-tip data-for="equipment" /> : ''}
                        </div>
                        <img className="flood-quote-page-cards-icon" src={Icon2} />
                        <div className="flood-quote-page-cards-title">{t('Mobile Home')}</div>
                        <br />
                        <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>{t('Residential or Non-Residential')}</p>
                      </div>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                      <div
                        className="flood-quote-page-cards-div"
                        onClick={() => navigatAction('UNIT')}
                      >
                        <div className="home-type-option-icon" id="hto-icon1">
                          {(Object.keys(props.emptyQuoteData).length > 0 && props.emptyQuoteData.viewType == "UNIT") || (onTypeSet === 'UNIT') ? <AiFillCheckCircle size={25} style={{ paddingLeft: '0%', marginTop: '10%', marginLeft: '325%', color: '#61d3c9' }} data-tip data-for="equipment" /> : ''}
                        </div>
                        <img className="flood-quote-page-cards-icon" src={Icon3} />
                        <div className="flood-quote-page-cards-title">{t('Unit')}</div>
                        <br />
                        <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>{t('Residential or Non-Residential')}</p>
                      </div>
                    </Col>

                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                      <div
                        className="flood-quote-page-cards-div"
                        onClick={() => navigatAction('RESIDENTIALBLDG')}
                      >
                        <div className="home-type-option-icon" id="hto-icon1">
                          {(Object.keys(props.emptyQuoteData).length > 0 && props.emptyQuoteData.viewType == "RESIDENTIALBLDG") || (onTypeSet === 'RESIDENTIALBLDG') ? <AiFillCheckCircle size={25} style={{ paddingLeft: '0%', marginTop: '10%', marginLeft: '325%', color: '#61d3c9' }} data-tip data-for="equipment" /> : ''}
                        </div>
                        <img className="flood-quote-page-cards-icon" src={Icon4} />
                        <div className="flood-quote-page-cards-title">{t('Residential Building')}</div>
                        <br />
                        <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>{t('5 or more Occupancies and RCBAP')}</p>
                      </div>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>

                    <Col xs="12" sm="12" md="6" lg="3" xl="3">
                      <div
                        className="flood-quote-page-cards-div"
                        onClick={() => navigatAction('NONRESIDENTIALBLDG')}
                      >
                        <div className="home-type-option-icon" id="hto-icon1">
                          {(Object.keys(props.emptyQuoteData).length > 0 && props.emptyQuoteData.viewType == "NONRESIDENTIALBLDG") || (onTypeSet === 'NONRESIDENTIALBLDG') ? <AiFillCheckCircle size={25} style={{ paddingLeft: '0%', marginTop: '10%', marginLeft: '325%', color: '#61d3c9' }} data-tip data-for="equipment" /> : ''}
                        </div>
                        <img className="flood-quote-page-cards-icon" src={Icon5} alt='flood' />
                        <div className="flood-quote-page-cards-title">{t('Non-Residential Building')}</div>
                        <br />
                        <p style={{ fontSize: "16px", fontStyle: 'italic', textAlign: 'center' }}>{t('All Non-Residential, including Detached Structures')}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col xs='8' sm="8" md="8" lg="8" >
                    {model}
                    </Col>
                  </Row>
                  <br></br>
                  <Col>
                    {props.emptyQuoteData.viewType &&
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t('Property Details')}</Accordion.Header>
                          <Accordion.Body>
                            <ModePropertyDetails {...props} />

                          </Accordion.Body>
                        </Accordion.Item>
                        {
                          mobileHomeCheck && (
                            <Accordion.Item eventKey="0">
                            <Accordion.Header>{t('Construction')}</Accordion.Header>
                            <Accordion.Body>
                              <ModeConstructionFormMH {...props} />
                            </Accordion.Body>
                          </Accordion.Item>
                          )
                        }
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t('Starting Estimate')}</Accordion.Header>
                          <Accordion.Body>
                            <Row>
                              <Col xs="12" className="starting-estimate-content" >
                                <div className="starting-estimate-icon"></div>
                                {/* <ModeStartingEstimate handleChange={props.handleChange} premium={props.premium}/> */}
                                <ModeStartingEstimate premium={props.premium} {...props} />
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t('Personal Information')}</Accordion.Header>
                          <Accordion.Body>
                            <ModeContactInformation {...props} />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t('Waiting Period')}</Accordion.Header>
                          <Accordion.Body>
                            <ModeWaitingPeriod {...props} />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header> {t('Construction')} </Accordion.Header>
                          <Accordion.Body>
                            <ModeConstructionFormOne {...props} />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{t('Discounts')}</Accordion.Header>
                          <Accordion.Body>
                            <ModeDiscountForm   {...props} />
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                    }
                  </Col>
                  {props.emptyQuoteData.viewType &&
                  <Row style={{ marginTop: "17px" }}>
                    <Col xs="12" className="form-btns">
                      <ModeSteps step={1} {...props} />
                    </Col>
                  </Row>
                  }
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </LoadingOverlay>
  )
}

const mapStateToProps = (state) => {
  return {
    apiLoading: state.flood.apiLoading ? state.flood.apiLoading : false,
    propertyDataFromAddress: state.common.propertyDataFromAdd ? state.common.propertyDataFromAdd.data : {},
    emptyQuoteData: state.flood.emptyQuoteData ? state.flood.emptyQuoteData : {},
    ZDDetails: state.flood.ZDDetails ? state.flood.ZDDetails : {},
    initApiObject: state.flood.initApiObject ? state.flood.initApiObject : {},
    pageMode: state.flood.pageMode,

    s_YearBuilt: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_YearBuilt || '',
    s_Usage: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_Usage || '',
    s_Foundation: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_Foundation || '',
    s_BldgConstructionType: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_BldgConstructionType || '',
    n_NoOfFloors: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.n_NoOfFloors || '',
    s_AreaInSqFt: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_AreaInSqFt || '',
    s_TotalSqFootage: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_TotalSqFootage || '',
    s_DtOfConstructionSource: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_DtOfConstructionSource || '',
    d_DateOfConstruction: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.d_DateOfConstruction || '',
    s_NoOfUnits: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_NoOfUnits || '',
    s_UnitLocatedOnFloor: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_UnitLocatedOnFloor || '',
    s_BuildingUse: state.flood.emptyQuoteData.propertyInfo && state.flood.emptyQuoteData.propertyInfo.s_BuildingUse || '',

    effectiveDate: state.flood.emptyQuoteData ? (state.flood.emptyQuoteData.effectiveDate || '') : '',
  };
}
export default connect(mapStateToProps)(ModeHomeType);