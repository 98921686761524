import React, { useState, useEffect } from "react";
import "./license.scss";
// import { Row, Col, Button, Modal, Card} from "react-bootstrap";
// import "react-datepicker/dist/react-datepicker.css";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
// import "react-datepicker/dist/react-datepicker.css";
import { loadCeLicenseDocumentDataTableServices, getViewDocument,deleteCeLicenseData } from "../../services/ceDocumentService";
// import {formatCeDate } from "../../services/commonService";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import  EditCELicense  from "./EditCELicense";
import  ViewCELicense  from "./ViewCELicense";

import Swal from 'sweetalert2';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Button,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
} from "@chakra-ui/react";
import { t } from "i18next";
import { formateDate } from "../common/FormatDate";
import { FaEye } from "react-icons/fa";

const CELicenseList = (props) => {
	const dispatch = useDispatch();
    const [listData, setListData] = useState([]);
    const [reasonModal, setReasonModal] = useState(false);
    const [viewReason, setViewReason] = useState('');
	const [isEditModal,setIsEditModal] = useState(null);
  const statusArray = {
    APPROVED: { label: t("Completed"), color: "green" },
    REJECTED: { label: t("Rejected"), color: "red" },
    PENDING: { label: t("Approval Pending"), color: "orange" },
  };
    
	useEffect(() => {
		if(props?.userId){
			loadTableData(props.userId);
		}else{
			loadTableData();
		}
        
    }, [props.userId]);
	
    const loadTableData = async (userId = 0) => {
		if(userId){
			const datas = await loadCeLicenseDocumentDataTableServices({userId:userId, pageTemp: 1, sizePerPageTemp: 50 });
			setListData(datas.returnObject);
		}else{
			const datas = await loadCeLicenseDocumentDataTableServices({pageTemp: 1, sizePerPageTemp: 50 });
			setListData(datas.returnObject);
		}
    }

    const handleClose = () => setReasonModal(false);

    const handleShow = (e,row) => {
		e.preventDefault();
	    setReasonModal(true);
	    setViewReason(row.reason);
  }

    const statusField = (cell, row) => {
        if (row.docStatus == "APPROVED") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "green" }} >Completed</h1>
                </div>
            )
        }
        if (row.docStatus == "REJECTED") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "red", cursor: "pointer" }} onClick={(e) => handleShow(e,row)}>
                        <div>
                            <a href='#' style={{ color: "red" }}>Rejected</a>
                        </div>
                    </h1>
                </div>
            )
        }
        if (row.docStatus == "PENDING") {
            return (
                <div>
                    <h1 style={{ fontSize: "1.2rem", color: "orange" }}>Approval Pending</h1>
                </div>
            )
        }
    }

    const viewDocument = async (e,row) => {
		e.preventDefault();
        let data = await getViewDocument(row.DocInfo_PK, 'NEW_SYSTEM', 'Producer');
        let docUrl = data.url;
        if (docUrl != '') {
            window.open(docUrl);
        }
    }

    const actionButton = (cell, row, enumObject, rowIndex) => {
		return (
			<div>
				<Button id="common-btn" name="actionNameData"  style={{ padding: "6px 10px" }} onClick={(e) => editModal(e,row,'VIEW')} ><a href='#' target="_blank" style={{ color: "white" }}><i className='fa fa-eye' /></a></Button>
				&nbsp;
			</div>
		)
		
        
    }
	
	const toggle = () => {
        setIsEditModal(null);
    }

    const editModal = (e,row,type) => {
		e.preventDefault();
		if(type == 'EDIT'){
			setIsEditModal(<EditCELicense toggle={toggle} rowData = {row}/>);
		}
		if(type == 'VIEW'){
			setIsEditModal(<ViewCELicense toggle={toggle} rowData = {row}/>);
		}
    }
    
	const deleteModal = (e,row) => {
		e.preventDefault();
        Swal.fire({
            title: 'Confirmation',
            text: "Are sure you want to delete Ce License!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
          }).then(async(result) => {
            if (result.isConfirmed) {
				const params ={
					rowId:row.id
				}
				let deleteFileData =  await deleteCeLicenseData(params);
            	if(deleteFileData?.data?.success == true){
					toast.success(deleteFileData?.data?.data?.message);
				}else{
					toast.error('Error deleting Celicense');
				}
				loadTableData();
            }
        })
    }

	
	const handleCloseEdit = () => setIsEditModal(false);

    // const columns = [
    //     {
    //         dataField: "courseName",
    //         text: "Course Name",
    //     },
    //     {
    //         dataField: "uploadedDate",
    //         text: "Upload Date",
		// 	formatter: (value) => formatCeDate(value),
    //     },
    //     {
    //         dataField: "expiryDate",
    //         text: "Expiry Date",
		// 	formatter: (value) => formatCeDate(value),
    //     },
    //     {
    //         dataField: "s_ScreenName",
    //         text: "User",
    //     },
    //     {
    //         dataField: "docStatus",
    //         text: "Status",
    //         formatter: (value, row) => statusField(value, row),
    //     },

    //     {
    //         text: "Actions",
    //         formatter: (value, row) => actionButton(value, row),
    //     },
    // ];

    const actionButtonRenderer = (data) => {
      return (
        <IconButton
          size={"sm"}
          icon={<FaEye size={16} />}
          onClick={(e) => editModal(e, data, "VIEW")}
        />
      );
    };

    return (
      <Box
        bg={"transparent"}
        maxHeight={"15rem"}
        overflowX={"hidden"}
        overflowY={"auto"}
        borderRadius={"2xl"}
        boxShadow={"lg"}
        mb={6}
        mt={2}
      >
        <TableContainer
          borderRadius={"2xl"}
          border={"1px solid"}
          boxShadow={"lg"}
          borderColor={"var(--chakra-colors-primary-50)"}
        >
          <Table
            variant="simple"
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"2xl"}
            overflow={"hidden"}
          >
            <Thead bg={"var(--chakra-colors-agGridHeaderBg)"}>
              <Tr>
                <Th>{t("Course Name")}</Th>
                <Th>{t("Upload Date")}</Th>
                <Th>{t("Expiry Date")}</Th>
                <Th>{t("User")}</Th>
                <Th>{t("Status")}</Th>
                <Th>{t("Actions")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {listData.length > 0 ? (
                listData.map((data, index) => {
                  return (
                    <Tr
                      key={index}
                      _hover={{
                        bg: `rgba(0, 0, 0, 0.075)`,
                      }}
                    >
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        {data.courseName}
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        {data.uploadedDate !== "N/A"
                          ? formateDate(data.uploadedDate)
                          : data.uploadedDate}
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        {data.expiryDate !== "N/A"
                          ? formateDate(data.expiryDate)
                          : data.expiryDate}
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        {data.s_ScreenName}
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        <Text
                          mb={0}
                          color={
                            data.docStatus in statusArray
                              ? statusArray[data.docStatus].color
                              : "black"
                          }
                          fontSize={"1rem"}
                          cursor={
                            data.docStatus in statusArray &&
                            statusArray[data.docStatus] === "REJECTED"
                              ? "pointer"
                              : "default"
                          }
                          onClick={(e) => {
                            if (
                              data.docStatus in statusArray &&
                              statusArray[data.docStatus] === "REJECTED"
                            ) {
                              handleShow(e, data);
                            }
                          }}
                        >
                          {data.docStatus in statusArray
                            ? statusArray[data.docStatus].label
                            : data.docStatus}
                        </Text>
                      </Td>
                      <Td
                        p={2}
                        border={"1px solid"}
                        borderColor={"var(--chakra-colors-primary-50)"}
                      >
                        {actionButtonRenderer(data)}
                      </Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={6} textAlign={"center"}>
                    {t("No Rows To Show")}
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {/* CE License Modal */}
        {isEditModal}

        {/* Rejected Reason Modal */}
        <Modal isOpen={reasonModal} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottom={"2px solid"} borderColor={"gray.200"}>{t("Message")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={0} fontSize={18}>
                {viewReason}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleClose}>{t("Close")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        {/* <div className="inbox-page-section">
            <Row>
                <Col xs="12">
                    <div
                        className="inbox-table"
                        id="inbox-table-outer-div"
                        style={{ paddingBottom: "20px", overflowX: "scroll" }}
                    >
                        <BootstrapTable
                            wrapperClasses="management-functional-table"
                            bootstrap4
                            keyField="BaseMessageLink_IdFk"
                            data={listData}
                            columns={columns}
                            bordered={false}
                            pagination={paginationFactory()}
                            tabIndexCell
							noDataIndication={
								<div className="center-div-class">No data</div>
							  }
							condensed
                        />
                    </div>
                </Col>
            </Row>
			{isEditModal}
			<Modal show={reasonModal} onHide={handleClose} className="modal-md">
                <Modal.Header className="p-2 mt-3" style={{ fontSize: "20px" }}><label >Message</label></Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Text>
                            <Row style={{ textAlign: "center" }}>
                                <Col>
                                    <label htmlFor="" style={{ fontSize: "21px" }}> {viewReason} </label>
                                </Col>
                            </Row>
                        </Card.Text>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> */}
      </Box>
    )
}

export default CELicenseList;