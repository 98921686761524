import {
  Card,
  Flex,
  Grid,
  Image,
  Text,
  Button,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import { FiChevronLeft } from "react-icons/fi";

const CardItem = ({ icon, title, description, onClick, selected }) => {
  return (
    <Card
      border={selected ? "1px solid" : "1px solid"}
      borderColor={selected ? "primary.500" : "neutral.100"}
      borderRadius={"2xl"}
      boxShadow={"0 0 10px rgba(0, 0, 0, 0.1)"}
      bg={"white"}
      _hover={{
        cursor: "pointer",
        boxShadow: selected ? "0" : "0 0 6px rgba(0, 0, 0, 0.4)",
        transition: "all 0.1s ease",
        borderColor: selected ? "primary.500" : "transparent",
      }}
      onClick={onClick}
    >
      <Flex alignItems={"center"} flexDir={"column"} p={5}>
        <Image
          userSelect={"none"}
          maxW={"225px"}
          h={"250px"}
          src={icon}
          alt={title}
        />
        <Text mt={2} fontWeight={"bold"} mb={1}>
          {title}
        </Text>
        <Text
          color="var(--chakra-colors-neutral-500)"
          mt={0}
          fontSize={"sm"}
          fontWeight={"light"}
          textAlign="center"
        >
          {description}
        </Text>
      </Flex>
    </Card>
  );
};

const QuestionUI = ({
  cardTitle,
  questions,
  selections,
  onOptionSelect,
  onBack,
  imageLink,
  selected,
}) => {
  return (
    <Card
      height={"340px"}
      p={4}
      borderRadius={"2xl"}
      boxShadow={"0 0 6px rgba(0, 0, 0, 0.1)"}
      border={"1px solid"}
      borderColor={selected ? "primary.500" : "transparent"}
    >
      <VStack align="start">
        <Flex justifyContent={"space-between"} w={"100%"}>
          <Flex justifyContent={"space-between"}>
            <Text my={0} fontWeight="bold" fontSize="xl" zIndex={2}>
              {cardTitle}
            </Text>
            <Box>
              <Image
                mt={6}
                opacity={0.7}
                objectFit="cover"
                userSelect={"none"}
                left={{ md: "14%", lg: "30%" }}
                position={"absolute"}
                width={{
                  md: "150px",
                  lg: "250px",
                }}
                height={{
                  md: "100px",
                  lg: "250px",
                }}
                src={imageLink}
              />
            </Box>
          </Flex>
          <Button
            borderRadius={"full"}
            variant={"outline"}
            size="sm"
            onClick={onBack}
          >
            <FiChevronLeft size={20} />
          </Button>
        </Flex>
        {Object.entries(questions).map(([question, { options }]) => (
          <Box key={question} mt={2} opacity={0.98}>
            <Text color={"neutral.500"} fontSize="md" fontWeight="bold">
              {question}
            </Text>
            <Flex gap={3}>
              {options.map((option) => (
                <Button
                  key={option}
                  borderRadius={"xl"}
                  border={"1px solid"}
                  borderColor={"neutral.200"}
                  size="sm"
                  onClick={() => onOptionSelect(cardTitle, question, option)}
                  colorScheme={
                    selections[cardTitle]?.[question] === option
                      ? "primary"
                      : "gray"
                  }
                >
                  {option}
                </Button>
              ))}
            </Flex>
          </Box>
        ))}
      </VStack>
    </Card>
  );
};

export const CardSelection = () => {
  const { t } = useTranslation();
  const { selectedCard, setSelectedCard, selections, setSelections } =
    useQuote();

  const handleCardClick = (cardTitle) => {
    setSelectedCard(cardTitle);
  };

  const handleOptionSelect = (cardTitle, question, option) => {
    setSelections((prev) => ({
      ...prev,
      [cardTitle]: { ...prev[cardTitle], [question]: option },
    }));
  };

  const handleBack = () => {
    setSelectedCard(null);
    setSelections({});
  };

  const treeData = {
    HOA: {
      icon: "/starImages/HOA.svg",
      title: "HOA",
      description: "Homeowners Association",
      children: null,
    },
    Hotel: {
      icon: "/starImages/hotel.svg",
      title: "Hotel",
      description: "Lodging and Services",
      children: null,
      // children: {
      //   Luxury: {
      //     type: "boolean",
      //     options: ["Yes", "No"],
      //   },
      //   Cooking: {
      //     type: "select",
      //     options: ["Yes", "None", "Limited Cooking"],
      //   },
      //   Stories: {
      //     type: "select",
      //     options: ["1-4", "5+"],
      //   },
      // },
    },
    ConvenienceStore: {
      icon: "/starImages/gas_station.svg",
      title: "Gas Station / Convenience Store",
      description: "Essentials",
      children: null,
      // children: {
      //   Restaurant: {
      //     type: "boolean",
      //     options: ["With", "Without"],
      //   },
      // },
    },
  };

  return (
    <Grid templateColumns={"repeat(3, 1fr)"} mt={4} mb={5} gap={6}>
      {Object.entries(treeData).map(
        ([key, { icon, title, description, children }]) => (
          <Box key={key}>
            {selectedCard === key && children ? (
              <QuestionUI
                cardTitle={title}
                questions={children}
                selections={selections}
                onOptionSelect={handleOptionSelect}
                onBack={handleBack}
                imageLink={icon}
                selected={selectedCard === key}
              />
            ) : (
              <CardItem
                selected={selectedCard === key}
                icon={icon}
                title={t(title)}
                description={t(description)}
                onClick={() => handleCardClick(key)}
              />
            )}
          </Box>
        )
      )}
    </Grid>
  );
};
