import * as ActionTypes from '../action-types';
import {SET_PRIVATE_PRODUCT_OPTION_VISIBILITY} from "../action-types";

// export function authLogin(payload) {
//   return {
//     type: ActionTypes.AUTH_LOGIN,
//     payload,
//   };
// }

export function authLogout() {
  return {
    type: ActionTypes.AUTH_LOGOUT,
  };
}

export function getSiteData() {
	return {
	  type: ActionTypes.SITE_DATA,
	};
  }

export function getLogo(payload) {
  return {
    type: ActionTypes.GET_LOGO,
    payload,
  };
}

export function authCheck() {
  return {
    type: ActionTypes.AUTH_CHECK,
  };
}

export function getQuoteListData(payload) {
  return {
    type: ActionTypes.GET_QUOTE_LIST_DATA,
    payload,
  }
}

export function setIsLoading(payload) {
  return {
    type: ActionTypes.IS_LOADING,
    payload,
  }
}

export function setSaveLoading(payload) {
  return {
    type: ActionTypes.SAVE_LOADING,
    payload,
  }
}

export function getPropertyDataFromAddress(payload) {
  return {
    type: ActionTypes.GET_PROPERTY_DATA_FROM_ADDRESS,
    payload,
  }
}

export function getActivityListData(payload) {
  return {
    type: ActionTypes.GET_ACTIVITY_LIST_DATA,
    payload,
  }
}

export function getQuoteDataHO3(payload) {
  return {
    type: ActionTypes.GET_QUOTE_DATA_HO3,
    payload,
  }
}

export function getBaseData(payload) {

  return {
    type: ActionTypes.GET_BASE_DATA,
    payload,
  }
}

export function getEmptyQuoteData(payload) {
  return {
    type: ActionTypes.GET_EMPTY_QUOTE_DATA,
    payload,
  }
}

export function getFloodSystemDD(payload) {
  return {
    type: ActionTypes.GET_FLOOD_SYSTEM_DD,
    payload,
  }
}

export function getEditEmptyQuoteData(payload) {
  return {
    type: ActionTypes.EDIT_EMPTY_QUOTE_DATA,
    payload,
  }
}

export function setPolicyPropsData(payload) {
  return {
    type: ActionTypes.SET_CORE_POLICY_DATA,
    payload,
  }
}
export function getInitQuoteApi(payload) {
  return {
    type: ActionTypes.GET_INIT_QUOTE_API,
    payload,
  }
}

export function getPropertyDataAutocompleteStore(payload) {
  return {
    type: ActionTypes.GET_PROPERTY_AUTOCOMPLETE_STORED,
    payload,
  }
}

export function updateAgencyAgentName(isUpdated) {
  return {
    type: ActionTypes.UPDATE_AGENCY_AGENT_NAME,
    payload: isUpdated,
  };
}

export function getZDDetails(payload) {
  return {
    type: ActionTypes.GET_ZD_DETAILS,
    payload,
  }
}

export function setTempZDDetails(payload) {
  return {
    type: ActionTypes.SET_TEMP_ZD_DATA,
    payload,
  }
}

export function setTempQuoteNo(payload) {
  return {
    type: ActionTypes.SET_TEMP_QUOTE_NO,
    payload,
  }
}

export function getQuoteResponseDetails(payload) {
  return {
    type: ActionTypes.GET_QUOTE_RESPONSE_DETAILS,
    payload,
  }
}

export function getBasicCvgDetails(payload) {
  return {
    type: ActionTypes.GET_BASIC_COVERAGE_DETAILS,
    payload,
  }
}

export function getProductListData(payload) {
  return {
    type: ActionTypes.GET_PRODUCT_LIST_DATA,
    payload,
  }
}

export function getRecommendedCvgDetails(payload) {
  return {
    type: ActionTypes.GET_RECOMMENDED_COVERAGE_DETAILS,
    payload,
  }
}

export function getAppStatusDD(payload) {
  return {
    type: ActionTypes.GET_APP_STATUS_DATA,
    payload,
  }
}

export function getBasicCvgResponse(payload) {
  return {
    type: ActionTypes.GET_BASIC_COVERAGE_RESPONSE,
    payload,
  }
}

export function getRecommendedCvgResponse(payload) {
  return {
    type: ActionTypes.GET_RECOMMENDED_COVERAGE_RESPONSE,
    payload,
  }
}

export function getCustomCvgDetails(payload) {
  return {
    type: ActionTypes.GET_CUSTOM_COVERAGE_DETAILS,
    payload,
  }
}

export function getCustomCvgResponse(payload) {
  return {
    type: ActionTypes.GET_CUSTOM_COVERAGE_RESPONSE,
    payload,
  }
}

export function getWhichSection(payload) {
  return {
    type: ActionTypes.GET_WHICH_SECTION,
    payload,
  }
}

export function getLastSection(payload) {
  return {
    type: ActionTypes.GET_LAST_SECTION,
    payload,
  }
}

export function getDeductibles(payload) {
  return {
    type: ActionTypes.GET_DEDUCTIBLE_OPTIONS,
    payload,
  }
}

export function getCreateQuoteResponse(payload) {
  return {
    type: ActionTypes.GET_CREATE_QUOTE_RESPONSE,
    payload,
  }
}

export function getInitPaymentData(payload) {
  return {
    type: ActionTypes.GET_INIT_PAYMENT_DATA,
    payload,
  }
}

export function setAllProducts(payload) {
  return {
    type: ActionTypes.SET_PRODUCT_LIST,
    payload
  }
}

export function setActionTypeDD(payload) {
  return {
    type: ActionTypes.SET_ACTION_TYPE_DD,
    payload
  }
}

export function setTermMaster(payload) {
  return {
    type: ActionTypes.SET_CORE_POLICY_TERM,
    payload
  }
}

export function setTransaction(payload) {
  return {
    type: ActionTypes.SET_CORE_POLICY_TRANS,
    payload
  }
}

export function setTermSelection(payload) {
  return {
    type: ActionTypes.SET_TERM_SELECTION,
    payload
  }
}

export function setTransactionDD(payload) {
  return {
    type: ActionTypes.SET_TRANSACTION_DD,
    payload
  }
}

export function setApplicationData(payload) {
  return {
    type: ActionTypes.SET_APPLICATION_DATA,
    payload
  }
}

export function getCvgData(payload) {
  return {
    type: ActionTypes.SET_POLICY_COVERAGE_DATA,
    payload,
  }
}

export function getAdditionalData(payload) {
  return {
    type: ActionTypes.SET_ADDITIONAL_DATA,
    payload
  }
}

export function getMortgageeData(payload) {
  return {
    type: ActionTypes.SET_MORTGAGEE_DATA,
    payload
  }
}

export function getHouseholdData(payload) {
  return {
    type: ActionTypes.SET_HOUSEHOLD_DATA,
    payload
  }
}

export function getAddiInterestData(payload) {
  return {
    type: ActionTypes.SET_ADDITIONAL_INTEREST_DATA,
    payload
  }
}

export function getPriorPolicyLossData(payload) {
  return {
    type: ActionTypes.SET_PRIOR_POLICY_LOSS_DATA,
    payload
  }
}

export function getFormCopyData(payload) {
  return {
    type: ActionTypes.SET_FORM_COPY_DATA,
    payload
  }
}
export function getBillingData(payload) {
  return {
    type: ActionTypes.SET_BILLING_DATA,
    payload
  }
}

export function getAccountData(payload) {
  return {
    type: ActionTypes.SET_ACCOUNT_DATA,
    payload
  }
}

export function getReceivableData(payload) {
  return {
    type: ActionTypes.SET_RECEIVABLE_DATA,
    payload
  }
}

export function getInvoiceData(payload) {
  return {
    type: ActionTypes.SET_INVOICE_DATA,
    payload
  }
}

export function getInvoicePreFeeData(payload) {
  return {
    type: ActionTypes.SET_INVOICE_PREMIUM_FEE_DATA,
    payload
  }
}

export function setPropertyInfo(payload) {
  return {
    type: ActionTypes.SET_POLICY_PROPERTY_INFO,
    payload,
  }
}

export function setBaseData(payload) {
  return {
    type: ActionTypes.SET_BASE_DATA,
    payload,
  }
}

export function resetCoreStore() {
  return {
    type: ActionTypes.RESET_CORE_STORE
  }
}

export function resetFloodStore() {
  return {
    type: ActionTypes.RESET_FLOOD_STORE
  }
}

export function getInstantPropertyDataFromAddress(payload) {
  return {
    type: ActionTypes.GET_INSTANT_PROPERTY_DATA_FROM_ADDRESS,
    payload,
  }
}

export function getEmptyInstantQuoteData(payload) {
  return {
    type: ActionTypes.GET_EMPTY_INSTANT_QUOTE_DATA,
    payload,
  }
}

export function getInstantSystemDD(payload) {
  return {
    type: ActionTypes.GET_INSTANT_SYSTEM_DD,
    payload,
  }
}

export function getEditEmptyInstantQuoteData(payload) {
  return {
    type: ActionTypes.EDIT_EMPTY_INSTANT_QUOTE_DATA,
    payload,
  }
}

export function getInstantPropertyDataAutocompleteStore(payload) {
  return {
    type: ActionTypes.GET_INSTANT_PROPERTY_AUTOCOMPLETE_STORED,
    payload,
  }
}

export function getSaveFinalPremiumInstantQuoteData(payload) {
  return {
    type: ActionTypes.GET_SAVE_FINAL_PREMIUM_INSTA_QUOTE,
    payload,
  }
}

export function getMortgageeListData(payload) {
  return {
    type: ActionTypes.GET_MORTGAGEE_LIST_DATA,
    payload,
  }
}

export function getNewTransactionBaseData(payload) {
  return {
    type: ActionTypes.GET_NEW_TRANSACTION_BASE_DATA,
    payload
  }
}

export function getAppRemarkDD(payload) {
  return {
    type: ActionTypes.GET_APP_REMARK_DATA,
    payload,
  }
}

export function getAddressAnalysisGeoData(payload) {
  return {
    type: ActionTypes.GET_ADD_GEO_DATA,
    payload,
  }
}

export function setPrivateProductData(payload) {
  return {
    type: ActionTypes.SET_PRIVATE_PRODUCT_DATA,
    payload,
  }
}

export function setExcessProductData(payload) {
  return {
    type: ActionTypes.SET_EXCESS_PRODUCT_DATA,
    payload,
  }
}

export function initExcessProducts() {
  return {
    type: ActionTypes.INIT_EXCESS_PRODUCTS
  }
}

export function initPrivatProducts() {
  return {
    type: ActionTypes.INIT_PRIVATE_PRODUCTS
  }
}

export function setActivePrivateProductTab(payload) {
  return {
    type: ActionTypes.SET_ACTIVE_PRIVATE_PRODUCT_TAB,
    payload,
  }
}

export function getPageMode(payload) {
  return {
    type: ActionTypes.GET_PAGE_MODE,
    payload,
  }
}

export function getProducerData(payload) {
  return {
    type: ActionTypes.GET_PRODUCER_DATA,
    payload
  }
}

export function setCoreClaimData(payload) {
  return {
    type: ActionTypes.SET_CORE_CLAIM_DATA,
    payload,
  }
}

export function setLoader(payload) {
  return {
    type: ActionTypes.SET_DYNAMIC_LOADER,
    payload,
  }
}
export function setAgencyUserModal(payload) {
  return {
    type: ActionTypes.SET_AGENCY_USER_MODAL,
    payload,
  }
}
export function setAgencyUserPk(payload) {
  return {
    type: ActionTypes.SET_AGENCY_USER_PK,
    payload,
  }
}
export function setCoreClaimPaymentData(payload) {
  return {
    type: ActionTypes.SET_CORE_CLAIM_PAYMENT_DATA,
    payload,
  }
}

export function setClaimDamageData(payload) {
  return {
    type: ActionTypes.SET_CLAIM_DAMAGE_DATA,
    payload,
  }
}

export function setSelectedAgencyWyoData(payload) {
  return {
    type: ActionTypes.SET_SELECTED_WYO_AGENCY_DATA,
    payload,
  }
}
