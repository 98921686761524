export function sortMutliDimensionArray(sortingKey, arrayToSort) {
  let tuples = [];

  for (let i = 0; i < arrayToSort.length; i++) {
    tuples.push([arrayToSort[i][sortingKey], arrayToSort[i]]);
  }

  tuples.sort(function (a, b) {
    return a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0;
  });

  let sortedArray = [];
  for (let i = 0; i < tuples.length; i++) {
    sortedArray.push(tuples[i][1]);
  }

  return sortedArray;
}
