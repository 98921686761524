import React from "react";
import {
  Table,
  Button,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Input,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { CustomCurrencyInput } from "../../../../../../common/CustomCurrencyInput";
import { toast } from "react-toastify";

const PriorLossesTable = ({ financialDetails, setFinancialDetails, edit }) => {
  const { t } = useTranslation();

  const handleAddRow = () => {
    const newRow = { year: 0, value: 0 };
    setFinancialDetails({
      ...financialDetails,
      priorLosses: [...financialDetails.priorLosses, newRow],
    });
  };

  const handleRemoveRow = (indexToRemove) => {
    const updatedPriorLosses = financialDetails.priorLosses.filter(
      (_, index) => index !== indexToRemove
    );
    setFinancialDetails({
      ...financialDetails,
      priorLosses: updatedPriorLosses,
    });
  };

  const handleChange = (field, value, rowIndex) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);

    const updatedPriorLosses = financialDetails.priorLosses.map(
      (row, index) => {
        if (index === rowIndex) {
          return { ...row, [field]: parsedValue };
        }
        return row;
      }
    );

    setFinancialDetails({
      ...financialDetails,
      priorLosses: updatedPriorLosses,
    });
  };

  const CurrencyStyles = {
    textAlign: "right",
    border: "2px solid var(--chakra-colors-gray-300)",
    padding: "0.62rem",
    borderRadius: "12px",
    width: "100%",
    height: "53.5px",
  };

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Loss Year</Th>
            <Th>Value</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {financialDetails &&
            financialDetails?.priorLosses?.map((row, index) => (
              <Tr key={index}>
                <Td>
                  <Input
                    size="lg"
                    min={0}
                    isDisabled={!edit}
                    style={CurrencyStyles}
                    value={row.year}
                    onChange={(e) => {
                      const currentYear = new Date().getFullYear();
                      if (e.target.value <= currentYear) {
                        handleChange("year", Number(e.target.value), index);
                      } else {
                        toast.warn(
                          t("cannot be greater than year bult or current year"),
                          {
                            toastId: "priorLossesYear",
                          }
                        );
                      }
                    }}
                  />
                  {row.year === 0 && (
                    <span
                      style={{
                        fontSize: "small",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Year can't be zero")}
                    </span>
                  )}
                </Td>
                <Td>
                  <CustomCurrencyInput
                    disabled={!edit}
                    value={row.value}
                    onChange={(e) =>
                      handleChange(
                        "value",
                        Number(e.target.value.replace(/[^\d.]/g, "")),
                        index
                      )
                    }
                  />
                  {row.value === 0 && (
                    <span
                      style={{
                        fontSize: "small",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Value can't be zero")}
                    </span>
                  )}
                </Td>
                <Td>
                  <Button
                    size="sm"
                    disabled={!edit}
                    variant="danger"
                    onClick={() => handleRemoveRow(index)}
                  >
                    <MdDelete backgroundColor="white" color="red" size={30} />
                  </Button>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      {edit && (
        <Flex justifyContent="flex-end" pt={1}>
          <Button
            size="sm"
            disabled={!edit}
            onClick={handleAddRow}
            id="primary-colored-btn"
          >
            Add
          </Button>
        </Flex>
      )}
    </>
  );
};

export default PriorLossesTable;
