import React from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
} from "react-bootstrap";
import { Modal } from "react-bootstrap";
import FeeTable from "./FeeTable";
import { Currency } from "../../../common/Currency";
import { Flex } from "@chakra-ui/react";

export const SaveModalBody = ({
  selectedCardData,
  includeLossOfUse,
  setIncludeLossOfUse,
  includeContents,
  setIncludeContents,
  businessIncomePremium,
  setBusinessIncomePremium,
  calculateTotalPremium,
  feeLoader,
  fees,
}) => {
  const formatNumber = (value) => {
    return <Currency amount={value} negetiveAmountStyle="none" />;
  };
  return (
    <Modal.Body>
      <Container>
        <Row className="justify-content-center mb-0">
          <Col xs={12} sm={10} md={8}>
            <Card className="shadow-sm mb-3">
              <Card.Header className="bg-light text-center">
                <strong>
                  Selected Deductible: $
                  {new Intl.NumberFormat("en-US").format(
                    selectedCardData?.deductible
                  ) || 0}
                </strong>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {selectedCardData?.buildingAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Building Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.buildingAnnualizedPremium -
                              selectedCardData?.buildingAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.buildingAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>Building Additional Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.buildingAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.includeContentsAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Contents Premium</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeContentsAnnualizedPremium -
                              selectedCardData?.includeContentsAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.includeContentsAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>Contents Additional Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeContentsAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.otherStructuresAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Other Structure Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.otherStructuresAnnualizedPremium -
                              selectedCardData?.otherStructuresAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.otherStructuresAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>Other Structure Additional Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.otherStructuresAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.includeLossOfUseAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Loss of Use Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeLossOfUseAnnualizedPremium -
                              selectedCardData?.includeLossOfUseAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.includeLossOfUseAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>Loss of Use Additional Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeLossOfUseAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.improvementsAndBettermentsAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Improvements and Betterments Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.improvementsAndBettermentsAnnualizedPremium -
                              selectedCardData?.improvementsAndBettermentsAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.improvementsAndBettermentsAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>
                          Improvements and Betterments Additional Premium:
                        </td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.improvementsAndBettermentsAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}

                    {selectedCardData?.includeBusinessIncomeAnnualizedPremium !==
                      undefined && (
                      <tr>
                        <td>Business Income Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeBusinessIncomeAnnualizedPremium -
                              selectedCardData?.includeBusinessIncomeAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    {selectedCardData?.includeBusinessIncomeAdditionalPremium !==
                      undefined && (
                      <tr>
                        <td>Business Income Additional Premium:</td>
                        <td className="text-end">
                          {formatNumber(
                            selectedCardData?.includeBusinessIncomeAdditionalPremium
                          )}
                        </td>
                      </tr>
                    )}
                    <tr className="fw-bold">
                      <td>Total Premium</td>
                      <td className="text-end">
                        <Currency
                          amount={calculateTotalPremium()}
                          negetiveAmountStyle={"none"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center mb-0">
          <Col xs={12} sm={10} md={8}>
            <Card className="shadow-sm mb-3">
              <Card.Header className=" d-flex align-items-center gap-2 bg-light text-center justify-content-center">
                <strong>Fees</strong>{" "}
                {feeLoader && <Spinner animation="border" size="sm" />}
              </Card.Header>
              <Card.Body>
                <FeeTable fees={fees} />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center mb-3">
          <Col xs={12} sm={10} md={8}>
            <Card
              className="shadow-sm"
              style={{ opacity: feeLoader ? 0.5 : 1 }}
            >
              <Card.Header className="bg-light text-center">
                <strong>Total Premium Changed</strong>
              </Card.Header>
              <Card.Body>
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  color={"gray.500"}
                  fontWeight={"400"}
                  fontSize={"5xl"}
                >
                  <Currency
                    amount={
                      Number(calculateTotalPremium() || 0) +
                      Number(fees?.totalFee || 0)
                    }
                    negetiveAmountStyle={"none"}
                  />
                </Flex>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8}>
            <Card className="shadow-sm">
              <Card.Header className="bg-light text-center">
                <strong>Include Options</strong>
              </Card.Header>
              <Card.Body>
                {selectedCardData?.includeLossOfUseAdditionalPremium !==
                  undefined && (
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Check
                      style={{ userSelect: "none" }}
                      className="custom-switch"
                      id="includeLossOfUseSwitch"
                      type="switch"
                      label="Include Loss of Use"
                      checked={includeLossOfUse}
                      onChange={(e) => setIncludeLossOfUse(e.target.checked)}
                    />

                    <Card.Text
                      className={`text-end ${
                        !includeLossOfUse ? "text-danger" : "text-success"
                      }`}
                    >
                      {includeLossOfUse
                        ? "+" +
                          selectedCardData?.includeLossOfUseAnnualizedPremium
                        : selectedCardData?.excludeLossOfUseAdditionalPremium}
                    </Card.Text>
                  </Form.Group>
                )}

                <Form.Group className="mb-3 d-flex justify-content-between">
                  <Form.Check
                    style={{ userSelect: "none", cursor: "pointer" }}
                    className="custom-switch"
                    id="includeContentsSwitch"
                    type="switch"
                    label="Include Contents"
                    checked={includeContents}
                    onChange={(e) => setIncludeContents(e.target.checked)}
                  />
                  <Card.Text
                    className={`text-end ${
                      !includeContents ? "text-danger" : "text-success"
                    }`}
                  >
                    {includeContents
                      ? `+${selectedCardData?.includeContentsAnnualizedPremium}`
                      : `${selectedCardData?.excludeContentsAdditionalPremium}`}
                  </Card.Text>
                </Form.Group>
                {selectedCardData?.includeBusinessIncomeAdditionalPremium !==
                  undefined && (
                  <Form.Group className="mb-3 d-flex justify-content-between">
                    <Form.Check
                      style={{ userSelect: "none", cursor: "pointer" }}
                      className="custom-switch"
                      id="businessIncomePremiumSwitch"
                      type="switch"
                      label="Include Business Income"
                      checked={businessIncomePremium}
                      onChange={(e) =>
                        setBusinessIncomePremium(e.target.checked)
                      }
                    />
                    <Card.Text
                      className={`text-end ${
                        !businessIncomePremium ? "text-danger" : "text-success"
                      }`}
                    >
                      {businessIncomePremium
                        ? "+" +
                          selectedCardData?.includeBusinessIncomeAnnualizedPremium
                        : selectedCardData?.excludeBusinessIncomeAdditionalPremium}
                    </Card.Text>
                  </Form.Group>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  );
};
