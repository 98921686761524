import React from "react";
import { Form, Row } from "react-bootstrap";

function EndorsementForm({
  requestBody,
  setRequestBody,
  ResidentialDataDD,
  CommercialDataDD,
  hiscoxBuildingType,
  quoteId,
}) {
  return (
    <Form
      style={{
        padding: "0.5rem",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "1.5rem",
      }}
    >
      <Form.Group>
        <Form.Label>Policy No</Form.Label>
        <Form.Control
          type="text"
          readOnly
          value={quoteId || "N/A"}
          style={{ backgroundColor: "#f9f9f9" }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Effective Date</Form.Label>
        <Form.Control
          type="date"
          value={requestBody?.effectiveDate}
          style={{ backgroundColor: "#f9f9f9" }}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              effectiveDate: e.target.value,
            })
          }
        />
        {requestBody.effectiveDate === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Occupancy Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.occupancyType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              occupancyType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.OccupancyType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}{" "}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.OccupancyType?.map((item, index) => (
              <option key={index} value={item.value}>
                {" "}
                {item.label}{" "}
              </option>
            ))}
        </Form.Select>
        {requestBody?.occupancyType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Construction Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.constructionType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              constructionType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.ConstructionType?.map((item, index) => (
              <option key={index} value={item.value}>
                {" "}
                {item.label}{" "}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.ConstructionType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.constructionType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>
          {requestBody?.commercialProductType === "Tenanted"
            ? "Business Income & Extra Expense"
            : "Building (RCV)"}
        </Form.Label>
        <Form.Control
          type="text"
          name={
            requestBody?.commercialProductType === "Tenanted"
              ? "businessIncomeAndExtraExpenseAnnualValue"
              : "buildingReplacementCostValues"
          }
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              [e.target.name]: Number(e.target.value),
            });
          }}
          value={
            requestBody?.commercialProductType === "Tenanted"
              ? requestBody?.businessIncomeAndExtraExpenseAnnualValue
              : requestBody?.buildingReplacementCostValues
          }
        />
      </Form.Group>
      {requestBody?.commercialProductType === "Owned" && (
        <Form.Group>
          <Form.Label>Business Income & Extra Expense</Form.Label>
          <Form.Control
            type="number"
            name="businessIncomeAndExtraExpenseAnnualValue"
            onChange={(e) => {
              setRequestBody({
                ...requestBody,
                businessIncomeAndExtraExpenseAnnualValue: Number(
                  e.target.value
                ),
              });
            }}
            value={requestBody?.businessIncomeAndExtraExpenseAnnualValue || 0}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Form.Label>Contents (RVC)</Form.Label>
        <Form.Control
          type="text"
          name="contentsReplacementCostValues"
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              contentsReplacementCostValues: Number(e.target.value),
            });
          }}
          value={requestBody?.contentsReplacementCostValues}
        />
      </Form.Group>
      {Object?.prototype?.hasOwnProperty?.call(
        requestBody,
        "improvementsAndBettermentsReplacementCostValues"
      ) && (
        <Form.Group>
          <Form.Label>Improvements And Betterments (RCV)</Form.Label>

          <Form.Control
            type="number"
            name="improvementsAndBettermentsReplacementCostValues"
            onChange={(e) => {
              setRequestBody({
                ...requestBody,
                improvementsAndBettermentsReplacementCostValues: Number(
                  e.target.value
                ),
              });
            }}
            value={requestBody?.improvementsAndBettermentsReplacementCostValues}
          />
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>Foundation Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.foundationType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              foundationType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.FoundationType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.FoundationType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.foundationType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Additional Foundation Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.additionalFoundationType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              additionalFoundationType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.AdditionalFoundationType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.AdditionalFoundationType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.additionalFoundationType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Contents Cost Value Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.contentsCostValueType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              contentsCostValueType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.ContentsCostValueType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.ContentsCostValueType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.contentsCostValueType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Basement Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.basementType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              basementType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.BasementType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.BasementType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.basementType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Attached Garbage Type</Form.Label>
        <Form.Select
          type="text"
          name="occupancy"
          value={requestBody?.attachedGarageType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              attachedGarageType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {requestBody?.productType === "Residential" &&
            ResidentialDataDD?.AttachedGarageType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}

          {requestBody?.productType === "Commercial" &&
            CommercialDataDD?.AttachedGarageType?.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
        </Form.Select>
        {requestBody?.attachedGarageType === "" && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Building Over Water Type</Form.Label>
        <Form.Select
          type="text"
          name="buildingOverWaterType"
          value={requestBody?.buildingOverWaterType}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              buildingOverWaterType: e.target.value,
            })
          }
        >
          <option value=""></option>
          {ResidentialDataDD?.BuildingOverWaterType?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </Form.Select>
        {hiscoxBuildingType?.includes(requestBody?.buildingOverWaterType) && (
          <Form.Text className="text-danger">This field is required</Form.Text>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label>Year Built</Form.Label>
        <Form.Control
          type="text"
          name="yearBuilt"
          value={requestBody.yearBuilt}
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              yearBuilt: Number(e.target.value),
            });
          }}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Square Footage</Form.Label>
        <Form.Control
          type="text"
          name="squareFootage"
          value={requestBody.squareFootage}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              squareFootage: Number(e.target.value),
            })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Number of Stories</Form.Label>
        <Form.Control
          type="text"
          name="numberOfStories"
          value={requestBody.numberOfStories}
          onChange={(e) =>
            setRequestBody({
              ...requestBody,
              numberOfStories: Number(e.target.value),
            })
          }
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Elevation Height</Form.Label>
        <Form.Control
          type="text"
          name="elevationHeight"
          value={requestBody.elevationHeight}
          onChange={(e) => {
            setRequestBody({
              ...requestBody,
              elevationHeight: Number(e.target.value),
            });
          }}
        />
      </Form.Group>

      {Object?.prototype?.hasOwnProperty?.call(
        requestBody,
        "stockReplacementCostValue"
      ) &&
        requestBody.stockReplacementCostValue !== "" && (
          <Form.Group>
            <Form.Label>Stock Replacement Cost Value</Form.Label>
            <Form.Control
              type="text"
              name="stockReplacementCostValue"
              onChange={(e) => {
                setRequestBody({
                  ...requestBody,
                  stockReplacementCostValue: Number(e.target.value),
                });
              }}
              value={requestBody?.stockReplacementCostValue}
            />
          </Form.Group>
        )}
    </Form>
  );
}

export default EndorsementForm;
