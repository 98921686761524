import React from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import CommercialQuoteTab from "./CommercialQuoteTab";
import ExistingPolicyTab from "./ExistingPolicyTab";
import FloodQuoteTab from "./FloodQuoteTab";
import "./product-offerings.scss";
import ProductQuoteTab from "./ProductQuoteTab";

const ProductOfferings = () => {
  return (
    <div className="product-offerings-page-section">
      <h5 className="product-offerings-page-title">Product Offerings</h5>

      <Row>
        <Col xs="12" className="custom-tabs">
          <Tabs defaultActiveKey="Product Quote" id="uncontrolled-tab-example">
            <Tab eventKey="Product Quote" title="Product Quote">
              <ProductQuoteTab />
            </Tab>
            <Tab eventKey="Flood Quote" title="Flood Quote">
              <FloodQuoteTab />
            </Tab>
            <Tab eventKey="Commercial Quote" title="Commercial Quote">
              <CommercialQuoteTab />
            </Tab>
            <Tab
              eventKey="Existing Policy Look Up"
              title="Existing Policy Look Up"
            >
              <ExistingPolicyTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};
export default ProductOfferings;
