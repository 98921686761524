import React, { useEffect, useState, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { 
  getPrivateProductConfigurations, 
  getProducerInfo, 
  getHiscoxPremium 
} from "../../../../../../../services/floodService";
import { selectPrivateProducts, selectHiscoxRequestParams } from '../../../../../../../store/selectors/private-flood';
import { getProfileInfo } from '../../../../../../../services/profileService';
import PrivateProductItem from '../PrivateProductItem';

const PrivateProductModal = ({ emptyQuoteData, isModalOpen, closePvtProductModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const privateProducts = useSelector(state => selectPrivateProducts(state));
  const hiscoxRequestParams = useSelector(state => selectHiscoxRequestParams(state));
  const [displayPrivateProducts, setDisplayPrivateProducts] = useState(false);
  const { policyInfo } = emptyQuoteData;
  const [tenantInterest, setTenantInterest] = useState(null);
  const [adminEmail, setAdminEmail] = useState('');
  const [floodAPIProcessing, setFloodAPIProcessing] = useState(false);

  useEffect(() => {
    dispatch(getProfileInfo()).then((res) => {
      setAdminEmail(res?.data?.data?.Email);
    });
  }, []);

  useEffect(() => {
    if (privateProducts.privateproduct.length > 0 && !floodAPIProcessing) {
      setFloodAPIProcessing(true);
      setDisplayPrivateProducts(true);
      sendFloodRequests();
    }
  }, [privateProducts.privateproduct]);

  useEffect(() => {
    const buildingDeductible = emptyQuoteData.propertyCoverageInfo?.s_BuildingDeductible;
    const customBldgDed = buildingDeductible ? parseFloat(buildingDeductible.replace('FLDDED', '')) : 0;
    const contentDeductible = emptyQuoteData.propertyCoverageInfo?.s_ContentDeductible;
    const customContentDed = contentDeductible ? parseFloat(contentDeductible.replace('FLDDED', '')) : 0;
    if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      customBldgDed > 0 &&
      customContentDed > 0
    ) {
      setTenantInterest('BuildingAndPersonalProperty');
    } else if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      !customBldgDed > 0 &&
      customContentDed > 0
    ) {
      setTenantInterest('PersonalProperty');
    } else if (
      emptyQuoteData.propertyInfo.s_Usage === 'TENANT' &&
      customBldgDed > 0 &&
      !customContentDed > 0
    ) {
      setTenantInterest('BuildingOnly');
    }
  }, [emptyQuoteData.propertyInfo.s_Usage]);

  const sendFloodRequests = () => {
    getHiscoxFloodQuote();
  };

  const handleClose = () => {
    closePvtProductModal();
  };

  const getHiscoxFloodQuote = async () => {
    try {
      const agencyInfo = await getProducerInfo(policyInfo.n_AgencyPeson_FK, policyInfo.n_SubAgentPersoninfo_FK);
      const agencyAddress = {
        city: agencyInfo.address?.s_CityName ?? '',
        county: agencyInfo.address?.s_CountyName ?? '',
        line1: agencyInfo.address?.s_AddressLine1 ?? '',
        line2: agencyInfo.address?.s_AddressLine2 ?? '',
        state: agencyInfo.address?.s_StateCode ?? '',
        street: agencyInfo.address?.s_StreetName ?? '',
        zip: agencyInfo.address?.s_PostalCode ?? '',
        googlePlaceId: '',
        latitude: agencyInfo.address?.s_Latitude ?? '',
        longitude: agencyInfo.address?.s_Longitude ?? '',
      };

      hiscoxRequestParams['agency'] = { ...agencyInfo, address: agencyAddress, agencyNo: hiscoxRequestParams['agency']['agencyNo'], agencyPersonPk: policyInfo.n_AgencyPeson_FK };
      hiscoxRequestParams['building'] = { ...hiscoxRequestParams['building'], tenantInterest };
      hiscoxRequestParams['agent'] = { ...hiscoxRequestParams['agent'], email: adminEmail };
      const propertyCoverages = {
        dwelling: emptyQuoteData.propertyCoverageInfo.n_BuildingCvg,
        personalProperty: emptyQuoteData.propertyCoverageInfo.n_ContentCvg,
        otherStructures: emptyQuoteData.propertyCoverageInfo.n_BuildingCvg * 0.1,
        lossOfUse: emptyQuoteData.propertyCoverageInfo.n_BuildingCvg * 0.2,
      };

      const buildingDeductibleString = emptyQuoteData.propertyCoverageInfo?.s_BuildingDeductible;
      const propertyDeductible = buildingDeductibleString ? parseFloat(buildingDeductibleString.replace('FLDDED', '')) : 0;
      
      const apiRequestParams = { ...hiscoxRequestParams, coverages: propertyCoverages, deductible: propertyDeductible };

      const privateProductArray = privateProducts.privateproduct;
      dispatch(getHiscoxPremium(apiRequestParams, false, privateProductArray));
    } catch (error) {
      console.error("Error while fetching Hiscox flood quote:", error);
      throw error;
    }
  };

  return (
    <Fragment>
      <Modal size="xl" show={isModalOpen} onHide={handleClose} id="confirmPropInfo">
        <Modal.Header closeButton>
          <Modal.Title>{t('Private Product Quotes')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto', textAlign: 'justify' }}>
          {displayPrivateProducts &&
            privateProducts.privateproduct.map((privateOption, key) => (
              <div key={key} style={{ width: "auto" }}>
                <PrivateProductItem
                  data={privateOption}
                  isLastItem={key === privateProducts.privateproduct.length - 1}
                  getExcessFloodQuote={''}
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default PrivateProductModal;