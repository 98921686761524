import React from "react";
import { Box, Flex, Text, VStack, Switch } from "@chakra-ui/react";
import { ChakraSelect } from "../../commercial-quote/Components/CustomComponents";
import { useTranslation } from "react-i18next";
import { useQuote } from "../../CommCreateQuote";
import {
  theftDeductibleFields,
  waterSublimitFields,
  windHailDeductibles,
} from "../../commercial-quote/data/commercialDD";
import { CustomCurrencyInput } from "../../common/CustomCurrencyInput";

const FormField = ({ label, children }) => (
  <Flex alignItems="center" justifyContent="space-between">
    <Text pt={0} mb={0}>
      {label}
    </Text>
    {children}
  </Flex>
);

const SelectField = ({ options, ...props }) => (
  <ChakraSelect w="30%" {...props}>
    <option value=""></option>
    {options.map((field, i) => (
      <option key={i} value={field}>
        {field}
      </option>
    ))}
  </ChakraSelect>
);

export const BlanketAdditional = () => {
  const { t } = useTranslation();
  const { blanketDisplayCoverages, setBlanketDisplayCoverages, selectedCard } =
    useQuote();

  const updateCoverageDetails = (name, value) => {
    setBlanketDisplayCoverages({
      ...blanketDisplayCoverages,
      additionalCoverages: {
        ...blanketDisplayCoverages.additionalCoverages,
        [name]: value,
      },
    });
  };

  return (
    <Box
      maxWidth="600px"
      height={selectedCard === "ConvenienceStore" ? 650 : 600}
      maxHeight={"700px"}
      margin="auto"
      padding="4"
      overflow={"auto"}
    >
      <VStack spacing="6" align="stretch">
        <FormField label={t("Water Sublimit")}>
          <SelectField
            value={
              blanketDisplayCoverages?.additionalCoverages?.waterSublimit || ""
            }
            onChange={(e) => {
              updateCoverageDetails("waterSublimit", e.target.value);
            }}
            options={waterSublimitFields}
          />
        </FormField>

        {selectedCard === "ConvenienceStore" && (
          <>
            <FormField label={t("Canopy")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.canopy}
                onValueChange={(value) => {
                  updateCoverageDetails("canopy", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Pumps")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.pumps}
                onValueChange={(value) => {
                  updateCoverageDetails("pumps", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
            <FormField label={t("Sign")}>
              <CustomCurrencyInput
                value={blanketDisplayCoverages?.additionalCoverages?.sign}
                onValueChange={(value) => {
                  updateCoverageDetails("sign", value);
                }}
                currencyStyles={{
                  width: "30%",
                  borderColor: "var(--chakra-colors-gray-400)",
                }}
              />
            </FormField>
          </>
        )}
        <FormField label={t("Exclude Sinkhole")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages?.excludSinkhole ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "excludSinkhole",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("Cosmetic Roof Damage")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages
                ?.cosmeticRoofDamage === "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "cosmeticRoofDamage",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>

        <FormField label={t("ACV Roof Surface")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.additionalCoverages?.acvRoofSurface ===
              "YES"
            }
            onChange={(e) => {
              updateCoverageDetails(
                "acvRoofSurface",
                e.target.checked ? "YES" : "NO"
              );
            }}
            size="lg"
          />
        </FormField>
        <FormField label={t("Theft Exclusion")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.basicCoverages?.theftExclusions === "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                basicCoverages: {
                  ...blanketDisplayCoverages.basicCoverages,
                  theftExclusions: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>

        <FormField label={t("Wind Exclusion")}>
          <Switch
            isChecked={
              blanketDisplayCoverages?.basicCoverages?.windExclusions === "YES"
            }
            onChange={(e) => {
              setBlanketDisplayCoverages({
                ...blanketDisplayCoverages,
                basicCoverages: {
                  ...blanketDisplayCoverages.basicCoverages,
                  windExclusions: e.target.checked ? "YES" : "NO",
                },
              });
            }}
            size="lg"
            defaultIsChecked
          />
        </FormField>
      </VStack>
    </Box>
  );
};
