import React, { useState } from 'react';
import { Modal, Form } from "react-bootstrap";

const ViewJson = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    // Close Josn Modal
    const toggle = () => {
        setIsOpen(!isOpen);
        props.setViewJson(null);
    }

    return (
        <Modal
            className="custom-dashboard-modal"
            size="mdyy"
            show={isOpen}
        >
            <Modal.Header closeButton className="custom-dashboard-modal-header" onClick={() => toggle()}>
                <Modal.Title>Response</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-dashboard-modal-body">
                <Form.Control
                    className="dashboard-group-input-flood"
                    as="textarea" rows={30}
                    defaultValue={props.json && JSON.stringify(JSON.parse(props.json), undefined, 4)}
                    style={{minHeight: '500px'}}
                    readOnly
                />
            </Modal.Body>
        </Modal>
    )
}

export default ViewJson
