import Http from "../../../../Http";

export async function getAutoLocationData({ requestBody }) {
  const url = `/api/quote/getPropertyDataAddr/10`;

  try {
    const { data } = await Http.post(url, requestBody);
    return data;
  } catch (error) {
    console.error("Error fetching base data:", error);
    throw error;
  }
}
