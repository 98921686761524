import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { FcDeleteDatabase } from "react-icons/fc";
import { toast } from "react-toastify";
import { FaExclamationTriangle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { MdCancel, MdDeleteForever } from "react-icons/md";

const LocationDeleteButton = ({ index, onDelete, totalLocations, address, isBind }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();
  const { t } = useTranslation();
  const handleDelete = () => {
    if (totalLocations <= 1) {
      toast.error("At least one location is required.");
      return;
    }
    onDelete(index);
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        isDisabled={isBind}
        mt={2.5}
        onClick={() => setIsOpen(true)}
        variant="outline"
        borderRadius={"2xl"}
        borderColor={"gray.200"}
        size="md"
      >
        <FcDeleteDatabase size={30} />
      </IconButton>

      <AlertDialog
        isCentered
        size={"lg"}
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent borderRadius="xl" boxShadow="lg">
            <AlertDialogHeader
              borderRadius="xl"
              bg={"primary.50"}
              fontSize="lg"
              fontWeight="bold"
              display="flex"
              color="red.600"
              justifyContent={"center"}
              borderBottom="1px solid"
              borderColor="gray.200"
              py={4}
            >
              <MdDeleteForever size={24} />
              <Text ml={2}>{t("Delete Location")}?</Text>
            </AlertDialogHeader>

            <AlertDialogBody
              display="flex"
              alignItems="center"
              justifyContent="center"
              py={8}
              textAlign="center"
              color="gray.600"
              fontSize="md"
            >
              <Flex direction="column" alignItems="center">
                <Text
                  fontWeight="bold"
                  borderBottom={"1px solid"}
                  borderColor={"primary"}
                >
                  {address || ""}
                </Text>
                <FaExclamationTriangle size={40} color="orange.400" />
                <Box mt={4}>
                  {t(
                    "Are you sure you want to delete this building detail? This action cannot be undone."
                  )}
                </Box>
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter
              justifyContent="center"
              borderTop="1px solid"
              borderColor="gray.200"
              py={4}
            >
              <Button
                borderRadius={"xl"}
                leftIcon={<MdCancel />}
                variant="outline"
                colorScheme="gray"
                mr={3}
                ref={cancelRef}
                onClick={() => setIsOpen(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                borderRadius={"xl"}
                colorScheme="red"
                leftIcon={<MdDeleteForever />}
                onClick={handleDelete}
                ml={3}
              >
                {t("Delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default LocationDeleteButton;
