import CreditScore from "./CreditScore";
import LossHistory from "./LossHistory";
import PropertyDetail from "./PropertyDetail";
import RoofProperties from "./RoofProperties";
import UnderWritingQuestion from "./UnderWritingQuestion";

export default function Underwriting() {
  return (
    <>
      <PropertyDetail />
      <RoofProperties />
      <LossHistory />
      <UnderWritingQuestion />
      <CreditScore />
    </>
  );
}
