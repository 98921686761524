import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Code,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { BiCheckCircle } from "react-icons/bi";

const CopyIcon = ({ copied }) =>
  copied ? <BiCheckCircle size={25} /> : <FiCopy size={25} />;

const ErrorLogModal = ({ isOpen, onClose, errorData, type = "Create" }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(errorData, null, 2));
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior="inside"
      size="5xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Future Proof Payload</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex justifyContent="space-between" alignItems="center">
              <Text fontSize={"xl"} color={"neutral.500"}>
                {type} Request Payload
              </Text>
              <Tooltip
                label={copied ? "Copied!" : "Copy to Clipboard"}
                aria-label="Copy to Clipboard"
              >
                <Button variant="ghost" colorScheme="blue" onClick={handleCopy}>
                  <CopyIcon copied={copied} />
                </Button>
              </Tooltip>
            </Flex>
            <Code
              w={"full"}
              fontWeight={600}
              as={"pre"}
              bg="whitesmoke"
              fontSize="xl"
            >
              {JSON.stringify(errorData, null, 2)}
            </Code>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorLogModal;
