import React, { useEffect, useState, useCallback } from "react";
import CancelPolicy from "./CancelPolicy";
import Endorsement from "./Endorsement";
import ReinstatePolicy from "./ReinstatePolicy";
import RenewPolicy from "./RenewPolicy";
import "./CommonStyles.scss";
import "react-toastify/dist/ReactToastify.css";
import ActivityLogs from "./ActivityLogs";
import { useSelector } from "react-redux";
import { getPropertyData } from "./api/getPropertyData";
import { BindEndorsement } from "./components/BindEndorsement";
import DocumentModal from "./DocumentModal";
import { BindRnewedPolicy } from "./components/BindRenewPolicy";

const MemoizedCancelPolicy = React.memo(CancelPolicy);
const MemoizedEndorsement = React.memo(Endorsement);
const MemoizedReinstatePolicy = React.memo(ReinstatePolicy);
const MemoizedRenewPolicy = React.memo(RenewPolicy);

//CurrentDate+60>End date then renew button should be visible
export default function HiscoxActions({
  setLoading,
  quoteStatus,
  tranTypeScreenName,
  transactionCycleCode,
  termSelection,
}) {
  const [coreIDs, setCoreIDs] = useState({
    PolicyPK: null,
    TransPK: null,
  });
  const [data, setData] = useState({});
  const [quoteDetailData, setQuoteDetailData] = useState({});
  const reduxStore = useSelector((state) => state);
  const { productType, commercialProductType } =
    quoteDetailData?.RA?.metadata || {};
  const policyNumber = reduxStore?.coreSystem?.applicationData?.Policy_No;
  const transTypeName =
    reduxStore?.coreSystem?.applicationData?.s_TranTypeScreenName;

  const { s_ProductCode, n_ProductId_FK } =
    reduxStore?.coreSystem?.applicationData;
  //s_productCode: is product name & n_ProductId_FK: is product number or ID
  //for ex: Unifi & 1.

  useEffect(() => {
    if (
      reduxStore?.coreSystem?.n_POPolicyMasterFK &&
      reduxStore?.coreSystem?.n_POTransactionFK
    ) {
      setCoreIDs({
        PolicyPK: reduxStore?.coreSystem?.n_POPolicyMasterFK || 0,
        TransPK: reduxStore?.coreSystem?.n_POTransactionFK || 0,
      });
    }
  }, [
    reduxStore?.coreSystem?.n_POPolicyMasterFK,
    reduxStore?.coreSystem?.n_POTransactionFK,
  ]);

  const fetchData = useCallback(async () => {
    const policyNoID = coreIDs.PolicyPK;
    const termMasterPK = coreIDs.TransPK;
    try {
      const propertyData = await getPropertyData({ policyNoID, termMasterPK });
      if (propertyData) {
        setQuoteDetailData(propertyData);
      }
    } catch (error) {
      console.error("Error fetching property data:", error);
    }
  }, [coreIDs]);

  const today = new Date();
  // Add 60 days to today's date
  const allowedDateForRenewal = new Date(today);
  allowedDateForRenewal.setDate(today.getDate() + 60);
  const termEndDate = new Date(termSelection[0]?.d_TermEndDate);

  useEffect(() => {
    if (coreIDs.PolicyPK && coreIDs.TransPK) {
      fetchData();
    }
  }, [coreIDs, fetchData]);

  useEffect(() => {
    const policyFetchedData = {
      data: {
        ...quoteDetailData?.RA?.metadata,
        squareFootage: quoteDetailData?.RA?.s_TotalSqFootage || 0,
        quoteId: policyNumber,
        yearBuilt:
          quoteDetailData?.RA?.d_DateOfConstruction?.substring(0, 4) || "",
        numberOfStories: quoteDetailData?.RA?.s_NoOfFloors,
        constructionType: quoteDetailData?.RA?.s_BldgConstructionType ?? "",
        occupancyType: quoteDetailData?.RA?.s_Occupancy ?? "",
        buildingOverWaterType: quoteDetailData?.RA?.s_BuildingOverWater ?? "",
        residentialLimits: quoteDetailData?.RA?.metadata?.limits || [],
        buildingReplacementCostValues:
          quoteDetailData?.RA?.metadata?.replacementCostValues?.building || 0,
        improvementsAndBettermentsReplacementCostValues:
          quoteDetailData?.RA?.metadata?.replacementCostValues
            ?.improvementsAndBetterments || 0,
        contentsReplacementCostValues:
          quoteDetailData?.RA?.metadata?.replacementCostValues?.contents || 0,
        priorLosses: quoteDetailData?.RA?.metadata?.priorLosses || [],
      },
    };
    if (policyFetchedData?.data) {
      setData(policyFetchedData || {});
    }
  }, [quoteDetailData, policyNumber]);

  return (
    <>
      <div
        className="d-flex flex-row py-2 justify-content-end gap-2"
        style={{ marginRight: "12.5px" }}
      >
        <ActivityLogs />
        <DocumentModal
          policyNumber={policyNumber}
          policyPK={coreIDs.PolicyPK}
        />
        {!quoteStatus &&
          tranTypeScreenName !== "Cancel Policy" &&
          transactionCycleCode == "ISSUED" && (
            <>
              <MemoizedCancelPolicy
                productType={productType}
                commercialProductType={commercialProductType}
                quoteId={policyNumber}
              />
              <MemoizedEndorsement
                quoteDetailData={data}
                quoteId={policyNumber}
                commercialProductType={commercialProductType}
                productType={productType}
                productCode={s_ProductCode}
                productId={n_ProductId_FK}
              />
            </>
          )}
        {/* <BindEndorsement /> */}
        {!quoteStatus && tranTypeScreenName === "Cancel Policy" && (
          <MemoizedReinstatePolicy quoteId={policyNumber} />
        )}
        {!quoteStatus &&
          tranTypeScreenName !== "Cancel Policy" &&
          transactionCycleCode == "ISSUED" && (
            // allowedDateForRenewal >= termEndDate &&
            <MemoizedRenewPolicy
              quoteDetailData={data}
              quoteId={policyNumber}
              commercialProductType={commercialProductType}
              productType={productType}
              productCode={s_ProductCode}
              productId={n_ProductId_FK}
            />
          )}
          {quoteStatus &&
            tranTypeScreenName === "Endorsement" &&
            transactionCycleCode === "QUOTE" && (
              <BindEndorsement
                policyNumber={policyNumber}
                productType={productType}
                commercialProductType={commercialProductType}
                coreIDs={coreIDs}
                quoteDetailData={quoteDetailData}
                setLoading={setLoading}
              />
            )}
          {quoteStatus &&
            tranTypeScreenName === "Renewal" &&
            transactionCycleCode === "QUOTE" && (
              <BindRnewedPolicy
                productCode={s_ProductCode}
                policyNumber={policyNumber}
                productType={productType}
                commercialProductType={commercialProductType}
                coreIDs={coreIDs}
                quoteDetailData={quoteDetailData}
                setLoading={setLoading}
              />
            )}
      </div>
    </>
  );
}
