import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import CommonHeader from "./CommonHeader";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { useTranslation } from "react-i18next";

const PropertyInfoTab = (props) => {
  const { t } = useTranslation();
  const [propertyInfo, setPropertyInfo] = useState({});
  const [renovationData, setRenovationData] = useState([]);
  const [baseData, setBaseData] = useState({});

  useEffect(() => {
    if (Object.keys(props.propertyInfo).length > 0) {
      setPropertyInfo(props.propertyInfo)
      setRenovationData(props.propertyInfo.renovationType)
    }
    if (Object.keys(props.baseData).length > 0) {
      setBaseData(props.baseData)
    }
  }, [props.propertyInfo, props.baseData]);

  const appcodeToValue = (key, value) => {
    if (Object.keys(propertyInfo).length > 0 && Object.keys(baseData).length > 0) {
      var displayData = baseData[key].filter(x => x.s_AppCodeName === propertyInfo[value])
      return Object.keys(displayData).length > 0 ? displayData[0].s_AppCodeNameForDisplay : '';
    }
    return "";
  }

  const columns = [
    {
      dataField: "s_RenovationCode",
      text: t("Renovation Code"),
      //sort: true,
    },
    {
      dataField: "s_RenovationTypeCode",
      text: t("Renovation Type"),
    },
    {
      dataField: "s_RenovationYear",
      text: t("Renovation Year"),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: true,
    // },
  ];

  return (
    <Row>
      {/* <Col xs="12" className="billing-tab-row1">
        <CommonHeader />
      </Col> */}
      <Col xs="12" id="policy-details-tab1-col1" style={{ marginTop: "44px" }}>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Table borderless className="policy-details-info-table">
              <tbody>
                <tr>
                  <th>{t('Year Build')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_YearBuilt}

                  </td>
                </tr>

                <tr>
                  <th>{t('Number of Units')}</th>
                  <td>{Object.keys(propertyInfo).length > 0 && propertyInfo.n_NoOfStories}</td>
                </tr>
                <tr>
                  <th>{t('Construction type')}</th>
                  <td>
                    {
                      appcodeToValue('CONSTRTYPE', 's_ConstrTypeCode')
                    }
                  </td>
                </tr>
                <tr>
                  <th>{t('Structure Type')}</th>
                  <td>
                    {appcodeToValue('STRUCTYPE', 's_StructureTypeCode')}
                  </td>
                </tr>
                <tr>
                  <th>{t('PPC')}</th>
                  <td>
                    {appcodeToValue('YESNOONLY', 's_PriorPolicyCarrier')}
                  </td>
                </tr>
                <tr>
                  <th>{t('BCEG')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_BCEGCode}

                  </td>
                </tr>
                <tr>
                  <th>{t('Roof type')}</th>
                  <td>
                    {appcodeToValue('ROOFTYPE', 's_RoofTypeCode')}

                  </td>
                </tr>
                <tr>
                  <th>{t('Roof Sub Type')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_RoofCondition}
                  </td>
                </tr>
                <tr>
                  <th>{t('Occupancy Type')}</th>
                  <td>
                    {appcodeToValue('OCCUPYTYPE', 's_OccupancyTypeCode')}
                  </td>
                </tr>
                <tr>
                  <th>{t('Area Sft')}.</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_HomeSqft}
                  </td>
                </tr>
                <tr>
                  <th>{t('Swimming Pool')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_SwimPoolTypeCode}

                  </td>
                </tr>
                <tr>
                  <th>{t('Prior Insurance')}</th>
                  <td>
                    {appcodeToValue('YESNOONLY', 's_PriorPolicyCarrier')}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" xl="6">
            <Table borderless className="policy-details-info-table">
              <tbody>
                <tr>
                  <th>{t('No. of Stories')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_NoOfStories}
                  </td>
                </tr>

                <tr>
                  <th>{t('No. of Resident')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_NoOfResidents}
                  </td>
                </tr>
                <tr>
                  <th>{t('Replacement Value')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_ReplacementCost}
                  </td>
                </tr>
                <tr>
                  <th>{t('Terrain')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_terrainexposurecode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Valuation ID')}</th>
                  <td>
                    --
                  </td>

                </tr>
                <tr>
                  <th>{t('Policy Code')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_PoliceCode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Fire Code')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_FireCode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Distance From Fire Station')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_DistanceFireStation}
                  </td>
                </tr>
                <tr>
                  <th>{t('Distance From Fire Hydrant')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.n_DistanceHydrant}
                  </td>
                </tr>
                <tr>
                  <th>{t('Wind Pool Eligibility')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_WindPoolCode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Usage')}</th>
                  <td>
                    {Object.keys(propertyInfo).length > 0 && propertyInfo.s_UsageTypeCode}
                  </td>
                </tr>
                <tr>
                  <th>{t('Purchase Date')}</th>
                  <td>
                    --
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Protection Devices')}</span>
          </div>

          <Row
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(27, 43, 64, 0.2)",
              padding: "32px",
            }}
          >
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Fire Alarm')}:</th>
                    <td>{
                      Object.keys(propertyInfo).length > 0 &&
                      propertyInfo.s_FireAlarmCode}</td>
                  </tr>
                  <tr>
                    <th>{t('Sprinkler')}:</th>
                    <td>{
                      Object.keys(propertyInfo).length > 0 &&
                      propertyInfo.s_SprinklerCode}</td>
                  </tr>
                  <tr>
                    <th>{t('Gated Community')}:</th>
                    {/* <td>{appcodeToValue('YESNOONLY', 's_GatedCommunityCode')}</td> */}

                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Burglar Alarm')}:</th>
                    <td>{
                      Object.keys(propertyInfo).length > 0 &&
                      propertyInfo.s_BurglarAlarmCode}</td>
                  </tr>
                  <tr>
                    <th>{t('Storm Shutter')}:</th>
                    <td>{
                      Object.keys(propertyInfo).length > 0 &&
                      propertyInfo.s_StormShutterCode}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">
              {t('Wind Mitigation Modifiers')}
            </span>
          </div>

          <Row
            style={{
              backgroundColor: "#fff",
              border: "1px solid rgba(27, 43, 64, 0.2)",
              padding: "32px",
            }}
          >
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Roof Cover')}:</th>
                    <td>
                      {appcodeToValue('HOROOFCOVER', 's_roofcovercode')}

                    </td>
                  </tr>
                  <tr>
                    <th>{t('Roof Wall Connection')}:</th>
                    <td>
                      {appcodeToValue('HOROOFWALLCONN', 's_roof_wallconnectcode')}
                    </td>
                  </tr>
                  <tr>
                    <th>{t('Opening Protection')}:</th>
                    <td>
                      {appcodeToValue('HOWINDOWPROTECT', 's_windowprotectcode')}

                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6" xl="6">
              <Table borderless className="policy-details-info-table">
                <tbody>
                  <tr>
                    <th>{t('Roof Deck Attachment')}:</th>
                    <td>{appcodeToValue('HOROOFDECKATT', 's_roofdeckattachcode')}</td>

                  </tr>
                  <tr>
                    <th>{t('Roof Shape')}:</th>
                    <td>{appcodeToValue('PLROOFSHAPE', 's_RoofShapeCode')}</td>

                  </tr>
                  <tr>
                    <th>{t('Secondary Water Resistance')}:</th>
                    <td>{appcodeToValue('HOWATERRESIST', 's_secwaterresistcode')}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs="12" className="billing-tab-row2">
        <div id="dashboard-table-section">
          <div className="table-main-title-div">
            <span className="table-main-title-name">{t('Renovation Details')}</span>
          </div>
          <BootstrapTable
            wrapperClasses="coverage-detail-table-main cdtmm"
            bootstrap4
            keyField="n_PoRiskRenovation_PK"
            data={renovationData}
            columns={columns}
            hover
          />
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    propertyInfo: state.coreSystem.propertyInfo,
    baseData: state.coreSystem.baseData,
  };
};
export default connect(mapStateToProps)(PropertyInfoTab);
