import { findLastKey } from 'lodash';
import {GET_PRODUCER_DATA,SET_DYNAMIC_LOADER,SET_AGENCY_USER_MODAL,SET_AGENCY_USER_PK} from  '../action-types';

const initialData = {
    producerData:{},
    loader:false,
    openAgentUserModel:false,
    agencyUserPk:0
}

const setProducerData = (state,payload) => {
    const stateObj = Object.assign({}, state, {
        producerData : payload,       
      });
      return stateObj;
}

const producerDataProcess = (state = initialData, { type, payload }) => {
    switch (type) {
        case GET_PRODUCER_DATA:
            return setProducerData(state,payload)
        case SET_DYNAMIC_LOADER:
            return { ...state, loader: payload };
        case SET_AGENCY_USER_MODAL:
            return { ...state, openAgentUserModel: payload };
        case SET_AGENCY_USER_PK:
            return { ...state, agencyUserPk: payload };
        default:
            return state
    }
}

export default producerDataProcess;